<div class="d-flex h-100 customer-wrap add-user">
  <app-side-list
    [getModuleId]="moduleId"
    [getModuleName]="moduleName"
    (showSideListAction)="showSideListAction($event)"
    (toggleSideList)="toggleSideList()"
    [reloadSideList]="reloadSideList"
  ></app-side-list>
  <div
    class="form"
    [ngClass]="{
      'hide-sidelist': istoggleSideList,
      'mb-4':
        (selectedIndex === 0 &&
          customerId === commonService.defaultGuidValue) ||
        (selectedIndex === 1 && customerId !== commonService.defaultGuidValue)
    }"
    [hidden]="isShowSideListAction"
  >
    <div class="content-body-wrapper">
      <div class="content-body">
        <p class="header-text d-flex justify-space-between align-items-center">
          <span>
            {{
              customerId === commonService.defaultGuidValue
                ? "Add New Customer"
                : "Edit Customer"
            }}
          </span>
          <button
            mat-button
            class="header-text-close d-flex align-items-center"
            disableRipple
            (click)="onCloseClick()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </p>
        <mat-tab-group
          mat-align-tabs="start"
          disableRipple
          animationDuration="0ms"
          [selectedIndex]="selectedIndex"
          (selectedTabChange)="tabClick($event)"
        >
          <mat-tab
            label="Overview"
            *ngIf="customerId !== commonService.defaultGuidValue"
          >
            <div>
              <app-contact-transaction
                [triggerCustomerId]="triggerAccountDetails"
              ></app-contact-transaction>
            </div>
          </mat-tab>
          <mat-tab label="Customer Details">
            <div class="alert-message" *ngIf="isViewPermission$ | async">
              <mat-icon class="material-icons-outlined">error_outline</mat-icon>
              {{ commonNotificationText.ViewOnlyAccessMessage }}
            </div>
            <div>
              <div class="accordion-toggle">
                <mat-icon
                  (click)="toggleAccordion(); accordion.openAll()"
                  *ngIf="isExpandAll"
                >
                  keyboard_double_arrow_down
                </mat-icon>
                <mat-icon
                  (click)="toggleAccordion(); accordion.closeAll()"
                  *ngIf="!isExpandAll"
                >
                  keyboard_double_arrow_up
                </mat-icon>
              </div>
              <mat-accordion [multi]="true">
                <mat-expansion-panel
                  [expanded]="true"
                  [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon
                        fontSet="material-icons-outlined"
                        class="text-primary"
                        >description</mat-icon
                      >
                      <div class="d-flex flex-column">
                        <span>Contact Details</span>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-add-contact-details
                    [getModuleId]="moduleId"
                    [getModuleName]="moduleName"
                    [triggerEditData]="triggerEditData"
                  ></app-add-contact-details>
                </mat-expansion-panel>

                <mat-expansion-panel
                  [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <mat-icon
                        fontSet="material-icons-outlined"
                        class="text-primary"
                        >receipt</mat-icon
                      >
                      <div class="d-flex flex-column">
                        <span>Commercial Details</span>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-add-commerical-details
                    [triggerEditData]="triggerEditData"
                  ></app-add-commerical-details>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <app-buttons
        *ngIf="
          (customerId === commonService.defaultGuidValue ||
            selectedIndex === 1) &&
          !(isViewPermission$ | async)
        "
        [getModuleId]="moduleId"
        (triggerOnSaveClick)="onSave($event)"
        (triggerOnCancelClick)="onCancel(true)"
      >
      </app-buttons>
    </div>
  </div>
</div>
