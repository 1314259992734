import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  AccountEntity,
  ChartOfAccountCreditorsGroupTypeName,
  GroupNames,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import {
  ChartOfAccountListParam,
  Currency,
  GlobalComponent,
  GroupListModel,
  SideListModel,
} from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import {
  CommonState,
  CreateAccount,
  CurrencyState,
  GetAllBranchList,
  GetAllDepartmentListByBranch,
  GetClientCurrencyList,
  GetCurrencyList,
  GetDataByAccountId,
  GetGroupAccountsBasedOnGroupId,
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetNonStandardAccountList,
  GetNonStandardAccountListByBranch,
  GetRefreshCurrencyData,
  MenuState,
} from '@app/core/Store';
import { QuickAddComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-bill',
  templateUrl: './add-bill.component.html',
  styleUrls: ['./add-bill.component.scss'],
})
export class AddBillComponent implements OnInit {
  billForm: FormGroup;
  customerList: SideListModel[];
  currencyList: Currency[];
  supplierList: SideListModel[];
  billTypeList: SideListModel[];
  accountGroupList: GroupListModel[];
  tempAccountGroupList: GroupListModel[];

  moduleId = Modules;
  departmentList: any[] = [];
  branchList: any[] = [];

  defaultCurrency: Guid;

  @Input() triggerEditData: Observable<any>;
  @Input() triggerTransactionLogData: Observable<any>;

  @Input() isFromBankImport: any;

  @Output() triggerOnVATChange = new EventEmitter<boolean>();

  @Output() triggerAccountSelectedOption: EventEmitter<string> =
    new EventEmitter<string>();

  @Output()
  readonly supplierClick = new EventEmitter<any>();

  @Output()
  readonly isVatIncludedClick = new EventEmitter<any>();

  @Output() triggerCurrencySelectedOption: EventEmitter<number> =
    new EventEmitter<number>();

  @Output() triggerCurrencyRefreshOption: EventEmitter<number> =
    new EventEmitter<number>();

  @Output() triggerSelectedCurrencyRate: EventEmitter<number> =
    new EventEmitter<number>();

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;
  @Output()
  readonly branchChange = new EventEmitter<any>();
  private destroy$ = new Subject<void>();

  disabledEditButton = true;
  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;
  isVatAmountIncluded = false;
  isVatRegistered: any;

  periodicDate: any;
  clientCurrencyList: any = [];
  isMultiCurrencySelected = false;
  selectedCurrencyRate: number;
  selectedCurrencyId: number;
  selectedCurrencySymbol: string;
  currencyIds: any = [];
  refreshedCurrencyData: any = [];
  refreshedCurrencyRate: number;
  bankImportCurrency: string = '';
  isSupplierChangePermission: boolean = true;
  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.selectedCurrencyRate = 1;
    this.selectedCurrencyId = 123;
    this.selectedCurrencySymbol = '£';
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();

    this.setForm();
    this.getCurrencyList();
    //this.getInvoiceType();
    this.getBranchList(true);

    this.triggerEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        //this.getInvoiceType();
        this.billForm.controls.billNo.disable();
        setTimeout(() => {
          this.editBill(data);
        }, 1500);
      });

    this.triggerTransactionLogData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.billForm.controls.currency.setValue(data.currencyId);
        this.billForm.controls.currency.disable();
        this.selectedCurrencyRate = this.clientCurrencyList.find(
          (x) => x.currencyId === data.currencyId
        )?.rate;
        if (data.currencyId === 123) {
          this.billForm.controls.currencyAmt.setValue(1);
        } else {
          this.isMultiCurrencySelected = true;
          this.billForm.controls.currencyAmt.setValue(
            this.selectedCurrencyRate
          );
        }
        if (this.isFromBankImport) {
          this.billForm.controls.bankImportAmount.setValue(data.amount);
          this.bankImportCurrency = data.symbol;
        }
      });

    this.billForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.billForm.touched;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isSupplierChangePermission = this.commonService.checkPermission(
        Modules.Contacts,
        Modules.Suppliers
      );
    }, 3000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getInvoiceType(): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.CostOfSales);
    groupNames.push(GroupNames.SellingAndDistributionCosts);
    groupNames.push(GroupNames.AdministrativeExpenses);

    this.store
      .dispatch(new GetGroupAccountsBasedOnGroupId(groupNames, Modules.Purchase))
      .pipe(
        switchMap((res) => {
          if (res.common.accountGroupList.length > 0) {
            this.accountGroupList = res.common.accountGroupList;
            this.tempAccountGroupList = this.accountGroupList;
          }

          const param: ChartOfAccountListParam = {
            groupIds: [GroupNames.Creditors],
            typeIds: [
              ChartOfAccountCreditorsGroupTypeName.CreditorsLessThanOneYearVAT,
            ],
          };

          return this.store.dispatch(
            new GetGroupAccountsBasedOnGroupIdAndTypeId(param)
          );
        })
      )
      .subscribe((secondRes) => {
        if (secondRes.common.accountGroupList.length > 0) {
          this.accountGroupList = this.accountGroupList.concat(
            secondRes.common.accountGroupList
          );
          this.tempAccountGroupList = this.accountGroupList;
        }
      });
  }

  getCurrency(): void {
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          this.billForm.controls.currency.setValue(this.defaultCurrency);
        })
      )
      .subscribe();
  }

  getSupplier(id?: Guid): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);
    let actionName;
    if (this.branchList.length > 0) {
      actionName = GetNonStandardAccountListByBranch;
    } else {
      actionName = GetNonStandardAccountList;
    }

    this.store
      .dispatch(new actionName(entityId, this.billForm.controls.branchId.value))
      .pipe(
        tap((res) => {
          this.supplierList = res.common.accountList;

          if (
            id !== null &&
            id !== undefined &&
            id !== this.commonService.defaultGuidValue
          ) {
            this.billForm.controls.supplierId.setValue(id);
            this.supplierClick.emit(id);
          }
        })
      )
      .subscribe();
  }

  gotoAddSupplier(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Suppliers,
          headerText: `Add ${ModuleName.Suppliers}`,
          saveActionName: CreateAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledEditButton = false;
          this.getSupplier(id);
        }
      });
  }

  gotoEditSupplier(): void {
    if (this.supplierList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.Suppliers,
            headerText: `Edit ${ModuleName.Suppliers}`,
            id: this.billForm.controls.supplierId.value,
            saveActionName: CreateAccount,
            getActionName: GetDataByAccountId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getSupplier(id);
          }
        });
    }
  }

  supplierChange(event): void {
    this.supplierClick.emit(event);
    if (event !== '-1') {
      this.disabledEditButton = false;
    } else {
      this.disabledEditButton = true;
    }
  }

  editBill(data): void {
    // let account;
    // this.accountGroupList.forEach((items) => {
    //   const accountId = items.listModels.find(
    //     (x: any) => x.id === data.bookAccountId
    //   );
    //   if (accountId !== null && accountId !== undefined) {
    //     account = accountId;
    //   }
    // });
    this.disabledEditButton = false;
    const x = this.supplierList.filter((x) => x.id === data.accountId);
    this.billForm.patchValue({
      billNo: data.entryNumber,
      billDate: data.entryDate,
      currency: data.currencyId,
      supplierId: x.length > 0 ? data.accountId : '',
      dueDate: data.dueDate,
      notes: data.note,
     // billType: account,
      isVatPostponed: data.isVatPostponed,
      isVatInclude: data.isVatIncluded,
      branchId: data.branchId,
      departmentId: data.departmentId,
      currencyAmt: data.currencyRate,
    });
    if (data.currencyId != 123) {
      this.triggerSelectedCurrencyRate.emit(data.currencyRate);
      this.isMultiCurrencySelected = true;
      this.selectedCurrencyRate = this.clientCurrencyList.find(
        (x) => x.currencyId === data.currencyId
      )?.rate;
      this.selectedCurrencyId = this.clientCurrencyList.find(
        (x) => x.currencyId === data.currencyId
      )?.currencyId;
      this.selectedCurrencySymbol = this.clientCurrencyList.find(
        (x) => x.currencyId === data.currencyId
      )?.symbol;
    } else {
      this.isMultiCurrencySelected = false;
      this.selectedCurrencyRate = 1;
      this.selectedCurrencySymbol = '£';
    }
    if (data.isVatIncluded) {
      this.isVatIncludedClick.emit(data.isVatIncluded);
    }
    if (data.isVatPostponed) {
      this.billForm.controls.isVatInclude.setValue(false);
      this.billForm.controls.isVatInclude.disable();
      this.isVatAmountIncluded = this.billForm.controls.isVatInclude.value;
    } else {
      this.billForm.controls.isVatInclude.setValue(data.isVatIncluded);
      this.billForm.controls.isVatInclude.enable();
      this.isVatAmountIncluded = this.billForm.controls.isVatInclude.value;
    }
    if (data.branchId !== null && data.branchId !== undefined) {
      this.getDepartmentListByBranch(data.branchId);
    }
  }

  setForm(): void {
    this.billForm = new FormGroup({
      billNo: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      supplierId: new FormControl('', [Validators.required]),
      billDate: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      currency: new FormControl('', [Validators.required]),
      dueDate: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      notes: new FormControl(''),
      billType: new FormControl(''),
      isVatPostponed: new FormControl(false),
      isVatInclude: new FormControl(false),
      branchId: new FormControl(''),
      departmentId: new FormControl(''),
      currencyAmt: new FormControl(''),
      bankImportAmount: new FormControl({ value: 0, disabled: true }),
    });
  }

  onSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    if (list.length === 0) {
      this.billForm.controls.billType.setValue('');
      this.accountGroupList = this.tempAccountGroupList;
    }
  }

  resetAccountList(): void {
    this.accountGroupList = this.tempAccountGroupList;
    this.scrollIntoView(this.billForm.controls.billType.value);
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    this.accountGroupList = list;
  }

  getOptionText(option) {
    return option.name;
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  isVatPostponedChecked(event: any): void {
    this.isVatAmountIncluded = this.billForm.controls.isVatInclude.value;
    if (event.checked) {
      this.billForm.controls.isVatInclude.setValue(false);
      this.billForm.controls.isVatInclude.disable();
      
      this.isVatIncludedClick.emit(this.isVatAmountIncluded);
    } else {
      this.billForm.controls.isVatInclude.enable();
      this.isVatIncludedClick.emit(this.isVatAmountIncluded);
    }
    this.triggerOnVATChange.emit(event.checked);
  }

  onCheckBoxSelected(event: any): void {
    this.isVatAmountIncluded = event.checked;
    this.isVatIncludedClick.emit(this.isVatAmountIncluded);
  }

  onOptionSelected(event: any) {
    const selectedValue = event.option.value; // Get the selected value from the event
    this.triggerAccountSelectedOption.emit(selectedValue);
  }
  getBranchList(isOnLoad): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.billForm.controls.branchId.setValue(this.branchList[0].id);
            this.billForm.controls.branchId.setValidators(Validators.required);
            this.billForm.controls.branchId.updateValueAndValidity();
            this.onChangeBranch(this.branchList[0].id, isOnLoad);
          } else {
            this.branchList = [];
            this.departmentList = [];
            this.getSupplier();
          }
        })
      )
      .subscribe();
  }

  onChangeBranch(branch, isOnLoad): void {
    this.getDepartmentListByBranch(branch);
    this.getSupplier();
    if (!isOnLoad) {
      this.billForm.controls.supplierId.setValue('');
      this.branchChange.emit(branch);
    }
  }

  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .pipe(
          tap((res) => {
            this.departmentList = [];
            if (res.company.department.length > 0) {
              this.departmentList = res.company.department;
              this.billForm.controls.departmentId.setValue(
                this.departmentList[0].id
              );
              this.billForm.controls.departmentId.setValidators(
                Validators.required
              );
              this.billForm.controls.departmentId.updateValueAndValidity();
            } else {
              this.billForm.controls.departmentId.clearValidators();
              this.billForm.controls.departmentId.updateValueAndValidity();
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
      this.billForm.controls.departmentId.clearValidators();
      this.billForm.controls.departmentId.updateValueAndValidity();
    }
  }
  getCurrencyList(): void {
    this.store
      .dispatch(new GetClientCurrencyList())
      .pipe(
        tap(() => {
          this.clientCurrencyList = this.store.selectSnapshot(
            CurrencyState.getCurrencyList
          );
          if (this.clientCurrencyList.length > 0) {
            this.billForm.controls.currency.setValue(
              this.clientCurrencyList[0].currencyId
            );
            this.billForm.controls.currencyAmt.setValue(1);
          }
        })
      )
      .subscribe();
  }
  currencyChange(value: any): void {
    this.selectedCurrencyRate = this.clientCurrencyList.find(
      (x) => x.currencyId === value
    )?.rate;
    this.selectedCurrencyId = this.clientCurrencyList.find(
      (x) => x.currencyId === value
    )?.currencyId;
    this.selectedCurrencySymbol = this.clientCurrencyList.find(
      (x) => x.currencyId === value
    )?.symbol;
    this.triggerCurrencySelectedOption.emit(this.selectedCurrencyRate);
    if (value === 123) {
      this.isMultiCurrencySelected = false;
      this.billForm.controls.currencyAmt.setValue(1);
    } else {
      this.billForm.controls.currencyAmt.setValue(this.selectedCurrencyRate);
      this.isMultiCurrencySelected = true;
    }
  }
  onCurrencyRefresh(): void {
    this.currencyIds = [];
    this.currencyIds.push(this.selectedCurrencyId);

    this.store
      .dispatch(new GetRefreshCurrencyData(this.currencyIds))
      .subscribe((res) => {
        this.currencyIds = [];
        this.refreshedCurrencyData = res.currency.currencyList.data;

        this.refreshedCurrencyData.forEach((x) => {
          this.refreshedCurrencyRate = x.rate;
        });
        this.billForm.controls.currencyAmt.setValue(this.refreshedCurrencyRate);
        this.selectedCurrencyRate = this.refreshedCurrencyRate;
        this.triggerCurrencyRefreshOption.emit(this.selectedCurrencyRate);
      });
  }
  onCurrencyRateEntered() {
    this.selectedCurrencyRate = this.billForm.controls.currencyAmt.value;
    this.triggerCurrencyRefreshOption.emit(this.selectedCurrencyRate);
  }
}
