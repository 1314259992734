import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MTD,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import {
  GlobalComponent,
  LiabilityModel,
  SideListModel,
  VATObligationModel,
  VATPaymentModel,
  VatRateScheme,
  VatReturnResponseModel,
} from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  GetAuthorized,
  GetCompanyVatSchemaId,
  GetVatLiabilities,
  GetVatObligations,
  GetVatPayments,
  GetVatRetunDetails,
  GetVatSchemeList,
  GetVatSubmissionPeriods,
  MenuState,
} from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-view-vat-detail',
  templateUrl: './view-vat-detail.component.html',
  styleUrls: ['./view-vat-detail.component.scss'],
})
export class ViewVatDetailComponent implements OnInit {
  selectedTab = 0;
  isAuthorized = false;
  isAuthorizedSection = false;
  obligationNoDataFound = true;
  liabilitiesNoDataFound = true;
  paymentsNoDataFound = true;
  viewVatReturnNoDataFound = true;
  vatObligations: VATObligationModel[];
  vatLiabilities: LiabilityModel[];
  vatPayments: VATPaymentModel[];
  vatReturns: VatReturnResponseModel[];
  obligationStatus = 'A';
  obligationDateTo = new Date();
  obligationDateFrom = new Date();
  liabilitiesDateTo = new Date();
  liabilitiesDateFrom = new Date();
  paymentsDateTo = new Date();
  paymentsDateFrom = new Date();
  datepipe: DatePipe;
  vatSchemaId: any;
  vatSchemeList: Array<VatRateScheme>;
  vatSubmissionPeriods: SideListModel[];
  notify: NotificationService;

  obligationDisplayedColumns: string[] = [
    'startDate',
    'endDate',
    'dueDate',
    'status',
    'receivedDate',
  ];

  liabilitiesDisplayedColumns: string[] = [
    'from',
    'to',
    'type',
    'originalAmount',
    'outstandingAmount',
    'due',
  ];

  paymentsDisplayedColumns: string[] = ['amount', 'received'];
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  viewVatReturnDisplayedColumns: string[] = [
    'boxNo',
    'vatDescription',
    'vatAmount',
  ];
  vatSubmissionPeriod: any;
  companyName: string;

  constructor(
    private injector: Injector,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public cookieService: CookieService,
    private store: Store,
    public commonService: CommonService,
    public globalComponent: GlobalComponent
  ) {
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.notify = injector.get<NotificationService>(NotificationService);
  }
  ngOnInit(): void {
    this.companyName = this.globalComponent.getDeafultCompanyName();
    this.spinner.hide();
    this.checkIsAuthorized();
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  checkIsAuthorized(): void {
    this.spinner.show();
    this.store.dispatch(new GetAuthorized()).subscribe(
      (res) => {
        this.spinner.hide();
        this.isAuthorized = res.viewVatDetails.isAuthorized;
        this.isAuthorizedSection = true;
      },
      (error) => {
        this.isAuthorized = false;
        this.isAuthorizedSection = true;
        this.spinner.hide();
      }
    );
  }

  onTabClick(event: any): void {
    switch (event) {
      case MTD.ViewVATReturn:
        this.getVatSchemeList().then(() => {
          this.getVatSchemaId();
        });
        this.getVatSubmissionPeriods();
        break;
      default:
        break;
    }
    this.selectedTab = event;
  }

  redirectOnUnAuthorized(): void {
    localStorage.setItem('ignoreAccountModal', 'true');
    this.commonService.prepareUrl().subscribe((res: any) => {
      window.open(res, '_self');
    });
  }

  getVatObligations(): void {
    this.store
      .dispatch(new GetVatObligations(this.getObligationParameter()))
      .pipe()
      .subscribe((res) => {
        this.vatObligations = res.viewVatDetails.vatObligations;
        this.obligationNoDataFound =
          this.vatObligations.length > 0 ? false : true;
      });
  }

  getObligationParameter(format?: number): any {
    const queryParams = {
      fromDate: this.datepipe
        .transform(this.obligationDateFrom, 'yyyy-MM-dd')
        ?.toString(),
      toDate: this.datepipe
        .transform(this.obligationDateTo, 'yyyy-MM-dd')
        ?.toString(),
      status: this.obligationStatus,
    };
    return queryParams;
  }

  getVatLiabilities(): void {
    this.store
      .dispatch(new GetVatLiabilities(this.getLiabilitiesParameter()))
      .pipe()
      .subscribe((res) => {
        this.vatLiabilities = res.viewVatDetails.vatLiabilities;
        this.liabilitiesNoDataFound =
          this.vatLiabilities.length > 0 ? false : true;
      });
  }

  getLiabilitiesParameter(format?: number): any {
    const queryParams = {
      fromDate: this.datepipe
        .transform(this.liabilitiesDateFrom, 'yyyy-MM-dd')
        ?.toString(),
      toDate: this.datepipe
        .transform(this.liabilitiesDateTo, 'yyyy-MM-dd')
        ?.toString(),
    };
    return queryParams;
  }

  getVatPayments(): void {
    this.store
      .dispatch(new GetVatPayments(this.getPaymentsParameter()))
      .pipe()
      .subscribe((res) => {
        this.vatPayments = res.viewVatDetails.vatPayments;
        this.paymentsNoDataFound = this.vatPayments.length > 0 ? false : true;
      });
  }

  getPaymentsParameter(format?: number): any {
    const queryParams = {
      fromDate: this.datepipe
        .transform(this.paymentsDateFrom, 'yyyy-MM-dd')
        ?.toString(),
      toDate: this.datepipe
        .transform(this.paymentsDateTo, 'yyyy-MM-dd')
        ?.toString(),
    };
    return queryParams;
  }

  async getVatSchemeList(): Promise<void> {
    await this.store
      .dispatch(new GetVatSchemeList())
      .toPromise()
      .then((res) => {
        this.vatSchemeList = res.company.vatSchemeList;
      });
  }

  getVatSubmissionPeriods(): void {
    this.store
      .dispatch(new GetVatSubmissionPeriods())
      .pipe(
        tap((res) => {
          this.vatSubmissionPeriods = res.viewVatDetails.vatSubmissionPeriods;
        })
      )
      .subscribe();
  }

  async getVatSchemaId(): Promise<void> {
    await this.store
      .dispatch(new GetCompanyVatSchemaId())
      .toPromise()
      .then((res) => {
        this.vatSchemaId = res.company.companyVatSchemaId;
        this.vatSchemaId = +this.vatSchemaId;
      });
  }

  getVatReturn(): void {
    if (
      this.vatSubmissionPeriod === undefined ||
      this.vatSubmissionPeriod === null ||
      this.vatSubmissionPeriod === ''
    ) {
      this.notify.error(NotificationHeader.error, 'Please select Period');
      return;
    }
    this.store
      .dispatch(new GetVatRetunDetails(this.vatSubmissionPeriod))
      .pipe()
      .subscribe((res) => {
        this.vatReturns = res.viewVatDetails.vatReturnDetailsData;
        this.viewVatReturnNoDataFound =
          this.vatReturns.length > 0 ? false : true;
      });
  }
}
