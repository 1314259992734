import {
  Component,
  Injector,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AccountProvider, Modules, ProjectName, RoutingPath } from '@app/core/Enum';
import { EntityTypes } from '@app/core/Enum/entity-types';
import {
  ActivityTask,
  ActivityTaskParameters,
  Company,
  GlobalComponent,
  MainListParameters,
  MenuModel,
  ProfileInfoModel,
} from '@app/core/Models';
import {
  CommonService,
  DashboardService,
  HighlightRow,
} from '@app/core/Services';
import {
  CompanyState,
  GetActivityList,
  GetAddMenu,
  GetAvailableCompanyCount,
  GetCompanyList,
  GetDefaultCurrency,
  GetFinancialPeriod,
  GetIsVatRegistered,
  GetMenu,
  GetProfileInfo,
  GetReportMenu,
  GetUserMailList,
  MenuState,
  RetrieveSubscription,
  SetAsDefaultCompany,
  UpdateThemeColor,
} from '@app/core/Store';
import { environment } from '@environments/environment';
import { Select, Store } from '@ngxs/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as theme from 'src/assets/json/themes.json';

declare const tinycolor: any;
export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userData: any;
  userName?: string;
  userPhoto = 'assets/images/favicon.png';
  moduleName: string;
  userEmail?: string;
  companyName: string;
  appUrl = '';
  subscriptionModuleList: any = [];

  isSearchboxShow = false;

  moduleId: number;
  userId?: Guid;
  headerForm: FormGroup;
  companyList: Array<Company>;
  addMenuList: Array<MenuModel>;
  addReportList: Array<MenuModel>;
  menuData: Array<MenuModel>;
  tempMenuData: Array<MenuModel>;
  profileInfo: ProfileInfoModel;
  myAdminUrl: string;

  // Multicolor theme
  colorSelected: any;
  singletheme: any;
  themes = JSON.parse(JSON.stringify(theme));
  colors: string[] = [
    '#6d41a1',
    '#6661b8',
    '#4461d7',
    '#a0144f',
    '#045b62',
    '#14539a',
    '#a76f05',
    '#000000',
  ];

  primaryColor = this.globalComponent.themecolor();
  primaryColorPalette: Color[] = [];
  commonService: CommonService;
  helpDeskUrl = environment.helpDeskUrl;
  subscriptionPortalAppUrl = '';
  isSubscriptionPortalEnabled: boolean = false;
  profileUrl = '';
  isVatRegistered: any;
  activityList: any = [];
  activityTaskParameters: ActivityTaskParameters;
  activities: Array<ActivityTask>;
  message = '';
  entityTypes = EntityTypes;
  hasAttachments = false;
  attachment: any[];

  @Input()
  triggerDataFromComponent: Observable<any>;

  @Select(MenuState.hasAddPermission)
  hasAddPermission$: Observable<boolean>;

  @Select(MenuState.moduleName)
  moduleName$: Observable<string>;

  @Select(MenuState.menu)
  menuList$: Observable<any>;

  @Select(CompanyState.companyCount)
  companyCount$: Observable<number>;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(MatSort) sort: MatSort;

  listParameters: MainListParameters = new MainListParameters();

  displayedColumns: string[] = [
    //'default',
    'companyname',
    'companyTypeName',
    'vatnumber',
  ];
  defaultCurrency: any;
  environment = environment;
  searchText = '';
  projectName = ProjectName;
  tenantName = '';
  router: Router;
  cookieService: CookieService;
  store: Store;
  spinner: NgxSpinnerService;
  subscriptionRouting: Subscription;
  showClientList = true;
  showTopList = true;
  isGeneralDashboard = false;
  selectedClientId: any;
  _Activatedroute: ActivatedRoute;
  constructor(
    public oidcSecurityService: OidcSecurityService,
    private globalComponent: GlobalComponent,
    private activatedroute: ActivatedRoute,
    public highlightRow: HighlightRow,
    private injector: Injector,
    private renderer: Renderer2,
    private dashboardService: DashboardService
  ) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.router = injector.get<Router>(Router);
    this.commonService = this.injector.get<CommonService>(CommonService);
    this.cookieService = injector.get<CookieService>(CookieService);
    this.store = injector.get<Store>(Store);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    let currentUrl = this.router.url;
    if (currentUrl === '/general-dashboard' || currentUrl === '/activity') {
      this.isGeneralDashboard = true;
      this.commonService.toggleMenu = false;
      this.showClientList = false;
      this.showTopList = true;
    } else if (currentUrl.includes('/general-manage')) {
      this.isGeneralDashboard = true;
      this.commonService.toggleMenu = true;
      this.showClientList = false;
      this.showTopList = true;
    } else {
      this.isGeneralDashboard = false;
      this.getCompanyList();
      this.commonService.toggleMenu = true;
      this.showClientList = true;
      this.showTopList = false;
    }
    //To reload companyList when new company is added
    this.router.events.subscribe((val) => {
      let currentUrl = this.router.url;
      if (currentUrl === '/general-dashboard' || currentUrl === '/activity') {
        this.isGeneralDashboard = true;
        this.commonService.toggleMenu = false;
        this.showClientList = false;
        this.showTopList = true;
      } else if (currentUrl.includes('/general-manage')) {
        this.isGeneralDashboard = true;
        this.commonService.toggleMenu = true;
        this.showClientList = false;
        this.showTopList = true;
      } else {
        this.isGeneralDashboard = false;
        this.commonService.toggleMenu = true;
        this.showClientList = true;
        this.showTopList = false;
      }
      if (val instanceof NavigationEnd) {
        let r = this.activatedroute;
        while (r.firstChild) {
          r = r.firstChild;
          this.getCompanyList();
        }

        r.params.subscribe((params) => {
          if (Boolean(params.loadCompany)) {
            this.getCompanyList();
          }
        });
      }
    });

    this.userData = JSON.parse(
      sessionStorage.getItem(`${environment.clientId}_userData`) as any
    );

    if (this.userData.picture !== undefined && this.userData.picture !== null) {
      this.userData.picture = `${environment.cdnUrl}` + this.userData.picture;
    }

    this.onSelectionChange(this.globalComponent.themecolor(), false);
  }

  checkManageAccess(): void {}

  getParamaters(): any {
    const queryParams = {
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      pageNumber: 1,
      pageSize: 0,
    };

    return queryParams;
  }

  ngOnInit(): void {
    this.headerForm = new FormGroup({
      searchText: new FormControl(''),
      colorSelected: new FormControl(this.primaryColor),
    });

    this.getCompanyList();
    this.getUserProfileInfo();
    this.retrieveSubscription();
    this.getQuickReportsMenu();

    this.store
      .dispatch(new GetAvailableCompanyCount())
      .subscribe((companyCount) => {
        if (companyCount.company.companyCount > 0) {
          this.getIsVatRegistered();
        } else {
          this.router.navigate([RoutingPath.Clients]);
        }
      });

    this.userId = this.globalComponent.getLoggedInUserId();

    this.triggerDataFromComponent?.subscribe((data) => {
      this.moduleName = data.moduleName;
      this.moduleId = data.moduleId;
    });

    setTimeout(() => {
      this.bindMenu();
    }, 1000);

    this.store
      .dispatch(new GetAddMenu())
      .pipe(
        tap((res) => {
          this.addMenuList = res.Menu.addMenu;
        })
      )
      .subscribe();
    this.selectedClientId = this.cookieService.get('companyid');
  }

  getQuickReportsMenu(): void {
    this.store.dispatch(new GetReportMenu()).subscribe((res) => {
      this.addReportList = res.Menu.reportMenu;
    });
  }

  bindMenu(): void {
    this.store.dispatch(new GetMenu()).subscribe((data) => {
      this.menuData = [];
      data.Menu.Menu.forEach((x) => {
        if (x.url !== undefined && x.url !== null) {
          this.menuData.push(x);
        } else {
          x.subMenu.map((y) => {
            if (y.url !== undefined && y.url !== null) {
              this.menuData.push(y);
            } else {
              y.subMenu.map((z) => {
                if (z.url !== undefined && z.url !== null) {
                  this.menuData.push(z);
                } else {
                  z.subMenu.map((z1) => {
                    if (z1.url !== undefined && z1.url !== null) {
                      this.menuData.push(z1);
                    }
                  });
                }
              });
            }
          });
        }
      });
      this.tempMenuData = this.menuData;
    });
  }

  onSearchName(event: any): void {
    const selectedArray: MenuModel[] = [];

    this.menuData.forEach((item) => {
      if (
        item.name
          .toLowerCase()
          .includes(event.currentTarget.value.toLowerCase())
      ) {
        selectedArray.push(item);
      }
    });

    if (selectedArray.length === 0) {
      this.headerForm.controls.searchText.setValue('');
      this.menuData = this.tempMenuData;
    }
  }

  onSearchModule(event: any): void {
    this.menuData = this.tempMenuData;

    if (
      event.currentTarget.value.length === 0 &&
      event.currentTarget.value === ''
    ) {
      this.menuData = this.tempMenuData;
    }
    const selectedArray: MenuModel[] = [];

    this.menuData.forEach((item) => {
      if (
        item.name
          .toLowerCase()
          .includes(event.currentTarget.value.toLowerCase())
      ) {
        selectedArray.push(item);
      }
    });

    if (event.currentTarget.value) {
      this.menuData = selectedArray;
    }
  }

  onSearchModuleSelection(event: any): void {
    this.headerForm.reset();
    this.bindMenu();
    this.router.navigate([event.option.value]);
  }

  goToSettings(): void {
    this.router.navigate([RoutingPath.EditCompany]);
  }

  goToProfile(): void {
    window.location.href = environment.stsServerUrl + '/Manage';
  }

  signOutUser(): void {
    this.cookieService.deleteAll();
    this.oidcSecurityService.logoff();
  }

  isActiveWidget(val: Array<string>): boolean {
    return val.includes(this.router.url);
  }

  onSearch(): void {
    this.listParameters.search = this.searchText;
    this.getCompanyList();
  }

  onSearchAll(): void {
    if (this.searchText === '') {
      this.onSearch();
    }
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.getCompanyList();
  }

  getCompanyList(): void {
    this.store
      .dispatch(new GetCompanyList(this.getParamaters()))
      .pipe(
        tap((res) => {
          this.companyList = res.company.company;

          if (this.companyList.length) {
            if (!this.cookieService.get('companyid')) {
              this.companyName =
                this.companyList.find((item) => item.isDefault === true)
                  ?.companyName ?? this.companyName;

              this.setCompanyName(this.companyName);
            } else {
              this.companyName = this.cookieService.get('defaultCompanyName');
            }
          }

          if (!this.cookieService.get('companyid')) {
            this.setCompanyId(
              this.companyList.find((item) => item.isDefault === true)?.id
            );
          } else {
            this.getDefaultCurrency();
          }
          if (this.cookieService.get('companyid')) {
            this.getDefaultCurrency();
            this.getFinancialPeriod();
          }
        })
      )
      .subscribe();
  }

  setCompanyId(companyId): void {
    if (
      companyId !== undefined &&
      companyId !== null &&
      companyId !== Guid.EMPTY
    ) {
      this.cookieService.set('companyid', companyId);
    }
  }

  onChangeCompany(company): void {
    this.trigger.closeMenu();
    this.companyName = company.companyName;
    this.setCompanyName(this.companyName);
    this.store.dispatch(new SetAsDefaultCompany(company.id)).subscribe((x) => {
      this.setCompanyId(company.id);
      this.ngOnInit();
      localStorage.setItem('defaultFinancialPeriod', '-1');
      this.dashboardService.setDashboardReload(true);
      this.spinner.show();
      this.getAllMail(5);
    });

    this.router.navigate([RoutingPath.Dashboard]);
  }

  showMailOption: any;
  capiumPayList: any;
  getAllMail(providerId): void {
    this.store
      .dispatch(new GetUserMailList(providerId))
      .subscribe((res: any) => {
        if (providerId === AccountProvider.CapiumPay) {
          this.capiumPayList = res.integration.integrationData;
          if (this.capiumPayList === null || this.capiumPayList.length === 0) {
            this.showMailOption = 'false';
          } else {
            this.showMailOption = 'true';
          }
          this.cookieService.set('showPaymentMailOption', this.showMailOption);
        }
      });
  }

  onEditClick(companyId: any): void {
    this.router.navigate([RoutingPath.AddClient, { id: btoa(companyId) }]);
    this.trigger.closeMenu();
  }

  onNewCompanyAdded(): void {
    this.router.navigate([RoutingPath.AddClient]);
  }

  onCancel(): void {
    this.isSearchboxShow = false;
  }

  onSearchClick(): void {
    this.isSearchboxShow = true;
  }

  onCloseClick(): void {
    this.isSearchboxShow = false;
  }

  getUserProfileInfo(): void {
    this.store
      .dispatch(new GetProfileInfo())
      .pipe(
        tap((res) => {
          this.userId = JSON.parse(
            sessionStorage.getItem(`${environment.clientId}_userData`) as any
          ).CustomerId;
          this.userName = res.Menu.profileInfo.fullName;
          this.userEmail = res.Menu.profileInfo.email;
          this.userPhoto = res.Menu.profileInfo.photo;
        })
      )
      .subscribe();
  }

  getDefaultCurrency(): void {
    this.store
      .dispatch(new GetDefaultCurrency())
      .pipe(
        tap((res) => {
          this.defaultCurrency = res.common.defaultCurrency;
          this.cookieService.set(
            'defaultCurrency',
            JSON.stringify(this.defaultCurrency)
          );
        })
      )
      .subscribe();
  }

  getFinancialPeriod(): void {
    this.highlightRow.financialData = [];
    this.store
      .dispatch(new GetFinancialPeriod())
      .pipe(
        tap((res) => {
          this.highlightRow.financialData = res.common.periodicDate;
          this.commonService.setLocalStorage(
            'financialPeriod',
            JSON.stringify(res.common.periodicDate)
          );
        })
      )
      .subscribe();
  }

  scrollIntoView() {
    this.commonService.autoScrollMatAutoComplete(this.renderer);
  }

  onSelectionChange(event: any, isThemeColor: boolean) {
    this.commonService.clearThemeMessages();
    this.colorSelected = event;
    this.primaryColor = event;

    this.computeColors(this.primaryColor);

    if (isThemeColor) {
      this.store
        .dispatch(new UpdateThemeColor(this.primaryColor))
        .subscribe(() => {
          this.cookieService.set('themecolor', this.primaryColor);
        });
      this.headerForm.controls.colorSelected.setValue(this.primaryColor);
      this.highlightRow.defaultColor.next(this.primaryColor);
    }
  }

  computeColors(hex: string): void {
    for (let index = 0; index < Object.keys(this.themes).length - 2; index++) {
      if (this.themes[index].id === hex) {
        const selectedThemeArray = this.themes[index];

        this.primaryColorPalette = [
          this.getColorObject(selectedThemeArray?.id, '500'),
          this.getColorObject(selectedThemeArray?.color50, '50'),
          this.getColorObject(selectedThemeArray?.color100, '100'),
          this.getColorObject(selectedThemeArray?.color200, '200'),
          this.getColorObject(selectedThemeArray?.color300, '300'),
        ];
      }
    }

    for (const color of this.primaryColorPalette) {
      const key = `--theme-primary-${color.name}`;
      const value = color.hex;
      document.documentElement.style.setProperty(key, value);
    }
  }

  getColorObject(value, name): Color {
    const c = tinycolor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight(),
    };
  }
  retrieveSubscription(): void {
    this.store.dispatch(new RetrieveSubscription()).subscribe(
      (res) => {
        this.subscriptionModuleList =
          res.subscription.retrieveSubscriptionList.listOfAddons ?? [];
        this.profileUrl = res.subscription.retrieveSubscriptionList.picture;
        this.isSubscriptionPortalEnabled =
          res.subscription.retrieveSubscriptionList.isSubscriptionPortalEnabled;
        this.subscriptionPortalAppUrl = this.isSubscriptionPortalEnabled
          ? res.subscription.retrieveSubscriptionList.subscriptionPortalAppUrl
          : '';
        this.cookieService.set(
          'isTrailUser',
          res.subscription.retrieveSubscriptionList.isTrial
        );
        this.subscriptionModuleList.forEach((element) => {
          if (element.externalName === 'My Admin') {
            this.myAdminUrl = element.appUrl;
          }
        });
      },
      (error) => {
        this.subscriptionModuleList = [];
        this.profileUrl = '';
      }
    );
  }

  redirectToModule(subscriptionModule, event: Event): void {
    if (subscriptionModule.isDisabled) {
      this.appUrl = '';
      event.preventDefault();
    } else {
      this.appUrl = subscriptionModule.appUrl;
    }
  }

  redirectToHome(): void {
    window.location.replace(environment.stsServerUrl);
  }

  myAdminClick() {
    window.location.replace(this.myAdminUrl);
  }
  onHelpClick(): void {
    window.open(this.helpDeskUrl, '_blank');
  }

  produdctSubscriptionClick(): void {
    if (this.isSubscriptionPortalEnabled)
      window.location.replace(this.subscriptionPortalAppUrl);
  }
  onClick(value) {
    if (value === '/general-dashboard') {
      this.isGeneralDashboard = true;
      this.commonService.toggleMenu = false;
      this.showClientList = false;
      this.showTopList = true;
    } else if (value.includes('/general-manage')) {
      this.isGeneralDashboard = true;
      this.commonService.toggleMenu = true;
      this.showClientList = false;
      this.showTopList = true;
    }
  }
  onMoveToGeneralDashboard(): void {
    this.router.navigate([RoutingPath.ToggleGeneralDashboard]);
  }
  GetClass(): string {
    let currentUrl = this.router.url;
    if (currentUrl.includes('/general-manage')) {
      return 'active';
    }
    return '';
  }

  getIsVatRegistered(): void {
    this.store
      .dispatch(new GetIsVatRegistered())
      .pipe(
        tap((res) => {
          this.isVatRegistered = res.common.isVatRegistered;
          this.cookieService.set(
            'isVatRegistered',
            JSON.stringify(res.common.isVatRegistered)
          );
        })
      )
      .subscribe();
  }

  setCompanyName(companyName: any): void {
    this.cookieService.set('defaultCompanyName', companyName);
  }

  onMoveToActivityPage(): void {
    this.router.navigate([RoutingPath.Activity]);
  }

  getRecentActivity(): void {
    this.activityList = [];

    this.activityTaskParameters = {
      moduleId: 0,
      pageNumber: 1,
      pageSize: 5,
      search: '',
      entityId: Guid.EMPTY as unknown as Guid,
      startDate: '',
      endDate: '',
      dateFilter: 1,
    };

    this.store
      .dispatch(new GetActivityList(this.activityTaskParameters))
      .pipe(
        tap((res) => {
          this.createMessage(res.activity.activities[0].activityModels);
        })
      )
      .subscribe();
  }

  createMessage(val: any): void {
    this.activityList = [];
    this.activities = val;
    if (this.activities) {
      this.activities.forEach((items) => {
        this.message = ' ';
        const moduleName = this.getEmunKeyByValue(items.moduleId, Modules);

        this.message +=
          this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
          ' ' +
          moduleName +
          ' ';
          
        items.entities.forEach((entity) => {
          this.attachment = entity.attachments;
          this.hasAttachments = entity.hasAttachment ?? false;
        });

        const obj = {
          id: items.entities[0].id,
          createdByName: items.createdByName,
          name: items.entities[0].name,
          message: this.message,
          entityTypeId: items.entityTypeId,
          activityDate: items.activityDate,
          hasAttachment: this.hasAttachments,
          attachments: this.attachment,
        };
        this.activityList.push(obj);
      });
    }
  }
  
  getEmunKeyByValue(value: number, enumName: any): string {
    const indexOfS = Object.values(enumName).indexOf(
      value as unknown as InstanceType<typeof enumName>
    );
    let key = Object.keys(enumName)[indexOfS];
    key = key.replace(/([A-Z])/g, ' $1').trim();

    return key;
  }
}
