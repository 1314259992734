import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { GroupNames, MaxLength, Modules } from '@app/core/Enum';
import { AccountNumber } from '@app/core/Enum/account-number';
import { VatName } from '@app/core/Enum/vat-name';
import { VatRateCodeType } from '@app/core/Enum/vat-rate-code-type';
import { GlobalComponent, GroupListModel, VatRateScheme } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { CommonState, GetGroupAccountsBasedOnGroupId, GetProductData, GetVatRateList } from '@app/core/Store';
import { CleanAllLinesComponent } from '@app/modules';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-cis-invoice-details',
  templateUrl: './cis-invoice-details.component.html',
  styleUrls: ['./cis-invoice-details.component.scss'],
})
export class CISInvoiceDetailsComponent implements OnInit {
  formCISInvoiceDetail: UntypedFormGroup;
  maxLength = MaxLength;
  cisInvoiceDetailArray: any;
  linkText: string;

  primaryContact = {
    id: 0,
  };

  itemList = [
    {
      id: '1',
      name: 'Labour',
    },
    {
      id: '2',
      name: 'Material',
    },
  ];

  @Input() triggereEditProductData: Observable<any>;
  tableDataSource: MatTableDataSource<AbstractControl>;

  @ViewChild('auto') matAutocompleteRef: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger: MatAutocompleteTrigger;
  @Input() triggerCurrencySelectedOption: Observable<any>;
  @Input() triggerCurrencyRefreshOption: Observable<any>;
  @Input() triggerSelectedCurrencyRate: Observable<any>;
  @Input() triggerisVatIncluded: Observable<any>;
  @Input() triggerOnVATChange: Observable<any>;
  @Input() triggerAccountSelectedOption: Observable<any>;

  @Output() triggerNewProductAdded: EventEmitter<number> =
    new EventEmitter<number>();

  displayCISInvoiceDetailsColumns: string[] = [
    'srNo',
    'description',
    'item',
    'price',
    'invoiceType',
    'vatrate',
    'vatammount',
    'netAmount',
    'amount',
    'closeButton',
  ];
  //vatRateList: VatRateScheme[];
  vatRateList: any[]=[];
  totalAmount = 0;
  vatRate: number;
  previousSelectedCurrencyRate: number;
  isPreviousRate = false;
  selectedCurrencyRate = 1;
  customVatAmount: number = 0;
  isVatIncuded: boolean = false;
  isVATChecked = false;
  accountName: string;
  //originalVatRateList: any[];
  originalVatRateList: any[]=[];
  isVatRegistered: any;
  accountGroupList: GroupListModel[];
  tempAccountGroupList: GroupListModel[];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private renderer: Renderer2,
    private commonService: CommonService,
    public dialog: MatDialog,
    public globalComponent: GlobalComponent
  ) {}

  ngOnInit(): void {
    this.getInvoiceType();
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    if (!this.isVatRegistered) {
      this.displayCISInvoiceDetailsColumns =
        this.displayCISInvoiceDetailsColumns.filter(
          (column) =>
            column !== 'vatrate' &&
            column !== 'vatammount' &&
            column !== 'amount'
        );
    }
    this.setCISInvoiceDetailsForm(true);
    this.totalAmount = 0;

    this.triggereEditProductData?.subscribe((data) => {
      if (data.isVATSelected !== undefined && data.isVATSelected !== null) {
        this.isVATChecked = data.isVATSelected;
      }
      this.loadDropdownValues(false);

      setTimeout(() => {
        this.editProduct(data);
      }, 1000);
    });

    this.triggerSelectedCurrencyRate?.subscribe((data) => {
      if (data) {
        this.previousSelectedCurrencyRate = data;
      }
    });
    this.triggerCurrencySelectedOption?.subscribe((data) => {
      if (data) {
        this.selectedCurrencyRate = data;
        this.cisInvoiceDetailArray.controls.forEach((x, index) => {
          this.onCurrencyChanges(index);
        });
        this.previousSelectedCurrencyRate = this.selectedCurrencyRate;
      }
    });
    this.triggerCurrencyRefreshOption?.subscribe((data) => {
      if (data) {
        this.selectedCurrencyRate = data;
        this.cisInvoiceDetailArray.controls.forEach((x, index) => {
          this.onCurrencyChanges(index);
        });
        this.previousSelectedCurrencyRate = this.selectedCurrencyRate;
      }
    });
    this.triggerisVatIncluded?.subscribe((data) => {
    
      this.isVatIncuded = data;
      this.UpdateVatProduct();
    });
    this.triggerOnVATChange?.subscribe((data) => {
    
      this.isVATChecked = data;
      if (data) {
        this.filterVatRatesBasedOnCISVAT();
        setTimeout(() => {
          this.cisInvoiceDetailArray.controls.forEach((x, index) => {
            (Object as any).values(x.controls).forEach((c) => {
             // console.log(this.vatRateList[index][0]['id'],'this.vatRateList[index][0]')
              x.controls.vatRate.setValue(this.vatRateList[index][0]['id']);
              //x.controls.vatAmount.setValue(0);
            });
            this.qtyChanges(index);
          });
        }, 500);

        
      } else {
        // this.vatRateList = this.originalVatRateList;
        // if (
        //   Object.values(AccountNumber).some(
        //     (constant) => constant === this.accountName
        //   )
        // ) {
        //   this.filterVatRatesBasedOnAccount(0);
        // }

       
        this.cisInvoiceDetailArray.controls.forEach((x, index) => {
          (Object as any).values(x.controls).forEach((c) => {
            this.onOptionSelected(index)
          });
        });
      }
    });
    // this.triggerAccountSelectedOption?.subscribe((data) => {
    //   this.accountName = data.name;
    //   this.vatRateList = this.originalVatRateList;
    //   if (
    //     Object.values(AccountNumber).some(
    //       (constant) => constant === this.accountName
    //     )
    //   ) {
    //     this.cisInvoiceDetailArray.controls.forEach((x, index) => {
    //       (Object as any).values(x.controls).forEach((c) => {
    //         x.controls.vatRate.setValue(null);
    //         x.controls.vatAmount.setValue(0);
    //       });
    //       this.qtyChanges(index);
    //     });
    //   }
    //   if (!this.isVATChecked) {
    //     this.filterVatRatesBasedOnAccount();
    //   } else {
    //     this.filterVatRatesBasedOnCISVAT();
    //     this.filterVatRatesBasedOnAccount();
    //   }
    // });
  }

  amountChange(): void {
    const formArray = this.formCISInvoiceDetail.get(
      'cisInvoiceDetailArray'
    ) as UntypedFormArray;
    this.totalAmount = 0;
    formArray?.getRawValue().forEach((x) => {
      this.totalAmount = this.totalAmount + x.amount;
    });
    this.triggerNewProductAdded.emit(this.totalAmount);
  }

  loadDropdownValues(isAddMode: boolean): void {
    const vatRateList = this.store.dispatch(new GetVatRateList());
    forkJoin([vatRateList]).subscribe((res) => {
      

      this.vatRateList.push(this.store.selectSnapshot(CommonState.getVatRate));
      this.originalVatRateList=this.store.selectSnapshot(CommonState.getVatRate);
      if (this.isVATChecked) {
        this.vatRateList[0] = this.originalVatRateList.filter(
          (rate) =>
            rate.name.includes('Standard Rated(20.00%)') ||
            rate.name.includes('Reduced Rated(5.00%)')
        );
      } else {
        this.vatRateList[0] = this.originalVatRateList;
      }
      this.filterVatRatesBasedOnAccount(0);
    });
  }

  resetForm(): void {
    const formArray = this.formCISInvoiceDetail.get(
      'cisInvoiceDetailArray'
    ) as UntypedFormArray;
    formArray.clear();
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formCISInvoiceDetail.get(
            'cisInvoiceDetailArray'
          ) as UntypedFormArray;
          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
            formArray.controls[i]
              .get('id')
              ?.setValue(Guid.EMPTY as unknown as Guid);
            formArray.controls[i].get('price')?.setValue(0);
            formArray.controls[i].get('account')?.setValue(0);
            formArray.controls[i].get('item')?.setValue(null);
            formArray.controls[i].get('vatRate')?.setValue(null);
            formArray.controls[i].get('vatAmount')?.setValue(0);
            formArray.controls[i].get('amount')?.setValue(0);
          }
          this.totalAmount = 0;
        }
      });
  }

  setCISInvoiceDetailsForm(addNewRow: boolean): void {
    this.formCISInvoiceDetail = new FormGroup({
      cisInvoiceDetailArray: new UntypedFormArray([]),
    });

    this.cisInvoiceDetailArray = this.formCISInvoiceDetail.get(
      'cisInvoiceDetailArray'
    ) as UntypedFormArray;

    this.setDataSource(this.cisInvoiceDetailArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.cisInvoiceDetailArray = this.formCISInvoiceDetail.get(
      'cisInvoiceDetailArray'
    ) as UntypedFormArray;

    this.cisInvoiceDetailArray.push(this.setForm());

    if (this.cisInvoiceDetailArray.length > 0) {
      this.loadDropdownValues(true);
      this.setDisable(this.cisInvoiceDetailArray.length - 1);
    }

    this.setDataSource(this.cisInvoiceDetailArray);
    this.triggerNewProductAdded.emit(this.totalAmount);
  }

  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      description: new FormControl<string | null>(''),
      item: new FormControl<number | null>(null, [Validators.required]),
      price: new FormControl<number | null>(0, Validators.required),
      invoiceType: new FormControl('', [Validators.required]),
      vatRate: new FormControl<number | null>(null),
      vatAmount: new FormControl<number | null>(0),
      amount: new FormControl<number | null>(0, Validators.required),
      netAmount: new FormControl<number | null>(0),
    });
  }

  onDeleteProductDetails(index: number): void {
    this.cisInvoiceDetailArray = this.formCISInvoiceDetail.get(
      'cisInvoiceDetailArray'
    ) as UntypedFormArray;
    if (this.cisInvoiceDetailArray.length === 1) {
      return;
    }
    this.cisInvoiceDetailArray.removeAt(index);
    this.setDataSource(this.cisInvoiceDetailArray);
    this.amountChange();
  }

  editProduct(data: any): void {
    this.cisInvoiceDetailArray = this.formCISInvoiceDetail.get(
      'cisInvoiceDetailArray'
    ) as UntypedFormArray;

    this.cisInvoiceDetailArray.clear();

    data.forEach((item, i) => {
      this.cisInvoiceDetailArray.push(this.buildOrderItemsForm(item,i));
      this.setFormValidity(true, i);
    });

    this.setDataSource(this.cisInvoiceDetailArray);
    this.amountChange();
  }

  buildOrderItemsForm(item: any,i:number): FormGroup {
    let account;
    this.accountGroupList.forEach((items) => {
      const accountId = items.listModels.find(
        (x: any) => x.id === item.bookAccountId
      );
      if (accountId !== null && accountId !== undefined) {
        account = accountId;
      }

      
    });

    const form = this.formBuilder.group({
      id: item.id,
      description: item.description,
      item:
        item.cisSupplyTypeId === null ? null : item.cisSupplyTypeId.toString(),
      price: item.foreignAmount,
      vatRate: item.vatRateId === null ? null : item.vatRateId,
      vatAmount: item.foreignVatAmount,
      amount: item.foreignTotalAmount,
      netAmount: item.foreignAmount,
      invoiceType: account,
    });
    setTimeout(() => {
      this.onOptionSelectedByEditRecord(i,item.vatRateId);
    }, 500);
    return form;

   
  }

  displayFn(product: any): string {
    return product && product.name ? product.name : '';
  }

  qtyChanges(index: any): void {
    const price = this.cisInvoiceDetailArray.controls[index].get('price').value;

    const totalNetAmount = price;
    this.cisInvoiceDetailArray.controls[index]
      .get('netAmount')
      ?.setValue(totalNetAmount);

    if (
      this.cisInvoiceDetailArray.controls[index].get('vatRate').value !==
        undefined &&
      this.cisInvoiceDetailArray.controls[index].get('vatRate').value != null &&
      this.cisInvoiceDetailArray.controls[index].get('vatRate').value !== -1
    ) {
      const data = this.vatRateList[index].filter(
        (x) =>
          x.id ===
          this.cisInvoiceDetailArray.controls[index].get('vatRate').value
      );

      if (data[0].codeType === VatRateCodeType.Custom) {
        this.cisInvoiceDetailArray.controls[index].get('vatAmount').enable();
        this.customVatAmount =
          this.cisInvoiceDetailArray.controls[index].get('vatAmount').value;
      } else {
        this.cisInvoiceDetailArray.controls[index].get('vatAmount').disable();
      }

      if (data.length > 0) {
        this.vatRate = data[0].rate;
      }

      let vatAmount;
      let amount;
      let vatRate;
      let totalNetAmount;

      if (this.isVatIncuded) {
        vatRate = this.vatRate === undefined ? 1 : this.vatRate;
        vatAmount = this.isVATChecked
          ? 0
          : !(data[0].codeType === VatRateCodeType.Custom)
          ? (((+price) * (+vatRate)) / (100 + (+vatRate))).toFixed(2)
          : this.customVatAmount;
        amount = (+price)?.toFixed(2);
        totalNetAmount = (+price - vatAmount).toFixed(2);
      } else {
        vatRate = this.vatRate === undefined ? 1 : this.vatRate;
        vatAmount = this.isVATChecked
          ? 0
          : !(data[0].codeType === VatRateCodeType.Custom)
          ? ((+price) * (+vatRate)) / 100
          : this.customVatAmount;
        amount = (+price + vatAmount)?.toFixed(2);;
        totalNetAmount = +price;
      }

      this.cisInvoiceDetailArray.controls[index]
        .get('netAmount')
        ?.setValue(totalNetAmount);

      this.cisInvoiceDetailArray.controls[index]
        .get('vatAmount')
        ?.setValue(Number(vatAmount).toFixed(2));

      this.cisInvoiceDetailArray.controls[index]
        .get('amount')
        ?.setValue(amount);
      this.amountChange();
    } else {
      const amount = (price * 1).toFixed(2);;

      this.cisInvoiceDetailArray.controls[index].get('vatAmount')?.setValue(0);
      this.cisInvoiceDetailArray.controls[index]
        .get('amount')
        ?.setValue(amount);
      this.cisInvoiceDetailArray.controls[index]
        .get('netAmount')
        ?.setValue(amount);
      this.amountChange();
    }
  }

  setFormValidity(isSelect: boolean, index: number): void {
    if (!isSelect) {
      this.cisInvoiceDetailArray.controls[index].get('vatAmount')?.setValue(0);
      this.cisInvoiceDetailArray.controls[index].get('amount')?.setValue(0);
      this.cisInvoiceDetailArray.controls[index].get('vatRate')?.setValue(null);
    }
    this.cisInvoiceDetailArray.controls[index].get('price').enable();
    this.cisInvoiceDetailArray.controls[index].get('description').enable();
    this.cisInvoiceDetailArray.controls[index].get('vatRate').enable();
    this.cisInvoiceDetailArray.controls[index].get('vatAmount').disable();
    this.cisInvoiceDetailArray.controls[index].get('amount').disable();
  }

  scrollIntoView() {
    this.commonService.autoScrollMatAutoComplete(this.renderer);
  }

  onProductChange(event: any, index: any): void {
    this.store
      .dispatch(new GetProductData(event.id))
      .pipe(
        tap((res) => {
          this.cisInvoiceDetailArray.controls[index]
            .get('price')
            ?.setValue(res.common.productDetails.price);
          this.cisInvoiceDetailArray.controls[index]
            .get('description')
            ?.setValue(res.common.productDetails.name);
          this.cisInvoiceDetailArray.controls[index]
            .get('vatRate')
            ?.setValue(
              res.common.productDetails.vatRateId === null
                ? -1
                : res.common.productDetails.vatRateId
            );
          this.qtyChanges(index);
          this.setFormValidity(true, index);
        })
      )
      .subscribe();
  }
  onCurrencyChanges(index: any): void {
    if (this.previousSelectedCurrencyRate != undefined) {
      this.isPreviousRate = true;
    } else {
      this.isPreviousRate = false;
    }
    const price = this.isPreviousRate
      ? (this.cisInvoiceDetailArray.controls[index].get('price').value /
          this.previousSelectedCurrencyRate) *
        this.selectedCurrencyRate
      : this.cisInvoiceDetailArray.controls[index].get('price').value *
        this.selectedCurrencyRate;
    this.cisInvoiceDetailArray.controls[index]
      .get('price')
      ?.setValue(price.toFixed(2));

    const totalNetAmount = price;
    this.cisInvoiceDetailArray.controls[index]
      .get('netAmount')
      ?.setValue(totalNetAmount);

    if (
      this.cisInvoiceDetailArray.controls[index].get('vatRate').value !==
        undefined &&
      this.cisInvoiceDetailArray.controls[index].get('vatRate').value != null &&
      this.cisInvoiceDetailArray.controls[index].get('vatRate').value !== -1
    ) {
      const data = this.vatRateList.filter(
        (x) =>
          x.id ===
          this.cisInvoiceDetailArray.controls[index].get('vatRate').value
      );

      if (data[0].codeType === VatRateCodeType.Custom) {
        this.cisInvoiceDetailArray.controls[index].get('vatAmount').enable();
        this.customVatAmount =
          this.cisInvoiceDetailArray.controls[index].get('vatAmount').value;
      } else {
        this.cisInvoiceDetailArray.controls[index].get('vatAmount').disable();
      }

      if (data.length > 0) {
        this.vatRate = data[0].rate;
      }

      let vatAmount;
      let amount;
      let vatRate;
      let totalNetAmount;

      if (this.isVatIncuded) {
        vatRate = this.vatRate === undefined ? 1 : this.vatRate;
        vatAmount = this.isVATChecked
          ? 0
          : !(data[0].codeType === VatRateCodeType.Custom)
          ? ((+price * vatRate) / (100 + vatRate)).toFixed(2)
          : this.customVatAmount;
        amount = +price.toFixed(2);
        totalNetAmount = (+price - vatAmount).toFixed(2);
      } else {
        vatRate = this.vatRate === undefined ? 1 : this.vatRate;
        vatAmount = this.isVATChecked
          ? 0
          : !(data[0].codeType === VatRateCodeType.Custom)
          ? (+price * vatRate) / 100
          : this.customVatAmount;
        amount = +price + vatAmount;
        totalNetAmount = +price;
      }

      this.cisInvoiceDetailArray.controls[index]
        .get('netAmount')
        ?.setValue(totalNetAmount);

      this.cisInvoiceDetailArray.controls[index]
        .get('vatAmount')
        ?.setValue(vatAmount.toFixed(2));

      this.cisInvoiceDetailArray.controls[index]
        .get('amount')
        ?.setValue(amount);
      this.amountChange();
    } else {
      const amount = price * 1;

      this.cisInvoiceDetailArray.controls[index].get('vatAmount')?.setValue(0);
      this.cisInvoiceDetailArray.controls[index]
        .get('amount')
        ?.setValue(amount);
      this.cisInvoiceDetailArray.controls[index]
        .get('netAmount')
        ?.setValue(amount);
      this.amountChange();
    }
  }
  UpdateVatProduct(): void {
    this.cisInvoiceDetailArray.controls.forEach((element, i) => {
      const price = this.cisInvoiceDetailArray.controls[i].get('price').value;

      if (
        element.controls.vatRate.value !== undefined &&
        element.controls.vatRate.value !== null &&
        element.controls.vatRate.value !== -1
      ) {
        const data = this.vatRateList[i].filter(
          (x) =>
            x.id === this.cisInvoiceDetailArray.controls[i].get('vatRate').value
        );

        if (data.length > 0) {
          this.vatRate = data[0].rate;
        }

        let vatAmount;
        let amount;
        let vatRate;
        let totalNetAmount;

        if (this.isVatIncuded) {
          vatRate = this.vatRate === undefined ? 1 : this.vatRate;
          vatAmount = ((+price * vatRate) / (100 + vatRate)).toFixed(2);
          amount = (+price).toFixed(2);
          totalNetAmount = (+price - vatAmount).toFixed(2);
        } else {
          vatRate = this.vatRate === undefined ? 1 : this.vatRate;
          vatAmount = (+price * vatRate) / 100;
          amount = (+price + vatAmount).toFixed(2);
          totalNetAmount = +price;
        }

        this.cisInvoiceDetailArray.controls[i]
          .get('netAmount')
          ?.setValue(totalNetAmount);

        this.cisInvoiceDetailArray.controls[i]
          .get('vatAmount')
          ?.setValue(vatAmount);
        this.cisInvoiceDetailArray.controls[i].get('amount')?.setValue(amount);
      } else {
        const amount = price * 1;

        this.cisInvoiceDetailArray.controls[i].get('vatAmount')?.setValue(0);
        this.cisInvoiceDetailArray.controls[i].get('amount')?.setValue(amount);
        this.cisInvoiceDetailArray.controls[i]
          .get('netAmount')
          ?.setValue(amount);
      }
    });
    this.amountChange();
  }
  filterVatRatesBasedOnCISVAT(): void {
    // this.vatRateList = this.vatRateList.filter(
    //   (item) => item.isStandard && (item.rate === 5 || item.rate === 20)
    // );

    this.cisInvoiceDetailArray.controls.forEach((x, index) => {
      (Object as any).values(x.controls).forEach((c) => {
        this.vatRateList[index] = this.originalVatRateList.filter(
          (item) => (item.isStandard && (item.rate === 5 || item.rate === 20) &&
          item.codeType === VatRateCodeType.Normal)
        );
       
      });
     
    });
  }
  filterVatRatesBasedOnAccount(i:number,vatId?:any): void {
    // this.vatRateList = this.vatRateList.filter(
    //   (item) => !(item.isStandard && item.codeType === 3 && item.rate === 0)
    // );

    if(this.isVATChecked){
      this.vatRateList[i] = this.originalVatRateList.filter(
        // (item) =>
        //   item.isStandard &&
        //   (item.rate === 5 || item.rate === 20) &&
        //   item.codeType === VatRateCodeType.Normal
        (item) => !(item.isStandard && item.codeType === 3 && item.rate === 0)
      );
    

  
    }else{

    if (AccountNumber.ACCOUNT_1274 === this.accountName) {
      this.vatRateList[i] = this.originalVatRateList.filter(
        (item) =>
          item.isStandard &&
          item.codeType === 3 &&
          item.rate === 20 &&
          item.abbreviation === VatName.EU_G
      );
    } else if (AccountNumber.ACCOUNT_1275 === this.accountName) {
      this.vatRateList[i] = this.originalVatRateList.filter(
        (item) =>
          item.isStandard &&
          item.codeType === 3 &&
          item.rate === 20 &&
          item.abbreviation === VatName.EU_S
      );
    } else if (AccountNumber.ACCOUNT_1273 === this.accountName) {
      this.vatRateList[i] = this.originalVatRateList.filter(
        (item) =>
          item.isStandard &&
          item.codeType === 1 &&
          item.rate === 20 &&
          item.abbreviation === VatName.Import_RC
      );
    } else if (AccountNumber.ACCOUNT_8215 === this.accountName) {
      this.vatRateList[i] = this.originalVatRateList.filter(
        (item) =>
          item.isStandard &&
          item.codeType === 5 &&
          item.rate === 0 &&
          item.abbreviation === VatName.Import_Vat
      );
    }
  }

  if(vatId){
    this.cisInvoiceDetailArray.controls[i].get('vatRate').setValue(vatId);
  }
  }
  setDisable(index: number): void {
    this.cisInvoiceDetailArray.controls[index].get('amount').disable();
    this.cisInvoiceDetailArray.controls[index].get('netAmount').disable();
    this.cisInvoiceDetailArray.controls[index].get('vatAmount').disable();
  }

  getInvoiceType(): void {
    const groupNames = new Array<number>();
    
      groupNames.push(GroupNames.CostOfSales);
      groupNames.push(GroupNames.SellingAndDistributionCosts);
      groupNames.push(GroupNames.AdministrativeExpenses);
    
    

    

    this.store
      .dispatch(new GetGroupAccountsBasedOnGroupId(groupNames, Modules.CISInvoice))
      .pipe(
        tap((res) => {
          if (res.common.accountGroupList.length > 0) {
            this.accountGroupList = res.common.accountGroupList;
            this.tempAccountGroupList = this.accountGroupList;
          }
        })
      )
      .subscribe();
  }
  onAccountSearch(event: any,i:number): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    this.accountGroupList = list;
  }
  onSearch(event: any,i:number): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    if (list.length === 0) {
      this.cisInvoiceDetailArray.controls[i].get('invoiceType')?.setValue('');
      //this.invoiceForm.controls.invoiceType.setValue('');
      this.accountGroupList = this.tempAccountGroupList;
    }
  }

  resetAccountListByAccount(i:number): void {
    this.accountGroupList = this.tempAccountGroupList;
    this.scrollIntoViewNew(this.cisInvoiceDetailArray.controls[i].get('invoiceType')?.value);
  }

  getOptionText(option) {
    return option.name;
  }

  scrollIntoViewByAccount(i:number) {
    if (this.cisInvoiceDetailArray.controls[i].get('invoiceType')?.value) {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  scrollIntoViewNew(element) {
    if (
      element.value.productName !== '' &&
      element.value.productName !== null &&
      element.value.productName !== undefined
    ) {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  onOptionSelected(i:number) {
  
    const selectedValue = this.cisInvoiceDetailArray?.controls[i].get('invoiceType')?.value; // Get the selected value from the event
    //this.triggerAccountSelectedOption.emit(selectedValue);


  //  this.triggerAccountSelectedOption?.subscribe((data) => {
      this.accountName = selectedValue.name;
      // if(this.isVATChecked){
      //   this.vatRateList[i] = this.originalVatRateList.filter(
      //     (item) =>
      //       item.isStandard &&
      //       (item.rate === 5 || item.rate === 20) &&
      //       item.codeType === VatRateCodeType.Normal
      //   );
      

    
      // }else{
      //   this.vatRateList[i] = this.originalVatRateList;
      // }
      
      // this.cisInvoiceDetailArray.controls.forEach((x, index) => {
      //   (Object as any).values(x.controls).forEach((c) => {
      //     x.controls.vatRate.setValue(null);
      //     x.controls.vatAmount.setValue(0);
      //   });
      // });

      //this.cisInvoiceDetailArray.controls[i].get('vatRate')?.setValue(null);
     // this.cisInvoiceDetailArray.controls[i].get('vatAmount')?.setValue(0);
      // if (
      //   Object.values(AccountNumber).some(
      //     (constant) => constant === this.accountName
      //   )
      // ) {
      //   this.cisInvoiceDetailArray.controls.forEach((x, index) => {
      //     this.qtyChanges(index);
      //   });
      // }
      if (!this.isVATChecked) {
        this.filterVatRatesBasedOnAccount(i);
      } else {
        this.filterVatRatesBasedOnCISVAT();
        this.filterVatRatesBasedOnAccount(i);
      }

  }
  onOptionSelectedByEditRecord(i:number,vatId:any) {
   
    const selectedValue = this.cisInvoiceDetailArray?.controls[i].get('invoiceType')?.value; // Get the selected value from the event
    //this.triggerAccountSelectedOption.emit(selectedValue);


  //  this.triggerAccountSelectedOption?.subscribe((data) => {
      this.accountName = selectedValue.name;
      this.vatRateList[i] = this.originalVatRateList;
      // this.cisInvoiceDetailArray.controls.forEach((x, index) => {
      //   (Object as any).values(x.controls).forEach((c) => {
      //     x.controls.vatRate.setValue(null);
      //     x.controls.vatAmount.setValue(0);
      //   });
      // });

      // this.cisInvoiceDetailArray.controls[i].get('vatRate')?.setValue(null);
      // this.cisInvoiceDetailArray.controls[i].get('vatAmount')?.setValue(0);
      // if (
      //   Object.values(AccountNumber).some(
      //     (constant) => constant === this.accountName
      //   )
      // ) {
      //   this.cisInvoiceDetailArray.controls.forEach((x, index) => {
      //     this.qtyChanges(index);
      //   });
      // }
      if (!this.isVATChecked) {
        this.filterVatRatesBasedOnAccount(i,vatId);
      } else {
        this.filterVatRatesBasedOnCISVAT();
        this.filterVatRatesBasedOnAccount(i,vatId);
      }

  }
}
