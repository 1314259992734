import { Guid } from 'guid-typescript';

export class ProductDetailsModel {
  id?: Guid;
  productId: Guid;
  productName?: string;
  description: string;
  quantity: number;
  price: number;
  vatRateId?: Guid;
  vatRateName?: string;
  vatAmount?: number;
  totalAmount: number;
  foreignVatAmount?: number;
  foreignPrice?: number;
  foreignTotalAmount?: number;
  discount?: number;
  isDiscountPercentage?: boolean;
  discountDescription?: string;
  foreignDiscount?: number;
  bookAccountId?: Guid| any;
}
