import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MaxLength, Modules } from '@app/core/Enum';
import { SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { AccountState, GetTermsList, MenuState } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-commerical-details',
  templateUrl: './add-commerical-details.component.html',
  styleUrls: ['./add-commerical-details.component.scss'],
})
export class AddCommericalDetailsComponent implements OnInit {
  termsList: Array<SideListModel>;
  commericalDetailsForm: FormGroup;
  moduleId = Modules;
  maxLength = MaxLength;

  @Input() triggerEditData: Observable<any>;
  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  private destroy$ = new Subject<void>();

  constructor(private store: Store, private commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.getTermsList();

    this.triggerEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.editCommericalData(data);
      });

    this.commericalDetailsForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange =
        this.commericalDetailsForm.touched;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTermsList(): void {
    this.store
      .dispatch(new GetTermsList())
      .pipe(
        tap(() => {
          this.termsList = this.store.selectSnapshot(AccountState.getTerms);
          this.commericalDetailsForm.controls.terms.setValue(
            this.termsList[0].id
          );
        })
      )
      .subscribe();
  }

  editCommericalData(data): void {
    this.commericalDetailsForm.patchValue({
      terms: data.termId,
      vatRegistrationNumber: data.vatRegistrationNumber,
      branchCode: data.bankBranchCode,
      accountNumber: data.bankAccountNumber,
      accountName: data.bankAccountName,
      accountIBANCode: data.bankAccountIBANCode,
    });
  }

  setForm(): void {
    this.commericalDetailsForm = new FormGroup({
      terms: new FormControl(),
      vatRegistrationNumber: new FormControl(''),
      branchCode: new FormControl(''),
      accountNumber: new FormControl(''),
      accountName: new FormControl(''),
      accountIBANCode: new FormControl(''),
    });
  }
}
