import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Modules, viewVatReturnBox } from '@app/core/Enum';
import { VatScheme } from '@app/core/Enum/vat-scheme';
import {
  ExportReceipt,
  ExportVatAudit,
  GetCompanyVatSchemaId,
  GetVatDetails,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-vat-view',
  templateUrl: './vat-view.component.html',
  styleUrls: ['./vat-view.component.scss'],
})
export class VatViewComponent implements OnInit {
  vatReturnDataSource = new MatTableDataSource();
  vatReturnList: any = [];

  vatSchemaId: any;
  ELEMENT_DATA: any[] = [
    {
      boxNo: 'Box 1',
      vatDesc: 'VAT due on sales and other outputs',
      vatAmount: 0,
    },
    {
      boxNo: 'Box 2',
      vatDesc:
        'VAT due in the period on acquisitions of goods made in Northern Ireland from EU Member States',
      vatAmount: 0,
    },
    {
      boxNo: 'Box 3',
      vatDesc: 'Total VAT due (Sum of Box 1 & 2)',
      vatAmount: 0,
    },
    {
      boxNo: 'Box 4',
      vatDesc:
        'VAT reclaimed in the period on purchases and other inputs (including acquisitions in Northern Ireland from EU member states)',
      vatAmount: 0,
    },
    {
      boxNo: 'Box 5',
      vatDesc: 'VAT payable or reclaimable (Difference of Box 3 & 4)',
      vatAmount: 0,
    },
    { boxNo: 'Box 6', vatDesc: 'Total sales excluding VAT', vatAmount: 0 },
    { boxNo: 'Box 7', vatDesc: 'Total purchases excluding VAT', vatAmount: 0 },
    {
      boxNo: 'Box 8',
      vatDesc:
        'Total value of dispatches of goods and related costs (excluding VAT) from Northern Ireland to EU Member States',
      vatAmount: 0,
    },
    {
      boxNo: 'Box 9',
      vatDesc:
        'Total value of acquisitions of goods and related costs (excluding VAT) made in Northern Ireland from EU Member States',
      vatAmount: 0,
    },
  ];
  id = Guid.EMPTY as unknown as Guid;
  noDataFound = false;

  vatReturnDisplayedColumns: string[] = ['boxNo', 'vatDesc', 'vatAmount'];

  REVIEW_ELEMENT_DATA: any[] = [];

  selectedTab = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VatViewComponent>,
    private store: Store,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.id = this.data.id;
    this.getVatSchemaId().then(() => {
      this.spinner.show();
      this.store.dispatch(new GetVatDetails(this.id)).subscribe((res) => {
        this.spinner.hide();
        this.vatReturnList = res.submitVat.vatData;

        this.vatReturnSetData();
      });
    });
  }

  async getVatSchemaId(): Promise<void> {
    await this.store
      .dispatch(new GetCompanyVatSchemaId())
      .toPromise()
      .then((res) => {
        this.vatSchemaId = res.company.companyVatSchemaId;
      });
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  vatReturnSetData(): void {
    this.ELEMENT_DATA.forEach((element, i) => {
      if (element.boxNo === viewVatReturnBox.Box1) {
        element.vatAmount = this.vatReturnList.vatDueSales;
      } else if (element.boxNo === viewVatReturnBox.Box2) {
        element.vatAmount = this.vatReturnList.vatDueAcquisitions;
      } else if (element.boxNo === viewVatReturnBox.Box3) {
        element.vatAmount = this.vatReturnList.totalVatDue;
      } else if (element.boxNo === viewVatReturnBox.Box4) {
        element.vatAmount = this.vatReturnList.vatReclaimedCurrPeriod;
      } else if (element.boxNo === viewVatReturnBox.Box5) {
        element.vatAmount = this.vatReturnList.netVatDue;
      } else if (element.boxNo === viewVatReturnBox.Box6) {
        if (
          +this.vatSchemaId === VatScheme.FlatRateAccrualScheme ||
          +this.vatSchemaId === VatScheme.FlatRateCashBasedScheme
        ) {
          element.vatDesc = 'Total sales including VAT';
        }
        element.vatAmount = this.vatReturnList.totalValueSalesExVAT;
      } else if (element.boxNo === viewVatReturnBox.Box7) {
        element.vatAmount = this.vatReturnList.totalValuePurchasesExVAT;
      } else if (element.boxNo === viewVatReturnBox.Box8) {
        element.vatAmount = this.vatReturnList.totalValueGoodsSuppliedExVAT;
      } else if (element.boxNo === viewVatReturnBox.Box9) {
        element.vatAmount = this.vatReturnList.totalAcquisitionsExVAT;
      }
    });
    this.vatReturnDataSource.data = this.ELEMENT_DATA;
  }

  onTabClick(event: any): void {
    this.selectedTab = event;
  }

  onPdfClick() {
    this.spinner.show();
    let ids: any = [];
    ids.push(this.id);
    if (this.vatReturnList.isBridgingVAT) {
      this.store
        .dispatch(new ExportReceipt(Modules.BridgingVAT, ids, false))
        .subscribe();
    } else {
      this.store.dispatch(new ExportVatAudit(this.id, false)).subscribe();
    }
  }
}
