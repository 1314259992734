import { Guid } from 'guid-typescript';

export interface CashCodingModel {
  transactionId: Guid;
  contactId?: Guid | null;
  bankAccountId: Guid;
  accountId: Guid;
  vatAmount: number;
  vatRateId?: Guid | null;
  isBankFeed: boolean;
  isCredit: boolean;
  branchId?: Guid | null;
  departmentId?: Guid | null;
}

export class CashCodingItemModel {
  id?: Guid;
  entryDate?: string;
  accountId: Guid;
  note: string;
  isCredit?: boolean;
  totalAmount: number;
  receiptItems: Array<any>;
}
