import { Injectable } from '@angular/core';
import { FixedAssetReturnModel, SideListModel } from '@app/core/Models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { FixedAssetsReturnService } from '@app/core/Services';
import { Guid } from 'guid-typescript';
import {
  ArchiveAndRestoreFixedAssetReturn,
  CreateFixedAssetReturn,
  DeleteFixedAssetReturn,
  GetDataByFixedAssetReturnId,
  QuickFixedAssetReturn,
} from './fixed-asset-return.action';

export class FixedAssetReturnStateInfo {
  fixedAssetReturnData?: FixedAssetReturnModel;
  fixedAssetReturn: Array<FixedAssetReturnModel>;
  fixedAssetReturnId?: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isFixedAssetReturnAdded?: boolean;
  isQuickFixedAssetReturnAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
}

@State<FixedAssetReturnStateInfo>({
  name: 'fixedAssetReturn',
  defaults: {
    fixedAssetReturn: [],
    fixedAssetReturnId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isFixedAssetReturnAdded: false,
  },
})
@Injectable()
export class FixedAssetReturnState {
  constructor(private fixedAssetReturnService: FixedAssetsReturnService) {}

  @Selector()
  static isLastPage(state: FixedAssetReturnStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getFixedAssetReturnId(state: FixedAssetReturnStateInfo) {
    return state.fixedAssetReturnId;
  }

  @Action(CreateFixedAssetReturn)
  createFixedAssetReturn(
    { patchState }: StateContext<FixedAssetReturnStateInfo>,
    action: CreateFixedAssetReturn
  ) {
    return this.fixedAssetReturnService
      .createFixedAssetReturn(action.fixedAssetReturn)
      .pipe(
        tap((res) => {
          patchState({
            fixedAssetReturnId: res,
            isFixedAssetReturnAdded: true,
          });
        })
      );
  }

  @Action(QuickFixedAssetReturn)
  quickFixedAssetReturn(
    { patchState }: StateContext<FixedAssetReturnStateInfo>,
    action: QuickFixedAssetReturn
  ) {
    return this.fixedAssetReturnService
      .quickFixedAssetReturn(action.fixedAssetReturn)
      .pipe(
        tap((res) => {
          patchState({
            isQuickFixedAssetReturnAdded: res,
          });
        })
      );
  }

  @Action(GetDataByFixedAssetReturnId)
  getDataByFixedAssetReturnId(
    { patchState }: StateContext<FixedAssetReturnStateInfo>,
    action: GetDataByFixedAssetReturnId
  ) {
    return this.fixedAssetReturnService
      .getDataByFixedAssetReturnId(action.fixedAssetReturnId)
      .pipe(
        tap((res) => {
          patchState({
            fixedAssetReturnData: res,
          });
        })
      );
  }

  @Action(DeleteFixedAssetReturn)
  deleteFixedAssetReturn(
    { getState, setState }: StateContext<FixedAssetReturnStateInfo>,
    action: DeleteFixedAssetReturn
  ) {
    return this.fixedAssetReturnService
      .deleteFixedAssetReturn(action.fixedAssetReturnIds)
      .pipe(
        tap((res) => {
          const state = getState();

          const filteredCrediteNote = state.fixedAssetReturn.filter(
            (item) =>
              !action.fixedAssetReturnIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          const filteredForSideList = state.sideListModel?.filter(
            (item) =>
              !action.fixedAssetReturnIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          setState({
            ...state.fixedAssetReturn,
            fixedAssetReturn: filteredCrediteNote,
            sideListModel: filteredForSideList,
          });
        })
      );
  }

  @Action(ArchiveAndRestoreFixedAssetReturn)
  archiveAndRestoreFixedAssetReturn(
    { getState }: StateContext<FixedAssetReturnStateInfo>,
    action: ArchiveAndRestoreFixedAssetReturn
  ) {
    return this.fixedAssetReturnService.archiveAndRestoreFixedAssetReturn(
      action.fixedAssetReturnIds,
      action.isArchive
    );
  }
}
