import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivityLogModel, ActivityTaskParameters, AuthorisedMenuModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  constructor(private http: HttpClient) {}

  getActivityList(
    queryParams: ActivityTaskParameters
  ): Observable<Array<ActivityLogModel>> {
    return this.http.get<Array<ActivityLogModel>>(
      `${environment.apiVersionUrl}ActivityLog/getActivityLog?moduleId=${queryParams.moduleId}&search=${queryParams.search}&entityId=${queryParams.entityId}&startDate=${queryParams.startDate}&endDate=${queryParams.endDate}&dateFilter=${queryParams.dateFilter}&pageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}`
    );
  }

  getAuthorizedMenus(): Observable<Array<AuthorisedMenuModel>> {
    return this.http.get<Array<AuthorisedMenuModel>>(
      `${environment.apiVersionUrl}ModuleRolePermission/getActivityLogMenus`
    );
  }

}
