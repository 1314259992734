import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Modules, NotificationTextMessage } from '@app/core/Enum';
import { CompanyPreference } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateCompanyPreference,
  GetCompanyPreferenceData,
  MenuState,
} from '@app/core/Store';
import { AddCompanyPreferenceComponent } from '@app/modules';
import { AddClosePopupComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-company-preference',
  templateUrl: './company-preference.component.html',
  styleUrls: ['./company-preference.component.scss'],
})
export class CompanyPreferenceComponent implements OnInit {
  companyPreferenceData: CompanyPreference;

  companyPreferenceId = Guid.EMPTY as unknown as Guid;
  selectedTab = 0;
  moduleId = Modules.ClientsPreference;

  @ViewChild(AddCompanyPreferenceComponent)
  companyPreferenceDetail;

  triggerEditData: Subject<any> = new Subject<any>();
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private store: Store,
    public commonService: CommonService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.editCompanyPreferences();
  }

  editCompanyPreferences(): void {
    this.store
      .dispatch(new GetCompanyPreferenceData(this.selectedTabModuleId()))
      .subscribe((res) => {
        this.companyPreferenceDetail.companyPreferenceForm.markAsUntouched();
        if (res.company.getCompanyPreferenceData) {
          this.companyPreferenceId = res.company.getCompanyPreferenceData.id;
          this.triggerEditData.next(res.company.getCompanyPreferenceData);
        } else {
          this.companyPreferenceId = this.commonService.defaultGuidValue;
        }
      });
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.companyPreferenceData = {
        id: this.companyPreferenceId,
        moduleId: this.selectedTabModuleId(),
        autoGenerate:
          this.companyPreferenceDetail.companyPreferenceForm.value.autoGenerate,
        prefix: this.companyPreferenceDetail.companyPreferenceForm.value.prefix,
        suffix: this.companyPreferenceDetail.companyPreferenceForm.value.suffix,
        repeatType:
          +this.companyPreferenceDetail.companyPreferenceForm.value.repeatType,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.companyPreferenceDetail.companyPreferenceForm.invalid) {
      this.commonService.addValidation(
        this.companyPreferenceDetail.companyPreferenceForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateCompanyPreference(this.companyPreferenceData))
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.onCancel();
                if (isExit) {
                  history.back();
                }
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  onTabClick(event: any): void {
    this.selectedTab = event;
    this.editCompanyPreferences();
  }

  onCancel(): void {
    this.commonService.isInitialValueChange = false;
    this.companyPreferenceId = Guid.EMPTY as unknown as Guid;
    this.ngOnInit();
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  selectedTabModuleId(): number {
    let selectedModuleId;

    switch (this.selectedTab) {
      case 0:
        selectedModuleId = Modules.Invoices;
        break;

      case 1:
        selectedModuleId = Modules.Quotation;
        break;

      case 2:
        selectedModuleId = Modules.RecurringInvoice;
        break;

      case 3:
        selectedModuleId = Modules.CreditNote;
        break;

      case 4:
        selectedModuleId = Modules.RecurringPurchase;
        break;
    }

    return selectedModuleId;
  }
}
