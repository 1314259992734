<div class="mat-dialog-wrapper match-journal">
  <h2 mat-dialog-title>Add Receipt</h2>
  <button
    class="close-button"
    type="button"
    (click)="onCloseClick()"
    mat-button
    disableRipple
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    <div class="content-body p-0">
      <form>
        <div class="form-field">
          <p>Contact</p>
          <mat-form-field>
            <input matInput autocomplete="off" name="contact" />
          </mat-form-field>
        </div>
        <div class="form-field textarea">
          <p>Description</p>
          <mat-form-field>
            <textarea
              matInput
              formControlName="Description"
              autocomplete="off"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Amount</p>
          <mat-form-field>
            <input matInput autocomplete="off" name="amount" />
          </mat-form-field>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="hr pt-1 mt-0 justify-content-end">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
    >
      Add
    </button>
    <button
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onCloseClick()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
