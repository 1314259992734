import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GetNextPeriod } from '@app/core/Store';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-cis-return-details',
  templateUrl: './cis-return-details.component.html',
  styleUrls: ['./cis-return-details.component.scss'],
})
export class CISReturnDetailsComponent implements OnInit {
  cisReturnDetailsForm: FormGroup;
  monthList: string[] = [];
  selectedYear: number;
  years: number[] = [];
  nillReturn = false;
  isActivity = false;
  informationCorrect = true;
  endDate: any;
  startDate: any;
  isVerificationChecked = true;
  isEmploymentStatusChecked = true;
  isDisabled = false;
  isEdit: boolean;
  @Input() triggerEditData: Observable<any>;
  private destroy$ = new Subject<void>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.setForm();
    this.isEdit = false;
    this.bindMonthList();
    this.bindYearList();
    this.getNextPeriod();
    this.triggerEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isEdit = true;
        this.editCISReturn(data);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setForm(): void {
    this.cisReturnDetailsForm = new FormGroup({
      month: new FormControl(),
      year: new FormControl(),
      returnPeriod: new FormControl(),
      nilReturn: new FormControl(),
      employmentStatus: new FormControl(),
      verification: new FormControl(),
      inactivity: new FormControl(),
      informationCorrect: new FormControl(),
    });
  }

  getNextPeriod(): void {
    if (!this.isEdit) {
      this.store
        .dispatch(new GetNextPeriod())
        .pipe(
          tap((res) => {
            if (res.CIS.nextPeriod !== null) {
              this.endDate = moment(res.CIS.nextPeriod).format('YYYY-MM-DD');
              this.startDate = moment(this.endDate)
                .subtract(30, 'd')
                .format('YYYY-MM-DD');
              let startDate: any = new Date(this.startDate);
              const m = startDate.getMonth() + 1;
              const y = startDate.getFullYear().toString();
              const month = moment(m, 'M').format('MMMM');
              this.cisReturnDetailsForm.get('month')?.setValue(month);
              this.cisReturnDetailsForm.get('year')?.setValue(y);
            } else {
              this.onMonthYearChange();
            }
          })
        )
        .subscribe();
    }
  }

  editCISReturn(data): void {
    let startDate: any = new Date(data.toDate);
    const m = startDate.getMonth() + 1;
    const y = startDate.getFullYear().toString();
    const month = moment(m, 'M').format('MMMM');
    this.startDate = moment(data.fromDate.valueOf()).format('YYYY-MM-DD');
    this.endDate = moment(data.toDate.valueOf()).format('YYYY-MM-DD');
    this.cisReturnDetailsForm.patchValue({
      month: month,
      year: y,
      employmentStatus: data.employmentStatus,
      nilReturn: data.nillReturn,
      verification: data.verification,
      inactivity: data.inActivity,
    });
  }

  bindMonthList(): void {
    this.monthList = Array.from({ length: 12 }, (e, i) => {
      return new Date(0, i + 1, 0).toLocaleDateString('en', {
        month: 'long',
      });
    });
    this.cisReturnDetailsForm.controls.month.setValue(this.monthList[0]);
  }

  bindYearList(): void {
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= 2008; year--) {
      this.years.push(year);
    }
    this.cisReturnDetailsForm.controls.year.setValue(this.years[0]);
  }

  onCheckBoxChanges(event: any): void {
    if (event.checked) {
      this.isVerificationChecked = false;
      this.isEmploymentStatusChecked = false;
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
      this.isVerificationChecked = true;
      this.isEmploymentStatusChecked = true;
    }
  }

  onMonthYearChange(): void {
    const month = this.cisReturnDetailsForm.get('month')?.value;
    const year = this.cisReturnDetailsForm.get('year')?.value;
    const day = '05';
    const date = month + '-' + day + '-' + year;
    this.endDate = moment(date.valueOf()).format('YYYY-MM-DD');
    this.startDate = moment(this.endDate)
      .subtract(30, 'd')
      .format('YYYY-MM-DD');
    if (month === 3) {
      this.startDate = moment(this.startDate).add(2, 'd').format('YYYY-MM-DD');
    } else if (month === 5) {
      this.startDate = moment(this.startDate).add(1, 'd').format('YYYY-MM-DD');
    } else if (month === 7) {
      this.startDate = moment(this.startDate).add(1, 'd').format('YYYY-MM-DD');
    } else if (month === 10) {
      this.startDate = moment(this.startDate).add(1, 'd').format('YYYY-MM-DD');
    } else if (month === 12) {
      this.startDate = moment(this.startDate).add(1, 'd').format('YYYY-MM-DD');
    }
  }
}
