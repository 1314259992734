import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainListParameters } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonService } from '../../common/common.service';
@Injectable({
  providedIn: 'root',
})
export class CISReportService {
  constructor(private http: HttpClient, public commonService: CommonService) {}

  subContractorYTDDetailsReport(
    queryParams: MainListParameters
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CISReport/subContractorYTDDetails`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getInvoiceReport(invoiceReportParameters: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}CISReport/getInvoiceReport`,
        JSON.stringify(invoiceReportParameters),
        headers
      )
      .pipe(
        switchMap((response: any) => {
          this.commonService.download(response);
          return of(true);
        })
      );
  }

  sendEmailInvoiceReport(
    attachmentParams: any,
    toMailId: string,
    ccMailId: string,
    bccMailId: string,
    message: string,
    subject: string
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const obj = {
      invoiceReportParameters: {
        cisId: attachmentParams.cisId,
        contactId: attachmentParams.contactId,
        startDate: attachmentParams.startDate,
        endDate: attachmentParams.endDate,
      },
      toMailId: toMailId,
      cc: ccMailId,
      bcc: bccMailId,
      message: message,
      subject: subject,
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CISReport/sendEmailInvoiceReport`,
      obj,
      options
    );
  }

  cisSubmissionReport(): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CISReport/cisSubmissionReport
      `,
      headers
    );
  }
}
