<div class="mat-dialog-wrapper permission-details">
  <mat-dialog-content>
    <div class="main-contain">
      <div class="sidenav-header">
        <div class="d-flex align-items-center justify-space-between">
          <h2>
            <span *ngIf="data.permissionId === authorizedRole['Client User']"
              >Regular User Permissions</span
            >
            <span *ngIf="data.permissionId === authorizedRole.Manager"
              >Manager Permissions</span
            >
            <span *ngIf="data.permissionId === authorizedRole.Admin"
              >Admin Permissions</span
            >
          </h2>
          <div>
            <mat-icon class="close" mat-dialog-close>close</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="mat-table-wrapper">
      <table
        mat-table
        [dataSource]="dataSource"
        aria-describedby="Billable hours by client by quarter Table"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            <span [style.paddingLeft.px]="0">
              <button mat-button (click)="toggleAllClick()" disableRipple>
                <mat-icon class="mat-icon-rtl-mirror">
                  {{
                    toggleAll ? "keyboard_arrow_down" : "keyboard_arrow_right"
                  }}
                </mat-icon>
              </button>
              Permissions
            </span>
          </th>
          <td mat-cell *matCellDef="let data">
            <div class="onelineclamp">
              <button
                mat-button
                [style.visibility]="!data.expandable ? 'hidden' : ''"
                [style.marginLeft.px]="data.level * 20"
                (click)="treeControl.toggle(data)"
                disableRipple
              >
                <mat-icon class="mat-icon-rtl-mirror">
                  {{
                    treeControl.isExpanded(data)
                      ? "keyboard_arrow_down"
                      : "keyboard_arrow_right"
                  }}
                </mat-icon>
              </button>
              <span
                class="fw-bold"
                *ngIf="
                  data.parentModuleId === undefined ||
                  data.parentModuleId === null
                "
                title="{{ data.name }}"
                >{{ data.name }}</span
              >
              <span *ngIf="data.parentModuleId > 0" title="{{ data.name }}">{{
                data.name
              }}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <div class="d-flex text-align-center gap-10">
              <mat-checkbox
                [(ngModel)]="isFullSelected"
                [disabled]="isFullSelected"
                (change)="selected(permissionType.Full)"
                >Full</mat-checkbox
              >
              <mat-checkbox
                [disabled]="isViewSelected"
                [(ngModel)]="isViewSelected"
                (change)="selected(permissionType.View)"
                >View</mat-checkbox
              >
              <mat-checkbox
                [disabled]="isNoneSelected"
                [(ngModel)]="isNoneSelected"
                (change)="selected(permissionType.None)"
                >None</mat-checkbox
              >
            </div>
          </th>
          <td mat-cell *matCellDef="let data">
            <mat-radio-group
              aria-label="Select an option"
              class="d-flex text-align-center gap-10"
              (change)="onSelected(data, $event.value)"
              [(ngModel)]="data.permissionId"
              [disabled]="data.isDisabled"
            >
              <mat-radio-button [value]="permissionType.Full"
                >Full
              </mat-radio-button>
              <mat-radio-button [value]="permissionType.View"
                >View
              </mat-radio-button>
              <mat-radio-button [value]="permissionType.None"
                >None
              </mat-radio-button>
            </mat-radio-group>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <div class="d-flex align-items-center justify-space-between pb-5 pt-1">
      <div>
        <button
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
          (click)="onSave()"
        >
          Save
        </button>
        <button
          class="action-button secondary-button"
          mat-dialog-close
          disableRipple
          type="button"
          mat-button
          disableRipple
        >
          Cancel
        </button>
      </div>
      <div class="reset-btn">
        <a
          href="javascript:void(0)"
          (click)="resetToDefault()"
          class="d-flex align-items-center gap-5"
        >
          <mat-icon
            fontSet="material-icons-outlined"
            title="edit"
            class="text-primary"
            >refresh</mat-icon
          >
          <span class="hyperlink"> Reset to Default</span>
        </a>
      </div>
    </div>
  </mat-dialog-content>
</div>
