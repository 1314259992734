<div
  class="content-header d-flex align-items-end justify-space-between header-filters header-count header-count-{{
    (headerList$ | async).length
  }}"
>
  <div class="d-flex align-items-end">
    <div *ngFor="let item of headerList$ | async; let i = index">
      <div class="{{ item.name }}">
        <div
          *ngIf="item.controlType === controlType.DateTimePicker"
          class="year-picker mr-10"
        >
          <div class="d-flex flex-column">
            <span class="fw-bold mb-5">Period:</span>
            <app-custom-year-picker
              [moduleId]="moduleId"
              (triggerDateChange)="triggerDateChange($event)"
            ></app-custom-year-picker>
          </div>
        </div>

        <div
          class="content-right-button d-flex flex-column gap-5 mr-10"
          *ngIf="item.controlType === controlType.Dropdown"
        >
          <span
            class="fw-bold"
            *ngIf="
              (moduleId$ | async) === moduleEnum.BankFeed ||
              (moduleId$ | async) === moduleEnum.BulkEdit ||
              (moduleId$ | async) === moduleEnum.AccountDetails
            "
          >
            Select Account:
          </span>
          <span
            class="fw-bold"
            *ngIf="
              (moduleId$ | async) === moduleEnum.SubcontractorYTDDetails ||
              (moduleId$ | async) === moduleEnum.CISReportCISInvoice
            "
          >
            Sub Contractors :
          </span>
          <span
            class="fw-bold mr-1"
            *ngIf="
              (moduleId$ | async) === moduleEnum.CustomerAdvanceReport ||
              (moduleId$ | async) === moduleEnum.CustomerAgeingList ||
              (moduleId$ | async) === moduleEnum.TransactionsByCustomer ||
              (moduleId$ | async) === moduleEnum.CustomerReceipts ||
              (moduleId$ | async) === moduleEnum.Debtors ||
              (moduleId$ | async) === moduleEnum.OpenInvoice ||
              (item.name === HeaderFilters.Module &&
                (moduleId$ | async) === moduleEnum.CustomerStatement) ||
              (moduleId$ | async) === moduleEnum.SalesInvoiceList
            "
          >
            Customer :
          </span>
          <span
            class="fw-bold"
            *ngIf="
              (item.name !== HeaderFilters.Module &&
                (moduleId$ | async) === moduleEnum.CustomerStatement) ||
              (item.name === HeaderFilters.SubModule &&
                (moduleId$ | async) === moduleEnum.DayBook)
            "
            >Type :</span
          >
          <span
            class="fw-bold"
            *ngIf="
              (moduleId$ | async) === moduleEnum.SupplierAdvanceReport ||
              (moduleId$ | async) === moduleEnum.SupplierAgeingList ||
              (moduleId$ | async) === moduleEnum.TransactionsBySupplier ||
              (moduleId$ | async) === moduleEnum.PaymentToSupplier ||
              (moduleId$ | async) === moduleEnum.Creditors ||
              (moduleId$ | async) === moduleEnum.UnpaidPurchases ||
              (moduleId$ | async) === moduleEnum.PurchasesInvoiceList ||
              (item.name === HeaderFilters.Module &&
                (moduleId$ | async) === moduleEnum.SupplierStatement)
            "
          >
            Supplier :
          </span>
          <span
            class="fw-bold"
            *ngIf="
              item.name !== HeaderFilters.Module &&
              ((moduleId$ | async) === moduleEnum.SupplierStatement ||
                (moduleId$ | async) === moduleEnum.BankDashboard)
            "
            >Type :</span
          >
          <span
            class="fw-bold"
            *ngIf="(moduleId$ | async) === moduleEnum.SalesReport"
          >
            Period:
          </span>
          <div
            [ngClass]="[
              (moduleId$ | async) === moduleEnum.BankFeed ? 'w-200' : '',
              (moduleId$ | async) === moduleEnum.AddStandardAccounts
                ? 'w-200'
                : '',
              (moduleId$ | async) === moduleEnum.BulkEdit ? 'w-200' : ''
            ]"
          >
            <ng-select
              *ngIf="isSelected"
              panelClass="mat-select-position"
              [disableOptionCentering]="true"
              appendTo="body"
              #commonSelection
              (focus)="commonSelection.open()"
              (valueChange)="commonSelection.close()"
              [(ngModel)]="
                item.name === HeaderFilters.Module
                  ? item.value.length > 0
                    ? commonService.defaultHeaderGuidValue
                    : ''
                  : defaultValue[i].id
              "
              (change)="onDropdownChange(item.name, $event, false)"
            >
              <ng-option *ngFor="let cl of item.value" [value]="cl.id">
                <span title="{{ cl.name }}">{{ cl.name }}</span>
              </ng-option>
            </ng-select>
          </div>
        </div>
        <div
          class="content-right-button d-flex flex-column gap-5 mr-10"
          *ngIf="
            (moduleId$ | async) === moduleEnum.ComparativeReport &&
            item.controlType === controlType.ReportTypeDropdown
          "
        >
          <span class="fw-bold"> Type: </span>

          <div [ngClass]="['w-200']">
            <ng-select
              *ngIf="isSelected"
              panelClass="mat-select-position"
              [disableOptionCentering]="true"
              appendTo="body"
              #commonSelection
              (focus)="commonSelection.open()"
              (valueChange)="commonSelection.close()"
              [(ngModel)]="
                item.name === HeaderFilters.Module
                  ? item.value.length > 0
                    ? commonService.defaultHeaderGuidValue
                    : ''
                  : defaultValue[i].id
              "
              (change)="onComparitiveReportTypeChange(item.name, $event, false)"
            >
              <ng-option *ngFor="let cl of item.value" [value]="cl.id">
                <span title="{{ cl.name }}">{{ cl.name }}</span>
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div
          class="content-right-button d-flex flex-column gap-5 mr-10"
          *ngIf="item.controlType === controlType.GroupDropdown"
        >
          <div
            *ngIf="
              (moduleId$ | async) === moduleEnum.BankFeed ||
              (moduleId$ | async) === moduleEnum.BulkEdit ||
              (moduleId$ | async) === moduleEnum.AccountDetails ||
              (moduleId$ | async) === moduleEnum.NominalLedger
            "
          >
            <span class="fw-bold mb-5 display-inline"> Select Account: </span>
            <div>
              <ng-select
                #accountName
                [items]="item.groupValue"
                (focus)="accountName.open()"
                (valueChange)="accountName.close()"
                class="bank-account w-250"
                [disableOptionCentering]="true"
                [(ngModel)]="
                  item.name === HeaderFilters.Module
                    ? item.groupValue.length > 0
                      ? commonService.defaultHeaderGuidValue
                      : ''
                    : defaultValue[i].id
                "
                bindLabel="name"
                bindValue="id"
                groupBy="groupName"
                (ngModelChange)="onDropdownChange(item.name, $event, false)"
                panelClass="mat-select-position"
                appendTo="body"
              >
                <ng-template ng-option-tmp let-item="item">
                  <span class="ng-option-label" [attr.title]="item.name">{{
                    item.name
                  }}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column gap-5 mr-10"
      *ngIf="
        !removeBranchSelection &&
        (moduleId$ | async) !== moduleEnum.Import &&
        (moduleId$ | async) !== moduleEnum.Director &&
        (moduleId$ | async) !== moduleEnum.Shareholder &&
        (moduleId$ | async) !== moduleEnum.AddStandardAccounts &&
        (moduleId$ | async) !== moduleEnum.CIS300 &&
        (moduleId$ | async) !== moduleEnum.SubmitVat &&
        (moduleId$ | async) !== moduleEnum.BankImportHistory &&
        branchList.length > 0
      "
    >
      <span class="fw-bold">Branch:</span>
      <ejs-multiselect
        id="multiselect-checkbox"
        #branch
        [dataSource]="branchList"
        [placeholder]="branchWaterMark"
        [fields]="branchFields"
        [mode]="branchmode"
        [popupHeight]="branchpopHeight"
        [showDropDownIcon]="true"
        showSelectAll="true"
        [(ngModel)]="selectedBranchValues"
        [filterBarPlaceholder]="branchfilterPlaceholder"
        (change)="onBranchMultiSelectChange($event)"
      ></ejs-multiselect>
    </div>
    <div
      class="d-flex flex-column gap-5"
      *ngIf="
        (moduleId$ | async) !== moduleEnum.Import &&
        (moduleId$ | async) !== moduleEnum.Director &&
        (moduleId$ | async) !== moduleEnum.Shareholder &&
        (moduleId$ | async) !== moduleEnum.AddStandardAccounts &&
        (moduleId$ | async) !== moduleEnum.Customers &&
        (moduleId$ | async) !== moduleEnum.Suppliers &&
        departmentList.length > 0
      "
    >
      <span class="fw-bold">Department:</span>
      <ejs-multiselect
        id="multiselect-checkbox"
        #department
        [dataSource]="departmentList"
        [placeholder]="departmentWaterMark"
        [fields]="departmentFields"
        [mode]="departmentmode"
        [popupHeight]="departmentpopHeight"
        [showDropDownIcon]="true"
        showSelectAll="true"
        [filterBarPlaceholder]="departmentfilterPlaceholder"
        [(ngModel)]="selectedDepartmentValues"
        (change)="onDepartmentMultiSelectChange($event)"
      ></ejs-multiselect>
    </div>
  </div>
  <div
    class="d-flex flex-column"
    *ngIf="(moduleId$ | async) === moduleEnum.Inventory"
  >
    <button
      class="action-button primary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onLossOfStockClick()"
      [disabled]="isViewPermission$ | async"
    >
      Loss of Stock
    </button>
  </div>

  <div
    *ngIf="
      (moduleId$ | async) !== moduleEnum.BankFeed &&
      (moduleId$ | async) !== moduleEnum.BulkEdit &&
      (moduleId$ | async) !== moduleEnum.Import &&
      (moduleId$ | async) !== moduleEnum.CISReportCISInvoice &&
      !isBankImport
    "
    class="toolbar"
  >
    <button mat-button [matMenuTriggerFor]="menu" disableRipple>
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="action-menu more-menu" xPosition="before">
      <button
        *ngIf="
          ((moduleId$ | async) === moduleEnum.Invoices ||
            (moduleId$ | async) === moduleEnum.Purchase ||
            (moduleId$ | async) === moduleEnum.CreditNote ||
            (moduleId$ | async) === moduleEnum.DebitNote ||
            (moduleId$ | async) === moduleEnum.Customers ||
            (moduleId$ | async) === moduleEnum.Suppliers ||
            (moduleId$ | async) === moduleEnum.BankDashboard ||
            (moduleId$ | async) === moduleEnum.FixedAssets ||
            (moduleId$ | async) === moduleEnum.Product ||
            (moduleId$ | async) === moduleEnum.Dividends ||
            (moduleId$ | async) === moduleEnum.Shareholder ||
            (moduleId$ | async) === moduleEnum.Director ||
            (moduleId$ | async) === moduleEnum.SubcontractorYTDDetails ||
            (moduleId$ | async) === moduleEnum.QuickEntry) &&
          !showAccountDetails &&
          isCustom
        "
        mat-menu-item
        (click)="import()"
        disableRipple
        [disabled]="(isViewPermission$ | async) || !isImportChangePermission"
        [ngClass]="
          (isViewPermission$ | async) || !isImportChangePermission
            ? 'cursor-not-allowed'
            : ''
        "
      >
        <mat-icon>save_alt</mat-icon>Import
      </button>

      <button
        *ngIf="
          (moduleId$ | async) === reportEnum.TrialBalance ||
          (moduleId$ | async) === reportEnum.BalanceSheet ||
          (moduleId$ | async) === reportEnum.BudgetReport ||
          (moduleId$ | async) === reportEnum.DayBook ||
          (moduleId$ | async) === reportEnum.ProfitAndLoss ||
          (moduleId$ | async) === moduleEnum.OpenInvoice ||
          (moduleId$ | async) === moduleEnum.UnpaidPurchases ||
          (moduleId$ | async) === moduleEnum.AdvanceAgeingList ||
          (moduleId$ | async) === moduleEnum.AccountPayableList ||
          (moduleId$ | async) === moduleEnum.AccountDetails ||
          (moduleId$ | async) === moduleEnum.NominalLedger ||
          (moduleId$ | async) === moduleEnum.BusinessSnapshot ||
          (moduleId$ | async) === moduleEnum.AccountList ||
          (moduleId$ | async) === moduleEnum.CompanyOverview ||
          (moduleId$ | async) === moduleEnum.SalesPerformance ||
          (moduleId$ | async) === moduleEnum.ExpensePerformance ||
          (moduleId$ | async) === moduleEnum.TransactionListByDate ||
          (moduleId$ | async) === moduleEnum.RecentTransactions ||
          (moduleId$ | async) === moduleEnum.BalanceSheetComparison ||
          (moduleId$ | async) === moduleEnum.BalanceSheetDetail ||
          (moduleId$ | async) === moduleEnum.CustomizeReport ||
          (moduleId$ | async) === moduleEnum.CustomerAdvanceReport ||
          (moduleId$ | async) === moduleEnum.SalesReport ||
          (moduleId$ | async) === moduleEnum.CustomerList ||
          (moduleId$ | async) === moduleEnum.CustomerAgeingList ||
          (moduleId$ | async) === moduleEnum.TransactionsByCustomer ||
          (moduleId$ | async) === moduleEnum.SalesProductList ||
          (moduleId$ | async) === moduleEnum.CustomerReceipts ||
          (moduleId$ | async) === moduleEnum.Debtors ||
          (moduleId$ | async) === moduleEnum.CustomerStatement ||
          (moduleId$ | async) === moduleEnum.SalesInvoiceList ||
          (moduleId$ | async) === moduleEnum.IncomeByCustomerSummary ||
          (moduleId$ | async) === moduleEnum.CustomerBalanceSummary ||
          (moduleId$ | async) === moduleEnum.DepositDetails ||
          (moduleId$ | async) === moduleEnum.QuotationByCustomer ||
          (moduleId$ | async) === moduleEnum.InvoiceAndReceivedPayments ||
          (moduleId$ | async) === moduleEnum.SalesByProductDetail ||
          (moduleId$ | async) === moduleEnum.SalesByProductSummary ||
          (moduleId$ | async) === moduleEnum.SupplierAdvanceReport ||
          (moduleId$ | async) === moduleEnum.PurchasesReport ||
          (moduleId$ | async) === moduleEnum.SupplierList ||
          (moduleId$ | async) === moduleEnum.SupplierAgeingList ||
          (moduleId$ | async) === moduleEnum.TransactionsBySupplier ||
          (moduleId$ | async) === moduleEnum.PaymentToSupplier ||
          (moduleId$ | async) === moduleEnum.Creditors ||
          (moduleId$ | async) === moduleEnum.SupplierStatement ||
          (moduleId$ | async) === moduleEnum.PurchasesInvoiceList ||
          (moduleId$ | async) === moduleEnum.ExpensesBySupplierSummary ||
          (moduleId$ | async) === moduleEnum.SupplierBalanceSummary ||
          (moduleId$ | async) === moduleEnum.ChequeDetails ||
          (moduleId$ | async) === moduleEnum.PurchaseAndAppliedPayments ||
          (moduleId$ | async) === moduleEnum.VAT ||
          (moduleId$ | async) === moduleEnum.ComparativeReport ||
          (moduleId$ | async) === moduleEnum.SubcontractorYTDDetails ||
          (moduleId$ | async) === moduleEnum.CashFlow
        "
        mat-menu-item
        (click)="SendMail(moduleId)"
        disableRipple
        [disabled]="isViewPermission$ | async"
        [ngClass]="(isViewPermission$ | async) ? 'cursor-not-allowed' : ''"
      >
        <mat-icon>mail</mat-icon>Send Mail
      </button>

      <button
        mat-menu-item
        (click)="export(exportType.PDF)"
        disableRipple
        [disabled]="isViewPermission$ | async"
        [ngClass]="(isViewPermission$ | async) ? 'cursor-not-allowed' : ''"
      >
        <mat-icon fontSet="material-icons-outlined">picture_as_pdf</mat-icon
        >Export As PDF
      </button>

      <button
        *ngIf="
          (moduleId$ | async) === moduleEnum.Debtors ||
          (moduleId$ | async) === moduleEnum.Creditors
        "
        mat-menu-item
        (click)="export(exportType.ExportAsPDFOutstandingBal)"
        disableRipple
        [disabled]="isViewPermission$ | async"
        [ngClass]="(isViewPermission$ | async) ? 'cursor-not-allowed' : ''"
      >
        <mat-icon fontSet="material-icons-outlined">picture_as_pdf</mat-icon>
        Export As PDF(OutStanding Bal.)
      </button>

      <button
        mat-menu-item
        (click)="export(exportType.Excel)"
        *ngIf="(moduleId$ | async) !== moduleEnum.VAT"
        disableRipple
        [disabled]="isViewPermission$ | async"
        [ngClass]="(isViewPermission$ | async) ? 'cursor-not-allowed' : ''"
      >
        <mat-icon>border_all</mat-icon>Export to Excel
      </button>

      <button
        *ngIf="
          (moduleId$ | async) === moduleEnum.Debtors ||
          (moduleId$ | async) === moduleEnum.Creditors
        "
        mat-menu-item
        (click)="export(exportType.ExportAsExcelOutstandingBal)"
        disableRipple
        [disabled]="isViewPermission$ | async"
        [ngClass]="(isViewPermission$ | async) ? 'cursor-not-allowed' : ''"
      >
        <mat-icon>border_all</mat-icon>
        Export As Excel(OutStanding Bal.)
      </button>

      <button
        *ngIf="
          (moduleId$ | async) !== moduleEnum.TrialBalance &&
          (moduleId$ | async) !== moduleEnum.ProfitAndLoss &&
          (moduleId$ | async) !== moduleEnum.ProfitAndLossComparision &&
          (moduleId$ | async) !== moduleEnum.BalanceSheet &&
          (moduleId$ | async) !== moduleEnum.BankDashboard &&
          (moduleId$ | async) !== moduleEnum.CashFlow &&
          (moduleId$ | async) !== moduleEnum.VAT
        "
        mat-menu-item
        (click)="export(exportType.CSV)"
        disableRipple
        [disabled]="isViewPermission$ | async"
        [ngClass]="(isViewPermission$ | async) ? 'cursor-not-allowed' : ''"
      >
        <mat-icon fontSet="material-icons-outlined">description</mat-icon>Export
        to CSV
      </button>

      <button
        *ngIf="
          (moduleId$ | async) === moduleEnum.Debtors ||
          (moduleId$ | async) === moduleEnum.Creditors
        "
        mat-menu-item
        (click)="export(exportType.ExportAsCSVOutstandingBal)"
        disableRipple
        [disabled]="isViewPermission$ | async"
        [ngClass]="(isViewPermission$ | async) ? 'cursor-not-allowed' : ''"
      >
        <mat-icon fontSet="material-icons-outlined">description</mat-icon>
        Export As CSV(OutStanding Bal.)
      </button>

      <button
        *ngIf="
          (moduleId$ | async) === moduleEnum.BankDashboard && !isForeignBank
        "
        mat-menu-item
        (click)="bankEntryClick(true)"
        disableRipple
        [disabled]="(isViewPermission$ | async) || !isJournalChangePermission"
        [ngClass]="
          (isViewPermission$ | async) || !isJournalChangePermission
            ? 'cursor-not-allowed'
            : ''
        "
      >
        <mat-icon fontSet="material-icons-outlined">account_balance</mat-icon
        >Quick Journal
      </button>
      <button
        *ngIf="(moduleId$ | async) === moduleEnum.BankDashboard"
        mat-menu-item
        (click)="manualImportClick()"
        disableRipple
        [disabled]="(isViewPermission$ | async) || !isImportChangePermission"
        [ngClass]="
          (isViewPermission$ | async) || !isImportChangePermission
            ? 'cursor-not-allowed'
            : ''
        "
      >
        <mat-icon fontSet="material-icons-outlined">save_alt</mat-icon>Manual
        Import
      </button>

      <button
        *ngIf="
          (moduleId$ | async) === moduleEnum.BankDashboard && !isForeignBank
        "
        mat-menu-item
        (click)="bankEntryClick(false)"
        disableRipple
        [disabled]="isViewPermission$ | async"
        [ngClass]="(isViewPermission$ | async) ? 'cursor-not-allowed' : ''"
      >
        <mat-icon fontSet="material-icons-outlined">view_list</mat-icon>Cash
        Coding
      </button>
    </mat-menu>
  </div>
</div>
