<div class="content-body">
  <form [formGroup]="dividendsForm">
    <div class="d-flex gap-40">
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Shareholder <span class="text-danger-300">*</span></p>
          <span>
            <button mat-button disableRipple>
              <mat-icon (click)="gotoAddShareholder()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button mat-button disableRipple [disabled]="disabledEditButton">
              <mat-icon
                (click)="gotoEditShareholder()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #shareholder
          [disableOptionCentering]="true"
          (focus)="shareholder.open()"
          (valueChange)="shareholder.close()"
          (change)="
            getShareholderDetailView($event); this.disabledEditButton = false
          "
          formControlName="shareHolder"
          panelClass="mat-select-position"
          required
        >
          <ng-option *ngFor="let option of shareHolderList" [value]="option.id">
            <div title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>No. of Shares</p>
        <mat-form-field>
          <input matInput formControlName="noOfShares" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Ref. No. <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input matInput formControlName="refNumber" autocomplete="off" />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Dividend per share</p>
        <mat-form-field>
          <input
            matInput
            (change)="onDividendPerShareChanges()"
            formControlName="dividendPerShare"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Share Type <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input matInput formControlName="shareType" autocomplete="off" />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Dividend Payable</p>
        <mat-form-field>
          <input
            matInput
            formControlName="dividendPayable"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40 align-items-center">
      <div class="form-field">
        <p>Share Class Type <span class="text-danger-300">*</span></p>
        <ng-select
          #shareClassType
          [disableOptionCentering]="true"
          (focus)="shareClassType.open()"
          (valueChange)="shareClassType.close()"
          formControlName="shareClassType"
          panelClass="mat-select-position"
          required
        >
          <ng-option
            *ngFor="let option of shareClassTypeList"
            [value]="option.id"
          >
            <div title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field m-0 d-flex flex-column justify-content-center">
        <div>
          <mat-checkbox
            formControlName="isTaxCredit"
            (change)="onTaxCreditChange()"
          >
            Do you wish to exclude Tax Credit?
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div class="d-flex gap-40">
      <div class="form-field datepicker">
        <p>Year End Date <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="yearEndDate"
            [matDatepicker]="yearEndDate"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            tabindex="-1"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="yearEndDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #yearEndDate></mat-datepicker>
        </mat-form-field>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(dividendsForm.get('yearEndDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(dividendsForm.get('yearEndDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
      <div class="form-field">
        <p>Tax Credit</p>
        <mat-form-field>
          <input matInput formControlName="taxCredit" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex gap-40">
      <div class="form-field datepicker">
        <p>Declaration Date <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="declarationDate"
            [matDatepicker]="declarationDate"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            tabindex="-1"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="declarationDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #declarationDate></mat-datepicker>
        </mat-form-field>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              dividendsForm.get('declarationDate')
            ).invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              dividendsForm.get('declarationDate')
            ).lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
      <div class="form-field">
        <p>Gross Dividend</p>
        <mat-form-field>
          <input matInput formControlName="grossDividend" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex gap-40 align-items-center">
      <div class="form-field datepicker">
        <p>Payment Date <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="paymentDate"
            [matDatepicker]="paymentDate"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            tabindex="-1"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="paymentDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #paymentDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div
        class="form-field checkbox mb-0"
        [ngClass]="[hidePostJournalCheckBox ? 'hidden' : '']"
      >
        <mat-checkbox formControlName="isPostJournal">
          Post Journal
        </mat-checkbox>
      </div>
    </div>
  </form>
</div>
