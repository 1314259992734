import { FixedAssetsModel, OverviewFilterModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class CreateFixedAssets {
  static readonly type = '[FIXEDASSETS] Create Fixed Assets';

  constructor(public fixedAssets: FixedAssetsModel) {}
}

export class GetDataByFixedAssetsId {
  static readonly type =
    '[FIXEDASSETS] Get Fixed Assets Data By Fixed Assets Id';

  constructor(public fixedAssetsId: Guid) {}
}

export class ArchiveAndRestoreFixedAssets {
  static readonly type =
    '[FIXEDASSETS] Archive and Restore Selected Fixed Assets';

  constructor(public fixedAssetsIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteFixedAssets {
  static readonly type = '[FIXEDASSETS] Delete Selected Fixed Assets';

  constructor(public fixedAssetsIds: Array<Guid>) {}
}

export class GetFixAssestAgeingSummaryOverview {
  static readonly type = '[FIXEDASSETS] Get Value By Assets Type Overview';
  constructor(public filterData: any) {}
}

export class GetFixAssestProductServiceListOverview {
  static readonly type =
    '[FIXEDASSETS] Get ProductList By Assets Type Overview';
  constructor(public filterData: any) {}
}

export class GetFixAssestValueByAssetTypeOverview {
  static readonly type =
    '[FIXEDASSETS] Get Assets Value By Asset Type Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetAllGroupByFixAssestAgeingSummary {
  static readonly type = '[ACCOUNT] Get All Group';
  constructor(public accountTypeId: number) {}
}

export class CheckDepreciation {
  static readonly type = '[FIXEDASSET] Check Depreciation';

  constructor(public id: Guid) {}
}
