import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  AccountEntity,
  ChartOfAccountCostOfSalesGroupTypeName,
  GroupNames,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import {
  ChartOfAccountListParam,
  Currency,
  GlobalComponent,
  GroupListModel,
  SideListModel,
} from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import {
  CommonState,
  CreateAccount,
  CurrencyState,
  GetAllBranchList,
  GetAllDepartmentListByBranch,
  GetClientCurrencyList,
  GetCurrencyList,
  GetDataByAccountId,
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetNonStandardAccountList,
  GetNonStandardAccountListByBranch,
  GetRefreshCurrencyData,
} from '@app/core/Store';
import { QuickAddComponent } from '@app/modules';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-debit-note',
  templateUrl: './add-debit-note.component.html',
  styleUrls: ['./add-debit-note.component.scss'],
})
export class AddDebitNoteComponent implements OnInit {
  debitNoteForm: FormGroup;
  customerList: SideListModel[];
  currencyList: Currency[];
  supplierList: SideListModel[];
  debitNoteTypeList: GroupListModel[];
  tempDebitNoteTypeList: GroupListModel[];

  @Input() triggereDebitNoteEditData: Observable<any>;

  @Input() isFromBankImport: boolean;
  @Input() triggerTransactionLogData: Observable<any>;

  @Output()
  readonly triggerSupplierChange = new EventEmitter<any>();
  @Output()
  readonly branchChange = new EventEmitter<any>();
  @Output()
  readonly isVatIncludedClick = new EventEmitter<any>();

  @Output() triggerCurrencySelectedOption: EventEmitter<number> =
    new EventEmitter<number>();

  @Output() triggerCurrencyRefreshOption: EventEmitter<number> =
    new EventEmitter<number>();

  @Output() triggerSelectedCurrencyRate: EventEmitter<number> =
    new EventEmitter<number>();

  private destroy$ = new Subject<void>();

  defaultCurrency: Guid;
  periodicDate: any;
  isVatAmountIncluded = false;
  departmentList: any[] = [];
  branchList: any[] = [];
  isVatRegistered: any;

  disabledEditButton = true;
  isAddMode = true;
  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;
  clientCurrencyList: any = [];
  isMultiCurrencySelected = false;
  currencyIds: any = [];
  selectedCurrencyId: number;
  refreshedCurrencyData: any = [];
  refreshedCurrencyRate: number;
  selectedCurrencySymbol: string;
  selectedCurrencyRate: number;
  isSupplierChangePermission: boolean = true;
  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    public dialog: MatDialog,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.selectedCurrencyRate = 1;
    this.selectedCurrencyId = 123;
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    this.setForm();
    this.getCurrencyList();
    this.getDebitNoteType();
    this.getBranchList(true);

    this.triggereDebitNoteEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isAddMode = false;
        setTimeout(() => {
          this.editDebitNote(data);
        }, 1500);
      });

    this.triggerTransactionLogData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.debitNoteForm.controls.currency.setValue(data.currencyId);
        this.debitNoteForm.controls.currency.disable();
        this.selectedCurrencyRate = this.clientCurrencyList.find(
          (x) => x.currencyId === data.currencyId
        )?.rate;
        if (data.currencyId === 123) {
          this.debitNoteForm.controls.currencyAmt.setValue(1);
        } else {
          this.isMultiCurrencySelected = true;
          this.debitNoteForm.controls.currencyAmt.setValue(
            this.selectedCurrencyRate
          );
        }
      });

    this.debitNoteForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.debitNoteForm.touched;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isSupplierChangePermission = this.commonService.checkPermission(
        Modules.Contacts,
        Modules.Suppliers
      );
    }, 3000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDebitNoteType(): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.CostOfSales);
    const typeNames = new Array<number>();
    typeNames.push(ChartOfAccountCostOfSalesGroupTypeName.Purchases);
    typeNames.push(ChartOfAccountCostOfSalesGroupTypeName.OtherDirectCosts);

    const param: ChartOfAccountListParam = {
      groupIds: groupNames,
      typeIds: typeNames,
    };

    this.store
      .dispatch(new GetGroupAccountsBasedOnGroupIdAndTypeId(param))
      .pipe(
        tap((res) => {
          this.debitNoteTypeList = res.common.accountGroupList;
          this.tempDebitNoteTypeList = this.debitNoteTypeList;
        })
      )
      .subscribe();
  }

  gotoAddSupplier(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Suppliers,
          headerText: `Add ${ModuleName.Suppliers}`,
          saveActionName: CreateAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.getSupplier(id);
        }
      });
  }

  getOptionText(option) {
    return option.name;
  }

  onSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempDebitNoteTypeList
    );
    if (list.length === 0) {
      this.debitNoteForm.controls.debitNoteType.setValue('');
      this.debitNoteTypeList = this.tempDebitNoteTypeList;
    }
  }

  resetAccountList(): void {
    this.debitNoteTypeList = this.tempDebitNoteTypeList;
    this.scrollIntoView(this.debitNoteForm.controls.debitNoteType.value);
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempDebitNoteTypeList
    );
    this.debitNoteTypeList = list;
  }

  gotoEditSupplier(): void {
    if (this.supplierList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.Suppliers,
            headerText: `Edit ${ModuleName.Suppliers}`,
            id: this.debitNoteForm.controls.accountId.value,
            saveActionName: CreateAccount,
            getActionName: GetDataByAccountId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getSupplier(id);
          }
        });
    }
  }

  editDebitNote(data): void {
    // let account;
    // this.debitNoteTypeList.forEach((items) => {
    //   const accountId = items.listModels.find(
    //     (x: any) => x.id === data.bookAccountId
    //   );
    //   if (accountId !== null && accountId !== undefined) {
    //     account = accountId;
    //   }
    // });
    this.disabledEditButton = false;
    this.debitNoteForm.controls.debitNoteNo.disable();
    const x = this.supplierList.filter((x) => x.id === data.accountId);
    this.debitNoteForm.patchValue({
      accountId: x.length > 0 ? data.accountId : '',
      debitNoteNo: data.entryNumber,
      postingAccountId: data.postingAccountId,
      billRefNo: data.voucherNumber,
      entryDate: data.entryDate,
      currency: data.currencyId,
      note: data.note,
      //debitNoteType: account,
      isVatInclude: data.isVatIncluded,
      branchId: data.branchId,
      departmentId: data.departmentId,
      currencyAmt: data.currencyRate,
    });
    if (data.currencyId != 123) {
      this.triggerSelectedCurrencyRate.emit(data.currencyRate);
      this.isMultiCurrencySelected = true;
      this.selectedCurrencyRate = this.clientCurrencyList.find(
        (x) => x.currencyId === data.currencyId
      )?.rate;
      this.selectedCurrencyId = this.clientCurrencyList.find(
        (x) => x.currencyId === data.currencyId
      )?.currencyId;
      this.selectedCurrencySymbol = this.clientCurrencyList.find(
        (x) => x.currencyId === data.currencyId
      )?.symbol;
    } else {
      this.isMultiCurrencySelected = false;
      this.selectedCurrencyRate = 1;
      this.selectedCurrencySymbol = '£';
    }
    if (data.isVatIncluded) {
      this.isVatIncludedClick.emit(data.isVatIncluded);
    }
    this.onSupplierChange();
    if (data.branchId !== null && data.branchId !== undefined) {
      this.getDepartmentListByBranch(data.branchId);
    }
  }

  getSelectedData(item: any[]): void {
    this.debitNoteForm.controls.referenceId.setValue(item);
  }

  setForm(): void {
    this.debitNoteForm = new FormGroup({
      accountId: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      debitNoteNo: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      billRefNo: new FormControl(''),
      debitNoteDate: new FormControl(),
      entryDate: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      note: new FormControl(''),
      currency: new FormControl('', [Validators.required]),
      debitNoteType: new FormControl(''),
      amountReceived: new FormControl(),
      isVatInclude: new FormControl(false),
      branchId: new FormControl(''),
      departmentId: new FormControl(''),
      currencyAmt: new FormControl(''),
    });

    this.getCurrency();
  }

  getCurrency(): void {
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          this.debitNoteForm.controls.currency.setValue(this.defaultCurrency);
        })
      )
      .subscribe();
  }

  getSupplier(id?: Guid): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);

    let actionName;
    if (this.branchList.length > 0) {
      actionName = GetNonStandardAccountListByBranch;
    } else {
      actionName = GetNonStandardAccountList;
    }

    this.store
      .dispatch(
        new actionName(entityId, this.debitNoteForm.controls.branchId.value)
      )

      .pipe(
        tap((res) => {
          this.supplierList = res.common.accountList;

          if (id !== null && id !== undefined) {
            this.disabledEditButton = false;
            this.debitNoteForm.controls.accountId.setValue(id);
          }

          this.onSupplierChange();
        })
      )
      .subscribe();
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  onSupplierChange(): void {
    this.triggerSupplierChange.emit();
  }

  onCheckBoxSelected(event: any): void {
    this.isVatAmountIncluded = event.checked;
    this.isVatIncludedClick.emit(this.isVatAmountIncluded);
  }
  getBranchList(isOnLoad): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.debitNoteForm.controls.branchId.setValue(
              this.branchList[0].id
            );
            this.debitNoteForm.controls.branchId.setValidators(
              Validators.required
            );
            this.debitNoteForm.controls.branchId.updateValueAndValidity();
            this.onChangeBranch(this.branchList[0].id, isOnLoad);
          } else {
            this.branchList = [];
            this.departmentList = [];
            this.getSupplier();
          }
        })
      )
      .subscribe();
  }

  onChangeBranch(branch, isOnLoad): void {
    this.getDepartmentListByBranch(branch);
    this.getSupplier();
    if (!isOnLoad) {
      this.debitNoteForm.controls.accountId.setValue('');
      this.branchChange.emit(branch);
    }
  }

  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .pipe(
          tap((res) => {
            this.departmentList = [];
            if (res.company.department.length > 0) {
              this.departmentList = res.company.department;
              this.debitNoteForm.controls.departmentId.setValue(
                this.departmentList[0].id
              );
              this.debitNoteForm.controls.departmentId.setValidators(
                Validators.required
              );
              this.debitNoteForm.controls.departmentId.updateValueAndValidity();
            } else {
              this.debitNoteForm.controls.departmentId.clearValidators();
              this.debitNoteForm.controls.departmentId.updateValueAndValidity();
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
      this.debitNoteForm.controls.departmentId.clearValidators();
      this.debitNoteForm.controls.departmentId.updateValueAndValidity();
    }
  }
  getCurrencyList(): void {
    this.store
      .dispatch(new GetClientCurrencyList())
      .pipe(
        tap(() => {
          this.clientCurrencyList = this.store.selectSnapshot(
            CurrencyState.getCurrencyList
          );
          if (this.clientCurrencyList.length > 0) {
            this.debitNoteForm.controls.currency.setValue(
              this.clientCurrencyList[0].currencyId
            );
            this.debitNoteForm.controls.currencyAmt.setValue(1);
          }
        })
      )
      .subscribe();
  }
  currencyChange(value: any): void {
    this.selectedCurrencyRate = this.clientCurrencyList.find(
      (x) => x.currencyId === value
    )?.rate;
    this.selectedCurrencyId = this.clientCurrencyList.find(
      (x) => x.currencyId === value
    )?.currencyId;
    this.selectedCurrencySymbol = this.clientCurrencyList.find(
      (x) => x.currencyId === value
    )?.symbol;
    this.triggerCurrencySelectedOption.emit(this.selectedCurrencyRate);
    if (value === 123) {
      this.isMultiCurrencySelected = false;
      this.debitNoteForm.controls.currencyAmt.setValue(1);
    } else {
      this.debitNoteForm.controls.currencyAmt.setValue(
        this.selectedCurrencyRate
      );
      this.isMultiCurrencySelected = true;
    }
    this.triggerSupplierChange.emit();
  }
  onCurrencyRefresh(): void {
    this.currencyIds = [];
    this.currencyIds.push(this.selectedCurrencyId);

    this.store
      .dispatch(new GetRefreshCurrencyData(this.currencyIds))
      .subscribe((res) => {
        this.currencyIds = [];
        this.refreshedCurrencyData = res.currency.currencyList.data;

        this.refreshedCurrencyData.forEach((x) => {
          this.refreshedCurrencyRate = x.rate;
        });
        this.debitNoteForm.controls.currencyAmt.setValue(
          this.refreshedCurrencyRate
        );
        this.selectedCurrencyRate = this.refreshedCurrencyRate;
        this.triggerCurrencyRefreshOption.emit(this.selectedCurrencyRate);
      });
  }
  onCurrencyRateEntered() {
    this.selectedCurrencyRate = this.debitNoteForm.controls.currencyAmt.value;
    this.triggerCurrencyRefreshOption.emit(this.selectedCurrencyRate);
  }
}
