import { PaymentDetailsModel, ProductDetailsModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class BillModel {
  id?: Guid;
  entryNumber?: string;
  entryDate?: string;
  dueDate?: string;
  currencyId: number;
  accountId: Guid;
  note: string;
  bookAccountId?: Guid;
  items: Array<ProductDetailsModel>;
  receiptDetails: Array<PaymentDetailsModel>;
  attachment?: Array<string>;
  transactionLogId?: Guid;
  isVatIncluded: boolean;
  isVatPostponed: boolean;
  advanceReceiptAmount?: number;
  isManualBank?: boolean;
  branchId?: Guid | null;
  departmentId?: Guid | null;
  currencyRate: number;
  discount: number;
  isDiscountPercentage: boolean;
  foreignDiscount: number;
}
