import { Guid } from 'guid-typescript';
export class ReceiptModel {
  id?: Guid;
  code?: string;
  receiptDate?: string;
  note: string;
  transactionTypeId: number;
  accountId: Guid;
  postingAccountId: Guid;
  isCredit?: boolean;
  invoiceTypeId?: Guid;
  currencyId: number;
  attachment: Array<string>;
  receiptItems: Array<any>;
  totalAmount: number;
  moduleId?: number;
  isAdvance: boolean;
  transactionLogId?: Guid;
  isManualBank?: boolean;
  branchId?: Guid | null;
  departmentId?: Guid | null;
  currencyRate?: number;
  foreignTotalAmount?: number;
}
