<div class="mb-4">
  <form [formGroup]="hmrcGatewayDetailsForm">
    <h2>HMRC Login Details</h2>
    <div class="d-flex gap-40">
      <div class="form-field wp-100">
        <div class="form-field">
          <p>Username</p>
          <mat-form-field>
            <input
              matInput
              formControlName="userName"
              autocomplete="off"
              required
              type="text"
            />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Password</p>
          <mat-form-field>
            <input
              matInput
              formControlName="password"
              autocomplete="off"
              required
              type="password"
            />
          </mat-form-field>
        </div>
        <div class="form-field d-flex align-items-center">
          <mat-checkbox
            formControlName="isAgent"
            (change)="onCheckBoxChanges($event)"
            [(ngModel)]="isAgent"
          ></mat-checkbox>
          <span>I am an Agent</span>
        </div>
      </div>
    </div>
  </form>
</div>
