import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import { NotificationTextMessage } from '@app/core/Enum';
import {
  FlatRateAdjusmentDataModel,
  FlatRateAdjustmentJournalModel,
  GlobalComponent,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetFlatRateAdjustmentCalculation,
  VATFlatRatePosting,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-flat-rate-adjustment',
  templateUrl: './flat-rate-adjustment.component.html',
  styleUrls: ['./flat-rate-adjustment.component.scss'],
})
export class FlatRateAdjustmentComponent implements OnInit {
  flatRateForm: FormGroup;

  periodicDate: any;
  flatRateData: FlatRateAdjusmentDataModel;
  notificationMessage = NotificationTextMessage;
  vatId: any;
  commonService: CommonService;
  spinner: NgxSpinnerService;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FlatRateAdjustmentComponent>,
    private renderer: Renderer2,
    private store: Store,
    public datepipe: DatePipe,

    private globalComponent: GlobalComponent,
    private injector: Injector
  ) {
    this.commonService = injector.get<CommonService>(CommonService);

    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
  }

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.vatId = this.data.id;
    this.setForm();
    this.getFlatRateAdjustmentCalculationData();
  }

  setForm(): void {
    this.flatRateForm = new FormGroup({
      date: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      reference: new FormControl(''),
      savingsAmount: new FormControl({ value: 0, disabled: true }),
      purchaseAmount: new FormControl({ value: 0, disabled: true }),
      adjustmentAmount: new FormControl({ value: 0, disabled: true }),
    });
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.flatRateForm.invalid) {
      this.commonService.addValidation(this.flatRateForm, this.renderer);
    } else {
      this.store
        .dispatch(new VATFlatRatePosting(this.dataSubmit()))
        .pipe()
        .subscribe(
          (res) => {
            if (res !== undefined) {
              this.onCloseClick();
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.message);
          }
        );
    }
  }

  dataSubmit(): any {
    let data: FlatRateAdjustmentJournalModel = {
      id: this.vatId,
      date: this.datepipe
        .transform(this.flatRateForm.controls.date.value, 'yyyy-MM-dd')
        ?.toString(),
      reference: this.flatRateForm.controls.reference.value,
      vatAmount: this.flatRateForm.controls.adjustmentAmount.value,
      purchaseAmount: this.flatRateForm.controls.purchaseAmount.value,
      savingAmount: this.flatRateForm.controls.savingsAmount.value,
    };

    return data;
  }

  getFlatRateAdjustmentCalculationData(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetFlatRateAdjustmentCalculation(this.vatId))
      .subscribe((res) => {
        this.spinner.hide();
        this.flatRateData = res.submitVat.flatRateAdjustmentData;
        this.flatRateForm.patchValue({
          date: this.flatRateData.toDate,
          savingsAmount: this.flatRateData.flatRateSavings,
          purchaseAmount: this.flatRateData.vATonPurchases,
          adjustmentAmount: this.flatRateData.finalAdjustmentAmount,
        });
      });
  }
}
