export enum AccountNumber {
  ACCOUNT_1014 = '1014 - EU Sales Goods',
  ACCOUNT_1016 = '1016 - EU Sales Services',
  ACCOUNT_1273 = '1273 - Import Purchase',
  ACCOUNT_1274 = '1274 - Purchase of Goods-EU',
  ACCOUNT_1275 = '1275 - Purches of Services-EU',
  ACCOUNT_8215 = '8215 - VAT',
  ACCOUNT_8214 = '8214 - CIS control account',
  ACCOUNT_8239 = '8239 - Advance Paid',
  ACCOUNT_8035 = '8035 - Advance Received',
  ACCOUNT_8110 = '8110 - Trade debtors',
  ACCOUNT_8310 = '8310 - Trade Creditors',
}
