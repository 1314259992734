import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Modules } from '@app/core/Enum';
import { OverviewModel } from '@app/core/Models';
import { GetDashboardIncomeByCategoriesOverview } from '@app/core/Store';
import { Store } from '@ngxs/store';

import {
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexLegend,
  ApexNoData,
  ApexPlotOptions,
  ApexResponsive,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

export type ChartOptionv1 = {
  noData: ApexNoData;
  series: any;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  lines: ApexGrid;
};

@Component({
  selector: 'app-overview-incomes-chart-v1',
  templateUrl: './overview-incomes-chart-v1.component.html',
  styleUrls: ['./overview-incomes-chart-v1.component.scss'],
})
export class OverviewIncomesChartComponentv1 implements OnInit {
  @ViewChild('chart') chart: ChartComponent;

  public chartOptionv1: Partial<ChartOptionv1>;
  reportList: OverviewModel;

  @Input()
  triggerHeaderFilter: Observable<any>;
  @Input() getModuleId: number;

  isNoRecord = true;
  isloadData = false;
  constructor(private store: Store, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.triggerHeaderFilter?.subscribe((filterData) => {
      if (this.getModuleId === Modules.Dashboard) {
        this.getIncomeByCategoriesData(filterData);
      }
    });
  }

  setSeriesData(data: any): any {
    let series: any[] = [];
    let count = 0;

    data.forEach((element) => {
      series.push(element.data);
      if (element.data === 0) {
        count = count + 1;
      }
    });

    this.isNoRecord = count === data.length ? true : false;
    return series;
  }

  setCategoryData(data: any): any {
    let categories: any[] = [];
    data.forEach((element) => {
      let param: any;
      param = element.name.split(' ');
      categories.push(param);
    });
    return categories;
  }

  getIncomeByCategoriesData(filterData: any): void {
    this.spinner.show();
    this.store
      .dispatch(new GetDashboardIncomeByCategoriesOverview(filterData))
      .subscribe((res) => {
        this.isloadData = true;
        let overViewData = res.dashboard.dashboardIncomeByCategoriesOverview;
        let seriesData: any[] = [];

        this.isNoRecord = overViewData.series.length === 0;

        if (overViewData.series.length > 0) {
          seriesData = this.setSeriesData(overViewData.series);
        }

        let categories = this.setCategoryData(overViewData.categories);

        this.chartOptionv1 = {
          series: [
            {
              name: '',
              data: seriesData,
            },
          ],
          chart: {
            height: 300,
            type: 'bar',
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          noData: {
            text: 'No Data to Display',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: '#6d41a1',
              fontSize: '18px',
            },
          },
          grid: {
            show: true,
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            labels: {
              show: true,
              rotate: 0,
            },
            categories: categories,
          },
          yaxis: {
            labels: {
              formatter: (val) => {
                return '£ ' + Number(val) / 1000 + 'K';
              },
            },
          },
          legend: {
            show: false,
            offsetX: 0,
            offsetY: 8,
          },
          fill: {
            colors: [
              '#7B76D3',
              '#F4C64D',
              '#F2816F',
              '#BA5397',
              '#7A5295',
              '#B9CE64',
            ],
          },
          tooltip: {
            marker: {
              show: false,
            },
            style: {
              fontFamily: '"Source Sans Pro", sans-serif',
              fontSize: '14px',
            },
            y: {
              formatter: (val) => {
                return '£ ' + Number(val) * 1 + '';
              },
            },
            enabled: true,
            fillSeriesColor: false,
            theme: 'light',
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
          ],
        };
      });
  }
}
