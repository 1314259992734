import { PaymentDetailsModel, ProductDetailsModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class FixedAssetReturnModel {
  id: Guid;
  entryNumber: string;
  accountId: Guid;
  bookAccountId?: Guid;
  entryDate?: string;
  currencyId: number;
  note: string;
  voucherNumber: string;
  attachment: Array<string>;
  fixedAssetReturnItems: Array<ProductDetailsModel>;
  invoiceAllocations: Array<PaymentDetailsModel>;
  receiptDetails: Array<any>;
  transactionLogId?: Guid;
  isManualBank?: boolean;
  isVatIncluded: boolean;
  branchId?: Guid | null;
  departmentId?: Guid | null;
  currencyRate?: number;
}
