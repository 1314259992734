import { PaymentDetailsModel, ProductDetailsModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class FixedAssetsModel {
  id: Guid;
  accountId: Guid;
  bookAccountId?: Guid;
  referenceId?: Guid;
  entryNumber: string;
  entryDate?: string;
  dueDate?: string;
  currencyId: number;
  note: string;
  attachment: Array<string>;
  items: Array<ProductDetailsModel>;
  receiptDetails: Array<PaymentDetailsModel>;
  isVatPostponed: boolean;
  isVatIncluded: boolean;
  advanceReceiptAmount?: number;
  branchId?: Guid | null;
  departmentId?: Guid | null;
  currencyRate?: number;
  discount: number;
  isDiscountPercentage: boolean;
  foreignDiscount: number;
}
