<div class="d-flex h-100">
  <app-side-list
    [getModuleId]="moduleId"
    [getModuleName]="moduleName"
    (showSideListAction)="showSideListAction($event)"
    (toggleSideList)="toggleSideList($event)"
    [reloadSideList]="reloadSideList"
  ></app-side-list>

  <div
    class="form mb-4"
    [ngClass]="istoggleSideList ? 'hide-sidelist' : ''"
    [hidden]="isShowSideListAction"
  >
    <div class="content-body-wrapper">
      <div class="content-body">
        <p class="header-text d-flex justify-space-between align-items-center">
          <span>
            {{
              fixedAssetsId === commonService.defaultGuidValue
                ? "Add New Fixed Asset"
                : "Edit Fixed Asset"
            }}
          </span>
          <button
            mat-button
            class="header-text-close d-flex align-items-center"
            disableRipple
            (click)="onCloseClick()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </p>
        <div class="alert-message" *ngIf="isViewPermission$ | async">
          <mat-icon class="material-icons-outlined">error_outline</mat-icon>
          {{ commonNotificationText.ViewOnlyAccessMessage }}
        </div>
        <div>
          <div class="accordion-toggle">
            <mat-icon
              (click)="toggleAccordion(); accordion.openAll()"
              *ngIf="isExpandAll"
              >keyboard_double_arrow_down
            </mat-icon>
            <mat-icon
              (click)="toggleAccordion(); accordion.closeAll()"
              *ngIf="!isExpandAll"
              >keyboard_double_arrow_up
            </mat-icon>
          </div>
          <mat-accordion [multi]="true">
            <mat-expansion-panel
              [expanded]="true"
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >description</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Fixed Assets Details</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-add-fixed-assests
                [triggerEditData]="triggerEditData"
                (triggerOnVATChange)="onVATChange($event)"
                (triggerAccountSelectedOption)="onAccountSelectedOption($event)"
                (supplierClick)="supplierClickChange($event)"
                (isVatPostponedClick)="isVatPostponedClick($event)"
                (isVatIncludedClick)="isVatIncludedClick($event)"
                (branchChange)="onBranchChange($event)"
                (triggerCurrencySelectedOption)="
                  onCurrencySelectedOption($event)
                "
                (triggerCurrencyRefreshOption)="onCurrencyRefreshOption($event)"
                (triggerSelectedCurrencyRate)="onCurrencySelectedRate($event)"
              >
              </app-add-fixed-assests>
              <app-product-details
                [triggereEditProductData]="triggereEditProductData"
                [triggerOnVATChange]="triggerOnVATChange"
                [triggerAccountSelectedOption]="triggerAccountSelectedOption"
                [triggerisVatPostponed]="triggerisVatPostponed"
                [triggerisVatIncluded]="triggerisVatIncluded"
                [triggerOnBranchChange]="triggerBranchChange"
                [triggerCurrencySelectedOption]="triggerCurrencySelectedOption"
                [triggerCurrencyRefreshOption]="triggerCurrencyRefreshOption"
                [triggerSelectedCurrencyRate]="triggerSelectedCurrencyRate"
                (triggerNewProductAdded)="onAddedNewProductDetails($event)"
                [isFixedAsset]="true"
              >
              </app-product-details>
            </mat-expansion-panel>
            <mat-expansion-panel
              [ngClass]="[
                !hasAdvanceAmount ? 'hidden' : '',
                (isViewPermission$ | async) ? 'is-readonly' : ''
              ]"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >payment</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Advance Payment</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-advance-payment
                [triggerAdvancePayment]="triggerAdvancePayment"
                [triggereEditAdvancePaymentData]="triggerEditData"
                [totalReceiptAmount]="
                  this.productDetails.totalAmount -
                  this.receiptDetails.totalReciptAmount
                "
                (hasAdvanceAmountUpdated)="onHasAdvanceAmountUpdated($event)"
                [selectedCurrencyId]="
                  this.fixedAssetsDetails.selectedCurrencyId
                "
                [currencySymbol]="
                  this.fixedAssetsDetails.selectedCurrencySymbol
                "
              ></app-advance-payment>
            </mat-expansion-panel>
            <mat-expansion-panel
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >receipt</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Payment</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-transactions-payment
                [triggereEditPaymentData]="triggereEditPaymentData"
                [headerText]="headerText"
                [moduleId]="moduleId"
                [totalReceiptAmount]="
                  this.productDetails.totalAmount -
                  this.advancePaymentDetails.allocatedAdvanceAmount
                "
                [currencyRate]="this.fixedAssetsDetails.selectedCurrencyRate"
                [selectedCurrencyId]="
                  this.fixedAssetsDetails.selectedCurrencyId
                "
              ></app-transactions-payment>
            </mat-expansion-panel>
            <mat-expansion-panel
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >attachment</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Attachment</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-transactions-attachment [triggerEditData]="triggerEditData">
              </app-transactions-attachment>
            </mat-expansion-panel>
          </mat-accordion>
          <app-product-details-footer
            [balanceAmount]="
              this.productDetailsFooter.grossTotal -
              this.receiptDetails.totalReciptAmount -
              this.advancePaymentDetails.allocatedAdvanceAmount
            "
            [triggerNewProductAdded]="triggerNewProductAdded"
            [triggerSelectedCurrencyRate]="triggerSelectedCurrencyRate"
            [triggerCurrencySelectedOption]="triggerCurrencySelectedOption"
            [currencySymbol]="this.fixedAssetsDetails.selectedCurrencySymbol"
            [triggerDiscountData]="triggerDiscountData"
          ></app-product-details-footer>
        </div>
      </div>
    </div>
    <app-buttons
      *ngIf="!(isViewPermission$ | async)"
      [getModuleId]="moduleId"
      (triggerOnSaveClick)="onSave($event)"
      (triggerOnCancelClick)="onCancel(true)"
    ></app-buttons>
  </div>
</div>
