<div class="content-body">
  <form [formGroup]="productForm">
    <div class="d-flex gap-40">
      <div class="form-field fund-name" *ngIf="branchList.length > 0">
        <p>Branch <span class="text-danger-300">*</span></p>
        <ng-select
          #branch
          (focus)="branch.open()"
          (valueChange)="branch.close()"
          [disableOptionCentering]="true"
          formControlName="branchId"
          dropdownPosition="bottom"
          (change)="onChangeBranch($event)"
        >
          <ng-option *ngFor="let bl of branchList" [value]="bl.id">
            <div title="{{ bl.name }}">{{ bl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="departmentList.length > 0">
        <p>Department <span class="text-danger-300">*</span></p>
        <ng-select
          #department
          (focus)="department.open()"
          (valueChange)="department.close()"
          [disableOptionCentering]="true"
          formControlName="departmentId"
          dropdownPosition="bottom"
        >
          <ng-option *ngFor="let dl of departmentList" [value]="dl.id">
            <div title="{{ dl.name }}">{{ dl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Product Name <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="productName"
            autocomplete="off"
            required
            maxlength="{{ maxLength.maxLength100 }}"
            type="text"
            appAutoFocusDirective
            unicodeCharacter
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Code</p>
        <mat-form-field>
          <input
            matInput
            formControlName="productCode"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field" *ngIf="isVatRegistered">
        <p>Vat Rate <span class="text-danger-300">*</span></p>
        <ng-select
          #vatRate
          [disableOptionCentering]="true"
          (focus)="vatRate.open()"
          (valueChange)="vatRate.close()"
          formControlName="vatRate"
          panelClass="mat-select-position"
          required
        >
          <ng-option *ngFor="let option of vatRateList" [value]="option.id">
            <div class="clamp" title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>Price <span class="text-danger-300">*</span></p>

        <mat-form-field>
          <input
            matInput
            zeroNotAllowed
            formControlName="productPrice"
            autocomplete="off"
            allow_10_2_Decimal
            required
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Opening Balance Quantity</p>
        <mat-form-field>
          <input
            matInput
            formControlName="openingBalanceQuantity"
            autocomplete="off"
            allowDecimal
            maxlength="{{ maxLength.maxLength13 }}"
            (change)="calAmount()"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Opening Balance Price</p>
        <mat-form-field>
          <input
            matInput
            formControlName="openingBalancePrice"
            autocomplete="off"
            allow_10_2_Decimal
            (change)="calAmount()"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field w-270">
        <p>Amount</p>
        <mat-form-field>
          <input
            matInput
            formControlName="productAmount"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            allowDecimal
          />
        </mat-form-field>
      </div>
      <div
        class="form-field m-0 d-flex flex-column justify-content-center"
        *ngIf="!isFromFixedAssets"
      >
        <div>
          <mat-checkbox formControlName="trackProductInInvetory">
            Track Product In Inventory Register
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="form-field textarea">
      <p>Description</p>
      <mat-form-field>
        <textarea
          matInput
          formControlName="productDescription"
          autocomplete="off"
          maxlength="{{ maxLength.maxLength200 }}"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
