import { Injectable } from '@angular/core';
import { PermissionService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { Guid } from 'guid-typescript';
import {
  CreateUser,
  GetPermissionDataByUserId,
  GetRole,
  GetUser,
  SaveUserModulePermission,
} from './permission.action';

export class PermissionStateInfo {
  isUserAdded?: boolean;
  userId?: Guid;
  userData?: any;
  roleList?: any;
  permissionData?: any;
  isUserPermissionAdded: boolean;
}

@State<PermissionStateInfo>({
  name: 'permission',
  defaults: {
    isUserPermissionAdded: false,
  },
})
@Injectable()
export class PermissionState {
  constructor(private permissionService: PermissionService) {}

  @Selector()
  static getRoleList(state: PermissionStateInfo) {
    return state.roleList;
  }

  @Action(CreateUser)
  createUser(
    { patchState }: StateContext<PermissionStateInfo>,
    action: CreateUser
  ) {
    return this.permissionService.createUser(action.user).pipe(
      tap((res) => {
        patchState({
          userId: res,
          isUserAdded: true,
        });
      })
    );
  }

  @Action(GetUser)
  getUser(
    { getState, patchState }: StateContext<PermissionStateInfo>,
    action: GetUser
  ) {
    return this.permissionService.getUser().pipe(
      tap((res) => {
        patchState({
          userData: res,
        });
      })
    );
  }

  @Action(GetRole)
  getRole(
    { getState, patchState }: StateContext<PermissionStateInfo>,
    action: GetRole
  ) {
    return this.permissionService.getRole().pipe(
      tap((res) => {
        patchState({
          roleList: res,
        });
      })
    );
  }

  @Action(GetPermissionDataByUserId)
  getPermissionDataByUserId(
    { patchState }: StateContext<PermissionStateInfo>,
    action: GetPermissionDataByUserId
  ) {
    return this.permissionService.getPermissionDataByUserId(action.userId).pipe(
      tap((res) => {
        patchState({
          permissionData: res,
        });
      })
    );
  }

  @Action(SaveUserModulePermission)
  saveUserModulePermission(
    { patchState }: StateContext<PermissionStateInfo>,
    action: SaveUserModulePermission
  ) {
    return this.permissionService
      .saveUserModulePermission(action.data, action.userId)
      .pipe(
        tap((res) => {
          patchState({
            isUserPermissionAdded: res,
          });
        })
      );
  }
}
