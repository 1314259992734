<div class="d-flex h-100">
  <app-side-list
    [getModuleId]="moduleId"
    [getModuleName]="moduleName"
    (showSideListAction)="showSideListAction($event)"
    (toggleSideList)="toggleSideList($event)"
    [reloadSideList]="reloadSideList"
  ></app-side-list>
  <div
    class="form mb-4"
    [ngClass]="istoggleSideList ? 'hide-sidelist' : ''"
    [hidden]="isShowSideListAction"
  >
    <div class="content-body-wrapper">
      <div class="content-body">
        <div class="mb-15 d-flex flex-column gap-5">
          <div class="header-actions wp-100">
            <app-header-actions
              [parentModuleId]="moduleId"
              (exportClick)="exportReport($event, false)"
              [removeBranchSelection]="true"
            >
            </app-header-actions>
          </div>
        </div>
        <div *ngIf="isReset">
          <ejs-querybuilder
            #querybuilder
            [rule]="importRules"
            (beforeChange)="onBeforeConditionChange($event)"
          >
            <e-columns>
              <e-column field="Credit" label="Credit" type="number"></e-column>
              <e-column field="Debit" label="Debit" type="number"></e-column>
              <e-column
                field="Reference"
                label="Reference"
                type="string"
              ></e-column>
              <e-column
                field="Date"
                label="Date"
                type="date"
                format="yyyy-MM-dd"
              ></e-column>
              <e-column
                field="Account"
                label="Account"
                type="string"
                [operators]="customOperators"
                [template]="accountTemplate"
              ></e-column>
            </e-columns>
          </ejs-querybuilder>

          <div class="content-header">
            <div class="toolbar mb-15">
              <button
                mat-button
                class="primary-button mr-1"
                (click)="getCustomiseReportList()"
                disableRipple
              >
                Get Customize Report
              </button>
              <button
                mat-button
                class="primary-button"
                (click)="saveReportClick()"
                disableRipple
              >
                Save Customize Report
              </button>
            </div>
          </div>
          <ng-template #accountTemplate let-data>
            <ejs-multiselect
              [dataSource]="values"
              [(ngModel)]="data.rule.value"
              [fields]="{ text: 'text', value: 'value' }"
              (change)="accountChange($event, data.ruleID)"
              [mode]="accountmode"
              [showDropDownIcon]="true"
              showSelectAll="true"
              [placeholder]="accountWaterMark"
              [filterBarPlaceholder]="accountfilterPlaceholder"
            ></ejs-multiselect>
          </ng-template>
        </div>
        <div class="report-grid">
          <ejs-grid
            *ngIf="columns.length > 0"
            #grid
            [dataSource]="gridData"
            [allowGrouping]="true"
            [groupSettings]="groupSettings"
            [allowReordering]="true"
            [allowSelection]="true"
            [allowPaging]="true"
            (checkBoxChange)="checkBoxChange($event)"
            (dataStateChange)="dataStateChange($event)"
            [pageSettings]="{
              pageSize: state.take,
              pageSizes: [5, 10, 20, 100],
              showInfo: true,
              showNavigationButtons: true,
              totalCount: totalRecords
            }"
            [selectedRowIndex]="selectedRowData.id"
            [allowFiltering]="true"
            [allowResizing]="true"
            [allowSorting]="true"
            [selectionSettings]="{
              type: 'Multiple',
              checkboxOnly: true,
              allowColumnSelection: true
            }"
            [filterSettings]="{ type: 'Menu' }"
            showColumnMenu="true"
            [skip]="skip"
          >
            <ng-template #toolbarTemplate>
              <div>
                <p class="fw-bold mb-10 mt-10 font-size-20">Customize Report</p>
              </div>
            </ng-template>

            <e-columns>
              <ng-container *ngFor="let col of columns; let colIndex = index">
                <e-column
                  [headerText]="col.name"
                  [field]="col.name + '.rowData'"
                  [hidden]="!col.isSelected"
                  [allowSorting]="true"
                  [allowFiltering]="col.columnType"
                  [allowGrouping]="true"
                  [visible]="col.isSelected"
                  [isPrimaryKey]="col.name === 'Id'"
                  [showInColumnChooser]="col.name === 'Id' ? false : true"
                >
                  <ng-template #template let-row let-rowIndex>
                    <div
                      *ngIf="
                        (col.dataType === dataType.String ||
                          col.dataType === dataType.Bool ||
                          col.dataType === dataType.DateTime) &&
                        col.name !== 'Status'
                      "
                      class="{{ col.alignment }}"
                      [ngClass]="{
                        'fw-bold': row[col.name]?.isBold,
                        'pr-40 clamp': moduleId !== moduleEnum.ComparativeReport
                      }"
                    >
                      <span title="{{ row[col.name]?.rowData }}">
                        {{ row[col.name]?.rowData }}</span
                      >
                    </div>

                    <div
                      *ngIf="
                        (col.dataType === dataType.Int32 ||
                          col.dataType === dataType.Currency) &&
                        col.name !== 'Status'
                      "
                      class="{{ col.alignment }} clamp pr-40 white-space-nowrap"
                      [ngClass]="{
                        'fw-bold': row[col.name]?.isBold
                      }"
                    >
                      <div *ngIf="moduleId !== moduleEnum.Invoices">
                        <span
                          title="£ {{ row[col.name]?.rowData | numberPipe }}"
                        >
                          £ {{ row[col.name]?.rowData | numberPipe }}</span
                        >
                      </div>
                      <div *ngIf="moduleId === moduleEnum.Invoices">
                        <span title="{{ row[col.name]?.rowData }}">
                          {{ row[col.name]?.rowData }}</span
                        >
                      </div>
                    </div>

                    <div
                      *ngIf="
                        col.dataType === dataType.URL && col.name !== 'Status'
                      "
                      class="{{ col.alignment }}"
                      [ngClass]="{
                        'fw-bold': row[col.name]?.isBold
                      }"
                      title="{{ row[col.name]?.rowData }}"
                    >
                      <a
                        *ngIf="row[col.name]?.moduleId > 0"
                        class="link text-primary wmx-100 clamp"
                        [ngClass]="{
                          'ml-2':
                            moduleId ===
                            (moduleEnum.BalanceSheet ||
                              moduleEnum.BalanceSheetComparison)
                        }"
                        (click)="redirect(col.name, row[col.name])"
                      >
                        {{ row[col.name]?.rowData }}
                      </a>
                    </div>

                    <div
                      *ngIf="
                        col.dataType === dataType.URL &&
                        row[col.name]?.id !== '' &&
                        row[col.name]?.id !== null &&
                        row[col.name]?.id !== undefined &&
                        row[col?.name]?.moduleId === 0 &&
                        col.name !== 'Status'
                      "
                      title="{{ row[col.name]?.rowData }}"
                      [ngClass]="row[col.name]?.isBold ? 'fw-bold' : ''"
                    >
                      <a
                        class="link fw-bold wmx-100 clamp"
                        (click)="
                          redirect(row[col.name]?.id, row[col.name]?.moduleId)
                        "
                      >
                        {{ row[col.name]?.rowData }}
                      </a>
                    </div>

                    <div
                      class="clamp"
                      *ngIf="
                        col.dataType === dataType.URL &&
                        (row[col.name]?.id === '' ||
                          row[col.name]?.id === null ||
                          row[col.name]?.id === undefined) &&
                        row[col?.name]?.moduleId === 0 &&
                        col.name !== 'Status'
                      "
                      title="{{ row[col.name]?.rowData }}"
                      [ngClass]="row[col.name]?.isBold ? 'fw-bold' : ''"
                    >
                      {{ row[col.name]?.rowData }}
                    </div>

                    <div
                      *ngIf="
                        col.dataType === dataType.Blob && col.name !== 'Status'
                      "
                    >
                      <button
                        mat-button
                        (click)="downloadFile(row[col.name].rowData)"
                        class="fw-bold text-primary"
                        disableRipple
                      >
                        <mat-icon>file_download</mat-icon>
                      </button>
                    </div>

                    <div
                      *ngIf="col.name === 'Status'"
                      title="{{ row[col.name]?.rowData }}"
                      [ngClass]="
                        row[col.name]?.rowData === 'Active' ||
                        row[col.name]?.rowData === 'Paid'
                          ? 'status-active'
                          : 'status-archived'
                      "
                    >
                      {{ row[col.name]?.rowData }}
                    </div>

                    <div
                      *ngIf="colIndex === columns.length - 2"
                      class="grid-actions"
                      [ngClass]="
                        selectedRowIndex === rowIndex ? 'show-button' : ''
                      "
                    >
                      <app-more-grid-actions
                        [moduleId]="moduleId"
                        [moreActionCount]="moreActionCount"
                        [element]="row[col.name]?.rowData"
                        (triggerActionType)="onButtonClick($event, row)"
                        (triggerSelectedRowIndex)="
                          getSelectedRowIndex($event, rowIndex)
                        "
                      ></app-more-grid-actions>
                    </div>
                  </ng-template>
                </e-column>
              </ng-container>
            </e-columns>
          </ejs-grid>
        </div>
      </div>
    </div>
  </div>
</div>
