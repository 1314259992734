<div
  class="content-body content-body-scroll"
  [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
>
  <form [formGroup]="submitVatForm">
    <div class="d-flex gap-40">
      <div class="form-field datepicker">
        <p>From <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="fromDate"
            [matDatepicker]="fromDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            tabindex="-1"
            required
            [max]="this.submitVatForm.controls.toDate.value"
            (dateChange)="updateDates(1)"
            [min]="this.vatReturnData?.vatRegistrationDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="fromDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(submitVatForm.get('fromDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(submitVatForm.get('fromDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
      <div class="form-field datepicker">
        <p>To<span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="toDate"
            [matDatepicker]="toDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            tabindex="-1"
            [min]="this.submitVatForm.controls.fromDate.value"
            required
            (dateChange)="updateDates(2)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="toDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #toDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(submitVatForm.get('toDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(submitVatForm.get('toDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>
    <div class="form-field textarea">
      <p>Description</p>
      <mat-form-field>
        <textarea
          matInput
          formControlName="description"
          autocomplete="off"
          maxlength="{{ maxLength.maxLength2000 }}"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="checkbox">
      <mat-checkbox formControlName="finalReturn"
        >De-registered for VAT</mat-checkbox
      >
      <p class="w-434 mb-0">
        Please tick this box if this is your last VAT submission. This is only
        needed when you have already cancelled you VAT registration at the
        HMRC's end.
      </p>
      <a
        href="https://www.gov.uk/government/publications/vat-notice-70011-cancelling-your-registration/vat-notice-70011-cancelling-your-registration"
        target="_blank"
        class="link mr-1"
        >Please click here for more guidance.</a
      >
    </div>
    <div class="checkbox" *ngIf="showGrid">
      <mat-checkbox formControlName="flatRate"
        >Two different VAT flat rates were found under the selected VAT period.
        ‘Tick’ mark the box if you wish to create two separate Sub periods with respective VAT rates.
        Else default VAT rate configured through ‘VAT settings’ will be applied to all the transactions falling under selected VAT period.</mat-checkbox
      >
     
      
    </div>
    <app-discount-flat-rate [fromDate]="fromDate" [toDate]="toDate" [vatReturnData]="vatReturnData"    (triggerGridUpdate)="getGridData($event)" *ngIf="submitVatForm.value.flatRate && showGrid" [editListData]="editListData"></app-discount-flat-rate>
  </form>
</div>
