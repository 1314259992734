import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';

import {
  AccountProvider,
  ConfirmationType,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { ConfirmationList } from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  ActiveDisposeId,
  ArchiveAndRestoreAccount,
  ArchiveAndRestoreBankEntry,
  ArchiveAndRestoreBill,
  ArchiveAndRestoreBudgeting,
  ArchiveAndRestoreCashEntry,
  ArchiveAndRestoreCISInvoice,
  ArchiveAndRestoreClient,
  ArchiveAndRestoreCreditNote,
  ArchiveAndRestoreCustomAccount,
  ArchiveAndRestoreDebitNote,
  ArchiveAndRestoreDirectorShareholder,
  ArchiveAndRestoreDividends,
  ArchiveAndRestoreFixedAssets,
  ArchiveAndRestoreInvoice,
  ArchiveAndRestoreJournals,
  ArchiveAndRestoreMinutesOfMeeting,
  ArchiveAndRestoreNote,
  ArchiveAndRestoreProduct,
  ArchiveAndRestoreQuickEntry,
  ArchiveAndRestoreQuotation,
  ArchiveAndRestoreReceipt,
  ArchiveAndRestoreRecurring,
  ArchiveAndRestoreVATCodes,
  DeleteAccount,
  DeleteBankAccount,
  DeleteBankImport,
  DeleteBankImportTransactionHistory,
  DeleteBill,
  DeleteBranch,
  DeleteBridgingVat,
  DeleteBudgeting,
  DeleteCIS300,
  DeleteCISInvoice,
  DeleteClient,
  DeleteClientCurrency,
  DeleteCreditNote,
  DeleteCustomAccount,
  DeleteCustomReport,
  DeleteDebitNote,
  DeleteDepartment,
  DeleteDirectorShareholderData,
  DeleteDividends,
  DeleteFinancialYear,
  DeleteFixedAssets,
  DeleteInventoryLossOfStock,
  DeleteInvoice,
  DeleteJournals,
  DeleteMail,
  DeleteMinutesOfMeeting,
  DeleteNote,
  DeleteProduct,
  DeleteQuickEntry,
  DeleteQuotation,
  DeleteReceipt,
  DeleteRecurringData,
  DeleteSubmitVat,
  DeleteUser,
  DeleteVATCodes,
  PrepareGoogleOrOffice,
  UnAllocateAmount,
} from '@app/core/Store';
import {
  ArchiveAndRestoreBankTransfer,
  DeleteBankTransfer,
} from '@app/core/Store/bank/bank-transfer';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss'],
})
export class ConfirmationBoxComponent implements OnInit {
  confirmationList: ConfirmationList;
  id: Guid;
  currencyRecordId: Guid;
  currencyId: number;
  InvoiceTypeId: number;

  constructor(
    private store: Store,

    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
    private notifier: NotificationService,
    public commonService: CommonService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.confirmationList = this.data;
    this.id = this.data.id;
    this.currencyRecordId = this.data.currencyRecordId;
    this.currencyId = this.data.currencyId;
    this.InvoiceTypeId = this.data?.InvoiceTypeId;
  }

  onYesClick(): void {
    this.spinner.show();
    if (this.confirmationList.type === ConfirmationType.Archive) {
      this.onArchiveClick();
    }

    if (this.confirmationList.type === ConfirmationType.Delete) {
      this.onDeleteClick();
    }

    if (this.confirmationList.type === ConfirmationType.Mail) {
      this.mailIntegration();
    }

    if (this.confirmationList.type === ConfirmationType.Activate) {
      this.onDisposedActivateClick();
    }

    if (this.confirmationList.type === ConfirmationType.MergeTrailBalance) {
      this.spinner.hide();
      this.dialogRef.close(true);
    }

    if (this.confirmationList.type === ConfirmationType.TrailBalanceErrorList) {
      this.spinner.hide();
      this.dialogRef.close(true);
    }

    if (this.confirmationList.type === ConfirmationType.BankTransferExist) {
      this.spinner.hide();
      this.dialogRef.close(true);
    }
    if (this.confirmationList.type === ConfirmationType.SubmitNillVAT) {
      this.spinner.hide();
      this.dialogRef.close(true);
    }
    if (this.confirmationList.type === ConfirmationType.DeleteBranch) {
      this.callDeleteAPI(
        DeleteBranch,
        this.confirmationList.ids,
        this.confirmationList.moduleId
      );
    }
    if (this.confirmationList.type === ConfirmationType.DeleteDepartment) {
      this.callDeleteAPI(
        DeleteDepartment,
        this.confirmationList.ids,
        this.confirmationList.moduleId
      );
    }
    if (this.confirmationList.type === ConfirmationType.DeleteLossOfStock) {
      this.callDeleteAPI(
        DeleteInventoryLossOfStock,
        this.confirmationList.ids,
        this.confirmationList.moduleId
      );
    }
    if (this.confirmationList.type === ConfirmationType.UnAllocateAmount) {
      this.onUnallocateClick();
    }
  }

  onArchiveClick(): void {
    let actionName;

    if (
      this.confirmationList.multipleModulesIds !== undefined &&
      Object.keys(this.confirmationList.multipleModulesIds).length > 0
    ) {
      Object.keys(this.confirmationList.multipleModulesIds).forEach(
        (element) => {
          actionName = this.getActionNameForArchive(+element);
          this.callArchiveAPI(
            actionName,
            this.confirmationList.multipleModulesIds[+element]
          );
        }
      );
    } else {
      actionName = this.getActionNameForArchive(this.confirmationList.moduleId);
      this.callArchiveAPI(actionName, this.confirmationList.ids);
    }
  }

  getActionNameForArchive(moduleId): any {
    let actionName;

    switch (moduleId) {
      case Modules.Invoices:
        actionName = ArchiveAndRestoreInvoice;
        break;

      case Modules.Customers:
      case Modules.Suppliers:
      case Modules.Subcontractor:
        actionName = ArchiveAndRestoreAccount;
        break;

      // case Modules.Activities:
      //   actionName = ArchiveAndRestoreActivity;
      //   break;

      case Modules.Journals:
        actionName = ArchiveAndRestoreJournals;
        break;

      case Modules.Product:
        actionName = ArchiveAndRestoreProduct;
        break;

      case Modules.MinutesOfMeeting:
        actionName = ArchiveAndRestoreMinutesOfMeeting;
        break;

      case Modules.VatSettings:
        actionName = ArchiveAndRestoreVATCodes;
        break;

      case Modules.Quotation:
        actionName = ArchiveAndRestoreQuotation;
        break;

      case Modules.Purchase:
        actionName = ArchiveAndRestoreBill;
        break;

      case Modules.AddCustomAccounts:
        actionName = ArchiveAndRestoreCustomAccount;
        break;

      case Modules.CreditNote:
        actionName = ArchiveAndRestoreCreditNote;
        break;

      case Modules.DebitNote:
        actionName = ArchiveAndRestoreDebitNote;
        break;

      case Modules.FixedAssets:
        actionName = ArchiveAndRestoreFixedAssets;
        break;

      case Modules.RecurringInvoice:
      case Modules.RecurringPurchase:
        actionName = ArchiveAndRestoreRecurring;
        break;

      case Modules.CashEntry:
        actionName = ArchiveAndRestoreCashEntry;
        break;
      case Modules.BankEntry:
        actionName = ArchiveAndRestoreBankEntry;
        break;

      case Modules.Receipt:
      case Modules.Payment:
        actionName = ArchiveAndRestoreReceipt;
        break;

      case Modules.BankTransfer:
        actionName = ArchiveAndRestoreBankTransfer;
        break;

      case Modules.Notes:
        actionName = ArchiveAndRestoreNote;
        break;

      case Modules.Director:
      case Modules.Shareholder:
        actionName = ArchiveAndRestoreDirectorShareholder;
        break;

      case Modules.Dividends:
        actionName = ArchiveAndRestoreDividends;
        break;

      case Modules.Budgeting:
        actionName = ArchiveAndRestoreBudgeting;
        break;

      case Modules.CISInvoice:
        actionName = ArchiveAndRestoreCISInvoice;
        break;

      case Modules.QuickEntry:
        actionName = ArchiveAndRestoreQuickEntry;
        break;

      case Modules.Clients:
        actionName = ArchiveAndRestoreClient;
        break;
    }

    return actionName;
  }

  callArchiveAPI(actionName, ids): void {
    if (actionName !== undefined) {
      this.store
        .dispatch(new actionName(ids, this.confirmationList.isArchive))
        .subscribe((data) => {
          data
            ? this.onArchiveAndRestoreSuccess()
            : this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
        });
    } else {
      this.onFailure();
      this.spinner.hide();
      this.dialogRef.close();
    }
  }

  mailIntegration() {
    let redirectUrl, provider, configType;
    switch (this.confirmationList.moduleId) {
      case Modules.Integration:
        redirectUrl =
          window.location.origin.toString() + '/manage/integrations';
        provider = this.id[0] === AccountProvider.Google ? 'google' : 'office';
        configType =
          this.confirmationList.headerText ==
          NotificationHeader.reauthenticateYourAccount
            ? 'ReAuth'
            : '';
        this.redirectToMail(configType, redirectUrl, provider);
        break;

      default:
        break;
    }
  }

  onDisposedActivateClick(): void {
    let actionName;
    actionName = this.getActionNameForDelete(this.confirmationList.moduleId);
    this.callDisposedActivateAPI(
      actionName,
      this.confirmationList.ids,
      this.confirmationList.moduleId
    );
  }

  redirectToMail(configType, redirectUrl, provider) {
    let payload = {
      ProviderId: AccountProvider.Google,
      configurationJson: configType,
      emailAddress: '',
      returnUrl: redirectUrl,
      ClientId: '1',
      UserId: '1',
      ProviderType: provider,
    };

    this.store
      .dispatch(new PrepareGoogleOrOffice(payload))
      .subscribe((res: any) => {
        window.open(res.integration.integrationData, '_self');
      });
  }

  onDeleteClick(): void {
    let actionName;

    if (
      this.confirmationList.multipleModulesIds !== undefined &&
      Object.keys(this.confirmationList.multipleModulesIds).length > 0
    ) {
      Object.keys(this.confirmationList.multipleModulesIds).forEach(
        (element) => {
          actionName = this.getActionNameForDelete(+element);
          this.callDeleteAPI(
            actionName,
            this.confirmationList.multipleModulesIds[+element],
            +element
          );
        }
      );
    } else {
      actionName = this.getActionNameForDelete(this.confirmationList.moduleId);
      if (actionName === DeleteClientCurrency) {
        this.callCurrencyDeleteAPI(actionName);
      } else {
        this.callDeleteAPI(
          actionName,
          this.confirmationList.ids,
          this.confirmationList.moduleId
        );
      }
    }
  }

  getActionNameForDelete(moduleId): any {
    let actionName;

    switch (moduleId) {
      case Modules.Invoices:
        actionName = DeleteInvoice;
        break;

      case Modules.Customers:
      case Modules.Suppliers:
      case Modules.Subcontractor:
        actionName = DeleteAccount;
        break;

      case Modules.BankTransfer:
        actionName = DeleteBankTransfer;
        break;

      case Modules.Journals:
      case Modules.FixedAssetRollBack:
        actionName = DeleteJournals;
        break;

      case Modules.Quotation:
        actionName = DeleteQuotation;
        break;

      case Modules.Purchase:
        actionName = DeleteBill;
        break;

      case Modules.AddCustomAccounts:
        actionName = DeleteCustomAccount;
        break;

      case Modules.CreditNote:
        actionName = DeleteCreditNote;
        break;

      case Modules.DebitNote:
        actionName = DeleteDebitNote;
        break;

      case Modules.FixedAssets:
        actionName = DeleteFixedAssets;
        break;

      case Modules.RecurringInvoice:
      case Modules.RecurringPurchase:
        actionName = DeleteRecurringData;
        break;

      case Modules.Receipt:
      case Modules.Payment:
      case Modules.CashEntry:
      case Modules.BankEntry:
        actionName = DeleteReceipt;
        break;

      case Modules.BankDashboard:
        actionName = DeleteAccount;
        break;

      case Modules.VatSettings:
        actionName = DeleteVATCodes;
        break;

      case Modules.FinancialYear:
        actionName = DeleteFinancialYear;
        break;

      case Modules.BankImportTransactionHistory:
        actionName = DeleteBankImportTransactionHistory;
        break;

      case Modules.BankImportHistory:
        actionName = DeleteBankImport;
        break;

      case Modules.Product:
        actionName = DeleteProduct;
        break;

      case Modules.Notes:
        actionName = DeleteNote;
        break;

      case Modules.MinutesOfMeeting:
        actionName = DeleteMinutesOfMeeting;
        break;

      case Modules.Director:
      case Modules.Shareholder:
        actionName = DeleteDirectorShareholderData;
        break;

      case Modules.Dividends:
        actionName = DeleteDividends;
        break;
      case Modules.VatSettings:
        actionName = DeleteVATCodes;
        break;
      case Modules.Budgeting:
        actionName = DeleteBudgeting;
        break;
      case Modules.CISInvoice:
        actionName = DeleteCISInvoice;
        break;
      case Modules.CIS300:
        actionName = DeleteCIS300;
        break;

      case Modules.FixedAssetsDispose:
      case Modules.DisposeIntangibleFixedAssets:
      case Modules.DisposeTangibleFixedAssets:
      case Modules.DisposeLeasedFixedAssets:
        actionName = ActiveDisposeId;
        break;

      case Modules.BankFeed:
        actionName = DeleteBankAccount;
        break;
      case Modules.Currency:
        actionName = DeleteClientCurrency;
        break;

      case Modules.SubmitVat:
        actionName = DeleteSubmitVat;
        break;

      case Modules.BridgingVAT:
        actionName = DeleteBridgingVat;
        break;

      case Modules.CustomizeReport:
        actionName = DeleteCustomReport;
        break;

      case Modules.QuickEntry:
        actionName = DeleteQuickEntry;
        break;

      case Modules.Clients:
        actionName = DeleteClient;
        break;

      case Modules.Users:
        actionName = DeleteUser;
        break;
    }

    return actionName;
  }

  callDeleteAPI(actionName, ids, moduleId): void {
    if (actionName !== undefined) {
      this.store.dispatch(new actionName(ids, moduleId)).subscribe(
        () => {
          this.onSuccessDelete();
        },
        (error) => {
          this.spinner.hide();
          this.dialogRef.close(false);
        }
      );
    } else if (Modules.Integration) {
      this.store.dispatch(new DeleteMail(this.id)).subscribe((data: any) => {
        this.dialogRef.close(data);
      });
    } else {
      this.spinner.hide();
      this.dialogRef.close(true);
      this.onFailure();
    }
  }
  callCurrencyDeleteAPI(actionName): void {
    if (actionName !== undefined) {
      this.store
        .dispatch(
          new DeleteClientCurrency(this.currencyRecordId, this.currencyId)
        )
        .subscribe(
          (res) => {
            if (res.currency.isCurrencyDeleted.status) {
              this.commonService.onSuccess(
                res.currency.isCurrencyDeleted.message
              );
              this.dialogRef.close(true);
            } else {
              this.commonService.onFailure(
                res.currency.isCurrencyDeleted.message
              );
              this.dialogRef.close(true);
            }
          },
          (error) => {
            this.spinner.hide();
            this.dialogRef.close(true);
          }
        );
    } else {
      this.spinner.hide();
      this.dialogRef.close(true);
      this.onFailure();
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onFailure(): void {
    this.notifier.error(
      NotificationHeader.error,
      NotificationTextMessage.errorMessage
    );
  }

  onSuccessDelete(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.recordDeletedSuccessfully
    );
    this.dialogRef.close(true);
  }

  onArchiveAndRestoreSuccess(): void {
    this.notifier.success(
      NotificationHeader.success,
      this.confirmationList.isArchive
        ? NotificationTextMessage.recordArchivedSuccessfully
        : NotificationTextMessage.recordRestoredSuccessfully
    );
    this.dialogRef.close(true);
  }

  callDisposedActivateAPI(actionName, ids, moduleId): void {
    if (actionName !== undefined) {
      this.store.dispatch(new actionName(ids, moduleId)).subscribe(
        () => {
          this.onSuccessDisposed();
        },
        (error) => {
          this.spinner.hide();
          this.dialogRef.close(true);
        }
      );
    } else {
      this.spinner.hide();
      this.dialogRef.close(true);
      this.onFailure();
    }
  }

  onSuccessDisposed(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.recordActivateSuccessfully
    );
    this.dialogRef.close(true);
  }

  onUnallocateClick(): void {
    this.store
      .dispatch(
        new UnAllocateAmount(this.confirmationList.ids[0], this.InvoiceTypeId)
      )
      .subscribe(
        () => {
          this.onSuccessUnallocation();
        },
        (error) => {
          this.spinner.hide();
          this.dialogRef.close(true);
        }
      );
  }
  onSuccessUnallocation(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.unallocateSuccessfully
    );
    this.dialogRef.close(true);
  }
}
