<div class="d-flex h-100">
  <app-side-list
    [getModuleId]="moduleId"
    [getModuleName]="moduleName"
    (showSideListAction)="showSideListAction($event)"
    (toggleSideList)="toggleSideList($event)"
    [reloadSideList]="reloadSideList"
  ></app-side-list>

  <div
    class="form mb-4"
    [ngClass]="istoggleSideList ? 'hide-sidelist' : ''"
    [hidden]="isShowSideListAction"
  >
    <div class="content-body-wrapper">
      <div class="content-body">
        <p class="header-text d-flex justify-space-between align-items-center">
          <span>
            {{
              debitNoteId === commonService.defaultGuidValue
                ? "Add New Credit Note"
                : "Edit Credit Note"
            }}
          </span>
          <button
            mat-button
            class="header-text-close d-flex align-items-center"
            disableRipple
            (click)="onCloseClick()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </p>
        <div class="alert-message" *ngIf="isViewPermission$ | async">
          <mat-icon class="material-icons-outlined">error_outline</mat-icon>
          {{ commonNotificationText.ViewOnlyAccessMessage }}
        </div>
        <div>
          <div class="accordion-toggle">
            <mat-icon
              (click)="toggleAccordion(); accordion.openAll()"
              *ngIf="isExpandAll"
              >keyboard_double_arrow_down
            </mat-icon>
            <mat-icon
              (click)="toggleAccordion(); accordion.closeAll()"
              *ngIf="!isExpandAll"
              >keyboard_double_arrow_up
            </mat-icon>
          </div>
          <mat-accordion [multi]="true">
            <mat-expansion-panel
              [expanded]="true"
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >description</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Credit Note Details</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-add-debit-note
                (triggerSupplierChange)="onSupplierChange()"
                [triggereDebitNoteEditData]="triggereDebitNoteEditData"
                [isFromBankImport]="isFromBankImport"
                (isVatIncludedClick)="isVatIncludedClick($event)"
                (branchChange)="onBranchChange($event)"
                (triggerCurrencySelectedOption)="
                  onCurrencySelectedOption($event)
                "
                (triggerCurrencyRefreshOption)="onCurrencyRefreshOption($event)"
                (triggerSelectedCurrencyRate)="onCurrencySelectedRate($event)"
                [triggerTransactionLogData]="triggerTransactionLogData"
              ></app-add-debit-note>
              <app-product-details
                [triggereEditProductData]="triggereEditProductData"
                [triggerisVatIncluded]="triggerisVatIncluded"
                [triggerOnBranchChange]="triggerBranchChange"
                [triggerCurrencySelectedOption]="triggerCurrencySelectedOption"
                [triggerCurrencyRefreshOption]="triggerCurrencyRefreshOption"
                [triggerSelectedCurrencyRate]="triggerSelectedCurrencyRate"
                [isSales]="false"
                [moduleId]="moduleId"
              ></app-product-details>
            </mat-expansion-panel>
            <mat-expansion-panel
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >attachment</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Attachment</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-transactions-attachment
                [triggerEditData]="triggerEditData"
              ></app-transactions-attachment>
            </mat-expansion-panel>

            <mat-expansion-panel
              [ngClass]="[
                isFromBankImport ? 'hidden' : '',
                (isViewPermission$ | async) ? 'is-readonly' : ''
              ]"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >receipt</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Receipts Allocation</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-transactions-payment
                [triggereEditPaymentData]="triggereEditPaymentData"
                [headerText]="headerText"
                [totalReceiptAmount]="this.productDetails.totalAmount"
                [moduleId]="moduleId"
                [totalCreditAmountReceived]="amountReceivedTotal"
                (totalPaymentReciptAmount)="onAmountPaymentReceivedChange()"
                [currencyRate]="this.addDebitNoteDetails.selectedCurrencyRate"
                [selectedCurrencyId]="
                  this.addDebitNoteDetails.selectedCurrencyId
                "
              >
              </app-transactions-payment>
            </mat-expansion-panel>

            <mat-expansion-panel
              [ngClass]="[
                isFromBankImport ? 'hidden' : '',
                (isViewPermission$ | async) ? 'is-readonly' : ''
              ]"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >receipt</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Credit Allocation</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-credit-note-allocation
                [getModuleId]="moduleId"
                (amountReceived)="onAmountReceivedChange()"
                [triggerDebitCustomer]="triggerCustomer"
                [triggerReceiptDetail]="triggerReceiptDetail"
                [totalReceiptAmount]="this.productDetails.totalAmount"
                [totalPaymentAmountReceived]="amountPaymentReceivedTotal"
              >
              </app-credit-note-allocation>
            </mat-expansion-panel>
          </mat-accordion>
          <app-product-details-footer
            [total]="this.productDetails.totalAmount"
            [balanceAmount]="
              this.productDetails.totalAmount -
              this.receiptInfo.totalReciptAmount -
              this.creditNoteAllocationInfo.totalAmount
            "
            [currencySymbol]="this.addDebitNoteDetails.selectedCurrencySymbol"
          >
          </app-product-details-footer>
        </div>
      </div>
    </div>
    <div class="action-wrapper">
      <div class="content-body">
        <app-buttons
          *ngIf="!(isViewPermission$ | async)"
          [getModuleId]="moduleId"
          (triggerOnSaveClick)="onSave($event)"
          (triggerOnCancelClick)="onCancel(true)"
        >
        </app-buttons>
      </div>
    </div>
  </div>
</div>
