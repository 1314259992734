import { CurrencyModel, SaveCurrencyModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CreateCurrency {
  static readonly type = '[Currency] Create Currency';

  constructor(public currency: SaveCurrencyModel) {}
}

export class GetClientCurrencyList {
  static readonly type = '[Currency] Get Currency';
}
export class DeleteClientCurrency {
  static readonly type = '[Currency] Delete Currency';

  constructor(public id: Guid, public currencyId: number) {}
}
export class GetRefreshCurrencyData {
  static readonly type = '[Currency] Get Client Currency Refresh Data';

  constructor(public currencyIds: Array<number>) {}
}
export class SaveCurrencyData {
  static readonly type = '[Currency] Save Client Currency Data';

  constructor(public currency: Array<CurrencyModel>) {}
}
