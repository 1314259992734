<div class="content">
  <div class="content-body">
    <p class="header-text d-flex justify-space-between align-items-center">
      <span> Cash Coding </span>
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCloseClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>
    <div class="content-header">
      <div
        class="content-header-input"
        [ngClass]="customDate ? 'content-header-custom-date' : ''"
      >
        <div class="d-flex justify-space-between wp-100 align-items-center">
          <div class="mr-1 d-flex flex-column gap-10">
            <p class="fw-bold m-0 white-space-nowrap">Select Bank:</p>
            <ng-select
              class="w-150"
              #accountList
              (focus)="accountList.open()"
              (valueChange)="accountList.close()"
              [disableOptionCentering]="true"
              [(ngModel)]="customId"
              (ngModelChange)="onBankAccountChange()"
              required
              panelClass="mat-select-position"
            >
              <ng-option
                *ngFor="let option of receiptAccountList"
                [value]="option.id"
              >
                <div class="clamp" title="{{ option.name }}">
                  {{ option.name }}
                </div>
              </ng-option>
            </ng-select>
          </div>
          <div
            class="d-flex gap-15 align-items-end header-count header-filters wp-100 ng-star-inserted header-count-2"
          >
            <div class="Search">
              <mat-form-field appearance="fill" class="search-field">
                <input
                  matInput
                  placeholder="Search here..."
                  autocomplete="off"
                  [(ngModel)]="searchText"
                  (ngModelChange)="onSearchAll()"
                  (keyup.enter)="onSearch()"
                />

                <button mat-button class="search-button" (click)="onSearch()">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="d-flex gap-10 header-actions">
              <div class="Datetime">
                <div class="year-picker">
                  <div class="d-flex flex-column gap-10">
                    <span class="fw-bold mr-1">Period:</span>
                    <app-custom-year-picker
                      [moduleId]="moduleEnum.CashCoding"
                      (triggerDateChange)="triggerDateChange($event)"
                    ></app-custom-year-picker>
                  </div>
                </div>
              </div>

              <div *ngIf="departmentList.length > 0">
                <p class="fw-bold">
                  Department <span class="text-danger-300">*</span>
                </p>
                <ng-select
                  #department
                  (focus)="department.open()"
                  (valueChange)="department.close()"
                  [disableOptionCentering]="true"
                  [(ngModel)]="departmentId"
                  dropdownPosition="bottom"
                >
                  <ng-option *ngFor="let dl of departmentList" [value]="dl.id">
                    <div title="{{ dl.name }}">{{ dl.name }}</div>
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mat-table-wrapper">
      <table
        mat-table
        [dataSource]="bankTransactions"
        aria-describedby="Cash-Coding"
      >
        <ng-container matColumnDef="srNo">
          <th mat-header-cell *matHeaderCellDef fxFlex="15" class="w-15">
            <mat-checkbox
              [(ngModel)]="isAllSelected"
              (change)="selectAll($event)"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="d-flex">
              <mat-checkbox
                [(ngModel)]="element.isSelected"
                (change)="onCheckBoxChanges($event, element)"
              >
              </mat-checkbox>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef class="w-100">Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.date | date: "dd-LLL-yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="reference">
          <th mat-header-cell *matHeaderCellDef>Reference</th>
          <td mat-cell *matCellDef="let element">
            {{ element.notes }}
          </td>
        </ng-container>

        <ng-container matColumnDef="account">
          <th mat-header-cell *matHeaderCellDef class="w-170">Account</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <ng-select
              #accountName
              [items]="accountGroupList"
              (focus)="accountName.open()"
              (valueChange)="accountName.close()"
              [disabled]="!element.isSelected"
              class="bank-account"
              [ngClass]="!element.isSelected ? 'disable' : ''"
              [disableOptionCentering]="true"
              [(ngModel)]="element.accountName"
              bindLabel="name"
              bindValue="id"
              groupBy="groupName"
              (ngModelChange)="onAccountChange(element)"
              panelClass="mat-select-position"
              appendTo="body"
            >
              <ng-template ng-option-tmp let-item="item">
                <span class="ng-option-label" [attr.title]="item.name">{{
                  item.name
                }}</span>
              </ng-template>
            </ng-select>
          </td>
        </ng-container>

        <ng-container matColumnDef="vatRate" *ngIf="isVatRegistered">
          <th mat-header-cell *matHeaderCellDef class="w-150">
            VAT Rate <span class="text-danger-300">*</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-select
              #vatRate
              (focus)="vatRate.open()"
              (valueChange)="vatRate.close()"
              [disableOptionCentering]="true"
              [disabled]="!element.isSelected"
              [ngClass]="!element.isSelected ? 'disable' : ''"
              [(ngModel)]="element.vatRateId"
              (ngModelChange)="onVatChange(element)"
              (selectionChange)="calVatAmount(element)"
              panelClass="mat-select-position"
              appendTo="body"
              required
            >
              <ng-option
                *ngFor="let option of vatRateList"
                [value]="option.id"
                title="{{ option.name }}"
                >{{ option.name }}</ng-option
              >
            </ng-select>
          </td>
        </ng-container>

        <ng-container matColumnDef="vatAmount" *ngIf="isVatRegistered">
          <th mat-header-cell *matHeaderCellDef class="w-100">VAT Amount</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field>
              <input
                matInput
                autocomplete="off"
                [(ngModel)]="element.vatAmount"
                autocomplete="off"
                [disabled]="vatAmountIsDisabled"
                appAutoFocusDirective
                allowDecimal
              />
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="contactName">
          <th mat-header-cell *matHeaderCellDef>Contact Name</th>
          <td mat-cell *matCellDef="let element">
            <ng-select
              *ngIf="element.reciepts !== 0"
              class="w-120"
              #contactName
              (focus)="contactName.open()"
              (valueChange)="contactName.close()"
              [disableOptionCentering]="true"
              [disabled]="!element.contactEnable"
              [ngClass]="!element.contactEnable ? 'disable' : ''"
              [(ngModel)]="element.contact"
              panelClass="mat-select-position"
              (ngModelChange)="onContactChange(element)"
              appendTo="body"
            >
              <ng-container>
                <ng-option
                  *ngFor="let option of customerList"
                  [value]="option.id"
                  title="{{ option.name }}"
                  >{{ option.name }}</ng-option
                >
              </ng-container>
            </ng-select>
            <ng-select
              *ngIf="element.reciepts === 0"
              class="w-120"
              #contactName
              (focus)="contactName.open()"
              (valueChange)="contactName.close()"
              [disableOptionCentering]="true"
              [disabled]="!element.contactEnable"
              [ngClass]="!element.contactEnable ? 'disable' : ''"
              [(ngModel)]="element.contact"
              panelClass="mat-select-position"
              (ngModelChange)="onContactChange(element)"
              appendTo="body"
            >
              <ng-container>
                <ng-option
                  *ngFor="let option of supplierList"
                  [value]="option.id"
                  title="{{ option.name }}"
                  >{{ option.name }}</ng-option
                >
              </ng-container>
            </ng-select>
          </td>
        </ng-container>

        <ng-container matColumnDef="receipt">
          <th mat-header-cell *matHeaderCellDef class="text-align-right">
            Receipt
          </th>
          <td mat-cell *matCellDef="let element" class="text-align-right">
            {{ element.reciepts | numberPipe }}
          </td>
        </ng-container>

        <ng-container matColumnDef="payment">
          <th mat-header-cell *matHeaderCellDef class="text-align-right">
            Payment
          </th>
          <td mat-cell *matCellDef="let element" class="text-align-right">
            {{ element.payments | numberPipe }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="isRowHighlighted ? 'highlightRow' : ''"
        ></tr>
      </table>
      <div *ngIf="noDataFound" class="text-align-center mt-1">
        No records found
      </div>
    </div>
  </div>
  <app-buttons
    *ngIf="!(isViewPermission$ | async)"
    (triggerOnSaveClick)="onSave()"
    (triggerOnCancelClick)="onCancel()"
    [saveButtonShow]="true"
  ></app-buttons>
</div>
