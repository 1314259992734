<div class="content-body-wrapper">
  <div class="content-body">
    <div>
      <p class="header-text d-flex justify-space-between align-items-center">
        <span>
          {{
            vatCodeId === commonService.defaultGuidValue
              ? "Add Vat Code"
              : "Edit Vat Code"
          }}
        </span>
        <button
          mat-button
          class="header-text-close d-flex align-items-center"
          disableRipple
          (click)="onCloseClick()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </p>
    </div>
    <form [formGroup]="vatCodesForm" class="p-0">
      <div class="d-flex gap-40">
        <div class="form-field">
          <p>VAT Name <span class="text-danger-300">*</span></p>
          <mat-form-field>
            <input matInput formControlName="vatName" autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="form-field quick-add-buttons">
          <p>VAT Type <span class="text-danger-300">*</span></p>
          <ng-select
            #vatType
            (focus)="vatType.open()"
            (valueChange)="vatType.close()"
            [disableOptionCentering]="true"
            formControlName="vatType"
            panelClass="mat-select-position"
          >
            <ng-option
              *ngFor="let option of vatRateCodeTypes | useEnumValues"
              [value]="option.key"
            >
              <div title="{{ option.value }}">{{ option.value }}</div>
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="d-flex gap-40">
        <div class="form-field">
          <p>Abbreviation <span class="text-danger-300">*</span></p>
          <mat-form-field>
            <input matInput formControlName="abbreviation" autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Rate (%) <span class="text-danger-300">*</span></p>
          <mat-form-field>
            <input
              matInput
              formControlName="rate"
              autocomplete="off"
              allowDecimal
            />
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>
<app-buttons
  [getModuleId]="moduleId"
  (triggerOnSaveClick)="onSave($event)"
  (triggerOnCancelClick)="onCancel(true)"
>
</app-buttons>
