import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { AddAccountComponent } from '@app/modules/common/add-account/add-account.component';
export interface PeriodicElement {
  name: string;
  category: string;
  code: string;
  archive: string;
  actionbtn: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    name: 'Sales',
    category: 'Turnover',
    code: '1000',
    archive: '',
    actionbtn: '',
  },
  {
    name: 'Fee Income',
    category: 'Turnover',
    code: '1010',
    archive: '',
    actionbtn: '',
  },
  {
    name: 'Domestic Sales',
    category: 'Turnover',
    code: '1020',
    archive: '',
    actionbtn: '',
  },
  {
    name: 'Export Sales',
    category: 'Turnover',
    code: '1030',
    archive: '',
    actionbtn: '',
  },
  {
    name: 'Bank Interest',
    category: 'Turnover',
    code: '1050',
    archive: '',
    actionbtn: '',
  },
];

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent {
  displayedColumns: string[] = [
    'name',
    'category',
    'code',
    'archive',
    'actionbtn',
  ];
  dataSource = ELEMENT_DATA;
  isExpandAll = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(public dialog: MatDialog) {}

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddAccountComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
