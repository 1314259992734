import { ComparitiveReportType, DateFilterType } from '@app/core/Enum';
import { Guid } from 'guid-typescript';
import { ExportType } from '../../common/export-type';
import { AccountingPeriodModel } from '../../company/company';

export class ComparitiveReportParamerters {
  type: ComparitiveReportType;
  option: DateFilterType;
  accountingPeriods: AccountingPeriodModel[];
  branchIds: Guid[];
  departmentIds: Guid[];
  format?: ExportType;
  isPrint?: boolean;
  modId?: number;
}
