<div class="content-body-wrapper">
  <div class="content-body content-body-scroll">
    <div>
      <mat-tab-group
        mat-align-tabs="start"
        disableRipple
        animationDuration="0ms"
        animationDuration="0ms "
        [(selectedIndex)]="selectedTab"
        (selectedTabChange)="onTabClick($event.index)"
      >
        <mat-tab label="Active Assets">
          <div [ngClass]="selectedTab === 0 ? '' : 'hidden'">
            <div class="accordion-toggle">
              <mat-icon
                (click)="toggleAccordion(); accordion.openAll()"
                *ngIf="isExpandAll"
                >keyboard_double_arrow_down
              </mat-icon>
              <mat-icon
                (click)="toggleAccordion(); accordion.closeAll()"
                *ngIf="!isExpandAll"
                >keyboard_double_arrow_up
              </mat-icon>
            </div>
            <mat-accordion [multi]="false">
              <mat-expansion-panel
                [expanded]="true"
                (opened)="
                  onAccordionClick(modulesEnum.ActiveTangibleFixedAssets)
                "
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon
                      fontSet="material-icons-outlined"
                      class="text-primary"
                      >description</mat-icon
                    >
                    <div class="d-flex flex-column">
                      <span>Tangible Fixed Assets </span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <app-report-list-with-actions
                  *ngIf="
                    selectedModuleId === modulesEnum.ActiveTangibleFixedAssets
                  "
                  [triggerModuleId]="triggerModuleId"
                ></app-report-list-with-actions>
              </mat-expansion-panel>
              <mat-expansion-panel
                (opened)="onAccordionClick(modulesEnum.ActiveLeasedFixedAssets)"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon
                      fontSet="material-icons-outlined"
                      class="text-primary"
                      >description</mat-icon
                    >
                    <div class="d-flex flex-column">
                      <span>Leased Fixed Assets </span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <app-report-list-with-actions
                  [triggerModuleId]="triggerModuleId"
                  *ngIf="
                    selectedModuleId === modulesEnum.ActiveLeasedFixedAssets
                  "
                ></app-report-list-with-actions>
              </mat-expansion-panel>
              <mat-expansion-panel
                (opened)="
                  onAccordionClick(modulesEnum.ActiveIntangibleFixedAssets)
                "
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon
                      fontSet="material-icons-outlined"
                      class="text-primary"
                      >description</mat-icon
                    >
                    <div class="d-flex flex-column">
                      <span>Intangible Fixed Assets </span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <app-report-list-with-actions
                  [triggerModuleId]="triggerModuleId"
                  *ngIf="
                    selectedModuleId === modulesEnum.ActiveIntangibleFixedAssets
                  "
                ></app-report-list-with-actions>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-tab>
        <mat-tab label="Disposed">
          <div *ngIf="selectedTab === 1">
            <div class="accordion-toggle">
              <mat-icon
                (click)="toggleAccordion(); accordion.openAll()"
                *ngIf="isExpandAll"
                >keyboard_double_arrow_down
              </mat-icon>
              <mat-icon
                (click)="toggleAccordion(); accordion.closeAll()"
                *ngIf="!isExpandAll"
                >keyboard_double_arrow_up
              </mat-icon>
            </div>
            <mat-accordion [multi]="false">
              <mat-expansion-panel
                [expanded]="true"
                (opened)="
                  onAccordionClick(modulesEnum.DisposeTangibleFixedAssets)
                "
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon
                      fontSet="material-icons-outlined"
                      class="text-primary"
                      >description</mat-icon
                    >
                    <div class="d-flex flex-column">
                      <span>Tangible Fixed Assets </span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <app-report-list-with-actions
                  *ngIf="
                    selectedModuleId === modulesEnum.DisposeTangibleFixedAssets
                  "
                  [triggerModuleId]="triggerModuleId"
                ></app-report-list-with-actions>
              </mat-expansion-panel>
              <mat-expansion-panel
                (opened)="
                  onAccordionClick(modulesEnum.DisposeLeasedFixedAssets)
                "
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon
                      fontSet="material-icons-outlined"
                      class="text-primary"
                      >description</mat-icon
                    >
                    <div class="d-flex flex-column">
                      <span>Leased Fixed Assets </span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <app-report-list-with-actions
                  *ngIf="
                    selectedModuleId === modulesEnum.DisposeLeasedFixedAssets
                  "
                  [triggerModuleId]="triggerModuleId"
                ></app-report-list-with-actions>
              </mat-expansion-panel>
              <mat-expansion-panel
                (opened)="
                  onAccordionClick(modulesEnum.DisposeIntangibleFixedAssets)
                "
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon
                      fontSet="material-icons-outlined"
                      class="text-primary"
                      >description</mat-icon
                    >
                    <div class="d-flex flex-column">
                      <span>Intangible Fixed Assets </span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <app-report-list-with-actions
                  *ngIf="
                    selectedModuleId ===
                    modulesEnum.DisposeIntangibleFixedAssets
                  "
                  [triggerModuleId]="triggerModuleId"
                ></app-report-list-with-actions>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
