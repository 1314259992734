<app-menu-items></app-menu-items>
<div class="sidenav-table sidenav-table-input wp-100">
  <div class="content-body">
    <p class="header-text d-flex justify-space-between align-items-center">
      <span>Logo On Reports</span>
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCloseClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>
    <div class="form-field">
      <mat-checkbox [(ngModel)]="isEnableReportLogo"
        >Enable/Disable Report Logo</mat-checkbox
      >
    </div>
  </div>
</div>
<div class="action-wrapper button-action-wrapper">
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave(true)"
    >
      Save
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onCloseClick()"
    >
      Cancel
    </button>
  </div>
</div>
