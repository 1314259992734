<div class="sidenav-table sidenav-table-input wp-100">
  <div class="content-body">
    <p class="header-text d-flex justify-space-between align-items-center">
      <span>Default Settings</span>
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCloseClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>

    <div>
      <div class="form-field fund-name">
        <p>Default Rows</p>
        <ng-select
          #drows
          (focus)="drows.open()"
          (valueChange)="drows.close()"
          [disableOptionCentering]="true"
          dropdownPosition="bottom"
          [(ngModel)]="defaultRows"
        >
          <ng-option [value]="15">15</ng-option>
          <ng-option [value]="25">25</ng-option>
          <ng-option [value]="50">50</ng-option>
          <ng-option [value]="100">100</ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>Default Tab</p>
        <ng-select
          #dtab
          (focus)="dtab.open()"
          (valueChange)="dtab.close()"
          [disableOptionCentering]="true"
          dropdownPosition="bottom"
          [(ngModel)]="defaultTab"
        >
          <ng-option [value]="0">All Clients</ng-option>
          <ng-option [value]="1">VAT Clients</ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <div class="form-field datepicker">
          <p>Default Month</p>
          <ng-select
            #dmonth
            (focus)="dmonth.open()"
            (valueChange)="dmonth.close()"
            [disableOptionCentering]="true"
            dropdownPosition="bottom"
            [(ngModel)]="defaultMonth"
          >
            <ng-option [value]="0">Current Month</ng-option>
            <ng-option [value]="1">Next Month</ng-option>
            <ng-option [value]="2">Previous Month</ng-option>
          </ng-select>
        </div>
      </div>
      <div class="form-field">
        <p>Auto Recall</p>
        <mat-checkbox [(ngModel)]="isAutoRecall"></mat-checkbox>
      </div>
    </div>
  </div>
</div>
<div class="action-wrapper button-action-wrapper">
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave(true)"
    >
      Save
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onCloseClick()"
    >
      Cancel
    </button>
  </div>
</div>
