import { Guid } from 'guid-typescript';

export class DividendsModel {
  id?: Guid;
  shareholderId: Guid;
  entryNumber: string;
  shareClassTypeId: number;
  yearEndDate: string;
  declarationDate: string;
  paymentDate: string;
  dividendPerShare: number;
  taxCredit: number;
  grossDividend: number;
  excludeTaxCredit: boolean;
  isPostJournal: boolean;
}
