import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cis-manual-verification-popup',
  templateUrl: './cis-manual-verification-popup.component.html',
  styleUrls: ['./cis-manual-verification-popup.component.scss'],
})
export class CisManualVerificationPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<CisManualVerificationPopupComponent>
  ) {}

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
