import { Injectable } from '@angular/core';
import { DirectorShareholderModel, SideListModel } from '@app/core/Models';
import { DirectorShareholderService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreDirectorShareholder,
  CreateDirectorShareholder,
  DeleteDirectorShareholderData,
  GetDataByDirectorShareholderId,
} from './director-Shareholder.action';

export class DirectorShareholderStateInfo {
  directorShareholderId?: Guid;
  directorShareholderData?: DirectorShareholderModel;
  isAccountAdded?: boolean;
  directorShareholder: Array<DirectorShareholderModel>;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isLastPage?: boolean;
}

@State<DirectorShareholderStateInfo>({
  name: 'directorShareholder',
  defaults: {
    isAccountAdded: false,
    directorShareholder: [],
    directorShareholderId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
  },
})
@Injectable()
export class DirectorShareholderState {
  constructor(private directorShareholderService: DirectorShareholderService) {}

  @Selector()
  static isLastPage(state: DirectorShareholderStateInfo) {
    return state.isLastPage;
  }

  @Action(CreateDirectorShareholder)
  createDirectorShareholder(
    { patchState }: StateContext<DirectorShareholderStateInfo>,
    action: CreateDirectorShareholder
  ) {
    return this.directorShareholderService
      .createDirectorShareholder(action.data)
      .pipe(
        tap((res) => {
          patchState({
            directorShareholderId: res,
            isAccountAdded: true,
          });
        })
      );
  }

  @Action(GetDataByDirectorShareholderId)
  getDataByDirectorShareholderId(
    { patchState }: StateContext<DirectorShareholderStateInfo>,
    action: GetDataByDirectorShareholderId
  ) {
    return this.directorShareholderService
      .getDataByDirectorShareholderId(action.directorShareholderId)
      .pipe(
        tap((res) => {
          patchState({
            directorShareholderData: res,
          });
        })
      );
  }

  @Action(ArchiveAndRestoreDirectorShareholder)
  archiveAndRestoreDirectorShareholder(
    { getState }: StateContext<DirectorShareholderStateInfo>,
    action: ArchiveAndRestoreDirectorShareholder
  ) {
    return this.directorShareholderService.archiveAndRestoreDirectorShareholder(
      action.directorShareholderId,
      action.isArchive
    );
  }

  @Action(DeleteDirectorShareholderData)
  deleteDirectorShareholderData(
    { getState, patchState }: StateContext<DirectorShareholderStateInfo>,
    action: DeleteDirectorShareholderData
  ) {
    return this.directorShareholderService
      .deleteDirectorShareholderData(action.directorShareholderId)
      .pipe(
        tap((res) => {
          const state = getState();

          const filteredShareholder = state.directorShareholder.filter(
            (item) =>
              !action.directorShareholderId?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          const filteredForSideList = state.sideListModel?.filter(
            (item) =>
              !action.directorShareholderId?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          patchState({
            ...state.directorShareholder,
            directorShareholder: filteredShareholder,
            sideListModel: filteredForSideList,
          });
        })
      );
  }
}
