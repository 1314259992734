import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ComparitiveReportType,
  DataType,
  DateFilterType,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import {
  AccountingPeriodModel,
  ComparitiveReportParamerters,
  ExportType,
  GlobalComponent,
  MainListParameters,
} from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  GetBranchList,
  GetComparitiveReport,
  GetComparitiveReportExport,
  GetDepartmentListByBranchIds,
} from '@app/core/Store';
import { CustomPeriodPopupComponent } from '@app/modules';
import { Store } from '@ngxs/store';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-grids';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';
import { ShareComponent } from '../share/share.component';
@Component({
  selector: 'app-comparitive-report',
  templateUrl: './comparitive-report.component.html',
  styleUrls: ['./comparitive-report.component.scss'],
})
export class ComparitiveReportComponent implements OnInit {
  @ViewChild('grid') grid!: GridComponent;

  exportType = ExportType;
  dataType = DataType;
  columns: any = [];
  gridData: any = [];
  tempGridData: any[] = [];
  gridHeight: number;
  reportColumnList: any[] = [];
  tempReportColumnList: any[] = [];

  public multiAccountingPeriodFields: Object = { text: 'name', value: 'id' };

  public branchmode: string;
  public branchfilterPlaceholder: string;
  public yearmode: string;
  public yearfilterPlaceholder: string;
  public branchFields: Object = { text: 'name', value: 'id' };
  public branchWaterMark: string = 'Branch-All';
  public branchpopHeight: string = '350px';

  public departmentmode: string;
  public departmentfilterPlaceholder: string;
  public departmentFields: Object = { text: 'name', value: 'id' };
  public departmentWaterMark: string = 'Department-All';
  public departmentpopHeight: string = '350px';

  departmentListParameters: MainListParameters = new MainListParameters();
  branchListParameters: MainListParameters = new MainListParameters();
  departmentList: any[] = [];
  branchList: any[] = [];

  state: DataStateChangeEventArgs = {
    skip: 0,
    take: 20,
    sorted: [],
    group: [],
  };

  comparitiveReportParamerter: ComparitiveReportParamerters;

  selectedBranchValues: any[] = [];
  selectedDepartmentValues: any[] = [];

  comparitiveReportTypeList: any[] = [
    {
      value: ComparitiveReportType.TrialBalance,
      name: 'Trial Balance',
    },
    {
      value: ComparitiveReportType.ProfitAndLoss,
      name: 'Profit & Loss',
    },
    {
      value: ComparitiveReportType.BalanceSheet,
      name: 'Balance Sheet',
    },
  ];

  comparitiveReportPeriodList: any[] = [
    {
      value: 0,
      name: 'Select Option',
    },
    {
      value: DateFilterType.Monthly,
      name: DateFilterType[DateFilterType.Monthly],
    },
    {
      value: DateFilterType.Quarterly,
      name: DateFilterType[DateFilterType.Quarterly],
    },
    {
      value: DateFilterType.Yearly,
      name: DateFilterType[DateFilterType.Yearly],
    },
    {
      value: DateFilterType.Custom,
      name: DateFilterType[DateFilterType.Custom],
    },
  ];

  comparitivePeriod: any = 0;
  comparitiveType: any = ComparitiveReportType.TrialBalance;
  cboyear: any;
  cbomultiyear: any[] = [];
  accountingPeriodList: any = [];
  multiaccountingPeriodList: any = [];
  customPeriodList: any = [];
  totalRecords = 0;

  store: Store;
  commonService: CommonService;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.calculateGridHeight();
  }

  @ViewChild('divdata') divdata: ElementRef;

  constructor(
    private injector: Injector,
    private dialog: MatDialog,
    private globalComponent: GlobalComponent,
    public spinner: NgxSpinnerService,
    public datepipe: DatePipe,
    public notify: NotificationService
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.getBranchList();
    this.yearmode = 'CheckBox';
    this.yearfilterPlaceholder = 'Select Accounting Period';
    this.branchmode = 'CheckBox';
    this.branchfilterPlaceholder = 'Search Branch';
    this.departmentmode = 'CheckBox';
    this.departmentfilterPlaceholder = 'Search Department';
  }

  getBranchParamaters(): any {
    const queryParams = {
      sortBy: this.branchListParameters.sortBy,
      sortOrder: this.branchListParameters.sortOrder,
      search: this.branchListParameters.search,
    };

    return queryParams;
  }

  getBranchList(): void {
    this.store
      .dispatch(new GetBranchList(this.getBranchParamaters()))
      .pipe(
        tap((res) => {
          this.branchList = [];
          res.company.branch.forEach((element) => {
            this.branchList.push(element);
          });
        })
      )
      .subscribe();
  }

  onBranchMultiSelectChange(event: any): void {
    this.selectedBranchValues = event.value;
    if (this.selectedBranchValues.length > 0) {
      this.getDepartmentListByBranchIds();
    } else {
      this.departmentList = [];
      this.selectedDepartmentValues = [];
    }

    this.onChangeBranchDepartment();
  }

  getDepartmentListByBranchIds(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetDepartmentListByBranchIds(this.selectedBranchValues))
      .subscribe((res) => {
        this.spinner.hide();
        this.departmentList = [];
        if (res.company.departmentListbyIds.length > 0) {
          res.company.departmentListbyIds.forEach((element) => {
            if (this.departmentList.length === 0) {
              this.departmentList.push(element);
            } else {
              const isExist = this.departmentList.filter(
                (x) => x.id === element.id
              );
              if (isExist.length === 0) {
                this.departmentList.push(element);
              }
            }
          });
        }
      });
  }

  onDepartmentMultiSelectChange(event: any): void {
    this.selectedDepartmentValues = event.value;

    this.onChangeBranchDepartment();
  }

  onChangeBranchDepartment(): void {
    this.getList();
  }

  export(format: number, isPrint: boolean = false): void {
    this.spinner.show();

    if (this.totalRecords > 0) {
      const queryParams = this.getParamter(format, isPrint);
      this.store
        .dispatch(new GetComparitiveReportExport(queryParams))
        .subscribe(() => {});
    } else {
      this.spinner.hide();
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noDataToExport
      );
    }
  }

  sendMail(): void {
    if (this.totalRecords > 0) {
      this.dialog
        .open(ShareComponent, {
          data: {
            sendMailParamsRequest: this.getParamter(ExportType.PDF),
          },
        })
        .afterClosed()
        .subscribe();
    } else {
      this.spinner.hide();
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noDataToEmail
      );
    }
  }

  redirect(columnName: string, data: any): void {
    if (data.moduleId > 0) {
      if (data.moduleId === Modules.AddCustomAccounts) {
        this.commonService.onEditRouting(
          true,
          Modules.ReportAccountDetail,
          data.id,
          false
        );
        this.commonService.setPermission(
          RoutingPath.ReportAccountDetailsPermission,
          data.id
        );
      } else if (
        data.moduleId === Modules.Customers ||
        data.moduleId === Modules.Suppliers
      ) {
        this.commonService.onEditRouting(true, data.moduleId, data.id);
      } else if (this.commonService.isAllowedOpenView(data.moduleId)) {
        this.commonService.openView(data.id, data.moduleId);
      } else {
        this.commonService.onEditRouting(true, data.moduleId, data.id);
      }
    } else if (data.moduleId > 0) {
      this.commonService.onEditRouting(
        true,
        Modules.BankImportTransactionHistory,
        data.id,
        true,
        Modules.BankImportTransactionHistory
      );
    }
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight;
    const headerHeight = 56;
    const gridHeaderHeight = 75;
    const tableHeaderHeight = 56;
    const cardFooterHeight = 116;
    const accountDetailsHeight = 400;
    const contentPadding = 15;
    const groupableSectionHeight = 48;
    const gridHeaderTitle = 34;
    let paginationHeight = 0;

    this.gridHeight =
      screenHeight -
      (headerHeight +
        gridHeaderHeight +
        tableHeaderHeight +
        contentPadding +
        groupableSectionHeight +
        gridHeaderTitle +
        paginationHeight);
  }

  typeChange() {
    if (this.comparitivePeriod !== null) {
      if (
        this.comparitivePeriod === DateFilterType.Monthly ||
        this.comparitivePeriod === DateFilterType.Quarterly ||
        this.comparitivePeriod === DateFilterType.Yearly ||
        this.comparitivePeriod === DateFilterType.Custom
      ) {
        this.getList();
      }
    }
  }

  onPeriodChange() {
    this.columns = [];
    this.gridData = [];
    this.tempGridData = [];
    this.totalRecords = 0;
    this.accountingPeriodList = [];
    this.multiaccountingPeriodList = [];
    this.cboyear = null;
    let SelectedOption = this.comparitivePeriod;
    if (
      SelectedOption == DateFilterType.Monthly ||
      SelectedOption == DateFilterType.Quarterly
    ) {
      this.accountingPeriodList = this.globalComponent.getFinancialPeriod();
      this.multiaccountingPeriodList = [];
    } else if (SelectedOption == DateFilterType.Yearly) {
      this.multiaccountingPeriodList =
        this.globalComponent.getFinancialPeriod();
      this.accountingPeriodList = [];
    } else if (SelectedOption == DateFilterType.Custom) {
      this.trialDialog();
    } else {
      this.accountingPeriodList = [];
      this.multiaccountingPeriodList = [];
    }
  }

  trialDialog(): void {
    this.dialog
      .open(CustomPeriodPopupComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.customPeriodList = result;
          this.getList();
        }
      });
  }

  onYearChange(event?: any): any {
    const selectedOption = this.comparitivePeriod;

    if (this.comparitivePeriod === DateFilterType.Yearly) {
      this.cbomultiyear = event.value;
    }

    this.getList();
  }

  customizeYear(): void {
    const selectedOption = this.comparitivePeriod;
    const selectedYear = [];
    const defaultYear = [0];
  }
  getList(isGridActionsSet: boolean = false): void {
    this.columns = [];
    this.gridData = [];
    this.tempGridData = [];
    this.totalRecords = 0;

    const params = this.getParamter();

    this.spinner.show();

    this.store.dispatch(new GetComparitiveReport(params)).subscribe((res) => {
      this.reportColumnList = [];

      let columnName = res.common.mainList.resultSet.columns;
      let rowData = res.common.mainList.resultSet.data;

      this.addNewLineItem(columnName, rowData);

      let filteredColumnList;
      const excludedNames = ['ExtraValue', 'srNo'];

      filteredColumnList = columnName.filter(
        (i) => !excludedNames.includes(i.name)
      );

      const colArray: any = [];
      filteredColumnList.forEach((element, i) => {
        colArray.push({
          name: element.name,
          id: element.id,
          userId: element.userId,
          dataType: element.dataType,
          allowSortBy: element.allowSortBy,
          alignment: element.alignment,
          moduleId: element.moduleId,
          isSelected: element.name === 'Id' ? false : element.isSelected,
          isLocked: element.isLocked,
          moduleGridColumnId: element.moduleGridColumnId,
          columnType: this.getDataType(element.dataType),
        });
      });

      const filteredGridList = rowData.map((element) => {
        let e = {};

        Object.keys(element).forEach((key) => {
          const newKey = columnName[key]?.name;
          e[newKey] = element[key];
        });
        return e;
      });

      this.reportColumnList = rowData.map((element, i) => {
        let e = {};
        Object.keys(element).forEach((key, j) => {
          const newKey = columnName[key]?.name;
          e[newKey] = element[j];
        });
        return e;
      });

      this.spinner.hide();
      this.tempGridData = filteredGridList;
      if (this.columns.length > 0 && this.columns.length !== colArray.length) {
        this.columns = [];
        this.spinner.show();
        setTimeout(() => {
          this.columns = colArray;
          this.spinner.hide();
        }, 0);
      } else if (this.columns.length === 0) {
        this.columns = colArray;
      }

      this.totalRecords = res.common.mainList.paginationModel.totalItemCount;

      this.state.take = this.totalRecords;

      this.gridData = {
        result: this.reportColumnList,
        count: this.totalRecords,
      };
    });
  }

  getParamter(format?: number, isPrint: boolean = false): any {
    let accountingPeriods: AccountingPeriodModel[];
    accountingPeriods = [];

    if (
      this.comparitivePeriod === DateFilterType.Monthly ||
      this.comparitivePeriod === DateFilterType.Quarterly
    ) {
      accountingPeriods.push({
        toDate:
          this.datepipe
            .transform(this.cboyear.toDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        fromDate:
          this.datepipe
            .transform(this.cboyear.fromDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
      });
    } else if (this.comparitivePeriod === DateFilterType.Yearly) {
      accountingPeriods = this.multiaccountingPeriodList.filter((x) =>
        this.cbomultiyear.includes(x.id)
      );
    } else if (this.comparitivePeriod === DateFilterType.Custom) {
      accountingPeriods = this.customPeriodList;
    }

    const queryParams: ComparitiveReportParamerters = {
      type: this.comparitiveType,
      option: this.comparitivePeriod,
      accountingPeriods: accountingPeriods,
      branchIds: this.selectedBranchValues,
      departmentIds: this.selectedDepartmentValues,
      format: format,
      isPrint: isPrint,
      modId: Modules.ComparativeReport,
    };

    return queryParams;
  }

  addNewLineItem(columnName, rowData): void {
    if (!columnName.some((e) => e.name === 'srNo')) {
      columnName.push({
        name: 'srNo',
        id: null,
        userId: null,
        dataType: 1,
        allowSortBy: true,
        alignment: null,
        isSelected: false,
        isLocked: false,
        moduleGridColumnId: 1,
        columnType: this.getDataType(1),
      });
      rowData = rowData.map(function (currentValue, index) {
        let extraField: any;
        extraField = {
          id: null,
          alignment: null,
          isBold: false,
          moduleId: 0,
          rowData: index,
        };

        currentValue.push(extraField);
        return currentValue;
      });
    }
  }

  getDataType(type: number): any {
    let dataType = '';

    switch (type) {
      case DataType.String:
        dataType = 'text';
        break;

      case DataType.Int32:
      case DataType.Int64:
      case DataType.Float:
      case DataType.Currency:
        dataType = `numeric`;
        break;

      case DataType.Bool:
        dataType = `boolean`;
        break;

      case DataType.Date:
      case DataType.DateTime:
        dataType = `date`;
        break;
      default:
        dataType = 'text';
        break;
    }

    return dataType;
  }

  getClassName(col) {
    return col?.dataType === DataType.Currency ||
      col?.dataType === DataType.CurrencySymbol
      ? 'e-rightamount'
      : 'no-class';
  }
}
