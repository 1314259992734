import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import {
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { FixedAssetDepriciationModel } from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import { GetFixedAssetRegisterList, SaveDepreciation } from '@app/core/Store';
import { ConfirmationBoxComponent } from '@app/modules';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-depreciation',
  templateUrl: './depreciation.component.html',
  styleUrls: ['./depreciation.component.scss'],
})
export class DepreciationComponent implements OnInit {
  fixedAssetRegisterList: any;
  displayedColumns: string[] = ['name', 'amount', 'depreciationAmount'];
  fixedAssetDepriciationData: FixedAssetDepriciationModel[] = [];

  moduleId = Modules.FixedAssetsRegister;
  isAllSelected = false;
  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
    private notify: NotificationService,
    public commonService: CommonService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getList();
  }

  getList(): void {
    this.store.dispatch(new GetFixedAssetRegisterList()).subscribe((res) => {
      this.fixedAssetRegisterList =
        res.fixedAssetRegister.fixedAssetRegisterList;
    });
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.fixedAssetRegisterList.forEach((element) => {
        if (element.isSelected) {
          this.fixedAssetDepriciationData.push({
            id: element.id,
            amount: +element.depriciationAmount,
          });
        }
      });
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(): void {
    if (this.dataSubmit()) {
      this.store
        .dispatch(new SaveDepreciation(this.fixedAssetDepriciationData))
        .subscribe((res) => {
          if (res !== null && res !== undefined) {
            this.notify.success(
              NotificationHeader.success,
              NotificationTextMessage.successMessage
            );
          } else {
            this.notify.error(
              NotificationHeader.error,
              NotificationTextMessage.errorMessage
            );
          }
        });
    }
  }

  onCancel(): void {
    this.fixedAssetRegisterList.forEach((x) => (x.isSelected = false));
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  selectAll(event: any): void {
    this.fixedAssetRegisterList.forEach((x) => (x.isSelected = event.checked));
  }
}
