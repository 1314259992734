<div class="import-stepper mt-2">
  <mat-stepper [stepControl]="uploadFormGroup" [linear]="false" #stepper>
    <mat-step disableRipple>
      <ng-template matStepLabel>Upload</ng-template>
      <form [formGroup]="uploadFormGroup">
        <div class="upload-detail">
          <p class="import-title text-primary d-flex mt-1 mb-0 pb-5">
            {{ importHeaderText }}
          </p>
          <span class="import-title mt-0 font-size-14">Choose a Type</span>
          <div class="import-leads mt-0">
            <div class="file-box">
              <div class="d-flex align-items-center gap-10">
                <img src="assets/images/upload-icon.svg" alt="Icon" />
                <p class="m-0">From File</p>
              </div>
              <div class="drop-detail">
                <p>Drag and drop files to upload</p>
                <h4><span>or</span></h4>
                <button class="file-upload-button primary-button">
                  <input
                    type="file"
                    #fileInput
                    accept=".xls, .xlsx"
                    (click)="onFileClick($event)"
                    (change)="onFileSelected(fileInput)"
                  />Browse
                </button>
                <p class="file-info">{{ fileInfo }}</p>
                <div
                  class="d-flex align-items-center justify-content-center gap-5 cursor-pointer"
                  (click)="downloadTemplateFile()"
                  *ngIf="fileInfo === ''"
                >
                  <mat-icon fontSet="material-icons-outlined"
                    >file_download</mat-icon
                  >
                  <p class="m-0 link">Download sample file</p>
                </div>
                <div class="file-formate">
                  <p>File Format : XLSX</p>
                  <p>Max Size : 10 MB</p>
                </div>
              </div>
            </div>
            <div class="or-text" *ngIf="moduleId === modules.Clients">
              <span>or</span>
            </div>
            <div class="other-box" *ngIf="moduleId === modules.Clients">
              <div class="d-flex align-items-center gap-10">
                <img src="assets/images/time-icon.svg" alt="Icon" />
                <p class="m-0">From Other Capium Modules</p>
              </div>
              <div class="pt-1 d-flex gap-40">
                <div class="connect-wraper">
                  <img src="assets/images/connect-img.svg" alt="Connect" />
                  <div class="connect-details">
                    <p>
                      Want to import {{ moduleName }} from any of your other
                      Capium modules?
                    </p>
                  </div>
                </div>
                <div>
                  <div class="form-field">
                    <p class="fw-normal">Module</p>
                    <div class="d-flex align-items-start gap-15">
                      <ng-select
                        class="w-210 import-dropdown"
                        panelClass="myPanelClass"
                        [disableOptionCentering]="true"
                        #module
                        (focus)="module.open()"
                        (valueChange)="module.close()"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="selectedAddonId"
                        (change)="onModuleSelection()"
                      >
                        <ng-option [value]="'0'">--Select--</ng-option>
                        <ng-option
                          *ngFor="
                            let subscriptionModule of subscriptionModuleList
                          "
                          [value]="subscriptionModule.addonId"
                        >
                          {{ subscriptionModule.externalName }}</ng-option
                        >
                      </ng-select>
                    </div>
                  </div>
                  <div class="form-field">
                    <p class="fw-normal">Client Type</p>
                    <div class="d-flex align-items-start gap-15">
                      <ng-select
                        class="w-210 import-dropdown"
                        panelClass="myPanelClass"
                        [disableOptionCentering]="true"
                        #modulecl
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="selectedClientType"
                      >
                        <ng-option
                          *ngFor="let cl of dropdownList"
                          [value]="cl.name"
                          >{{ cl.name }}</ng-option
                        >
                      </ng-select>
                    </div>
                  </div>
                  <div class="form-field horizontal-radio-button d-none">
                    <form [formGroup]="formRecurringImport">
                      <mat-radio-group formControlName="estimatesAutoGenId">
                        <div class="d-flex align-center gap-10">
                          <mat-radio-button
                            value="1"
                            [checked]="true"
                            (change)="onSelectionChange('1')"
                            disableRipple
                          >
                            One Time Import</mat-radio-button
                          >
                          <mat-radio-button
                            value="2"
                            [disabled]="true"
                            (change)="onSelectionChange('2')"
                            >Recurring Import</mat-radio-button
                          >
                        </div>
                        <div
                          class="d-flex justify-space-between setting-details pt-10"
                          *ngIf="isRecurringImport"
                        >
                          <div>
                            <p class="prefix">Schedule</p>
                            <div>
                              <div
                                class="form-field-wrapper d-flex align-items-center gap-10"
                              >
                                <div
                                  class="schedule-list d-flex align-items-center gap-10"
                                >
                                  <ng-select
                                    class="w-150"
                                    formControlName="recurringType"
                                  >
                                    <ng-option
                                      *ngFor="let type of recurringTypeList"
                                      [value]="type.value"
                                    >
                                      {{ type.name }}
                                    </ng-option>
                                  </ng-select>
                                  <p
                                    class="m-0"
                                    *ngIf="
                                      formRecurringImport.controls.recurringType
                                        .value !== recurringType.Daily &&
                                      formRecurringImport.controls.recurringType
                                        .value !== recurringType.Monthly
                                    "
                                  >
                                    every
                                  </p>
                                  <p
                                    class="m-0"
                                    *ngIf="
                                      formRecurringImport.controls.recurringType
                                        .value === recurringType.Monthly
                                    "
                                  >
                                    on the
                                  </p>
                                </div>
                                <div
                                  class="schedule-list d-flex align-items-center gap-10"
                                  *ngIf="
                                    formRecurringImport.controls.recurringType
                                      .value === recurringType.Monthly
                                  "
                                >
                                  <ng-select
                                    class="w-150"
                                    [disableOptionCentering]="true"
                                    formControlName="dayOfMonth"
                                  >
                                    <ng-option
                                      *ngFor="let day of dayList"
                                      [value]="day.value"
                                      >{{ day.lable }}
                                    </ng-option>
                                  </ng-select>
                                  <p class="m-0">Day of The Month</p>
                                </div>

                                <div
                                  class="schedule-list d-flex align-items-center gap-10"
                                  *ngIf="
                                    formRecurringImport.controls.recurringType
                                      .value === recurringType.Weekly
                                  "
                                >
                                  <ng-select
                                    class="w-150"
                                    [disableOptionCentering]="true"
                                    formControlName="dayOfWeek"
                                  >
                                    <ng-option
                                      *ngFor="
                                        let week of weekList;
                                        let i = index
                                      "
                                      [value]="i + 1"
                                      >{{ week }}
                                    </ng-option>
                                  </ng-select>
                                </div>

                                <div
                                  class="schedule-list d-flex align-items-center gap-10"
                                  *ngIf="
                                    formRecurringImport.controls.recurringType
                                      .value === recurringType.Yearly
                                  "
                                >
                                  <ng-select
                                    class="w-150"
                                    [disableOptionCentering]="true"
                                    formControlName="monthOfYear"
                                  >
                                    <ng-option
                                      *ngFor="
                                        let month of monthList;
                                        let i = index
                                      "
                                      [value]="i + 1"
                                      >{{ month }}
                                    </ng-option>
                                  </ng-select>
                                  <p
                                    class="m-0"
                                    *ngIf="
                                      formRecurringImport.controls.recurringType
                                        .value === recurringType.Yearly
                                    "
                                  >
                                    on the
                                  </p>
                                </div>
                              </div>
                              <div
                                class="form-field-wrapper d-flex align-items-center gap-10"
                              >
                                <div
                                  class="schedule-list d-flex align-items-center gap-10 mt-1"
                                  *ngIf="
                                    formRecurringImport.controls.recurringType
                                      .value === recurringType.Yearly
                                  "
                                >
                                  <ng-select
                                    class="w-150"
                                    [disableOptionCentering]="true"
                                    formControlName="dayOfMonth"
                                  >
                                    <ng-option
                                      *ngFor="let day of dayList"
                                      [value]="day.value"
                                      >{{ day.lable }}
                                    </ng-option>
                                  </ng-select>
                                  <p class="m-0">Day of The Month</p>
                                </div>
                              </div>
                              <div
                                class="form-field-wrapper d-flex align-items-center gap-10"
                              >
                                <div
                                  class="schedule-list d-flex align-items-center gap-10 mt-1"
                                >
                                  <p class="m-0">at</p>
                                  <ng-select
                                    class="w-120"
                                    formControlName="timesheetDueTime"
                                  >
                                    <ng-option
                                      *ngFor="let hour of timeHours"
                                      [value]="hour.id"
                                      >{{ hour.value }}</ng-option
                                    >
                                  </ng-select>
                                </div>
                              </div>
                              <div
                                class="form-field-wrapper d-flex align-items-center gap-10"
                              >
                                <div
                                  class="schedule-list d-flex align-items-center gap-10 mt-1"
                                >
                                  <a
                                    href="javascript:void(0)"
                                    class="d-flex align-items-center gap-5 text-decoration-none text-red mt-1"
                                  >
                                    End this Recurring
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-radio-group>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step disableRipple>
      <ng-template matStepLabel>Verified Data</ng-template>
      <div class="content-body mt-2 pl-5 pr-5">
        <mat-accordion [multi]="true">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon fontSet="material-icons-outlined" class="text-primary"
                  >description</mat-icon
                >
                <div class="d-flex flex-column">
                  <span
                    >Success List ({{ importSuccessList.data.length }})</span
                  >
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="content-body">
              <div class="mat-table-wrapper">
                <table
                  mat-table
                  aria-describedby="Import Success List"
                  [dataSource]="importSuccessList"
                >
                  <!-- #region Clients -->
                  <ng-container matColumnDef="companyName">
                    <th mat-header-cell *matHeaderCellDef class="w-170">
                      Company Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div
                        class="onelineclamp"
                        title="{{ element.companyName }}"
                      >
                        {{ element.companyName }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="companyType">
                    <th mat-header-cell *matHeaderCellDef>Client Type</th>
                    <td mat-cell *matCellDef="let element">
                      <div
                        class="onelineclamp"
                        title="{{ clientType[element.clientTypeId] }}"
                      >
                        {{ clientType[element.clientTypeId] }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="registrationNumber">
                    <th mat-header-cell *matHeaderCellDef>
                      Registration Number
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.registrationNumber }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef>Address</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp" title="{{ element.address }}">
                        {{ element.address }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="postalCode">
                    <th mat-header-cell *matHeaderCellDef>Postal Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.postalCode }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef>City</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp" title="{{ element.city }}">
                        {{ element.city }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="county">
                    <th mat-header-cell *matHeaderCellDef>County</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp" title="{{ element.county }}">
                        {{ element.county }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef>Country</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp" title="{{ element.country }}">
                        {{ element.country }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Title
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.title }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="contactName">
                    <th mat-header-cell *matHeaderCellDef>Contact Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div
                        class="onelineclamp"
                        title="{{ element.contactName }}"
                      >
                        {{ element.contactName }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Email
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.email }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="phoneNumber">
                    <th mat-header-cell *matHeaderCellDef>Phone Number</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.phoneNumber }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="clientCode">
                    <th mat-header-cell *matHeaderCellDef class="w-170">
                      Client Id
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.clientCode }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="businessStartDate">
                    <th mat-header-cell *matHeaderCellDef>
                      Business Start Date
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.businessStartDate | date: "dd-MMM-yyyy" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bookStartDate">
                    <th mat-header-cell *matHeaderCellDef>Book Start Date</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.bookStartDate | date: "dd-MMM-yyyy" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="yearEndDate">
                    <th mat-header-cell *matHeaderCellDef>Year End Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      {{ element.yearEndDate }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="yearEndMonth">
                    <th mat-header-cell *matHeaderCellDef>Year End Month</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      {{ monthList[element.yearEndMonth - 1] }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="utrnumber">
                    <th mat-header-cell *matHeaderCellDef>UTR No.</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.utrnumber }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatschemeId">
                    <th mat-header-cell *matHeaderCellDef>Vat Scheme</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <div class="onelineclamp">
                        {{
                          element.vatschemeId && element.vatschemeId !== null
                            ? getVatName(+element.vatschemeId)
                            : ""
                        }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatregNumber">
                    <th mat-header-cell *matHeaderCellDef>VAT Reg. No.</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.vatregNumber }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatregistrationDate">
                    <th mat-header-cell *matHeaderCellDef>
                      VAT Registration Date
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.vatregistrationDate | date: "dd-MMM-yyyy" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatsubmitTypeId">
                    <th mat-header-cell *matHeaderCellDef>VAT Submit Type</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      {{ getVATSubmitValue(element.vatsubmitTypeId) }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="accountOfficeRefNumber">
                    <th mat-header-cell *matHeaderCellDef>
                      Account Office Ref No.
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.accountOfficeRefNumber }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="payeRefNumber">
                    <th mat-header-cell *matHeaderCellDef>PAYE Ref No.</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.payeRefNumber }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="isShowVat">
                    <th mat-header-cell *matHeaderCellDef>Is Show VAT</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.isShowVat }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="nationalInsuranceNo">
                    <th mat-header-cell *matHeaderCellDef>
                      National Insurance Number
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.nationalInsuranceNo }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="dateOfBirth">
                    <th mat-header-cell *matHeaderCellDef>Date of Birth</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.dateOfBirth | date: "dd-MMM-yyyy" }}
                    </td>
                  </ng-container>
                  <!-- #endregion -->

                  <!-- #region Tasks -->
                  <ng-container matColumnDef="estimatedTime">
                    <th mat-header-cell *matHeaderCellDef>Estimated Time</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.estimatedTime }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="defaultFeesType">
                    <th mat-header-cell *matHeaderCellDef>Default Fees Type</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.defaultFeesType }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="fixedFeeAmount">
                    <th mat-header-cell *matHeaderCellDef>Fixed Fee Amount</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.fixedFeeAmount }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="timeAndFeesAmount">
                    <th mat-header-cell *matHeaderCellDef>
                      Time And Fees Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.timeAndFeesAmount }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask1Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 1 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask1Name }}</div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask1Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 1 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask1Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask2Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 2 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask2Name }}</div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask2Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 2 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask2Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask3Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 3 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask3Name }}</div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask3Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 3 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask3Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask4Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 4 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask4Name }}</div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask4Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 4 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask4Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask5Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 5 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask5Name }}</div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask5Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 5 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask5Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask6Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 6 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask6Name }}</div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask6Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 6 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask6Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask7Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 7 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask7Name }}</div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask7Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 7 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask7Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask8Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 8 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask8Name }}</div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask8Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 8 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask8Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask9Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 9 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask9Name }}</div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask9Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 9 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask9Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask10Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 10 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask10Name }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="subTask10Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 10 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask10Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>
                  <!-- #endregion -->

                  <!-- #region Users -->
                  <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef>First Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.firstName }}</div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="middleName">
                    <th mat-header-cell *matHeaderCellDef>Middle Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.middleName }}</div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef>Last Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.lastName }}</div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="capacity">
                    <th mat-header-cell *matHeaderCellDef>Capacity</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.capacity }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="billableRate">
                    <th mat-header-cell *matHeaderCellDef>Billable Rate</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.billableRate }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="costRate">
                    <th mat-header-cell *matHeaderCellDef>Cost Rate</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.costRate }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="permission">
                    <th mat-header-cell *matHeaderCellDef>Permission</th>
                    <th></th>
                    <td mat-cell *matCellDef="let element">
                      <div
                        class="onelineclamp"
                        title="{{ authorizedRole[element.permission] }}"
                      >
                        {{ authorizedRole[element.permission] }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>
                  <!-- #endregion -->

                  <!-- #region Expense Types -->
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.name }}</div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="hasUnitPrice">
                    <th mat-header-cell *matHeaderCellDef>Has Unit Price</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.hasUnitPrice ? "Yes" : "No" }}
                      </div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>
                  <!-- #endregion -->
                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="importErrorList.data.length > 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon fontSet="material-icons-outlined" class="text-primary"
                  >error_outline</mat-icon
                >
                <div class="d-flex flex-column">
                  <span>Error List ({{ importErrorList.data.length }})</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="content-body">
              <div class="mat-table-wrapper">
                <table
                  mat-table
                  aria-describedby="Import Error List"
                  [dataSource]="importErrorList"
                >
                  <!-- #region Clients -->
                  <ng-container matColumnDef="companyName">
                    <th mat-header-cell *matHeaderCellDef class="w-170">
                      Company Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        [ngClass]="
                          element.isInValidCompanyName ? 'error-field' : ''
                        "
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.companyName"
                          [disabled]="!element.isInValidCompanyName"
                          required
                          (focusout)="checkClientValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="companyType">
                    <th mat-header-cell *matHeaderCellDef>Client Type</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #clientType
                        [disableOptionCentering]="true"
                        (focus)="clientType.open()"
                        (valueChange)="clientType.close()"
                        [(ngModel)]="element.clientTypeId"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.isInValidCompanyType"
                        required
                        (change)="checkClientValidElement(element, i)"
                      >
                        <ng-option
                          *ngFor="let type of clientTypeList"
                          [value]="+type.id"
                        >
                          {{ type.name }}</ng-option
                        >
                      </ng-select>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="registrationNumber">
                    <th mat-header-cell *matHeaderCellDef>
                      Registration Number
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        [ngClass]="
                          element.isInValidRegistrationNumber
                            ? 'error-field'
                            : ''
                        "
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.registrationNumber"
                          [disabled]="!element.isInValidRegistrationNumber"
                          (focusout)="checkClientValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef>Address</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field
                        [ngClass]="
                          element.isInValidAddress ? 'error-field' : ''
                        "
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.address"
                          [disabled]="!element.isInValidAddress"
                          maxlength="1000"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="postalCode">
                    <th mat-header-cell *matHeaderCellDef>Postal Code</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field
                        [ngClass]="
                          element.isInValidPostalCode ? 'error-field' : ''
                        "
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.postalCode"
                          [disabled]="!element.isInValidPostalCode"
                          maxlength="16"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef>City</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.city"
                          [disabled]="!element.isInValidCity"
                          maxlength="100"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="county">
                    <th mat-header-cell *matHeaderCellDef>County</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.county"
                          [disabled]="!element.isInValidCounty"
                          maxlength="100"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef>Country</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.country"
                          [disabled]="!element.isInValidCountry"
                          maxlength="100"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Title
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        class="w-80"
                        #title
                        (focus)="title.open()"
                        (valueChange)="title.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.title"
                        [disabled]="!element.isInValidTitle"
                        panelClass="mat-select-position"
                        appendTo="body"
                        required
                      >
                        <ng-option
                          *ngFor="let prefix of titleList"
                          [value]="prefix.id"
                          >{{ prefix.name }}</ng-option
                        >
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="contactName">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Contact Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        [ngClass]="
                          element.isInValidContactName ? 'error-field' : ''
                        "
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.contactName"
                          [disabled]="!element.isInValidContactName"
                          maxlength="255"
                          required
                          (focusout)="checkClientValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Email
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        [ngClass]="element.isInValidEmail ? 'error-field' : ''"
                      >
                        <input
                          matInput
                          emailValidator
                          [(ngModel)]="element.email"
                          (focusout)="checkEmailValid(element, i)"
                          autocomplete="off"
                          maxlength="320"
                          type="email"
                          [disabled]="!element.isInValidEmail"
                          required
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="phoneNumber">
                    <th mat-header-cell *matHeaderCellDef>Phone Number</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        [ngClass]="element.isInValidPhone ? 'error-field' : ''"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.phoneNumber"
                          [disabled]="!element.isInValidPhone"
                          (paste)="validateInput($event)"
                          (keypress)="keyPress($event)"
                          minlenght="10"
                          maxlength="15"
                          (focusout)="checkPhoneNumber(element, i)"
                          required
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="clientCode">
                    <th mat-header-cell *matHeaderCellDef>Client Id</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        [ngClass]="
                          element.isInValidClientCode ? 'error-field' : ''
                        "
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.clientCode"
                          [disabled]="!element.isInValidClientCode"
                          (focusout)="checkClientValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="businessStartDate">
                    <th mat-header-cell *matHeaderCellDef>
                      Business Start Date
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        (click)="picker1.open()"
                        appearance="fill"
                        [ngClass]="
                          element.isInValidBusinessStartDate
                            ? 'error-field'
                            : ''
                        "
                      >
                        <input
                          matInput
                          readonly
                          cdkFocusInitial
                          [matDatepicker]="picker1"
                          autocomplete="off"
                          [(ngModel)]="element.businessStartDate"
                          [disabled]="!element.isInValidBusinessStartDate"
                          (dateChange)="checkClientValidElement(element, i)"
                          [max]="element.bookStartDate"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker1"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bookStartDate">
                    <th mat-header-cell *matHeaderCellDef>Book Start Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        (click)="picker2.open()"
                        appearance="fill"
                        [ngClass]="
                          element.isInValidBookStartDate ? 'error-field' : ''
                        "
                      >
                        <input
                          matInput
                          readonly
                          cdkFocusInitial
                          [matDatepicker]="picker2"
                          autocomplete="off"
                          [(ngModel)]="element.bookStartDate"
                          [disabled]="!element.isInValidBookStartDate"
                          (dateChange)="checkClientValidElement(element, i)"
                          [min]="element.businessStartDate"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker2"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="yearEndDate">
                    <th mat-header-cell *matHeaderCellDef>Year End Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #yearEndDateSelection
                        (focus)="yearEndDateSelection.open()"
                        (valueChange)="yearEndDateSelection.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.yearEndDate"
                        [disabled]="!element.isInValidYearEndDate"
                        panelClass="mat-select-position"
                        appendTo="body"
                        (change)="checkClientValidElement(element, i)"
                      >
                        <ng-option
                          *ngFor="let day of yearEndDayList"
                          [value]="day.value"
                          >{{ day.lable }}
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="yearEndMonth">
                    <th mat-header-cell *matHeaderCellDef>Year End Month</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #yearEndMonthSelection
                        (focus)="yearEndMonthSelection.open()"
                        (valueChange)="yearEndMonthSelection.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.yearEndMonth"
                        [disabled]="!element.isInValidYearEndMonth"
                        panelClass="mat-select-position"
                        appendTo="body"
                        (change)="checkClientValidElement(element, i)"
                      >
                        <ng-option
                          *ngFor="let month of monthList; let i = index"
                          [value]="i + 1"
                          >{{ month }}
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="utrnumber">
                    <th mat-header-cell *matHeaderCellDef>UTR No.</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        [ngClass]="
                          element.isInValidUtrnumber ? 'error-field' : ''
                        "
                      >
                        <input
                          matInput
                          autocomplete="off"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          maxlength="10"
                          [(ngModel)]="element.utrnumber"
                          [disabled]="!element.isInValidUtrnumber"
                          (focusout)="checkClientValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatschemeId">
                    <th mat-header-cell *matHeaderCellDef>Vat Scheme</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #vatScheme
                        (focus)="vatScheme.open()"
                        (valueChange)="vatScheme.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.vatschemeId"
                        [disabled]="!element.isInValidVatschemeId"
                        panelClass="mat-select-position"
                        appendTo="body"
                        (change)="checkClientValidElement(element, i)"
                      >
                        <ng-option
                          *ngFor="let vatScheme of vatSchemeList"
                          value="{{ vatScheme.id }}"
                          >{{ vatScheme.name }}
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatregNumber">
                    <th mat-header-cell *matHeaderCellDef>VAT Reg. No.</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        [ngClass]="
                          element.isInValidVatregNumber ? 'error-field' : ''
                        "
                      >
                        <input
                          matInput
                          autocomplete="off"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          maxlength="9"
                          [(ngModel)]="element.vatregNumber"
                          [disabled]="!element.isInValidVatregNumber"
                          (focusout)="checkClientValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatregistrationDate">
                    <th mat-header-cell *matHeaderCellDef>
                      VAT Registration Date
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        [ngClass]="
                          element.isInValidVatregistrationDate
                            ? 'error-field'
                            : ''
                        "
                        (click)="picker3.open()"
                        appearance="fill"
                      >
                        <input
                          matInput
                          readonly
                          cdkFocusInitial
                          [matDatepicker]="picker3"
                          autocomplete="off"
                          [(ngModel)]="element.vatregistrationDate"
                          [disabled]="!element.isInValidVatregistrationDate"
                          (dateChange)="checkClientValidElement(element, i)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker3"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatsubmitTypeId">
                    <th mat-header-cell *matHeaderCellDef>VAT Submit Type</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #vatSubmitType
                        (focus)="vatSubmitType.open()"
                        (valueChange)="vatSubmitType.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.vatsubmitTypeId"
                        [disabled]="!element.isInValidVatsubmitTypeId"
                        panelClass="mat-select-position"
                        appendTo="body"
                        (change)="checkClientValidElement(element, i)"
                      >
                        <ng-option
                          *ngFor="let type of vatSubmitTypeList"
                          value="{{ type.id }}"
                          >{{ type.name }}
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="accountOfficeRefNumber">
                    <th mat-header-cell *matHeaderCellDef>
                      Account Office Ref No.
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        [ngClass]="
                          element.isInValidAccountOfficeRefNumber
                            ? 'error-field'
                            : ''
                        "
                      >
                        <input
                          matInput
                          autocomplete="off"
                          maxlength="13"
                          [(ngModel)]="element.accountOfficeRefNumber"
                          [disabled]="!element.isInValidAccountOfficeRefNumber"
                          (focusout)="checkClientValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="payeRefNumber">
                    <th mat-header-cell *matHeaderCellDef>PAYE Ref No.</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        [ngClass]="
                          element.isInValidPayeRefNumber ? 'error-field' : ''
                        "
                      >
                        <input
                          matInput
                          autocomplete="off"
                          maxlength="12"
                          [(ngModel)]="element.payeRefNumber"
                          [disabled]="!element.isInValidPayeRefNumber"
                          (focusout)="checkClientValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="isShowVat">
                    <th mat-header-cell *matHeaderCellDef>Is Show VAT</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-checkbox
                        class="example-margin"
                        [disabled]="!element.isInValidIsShowVat"
                        [checked]="showVat(element.isShowVat)"
                      >
                      </mat-checkbox>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="nationalInsuranceNo">
                    <th mat-header-cell *matHeaderCellDef>
                      National Insurance Number
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        [ngClass]="
                          element.isInValidNationalInsuranceNo
                            ? 'error-field'
                            : ''
                        "
                      >
                        <input
                          #nationalInsuranceNumber
                          matInput
                          autocomplete="off"
                          maxlength="9"
                          [(ngModel)]="element.nationalInsuranceNo"
                          [disabled]="!element.isInValidNationalInsuranceNo"
                          (focusout)="checkClientValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="dateOfBirth">
                    <th mat-header-cell *matHeaderCellDef>Date of Birth</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        (click)="picker4.open()"
                        appearance="fill"
                        [ngClass]="
                          element.isInValidDateOfBirth ? 'error-field' : ''
                        "
                      >
                        <input
                          matInput
                          readonly
                          cdkFocusInitial
                          [matDatepicker]="picker4"
                          autocomplete="off"
                          [(ngModel)]="element.dateOfBirth"
                          [disabled]="!element.isInValidDateOfBirth"
                          (dateChange)="checkClientValidElement(element, i)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker4"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker4></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <!-- #endregion -->

                  <!-- #region Tasks -->
                  <ng-container matColumnDef="estimatedTime">
                    <th mat-header-cell *matHeaderCellDef>Estimated Time</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          appDurationMask
                          autocomplete="off"
                          [(ngModel)]="element.estimatedTime"
                          [disabled]="!element.isInValidEstimatedTime"
                          maxlength="12"
                          (focusout)="checkTaskValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="defaultFeesType">
                    <th mat-header-cell *matHeaderCellDef>Default Fees Type</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #defaultFeesType
                        [disableOptionCentering]="true"
                        (focus)="defaultFeesType.open()"
                        (valueChange)="defaultFeesType.close()"
                        [(ngModel)]="element.defaultFeesType"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.isInValidDefaultFeesType"
                        required
                      >
                        <ng-option [value]="1">Time and fees</ng-option>
                        <ng-option [value]="2">Fixed price</ng-option>
                        <ng-option [value]="3">Non billable</ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fixedFeeAmount">
                    <th mat-header-cell *matHeaderCellDef>Fixed Fee Amount</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          currencyMask
                          type="text"
                          autocomplete="off"
                          [maxDigits]="8"
                          appTwoDigitDecimalNumber
                          [(ngModel)]="element.fixedFeeAmount"
                          [disabled]="!element.isInValidFixedFeeAmount"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                          [options]="{ allowNegative: false }"
                          (focusout)="checkTaskValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="timeAndFeesAmount">
                    <th mat-header-cell *matHeaderCellDef>
                      Time And Fees Amount
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          currencyMask
                          type="text"
                          [(ngModel)]="element.timeAndFeesAmount"
                          [maxDigits]="8"
                          appTwoDigitDecimalNumber
                          [disabled]="!element.isInValidTimeAndFeesAmount"
                          [options]="{ allowNegative: false }"
                          (focusout)="checkTaskValidElement(element, i)"
                          autocomplete="off"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask1Name">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Sub Task 1 Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.subTask1Name"
                          [disabled]="!element.isInValidSubTask1Name"
                          (focusout)="checkTaskValidElement(element, i)"
                          maxlength="255"
                          required
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask1Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 1 Billable
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #subTask1Billable
                        [disableOptionCentering]="true"
                        (focus)="subTask1Billable.open()"
                        (valueChange)="subTask1Billable.close()"
                        [(ngModel)]="element.subTask1Billable"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.isInValidSubTask1Billable"
                        required
                      >
                        <ng-option [value]="true">Yes</ng-option>
                        <ng-option [value]="false">No</ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask2Name">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Sub Task 2 Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.subTask2Name"
                          [disabled]="!element.isInValidSubTask2Name"
                          (focusout)="checkTaskValidElement(element, i)"
                          maxlength="255"
                          required
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask2Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 2 Billable
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #subTask2Billable
                        [disableOptionCentering]="true"
                        (focus)="subTask2Billable.open()"
                        (valueChange)="subTask2Billable.close()"
                        [(ngModel)]="element.subTask2Billable"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.isInValidSubTask2Billable"
                        required
                      >
                        <ng-option [value]="true">Yes</ng-option>
                        <ng-option [value]="false">No</ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask3Name">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Sub Task 3 Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.subTask3Name"
                          [disabled]="!element.isInValidSubTask3Name"
                          (focusout)="checkTaskValidElement(element, i)"
                          maxlength="255"
                          required
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask3Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 3 Billable
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #subTask3Billable
                        [disableOptionCentering]="true"
                        (focus)="subTask3Billable.open()"
                        (valueChange)="subTask3Billable.close()"
                        [(ngModel)]="element.subTask3Billable"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.isInValidSubTask3Billable"
                        required
                      >
                        <ng-option [value]="true">Yes</ng-option>
                        <ng-option [value]="false">No</ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask4Name">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Sub Task 4 Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.subTask4Name"
                          [disabled]="!element.isInValidSubTask4Name"
                          (focusout)="checkTaskValidElement(element, i)"
                          maxlength="255"
                          required
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask4Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 4 Billable
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #subTask4Billable
                        [disableOptionCentering]="true"
                        (focus)="subTask4Billable.open()"
                        (valueChange)="subTask4Billable.close()"
                        [(ngModel)]="element.subTask4Billable"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.isInValidSubTask4Billable"
                        required
                      >
                        <ng-option [value]="true">Yes</ng-option>
                        <ng-option [value]="false">No</ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask5Name">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Sub Task 5 Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.subTask5Name"
                          [disabled]="!element.isInValidSubTask5Name"
                          (focusout)="checkTaskValidElement(element, i)"
                          maxlength="255"
                          required
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask5Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 5 Billable
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #subTask5Billable
                        [disableOptionCentering]="true"
                        (focus)="subTask5Billable.open()"
                        (valueChange)="subTask5Billable.close()"
                        [(ngModel)]="element.subTask5Billable"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.isInValidSubTask5Billable"
                        required
                      >
                        <ng-option [value]="true">Yes</ng-option>
                        <ng-option [value]="false">No</ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask6Name">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Sub Task 6 Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.subTask6Name"
                          [disabled]="!element.isInValidSubTask6Name"
                          (focusout)="checkTaskValidElement(element, i)"
                          maxlength="255"
                          required
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask6Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 6 Billable
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #subTask6Billable
                        [disableOptionCentering]="true"
                        (focus)="subTask6Billable.open()"
                        (valueChange)="subTask6Billable.close()"
                        [(ngModel)]="element.subTask6Billable"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.isInValidSubTask6Billable"
                        required
                      >
                        <ng-option [value]="true">Yes</ng-option>
                        <ng-option [value]="false">No</ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask7Name">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Sub Task 7 Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.subTask7Name"
                          [disabled]="!element.isInValidSubTask7Name"
                          (focusout)="checkTaskValidElement(element, i)"
                          maxlength="255"
                          required
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask7Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 7 Billable
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #subTask7Billable
                        [disableOptionCentering]="true"
                        (focus)="subTask7Billable.open()"
                        (valueChange)="subTask7Billable.close()"
                        [(ngModel)]="element.subTask7Billable"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.isInValidSubTask7Billable"
                        required
                      >
                        <ng-option [value]="true">Yes</ng-option>
                        <ng-option [value]="false">No</ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask8Name">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Sub Task 8 Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.subTask8Name"
                          [disabled]="!element.isInValidSubTask8Name"
                          (focusout)="checkTaskValidElement(element, i)"
                          maxlength="255"
                          required
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask8Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 8 Billable
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #subTask8Billable
                        [disableOptionCentering]="true"
                        (focus)="subTask8Billable.open()"
                        (valueChange)="subTask8Billable.close()"
                        [(ngModel)]="element.subTask8Billable"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.isInValidSubTask8Billable"
                        required
                      >
                        <ng-option [value]="true">Yes</ng-option>
                        <ng-option [value]="false">No</ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask9Name">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Sub Task 9 Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.subTask9Name"
                          [disabled]="!element.isInValidSubTask9Name"
                          (focusout)="checkTaskValidElement(element, i)"
                          maxlength="255"
                          required
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask9Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 9 Billable
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #subTask9Billable
                        [disableOptionCentering]="true"
                        (focus)="subTask9Billable.open()"
                        (valueChange)="subTask9Billable.close()"
                        [(ngModel)]="element.subTask9Billable"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.isInValidSubTask9Billable"
                        required
                      >
                        <ng-option [value]="true">Yes</ng-option>
                        <ng-option [value]="false">No</ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask10Name">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Sub Task 10 Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.subTask10Name"
                          [disabled]="!element.isInValidSubTask10Name"
                          (focusout)="checkTaskValidElement(element, i)"
                          maxlength="255"
                          required
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask10Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 10 Billable
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #subTask10Billable
                        [disableOptionCentering]="true"
                        (focus)="subTask10Billable.open()"
                        (valueChange)="subTask10Billable.close()"
                        [(ngModel)]="element.subTask10Billable"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.isInValidSubTask10Billable"
                        required
                      >
                        <ng-option [value]="true">Yes</ng-option>
                        <ng-option [value]="false">No</ng-option>
                      </ng-select>
                    </td>
                  </ng-container>
                  <!-- #endregion -->

                  <!-- #region Users -->
                  <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef>First Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.firstName"
                          [disabled]="!element.isInValidFirstName"
                          maxlength="255"
                          required
                          (focusout)="checkUserValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="middleName">
                    <th mat-header-cell *matHeaderCellDef>Middle Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.middleName"
                          [disabled]="!element.isInValidMiddleName"
                          maxlength="255"
                          required
                          (focusout)="checkUserValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef>Last Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.lastName"
                          [disabled]="!element.isInValidLastName"
                          maxlength="255"
                          (focusout)="checkUserValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="capacity">
                    <th mat-header-cell *matHeaderCellDef>Capacity</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          appDurationMask
                          autocomplete="off"
                          [(ngModel)]="element.capacity"
                          [disabled]="!element.isInValidCapacity"
                          (focusout)="checkUserValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="billableRate">
                    <th mat-header-cell *matHeaderCellDef>Billable Rate</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          currencyMask
                          autocomplete="off"
                          [maxDigits]="8"
                          appTwoDigitDecimalNumber
                          [(ngModel)]="element.billableRate"
                          [disabled]="!element.isInValidBillableRate"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                          [options]="{ allowNegative: false }"
                          (focusout)="checkUserValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="costRate">
                    <th mat-header-cell *matHeaderCellDef>Cost Rate</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          currencyMask
                          autocomplete="off"
                          [maxDigits]="8"
                          appTwoDigitDecimalNumber
                          [(ngModel)]="element.costRate"
                          [disabled]="!element.isInValidCostRate"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                          [options]="{ allowNegative: false }"
                          (focusout)="checkUserValidElement(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="permission">
                    <th mat-header-cell *matHeaderCellDef>Permission</th>
                    <td mat-cell *matCellDef="let element">
                      <ng-select
                        #permission
                        (focus)="permission.open()"
                        (valueChange)="permission.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.permission"
                        [disabled]="!element.isInValidPermission"
                        panelClass="mat-select-position"
                        appendTo="body"
                      >
                        <ng-option
                          *ngFor="let pl of permissionList"
                          [value]="pl.id"
                        >
                          {{ pl.name }}</ng-option
                        >
                      </ng-select>
                    </td>
                  </ng-container>
                  <!-- #endregion -->

                  <!-- #region Expense Types -->
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="element.name"
                          autocomplete="off"
                          maxlength="255"
                          [disabled]="!element.isInValidName"
                          (focusout)="checkNameValid(element, i)"
                          required
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="hasUnitPrice">
                    <th mat-header-cell *matHeaderCellDef>Has Unit Price</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #unitPrice
                        [disableOptionCentering]="true"
                        (focus)="unitPrice.open()"
                        (valueChange)="unitPrice.close()"
                        [(ngModel)]="element.hasUnitPrice"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.isInValidHasUnitPrice"
                        required
                      >
                        <ng-option [value]="true">Yes</ng-option>
                        <ng-option [value]="false">No</ng-option>
                      </ng-select>
                    </td>
                  </ng-container>
                  <!-- #endregion -->

                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="importDuplicateList.data.length > 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon fontSet="material-icons-outlined" class="text-primary"
                  >content_copy</mat-icon
                >
                <div class="d-flex flex-column">
                  <span
                    >Duplicate List ({{
                      importDuplicateList.data.length
                    }})</span
                  >
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="content-body">
              <div class="mat-table-wrapper">
                <table
                  mat-table
                  aria-describedby="Duplicate List"
                  [dataSource]="importDuplicateList"
                >
                  <!-- #region Clients -->
                  <ng-container matColumnDef="companyName">
                    <th mat-header-cell *matHeaderCellDef class="w-170">
                      Company Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        [ngClass]="
                          element.isInValidCompanyName ? 'error-field' : ''
                        "
                      >
                        <input
                          matInput
                          autocomplete="off"
                          required
                          [disabled]="!element.isInValidCompanyName"
                          [(ngModel)]="element.companyName"
                          (focusout)="checkClientValidElement(element, i, true)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="companyType">
                    <th mat-header-cell *matHeaderCellDef>Client Type</th>
                    <td mat-cell *matCellDef="let element">
                      <div
                        class="onelineclamp"
                        title="{{ clientType[element.clientTypeId] }}"
                      >
                        {{ clientType[element.clientTypeId] }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="registrationNumber">
                    <th mat-header-cell *matHeaderCellDef>
                      Registration Number
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      {{ element.registrationNumber }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef>Address</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp" title="{{ element.address }}">
                        {{ element.address }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="postalCode">
                    <th mat-header-cell *matHeaderCellDef>Postal Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.postalCode }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef>City</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.city }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="county">
                    <th mat-header-cell *matHeaderCellDef>County</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.county }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef>Country</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.country }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Title
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <div class="onelineclamp">{{ element.title }}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="contactName">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Contact Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div
                        class="onelineclamp"
                        title="{{ element.contactName }}"
                      >
                        {{ element.contactName }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Email
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          emailValidator
                          [(ngModel)]="element.email"
                          (focusout)="
                            checkDuplicateExist(
                              element,
                              i,
                              false,
                              checkDuplicateBy.Email
                            )
                          "
                          autocomplete="off"
                          maxlength="320"
                          type="email"
                          [disabled]="!element.isInvalidema"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="phoneNumber">
                    <th mat-header-cell *matHeaderCellDef>Phone Number</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.phoneNumber }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="clientCode">
                    <th mat-header-cell *matHeaderCellDef>Client Id</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field
                        [ngClass]="
                          element.isInValidClientCode ? 'error-field' : ''
                        "
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.clientCode"
                          [disabled]="!element.isInValidClientCode"
                          (focusout)="checkClientValidElement(element, i, true)"
                        />
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="businessStartDate">
                    <th mat-header-cell *matHeaderCellDef>
                      Business Start Date
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.businessStartDate | date: "dd-MMM-yyyy" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bookStartDate">
                    <th mat-header-cell *matHeaderCellDef>Book Start Date</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.bookStartDate | date: "dd-MMM-yyyy" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="yearEndDate">
                    <th mat-header-cell *matHeaderCellDef>Year End Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      {{ element.yearEndDate }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="yearEndMonth">
                    <th mat-header-cell *matHeaderCellDef>Year End Month</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      {{ monthList[element.yearEndMonth - 1] }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="utrnumber">
                    <th mat-header-cell *matHeaderCellDef>UTR No.</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.utrnumber }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatschemeId">
                    <th mat-header-cell *matHeaderCellDef>Vat Scheme</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <div class="onelineclamp">
                        {{
                          element.vatschemeId && element.vatschemeId !== null
                            ? vatSchemeList[i]?.id === +element.vatschemeId
                              ? vatSchemeList[i]?.name
                              : ""
                            : ""
                        }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatregNumber">
                    <th mat-header-cell *matHeaderCellDef>VAT Reg. No.</th>
                    <td mat-cell *matCellDef="let element">
                      <div
                        class="onelineclamp"
                        title="{{ element.vatregNumber }}"
                      >
                        {{ element.vatregNumber }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatregistrationDate">
                    <th mat-header-cell *matHeaderCellDef>
                      VAT Registration Date
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.vatregistrationDate | date: "dd-MMM-yyyy" }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatsubmitTypeId">
                    <th mat-header-cell *matHeaderCellDef>VAT Submit Type</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <div class="onelineclamp">
                        {{ getVATSubmitValue(element.vatsubmitTypeId) }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="accountOfficeRefNumber">
                    <th mat-header-cell *matHeaderCellDef>
                      Account Office Ref No.
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div
                        class="onelineclamp"
                        title="{{ element.accountOfficeRefNumber }}"
                      >
                        {{ element.accountOfficeRefNumber }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="payeRefNumber">
                    <th mat-header-cell *matHeaderCellDef>PAYE Ref No.</th>
                    <td mat-cell *matCellDef="let element">
                      <div
                        class="onelineclamp"
                        title="{{ element.payeRefNumber }}"
                      >
                        {{ element.payeRefNumber }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="isShowVat">
                    <th mat-header-cell *matHeaderCellDef>Is Show VAT</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.isShowVat }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="nationalInsuranceNo">
                    <th mat-header-cell *matHeaderCellDef>
                      National Insurance Number
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.nationalInsuranceNo }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="dateOfBirth">
                    <th mat-header-cell *matHeaderCellDef>Date of Birth</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.dateOfBirth | date: "dd-MMM-yyyy" }}
                    </td>
                  </ng-container>
                  <!-- #endregion -->

                  <!-- #region Tasks -->
                  <ng-container matColumnDef="estimatedTime">
                    <th mat-header-cell *matHeaderCellDef>Estimated Time</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.estimatedTime }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="defaultFeesType">
                    <th mat-header-cell *matHeaderCellDef>Default Fees Type</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.defaultFeesType }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fixedFeeAmount">
                    <th mat-header-cell *matHeaderCellDef>Fixed Fee Amount</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.fixedFeeAmount }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="timeAndFeesAmount">
                    <th mat-header-cell *matHeaderCellDef>
                      Time And Fees Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.timeAndFeesAmount }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask1Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 1 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask1Name }}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask1Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 1 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask1Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask2Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 2 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask2Name }}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask2Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 2 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask2Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask3Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 3 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask3Name }}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask3Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 3 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask3Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask4Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 4 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask4Name }}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask4Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 4 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask4Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask5Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 5 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask5Name }}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask5Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 5 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask5Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask6Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 6 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask6Name }}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask6Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 6 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask6Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask7Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 7 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask7Name }}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask7Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 7 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask7Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask8Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 8 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask8Name }}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask8Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 8 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask8Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask9Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 9 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.subTask9Name }}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask9Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 9 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask9Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask10Name">
                    <th mat-header-cell *matHeaderCellDef>Sub Task 10 Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask10Name }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="subTask10Billable">
                    <th mat-header-cell *matHeaderCellDef>
                      Sub Task 10 Billable
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ element.subTask10Billable ? "Yes" : "No" }}
                      </div>
                    </td>
                  </ng-container>
                  <!-- #endregion -->

                  <!-- #region Users -->
                  <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef>First Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.firstName }}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="middleName">
                    <th mat-header-cell *matHeaderCellDef>middleName</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.middleName }}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef>Last Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.lastName }}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="capacity">
                    <th mat-header-cell *matHeaderCellDef>Capacity</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.capacity }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="billableRate">
                    <th mat-header-cell *matHeaderCellDef>Billable Rate</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">{{ element.billableRate }}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="costRate">
                    <th mat-header-cell *matHeaderCellDef>Cost Rate</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.costRate }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="permission">
                    <th mat-header-cell *matHeaderCellDef>Permission</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="onelineclamp">
                        {{ authorizedRole[element.permission] }}
                      </div>
                    </td>
                  </ng-container>
                  <!-- #endregion -->

                  <!-- #region Expense Types -->
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="w-150">
                      Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="element.name"
                          (focusout)="
                            checkDuplicateExist(
                              element,
                              i,
                              false,
                              checkDuplicateBy.Name
                            )
                          "
                          autocomplete="off"
                          maxlength="255"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="hasUnitPrice">
                    <th mat-header-cell *matHeaderCellDef>Has Unit Price</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.hasUnitPrice ? "Yes" : "No" }}
                    </td>
                  </ng-container>
                  <!-- #endregion -->

                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-step>
    <mat-step disableRipple>
      <ng-template matStepLabel>Details</ng-template>
      <form [formGroup]="finalFormGroup">
        <div class="end-details">
          <div class="list-detail">
            <p>
              {{ importHeaderText }} Imported ({{
                finalResponse?.imported ?? 0
              }})
            </p>
            <p>No. of records duplicate ({{ finalResponse?.skipped ?? 0 }})</p>
            <p>No. of error records ({{ finalResponse?.error ?? 0 }})</p>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-stepper>
</div>
<app-custom-notifications></app-custom-notifications>
