import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ComponentName,
  DataType,
  ModuleName,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import '@app/core/Extensions/date.extensions';
import {
  MainListParameters,
  MenuModel,
  PermissionModel,
} from '@app/core/Models';
import {
  CommonService,
  ModulePermission,
  NotificationService,
} from '@app/core/Services';
import {
  Export,
  GetProfitLossComparisonReport,
  MenuState,
  ReportState,
  SetModulePermission,
} from '@app/core/Store';
import { HeaderActionsComponent } from '@app/modules/common/header-actions/header-actions.component';
import { TransactionHeaderComponent } from '@app/modules/transactions';
import { Select, Store } from '@ngxs/store';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
@Component({
  selector: 'app-profit-loss-comparison',
  templateUrl: './profit-loss-comparison.component.html',
  styleUrls: ['./profit-loss-comparison.component.scss'],
})
export class ProfitLossComparisonComponent implements OnInit {
  @ViewChild('grid', { static: false }) public grid: GridComponent;
  subscriptionRouting: Subscription;
  dataType = DataType;
  data: any[] = [];
  modules = Modules;
  moduleId = Modules.ProfitAndLossComparision;
  moduleName = ModuleName.ProfitAndLossComparison.toString();
  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;

  @ViewChild(TransactionHeaderComponent, { static: true })
  transactionHeader;

  @ViewChild(HeaderActionsComponent, { static: true })
  headerAction;

  @Select(ReportState.totalRecord)
  totalRecord$: Observable<number>;

  notify: NotificationService;

  listParameters: MainListParameters = new MainListParameters();
  constructor(
    private _Activatedroute: ActivatedRoute,
    private store: Store,
    public commonService: CommonService,
    private modulePermission: ModulePermission,
    private spinner: NgxSpinnerService,
    private datepipe: DatePipe,
    private injector: Injector
  ) {
    this.notify = injector.get<NotificationService>(NotificationService);
  }

  ngOnInit(): void {
    this.spinner.hide();
  }

  queryCellInfo(args: any): void {
    if (args.column.field == 'thisYear' || args.column.field == 'lastYear') {
      if (args.data.alignment !== undefined && args.data.alignment !== null) {
        args.cell.classList.add(args.data.alignment);
      }
      if (
        args.data.isTotal !== undefined &&
        args.data.isTotal !== null &&
        args.data.isTotal
      ) {
        args.cell.classList.add('fw-bold');
      }
      if (
        args.data.isHeader !== undefined &&
        args.data.isHeader !== null &&
        args.data.isHeader
      ) {
        args.cell.classList.add('fw-bold');
      }
    }
  }
  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      branchIds: this.listParameters.branchIds ?? [],
      departmentIds: this.listParameters.departmentIds ?? [],
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    return queryParams;
  }

  getDataFromHeader(data: any): void {
    this.headerAction.ngOnDestroy();
    this.listParameters = data;
    this.getList();
  }

  getList(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetProfitLossComparisonReport(this.getParamter()))
      .subscribe((res) => {
        let column = this.grid.getColumnByField('thisYear');
        let column1 = this.grid.getColumnByField('lastYear');
        column.headerText =
          'As of ' +
          this.datepipe
            .transform(this.listParameters.endDate, 'dd-MMM-yyyy')
            ?.toString();
        column1.headerText =
          'As of ' +
          this.datepipe
            .transform(
              new Date(this.listParameters.endDate?.toString()).addYearsToDate(
                -1
              ),
              'dd-MMM-yyyy'
            )
            ?.toString();
        this.grid.refreshColumns();
        this.data = res.report.mainList.resultSet.data;
      });
  }

  redirect(id, moduleId): void {
    if (moduleId > 0) {
      if (moduleId === Modules.AddCustomAccounts) {
        this.commonService.onEditRouting(
          true,
          Modules.ReportAccountDetail,
          id,
          false
        );
        this.setPermission(RoutingPath.ReportAccountDetailsPermission, id);
      } else if (
        moduleId === Modules.Customers ||
        moduleId === Modules.Suppliers
      ) {
        this.commonService.onEditRouting(true, moduleId, id);
      } else if (this.commonService.isAllowedOpenView(moduleId)) {
        this.commonService.openView(id, moduleId);
      } else {
        this.commonService.onEditRouting(true, moduleId, id);
      }
    }
  }

  emitPermissionData(data: any, componentName: any, id: any): void {
    const permissionData = new PermissionModel();
    permissionData.data = data;
    permissionData.componentName = componentName;
    this.modulePermission.permissionData.next(permissionData);
  }

  setData(event: any, data: any, componentName: any, id: any): void {
    data.forEach((x) => {
      if (x.url === event.slice(1) || x.addUrl === event.slice(1)) {
        this.store.dispatch(new SetModulePermission(x)).subscribe();
        this.emitPermissionData(x, componentName, id);
      } else {
        x.subMenu.map((y) => {
          if (
            y.url === event.slice(1) ||
            y.addUrl === event.slice(1).split(';')[0]
          ) {
            this.store.dispatch(new SetModulePermission(y)).subscribe();
            this.emitPermissionData(y, componentName, id);
          } else {
            y.subMenu.map((z) => {
              if (
                z.url === event.slice(1) ||
                z.addUrl === event.slice(1).split(';')[0]
              ) {
                this.store.dispatch(new SetModulePermission(z)).subscribe();
                this.emitPermissionData(z, componentName, id);
              } else {
                z.subMenu.map((z1) => {
                  if (
                    z1.url === event.slice(1) ||
                    z1.addUrl === event.slice(1).split(';')[0]
                  ) {
                    this.store
                      .dispatch(new SetModulePermission(z1))
                      .subscribe();
                    this.emitPermissionData(z1, componentName, id);
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  setPermission(url: any, id: any): void {
    setTimeout(() => {
      this.menuList$.subscribe((data) => {
        this.setData(url, data, ComponentName.DynamicGridListComponent, id);
      });
    }, 500);
  }

  exportReport(format: number, isPrint?: boolean): void {
    let totalRecordOfRecords = 0;

    this.totalRecord$.subscribe((x) => {
      totalRecordOfRecords = x;
    });

    if (totalRecordOfRecords > 0) {
      this.spinner.show();
      const queryParams = {
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: this.listParameters.filter,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        search: this.listParameters.search,
        moduleId: this.listParameters.moduleId,
        subModuleId: this.listParameters.subModuleId ?? -1,
        branchIds: this.listParameters.branchIds ?? [],
        departmentIds: this.listParameters.departmentIds ?? [],
        ids: [],
        startDate:
          this.datepipe
            .transform(this.listParameters.startDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        endDate:
          this.datepipe
            .transform(this.listParameters.endDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        format: format,
        isPrint: isPrint,
      };

      this.store.dispatch(new Export(queryParams, this.moduleId)).subscribe();
    } else {
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noDataToExport
      );
    }
  }
}
