import { Injectable } from '@angular/core';
import { DataType } from '@app/core/Enum';
import { MainList } from '@app/core/Models';
import { ReportService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  CashFlowTransaction,
  GetBalanceSheetReport,
  GetCashFlow,
  GetProfitLossComparisonReport,
  GetProfitLossReport,
  SendComparativeReportEmail,
  ShareFile,
} from './report.action';
export class ReportStateInfo {
  mainList?: MainList;
  totalRecord?: number;
  isLoading?: boolean;
  cashFlowData?: any;
  cashFlowDetails?: any;
  actions?: any;
}

@State<ReportStateInfo>({
  name: 'report',
})
@Injectable()
export class ReportState {
  constructor(private reportService: ReportService) {}

  @Selector()
  static columnNames(state: ReportStateInfo): Array<string> {
    if (!state.cashFlowDetails) {
      return [];
    }

    return state.cashFlowDetails.resultSet.columns.map((x) => x.name);
  }

  @Selector()
  static columns(state: ReportStateInfo): Array<{
    name: string;
    id: Guid;
    dataType?: DataType;
    allowSortBy: boolean;
  }> {
    if (!state.cashFlowDetails) {
      return [];
    }

    let columns;
    columns = state.cashFlowDetails.resultSet.columns.filter(
      (i) => i.name !== 'ModuleId'
    );

    return columns;
  }

  @Selector()
  static totalRecord(state: ReportStateInfo): number {
    return state.totalRecord ?? 0;
  }

  @Action(GetProfitLossReport, { cancelUncompleted: true })
  getProfitLossReport(
    { patchState }: StateContext<ReportStateInfo>,
    action: GetProfitLossReport
  ) {
    return this.reportService.getProfitLossReport(action.queryParams).pipe(
      tap({
        next: (result) => {
          patchState({
            mainList: result,
            totalRecord: result.paginationModel.totalItemCount,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(GetProfitLossComparisonReport, { cancelUncompleted: true })
  getProfitLossComparisonReport(
    { patchState }: StateContext<ReportStateInfo>,
    action: GetProfitLossComparisonReport
  ) {
    return this.reportService
      .getProfitLossComparisonReport(action.queryParams)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              mainList: result,
              totalRecord: result.paginationModel.totalItemCount,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetBalanceSheetReport, { cancelUncompleted: true })
  getBalanceSheetReport(
    { patchState }: StateContext<ReportStateInfo>,
    action: GetBalanceSheetReport
  ) {
    return this.reportService.getBalanceSheetReport(action.queryParams).pipe(
      tap({
        next: (result) => {
          patchState({
            mainList: result,
            totalRecord: result.paginationModel.totalItemCount,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(GetCashFlow, { cancelUncompleted: true })
  getCashFlow(
    { patchState }: StateContext<ReportStateInfo>,
    action: GetCashFlow
  ) {
    return this.reportService.getCashFlow(action.queryParams).pipe(
      tap({
        next: (result) => {
          patchState({
            cashFlowData: result,
            // totalRecord: result.paginationModel.totalItemCount,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(CashFlowTransaction, { cancelUncompleted: true })
  cashFlowTransaction(
    { patchState }: StateContext<ReportStateInfo>,
    action: CashFlowTransaction
  ) {
    return this.reportService
      .cashFlowTransaction(action.queryParams, action.tagName)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              cashFlowDetails: result,
              totalRecord: result.paginationModel?.totalItemCount,
              actions: result.actions,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(ShareFile)
  shareFile(
    { getState, patchState }: StateContext<ReportStateInfo>,
    action: ShareFile
  ) {
    return this.reportService.shareFile(
      action.attachmentParams,
      action.toMailId,
      action.ccMailId,
      action.bccMailId,
      action.message,
      action.subject
    );
  }

  @Action(SendComparativeReportEmail)
  sendComparativeReportEmail(
    { getState, patchState }: StateContext<ReportStateInfo>,
    action: SendComparativeReportEmail
  ) {
    return this.reportService.sendComparativeReportEmail(
      action.attachmentParams,
      action.toMailId,
      action.ccMailId,
      action.bccMailId,
      action.message,
      action.subject
    );
  }
}
