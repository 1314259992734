<div class="mat-dialog-wrapper">
  <h2 mat-dialog-title>Quick Journal</h2>
  <mat-dialog-content>
    <form [formGroup]="quickJournalForm">
      <div class="d-flex gap-40">
        <div class="form-field datepicker">
          <p>Date</p>
          <mat-form-field appearance="standard">
            <input
              matInput
              formControlName="receiptDate"
              [matDatepicker]="receiptDatePicker"
              autocomplete="off"
              (change)="commonService.validateDate($event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="receiptDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #receiptDatePicker></mat-datepicker>
          </mat-form-field>
          <mat-error
            *ngIf="
              commonService.checkDateValidation(
                quickJournalForm.get('receiptDate')
              ).invalidFinancialYear
            "
          >
            {{ notificationMessage.validPeriodDateMessage }}
          </mat-error>

          <mat-error
            *ngIf="
              commonService.checkDateValidation(
                quickJournalForm.get('receiptDate')
              ).lockedFinancialYear
            "
          >
            {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
          </mat-error>
        </div>
      </div>
      <div class="d-flex gap-40">
        <div class="form-field">
          <p>Transaction Type</p>
          <ng-select
            #accountType
            (focus)="accountType.open()"
            (valueChange)="accountType.close()"
            [disableOptionCentering]="true"
            (change)="onTransactionTypeChange()"
            formControlName="transactionType"
            dropdownPosition="top"
            panelClass="mat-select-position"
          >
            <ng-option *ngFor="let al of transactionType" [value]="al.id">
              <div class="clamp" title="{{ al.name }}">{{ al.name }}</div>
            </ng-option>
          </ng-select>
        </div>
      </div>

      <div class="d-flex gap-40">
        <div class="form-field fund-name" *ngIf="branchList.length > 0">
          <p>Branch <span class="text-danger-300">*</span></p>
          <ng-select
            #branch
            (focus)="branch.open()"
            (valueChange)="branch.close()"
            [disableOptionCentering]="true"
            formControlName="branchId"
            dropdownPosition="bottom"
            (change)="onChangeBranch($event)"
          >
            <ng-option *ngFor="let bl of branchList" [value]="bl.id">
              <div title="{{ bl.name }}">{{ bl.name }}</div>
            </ng-option>
          </ng-select>
        </div>
      </div>

      <div class="d-flex gap-40">
        <div class="form-field" *ngIf="departmentList.length > 0">
          <p>Department <span class="text-danger-300">*</span></p>
          <ng-select
            #department
            (focus)="department.open()"
            (valueChange)="department.close()"
            [disableOptionCentering]="true"
            formControlName="departmentId"
            dropdownPosition="bottom"
          >
            <ng-option *ngFor="let dl of departmentList" [value]="dl.id">
              <div title="{{ dl.name }}">{{ dl.name }}</div>
            </ng-option>
          </ng-select>
        </div>
      </div>

      <div class="d-flex gap-40">
        <div class="form-field wap-40">
          <p>
            {{ accountLabelText }} Account<span class="text-danger-300">*</span>
          </p>
          <ng-select
            #accountList
            (focus)="accountList.open()"
            (valueChange)="accountList.close()"
            [disableOptionCentering]="true"
            formControlName="bankAccountId"
            panelClass="mat-select-position"
          >
            <ng-option
              *ngFor="let option of receiptAccountList"
              [value]="option.id"
            >
              <div title="{{ option.name }}">{{ option.name }}</div>
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="d-flex gap-40">
        <div class="form-field wap-40">
          <p>
            Entry For Account
            <span class="text-danger-300">*</span>
          </p>
          <ng-select
            #accountName
            [items]="accountGroupList"
            (focus)="accountName.open()"
            (valueChange)="accountName.close()"
            class="bank-account"
            [disableOptionCentering]="true"
            bindLabel="name"
            bindValue="id"
            groupBy="groupName"
            panelClass="mat-select-position"
            appendTo="body"
            formControlName="accountId"
          >
            <ng-template ng-option-tmp let-item="item">
              <span class="ng-option-label" [attr.title]="item.name">{{
                item.name
              }}</span>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="d-flex gap-40">
        <div class="form-field">
          <p>Currency <span class="text-danger-300">*</span></p>
          <ng-select
            #currency
            (focus)="currency.open()"
            (valueChange)="currency.close()"
            [disableOptionCentering]="true"
            formControlName="currency"
            dropdownPosition="top"
            panelClass="mat-select-position"
          >
            <ng-option *ngFor="let cl of currencyList" [value]="cl.id">
              <div title="{{ cl.country }} - {{ cl.name }} - {{ cl.code }}">
                {{ cl.country }} - {{ cl.name }} - {{ cl.code }}
              </div>
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="d-flex gap-40">
        <div class="form-field">
          <p>
            Amount {{ amountLabelText }} <span class="text-danger-300">*</span>
          </p>
          <mat-form-field>
            <input
              allow_14_2_Decimal
              matInput
              zeroNotAllowed
              formControlName="amountReceived"
              autocomplete="off"
              allowDecimal
              (change)="calVatAmount()"
            />
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="isVatRegistered" class="d-flex gap-40 align-items-center">
        <div class="form-field">
          <p>VAT</p>
          <ng-select
            #method
            (focus)="method.open()"
            (valueChange)="method.close()"
            [disableOptionCentering]="true"
            formControlName="vat"
            panelClass="mat-select-position"
            (change)="calVatAmount()"
          >
            <ng-option
              *ngFor="let font of vatRateList"
              [value]="font.id"
              title="{{ font.name }}"
            >
              <div title="{{ font.name }}">{{ font.name }}</div>
            </ng-option>
          </ng-select>
        </div>
        <mat-form-field>
          <input
            matInput
            formControlName="vatRate"
            autocomplete="off"
            appAutoFocusDirective
            allowDecimal
          />
        </mat-form-field>
      </div>
      <div class="form-field wp-100 textarea">
        <p>Notes</p>
        <mat-form-field>
          <textarea
            matInput
            maxlength="{{ maxLength.maxLength2000 }}"
            formControlName="note"
            autocomplete="off"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="d-flex gap-40">
        <div
          class="form-field flex-column justify-contents-center"
          *ngIf="vatReturnCheckboxVisibility"
        >
          <div>
            <mat-checkbox formControlName="includeInVatReturnCheckbox">
              Include in VAT return
            </mat-checkbox>
          </div>
        </div>
      </div>
    </form>
    <mat-accordion [multi]="true">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-icons-outlined" class="text-primary"
              >attachment</mat-icon
            >
            <div class="d-flex flex-column">
              <span>Attachment</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-transactions-attachment> </app-transactions-attachment>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="close-button"
      type="button"
      (click)="onCloseClick()"
      mat-button
      disableRipple
    >
      <mat-icon>close</mat-icon>
    </button>
    <app-buttons
      (triggerOnSaveClick)="onSave($event)"
      (triggerOnCancelClick)="onCancel()"
    >
    </app-buttons>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
