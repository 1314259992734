import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  GroupNames,
  MaxLength,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import { Currency, GlobalComponent, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  CurrencyState,
  CustomAccountState,
  GetAccountTypeList,
  GetAllBranchList,
  GetChartOfAccountGroupList,
  GetChartOfAccountTypeList,
  GetClientCurrencyList,
  GetCurrencyList,
  GetRole,
  MenuState,
  PermissionState,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-quick-add',
  templateUrl: './quick-add.component.html',
  styleUrls: ['./quick-add.component.scss'],
})
export class QuickAddComponent implements OnInit {
  moduleEnum = Modules;
  form: FormGroup;
  currencyForm: FormGroup;

  id = Guid.EMPTY as unknown as Guid;
  branchList: any[] = [];

  quickSaveData: any;
  editData: any;
  quickData: any;
  maxLength = MaxLength;
  chartOfAccountGroupList: SideListModel[];
  currencyList: Currency[];
  defaultCurrency: Guid;
  accountTypeList: SideListModel[];
  isCashInHandGroup = false;
  isFixedAssestsGroup = false;
  notificationMessage = NotificationTextMessage;
  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;
  moduleId: Number;
  chartOfAccountTypeList: SideListModel[];
  isAddMode = true;
  isReadOnly = false;
  roleList: any;
  accountType: number;
  isAddCurrencyPage = false;
  selectedCurrencyId: number;
  clientCurrencyList: any = [];
  defaultGuId = Guid.EMPTY as unknown as Guid;
  triggerOnProductSave: Subject<any> = new Subject<any>();
  triggerOnProductClear: Subject<any> = new Subject<any>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    public dialogRef: MatDialogRef<QuickAddComponent>,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private store: Store,
    private globalComponent: GlobalComponent
  ) {
    this.quickData = data;
    this.id = data.id === undefined ? (Guid.EMPTY as unknown as Guid) : data.id;
  }

  ngOnInit(): void {
    this.moduleId$.subscribe((moduleId) => {
      this.moduleId = moduleId;
    });
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    this.setForm();

    if (!this.commonService.isEmpty(this.id)) {
      this.isReadOnly = true;
      this.edit();
    }

    if (this.quickData.moduleId === Modules.AddCustomAccounts) {
      this.getChartOfAccountGroupList();
      this.getCurrency();
    }
    if (this.quickData.moduleId === Modules.Currency) {
      this.isAddCurrencyPage = true;
      this.getClientCurrencyList();
      this.getMultiCurrency();
    }

    if (this.quickData.moduleId === Modules.Permission) {
      this.getRole();
    }

    if (
      this.quickData.moduleId === Modules.Customers ||
      this.quickData.moduleId === Modules.Suppliers
    ) {
      this.getBranchList();
    }
  }

  onCloseClick(): void {
    this.dialogRef.close(Guid.EMPTY as unknown as Guid);
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      if (this.quickData.moduleId === Modules.AddCustomAccounts) {
        this.quickSaveData = {
          id: this.id,
          name: this.isFixedAssestsGroup
            ? this.form.controls.chartOfAccountType.value
            : this.form.controls.accountName.value,
          code: this.form.controls.accountCode.value,
          chartOfAccountGroupId: this.form.controls.chartOfAccountGroup.value,
          chartOfAccountTypeId: !this.isFixedAssestsGroup
            ? this.form.controls.chartOfAccountType.value
            : 0,
          bankAccountTypeId:
            this.form.controls.bankAccountType.value === ''
              ? null
              : this.form.controls.bankAccountType.value,
          currencyId: this.form.controls.currency.value,
          bankBranchCode: this.form.controls.sortBranchCode.value,
          bankAccountNumber:
            this.form.controls.accountNo.value !== undefined &&
            this.form.controls.accountNo.value !== null
              ? this.form.controls.accountNo.value.toString()
              : '',
          branchId:
            this.form.controls.branchId.value === ''
              ? null
              : this.form.controls.branchId.value,
          bankAccountIBANCode: this.form.controls.bankAccountIBANCode.value,
        };
      } else if (this.quickData.moduleId === Modules.Shareholder) {
        this.quickSaveData = {
          id: this.id,
          isQuickEdit: true,
          name: this.form.controls.name.value,
          firstName: this.form.controls.name.value,
          valuePerShare: +this.form.controls.valuePerShare.value,
          noOfShare: +this.form.controls.noOfShare.value,
          email: this.form.controls.email.value,
          accountTypeId: this.commonService.getEntityTypeId(
            this.quickData.moduleId
          ),
          isShareHolder: true,
          shareType: this.form.controls.shareType.value,
        };
      } else if (this.quickData.moduleId === Modules.Permission) {
        this.quickSaveData = {
          id: this.id,
          fullName:
            this.form.controls.firstName.value +
            ' ' +
            this.form.controls.lastName.value,
          email: this.form.controls.email.value,
          roleId: this.form.controls.roleId.value,
          isInviteUser: true,
        };
      } else if (this.quickData.moduleId === Modules.Currency) {
        this.selectedCurrencyId = this.currencyForm.controls.currency.value;
        if (this.selectedCurrencyId === null) {
          this.spinner.hide();
          return false;
        } else {
          this.quickSaveData = {
            id: this.defaultGuId,
            currencyId: this.selectedCurrencyId,
            rate: 0,
            companyId: this.defaultGuId,
            userId: this.defaultGuId,
          };
          const isCurrencyRecordFound = this.clientCurrencyList.find(
            (element) => element.currencyId === this.selectedCurrencyId
          );
          if (isCurrencyRecordFound) {
            this.commonService.onFailure(
              NotificationTextMessage.currencyValidation
            );
            this.spinner.hide();
            return false;
          }
        }
      } else {
        if (this.quickData.moduleId === Modules.QuickEntry) {
          if (this.form.controls.quickEntryType.value) {
            this.quickData.moduleId = Modules.Customers;
          } else {
            this.quickData.moduleId = Modules.Suppliers;
          }
        }
        this.quickSaveData = {
          id: this.id,
          isQuickEdit: true,
          name: this.form.controls.name.value,
          firstName: this.form.controls.name.value,
          valuePerShare: +this.form.controls.valuePerShare.value,
          noOfShare: +this.form.controls.noOfShare.value,
          email: this.form.controls.email.value,
          branchId:
            this.form.controls.branchId.value === ''
              ? null
              : this.form.controls.branchId.value,
          accountTypeId: this.commonService.getEntityTypeId(
            this.quickData.moduleId
          ),
        };
      }
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  getEditData(res): void {
    switch (this.quickData.moduleId) {
      case Modules.Customers:
      case Modules.Suppliers:
        this.editData = res.account.accountData;
        break;
      case Modules.Shareholder:
        this.editData = res.directorShareholder.directorShareholderData;
        break;
    }
  }

  edit(): void {
    this.store
      .dispatch(new this.quickData.getActionName(this.id))
      .subscribe((res) => {
        this.getEditData(res);
        this.form.patchValue({
          name:
            this.quickData.moduleId === Modules.Shareholder
              ? this.editData.firstName
              : this.editData.name,
          email: this.editData.email,
          valuePerShare: this.editData.valuePerShare,
          noOfShare: this.editData.noOfShare,
          shareType:
            this.editData.shareType !== null &&
            this.editData.shareType !== undefined
              ? this.editData.shareType.toString()
              : '1',
        });
      });
  }

  setId(res): void {
    switch (this.quickData.moduleId) {
      case Modules.Customers:
      case Modules.Suppliers:
        this.id = res.account.accountId;
        break;
      case Modules.AddCustomAccounts:
        this.id = res.custom.customAccountId;
        break;
      case Modules.Shareholder:
        this.id = res.directorShareholder.directorShareholderId;
        break;
      case Modules.Permission:
        this.id = res.permission.userId;
        break;
    }
  }

  findInvalidControls() {
    const invalid: any = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onSave(): void {
    if (this.quickData.moduleId === Modules.Product) {
      this.triggerOnProductSave.next(this.moduleId);
    } else {
      const invalid = this.findInvalidControls();
      if (invalid.length > 0 && !this.isAddCurrencyPage) {
        this.commonService.addValidation(this.form, this.renderer);
      } else {
        if (this.dataSubmit()) {
          if (this.quickData.moduleId === Modules.Currency) {
            this.store
              .dispatch(new this.quickData.saveActionName(this.quickSaveData))
              .pipe()
              .subscribe((res) => {
                if (res !== undefined) {
                  this.commonService.onSuccess(
                    NotificationTextMessage.successMessage
                  );
                  this.dialogRef.close();
                }
              });
          } else {
            this.store
              .dispatch(new this.quickData.saveActionName(this.quickSaveData))
              .pipe()
              .subscribe((res) => {
                if (res !== undefined) {
                  this.setId(res);
                  this.commonService.onSuccess(
                    NotificationTextMessage.successMessage
                  );
                  this.dialogRef.close(this.id);
                }
              });
          }
        }
      }
    }
  }

  onCancel(): void {
    if (this.quickData.moduleId === Modules.Product) {
      this.triggerOnProductClear.next();
    } else {
      if (!this.commonService.isEmpty(this.id)) {
        this.edit();
      } else {
        this.id = Guid.EMPTY as unknown as Guid;
        this.ngOnInit();
      }
    }
  }

  setForm(): void {
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      email: new FormControl(''),
      valuePerShare: new FormControl(''),
      noOfShare: new FormControl(''),
      chartOfAccountGroup: new FormControl(''),
      accountName: new FormControl(''),
      accountCode: new FormControl(''),
      accountNo: new FormControl(''),
      shareType: new FormControl('1'),
      chartOfAccountType: new FormControl(''),
      currency: new FormControl(''),
      bankAccountType: new FormControl(''),
      sortBranchCode: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      roleId: new FormControl('1'),
      branchId: new FormControl(''),
      quickEntryType: new FormControl(true),
      bankAccountIBANCode: new FormControl(''),
    });
    this.currencyForm = new FormGroup({
      currency: new FormControl('', Validators.required),
    });

    if (this.quickData.moduleId === Modules.Shareholder) {
      this.form.controls.valuePerShare.setValidators(Validators.required);
      this.form.controls.noOfShare.setValidators(Validators.required);

      this.form.controls.email.clearValidators();
      this.form.controls.email.updateValueAndValidity();
      this.form.controls.valuePerShare.updateValueAndValidity();
      this.form.controls.noOfShare.updateValueAndValidity();
    }

    if (this.quickData.moduleId === Modules.Permission) {
      this.form.controls.name.clearValidators();

      this.form.controls.firstName.setValidators(Validators.required);
      this.form.controls.lastName.setValidators(Validators.required);
      this.form.controls.email.setValidators(Validators.required);

      this.form.controls.name.updateValueAndValidity();
      this.form.controls.firstName.setValidators(Validators.required);
      this.form.controls.lastName.setValidators(Validators.required);
      this.form.controls.email.setValidators(Validators.required);
    }
  }

  getRole(): void {
    this.store
      .dispatch(new GetRole())
      .pipe(
        tap(() => {
          this.roleList = this.store.selectSnapshot(
            PermissionState.getRoleList
          );
          if (this.roleList.length > 0) {
            this.form.controls.roleId.setValue(this.roleList[0].id);
          }
        })
      )
      .subscribe();
  }

  getAccountTypeList(): void {
    this.store
      .dispatch(new GetAccountTypeList())
      .pipe(
        tap(() => {
          this.accountTypeList = this.store.selectSnapshot(
            CustomAccountState.getAccountType
          );
        })
      )
      .subscribe();
  }

  checkIsCashInHandGroup(): void {
    if (
      this.form.controls.chartOfAccountGroup.value ===
      GroupNames.CashAtBankInHand
    ) {
      this.isCashInHandGroup = true;
    } else {
      this.isCashInHandGroup = false;
    }
  }

  onChartOfAccountGroupChange(accountGroupId?: number): void {
    this.form.controls.chartOfAccountGroup.setValue(accountGroupId);
    this.checkIsCashInHandGroup();

    if (this.isCashInHandGroup) {
      this.getBranchList();
    }
    if (
      accountGroupId === GroupNames.FixedAssetsIntangibles ||
      accountGroupId === GroupNames.FixedAssetsInvestmentProperty ||
      accountGroupId === GroupNames.FixedAssetsTangibles ||
      accountGroupId === GroupNames.FixedAssetsInvestments ||
      accountGroupId === GroupNames.FixedAssetsLeased
    ) {
      this.setValidation(true);
    } else {
      this.getChartOfAccountTypeList(accountGroupId);
      this.setValidation(false);
    }
  }

  setValidation(isFixesAssest: boolean): void {
    if (isFixesAssest) {
      this.isFixedAssestsGroup = true;
      this.form.clearValidators();
      this.form.controls.chartOfAccountType.setValue(
        this.form.controls.accountName.value
      );
      if (!this.isAddMode) {
        this.form.controls.chartOfAccountGroup.disable();
      }
      this.form.get('accountName')?.clearValidators();
      this.form.get('accountCode')?.clearValidators();
      this.form.get('accountName')?.updateValueAndValidity();
      this.form.get('accountCode')?.updateValueAndValidity();
      this.form.updateValueAndValidity();
    } else {
      this.isFixedAssestsGroup = false;
      this.form.clearValidators();
      this.form.get('accountName')?.setValidators(Validators.required);
      this.form
        .get('accountCode')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);
      this.form.controls.chartOfAccountGroup.enable();
      this.form.get('email')?.clearValidators();
      this.form.get('name')?.clearValidators();
      this.form.get('accountName')?.updateValueAndValidity();
      this.form.get('accountCode')?.updateValueAndValidity();
      this.form.get('email')?.updateValueAndValidity();
      this.form.get('name')?.updateValueAndValidity();
      this.form.updateValueAndValidity();
    }
  }

  getChartOfAccountTypeList(accountGroupId?: number): void {
    if (accountGroupId !== undefined) {
      this.store
        .dispatch(new GetChartOfAccountTypeList(accountGroupId))
        .pipe(
          tap((res) => {
            this.chartOfAccountTypeList = res.account.chartOfAccountTypeList;
            if (this.chartOfAccountTypeList.length > 0) {
              this.form.controls.chartOfAccountType.setValue(
                this.isReadOnly
                  ? this.accountType
                  : this.chartOfAccountTypeList[0]?.id
              );

              if (accountGroupId == GroupNames.CashAtBankInHand) {
                this.chartOfAccountTypeList.splice(0, 1);
              }
            }
          })
        )
        .subscribe();
    }
  }

  getCurrency(): void {
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          if (this.currencyList.length > 0) {
            this.form.controls.currency.setValue(this.defaultCurrency);
          }
        })
      )
      .subscribe();
  }
  getMultiCurrency(): void {
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          if (this.currencyList.length > 0) {
            this.currencyForm.controls.currency.setValue(
              this.currencyList[0].id
            );
          }
        })
      )
      .subscribe();
  }

  getChartOfAccountGroupList(): void {
    this.store
      .dispatch(new GetChartOfAccountGroupList())
      .pipe(
        tap((res) => {
          const dataList = res.account.chartOfAccountGroupList;
          this.chartOfAccountGroupList = [];

          dataList.forEach((element) => {
            this.chartOfAccountGroupList.push(element);
          });

          if (this.moduleId === Modules.BankDashboard) {
            this.form.controls.chartOfAccountGroup.setValue(
              GroupNames.CashAtBankInHand
            );
          } else {
            this.form.controls.chartOfAccountGroup.setValue(
              this.chartOfAccountGroupList[0].id
            );
          }

          this.onChartOfAccountGroupChange(
            this.form.controls.chartOfAccountGroup.value
          );
          this.getAccountTypeList();
        })
      )
      .subscribe();
  }
  getClientCurrencyList(): void {
    this.store
      .dispatch(new GetClientCurrencyList())
      .pipe(
        tap(() => {
          this.clientCurrencyList = this.store.selectSnapshot(
            CurrencyState.getCurrencyList
          );
        })
      )
      .subscribe();
  }

  getBranchList(): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.form.controls.branchId.setValue(this.branchList[0].id);
            this.form.controls.branchId.setValidators(Validators.required);
            this.form.controls.branchId.updateValueAndValidity();
          } else {
            this.branchList = [];
          }
        })
      )
      .subscribe();
  }
}
