import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import { GlobalComponent, ReceiptModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  GetDataByReceiptId,
  MenuState,
  QuickEditReceipt,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-receipt',
  templateUrl: './edit-receipt.component.html',
  styleUrls: ['./edit-receipt.component.scss'],
})
export class EditReceiptComponent implements OnInit {
  receiptForm: FormGroup;
  receiptId = Guid.EMPTY as unknown as Guid;

  receiptData: ReceiptModel;
  editReceiptData: any;

  subscriptionRouting: Subscription;
  moduleName = ModuleName;
  moduleId = Modules;
  store: Store;
  commonService: CommonService;
  spinner: NgxSpinnerService;
  highlightRow: HighlightRow;

  amountReceivedValue = 0;
  amountReceivedTotal = 0;
  totalAmountDue = 0;
  amountReceived = 0;
  amountReceivedConverted = 0;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  customerLabelText: string;
  accountLabelText: string;

  defaultCurrency: Guid;
  periodicDate: any;

  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;
  selectedCurrencyId: number;
  refreshedCurrencyData: any = [];
  refreshedCurrencyRate: number;
  selectedCurrencySymbol: string;
  selectedCurrencyRate: number;
  defaultCurrencySymbol: string;
  currencyName: string;
  accountCurrencySymbol: string;
  accountSelectedCurrencyId: number;

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditReceiptComponent>,
    private globalComponent: GlobalComponent,
    private renderer: Renderer2,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.highlightRow = injector.get<HighlightRow>(HighlightRow);
  }

  ngOnInit(): void {
    this.selectedCurrencySymbol = '£';
    this.defaultCurrencySymbol = '£';
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setForm().then(() => {
      this.data.moduleId;
      this.receiptId = this.data.id;
      this.getReceiptData();
    });
  }

  getReceiptData(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetDataByReceiptId(this.data.id, this.data.moduleId))
      .subscribe((res) => {
        this.editReceiptData = res.receipt.receiptData;
        this.receiptForm.patchValue({
          code: this.editReceiptData.code,
          receiptDate: this.editReceiptData.receiptDate,
          note: this.editReceiptData.note,
          amountReceived:
            this.editReceiptData.currencyId !== 123
              ? this.editReceiptData.foreignTotalAmount
              : this.editReceiptData.totalAmount,
          currency: this.editReceiptData.invoiceCurrencyId,
          currencyAmt: this.editReceiptData.currencyRate,
        });
        this.selectedCurrencySymbol = this.editReceiptData.symbol;

        this.spinner.hide();
      });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getOptionText(option) {
    return option.name;
  }

  async setForm(): Promise<void> {
    this.receiptForm = new FormGroup({
      code: new FormControl(''),
      receiptDate: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      amountReceived: new FormControl('', [Validators.required]),
      note: new FormControl(''),
      currencyAmt: new FormControl(''),
      foreignCurrency: new FormControl({ value: '', disabled: true }),
      convertedAmtRcvd: new FormControl({ value: '', disabled: true }),
      accountSelectedCurrencyId: new FormControl(''),
    });
    if (
      this.data.moduleId === Modules.Receipt ||
      this.data.moduleId === Modules.Payment
    ) {
      this.receiptForm.controls.amountReceived.disable();
      this.receiptForm.controls.code.disable();
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.receiptData = {
        id: this.receiptId,
        accountId: this.editReceiptData.accountId,
        postingAccountId: this.editReceiptData.postingAccountId,
        currencyId: this.editReceiptData.currencyId,
        transactionTypeId: this.editReceiptData.transactionTypeId,
        attachment: this.editReceiptData.attachment,
        totalAmount: this.editReceiptData.totalAmount,
        isAdvance: this.editReceiptData.isAdvance,
        receiptItems: this.editReceiptData.receiptItems,
        receiptDate: this.datepipe
          .transform(this.receiptForm.controls.receiptDate.value, 'yyyy-MM-dd')
          ?.toString(),

        note: this.receiptForm.controls.note.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }
  onSaveClick(): void {
    if (this.receiptForm.invalid) {
      this.commonService.addValidation(this.receiptForm, this.renderer);
    } else if (this.dataSubmit()) {
      this.store
        .dispatch(new QuickEditReceipt(this.receiptData))
        .pipe()
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res !== undefined) {
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
              this.dialogRef.close(true);
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    }
  }
}
