import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '@app/core/Services';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-add-customize-report-popup',
  templateUrl: './add-customize-report-popup.componenet.html',
  styleUrls: ['./add-customize-report-popup.componenet.scss'],
})
export class AddCustomizeReportPopup implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddCustomizeReportPopup>,
    @Inject(MAT_DIALOG_DATA) public customReportData,
    public store: Store,
    public commonService: CommonService,
    private renderer: Renderer2
  ) {}

  customReportForm: FormGroup;

  ngOnInit(): void {
    this.setForm();
  }
  setForm() {
    return (this.customReportForm = new FormGroup({
      customReportName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
    }));
  }
  onSave() {
    if (this.customReportForm.invalid) {
      this.commonService.addValidation(this.customReportForm, this.renderer);
      return;
    }
    const param = {
      name: this.customReportForm.get('customReportName')?.value,
    };
    this.dialogRef.close(param);
  }

  onCancel(): void {
    this.customReportForm.reset();
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
