export enum MaxLength {
  maxLength5 = 5,
  maxLength8 = 8,
  maxLength9 = 9,
  maxLength10 = 10,
  maxLength20 = 20,
  maxLength11 = 11,
  maxLength13 = 13,
  maxLength14 = 14,
  maxLength25 = 25,
  maxLength50 = 50,
  maxLength100 = 100,
  maxLength160 = 160,
  maxLength180 = 180,
  maxLength200 = 200,
  maxLength255 = 255,
  maxLength500 = 500,
  maxLength998 = 998,
  maxLength2000 = 2000,
}
