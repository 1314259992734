<app-menu-items></app-menu-items>
<div class="d-flex h-100 add-user">
  <app-side-list
    *ngIf="!isHideSideList"
    [getModuleId]="moduleId"
    [getModuleName]="moduleName"
    (showSideListAction)="showSideListAction($event)"
    (toggleSideList)="toggleSideList($event)"
    [reloadSideList]="reloadSideList"
  ></app-side-list>

  <div
    class="form"
    [ngClass]="[!istoggleSideList ? 'hide-sidelist' : '']"
    *ngIf="!isShowSideListAction"
  >
    <div class="content-body-wrapper">
      <div
        class="content-body"
        [ngClass]="
          basicInfoDetails?.userForm.dirty ||
          basicInfoDetails?.contactDetailArray.dirty ||
          basicInfoDetails?.addressDetailArray.dirty
            ? 'mb-4'
            : ''
        "
        *ngIf="!showAssignClients"
      >
        <div>
          <p
            class="header-text d-flex justify-space-between align-items-center"
          >
            <span>
              {{ headerText }}
            </span>
            <button
              mat-button
              class="header-text-close d-flex align-items-center"
              disableRipple
              (click)="onCloseClick()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </p>
        </div>

        <div>
          <div class="analysis-wrap">
            <div class="analysis-tab">
              <div>
                <div class="accordion-toggle">
                  <mat-icon
                    (click)="toggleAccordion(); accordion.openAll()"
                    *ngIf="isExpandAll"
                    >keyboard_double_arrow_down</mat-icon
                  >
                  <mat-icon
                    (click)="toggleAccordion(); accordion.closeAll()"
                    *ngIf="!isExpandAll"
                    >keyboard_double_arrow_up</mat-icon
                  >
                </div>
                <mat-accordion [multi]="true" [expanded]="true">
                  <mat-expansion-panel
                    [expanded]="true"
                    [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <div class="d-flex flex-column">
                          <span>Basic Info</span>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <app-user-basic-info
                      [triggerEditBasicInfoData]="triggerEditBasicInfoData"
                    >
                    </app-user-basic-info>
                  </mat-expansion-panel>
                  <mat-expansion-panel
                    [expanded]="true"
                    [ngClass]="{
                      hidden: userId === commonService.defaultGuidValue,
                      'is-readonly': (isViewPermission$ | async)
                    }"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <span> Clients </span>
                      </mat-panel-title>
                      <button
                        class="action-button secondary-button mr-1 mb-5 border-button"
                        (click)="toggleAssignClient()"
                        type="button"
                        mat-button
                        disableRipple
                        [disabled]="isViewPermission$ | async"
                      >
                        Assign Client
                      </button>
                    </mat-expansion-panel-header>
                    <div class="content-body">
                      <app-user-clients
                        [showAssignClients]="false"
                        [userId]="userId"
                        [displayedUserClientColumns]="
                          displayedUserClientColumns
                        "
                      ></app-user-clients>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showAssignClients">
        <app-assign-client
          [getModuleId]="moduleId"
          [userId]="userId"
          (toggleAssignClient)="toggleAssignClient()"
        ></app-assign-client>
      </div>

      <app-buttons
        *ngIf="
          (basicInfoDetails?.userForm.dirty ||
            basicInfoDetails?.contactDetailArray.dirty ||
            basicInfoDetails?.addressDetailArray.dirty) &&
          !(isViewPermission$ | async)
        "
        [getModuleId]="moduleId"
        (triggerOnSaveClick)="onSave($event)"
        (triggerOnSaveInviteClick)="onSave($event, true)"
        (triggerOnCancelClick)="onCancel(true)"
      >
      </app-buttons>
    </div>
  </div>
</div>
