<div class="mat-dialog-wrapper preview-details">
  <mat-dialog-content>
    <form>
      <div class="main-contain">
        <div class="sidenav-header">
          <div class="d-flex align-items-center justify-space-between mb-10">
            <h2 class="m-0">
              <span>Upload Templates For (Default Template)</span>
            </h2>
            <div>
              <mat-icon class="close cursor-pointer" mat-dialog-close
                >close</mat-icon
              >
            </div>
          </div>
        </div>
      </div>

      <div class="content-body p-0">
        <div class="form-field">
          <p>Invoice Template <span class="text-danger-300">*</span></p>
          <div class="upload-button-wrap">
            <span class="file-info" title="{{ fileInfo }}">{{ fileInfo }}</span>
            <button class="file-upload-button action-button primary-button">
              Choose File
              <input
                type="file"
                #fileInputInv
                (change)="onFileSelect(fileInputInv)"
              />
            </button>
          </div>
        </div>
        <div class="form-field">
          <p>Credit Note Template <span class="text-danger-300">*</span></p>
          <div class="upload-button-wrap">
            <span class="file-info" title="{{ fileInfo }}">{{ fileInfo }}</span>
            <button class="file-upload-button action-button primary-button">
              Choose File
              <input
                type="file"
                #fileInputNote
                (change)="onFileSelect(fileInputNote)"
              />
            </button>
          </div>
        </div>
        <div class="form-field">
          <p>Dividend Template <span class="text-danger-300">*</span></p>
          <div class="upload-button-wrap">
            <span class="file-info" title="{{ fileInfo }}">{{ fileInfo }}</span>
            <button class="file-upload-button action-button primary-button">
              Choose File
              <input
                type="file"
                #fileInputDiv
                (change)="onFileSelect(fileInputDiv)"
              />
            </button>
          </div>
        </div>
        <div class="form-field">
          <p>
            Quotation TemplateNo file chosen
            <span class="text-danger-300">*</span>
          </p>
          <div class="upload-button-wrap">
            <span class="file-info" title="{{ fileInfo }}">{{ fileInfo }}</span>
            <button class="file-upload-button action-button primary-button">
              Choose File
              <input
                type="file"
                #fileInputchosen
                (change)="onFileSelect(fileInputchosen)"
              />
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="m-0 justify-content-end hr pt-1">
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      mat-dialog-close
    >
      Cancel
    </button>
    <button
      class="action-button primary-button"
      type="button"
      mat-button
      disableRipple
    >
      Upload
    </button>
  </mat-dialog-actions>
</div>
