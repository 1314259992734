import { FixedAssetReturnModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CreateFixedAssetReturn {
  static readonly type = '[FixedAssetReturn] Create FixedAssetReturn';

  constructor(public fixedAssetReturn: FixedAssetReturnModel) {}
}

export class QuickFixedAssetReturn {
  static readonly type = '[FixedAssetReturn] Quick Fixed Asset Return';

  constructor(public fixedAssetReturn: any) {}
}

export class GetDataByFixedAssetReturnId {
  static readonly type =
    '[FixedAssetReturn] Get FixedAssetReturn Data By FixedAssetReturn Id';

  constructor(public fixedAssetReturnId: Guid) {}
}

export class ArchiveAndRestoreFixedAssetReturn {
  static readonly type =
    '[FixedAssetReturn] Archive and Restore Selected FixedAssetReturn';

  constructor(
    public fixedAssetReturnIds: Array<Guid>,
    public isArchive: boolean
  ) {}
}

export class DeleteFixedAssetReturn {
  static readonly type = '[FixedAssetReturn] Delete Selected FixedAssetReturn';

  constructor(public fixedAssetReturnIds: Array<Guid>) {}
}
