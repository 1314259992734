<div class="d-flex h-100">
  <app-side-list
    [getModuleId]="moduleId"
    [getModuleName]="moduleName"
    (showSideListAction)="showSideListAction($event)"
    (toggleSideList)="toggleSideList($event)"
    [reloadSideList]="reloadSideList"
    (reloadAutoNumberGenerator)="reloadAutoNumberGenerator()"
  ></app-side-list>
  <div
    class="form mb-4"
    [ngClass]="istoggleSideList ? 'hide-sidelist' : ''"
    [hidden]="isShowSideListAction"
  >
    <div class="content-body-wrapper">
      <div class="content-body">
        <div>
          <p
            class="header-text d-flex justify-space-between align-items-center"
          >
            <span>
              {{
                invoiceId === commonService.defaultGuidValue
                  ? "Add New Invoice"
                  : "Edit Invoice"
              }}
            </span>
            <button
              mat-button
              class="header-text-close d-flex align-items-center"
              disableRipple
              (click)="onCloseClick()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </p>
          <div class="alert-message" *ngIf="isViewPermission$ | async">
            <mat-icon class="material-icons-outlined">error_outline</mat-icon>
            {{ commonNotificationText.ViewOnlyAccessMessage }}
          </div>
          <div class="accordion-toggle">
            <mat-icon
              (click)="toggleAccordion(); accordion.openAll()"
              *ngIf="isExpandAll"
              >keyboard_double_arrow_down
            </mat-icon>
            <mat-icon
              (click)="toggleAccordion(); accordion.closeAll()"
              *ngIf="!isExpandAll"
              >keyboard_double_arrow_up
            </mat-icon>
          </div>
          <mat-accordion [multi]="true">
            <mat-expansion-panel
              [expanded]="true"
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >description</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Invoice Details</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-add-invoice
                [triggerEditData]="triggerEditData"
                (triggerOnVATChange)="onVATChange($event)"
                (triggerAccountSelectedOption)="onAccountSelectedOption($event)"
                (customerClick)="customerClickChange($event)"
                (isVatPostponedClick)="isVatPostponedClick($event)"
                (isVatIncludedClick)="isVatIncludedClick($event)"
                (triggerCurrencySelectedOption)="
                  onCurrencySelectedOption($event)
                "
                (branchChange)="onBranchChange($event)"
                (triggerCurrencyRefreshOption)="onCurrencyRefreshOption($event)"
                (triggerSelectedCurrencyRate)="onCurrencySelectedRate($event)"
                [triggerTransactionLogData]="triggerTransactionLogData"
                [isFromBankImport]="isFromBankImport"
                (isGlobalDiscountCheckedValue)="isGlobalChecked($event)"
              ></app-add-invoice>
              <app-product-details
                [triggereEditProductData]="triggereEditProductData"
                [triggerOnVATChange]="triggerOnVATChange"
                [triggerAccountSelectedOption]="triggerAccountSelectedOption"
                [triggerisVatPostponed]="triggerisVatPostponed"
                [triggerisVatIncluded]="triggerisVatIncluded"
                [triggerCurrencySelectedOption]="triggerCurrencySelectedOption"
                [triggerCurrencyRefreshOption]="triggerCurrencyRefreshOption"
                [triggerOnBranchChange]="triggerBranchChange"
                (triggerNewProductAdded)="onAddedNewProductDetails($event)"
                [triggerSelectedCurrencyRate]="triggerSelectedCurrencyRate"
                [isSales]="true"
                [moduleId]="moduleId"
                (triggerVatRateChange)="onVatRateChange($event)"
                [triggerOnDiscountChange]="triggerDiscountChangeVal"
                [triggerisHlobalDiscountChecked]="triggerisHlobalDiscountChecked"
              >
              </app-product-details>
            </mat-expansion-panel>
            <mat-expansion-panel
              [ngClass]="[
                isFromBankImport ? 'hidden' : !hasAdvanceAmount ? 'hidden' : '',
                (isViewPermission$ | async) ? 'is-readonly' : ''
              ]"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >receipt</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Advance Receipt</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-advance-payment
                [triggerAdvancePayment]="triggerAdvancePayment"
                [triggereEditAdvancePaymentData]="triggerEditData"
                [totalReceiptAmount]="
                  this.invoiceProductDetails.totalAmount -
                  this.invoiceReceiptDetails.totalReciptAmount
                "
                (hasAdvanceAmountUpdated)="onHasAdvanceAmountUpdated($event)"
                [selectedCurrencyId]="
                  this.invoiceCurrencyDetails.selectedCurrencyId
                "
                [currencySymbol]="
                  this.invoiceCurrencyDetails.selectedCurrencySymbol
                "
              ></app-advance-payment>
            </mat-expansion-panel>
            <mat-expansion-panel
              [ngClass]="[
                isFromBankImport ? 'hidden' : '',
                (isViewPermission$ | async) || !isReceiptChangePermission
                  ? 'is-readonly'
                  : ''
              ]"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >receipt</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Receipts</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-transactions-payment
                [triggereEditPaymentData]="triggereEditPaymentData"
                [branchList]="this.incomeInvoiceDetails.branchList"
                [branchId]="
                  this.incomeInvoiceDetails.invoiceForm.controls.branchId.value
                "
                [headerText]="headerText"
                [moduleId]="moduleId"
                [totalReceiptAmount]="
                  this.productDetailsFooter.grossTotal -
                  this.advancePaymentDetails.allocatedAdvanceAmount
                "
                [currencyRate]="
                  this.invoiceCurrencyDetails.selectedCurrencyRate
                "
                [triggerNewProductAdded]="triggerNewProductAdded"
                [selectedCurrencyId]="
                  this.invoiceCurrencyDetails.selectedCurrencyId
                "
              >
              </app-transactions-payment>
            </mat-expansion-panel>
            <mat-expansion-panel
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >attachment</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Attachment</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-transactions-attachment
                [triggerEditData]="triggerEditData"
              ></app-transactions-attachment>
            </mat-expansion-panel>
          </mat-accordion>
          <app-product-details-footer
            [balanceAmount]="
              this.productDetailsFooter.grossTotal -
              this.invoiceReceiptDetails.totalReciptAmount -
              this.advancePaymentDetails.allocatedAdvanceAmount
            "
            [currencySymbol]="
              this.invoiceCurrencyDetails.selectedCurrencySymbol
            "
            [triggerDiscountData]="triggerDiscountData"
            [triggerNewProductAdded]="triggerNewProductAdded"
            [triggerSelectedCurrencyRate]="triggerSelectedCurrencyRate"
            [triggerCurrencySelectedOption]="triggerCurrencySelectedOption"
            [isVatrateEqual]="isVatrateEqual"
           
            (triggerDiscountChange)="onDiscountChange($event)"
          ></app-product-details-footer>
        </div>
      </div>
    </div>
    <app-buttons
      *ngIf="!(isViewPermission$ | async)"
      [getModuleId]="moduleId"
      (triggerOnSaveClick)="onSave($event)"
      (triggerOnCancelClick)="onCancel(true)"
    >
    </app-buttons>
  </div>
</div>
