import { Injectable } from '@angular/core';
import { MatchQuickEntryModel } from '@app/core/Enum/quick-entry';
import { QuickEntryModel, SideListModel } from '@app/core/Models';
import { QuickEntryService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreQuickEntry,
  CreateQuickEntry,
  DeleteQuickEntry,
  GetCustomerSupplierList,
  GetDataByQuickEntryId,
  GetMatchingQuickEntry,
  GetQuickEntryNumber,
  LinkQuickEntry,
} from './quick-entry.action';

export class QuickEntryStateInfo {
  quickEntryNo: string;
  customerSupplierList: Array<SideListModel>;
  quickEntryId?: Guid;
  isQuickEntryAdded?: boolean;
  quickEntryData?: QuickEntryModel;
  quickEntry: Array<QuickEntryModel>;
  sideListModel: Array<SideListModel>;
  matchQuickEntry?: MatchQuickEntryModel;
}

@State<QuickEntryStateInfo>({
  name: 'quickentry',
  defaults: {
    quickEntryNo: '',
    customerSupplierList: [],
    quickEntryId: Guid.EMPTY as unknown as Guid,
    isQuickEntryAdded: false,
    quickEntry: [],
    sideListModel: [],
  },
})
@Injectable()
export class QuickEntryState {
  constructor(private quickEntryService: QuickEntryService) {}

  @Selector()
  static customerSupplierList(
    state: QuickEntryStateInfo
  ): Array<SideListModel> {
    return state.customerSupplierList;
  }
  @Selector()
  static getQuickEntryId(state: QuickEntryStateInfo) {
    return state.quickEntryId;
  }

  @Action(GetQuickEntryNumber)
  getQuickEntryNumber({ patchState }: StateContext<QuickEntryStateInfo>) {
    return this.quickEntryService.getQuickEntryNumber().pipe(
      tap((res) => {
        patchState({
          quickEntryNo: res,
        });
      })
    );
  }

  @Action(GetCustomerSupplierList)
  getCustomerSupplierList(
    { patchState }: StateContext<QuickEntryStateInfo>,
    action: GetCustomerSupplierList
  ) {
    return this.quickEntryService
      .getCustomerSupplierList(action.quickEntryType)
      .pipe(
        tap((res) => {
          patchState({
            customerSupplierList: res,
          });
        })
      );
  }
  @Action(CreateQuickEntry)
  createQuickEntry(
    { patchState }: StateContext<QuickEntryStateInfo>,
    action: CreateQuickEntry
  ) {
    return this.quickEntryService.createQuickEntry(action.quickEntry).pipe(
      tap((res) => {
        patchState({
          quickEntryId: res,
          isQuickEntryAdded: true,
        });
      })
    );
  }
  @Action(GetDataByQuickEntryId)
  getDataByQuickEntryId(
    { patchState }: StateContext<QuickEntryStateInfo>,
    action: GetDataByQuickEntryId
  ) {
    return this.quickEntryService
      .getDataByQuickEntryId(action.quickEntryId)
      .pipe(
        tap((res) => {
          patchState({
            quickEntryData: res,
          });
        })
      );
  }
  @Action(DeleteQuickEntry)
  deleteQuickEntry(
    { getState, patchState }: StateContext<QuickEntryStateInfo>,
    action: DeleteQuickEntry
  ) {
    return this.quickEntryService.deleteQuickEntry(action.quickEntryIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredQuickEntry = state.quickEntry.filter(
          (item) =>
            !action.quickEntryIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.quickEntryIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          quickEntry: filteredQuickEntry,
          sideListModel: filteredForSideList,
        });
      })
    );
  }
  @Action(ArchiveAndRestoreQuickEntry)
  archiveAndRestoreAccount(
    { getState }: StateContext<QuickEntryStateInfo>,
    action: ArchiveAndRestoreQuickEntry
  ) {
    return this.quickEntryService.archiveAndRestoreQuickEntry(
      action.quickEntryIds,
      action.isArchive
    );
  }
  @Action(GetMatchingQuickEntry)
  getMatchingJournals(
    { patchState }: StateContext<QuickEntryStateInfo>,
    action: GetMatchingQuickEntry
  ) {
    return this.quickEntryService
      .getMatchingQuickEntry(action.transactionId)
      .pipe(
        tap((res) => {
          patchState({
            matchQuickEntry: res,
          });
        })
      );
  }

  @Action(LinkQuickEntry)
  linkQuickEntry(
    { patchState }: StateContext<QuickEntryStateInfo>,
    action: LinkQuickEntry
  ) {
    return this.quickEntryService.linkQuickEntry(action.linkQuickEntry).pipe(
      tap((res) => {
        patchState({
          quickEntryId: res,
          isQuickEntryAdded: true,
        });
      })
    );
  }
}
