<div class="mat-dialog-wrapper quick-add">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">View CIS Monthly Return</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <app-view-cis-monthly-return [returnId]="returnId">
    </app-view-cis-monthly-return>
  </mat-dialog-content>
</div>
