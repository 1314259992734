import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SaveInventoryModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { CommonService } from '..';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  saveInventory(inventory: SaveInventoryModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}inventory/save`,
      this.commonService.trimObjectSpace(JSON.stringify(inventory)),
      headers
    );
  }

  getInventory(dataParam: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Inventory/getInventory`,
      JSON.stringify(dataParam),
      headers
    );
  }

  getInventoryTransaction(dataParam: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Inventory/getInventoryTransaction`,
      JSON.stringify(dataParam),
      headers
    );
  }
}
