<app-menu-items></app-menu-items>
<div class="content-body" [ngClass]="isDirty ? 'mb-4' : ''">
  <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
    <mat-icon class="material-icons-outlined">error_outline</mat-icon>
    {{ commonNotificationText.ViewOnlyAccessMessage }}
  </div>
  <div class="content-header">
    <div class="Search">
      <mat-form-field appearance="fill" class="search-field">
        <input
          matInput
          placeholder="Search here..."
          autocomplete="off"
          [(ngModel)]="searchText"
          (ngModelChange)="onSearchAll()"
          (keyup.enter)="onSearch($event)"
        />

        <button mat-button class="search-button">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
  <div class="content-body">
    <div class="sidenav-content-body pt-0 pb-0">
      <div class="mat-table-wrapper">
        <table
          mat-table
          [dataSource]="dataSource"
          aria-describedby="Permission settings table"
          [ngClass]="(isViewPermission$ | async) ? 'is-readonly ' : ''"
        >
          <ng-container matColumnDef="blank-title">
            <th mat-header-cell *matHeaderCellDef></th>
          </ng-container>
          <ng-container matColumnDef="admin-access">
            <th mat-header-cell *matHeaderCellDef>Admin Access</th>
          </ng-container>
          <ng-container matColumnDef="manager-access">
            <th mat-header-cell *matHeaderCellDef>Manager Access</th>
          </ng-container>
          <ng-container matColumnDef="regular-access">
            <th mat-header-cell *matHeaderCellDef>Regular User Access</th>
          </ng-container>

          <ng-container matColumnDef="permission">
            <th mat-header-cell *matHeaderCellDef>
              <button mat-button (click)="toggleAllClick()" disableRipple>
                <mat-icon class="mat-icon-rtl-mirror">
                  {{
                    toggleAll ? "keyboard_arrow_down" : "keyboard_arrow_right"
                  }}
                </mat-icon>
              </button>
              Permissions
            </th>
            <td mat-cell *matCellDef="let data">
              <div class="onelineclamp">
                <button
                  mat-button
                  [style.visibility]="!data.expandable ? 'hidden' : ''"
                  [style.marginLeft.px]="data.level * 20"
                  (click)="treeControl.toggle(data)"
                  disableRipple
                >
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{
                      treeControl.isExpanded(data)
                        ? "keyboard_arrow_down"
                        : "keyboard_arrow_right"
                    }}
                  </mat-icon>
                </button>
                <span
                  class="fw-bold"
                  *ngIf="data.parentModuleId === 0"
                  title="{{ data.name }}"
                  >{{ data.name }}</span
                >
                <span *ngIf="data.parentModuleId > 0" title="{{ data.name }}">{{
                  data.name
                }}</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="admin">
            <th mat-header-cell *matHeaderCellDef>
              <div class="d-flex text-align-center gap-10">
                <mat-checkbox
                  [disabled]="
                    isAllAdminFullSelected ||
                    authorizedRoleId === authorizedRole.Admin ||
                    authorizedRoleId === authorizedRole.Manager ||
                    authorizedRoleId === authorizedRole['Client User'] ||
                    authorizedRoleId === authorizedRole['Staff User']
                  "
                  [(ngModel)]="isAllAdminFullSelected"
                  (change)="selectedAllAdmin(permissionType.Full)"
                  >Full</mat-checkbox
                >
                <mat-checkbox
                  [disabled]="
                    isAllAdminViewSelected ||
                    authorizedRoleId === authorizedRole.Admin ||
                    authorizedRoleId === authorizedRole.Manager ||
                    authorizedRoleId === authorizedRole['Client User'] ||
                    authorizedRoleId === authorizedRole['Staff User']
                  "
                  [(ngModel)]="isAllAdminViewSelected"
                  (change)="selectedAllAdmin(permissionType.View)"
                  >View</mat-checkbox
                >
                <mat-checkbox
                  [disabled]="
                    isAllAdminNoneSelected ||
                    authorizedRoleId === authorizedRole.Admin ||
                    authorizedRoleId === authorizedRole.Manager ||
                    authorizedRoleId === authorizedRole['Client User'] ||
                    authorizedRoleId === authorizedRole['Staff User']
                  "
                  [(ngModel)]="isAllAdminNoneSelected"
                  (change)="selectedAllAdmin(permissionType.None)"
                  >None</mat-checkbox
                >
              </div>
            </th>
            <td mat-cell *matCellDef="let data; let i = index">
              <mat-radio-group
                [disabled]="
                  authorizedRoleId === authorizedRole.Admin ||
                  authorizedRoleId === authorizedRole.Manager ||
                  authorizedRoleId === authorizedRole['Client User'] ||
                  authorizedRoleId === authorizedRole['Staff User'] ||
                  data.isDisabled
                "
                aria-label="Select an option"
                class="d-flex text-align-center gap-10"
                (change)="onAdminSelected(data, $event.value)"
                [(ngModel)]="data.isAdministrator"
              >
                <mat-radio-button [value]="permissionType.Full"
                  >Full
                </mat-radio-button>
                <mat-radio-button [value]="permissionType.View"
                  >View
                </mat-radio-button>
                <mat-radio-button [value]="permissionType.None"
                  >None
                </mat-radio-button>
              </mat-radio-group>
            </td>
          </ng-container>

          <ng-container matColumnDef="manager">
            <th mat-header-cell *matHeaderCellDef>
              <div class="d-flex text-align-center gap-10">
                <mat-checkbox
                  [(ngModel)]="isAllManagerFullSelected"
                  [disabled]="
                    isAllManagerFullSelected ||
                    authorizedRoleId === authorizedRole.Manager ||
                    authorizedRoleId === authorizedRole['Client User'] ||
                    authorizedRoleId === authorizedRole['Staff User']
                  "
                  (change)="selectedAllManager(permissionType.Full)"
                  >Full</mat-checkbox
                >
                <mat-checkbox
                  [(ngModel)]="isAllManagerViewSelected"
                  [disabled]="
                    isAllManagerViewSelected ||
                    authorizedRoleId === authorizedRole.Manager ||
                    authorizedRoleId === authorizedRole['Client User'] ||
                    authorizedRoleId === authorizedRole['Staff User']
                  "
                  (change)="selectedAllManager(permissionType.View)"
                  >View</mat-checkbox
                >
                <mat-checkbox
                  [(ngModel)]="isAllManagerNoneSelected"
                  [disabled]="
                    isAllManagerNoneSelected ||
                    authorizedRoleId === authorizedRole.Manager ||
                    authorizedRoleId === authorizedRole['Client User'] ||
                    authorizedRoleId === authorizedRole['Staff User']
                  "
                  (change)="selectedAllManager(permissionType.None)"
                  >None</mat-checkbox
                >
              </div>
            </th>
            <td mat-cell *matCellDef="let data; let i = index">
              <mat-radio-group
                [disabled]="
                  authorizedRoleId === authorizedRole.Manager ||
                  authorizedRoleId === authorizedRole['Client User'] ||
                  authorizedRoleId === authorizedRole['Staff User'] ||
                  data.isDisabled
                "
                aria-label="Select an option"
                class="d-flex text-align-center gap-10"
                (change)="onManagerSelected(data, $event.value)"
                [(ngModel)]="data.isManager"
              >
                <mat-radio-button [value]="permissionType.Full"
                  >Full
                </mat-radio-button>
                <mat-radio-button [value]="permissionType.View" disableRipple
                  >View
                </mat-radio-button>
                <mat-radio-button [value]="permissionType.None" disableRipple
                  >None
                </mat-radio-button>
              </mat-radio-group>
            </td>
          </ng-container>

          <ng-container matColumnDef="regularUser">
            <th mat-header-cell *matHeaderCellDef>
              <div class="d-flex text-align-center gap-10">
                <mat-checkbox
                  [(ngModel)]="isAllRegularUserFullSelected"
                  [disabled]="
                    isAllRegularUserFullSelected ||
                    authorizedRoleId === authorizedRole['Client User'] ||
                    authorizedRoleId === authorizedRole['Staff User']
                  "
                  (change)="selectedAllRegularUser(permissionType.Full)"
                  >Full</mat-checkbox
                >
                <mat-checkbox
                  [(ngModel)]="isAllRegularUserViewSelected"
                  [disabled]="
                    isAllRegularUserViewSelected ||
                    authorizedRoleId === authorizedRole['Client User'] ||
                    authorizedRoleId === authorizedRole['Staff User']
                  "
                  (change)="selectedAllRegularUser(permissionType.View)"
                  >View</mat-checkbox
                >
                <mat-checkbox
                  [(ngModel)]="isAllRegularUserNoneSelected"
                  [disabled]="
                    isAllRegularUserNoneSelected ||
                    authorizedRoleId === authorizedRole['Client User'] ||
                    authorizedRoleId === authorizedRole['Staff User']
                  "
                  (change)="selectedAllRegularUser(permissionType.None)"
                  >None</mat-checkbox
                >
              </div>
            </th>
            <td mat-cell *matCellDef="let data; let i = index">
              <mat-radio-group
                aria-label="Select an option"
                class="d-flex text-align-center gap-10"
                [disabled]="
                  authorizedRoleId === authorizedRole['Client User'] ||
                  authorizedRoleId === authorizedRole['Staff User'] ||
                  data.isDisabled
                "
                (change)="onRegularUserSelected(data, $event.value)"
                [(ngModel)]="data.isRegularUser"
              >
                <mat-radio-button [value]="permissionType.Full"
                  >Full
                </mat-radio-button>
                <mat-radio-button [value]="permissionType.View" disableRipple
                  >View
                </mat-radio-button>
                <mat-radio-button [value]="permissionType.None" disableRipple
                  >None
                </mat-radio-button>
              </mat-radio-group>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="
              [
                'blank-title',
                'admin-access',
                'manager-access',
                'regular-access'
              ];
              sticky: true
            "
          ></tr>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td
              class="mat-cell"
              [ngClass]="'text-align-center'"
              [attr.colspan]="displayedColumns.length"
            >
              <span>No records found</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="action-wrapper" *ngIf="!(isViewPermission$ | async)">
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave(false)"
    >
      Save
    </button>

    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave(true)"
    >
      Save and Exit
    </button>

    <button
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onCancel()"
    >
      Cancel Changes
    </button>
  </div>
</div>
