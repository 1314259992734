<div class="content-body-wrapper data-security">
    <div class="content-body">
      <p class="header-text d-flex justify-space-between align-items-center">
        Data Security
        <button
          mat-button
          class="header-text-close d-flex align-items-center"
          disableRipple
          (click)="onCloseClick()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </p>
      <div>
        <form [formGroup]="dataSecurityForm" class="mb-4">
            <div class="form-field">
              <p class="align-items-center">Data Security</p>
              <div>
                <mat-checkbox
                  class="example-margin"
                  formControlName="isEnableDocument"
                  (change)="onCheckBoxSelected($event)"
                  >Enable Document
                </mat-checkbox>
    
                <button
                  mat-button
                  (click)="$event.stopPropagation()"
                  [matMenuTriggerFor]="menuGDPR"
                  disableRipple
                >
                  <mat-icon class="text-yellow-200 highlight-icon"
                    >emoji_objects</mat-icon
                  >
                </button>
    
                <mat-menu #menuGDPR="matMenu" class="highlight" xPosition="before">
                  <div>
                    <p>
                      As per GDPR norms, Capium allows you to password-protect your VAT files before sharing, 
                      ensuring your sensitive information remains secure. Passwords are case-sensitive either way.
                    </p>
                  </div>
                </mat-menu>
              </div>
            </div>
    
            <div class="form-field">
              <p>
                Master Password<span class="text-danger-300">*</span>
                <button
                  mat-button
                  (click)="$event.stopPropagation()"
                  [matMenuTriggerFor]="menu"
                  disableRipple
                >
                  <mat-icon class="text-yellow-200 highlight-icon"
                    >emoji_objects</mat-icon
                  >
                </button>
              </p>
              <mat-menu #menu="matMenu" class="highlight" xPosition="before">
                <div>
                  <p>
                    The Master password helps clients,accountants and staff to view
                    VAT PDF. Please copy and paste this password into the PDF Viewer to unlock it for you.
                  </p>
                </div>
              </mat-menu>
              <mat-form-field class="w-300 master-password mb-1"
              appearance="fill"
              [ngClass]="{
                'is-invalid': isFormSubmitted
              }">
                <input
                  matInput
                  type="password"
                  formControlName="masterPassword"
                  id="inputPassword"
                  autocomplete="off"
                  maxlength="36"
                  (keypress)="onChangeMasterPassword()"
                />
                <span class="material-icons" (click)="copyMasterPassword()">file_copy</span>
              </mat-form-field>
            </div>
        </form>
      </div>
    </div>
</div>

<div class="action-wrapper button-action-wrapper">
    <div class="content-body">
        <button 
            class="action-button primary-button mr-1"
            type="button"
            mat-button
            disableRipple
            (click)="onSave()"
            [disabled]="!isEnableSaveBtn"
            >
            Save
        </button>
        <button 
            class="action-button secondary-button"
            type="button"
            mat-button
            disableRipple
            (click)="onClearForm()"
            >
            Clear Form
        </button>
    </div>
</div>