import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  ActionType,
  ConfirmationType,
  Inventory,
  NotificationDetails,
  NotificationHeader,
} from '@app/core/Enum';
import { CommonService, NotificationService } from '@app/core/Services';
import { GetInventoryTransaction } from '@app/core/Store';
import { ConfirmationBoxComponent } from '@app/modules/common';
import { LossOfStockComponent } from '@app/modules/common/loss-of-stock/loss-of-stock.component';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-inventory-transaction-popup',
  templateUrl: './inventory-transaction-popup.component.html',
  styleUrls: ['./inventory-transaction-popup.component.scss'],
})
export class InventoryTransactionPopupComponent implements OnInit {
  inventoryEnum = Inventory;
  noDataFound = true;
  dateFrom: any;
  dateTo: any;
  type: any;
  productId: Guid = Guid.EMPTY as unknown as Guid;

  transactionData: any;
  ids: Array<Guid>;
  actions: any = [];
  actionTypeEnum = ActionType;
  selectedRowIndex = -1;

  displayedColumns: string[] = [
    'refNo',
    'itemName',
    'invoiceDate',
    'purchaseQty',
    'price',
    'saleQty',
    'lossQty',
    'action',
  ];
  commonService: CommonService;
  spinner: NgxSpinnerService;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InventoryTransactionPopupComponent>,

    private store: Store,
    private injector: Injector,
    private datepipe: DatePipe,
    private notify: NotificationService,
    private dialog: MatDialog
  ) {
    this.commonService = injector.get<CommonService>(CommonService);

    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
  }

  ngOnInit(): void {
    this.actions = [
      {
        name: 'Edit',
        actionType: ActionType.Update,
        icon: 'edit',
      },
      {
        name: 'Delete',
        actionType: ActionType.Delete,
        icon: 'delete_outline',
      },
    ];
    this.dateFrom = this.data.dateFrom;
    this.dateTo = this.data.dateTo;
    this.type = this.data.type;
    this.productId = this.data.productId;
    this.getInventoryData();
  }

  getInventoryData(): void {
    if (this.dateFrom === undefined || this.dateFrom === null) {
      this.notify.error(NotificationHeader.error, 'Please enter From Date');
    } else if (this.dateTo === undefined || this.dateTo === null) {
      this.notify.error(NotificationHeader.error, 'Please enter To Date');
    } else {
      this.store
        .dispatch(new GetInventoryTransaction(this.getParameter()))
        .pipe()
        .subscribe((res) => {
          this.transactionData = res.inventory.inventoryTransactionData;
          this.noDataFound = this.transactionData.length > 0 ? false : true;
        });
    }
  }

  getParameter(): any {
    const queryParams = {
      startDate: this.datepipe
        .transform(this.dateFrom, 'yyyy-MM-dd')
        ?.toString(),
      endDate: this.datepipe.transform(this.dateTo, 'yyyy-MM-dd')?.toString(),
      type: this.type,
      productId: this.productId,
    };
    return queryParams;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onButtonClick(element: any, actionType: ActionType): void {
    let id = element.id;

    this.ids = [];
    this.ids.push(id);

    switch (actionType) {
      case ActionType.Update:
        const parameter = {
          id: id,
        };
        this.dialog
          .open(LossOfStockComponent, {
            data: parameter,
            disableClose: true,
          })
          .afterClosed()
          .subscribe((result: boolean) => {
            this.getInventoryData();
          });
        break;

      case ActionType.Delete:
        this.dialog
          .open(ConfirmationBoxComponent, {
            data: {
              ids: this.ids,
              type: ConfirmationType.DeleteLossOfStock,
              headerText: NotificationHeader.deleteConfirmation,
              detailText: NotificationDetails.deleteAllDetailText,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.getInventoryData();
            }
          });
        break;
    }
  }
}
