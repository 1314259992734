import { Modules } from '@app/core/Enum';
import { AnnualReportModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class GetAnnualReportContent {
  static readonly type = '[AnnualReport] Get Annual Report Content';

  constructor(
    public accountPeriodId: Guid,
    public annualReportModel: AnnualReportModel,
    public moduleId: Modules
  ) {}
}
