export class SaveInventoryModel {
  fromDate?: string;
  toDate?: string;
  items: SaveInventoryItemModel[];
}

export class SaveInventoryItemModel {
  productId: string;
  openingBalance: number;
  purchase: number;
  sale: number;
  lossOfStock: number;
  closingBalance: number;
  valuation: number;
  price: number;
  amount: number;
}
