<div class="content">
  <div class="content-body content-body-scroll">
    <div class="mb-15 d-flex flex-column gap-5">
      <div class="header-actions wp-100">
        <app-header-actions
          (triggerDataFromHeader)="getDataFromHeader($event)"
          [listParameters]="listParameters"
          [parentModuleId]="moduleId"
          (exportClick)="exportReport($event, false)"
        >
        </app-header-actions>
      </div>
    </div>
    <div>
      <p class="fw-bold mb-10 mt-10 font-size-20">
        {{ moduleName }}
      </p>
    </div>
    <table
      class="mat-table table-hover cashflow wp-100"
      aria-describedby="cash-flow"
    >
      <thead>
        <tr class="mat-header-row">
          <th
            class="mat-header-cell cdk-header-cell text-align-left"
            colspan="3"
          >
            <div>Description</div>
          </th>
          <th class="mat-header-cell cdk-header-cell text-align-right">
            <div>Amount</div>
          </th>
          <th class="mat-header-cell cdk-header-cell text-align-right">
            <div>Amount</div>
          </th>
        </tr>
      </thead>

      <tr class="mat-row">
        <td class="mat-cell">I.</td>
        <td class="mat-cell" colspan="4">
          <strong>Cash Flow from Operating Activities</strong>
        </td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_1)"
            >{{ data.OA_1.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_1.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">Add:</td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_2)"
            >{{ data.OA_2.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_2.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_3)"
            >{{ data.OA_3.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_3.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_4)"
            >{{ data.OA_4.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £{{ data.OA_4.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_5)"
            >{{ data.OA_5.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £{{ data.OA_5.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">Less:</td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_6)"
            >{{ data.OA_6.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £{{ data.OA_6.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">(A)</td>
        <td class="mat-cell">
          <strong>Net Profit before Taxation and Extraordinary items</strong>
        </td>
        <td class="mat-cell text-align-right">
          <strong>£{{ data.total_A.amount | numberPipe }}</strong>
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td colspan="3">
          <strong>Adjustments for Non-cash and Non-operating items</strong>
        </td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">(B)</td>
        <td class="mat-cell"><strong>Items to be Added:</strong></td>
        <td class="mat-cell text-align-right"></td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_7)"
            >{{ data.OA_7.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £{{ data.OA_7.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_8)"
            >{{ data.OA_8.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £{{ data.OA_8.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_8)"
            >{{ data.OA_8.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £{{ data.OA_9.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_10)"
            >{{ data.OA_10.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £{{ data.OA_10.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_11)"
            >{{ data.OA_11.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_11.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_12)"
            >{{ data.OA_12.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_12.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_13)"
            >{{ data.OA_13.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_13.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">(C)</td>
        <td class="mat-cell"><strong>Items to be Deducted:</strong></td>
        <td class="mat-cell text-align-right"></td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_14)"
            >{{ data.OA_14.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_14.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_15)"
            >{{ data.OA_15.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_15.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">(D)</td>
        <td class="mat-cell">
          <strong
            >Operating Profit before Working Capital Changes (A+B-C)</strong
          >
        </td>
        <td class="mat-cell text-align-right">
          <strong>£{{ data.total_D.amount | numberPipe }}</strong>
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">(E)</td>
        <td class="mat-cell">
          <strong
            >Decrease in Current Assets and Increase in Current
            Liabilities</strong
          >
        </td>
        <td class="mat-cell text-align-right"></td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">Add:</td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_16)"
            >{{ data.OA_16.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_16.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_17)"
            >{{ data.OA_17.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_17.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_18)"
            >{{ data.OA_18.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £{{ data.OA_18.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_19)"
            >{{ data.OA_19.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_19.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_20)"
            >{{ data.OA_20.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_20.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>

      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_21)"
            >{{ data.OA_21.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_21.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>

      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">(F)</td>
        <td class="mat-cell">
          <strong
            >Increase in Current Assets and Decrease in Current
            Liabilities</strong
          >
        </td>
        <td class="mat-cell text-align-right"></td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">Less:</td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_22)"
            >{{ data.OA_22.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_22.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_23)"
            >{{ data.OA_23.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_23.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_24)"
            >{{ data.OA_24.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_24.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_25)"
            >{{ data.OA_25.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £{{ data.OA_25.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_26)"
            >{{ data.OA_26.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_26.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_27)"
            >{{ data.OA_27.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_27.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">(G)</td>
        <td class="mat-cell">
          <strong>Cash Generated from operations (D+E-F)</strong>
        </td>
        <td class="mat-cell text-align-right">
          <strong>£{{ data.total_G.amount | numberPipe }}</strong>
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">(H)</td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.OA_28)"
            >{{ data.OA_28.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.OA_28.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">(I)</td>
        <td class="mat-cell">
          <strong
            >Cash Flow from operating activities before extraordinary
            Items</strong
          >
        </td>
        <td class="mat-cell text-align-right">
          <strong>£{{ data.total_I.amount | numberPipe }}</strong>
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">(J)</td>
        <td class="mat-cell"><strong>Extraordinary Items (+/-)</strong></td>
        <td class="mat-cell text-align-right">
          <strong>£{{ data.total_J.amount | numberPipe }}</strong>
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">(K)</td>
        <td class="mat-cell">
          <strong>Net Cash from (or used in) operating activities</strong>
        </td>
        <td class="mat-cell text-align-right">&nbsp;</td>
        <td class="mat-cell text-align-right">
          <strong>£{{ data.total_K.amount | numberPipe }}</strong>
        </td>
      </tr>
      <!-- OA end -->
      <!-- IA start -->
      <tr class="mat-row">
        <td class="mat-cell">II</td>

        <td class="mat-cell" colspan="4">
          <strong>Cash Flow from Investing Activities:</strong>
        </td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.IA_1)"
            >{{ data.IA_1.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.IA_1.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.IA_2)"
            >{{ data.IA_2.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.IA_2.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.IA_3)"
            >{{ data.IA_3.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.IA_3.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.IA_4)"
            >{{ data.IA_4.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.IA_4.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.IA_5)"
            >{{ data.IA_5.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.IA_5.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.IA_6)"
            >{{ data.IA_6.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.IA_6.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.IA_7)"
            >{{ data.IA_7.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.IA_7.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <strong>Net Cash from (or used in) Investing Activities</strong>
        </td>
        <td class="mat-cell text-align-right"></td>
        <td class="mat-cell text-align-right">
          <strong>£{{ data.total_II.amount | numberPipe }}</strong>
        </td>
      </tr>
      <!-- OA end -->
      <!-- FA start -->
      <tr class="mat-row">
        <td class="mat-cell">III</td>

        <td class="mat-cell" colspan="4">
          <strong>Cash Flow from Financing Activities</strong>
        </td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.FA_1)"
            >{{ data.FA_1.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.FA_1.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.FA_2)"
            >{{ data.FA_2.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.FA_2.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.FA_3)"
            >{{ data.FA_3.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.FA_3.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.FA_4)"
            >{{ data.FA_4.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.FA_4.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.FA_5)"
            >{{ data.FA_5.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.FA_5.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.FA_6)"
            >{{ data.FA_6.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.FA_6.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.FA_7)"
            >{{ data.FA_7.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.FA_7.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.FA_8)"
            >{{ data.FA_8.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.FA_8.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <strong>Net Cash from (or used in) Financing Activities</strong>
        </td>
        <td class="mat-cell text-align-right"></td>
        <td class="mat-cell text-align-right">
          <strong>£{{ data.total_III.amount | numberPipe }}</strong>
        </td>
      </tr>
      <!-- FA end -->
      <!-- IV start -->
      <tr class="mat-row">
        <td class="mat-cell">IV</td>

        <td class="mat-cell" colspan="2">
          <strong
            >Net Increase/ Decrease in Cash and Equivalents (I+II+III)</strong
          >
        </td>
        <td class="mat-cell text-align-right"></td>
        <td class="mat-cell text-align-right">
          <strong>£{{ data.total_IV.amount | numberPipe }}</strong>
        </td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell">V</td>

        <td class="mat-cell" colspan="4">
          <strong
            >Cash and Cash Equivalents in the beginning of the year</strong
          >
        </td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell">Add:</td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.CA_1)"
            >{{ data.CA_1.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.CA_1.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell"></td>
        <td class="mat-cell"></td>
        <td class="mat-cell">
          <a
            class="link"
            href="javascript:void(0)"
            (click)="onClick(data.CA_2)"
            >{{ data.CA_2.name }}</a
          >
        </td>
        <td class="mat-cell text-align-right">
          £ {{ data.CA_2.amount | numberPipe }}
        </td>
        <td class="mat-cell text-align-right"></td>
      </tr>
      <!-- IV end -->
      <!-- V start -->
      <tr class="mat-row">
        <td class="mat-cell">VI</td>

        <td class="mat-cell" colspan="2">
          <strong>Cash and Cash Equivalents at the end of the year</strong>
        </td>
        <td class="mat-cell text-align-right"></td>
        <td class="mat-cell text-align-right">
          <strong>£{{ data.total_VI.amount | numberPipe }}</strong>
        </td>
      </tr>
    </table>
  </div>
</div>
