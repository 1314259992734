<div>
  <div class="main-list content-body content-account-details mb-4">
    <div>
      <div class="content-body">
        <app-report-header-details
          [triggerCustomerId]="triggerCustomerId"
        ></app-report-header-details>
      </div>

      <div class="report-grid">
        <div class="d-flex justify-space-between align-items-end wp-100 mb-1">
          <div class="grid-search mr-10">
            <mat-form-field appearance="fill" class="search-field">
              <input
                matInput
                placeholder="Search here..."
                autocomplete="off"
                (change)="onSearch($event)"
              />

              <button mat-button disableRipple class="search-button">
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div class="header-actions header-actions-report">
            <div class="toolbar">
              <button mat-button [matMenuTriggerFor]="menu" disableRipple>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu
                #menu="matMenu"
                class="action-menu more-menu"
                xPosition="before"
              >
                <button
                  mat-menu-item
                  (click)="export(exportType.PDF)"
                  disableRipple
                >
                  <mat-icon fontSet="material-icons-outlined"
                    >picture_as_pdf</mat-icon
                  >Export As PDF
                </button>

                <button
                  mat-menu-item
                  (click)="export(exportType.Excel)"
                  disableRipple
                >
                  <mat-icon>border_all</mat-icon>Export to Excel
                </button>

                <button
                  mat-menu-item
                  (click)="export(exportType.CSV)"
                  disableRipple
                >
                  <mat-icon fontSet="material-icons-outlined"
                    >description</mat-icon
                  >Export to CSV
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
          <mat-icon class="material-icons-outlined">error_outline</mat-icon>
          {{ commonNotificationText.ViewOnlyAccessMessage }}
        </div>
        <div
          class="mat-table-wrapper"
          [ngClass]="{
            'mat-table-wrapper-noData': noDataFound
          }"
        >
          <table
            mat-table
            [dataSource]="transactionData"
            aria-describedby="contact-transaction"
            mat-table
            matSort
          >
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>Date</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="clamp"
                title="{{ element.date }}"
              >
                <div class="clamp">
                  {{ element.date | date: "dd-MMM-yyyy" }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="accountName">
              <th mat-header-cell *matHeaderCellDef>Account Name</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="clamp"
                title="{{ element.accountName }}"
              >
                <div class="clamp">
                  <a
                    *ngIf="
                      element.accountId !== undefined &&
                      element.accountId !== null &&
                      element.accountId !== commonService.defaultGuidValue
                    "
                    class="link text-primary wmx-100 clamp"
                    (click)="redirect(moduleEnum.AddCustomAccounts, element)"
                  >
                    {{ element.accountName }}
                  </a>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="reference">
              <th mat-header-cell *matHeaderCellDef>Reference</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="clamp"
                title="{{ element.reference }}"
              >
                <div class="clamp">
                  {{ element.reference }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>Type</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="clamp"
                title="{{ element.type }}"
              >
                <div class="clamp">
                  {{ element.type }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="referenceNo">
              <th mat-header-cell *matHeaderCellDef>Ref. No.</th>
              <td mat-cell *matCellDef="let element">
                <div class="clamp">
                  <a
                    *ngIf="element.invoiceTypeId > 0"
                    class="link text-primary wmx-100 clamp"
                    (click)="redirect(element.moduleId, element)"
                  >
                    {{ element.referenceNo }}
                  </a>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="debit">
              <th mat-header-cell *matHeaderCellDef class="text-align-right">
                Debit
              </th>
              <td mat-cell *matCellDef="let element" class="text-align-right">
                <div class="clamp">£ {{ element.debit | numberPipe }}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="credit">
              <th mat-header-cell *matHeaderCellDef class="text-align-right">
                Credit
              </th>
              <td mat-cell *matCellDef="let element" class="text-align-right">
                <div class="clamp">£ {{ element.credit | numberPipe }}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="balance">
              <th mat-header-cell *matHeaderCellDef class="w-200">Balance</th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div
                  class="d-flex align-items-center button-wrapper justify-space-between"
                >
                  <div class="clamp">£ {{ element.balance | numberPipe }}</div>
                  <div
                    class="grid-actions"
                    [ngClass]="selectedRowIndex === i ? 'show-button' : ''"
                  >
                    <div
                      class="d-flex align-items-center"
                      *ngFor="
                        let item of getActions(element);
                        let permissionsIndex = index
                      "
                    >
                      <span>
                        <button
                          mat-button
                          *ngIf="permissionsIndex < moreActionCount"
                          disableRipple
                          title="{{ item.name }}"
                          (click)="onButtonClick(element, item.actionType)"
                        >
                          <mat-icon fontSet="material-icons-outlined">{{
                            item.icon
                          }}</mat-icon>
                        </button>
                      </span>
                      <button
                        mat-button
                        *ngIf="permissionsIndex === moreActionCount"
                        class="more-button"
                        (click)="onToggleMatMenu(i)"
                        [matMenuTriggerFor]="menu"
                        (menuClosed)="allowCloseOnClickOut()"
                        disableRipple
                      >
                        <mat-icon *ngIf="permissionsIndex === moreActionCount"
                          >more_vert</mat-icon
                        >
                      </button>
                    </div>
                    <mat-menu
                      #menu="matMenu"
                      xPosition="before"
                      class="more-button-menu"
                    >
                      <ng-container
                        *ngFor="
                          let item of getActions(element);
                          let permissionsIndex = index
                        "
                      >
                        <div *ngIf="permissionsIndex > 2">
                          <button
                            mat-menu-item
                            disableRipple
                            title="{{ item.name }}"
                            (click)="onButtonClick(element, item.actionType)"
                          >
                            <mat-icon fontSet="material-icons-outlined">{{
                              item.icon
                            }}</mat-icon>
                            <span>{{ item.name }} </span>
                          </button>
                        </div>
                      </ng-container>
                    </mat-menu>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="submitVatDisplayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: submitVatDisplayedColumns"
            ></tr>
          </table>
          <div *ngIf="noDataFound" class="text-align-center mt-1">
            No records found
          </div>
        </div>
      </div>

      <div class="paginator-wrapper" *ngIf="(totalRecord$ | async) > 0">
        <app-custom-paginator
          *ngIf="(totalRecord$ | async) > 10"
          [length]="totalRecord$ | async"
          [listParameters]="listParameters"
          (pageChanged)="pageChanged($event)"
          (pageSizeVal)="pageSizeVal($event)"
          (togglePaginator)="togglePaginator($event)"
          [triggerPaginationChange]="triggerPaginationChange"
        >
        </app-custom-paginator>
      </div>
    </div>
  </div>
</div>
