import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CISReturn,
  CISReturnDetail,
  ContractorSettings,
  MainListParameters,
  SubmitCisReturn,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonService } from '../common/common.service';
@Injectable({
  providedIn: 'root',
})
export class CISService {
  constructor(private http: HttpClient, public commonService: CommonService) {}

  saveSubContractorDetails(
    saveSubContractorDetailsData: CISReturnDetail
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CIS/saveSubContractorDetails`,
      JSON.stringify(saveSubContractorDetailsData),
      headers
    );
  }

  saveVerifyData(saveVerifyData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CIS/cisSubcontractorVerifiaction`,
      JSON.stringify(saveVerifyData),
      headers
    );
  }

  saveContratorSetting(
    contractorSettingsData: ContractorSettings
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}company/saveCisSetting
      `,
      JSON.stringify(contractorSettingsData),
      headers
    );
  }

  saveCISReturnDetails(saveCISReturnDetailsData: CISReturn): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CIS/saveCisReturn`,
      JSON.stringify(saveCISReturnDetailsData),
      headers
    );
  }

  submitCISReturn(submitCisReturnData: SubmitCisReturn): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CIS/submitCisReturn`,
      JSON.stringify(submitCisReturnData),
      headers
    );
  }

  SaveHMRCGatewayDetails(saveHMRCGatewayDetailsData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CIS/saveHMRCGatewayDetails`,
      JSON.stringify(saveHMRCGatewayDetailsData),
      headers
    );
  }

  getCIS300Details(id: Guid): Observable<any> {
    return this.http.get<any>(`${environment.apiVersionUrl}CIS/get/${id}`);
  }

  getContratorSetting(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Company/getCisSetting`
    );
  }

  getSubContractorDetailsList(
    queryParams: MainListParameters,
    directorShareholderId: Guid
  ): Observable<CISReturn> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<CISReturn>(
      `${environment.apiVersionUrl}CIS/subContractorDetailList/${directorShareholderId}`,
      JSON.stringify(queryParams),
      headers
    );
  }

  deleteCIS300(cis300Ids?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(cis300Ids),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}CIS/delete`,
      options
    );
  }

  getNextPeriod(): Observable<any> {
    return this.http.get<any>(`${environment.apiVersionUrl}CIS/getNextPeriod`);
  }

  exportCisReturn(ids: Array<Guid>): Observable<any> {
    let url;

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}CIS/export`,
        JSON.stringify(ids),
        headers
      )
      .pipe(
        switchMap((response: any) => {
          this.commonService.download(response);
          return of(true);
        })
      );
  }

  exportSubContractorReturn(
    cisReturnId: Guid,
    contactId: Guid,
    cisReturnDetailId: Guid
  ): Observable<any> {
    let url;

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}CIS/downloadSubContractorReturn?cisReturnId=${cisReturnId}&contactId=${contactId}&cISReturnDetailId=${cisReturnDetailId}`,
        headers
      )
      .pipe(
        switchMap((response: any) => {
          this.commonService.download(response);
          return of(true);
        })
      );
  }

  getCISMonthlyReturn(id: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CIS/getCISMonthlyReturn/${id}`
    );
  }

  sendEmailCis300Verification(
    attachmentParams: any,
    toMailId: string,
    ccMailId: string,
    bccMailId: string,
    message: string,
    subject: string
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const obj = {
      cisId: attachmentParams.cisId,
      contactId: attachmentParams.contactId,
      cisReturnDetailId: attachmentParams.cisReturnDetailId,
      toMailId: toMailId,
      cc: ccMailId,
      bcc: bccMailId,
      message: message,
      subject: subject,
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CIS/sendEmailCis300Verification`,
      JSON.stringify(obj),
      options
    );
  }
  getHMRCCredentails(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CIS/getHmrcSubmissionCredentials`
    );
  }
}
