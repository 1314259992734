import { Component, Input, OnInit } from '@angular/core';
import { Modules } from '@app/core/Enum';
import { DashboardVATSummary } from '@app/core/Models';
import { GetDashboardVATSummary } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard-vat-summary',
  templateUrl: './dashboard-vat-summary.component.html',
  styleUrls: ['./dashboard-vat-summary.component.scss'],
})
export class DashboardVatSummaryComponent implements OnInit {
  displayedColumns: string[] = ['fromDate', 'toDate', 'status', 'vatAmount'];
  @Input()
  triggerHeaderFilter: Observable<any>;
  @Input()
  getModuleId: number;
  vatSummaryData: Array<DashboardVATSummary>;
  constructor(private store: Store, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.triggerHeaderFilter?.subscribe((filterData) => {
      if (+this.getModuleId === Modules.Dashboard) {
        this.getClientVATSummaryData(filterData);
      }
    });
  }
  getClientVATSummaryData(filterData: any): void {
    this.spinner.show();
    this.store
      .dispatch(new GetDashboardVATSummary(filterData))
      .subscribe((res) => {
        this.vatSummaryData = res.dashboard.dashboardVATSummaryData;
      });
  }
}
