import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Modules } from '@app/core/Enum';
import { GetDashboardIncomeVsExpensesOverview } from '@app/core/Store';
import { Store } from '@ngxs/store';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexMarkers,
  ApexNoData,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';

import { Observable } from 'rxjs';

export type chartOptionsv1 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
  stroke: ApexStroke;
  markers: ApexMarkers;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  noData: ApexNoData;
  colors: string[];
};

@Component({
  selector: 'app-dashboard-income-expenses-chart-v1',
  templateUrl: './dashboard-income-expenses-chart-v1.component.html',
  styleUrls: ['./dashboard-income-expenses-chart-v1.component.scss'],
})
export class DashboardIncomeExpensesChartComponentv1 implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptionsv1: Partial<chartOptionsv1>;
  reportList: any;
  isNoRecord = true;
  isloadData = false;

  @Input()
  getModuleId: number;

  @Input()
  triggerHeaderFilter: Observable<any>;

  @Input()
  triggerHeaderFilterForIncomeVsExpenses: Observable<any>;

  constructor(private store: Store, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.triggerHeaderFilter?.subscribe((filterData) => {
      if (+this.getModuleId === Modules.Dashboard) {
        this.getData(filterData);
      }
    });

    this.triggerHeaderFilterForIncomeVsExpenses?.subscribe((filterData) => {
      this.getData(filterData);
    });
  }

  getData(filterData): void {
    this.spinner.show();
    let actionName;
    switch (this.getModuleId) {
      case Modules.Dashboard:
        actionName = GetDashboardIncomeVsExpensesOverview;
        break;
    }
    this.store.dispatch(new actionName(filterData)).subscribe((x) => {
      this.isloadData = true;
      switch (this.getModuleId) {
        case Modules.Dashboard:
          this.reportList = x.dashboard.dashboardIncomeVsExpensesOverview;
          break;
      }

      this.getDataForGraph();
    });
  }

  getDataForGraph(): void {
    if (+this.getModuleId === Modules.Dashboard) {
      this.isNoRecord = this.reportList.series.length === 0;

      this.chartOptionsv1 = {
        series: this.reportList.series,
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'right',
          labels: {
            colors: ['#8A61C3', '#EB7074'],
          },
          markers: {
            fillColors: ['#8A61C3', '#EB7074'],
            width: 14,
            height: 14,
            radius: 2,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#7B76D3', '#F2816F'],
        stroke: {
          curve: 'straight',
          width: 3,
          colors: ['#8A61C3', '#EB7074'],
        },
        markers: {
          colors: ['#8A61C3', '#EB7074'],
        },
        noData: {
          text: 'No Data to Display',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#6d41a1',
            fontSize: '18px',
          },
        },
        fill: {
          opacity: 1,
          colors: ['#7B76D3', '#F2816F'],
          gradient: {
            shade: 'light',
            type: 'vertical',
            gradientToColors: ['#8A61C3'],
            inverseColors: true,
            shadeIntensity: 0.25,
            opacityFrom: 0.8,
            opacityTo: 0.4,
            stops: [0, 100],
          },
        },
        xaxis: {
          labels: {
            trim: false,
          },
          categories: this.reportList.categories,
        },
        tooltip: {
          marker: {
            show: false,
            fillColors: ['#8A61C3', '#EB7074'],
          },
          x: {
            format: 'dd/MM/yy HH:mm',
          },
          y: {
            formatter: (val) => {
              return '£ ' + Number(val) * 1 + '';
            },
          },
        },
      };
    }
  }
}
