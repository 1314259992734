import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActionType,
  ComponentName,
  ConfirmationType,
  DataType,
  DetailListModules,
  ExportFormat,
  FilteredStatus,
  Inventory,
  InventoryName,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import {
  ExportType,
  MainListParameters,
  MenuModel,
  PermissionModel,
  ViewParamModel,
} from '@app/core/Models';
import {
  CommonService,
  HighlightRow,
  ModulePermission,
  NotificationService,
} from '@app/core/Services';
import {
  CheckDepreciation,
  CommonState,
  ConvertToInvoice,
  Copy,
  Export,
  ExportReceipt,
  ExportSubcontractorVerification,
  ExportVatAudit,
  GetAccountTransactionList,
  GetDataByInvoiceId,
  GetHeaderList,
  GetMainList,
  GetReportList,
  GetVatReportDetailList,
  MenuState,
  ReportExport,
  SaveColumnsUserLevel,
  SendEmail,
  SetModulePermission,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import {
  ColumnMenuClickEventArgs,
  DataStateChangeEventArgs,
  GridComponent,
  GroupSettingsModel,
  SortDescriptor,
} from '@syncfusion/ej2-angular-grids';
import { TreeGridComponent } from '@syncfusion/ej2-angular-treegrid';
import { closest } from '@syncfusion/ej2-base';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { BankDashboardComponent, BankTransferViewComponent } from '../bank';
import {
  AddAllocateRefundPopupComponent,
  AddReceiptPaymentPopupComponent,
  ConfirmationBoxComponent,
  ConvertToInvoicePopupComponent,
  EmailPopupComponent,
  ViewReceiptComponent,
} from '../common';
import { HeaderActionsComponent } from '../common/header-actions/header-actions.component';
import { ViewCISMonthlyReturnPopupComponent } from '../common/view-cis-monthly-return-popup/view-cis-monthly-return-popup.component';
import { FixedAssetReturnPopupComponent } from '../fixed-assets';
import { EditAssetDetailComponent } from '../fixed-assets-register';
import { EditReceiptComponent } from '../income';
import { SchedulesNotesViewComponent } from '../schedules';
import { TransactionHeaderComponent } from '../transactions';

@Component({
  selector: 'app-dynamic-grid-list',
  templateUrl: './dynamic-grid-list.component.html',
  styleUrls: ['./dynamic-grid-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicGridListComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid!: GridComponent;
  @ViewChild('treegrid')
  public treegrid: TreeGridComponent;
  gridData: any = [];
  tempGridData: any[] = [];
  columns: any = [];
  reportColumnList: any[] = [];
  tempReportColumnList: any[] = [];
  searchData: any[];
  customId: any;
  dataSource: any = [];
  selectedRowData: any = [];
  multipleModulesIds: any = [];
  selectedStatus: Array<any> = [];
  ids: Array<Guid> = [];
  sort: SortDescriptor[] = [];
  groups: GroupSettingsModel[] = [];
  paginationCount = 0;
  state: DataStateChangeEventArgs = {
    skip: 0,
    take: 20,
    sorted: [],
    group: [],
  };
  commands: any[] = [];
  groupSettings: any = { columns: [] };
  public selectedData: any;

  gridHeight: number;
  moduleId: number;
  moduleName: string;
  accountId: Guid;
  selectedRowIndex: number = -1;
  totalRecords = 0;
  skip = 0;
  moreActionCount = 4;
  columnWidth: number = 100;

  filteredStatus = FilteredStatus;
  dataType = DataType;
  moduleEnum = Modules;

  @ViewChild(TransactionHeaderComponent, { static: true })
  headerDetails;

  @ViewChild(HeaderActionsComponent, { static: true })
  headerActions;

  @ViewChild(BankDashboardComponent, { static: true })
  dashboard;

  isCustom = true;
  isReport = true;
  showAddButton = true;
  showAccountDetails = false;

  filter: any = null;

  receiptList: any[] = [];
  paymentList: any[] = [];

  triggerResetBankModuleId: Subject<any> = new Subject<any>();
  triggerBankAccountList: Subject<any> = new Subject<any>();
  triggerPaginationChange: Subject<any> = new Subject<any>();
  triggerSelectedRowData: Subject<any> = new Subject<any>();
  triggerVatApi: Subject<any> = new Subject<any>();
  listParameters: MainListParameters = new MainListParameters();
  triggerCustomerId: Subject<any> = new Subject<any>();
  triggerBadDebtsData: Subject<any> = new Subject<any>();

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;

  @Select(CommonState.actions)
  actions$: Observable<any>;

  dialog: MatDialog;
  store: Store;
  notify: NotificationService;
  commonService: CommonService;
  router: Router;
  highlightRow: HighlightRow;
  datepipe: DatePipe;
  spinner: NgxSpinnerService;
  modulePermission: ModulePermission;
  cookieService: CookieService;
  subscriptionRouting: Subscription;
  subscriptionGetList: Subscription;
  subscriptionAPIGetList: Subscription;
  isConditionTrue = true;
  isHeaderSelected = false;
  isTreeList = true;
  groupable = true;
  bankDetailsIsExpanded = true;
  @Input() isBankImport: boolean = false;
  @Input() isDirectImport: boolean = false;

  @Input()
  triggerAccountDetails: Observable<any>;

  @Input()
  triggerUpdateLossOfStock: Observable<any>;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.calculateGridHeight();
  }

  @Input() removeBranchSelection = false;

  @Input()
  triggerBankAccountId: Observable<any>;
  pageableConfig: any;

  public customAttributes?: Object;

  public fetchChildren = (item: any): any[] => {
    const arrayOfArrays = item['Id'].hasChildren;
    const combinedArray = [].concat(...arrayOfArrays);

    return combinedArray;
  };
  headerChildren = (item: any): boolean => {
    return item.hasChildRecords;
  };
  public hasChildren = (item: any): boolean => {
    return item['Id'].hasChildren && item['Id'].hasChildren.length > 0;
  };
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private injector: Injector,
    private elementRef: ElementRef,
    private _Activatedroute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.store = injector.get<Store>(Store);
    this.notify = injector.get<NotificationService>(NotificationService);
    this.commonService = injector.get<CommonService>(CommonService);
    this.router = injector.get<Router>(Router);
    this.highlightRow = injector.get<HighlightRow>(HighlightRow);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.modulePermission = injector.get<ModulePermission>(ModulePermission);
    this.cookieService = injector.get<CookieService>(CookieService);
    this.paymentList = this.commonService.paymentList;
    this.receiptList = this.commonService.receiptList;
    this.getPageableConfig();
  }

  ngOnInit(): void {
    this.moduleId$.subscribe((moduleId) => {
      if (moduleId === Modules.ViewVATDetails) {
        this.moduleId = Modules.ViewVATDetails;
        this.commands = [];
        this.getList();
      }
      this.columnWidth = this.setColumnWidth(moduleId);
    });
    if (!this.isBankImport) {
      this.subscriptionGetList = this.modulePermission.permissionData.subscribe(
        (value) => {
          this.commands = [];
          this.columns = [];
          this.moduleId = value.data.id;
          this.moduleName = value.data.name;
          this.isReport = this.commonService.isReportModule(this.moduleId);
          this.isTreeList = this.commonService.isTreeList(this.moduleId);

          if (this.isReport) {
            this.listParameters.moduleId = this.commonService.defaultGuidValue;
          }

          this.isHeaderSelected = false;
          if (
            this.isReport &&
            this.moduleId !== Modules.Customers &&
            this.moduleId !== Modules.Suppliers &&
            this.moduleId !== Modules.Import &&
            this.moduleId !== Modules.SalesDayBook &&
            this.moduleId !== Modules.PurchaseDayBook &&
            this.moduleId !== Modules.SupplierList &&
            this.moduleId !== Modules.CustomerList &&
            this.moduleId !== Modules.Inventory &&
            this.moduleId !== Modules.IncomeByCustomerSummary &&
            this.moduleId !== Modules.CustomerBalanceSummary &&
            this.moduleId !== Modules.ExpensesBySupplierSummary &&
            this.moduleId !== Modules.SupplierBalanceSummary &&
            this.moduleId !== Modules.AccountList &&
            this.moduleId !== Modules.RecentTransactions &&
            this.moduleId !== Modules.TransactionListByDate &&
            this.moduleId !== Modules.ChequeDetails &&
            this.moduleId !== Modules.DepositDetails &&
            this.moduleId !== Modules.SalesByProductSummary &&
            this.moduleId !== Modules.Invoices &&
            this.moduleId !== Modules.RecurringInvoice &&
            this.moduleId !== Modules.Quotation &&
            this.moduleId !== Modules.CreditNote &&
            this.moduleId !== Modules.Receipt &&
            this.moduleId !== Modules.Purchase &&
            this.moduleId !== Modules.RecurringPurchase &&
            this.moduleId !== Modules.DebitNote &&
            this.moduleId !== Modules.CISInvoice &&
            this.moduleId !== Modules.Payment &&
            this.moduleId !== Modules.QuickEntry &&
            this.moduleId !== Modules.FixedAssets &&
            this.moduleId !== Modules.BankTransfer &&
            this.moduleId !== Modules.Notes
          ) {
            this.isHeaderSelected = true;
          }

          if (
            (value.data.url === this.router.url.slice(1) &&
              !this.isHeaderSelected &&
              value.componentName === ComponentName.DynamicGridListComponent) ||
            value.componentName === ComponentName.ImportListComponent ||
            value.componentName === ComponentName.VatSettings
          ) {
            this.commands = [];
            this.getList(true);
            this.calculateGridHeight();
          }
        }
      );
    } else if (this.isBankImport) {
      this.triggerBankAccountId?.subscribe((customId) => {
        this.isReport = true;
        this.moduleId = Modules.BankImportHistory;
        this.listParameters.moduleId = customId;
        this.commands = [];
        this.getList();
      });
    }

    this.triggerAccountDetails?.subscribe((data) => {
      this.showAccountDetails = true;

      this.accountId = data.id;
      this.moduleId = data.moduleId;
      this.moduleName = this.commonService.getModuleName(this.moduleId);

      setTimeout(() => {
        this.triggerCustomerId.next(data);
      }, 0);
      if (this.moduleId !== undefined && this.moduleId !== null) {
        this.getHeaderData();
      }
      this.isTreeList = false;
      this.commands = [];
      this.getList();
    });

    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        const currentUrl = this._Activatedroute.snapshot['_routerState'].url;
        if (currentUrl.includes('bank/cashEntry')) {
          this.isReport = false;
          this.isTreeList = false;
          this.moduleId = Modules.CashEntry;
          this.getList();
        }
        if (params.keys.length > 0) {
          const bankTransactionUrl = this.router.url
            .slice(0)
            .split(';')[0]
            .replace('/', '');
          this.showAccountDetails =
            params.get('details') !== null &&
            params.get('details') !== undefined
              ? JSON.parse(atob(params.get('details')!))
              : false;
          this.showAddButton = false;
          if (
            this.showAccountDetails &&
            RoutingPath.BankTransaction !== bankTransactionUrl
          ) {
            this.accountId = atob(params.get('id')!) as unknown as any;
            const moduleId = +atob(params.get('moduleId')!) as unknown as any;
            this.moduleId = moduleId;
            const param = {
              id: this.accountId,
              moduleId: moduleId,
            };
            setTimeout(() => {
              this.triggerCustomerId.next(param);
            }, 1000);

            if (this.moduleId !== undefined && this.moduleId !== null) {
              this.getHeaderData();
            }
            this.isTreeList = false;
            this.getList();
          } else if (
            this.showAccountDetails &&
            RoutingPath.BankTransaction === bankTransactionUrl
          ) {
            this.showAccountDetails = false;
            const id = atob(params.get('id')!) as unknown as any;
            this.listParameters.moduleId = id;
            this.moduleId = +atob(params.get('moduleId')!) as unknown as any;
            this.moduleName = this.commonService.getModuleName(this.moduleId);
            this.getList();
          }
        }
      }
    );
    this.calculateGridHeight();
  }

  updateList(): void {
    this.getList();
  }

  ngOnDestroy(): void {
    this.subscriptionGetList?.unsubscribe();
    this.subscriptionAPIGetList?.unsubscribe();
    this.subscriptionRouting?.unsubscribe();
  }

  receiptPaymentChange(event: any, data: any): void {
    this.commonService.onEditRouting(
      false,
      event.moduleId,
      data.Id.rowData,
      true
    );
  }

  setColumnWidth(moduleId): number {
    let columnWidth;

    switch (moduleId) {
      case Modules.CustomerAgeingList:
      case Modules.SalesInvoiceList:
      case Modules.SupplierAgeingList:
        columnWidth = 200;
        break;
      case Modules.ComparativeReport:
        columnWidth = 250;
        break;
      default:
        columnWidth = 150;
        break;
    }

    return columnWidth;
  }

  getHeaderData(): void {
    let param;

    switch (this.moduleId) {
      case Modules.Customers:
        param = Modules.CustomerDetailHeader;
        break;
      case Modules.Suppliers:
        param = Modules.SupplierDetailHeader;
        break;
    }
    this.store.dispatch(new GetHeaderList(param));
  }

  calculateGridHeight(): void {
    if (this.moduleId !== undefined) {
      if (this.moduleId !== Modules.VAT) {
        const screenHeight = window.innerHeight;
        const headerHeight = 56;
        const gridHeaderHeight = 75;
        const tableHeaderHeight = 56;
        const cardFooterHeight = 116;
        const accountDetailsHeight = 400;
        const contentPadding = 15;
        const groupableSectionHeight = 48;
        const gridHeaderTitle = 34;
        let paginationHeight = this.isReportGridGroupingAllowed(this.moduleId)
          ? 62
          : 0;

        if (this.selectedRowData.length > 0) {
          this.gridHeight =
            screenHeight -
            (headerHeight +
              gridHeaderHeight +
              tableHeaderHeight +
              cardFooterHeight +
              contentPadding +
              groupableSectionHeight);
        } else if (this.showAccountDetails) {
          this.gridHeight =
            screenHeight -
            (headerHeight +
              gridHeaderHeight +
              paginationHeight +
              tableHeaderHeight +
              contentPadding +
              groupableSectionHeight +
              accountDetailsHeight);
        } else if (
          this.moduleId === Modules.TransactionListByDate ||
          this.moduleId === Modules.RecentTransactions ||
          this.moduleId === Modules.DayBook ||
          this.moduleId === Modules.OpenInvoice ||
          this.moduleId === Modules.UnpaidPurchases ||
          this.moduleId === Modules.AccountList ||
          this.moduleId === Modules.CustomerAdvanceReport ||
          this.moduleId === Modules.CustomerList ||
          this.moduleId === Modules.CustomerAgeingList ||
          this.moduleId === Modules.TransactionsByCustomer ||
          this.moduleId === Modules.SalesProductList ||
          this.moduleId === Modules.CustomerStatement ||
          this.moduleId === Modules.SalesInvoiceList ||
          this.moduleId === Modules.IncomeByCustomerSummary ||
          this.moduleId === Modules.CustomerBalanceSummary ||
          this.moduleId === Modules.SupplierAdvanceReport ||
          this.moduleId === Modules.SupplierList ||
          this.moduleId === Modules.SupplierAgeingList ||
          this.moduleId === Modules.TransactionsBySupplier ||
          this.moduleId === Modules.SupplierStatement ||
          this.moduleId === Modules.PurchasesInvoiceList ||
          this.moduleId === Modules.AccountDetails
        ) {
          this.gridHeight =
            screenHeight -
            (headerHeight +
              gridHeaderHeight +
              tableHeaderHeight +
              contentPadding +
              groupableSectionHeight +
              gridHeaderTitle +
              paginationHeight);
        } else {
          this.gridHeight =
            screenHeight -
            (headerHeight +
              paginationHeight +
              gridHeaderHeight +
              tableHeaderHeight +
              contentPadding +
              groupableSectionHeight);
        }
      }
    }
  }

  isQuickAdd(event): void {
    if (event) {
      this.triggerBankAccountList.next(true);
    }
  }

  checkIsCustom(event: any): void {
    this.isCustom = event.isCustom;
    this.customId = event.id;
  }

  checkIsExpanded(event: any): void {
    this.bankDetailsIsExpanded = event;
    this.calculateGridHeight();
  }

  getActionName(): any {
    let actionName;

    if (this.showAccountDetails) {
      actionName = GetAccountTransactionList;
    } else {
      switch (this.moduleId) {
        case Modules.TrialBalance:
        case Modules.SupplierAdvanceReport:
        case Modules.PurchasesReport:
        case Modules.SupplierList:
        case Modules.SupplierAgeingList:
        case Modules.TransactionsBySupplier:
        case Modules.PaymentToSupplier:
        case Modules.SupplierStatement:
        case Modules.PurchasesInvoiceList:
        case Modules.CustomerAdvanceReport:
        case Modules.SalesReport:
        case Modules.CustomerList:
        case Modules.CustomerAgeingList:
        case Modules.TransactionsByCustomer:
        case Modules.SalesProductList:
        case Modules.CustomerReceipts:
        case Modules.CustomerStatement:
        case Modules.SalesInvoiceList:
        case Modules.Import:
        case Modules.Customers:
        case Modules.Suppliers:
        case Modules.SalesInvoiceList:
        case Modules.AccountDetails:
        case Modules.BalanceSheet:
        case Modules.ComparativeReport:
        case Modules.Inventory:
        case Modules.BudgetReport:
        case Modules.SalesDayBook:
        case Modules.PurchaseDayBook:
        case Modules.BankImportHistory:
        case Modules.IncomeAndExpenditure:
        case Modules.BankImportTransactionHistory:
        case Modules.VAT:
        case Modules.ProfitAndLoss:
        case Modules.OpenInvoice:
        case Modules.UnpaidPurchases:
        case Modules.IncomeByCustomerSummary:
        case Modules.CustomerBalanceSummary:
        case Modules.ExpensesBySupplierSummary:
        case Modules.SupplierBalanceSummary:
        case Modules.AdvanceAgeingList:
        case Modules.AccountPayableList:
        case Modules.QuotationByCustomer:
        case Modules.InvoiceAndReceivedPayments:
        case Modules.PurchaseAndAppliedPayments:
        case Modules.SalesByProductDetail:
        case Modules.SalesByProductSummary:
        case Modules.BalanceSheetComparison:
        case Modules.AccountList:
        case Modules.TransactionListByDate:
        case Modules.RecentTransactions:
        case Modules.BalanceSheetDetail:
        case Modules.DepositDetails:
        case Modules.ChequeDetails:
        case Modules.Invoices:
        case Modules.RecurringInvoice:
        case Modules.Quotation:
        case Modules.CreditNote:
        case Modules.Receipt:
        case Modules.Purchase:
        case Modules.RecurringPurchase:
        case Modules.DebitNote:
        case Modules.Payment:
        case Modules.ProfitAndLossComparision:
        case Modules.ProfitAndLossSummary:
        case Modules.ProfitAndLossWithPercentage:
        case Modules.ProfitAndLossDetail:
        case Modules.DayBook:
        case Modules.QuickEntry:
        case Modules.CIS300:
        case Modules.CISInvoice:
        case Modules.CIS300:
        case Modules.Journals:
        case Modules.BankTransfer:
        case Modules.Notes:
        case Modules.FixedAssets:
          actionName = GetReportList;
          break;

        default:
          actionName = GetMainList;
          break;
      }
    }

    return actionName;
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      branchIds: this.listParameters.branchIds ?? [],
      departmentIds: this.listParameters.departmentIds ?? [],
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids:
        this.listParameters.ids !== undefined &&
        this.listParameters.ids.length > 0
          ? this.listParameters.ids
          : null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      type:
        this.moduleId === Modules.ComparativeReport
          ? this.listParameters.type === undefined
            ? -1
            : this.listParameters.type
          : 0,
    };

    return queryParams;
  }

  getDataFromBank(data: any): void {
    if (this.moduleId === Modules.BankDashboard) {
      this.listParameters.pageNumber = 1;
      this.listParameters.moduleId = data.id;
      this.triggerResetBankModuleId.next(data.id);
      this.getList();
    }
  }

  getSecondParamter(params?: any): any {
    let queryParams;

    const dataParam = {
      detailListId: +this.cookieService.get('detailListId'),
      productId: this.cookieService.get('productId'),
      detailListModulesId:
        this.moduleId === Modules.Inventory
          ? DetailListModules.Inventory
          : undefined,
    };
    if (this.moduleId === Modules.ViewVATDetails) {
      queryParams = dataParam;
    } else if (this.showAccountDetails) {
      queryParams = this.commonService.isEmpty(params.moduleId)
        ? this.accountId
        : params.moduleId;
    } else {
      queryParams = this.moduleId;
    }

    return queryParams;
  }

  addNewLineItem(columnName, rowData): void {
    if (!columnName.some((e) => e.name === 'srNo')) {
      columnName.push({
        name: 'srNo',
        id: null,
        userId: null,
        dataType: 1,
        allowSortBy: true,
        alignment: null,
        isSelected: false,
        isLocked: false,
        moduleGridColumnId: 1,
        columnType: this.getDataType(1),
      });
      rowData = rowData.map(function (currentValue, index) {
        let extraField: any;
        extraField = {
          id: null,
          alignment: null,
          isBold: false,
          moduleId: 0,
          rowData: index,
        };

        currentValue.push(extraField);
        return currentValue;
      });
    }
  }

  setGridActions(actionsList: any): void {
    actionsList.forEach((element) => {
      let iconsCss;
      let clickevent;

      switch (element.actionType) {
        case ActionType.Create:
          iconsCss = 'e-create e-icons';
          clickevent = this.onGridCreateClick.bind(this);
          break;
        case ActionType.Update:
          iconsCss = 'e-edit e-icons';
          clickevent = this.onGridEditClick.bind(this);
          break;
        case ActionType.Archive:
          iconsCss = 'e-archive e-icons';
          clickevent = this.onGridArchiveClick.bind(this);
          break;
        case ActionType.Restore:
          iconsCss = 'e-restore e-icons';
          clickevent = this.onGridRestoreClick.bind(this);
          break;
        case ActionType.Delete:
          iconsCss = 'e-delete e-icons';
          clickevent = this.onGridDeleteClick.bind(this);
          break;
        case ActionType.Export:
          iconsCss = 'e-export e-icons';
          clickevent = this.onGridExportClick.bind(this);
          break;
        case ActionType.View:
          iconsCss = 'e-print-layout e-icons';
          clickevent = this.onGridViewClick.bind(this);
          break;
        case ActionType.Import:
          iconsCss = 'e-import e-icons';
          clickevent = this.onGridImportClick.bind(this);
          break;
        case ActionType.Email:
          iconsCss = 'e-comment-show e-icons';
          clickevent = this.onGridEmailClick.bind(this);
          break;
        case ActionType.Sell:
          iconsCss = 'e-create e-icons';
          clickevent = this.onGridSellClick.bind(this);
          break;
        case ActionType.Dispose:
          iconsCss = 'e-create e-icons';
          clickevent = this.onGridDisposeClick.bind(this);
          break;
        case ActionType.Explain:
          iconsCss = 'e-create e-icons';
          clickevent = this.onGridExplainClick.bind(this);
          break;
        case ActionType.Activate:
          iconsCss = 'e-create e-icons';
          clickevent = this.onGridActivateClick.bind(this);
          break;
      }

      const commandParam = {
        type: element.name,
        buttonOption: { iconCss: iconsCss, click: clickevent },
      };
      this.commands.push(commandParam);
    });
  }

  getList(isGridActionsSet: boolean = false): void {
    const actionName = this.getActionName();
    const params = this.getParamter();
    const secoundParameter = this.getSecondParamter(params);
    if (
      this.moduleId === Modules.AccountDetails &&
      (params.moduleId === undefined ||
        params.moduleId === null ||
        params.moduleId === Guid.EMPTY)
    ) {
      return;
    }

    if (
      this.moduleId !== Modules.Dashboard &&
      this.moduleId !== Modules.IncomeOverview &&
      this.moduleId !== Modules.ExpenseOverview &&
      this.moduleId !== Modules.FixedAssetOverview
    ) {
      if (
        secoundParameter !== null &&
        secoundParameter !== undefined &&
        secoundParameter.detailListId !== 0 &&
        !this.isDirectImport
      ) {
        this.spinner.show();

        this.subscriptionAPIGetList = this.store
          .dispatch(new actionName(params, secoundParameter))
          .subscribe((res) => {
            this.reportColumnList = [];

            let columnName = res.common.mainList.resultSet.columns;
            let rowData = res.common.mainList.resultSet.data;

            this.actions$.subscribe((x) => {
              if (x !== null && x !== undefined && x.length > 4) {
                this.moreActionCount = 3;
              }
            });

            if (this.isReport || this.isTreeList) {
              this.addNewLineItem(columnName, rowData);
            }

            let filteredColumnList;
            const excludedNames = ['ExtraValue1', 'ExtraValue', 'srNo'];

            filteredColumnList = columnName.filter(
              (i) => !excludedNames.includes(i.name)
            );

            const colArray: any = [];
            filteredColumnList.forEach((element, i) => {
              colArray.push({
                name: element.name,
                id: element.id,
                userId: element.userId,
                dataType: element.dataType,
                allowSortBy: element.allowSortBy,
                alignment: element.alignment,
                moduleId: element.moduleId,
                isSelected: element.name === 'Id' ? false : element.isSelected,
                isLocked: element.isLocked,
                moduleGridColumnId: element.moduleGridColumnId,
                columnType: this.getDataType(element.dataType),
              });
            });

            const filteredGridList = rowData.map((element) => {
              let e = {};

              Object.keys(element).forEach((key) => {
                const newKey = columnName[key].name;
                e[newKey] = element[key];
              });
              return e;
            });

            if (this.isReport) {
              this.reportColumnList = rowData.map((element, i) => {
                let e = {};
                Object.keys(element).forEach((key, j) => {
                  const newKey = columnName[key].name;
                  e[newKey] = element[j];
                });
                return e;
              });
            }

            if (this.isTreeList) {
              this.reportColumnList = this.generateRowData(rowData, columnName);

              this.tempReportColumnList = this.reportColumnList.map(
                (element, i) => {
                  let e = {};
                  let dataSub: any;
                  Object.keys(element).forEach((key, j) => {
                    if (key !== 'subtasks') {
                      const newKey = key;
                      e[newKey] = element[key].rowData;
                    } else {
                      const subTaskArray: any = [];
                      if (element['subtasks'].length > 0) {
                        element['subtasks'].forEach((elements) => {
                          subTaskArray.push(elements[0]);
                        });
                      }
                      if (subTaskArray.length > 0) {
                        dataSub = subTaskArray.map((elementSub, i) => {
                          let f = {};
                          Object.keys(elementSub).forEach((keys, k) => {
                            if (keys !== 'subtasks') {
                              const newKey = keys;
                              f[newKey] = elementSub[newKey].rowData;
                            }
                          });
                          return f;
                        });
                      }
                    }
                  });
                  e['subtasks'] = dataSub;
                  return e;
                }
              );
            }
            this.spinner.hide();
            this.tempGridData = filteredGridList;
            if (
              this.columns.length > 0 &&
              this.columns.length !== colArray.length
            ) {
              this.columns = [];
              this.spinner.show();
              setTimeout(() => {
                this.columns = colArray;
                this.spinner.hide();
              }, 0);
            } else if (this.columns.length === 0) {
              this.columns = colArray;
            }

            this.totalRecords =
              res.common.mainList.paginationModel.totalItemCount;

            this.state.take = this.isPaginationAllowed(this.moduleId)
              ? this.totalRecords
              : this.listParameters.pageSize;
            if (this.isTreeList) {
              this.gridData = [...this.tempReportColumnList];
            } else if (this.isReport) {
              this.gridData = {
                result: this.reportColumnList,
                count: this.totalRecords,
              };
            } else {
              this.gridData = {
                result: filteredGridList,
                count: this.totalRecords,
              };
            }
            this.subscriptionAPIGetList.unsubscribe();
          });
      }
    }
  }

  generateRowData(rowData, columnName) {
    return rowData
      .map((element) => {
        let hasChildren = element[0].hasChildren;
        let e: any = {};
        Object.keys(element).forEach((key, j) => {
          const newKey = columnName[key].name;
          let childArray: any = [];

          if (hasChildren.length > 0) {
            for (let index = 0; index < hasChildren.length; index++) {
              childArray.push(
                this.generateRowData([hasChildren[index]], columnName)
              );
            }
          }
          e[newKey] = {
            rowData: element[j],
            hasChildren: childArray,
          };
        });

        e['subtasks'] = e.Id.hasChildren;

        return e;
      })
      .flat();
  }

  isReportGridPaginationAllowed(moduleId): boolean {
    let isAllowed;

    switch (moduleId) {
      case Modules.SalesReport:
      case Modules.BalanceSheet:
      case Modules.TrialBalance:
      case Modules.ProfitAndLoss:
      case Modules.SalesReport:
      case Modules.ComparativeReport:
      case Modules.ProfitAndLossComparision:
      case Modules.ProfitAndLossSummary:
      case Modules.ProfitAndLossWithPercentage:
      case Modules.BalanceSheetComparison:
      case Modules.PurchasesReport:
      case Modules.VAT:
        isAllowed = false;
        break;
      default:
        isAllowed = true;
        break;
    }
    return isAllowed;
  }

  isReportGridGroupingAllowed(moduleId): boolean {
    let isAllowed;

    switch (moduleId) {
      case Modules.BalanceSheet:
      case Modules.TrialBalance:
      case Modules.ProfitAndLoss:
      case Modules.VAT:
        isAllowed = false;
        break;
      default:
        isAllowed = true;
        break;
    }
    return isAllowed;
  }

  isPaginationAllowed(moduleId): boolean {
    let isAllowed;

    switch (moduleId) {
      case Modules.TrialBalance:
      case Modules.BalanceSheet:
      case Modules.VAT:
      case Modules.ComparativeReport:
      case Modules.IncomeAndExpenditure:
      case Modules.ProfitAndLoss:
      case Modules.BalanceSheetComparison:
      case Modules.BalanceSheetDetail:
      case Modules.ProfitAndLossComparision:
      case Modules.ProfitAndLossSummary:
      case Modules.ProfitAndLossWithPercentage:
      case Modules.PurchasesReport:
        isAllowed = true;
        break;
      default:
        isAllowed = false;
        break;
    }

    return isAllowed;
  }

  getPageableConfig(): any {
    let moduleId: number = 0;
    this.moduleId$.subscribe((Id) => {
      moduleId = Id;

      if (this.isPaginationAllowed(moduleId)) {
        this.pageableConfig = false;
        this.groupable = false;
      } else {
        this.pageableConfig = {
          buttonCount: 5,
          info: true,
          pageSizes: true,
          position: 'bottom',
        };
      }
    });
  }

  filterData(): void {
    if (this.isReport || this.isTreeList) {
      this.gridData = this.reportColumnList;
    }

    const dataManager = new DataManager(this.gridData);

    // Apply the state information (sorting, filtering, grouping, and paging) to the DataManager
    const query = new Query().requiresCount();
    query.skip(0);
    query.take(20);

    // Execute the query using the DataManager
    const processedData = dataManager.executeQuery(query);

    // Assign the processed data to the dataSource property
    this.dataSource = processedData;

    if (this.isReport) {
      this.resetFilterData();
    }

    if (this.isTreeList) {
      this.dataSource = this.reportColumnList;
    }

    this.dataSource.total = this.totalRecords;
    this.state.take = this.listParameters.pageSize;
    this.cdr.detectChanges();
  }

  resetFilterDataForTreeList(): void {
    let filterArray: any[] = [];
    let isFilter = false;

    this.dataSource.data.forEach((element) => {
      if (
        element.items !== null &&
        element.items !== undefined &&
        element?.items?.length > 0
      ) {
        isFilter = true;
        element.items.forEach((res, i) => {
          const itemdata = this.tempGridData.find(
            (x) => x.srNo.rowData === res.srNo
          );
          if (itemdata !== null && itemdata !== undefined) {
            element.items[i] = itemdata;
          }
        });
      } else {
        const data = this.tempGridData.find(
          (x) => x.srNo.rowData === element.srNo.rowData.rowData
        );
        if (data !== null && data !== undefined) {
          filterArray.push(data);
        }
      }
    });
    if (!isFilter) {
      this.gridData = filterArray;
      this.dataSource.data = this.gridData;
    }
  }

  resetFilterData(): void {
    let filterArray: any[] = [];
    let isFilter = false;

    this.dataSource.data.forEach((element) => {
      if (
        element.items !== null &&
        element.items !== undefined &&
        element?.items?.length > 0
      ) {
        isFilter = true;
        element.items.forEach((res, i) => {
          const itemdata = this.tempGridData.find(
            (x) => x.srNo.rowData === res.srNo
          );
          if (itemdata !== null && itemdata !== undefined) {
            element.items[i] = itemdata;
          }
        });
      } else {
        const data = this.tempGridData.find(
          (x) => x.srNo.rowData === element.srNo
        );
        if (data !== null && data !== undefined) {
          filterArray.push(data);
        }
      }
    });
    if (!isFilter) {
      this.gridData = filterArray;
      this.dataSource.data = this.gridData;
    }
  }

  resetSearchFilterData(): void {
    let filterArray: any[] = [];
    let isFilter = false;
    this.dataSource.forEach((element) => {
      if (
        element.items !== null &&
        element.items !== undefined &&
        element?.items?.length > 0
      ) {
        isFilter = true;
        element.items.forEach((res, i) => {
          const itemdata = this.tempGridData.find(
            (x) => x.srNo.rowData === res.srNo
          );
          if (itemdata !== null && itemdata !== undefined) {
            element.items[i] = itemdata;
          }
        });
      } else {
        const data = this.tempGridData.find(
          (x) => x.srNo.rowData === element.srNo
        );
        if (data !== null && data !== undefined) {
          filterArray.push(data);
        }
      }
    });
    if (!isFilter) {
      this.gridData = filterArray;
      this.dataSource = this.gridData;
    }
  }

  dataStateChange(state: DataStateChangeEventArgs): void {
    if (
      state.action.requestType === 'filtering' ||
      state.action.requestType === 'paging' ||
      state.action.requestType === 'sorting' ||
      state.action.requestType === 'grouping' ||
      state.action.requestType === 'ungrouping'
    ) {
      this.state = state;
      if (
        state.sorted !== null &&
        state.sorted !== undefined &&
        state.sorted.length > 0
      ) {
        this.listParameters.sortBy = state.sorted[0].name.replace(
          '.rowData',
          ''
        );
        this.listParameters.sortOrder =
          state.sorted[0].direction === 'ascending' ? true : false;
      }
      this.listParameters.pageNumber = state.skip / state.take + 1;
      this.listParameters.pageSize = state.take ?? 0;
      this.getList();
    }
  }

  getDataType(type: number): any {
    let dataType = '';

    switch (type) {
      case DataType.String:
        dataType = 'text';
        break;

      case DataType.Int32:
      case DataType.Int64:
      case DataType.Float:
      case DataType.Currency:
        dataType = `numeric`;
        break;

      case DataType.Bool:
        dataType = `boolean`;
        break;

      case DataType.Date:
      case DataType.DateTime:
        dataType = `date`;
        break;
      default:
        dataType = 'text';
        break;
    }

    return dataType;
  }

  isTreeGridPaginationAllowed(moduleId): boolean {
    let isAllowed;

    switch (moduleId) {
      case Modules.BankImportHistory:
        isAllowed = true;
        break;
      default:
        isAllowed = false;
        break;
    }
    return isAllowed;
  }

  onSearch(input: Event): void {
    const searchText: string = (input.target as HTMLInputElement).value;
    this.listParameters.search = searchText;
    this.getList();
  }

  getMultipleModuleId(element): void {
    if (!this.multipleModulesIds[+element.ExtraValue]) {
      this.multipleModulesIds[+element.ExtraValue] = [];
    }
    this.multipleModulesIds[+element.ExtraValue].push(element.Id);
  }

  downloadFile(event: any): void {
    const list: any[] = [];
    list.push(event);
    const param = {
      fileURLs: list,
    };
    this.commonService.downloadFile(param).subscribe();
  }

  selectedIds(): void {
    this.ids = [];
    this.multipleModulesIds = {};
    this.selectedStatus = [];

    this.selectedRowData.forEach((element) => {
      if (this.isReport) {
        this.ids.push(element.Id.rowData);
        this.selectedStatus.push(element.Status.rowData);
      } else {
        this.ids.push(element.Id);
        this.selectedStatus.push(element.Status);
      }

      if (
        this.moduleId === Modules.CashEntry ||
        this.moduleId === Modules.BankEntry
      ) {
        this.getMultipleModuleId(element);
      }
    });

    if (
      this.moduleId === Modules.VatSettings &&
      this.selectedRowData.some((e) => e.ExtraValue === '1')
    ) {
      this.selectedRowData = [];
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.standardVATCodeErrorMessage
      );
    }

    setTimeout(() => {
      this.triggerSelectedRowData.next(this.selectedStatus);
    }, 100);

    this.calculateGridHeight();
  }

  onDeleteClick(id: any, moduleId: number): void {
    this.isCustom = true;
    this.ids = [];
    this.ids.push(id);

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getList();
          }
        });
    }
  }

  getSelectedRowIndex(selectedRowIndex, rowIndex: number): void {
    this.selectedRowIndex =
      selectedRowIndex === -1 ? selectedRowIndex : rowIndex;
  }

  onTOCButtonClick(element: any): void {
    let id = element.Id;
    this.dialog
      .open(EditAssetDetailComponent, {
        data: {
          id: btoa(id),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.getList();
      });
  }

  onButtonClick(actionType: any, data: any): void {
    if (actionType === ActionType.Explain) {
      this.onTOCButtonClick(data);
    } else {
      this.onActionClick(actionType, data);
    }
  }

  onActionClick(actionType: any, data: any): void {
    let id = this.isReport ? data.Id.rowData : data.Id;
    this.ids = [];
    this.ids.push(id);

    let moduleId =
      this.moduleId === Modules.CashEntry || this.moduleId === Modules.BankEntry
        ? +data.ExtraValue
        : this.moduleId;

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (
          isValidPeriod ||
          actionType === ActionType.Email ||
          actionType === ActionType.Export ||
          actionType === ActionType.View
        ) {
          switch (actionType) {
            case ActionType.Update:
              if (this.moduleId === Modules.FixedAssets) {
                this.fixedAssetExplainCheck(id).subscribe((res) => {
                  if (res) {
                    this.dialog
                      .open(ConfirmationBoxComponent, {
                        data: {
                          ids: this.ids,
                          type: ConfirmationType.FixedAssetExplainRemove,
                          moduleId: Modules.FixedAssets,
                          totalNumberOfRecordSelected: this.ids.length,
                          headerText: NotificationHeader.confirmation,
                          detailText: NotificationDetails.fixedAssetExplain,
                        },
                      })
                      .afterClosed()
                      .subscribe((result) => {
                        if (result) {
                          this.commonService.onEditRouting(true, moduleId, id);
                        }
                      });
                  } else {
                    this.commonService.onEditRouting(true, moduleId, id);
                  }
                });
              } else if (
                this.moduleId === Modules.Payment ||
                this.moduleId === Modules.Receipt
              ) {
                const parameter = {
                  moduleId: this.moduleId,
                  id: id,
                };
                this.dialog
                  .open(EditReceiptComponent, {
                    data: parameter,
                    disableClose: true,
                  })
                  .afterClosed()
                  .subscribe((result: boolean) => {
                    if (result) {
                      this.getList();
                    }
                  });
              } else {
                this.commonService.onEditRouting(true, moduleId, id);
              }
              break;

            case ActionType.Delete:
              this.onDeleteClick(id, moduleId);
              break;

            case ActionType.DownloadCertificate:
              this.store
                .dispatch(new ExportSubcontractorVerification(id))
                .subscribe();
              break;

            case ActionType.Export:
              this.ids = [];
              this.ids.push(id);
              if (moduleId !== Modules.SubmitVat) {
                this.store
                  .dispatch(new ExportReceipt(moduleId, this.ids, false))
                  .subscribe();
              } else {
                this.store.dispatch(new ExportVatAudit(id, false)).subscribe();
              }
              break;

            case ActionType.Email:
              this.store
                .dispatch(new SendEmail(moduleId, id))
                .subscribe((result: any) => {
                  if (result.common.isMailSent) {
                    this.notify.success(
                      NotificationHeader.success,
                      NotificationTextMessage.emailMessage
                    );
                  } else {
                    this.notify.error(
                      NotificationHeader.error,
                      NotificationTextMessage.emailNotSendMessage
                    );
                  }
                });
              break;

            case ActionType.EmailWithPaymentLink:
              this.store
                .dispatch(new SendEmail(moduleId, id, true))
                .subscribe();
              this.notify.success(
                NotificationHeader.success,
                NotificationTextMessage.emailMessage
              );
              break;

            case ActionType.View:
              this.openView(id, moduleId);
              break;

            case ActionType.AddReceipt:
              const params = {
                isReceipt: true,
                id: id,
              };
              this.dialog
                .open(AddReceiptPaymentPopupComponent, {
                  data: params,
                  disableClose: true,
                })
                .afterClosed()
                .subscribe((result: boolean) => {
                  if (result) {
                    this.getList();
                  }
                });
              break;

            case ActionType.AddPayment:
              const param = {
                isReceipt: false,
                id: id,
              };
              this.dialog
                .open(AddReceiptPaymentPopupComponent, {
                  data: param,
                  disableClose: true,
                })
                .afterClosed()
                .subscribe((result: boolean) => {
                  if (result) {
                    this.getList();
                  }
                });
              break;
            case ActionType.BadDebtsEntry:
              this.store
                .dispatch(new GetDataByInvoiceId(id))
                .subscribe((res) => {
                  const invoiceVatDetails = res.invoice.invoiceData.items;
                  let badDebtsParams: any;
                  badDebtsParams = {
                    invoiceId: btoa(id),
                    isFromInvoiceAction: true,
                    customerId: btoa(data.Customer.id),
                    dueAmount: data['Due Amount'].rowData,
                    vatRateId: invoiceVatDetails[0]?.vatRateId,
                    vatRateAmount: invoiceVatDetails[0]?.vatAmount,
                  };
                  this.router.navigate([
                    RoutingPath.AddJournals,
                    badDebtsParams,
                  ]);
                });
              break;
            case ActionType.AllocateRefund:
              const parameter = {
                moduleId: this.moduleId,
                id: id,
              };
              this.dialog
                .open(AddAllocateRefundPopupComponent, {
                  data: parameter,
                  disableClose: true,
                })
                .afterClosed()
                .subscribe((result: boolean) => {
                  if (result) {
                    this.getList();
                  }
                });
              break;
            case ActionType.CreateInvoice:
              this.spinner.show();
              this.convertToInvoice(id);
              break;
            case ActionType.FixedAssetReturn:
              this.spinner.show();

              const parameters = {
                moduleId: this.moduleId,
                id: id,
              };
              this.dialog
                .open(FixedAssetReturnPopupComponent, {
                  data: parameters,
                  disableClose: true,
                })
                .afterClosed()
                .subscribe((result: boolean) => {
                  if (result) {
                    this.getList();
                  }
                });

              break;

              case ActionType.Copy:
                this.spinner.show();
                this.ids = [];
                this.ids.push(id);
                this.store
                  .dispatch(new Copy(this.moduleId, this.ids))
                  .subscribe((res) => {
                    if (res.common.isCopied) {
                      this.notify.success(
                        NotificationHeader.success,
                        NotificationTextMessage.recordCopySuccessfully
                      );
                      this.getList();
                    }
                  });
                break;
          }
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  openView(id: any, moduleId: any): void {
    const data: ViewParamModel = {
      moduleId: moduleId,
      id: id,
    };

    if (
      this.moduleId !== Modules.BankTransfer &&
      this.moduleId !== Modules.Notes
    ) {
      this.dialog
        .open(ViewReceiptComponent, {
          data,
          disableClose: true,
        })
        .afterClosed()
        .subscribe((result) => {});
    } else if (this.moduleId === Modules.Notes) {
      this.dialog
        .open(SchedulesNotesViewComponent, {
          data,
          disableClose: true,
        })
        .afterClosed()
        .subscribe((result) => {});
    } else {
      this.dialog
        .open(BankTransferViewComponent, {
          data,
          disableClose: true,
        })
        .afterClosed()
        .subscribe((result) => {});
    }
  }

  emailClick(): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(EmailPopupComponent, {
          data: {
            ids: this.ids,
            moduleId: this.moduleId,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.notify.success(
              NotificationHeader.success,
              NotificationTextMessage.mailSentSuccessfully
            );
            this.cancelSelectionClick();
          }
        });
    }
  }

  onSelectedKey(context: any): any {
    return context.dataItem;
  }

  public onSelected(args: any): void {
    // Update the selectedData variable with the selected data item
    this.selectedData = args.selectedRows[0]?.data;
  }

  pageChange(event: any): void {
    decodeURI;
    this.skip = event.skip;
    if (this.state.skip === 0) {
      this.listParameters.pageNumber = event.skip / event.take + 1;
      this.listParameters.pageSize = event.take;
      this.getList();
    }
  }

  dateChanged(date: any): void {
    this.listParameters.startDate = date.startDate;
    this.listParameters.endDate = date.endDate;
    this.getList();
  }

  exportType(format: number, isPrint: boolean = false): void {
    if (this.isReport) {
      this.exportReport(format, isPrint);
    } else {
      this.export(format, isPrint);
    }
  }

  exportReport(format: number, isPrint?: boolean): void {
    this.spinner.show();
    this.selectedIds();

    if (
      (this.moduleId === Modules.Invoices ||
        this.moduleId === Modules.Purchase ||
        this.moduleId === Modules.Quotation ||
        this.moduleId === Modules.CreditNote ||
        this.moduleId === Modules.DebitNote ||
        this.moduleId === Modules.CISInvoice ||
        this.moduleId === Modules.FixedAssets ||
        this.moduleId === Modules.Journals ||
        this.moduleId === Modules.Receipt ||
        this.moduleId === Modules.Payment ||
        this.moduleId === Modules.Dividends ||
        this.moduleId === Modules.Budgeting ||
        this.moduleId === Modules.FixedAssetDetail ||
        this.moduleId === Modules.BridgingVAT ||
        this.moduleId === Modules.QuickEntry) &&
      format === ExportFormat.PDF &&
      this.ids.length > 0
    ) {
      this.store
        .dispatch(new ExportReceipt(this.moduleId, this.ids, isPrint))
        .subscribe();
      this.cancelSelectionClick();
    } else {
      let totalRecordOfRecords = 0;

      this.totalRecord$.subscribe((x) => {
        totalRecordOfRecords = x;
      });

      let actionName;
      let param;

      switch (this.showAccountDetails) {
        case true:
          actionName = ReportExport;
          param = this.accountId;
          break;
        case false:
          actionName = Export;
          param = this.moduleId;
          break;
      }

      if (actionName !== undefined && totalRecordOfRecords > 0) {
        this.spinner.show();
        const queryParams = {
          pageNumber: this.listParameters.pageNumber,
          pageSize: this.listParameters.pageSize,
          filter: this.listParameters.filter,
          sortBy: this.listParameters.sortBy,
          sortOrder: this.listParameters.sortOrder,
          search: this.listParameters.search,
          moduleId: this.listParameters.moduleId,
          subModuleId: this.listParameters.subModuleId ?? -1,
          ids: this.ids,
          startDate:
            this.datepipe
              .transform(this.listParameters.startDate, 'yyyy-MM-dd')
              ?.toString() ?? null,
          endDate:
            this.datepipe
              .transform(this.listParameters.endDate, 'yyyy-MM-dd')
              ?.toString() ?? null,
          format: format,
          isPrint: isPrint,
          type:
            this.moduleId === Modules.ComparativeReport
              ? this.listParameters.type === undefined
                ? -1
                : this.listParameters.type
              : 0,
        };
        this.store.dispatch(new actionName(queryParams, param)).subscribe();
      } else {
        this.notify.error(
          NotificationHeader.error,
          NotificationTextMessage.noDataToExport
        );
      }
    }
  }

  export(format: number, isPrint: boolean = false): void {
    this.spinner.show();
    this.selectedIds();

    if (
      (this.moduleId === Modules.Invoices ||
        this.moduleId === Modules.Purchase ||
        this.moduleId === Modules.Quotation ||
        this.moduleId === Modules.CreditNote ||
        this.moduleId === Modules.DebitNote ||
        this.moduleId === Modules.CISInvoice ||
        this.moduleId === Modules.FixedAssets ||
        this.moduleId === Modules.Journals ||
        this.moduleId === Modules.Receipt ||
        this.moduleId === Modules.Payment ||
        this.moduleId === Modules.Dividends ||
        this.moduleId === Modules.Budgeting ||
        this.moduleId === Modules.FixedAssetDetail ||
        this.moduleId === Modules.BridgingVAT ||
        this.moduleId === Modules.QuickEntry) &&
      format === ExportFormat.PDF &&
      this.ids.length > 0
    ) {
      this.store
        .dispatch(new ExportReceipt(this.moduleId, this.ids, isPrint))
        .subscribe();
      this.cancelSelectionClick();
    } else {
      let totalRecordOfRecords = 0;

      this.totalRecord$.subscribe((x) => {
        totalRecordOfRecords = x;
      });

      if (totalRecordOfRecords > 0) {
        const queryParams: any = {
          pageNumber: this.listParameters.pageNumber,
          pageSize: this.listParameters.pageSize,
          filter: this.listParameters.filter,
          sortBy: this.listParameters.sortBy,
          sortOrder: this.listParameters.sortOrder,
          search: this.listParameters.search,
          moduleId: this.listParameters.moduleId,
          subModuleId: this.listParameters.subModuleId ?? -1,
          ids: this.ids,
          startDate:
            this.datepipe
              .transform(this.listParameters.startDate, 'yyyy-MM-dd')
              ?.toString() ?? null,
          endDate:
            this.datepipe
              .transform(this.listParameters.endDate, 'yyyy-MM-dd')
              ?.toString() ?? null,
          format: format,
          isPrint: isPrint,
        };
        this.store
          .dispatch(new Export(queryParams, this.moduleId))
          .subscribe(() => {
            this.cancelSelectionClick();
          });
        this.cancelSelectionClick();
      } else {
        this.spinner.hide();
        this.notify.error(
          NotificationHeader.error,
          NotificationTextMessage.noDataToExport
        );
      }
    }
  }

  cancelSelectionClick(): void {
    this.ids = [];
    this.multipleModulesIds = {};
    this.selectedStatus = [];
    this.selectedRowData = [];
    this.calculateGridHeight();
    if (!this.isTreeList) {
      this.grid?.clearSelection();
    } else {
      this.treegrid?.clearSelection();
    }
  }

  copyClick(): void {
    this.spinner.show();
    this.selectedIds();

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (isValidPeriod) {
          if (Object.keys(this.multipleModulesIds).length > 0) {
            Object.keys(this.multipleModulesIds).forEach((element) => {
              this.store
                .dispatch(new Copy(+element, this.multipleModulesIds[+element]))
                .subscribe((res) => {
                  if (res.common.isCopied) {
                    this.notify.success(
                      NotificationHeader.success,
                      NotificationTextMessage.recordCopySuccessfully
                    );
                    this.getList();
                    this.cancelSelectionClick();
                  }
                });
            });
          } else {
            this.store
              .dispatch(new Copy(this.moduleId, this.ids))
              .subscribe((res) => {
                if (res.common.isCopied) {
                  this.notify.success(
                    NotificationHeader.success,
                    NotificationTextMessage.recordCopySuccessfully
                  );
                  this.getList();
                  this.cancelSelectionClick();
                }
              });
          }
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  deleteClick(): void {
    this.selectedIds();

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (this.ids.length > 0 && isValidPeriod) {
          this.dialog
            .open(ConfirmationBoxComponent, {
              data: {
                ids: this.ids,
                type: ConfirmationType.Delete,
                moduleId: this.moduleId,
                totalNumberOfRecordSelected: this.ids.length,
                headerText: NotificationHeader.deleteConfirmation,
                detailText: NotificationDetails.deleteAllDetailText,
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.getList();
                this.cancelSelectionClick();
              }
            });
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  archiveAndRestoreClick(isArchive: any): void {
    this.selectedIds();

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (this.ids.length > 0 && isValidPeriod) {
          this.dialog
            .open(ConfirmationBoxComponent, {
              data: {
                ids: this.ids,
                isArchive,
                multipleModulesIds: this.multipleModulesIds,
                type: ConfirmationType.Archive,
                moduleId: this.moduleId,
                totalNumberOfRecordSelected: this.ids.length,
                headerText: isArchive
                  ? NotificationHeader.archiveConfirmation
                  : NotificationHeader.restoreConfirmation,
                detailText: isArchive
                  ? NotificationDetails.archiveDetailText
                  : NotificationDetails.restoreDetailText,
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.ids = [];
                this.getList();
                this.cancelSelectionClick();
              }
            });
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  printClick(): void {
    this.export(ExportType.PDF, true);
    this.cancelSelectionClick();
  }

  columnMenuClick(args: ColumnMenuClickEventArgs) {
    if (args.item.id?.toString().includes('_chooser_')) {
      let filteredColumns = this.isReport
        ? this.columns.filter((i) => i.name !== 'srNo')
        : this.columns;

      filteredColumns.forEach((x) => {
        if (this.grid.getVisibleColumns().length > 0) {
          if (x.name === 'Id' || x.name === 'srNo') {
            x.isSelected = false;
            return;
          } else {
            const isSelectedColumn = this.grid
              .getVisibleColumns()
              .filter((data) => data.headerText === x.name);
            if (isSelectedColumn.length > 0) {
              x.isSelected = true;
              return;
            } else {
              x.isSelected = false;
              return;
            }
          }
        }
      });

      this.store
        .dispatch(new SaveColumnsUserLevel(filteredColumns))
        .subscribe();
    }
  }

  onVisibilityChange(filter: any): void {
    let filteredColumns = this.isReport
      ? this.columns.filter((i) => i.name !== 'srNo')
      : this.columns;

    filteredColumns.forEach((x) => {
      filter.columns.forEach((element) => {
        if (x.name !== 'Id' && x.name !== 'srNo' && x.name === element.field) {
          x.isSelected = !element.hidden;
          return;
        }
      });
    });

    this.store.dispatch(new SaveColumnsUserLevel(filteredColumns)).subscribe();
  }

  setPermission(url: any, id: any): void {
    setTimeout(() => {
      this.menuList$.subscribe((data) => {
        this.setData(url, data, ComponentName.DynamicGridListComponent, id);
      });
    }, 500);
  }

  emitPermissionData(data: any, componentName: any, id: any): void {
    const permissionData = new PermissionModel();
    permissionData.data = data;
    permissionData.componentName = componentName;
    this.modulePermission.permissionData.next(permissionData);
  }

  setData(event: any, data: any, componentName: any, id: any): void {
    data.forEach((x) => {
      if (x.url === event.slice(1) || x.addUrl === event.slice(1)) {
        this.store.dispatch(new SetModulePermission(x)).subscribe();
        this.emitPermissionData(x, componentName, id);
      } else {
        x.subMenu.map((y) => {
          if (
            y.url === event.slice(1) ||
            y.addUrl === event.slice(1).split(';')[0]
          ) {
            this.store.dispatch(new SetModulePermission(y)).subscribe();
            this.emitPermissionData(y, componentName, id);
          } else {
            y.subMenu.map((z) => {
              if (
                z.url === event.slice(1) ||
                z.addUrl === event.slice(1).split(';')[0]
              ) {
                this.store.dispatch(new SetModulePermission(z)).subscribe();
                this.emitPermissionData(z, componentName, id);
              } else {
                z.subMenu.map((z1) => {
                  if (
                    z1.url === event.slice(1) ||
                    z1.addUrl === event.slice(1).split(';')[0]
                  ) {
                    this.store
                      .dispatch(new SetModulePermission(z1))
                      .subscribe();
                    this.emitPermissionData(z1, componentName, id);
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  isAllowedOpenView(moduleId: number): boolean {
    let isAllowed;

    switch (moduleId) {
      case Modules.Invoices:
      case Modules.Purchase:
      case Modules.CreditNote:
      case Modules.DebitNote:
      case Modules.CISInvoice:
      case Modules.FixedAssets:
      case Modules.Quotation:
      case Modules.Journals:
      case Modules.Receipt:
      case Modules.Payment:
      case Modules.CIS:
      case Modules.FixedAssetDetail:
      case Modules.QuickEntry:
      case Modules.BankTransfer:
      case Modules.Notes:
        isAllowed = true;
        break;
      default:
        isAllowed = false;
        break;
    }

    return isAllowed;
  }

  redirect(columnName: string, data: any): void {
    if (data.moduleId > 0 && !this.isBankImport) {
      if (data.moduleId === Modules.AddCustomAccounts) {
        this.commonService.onEditRouting(
          true,
          Modules.ReportAccountDetail,
          data.id,
          false
        );
        this.setPermission(RoutingPath.ReportAccountDetailsPermission, data.id);
      } else if (
        data.moduleId === Modules.Customers ||
        data.moduleId === Modules.Suppliers
      ) {
        this.commonService.onEditRouting(true, data.moduleId, data.id);
      } else if (this.isAllowedOpenView(data.moduleId)) {
        this.openView(data.id, data.moduleId);
      } else if (this.moduleId === Modules.Inventory) {
        if (columnName === InventoryName.Purchase) {
          this.cookieService.set('detailListId', Inventory.Purchase.toString());
        } else if (columnName === InventoryName.Sales) {
          this.cookieService.set('detailListId', Inventory.Sales.toString());
        } else if (columnName === InventoryName.LossOfStock) {
          this.cookieService.set('detailListId', Inventory.Loss.toString());
        }
        this.cookieService.set('productId', data.id);
        this.router.navigate([RoutingPath.DetailList]);
      } else if (data.moduleId === Modules.CIS300) {
        this.viewCISMonthlyReturn(data.id);
      } else {
        this.commonService.onEditRouting(true, data.moduleId, data.id);
      }
    } else if (data.moduleId > 0 && this.isBankImport) {
      this.commonService.onEditRouting(
        true,
        Modules.BankImportTransactionHistory,
        data.id,
        true,
        Modules.BankImportTransactionHistory
      );
    }
  }

  viewCISMonthlyReturn(id: any): void {
    const params = {
      returnId: btoa(id),
    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = params;
    this.dialog
      .open(ViewCISMonthlyReturnPopupComponent, dialogConfig)
      .afterClosed()
      .subscribe();
  }

  onAddClick(element: any): void {
    this.router.navigate([
      RoutingPath.AddCustomAccounts,
      { accountId: btoa(element.Id) },
    ]);
  }

  getDataFromVatReport(data: any): void {
    this.listParameters.filter = data.filter;
    this.store
      .dispatch(new GetVatReportDetailList(data.id, this.getParamter()))
      .subscribe((res) => {});
  }

  getDataFromHeader(data: any): void {
    this.listParameters = data;
    this.listParameters.filter = data.filter;
    this.listParameters.moduleId =
      data.moduleId === -1 ? (Guid.EMPTY as unknown as Guid) : data.moduleId;
    this.listParameters.subModuleId = data.subModuleId;
    this.getList();
    if (this.moduleId === Modules.VAT) {
      this.triggerVatApi.next(true);
    }

    this.cancelSelectionClick();
  }

  onGridCreateClick(args: Event): void {}

  onGridArchiveClick(args: Event): void {}

  onGridRestoreClick(args: Event): void {}

  onGridImportClick(args: Event): void {}

  onGridEditClick(args: Event): void {
    let rowIndex: number = (<HTMLTableRowElement>(
      closest(args.target as Element, '.e-row')
    )).rowIndex;
    let data: any[] = (this.grid as GridComponent).getCurrentViewRecords();
    const selectedId = {
      Id: data[rowIndex].Id,
    };
    this.onActionClick(ActionType.Update, selectedId);
  }

  checkBoxChange(args): void {
    let data: any[] = (this.grid as GridComponent).getCurrentViewRecords();
    this.selectedRowData = [];
    if (args.selectedRowIndexes.length > 0) {
      args.selectedRowIndexes.forEach((element) => {
        this.selectedRowData.push(data[element]);
      });
    }
    this.calculateGridHeight();
  }

  onGridDeleteClick(args: Event): void {
    let rowIndex: number = (<HTMLTableRowElement>(
      closest(args.target as Element, '.e-row')
    )).rowIndex;
    let data: any[] = (this.grid as GridComponent).getCurrentViewRecords();
    const selectedId = {
      Id: data[rowIndex].Id,
    };
    this.onActionClick(ActionType.Delete, selectedId);
  }

  onGridExportClick(args: Event): void {
    let rowIndex: number = (<HTMLTableRowElement>(
      closest(args.target as Element, '.e-row')
    )).rowIndex;
    let data: any[] = (this.grid as GridComponent).getCurrentViewRecords();
    const selectedId = {
      Id: data[rowIndex].Id,
    };
    this.onActionClick(ActionType.Export, selectedId);
  }

  onGridViewClick(args: Event): void {
    let rowIndex: number = (<HTMLTableRowElement>(
      closest(args.target as Element, '.e-row')
    )).rowIndex;
    let data: any[] = (this.grid as GridComponent).getCurrentViewRecords();
    const selectedId = {
      Id: data[rowIndex].Id,
    };
    this.onActionClick(ActionType.View, selectedId);
  }

  onGridEmailClick(args: Event): void {
    let rowIndex: number = (<HTMLTableRowElement>(
      closest(args.target as Element, '.e-row')
    )).rowIndex;
    let data: any[] = (this.grid as GridComponent).getCurrentViewRecords();
    const selectedId = {
      Id: data[rowIndex].Id,
    };
    this.onActionClick(ActionType.Email, selectedId);
  }

  onGridSellClick(args: Event): void {}
  onGridDisposeClick(args: Event): void {}
  onGridExplainClick(args: Event): void {}
  onGridActivateClick(args: Event): void {}

  fixedAssetExplainCheck(id: any): Observable<boolean> {
    let actionName;
    if (this.moduleId === Modules.FixedAssets) {
      actionName = CheckDepreciation;
    } else {
    }
    let result = new Subject<boolean>();
    this.store.dispatch(new actionName(id)).subscribe((res) => {
      result.next(res.fixedAssets.isEdit);
    });

    return result.asObservable();
  }
  convertToInvoice(id: any): void {
    this.store.dispatch(new ConvertToInvoice(id)).subscribe((res) => {
      const parameters = {
        id: id,
        invoiceNumber: res.common.invoiceDetails.invoiceNumber,
        invoiceId: res.common.invoiceDetails.invoiceId,
        quotationNumber: res.common.invoiceDetails.quotationNumber,
      };
      this.dialog
        .open(ConvertToInvoicePopupComponent, {
          data: parameters,
          disableClose: true,
        })
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.getList();
            this.spinner.hide();
          }
        });
    });
  }

  rowDataBound(args) {
    if (
      this.moduleId === this.moduleEnum.Invoices ||
      this.moduleId === this.moduleEnum.CreditNote ||
      this.moduleId === this.moduleEnum.Purchase ||
      this.moduleId === this.moduleEnum.DebitNote ||
      this.moduleId === this.moduleEnum.FixedAssets ||
      this.moduleId === this.moduleEnum.Receipt ||
      this.moduleId === this.moduleEnum.Payment ||
      this.moduleId === this.moduleEnum.QuickEntry ||
      this.moduleId === this.moduleEnum.Journals
    ) {
      args.isSelectable = args.data.ExtraValue.rowData !== 'True';
    }
  }

  getClassName(col) {
    return col?.dataType === DataType.Currency ||
      col?.dataType === DataType.CurrencySymbol ||
      col?.dataType === DataType.Percentage
      ? 'e-rightamount'
      : 'no-class';
  }

  queryCellInfo(args: any): void {
    if (this.moduleId === Modules.TrialBalance) {
      if (
        args.column.field === 'Debit.rowData' ||
        args.column.field === 'Credit.rowData'
      ) {
        if (
          args.data?.Name?.id !== '' &&
          args.data?.Name?.id !== null &&
          args.data?.Name?.id !== undefined &&
          args.data?.Name?.id !== this.commonService.defaultGuidValue
        ) {
          args.cell.classList.add('text-primary');
        }
      }
    }
  }
}
