import { ProductsModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class GetProductList {
  static readonly type = '[PRODUCT] Get Product';
}

export class GetTrackedProducts {
  static readonly type = '[PRODUCT] Get Tracked Products';
}

export class CreateProduct {
  static readonly type = '[PRODUCT] Create Product';

  constructor(public product: ProductsModel) {}
}

export class GetDataByProductId {
  static readonly type = '[PRODUCT] Get Product Data By Product Id';

  constructor(public productId: Guid) {}
}

export class ArchiveAndRestoreProduct {
  static readonly type = '[PRODUCT] Archive and Restore Selected Product';

  constructor(public productIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteProduct {
  static readonly type = '[PRODUCT] Delete Selected Product';

  constructor(public productIds: Array<Guid>) {}
}

export class GetProductListByProductType {
  static readonly type = '[PRODUCT] Get Product Based on Product Type';
  constructor(public productType?: number) {}
}

export class GetProductListByProductTypeByBranch {
  static readonly type =
    '[PRODUCT] Get Product Based on Product Type By Branch';
  constructor(public productType?: number, public branchId?: Guid) {}
}
