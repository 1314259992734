import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModuleName, Modules, ReportFilterTypes } from '@app/core/Enum';
import { ModulePermission } from '@app/core/Services';
import { GetOverviewData, MenuState } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  filter1 = ReportFilterTypes.Last30Days;
  filter2 = ReportFilterTypes.Last30Days;
  filter3 = ReportFilterTypes.Last30Days;

  startDate = new FormControl({ value: new Date(), disabled: true });
  endDate = new FormControl({ value: new Date(), disabled: true });

  selectYear = [
    {
      id: 0,
      name: 'Last 30 days',
    },
    {
      id: 1,
      name: 'This month',
    },
    {
      id: 2,
      name: 'This quarter',
    },
    {
      id: 3,
      name: 'This year',
    },
    {
      id: 4,
      name: 'Last month',
    },
    {
      id: 5,
      name: 'Last quarter',
    },
    {
      id: 6,
      name: 'Last year',
    },
    {
      id: 7,
      name: 'Custom',
    },
  ];

  showCalender = false;
  moduleId = 0;
  module = Modules;
  moduleName = ModuleName;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Output()
  readonly triggerOverviewData = new EventEmitter<any>();

  @Output()
  readonly triggerData = new EventEmitter<any>();

  rangeFormGroup: FormGroup;

  constructor(
    private store: Store,
    public datepipe: DatePipe,
    private modulePermission: ModulePermission,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getData();
    this.setForm();
  }

  setForm(): void {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.rangeFormGroup = this.formBuilder.group({
      start: [startDate],
      end: [new Date()],
    });
  }

  getData(): void {
    const filterData = {
      filter1: {
        filter: this.filter1,
        startDate:
          this.filter1 < 7
            ? undefined
            : this.rangeFormGroup.controls.start.value,
        endDate:
          this.filter1 < 7 ? undefined : this.rangeFormGroup.controls.end.value,
      },

      filter2: {
        filter: this.filter2,
        startDate:
          this.filter2 < 7
            ? undefined
            : this.rangeFormGroup.controls.start.value,
        endDate:
          this.filter2 < 7 ? undefined : this.rangeFormGroup.controls.end.value,
      },

      filter3: {
        filter: this.filter3,
        startDate:
          this.filter3 < 7
            ? undefined
            : this.rangeFormGroup.controls.start.value,
        endDate:
          this.filter3 < 7 ? undefined : this.rangeFormGroup.controls.end.value,
      },
    };

    this.modulePermission.permissionData.subscribe((value) => {
      this.moduleId = value.data.id;
    });

    setTimeout(() => {
      this.store
        .dispatch(new GetOverviewData(filterData, this.moduleId))
        .subscribe((x) => {
          this.triggerOverviewData.emit(x.overview.overviewData);

          if (this.moduleId === this.module.FixedAssetOverview) {
            this.triggerData.emit(x.overview.overviewData.pieChart1);
          }
        });
    }, 1000);
  }

  onSelectionChange(val: any): void {
    this.showCalender = val === 7;
    if (
      this.rangeFormGroup.controls.start.value !== null &&
      this.rangeFormGroup.controls.end.value !== null
    ) {
      this.getData();
    }
  }
}
