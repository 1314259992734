import { DatePipe } from '@angular/common';
import {
  Component,
  Injector,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CompanyType,
  ModuleName,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { FileImportRequestModel, ImportStep } from '@app/core/Enum/import';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  GetCompanyTypeId,
  ImportModules,
  ImportState,
  MenuState,
  SaveImport,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { AddClosePopupComponent } from '../common';
import { DynamicGridListComponent } from '../dynamic-grid-list/dynamic-grid-list.component';
import { ImportFileDataComponent } from './import-file-data/import-file-data.component';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
})
export class ImportComponent implements OnInit {
  selectedModuleId = Modules.Invoices;
  moduleId = Modules.Import;
  panelHeaderTitle = ModuleName.Invoices;
  showImport: boolean = false;
  triggerImport: Subject<boolean> = new Subject<boolean>();
  triggerImportDetail: Subject<boolean> = new Subject<boolean>();
  triggerCancel: Subject<boolean> = new Subject<boolean>();
  isDirectImport = false;

  selectCategory: any[] = [
    {
      moduleId: Modules.Invoices,
      isSelected: true,
      icon: 'sticky_note_2',
      name: ModuleName.Invoices,
    },
    {
      moduleId: Modules.Purchase,
      isSelected: false,
      icon: 'receipt_long',
      name: ModuleName.Purchase,
    },
    {
      moduleId: Modules.CreditNote,
      isSelected: false,
      icon: 'post_add',
      name: ModuleName.CreditNote,
    },
    {
      moduleId: Modules.DebitNote,
      isSelected: false,
      icon: 'playlist_remove',
      name: ModuleName.DebitNote,
    },
    {
      moduleId: Modules.Customers,
      isSelected: false,
      icon: 'person_outline',
      name: ModuleName.Customers,
    },
    {
      moduleId: Modules.Suppliers,
      isSelected: false,
      icon: 'people',
      name: ModuleName.Suppliers,
    },
    {
      moduleId: Modules.OpeningBalance,
      isSelected: false,
      icon: 'balance',
      name: ModuleName.OpeningBalance,
    },
    {
      moduleId: Modules.Product,
      isSelected: false,
      icon: 'receipt_long',
      name: ModuleName.Product,
    },
    {
      moduleId: Modules.Director,
      isSelected: false,
      icon: 'supervisor_account',
      name: ModuleName.Directors,
    },
    {
      moduleId: Modules.Shareholder,
      isSelected: false,
      icon: 'groups',
      name: ModuleName.Shareholders,
    },
    {
      moduleId: Modules.Dividends,
      isSelected: false,
      icon: 'receipt_long',
      name: ModuleName.Dividends,
    },
    {
      moduleId: Modules.FixedAssets,
      isSelected: false,
      icon: 'call_to_action',
      name: ModuleName.FixedAssets,
    },
    {
      moduleId: Modules.BankDashboard,
      isSelected: false,
      icon: 'account_balance',
      name: ModuleName.BankImport,
    },
    {
      moduleId: Modules.QuickEntry,
      isSelected: false,
      icon: 'bolt',
      name: ModuleName.QuickEntry,
    },
    {
      moduleId: Modules.CIS,
      isSelected: false,
      icon: 'pallet',
      name: ModuleName.CIS,
    },
  ];

  fileImportRequestModel: FileImportRequestModel;
  importDetailList: any[] = [];

  @ViewChild(ImportFileDataComponent, { static: true })
  importFileData;

  @ViewChild(DynamicGridListComponent, { static: true })
  reportList;

  subscriptionRouting: Subscription;
  isLocked = false;
  companyTypeId = 0;
  store: Store;
  notifier: NotificationService;
  spinner: NgxSpinnerService;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  isModuleChangePermission: boolean = true;

  constructor(
    public dialog: MatDialog,
    public commonService: CommonService,
    private _Activatedroute: ActivatedRoute,
    private renderer: Renderer2,
    private router: Router,
    public datepipe: DatePipe,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.notifier = injector.get<NotificationService>(NotificationService);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
  }

  ngOnInit(): void {
    this.getCompanyTypeId().then(() => {
      this.filterList();
    });
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.spinner.hide();
          this.selectedModuleId = +atob(params.get('moduleId')!);
          this.radioChange(this.selectedModuleId);
          this.onNextClick();
        } else {
          this.isDirectImport = true;
        }
      }
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isViewPermission$.subscribe((res) => {
        if (!res) {
          this.checkPermissionToImport(Modules.GeneralManage, Modules.Clients);
        }
      });
    }, 500);
  }

  checkPermissionToImport(mainModule, subModule): void {
    this.isModuleChangePermission = this.commonService.checkPermission(
      mainModule,
      subModule
    );
  }

  async getCompanyTypeId(): Promise<void> {
    await this.store
      .dispatch(new GetCompanyTypeId())
      .toPromise()
      .then((res) => {
        this.companyTypeId = res.company.companyTypeId;
      });
  }

  filterList(): void {
    if (+this.companyTypeId !== CompanyType.Limited) {
      this.selectCategory = this.selectCategory.filter(
        (element) =>
          element.moduleId !== Modules.Director &&
          element.moduleId !== Modules.Shareholder &&
          element.moduleId !== Modules.Dividends
      );
    }
  }

  radioChange(event: any): void {
    this.selectedModuleId = event;
    switch (this.selectedModuleId) {
      case Modules.Invoices:
        this.panelHeaderTitle = ModuleName.Invoices;
        break;
      case Modules.Purchase:
        this.panelHeaderTitle = ModuleName.Purchase;
        break;
      case Modules.CreditNote:
        this.panelHeaderTitle = ModuleName.CreditNote;
        break;
      case Modules.DebitNote:
        this.panelHeaderTitle = ModuleName.DebitNote;
        break;
      case Modules.Customers:
        this.panelHeaderTitle = ModuleName.Customers;
        break;
      case Modules.Suppliers:
        this.panelHeaderTitle = ModuleName.Suppliers;
        break;
      case Modules.TrialBalance:
        this.panelHeaderTitle = ModuleName.TrialBalance;
        break;
      case Modules.OpeningBalance:
        this.panelHeaderTitle = ModuleName.OpeningBalance;
        break;

      case Modules.Product:
        this.panelHeaderTitle = ModuleName.Product;
        break;

      case Modules.Director:
        this.panelHeaderTitle = ModuleName.Directors;
        break;

      case Modules.Shareholder:
        this.panelHeaderTitle = ModuleName.Shareholders;
        break;

      case Modules.BridgingVAT:
        this.panelHeaderTitle = ModuleName.BridgingVAT;
        break;

      case Modules.Dividends:
        this.panelHeaderTitle = ModuleName.Dividends;
        break;

      case Modules.FixedAssets:
        this.panelHeaderTitle = ModuleName.FixedAssets;
        break;
      case Modules.BankDashboard:
        this.panelHeaderTitle = ModuleName.Bank;
        break;
      case Modules.CIS:
        this.panelHeaderTitle = ModuleName.CIS;
        break;
      case Modules.QuickEntry:
        this.panelHeaderTitle = ModuleName.QuickEntry;
        break;
      case Modules.Journals:
        this.panelHeaderTitle = ModuleName.Journals;
        break;
      default:
        break;
    }
  }
  onIgnoreClick(): void {
    this.dialog
      .open(AddClosePopupComponent, {
        data: {
          moduleId: this.moduleId,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.saveImport();
        }
      });
  }

  onCloseImportClick(): void {
    if (this.isDirectImport) {
      window.location.reload();
    } else {
      history.back();
    }
  }

  saveImport(): void {
    if (this.importFileData.importForm.invalid) {
      this.commonService.addValidation(
        this.importFileData.importForm,
        this.renderer
      );
    } else {
      const succesList: any[] = [];
      this.importFileData.importSuccessList.data.forEach((element) => {
       
        if (
          this.selectedModuleId !== Modules.Customers &&
          this.selectedModuleId !== Modules.Suppliers &&
          this.selectedModuleId !== Modules.TrialBalance &&
          this.selectedModuleId !== Modules.OpeningBalance &&
          this.selectedModuleId !== Modules.QuickEntry &&
          (!this.importFileData.importForm.controls.accountObj.value)
        ) {
          element.bookAccountId = element.accountData.id;
         
          element.branchId =
            this.importFileData.importForm.controls.branchId.value;
          element.departmentId =
            this.importFileData.importForm.controls.departmentId.value;
            delete element.accountData;
          succesList.push(element);
        } else if (
          this.selectedModuleId !== Modules.Customers &&
          this.selectedModuleId !== Modules.Suppliers &&
          this.selectedModuleId !== Modules.TrialBalance &&
          this.selectedModuleId !== Modules.OpeningBalance &&
          this.selectedModuleId !== Modules.QuickEntry &&
          this.importFileData.importForm.controls.incomeAccountObj.value !==
            null &&
          this.importFileData.importForm.controls.incomeAccountObj.value !==
            undefined &&
          this.importFileData.importForm.controls.depositAccountObj.value !==
            null &&
          this.importFileData.importForm.controls.depositAccountObj.value !==
            undefined
        ) {
          element.incomeAccountId =
            this.importFileData.importForm.controls.incomeAccountObj.value.id;
          element.depositedToId =
            this.importFileData.importForm.controls.depositAccountObj.value.id;
          succesList.push(element);
        } else if (
          this.selectedModuleId === Modules.Customers ||
          this.selectedModuleId === Modules.Suppliers ||
          this.selectedModuleId === Modules.Shareholder ||
          this.selectedModuleId === Modules.Director ||
          this.selectedModuleId === Modules.Dividends ||
          this.selectedModuleId === Modules.Product
        ) {
          if (
            this.selectedModuleId === Modules.Customers ||
            this.selectedModuleId === Modules.Suppliers ||
            this.selectedModuleId === Modules.Product
          ) {
            element.branchId =
              this.importFileData.importForm.controls.openingBranchId.value;
            if (this.selectedModuleId === Modules.Product) {
              element.departmentId =
                this.importFileData.importForm.controls.departmentId.value;
            }
          }
          succesList.push(element);
        } else if (
          this.selectedModuleId === Modules.TrialBalance &&
          element.accountId !== null &&
          element.accountId !== undefined
        ) {
          this.importFileData.periodicDateList.forEach((element) => {
            if (
              element.id ===
              this.importFileData.importForm.controls.accountingPeriod.value
            ) {
              this.isLocked = element.isLocked;
            }
          });
          if (!this.isLocked) {
            element.accountingPeriodId =
              this.importFileData.importForm.controls.accountingPeriod.value;
            element.accountId = element.accountId.id;
            succesList.push(element);
          }
        } else if (
          (this.selectedModuleId === Modules.OpeningBalance ||
            this.selectedModuleId === Modules.QuickEntry) &&
          element.accountId !== null &&
          element.accountId !== undefined
        ) {
          element.date = this.datepipe
            .transform(
              this.importFileData.importForm.controls.accountingDate.value,
              'yyyy-MM-dd'
            )
            ?.toString();
          element.accountId = element.accountId;

          succesList.push(element);
        } else if (
          this.selectedModuleId === Modules.CIS &&
          element.customerId !== null &&
          element.customerId !== undefined
        ) {
          succesList.push(element);
        }
      });
      if (succesList.length > 0) {
        const param = {
          successList: succesList,
          duplicateList:
            this.importFileData?.importDuplicateList.data.length > 0
              ? this.importFileData.importDuplicateList.data
              : [],
          errorList:
            this.importFileData.importErrorList.data.length > 0
              ? this.importFileData.importErrorList.data
              : [],
        };
        this.spinner.show();
        this.store
          .dispatch(new SaveImport(param, this.selectedModuleId))
          .subscribe((res) => {
            if (res) {
              this.spinner.hide();
              this.showImport = true;
              this.triggerImportDetail.next(res.import.saveImportData);
            }
          });
      } else {
        this.notifier.error(
          NotificationHeader.error,
          NotificationTextMessage.succesList
        );
      }
    }
  }

  onSaveNextClick(): void {
    this.saveImport();
  }

  onNextClick() {
    if (this.selectedModuleId === Modules.BankDashboard) {
      this.router.navigate([RoutingPath.BankImport]);
    } else {
      this.showImport = true;
      this.triggerImport.next(true);
    }
  }

  onPrevClick() {
    this.importFileData.step = ImportStep.Upload;
    this.importFileData.myStepper.selectedIndex = 0;
    this.importFileData.totalCredit = 0;
    this.importFileData.totalDebit = 0;
    this.importFileData.TotalDifference = 0;
  }

  onCancelClick() {
    if (this.isDirectImport) {
      this.showImport = false;
      this.importDetailList = [];
      this.reportList.getList();
      this.importFileData.showAccountName = false;
      this.importFileData.showIncomeAccount = false;
      this.importFileData.showDepositAccount = false;
      this.importFileData.importForm.reset();
      this.importFileData.selectedFile = '';
      this.importFileData.url = '';
      this.importFileData.fileInfo = '';
      this.importFileData.selectedFileName = '';
      this.importFileData.step = ImportStep.Select;
      this.importFileData.myStepper.selectedIndex = 0;
      this.importFileData.importErrorList.data = new MatTableDataSource([]);
      this.importFileData.importDuplicateList.data = new MatTableDataSource([]);
      this.importFileData.importSuccessList.data = new MatTableDataSource([]);
    } else {
      history.back();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  onCancel(isCancelClick: boolean): void {
    location.reload();
  }

  onSave(isExit: boolean): void {
    this.fileImportRequestModel = {
      file: this.importFileData.selectedFile,
      step: this.importFileData.step,
    };

    this.onImport();
  }

  onImport(): void {
    this.spinner.show();
    this.store
      .dispatch(
        new ImportModules(this.fileImportRequestModel, this.selectedModuleId)
      )
      .subscribe(() => {
        const details = this.store.selectSnapshot(ImportState.getImportData);
        this.spinner.hide();
        if (details) {
          this.onSuccess();
          this.resetForm(details);
        } else {
          this.onError();
        }
      });
  }

  resetForm(details): void {
    this.importDetailList = details;
    this.reportList.getList();
    this.importFileData.fileInfo = '';
    this.importFileData.selectedFileName = '';
    this.importFileData.selectedFile = '';
    this.importFileData.url = '';
  }

  onError(): void {
    this.notifier.error(
      NotificationHeader.error,
      NotificationTextMessage.errorMessage
    );
  }

  onSuccess(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.fileUploaded
    );
  }
}
