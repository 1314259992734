import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  DiscountType,
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import {
  CISInvoiceDetailsModel,
  CISInvoiceModel,
  PaymentDetailsModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateCISInvoice,
  GetDataByCISInvoiceId,
  MenuState,
} from '@app/core/Store';
import {
  AddBillComponent,
  AddClosePopupComponent,
  AdvancePaymentComponent,
  CISInvoiceDetailsComponent,
  ProductDetailsFooterComponent,
  TransactionsAttachmentComponent,
  TransactionsPaymentComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-cis-invoice',
  templateUrl: './cis-invoice.component.html',
  styleUrls: ['./cis-invoice.component.scss'],
})
export class CISInvoiceComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;

  moduleId = Modules.CISInvoice;
  moduleName = ModuleName.CISInvoice;

  cisInvoiceId = Guid.EMPTY as unknown as Guid;
  cisInvoiceData: CISInvoiceModel;
  cisInvoiceItem: Array<CISInvoiceDetailsModel>;
  receiptItems: Array<PaymentDetailsModel>;
  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(CISInvoiceDetailsComponent, { static: true })
  cisInvoiceDetails;

  @ViewChild(AddBillComponent, { static: true })
  billDetails;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  @ViewChild(TransactionsPaymentComponent, { static: true })
  receiptDetails;

  @ViewChild(AdvancePaymentComponent, { static: true })
  advancePaymentDetails;

  @ViewChild(ProductDetailsFooterComponent, { static: true })
  productDetailsFooter;

  subscriptionRouting: Subscription;
  headerText = 'Payment';
  hasAdvanceAmount = false;

  triggerEditData: Subject<any> = new Subject<any>();
  triggereEditProductData: Subject<any> = new Subject<any>();
  triggereEditPaymentData: Subject<any> = new Subject<any>();
  triggerAdvancePayment: Subject<any> = new Subject<any>();
  triggerCurrencySelectedOption: Subject<boolean> = new Subject<boolean>();
  triggerCurrencyRefreshOption: Subject<boolean> = new Subject<boolean>();
  triggerSelectedCurrencyRate: Subject<number> = new Subject<number>();
  triggerNewProductAdded: Subject<any> = new Subject<any>();
  triggerOnVATChange: Subject<boolean> = new Subject<boolean>();
  triggerisVatIncluded: Subject<any> = new Subject<any>();
  triggerAccountSelectedOption: Subject<boolean> = new Subject<boolean>();

  amount = 0;
  balanceAmount = 0;
  receiptAccountSelectedCurrencyId: number;
  selectedCurrencyRate = 1;
  store: Store;
  commonService: CommonService;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private spinner: NgxSpinnerService,
    private _Activatedroute: ActivatedRoute,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private location: Location,
    private renderer: Renderer2,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.cisInvoiceId = atob(params.get('id')!) as unknown as Guid;
          this.editCISInvoice();
        }
      }
    );
  }

  editCISInvoice(): void {
    this.store
      .dispatch(new GetDataByCISInvoiceId(this.cisInvoiceId))
      .subscribe((res) => {
        this.triggerEditData.next(res.cisInvoice.cisInvoiceData);
        this.triggereEditProductData.next(res.cisInvoice.cisInvoiceData.items);
        this.triggereEditPaymentData.next(
          res.cisInvoice.cisInvoiceData.receiptDetails
        );
        this.triggerAdvancePayment.next(
          res.cisInvoice.cisInvoiceData.accountId
        );
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  triggerReloadSideList(): void {
    this.reloadSideList.emit();
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  dataSubmit(): boolean {
    this.spinner.show();
    this.selectedCurrencyRate =
      this.billDetails.billForm.controls.currencyAmt.value;
    this.receiptAccountSelectedCurrencyId =
      this.receiptDetails.selectedAccountCurrencyId;
    try {
      this.cisInvoiceItem = new Array<CISInvoiceDetailsModel>();
      this.receiptItems = new Array<PaymentDetailsModel>();

      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.cisInvoiceDetails.cisInvoiceDetailArray
        ?.getRawValue()
        .forEach((x) => {
          this.cisInvoiceItem.push({
            id: x.id,
            description: x.description,
            cisSupplyTypeId: x.item,
            amount: +x.price / this.selectedCurrencyRate,
            vatRateId: x.vatRate === -1 ? null : x.vatRate,
            vatAmount: +x.vatAmount / this.selectedCurrencyRate,
            totalAmount: +(x.amount / this.selectedCurrencyRate).toFixed(2),
            foreignVatAmount: +x.vatAmount,
            foreignAmount: +x.price,
            foreignTotalAmount: +(+x.amount).toFixed(2),
            bookAccountId: x.invoiceType?.id
          });
        });

      this.receiptDetails.paymentArray?.getRawValue().forEach((x) => {
        const attachmentList: any = [];
        if (
          x.attachment !== null &&
          x.attachment !== undefined &&
          x.attachment !== '' &&
          x.attachment.length > 0
        ) {
          x.attachment.forEach((element) => {
            attachmentList.push(element);
          });
        }
        if (x.accountId !== '' && x.amount !== '') {
          this.receiptItems.push({
            id: x.id,
            receiptId: x.receiptId,
            transactionDate: this.datepipe
              .transform(x.receiptDate, 'yyyy-MM-dd')
              ?.toString(),
            amount:
              this.receiptAccountSelectedCurrencyId !== 123
                ? x.amount / this.selectedCurrencyRate
                : x.amount,
            postingAccountId: x.accountId,
            attachment: attachmentList,
            foreignAmount:
              this.receiptAccountSelectedCurrencyId !== 123
                ? x.amount
                : x.amount * this.selectedCurrencyRate,
            currencyRate: this.selectedCurrencyRate,
          });
        }
      });

      this.cisInvoiceData = {
        id: this.cisInvoiceId,
        entryNumber: this.billDetails.billForm.controls.billNo.value,
        entryDate: this.datepipe
          .transform(
            this.billDetails.billForm.controls.billDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),

        dueDate: this.datepipe
          .transform(
            this.billDetails.billForm.controls.dueDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),

        currencyId: this.billDetails.billForm.controls.currency.value,
        currencyRate: this.billDetails.billForm.controls.currencyAmt.value,
        accountId: this.billDetails.billForm.controls.supplierId.value,
        note: this.billDetails.billForm.controls.notes.value,
        //bookAccountId: this.billDetails.billForm.controls.billType.value.id,
        items: this.cisInvoiceItem,
        receiptDetails: this.receiptItems,
        attachment: filedata,
        advanceReceiptAmount:
          +this.advancePaymentDetails.advancePaymentDetailForm.controls
            .allocateAdvance.value,
        branchId:
          this.billDetails.billForm.controls.branchId.value === ''
            ? null
            : this.billDetails.billForm.controls.branchId.value,
        departmentId:
          this.billDetails.billForm.controls.departmentId.value === ''
            ? null
            : this.billDetails.billForm.controls.departmentId.value,
        isVatPostponed: this.billDetails.billForm.controls.isVatPostponed.value,
        isVatIncluded: this.billDetails.billForm.controls.isVatInclude.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.cisInvoiceId,
      isExit,
      Modules.CISInvoice,
      RoutingPath.AddCISInvoice
    );
  }

  onSave(isExit: boolean): void {
    if (this.billDetails.billForm.invalid) {
      this.commonService.addValidation(
        this.billDetails.billForm,
        this.renderer
      );
    } else if (
     +((+this.cisInvoiceDetails.totalAmount).toFixed(2) )< this.receiptDetails.totalReciptAmount
    ) {
      this.receiptDetails.paymentArray.controls.forEach((x) => {
        (Object as any)
          .values(x.controls)
          .forEach((c) => c.markAsTouched(false));
        x.controls.amount.status = 'INVALID';
      });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
    } else if (this.receiptDetails.paymentArray.invalid) {
      this.receiptDetails.paymentArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (
            x.controls.amount.value === '' ||
            x.controls.amount.value === '0'
          ) {
            this.commonService.onFailure(
              NotificationTextMessage.amtErrorMessage
            );
          }
          c.markAsTouched();
        });
      });
    } else if (this.cisInvoiceDetails.cisInvoiceDetailArray.invalid) {
      this.cisInvoiceDetails.cisInvoiceDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (x.controls.price.value === 0) {
            this.commonService.onFailure(
              NotificationTextMessage.amtErrorMessage
            );
          }
          c.markAsTouched();
        });
      });
    } else if (this.advancePaymentDetails.advancePaymentDetailForm.invalid) {
      this.advancePaymentDetails.advancePaymentDetailForm.controls[
        'allocateAdvance'
      ].setErrors({ incorrect: true });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    } else if (
      this.advancePaymentDetails.advancePaymentDetailForm.controls
        .allocateAdvance.value > +((+this.cisInvoiceDetails.totalAmount).toFixed(2))
    ) {
      this.advancePaymentDetails.advancePaymentDetailForm.controls[
        'allocateAdvance'
      ].setErrors({ incorrect: true });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    } else if (
      +this.receiptDetails.totalReciptAmount +
        +this.advancePaymentDetails.allocatedAdvanceAmount >
      +((+this.cisInvoiceDetails.totalAmount).toFixed(2))
    ) {
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    } else {
      if (this.dataSubmit()) {
        if (this.cisInvoiceData) {
          this.store
            .dispatch(new CreateCISInvoice(this.cisInvoiceData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.setHighlightData(isExit);

                  if (!isExit) {
                    this.onCancel(false);
                    this.reloadSideList.emit();
                  } else {
                    this.onCancel(false);
                    this.location.back();
                  }
                  this.commonService.onSuccess(
                    NotificationTextMessage.successMessage
                  );
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.message);
              }
            );
        }
      }
    }
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.cisInvoiceId)) {
      this.editCISInvoice();
    } else {
      this.cisInvoiceId = Guid.EMPTY as unknown as Guid;
      this.billDetails.ngOnInit();
      this.cisInvoiceDetails.ngOnInit();
      this.attachmentDetail.ngOnInit();
      this.receiptDetails.ngOnInit();
      this.advancePaymentDetails.ngOnInit();
      this.productDetailsFooter.grossTotal = 0;
      this.productDetailsFooter.total = 0;
      this.productDetailsFooter.balanceAmount = 0;
      this.productDetailsFooter.postponedVatAmount = 0;
      this.productDetailsFooter.discountedValue = 0;
      this.hasAdvanceAmount = false;
      this.productDetailsFooter.discountType = DiscountType.Value;
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  supplierClickChange(event) {
    this.triggerAdvancePayment.next(event);
  }

  onHasAdvanceAmountUpdated(updatedValue: boolean): void {
    this.hasAdvanceAmount = updatedValue;
  }
  onCurrencySelectedOption(selectedOption: any) {
    this.triggerCurrencySelectedOption.next(selectedOption);
  }
  onCurrencyRefreshOption(selectedOption: any) {
    this.triggerCurrencyRefreshOption.next(selectedOption);
  }
  onCurrencySelectedRate(selectedOption: any) {
    this.triggerSelectedCurrencyRate.next(selectedOption);
  }
  onAddedNewProductDetails(selectedOption: any) {
    this.triggerNewProductAdded.next(selectedOption);
  }
  onVATChange(isVATChecked: boolean) {
    this.triggerOnVATChange.next(isVATChecked);
  }
  isVatIncludedClick(event): void {
    
    this.triggerisVatIncluded.next(event);
  }
  onAccountSelectedOption(selectedOption: any) {
    this.triggerAccountSelectedOption.next(selectedOption);
  }
}
