<div class="sidenav-body-content activity-list">
    <div class="notification">
      <div class="notification-list">
        <div class="notification-list-wrapper">
          <ul>
            <li *ngFor="let details of activityList">
              <div class="d-flex gap-20">
                <div class="notification-date d-flex flex-column">
                  <span class="date">{{
                    details.activityDate | date : "MMM dd, y" | slice : 4 : 6
                  }}</span>
                  <span class="month">{{
                    details.activityDate | date : "MMM d, y" | slice : 0 : 4
                  }}</span>
                </div>
                <div class="notification-body">
                  <div class="media d-flex">
                    <div class="main-avatar avatar-md online">
                      <span class="rounded-img rounded-img-text">{{
                        details.createdByName | shortName : 2
                      }}</span>
                    </div>
                    <div class="media-body">
                      <p class="status">
                        <span class="fw-bold">{{ details.createdByName }}</span>
                        <a href="javascript:void(0)">
                          <mat-icon
                            title="add"
                            *ngIf="
                              details.entityTypeId === entityTypes.Created ||
                              details.entityTypeId === entityTypes.NoteCreated
                            "
                            >post_add</mat-icon
                          >
                          <mat-icon
                            title="update"
                            *ngIf="details.entityTypeId === entityTypes.Updated"
                            >update</mat-icon
                          >
                          <mat-icon
                            title="delete"
                            *ngIf="details.entityTypeId === entityTypes.Deleted"
                            >delete</mat-icon
                          >
                          <mat-icon
                            title="copy"
                            *ngIf="details.entityTypeId === entityTypes.Copy"
                            >file_copy</mat-icon
                          >
                          <mat-icon
                            title="archive"
                            *ngIf="details.entityTypeId === entityTypes.Archived"
                            >archive</mat-icon
                          >
                          <mat-icon
                            title="restore"
                            *ngIf="details.entityTypeId === entityTypes.Restored"
                            >restore_from_trash
                          </mat-icon>
                          <mat-icon
                            title="import/export"
                            *ngIf="
                              details.entityTypeId === entityTypes.Imported ||
                              details.entityTypeId === entityTypes.ExportedCSV ||
                              details.entityTypeId ===
                                entityTypes.ExportedEXCEL ||
                              details.entityTypeId === entityTypes.ExportedPDF
                            "
                            >import_export</mat-icon
                          >
                        </a>
                        <span [innerHTML]="details.message"> </span>
                        <span
                          class="fw-bold"
                          *ngIf="
                            entityTypes.Sent !== details.entityTypeId &&
                            entityTypes.Reminder !== details.entityTypeId &&
                            entityTypes.Recorded !== details.entityTypeId &&
                            entityTypes.Submitted !== details.entityTypeId &&
                            entityTypes.Approved !== details.entityTypeId &&
                            entityTypes.Withdrawn !== details.entityTypeId &&
                            entityTypes.Status !== details.entityTypeId &&
                            entityTypes.Rejected !== details.entityTypeId &&
                            entityTypes.DeletePayment !== details.entityTypeId &&
                            entityTypes.UpdatedBasicInfo !==
                              details.entityTypeId &&
                            entityTypes.UpdatedReminders !== details.entityTypeId
                          "
                          >{{ details.name }}</span
                        >
                      </p>
                      <div
                        class="d-inline-block mt-1"
                        *ngIf="details.hasAttachment"
                      >
                        <div
                          class="d-inline-block mr-1 pr-5 mb-5"
                          *ngFor="let files of details.attachments; let i = index"
                        >
                          <div>
                            <span class="text-primary-400 fw-normal">
                              <a
                                class="link text-blue-400"
                                *ngIf="
                                  details.entityTypeId === entityTypes.Imported
                                "
                              >
                              </a
                              >
                              {{ files.attachmentName }}</span
                            >
                            <p class="time">{{ files.size }}</p>
                          </div>
                        </div>
                      </div>
                      <p class="time">
                        at {{ details.activityDate | date : "shortTime" }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="text-align-center">
        <span *ngIf="!activityList?.length">No records found</span>
      </div>
    </div>
  </div>
  
