<div class="mat-dialog-wrapper">
  <h2 mat-dialog-title>Include / Exclude Accounts in the Current Budget</h2>
  <mat-dialog-content>
    <form [formGroup]="includeExcludeForm">
      <div class="d-flex align-items-center gap-20 form-field wp-100">
        <p class="fw-bold white-space-nowrap m-0">Account Type</p>
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            formControlName="accountType"
            [matAutocomplete]="accountGroup"
            (keyup)="onAccountSearch($event)"
            #trigger="matAutocompleteTrigger"
            appAutocompletePosition="trigger"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete
            [displayWith]="getOptionText"
            #accountGroup="matAutocomplete"
            (opened)="scrollIntoView()"
            (optionSelected)="getBudgetAccountList()"
          >
            <mat-optgroup
              *ngFor="let group of allGroupTypeList"
              [label]="group.groupName"
              title="{{ group.groupName }}"
            >
              <mat-option
                *ngFor="let names of group.listModels"
                [value]="names"
                title="{{ names.name }}"
              >
                {{ names.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </form>
    <ul class="mt-1 mb-1 account-list-wrapper">
      <li class="account-list-heading">
        <mat-checkbox
          [(ngModel)]="isAllSelected"
          (change)="selectAllAccount($event)"
        >
        </mat-checkbox>
        <span class="fw-bold">Account Name</span>
      </li>
      <ul class="account-list">
        <li *ngFor="let acc of budgetAccountList">
          <mat-checkbox [(ngModel)]="acc.isSelected" (change)="selectAccount()">
          </mat-checkbox>
          <span>{{ acc.name }}</span>
        </li>
      </ul>
    </ul>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave()"
    >
      Save
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onCancel()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
