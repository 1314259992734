<div class="mat-dialog-wrapper match-journal">
  <h2 mat-dialog-title>VAT Payment Status</h2>
  <button
    class="close-button"
    type="button"
    (click)="onCloseClick()"
    mat-button
    disableRipple
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    <div class="content-body p-0">
      <form [formGroup]="vatPaymentForm">
        <div class="form-field datepicker">
          <p>Payment Date <span class="text-danger-300">*</span></p>
          <mat-form-field appearance="standard">
            <input
              matInput
              formControlName="paymentDate"
              [matDatepicker]="paymentDatePicker"
              autocomplete="off"
              (change)="commonService.validateDate($event)"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="paymentDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #paymentDatePicker></mat-datepicker>
          </mat-form-field>
          <mat-error
            *ngIf="
              commonService.checkDateValidation(
                vatPaymentForm.get('paymentDate')
              ).invalidFinancialYear
            "
          >
            {{ notificationMessage.validPeriodDateMessage }}
          </mat-error>

          <mat-error
            *ngIf="
              commonService.checkDateValidation(
                vatPaymentForm.get('paymentDate')
              ).lockedFinancialYear
            "
          >
            {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
          </mat-error>
        </div>
        <div class="form-field textarea">
          <p>Reference</p>
          <mat-form-field>
            <textarea
              matInput
              formControlName="reference"
              autocomplete="off"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="form-field flex-column justify-contents-center">
          <div>
            <mat-checkbox
              formControlName="postJournal"
              (change)="onCheckBoxSelected($event)"
            >
              Post Journal
            </mat-checkbox>
          </div>
        </div>
        <div *ngIf="isPostJournal">
          <div class="form-field wap-40">
            <p>Payment Account<span class="text-danger-300">*</span></p>

            <ng-select
              #accountList
              [items]="receiptAccountList"
              (focus)="accountList.open()"
              (valueChange)="accountList.close()"
              [disableOptionCentering]="true"
              formControlName="accountId"
              bindLabel="name"
              bindValue="id"
              groupBy="groupName"
              panelClass="mat-select-position"
              appendTo="body"
            >
              <ng-template ng-option-tmp let-item="item">
                <span class="ng-option-label" [attr.title]="item.name">{{
                  item.name
                }}</span>
              </ng-template>
            </ng-select>
          </div>
          <div class="form-field">
            <p>VAT Account <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                autocomplete="off"
                value="VAT"
                [disabled]="true"
              />
            </mat-form-field>
          </div>
          <div class="form-field">
            <p>VAT Amount <span class="text-danger-300"></span></p>
            <mat-form-field class="amt-inputbox">
              <input
                currencyMask
                allow_10_2_Decimal
                matInput
                autocomplete="off"
                formControlName="vatAmount"
                [disabled]="true"
              />
              <button mat-button type="button" disableRipple matSuffix>
                <span> £ </span>
              </button>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="hr pt-1 mt-0 justify-content-end">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave()"
    >
      Save
    </button>
    <button
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onCloseClick()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
