import {
  AccountModel,
  CompanyModel,
  PaymentDetailsModel,
  ProductDetailsModel,
} from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class InvoiceModel {
  id?: Guid;
  entryDate?: string;
  entryNumber: string;
  dueDate?: string;
  currencyId: number;
  currencyRate?: number;
  accountId?: Guid;
  bookAccountId?: Guid;
  note: string;
  baseAmount?: number;
  totalAmount?: number;
  attachment?: Array<string>;
  isVatIncluded: boolean;
  isVatPostponed: boolean;
  items: Array<ProductDetailsModel>;
  receiptDetails?: Array<PaymentDetailsModel>;
  transactionLogId?: Guid;
  bankAccountId: Guid | null;
  advanceReceiptAmount?: number;
  isManualBank?: boolean;
  branchId?: Guid | null;
  departmentId?: Guid | null;
  discount: number;
  isDiscountPercentage: boolean;
  foreignDiscount: number;
 // isInvoiceDiscount: boolean;
}

export class ChartOfAccountListParam {
  groupIds: Array<number>;
  typeIds: Array<number>;
}

export class InvoiceViewDetails {
  columns: Array<any>;
  account: AccountModel;
  company: CompanyModel;
  invoice: InvoiceModel;
}
