<div class="content-body content-body-scroll">
  <div class="chart-header">
    <div class="content-header-input d-flex justify-space-between">
      <div class="d-flex align-items-center gap-20">
        <p class="text-primary font-size-20 fw-bold m-0">
          {{ dateRange.startDate | date: "dd-LLL-yyyy" }} to
          {{ dateRange.endDate | date: "dd-LLL-yyyy" }}
        </p>
      </div>
      <div class="d-flex align-items-end gap-10">
        <div class="d-flex flex-column" *ngIf="branchList.length > 0">
          <ejs-multiselect
            id="multiselect-checkbox"
            #branch
            [dataSource]="branchList"
            [placeholder]="branchWaterMark"
            [fields]="branchFields"
            [mode]="branchmode"
            [popupHeight]="branchpopHeight"
            [showDropDownIcon]="true"
            showSelectAll="true"
            [(ngModel)]="selectedBranchValues"
            [filterBarPlaceholder]="branchfilterPlaceholder"
            (change)="onBranchMultiSelectChange($event)"
          ></ejs-multiselect>
        </div>
        <div class="d-flex flex-column" *ngIf="departmentList.length > 0">
          <ejs-multiselect
            id="multiselect-checkbox"
            #department
            [dataSource]="departmentList"
            [placeholder]="departmentWaterMark"
            [fields]="departmentFields"
            [mode]="departmentmode"
            [popupHeight]="departmentpopHeight"
            [showDropDownIcon]="true"
            showSelectAll="true"
            [filterBarPlaceholder]="departmentfilterPlaceholder"
            [(ngModel)]="selectedDepartmentValues"
            (change)="onDepartmentMultiSelectChange($event)"
          ></ejs-multiselect>
        </div>
        <div class="content-header m-0">
          <div class="year-picker">
            <div class="d-flex year-picker-content">
              <div>
                <mat-form-field appearance="fill" class="w-150">
                  <mat-select
                    panelClass="myPanelClass"
                    [disableOptionCentering]="true"
                    [(ngModel)]="selectedYearValue"
                    (selectionChange)="onSelectionChange($event.value)"
                  >
                    <mat-option
                      *ngFor="let year of selectYear | useEnumValues"
                      [value]="year.key"
                      title="{{ year.value }}"
                    >
                      {{ year.value }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <form *ngIf="showCalender" [formGroup]="rangeFormGroup">
                <mat-form-field appearance="standard">
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      matInput
                      #start
                      (dateChange)="onSelectionChange(7)"
                      formControlName="start"
                    />
                    <input
                      matEndDate
                      matInput
                      #end
                      formControlName="end"
                      (dateChange)="onSelectionChange(7)"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matPrefix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="chart-wrapper d-flex gap-20 mt-2">
    <div class="wp-50">
      <div class="card">
        <div
          class="d-flex align-items-center hr-bottom justify-space-between pb-10"
        >
          <h3 class="card-title card-title-without-dropdown">Assets</h3>
        </div>
        <app-assets-count-chart></app-assets-count-chart>
        <div class="d-flex align-items-center gap-10">
          <div
            class="main-contain justify-space-between d-flex align-items-center gap-10"
          >
            <div id="chart" class="w-150">
              <apx-chart
                [series]="FixAssetsOptions.series!"
                [chart]="FixAssetsOptions.chart!"
                [xaxis]="FixAssetsOptions.xaxis!"
                [yaxis]="FixAssetsOptions.yaxis!"
                [stroke]="FixAssetsOptions.stroke!"
                [tooltip]="FixAssetsOptions.tooltip!"
                [legend]="FixAssetsOptions.legend!"
                [grid]="FixAssetsOptions.grid!"
                [fill]="FixAssetsOptions.fill!"
                [dataLabels]="FixAssetsOptions.dataLabels!"
              ></apx-chart>
            </div>
            <div class="d-flex align-items-center gap-10">
              <div
                class="text-success view-list d-flex align-items-center gap-5"
              >
                <mat-icon class="font-size-16">north</mat-icon>
                <span class="font-size-14">10.2%</span>
                <mat-icon>arrow_drop_up</mat-icon>
              </div>
              <div>From Last Year</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div
          class="d-flex align-items-center hr-bottom justify-space-between pb-10"
        >
          <h3 class="card-title">Value by Asset Type</h3>
          <ng-select
            #valueByAssetType1
            (focus)="valueByAssetType1.open()"
            (valueChange)="valueByAssetType1.close()"
            [disableOptionCentering]="true"
            [(ngModel)]="valueByAssetType"
            (change)="onValueByAssetType()"
            panelClass="mat-select-position"
            class="w-150"
          >
            <ng-option *ngFor="let ft of valueByAssetTypeList" [value]="ft.id">
              <div class="clamp" title="{{ ft.name }}">{{ ft.name }}</div>
            </ng-option>
          </ng-select>
        </div>
        <app-dashboard-expenses-v1
          [triggerHeaderFilterForValueByAssetType]="
            triggerHeaderFilterForValueByAssetType
          "
          [getModuleId]="moduleId"
        >
        </app-dashboard-expenses-v1>
      </div>
    </div>
    <div class="card">
      <div
        class="d-flex align-items-center hr-bottom justify-space-between pb-10"
      >
        <h3 class="card-title">Product / Service</h3>
        <div>
          <ng-select
            #productService1
            (focus)="productService1.open()"
            (valueChange)="productService1.close()"
            [disableOptionCentering]="true"
            [(ngModel)]="productService"
            (change)="getFixAssestProductServiceListOverview()"
            panelClass="mat-select-position"
            class="w-170"
          >
            <ng-option *ngFor="let ft of valueByAssetTypeList" [value]="ft.id">
              <div class="clamp" title="{{ ft.name }}">{{ ft.name }}</div>
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="chart-list-wrapper">
        <div class="chart-list-title pr-10">
          <div class="d-flex align-items-center justify-space-between">
            <span>Asset Name</span>
            <span>Balance</span>
          </div>
        </div>
        <div class="chart-list-details">
          <div class="chart-list" *ngFor="let data of productServiecData">
            <div class="d-flex align-items-center justify-space-between">
              <span>{{ data.name }}</span>
              <span class="fw-bold">£ {{ data.data | numberPipe }}</span>
            </div>
          </div>
        </div>
        <div class="norecord-chart" *ngIf="productServiecData?.length === 0">
          <p class="pt-10">No data available yet!</p>
        </div>
      </div>
    </div>
  </div>
</div>
