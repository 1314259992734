<div class="mat-dialog-wrapper" style="width: 600px">
  <h2 mat-dialog-title class="text-align-center">Bank Detail</h2>

  <button
    class="edit-button"
    type="button"
    (click)="onEditClick()"
    mat-button
    disableRipple
    [disabled]="!isJournalChangePermissionEdit"
  >
    <mat-icon class="text-primary" fontset="material-icons-outlined"
      >edit</mat-icon
    >
  </button>
  <button
    class="close-button"
    type="button"
    (click)="onCloseClick()"
    mat-button
    disableRipple
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    <div class="content-body">
      <table class="mat-table" aria-describedby="bankTransfer">
        <tr class="mat-row">
          <td class="mat-cell">Date</td>
          <td class="mat-cell">:</td>
          <td class="mat-cell">
            {{ bankTransfer.entryDate | date: "dd-LLL-yyyy" }}
          </td>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell">Trans. No.</td>
          <td class="mat-cell">:</td>
          <td class="mat-cell">{{ bankTransfer.entryNumber }}</td>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell">Account From</td>
          <td class="mat-cell">:</td>
          <td class="mat-cell">{{ bankTransfer.transferredFromName }}</td>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell">Amount</td>
          <td class="mat-cell">:</td>
          <td class="mat-cell">
            {{
              bankTransfer.fromCurrencyId === 123 ||
              bankTransfer.fromCurrencyId === 0
                ? "£"
                : bankTransfer.fromSymbol
            }}
            {{ bankTransfer.foreignAmount | numberPipe }}
          </td>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell">Account To</td>
          <td class="mat-cell">:</td>
          <td class="mat-cell">{{ bankTransfer.transferredToName }}</td>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell">Amount</td>
          <td class="mat-cell">:</td>
          <td class="mat-cell">
            {{
              bankTransfer.toCurrencyId === 123 ||
              bankTransfer.toCurrencyId === 0
                ? "£"
                : bankTransfer.toSymbol
            }}
            {{ bankTransfer.foreignAmount | numberPipe }}
          </td>
        </tr>
        <tr class="mat-row">
          <td class="mat-cell">Reference</td>
          <td class="mat-cell">:</td>
          <td class="mat-cell">{{ bankTransfer.note }}</td>
        </tr>
      </table>
    </div>
  </mat-dialog-content>
</div>

<app-custom-notifications></app-custom-notifications>
