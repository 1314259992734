<div class="content-body p-0 pt-1">
  <div class="content-header">
    <div class="content-header-input">
      <div class="d-flex justify-space-between wp-100 mb-1">
        <button
          class="action-button primary-button mr-10"
          mat-button
          disableRipple
          (click)="openAddTemplate()"
        >
          <mat-icon>add</mat-icon>
          <span>Template</span>
        </button>
        <div class="d-flex align-items-center justify-space-between">
          <button
            mat-button
            (click)="$event.stopPropagation()"
            [matMenuTriggerFor]="menu"
            disableRipple
          >
            <mat-icon class="text-yellow-200 highlight-icon"
              >emoji_objects</mat-icon
            >
          </button>
          <mat-menu #menu="matMenu" class="highlight" xPosition="before">
            <div class="highlight-wrapper">
              <p class="font-size-14 fw-bold">
                Customise your company invoice, credit note and dividend
                templates through word document at ease.
              </p>
              <p class="font-size-14">Steps to add & upload your templates:</p>
              <ul>
                <li>
                  You may either make adjustments to ‘Default Templates’ or add
                  a new template from the page.
                </li>
                <li>
                  Select Download option under ‘Action’ column to download
                  zipped word documents.
                </li>
                <li>
                  Make necessary adjustments in the documents through simple
                  word formatting.
                </li>
                <li>Add your company logo and Save the document.</li>
                <li>
                  Select ‘Upload’ option under ‘Action’ column to upload the
                  customised templates.
                </li>
              </ul>
            </div>
          </mat-menu>
        </div>
      </div>
      <div class="top-list-wrapper wp-100">
        <div class="list-card bg-yellow-300 warning">
          <em class="fa-fw fa fa-check"></em>
          <span>
            Warning: Do not remove information and tags/objects enclosed within
            ‘<< >>’ operators, unless you wish to exclude that information to be
            printed on your invoice or credit note. In case you have removed
            these objects you may download another template and copy it from
            there into your existing template and upload it again for the
            impact. You may ‘Reset’ templates to default from ‘Action’ button on
            the page.
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="mat-table-wrapper">
    <table
      mat-table
      [dataSource]="dataSource"
      aria-describedby="Templates (Doc) Table"
    >
      <ng-container matColumnDef="templatename">
        <th mat-header-cell *matHeaderCellDef>Template Name</th>
        <td mat-cell *matCellDef="let element">{{ element.templatename }}</td>
      </ng-container>
      <ng-container matColumnDef="default">
        <th mat-header-cell *matHeaderCellDef class="w-100 text-align-center">
          Default
        </th>
        <td mat-cell *matCellDef="let element" class="text-align-center">
          <mat-checkbox></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="invoice">
        <th mat-header-cell *matHeaderCellDef>Invoice File</th>
        <td mat-cell *matCellDef="let element">{{ element.invoice }}</td>
      </ng-container>
      <ng-container matColumnDef="creditnote">
        <th mat-header-cell *matHeaderCellDef>Credit Note File</th>
        <td mat-cell *matCellDef="let element">{{ element.creditnote }}</td>
      </ng-container>
      <ng-container matColumnDef="dividend">
        <th mat-header-cell *matHeaderCellDef>Dividend File</th>
        <td mat-cell *matCellDef="let element">{{ element.dividend }}</td>
      </ng-container>
      <ng-container matColumnDef="quotation">
        <th mat-header-cell *matHeaderCellDef>Quotation File</th>
        <td mat-cell *matCellDef="let element">{{ element.quotation }}</td>
      </ng-container>
      <ng-container matColumnDef="updated">
        <th mat-header-cell *matHeaderCellDef>Updated On</th>
        <td mat-cell *matCellDef="let element">{{ element.updated }}</td>
      </ng-container>
      <ng-container matColumnDef="bank">
        <th mat-header-cell *matHeaderCellDef>Bank</th>
        <td mat-cell *matCellDef="let element">{{ element.bank }}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="w-40">Action</th>
        <td mat-cell *matCellDef="let element">
          <div class="grid-actions">
            <button mat-button [matMenuTriggerFor]="menu8" disableRipple>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu
              #menu8="matMenu"
              xPosition="before"
              class="more-button-menu scroll"
            >
              <button
                mat-menu-item
                disableRipple
                title="Edit"
                (click)="openAddTemplate()"
              >
                <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item disableRipple title="Download">
                <mat-icon fontSet="material-icons-outlined"
                  >file_download</mat-icon
                >
                <span>Download</span>
              </button>
              <button
                mat-menu-item
                disableRipple
                title="Upload"
                (click)="openUploadTemplate()"
              >
                <mat-icon fontSet="material-icons-outlined"
                  >file_upload</mat-icon
                >
                <span>Upload</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
