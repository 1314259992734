import { Injectable } from '@angular/core';
import { DefaultSettings, LogoOnReports } from '@app/core/Models';
import { SettingsService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  GetDefaultSettingsData,
  GetLogoOnReportsData,
  SaveDefaultSettings,
  SaveLogoOnReports,
} from './settings.action';

export class SettingsStateInfo {
  isDefaultSettingsAdded: boolean;
  isLogoOnReportUpdated: boolean;
  defaultSettingsData?: DefaultSettings;
  logoOnReportsData?: LogoOnReports;
}
@State<SettingsStateInfo>({
  name: 'settings',
  defaults: {
    isDefaultSettingsAdded: false,
    isLogoOnReportUpdated: false,
  },
})
@Injectable()
export class SettingsState {
  constructor(private settingService: SettingsService) {}

  @Selector()
  static getSettingsData(state: SettingsStateInfo) {
    return state.defaultSettingsData;
  }

  @Action(SaveDefaultSettings)
  saveDefaultSettings(
    { patchState }: StateContext<SettingsStateInfo>,
    action: SaveDefaultSettings
  ) {
    return this.settingService.saveDefaultSettings(action.company).pipe(
      tap((res) => {
        patchState({
          isDefaultSettingsAdded: true,
        });
      })
    );
  }
  @Action(SaveLogoOnReports)
  saveLogoOnReports(
    { patchState }: StateContext<SettingsStateInfo>,
    action: SaveLogoOnReports
  ) {
    return this.settingService.saveLogoOnReports(action.logoOnReports).pipe(
      tap((res) => {
        patchState({
          isLogoOnReportUpdated: true,
        });
      })
    );
  }
  @Action(GetDefaultSettingsData)
  getDefaultSettingsData(
    { getState, patchState }: StateContext<SettingsStateInfo>,
    action: GetDefaultSettingsData
  ) {
    return this.settingService.getDefaultSettingsData().pipe(
      tap((res) => {
        patchState({
          defaultSettingsData: res,
        });
      })
    );
  }
  @Action(GetLogoOnReportsData)
  getLogoOnReportsData(
    { getState, patchState }: StateContext<SettingsStateInfo>,
    action: GetLogoOnReportsData
  ) {
    return this.settingService.getLogoOnReportsData().pipe(
      tap((res) => {
        patchState({
          logoOnReportsData: res,
        });
      })
    );
  }
}
