import { DefaultSettings, LogoOnReports } from '@app/core/Models';

export class SaveDefaultSettings {
  static readonly type = '[Settings] Save Default Settings';

  constructor(public company: DefaultSettings) {}
}
export class SaveLogoOnReports {
  static readonly type = '[Settings] Save Logo On Reports';

  constructor(public logoOnReports: LogoOnReports) {}
}
export class GetDefaultSettingsData {
  static readonly type = '[Settings] Get Default Settings Data';
}
export class GetLogoOnReportsData {
  static readonly type = '[Settings] Get Logo On Reports Data';
}
