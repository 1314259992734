<div class="mat-dialog-wrapper preview-details">
  <mat-dialog-content>
    <form>
      <div class="main-contain">
        <div class="sidenav-header mb-10">
          <div
            class="d-flex align-items-center justify-space-between hr-bottom pb-10"
          >
            <h2 class="m-0">
              <span>Payout Schedule</span>
            </h2>
            <div mat-dialog-close>
              <mat-icon class="close cursor-pointer">close</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="pay-details w-636">
        <div class="form-field">
          <mat-radio-group
            aria-label="Select an option"
            formControlName="isActive"
          >
            <mat-radio-button value="1">No scheduled payout</mat-radio-button>
            <mat-radio-button value="2">Daily payout</mat-radio-button>
            <mat-radio-button value="3">Weekly payout</mat-radio-button>
            <mat-radio-button value="4">Monthly payout</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div
        class="contact-button d-flex justify-content-end align-items-center mt-2"
      >
        <button
          class="action-button secondary-button mr-1"
          type="button"
          mat-button
          disableRipple
        >
          Cancel
        </button>
        <button
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
        >
          Save
        </button>
      </div>
    </form>
  </mat-dialog-content>
</div>

<app-custom-notifications></app-custom-notifications>
