import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MainListParameters } from '@app/core/Models';
import { GeneralDashBoardChartQueryParams } from '@app/core/Models/general-dashboard/general-dashboard';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralDashboardService {
  setReloadDashboardEmitter = new EventEmitter();
  constructor(
    private http: HttpClient,
    public datepipe: DatePipe,
    private commonService: CommonService
  ) {}

  getGeneralDashboardCompanyList(
    queryParams: MainListParameters
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}GeneralDashBoard/list`,
      this.commonService.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }
  getGeneralDashboardChartsData(
    queryParams: GeneralDashBoardChartQueryParams
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}GeneralDashBoard/chartlist`,
      this.commonService.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }
}
