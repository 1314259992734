<div class="mat-dialog-wrapper quick-add">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">Add New General Account</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <div>
      <form>
        <div class="form-field">
          <p>Company Type</p>
          <div class="d-flex gap-10">
            <mat-checkbox>All</mat-checkbox>
            <mat-checkbox>Limited</mat-checkbox>
            <mat-checkbox>Sole Trader</mat-checkbox>
            <mat-checkbox>Partnership</mat-checkbox>
            <mat-checkbox>LLP</mat-checkbox>
          </div>
        </div>
        <div class="form-field">
          <p>Account Type</p>
          <ng-select
            #currency
            [disableOptionCentering]="true"
            (focus)="currency.open()"
            (valueChange)="currency.close()"
            formControlName="currency"
            panelClass="mat-select-position"
          >
            <ng-option>All Account Types</ng-option>
            <ng-option>Turnover</ng-option>
            <ng-option>Cost of Sales</ng-option>
            <ng-option>Other Operating Income</ng-option>
          </ng-select>
        </div>
        <div class="form-field">
          <p>Account Name</p>
          <mat-form-field>
            <input matInput autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Account Code</p>
          <mat-form-field>
            <input matInput autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>VAT Code</p>
          <ng-select
            #currency
            [disableOptionCentering]="true"
            (focus)="currency.open()"
            (valueChange)="currency.close()"
            formControlName="currency"
            panelClass="mat-select-position"
          >
            <ng-option>No VAT</ng-option>
            <ng-option>Exempt</ng-option>
            <ng-option>Zero-Rated (0%)</ng-option>
            <ng-option>Reduced (5.0%)</ng-option>
            <ng-option>EU VAT (0.0%)</ng-option>
            <ng-option>Standard (20%)</ng-option>
          </ng-select>
        </div>
        <div class="form-field">
          <mat-checkbox>Active</mat-checkbox>
        </div>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="m-0">
    <app-buttons [saveButtonShow]="true"> </app-buttons>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
