import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Modules } from '@app/core/Enum';
import {
  FlatRateAdjusmentDataModel,
  FlatRateAdjustmentJournalModel,
  HeaderModel,
  IncluceExcludeTransactionModel,
  MainList,
  MainListParameters,
  PaymentStatusViewModel,
  SubmitVATModel,
  VATReturnDateModel,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonService } from '../../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class SubmitVatService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createVat(vatData: SubmitVATModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}MTD/vatreturn/saveperiod`,
      JSON.stringify(vatData),
      headers
    );
  }

  getVatDetail(id: Guid): Observable<SubmitVATModel> {
    return this.http.get<SubmitVATModel>(
      `${environment.apiVersionUrl}MTD/vatreturnbyId/${id}`
    );
  }

  getVatReturnDate(): Observable<VATReturnDateModel> {
    return this.http.get<VATReturnDateModel>(
      `${environment.apiVersionUrl}MTD/nextVatReturnDate`
    );
  }

  getVatRemainingTransaction(
    queryParams: MainListParameters,
    isHeaderVisible: boolean,
    id: Guid
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let url = '';
    if (isHeaderVisible) {
      url = 'MTD/vatRemainingTransaction';
    } else {
      url = `MTD/vatReturnTransaction/${id}`;
    }

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}${url}`,
      JSON.stringify(queryParams),
      headers
    );
  }

  createVATReturnStatus(vatReturnStatus: number, id: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}MTD/vatReturnStatus/${id}/status/${vatReturnStatus}`,
      JSON.stringify(vatReturnStatus),
      headers
    );
  }

  checkVatNumber(): Observable<any> {
    return this.http.get<any>(`${environment.apiVersionUrl}MTD/checkVatNumber`);
  }

  getvatRemainingTransactionHeader(): Observable<Array<HeaderModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<HeaderModel>>(
      `${environment.apiVersionUrl}MTD/vatRemainingTransactionHeader`,
      headers
    );
  }

  saveIncludeExclude(
    params: IncluceExcludeTransactionModel,
    id: Guid,
    isInclude: boolean
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}MTD/vatreturn/${id}/saveTransaction/${isInclude}`,
      JSON.stringify(params),
      headers
    );
  }

  deleteSubmitVat(ids?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(ids),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}mtd/delete`,
      options
    );
  }

  deleteBridgingVat(ids?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(ids),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}mtd/bridgingVat/delete`,
      options
    );
  }

  exportVatAudit(id: Guid, isPrint: boolean): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}mtd/exportVATAudit`,
        JSON.stringify(id),
        headers
      )
      .pipe(
        switchMap((response: any) => {
          const body: Blob = response.body || new Blob();
          if (isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  getSubmitVatList(
    queryParams: MainListParameters,
    moduleId: number
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}MTD/submitVatList/${
        moduleId === Modules.BridgingVAT ? true : false
      }`,
      this.commonService.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  updatePaymentStatus(paymentStatusUpdateModel: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}MTD/updateVatPaymentStatus`,
      JSON.stringify(paymentStatusUpdateModel),
      headers
    );
  }

  vatFlatRatePosting(model: FlatRateAdjustmentJournalModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}MTD/vatFlatRatePosting`,
      JSON.stringify(model),
      headers
    );
  }

  getVatPaymentStatusView(id: Guid): Observable<PaymentStatusViewModel> {
    return this.http.get<PaymentStatusViewModel>(
      `${environment.apiVersionUrl}MTD/vatPaymentStatusView/${id}`
    );
  }

  getFlatRateAdjustmentCalculation(
    id: Guid
  ): Observable<FlatRateAdjusmentDataModel> {
    return this.http.get<FlatRateAdjusmentDataModel>(
      `${environment.apiVersionUrl}MTD/getFlatRateAdjustmentCalculation/${id}`
    );
  }

  getFinalReturn(): Observable<PaymentStatusViewModel> {
    return this.http.get<PaymentStatusViewModel>(
      `${environment.apiVersionUrl}MTD/getFinalReturn`
    );
  }

  revertPaymentStatus(id: Guid): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.apiVersionUrl}MTD/revertPaymentStatus/${id}`
    );
  }

  finalVATUpdate(): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.apiVersionUrl}MTD/finalVATUpdate`
    );
  }
}
