import { Guid } from 'guid-typescript';

export class PaymentDetailsModel {
  id: Guid;
  receiptId: Guid;
  transactionDate?: string;
  amount: number;
  postingAccountId: Guid;
  attachment: Array<string>;
  foreignAmount: number;
  currencyRate: number;
}
