import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { NotificationTextMessage } from '@app/core/Enum';
import { MainListParameters } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { MenuState } from '@app/core/Store';
import { GetSubContractorDetailsList } from '@app/core/Store/CIS';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sub-contractor-details',
  templateUrl: './sub-contractor-details.component.html',
  styleUrls: ['./sub-contractor-details.component.scss'],
})
export class SubContractorDetailsComponent implements OnInit {
  displayedColumns: any[] = [
    'name',
    'tredingName',
    'taxTreatment',
    'verificationNo',
    'deductionRate',
    'totalPayment',
    'costOfMaterials',
    'netAmount',
    'totalDeducted',
  ];

  isDisable = true;
  id = Guid.EMPTY as unknown as Guid;
  cisSubContactorList: any;
  listParameters: MainListParameters = new MainListParameters();
  @Input() monthlyReturnId: Guid;

  @ViewChild(MatSort) sort: MatSort;
  @Input() startDate: string;
  @Input() endDate: string;

  constructor(private store: Store, private commonService: CommonService) {}
  isEdit: boolean;
  @Input() triggerEditSubContractorData: Observable<any>;
  private destroy$ = new Subject<void>();
  isTotalPaymentValid = true;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  ngOnInit(): void {
    this.isEdit = false;
    this.getList();
    this.triggerEditSubContractorData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isEdit = true;
        this.cisSubContactorList = data;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      startDate: this.startDate ?? null,
      endDate: this.endDate ?? null,
    };
    return queryParams;
  }

  getList(): void {
    if (!this.isEdit) {
      this.store
        .dispatch(
          new GetSubContractorDetailsList(
            this.getParamter(),
            this.monthlyReturnId
          )
        )
        .subscribe((res) => {
          this.cisSubContactorList = res.CIS.cisReturnData.resultSet.data;
        });
    }
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.getList();
  }

  onTotalPaymentChange(index): void {
    this.cisSubContactorList.forEach((value, key) => {
      if (key === index) {
        if (+value.totalPayment < +value.costofMaterial) {
          this.isTotalPaymentValid = false;
          this.commonService.onFailure(NotificationTextMessage.totalPayment);
          return;
        } else {
          this.isTotalPaymentValid = true;
        }
        value.netAmount = +value.totalPayment - +(value.costofMaterial ?? 0);
        value.totalDeducted = (+value.deductionRate * +value.netAmount) / 100;
      }
    });
  }
}
