import { Guid } from 'guid-typescript';

export class CustomAccountModel {
  id: Guid;
  groupId?: number;
  groupCode?: string;
  groupName?: string;
  typeId?: number;
  typeCode?: string;
  typeName?: string;
  nameId?: number;
  name: string;
  chartOfAccountId: Guid;
  branchId?: Guid | null;
}

export class AccountGroupAndType {
  groupId: number;
  typeId: number;
}
