<div class="company-preference content-body-wrapper">
  <div class="content-body">
    <div class="alert-message" *ngIf="isViewPermission$ | async">
      <mat-icon class="material-icons-outlined">error_outline</mat-icon>
      {{ commonNotificationText.ViewOnlyAccessMessage }}
    </div>
    <div class="company-preference-info">
      <mat-tab-group
        mat-align-tabs="start"
        disableRipple
        animationDuration="0ms"
        animationDuration="0ms "
        (selectedTabChange)="onTabClick($event.index)"
      >
        <mat-tab label="Authorise Software">
          <div class="content-body" *ngIf="isAuthorizedSection">
            <div class="bg-yellow-300 warning" *ngIf="!isAuthorized">
              <p class="d-flex align-items-center gap-5 mb-5">
                <mat-icon fontSet="material-icons-outlined"
                  >report_problem</mat-icon
                >
                <span class="fw-bold">Info!</span> Please authorise Capium to
                access HMRC's data.
              </p>
              <p class="d-flex align-items-center gap-5 m-0">
                <a
                  href="javascript:void(0)"
                  (click)="redirectOnUnAuthorized()"
                  class="link text-primary"
                  [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
                  >Click here</a
                >
                to authorise for
                <span class="fw-bold">{{ companyName }}</span> only.
              </p>
            </div>
            <div class="sucess" *ngIf="isAuthorized">
              <p class="d-flex align-items-center gap-5 m-0">
                <mat-icon
                  fontSet="material-icons-outlined"
                  class="text-success-100"
                  >check_circle</mat-icon
                >
                Company is authorised.
              </p>
            </div>
          </div>
        </mat-tab>
        <mat-tab [disabled]="!isAuthorized" label="Obligations">
          <div class="content-body content-body-scroll">
            <div class="content-header">
              <div class="content-header-input">
                <div
                  class="d-flex justify-space-between align-items-end wp-100 mb-15"
                >
                  <div
                    class="content-header d-flex align-items-end justify-space-between header-filters header-count header-count-1"
                  >
                    <div class="d-flex align-items-end">
                      <div
                        class="content-right-button d-flex flex-column gap-5 mr-10"
                      >
                        <span class="fw-bold"> From : </span>
                        <div>
                          <mat-form-field appearance="standard" class="ml-0">
                            <input
                              [(ngModel)]="obligationDateFrom"
                              [matDatepicker]="obligationFromPicker"
                              matInput
                              autocomplete="off"
                              tabindex="-1"
                              required
                            />
                            <mat-datepicker-toggle
                              matSuffix
                              [for]="obligationFromPicker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker
                              #obligationFromPicker
                            ></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                      <div
                        class="content-right-button d-flex flex-column gap-5 mr-10"
                      >
                        <span class="fw-bold"> To : </span>
                        <div>
                          <mat-form-field appearance="standard" class="ml-0">
                            <input
                              [(ngModel)]="obligationDateTo"
                              [matDatepicker]="obligationToPicker"
                              [min]="obligationDateFrom"
                              matInput
                              autocomplete="off"
                              tabindex="-1"
                              required
                            />
                            <mat-datepicker-toggle
                              matSuffix
                              [for]="obligationToPicker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker
                              #obligationToPicker
                            ></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                      <div
                        class="content-right-button d-flex flex-column gap-5 mr-10"
                      >
                        <span class="fw-bold"> Status : </span>
                        <div [ngClass]="['w-200']">
                          <ng-select
                            [(ngModel)]="obligationStatus"
                            class="input-sm form-control"
                            [ngModelOptions]="{ standalone: true }"
                            [virtualScroll]="true"
                            required
                          >
                            <ng-option value="A">All</ng-option>
                            <ng-option value="O">Open</ng-option>
                            <ng-option value="F">Fulfilled</ng-option>
                          </ng-select>
                        </div>
                      </div>
                      <div class="toolbar mr-10">
                        <button
                          matTooltip="Search"
                          mat-button
                          (click)="getVatObligations()"
                        >
                          <mat-icon>search</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="mat-table-wrapper"
                  [ngClass]="{
                    'mat-table-wrapper-noData': obligationNoDataFound
                  }"
                >
                  <table
                    mat-table
                    [dataSource]="vatObligations"
                    aria-describedby="Obligations"
                    mat-table
                    matSort
                  >
                    <ng-container matColumnDef="startDate">
                      <th mat-header-cell *matHeaderCellDef>Start Date</th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.startDate }}"
                      >
                        {{ element.startDate }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="endDate">
                      <th mat-header-cell *matHeaderCellDef>End Date</th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.endDate }}"
                      >
                        {{ element.endDate }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="dueDate">
                      <th mat-header-cell *matHeaderCellDef>Due Date</th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.dueDate }}"
                      >
                        {{ element.dueDate }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef>Status</th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.status }}"
                      >
                        {{ element.status }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="receivedDate">
                      <th mat-header-cell *matHeaderCellDef>Received Date</th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.received }}"
                      >
                        {{ element.receivedDate }}
                      </td>
                    </ng-container>

                    <tr
                      mat-header-row
                      *matHeaderRowDef="
                        obligationDisplayedColumns;
                        sticky: true
                      "
                    ></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: obligationDisplayedColumns"
                    ></tr>
                  </table>
                  <div
                    *ngIf="obligationNoDataFound"
                    class="text-align-center mt-1"
                  >
                    No records found
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab [disabled]="!isAuthorized" label="View VAT Return">
          <div class="content-body content-body-scroll">
            <div class="content-header">
              <div class="content-header-input">
                <div
                  class="d-flex justify-space-between align-items-end wp-100 mb-15"
                >
                  <div
                    class="content-header d-flex align-items-end justify-space-between header-filters header-count header-count-1"
                  >
                    <div class="d-flex align-items-end">
                      <div
                        class="content-right-button d-flex flex-column gap-5 mr-10"
                      >
                        <span class="fw-bold"> VAT Scheme </span>
                        <div [ngClass]="['w-200']">
                          <ng-select
                            #vatScheme
                            (focus)="vatScheme.open()"
                            (valueChange)="vatScheme.close()"
                            [disableOptionCentering]="true"
                            [(ngModel)]="vatSchemaId"
                            panelClass="mat-select-position"
                            [disabled]="true"
                          >
                            <ng-option
                              *ngFor="let option of vatSchemeList"
                              [value]="option.id"
                            >
                              <div title="{{ option.name }}">
                                {{ option.name }}
                              </div>
                            </ng-option>
                          </ng-select>
                        </div>
                      </div>
                      <div
                        class="content-right-button d-flex flex-column gap-5 mr-10"
                      >
                        <span class="fw-bold"> Periods </span>
                        <div [ngClass]="['w-200']">
                          <ng-select
                            #periods
                            (focus)="periods.open()"
                            (valueChange)="periods.close()"
                            [disableOptionCentering]="true"
                            [(ngModel)]="vatSubmissionPeriods"
                            class="input-sm form-control"
                            panelClass="mat-select-position"
                          >
                            <ng-option
                              *ngFor="let fn of vatSubmissionPeriods"
                              [value]="fn.id"
                            >
                              <div title="{{ fn.name }}">{{ fn.name }}</div>
                            </ng-option>
                          </ng-select>
                        </div>
                      </div>

                      <div class="toolbar mr-10">
                        <button
                          matTooltip="Search"
                          mat-button
                          (click)="getVatReturn()"
                        >
                          <mat-icon>search</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="mat-table-wrapper"
                  [ngClass]="{
                    'mat-table-wrapper-noData': viewVatReturnNoDataFound
                  }"
                >
                  <table
                    mat-table
                    [dataSource]="vatReturns"
                    aria-describedby="VatReturn"
                    mat-table
                    matSort
                  >
                    <ng-container matColumnDef="boxNo">
                      <th mat-header-cell *matHeaderCellDef>Box No.</th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.boxName }}"
                      >
                        {{ element.boxName }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="vatDescription">
                      <th mat-header-cell *matHeaderCellDef>VAT Description</th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.description }}"
                      >
                        {{ element.description }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="vatAmount">
                      <th mat-header-cell *matHeaderCellDef>VAT Amount</th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.amount }}"
                      >
                        {{ element.amount | numberPipe }}
                      </td>
                    </ng-container>

                    <tr
                      mat-header-row
                      *matHeaderRowDef="
                        viewVatReturnDisplayedColumns;
                        sticky: true
                      "
                    ></tr>
                    <tr
                      mat-row
                      *matRowDef="
                        let row;
                        columns: viewVatReturnDisplayedColumns
                      "
                    ></tr>
                  </table>
                  <div
                    *ngIf="viewVatReturnNoDataFound"
                    class="text-align-center mt-1"
                  >
                    Select drop down and click on the search button to view the
                    results
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab [disabled]="!isAuthorized" label="Retrieve VAT Liabilities">
          <div class="content-body content-body-scroll">
            <div class="content-header">
              <div class="content-header-input">
                <div
                  class="d-flex justify-space-between align-items-end wp-100 mb-15"
                >
                  <div
                    class="content-header d-flex align-items-end justify-space-between header-filters header-count header-count-1"
                  >
                    <div class="d-flex align-items-end">
                      <div
                        class="content-right-button d-flex flex-column gap-5 mr-10"
                      >
                        <span class="fw-bold"> From : </span>
                        <div>
                          <mat-form-field appearance="standard" class="ml-0">
                            <input
                              [(ngModel)]="liabilitiesDateFrom"
                              [matDatepicker]="liabilitiesFromPicker"
                              matInput
                              autocomplete="off"
                              tabindex="-1"
                              required
                            />
                            <mat-datepicker-toggle
                              matSuffix
                              [for]="liabilitiesFromPicker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker
                              #liabilitiesFromPicker
                            ></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                      <div
                        class="content-right-button d-flex flex-column gap-5 mr-10"
                      >
                        <span class="fw-bold"> To : </span>
                        <div>
                          <mat-form-field appearance="standard" class="ml-0">
                            <input
                              [(ngModel)]="liabilitiesDateTo"
                              [matDatepicker]="liabilitiesToPicker"
                              [min]="liabilitiesDateFrom"
                              matInput
                              autocomplete="off"
                              tabindex="-1"
                              required
                            />
                            <mat-datepicker-toggle
                              matSuffix
                              [for]="liabilitiesToPicker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker
                              #liabilitiesToPicker
                            ></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="toolbar mr-10">
                        <button
                          matTooltip="Search"
                          mat-button
                          (click)="getVatLiabilities()"
                        >
                          <mat-icon>search</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="mat-table-wrapper"
                  [ngClass]="{
                    'mat-table-wrapper-noData': liabilitiesNoDataFound
                  }"
                >
                  <table
                    mat-table
                    [dataSource]="vatLiabilities"
                    aria-describedby="Liabilities"
                    mat-table
                    matSort
                  >
                    <ng-container matColumnDef="from">
                      <th mat-header-cell *matHeaderCellDef>From</th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.taxPeriod.from }}"
                      >
                        {{ element.taxPeriod.from }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="to">
                      <th mat-header-cell *matHeaderCellDef>To</th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.taxPeriod.to }}"
                      >
                        {{ element.taxPeriod.to }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="type">
                      <th mat-header-cell *matHeaderCellDef>Type</th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.type }}"
                      >
                        {{ element.type }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="originalAmount">
                      <th mat-header-cell *matHeaderCellDef>Original Amount</th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.originalAmount }}"
                      >
                        {{ element.originalAmount | numberPipe }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="outstandingAmount">
                      <th mat-header-cell *matHeaderCellDef>
                        Outstanding Amount
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.outstandingAmount }}"
                      >
                        {{ element.outstandingAmount | numberPipe }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="due">
                      <th mat-header-cell *matHeaderCellDef>Due</th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.due }}"
                      >
                        {{ element.due }}
                      </td>
                    </ng-container>

                    <tr
                      mat-header-row
                      *matHeaderRowDef="
                        liabilitiesDisplayedColumns;
                        sticky: true
                      "
                    ></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: liabilitiesDisplayedColumns"
                    ></tr>
                  </table>
                  <div
                    *ngIf="liabilitiesNoDataFound"
                    class="text-align-center mt-1"
                  >
                    No records found
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab [disabled]="!isAuthorized" label="Retrieve VAT Payments">
          <div class="content-body content-body-scroll">
            <div class="content-header">
              <div class="content-header-input">
                <div
                  class="d-flex justify-space-between align-items-end wp-100 mb-15"
                >
                  <div
                    class="content-header d-flex align-items-end justify-space-between header-filters header-count header-count-1"
                  >
                    <div class="d-flex align-items-end">
                      <div
                        class="content-right-button d-flex flex-column gap-5 mr-10"
                      >
                        <span class="fw-bold"> From : </span>
                        <div>
                          <mat-form-field appearance="standard" class="ml-0">
                            <input
                              [(ngModel)]="paymentsDateFrom"
                              [matDatepicker]="paymentsFromPicker"
                              matInput
                              autocomplete="off"
                              tabindex="-1"
                              required
                            />
                            <mat-datepicker-toggle
                              matSuffix
                              [for]="paymentsFromPicker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker
                              #paymentsFromPicker
                            ></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                      <div
                        class="content-right-button d-flex flex-column gap-5 mr-10"
                      >
                        <span class="fw-bold"> To : </span>
                        <div>
                          <mat-form-field appearance="standard" class="ml-0">
                            <input
                              [(ngModel)]="paymentsDateTo"
                              [matDatepicker]="paymentsToPicker"
                              [min]="paymentsDateFrom"
                              matInput
                              autocomplete="off"
                              tabindex="-1"
                              required
                            />
                            <mat-datepicker-toggle
                              matSuffix
                              [for]="paymentsToPicker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #paymentsToPicker></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="toolbar mr-10">
                        <button
                          matTooltip="Search"
                          mat-button
                          (click)="getVatPayments()"
                        >
                          <mat-icon>search</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="mat-table-wrapper"
                  [ngClass]="{
                    'mat-table-wrapper-noData': paymentsNoDataFound
                  }"
                >
                  <table
                    mat-table
                    [dataSource]="vatPayments"
                    aria-describedby="Payments"
                    mat-table
                    matSort
                  >
                    <ng-container matColumnDef="amount">
                      <th mat-header-cell *matHeaderCellDef>Amount</th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.amount }}"
                      >
                        {{ element.amount | numberPipe }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="received">
                      <th mat-header-cell *matHeaderCellDef>Received</th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="clamp"
                        title="{{ element.received }}"
                      >
                        {{ element.received }}
                      </td>
                    </ng-container>

                    <tr
                      mat-header-row
                      *matHeaderRowDef="paymentsDisplayedColumns; sticky: true"
                    ></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: paymentsDisplayedColumns"
                    ></tr>
                  </table>
                  <div
                    *ngIf="paymentsNoDataFound"
                    class="text-align-center mt-1"
                  >
                    No records found
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
