import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MaxLength, Modules, NotificationTextMessage } from '@app/core/Enum';
import { ProductType } from '@app/core/Enum/product-type';
import { GlobalComponent, ProductsModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateProduct,
  GetAllBranchList,
  GetAllDepartmentListByBranch,
  GetVatRateList,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { QuickAddComponent } from '../quick-add/quick-add.component';

@Component({
  selector: 'app-add-product-popup',
  templateUrl: './add-product-popup.component.html',
})
export class AddProductPopupComponent implements OnInit {
  vatRateList: any[];
  productForm: FormGroup;
  maxLength = MaxLength;
  @Input() inputModuleId;
  @Input() triggerOnProductSave: Observable<any>;
  @Input() triggerOnProductClear: Observable<any>;
  productId = Guid.EMPTY as unknown as Guid;
  productData: ProductsModel;

  departmentList: any[] = [];
  branchList: any[] = [];
  isFromFixedAssets: boolean = false;
  isVatRegistered: any;
  constructor(
    private store: Store,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<QuickAddComponent>,
    public globalComponent: GlobalComponent
  ) {}

  ngOnInit(): void {
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    this.isFromFixedAssets = this.inputModuleId === Modules.FixedAssets;
    this.setForm();
    this.getVatRateList();
    this.getBranchList();

    this.triggerOnProductSave.subscribe((res) => {
      this.isFromFixedAssets = res === Modules.FixedAssets;
      this.onSave(false);
    });

    this.triggerOnProductClear.subscribe((res) => {
      this.onCancel();
    });

    this.productForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.productForm.touched;
    });
  }

  onCancel(): void {
    this.commonService.isInitialValueChange = false;

    this.productId = Guid.EMPTY as unknown as Guid;
    this.ngOnInit();
  }

  calAmount(): void {
    if (
      this.productForm.controls.openingBalancePrice.value !== undefined &&
      this.productForm.controls.openingBalancePrice.value !== null &&
      this.productForm.controls.openingBalancePrice.value !== '' &&
      this.productForm.controls.openingBalanceQuantity.value !== undefined &&
      this.productForm.controls.openingBalanceQuantity.value !== null &&
      this.productForm.controls.openingBalanceQuantity.value !== ''
    ) {
      const amount =
        this.productForm.controls.openingBalancePrice.value *
        this.productForm.controls.openingBalanceQuantity.value;
      this.productForm.controls.productAmount.setValue(+amount);
    }
  }

  editProduct(data: any): void {
    this.productForm.patchValue({
      productName: data.name,
      productCode: data.code,
      vatRate: data.vatRateId,
      productPrice: data.price,
      openingBalanceQuantity: data.openingBalanceQuantity,
      openingBalancePrice: data.openingBalancePrice,
      productAmount: data.openingBalanceQuantity * data.openingBalancePrice,
      productDescription: data.description,
      branchId: data.branchId,
      departmentId: data.departmentId,
    });
  }

  setForm(): void {
    this.productForm = new FormGroup({
      productName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      productCode: new FormControl(''),
      vatRate: new FormControl(null),
      productPrice: new FormControl('', [Validators.required]),
      openingBalanceQuantity: new FormControl('', [
        Validators.pattern('[0-9]+'),
      ]),
      openingBalancePrice: new FormControl(''),
      productAmount: new FormControl({ value: '', disabled: true }),
      productDescription: new FormControl(''),
      branchId: new FormControl(''),
      departmentId: new FormControl(''),
      trackProductInInvetory: new FormControl(false),
    });
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.productData = {
        id: this.productId,
        name: this.productForm.controls.productName.value,
        code: this.productForm.controls.productCode.value,
        description: this.productForm.controls.productDescription.value,
        vatRateId: this.productForm.controls.vatRate.value ?? null,
        price: +this.productForm.controls.productPrice.value,
        openingBalanceQuantity:
          +this.productForm.controls.openingBalanceQuantity.value,
        openingBalancePrice:
          +this.productForm.controls.openingBalancePrice.value,
        branchId:
          this.productForm.controls.branchId.value === ''
            ? null
            : this.productForm.controls.branchId.value,
        departmentId:
          this.productForm.controls.departmentId.value === ''
            ? null
            : this.productForm.controls.departmentId.value,
        productType: this.isFromFixedAssets ? ProductType.Asset : undefined,
        trackProductInInvetory: this.isFromFixedAssets
          ? false
          : this.productForm.controls.trackProductInInvetory.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.productForm.invalid) {
      this.commonService.addValidation(this.productForm, this.renderer);
    } else if (
      this.productForm.controls.productPrice.value === '' ||
      this.productForm.controls.productPrice.value === '0'
    ) {
      this.commonService.onFailure(NotificationTextMessage.amtErrorMessage);
      this.commonService.addValidation(
        this.productForm.controls.productPrice.value,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateProduct(this.productData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.dialogRef.close(true);
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  getVatRateList(): void {
    this.store.dispatch(new GetVatRateList()).subscribe((res) => {
      this.vatRateList = res.common.vatRate;
    });
  }

  getBranchList(): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.productForm.controls.branchId.setValidators(
              Validators.required
            );
            this.productForm.controls.branchId.updateValueAndValidity();
            this.onChangeBranch(this.branchList[0].id);
          } else {
            this.branchList = [];
            this.departmentList = [];
          }
        })
      )
      .subscribe();
  }

  onChangeBranch(branch): void {
    this.getDepartmentListByBranch(branch);
  }

  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .pipe(
          tap((res) => {
            this.departmentList = [];
            if (res.company.department.length > 0) {
              this.departmentList = res.company.department;
              this.productForm.controls.departmentId.setValidators(
                Validators.required
              );
              this.productForm.controls.departmentId.updateValueAndValidity();
            } else {
              this.productForm.controls.departmentId.clearValidators();
              this.productForm.controls.departmentId.updateValueAndValidity();
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
      this.productForm.controls.departmentId.clearValidators();
      this.productForm.controls.departmentId.updateValueAndValidity();
    }
  }
}
