<div class="mat-dialog-wrapper">
  <h2 mat-dialog-title>Share</h2>
  <mat-dialog-content>
    <form [formGroup]="emailForm">
      <div class="form-field">
        <p class="fw-bold">To<span class="text-danger-300">*</span></p>
        <mat-form-field
          appearance="fill"
          [ngClass]="{
            'is-invalid': isFormSubmitted && getFormControls.to.errors
          }"
        >
          <input
            matInput
            cdkFocusInitial
            formControlName="to"
            autocomplete="off"
            required
            maxlength="500"
          />
          <div class="d-flex align-items-center gap-5 pr-10" matSuffix>
            <div class="text-cc" (click)="HideorShowCC()" *ngIf="!hideOrShowCC">
              CC/
            </div>
            <div
              class="text-cc"
              (click)="HideorShowBCC()"
              *ngIf="!hideOrShowBCC"
            >
              BCC
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="form-field" *ngIf="hideOrShowCC">
        <p class="fw-bold">CC</p>
        <mat-form-field>
          <input
            matInput
            formControlName="cc"
            autocomplete="off"
            maxlength="500"
          />
        </mat-form-field>
      </div>
      <div class="form-field" *ngIf="hideOrShowBCC">
        <p class="fw-bold">BCC</p>
        <mat-form-field>
          <input
            matInput
            formControlName="bcc"
            autocomplete="off"
            maxlength="500"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p class="fw-bold">Subject<span class="text-danger-300">*</span></p>
        <mat-form-field
          appearance="fill"
          [ngClass]="{
            'is-invalid': isFormSubmitted && getFormControls.subject.errors
          }"
        >
          <input
            matInput
            formControlName="subject"
            autocomplete="off"
            required
            maxlength="500"
          />
        </mat-form-field>
      </div>
      <div class="form-field textarea">
        <p class="fw-bold">Message<span class="text-danger-300">*</span></p>
        <angular-editor
          formControlName="message"
          [placeholder]="'Enter text here...'"
        ></angular-editor>
      </div>
    </form>
  </mat-dialog-content>
</div>

<mat-dialog-actions class="m-0">
  <div>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSend()"
    >
      Send
    </button>
    <button
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      mat-dialog-close
    >
      Cancel
    </button>
  </div>
</mat-dialog-actions>
<app-custome-loader *ngIf="isShowLoader"></app-custome-loader>

<app-custom-notifications></app-custom-notifications>
