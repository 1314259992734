import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { GeneralDashboardPieChartData } from '@app/core/Models/general-dashboard/general-dashboard';
import {
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexTooltip,
  ChartComponent,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  fill: ApexFill;
  legend: ApexLegend;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  labels: any;
};

@Component({
  selector: 'app-dashboard-current-month',
  templateUrl: './dashboard-current-month.component.html',
  styleUrls: ['./dashboard-current-month.component.scss'],
})
export class DashboardCurrentMonthComponent {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  analyticsData: Array<GeneralDashboardPieChartData>;
  @Input() generalDashboardPieChartData: any;
  ngOnChanges(changes: SimpleChanges) {
    if (this.generalDashboardPieChartData) {
      if (changes['generalDashboardPieChartData']) {
        this.analyticsData = this.generalDashboardPieChartData;
        if (this.analyticsData?.length > 0) {
          this.setChartValue();
        }
      }
    }
  }
  setChartValue(): void {
    const pieChartLables: any = [];
    const pieChartValues: any = [];

    for (const data of this.analyticsData) {
      pieChartValues.push(data.value);
    }
    for (const data of this.analyticsData) {
      pieChartLables.push(data.label);
    }
    this.chartOptions = {
      series: pieChartValues,
      chart: {
        height: 450,
        width: 450,
        type: 'donut',
      },
      labels: pieChartLables,
      fill: {
        colors: ['#7B76D3', '#7A5295', '#BA5397', '#7B7644'],
      },
      legend: {
        show: true,
        position: 'right',
        horizontalAlign: 'right',
        offsetX: -40,
        offsetY: 30,
        fontSize: '14px',
        fontFamily: 'Source Sans Pro, sans-serif',
        markers: {
          fillColors: ['#7B76D3', '#7A5295', '#BA5397', '#7B7644'],
          offsetY: 3,
          offsetX: -5,
        },
        itemMargin: {
          vertical: 2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        marker: {
          show: false,
        },
        enabled: true,
        fillSeriesColor: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }
}
