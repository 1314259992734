import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { AccountModel, CISDetailModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { CreateAccount, GetDataByAccountId, MenuState } from '@app/core/Store';
import {
  AddCISInformationComponent,
  AddClosePopupComponent,
  AddCommericalDetailsComponent,
  AddContactDetailsComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss'],
})
export class SupplierComponent implements OnInit {
  moduleId = Modules.Suppliers;
  moduleName = ModuleName.Suppliers;

  selectedIndex = 0;
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isExit = false;
  headerText = '';

  supplierId = Guid.EMPTY as unknown as Guid;
  accountData: AccountModel;
  cISDetailData: CISDetailModel;

  @ViewChild(AddContactDetailsComponent, { static: true })
  contactDetails;

  @ViewChild(AddCommericalDetailsComponent, { static: true })
  commericalDetails;

  @ViewChild(AddCISInformationComponent, { static: true })
  cisInformation;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @Output()
  readonly triggerAccountDetails = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  triggerEditData: Subject<any> = new Subject<any>();
  subscriptionRouting: Subscription;
  store: Store;
  commonService: CommonService;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(
    private spinner: NgxSpinnerService,
    private _Activatedroute: ActivatedRoute,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private location: Location,
    private renderer: Renderer2,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.supplierId = atob(params.get('id')!) as unknown as Guid;
          this.editSupplier();
        } else {
          this.supplierId = Guid.EMPTY as unknown as Guid;
          this.headerText = 'Add New Supplier';
        }
      }
    );
  }

  tabClick(tab) {
    this.selectedIndex = tab.index;
    window.dispatchEvent(new Event('resize'));
  }

  editSupplier(): void {
    this.store
      .dispatch(new GetDataByAccountId(this.supplierId))
      .subscribe((res) => {
        this.headerText = res.account.accountData.name;
        this.contactDetails.contactDetailForm.markAsUntouched();
        this.commericalDetails.commericalDetailsForm.markAsUntouched();
        this.triggerEditData.next(res.account.accountData);
      });

    setTimeout(() => {
      const data = {
        id: this.supplierId,
        moduleId: this.moduleId,
      };

      this.triggerAccountDetails.emit(data);
    }, 0);
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  dataSubmit(): boolean {
    this.spinner.show();
    if (
      this.cisInformation &&
      this.contactDetails.contactDetailForm.controls.cisEnabled.value
    ) {
      this.cISDetailData = {
        businessTypeId:
          +this.cisInformation.cisInformationForm.controls.businessTypeId.value,
        traderTypeId:
          +this.cisInformation.cisInformationForm.controls.businessTypeId.value,
        tradingName:
          this.cisInformation.cisInformationForm.controls.tradingName.value,
        firstName:
          this.cisInformation.cisInformationForm.controls.firstName.value,
        secondName:
          this.cisInformation.cisInformationForm.controls.secondName.value,
        surName: this.cisInformation.cisInformationForm.controls.surname.value,
        partnershipUTR:
          this.cisInformation.cisInformationForm.controls.partnershipUTR.value,
        partnershipName:
          this.cisInformation.cisInformationForm.controls.partnershipName.value,
        subContractorUTR:
          this.cisInformation.cisInformationForm.controls.subContractorUTR
            .value,
        nINumber:
          this.cisInformation.cisInformationForm.controls.niNumber.value,
        companyNumber:
          this.cisInformation.cisInformationForm.controls.companyNumber.value,
        workReference:
          this.cisInformation.cisInformationForm.controls.workReference.value,
        verifyTypeId:
          +this.cisInformation.cisInformationForm.controls.verify.value,
        status:
          this.cisInformation.cisInformationForm.controls.status.value === ''
            ? false
            : this.cisInformation.cisInformationForm.controls.status.value,
        verificationNumber:
          this.cisInformation.cisInformationForm.controls.verificationNumber
            .value,
        taxTreatmentTypeId:
          +this.cisInformation.cisInformationForm.controls.taxTreatment.value,
        lastVerified: this.datepipe
          .transform(
            this.cisInformation.cisInformationForm.controls.lastVerified.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        paymentFrequency:
          this.cisInformation.cisInformationForm.controls.paymentFrequency
            .value,
      };
    }
    this.accountData = {
      id: this.supplierId,
      moduleId: this.moduleId,
      name: this.contactDetails.contactDetailForm.controls.name.value,
      tradingName:
        this.contactDetails.contactDetailForm.controls.tradingName.value,
      address1:
        this.contactDetails.contactDetailForm.controls.addressLine1.value,
      address2:
        this.contactDetails.contactDetailForm.controls.addressLine2.value,
      city: this.contactDetails.contactDetailForm.controls.city.value,
      address3:
        this.contactDetails.contactDetailForm.controls.addressLine3.value,
      countryId: this.contactDetails.contactDetailForm.controls.country.value,
      postalCode: this.contactDetails.contactDetailForm.controls.postCode.value,
      phone: this.contactDetails.contactDetailForm.controls.phone.value,
      email: this.contactDetails.contactDetailForm.controls.email.value,
      mobileNumber:
        this.contactDetails.contactDetailForm.controls.mobileNumber.value,
      fax: this.contactDetails.contactDetailForm.controls.fax.value,
      vatRegistrationNumber:
        this.commericalDetails.commericalDetailsForm.controls
          .vatRegistrationNumber.value,
      bankAccountNumber:
        this.commericalDetails.commericalDetailsForm.controls.accountNumber
          .value,
      bankAccountName:
        this.commericalDetails.commericalDetailsForm.controls.accountName.value,
      bankBranchCode:
        this.commericalDetails.commericalDetailsForm.controls.branchCode.value,
      bankAccountIBANCode:
        this.commericalDetails.commericalDetailsForm.controls.accountIBANCode
          .value,
      termId: this.commericalDetails.commericalDetailsForm.controls.terms.value,
      accountTypeId: this.commonService.getEntityTypeId(this.moduleId),
      cISEnabled:
        this.contactDetails.contactDetailForm.controls.cisEnabled.value,
      cISDetail: this.cISDetailData,
      branchId:
        this.contactDetails.contactDetailForm.controls.branchId.value === ''
          ? null
          : this.contactDetails.contactDetailForm.controls.branchId.value,
    };
    return true;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.supplierId,
      isExit,
      Modules.Suppliers,
      RoutingPath.AddSuppliers
    );
  }

  onSave(isExit: boolean): void {
    if (this.contactDetails.contactDetailForm.invalid) {
      this.commonService.addValidation(
        this.contactDetails.contactDetailForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateAccount(this.accountData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.supplierId)) {
      this.editSupplier();
    } else {
      this.supplierId = Guid.EMPTY as unknown as Guid;
      this.contactDetails.ngOnInit();
      this.commericalDetails.ngOnInit();
      this.cisInformation.ngOnInit();
    }
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
