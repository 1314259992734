<div class="content-body-scroll">
  <div class="content-body">
    <p class="header-text d-flex justify-space-between align-items-center">
      {{ headerText }}
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCloseClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>
    <div class="d-flex gap-20 align-items-center mb-2">
      <div
        class="d-flex gap-20 align-items-center"
        [ngClass]="{ hidden: this.moduleId === moduleEnum.EditTrialBalance }"
      >
        <p class="fw-bold m-0">Date: <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard" class="w-150">
          <input
            matInput
            [(ngModel)]="accountingDate"
            [matDatepicker]="accountingDatePicker"
            autocomplete="off"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="accountingDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #accountingDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div
        class="d-flex gap-20 align-items-center"
        *ngIf="
          this.moduleId === moduleEnum.OpeningBalance &&
          this.branchList.length > 0
        "
      >
        <p class="fw-bold m-0">
          Branch: <span class="text-danger-300">*</span>
        </p>
        <ng-select
          #branch
          class="form-field mb-0"
          (focus)="branch.open()"
          (valueChange)="branch.close()"
          [disableOptionCentering]="true"
          [(ngModel)]="selectedBranchValue"
          dropdownPosition="bottom"
          (change)="onChangeBranch($event)"
        >
          <ng-option *ngFor="let bl of branchList" [value]="bl.id">
            <div title="{{ bl.name }}">{{ bl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div
      class="sidenav-table sidenav-table-input wp-100"
      [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
    >
      <div class="mat-table-wrapper account-detail-table">
        <form [formGroup]="formOpeningBalance">
          <ng-container formArrayName="openingBalanceArray">
            <table
              aria-describedby="account-list"
              mat-table
              [dataSource]="tableDataSource"
            >
              <ng-container matColumnDef="srNo">
                <th mat-header-cell *matHeaderCellDef class="w-20">Sr No.</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <div class="d-flex">
                    {{ i + 1 }}
                  </div>
                </td>
                <td mat-footer-cell *matFooterCellDef>Total</td>
              </ng-container>
              <ng-container matColumnDef="account">
                <th mat-header-cell *matHeaderCellDef class="w-300">
                  Account Name
                </th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                  class="widthemail"
                >
                  <mat-form-field appearance="fill">
                    <input
                      type="text"
                      matInput
                      formControlName="account"
                      required
                      [matAutocomplete]="accountGroup"
                      #trigger="matAutocompleteTrigger"
                      appAutocompletePosition="trigger"
                      (keyup)="onAccountSearch($event, i)"
                      (keydown.Tab)="onSearch($event, i)"
                      (change)="onSearch($event, i)"
                      (mousedown)="resetAccountList(element, i)"
                    />
                    <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                    <mat-autocomplete
                      [displayWith]="getOptionText"
                      (opened)="scrollIntoView(element)"
                      #accountGroup="matAutocomplete"
                      (optionSelected)="accountSelect($event, element, i)"
                    >
                      <mat-optgroup
                        *ngFor="let group of accountGroupList[i]"
                        [label]="group.groupName"
                      >
                        <mat-option
                          *ngFor="let names of group.listModels"
                          [value]="names"
                          title="{{ names.name }}"
                          [class]="
                            element.value.account?.id === names.id
                              ? 'mat-selected'
                              : ''
                          "
                        >
                          {{ names.name }}
                        </mat-option>
                      </mat-optgroup>
                    </mat-autocomplete>
                  </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container matColumnDef="debit">
                <th mat-header-cell *matHeaderCellDef>Debit</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      allowDecimal
                      matInput
                      [value]="element.debit"
                      autocomplete="off"
                      formControlName="debit"
                      (keypress)="debitChanges(i, $event)"
                      (change)="calAmount()"
                      maxlength="{{ maxLength.maxLength10 }}"
                    />
                  </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  {{ totalDebit | numberPipe }}
                </td>
              </ng-container>
              <ng-container matColumnDef="credit">
                <th mat-header-cell *matHeaderCellDef>Credit</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field>
                    <input
                      allowDecimal
                      matInput
                      [value]="element.credit"
                      autocomplete="off"
                      formControlName="credit"
                      (keypress)="creditChanges(i, $event)"
                      (change)="calAmount()"
                      maxlength="{{ maxLength.maxLength10 }}"
                      (keydown.Tab)="addNewRow()"
                    />
                  </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  {{ totalCredit | numberPipe }}
                </td>
              </ng-container>
              <ng-container matColumnDef="closeButton">
                <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                  class="text-align-center"
                >
                  <div
                    class="d-flex align-items-center justify-content-end gap-10"
                  >
                    <button
                      *ngIf="
                        this.openingBalanceArray?.controls[i]?.get('isExplain')
                          ?.value ||
                        this.openingBalanceArray?.controls[i]?.get('isAdvance')
                          ?.value
                      "
                      type="button"
                      mat-button
                      disableRipple
                      title="Explain"
                      (click)="onExplainContacts(i, element)"
                    >
                      <mat-icon>toc</mat-icon>
                    </button>

                    <button
                      class="close-btn"
                      type="button"
                      mat-button
                      disableRipple
                      title="Delete"
                      (click)="onDeleteOpeningBalance(i)"
                    >
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </div>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              <ng-container
                *ngFor="let columnName of displayOpeningBalanceColumns2"
                [matColumnDef]="columnName"
              >
                <td mat-footer-cell *matFooterCellDef="let row; let i = index">
                  {{ countDifferenceOfDrCr(columnName) }}
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="displayOpeningBalanceColumns; sticky: true"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayOpeningBalanceColumns"
              ></tr>
              <tr
                mat-footer-row
                *matFooterRowDef="displayOpeningBalanceColumns"
              ></tr>
              <tr
                mat-footer-row
                *matFooterRowDef="displayOpeningBalanceColumns2"
              ></tr>
            </table>
          </ng-container>
        </form>
      </div>
      <div class="mb-1 mt-1 d-flex justify-space-between">
        <div class="mt-1">
          <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
            >Add New Line</a
          >
          <a
            class="link mr-1"
            *ngIf="openingBalanceArray.length > 0"
            href="javascript:void(0)"
            (click)="clearForm()"
            >Clear All Lines</a
          >
        </div>
      </div>
    </div>
  </div>
  <app-buttons
    *ngIf="!(isViewPermission$ | async)"
    [getModuleId]="moduleId"
    (triggerOnSaveClick)="isUpdated($event)"
    (triggerOnCancelClick)="onCancel(true)"
  ></app-buttons>
</div>
