import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MinuteOfMeeting } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable()
export class MinutesOfMeetingsService {
  constructor(private http: HttpClient) {}

  createMeeting(data: MinuteOfMeeting): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}MinutesOfMeeting/save`,
      JSON.stringify(data),
      headers
    );
  }

  getDefaultTemplate(): Observable<any> {
    return this.http.get(
      `${environment.apiVersionUrl}MinutesOfMeeting/default`,
      {
        responseType: 'text',
      }
    );
  }

  getDataByMeetingId(minutesOfMeetingId: Guid): Observable<MinuteOfMeeting> {
    return this.http.get<MinuteOfMeeting>(
      `${environment.apiVersionUrl}MinutesOfMeeting/get/${minutesOfMeetingId}`
    );
  }

  deleteMinutesOfMeeting(minutesOfMeetingIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(minutesOfMeetingIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}MinutesOfMeeting/delete`,
      options
    );
  }

  copyMinutesOfMeeting(minutesOfMeetingIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}MinutesOfMeeting/copyMinutesOfMeeting`,
      JSON.stringify(minutesOfMeetingIds),
      headers
    );
  }

  archiveAndRestoreMinutesOfMeeting(
    minutesOfMeetingIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}MinutesOfMeeting/archive/${isArchive}`,
      JSON.stringify(minutesOfMeetingIds),
      options
    );
  }
}
