import { Injectable } from '@angular/core';
import { ProductModel, ProductsModel, SideListModel } from '@app/core/Models';
import { CommonService, ProductService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreProduct,
  CreateProduct,
  DeleteProduct,
  GetDataByProductId,
  GetProductList,
  GetProductListByProductType,
  GetProductListByProductTypeByBranch,
  GetTrackedProducts,
} from './product.action';

export class ProductStateInfo {
  product: Array<ProductModel>;
  productId?: Guid;
  isProductAdded?: boolean;
  isLastPage?: boolean;
  productData?: ProductsModel;
  sideListModel: Array<SideListModel>;
  trackedProducts?: Array<any>;
}

@State<ProductStateInfo>({
  name: 'product',
  defaults: {
    product: [],
    sideListModel: [],
  },
})
@Injectable()
export class ProductState {
  constructor(
    private commonService: CommonService,
    public productService: ProductService
  ) {}

  @Selector()
  static getProduct(state: ProductStateInfo) {
    return state.product;
  }

  @Selector()
  static isLastPage(state: ProductStateInfo) {
    return state.isLastPage;
  }

  @Action(GetProductList, { cancelUncompleted: true })
  getProductList(
    { getState, setState }: StateContext<ProductStateInfo>,
    action: GetProductList
  ) {
    return this.commonService.getProductList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          product: res,
        });
      })
    );
  }

  @Action(GetTrackedProducts, { cancelUncompleted: true })
  getTrackedProducts(
    { getState, setState }: StateContext<ProductStateInfo>,
    action: GetTrackedProducts
  ) {
    return this.productService.getTrackedProducts().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          trackedProducts: res,
        });
      })
    );
  }

  @Action(CreateProduct)
  createProduct(
    { patchState }: StateContext<ProductStateInfo>,
    action: CreateProduct
  ) {
    return this.productService.createProduct(action.product).pipe(
      tap((res) => {
        patchState({
          productId: res,
          isProductAdded: true,
        });
      })
    );
  }

  @Action(GetDataByProductId)
  getDataByProductId(
    { patchState }: StateContext<ProductStateInfo>,
    action: GetDataByProductId
  ) {
    return this.productService.getDataByProductId(action.productId).pipe(
      tap((res) => {
        patchState({
          productData: res,
        });
      })
    );
  }

  @Action(DeleteProduct)
  deleteProduct(
    { getState, patchState }: StateContext<ProductStateInfo>,
    action: DeleteProduct
  ) {
    return this.productService.deleteProduct(action.productIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredProduct = state.product.filter(
          (item) =>
            !action.productIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.productIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          product: filteredProduct,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreProduct)
  archiveAndRestoreAccount(
    { getState }: StateContext<ProductStateInfo>,
    action: ArchiveAndRestoreProduct
  ) {
    return this.productService.archiveAndRestoreProduct(
      action.productIds,
      action.isArchive
    );
  }

  @Action(GetProductListByProductType)
  getProductListByProductType(
    { patchState }: StateContext<ProductStateInfo>,
    action: GetProductListByProductType
  ) {
    return this.commonService
      .getProductListByProductType(action.productType)
      .pipe(
        tap((res) => {
          patchState({
            product: res,
          });
        })
      );
  }

  @Action(GetProductListByProductTypeByBranch)
  getProductListByProductTypeByBranch(
    { patchState }: StateContext<ProductStateInfo>,
    action: GetProductListByProductTypeByBranch
  ) {
    return this.commonService
      .getProductListByProductTypeByBranch(action.productType, action.branchId)
      .pipe(
        tap((res) => {
          patchState({
            product: res,
          });
        })
      );
  }
}
