import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaxLength, NotificationTextMessage } from '@app/core/Enum';
import { ProductType } from '@app/core/Enum/product-type';
import { ProductModel, VatRateScheme } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetCustomBankAndCashAccount,
  GetDataByFixedAssetsId,
  GetFixedAssetAdditionList,
  GetProductListByProductType,
  GetVatRateList,
  ProductState,
  QuickFixedAssetReturn,
} from '@app/core/Store';
import { TransactionsAttachmentComponent } from '@app/modules/transactions';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-fixed-asset-return-popup',
  templateUrl: './fixed-asset-return-popup.component.html',
  styleUrls: ['./fixed-asset-return-popup.component.scss'],
})
export class FixedAssetReturnPopupComponent implements OnInit {
  maxLength = MaxLength;
  paymentAccountList: any[] = [];
  vatRateList: VatRateScheme[];

  fixedAssetsId: any;
  refundDate: any;
  returnDate: any;
  refundAccountId: any;
  dueAmount: number = 0;
  refundAmount: number = 0;
  showRefund: boolean = false;

  fixedAssetsTypeList: any[] = [];
  fixedAsset: any;

  productTypeList: ProductModel[];

  displayProductDetailsColumns: string[] = [
    'srNo',
    'product',
    'qty',
    'price',
    'returnQty',
  ];

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FixedAssetReturnPopupComponent>,
    public commonService: CommonService,
    private store: Store,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.fixedAssetsId = this.data.id;
    if (
      this.fixedAssetsId !== undefined &&
      this.fixedAssetsId !== null &&
      this.fixedAssetsId !== Guid.EMPTY
    ) {
      this.getInvoiceType().then(() => {
        this.getProducts().then(() => {
          this.getAccountList().then(() => {
            this.getVatRateList().then(() => {
              this.getFixedAssets();
            });
          });
        });
      });
    }
  }

  async getFixedAssets(): Promise<void> {
    this.spinner.show();
    await this.store
      .dispatch(new GetDataByFixedAssetsId(this.fixedAssetsId))
      .toPromise()
      .then((res) => {
        this.spinner.hide();
        this.fixedAsset = res.fixedAssets.fixedAssetsData;
      });
  }

  async getInvoiceType(): Promise<void> {
    this.spinner.show();
    await this.store
      .dispatch(new GetFixedAssetAdditionList())
      .toPromise()
      .then((res) => {
        this.spinner.hide();
        this.fixedAssetsTypeList = this.commonService.addGroupNameToListModels(
          res.account.accounts
        );
      });
  }

  async getAccountList(): Promise<void> {
    this.spinner.show();
    await this.store
      .dispatch(new GetCustomBankAndCashAccount())
      .toPromise()
      .then((res) => {
        this.spinner.hide();
        this.paymentAccountList = res.common.customBankAndCashAccount;
      });
  }

  async getProducts(): Promise<void> {
    this.spinner.show();
    await this.store
      .dispatch(new GetProductListByProductType(ProductType.Asset))
      .toPromise()
      .then((res) => {
        this.spinner.hide();
        this.productTypeList = this.store.selectSnapshot(
          ProductState.getProduct
        );
      });
  }

  async getVatRateList(): Promise<void> {
    await this.store
      .dispatch(new GetVatRateList())
      .toPromise()
      .then((res) => {
        this.vatRateList = res.common.vatRate;
      });
  }

  onReturnQuantityChange(element: any): void {
    if (+element.returnQuantity > +element.quantity) {
      element.returnQuantity = 0;
      return;
    } else {
      let vatRate = 1;
      let vatAmount = 0;
      const data = this.vatRateList.filter((x) => x.id === element.vatRateId);

      if (data.length > 0) {
        vatRate = data[0].rate;
      } else {
        vatRate = 1;
      }

      element.refundVatRate = vatRate === undefined ? 1 : vatRate;
      element.refundVatAmount =
        (+element.foreignPrice * +element.returnQuantity * vatRate) /
        (100 + vatRate);
      element.refundAmount =
        +element.returnQuantity * +element.foreignPrice + vatAmount;
      element.refundTotalNetAmount =
        +element.returnQuantity * +element.foreignPrice;

      this.refundAmount = this.fixedAsset.items
        .filter((x) => x.refundAmount !== null && x.refundAmount !== undefined)
        .reduce((x, y) => +x + +y.refundAmount, 0);

      this.dueAmount =
        this.fixedAsset.foreignTotalAmount -
        this.fixedAsset.receiptDetails
          .filter(
            (x) => x.foreignAmount !== null && x.foreignAmount !== undefined
          )
          .reduce((x, y) => +x + +y.foreignAmount, 0) -
        this.refundAmount;

      this.showRefund = this.dueAmount < 0 ? true : false;
      if (this.showRefund) {
        this.dueAmount = this.dueAmount * -1;
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSaveClick(): void {
    if (
      this.showRefund &&
      +this.dueAmount > 0 &&
      (this.refundAccountId === null || this.refundAccountId === undefined)
    ) {
      this.commonService.onFailure('Please select a refund account');
    } else if (
      this.showRefund &&
      +this.dueAmount > 0 &&
      (this.refundDate === null || this.refundDate === undefined)
    ) {
      this.commonService.onFailure('Please select a refund Date');
    } else if (this.returnDate === null || this.returnDate === undefined) {
      this.commonService.onFailure('Please select a Purchase Return Date');
    } else {
      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      const fixedAssetReturn = {
        id: this.fixedAssetsId,
        returnDate: this.returnDate,
        receiptAccountId: this.showRefund ? this.refundAccountId : null,
        receiptDate: this.showRefund ? this.refundDate : null,
        receiptAmount: this.showRefund ? this.dueAmount : 0,
        attachment: filedata,
        items: this.fixedAsset.items.filter(
          (x) =>
            x.returnQuantity !== undefined &&
            x.returnQuantity !== null &&
            +x.returnQuantity > 0
        ),
      };
      this.spinner.show();
      this.store
        .dispatch(new QuickFixedAssetReturn(fixedAssetReturn))
        .pipe()
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res !== undefined) {
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
              this.dialogRef.close(true);
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    }
  }
}
