import { Component, EventEmitter, Injector, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AccountProvider, RoutingPath } from '@app/core/Enum';
import { GlobalComponent, MainListParameters } from '@app/core/Models';
import { GeneralDashboardCompany } from '@app/core/Models/general-dashboard/general-dashboard';
import { CommonService, DashboardService } from '@app/core/Services';
import {
  GeneralDashBoardState,
  GetGeneralDashboardCompanyList,
  GetUserMailList,
  SetAsDefaultCompany,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
export interface PeriodicElement {
  sno: string;
  clientid: string;
  clientname: string;
  clienttype: string;
  period: string;
  duedate: string;
  status: string;
  amount: string;
}

@Component({
  selector: 'app-dashboard-vat-client',
  templateUrl: './dashboard-vat-client.component.html',
  styleUrls: ['./dashboard-vat-client.component.scss'],
})
export class DashboardVatClientComponent {
  displayedColumns: string[] = [
    'sno',
    'clientid',
    'clientname',
    'clienttype',
    'period',
    'duedate',
    'status',
    'amount',
  ];
  store: Store;
  spinner: NgxSpinnerService;
  cookieService: CookieService;
  commonService: CommonService;
  listParameters: MainListParameters = new MainListParameters();
  vatCompanyList: Array<GeneralDashboardCompany>;
  isHeaderChange = false;
  showPaginator = true;
  defaultCompanyId: any;
  reloadDashboard = false;
  noDataFound = false;
  companyName: string;
  capiumPayList: any;
  showMailOption: any;
  companyType = 0;
  selectedMonth = 0;
  selectedStatus = 0;
  selectedPaymentStatus = 0;
  searchText = '';
  isNoRecord = true;
  @Select(GeneralDashBoardState.totalRecord)
  totalRecord$: Observable<number>;
  totalSelectedRecords = 0;

  triggerPaginationChange: Subject<any> = new Subject<any>();
  @Output() onMonthChanged = new EventEmitter<any>();
  constructor(
    private injector: Injector,
    private dashboardService: DashboardService,
    private router: Router,
    private globalComponent: GlobalComponent
  ) {
    this.store = injector.get<Store>(Store);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.cookieService = injector.get<CookieService>(CookieService);
    this.router = injector.get<Router>(Router);
    this.commonService = this.injector.get<CommonService>(CommonService);
  }
  ngOnInit(): void {
    this.getVATCompanyList();
  }
  getVATCompanyList(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetGeneralDashboardCompanyList(this.getParamaters()))
      .pipe(
        tap((res) => {
          this.isHeaderChange = true;
          this.vatCompanyList = res.generalDashboard.dashBoardcompany;
          this.isNoRecord = this.vatCompanyList.length === 0;
          this.noDataFound = this.vatCompanyList.length > 0 ? false : true;
          const param = {
            pageSize: this.listParameters.pageSize,
            totalRecord: res.generalDashboard.totalRecord,
            isHeaderChange: this.isHeaderChange,
          };

          this.triggerPaginationChange.next(param);
          if (this.vatCompanyList.length) {
            this.companyName =
              this.vatCompanyList.find((item) => item.isDefault === true)
                ?.companyName ?? this.globalComponent.getDeafultCompanyName();
            this.defaultCompanyId =
              this.vatCompanyList.find((item) => item.isDefault === true)?.id ??
              this.globalComponent.getCompanyId();
          } else {
            this.companyName = this.globalComponent.getDeafultCompanyName();
            this.defaultCompanyId = this.globalComponent.getCompanyId();
          }
          this.spinner.hide();
          this.setCompanyId(this.defaultCompanyId);
          this.setCompanyName(this.companyName);
        })
      )
      .subscribe();
  }
  getParamaters(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.searchText,
      companyType: this.companyType,
      companyTypeName: null,
      isVatRegistered: true,
      status: this.selectedStatus,
      year: 0,
      month: this.selectedMonth,
      paymentStatus: this.selectedPaymentStatus,
    };

    return queryParams;
  }
  setCompanyId(companyId): void {
    if (
      companyId !== undefined &&
      companyId !== null &&
      companyId !== Guid.EMPTY
    ) {
      this.cookieService.set('companyid', companyId);
    }
  }
  setCompanyName(companyName: any): void {
    this.cookieService.set('defaultCompanyName', companyName);
  }

  onChangeCompany(isDefaultCompany: boolean, company: any): void {
    this.companyName = isDefaultCompany
      ? this.companyName
      : company.companyName;
    this.defaultCompanyId = isDefaultCompany
      ? this.defaultCompanyId
      : company.id;
    this.store
      .dispatch(new SetAsDefaultCompany(this.defaultCompanyId))
      .subscribe((x) => {
        this.setCompanyId(this.defaultCompanyId);
        this.setCompanyName(this.companyName);
        localStorage.setItem('defaultFinancialPeriod', '-1');
        this.dashboardService.setDashboardReload(true);
        this.spinner.show();
        this.getAllMail(5);
      });
    this.commonService.toggleMenu = true;
    this.router.navigate([RoutingPath.Dashboard]);
    this.reloadDashboard = true;
  }
  getAllMail(providerId): void {
    this.store
      .dispatch(new GetUserMailList(providerId))
      .subscribe((res: any) => {
        if (providerId === AccountProvider.CapiumPay) {
          this.capiumPayList = res.integration.integrationData;
          if (this.capiumPayList === null || this.capiumPayList.length === 0) {
            this.showMailOption = 'false';
          } else {
            this.showMailOption = 'true';
          }
          this.cookieService.set('showPaymentMailOption', this.showMailOption);
        }
      });
  }
  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.isHeaderChange = false;

      this.getVATCompanyList();
    }
  }
  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.isHeaderChange = false;

    this.getVATCompanyList();
  }
  togglePaginator(val: any): void {
    this.showPaginator = val;
  }
  onMonthDropdownChange(): void {
    this.getVATCompanyList();
    this.onMonthChanged.emit(this.selectedMonth);
  }
  onDropdownChange(): void {
    this.getVATCompanyList();
  }
  onSearch() {
    this.getVATCompanyList();
  }
}
