import { Injectable } from '@angular/core';
import {
  GeneralDashboardBarChart,
  GeneralDashboardCompany,
  GeneralDashboardPieChartData,
} from '@app/core/Models/general-dashboard/general-dashboard';
import { GeneralDashboardService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  GetGeneralDashboardChartData,
  GetGeneralDashboardCompanyList,
} from './general-dashboard.action';

export class GeneralDashBoardStateInfo {
  dashBoardcompany: Array<GeneralDashboardCompany>;
  totalRecord?: number;
  actions?: any;
  dashBoardPieChartData: Array<GeneralDashboardPieChartData>;
  dashBoardBarChartData: Array<GeneralDashboardBarChart>;
}
@State<GeneralDashBoardStateInfo>({
  name: 'generalDashboard',
  defaults: {
    dashBoardcompany: [],
    dashBoardPieChartData: [],
    dashBoardBarChartData: [],
  },
})
@Injectable()
export class GeneralDashBoardState {
  constructor(private generealDashboardService: GeneralDashboardService) {}
  @Selector()
  static totalRecord(state: GeneralDashBoardStateInfo): number {
    return state.totalRecord ?? 0;
  }
  @Action(GetGeneralDashboardCompanyList, { cancelUncompleted: true })
  getGeneralDashboardCompanyList(
    { patchState }: StateContext<GeneralDashBoardStateInfo>,
    action: GetGeneralDashboardCompanyList
  ) {
    return this.generealDashboardService
      .getGeneralDashboardCompanyList(action.queryParams)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              dashBoardcompany: result.resultSet.data,
              totalRecord: result.paginationModel.totalItemCount,
              actions: result.actions,
            });
          },
        })
      );
  }

  @Action(GetGeneralDashboardChartData, { cancelUncompleted: true })
  getGeneralDashboardChartsData(
    { patchState }: StateContext<GeneralDashBoardStateInfo>,
    action: GetGeneralDashboardChartData
  ) {
    return this.generealDashboardService
      .getGeneralDashboardChartsData(action.dashboardParams)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              dashBoardPieChartData: result.generalDashBoardDognutChart,
              dashBoardBarChartData: result.generalDashBoardBarChart,
            });
          },
        })
      );
  }
}
