import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  LiabilityModel,
  VATObligationModel,
  VATPaymentModel,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ViewVatDeatilsService {
  constructor(private http: HttpClient) {}

  getVatReturn(periodKey: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}MTD/vatReturn/${periodKey}`
    );
  }

  getVatRetunDetails(periodKey: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}MTD/vatReturnDetail/${periodKey}`
    );
  }

  getVatSubmissionPeriods(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}MTD/vatSubmissionPeriods`
    );
  }

  getAuthorized(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiVersionUrl}MTD/authorized`);
  }

  getVatObligations(queryParams: any): Observable<VATObligationModel[]> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<VATObligationModel[]>(
      `${environment.apiVersionUrl}MTD/vatObligations`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getVatLiabilities(queryParams: any): Observable<LiabilityModel[]> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<LiabilityModel[]>(
      `${environment.apiVersionUrl}MTD/vatLiabilities`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getPayments(queryParams: any): Observable<VATPaymentModel[]> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<VATPaymentModel[]>(
      `${environment.apiVersionUrl}MTD/vatPayments`,
      JSON.stringify(queryParams),
      headers
    );
  }
  getBridgingVatRetunDetails(periodKey: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Mtd/getBridgingCapDetails/${periodKey}`
    );
  }
}
