import { Injectable } from '@angular/core';
import { SideListModel } from '@app/core/Models';
import { CISService } from '@app/core/Services';
import { Action, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  DeleteCIS300,
  ExportCisReturn,
  ExportSubContractorReturn,
  GetCIS300Details,
  GetCISMonthlyReturn,
  GetContratorSetting,
  GetHMRCCredentials,
  GetNextPeriod,
  GetSubContractorDetailsList,
  SaveCISReturnDetails,
  SaveContratorSetting,
  SaveHMRCGatewayDetails,
  SaveSubContractorDetails,
  SaveVerifyData,
  SendEmailCis300Verification,
  SubmitCISReturn,
} from './CIS.action';

export class CISStateInfo {
  CIS300Details?: any;
  cisReturnData?: any;
  isSuccess: boolean;
  saveCISReturnDetailsData?: any;
  sideListModel: Array<SideListModel>;
  cis300: any[];
  nextPeriod?: any;
  verifyData?: any;
  contratorSettingData?: any;
  cisMonthlyReturnData?: any;
  isExport: boolean;
  hmrcCredentialsData?: any;
}

@State<CISStateInfo>({
  name: 'CIS',
  defaults: {
    cis300: [],
    sideListModel: [],
    isSuccess: false,
    isExport: false,
  },
})
@Injectable()
export class CISState {
  constructor(private cISService: CISService) {}

  @Action(SaveSubContractorDetails)
  saveSubContractorDetails(
    { patchState }: StateContext<CISStateInfo>,
    action: SaveSubContractorDetails
  ) {
    return this.cISService
      .saveSubContractorDetails(action.saveSubContractorDetailsData)
      .pipe(
        tap((res) => {
          patchState({
            isSuccess: res,
          });
        })
      );
  }

  @Action(SaveVerifyData)
  saveVerifyData(
    { patchState }: StateContext<CISStateInfo>,
    action: SaveVerifyData
  ) {
    return this.cISService.saveVerifyData(action.saveVerifyData).pipe(
      tap((res) => {
        patchState({
          verifyData: res,
        });
      })
    );
  }

  @Action(SaveCISReturnDetails)
  saveCISReturnDetails(
    { patchState }: StateContext<CISStateInfo>,
    action: SaveCISReturnDetails
  ) {
    return this.cISService
      .saveCISReturnDetails(action.saveCISReturnDetailsData)
      .pipe(
        tap((res) => {
          patchState({
            saveCISReturnDetailsData: res,
          });
        })
      );
  }

  @Action(SubmitCISReturn)
  submitCISReturn(
    { patchState }: StateContext<CISStateInfo>,
    action: SubmitCISReturn
  ) {
    return this.cISService.submitCISReturn(action.submitCISReturnData).pipe(
      tap((res) => {
        patchState({
          isSuccess: res,
        });
      })
    );
  }

  @Action(SaveHMRCGatewayDetails)
  saveHMRCGatewayDetails(
    { patchState }: StateContext<CISStateInfo>,
    action: SaveHMRCGatewayDetails
  ) {
    return this.cISService
      .SaveHMRCGatewayDetails(action.saveHMRCGatewayDetailsData)
      .pipe(
        tap((res) => {
          patchState({
            isSuccess: res,
          });
        })
      );
  }

  @Action(GetCIS300Details)
  getCIS300Details(
    { patchState }: StateContext<CISStateInfo>,
    action: GetCIS300Details
  ) {
    return this.cISService.getCIS300Details(action.id).pipe(
      tap((res) => {
        patchState({
          CIS300Details: res,
        });
      })
    );
  }

  @Action(GetSubContractorDetailsList)
  getSubContractorDetailsList(
    { patchState }: StateContext<CISStateInfo>,
    action: GetSubContractorDetailsList
  ) {
    return this.cISService
      .getSubContractorDetailsList(action.queryParams, action.cisReturnId)
      .pipe(
        tap((res) => {
          patchState({
            cisReturnData: res,
          });
        })
      );
  }

  @Action(DeleteCIS300)
  deleteCIS300(
    { getState, patchState }: StateContext<CISStateInfo>,
    action: DeleteCIS300
  ) {
    return this.cISService.deleteCIS300(action.cis300Ids).pipe(
      tap((res) => {
        const state = getState();

        const filteredCIS300 = state.cis300.filter(
          (item) =>
            !action.cis300Ids?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.cis300Ids?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          cis300: filteredCIS300,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(SaveContratorSetting)
  saveContratorSetting(
    { patchState }: StateContext<CISStateInfo>,
    action: SaveContratorSetting
  ) {
    return this.cISService
      .saveContratorSetting(action.contractorSettingsData)
      .pipe(
        tap((res) => {
          patchState({
            isSuccess: res,
          });
        })
      );
  }

  @Action(GetNextPeriod)
  getNextPeriod({ patchState }: StateContext<CISStateInfo>) {
    return this.cISService.getNextPeriod().pipe(
      tap((res) => {
        patchState({
          nextPeriod: res,
        });
      })
    );
  }

  @Action(GetContratorSetting)
  getContratorSetting(
    { patchState }: StateContext<CISStateInfo>,
    action: GetContratorSetting
  ) {
    return this.cISService.getContratorSetting().pipe(
      tap((res) => {
        patchState({
          contratorSettingData: res,
        });
      })
    );
  }

  @Action(ExportCisReturn, { cancelUncompleted: true })
  exportCisReturn(
    { patchState }: StateContext<CISStateInfo>,
    action: ExportCisReturn
  ) {
    let isExport = false;
    return this.cISService.exportCisReturn(action.ids).pipe(
      tap((res) => {
        isExport = true;
        patchState({
          isExport,
        });
      })
    );
  }

  @Action(ExportSubContractorReturn, { cancelUncompleted: true })
  exportSubContractorReturn(
    { patchState }: StateContext<CISStateInfo>,
    action: ExportSubContractorReturn
  ) {
    let isExport = false;
    return this.cISService
      .exportSubContractorReturn(
        action.cisReturnId,
        action.contactId,
        action.cisReturnDetailId
      )
      .pipe(
        tap((res) => {
          isExport = true;
          patchState({
            isExport,
          });
        })
      );
  }

  @Action(GetCISMonthlyReturn)
  getCISMonthlyReturn(
    { patchState }: StateContext<CISStateInfo>,
    action: GetCISMonthlyReturn
  ) {
    return this.cISService.getCISMonthlyReturn(action.id).pipe(
      tap((res) => {
        patchState({
          cisMonthlyReturnData: res,
        });
      })
    );
  }

  @Action(SendEmailCis300Verification)
  sendEmailCis300Verification(
    { getState, patchState }: StateContext<CISStateInfo>,
    action: SendEmailCis300Verification
  ) {
    return this.cISService.sendEmailCis300Verification(
      action.attachmentParams,
      action.toMailId,
      action.ccMailId,
      action.bccMailId,
      action.message,
      action.subject
    );
  }
  @Action(GetHMRCCredentials)
  getHMRCCredentails(
    { patchState }: StateContext<CISStateInfo>,
    action: GetHMRCCredentials
  ) {
    return this.cISService.getHMRCCredentails().pipe(
      tap((res) => {
        patchState({
          hmrcCredentialsData: res,
        });
      })
    );
  }
}
