<div class="mat-dialog-wrapper" style="width: 600px">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">Rollback Depreciation</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onNoClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="mat-table-wrapper">
      <table
        mat-table
        [dataSource]="expandedCollapseDetailList"
        aria-describedby="RollBack-Data"
      >
        <ng-container matColumnDef="refNo">
          <th mat-header-cell *matHeaderCellDef>Ref. No.</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <span (click)="redirect(element.id, modules.Journals)">
              <a class="link fw-bold">
                {{ element.entryNumber }}
              </a>
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="depreciationDate">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element">
            <div>{{ element.depreciationDate | date: "dd-LLL-yyyy" }}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="depreciationAmount">
          <th mat-header-cell *matHeaderCellDef>Amount</th>
          <td mat-cell *matCellDef="let element">
            <span class="white-space-nowrap">
              £ {{ element.depreciation | numberPipe }}</span
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              *ngIf="element.canRollBack"
              mat-menu-item
              disableRipple
              title="RollBack"
              (click)="actionClick(element.id)"
            >
              <mat-icon fontSet="material-icons-outlined">undo </mat-icon>
            </button>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayAllocationRefundColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayAllocationRefundColumns"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell"
            [ngClass]="'text-align-center'"
            [attr.colspan]="displayAllocationRefundColumns.length"
          >
            <span *ngIf="expandedCollapseDetailList.length == 0"
              >No Records Found</span
            >
          </td>
        </tr>
      </table>
    </div>
  </mat-dialog-content>
</div>

<app-custom-notifications></app-custom-notifications>
