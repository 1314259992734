import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
  Stepper,
  VATReturnStatus,
} from '@app/core/Enum';
import {
  FinancialData,
  GlobalComponent,
  SubmitVATModel,
} from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  BridgingVatState,
  CommonState,
  CreateVat,
  CreateVATReturnStatus,
  GetVatDetails,
  GetVatReturnDate,
  MenuState,
  SaveBridgingVAT,
  SetBridgingVatId,
  SubmitVatState,
} from '@app/core/Store';

import {
  AddClosePopupComponent,
  AddSubmitVatComponent,
  VATDetailsComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-bridging-vat',
  templateUrl: './bridging-vat.component.html',
  styleUrls: ['./bridging-vat.component.scss'],
})
export class BridgingVATComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isFirstStepDone = false;
  isSecondStepDone = false;
  isThirdStepDone = false;
  capiumButtonShow = false;
  hmrcButtonShow = false;
  isDisable = true;

  moduleId = Modules.BridgingVAT;
  moduleName = ModuleName.BridgingVAT;
  stepperEnum = Stepper;
  vatReturnStatus = VATReturnStatus;
  submitToCapiumData: SubmitVATModel;
  bridgingVatId = Guid.EMPTY as unknown as Guid;
  bridgingVatData: SubmitVATModel;
  id = Guid.EMPTY as unknown as Guid;
  fileData: any;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddSubmitVatComponent, { static: false })
  submitVatDetails;

  @ViewChild(VATDetailsComponent, { static: false })
  vatDetails;

  @ViewChild('horizontalStepper') stepper: MatStepper;

  subscriptionRouting: Subscription;

  triggerEditData: Subject<any> = new Subject<any>();

  @Select(CommonState.periodicDate)
  periodicDate$: Observable<Array<FinancialData>>;
  isEdit = false;
  commonService: CommonService;
  store: Store;
  spinner: NgxSpinnerService;
  notifier: NotificationService;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  isTrailUser: boolean = false;
  _Activatedroute: ActivatedRoute;
  isSubmitHmrc:boolean=false;
  isDeclerationChecked:boolean=false;
  constructor(
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public cookieService: CookieService,
    private router: Router,

    private renderer: Renderer2,
    private injector: Injector,
    private globalComponent: GlobalComponent
  ) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.commonService = injector.get<CommonService>(CommonService);
    this.store = injector.get<Store>(Store);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.notifier = injector.get<NotificationService>(NotificationService);
  }

  ngOnInit(): void {
    this.spinner.hide();
    this.isTrailUser = this.globalComponent.getIsTrailUser();
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.isEdit = true;
          this.id = atob(params.get('id')!) as unknown as Guid;
          this.store.dispatch(new SetBridgingVatId(this.id));
          this.editVatDetails();

          if(params.get('isSubmit')){
            this.isSubmitHmrc=true;
            this.isDeclerationChecked=true;
            this.spinner.show();
            setTimeout(() => {
              this.redirectTab();
            },100);
          }else{
            this.isSubmitHmrc=false;
            this.isDeclerationChecked=false;
          }
        } else {
          this.getVatReturnDate();
        }
      }
    );
  }


  
  redirectTab():void{
    this.isSecondStepDone = true;
    this.capiumButtonShow = false;
    this.hmrcButtonShow=true
    this.stepper.linear = false;
    this.stepper.selectedIndex = 2;
      setTimeout(() => {
        this.stepper.linear = true;
        this.spinner.hide();
      });
  
  }
  
  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
  public editVatData:any;
  editVatDetails(): void {
    this.store.dispatch(new GetVatDetails(this.id)).subscribe((res) => {
      this.triggerEditData.next(res.submitVat.vatData);
      this.editVatData=res.submitVat.vatData;
    });
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.bridgingVatData = {
        id: this.id,
        fromDate: this.datepipe
          .transform(
            this.submitVatDetails.submitVatForm.controls.fromDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        toDate: this.datepipe
          .transform(
            this.submitVatDetails.submitVatForm.controls.toDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        description:
          this.submitVatDetails.submitVatForm.controls.description.value,
        isBridgingVAT: true,
        finalReturn:
          this.submitVatDetails.submitVatForm.controls.finalReturn.value,
          vatSchemeId:this.isEdit?this.editVatData?.vatSchemeId:this.vatReturnData.vatSchemeId,
          subReturns:[]
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onNextClick(isExit: boolean) {
    if (this.stepper.selectedIndex === 0) {
      if (this.submitVatDetails.submitVatForm.invalid) {
        this.commonService.addValidation(
          this.submitVatDetails.submitVatForm,
          this.renderer
        );
      } else {
        if (this.dataSubmit()) {
          this.store.dispatch(new CreateVat(this.bridgingVatData)).subscribe(
            (res) => {
              if (res !== undefined) {
                if (!isExit) {
                  this.cancelClick(isExit);
                } else {
                  this.isFirstStepDone = true;
                  this.capiumButtonShow = true;
                  this.stepper.linear = false;
                  this.stepper.selectedIndex = 1;
                  setTimeout(() => {
                    this.stepper.linear = true;
                  });
                }
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
               
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
               
            }
          );
        }
      }
    } else if (this.stepper.selectedIndex === 1) {
      this.isSecondStepDone = true;
      this.capiumButtonShow = true;
      this.stepper.linear = false;
      this.stepper.selectedIndex = 2;
      setTimeout(() => {
        this.stepper.linear = true;
      });
    }
  }

  fileDataSubmit() {
    this.spinner.show();
    try {
      this.fileData = {
        file:
          this.vatDetails.fileUploadResponse === undefined
            ? null
            : this.vatDetails.fileUploadResponse[0].fileUrl,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  dataSubmitToCapium(): boolean {
    this.spinner.show();
    const details = this.store.selectSnapshot(BridgingVatState.getImportData);
    this.id = this.store.selectSnapshot(SubmitVatState.vatDataId);
    try {
      this.submitToCapiumData = {
        id: this.id,
        vatDueSales: details.vatDueSales,
        vatDueAcquisitions: details.vatDueAcquisitions,
        totalVatDue: details.totalVatDue,
        vatReclaimedCurrPeriod: details.vatReclaimedCurrPeriod,
        netVatDue: details.netVatDue,
        totalValueSalesExVAT: details.totalValueSalesExVAT,
        totalValuePurchasesExVAT: details.totalValuePurchasesExVAT,
        totalValueGoodsSuppliedExVAT: details.totalValueGoodsSuppliedExVAT,
        totalAcquisitionsExVAT: details.totalAcquisitionsExVAT,
        isBridgingVAT: true,
        fileURL: details.fileURL,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  submitToCapium(): void {
    if (this.dataSubmitToCapium()) {
      this.store
        .dispatch(new SaveBridgingVAT(this.submitToCapiumData))
        .pipe()
        .subscribe(
          (res) => {
            if (res !== undefined) {
              this.onSave(this.vatReturnStatus.SubmitToCapium);
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.message);
          }
        );
    }
  }

  onSave(vatReturnStatus) {
    this.id = this.store.selectSnapshot(BridgingVatState.vatDataId);
    this.store
      .dispatch(new CreateVATReturnStatus(vatReturnStatus, this.id))
      .subscribe(
        (res) => {
          if (res.submitVat.vatReturnStatusId) {
            if (vatReturnStatus === VATReturnStatus.SubmitToCapium) {
              this.isSecondStepDone = true;
              this.capiumButtonShow = false;
              this.hmrcButtonShow = true;
              this.stepper.linear = false;
              this.stepper.selectedIndex = 2;
              setTimeout(() => {
                this.stepper.linear = true;
              });
              this.onCapiuamSuccess();
            } else {
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
              this.router.navigate([RoutingPath.BridgingVAT]);
            }
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        },
        (err) => {
          this.commonService.onFailure(err.message);
        }
      );
  }

  cancelClick(isCancelClick: boolean) {
    if (!isCancelClick) {
      this.router.navigate([RoutingPath.BridgingVAT]);
    }
  }

  onPrevClick(stepper) {
    this.id = this.store.selectSnapshot(SubmitVatState.vatDataId);
    this.capiumButtonShow = true;
    if (this.id) {
      this.editVatDetails();
    }
    this.isSecondStepDone = false;
    stepper.previous();
  }

  onCheckBoxChanges(event: any): void {
    if (event.checked) {
      this.isDisable = false;
    } else {
      this.isDisable = true;
    }
  }

  onCapiuamSuccess(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.capiumSaveMessage
    );
  }
  vatReturnData:any;
  async getVatReturnDate(): Promise<void> {
    await this.store
      .dispatch(new GetVatReturnDate())
      .toPromise()
      .then((res) => {
        this.submitVatDetails.submitVatForm.controls.fromDate.setValue(
          res.submitVat.vatReturnDate.fromDate
        );
        this.submitVatDetails.submitVatForm.controls.toDate.setValue(
          res.submitVat.vatReturnDate.toDate
        );

        this.vatReturnData=res.submitVat.vatReturnDate;
      });
  }
}
