import { Guid } from '@syncfusion/ej2-pdf-export';

export class DefaultSettings {
  id: Guid;
  userId: Guid;
  defaultRows: number;
  defaultTab: number;
  defaultMonth: number;
  isAutoRecall: boolean;
}
export class LogoOnReports {
  reportLogoEnabled: boolean;
}
