<div
  class="mt-1"
  *ngIf="
    moduleId !== moduleEnum.Invoices &&
    moduleId !== moduleEnum.RecurringInvoice &&
    moduleId !== moduleEnum.Purchase &&
    moduleId !== moduleEnum.Quotation &&
    moduleId !== moduleEnum.FixedAssets &&
    moduleId !== moduleEnum.RecurringPurchase
  "
>
  <p class="fw-bold mr-1 mt-0 text-align-right">
    Total
    <span class="fw-normal ml-1"
      >{{ currencySymbol ?? "£" }} {{ total | numberPipe }}</span
    >
  </p>
  <p class="fw-bold mr-1 text-align-right">
    Balance Due
    <span class="fw-normal ml-1"
      >{{ currencySymbol ?? "£" }} {{ balanceAmount | numberPipe }}</span
    >
  </p>
</div>
<div
  class="mt-1"
  *ngIf="
    moduleId === moduleEnum.Invoices ||
    moduleId === moduleEnum.RecurringInvoice ||
    moduleId === moduleEnum.Purchase ||
    moduleId === moduleEnum.RecurringPurchase ||
    moduleId === moduleEnum.Quotation ||
    moduleId === moduleEnum.FixedAssets
  "
>
  <p class="fw-bold mr-1 mt-0 text-align-right">
    Subtotal
    <span class="fw-normal ml-1"
      >{{ currencySymbol ?? "£" }} {{ total | numberPipe }}</span
    >
  </p>

  <div class="d-flex align-items-center justify-content-end gap-10 mb-10 mr-1">
    <ng-select
      class="w-170"
      #selectDiscountType
      [disableOptionCentering]="true"
      (focus)="selectDiscountType.open()"
      (valueChange)="selectDiscountType.close()"
      [disableOptionCentering]="true"
      [(ngModel)]="discountType"
      dropdownPosition="top"
      panelClass="mat-select-position"
      required
      (change)="onDiscountChange()"
      [disabled]="(+total === 0)"
    >
      <ng-option *ngFor="let dt of discountTypeList" [value]="dt.id">
        <div title="{{ dt.name }}">
          {{ dt.name }}
        </div>
      </ng-option>
    </ng-select>
    <mat-form-field
      class="w-80"
      *ngIf="discountType === discountTypeEnum.Value"
    >
      <input
        allow_10_2_Decimal
        matInput
        autocomplete="off"
        [(ngModel)]="discountValue"
        [disabled]="(+total === 0) "
        (change)="onDiscountChange()"
        
      />
    </mat-form-field>
    <mat-form-field
      class="w-80"
      *ngIf="discountType === discountTypeEnum.Percentage"
    >
      <input
        zeroNotAllowed
        appPercentageDirective
        matInput
        autocomplete="off"
        [(ngModel)]="discountValue"
        [disabled]="(total === 0)  "
        (change)="onDiscountChange()"
      />
    </mat-form-field>
    <span
      class="fw-normal ml-5"
      *ngIf="discountedValue === 0 || discountedValue === ''"
      >{{ currencySymbol ?? "£" }} {{ discountedValue | numberPipe }}</span
    >
    <span
      class="fw-normal ml-5"
      *ngIf="discountedValue !== 0 && discountedValue !== ''"
      >{{ currencySymbol ?? "£" }} ({{ discountedValue | numberPipe }})</span
    >
  </div>

  <p
    class="fw-bold mr-1 mt-0 text-align-right"
    *ngIf="
      (moduleId === moduleEnum.Purchase ||
        moduleId === moduleEnum.RecurringPurchase ||
        moduleId === moduleEnum.FixedAssets) &&
      isPostponedVat
    "
  >
    Postponed VAT Amount
    <span class="fw-normal ml-1"
      >{{ currencySymbol ?? "£" }} {{ postponedVatAmount | numberPipe }}</span
    >
  </p>

  <p class="fw-bold mr-1 mt-0 text-align-right">
    Total
    <span class="fw-normal ml-1"
      >{{ currencySymbol ?? "£" }} {{ grossTotal | numberPipe }}</span
    >
  </p>
  <p
    class="fw-bold mr-1 text-align-right"
    *ngIf="
      moduleId !== moduleEnum.RecurringInvoice &&
      moduleId !== moduleEnum.RecurringPurchase &&
      moduleId !== moduleEnum.Quotation
    "
  >
    Balance Due
    <span class="fw-normal ml-1"
      >{{ currencySymbol ?? "£" }} {{ balanceAmount | numberPipe }}</span
    >
  </p>
</div>
