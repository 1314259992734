import {
  ComparitiveReportParamerters,
  MainListParameters,
} from '@app/core/Models';

export class GetProfitLossReport {
  static readonly type = '[REPORTLIST] Get Profit Loss Report ';

  constructor(public queryParams: MainListParameters) {}
}

export class GetProfitLossComparisonReport {
  static readonly type = '[REPORTLIST] Get Profit Loss Comparison Report ';

  constructor(public queryParams: MainListParameters) {}
}

export class GetBalanceSheetReport {
  static readonly type = '[REPORTLIST] Get Balance Sheet Report ';

  constructor(public queryParams: MainListParameters) {}
}

export class GetCashFlow {
  static readonly type = '[REPORTLIST] Get Cash Flow';

  constructor(public queryParams: MainListParameters) {}
}

export class CashFlowTransaction {
  static readonly type = '[REPORTLIST] Cash Flow Transaction';

  constructor(public queryParams: any, public tagName: string) {}
}

export class ShareFile {
  static readonly type = '[Report] Share file';

  constructor(
    public attachmentParams: MainListParameters,
    public toMailId: string,
    public ccMailId: string,
    public bccMailId: string,
    public message: string,
    public subject: string
  ) {}
}

export class SendComparativeReportEmail {
  static readonly type = '[Report] Send Comparative Report Email';

  constructor(
    public attachmentParams: ComparitiveReportParamerters,
    public toMailId: string,
    public ccMailId: string,
    public bccMailId: string,
    public message: string,
    public subject: string
  ) {}
}
