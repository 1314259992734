import { Injectable } from '@angular/core';
import { NoteDataModel, SideListModel } from '@app/core/Models';
import { ScheduleService } from '@app/core/Services';
import { Action, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreNote,
  CreateScheduleNote,
  DeleteNote,
  GetDataByNoteId,
} from './schedules.action';

export class ScheduleStateInfo {
  isNoteAdded?: any;
  noteData?: NoteDataModel;
  note: Array<NoteDataModel>;
  sideListModel: Array<SideListModel>;
}

@State<ScheduleStateInfo>({
  name: 'scheduleNote',
  defaults: {
    isNoteAdded: false,
    note: [],
    sideListModel: [],
  },
})
@Injectable()
export class ScheduleNoteState {
  constructor(private scheduleService: ScheduleService) {}

  @Action(CreateScheduleNote)
  createScheduleNote(
    { getState, patchState }: StateContext<ScheduleStateInfo>,
    action: CreateScheduleNote
  ) {
    return this.scheduleService.createScheduleNote(action.scheduleNote).pipe(
      tap((res) => {
        patchState({
          isNoteAdded: res ? true : false,
        });
      })
    );
  }

  @Action(GetDataByNoteId)
  getDataByNoteId(
    { patchState }: StateContext<ScheduleStateInfo>,
    action: GetDataByNoteId
  ) {
    return this.scheduleService.getDataByNoteId(action.noteId).pipe(
      tap((res) => {
        patchState({
          noteData: res,
        });
      })
    );
  }

  @Action(DeleteNote)
  deleteNote(
    { getState, setState }: StateContext<ScheduleStateInfo>,
    action: DeleteNote
  ) {
    return this.scheduleService.deleteNote(action.noteIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredNote = state.note.filter(
          (item) =>
            !action.noteIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.noteIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        setState({
          ...state.note,
          note: filteredNote,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreNote)
  archiveAndRestoreNote(
    { getState }: StateContext<ScheduleStateInfo>,
    action: ArchiveAndRestoreNote
  ) {
    return this.scheduleService.archiveAndRestoreNote(
      action.noteIds,
      action.isArchive
    );
  }
}
