<div class="content-body content-body-scroll">
  <div class="content-header mb-1">
    <div class="content-header-input header-text d-flex justify-space-between">
      <div class="d-flex align-items-center gap-20">
        <p class="text-primary font-size-20 fw-bold m-0">
          {{ dateRange.startDate | date: "dd-LLL-yyyy" }} to
          {{ dateRange.endDate | date: "dd-LLL-yyyy" }}
        </p>
      </div>
      <div class="d-flex align-items-end gap-10">
        <div class="d-flex flex-column" *ngIf="branchList.length > 0">
          <ejs-multiselect
            id="multiselect-checkbox"
            #branch
            [dataSource]="branchList"
            [placeholder]="branchWaterMark"
            [fields]="branchFields"
            [mode]="branchmode"
            [popupHeight]="branchpopHeight"
            [showDropDownIcon]="true"
            showSelectAll="true"
            [(ngModel)]="selectedBranchValues"
            [filterBarPlaceholder]="branchfilterPlaceholder"
            (change)="onBranchMultiSelectChange($event)"
          ></ejs-multiselect>
        </div>
        <div class="d-flex flex-column" *ngIf="departmentList.length > 0">
          <ejs-multiselect
            id="multiselect-checkbox"
            #department
            [dataSource]="departmentList"
            [placeholder]="departmentWaterMark"
            [fields]="departmentFields"
            [mode]="departmentmode"
            [popupHeight]="departmentpopHeight"
            [showDropDownIcon]="true"
            showSelectAll="true"
            [filterBarPlaceholder]="departmentfilterPlaceholder"
            [(ngModel)]="selectedDepartmentValues"
            (change)="onDepartmentMultiSelectChange($event)"
          ></ejs-multiselect>
        </div>
        <div class="content-header m-0">
          <div class="year-picker">
            <div class="d-flex year-picker-content">
              <div>
                <mat-form-field appearance="fill" class="w-150">
                  <mat-select
                    panelClass="myPanelClass"
                    [disableOptionCentering]="true"
                    [(ngModel)]="selectedYearValue"
                    (selectionChange)="onSelectionChange($event.value)"
                  >
                    <mat-option
                      *ngFor="let year of selectYear | useEnumValues"
                      [value]="year.key"
                      title="{{ year.value }}"
                    >
                      {{ year.value }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <form *ngIf="showCalender" [formGroup]="rangeFormGroup">
                <mat-form-field appearance="standard">
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      matInput
                      #start
                      (dateChange)="onSelectionChange(7)"
                      formControlName="start"
                    />
                    <input
                      matEndDate
                      matInput
                      #end
                      formControlName="end"
                      (dateChange)="onSelectionChange(7)"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matPrefix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chart-wrapper d-flex gap-20 mt-2">
    <div class="card">
      <div class="d-flex align-items-center justify-space-between">
        <h3 class="card-title mb-1">Income</h3>
      </div>
      <app-business-snapshot-pie-chart
        [getModuleId]="moduleId"
        [triggerIncomeData]="triggerIncomeData"
      ></app-business-snapshot-pie-chart>
    </div>
    <div class="card">
      <div class="d-flex align-items-center justify-space-between">
        <h3 class="card-title mb-1">Expenses</h3>
      </div>
      <app-business-snapshot-pie-chart
        [getModuleId]="moduleId"
        [triggerExpenseData]="triggerExpenseData"
      ></app-business-snapshot-pie-chart>
    </div>
  </div>
  <div class="chart-wrapper d-flex gap-20 mt-2">
    <div class="card">
      <div class="d-flex align-items-center justify-space-between">
        <h3 class="card-title mb-1">Previous Year's Income Comparison</h3>
      </div>
      <app-dashboard-cashflow-chart-v1
        [getModuleId]="moduleId"
        [triggerIncomeComparison]="triggerIncomeComparison"
      ></app-dashboard-cashflow-chart-v1>
    </div>
    <div class="card">
      <div class="d-flex align-items-center justify-space-between">
        <h3 class="card-title">Previous Year's Expenses Comparison</h3>
      </div>
      <app-dashboard-cashflow-chart-v1
        [getModuleId]="moduleId"
        [triggerExpenseComparison]="triggerExpenseComparison"
      ></app-dashboard-cashflow-chart-v1>
    </div>
  </div>
  <div class="chart-wrapper d-flex gap-20 mt-2">
    <div class="card">
      <div
        class="d-flex align-items-center justify-space-between hr-bottom pb-10"
      >
        <h3 class="card-title mb-1">Debtors</h3>
      </div>
      <div class="chart-list-wrapper">
        <div class="chart-list-title pr-10">
          <div class="d-flex align-items-center justify-space-between">
            <span>Customer</span>
            <span>£ Amount Due</span>
          </div>
        </div>
        <div class="chart-list-details">
          <div class="chart-list" *ngFor="let data of debitorsData">
            <div class="d-flex align-items-center justify-space-between">
              <span class="clamp w-200">{{ data[1] }}</span>
              <span class="fw-bold">£ {{ data[2] | numberPipe }}</span>
            </div>
          </div>
        </div>
        <div class="norecord-chart" *ngIf="debitorsData?.length === 0">
          <p class="pt-10">No data available yet!</p>
        </div>
      </div>
    </div>
    <div class="card">
      <div
        class="d-flex align-items-center justify-space-between hr-bottom pb-10"
      >
        <h3 class="card-title mb-1">Creditors</h3>
      </div>
      <div class="chart-list-wrapper">
        <div class="chart-list-title pr-10">
          <div class="d-flex align-items-center justify-space-between">
            <span>Supplier</span>
            <span>£ Amount Due</span>
          </div>
        </div>
        <div class="chart-list-details">
          <div class="chart-list" *ngFor="let data of creditorsData">
            <div class="d-flex align-items-center justify-space-between">
              <span class="clamp w-200">{{ data[1] }}</span>
              <span class="fw-bold">£ {{ data[2] | numberPipe }}</span>
            </div>
          </div>
        </div>
        <div class="norecord-chart" *ngIf="creditorsData?.length === 0">
          <p class="pt-10">No data available yet!</p>
        </div>
      </div>
    </div>
  </div>
</div>
