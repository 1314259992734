import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExportFormat, ModuleName, Modules } from '@app/core/Enum';
import { ExportType } from '@app/core/Models';
import { MenuState } from '@app/core/Store';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.scss'],
})
export class CardFooterComponent {
  @Input()
  totalSelectedRecords: number;

  @Input() triggerSelectedRowData: Observable<any>;

  @Input()
  getModuleId: number;

  moduleId = Modules;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  exportType = ExportType;
  moduleName = ModuleName;

  @Output()
  readonly deleteClick = new EventEmitter<any>();

  @Output()
  readonly exportClick = new EventEmitter<any>();

  @Output()
  readonly copyClick = new EventEmitter<any>();

  @Output()
  readonly cancelSelectionClick = new EventEmitter<any>();

  @Output()
  readonly printClick = new EventEmitter<any>();

  @Output()
  readonly archiveAndRestoreClick = new EventEmitter<any>();

  @Output()
  readonly emailClick = new EventEmitter<any>();

  exportFormat = ExportFormat;

  disableActiveAction: boolean;
  disableInActiveAction: boolean;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  ngOnInit(): void {
    this.triggerSelectedRowData?.subscribe((data) => {
      if (data.length > 0) {
        this.disableActiveAction =
          data.includes('Active') && !data.includes('Inactive');
        this.disableInActiveAction =
          data.includes('Inactive') && !data.includes('Active');
      }
    });
  }

  export(format: number): void {
    this.exportClick.emit(format);
  }

  delete(): void {
    this.deleteClick.emit();
  }

  copy(): void {
    this.copyClick.emit();
  }

  cancelSelection(): void {
    this.cancelSelectionClick.emit();
  }

  print(): void {
    this.printClick.emit();
  }

  archiveAndRestore(isArchive: boolean): void {
    this.archiveAndRestoreClick.emit(isArchive);
  }

  email(): void {
    this.emailClick.emit();
  }
}
