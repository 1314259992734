<div class="content-body content-body-scroll" *ngIf="isloaded">
  <div class="card-wrapper">
    <div class="d-flex align-items-center justify-space-between gap-20">
      <mat-card>
        <mat-card-title>
          <div class="d-flex justify-space-between">
            <div class="icon">
              <mat-icon class="material-icons-outlined"
                >currency_pound</mat-icon
              >
            </div>
            <div>
              <p class="title">Total Sales</p>
              <p class="amount">£ {{ totalIncomeAmount }}</p>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="action d-flex justify-space-between">
            <div class="action-title">Receivable</div>
            <div class="action-amount">£ {{ incomeAmount | numberPipe }}</div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-title>
          <div class="d-flex justify-space-between">
            <div class="icon">
              <mat-icon class="material-icons-outlined">shopping_bag</mat-icon>
            </div>
            <div>
              <p class="title">Total Purchase</p>
              <p class="amount">£ {{ totalExpenseAmount | numberPipe }}</p>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="action d-flex justify-space-between">
            <div class="action-title">Payable</div>
            <div class="action-amount">£ {{ expenseAmount | numberPipe }}</div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-title>
          <div class="d-flex justify-space-between">
            <div class="icon">
              <mat-icon class="material-icons-outlined"
                >account_balance</mat-icon
              >
            </div>
            <div>
              <p class="title">Bank Balance</p>
              <p class="amount">£ {{ bank | numberPipe }}</p>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="action d-flex justify-space-between">
            <div class="action-title">
              <a href="javscript:void(0)" class="link">View Bank Book</a>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-title>
          <div class="d-flex justify-space-between">
            <div class="icon">
              <mat-icon class="material-icons-outlined"
                >account_balance_wallet</mat-icon
              >
            </div>
            <div>
              <p class="title">Cash In Hand</p>
              <p class="amount">£ {{ cashInHand | numberPipe }}</p>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class="action d-flex justify-space-between">
            <div class="action-title">
              <a href="javscript:void(0)" class="link">View Cash Book</a>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="chart-wrapper d-flex gap-20 mt-2">
    <div class="card">
      <div class="d-flex align-items-center justify-space-between">
        <h3 class="card-title mb-1">Sales</h3>
      </div>
      <app-overview-incomes-chart [triggerIncomeData]="triggerIncomeData">
      </app-overview-incomes-chart>
    </div>
    <div class="card">
      <div class="d-flex align-items-center justify-space-between">
        <h3 class="card-title">Expenses</h3>
      </div>
      <app-overview-incomes-chart [triggerExpensesData]="triggerExpensesData">
      </app-overview-incomes-chart>
    </div>
  </div>
  <div class="chart-wrapper d-flex gap-20 mt-2 mb-2">
    <div class="card">
      <h3 class="card-title mb-1">Sales v/s Purchase</h3>
      <app-dashboard-income-expenses-chart
        [triggerIncomeVsExpensesData]="triggerIncomeVsExpensesData"
      >
      </app-dashboard-income-expenses-chart>
    </div>
    <div class="card">
      <h3 class="card-title mb-1">
        Ageing Summary <span class="material-icons-outlined">help_outline</span>
      </h3>
      <div class="chart-tab">
        <mat-tab-group
          mat-align-tabs="start"
          [(selectedIndex)]="selectedTab"
          (selectedTabChange)="tabClick($event.index)"
          disableRipple
        >
          <mat-tab label="Receivable">
            <app-overview-summary-chart
              [triggerSummaryIncomeData]="triggerSummaryIncomeData"
            ></app-overview-summary-chart>
          </mat-tab>
          <mat-tab label="Payable">
            <app-overview-summary-chart
              [triggerSummaryExpensesData]="triggerSummaryExpensesData"
            ></app-overview-summary-chart>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
  <div class="chart-wrapper d-flex gap-20">
    <div class="card">
      <div class="d-flex align-items-center justify-space-between">
        <h3 class="card-title mb-1">Cash Flow</h3>
      </div>
      <app-dashboard-cashflow-chart
        [triggerCashFlow]="triggerCashFlow"
      ></app-dashboard-cashflow-chart>
      <div class="d-flex align-items-center justify-space-between hidden">
        <div class="flow-box">
          <p class="flow-title">Sales as on 01/01/2021</p>
          <p class="flow-amount">£ 4500.00</p>
        </div>
        <div class="flow-box">
          <p class="flow-title success">Incoming</p>
          <p class="flow-amount">£ 2400.00</p>
        </div>
        <div class="flow-box">
          <p class="flow-title danger">Outgoing</p>
          <p class="flow-amount">£ 2100.00</p>
        </div>
        <div class="flow-box">
          <p class="flow-title sky">Sales as on 31/12/2022</p>
          <p class="flow-amount">£ 4500.00</p>
        </div>
      </div>
    </div>
  </div>
</div>
