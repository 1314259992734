import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Modules } from '@app/core/Enum';
import { AccountDetailViewModel } from '@app/core/Models';
import { MenuState, ReportDetailView } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-report-header-details',
  templateUrl: './report-header-details.component.html',
  styleUrls: ['./report-header-details.component.scss'],
})
export class ReportHeaderDetailsComponent implements OnInit {
  triggerBillOutstanding: Subject<any> = new Subject<any>();

  detailViewData: AccountDetailViewModel;

  @Input()
  triggerCustomerId: Observable<any>;

  @Output()
  readonly triggerOnCancel = new EventEmitter<any>();

  customerId: Guid;
  moduleId = 0;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;
  moduleEnum = Modules;
  constructor(private store: Store, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.triggerCustomerId.subscribe((data) => {
      this.customerId = data;
      this.moduleId = data.moduleId;
      this.getDetailView(data);
    });
  }

  getDetailView(id: any): void {
    this.spinner.show();
    this.store.dispatch(new ReportDetailView(id.id)).subscribe((res) => {
      this.spinner.hide();
      this.detailViewData = res.common.detailView;
      this.triggerBillOutstanding.next(
        this.detailViewData.accountFlowDetailModel
      );
    });
  }

  cancelClick(): void {
    this.triggerOnCancel.emit(true);
  }
}
