<div class="content-body content-body-scroll">
  <div class="email-template mb-4">
    <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
      <mat-icon class="material-icons-outlined">error_outline</mat-icon>
      {{ commonNotificationText.ViewOnlyAccessMessage }}
    </div>
    <div class="accordion-toggle">
      <mat-icon
        (click)="toggleAccordion(); accordion.openAll()"
        *ngIf="isExpandAll"
        >keyboard_double_arrow_down</mat-icon
      >
      <mat-icon
        (click)="toggleAccordion(); accordion.closeAll()"
        *ngIf="!isExpandAll"
        >keyboard_double_arrow_up</mat-icon
      >
    </div>
    <mat-accordion [multi]="true">
      <mat-expansion-panel
        [expanded]="true"
        *ngFor="
          let template of emailTemplate;
          let templateIndex = index;
          first as isFirst
        "
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="d-flex flex-column">
              <span>{{ template.templateTypeName }}</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div class="email-template-wrapper content-body pt-0">
            <div class="vertical-box p-0">
              <div
                class="vertical-box-content"
                id="{{ templateIndex }}"
                *ngFor="
                  let items of template.templateDetails;
                  let templateIndex1 = index
                "
              >
                <h2 class="common-title fw-bold font-size-16">
                  {{ items.displayName }}
                </h2>
                <div class="form-field mb-1">
                  <p class="pr-1">Subject</p>

                  <div
                    class="custom-textarea input-container"
                    [attr.contenteditable]="!isPermission"
                    [(ngModel)]="items.subject!"
                    (blur)="onMessageChange($event, items, true)"
                    [innerHTML]="
                      highlightValue(items.subject, items.suggestedLabels)
                    "
                    [mentionConfig]="{
                      triggerChar: ' ',
                      maxItems: 10,
                      labelKey: 'name'
                    }"
                    required
                    [mention]="suggestedLabel"
                    ngDefaultControl
                  ></div>
                </div>

                <div class="form-field align-items-start mb-1">
                  <p class="pr-1">Message</p>

                  <div
                    class="custom-textarea textarea-container"
                    [attr.contenteditable]="!isPermission"
                    [(ngModel)]="items.templateHTML!"
                    (blur)="onMessageChange($event, items, false)"
                    [innerHTML]="
                      highlightValue(items.templateHTML, items.suggestedLabels)
                    "
                    [mentionConfig]="{
                      triggerChar: ' ',
                      maxItems: 10,
                      labelKey: 'name'
                    }"
                    [mention]="suggestedLabel"
                    ngDefaultControl
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<app-buttons
  *ngIf="!(isViewPermission$ | async)"
  [getModuleId]="moduleId"
  (triggerOnSaveClick)="onSave($event)"
  (triggerOnCancelClick)="onCancel()"
  [saveButtonShow]="true"
>
</app-buttons>
