import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationTextMessage, RoutingPath } from '@app/core/Enum';
import { LogoOnReports } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { GetLogoOnReportsData, SaveLogoOnReports } from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-report-settings',
  templateUrl: './report-settings.component.html',
  styleUrls: ['./report-settings.component.scss'],
})
export class ReportSettingsComponent implements OnInit {
  isEnableReportLogo = false;
  logoOnReportsParams: LogoOnReports;
  logoOnReports: LogoOnReports;
  constructor(
    private store: Store,
    public dialog: MatDialog,
    public commonService: CommonService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getLogoOnReportsData();
  }
  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.logoOnReportsParams = {
        reportLogoEnabled: this.isEnableReportLogo,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }
  onSave(isExit: boolean): void {
    if (this.dataSubmit()) {
      this.store
        .dispatch(new SaveLogoOnReports(this.logoOnReportsParams))
        .subscribe(
          (res) => {
            if (res !== undefined) {
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    }
  }
  getLogoOnReportsData(): void {
    this.store
      .dispatch(new GetLogoOnReportsData())
      .pipe(
        tap((res) => {
          this.logoOnReports = res.settings.logoOnReportsData;
          if (this.logoOnReports != null) {
            this.isEnableReportLogo = this.logoOnReports.reportLogoEnabled;
          }
        })
      )
      .subscribe();
  }
}
