import { Injectable } from '@angular/core';
import { DataSecurityService } from '@app/core/Services';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { GetDataSecurityDetails, SaveDataSecurity } from './datasecurity.action';
import { Guid } from 'guid-typescript';
import { DataSecurityModel } from '@app/core/Models';

export class DataSecurityStateInfo {
  dataSecurityId?: Guid;
  dataSecurityData?: DataSecurityModel;
}

@State<DataSecurityStateInfo>({
  name: 'datasecurity',
})
@Injectable()
export class DataSecurityState {
  constructor(private datasecurityService: DataSecurityService) {}

  @Action(SaveDataSecurity)
  createQuickEntry(
    { patchState }: StateContext<DataSecurityStateInfo>,
    action: SaveDataSecurity
  ) {
    return this.datasecurityService.saveDataSecurity(action.dataSecurity).pipe(
      tap((res) => {
        patchState({
          dataSecurityId: res,
        });
      })
    );
  }

  @Action(GetDataSecurityDetails)
  getDataSecurityDetails(
    { patchState }: StateContext<DataSecurityStateInfo>,
    action: GetDataSecurityDetails
  ) {
    return this.datasecurityService
      .getDataSecurityDetails(action.companyId)
      .pipe(
        tap((res) => {
          patchState({
            dataSecurityData: res,
          });
        })
      );
  }
}
