<div
  id="chart"
  *ngIf="
    businessSnapshotPieChartComponent &&
    businessSnapshotPieChartComponent?.series?.length !== 0 &&
    isloadData
  "
>
  <apx-chart
    [series]="businessSnapshotPieChartComponent.series!"
    [chart]="businessSnapshotPieChartComponent.chart!"
    [labels]="businessSnapshotPieChartComponent.labels!"
    [responsive]="businessSnapshotPieChartComponent.responsive!"
    [fill]="businessSnapshotPieChartComponent.fill!"
    [dataLabels]="businessSnapshotPieChartComponent.dataLabels!"
    [legend]="businessSnapshotPieChartComponent.legend!"
    [tooltip]="businessSnapshotPieChartComponent.tooltip!"
  ></apx-chart>
</div>

<div
  class="norecord-chart"
  *ngIf="businessSnapshotPieChartComponent?.series?.length === 0 && isloadData"
>
  <img src="assets/images/pia-icon.svg" alt="Pie Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
