import { Guid } from 'guid-typescript';

export class CISInvoiceDetailsModel {
  id: Guid;
  companyId?: Guid;
  branchId?: Guid;
  departmentId?: Guid;
  referenceId?: Guid;
  description: string;
  cisSupplyTypeId?: number;
  supplyType?: string;
  amount: number;
  vatRateId?: Guid;
  vatRateName?: string;
  vatAmount: number;
  totalAmount: number;
  isVatReturnIncluded?: boolean;
  vatReturnId?: Guid;
  foreignVatAmount?: number;
  foreignAmount?: number;
  foreignTotalAmount?: number;
  bookAccountId?:Guid;
}
