<div class="mat-dialog-wrapper">
  <h2 mat-dialog-title>Calculate Amount</h2>
  <mat-dialog-content>
    <form [formGroup]="budgetingCalculationForm">
      <div class="form-field wp-100">
        <ng-select
          formControlName="calculationType"
          panelClass="myPanelClass"
          [disableOptionCentering]="true"
        >
          <ng-option
            *ngFor="let budget of budgetingTypeList"
            [value]="budget.id"
            title="{{ budget.name }}"
          >
            {{ budget.name }}</ng-option
          >
        </ng-select>
      </div>
      <div class="form-field wp-100">
        <p
          *ngIf="budgetingCalculationForm.controls.calculationType.value !== 3"
        >
          Amount
        </p>
        <p
          *ngIf="budgetingCalculationForm.controls.calculationType.value === 3"
        >
          Percentage
        </p>
        <mat-form-field appearance="fill">
          <input
            allowDecimal
            matInput
            formControlName="amount"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength10 }}"
          />
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave()"
    >
      Save
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onCancel()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
