import { Component, Injector, OnInit } from '@angular/core';
import {
  GeneralDashboardBarChart,
  GeneralDashboardPieChartData,
} from '@app/core/Models/general-dashboard/general-dashboard';
import { GetGeneralDashboardChartData } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-general-dashboard',
  templateUrl: './general-dashboard.component.html',
  styleUrls: ['./general-dashboard.component.scss'],
})
export class GeneralDashboardComponent implements OnInit {
  generalDashboardPieChartData: GeneralDashboardPieChartData;
  generalDashboardBarChartData: GeneralDashboardBarChart;
  store: Store;
  selectedTab = 0;
  month = 0;
  year = 2024;

  constructor(private injector: Injector) {
    this.store = injector.get<Store>(Store);
  }

  ngOnInit(): void {
    this.getGeneralDashboardChartsData();
  }

  public triggermonth(data: any): void {
    this.month = data;
    this.getGeneralDashboardChartsData();
  }
  getGeneralDashboardChartsData(): void {
    this.store
      .dispatch(
        new GetGeneralDashboardChartData(this.getDashboardChartsParamaters())
      )
      .pipe(
        tap((res) => {
          this.generalDashboardPieChartData =
            res.generalDashboard.dashBoardPieChartData;
          this.generalDashboardBarChartData =
            res.generalDashboard.dashBoardBarChartData;
        })
      )
      .subscribe();
  }
  getDashboardChartsParamaters(): any {
    const queryParams = {
      month: this.month,
      year: this.year,
    };

    return queryParams;
  }
  onTabClick(event: any): void {
    this.selectedTab = event;
  }
  onYearChange(): void {
    this.getGeneralDashboardChartsData();
  }
}
