import { Component, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Modules } from '@app/core/Enum';
import { ReportListWithActionsComponent } from '@app/modules/common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-fixed-assest-dispose-active',
  templateUrl: './fixed-assest-dispose-active.component.html',
  styleUrls: ['./fixed-assest-dispose-active.component.scss'],
})
export class FixedAssestDisposeActiveComponent {
  selectedTab = 0;
  modulesEnum = Modules;
  @ViewChild(ReportListWithActionsComponent, { static: true })
  reportListWithActions;
  isExpandAll = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  selectedModuleId = Modules.ActiveTangibleFixedAssets;
  triggerModuleId: BehaviorSubject<any> = new BehaviorSubject<any>(
    Modules.ActiveTangibleFixedAssets
  );

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onTabClick(event: any): void {
    this.selectedTab = event;
    if (this.selectedTab === 0) {
      this.selectedModuleId = Modules.ActiveTangibleFixedAssets;
      this.triggerModuleId.next(Modules.ActiveTangibleFixedAssets);
    } else {
      this.selectedModuleId = Modules.DisposeTangibleFixedAssets;
      this.triggerModuleId.next(Modules.DisposeTangibleFixedAssets);
    }
  }

  onAccordionClick(moduleId: number): void {
    this.selectedModuleId = moduleId;
    if (this.selectedTab === 0 || this.selectedTab === 1) {
      this.triggerModuleId.next(this.selectedModuleId);
    }
  }
}
