import { Guid } from 'guid-typescript';

export class Currency {
  id: number;
  name: string;
  code: string;
  country: string;
}

export class DefaultCurrency {
  id: number;
}
export class CurrencyList {
  currencyId: number;
}
export class CurrencyModel {
  currencyId: number;
  rate: number;
}
export class SaveCurrencyModel {
  id: Guid;
  currencyId: number;
  rate: number;
  companyId: Guid;
  userId: Guid;
}
