import {
  Component,
  Inject,
  Injector,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatTableDataSource } from '@angular/material/table';
import { MaxLength, NotificationTextMessage } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import {
  GetAllBranchList,
  GetAllDepartmentListByBranch,
  GetInventoryLossOfStock,
  GetTrackedProducts,
  MenuState,
  SaveInventoryLossOfStock,
} from '@app/core/Store';
import { TransactionsAttachmentComponent } from '@app/modules/transactions';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CleanAllLinesComponent } from '../clean-all-lines/clean-all-lines.component';

@Component({
  selector: 'app-loss-of-stock',
  templateUrl: './loss-of-stock.component.html',
  styleUrls: ['./loss-of-stock.component.scss'],
})
export class LossOfStockComponent implements OnInit {
  displayColumns: string[] = [
    'srNo',
    'product',
    'unitLost',
    'pricePerUnit',
    'amount',
    'closeButton',
  ];
  tableDataSource: MatTableDataSource<AbstractControl>;
  formLossOfStock: UntypedFormGroup;
  lossOfStockArray: any;
  maxLength = MaxLength;
  trackedProductList: any = [];
  totalLoss: any = 0;
  isExpandAll = false;
  lossOfStockFrom: FormGroup;
  branchList: any[] = [];
  departmentList: any[] = [];
  stockFrom: FormGroup;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;
  productItems: any = [];
  stockData: any;
  stockId = Guid.EMPTY as unknown as Guid;
  store: Store;
  commonService: CommonService;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  isPermission: boolean = false;
  constructor(
    public dialog: MatDialogRef<LossOfStockComponent>,
    private formBuilder: UntypedFormBuilder,
    private renderer: Renderer2,
    public dialogClose: MatDialog,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.stockId = this.data.id;
    this.isViewPermission$.subscribe((res) => {
      this.isPermission = res;
    });
    this.getBranchList(true);
    this.getTrackedProducts();
    this.setStockForm();
    this.setForm();
    this.setLossOfStockForm(true);

    if (this.stockId !== (Guid.EMPTY as unknown as Guid)) {
      setTimeout(() => {
        this.editLossOfStock();
      }, 1000);
    }
  }

  editLossOfStock(): void {
    this.store
      .dispatch(new GetInventoryLossOfStock(this.stockId))
      .subscribe((res) => {
        let data = res.inventoryLossOfStock.inventoryLossOfStockData;
        this.setData(data);
      });
  }

  setData(data): void {
    this.stockFrom.patchValue({
      notes: data.note,
      date: data.entryDate,
      branchId: data.branchId,
      departmentId: data.departmentId,
    });
    this.lossOfStockArray = this.formLossOfStock.get(
      'lossOfStockArray'
    ) as UntypedFormArray;

    this.lossOfStockArray.clear();

    data.items.forEach((item, i) => {
      this.lossOfStockArray.push(this.buildOrderItemsForm(item));
      this.onUnitChanges(i);
    });

    this.setDataSource(this.lossOfStockArray);
  }

  buildOrderItemsForm(item: any): FormGroup {
    const form = this.formBuilder.group({
      id: item.id,
      product: item.productId,
      unitLost: item.unitsLost,
      pricePerUnit: item.price,
      amount: 0,
    });
    return form;
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.productItems = [];
      this.lossOfStockArray?.getRawValue().forEach((x) => {
        this.productItems.push({
          id: x.id,
          productId: x.product,
          unitsLost: x.unitLost,
          price: x.pricePerUnit,
          amount: x.amount,
        });
      });
      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }
      this.stockData = {
        id: this.stockId,
        branchId:
          this.stockFrom.controls.branchId.value === ''
            ? null
            : this.stockFrom.controls.branchId.value,
        departmentId:
          this.stockFrom.controls.departmentId.value === ''
            ? null
            : this.stockFrom.controls.departmentId.value,
        note: this.stockFrom.controls.notes.value,
        entryDate: this.stockFrom.controls.date.value,
        items: this.productItems,
        totalAmount: this.totalLoss,
        attachment: filedata,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  validationOnUnitPrice() {
    let validUnitPrice: boolean = true;
    this.lossOfStockArray.controls.forEach((x) => {
      (Object as any).values(x.controls).forEach((c) => {
        if (
          x.controls.unitLost.value === '' ||
          +x.controls.unitLost.value === 0 ||
          x.controls.pricePerUnit.value === '' ||
          +x.controls.pricePerUnit.value === 0
        ) {
          validUnitPrice = false;
        }
        c.markAsTouched();
      });
    });
    return validUnitPrice;
  }

  onSave(): void {
    let isValidUnitPrice = this.validationOnUnitPrice();
    if (this.stockFrom.invalid) {
      this.commonService.addValidation(this.stockFrom, this.renderer);
    } else if (this.lossOfStockArray.invalid) {
      this.lossOfStockArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else if (!isValidUnitPrice) {
      this.commonService.onFailure(NotificationTextMessage.amtErrorMessage);
      return;
    } else {
      if (this.dataSubmit()) {
        if (this.stockData) {
          this.store
            .dispatch(new SaveInventoryLossOfStock(this.stockData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.dialog.close();
                  this.commonService.onSuccess(
                    NotificationTextMessage.successMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    }
  }

  clearForm(): void {
    this.dialogClose
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formLossOfStock.get(
            'lossOfStockArray'
          ) as UntypedFormArray;
          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
            formArray.controls[i]
              .get('id')
              ?.setValue(Guid.EMPTY as unknown as Guid);
            formArray.controls[i].get('product')?.setValue('');
            formArray.controls[i].get('unitLost')?.setValue(0);
            formArray.controls[i].get('pricePerUnit')?.setValue(0);
            formArray.controls[i].get('amount')?.setValue(0);
            this.setValidation(formArray, i);
          }

          this.totalLoss = 0;
        }
      });
  }

  setValidation(formArray, index): void {
    formArray.controls[index]
      .get('product')
      ?.setValidators(Validators.required);

    formArray.controls[index]
      .get('unitLost')
      ?.setValidators(Validators.required);

    formArray.controls[index]
      .get('pricePerUnit')
      ?.setValidators(Validators.required);

    formArray.controls[index].get('product')?.updateValueAndValidity();
    formArray.controls[index].get('unitLost')?.updateValueAndValidity();
    formArray.controls[index].get('pricePerUnit')?.updateValueAndValidity();
  }

  setStockForm(): void {
    this.stockFrom = new FormGroup({
      notes: new FormControl(''),
      date: new FormControl(new Date(), [Validators.required]),
      branchId: new FormControl(''),
      departmentId: new FormControl(''),
    });
  }

  onOptionSelected(event: any) {}

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  getOptionText(option) {
    return option.name;
  }

  getBranchList(isOnLoad): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.stockFrom.controls.branchId.setValue(this.branchList[0].id);
            this.stockFrom.controls.branchId.setValidators(Validators.required);
            this.stockFrom.controls.branchId.updateValueAndValidity();
            this.onChangeBranch(this.branchList[0].id, isOnLoad);
          } else {
            this.branchList = [];
            this.departmentList = [];
          }
        })
      )
      .subscribe();
  }

  onChangeBranch(branch, isOnLoad): void {
    this.getDepartmentListByBranch(branch);
  }

  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .pipe(
          tap((res) => {
            this.departmentList = [];
            if (res.company.department.length > 0) {
              this.departmentList = res.company.department;
              this.stockFrom.controls.departmentId.setValue(
                this.departmentList[0].id
              );
              this.stockFrom.controls.departmentId.setValidators(
                Validators.required
              );
              this.stockFrom.controls.departmentId.updateValueAndValidity();
            } else {
              this.stockFrom.controls.departmentId.clearValidators();
              this.stockFrom.controls.departmentId.updateValueAndValidity();
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
      this.lossOfStockFrom.controls.departmentId.clearValidators();
      this.lossOfStockFrom.controls.departmentId.updateValueAndValidity();
    }
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onUnitChanges(index): void {
    const unit = this.lossOfStockArray.controls[index].get('unitLost').value;
    const pricePerUnit =
      this.lossOfStockArray.controls[index].get('pricePerUnit').value;
    this.lossOfStockArray.controls[index]
      .get('amount')
      .setValue(unit * pricePerUnit);
    this.totalLoss = 0;
    this.lossOfStockArray?.getRawValue().forEach((x) => {
      this.totalLoss += +x.amount;
    });
  }

  getTrackedProducts(): void {
    this.store
      .dispatch(new GetTrackedProducts())
      .pipe(
        tap((res) => {
          this.trackedProductList = res.product.trackedProducts;
        })
      )
      .subscribe();
  }

  onDelete(index: number): void {
    this.lossOfStockArray = this.formLossOfStock.get(
      'lossOfStockArray'
    ) as UntypedFormArray;
    if (this.lossOfStockArray.length > 1) this.lossOfStockArray.removeAt(index);
    this.setDataSource(this.lossOfStockArray);

    this.totalLoss = 0;
    this.lossOfStockArray?.getRawValue().forEach((x) => {
      this.totalLoss += +x.amount;
    });
  }

  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  setLossOfStockForm(addNewRow: boolean): void {
    this.formLossOfStock = new FormGroup({
      lossOfStockArray: new UntypedFormArray([]),
    });

    this.lossOfStockArray = this.formLossOfStock.get(
      'lossOfStockArray'
    ) as UntypedFormArray;

    this.setDataSource(this.lossOfStockArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.lossOfStockArray = this.formLossOfStock.get(
      'lossOfStockArray'
    ) as UntypedFormArray;

    this.lossOfStockArray.push(this.setForm());

    this.setDataSource(this.lossOfStockArray);
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      product: new FormControl(''),
      unitLost: new FormControl<number | null>(0, Validators.required),
      pricePerUnit: new FormControl<number | null>(0, Validators.required),
      amount: new FormControl<number | null>({ value: 0, disabled: true }),
    });
  }

  onCancel(): void {
    this.dialog.close();
  }

  onProductChange(event: any, index: any): void {
    if (event) {
      let list = this.trackedProductList.filter((item:any)=> item.id === event);
      this.lossOfStockArray.controls[index].get('pricePerUnit')?.setValue((list.length > 0) ? list[0]['price'] : 0);
      this.onUnitChanges(index);
    }
  }
}
