import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  MaxLength,
  NotificationTextMessage,
  TradingStatus,
} from '@app/core/Enum';
import {
  CompanyAddressDetailModel,
  CompanyContactDetailModel,
  CompanyRegionalDetailModel,
  Country,
  Currency,
  FileUploadRequestModel,
  FileUploadResponseModel,
  SideListModel,
  VatRateScheme,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  CountryState,
  FileUpload,
  GetAccountingMethodList,
  GetCompanyNo,
  GetCompanyType,
  GetCountryList,
  GetCurrencyList,
  GetLanguage,
  GetTimeZone,
  GetVatReturnTypeList,
  GetVatSchemeList,
  MenuState,
} from '@app/core/Store';
import {
  AddBranchComponent,
  AddClosePopupComponent,
  AddDepartmentComponent,
  AddFinancialYearComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss'],
})
export class AddCompanyComponent implements OnInit {
  isRegisteredForVat = false;
  isAddBranchDep = false;
  keepBillingDetailSeparate = false;
  isCompanyAddress = false;
  isRegional = false;
  isImageSelected = false;
  isAddMode = true;
  maxLength = MaxLength;

  tooggleFlag: boolean;
  companyImage: string;
  tooggleText = 'No';
  tooggleBranchDepText = 'No';
  GUID: any;
  url: any;
  selectedFile: File;

  companyForm: FormGroup;

  addressDetail: CompanyAddressDetailModel;
  billingDetail: CompanyAddressDetailModel;
  contactDetail: CompanyContactDetailModel;
  regionalDetail: CompanyRegionalDetailModel;
  fileUploadResponse: FileUploadResponseModel;
  fileUploadRequest: FileUploadRequestModel;

  countryList: Country[];
  currencyList: Currency[];
  vatReturnTypeList: SideListModel[];
  companyType: SideListModel[];
  timezone: SideListModel[];
  languageList: SideListModel[];
  accountingMethodList: SideListModel[];
  vatSchemeList: VatRateScheme[];
  fileList: any[] = [];

  acceptedFileTypes = ['.gif', '.jpg', '.jpeg', '.png'];

  tradingStatus = [
    {
      id: TradingStatus.Active,
      name: TradingStatus[TradingStatus.Active],
    },
    {
      id: TradingStatus.Dissolved,
      name: TradingStatus[TradingStatus.Dissolved],
    },
  ];

  @Input() triggerEditData: Observable<any>;
  notificationMessage = NotificationTextMessage;

  triggerEditFinancialData: Subject<any> = new Subject<any>();
  triggerEditDepartmentData: Subject<any> = new Subject<any>();
  triggerEditBranchData: Subject<any> = new Subject<any>();
  triggerSetDefaultBranch: Subject<any> = new Subject<any>();
  @ViewChild(AddDepartmentComponent, { static: true })
  departmentDetails;

  @ViewChild(AddFinancialYearComponent, { static: true })
  financialYear;

  @ViewChild(AddBranchComponent, { static: true })
  branchDetails;

  @ViewChild(AddFinancialYearComponent, { static: false })
  financialYearDetail;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(
    private store: Store,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.setForm();

    this.triggerEditData?.subscribe((data) => {
      this.isAddMode = false;
      this.companyImage = '';
      this.editCompanyDetails(data);
      this.triggerEditFinancialData.next(data.accountingPeriods);
      this.triggerEditBranchData.next(data.branchs);

      this.triggerEditDepartmentData.next(data.departments);
    });

    this.companyForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.companyForm.touched;
    });
  }

  editCompanyDetails(data: any): void {
    if (data.logo) {
      this.isImageSelected = true;
      this.companyImage = data.logo;
    }

    this.keepBillingDetailSeparate = data.keepBillingDetailSeparate;
    this.isAddBranchDep = data.isBranchDepartmentAccounting;
    this.isRegisteredForVat = data.isVatRegistered;
    this.tooggleBranchDepText = this.isAddBranchDep ? 'Yes' : 'No';
    this.tooggleText = this.isRegisteredForVat ? 'Yes' : 'No';
    this.tooggleFlag = this.isRegisteredForVat ? true : false;

    this.companyForm.patchValue({
      clientId: data.companyRefNo,
      name: data.name,
      companyTypeId: data.companyTypeId,
      tradingStatusType: data.tradingStatus === true ? 1 : 0,
      establishDate: data.establishmentDate,
      registrationNo: data.registrationNo,
      taxRefNo: data.taxRefNo,
      AccountOfficeRefNo: data.accountOfficeRefNo,
      PAYERefNo: data.payeRefNo,
      companyNo: data.companyNo,
      principalPurpose: data.principalPurpose,
      country: data.addressDetail.country,
      address1: data.addressDetail.address1,
      address2: data.addressDetail.address2,
      address3: data.addressDetail.address3,
      city: data.addressDetail.city,
      postalCode: data.addressDetail.postalCode,
      keepBillingDetailSeparate: data.keepBillingDetailSeparate,
      contactPerson: data.contactDetail.name,
      phone1: data.contactDetail.phone1,
      phone2: data.contactDetail.phone2,
      email: data.contactDetail.email,
      alternateEmail: data.contactDetail.alternateEmail,
      website: data.contactDetail.website,
      currency: data.regionalDetail.currencyId,
      language: data.regionalDetail.languageId,
      timezone: data.regionalDetail.timezoneId,
      state: data.addressDetail.state,
      address1BillingSeparate:
        data.billingDetail === null ? '' : data.billingDetail.address1,
      address2BillingSeparate:
        data.billingDetail === null ? '' : data.billingDetail.address2,
      cityBillingSeparate:
        data.billingDetail === null ? '' : data.billingDetail.city,
      address3BillingSeparate:
        data.billingDetail === null ? '' : data.billingDetail.address3,
      countryBillingSeparate:
        data.billingDetail === null ? '' : data.billingDetail.country,
      postalCodeBillingSeparate:
        data.billingDetail === null ? '' : data.billingDetail.postalCode,
      vatRegistrationNo:
        data.vatDetail === null || data.vatDetail === undefined
          ? null
          : data.vatDetail.vatRegistrationNo,
      vatRegistrationDate:
        data.vatDetail === null || data.vatDetail === undefined
          ? null
          : data.vatDetail.vatRegistrationDate,
      vatSchemeId:
        data.vatDetail === null || data.vatDetail === undefined
          ? null
          : data.vatDetail.vatSchemeId,
      vatReturnTypeId:
        data.vatDetail === null || data.vatDetail === undefined
          ? null
          : data.vatDetail.vatReturnTypeId,
      accountingMethodId: data.accountingMethodId,
      startDate: data.startDate,
      endDate: data.endDate,
    });

    this.companyForm.controls.companyTypeId.disable();
  }

  addCompanyAddress(isCompanyAddress: boolean): void {
    this.isCompanyAddress = !isCompanyAddress;
  }

  addRegionalInfo(isRegional: boolean): void {
    this.isRegional = !isRegional;
  }

  getCountry(): void {
    this.store
      .dispatch(new GetCountryList())
      .pipe(
        tap(() => {
          this.countryList = this.store.selectSnapshot(CountryState.getCountry);
          if (this.isAddMode && this.countryList.length > 0) {
            this.companyForm.controls.country.setValue(
              this.countryList[205].id
            );
            this.companyForm.controls.countryBillingSeparate.setValue(
              this.countryList[205].id
            );
          }
        })
      )
      .subscribe();
  }

  getAccountingMethod(): void {
    this.store
      .dispatch(new GetAccountingMethodList())
      .pipe(
        tap((res) => {
          this.accountingMethodList = res.company.accountingMethod;
          if (this.isAddMode && this.accountingMethodList.length > 0) {
            this.companyForm.controls.accountingMethodId.setValue(
              this.accountingMethodList[0].id
            );
          }
        })
      )
      .subscribe();
  }

  getCurrency(): void {
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          if (this.isAddMode && this.currencyList.length > 0) {
            this.companyForm.controls.currency.setValue(
              this.currencyList[122].id
            );
          }
        })
      )
      .subscribe();
  }

  getVatReturnType(): void {
    this.store
      .dispatch(new GetVatReturnTypeList())
      .pipe(
        tap((res) => {
          this.vatReturnTypeList = res.company.vatReturnTypeList;
          if (this.isAddMode && this.vatReturnTypeList.length > 0) {
            this.companyForm.controls.vatReturnTypeId.setValue(
              this.vatReturnTypeList[0].id
            );
          }
        })
      )
      .subscribe();
  }

  addBranchDep(): void {
    this.isAddBranchDep = !this.isAddBranchDep;
    this.tooggleBranchDepText = this.isAddBranchDep ? 'Yes' : 'No';
    this.triggerSetDefaultBranch.next(this.isAddBranchDep);
  }

  registeredForVat(): void {
    this.isRegisteredForVat = !this.isRegisteredForVat;
    this.tooggleText = this.isRegisteredForVat ? 'Yes' : 'No';

    if (!this.isRegisteredForVat) {
      this.companyForm.controls.vatRegistrationNo.clearValidators();
      this.companyForm.controls.vatRegistrationNo.updateValueAndValidity();
    }
  }

  onFileSelected(event: any): void {
    if (!event.target.files || !event.target.files.length) {
      return;
    }
    this.isImageSelected = true;
    this.selectedFile = event.target.files[0];
    this.saveFile(event.target.files[0]);

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.url = reader.result;
    };
  }

  saveFile(file: File): void {
    this.fileList = [];
    this.fileList.push(file);

    this.fileUploadRequest = {
      file: this.fileList,
      attachmentType: 1,
    };

    this.store
      .dispatch(new FileUpload(this.fileUploadRequest))
      .pipe(
        tap((data) => {
          this.fileUploadResponse = data.common.fileUploadRequestModel[0];
        })
      )
      .subscribe();
  }

  showOptions(event: any): void {
    this.companyForm.controls.keepBillingDetailSeparate.setValue(event.checked);
    this.keepBillingDetailSeparate = !this.keepBillingDetailSeparate;
    this.companyForm.controls.countryBillingSeparate.setValue(
      this.countryList[205].id
    );
  }

  getVatSchemeList(): void {
    this.store
      .dispatch(new GetVatSchemeList())
      .pipe(
        tap((res) => {
          this.vatSchemeList = res.company.vatSchemeList;
          if (this.isAddMode && this.vatSchemeList.length > 0) {
            this.companyForm.controls.vatSchemeId.setValue(
              this.vatSchemeList[0].id
            );
          }
        })
      )
      .subscribe();
  }

  setForm(): void {
    this.companyForm = new FormGroup({
      clientId: new FormControl({ value: '', disabled: true }),
      name: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      companyTypeId: new FormControl('', [Validators.required]),
      vatReturnTypeId: new FormControl(),
      vatSchemeId: new FormControl(),
      establishDate: new FormControl(new Date()),
      registrationNo: new FormControl(''),
      taxRefNo: new FormControl(''),
      AccountOfficeRefNo: new FormControl(''),
      PAYERefNo: new FormControl(''),
      companyNo: new FormControl('', [
        Validators.required,
        Validators.maxLength(8),
        Validators.pattern(/^(?:\d{8}|[a-zA-Z]{2}\d{6})$/),
      ]),
      principalPurpose: new FormControl(''),
      country: new FormControl('', Validators.required),
      address1: new FormControl(''),
      address2: new FormControl(''),
      address3: new FormControl(''),
      city: new FormControl(''),
      postalCode: new FormControl(''),
      keepBillingDetailSeparate: new FormControl(false),
      countryBillingSeparate: new FormControl(''),
      address1BillingSeparate: new FormControl(''),
      address2BillingSeparate: new FormControl(''),
      address3BillingSeparate: new FormControl(''),
      cityBillingSeparate: new FormControl(''),
      postalCodeBillingSeparate: new FormControl(''),
      contactPerson: new FormControl(''),
      phone1: new FormControl(''),
      phone2: new FormControl(''),
      email: new FormControl('', [Validators.email]),
      alternateEmail: new FormControl('', [Validators.email]),
      website: new FormControl(''),
      tradingStatusType: new FormControl(TradingStatus.Active),
      currency: new FormControl({ value: '', disabled: true }),
      language: new FormControl(),
      timezone: new FormControl(),
      vatRegistrationNo: new FormControl(''),
      vatRegistrationDate: new FormControl(new Date()),
      state: new FormControl(''),
      accountingMethodId: new FormControl(Validators.required),
    });

    this.getCountry();
    this.getCurrency();
    this.getCompanyType();
    this.getTimeZone();
    this.getLanguage();
    this.getVatSchemeList();
    this.getVatReturnType();
    this.getAccountingMethod();
    this.getCompanyNo();
  }

  getCompanyType(): void {
    this.store
      .dispatch(new GetCompanyType())
      .pipe(
        tap((res) => {
          this.companyType = res.company.companyType;
          if (this.isAddMode) {
            this.companyForm.controls.companyTypeId.setValue(
              this.companyType[0].id
            );
          }
        })
      )
      .subscribe();
  }

  getCompanyNo(): void {
    this.store.dispatch(new GetCompanyNo()).subscribe((res) => {
      this.companyForm.controls.clientId.setValue(res.company.companyNo);
    });
  }

  getTimeZone(): void {
    this.store
      .dispatch(new GetTimeZone())
      .pipe(
        tap((res) => {
          this.timezone = res.company.timeZone;
          this.companyForm.controls.timezone.setValue(this.timezone[0].id);
        })
      )
      .subscribe();
  }

  getLanguage(): void {
    this.store
      .dispatch(new GetLanguage())
      .pipe(
        tap((res) => {
          this.languageList = res.company.language;
          if (this.isAddMode && this.languageList.length > 0) {
            this.companyForm.controls.language.setValue(
              this.languageList[0].id
            );
          }
        })
      )
      .subscribe();
  }

  get accept(): string {
    return this.acceptedFileTypes?.join(', ') ?? '';
  }

  onDeletePhoto(): void {
    this.isImageSelected = false;
    this.companyImage = '';
    this.url = '';
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
