import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FixedAssetReturnModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FixedAssetsReturnService {
  constructor(private http: HttpClient) {}

  createFixedAssetReturn(
    fixedAssetReturn: FixedAssetReturnModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}FixedAssetReturn/save`,
      JSON.stringify(fixedAssetReturn),
      headers
    );
  }

  quickFixedAssetReturn(fixedAssetReturn: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}FixedAssetReturn/saveFixedAssetReturn`,
      JSON.stringify(fixedAssetReturn),
      headers
    );
  }

  getDataByFixedAssetReturnId(
    fixedAssetReturnId: Guid
  ): Observable<FixedAssetReturnModel> {
    return this.http.get<FixedAssetReturnModel>(
      `${environment.apiVersionUrl}FixedAssetReturn/get/${fixedAssetReturnId}`
    );
  }

  deleteFixedAssetReturn(fixedAssetReturnIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(fixedAssetReturnIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}FixedAssetReturn/delete`,
      options
    );
  }

  copyFixedAssetReturn(fixedAssetReturnIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}FixedAssetReturn/copyFixedAssetReturn`,
      JSON.stringify(fixedAssetReturnIds),
      headers
    );
  }

  archiveAndRestoreFixedAssetReturn(
    fixedAssetReturnIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}FixedAssetReturn/archive/${isArchive}`,
      JSON.stringify(fixedAssetReturnIds),
      options
    );
  }
}
