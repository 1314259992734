<div class="content-body content-body-scroll">
  <p class="header-text d-flex justify-space-between align-items-center">
    {{ headerText }}
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </p>

  <div class="content-right-button d-flex align-items-center gap-30 mb-1">
    <div class="d-flex align-items-center gap-10">
      <span class="fw-bold">Period: </span>
      <mat-form-field class="w-200">
        <mat-select
          panelClass="mat-select-position"
          [disableOptionCentering]="true"
          [(ngModel)]="accountingPeriod"
          (selectionChange)="onAccountPeriodChange()"
        >
          <mat-option
            *ngFor="let option of periodicDateList"
            [value]="option.id"
            title="{{ option.fromDate | date: 'dd-LLL-yyyy' }} - {{
              option.toDate | date: 'dd-LLL-yyyy'
            }}"
          >
            {{ option.fromDate | date: "dd-LLL-yyyy" }} -
            {{ option.toDate | date: "dd-LLL-yyyy" }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <button
        class="action-button primary-button"
        (click)="generateReport()"
        mat-button
        disableRipple
      >
        Generate Report
      </button>
    </div>
  </div>
  <div class="d-flex gap-40 justify-space-between">
    <div class="flex-1">
      <form [formGroup]="annualForm" class="p-0">
        <div class="d-flex gap-40">
          <div class="form-field mb-0">
            <p>Font Size <span class="text-danger-300">*</span></p>
            <ng-select
              panelClass="mat-select-position"
              [disableOptionCentering]="true"
              formControlName="fontSize"
              dropdownPosition="bottom"
            >
              <ng-option
                *ngFor="let font of fontSizeList | useEnumValues"
                [value]="font.key"
                title="{{ font.key }}"
              >
                <div title="{{ font.key }}">{{ font.key }}</div>
              </ng-option>
            </ng-select>
          </div>

          <div class="form-field mb-0">
            <p>Font Family <span class="text-danger-300">*</span></p>
            <ng-select
              panelClass="mat-select-position"
              [disableOptionCentering]="true"
              formControlName="fontFamily"
              dropdownPosition="bottom"
            >
              <ng-option
                *ngFor="let names of fontFamilies"
                [value]="names"
                title="{{ names }}"
              >
                <div title="{{ names }}">{{ names }}</div>
              </ng-option>
            </ng-select>
          </div>
          <div class="form-field mb-0">
            <p>Color <span class="text-danger-300">*</span></p>
            <input
              type="color"
              [(ngModel)]="fontColors"
              formControlName="fontColor"
              class="color-picker"
            />
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="control-section mt-2" *ngIf="showDocument">
    <div class="default_title_bar e-de-ctn-title">
      <button
        title="Download this document."
        (click)="onDownloadClick()"
        class="e-btn"
        type="button"
      >
        <mat-icon class="e-de-padding-right e-icon-left">download</mat-icon>
      </button>
      <button
        title="Print this document (Ctrl+P)."
        class="e-btn"
        (click)="onPrint()"
      >
        <mat-icon class="e-de-padding-right e-icon-left">print</mat-icon>
      </button>
    </div>
    <ejs-documenteditorcontainer
      #documenteditor_default
      [enableToolbar]="true"
      [enablePrint]="true"
      [enableSfdtExport]="true"
      height="800px"
      [serviceUrl]="hostUrl"
      style="display: block"
    ></ejs-documenteditorcontainer>
  </div>
</div>
