import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { AuthorizedRole, NotificationTextMessage } from '@app/core/Enum';
import { PermissionType } from '@app/core/Enum/settings';
import { UserStandardPermission } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  GetUserBusinessPermission,
  SaveUserPermission,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

export class StandardPermissionHeader extends UserStandardPermission {
  children: Array<StandardPermissionHeader>;
}
export class StandardPermissionFlatNode extends UserStandardPermission {
  expandable: boolean;
  level: number;
}

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss'],
})
export class UserPermissionsComponent implements OnInit {
  UserPermissions: FormGroup;

  displayedColumns: string[] = ['name', 'select'];

  isFullSelected: boolean = false;
  isViewSelected: boolean = false;
  isNoneSelected: boolean = false;

  permissionType = PermissionType;
  permissionData: Array<UserStandardPermission>;
  toggleAll = false;
  authorizedRole = AuthorizedRole;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserPermissionsComponent>,
    public store: Store,
    private spinner: NgxSpinnerService,
    public commonService: CommonService
  ) {}

  private transformer = (node: UserStandardPermission, level: number) => {
    return {
      expandable: !!node.subMenu && node.subMenu.length > 0,
      name: node.name,
      level: level,
      parentModuleId: node.parentModuleId,
      id: node.id,
      permissionId: node.permissionId,
      isDisabled: node.isDisabled,
      subMenu: node.subMenu,
    };
  };

  treeControl = new FlatTreeControl<StandardPermissionFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.subMenu
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  ngOnInit(): void {
    this.getUserBusinessPermission();
  }

  toggleAllClick(): void {
    this.toggleAll = !this.toggleAll;
    if (this.toggleAll) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }

  dataSubmit(): void {
    this.spinner.show();
    this.permissionData = new Array<UserStandardPermission>();
    this.treeControl.dataNodes?.forEach((node) => {
      if (node.parentModuleId) {
        const parentIndex = this.permissionData.findIndex(
          (parent) => parent.id === node.parentModuleId
        );
        if (parentIndex === -1) {
          this.permissionData.forEach((parent) => {
            if (parent.subMenu) {
              const childIndex = parent.subMenu.findIndex(
                (child) => child.id === node.parentModuleId
              );

              if (childIndex !== -1) {
                parent.subMenu[childIndex].subMenu.push(node);
              }
            }
          });
        } else {
          node.subMenu = [];
          this.permissionData[parentIndex].subMenu.push(node);
        }
      } else {
        node.subMenu = [];
        this.permissionData.push(node);
      }
    });
  }

  onSave(): void {
    this.dataSubmit();

    if (this.permissionData?.length > 0) {
      this.store
        .dispatch(new SaveUserPermission(this.permissionData, this.data.userId))
        .subscribe((res: any) => {
          if (res.common.isUserPermissionUpdated) {
            this.dialogRef.close(true);
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        });
    }
  }

  getUserBusinessPermission(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetUserBusinessPermission(this.data.userId))
      .subscribe(() => {
        const permissions = this.store.selectSnapshot(
          CommonState.getUserBusinessPermission
        );
        this.dataSource.data = permissions ?? [];
        this.setAllSelected();
      });
  }

  selected(type): void {
    for (let dataNode of this.treeControl.dataNodes) {
      if (!dataNode.isDisabled) {
        if (type === PermissionType.Full) {
          dataNode.permissionId = PermissionType.Full;
          this.isFullSelected = true;
          this.isViewSelected = false;
          this.isNoneSelected = false;
        }

        if (type === PermissionType.View) {
          dataNode.permissionId = PermissionType.View;
          this.isFullSelected = false;
          this.isViewSelected = true;
          this.isNoneSelected = false;
        }

        if (type === PermissionType.None) {
          dataNode.permissionId = PermissionType.None;
          this.isFullSelected = false;
          this.isViewSelected = false;
          this.isNoneSelected = true;
        }
      }
    }
  }

  setAllSelected(): void {
    this.isFullSelected = this.treeControl.dataNodes.every(
      (item: any) => item.permissionId === PermissionType.Full
    );

    this.isViewSelected = this.treeControl.dataNodes.every(
      (item: any) => item.permissionId === PermissionType.View
    );

    this.isNoneSelected = this.treeControl.dataNodes.every(
      (item: any) => item.permissionId === PermissionType.None
    );
  }

  onSelected(data: any, permissionType): void {
    const parent: StandardPermissionFlatNode | null = this.getParentNode(data);
    const descendants = this.treeControl.getDescendants(data);

    descendants.forEach((ele) => {
      if (!ele.isDisabled) {
        if (permissionType === PermissionType.Full) {
          ele.permissionId = PermissionType.Full;
        }
        if (permissionType === PermissionType.View) {
          ele.permissionId = PermissionType.View;
        }
        if (permissionType === PermissionType.None) {
          ele.permissionId = PermissionType.None;
        }
      }
    });

    if (parent) {
      let tempChild: any = [];
      this.treeControl.dataNodes.forEach((element) => {
        if (element.parentModuleId > 0) tempChild.push(element);
      });

      let mainTemp: any = [];
      mainTemp = tempChild.filter((x) => x.parentId === parent.id);
      let result = mainTemp.every((e) => {
        return e.permissionId === permissionType;
      });

      if (result) {
        parent.permissionId = permissionType;
      }

      if (parent.permissionId !== permissionType) {
        parent.permissionId = PermissionType.Full;
      }
    }
    this.setAllSelected();
  }

  getLevel = (node: StandardPermissionFlatNode) => node.level;

  getParentNode(
    node: StandardPermissionFlatNode
  ): StandardPermissionFlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  resetToDefault(): void {
    this.getUserBusinessPermission();
  }
}
