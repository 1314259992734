import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe, Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Modules, NotificationTextMessage } from '@app/core/Enum';
import { MainListParameters } from '@app/core/Models';
import { CommonEmitterService, CommonService } from '@app/core/Services';
import {
  GetMainList,
  GetPermissionDataByUserId,
  SaveUserModulePermission,
} from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

export interface UserElement {
  userName: string;
}

export interface PermissionElement {
  permission: string;
  read: boolean;
  write: boolean;
}
@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss'],
})
export class PermissionComponent implements OnInit, OnDestroy {
  isExpandAll = false;
  isDataUpdated = false;
  moduleId = Modules.Permission;
  selectedIndex: number = -1;
  selectedUserId = Guid.EMPTY as unknown as Guid;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  selection = new SelectionModel<UserElement>(false, []);
  selectedItem = <UserElement>{};

  displayedUserColumns: string[] = ['userName'];
  dataSource: any[] = [];
  userDataList: any = [];

  displayedPermissionColumns: string[] = ['permission', 'access'];

  listParameters: MainListParameters = new MainListParameters();
  permissionData: any[] = [];
  setUserListEmitter;
  constructor(
    public dialog: MatDialog,
    public commonService: CommonService,
    public store: Store,
    public datepipe: DatePipe,
    public spinner: NgxSpinnerService,
    private location: Location,
    private commonEmitterSerice: CommonEmitterService
  ) {}

  ngOnInit(): void {
    this.getUserData();

    this.setUserListEmitter =
      this.commonEmitterSerice.setUserListEmitter.subscribe((data) => {
        if (data) {
          this.getUserData();
        }
      });
  }

  ngOnDestroy(): void {
    if (
      this.setUserListEmitter !== null &&
      this.setUserListEmitter !== undefined
    ) {
      this.setUserListEmitter.unsubscribe();
    }
  }

  getUserData(): void {
    const queryParams: any = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids:
        this.listParameters.ids !== undefined &&
        this.listParameters.ids.length > 0
          ? this.listParameters.ids
          : null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    this.store
      .dispatch(new GetMainList(queryParams, this.moduleId))
      .subscribe((res) => {
        this.spinner.hide();
        this.userDataList = [];
        this.userDataList = res.common.mainList.resultSet.data;
        let data: any[] = [];
        this.userDataList.forEach((element, i) => {
          const disData = {
            id: element[0],
            name: element[1],
          };
          data.push(disData);
        });
        this.dataSource = data;
      });
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onUserSelect(element: any, event: any, index: number): void {
    if (this.isDataUpdated) {
      this.dialog
        .open(AddClosePopupComponent, {
          data: {
            isWarning: true,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.selectItem(element, event, index);
          } else {
            this.selectedIndex =
              this.dataSource.findIndex((x) => x.id === this.selectedUserId) ??
              -1;
          }
        });
    } else {
      this.selectItem(element, event, index);
    }
  }

  selectItem(element: any, event, index: number): void {
    this.selectedIndex = index;
    this.selectedUserId = element.id;
    this.getPermissionData();
  }

  getPermissionData(): void {
    this.store
      .dispatch(new GetPermissionDataByUserId(this.selectedUserId))
      .subscribe((res) => {
        this.permissionData = res.permission.permissionData;
        this.isDataUpdated = false;
      });
  }

  isQuickAdd(event): void {
    if (event) {
      this.selectedUserId = Guid.EMPTY as unknown as Guid;
      this.getUserData();
    }
  }

  onSave(): void {
    this.spinner.show();
    this.store
      .dispatch(
        new SaveUserModulePermission(this.permissionData, this.selectedUserId)
      )
      .subscribe((res) => {
        this.spinner.hide();
        if (res.permission.isUserPermissionAdded) {
          this.location.back();
          this.commonService.onSuccess(NotificationTextMessage.successMessage);
        } else {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      });
  }

  onCancel(): void {
    this.getPermissionData();
  }
}
