import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import {
  AuthorizedRole,
  CommonNotificationText,
  ConfirmationType,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { MainListParameters } from '@app/core/Models';
import { NotificationService } from '@app/core/Services';
import { GetUserClientsDetails, UserState } from '@app/core/Store';
import { ConfirmationBoxComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-clients',
  templateUrl: './user-clients.component.html',
  styleUrls: ['./user-clients.component.scss'],
})
export class UserClientsComponent implements OnInit {
  @Input() displayedUserClientColumns: any;

  commonNotificationText = CommonNotificationText;

  @Select(UserState.getUserClientsDetails)
  userClientsDetails$: Observable<Array<any>>;

  @Input() userId: Guid;

  @Input() showAssignClients: boolean;

  @Input() triggerReloadList: any;

  isAssignedToAllClients = false;
  isClientUpdate = false;

  authorizedRole = AuthorizedRole;

  @ViewChild(MatSort) sort: MatSort;

  @Select(UserState.getUserClientsDataLength)
  userClientsDataLength$: Observable<number>;

  @Select(UserState.totalRecordOfUserClient)
  totalRecordOfUserClient$: Observable<number>;

  listParameters: MainListParameters = new MainListParameters();

  @Input() triggerDataFromHeader: Observable<any>;
  ids?: Array<Guid>;

  constructor(
    private store: Store,
    private notifier: NotificationService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.checkAssignedToAllClient();
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      filter: this.listParameters.filter,
      userId: this.userId,
      isUserClient: !this.showAssignClients,
    };

    return queryParams;
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.userId = this.store.selectSnapshot(UserState.getUserId)!;
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.getList();
  }

  getList(): void {
    this.store
      .dispatch(new GetUserClientsDetails(this.getParamter()))
      .subscribe();
  }

  checkAssignedToAllClient() {
    this.userClientsDetails$.subscribe((x) => {
      this.isAssignedToAllClients = x.every(
        (item: any) => item.isAssigned === true
      );
    });
  }

  assignToAllClients(event: any): void {
    this.userClientsDetails$.subscribe((x) => {
      x.forEach((x) => (x.isAssigned = event.checked));

      this.checkAssignedToAllClient();
    });
  }

  getSelectedIds(element?: any): void {
    this.ids = [];
    this.ids.push(element.universalId);
  }

  onAllDelete(): void {
    this.ids = [];

    this.userClientsDetails$.subscribe((x) => {
      x.forEach((element) => {
        if (element.permissionId !== AuthorizedRole.Owner) {
          this.ids?.push(element.universalId);
        }
      });
    });
    this.deleteDialog();
  }

  onDelete(element: any) {
    this.spinner.hide();
    this.getSelectedIds(element);
    this.deleteDialog();
  }

  deleteDialog(): void {
    if (this.ids!.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: Modules.UserClients,
            headerText: NotificationHeader.deleteConfirmation,
            totalNumberOfRecordSelected: this.ids!.length,
            detailText: NotificationDetails.deleteSelectedDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getList();
            this.notifier.success(
              NotificationHeader.success,
              NotificationTextMessage.recordDeletedSuccessfully
            );
          }
        });
    }
  }

  pageChanged(pageIndex: number): void {
    this.userId = this.store.selectSnapshot(UserState.getUserId)!;
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.getList();
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.getList();
  }
}
