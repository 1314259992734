import { Injectable } from '@angular/core';
import {
  AccountModel,
  CustomAccountModel,
  SideListModel,
} from '@app/core/Models';
import { CustomAccountService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';

import {
  ArchiveAndRestoreCustomAccount,
  CreateCustomAccount,
  DeleteCustomAccount,
  GetAccountTypeList,
  GetDataByCustomAccountId,
  GetDataByStandardAccountId,
} from './custom-accounts.action';

export class CustomAccountStateInfo {
  customAccountId: Guid;
  isAccountAdded: boolean;
  customAccountData?: CustomAccountModel;
  customAccount: Array<AccountModel>;
  accountTypeList: SideListModel[];
  sideListModel: Array<SideListModel>;
  isLastPage?: boolean;
}

@State<CustomAccountStateInfo>({
  name: 'custom',
  defaults: {
    customAccountId: Guid.EMPTY as unknown as Guid,
    isAccountAdded: false,
    accountTypeList: [],
    customAccount: [],
    sideListModel: [],
  },
})
@Injectable()
export class CustomAccountState {
  constructor(private customAccountService: CustomAccountService) {}

  @Selector()
  static getAccountType(state: CustomAccountStateInfo) {
    return state.accountTypeList;
  }

  @Selector()
  static isLastPage(state: CustomAccountStateInfo) {
    return state.isLastPage;
  }

  @Action(CreateCustomAccount)
  createCustomAccount(
    { patchState }: StateContext<CustomAccountStateInfo>,
    action: CreateCustomAccount
  ) {
    return this.customAccountService
      .createCustomAccount(action.customAccount)
      .pipe(
        tap((res) => {
          patchState({
            customAccountId: res,
            isAccountAdded: true,
          });
        })
      );
  }

  @Action(GetDataByCustomAccountId)
  getDataByCustomAccountId(
    { patchState }: StateContext<CustomAccountStateInfo>,
    action: GetDataByCustomAccountId
  ) {
    return this.customAccountService
      .getDataByCustomAccountId(action.customAccountId)
      .pipe(
        tap((res) => {
          patchState({
            customAccountData: res,
          });
        })
      );
  }

  @Action(GetDataByStandardAccountId)
  getDataByStandardAccountId(
    { patchState }: StateContext<CustomAccountStateInfo>,
    action: GetDataByStandardAccountId
  ) {
    return this.customAccountService
      .getDataByStandardAccountId(action.standardAccountId)
      .pipe(
        tap((res) => {
          patchState({
            customAccountData: res,
          });
        })
      );
  }

  @Action(GetAccountTypeList)
  getCountryList({ patchState }: StateContext<CustomAccountStateInfo>) {
    return this.customAccountService.getAccountTypeList().pipe(
      tap((res) => {
        patchState({
          accountTypeList: res,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreCustomAccount)
  archiveAndRestoreCustomAccount(
    { getState }: StateContext<CustomAccountStateInfo>,
    action: ArchiveAndRestoreCustomAccount
  ) {
    return this.customAccountService.archiveAndRestoreCustomAccount(
      action.customAccountIds,
      action.isArchive
    );
  }

  @Action(DeleteCustomAccount)
  deleteCustomAccount(
    { getState, patchState }: StateContext<CustomAccountStateInfo>,
    action: DeleteCustomAccount
  ) {
    return this.customAccountService
      .deleteCustomAccount(action.customAccountIds)
      .pipe(
        tap((res) => {
          const state = getState();

          const filteredQuatation = state.customAccount.filter(
            (item) =>
              !action.customAccountIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          const filteredForSideList = state.sideListModel?.filter(
            (item) =>
              !action.customAccountIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          patchState({
            ...state.customAccount,
            customAccount: filteredQuatation,
            sideListModel: filteredForSideList,
          });
        })
      );
  }
}
