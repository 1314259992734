import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OpeningBalance, SaveOpeningBalanceModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable()
export class OpeningBalanceService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  saveOpeningBalanceExplain(
    openingBalanceExplain: Array<OpeningBalance>,
    accountTypeId: number
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}OpeningBalance/saveExplain/${accountTypeId}`,
      JSON.stringify(openingBalanceExplain),
      headers
    );
  }

  saveOpeningBalanceAdvance(
    openingBalanceExplain: Array<OpeningBalance>,
    accountTypeId: number
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}OpeningBalance/saveAdvance/${accountTypeId}`,
      JSON.stringify(openingBalanceExplain),
      headers
    );
  }

  saveOpeningBalance(openingBalance: SaveOpeningBalanceModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}OpeningBalance/save`,
      this.commonService.trimObjectSpace(JSON.stringify(openingBalance)),
      headers
    );
  }

  getOpeningBalanceList(): Observable<Array<OpeningBalance>> {
    return this.http.get<Array<OpeningBalance>>(
      `${environment.apiVersionUrl}OpeningBalance/filteredList`
    );
  }

  getOpeningBalanceListBasedOnBranchId(
    branchId: Guid
  ): Observable<Array<OpeningBalance>> {
    return this.http.get<Array<OpeningBalance>>(
      `${environment.apiVersionUrl}OpeningBalance/filteredListBasedOnBranch/${branchId}`
    );
  }

  getOpeningBalanceBasedOnAPId(
    accoutingPeriodId: Guid
  ): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}OpeningBalance/getBasedOnAccountingPeriod/${accoutingPeriodId}`
    );
  }

  saveTrialBalance(trialBalance: SaveOpeningBalanceModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}OpeningBalance/saveTrialBalance`,
      this.commonService.trimObjectSpace(JSON.stringify(trialBalance)),
      headers
    );
  }
}
