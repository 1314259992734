<div class="content-body content-body-scroll">
  <div>
    <p class="header-text d-flex justify-space-between align-items-center">
      <span> All Reports </span>
    </p>
  </div>

  <div class="mt-2 report-wrapper" *ngFor="let report of reportlistitems">
    <div class="report-content-details gap-40">
      <ng-container *ngFor="let submenu of report.subMenu">
        <div *ngIf="submenu.id != modules.ReportDashboard">
          <div class="dash-border pb-1">
            <div class="d-flex align-items-center mat-accordion">
              <span>
                <mat-icon class="material-symbols-outlined">
                  {{ submenu.icon }}
                </mat-icon></span
              ><span class="mat-expansion-panel-header-title ml-10">{{
                submenu.name
              }}</span>
            </div>
          </div>
          <div *ngIf="submenu.subMenu.length > 0">
            <ul class="report-name">
              <li *ngFor="let reportName of submenu.subMenu">
                <div *ngIf="reportName.subMenu.length === 0">
                  <a
                    href="{{ reportName.url }}"
                    class="link d-flex align-items-center gap-10 dash-border"
                  >
                    <span>
                      <mat-icon class="material-symbols-outlined">
                        {{ submenu.icon }}
                      </mat-icon></span
                    ><span>{{ reportName.name }}</span>
                  </a>
                </div>
                <div *ngIf="reportName.subMenu.length > 0">
                  <div class="pt-15 pb-15 pl-30">
                    <div class="dash-border pb-1">
                      <div class="d-flex align-items-center mat-accordion">
                        <span>
                          <mat-icon class="material-symbols-outlined">
                            {{ reportName.icon }}
                          </mat-icon></span
                        ><span class="ml-10">{{ reportName.name }}</span>
                      </div>
                    </div>
                    <ul class="report-name">
                      <li *ngFor="let reportSubName of reportName.subMenu">
                        <a
                          href="{{ reportSubName.url }}"
                          class="link d-flex align-items-center gap-10 dash-border"
                        >
                          <span>
                            <mat-icon class="material-symbols-outlined">
                              {{ reportSubName.icon }}
                            </mat-icon></span
                          ><span>{{ reportSubName.name }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div *ngIf="submenu.subMenu.length == 0" class="report-name">
            <a
              href="{{ submenu.url }}"
              class="link d-flex align-items-center gap-10 dash-border"
            >
              <span>View {{ submenu.name }}</span>
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
