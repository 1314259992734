import { Guid } from 'guid-typescript';

export class GetUserDetailList {
  static readonly type = '[USER] Get User';

  constructor(public queryParams: any) {}
}

export class GetUserDataByUserId {
  static readonly type = '[USER] Get User Data By User Id';

  constructor(public userId: Guid) {}
}

export class ExportUser {
  static readonly type = '[USER] Export User';

  constructor(public exportParams: any) {}
}

export class DeleteUser {
  static readonly type = '[USER] Delete User';

  constructor(public userIds?: Array<Guid>) {}
}

export class GetUserList {
  static readonly type = '[USER] Get User List ';

  constructor(public queryParams: any) {}
}

export class ArchiveAndRestoreUser {
  static readonly type = '[USER] Archive and Restore Selected User';

  constructor(public userIds?: Array<Guid>, public isArchive?: boolean) {}
}

export class SetUserId {
  static readonly type = '[USER] Set User Id ';

  constructor(public userId: Guid) {}
}

export class GetAllUsers {
  static readonly type = '[USER] Get All Users';
}

export class SetUserDefaultState {
  static readonly type = '[USER] Set User Default State';
}

export class GetUserPermissionsList {
  static readonly type = '[USER] Get User Permissions List in User';
}

export class InviteUser {
  static readonly type = '[USER] Invite User';

  constructor(public id: Guid) {}
}

export class SaveUser {
  static readonly type = '[USER] Save User';

  constructor(public user: any) {}
}

export class GetUsersByPermission {
  static readonly type = '[USER] Get Users By Permission';
}

export class GetUserClientsDetails {
  static readonly type = '[USER] Get User Clients Details';

  constructor(public queryParams: any) {}
}

export class SaveUserCompanies {
  static readonly type = '[USER] Save User Companies';

  constructor(public userId: Guid, public userCompanies: Array<any>) {}
}

export class DeleteUserCompanyByUserId {
  static readonly type = '[USER] Delete User Company By User Id';

  constructor(public userId: Guid) {}
}
