import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { DefaultSettings, LogoOnReports } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  setReloadDashboardEmitter = new EventEmitter();
  constructor(
    private http: HttpClient,
    public datepipe: DatePipe,
    private commonService: CommonService
  ) {}

  saveDefaultSettings(defaultSettings: DefaultSettings): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}GeneralSettings/saveDefaultSettingsData`,
      JSON.stringify(defaultSettings),
      headers
    );
  }
  saveLogoOnReports(logoOnReports: LogoOnReports): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Business/saveBusinessSettingAsync`,
      JSON.stringify(logoOnReports),
      headers
    );
  }
  getDefaultSettingsData(): Observable<DefaultSettings> {
    return this.http.get<DefaultSettings>(
      `${environment.apiVersionUrl}GeneralSettings/getDefaultSettingsData`
    );
  }
  getLogoOnReportsData(): Observable<LogoOnReports> {
    return this.http.get<LogoOnReports>(
      `${environment.apiVersionUrl}Business/getBusinessSettingAsync`
    );
  }
}
