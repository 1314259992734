import { Injectable } from '@angular/core';
import {
  JournalsModel,
  MatchJournalModel,
  SideListModel,
} from '@app/core/Models';
import { JournalsService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreJournals,
  CreateJournals,
  DeleteJournals,
  GetDataByJournalsId,
  GetJournalNo,
  GetMatchingJournal,
  LinkJournal,
  QuickJournal,
} from './journals.action';

export class JournalsStateInfo {
  journalsData?: JournalsModel;
  journals: Array<JournalsModel>;
  journalsId?: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isJournalsAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
  journalNo?: any;
  matchJournal?: MatchJournalModel;
}

@State<JournalsStateInfo>({
  name: 'journals',
  defaults: {
    journals: [],
    journalsId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isJournalsAdded: false,
  },
})
@Injectable()
export class JournalsState {
  constructor(private journalsService: JournalsService) {}

  @Selector()
  static isLastPage(state: JournalsStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getJournalsId(state: JournalsStateInfo) {
    return state.journalsId;
  }

  @Action(CreateJournals)
  createJournals(
    { patchState }: StateContext<JournalsStateInfo>,
    action: CreateJournals
  ) {
    return this.journalsService.createJournals(action.journals).pipe(
      tap((res) => {
        patchState({
          journalsId: res,
          isJournalsAdded: true,
        });
      })
    );
  }

  @Action(GetDataByJournalsId)
  getDataByJournalsId(
    { patchState }: StateContext<JournalsStateInfo>,
    action: GetDataByJournalsId
  ) {
    return this.journalsService.getDataByJournalsId(action.journalsId).pipe(
      tap((res) => {
        patchState({
          journalsData: res,
        });
      })
    );
  }

  @Action(DeleteJournals)
  deleteJournals(
    { getState, setState }: StateContext<JournalsStateInfo>,
    action: DeleteJournals
  ) {
    return this.journalsService.deleteJournals(action.journalsIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredJournals = state.journals.filter(
          (item) =>
            !action.journalsIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.journalsIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        setState({
          ...state.journals,
          journals: filteredJournals,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreJournals)
  archiveAndRestoreJournals(
    { getState }: StateContext<JournalsStateInfo>,
    action: ArchiveAndRestoreJournals
  ) {
    return this.journalsService.archiveAndRestoreJournals(
      action.journalsIds,
      action.isArchive
    );
  }

  @Action(GetJournalNo)
  getJournalNo({ patchState }: StateContext<JournalsStateInfo>) {
    return this.journalsService.getJournalNo().pipe(
      tap((res) => {
        patchState({
          journalNo: res,
        });
      })
    );
  }

  @Action(QuickJournal)
  quickJournal(
    { patchState }: StateContext<JournalsStateInfo>,
    action: QuickJournal
  ) {
    return this.journalsService.quickJournal(action.quickJournals).pipe(
      tap((res) => {
        patchState({
          journalsId: res,
          isJournalsAdded: true,
        });
      })
    );
  }

  @Action(GetMatchingJournal)
  getMatchingJournals(
    { patchState }: StateContext<JournalsStateInfo>,
    action: GetMatchingJournal
  ) {
    return this.journalsService.getMatchingJournals(action.transactionId).pipe(
      tap((res) => {
        patchState({
          matchJournal: res,
        });
      })
    );
  }

  @Action(LinkJournal)
  linkJournal(
    { patchState }: StateContext<JournalsStateInfo>,
    action: LinkJournal
  ) {
    return this.journalsService.linkJournal(action.linkJournal).pipe(
      tap((res) => {
        patchState({
          journalsId: res,
          isJournalsAdded: true,
        });
      })
    );
  }
}
