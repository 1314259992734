import { Injectable } from '@angular/core';
import { DataType } from '@app/core/Enum';
import {
  FlatRateAdjusmentDataModel,
  HeaderModel,
  MainList,
  PaymentStatusViewModel,
  SubmitVATModel,
  VATReturnDateModel,
} from '@app/core/Models';
import { SubmitVatService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import { CommonStateInfo } from '../../common';
import {
  CheckVatNumber,
  CreateVat,
  CreateVATReturnStatus,
  DeleteBridgingVat,
  DeleteSubmitVat,
  ExportVatAudit,
  FinalVATUpdate,
  GetFinalReturn,
  GetFlatRateAdjustmentCalculation,
  GetSubmitVatList,
  GetVatDetails,
  GetVatPaymentStatusView,
  GetVatRemainingTransaction,
  GetvatRemainingTransactionHeader,
  GetVatReturnDate,
  RevertPaymentStatus,
  SaveIncludeExclude,
  SetDefaultVatId,
  SetSubmitVatId,
  UpdatePaymentStatus,
  VATFlatRatePosting,
} from './submit-vat.action';

export class SubmitVatStateInfo {
  vatData?: SubmitVATModel;
  vatReturnDate?: VATReturnDateModel;
  mainList?: MainList;
  totalRecord?: number;
  actions?: any;
  isLoading?: boolean;
  vatDataId? = Guid.EMPTY as unknown as Guid;
  vatReturnStatusId? = Guid.EMPTY as unknown as Guid;
  checkVatNumber?: boolean;
  headerList?: Array<HeaderModel>;
  isExport: boolean;
  paymentStatusUpdated: boolean;
  flatRateJournalPosted: boolean;
  getSubmitVatTransactions?: any;
  paymentStatusView?: PaymentStatusViewModel;
  flatRateAdjustmentData?: FlatRateAdjusmentDataModel;
  revertPaymentStatusUpdated: boolean;
  finalVATUpdated: boolean;
  finalReturn?: any;
}

@State<SubmitVatStateInfo>({
  name: 'submitVat',
  defaults: {
    isExport: false,
    paymentStatusUpdated: false,
    revertPaymentStatusUpdated: false,
    finalVATUpdated: false,
    flatRateJournalPosted: false,
  },
})
@Injectable()
export class SubmitVatState {
  constructor(private submitVatService: SubmitVatService) {}

  @Selector()
  static vatDataId(state: SubmitVatStateInfo): any {
    return state.vatDataId;
  }

  @Selector()
  static headerList(state: SubmitVatStateInfo): Array<HeaderModel> {
    if (!state.headerList) {
      return [];
    }

    return state.headerList;
  }

  @Selector()
  static totalRecord(state: SubmitVatStateInfo): number {
    return state.totalRecord ?? 0;
  }

  @Selector()
  static actions(state: SubmitVatStateInfo): any {
    if (!state.mainList) {
      return [];
    }

    return state.actions;
  }

  @Selector()
  static columns(state: SubmitVatStateInfo): Array<{
    name: string;
    id: Guid;
    dataType?: DataType;
    allowSortBy: boolean;
  }> {
    if (!state.mainList) {
      return [];
    }
    return state.mainList.resultSet.columns;
  }

  @Selector()
  static columnNames(state: SubmitVatStateInfo): Array<string> {
    if (!state.mainList) {
      return [];
    }

    return state.mainList.resultSet.columns.map((x) => x.name);
  }

  @Action(SaveIncludeExclude, { cancelUncompleted: true })
  saveIncludeExclude(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: SaveIncludeExclude
  ) {
    return this.submitVatService
      .saveIncludeExclude(action.params, action.id, action.isInclude)
      .pipe(
        tap((res) => {
          patchState({});
        })
      );
  }

  @Action(GetVatDetails)
  getVatDetails(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: GetVatDetails
  ) {
    return this.submitVatService.getVatDetail(action.id).pipe(
      tap((res) => {
        patchState({
          vatData: res,
        });
      })
    );
  }

  @Action(SetDefaultVatId)
  setDefaultVatId({ patchState }: StateContext<SubmitVatStateInfo>) {
    return patchState({
      vatDataId: Guid.EMPTY as unknown as Guid,
    });
  }

  @Action(SetSubmitVatId)
  setSubmitVatId(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: SetSubmitVatId
  ) {
    return patchState({
      vatDataId: action.id,
    });
  }

  @Action(CreateVat)
  createVat(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: CreateVat
  ) {
    return this.submitVatService.createVat(action.vatData).pipe(
      tap((res) => {
        patchState({
          vatDataId: res,
        });
      })
    );
  }

  @Action(GetVatReturnDate)
  getVatReturnDate(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: GetVatReturnDate
  ) {
    return this.submitVatService.getVatReturnDate().pipe(
      tap((res) => {
        patchState({
          vatReturnDate: res,
        });
      })
    );
  }

  @Action(GetVatRemainingTransaction, { cancelUncompleted: true })
  getVatRemainingTransaction(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: GetVatRemainingTransaction
  ) {
    return this.submitVatService
      .getVatRemainingTransaction(
        action.queryParams,
        action.isHeaderVisible,
        action.id
      )
      .pipe(
        tap({
          next: (result) => {
            patchState({
              mainList: result,
              totalRecord: result.paginationModel.totalItemCount,
              actions: result.actions,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(CreateVATReturnStatus)
  createVATReturnStatus(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: CreateVATReturnStatus
  ) {
    return this.submitVatService
      .createVATReturnStatus(action.vatReturnStatus, action.id)
      .pipe(
        tap((res) => {
          patchState({
            vatReturnStatusId: res,
          });
        })
      );
  }

  @Action(CheckVatNumber)
  checkVatNumber(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: CheckVatNumber
  ) {
    return this.submitVatService.checkVatNumber().pipe(
      tap((res) => {
        patchState({
          checkVatNumber: res,
        });
      })
    );
  }

  @Action(GetvatRemainingTransactionHeader, { cancelUncompleted: true })
  getvatRemainingTransactionHeader(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetvatRemainingTransactionHeader
  ) {
    return this.submitVatService.getvatRemainingTransactionHeader().pipe(
      tap({
        next: (result) => {
          patchState({
            headerList: result,
          });
        },
      })
    );
  }

  @Action(DeleteSubmitVat)
  deleteSubmitVat(
    { getState, patchState }: StateContext<SubmitVatStateInfo>,
    action: DeleteSubmitVat
  ) {
    return this.submitVatService
      .deleteSubmitVat(action.ids)
      .pipe(tap((res) => {}));
  }

  @Action(DeleteBridgingVat)
  deleteBridgingVat(
    { getState, patchState }: StateContext<SubmitVatStateInfo>,
    action: DeleteBridgingVat
  ) {
    return this.submitVatService
      .deleteBridgingVat(action.ids)
      .pipe(tap((res) => {}));
  }

  @Action(ExportVatAudit, { cancelUncompleted: true })
  exportVatAudit(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: ExportVatAudit
  ) {
    let isExport = false;
    return this.submitVatService.exportVatAudit(action.id, action.isPrint).pipe(
      tap((res) => {
        isExport = true;
        patchState({
          isExport,
        });
      })
    );
  }

  @Action(GetSubmitVatList, { cancelUncompleted: true })
  getSubmitVatList(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: GetSubmitVatList
  ) {
    return this.submitVatService
      .getSubmitVatList(action.queryParams, action.moduleId)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              getSubmitVatTransactions: result.resultSet.data,
              totalRecord: result.paginationModel.totalItemCount,
              actions: result.actions,
            });
          },
        })
      );
  }

  @Action(UpdatePaymentStatus)
  updatePaymentStatus(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: UpdatePaymentStatus
  ) {
    return this.submitVatService
      .updatePaymentStatus(action.paymentStatusUpdateModel)
      .pipe(
        tap((res) => {
          patchState({
            paymentStatusUpdated: res,
          });
        })
      );
  }

  @Action(VATFlatRatePosting)
  VATFlatRatePosting(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: VATFlatRatePosting
  ) {
    return this.submitVatService.vatFlatRatePosting(action.model).pipe(
      tap((res) => {
        patchState({
          flatRateJournalPosted: res,
        });
      })
    );
  }

  @Action(GetVatPaymentStatusView)
  getVatPaymentStatusView(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: GetVatPaymentStatusView
  ) {
    return this.submitVatService.getVatPaymentStatusView(action.id).pipe(
      tap((res) => {
        patchState({
          paymentStatusView: res,
        });
      })
    );
  }

  @Action(GetFlatRateAdjustmentCalculation)
  getFlatRateAdjustmentCalculation(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: GetFlatRateAdjustmentCalculation
  ) {
    return this.submitVatService
      .getFlatRateAdjustmentCalculation(action.id)
      .pipe(
        tap((res) => {
          patchState({
            flatRateAdjustmentData: res,
          });
        })
      );
  }

  @Action(GetFinalReturn)
  getFinalReturn(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: GetFinalReturn
  ) {
    return this.submitVatService.getFinalReturn().pipe(
      tap((res) => {
        patchState({
          finalReturn: res,
        });
      })
    );
  }

  @Action(RevertPaymentStatus)
  revertPaymentStatus(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: RevertPaymentStatus
  ) {
    return this.submitVatService.revertPaymentStatus(action.id).pipe(
      tap((res) => {
        patchState({
          revertPaymentStatusUpdated: res,
        });
      })
    );
  }

  @Action(FinalVATUpdate)
  finalVATUpdate(
    { patchState }: StateContext<SubmitVatStateInfo>,
    action: FinalVATUpdate
  ) {
    return this.submitVatService.finalVATUpdate().pipe(
      tap((res) => {
        patchState({
          finalVATUpdated: res,
        });
      })
    );
  }
}
