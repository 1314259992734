<app-menu-items></app-menu-items>
<div class="content-body-scroll">
  <div class="content-body mb-4">
    <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
      <mat-icon class="material-icons-outlined">error_outline</mat-icon>
      {{ commonNotificationText.ViewOnlyAccessMessage }}
    </div>
    <div class="accordion-toggle">
      <mat-icon
        (click)="toggleAccordion(); accordion.openAll()"
        *ngIf="isExpandAll"
        >keyboard_double_arrow_down
      </mat-icon>
      <mat-icon
        (click)="toggleAccordion(); accordion.closeAll()"
        *ngIf="!isExpandAll"
        >keyboard_double_arrow_up
      </mat-icon>
    </div>
    <mat-accordion [multi]="true">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title> Email </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-body pt-0">
          <h2 class="common-title font-size-16 fw-bold">
            Default method to send email
          </h2>
          <form>
            <div class="form-field wp-100 horizontal-radio-button">
              <mat-radio-group formControlName="isEmailSendFromCapium">
                <mat-radio-button
                  value="true"
                  (change)="onChange(true)"
                  [checked]="true"
                >
                  <h4>
                    Capium’s Company
                    <span *ngIf="showForm"> (Current Method)</span>
                  </h4>
                  <h5>
                    Emails will be sent from capium's Company for which your
                    clients cannot reply.
                  </h5>
                </mat-radio-button>
                <mat-radio-button value="false" (change)="onChange(false)">
                  <h4>
                    Gmail, Outlook<span *ngIf="!showForm">
                      (Current Method)</span
                    >
                  </h4>
                  <h5>
                    Connect your Gmail or Outlook account and emails to your
                    clients will be sent from that address. Each email will
                    appear in the “Sent” folder in Gmail or Outlook.
                  </h5>
                </mat-radio-button>
              </mat-radio-group>
              <h5 class="pl-2">
                <strong>Note:</strong> Capium’s Company cannot track emails sent
                via Gmail or Outlook.
              </h5>
            </div>

            <div class="white-box" *ngIf="!showForm">
              <div class="send-box">
                <div
                  class="d-flex align-items-center justify-space-between send-box-details"
                >
                  <div
                    class="d-flex align-items-center justify-space-between gap-20"
                  >
                    <img src="assets/images/gmail.svg" alt="Gmail" />
                    <div>
                      <p>Gmail</p>
                    </div>
                  </div>
                  <button mat-button disableRipple (click)="showGmailAccount()">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </button>
                </div>
                <div
                  class="sidenav-table wp-100 mt-1"
                  *ngIf="showGmailAccounts"
                >
                  <div class="mat-table-wrapper contact-detail-table">
                    <form>
                      <table
                        mat-table
                        [dataSource]="userMailList"
                        aria-describedby="Config settings table"
                      >
                        <ng-container matColumnDef="UserImage">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="w-50 text-align-center"
                          ></th>
                          <td
                            mat-cell
                            *matCellDef="let element; let i = index"
                            class="text-align-center"
                          >
                            <div *ngIf="element.photo" class="w-50">
                              <img
                                class="rounded-img"
                                [src]="element.photo"
                                alt="User"
                              />
                            </div>
                            <div *ngIf="!element.photo" class="rounded-border">
                              {{ element.emailAddress | shortName: 2 }}
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="EmailId">
                          <th mat-header-cell *matHeaderCellDef>Email ID</th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <span>{{ element.emailAddress }}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Date">
                          <th mat-header-cell *matHeaderCellDef class="w-100">
                            Date
                          </th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <span>{{
                              element.createdOn | date: "dd LLL yyyy"
                            }}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Default">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="w-100 text-align-center"
                          >
                            Default
                          </th>
                          <td
                            mat-cell
                            *matCellDef="let element; let i = index"
                            class="text-align-center"
                          >
                            <mat-radio-group aria-label="Select an option">
                              <mat-radio-button
                                value="{{ element.id }}"
                                [checked]="element.isDefault"
                                [disabled]="isViewPermission$ | async"
                              >
                              </mat-radio-button>
                            </mat-radio-group>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Action">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="w-50"
                          ></th>
                          <td
                            mat-cell
                            *matCellDef="let element; let i = index"
                            class="text-align-right"
                          >
                            <div class="grid-actions">
                              <button
                                mat-button
                                disableRipple
                                title="Delete"
                                [disabled]="!(isViewPermission$ | async)"
                              >
                                <mat-icon>delete_outline</mat-icon>
                              </button>
                            </div>
                          </td>
                        </ng-container>

                        <tr
                          mat-header-row
                          *matHeaderRowDef="displayGmailColumns; sticky: true"
                        ></tr>
                        <tr
                          mat-row
                          *matRowDef="let row; columns: displayGmailColumns"
                        ></tr>
                      </table>
                    </form>
                  </div>
                  <div class="add-account pt-1">
                    <button mat-button disableRipple>
                      <mat-icon class="text-gray-300 mr-1"
                        >person_pin
                      </mat-icon>
                      <span class="text-gray-300 font-size-16"
                        >Use Another Account</span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="white-box" *ngIf="!showForm">
              <div class="send-box">
                <div
                  class="d-flex align-items-center justify-space-between send-box-details"
                >
                  <div
                    class="d-flex align-items-center justify-space-between gap-20"
                  >
                    <img src="assets/images/outlook.svg" alt="Outlook" />
                    <div>
                      <p>Outlook</p>
                    </div>
                  </div>
                  <button
                    mat-button
                    disableRipple
                    (click)="showOutlookAccount()"
                  >
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </button>
                </div>
                <div
                  class="sidenav-table wp-100 mt-1"
                  *ngIf="showOutlookAccounts"
                >
                  <div class="mat-table-wrapper contact-detail-table">
                    <form>
                      <table
                        mat-table
                        [dataSource]="userOutlookList"
                        aria-describedby="Config settings table"
                      >
                        <ng-container matColumnDef="UserImage">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="w-50 text-align-center"
                          ></th>
                          <td
                            mat-cell
                            *matCellDef="let element; let i = index"
                            class="text-align-center"
                          >
                            <div *ngIf="element.photo" class="w-50">
                              <img
                                class="rounded-img"
                                [src]="element.photo"
                                alt="User"
                              />
                            </div>
                            <div *ngIf="!element.photo" class="rounded-border">
                              {{ element.emailAddress | shortName: 2 }}
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="EmailId">
                          <th mat-header-cell *matHeaderCellDef>Email ID</th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <span>{{ element.emailAddress }}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Date">
                          <th mat-header-cell *matHeaderCellDef class="w-100">
                            Date
                          </th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <span>{{
                              element.createdOn | date: "dd LLL yyyy"
                            }}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Default">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="w-100 text-align-center"
                          >
                            Default
                          </th>
                          <td
                            mat-cell
                            *matCellDef="let element; let i = index"
                            class="text-align-center"
                          >
                            <mat-radio-group aria-label="Select an option">
                              <mat-radio-button
                                value="{{ element.id }}"
                                [checked]="element.isDefault"
                                [disabled]="isViewPermission$ | async"
                              >
                              </mat-radio-button>
                            </mat-radio-group>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Action">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="w-50"
                          ></th>
                          <td
                            mat-cell
                            *matCellDef="let element; let i = index"
                            class="text-align-right"
                          >
                            <div class="grid-actions">
                              <button
                                mat-button
                                disableRipple
                                title="Delete"
                                [disabled]="isViewPermission$ | async"
                              >
                                <mat-icon>delete_outline</mat-icon>
                              </button>
                            </div>
                          </td>
                        </ng-container>

                        <tr
                          mat-header-row
                          *matHeaderRowDef="displayGmailColumns; sticky: true"
                        ></tr>
                        <tr
                          mat-row
                          *matRowDef="let row; columns: displayGmailColumns"
                        ></tr>
                      </table>
                    </form>
                  </div>
                  <div class="add-account pt-1">
                    <button mat-button disableRipple>
                      <mat-icon class="text-gray-300 mr-1"
                        >person_pin
                      </mat-icon>
                      <span class="text-gray-300 font-size-16"
                        >Use Another Account</span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel
        [expanded]="true"
        [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> Payment </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-body">
          <div class="capipay-box">
            <img
              src="assets/images/capipay-img.svg"
              alt="capiumPay"
              class="h-auto"
            />
            <h2>Introducing CapiumPay</h2>
            <p class="pb-5">
              Connect to CapiumPay now and start <br />
              accepting card payments
            </p>
            <a
              class="d-flex align-items-center justify-content-center gap-5 link"
              (click)="capiumPayIntegration()"
              >Connect to CapiumPay now
              <mat-icon fontSet="material-icons-outlined"
                >keyboard_double_arrow_right</mat-icon
              >
            </a>
          </div>

          <div>
            <div class="top-list-wrapper">
              <div class="font-size-18 text-primary fw-bold mb-1">
                Balance and Activity
              </div>
              <div class="list-card">
                <div class="d-flex gap-30">
                  <div class="bdr-left">
                    <p>
                      Payable Funds
                      <mat-icon
                        fontSet="material-icons-outlined"
                        matTooltipPosition="right"
                        matTooltip="Funds ready for payout. Funds are available for payout once settled, which occurs when they move from 'Pending' to 'Payable'."
                      >
                        info</mat-icon
                      >
                    </p>
                    <h3>-£2.40</h3>
                    <p>
                      Pending amount:
                      <span>£0.00</span>
                      <mat-icon
                        fontSet="material-icons-outlined"
                        matTooltipPosition="right"
                        matTooltip="Pending funds consist of transactions that have been processed, but have not yet been settled in your account. Credit card transactions will typically take up to 3 days to settle."
                        >info</mat-icon
                      >
                    </p>
                  </div>
                  <div>
                    <div class="d-flex align-items-center mb-1 gap-10">
                      <p>
                        Next scheduled payout: <span>No funds to payout</span>
                      </p>
                      <div class="daily" (click)="onDailyclick()">
                        <mat-icon fontSet="material-icons-outlined"
                          >settings</mat-icon
                        >
                        <p>Daily</p>
                      </div>
                    </div>

                    <button
                      class="action-button secondary-button mr-1"
                      type="button"
                      mat-button
                      disableRipple
                    >
                      Pay out now
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <mat-tab-group
              mat-align-tabs="start"
              disableRipple
              animationDuration="0ms"
              animationDuration="0ms"
            >
              <mat-tab label="Payments">
                <div class="main-list content">
                  <div class="mb-1">
                    <div
                      class="align-items-end content-header d-flex header-count header-filters justify-content-end header-count-1"
                    >
                      <div class="toolbar d-flex align-items-center gap-10">
                        <ng-select
                          class="w-150"
                          #status
                          (focus)="status.open()"
                          (valueChange)="status.close()"
                          panelClass="myPanelClass"
                        >
                          <ng-option>All Payments</ng-option>
                          <ng-option>Charged</ng-option>
                          <ng-option>Settled</ng-option>
                          <ng-option>Paid into bank</ng-option>
                        </ng-select>
                        <button
                          mat-button
                          [matMenuTriggerFor]="menu"
                          disableRipple
                        >
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu
                          #menu="matMenu"
                          class="action-menu more-menu"
                          xPosition="before"
                        >
                          <button mat-menu-item disableRipple>
                            <mat-icon>description</mat-icon>Export to CSV
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                  <div class="mat-table-wrapper">
                    <table
                      mat-table
                      [dataSource]="paymentdataSource"
                      aria-describedby="Main Grid Table"
                    >
                      <ng-container matColumnDef="reference">
                        <th mat-header-cell *matHeaderCellDef>Reference</th>
                        <td mat-cell *matCellDef="let element">
                          <a class="link">{{ element.reference }}</a>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="paid">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.paid }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="iddate">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.iddate }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef>Customer</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.customer }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="method">
                        <th mat-header-cell *matHeaderCellDef>Method</th>
                        <td mat-cell *matCellDef="let element">
                          <img
                            src="assets/images/visa.svg"
                            alt="Visa"
                            class="h-auto"
                          />
                          {{ element.method }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="charged">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          class="text-align-right"
                        >
                          Charged
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element"
                          class="text-align-right"
                        >
                          {{ element.charged }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="fee">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          class="text-align-right"
                        >
                          Fee
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element"
                          class="text-align-right"
                        >
                          {{ element.fee }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="net">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          class="text-align-right"
                        >
                          Net
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element"
                          class="text-align-right"
                        >
                          {{ element.net }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="chargedon">
                        <th mat-header-cell *matHeaderCellDef>Charged on</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.chargedon }}
                        </td>
                      </ng-container>
                      <tr
                        mat-header-row
                        *matHeaderRowDef="displayedPaymentColumns; sticky: true"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedPaymentColumns"
                      ></tr>
                    </table>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Disputes">
                <div class="mat-table-wrapper mt-1">
                  <table
                    mat-table
                    [dataSource]="disputesdataSource"
                    aria-describedby="Main Grid Table"
                  >
                    <ng-container matColumnDef="reference">
                      <th mat-header-cell *matHeaderCellDef>Reference</th>
                      <td mat-cell *matCellDef="let element">
                        <a class="link">{{ element.reference }}</a>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="paid">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.paid }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="iddate">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.iddate }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="customer">
                      <th mat-header-cell *matHeaderCellDef>Customer</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.customer }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="method">
                      <th mat-header-cell *matHeaderCellDef>Method</th>
                      <td mat-cell *matCellDef="let element">
                        <img
                          src="assets/images/visa.svg"
                          alt="Visa"
                          class="h-auto"
                        />
                        {{ element.method }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="charged">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        class="text-align-right"
                      >
                        Disputed amount
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="text-align-right"
                      >
                        {{ element.charged }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="fee">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        class="text-align-right"
                      >
                        Fee
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="text-align-right"
                      >
                        {{ element.fee }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="net">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        class="text-align-right"
                      >
                        Net
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="text-align-right"
                      >
                        {{ element.net }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="chargedon">
                      <th mat-header-cell *matHeaderCellDef>Charged on</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.chargedon }}
                      </td>
                    </ng-container>
                    <tr
                      mat-header-row
                      *matHeaderRowDef="displayedDisputesColumns; sticky: true"
                    ></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayedDisputesColumns"
                    ></tr>
                  </table>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<app-buttons *ngIf="!(isViewPermission$ | async)"></app-buttons>
