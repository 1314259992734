import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ControlType,
  HeaderFilter,
  Modules,
  MTD,
  NotificationTextMessage,
  ReportEnum,
} from '@app/core/Enum';
import {
  ExportType,
  GlobalComponent,
  HeaderModel,
  MainListParameters,
} from '@app/core/Models';
import { BankDasboardService, CommonService } from '@app/core/Services';
import {
  GetvatRemainingTransactionHeader,
  MenuState,
  SubmitVatState,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-submit-vat-header',
  templateUrl: './submit-vat-header.component.html',
  styleUrls: ['./submit-vat-header.component.scss'],
})
export class SubmitVatHeaderComponent implements OnInit {
  defaultValue: any[] = [
    { id: -1 },
    { id: -1 },
    { id: -1 },
    { id: -1 },
    { id: -1 },
  ];

  @Output()
  readonly triggerDataFromHeader = new EventEmitter<any>();

  @Input() listParameters: any;

  @Input() showAccountDetails: boolean;

  @Select(MenuState.hasAddPermission)
  hasAddPermission$: Observable<boolean>;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(MenuState.menu)
  menu$: Observable<any>;

  @Select(MenuState.moduleName)
  moduleName$: Observable<string>;

  controlType = ControlType;
  periodicDateList: any;

  @Select(SubmitVatState.headerList)
  headerList$: Observable<Array<HeaderModel>>;

  customDate = false;

  HeaderFilters = HeaderFilter;
  moduleEnum = Modules;
  moduleId: number;
  headerData: MainListParameters = new MainListParameters();
  exportType = ExportType;
  modules = Modules;
  periodicId = '-1';
  moduleName = '';
  reportlist: any[] = [];
  detailListId: number;
  mtd = MTD;
  private _moduleIdSubscription;
  triggerResetDetailList: Subject<any> = new Subject<any>();

  constructor(
    public commonService: CommonService,
    private store: Store,
    private bankDasboardService: BankDasboardService,
    public dialog: MatDialog,
    private globalComponent: GlobalComponent,
    private cookieService: CookieService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    for (let key in ReportEnum) {
      this.reportlist.push({ number: key, word: ReportEnum[key] });
    }

    this.periodicDateList = this.globalComponent.getFinancialPeriod();

    this.store.dispatch(new GetvatRemainingTransactionHeader());
    this.headerList$.subscribe((data) => {
      data?.forEach((item) => {
        if (item.value?.length > 0) {
          this.commonService.defaultGuidValue = item.value[0].id;
          this.onDropdownChange(item.name, item.value[0].id);
        }
      });
    });

    this.customDate = false;
    this.moduleName$.subscribe((x) => {
      this.moduleName =
        x[x.length - 1] === 's' ? x.substring(0, x.length - 1) : x;
    });
  }

  onAddClick(): void {
    this._moduleIdSubscription = this.moduleId$.subscribe((data) => {
      if (data !== Modules.BankDashboard) {
        this.commonService.onAddRouting(data);
      }
    });
    this._moduleIdSubscription.unsubscribe();
  }

  dataChangeFromHeader(): void {
    const params = {
      startDate: this.headerData.startDate,
      endDate: this.headerData.endDate,
      search: this.headerData.search,
      moduleId: this.headerData.moduleId,
      subModuleId: this.headerData.subModuleId,
      format: this.headerData.format,
      isPrint: this.headerData.isPrint,
      filter: this.headerData.filter,
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      isCustomDate: this.headerData.isCustomDate,
    };

    this.triggerDataFromHeader.emit(params);
  }

  onSearch(event: any): void {
    this.listParameters.pageNumber = 1;
    this.headerData.search = event.target.value;
    this.dataChangeFromHeader();
  }

  triggerDateChange(data: any): void {
    this.headerData.startDate = data.startDate;
    this.headerData.endDate = data.endDate;
    this.headerData.isCustomDate = data.isCustomDate;

    if (this.headerData.isCustomDate) {
      const endDate: any = this.datepipe
        .transform(data.endDate, 'yyyy-MM-dd')
        ?.toString();

      const startDate: any = this.datepipe
        .transform(data.startDate, 'yyyy-MM-dd')
        ?.toString();

      let dateValue: any = new Date(startDate);

      dateValue.setFullYear(dateValue.getFullYear() + 1);
      dateValue = this.datepipe.transform(dateValue, 'yyyy-MM-dd')?.toString();

      let date1Updated = new Date(dateValue.replace(/-/g, '/'));
      let date2Updated = new Date(endDate.replace(/-/g, '/'));

      if (date1Updated < date2Updated) {
        this.commonService.onFailure(NotificationTextMessage.exceedDateMessage);
        return;
      }
    }

    this.dataChangeFromHeader();
  }

  export(format: number, isPrint: boolean): void {
    const params = {
      startDate: this.headerData.startDate,
      endDate: this.headerData.endDate,
      filter: this.listParameters.filter,
      search: this.headerData.search,
      moduleId: this.headerData.moduleId,
      subModuleId: this.headerData.subModuleId,
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      format: format,
      isPrint: isPrint,
      isCustomDate: this.headerData.isCustomDate,
    };

    this.triggerDataFromHeader.emit(params);
  }

  onDropdownChange(headerName: any, value: any): void {
    if (headerName === HeaderFilter.Filter) {
      this.headerData.filter = value;
    }
    if (headerName === HeaderFilter.Module) {
      this.headerData.moduleId = value;
    }
    if (headerName === HeaderFilter.SubModule) {
      this.headerData.subModuleId = value;
    }

    this.listParameters.pageNumber = 1;
    this.dataChangeFromHeader();
  }

  selectCustomDate(): void {
    this.triggerResetDetailList.next(true);
  }
}
