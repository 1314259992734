import { Injectable } from '@angular/core';
import { Modules } from '@app/core/Enum';
import { AnnualReportModel } from '@app/core/Models';
import { AnnualReportService } from '@app/core/Services';
import { Action, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import { GetAnnualReportContent } from './annual-report.action';
export class AnnualReportStateInfo {
  annualReportModel: AnnualReportModel;
  accountingPeriodId? = Guid.EMPTY as unknown as Guid;
  moduleId? = Modules;
  isExport: boolean;
  blobFile?: any;
  exported?: boolean;
  getContent?: any;
}

@State<AnnualReportStateInfo>({
  name: 'annualReport',
})
@Injectable()
export class AnnualReportState {
  constructor(private annualReportService: AnnualReportService) {}

  @Action(GetAnnualReportContent)
  getAnnualReportContent(
    { patchState }: StateContext<AnnualReportStateInfo>,
    action: GetAnnualReportContent
  ) {
    return this.annualReportService
      .getAnnualReportContent(
        action.accountPeriodId,
        action.annualReportModel,
        action.moduleId
      )
      .pipe(
        tap((res) => {
          patchState({
            getContent: res,
          });
        })
      );
  }
}
