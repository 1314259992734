import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AccountGroupAndType,
  AccountModel,
  ChartOfAccountListModel,
  GroupListModel,
  SideListModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createAccount(account: AccountModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Account/save`,
      JSON.stringify(account),
      headers
    );
  }

  getTermsList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Term/all`
    );
  }

  getBusinessTypeList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}BusinessType/all`
    );
  }

  getDataByAccountId(accountId: Guid): Observable<AccountModel> {
    return this.http.get<AccountModel>(
      `${environment.apiVersionUrl}Account/get/${accountId}`
    );
  }

  getChartOfAccountList(moduleId?: number): Observable<SideListModel> {
    const entityType = this.commonService.getEntityTypeId(moduleId);

    return this.http.get<SideListModel>(
      `${environment.apiVersionUrl}ChartOfAccount/list/${entityType}`
    );
  }

  getChartOfAccountGroupList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}ChartOfAccount/getAllGroups`
    );
  }

  getChartOfAccountTypeList(
    groupId?: number
  ): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}ChartOfAccount/getAllTypes/${groupId}`
    );
  }

  getChartOfAllAccountsList(
    groupId?: number,
    typeId?: number
  ): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}ChartOfAccount/getAllAccounts/${groupId}/${typeId}`
    );
  }

  getJournalAccounts(): Observable<Array<GroupListModel>> {
    return this.http.get<Array<GroupListModel>>(
      `${environment.apiVersionUrl}Account/groupFilteredAccounts`
    );
  }

  getFilteredAccounts(): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}Account/filteredAccounts`
    );
  }

  deleteAccount(accountIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(accountIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Account/delete`,
      options
    );
  }

  copyAccount(accountIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Account/copy`,
      JSON.stringify(accountIds),
      headers
    );
  }

  archiveAndRestoreAccount(
    accountIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Account/archive/${isArchive}`,
      JSON.stringify(accountIds),
      options
    );
  }

  getSupplierList(entityId: number): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/all/AccountType/${entityId}`
    );
  }

  getAccountTypeList(
    accountTypeId: number
  ): Observable<Array<ChartOfAccountListModel>> {
    return this.http.get<Array<ChartOfAccountListModel>>(
      `${environment.apiVersionUrl}ChartOfAccount/getAll/accountType/${accountTypeId}`
    );
  }

  getAccountGroupAndType(
    accountGroupAndTypeId: Guid
  ): Observable<Array<AccountGroupAndType>> {
    return this.http.get<Array<AccountGroupAndType>>(
      `${environment.apiVersionUrl}Account/getAccountGroupAndType/${accountGroupAndTypeId}`
    );
  }

  getExplain(id: Guid): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}OpeningBalance/getExplain/${id}`
    );
  }

  getExplainListBasedOnAccountingPeriod(
    accountTypeId: number,
    accountingPeriodId: Guid
  ): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}openingBalance/explainListBasedOnAccountingPeriod/${accountTypeId}/${accountingPeriodId}`
    );
  }

  getExplainListBasedOnBranchId(
    accountTypeId: number,
    branchId: Guid
  ): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}openingBalance/explainListByBranch/${branchId}/${accountTypeId}`
    );
  }

  getExplainList(accountTypeId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}openingBalance/explainList/${accountTypeId}`
    );
  }

  getAdvanceList(accountTypeId: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}openingBalance/advanceList/${accountTypeId}`
    );
  }

  getAdvanceListBasedOnBranchId(
    accountTypeId: number,
    branchId: Guid
  ): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}openingBalance/advanceListByBranch/${branchId}/${accountTypeId}`
    );
  }

  getAccountsForBankFeed(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/getAccountsForBankFeed`
    );
  }

  getFixedAssetAdditionList(): Observable<Array<GroupListModel>> {
    return this.http.get<Array<GroupListModel>>(
      `${environment.apiVersionUrl}account/getGroupFixedAssetAddition`
    );
  }

  getSubcontractorList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}account/getSubcontractorList`
    );
  }
}
