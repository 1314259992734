import { LinkQuickEntryModel } from '@app/core/Enum/quick-entry';
import { QuickEntryModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class GetQuickEntryNumber {
  static readonly type = '[QuickEntry] Get QuickEntry Number';
}

export class GetCustomerSupplierList {
  static readonly type = '[QuickEntry] Get CustomerSupplier List';

  constructor(public quickEntryType: number) {}
}
export class CreateQuickEntry {
  static readonly type = '[QuickEntry] Create QuickEntry';

  constructor(public quickEntry: QuickEntryModel) {}
}
export class GetDataByQuickEntryId {
  static readonly type = '[QuickEntry] Get QuickEntry Data By QuickEntry Id';

  constructor(public quickEntryId: Guid) {}
}
export class DeleteQuickEntry {
  static readonly type = '[QuickEntry] Delete Selected QuickEntry';

  constructor(public quickEntryIds: Array<Guid>) {}
}
export class ArchiveAndRestoreQuickEntry {
  static readonly type = '[QuickEntry] Archive and Restore Selected QuickEntry';

  constructor(public quickEntryIds: Array<Guid>, public isArchive: boolean) {}
}
export class GetMatchingQuickEntry {
  static readonly type = '[QuickEntry] Get Matching QuickEntry';

  constructor(public transactionId: Guid) {}
}
export class LinkQuickEntry {
  static readonly type = '[QuickEntry] Link QuickEntry';

  constructor(public linkQuickEntry: LinkQuickEntryModel) {}
}
