import { ScheduleNote } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CreateScheduleNote {
  static readonly type = '[Schedule Note] Create Schedule Note';

  constructor(public scheduleNote: ScheduleNote) {}
}

export class GetDataByNoteId {
  static readonly type = '[Schedule Note] Get Note data by id';

  constructor(public noteId: Guid) {}
}

export class DeleteNote {
  static readonly type = '[Schedule Note] Delete Selected Note';

  constructor(public noteIds: Array<Guid>) {}
}

export class ArchiveAndRestoreNote {
  static readonly type =
    '[Schedule Note] Archive and Restore Selected Schedule Note';

  constructor(public noteIds: Array<Guid>, public isArchive: boolean) {}
}
