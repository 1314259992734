<div class="content-body">
  <form [formGroup]="advancePaymentDetailForm">
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Advance Received</p>
        <div class="d-flex">
          <button mat-button type="button" disableRipple matSuffix>
            <span class="symbol-align">{{ currencySymbol }}</span>
          </button>
          <mat-form-field>
            <input
              matInput
              formControlName="advanceReceived"
              autocomplete="off"
              allowNumberOnly
              [(ngModel)]="advancePayment"
              readonly
            />
          </mat-form-field>
        </div>
      </div>
      <div class="form-field">
        <p>Allocate Advance</p>
        <div class="d-flex">
          <button mat-button type="button" disableRipple matSuffix>
            <span class="symbol-align">{{ currencySymbol }}</span>
          </button>
          <mat-form-field>
            <input
              matInput
              formControlName="allocateAdvance"
              autocomplete="off"
              allow_10_2_Decimal
              (blur)="onAllocateAdvanceChange()"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
