import { MainListParameters } from '@app/core/Models';
import { GeneralDashBoardChartQueryParams } from '@app/core/Models/general-dashboard/general-dashboard';

export class GetGeneralDashboardCompanyList {
  static readonly type =
    '[GENERALDASHBOARD] Get General Dashboard Company List';
  constructor(public queryParams: MainListParameters) {}
}

export class GetGeneralDashboardChartData {
  static readonly type = '[GENERALDASHBOARD] Get General Dashboard Chart Data';
  constructor(public dashboardParams: GeneralDashBoardChartQueryParams) {}
}
