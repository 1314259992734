import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Company,
  CompanyListModel,
  CompanyModel,
  CompanyParameters,
  CompanyPreference,
  MainListParameters,
  SideListModel,
} from '@app/core/Models';
import { DuplicateExistModel } from '@app/core/Models/common/title';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';
@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createCompany(company: CompanyModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Company/save`,
      JSON.stringify(company),
      headers
    );
  }

  getCompanyList(params: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Company/list`,
      JSON.stringify(params),
      headers
    );
  }

  getDepartmentList(params: any): Observable<Array<Company>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<Company>>(
      `${environment.apiVersionUrl}department/list`,
      JSON.stringify(params),
      headers
    );
  }

  getBranchList(params: any): Observable<Array<Company>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<Company>>(
      `${environment.apiVersionUrl}branch/list`,
      JSON.stringify(params),
      headers
    );
  }

  getAvailableCompanyCount(): Observable<number> {
    return this.http.get<number>(`${environment.apiVersionUrl}Company/count`);
  }

  getVatReturnTypeList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Vat/all/returnType`
    );
  }

  setAsDefaultCompany(companyId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Company/${companyId}/setAsDefault`,
      JSON.stringify(companyId),
      headers
    );
  }

  setAsDefaultDepartment(departmentId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}department/${departmentId}/setAsDefault`,
      JSON.stringify(departmentId),
      headers
    );
  }

  setAsDefaultBranch(branchId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}branch/${branchId}/setAsDefault`,
      JSON.stringify(branchId),
      headers
    );
  }

  getDepartmentListByBranchIds(branchIds: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}branch/allDepartmentByBranchIds`,
      JSON.stringify(branchIds),
      headers
    );
  }

  getDepartmentListByBranch(
    branchId: Guid,
    queryParams: MainListParameters
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Branch/${branchId}/Departmentlist`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getDataByCompanyId(companyId: Guid): Observable<CompanyModel> {
    return this.http.get<CompanyModel>(
      `${environment.apiVersionUrl}Company/get/${companyId}`
    );
  }

  getCompanyType(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}CompanyType/all`
    );
  }

  getIndustry(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}IndustryType/all`
    );
  }

  getTimeZone(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Timezone/all`
    );
  }

  getLanguage(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Language/all`
    );
  }

  createCompanyPreference(company: CompanyPreference): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Company/saveCompanyPreference`,
      JSON.stringify(company),
      headers
    );
  }

  getCompanyPreferenceData(moduleId: number): Observable<CompanyPreference> {
    return this.http.get<CompanyPreference>(
      `${environment.apiVersionUrl}Company/getCompanyPreference/${moduleId}`
    );
  }

  getAccountingMethodList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}AccountingMethod/all`
    );
  }

  getVatSchemeList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Vat/all/scheme`
    );
  }

  deleteFinancialYear(ids?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(ids)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}AccountingPeriod/delete`,
      options
    );
  }

  lockFinancialYear(ids?: Array<Guid>, isLocked?: boolean): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}AccountingPeriod/locked/${isLocked}`,
      JSON.stringify(ids),
      options
    );
  }

  dateBetweenAccountingPeriod(date?: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}AccountingPeriod/dateBetweenAccountingPeriod`,
      JSON.stringify(date),
      options
    );
  }

  getCompanyNo(): Observable<any> {
    return this.http.get(`${environment.apiVersionUrl}Company/getCompanyno`, {
      responseType: 'text',
    });
  }

  getCompanyVatSchemaId(): Observable<any> {
    return this.http.get(
      `${environment.apiVersionUrl}Company/getCompanyVatSchemaId`,
      {
        responseType: 'text',
      }
    );
  }

  getCompanyTypeId(): Observable<any> {
    return this.http.get(`${environment.apiVersionUrl}Company/getCompanyType`, {
      responseType: 'text',
    });
  }

  getAllBranchList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Branch/all`
    );
  }

  getAllDepartmentListByBranch(
    branchId: Guid
  ): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Branch/${branchId}/allDepartment`
    );
  }

  getManageCompanyList(
    queryParams: CompanyParameters
  ): Observable<Array<CompanyListModel>> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Company/getCompany?companyName=${queryParams.companyName}&pageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}`
    );
  }

  deleteClient(clientIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}Company/DeleteCompanies`,
      JSON.stringify(clientIds),
      options
    );
  }

  archiveAndRestoreClient(
    clientIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const statusModel = {
      universalIds: clientIds,
      status: isArchive,
    };

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(statusModel),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Company/updateClientStatus`,
      JSON.stringify(statusModel),
      options
    );
  }

  isDuplicateExist(reqBody: DuplicateExistModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}Company/isDuplicateExist`,
      JSON.stringify(reqBody),
      headers
    );
  }
}
