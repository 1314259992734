import { Modules, MTD } from '@app/core/Enum';
import {
  ChartOfAccountListParam,
  ComparitiveReportParamerters,
  FileUploadRequestModel,
  GlobalStandardPermission,
  MainListParameters,
  MultipleFileDownloadModel,
  QueryParams,
  RefundInputParameters,
  ViewParamModel,
} from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class GetPermissions {
  static readonly type = '[COMMON] Get Permissions';
  constructor(public searchText?: string) {}
}

export class SaveBusinessPermission {
  static readonly type = '[COMMON] Save Business Permission';
  constructor(
    public standardPermission: GlobalStandardPermission[],
    public userId: Guid
  ) {}
}

export class SaveUserPermission {
  static readonly type = '[COMMON] Save User Permission';
  constructor(public standardPermission: any[], public userId: Guid) {}
}

export class GetUserBusinessPermission {
  static readonly type = '[COMMON] Get User Business Permission';
  constructor(public userId: Guid) {}
}

export class GetTitleList {
  static readonly type = '[COMMON] Get Titles';
}

export class GetFileAsByte {
  static readonly type = '[Common] Get File As Byte';

  constructor(public fileUrl: string) {}
}

export class GetSideList {
  static readonly type = '[SIDELIST] Get Side-List  List ';

  constructor(public queryParams: QueryParams, public moduleId: Modules) {}
}

export class GetMainList {
  static readonly type = '[MAINLIST] Get Main-List List ';

  constructor(
    public queryParams: MainListParameters,
    public moduleId: Modules
  ) {}
}

export class GetDetailList {
  static readonly type = '[DETAILLIST] Get Deatil-List List ';

  constructor(public queryParams: MainListParameters, public dataParam: any) {}
}

export class GetVatViewData {
  static readonly type = '[DETAILLIST] Get Vat View Data ';

  constructor(
    public queryParams: MainListParameters,
    public detailListId: any
  ) {}
}

export class GetNominalLedgerReport {
  static readonly type = '[NOMINALLEDGER] Get Nominal Ledger Report ';

  constructor(public queryParams: MainListParameters) {}
}

export class GetVatReportDetailList {
  static readonly type = '[REPORTLIST] Get Vat Report Detail List ';

  constructor(public vatId: number, public queryParams: any) {}
}

export class GetNominalLedgerTransaction {
  static readonly type =
    '[NOMINALLEDGERTRANSACTION] Get Nominal Ledger Transaction ';

  constructor(public queryParams: any) {}
}

export class GetVatReportList {
  static readonly type = '[REPORTLIST] Get Vat Report List ';

  constructor(public queryParams: any) {}
}

export class GetReportList {
  static readonly type = '[REPORTLIST] Get Report-List List ';

  constructor(
    public queryParams: MainListParameters,
    public moduleId: Modules
  ) {}
}

export class GetComparitiveReport {
  static readonly type = '[REPORTLIST] Get Comparitive Report ';

  constructor(public queryParams: ComparitiveReportParamerters) {}
}

export class GetCustomizeReportList {
  static readonly type = '[REPORTLIST] Get Customize Report List ';

  constructor(public queryParams: any) {}
}

export class GetAccountTransactionList {
  static readonly type =
    '[ACCOUNTTRANSACTIONLIST] Get Account transaction List ';

  constructor(public queryParams: MainListParameters, public id: Guid) {}
}

export class GetHeaderList {
  static readonly type = '[HEADERLIST] Get Header List ';

  constructor(public moduleId: Modules) {}
}

export class GetDetailHeaderList {
  static readonly type = '[DETAILHEADERLIST] Get Detail Header List ';

  constructor(public mtdId: MTD, public detailListModulesId: number) {}
}

export class Export {
  static readonly type = '[EXPORT] Export ';

  constructor(
    public queryParams: MainListParameters,
    public moduleId: Modules
  ) {}
}

export class GetComparitiveReportExport {
  static readonly type = '[EXPORT] Comparitive Report Export ';

  constructor(public queryParams: ComparitiveReportParamerters) {}
}

export class ReportExport {
  static readonly type = '[EXPORT] Report Export ';

  constructor(public queryParams: MainListParameters, public id: Guid) {}
}

export class ExportSubcontractorVerification {
  static readonly type = '[EXPORT] Export Subcontractor Verification ';

  constructor(public id: Guid) {}
}

export class CustomEmail {
  static readonly type = '[CUSTOMEMAIL] Custom Email';

  constructor(public moduleId: number, public emailData: any) {}
}

export class ExportReceipt {
  static readonly type = '[EXPORTRECEIPT] Export Receipt';

  constructor(
    public moduleId: Modules,
    public ids: Array<Guid>,
    public isPrint: boolean
  ) {}
}

export class EditReceipt {
  static readonly type = '[EditReceipt] Edit Receipt';

  constructor(public ids: Array<Guid>, public moduleId: Modules) {}
}

export class ReportDetailView {
  static readonly type = '[REPORTDETAILVIEW] Detail View';

  constructor(public id: Guid) {}
}

export class FileUpload {
  static readonly type = '[FILE] File Upload';

  constructor(public fileUploadRequestModel: FileUploadRequestModel) {}
}

export class GetCurrencyList {
  static readonly type = '[CURRENCY] Get Currency';
}

export class Copy {
  static readonly type = '[COPY] Copy';

  constructor(public moduleId: number, public ids: Array<Guid>) {}
}

export class FileDowload {
  static readonly type = '[FILE] File Dowload';

  constructor(public fileData: MultipleFileDownloadModel) {}
}

//#region transactions
export class GetVatRateList {
  static readonly type = '[VATRATE] Get Vat Rate List';
}

export class GetProductData {
  static readonly type = '[PRODUCT] Get Product Data By Product Id';

  constructor(public productId: Guid) {}
}
export class GetProductUnit {
  static readonly type = '[PRODUCT] Get Product Unit';
}

export class GetChartOfAccountsBasedOnGroupId {
  static readonly type = '[CHARTOFACCOUNT] Get Invoice Type';

  constructor(public groupIds: Array<number>) {}
}

export class GetAccountListByNature {
  static readonly type = '[CHARTOFACCOUNT] Get Account List By Nature';

  constructor(public natures: Array<string>) {}
}

export class GetChartOfAccountsBasedOnGroupIdAndTypeId {
  static readonly type =
    '[ChartOFACCOUNT] Get Accounts Based On GroupId And TypeId';

  constructor(public param: ChartOfAccountListParam) {}
}

export class GetDefaultCurrency {
  static readonly type = '[CURRENCY] Get Default Currency';
}

export class GetModuleDetailView {
  static readonly type = '[INVOICEVIEW] Get Invoice View';

  constructor(public param: ViewParamModel) {}
}

export class GetStandardAccountList {
  static readonly type = '[ACCOUNT] Get Standard Account List';

  constructor(public entityId: Array<number>) {}
}

export class AdvanceAllotmentAccounts {
  static readonly type = '[ACCOUNT] Advance Allotment Accounts';

  constructor(public entityId: Array<number>) {}
}

export class AdvanceAllotmentAccountsByBranch {
  static readonly type = '[ACCOUNT] Advance Allotment Accounts By Branch';

  constructor(public entityId: Array<number>, public branchId: any) {}
}

export class ExplainAllotmentAccounts {
  static readonly type = '[ACCOUNT] Explain Allotment Accounts';

  constructor(public entityId: Array<number>) {}
}

export class ExplainAllotmentAccountsByBranch {
  static readonly type = '[ACCOUNT] Explain Allotment Accounts By Branch';

  constructor(public entityId: Array<number>, public branchId: any) {}
}

export class GetNonStandardAccountList {
  static readonly type = '[ACCOUNT] Get Non Standard Account List';

  constructor(public entityId: Array<number>) {}
}

export class GetNonStandardAccountListByBranch {
  static readonly type = '[ACCOUNT] Get Non Standard Account List By Branch';

  constructor(public entityId: Array<number>, public branchId: Guid) {}
}

//#endregion
export class GetFinancialPeriod {
  static readonly type = '[COMMON] Get Periodic Year';
}

export class GetCustomBankAccountList {
  static readonly type = '[ACCOUNT] Get Custom Bank Account List';
}

export class GetGroupList {
  static readonly type = '[ACCOUNT] Get Group All List';
}

export class GetAccountsBasedOnGroupId {
  static readonly type = '[ACCOUNT] Get Accounts Based on GroupId';

  constructor(public groupIds: Array<number>) {}
}

export class GetGroupAccountsBasedOnGroupId {
  static readonly type = '[ACCOUNT] Get Group Accounts Based on GroupId';

  constructor(public groupIds: Array<number>, public moduleId: number) {}
}

export class GetGroupAccountsBasedOnGroupIdAndTypeId {
  static readonly type =
    '[ACCOUNT] Get Group Accounts Based On GroupId And TypeId';

  constructor(public param: ChartOfAccountListParam) {}
}

export class GetAccountsBasedOnGroupIdAndTypeId {
  static readonly type = '[ACCOUNT] Get Accounts Based On GroupId And TypeId';

  constructor(public param: ChartOfAccountListParam) {}
}

export class GetGroupAccountList {
  static readonly type = '[ACCOUNT] Get Group Account List';

  constructor(public entityId: Array<number>) {}
}

export class GetCustomBankAndCashAccount {
  static readonly type = '[ACCOUNT] Get Custom Bank And Cash Account List';
}

export class GetCustomBankAccount {
  static readonly type = '[ACCOUNT] Get Custom Bank Account';
}

export class GetCreditorDebtorReport {
  static readonly type = '[REPORT] Get Creditor Debtor Report ';

  constructor(
    public queryParams: MainListParameters,
    public moduleId: number
  ) {}
}

export class GetCreditorDebtorTransaction {
  static readonly type = '[REPORT] Get Creditor Debtor Transaction ';

  constructor(
    public queryParams: any,
    public moduleId: number,
    public id: any
  ) {}
}

export class CheckCustomerSupplierDuplicate {
  static readonly type = '[IMPORT] Check Customer Supplier Duplicate';

  constructor(
    public name: string,
    public email: string,
    public moduleId: number
  ) {}
}

export class CheckEntryNumber {
  static readonly type = '[IMPORT] Check Entry Number';

  constructor(public entryNumber: string, public moduleId: number) {}
}

export class UpdateThemeColor {
  static readonly type = '[COLOR] Update Theme Color';
  constructor(public colorCode: string) {}
}

export class GetCashAccountList {
  static readonly type = '[ACCOUNT] Get Cash Account List';
}

export class SendEmail {
  static readonly type = '[SENDEMAIL] Send Email';

  constructor(
    public moduleId: Modules,
    public id: Guid,
    public paymentLink: boolean = false
  ) {}
}

export class GetTransactionLogData {
  static readonly type = '[ACCOUNTTRANSACTIONLIST] Get Transaction Log Data ';

  constructor(public id: Guid) {}
}

export class GetGroupCustomBankAndCashAccount {
  static readonly type =
    '[BANKTRANSFER] Get Group Custom Bank And Cash Account';
}

export class GetGroupCustomBankAndCashAccountByBranch {
  static readonly type =
    '[BANKTRANSFER] Get Group Custom Bank And Cash Account By Branch';

  constructor(public branchId: Guid) {}
}

export class DeleteBankImportTransactionHistory {
  static readonly type = '[COMPANY] Delete Financial Year';

  constructor(public ids: Array<Guid>) {}
}

export class DeleteBankImport {
  static readonly type = '[COMPANY] Delete Bank Import';

  constructor(public ids: Array<Guid>) {}
}

export class SaveColumnsUserLevel {
  static readonly type = '[COMPANY] Save Columns User Level';

  constructor(public data: any) {}
}

export class GetAdvancePaymentList {
  static readonly type = '[ADVANCEPAYMENT Get Non Standard Account List';

  constructor(public entityId: Guid, public currencyId: number) {}
}

export class GetDueInvoicesReceipt {
  static readonly type = '[RECEIPT] Get Due Invoices Receipt';

  constructor(public receiptId: Guid, public currencyId: number) {}
}

export class GetBankAccountTransactionData {
  static readonly type =
    '[ACCOUNTTRANSACTIONLIST] Get Bank Account Transaction Data ';

  constructor(public id: Guid) {}
}

export class GetAllocationData {
  static readonly type = '[COMMON] Get Allocation Data';

  constructor(public id: Guid, public moduleId: number) {}
}

export class SaveAllocation {
  static readonly type = '[COMMON] Save Allocation';

  constructor(public allocationData: any, public moduleId: number) {}
}

export class GetUnExplainedBankTransactions {
  static readonly type = '[COMMON] Get UnExplained Bank Transactions ';

  constructor(public postingAccountId: any) {}
}

export class SaveReconciliation {
  static readonly type = '[COMMON] Save Reconciliation';

  constructor(public reconcilationData: any) {}
}

export class CreateCustomReport {
  static readonly type = '[COMMON] Create CustomReport';

  constructor(public customReportQuery: any) {}
}

export class GetCustomReportQuery {
  static readonly type = '[REPORTDETAILVIEW] Get Custom Report Query';

  constructor(public id: Guid) {}
}

export class DeleteCustomReport {
  static readonly type = '[CUSTOM REPORT] Delete Selected Custom Report';

  constructor(public ids: Array<Guid>) {}
}

export class CustomReportExport {
  static readonly type = '[CUSTOM REPORT] Custom Report Export ';

  constructor(public queryParams: any) {}
}

export class GetAccountBranchId {
  static readonly type = '[ACCOUNT] Get Account BranchId ';

  constructor(public id: Guid) {}
}
export class ConvertToInvoice {
  static readonly type = '[COMMON] Convert To Invoice';

  constructor(public id: Guid) {}
}

export class GetClientTypes {
  static readonly type = '[COMMON] Get Client Types';
}

export class GetIsVatRegistered {
  static readonly type = '[Common] Get Is Vat Registered';
}

export class GetRefundData {
  static readonly type = '[COMMON] Get Refund Data';

  constructor(
    public refundInputParameters: RefundInputParameters,
    public moduleId: number
  ) {}
}

export class DeleteBranch {
  static readonly type = '[BRANCH] Delete Branch';

  constructor(public ids: Array<Guid>) {}
}

export class DeleteDepartment {
  static readonly type = '[DEPARTMENT] Delete Department';

  constructor(public ids: Array<Guid>) {}
}

export class DeleteAccountingPeriod {
  static readonly type = '[FINANCIALYEAR] Delete Accounting Period';

  constructor(public ids: Array<Guid>) {}
}
export class UnAllocateAmount {
  static readonly type = '[Transactions] UnAllocate Amount ';

  constructor(public id: Guid, public typeId: number) {}
}
