import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Modules } from '@app/core/Enum';
import { RecurringModule } from '@app/core/Models';
import { MenuState } from '@app/core/Store';
import { environment } from '@environments/environment';
import { Select } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecurringModuleService {
  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  constructor(private http: HttpClient) {}

  createRecurringModule(invoice: RecurringModule): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}${this.getRecurringType()}/save`,
      JSON.stringify(invoice),

      headers
    );
  }

  getRecurringType(): string {
    let recurringType;

    this.moduleId$.subscribe((moduleId) => {
      recurringType =
        moduleId === Modules.RecurringPurchase
          ? 'RecurringPurchase'
          : 'RecurringInvoice';
    });
    return recurringType;
  }

  getDataByRecurringModuleId(recurringId: Guid): Observable<RecurringModule> {
    return this.http.get<RecurringModule>(
      `${
        environment.apiVersionUrl
      }${this.getRecurringType()}/get/${recurringId}`
    );
  }

  deleteRecurringData(recurringIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(recurringIds),
    };

    return this.http.delete<any>(
      `${environment.apiVersionUrl}${this.getRecurringType()}/delete`,
      options
    );
  }

  copyRecurringData(recurringIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${
        environment.apiVersionUrl
      }${this.getRecurringType()}/copy${this.getRecurringType()}`,
      JSON.stringify(recurringIds),
      headers
    );
  }

  archiveAndRestoreRecurring(
    recurringIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${
        environment.apiVersionUrl
      }${this.getRecurringType()}/archive/${isArchive}`,
      JSON.stringify(recurringIds),
      options
    );
  }

  getRecurringNumber(moduleId?: number): Observable<any> {
    let url = '';

    switch (moduleId) {
      case Modules.RecurringInvoice:
        url = `RecurringInvoice/getRecurringInvoiceNo`;
        break;
      case Modules.RecurringPurchase:
        url = `RecurringPurchase/getRecurringPurchaseNo`;
        break;
      default:
        break;
    }
    return this.http.get(`${environment.apiVersionUrl}${url}`, {
      responseType: 'text',
    });
  }
}
