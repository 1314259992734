import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaxLength } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import {
  GetAllBranchList,
  GetAllDepartmentListByBranch,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { NgxSummernoteModule } from 'ngx-summernote';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-minutes-of-meetings',
  templateUrl: './add-minutes-of-meetings.component.html',
  styleUrls: ['./add-minutes-of-meetings.component.scss'],
})
export class AddMinutesOfMeetingsComponent implements OnInit {
  @ViewChild('picker') picker: any;
  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  configsummernote: NgxSummernoteModule = {
    placeholder: '',
    tabsize: 2,
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table']],
    ],
    fontNames: [
      'Helvetica',
      'Arial',
      'Arial Black',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
    ],
  };

  maxLength = MaxLength;
  momForm: FormGroup;
  departmentList: any[] = [];
  branchList: any[] = [];
  @Input() triggerDefaultData: Observable<any>;
  @Input() triggerEditData: Observable<any>;

  constructor(private store: Store, private commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.getBranchList();
    this.triggerDefaultData.subscribe((res) => {
      this.momForm.controls.summaryNote.setValue(res);
    });

    this.triggerEditData.subscribe((res) => {
      this.editForm(res);
    });

    this.momForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.momForm.touched;
    });
  }

  editForm(data: any): void {
    this.momForm.patchValue({
      summaryNote: data.description,
      meetingType: data.meetingType,
      meetingDate: data.meetingDate,
      branchId: data.branchId,
      departmentId: data.departmentId,
    });
    if (data.branchId !== null && data.branchId !== undefined) {
      this.getDepartmentListByBranch(data.branchId);
    }
  }

  setForm(): void {
    this.momForm = new FormGroup({
      summaryNote: new FormControl(''),
      meetingType: new FormControl('', [Validators.required]),
      meetingDate: new FormControl('', [Validators.required]),
      branchId: new FormControl(''),
      departmentId: new FormControl(''),
    });
  }

  getBranchList(): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.momForm.controls.branchId.setValue(this.branchList[0].id);
            this.momForm.controls.branchId.setValidators(Validators.required);
            this.momForm.controls.branchId.updateValueAndValidity();
            this.getDepartmentListByBranch(this.branchList[0].id);
          } else {
            this.branchList = [];
            this.departmentList = [];
          }
        })
      )
      .subscribe();
  }

  onChangeBranch(branch): void {
    this.getDepartmentListByBranch(branch);
  }

  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .pipe(
          tap((res) => {
            this.departmentList = [];
            if (res.company.department.length > 0) {
              this.departmentList = res.company.department;
              this.momForm.controls.departmentId.setValue(
                this.departmentList[0].id
              );
              this.momForm.controls.departmentId.setValidators(
                Validators.required
              );
              this.momForm.controls.departmentId.updateValueAndValidity();
            } else {
              this.momForm.controls.departmentId.clearValidators();
              this.momForm.controls.departmentId.updateValueAndValidity();
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
      this.momForm.controls.departmentId.clearValidators();
      this.momForm.controls.departmentId.updateValueAndValidity();
    }
  }
}
