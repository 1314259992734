<div class="content-body content-body-scroll">
  <div class="sidenav-body-content">
    <div class="main-list content">
      <div class="content-header d-flex align-items-end wp-100 mb-1 gap-10">
        <div class="grid-search">
          <mat-form-field appearance="fill" class="search-field">
            <input
              matInput
              placeholder="Search"
              autocomplete="off"
            />
            <button mat-button disableRipple class="search-button">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="year-picker mr-10">
          <div class="d-flex flex-column">
            <span class="fw-bold mb-5">Period:</span>
            <app-custom-year-picker
              [moduleId]="moduleId"
              (triggerDateChange)="triggerDateChange($event)"
            ></app-custom-year-picker>
          </div>
        </div>
        <div class="content-right-button d-flex flex-column gap-5 mr-10">
            <div class="d-flex flex-column">
                <span class="fw-bold mb-5">Filter by:</span>
                <ng-select
                    class="w-120"
                    #selectType
                    (focus)="selectType.open()"
                    (valueChange)="selectType.close()"
                    panelClass="myPanelClass"
                    [disableOptionCentering]="true"
                >
                    <ng-option>Contact</ng-option>
                    <ng-option>Ref. No.</ng-option>
                </ng-select>
            </div>
        </div>

        <div class="header-actions wp-100">
          <div class="align-items-end content-header d-flex header-count header-filters justify-content-end header-count-1">
            <div class="toolbar d-flex align-items-center gap-10">              
              <button mat-button [matMenuTriggerFor]="menu" disableRipple>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu
                #menu="matMenu"
                class="action-menu more-menu"
                xPosition="before"
              >
                <button
                  mat-menu-item
                  disableRipple
                >
                  <mat-icon fontSet="material-icons-outlined"
                    >picture_as_pdf</mat-icon
                  >Export As PDF
                </button>
                <button
                  mat-menu-item
                  disableRipple
                >
                  <mat-icon>border_all</mat-icon>Export As Excel
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-tab-group
        mat-align-tabs="start"
        disableRipple
        animationDuration="0ms"
        animationDuration="0ms "
      >
        <mat-tab label="VAT Report">
          <div class="pt-1">
            <app-vat-details-grid></app-vat-details-grid>
          </div>
        </mat-tab>
        <mat-tab label="No VAT Report">
          <div class="pt-1">
            <app-vat-details-grid></app-vat-details-grid>
          </div> 
        </mat-tab>
    </mat-tab-group>            
  </div>
</div>