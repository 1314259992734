import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmationType,
  ModuleName,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
  Stepper,
  VATReturnStatus,
} from '@app/core/Enum';
import {
  FinancialData,
  GlobalComponent,
  SubmitVATModel,
} from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  CommonState,
  CreateVat,
  CreateVATReturnStatus,
  GetVatDetails,
  GetVatRetunDetails,
  GetVatReturnDate,
  MenuState,
  SetSubmitVatId,
  SubmitVatState,
} from '@app/core/Store';

import {
  AddClosePopupComponent,
  AddSubmitVatComponent,
  ConfirmationBoxComponent,
  IncludeExcludeTransactionComponent,
  VATDetailsComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { DiscountFlatRateComponent } from './add-submit-vat/discount-flat-rate/discount-flat-rate.component';

@Component({
  selector: 'app-submit-vat',
  templateUrl: './submit-vat.component.html',
  styleUrls: ['./submit-vat.component.scss'],
})
export class SubmitVatComponent implements OnInit, AfterViewInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isFirstStepDone = false;
  isSecondStepDone = false;
  isThirdStepDone = false;
  capiumButtonShow = false;
  hmrcButtonShow = false;
  isDisable = true;

  moduleId = Modules.SubmitVat;
  moduleName = ModuleName.SubmitVat;
  stepperEnum = Stepper;
  vatReturnStatus = VATReturnStatus;

  submitVatId = Guid.EMPTY as unknown as Guid;
  submitVatData: SubmitVATModel;
  id = Guid.EMPTY as unknown as Guid;
  subReturnId=Guid.EMPTY as unknown as Guid;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddSubmitVatComponent, { static: false })
  submitVatDetails;

  @ViewChild(VATDetailsComponent, { static: false })
  vatDetails;

  @ViewChild(IncludeExcludeTransactionComponent, { static: false })
  includeExcludeTransactionDetails;

  @ViewChild('horizontalStepper') stepper: MatStepper;

  subscriptionRouting: Subscription;

  triggerEditData: Subject<any> = new Subject<any>();

  @Select(CommonState.periodicDate)
  periodicDate$: Observable<Array<FinancialData>>;
  isEdit = false;
  commonService: CommonService;
  store: Store;
  spinner: NgxSpinnerService;
  notifier: NotificationService;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  vatReturnDetailsList: any = [];
  totalVATAmount = 0;
  isProceedtoSubmit = false;
  isTrailUser: boolean = false;
  _Activatedroute: ActivatedRoute
  vatReturnData:any;
  isSubmitHmrc:boolean=false;
  isDeclerationChecked:boolean=false;
  constructor(
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public cookieService: CookieService,
    private router: Router,

    private renderer: Renderer2,
    private injector: Injector,
    private globalComponent: GlobalComponent
  ) {
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.commonService = injector.get<CommonService>(CommonService);
    this.store = injector.get<Store>(Store);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.notifier = injector.get<NotificationService>(NotificationService);
  }

  ngOnInit(): void {
    this.spinner.hide();
    this.isTrailUser = this.globalComponent.getIsTrailUser();
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          console.log(params.get('isSubmit'),'params')
          this.isEdit = true;
          this.id = atob(params.get('id')!) as unknown as Guid;
          this.store.dispatch(new SetSubmitVatId(this.id));
          this.editVatDetails();

          if(params.get('isSubmit')){
            this.isSubmitHmrc=true;
            this.isDisable=false;
            this.isDeclerationChecked=true;
            this.spinner.show();
            setTimeout(() => {
              this.redirectTab();
            },100);
          }else{
            this.isSubmitHmrc=false;
            this.isDisable=true;
            this.isDeclerationChecked=false;
          }
        
        } else {
          this.getVatReturnDate();
        }
      }
    );
  }

  redirectTab():void{
    this.isSecondStepDone = true;
    this.capiumButtonShow = false;
    this.hmrcButtonShow=true
    this.stepper.linear = false;
    this.stepper.selectedIndex = 2;
      setTimeout(() => {
        this.stepper.linear = true;
        this.spinner.hide();
      });
  
  }
  ngAfterViewInit(): void {
    this.id =
      this.store.selectSnapshot(SubmitVatState.vatDataId) ??
      (Guid.EMPTY as unknown as Guid);

    if (this.id !== (Guid.EMPTY as unknown as Guid) && !this.isEdit) {
      this.isFirstStepDone = true;
      this.stepper.linear = false;
      this.stepper.selectedIndex = 1;
      setTimeout(() => {
        this.stepper.linear = true;
      });
    }
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
public editVatData:any;
  editVatDetails(): void {
    this.store.dispatch(new GetVatDetails(this.id)).subscribe((res) => {
      this.triggerEditData.next(res.submitVat.vatData);
      this.editVatData=res.submitVat.vatData;
    });
  }

  dataSubmit(): boolean {
   
   let list:any=[];
  
   this.submitVatDetails.gridList.forEach(element => {

   // let obj={"id":this.subReturnId,"fromDate":element.from,"toDate":element.to,"flateRate":element.rate};
   let obj={"id":this.subReturnId,
    "fromDate":
    element.from,
   
    "toDate":
    element.to,
     
    "flateRate":element.rate}; 

   list.push(obj);
   });
    this.spinner.show();
    try {
      this.submitVatData = {
        id: this.id,
        fromDate: this.datepipe
          .transform(
            this.submitVatDetails.submitVatForm.controls.fromDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        toDate: this.datepipe
          .transform(
            this.submitVatDetails.submitVatForm.controls.toDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        description:
          this.submitVatDetails.submitVatForm.controls.description.value,
        finalReturn:
          this.submitVatDetails.submitVatForm.controls.finalReturn.value,
          vatSchemeId:this.isEdit?this.editVatData?.vatSchemeId:this.vatReturnData.vatSchemeId,
        subReturns:this.isEdit?this.submitVatDetails.gridList:list
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onNextClick(isExit: boolean) {
    if (this.stepper.selectedIndex === 0) {
      if (this.submitVatDetails.submitVatForm.invalid) {
        this.commonService.addValidation(
          this.submitVatDetails.submitVatForm,
          this.renderer
        );
      } else {
        if (this.dataSubmit()) {
          this.store.dispatch(new CreateVat(this.submitVatData)).subscribe(
            (res) => {
              if (res !== undefined) {
                if (!isExit) {
                  this.cancelClick(isExit);
                } else {
                  this.isFirstStepDone = true;
                  this.stepper.linear = false;
                  this.stepper.selectedIndex = 1;
                  setTimeout(() => {
                    this.stepper.linear = true;
                  });
                }
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
        }
      }
    } else if (this.stepper.selectedIndex === 1) {
      this.isSecondStepDone = true;
      this.capiumButtonShow = true;
      this.stepper.linear = false;
      this.stepper.selectedIndex = 2;
      setTimeout(() => {
        this.stepper.linear = true;
      });
    }
  }

  onSave(vatReturnStatus) {
    this.id = this.store.selectSnapshot(SubmitVatState.vatDataId);
    setTimeout(() => {
      this.store
        .dispatch(
          new GetVatRetunDetails(this.id ?? (Guid.EMPTY as unknown as Guid))
        )
        .subscribe((res) => {
          this.vatReturnDetailsList =
            res.viewVatDetails.vatReturnDetailsData.resultSet.data;
          this.totalVATAmount = 0;
          this.vatReturnDetailsList.forEach((item) => {
            this.totalVATAmount = this.totalVATAmount + +item[2];
          });
        });
    }, 500);

    if (vatReturnStatus === VATReturnStatus.SubmitToHMRC) {
      this.isProceedtoSubmit = false;
      if (this.vatReturnDetailsList.length > 0 && this.totalVATAmount === 0) {
        this.dialog
          .open(ConfirmationBoxComponent, {
            data: {
              type: ConfirmationType.SubmitNillVAT,
              moduleId: Modules.SubmitVat,
              headerText: NotificationHeader.confirmation,
              detailText: NotificationDetails.submitNillVAT,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.isProceedtoSubmit = true;
              this.onSubmit(vatReturnStatus);
            } else {
              this.isProceedtoSubmit = false;
            }
          });
      } else {
        this.isProceedtoSubmit = true;
        this.onSubmit(vatReturnStatus);
      }
    } else {
      this.isProceedtoSubmit = true;
      this.onSubmit(vatReturnStatus);
    }
  }
  onSubmit(vatReturnStatus): void {
    this.store
      .dispatch(new CreateVATReturnStatus(vatReturnStatus, this.id))
      .subscribe(
        (res) => {
          this.capiumButtonShow = false;
          if (res.submitVat.vatReturnStatusId) {
            if (vatReturnStatus === VATReturnStatus.SubmitToCapium) {
              this.hmrcButtonShow = true;
              this.isProceedtoSubmit = false;
              this.onCapiuamSuccess();
            } else {
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
              this.router.navigate([RoutingPath.SubmitVAT]);
            }
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        },
        (err) => {
          this.commonService.onFailure(err.message);
        }
      );
  }

  cancelClick(isCancelClick: boolean) {
    if (!isCancelClick) {
      this.router.navigate([RoutingPath.SubmitVAT]);
    }
  }

  onPrevClick(stepper) {
    this.id = this.store.selectSnapshot(SubmitVatState.vatDataId);
    if (this.id) {
      this.editVatDetails();
    }
    stepper.previous();
    this.isSecondStepDone = false;
  }

  onCheckBoxChanges(event: any): void {
    if (event.checked) {
      this.isDisable = false;
    } else {
      this.isDisable = true;
    }
  }

  onCapiuamSuccess(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.capiumSaveMessage
    );
  }

  async getVatReturnDate(): Promise<void> {
    await this.store
      .dispatch(new GetVatReturnDate())
      .toPromise()
      .then((res) => {
        this.submitVatDetails.submitVatForm.controls.fromDate.setValue(
          res.submitVat.vatReturnDate.fromDate
        );
        this.submitVatDetails.submitVatForm.controls.toDate.setValue(
          res.submitVat.vatReturnDate.toDate
        );
        this.vatReturnData=res.submitVat.vatReturnDate;
      });
  }
}
