import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-receipts',
  templateUrl: './add-receipts.component.html',
  styleUrls: ['./add-receipts.component.scss'],
})
export class AddReceiptsComponent {
  constructor(public dialogRef: MatDialogRef<AddReceiptsComponent>) {}

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
