<div
  class="main-wrapper"
  [ngClass]="[
    this.commonService.toggleMenu ? 'show-menu' : 'hide-menu',
    activeRouteLink === routingPath.AddCompany ? 'add-company' : ''
  ]"
  *ngIf="this.authResolverService.isLoggedUser"
>
  <div class="header-wrapper">
    <div class="navigation-container">
      <app-header></app-header>
    </div>
  </div>
  <div class="content-wrapper">
    <app-menu-items [activeMenuLink]="activeRouteLink"></app-menu-items>
    <div class="container">
      <router-outlet (activate)="changeOfRoutes($event)"></router-outlet>
    </div>
  </div>
  <div *ngIf="toggleIcon" class="menu-toggle" (click)="onIconClick()">
    <mat-icon>{{
      this.commonService.toggleMenu
        ? "keyboard_arrow_left"
        : "keyboard_arrow_right"
    }}</mat-icon>
  </div>
</div>
<ngx-spinner
  template="<img class='loader-image mr-1' src='assets/images/loader.svg' />
<span class='loader'></span>"
></ngx-spinner>
