import { Guid } from 'guid-typescript';

export class QuickEntryModel {
  id?: Guid;
  branchId?: Guid | null;
  departmentId?: Guid | null;
  entryNumber: string;
  note: string;
  transactionLogId?: Guid;
  quickEntryItems: Array<QuickEntryItemModel>;
  bookAccountId?: Guid;
  vatAmountType: number;
  defaultPayDate: number;
  totalReceiptAmount: number;
  totalPaymentAmount: number;
}
export class QuickEntryItemModel {
  id?: Guid;
  type: number;
  customerSupplierId?: Guid;
  reference: string;
  accountId?: Guid;
  description: string;
  invoiceDate?: string;
  netAmount: number;
  vatRateId?: Guid;
  vatAmount: number;
  totalAmount: number;
  payDate?: string;
  paymentAccountId?: Guid;
  isNetToGross: boolean;
}
