import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActionType,
  ConfirmationType,
  DataType,
  ExportFormat,
  FilteredStatus,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import {
  ExportType,
  HighLightModel,
  MainListParameters,
  ViewParamModel,
} from '@app/core/Models';
import {
  CommonService,
  HighlightRow,
  ModulePermission,
  NotificationService,
} from '@app/core/Services';
import {
  Copy,
  Export,
  ExportReceipt,
  GetVatRemainingTransaction,
  MenuState,
  SaveIncludeExclude,
  SubmitVatState,
} from '@app/core/Store';
import {
  ConfirmationBoxComponent,
  ViewReceiptComponent,
} from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-vat-view-data',
  templateUrl: './vat-view-data.component.html',
  styleUrls: ['./vat-view-data.component.scss'],
})
export class VatViewDataComponent implements OnInit {
  @Select(SubmitVatState.columns)
  columns$: Observable<
    Array<{
      name: string;
      value: string;
      dataType?: DataType;
      allowSortBy?: boolean;
    }>
  >;

  isRowHighlighted = false;

  showPaginator = true;
  checkBoxValue = 0;
  filteredStatus = FilteredStatus;

  @Select(SubmitVatState.columnNames)
  columnsNames$: Observable<Array<string>>;

  @Select(SubmitVatState.actions)
  actions$: Observable<any>;

  @Select(SubmitVatState.totalRecord)
  totalRecord$: Observable<number>;

  listParameters: MainListParameters = new MainListParameters();
  totalSelectedRecords = 0;
  isAllSelected = false;
  isExpanded = true;
  ids: Array<Guid>;
  mainList: any[] = [];
  dataSource = new MatTableDataSource();
  dataType = DataType;
  moduleId: number;
  moduleEnum = Modules;

  @Input()
  tabSalesPurchase: number = 0;

  isInclude: boolean = true;

  selectedRowIndex = -1;

  triggerPaginationChange: Subject<any> = new Subject<any>();

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(MenuState.hasEditPermission)
  hasEditPermission$: Observable<boolean>;

  @Select(MenuState.hasDeletePermission)
  hasDeletePermission$: Observable<boolean>;

  @ViewChild(MatSort) sort: MatSort;

  dialog: MatDialog;
  store: Store;
  notify: NotificationService;
  commonService: CommonService;
  router: Router;
  highlightRow: HighlightRow;
  datepipe: DatePipe;
  spinner: NgxSpinnerService;
  modulePermission: ModulePermission;
  noDataFound = false;
  subscriptionRouting: Subscription;
  _Activatedroute: ActivatedRoute;
  cookieService: CookieService;
  productId: string;
  detailListModulesId: number;
  selectedData: any = [];
  @Output()
  readonly triggerInclude = new EventEmitter<any>();

  @Input() reloadList: Observable<any>;

  @Input() vatReturnId: Guid = Guid.EMPTY as unknown as Guid;

  id = Guid.EMPTY as unknown as Guid;
  isRecordSelected = false;

  constructor(private injector: Injector) {
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.store = injector.get<Store>(Store);
    this.notify = injector.get<NotificationService>(NotificationService);
    this.commonService = injector.get<CommonService>(CommonService);
    this.router = injector.get<Router>(Router);
    this.highlightRow = injector.get<HighlightRow>(HighlightRow);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.modulePermission = injector.get<ModulePermission>(ModulePermission);
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.cookieService = injector.get<CookieService>(CookieService);
  }

  ngOnInit(): void {
    this.productId = this.cookieService.get('productId');
    this.moduleId$.subscribe((moduleId) => {
      this.moduleId = moduleId;
    });

    // // this.triggerSaveExclude?.subscribe((data) => {
    // //   this.isInclude = data;
    // //   this.onSave();
    // // });

    this.highlightRecord();
  }

  onSave(): void {
    this.id =
      this.store.selectSnapshot(SubmitVatState.vatDataId) ??
      (Guid.EMPTY as unknown as Guid);

    this.store
      .dispatch(
        new SaveIncludeExclude(this.selectedData, this.id, this.isInclude)
      )
      .subscribe((res) => {
        this.spinner.hide();
        if (res !== null && res !== undefined) {
          if (this.isInclude) {
            this.notify.success(
              NotificationHeader.success,
              NotificationTextMessage.vatTransactionImported
            );
            this.router.navigate([RoutingPath.AddSubmitVAT]);
          } else {
            this.getList(false);
          }
        } else {
          this.notify.error(
            NotificationHeader.error,
            NotificationTextMessage.errorMessage
          );
        }
      });
  }

  onCloseClick(): void {
    this.router.navigate([RoutingPath.AddSubmitVAT]);
  }

  highlightRecord(): void {
    this.listParameters.sortBy = this.highlightRow.mainListHighlighted.sortBy;
    this.listParameters.sortOrder = false;
    this.getList(true);
  }

  checkIfValidUUID(str) {
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(str);
  }

  selectAll(event: any): void {
    this.mainList.forEach((x) => (x.isSelected = event.checked));
    if (this.mainList.length > 0) {
      this.isRecordSelected = event.checked;
    }

    if (event.checked) {
      this.mainList.forEach((element) => {
        if (this.checkIfValidUUID(element[0])) {
          this.selectedData.push({ id: element[0], typeId: element[7] });
        }
      });
    } else {
      this.selectedData = [];
    }
  }

  showFooter(): void {
    this.selectedIds();
    this.totalSelectedRecords = this.ids.length;
  }

  deleteClick(): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: this.moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getList(false);
            this.cancelSelectionClick();
          }
        });
    }
  }

  export(format: number, isPrint: boolean = false): void {
    this.spinner.show();
    this.selectedIds();
    if (
      (this.moduleId === Modules.Invoices ||
        this.moduleId === Modules.Purchase ||
        this.moduleId === Modules.Quotation ||
        this.moduleId === Modules.CreditNote ||
        this.moduleId === Modules.DebitNote ||
        this.moduleId === Modules.FixedAssets ||
        this.moduleId === Modules.Journals ||
        this.moduleId === Modules.Receipt ||
        this.moduleId === Modules.Payment) &&
      format === ExportFormat.PDF
    ) {
      this.store
        .dispatch(new ExportReceipt(this.moduleId, this.ids, isPrint))
        .subscribe();
      this.cancelSelectionClick();
    } else {
      const queryParams = {
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: this.listParameters.filter,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        search: this.listParameters.search,
        moduleId:
          this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
        subModuleId: this.listParameters.subModuleId ?? -1,
        ids: this.ids,
        startDate:
          this.datepipe
            .transform(this.listParameters.startDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        endDate:
          this.datepipe
            .transform(this.listParameters.endDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        format: format,
        isPrint: isPrint,
      };

      this.store.dispatch(new Export(queryParams, this.moduleId)).subscribe();
      this.cancelSelectionClick();
    }
  }

  copyClick(): void {
    if (this.moduleId !== Modules.CashEntry) {
      this.spinner.show();

      this.selectedIds();

      this.store
        .dispatch(new Copy(this.moduleId, this.ids))
        .subscribe((res) => {
          if (res.common.isCopied) {
            this.notify.success(
              NotificationHeader.success,
              NotificationTextMessage.recordCopySuccessfully
            );
            this.getList(false);
            this.cancelSelectionClick();
          }
        });
    }
  }

  cancelSelectionClick(): void {
    this.deSelectAll();
  }

  deSelectAll(): void {
    this.mainList.forEach((x) => (x.isSelected = false));
    this.isAllSelected = false;
    this.totalSelectedRecords = 0;
  }

  printClick(): void {
    this.export(ExportType.PDF, true);
    this.cancelSelectionClick();
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.getList(false);
  }

  onAddClick(element: any): void {
    this.router.navigate([
      RoutingPath.AddCustomAccounts,
      { accountId: btoa(element[0]) },
    ]);
  }

  archiveAndRestoreClick(isArchive: any): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            isArchive,
            type: ConfirmationType.Archive,
            moduleId: this.moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: isArchive
              ? NotificationHeader.archiveConfirmation
              : NotificationHeader.restoreConfirmation,
            detailText: isArchive
              ? NotificationDetails.archiveDetailText
              : NotificationDetails.restoreDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.ids = [];
            this.getList(false);
            this.cancelSelectionClick();
          }
        });
    }
  }

  selectedIds(): void {
    this.ids = [];
    this.mainList.forEach((value) =>
      value.isSelected ? this.ids.push(value[0] ?? 0) : ''
    );
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.getList(false);
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.getList(false);
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  onCheckBoxSelected(element: any, event: any): void {
    element.isSelected = event.checked;

    let temp = this.mainList.find((x) => x.isSelected === true);
    if (temp) {
      this.isRecordSelected = true;
    } else {
      this.isRecordSelected = false;
    }

    this.isAllSelected = this.mainList.every(
      (item: any) => item.isSelected === true
    );

    if (element.isSelected) {
      this.selectedData.push({ id: element[0], typeId: element[7] });
    } else {
      this.selectedData?.forEach((item, index) => {
        if (item.id === element[0]) this.selectedData.splice(index, 1);
      });
    }
  }

  onDeleteClick(id: any): void {
    this.ids = [];
    this.ids.push(id);

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: this.moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getList(false);
          }
        });
    }
  }

  onButtonClick(id: any, actionType: ActionType): void {
    switch (actionType) {
      case ActionType.Update:
        this.commonService.onEditRouting(true, this.moduleId, id);
        break;

      case ActionType.Delete:
        this.onDeleteClick(id);
        break;

      case ActionType.Export:
        this.ids = [];
        this.ids.push(id);
        this.store
          .dispatch(new ExportReceipt(this.moduleId, this.ids, false))
          .subscribe();
        break;

      case ActionType.View:
        const data: ViewParamModel = {
          moduleId: this.moduleId,
          id,
        };

        this.dialog
          .open(ViewReceiptComponent, {
            data,
            disableClose: true,
          })
          .afterClosed()
          .subscribe((result) => {});
        break;
    }
  }

  isGroup(index, item): boolean {
    return item.level;
  }

  getList(isRowHighlighted: boolean): void {
    this.id = this.vatReturnId ?? (Guid.EMPTY as unknown as Guid);
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter:
        this.tabSalesPurchase === 1 || this.tabSalesPurchase === 3 ? 0 : 1,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId:
        this.tabSalesPurchase === 3 || this.tabSalesPurchase === 4 ? 0 : -1,
      ids:
        this.listParameters.ids !== undefined &&
        this.listParameters.ids.length > 0
          ? this.listParameters.ids
          : null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    this.spinner.show();
    this.store
      .dispatch(new GetVatRemainingTransaction(queryParams, false, this.id))
      .pipe(
        tap(() => {
          this.isRowHighlighted = isRowHighlighted;
        })
      )
      .subscribe((res) => {
        this.spinner.hide();
        this.mainList = res.submitVat.mainList.resultSet.data;
        this.noDataFound = this.mainList.length > 0 ? false : true;
        if (this.tabSalesPurchase === 1 || this.tabSalesPurchase === 2) {
          this.removeNoVatRecords();
        }
        this.dataSource.data = this.mainList;
        this.checkBoxValue = this.dataSource.data.length;
        this.highlightRow.mainListHighlighted = new HighLightModel();
        const param = {
          pageSize: this.listParameters.pageSize,
          totalRecord: res.submitVat.totalRecord,
        };
        this.mainList.forEach((element) => {
          if (!this.checkIfValidUUID(element[0])) {
            element.isSelected = true;
          }
        });
        this.triggerPaginationChange.next(param);
      });
  }

  getDataFromHeader(data: any): void {
    data.ids = this.ids;
    if (data.search === '') {
      this.spinner.show();
    }
    this.listParameters = data;
    !this.listParameters.format
      ? this.getList(false)
      : this.export(this.listParameters.format, this.listParameters.isPrint);
    this.cancelSelectionClick();
  }

  getDataFromBank(data: any): void {
    this.isExpanded = data.isExpanded;
    this.listParameters.moduleId = data.id;
    this.getList(false);
  }

  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
  }

  onToggleMatMenu(i): void {
    this.selectedRowIndex = i;
  }
  removeNoVatRecords() {
    let removeMode = false;
    let indexToRemove = -1;

    for (let i = 0; i < this.mainList.length; i++) {
      const record = this.mainList[i];
      if (record[0] === 'No VAT' && record[8] === 'True') {
        removeMode = true;
        indexToRemove = i;
      } else if (record[8] === 'True') {
        if (removeMode) {
          this.mainList.splice(indexToRemove, i - indexToRemove);
          i = indexToRemove - 1; // Reset loop index to the start of removed records
          removeMode = false;
        }
      }
    }

    // If remove mode is still active at the end, remove records until the end
    if (removeMode) {
      this.mainList.splice(indexToRemove, this.mainList.length - indexToRemove);
    }
  }
}
