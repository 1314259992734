import { Guid } from 'guid-typescript';

export enum VatAmountType {
  Nettogross = 1,
  GrosstoNet = 2,
}
export enum payDateType {
  SameAsInvoiceDate = 1,
}
export enum quickEntryType {
  Payment = 1,
  Receipt = 2,
}
export interface MatchQuickEntryModel {
  id: Guid;
  date: string;
  amount: number;
  isManualBank: boolean;
  isCredit: boolean;
  items: MatchQuickEntryItemModel[];
}

export interface MatchQuickEntryItemModel {
  journalId: Guid;
  entryNumber: string;
  entryDate: string;
  totalAmount: number;
  transactionId: Guid;
}
export interface LinkQuickEntryModel {
  id: Guid;
  itemId: Guid;
  transactionId: Guid;
  isManualBank: boolean;
}
