import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Observable, Subject, Subscription } from 'rxjs';

import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {
  DiscountType,
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import {
  FixedAssetsModel,
  PaymentDetailsModel,
  ProductDetailsModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateFixedAssets,
  GetDataByFixedAssetsId,
  MenuState,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  AddFixedAssestsComponent,
  AdvancePaymentComponent,
  ProductDetailsComponent,
  ProductDetailsFooterComponent,
  TransactionsAttachmentComponent,
  TransactionsPaymentComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-fixed-assets',
  templateUrl: './fixed-assets.component.html',
  styleUrls: ['./fixed-assets.component.scss'],
})
export class FixedAssetsComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isExit = false;

  fixedAssetsId = Guid.EMPTY as unknown as Guid;
  selectedSupplierId = Guid.EMPTY as unknown as Guid;
  moduleId = Modules.FixedAssets;
  moduleName = ModuleName.FixedAssets;

  fixedAssetsData: FixedAssetsModel;
  productItems: Array<ProductDetailsModel>;
  receiptItems: Array<PaymentDetailsModel>;

  @ViewChild(AddFixedAssestsComponent, { static: true })
  fixedAssetsDetails;

  @ViewChild(ProductDetailsComponent, { static: true })
  productDetails;

  @ViewChild(TransactionsPaymentComponent, { static: true })
  receiptDetails;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  @ViewChild(AdvancePaymentComponent, { static: true })
  advancePaymentDetails;

  @ViewChild(ProductDetailsFooterComponent, { static: true })
  productDetailsFooter;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();
  @ViewChild(MatAccordion) accordion: MatAccordion;

  subscriptionRouting: Subscription;

  triggerEditData: Subject<any> = new Subject<any>();
  triggereEditProductData: Subject<any> = new Subject<any>();
  triggereEditPaymentData: Subject<any> = new Subject<any>();
  triggerOnVATChange: Subject<boolean> = new Subject<boolean>();
  triggerAccountSelectedOption: Subject<boolean> = new Subject<boolean>();
  triggerAdvancePayment: Subject<any> = new Subject<any>();
  triggerisVatPostponed: Subject<any> = new Subject<any>();
  triggerisVatIncluded: Subject<any> = new Subject<any>();
  triggerBranchChange: Subject<any> = new Subject<any>();
  triggerCurrencySelectedOption: Subject<boolean> = new Subject<boolean>();
  triggerCurrencyRefreshOption: Subject<boolean> = new Subject<boolean>();
  triggerSelectedCurrencyRate: Subject<number> = new Subject<number>();
  triggerNewProductAdded: Subject<any> = new Subject<any>();
  triggerDiscountData: Subject<any> = new Subject<any>();

  headerText = 'Payment';
  hasAdvanceAmount = false;
  selectedCurrencyId: number;
  selectedCurrencyRate: number;
  receiptAccountSelectedCurrencyId: number;
  store: Store;
  commonService: CommonService;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  isSupplierChangePermission: boolean = true;
  public defaultGuidValue = Guid.EMPTY as unknown as Guid;
  constructor(
    private _Activatedroute: ActivatedRoute,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private location: Location,
    private renderer: Renderer2,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.fixedAssetsId = atob(params.get('id')!) as unknown as Guid;
          this.editFixAssets();
        }
      }
    );
  }

  editFixAssets(): void {
    this.store
      .dispatch(new GetDataByFixedAssetsId(this.fixedAssetsId))
      .subscribe((res) => {
        this.fixedAssetsDetails.fixedAssetsForm.markAsUntouched();
        this.productDetails.formProductDetail.markAsUntouched();
        this.receiptDetails.paymentForm.markAsUntouched();
        setTimeout(() => {
          this.triggerEditData.next(res.fixedAssets.fixedAssetsData);

          const data = {
            productData: res.fixedAssets.fixedAssetsData.items,
            isVATSelected: res.fixedAssets.fixedAssetsData.isVatPostponed,
          };
          this.triggereEditProductData.next(data);
          this.triggereEditPaymentData.next(
            res.fixedAssets.fixedAssetsData.receiptDetails
          );
          this.triggerAdvancePayment.next(
            res.fixedAssets.fixedAssetsData.accountId
          );
          this.triggerisVatPostponed.next(
            res.fixedAssets.fixedAssetsData.isVatPostponed
          );
          this.triggerisVatIncluded.next(
            res.fixedAssets.fixedAssetsData.isVatIncluded
          );
          let discountData = {
            discountValue: res.fixedAssets.fixedAssetsData.foreignDiscount,
            discountType: res.fixedAssets.fixedAssetsData.isDiscountPercentage
              ? DiscountType.Percentage
              : DiscountType.Value,
          };
          this.triggerDiscountData.next(discountData);
        }, 500);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  dataSubmit(): boolean {
    this.spinner.show();
    this.selectedCurrencyId =
      this.fixedAssetsDetails.fixedAssetsForm.controls.currencyId.value === 0
        ? 123
        : this.fixedAssetsDetails.fixedAssetsForm.controls.currencyId.value;
    this.selectedCurrencyRate =
      this.fixedAssetsDetails.fixedAssetsForm.controls.currencyAmt.value;
    this.receiptAccountSelectedCurrencyId =
      this.receiptDetails.selectedAccountCurrencyId;
    try {
      this.productItems = new Array<ProductDetailsModel>();
      this.receiptItems = new Array<PaymentDetailsModel>();

      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.productDetails.productDetailArray?.getRawValue().forEach((x) => {
        this.productItems.push({
          id: x.id,
          productId: x.productName ? x.productName : this.defaultGuidValue,
          description: x.description,
          quantity: +x.qty,
          price: +x.price / this.selectedCurrencyRate,
          vatAmount: +x.vatAmount / this.selectedCurrencyRate,
          vatRateId: x.vatRate === -1 ? null : x.vatRate,
          totalAmount: +(x.amount / this.selectedCurrencyRate).toFixed(2),
          foreignVatAmount: +x.vatAmount,
          foreignPrice: +x.price,
          foreignTotalAmount: +(+x.amount).toFixed(2),
          discount:
            x.discountType === DiscountType.Percentage
              ? +x.discountValue
              : +x.discountValue / this.selectedCurrencyRate,
          isDiscountPercentage: x.discountType === DiscountType.Percentage,
          discountDescription: x.discountDescription,
          foreignDiscount: +x.discountValue,
          bookAccountId:x.invoiceType,
        });
      });

      this.receiptDetails.paymentArray?.getRawValue().forEach((x) => {
        const attachmentList: any = [];
        if (
          x.attachment !== null &&
          x.attachment !== undefined &&
          x.attachment !== '' &&
          x.attachment.length > 0
        ) {
          x.attachment.forEach((element) => {
            attachmentList.push(element);
          });
        }
        if (x.accountId !== '' && x.amount !== '') {
          this.receiptItems.push({
            id: x.id,
            receiptId: x.receiptId,
            transactionDate: this.datepipe
              .transform(x.receiptDate, 'yyyy-MM-dd')
              ?.toString(),
            amount:
              this.receiptAccountSelectedCurrencyId !== 123
                ? x.amount / this.selectedCurrencyRate
                : x.amount,
            postingAccountId: x.accountId,
            attachment: attachmentList,
            foreignAmount:
              this.receiptAccountSelectedCurrencyId !== 123
                ? x.amount
                : x.amount * this.selectedCurrencyRate,
            currencyRate: this.selectedCurrencyRate,
          });
        }
      });

      this.fixedAssetsData = {
        id: this.fixedAssetsId,
        accountId:
          this.fixedAssetsDetails.fixedAssetsForm.controls.accountId.value,
        //bookAccountId:
        //  this.fixedAssetsDetails.fixedAssetsForm.controls.invoiceType.value,
        entryNumber:
          this.fixedAssetsDetails.fixedAssetsForm.controls.billNo.value,
        entryDate: this.datepipe
          .transform(
            this.fixedAssetsDetails.fixedAssetsForm.controls.invoiceDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        currencyId:
          this.fixedAssetsDetails.fixedAssetsForm.controls.currencyId.value,
        currencyRate:
          this.fixedAssetsDetails.fixedAssetsForm.controls.currencyAmt.value,
        note: this.fixedAssetsDetails.fixedAssetsForm.controls.note.value,
        dueDate: this.datepipe
          .transform(
            this.fixedAssetsDetails.fixedAssetsForm.controls.dueDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        items: this.productItems,
        receiptDetails: this.receiptItems,
        attachment: filedata,
        isVatPostponed:
          this.fixedAssetsDetails.fixedAssetsForm.controls.isVatPostponed.value,
        advanceReceiptAmount:
          +this.advancePaymentDetails.advancePaymentDetailForm.controls
            .allocateAdvance.value,
        isVatIncluded:
          this.fixedAssetsDetails.fixedAssetsForm.controls.isVatInclude.value,
        branchId:
          this.fixedAssetsDetails.fixedAssetsForm.controls.branchId.value === ''
            ? null
            : this.fixedAssetsDetails.fixedAssetsForm.controls.branchId.value,
        departmentId:
          this.fixedAssetsDetails.fixedAssetsForm.controls.departmentId
            .value === ''
            ? null
            : this.fixedAssetsDetails.fixedAssetsForm.controls.departmentId
                .value,
        discount:
          this.productDetailsFooter.discountType === DiscountType.Percentage
            ? this.productDetailsFooter.discountValue
            : this.productDetailsFooter.discountValue /
              this.selectedCurrencyRate,
        isDiscountPercentage:
          this.productDetailsFooter.discountType === DiscountType.Percentage,
        foreignDiscount: this.productDetailsFooter.discountValue,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.fixedAssetsId,
      isExit,
      Modules.FixedAssets,
      RoutingPath.AddFixedAssets
    );
  }

  onSave(isExit: boolean): void {
    if (this.fixedAssetsDetails.fixedAssetsForm.invalid) {
      this.commonService.addValidation(
        this.fixedAssetsDetails.fixedAssetsForm,
        this.renderer
      );
    } else if (
      
      +((+this.productDetails.totalAmount).toFixed(2)) < this.receiptDetails.totalReciptAmount
    ) {
      this.receiptDetails.paymentArray.controls.forEach((x) => {
        (Object as any)
          .values(x.controls)
          .forEach((c) => c.markAsTouched(false));
        x.controls.amount.status = 'INVALID';
      });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
    } else if (this.productDetails.productDetailArray.invalid) {
      this.productDetails.productDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else if (this.receiptDetails.paymentArray.invalid) {
      this.receiptDetails.paymentArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (
            x.controls.amount.value === '' ||
            x.controls.amount.value === '0'
          ) {
            this.commonService.onFailure(
              NotificationTextMessage.amtErrorMessage
            );
          }
          c.markAsTouched();
        });
      });
    } else if (this.advancePaymentDetails.advancePaymentDetailForm.invalid) {
      this.advancePaymentDetails.advancePaymentDetailForm.controls[
        'allocateAdvance'
      ].setErrors({ incorrect: true });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    } else if (
      this.advancePaymentDetails.advancePaymentDetailForm.controls
        .allocateAdvance.value > +((+this.productDetails.totalAmount).toFixed(2))
    ) {
      this.advancePaymentDetails.advancePaymentDetailForm.controls[
        'allocateAdvance'
      ].setErrors({ incorrect: true });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    } else if (
      +this.receiptDetails.totalReciptAmount +
        +this.advancePaymentDetails.allocatedAdvanceAmount >
      +((+this.productDetails.totalAmount).toFixed(2))
    ) {
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    } else {
      if (this.dataSubmit()) {
        if (this.fixedAssetsData) {
          this.store
            .dispatch(new CreateFixedAssets(this.fixedAssetsData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.setHighlightData(isExit);

                  if (!isExit) {
                    this.onCancel(false);
                    this.reloadSideList.emit();
                  } else {
                    this.onCancel(false);
                    this.location.back();
                  }
                  this.commonService.onSuccess(
                    NotificationTextMessage.successMessage
                  );
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.message);
              }
            );
        }
      }
    }
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.fixedAssetsId)) {
      this.editFixAssets();
    } else {
      this.fixedAssetsId = Guid.EMPTY as unknown as Guid;
      this.fixedAssetsDetails.ngOnInit();
      this.productDetails.ngOnInit();
      this.receiptDetails.ngOnInit();
      this.attachmentDetail.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  onVATChange(isVATChecked: boolean) {
    this.triggerOnVATChange.next(isVATChecked);
  }

  onAccountSelectedOption(selectedOption: any) {
    this.triggerAccountSelectedOption.next(selectedOption);
  }

  supplierClickChange(event) {
    this.triggerAdvancePayment.next(event);
    this.selectedSupplierId = event;
  }

  onHasAdvanceAmountUpdated(updatedValue: boolean): void {
    this.hasAdvanceAmount = updatedValue;
  }

  isVatPostponedClick(event): void {
    this.triggerisVatPostponed.next(event);
  }

  isVatIncludedClick(event): void {
    this.triggerisVatIncluded.next(event);
  }

  onBranchChange(selectedOption: any) {
    this.triggerBranchChange.next(selectedOption);
  }
  onCurrencySelectedOption(selectedOption: any) {
    this.triggerCurrencySelectedOption.next(selectedOption);
    this.triggerAdvancePayment.next(this.selectedSupplierId);
  }
  onCurrencyRefreshOption(selectedOption: any) {
    this.triggerCurrencyRefreshOption.next(selectedOption);
  }
  onCurrencySelectedRate(selectedOption: any) {
    this.triggerSelectedCurrencyRate.next(selectedOption);
  }
  onAddedNewProductDetails(selectedOption: any) {
    this.triggerNewProductAdded.next(selectedOption);
  }
}
