import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  MaxLength,
  NotificationTextMessage,
  TransactionStatus,
} from '@app/core/Enum';
import { VatScheme } from '@app/core/Enum/vat-scheme';
import { Currency, GlobalComponent, SideListModel } from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import {
  CommonState,
  GetAllBranchList,
  GetAllDepartmentListByBranch,
  GetCompanyVatSchemaId,
  GetJournalNo,
  GetRefreshCurrencyData,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-journals',
  templateUrl: './add-journals.component.html',
  styleUrls: ['./add-journals.component.scss'],
})
export class AddJournalsComponent implements OnInit {
  journalsForm: FormGroup;
  subscriptionRouting: Subscription;

  @Input() triggerEditData: Observable<any>;
  @Input() triggerTransactionLogData: Observable<any>;
  @Output() triggerOnCurrencyRateUpdate: EventEmitter<number> =
    new EventEmitter<number>();

  @Select(CommonState.defaultCurrency)
  defaultCurrency$: Observable<Guid>;

  private destroy$ = new Subject<void>();

  defaultCurrency: Guid;
  periodicDate: any;

  customerList: SideListModel[];
  postedToList: SideListModel[];
  currencyList: Currency[];
  maxLength = MaxLength;
  hideReveringDate: boolean = true;

  disabledEditButton = true;
  transferredAmtSymbol: string;
  transferAmountSymbol: string;
  currencyIds: any = [];
  refreshedCurrencyData: any = [];
  refreshedCurrencyRate: number;
  isManualBank = false;
  vatSchemaId: any;

  statusFilter: any[] = [
    {
      value: TransactionStatus.IsCredit,
      name: TransactionStatus[TransactionStatus.IsCredit],
    },
    {
      value: TransactionStatus.IsDebit,
      name: TransactionStatus[TransactionStatus.IsDebit],
    },
  ];
  departmentList: any[] = [];
  branchList: any[] = [];

  notificationMessage = NotificationTextMessage;
  isMultiCurrencyBank = false;

  isVatRegistered: boolean = false;
  vatReturnCheckboxVisibility: boolean = false;

  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.transferredAmtSymbol = '£';
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();

    if (this.isVatRegistered) {
      this.getVatSchemaId().then(() => {
        if (
          +this.vatSchemaId === VatScheme.CashBased ||
          +this.vatSchemaId === VatScheme.FlatRateCashBasedScheme
        ) {
          this.vatReturnCheckboxVisibility = true;
        }
      });
    }

    this.setForm();
    this.getJournalNo();
    this.getBranchList();
    this.triggerTransactionLogData
      .pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isManualBank = true;
        if (data.currencyId !== 123) {
          this.isMultiCurrencyBank = true;
        }
        this.transferAmountSymbol = data.symbol;
        this.journalsForm.controls.currencyId.setValue(data.currencyId);
        this.journalsForm.controls.transferAmount.setValue(data.amount);
        this.journalsForm.controls.currencyRate.setValue(data.currencyRate);
        this.journalsForm.controls.convertedGbpAmnt.setValue(
          (data.amount / data.currencyRate).toFixed(2)
        );
        this.journalsForm.controls.transferAmount.disable();
        this.journalsForm.controls.convertedGbpAmnt.disable();
      });
    this.triggerEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.editJournals(data);
        this.hideReveringDate = false;
      });

    this.journalsForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.journalsForm.touched;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getJournalNo(): void {
    this.spinner.show();
    this.store.dispatch(new GetJournalNo()).subscribe((res) => {
      this.spinner.hide();
      this.journalsForm.get('journalNo')?.setValue(res.journals.journalNo);
      this.journalsForm.get('journalNo')?.disable();
    });
  }

  editJournals(data): void {
    if (data.transferAmount > 0) {
      this.isManualBank = true;
    }
    this.journalsForm.patchValue({
      id: data.id,
      journalNo: data.entryNumber,
      date: data.entryDate,
      reference: data.note,
      branchId: data.branchId,
      transferAmount: data.transferAmount,
      departmentId: data.departmentId,
      currencyId: data.currencyId,
      currencyRate: data.currencyRate,
      includeInVatReturnCheckbox: data.includeInVatReturn,
      convertedGbpAmnt: (data.transferAmount / data.currencyRate).toFixed(2),
    });
    this.transferAmountSymbol = data.symbol;
    this.journalsForm.controls.transferAmount.disable();
    this.journalsForm.controls.convertedGbpAmnt.disable();
    if (data.branchId !== null && data.branchId !== undefined) {
      this.getDepartmentListByBranch(data.branchId);
    }
  }

  setForm(): void {
    this.journalsForm = new FormGroup({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      journalNo: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      date: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      reversingDate: new FormControl('', [
        datePickerValidator(this.periodicDate),
      ]),
      reference: new FormControl(''),
      branchId: new FormControl(''),
      departmentId: new FormControl(''),
      transferAmount: new FormControl(''),
      currencyId: new FormControl(''),
      currencyRate: new FormControl(''),
      convertedGbpAmnt: new FormControl(''),
      includeInVatReturnCheckbox: new FormControl(true),
    });
  }

  getBranchList(): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.journalsForm.controls.branchId.setValue(this.branchList[0].id);
            this.journalsForm.controls.branchId.setValidators(
              Validators.required
            );
            this.journalsForm.controls.branchId.updateValueAndValidity();
            this.getDepartmentListByBranch(this.branchList[0].id);
          } else {
            this.branchList = [];
            this.departmentList = [];
          }
        })
      )
      .subscribe();
  }

  onChangeBranch(branch): void {
    this.getDepartmentListByBranch(branch);
  }

  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .pipe(
          tap((res) => {
            this.departmentList = [];
            if (res.company.department.length > 0) {
              this.departmentList = res.company.department;
              this.journalsForm.controls.departmentId.setValue(
                this.departmentList[0].id
              );
              this.journalsForm.controls.departmentId.setValidators(
                Validators.required
              );
              this.journalsForm.controls.departmentId.updateValueAndValidity();
            } else {
              this.journalsForm.controls.departmentId.clearValidators();
              this.journalsForm.controls.departmentId.updateValueAndValidity();
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
      this.journalsForm.controls.departmentId.clearValidators();
      this.journalsForm.controls.departmentId.updateValueAndValidity();
    }
  }
  onCurrencyRateEntered() {
    this.journalsForm.controls.convertedGbpAmnt.setValue(
      (
        this.journalsForm.controls.transferAmount.value /
        this.journalsForm.controls.currencyRate.value
      ).toFixed(2)
    );
    this.triggerOnCurrencyRateUpdate.emit(
      this.journalsForm.controls.currencyRate.value
    );
  }
  onCurrencyRefresh(): void {
    this.currencyIds = [];
    this.currencyIds.push(this.journalsForm.controls.currencyId.value);
    this.store
      .dispatch(new GetRefreshCurrencyData(this.currencyIds))
      .subscribe((res) => {
        this.currencyIds = [];
        this.refreshedCurrencyData = res.currency.currencyList.data;

        this.refreshedCurrencyData.forEach((x) => {
          this.refreshedCurrencyRate = x.rate;
        });
        this.journalsForm.controls.currencyRate.setValue(
          this.refreshedCurrencyRate.toFixed(2)
        );
        this.journalsForm.controls.convertedGbpAmnt.setValue(
          (
            this.journalsForm.controls.transferAmount.value /
            this.journalsForm.controls.currencyRate.value
          ).toFixed(2)
        );
        this.triggerOnCurrencyRateUpdate.emit(
          this.journalsForm.controls.currencyRate.value
        );
      });
  }

  async getVatSchemaId(): Promise<void> {
    await this.store
      .dispatch(new GetCompanyVatSchemaId())
      .toPromise()
      .then((res) => {
        this.vatSchemaId = res.company.companyVatSchemaId;
      });
  }
}
