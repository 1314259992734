import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NotificationTextMessage } from '@app/core/Enum';
import { DataSecurityModel, GlobalComponent } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { GetDataSecurityDetails, SaveDataSecurity } from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-data-security',
  templateUrl: './data-security.component.html',
  styleUrls: ['./data-security.component.scss']
})
export class DataSecurityComponent implements OnInit {
  dataSecurityData: DataSecurityModel;
  dataSecurityForm: FormGroup;
  isFormSubmitted = false;
  isEnableSaveBtn = false;
  id = Guid.EMPTY as unknown as Guid;

  constructor(
    public dialog: MatDialog,
    private store: Store,
    public commonService: CommonService,
    private globalComponent: GlobalComponent,
  ) { }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void{
    this.dataSecurityForm = new FormGroup({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      isEnableDocument: new FormControl(false),
      masterPassword:new FormControl('', [
        Validators.required,
      ]),
    });
    this.getDataSecurityDetails();
  }

  onCheckBoxSelected(event): void{
    this.isEnableSaveBtn = true;
    if(!event.checked){
      this.dataSecurityForm.controls.masterPassword.disable();
      this.dataSecurityForm.controls.masterPassword.setValue(null);
    }else{
      this.dataSecurityForm.controls.masterPassword.enable();
    }

  }

  getDataSecurityDetails(): void {
    this.store
      .dispatch(new GetDataSecurityDetails(this.globalComponent.getCompanyId()))
      .subscribe((res) => {
        if(res.datasecurity.dataSecurityData && res.datasecurity.dataSecurityData.isEnablePassword){
          this.mapDataSecurityDetails(res.datasecurity.dataSecurityData);
          this.id = res.datasecurity.dataSecurityData.id;
        }else{
          this.dataSecurityForm.controls.masterPassword.disable();
        }
      });
  }

  mapDataSecurityDetails(data: any): void{
    this.dataSecurityForm.patchValue({
      id: data.id,
      isEnableDocument: data.isEnablePassword,
      masterPassword: data.masterPassword
    });
  }

  onSave(): void{
    if(this.dataSecurityForm.controls.isEnableDocument.value && (this.dataSecurityForm.controls.masterPassword.value === "" || this.dataSecurityForm.controls.masterPassword.value === null)){
      this.isFormSubmitted = true;
      this.commonService.onFailure(NotificationTextMessage.validationMessage);
    }else{
      this.isFormSubmitted = false;
        this.dataSecurityData = {
          id : this.dataSecurityForm.controls.id.value ?? this.id,
          companyId: this.globalComponent.getCompanyId(),
          isEnablePassword: this.dataSecurityForm.controls.isEnableDocument.value ?? false,
          masterPassword: this.dataSecurityForm.controls.masterPassword.value,
          userId: this.globalComponent.getLoggedInUserId()
        };
        this.store
          .dispatch(new SaveDataSecurity(this.dataSecurityData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.dataSecurityForm.controls.id.setValue(res.datasecurity.dataSecurityId);
                this.isEnableSaveBtn = false;
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
                this.getDataSecurityDetails();
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
  }

    
  copyMasterPassword() {
    let copyText = <HTMLInputElement>document.getElementById('inputPassword');

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
  }
  
  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  onClearForm(): void{
    this.dataSecurityForm.reset();
    this.getDataSecurityDetails();
  }

  onChangeMasterPassword(): void{
    this.isEnableSaveBtn = true;
  }

}
