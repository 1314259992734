import { DatePipe } from '@angular/common';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GroupNames, Modules, NotificationTextMessage } from '@app/core/Enum';
import {
  BankReconciliationModel,
  GroupListModel,
  MainListParameters,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  BankReconciliationState,
  GetAccountGroupAndType,
  GetBankReconciliation,
  GetGroupList,
  SaveBankReconciliation,
} from '@app/core/Store';
import { TransactionHeaderComponent } from '@app/modules/transactions';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BankDashboardComponent } from '../bank-dashboard/bank-dashboard.component';
import { BankReconciliationDetailsComponent } from './bank-reconciliation-details/bank-reconciliation-details.component';

@Component({
  selector: 'app-bank-reconciliation',
  templateUrl: './bank-reconciliation.component.html',
  styleUrls: ['./bank-reconciliation.component.scss'],
})
export class BankReconciliationComponent implements OnInit {
  formReconciliationDetail: UntypedFormGroup;
  reconciliationDetailArray: any;
  reconciliationData: any[];
  accountList: any[] = [];
  tempAccountGroupList: any[] = [];
  reconciliationList: BankReconciliationModel[] = [];
  accountId = Guid.EMPTY as unknown as Guid;
  listParameters: MainListParameters = new MainListParameters();
  totalSelectedRecords = 0;
  accountListValue = '-1';
  showAddButton = false;
  showPaginator = true;
  isExpanded = true;
  isCustom = true;
  customId: any;

  datepipe: DatePipe;
  accountGroupAndTypeId: any;
  displayReconciliationColumns: string[] = [
    'srNo',
    'transactionCategory',
    'transactionType',
    'description',
    'totalAmount',
    'accounts',
    'addButton',
  ];

  displayReconciliationColumns_v1: string[] = [
    'srNo',
    'transactionCategory',
    'transactionType',
    'description',
    'totalAmount',
    'explain',
  ];

  receiptList: any[] = [];
  paymentList: any[] = [];

  moduleId = Modules.BankFeed;
  triggerPaginationChange: Subject<any> = new Subject<any>();

  @Select(BankReconciliationState.totalRecord)
  totalRecord$: Observable<number>;

  @ViewChild(TransactionHeaderComponent, { static: true })
  headerDetails;

  @ViewChild(BankDashboardComponent, { static: true })
  bankDetails;

  constructor(
    public dialog: MatDialog,
    private store: Store,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private renderer: Renderer2
  ) {
    this.receiptList = this.commonService.receiptList;
    this.paymentList = this.commonService.paymentList;
  }

  ngOnInit(): void {
    this.getAccountList();
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      startDate: null,
      endDate: null,
    };
    return queryParams;
  }

  getDataFromBank(data: any): void {
    this.isExpanded = data.isExpanded;
    this.listParameters.pageNumber = 1;
    this.listParameters.moduleId = data.id;
    this.headerDetails.headerData.moduleId = data.id;

    this.getList();
    this.bankDetails.ngOnDestroy();
  }

  getList(): void {
    this.accountList = this.tempAccountGroupList;
    this.spinner.show();
    this.store
      .dispatch(new GetBankReconciliation(this.getParamter()))
      .subscribe((res) => {
        this.reconciliationList = res.bankReconciliation.getBankReconciliation;

        for (let rec of this.reconciliationList) {
          if (rec.accountId === (Guid.EMPTY as unknown as Guid)) {
            rec.accountValue = '';
          } else {
            this.accountList.forEach((items) => {
              const data = items.listModels.find(
                (x: any) => x.id === rec.accountId
              );
              if (data !== null && data !== undefined) {
                rec.accountValue = data;
              }
            });
          }
        }
      });
  }

  resetAccountList(element: any): void {
    this.accountList = this.tempAccountGroupList;
    this.scrollIntoView(element.accountValue);
  }

  onAccountSearch(event: any): void {
    this.accountList = this.tempAccountGroupList;
    const projectSearhText = event.currentTarget.value;
    const trimProjectSearhText = projectSearhText.trimStart();
    const selectedArray: GroupListModel[] = [];
    let listModel: any;
    this.accountList.filter((item) => {
      let groupName = item.groupName;
      if (
        item.groupName
          .toLowerCase()
          .includes(trimProjectSearhText.toLowerCase())
      ) {
        selectedArray.push(item);
      }
      listModel = [];
      item.listModels.filter((items) => {
        if (
          items.name.toLowerCase().includes(trimProjectSearhText.toLowerCase())
        ) {
          let groupNameExist = selectedArray.filter(
            (x) => x.groupName === groupName
          );
          let tempGroupNameExist = this.tempAccountGroupList.filter(
            (x) => x.groupName === groupName
          );
          if (groupNameExist.length > 0) {
            if (
              tempGroupNameExist[0].listModels.length !==
              groupNameExist[0].listModels.length
            ) {
              groupNameExist[0].listModels.push(items);
            }
          } else {
            listModel.push(items);
            const data = {
              groupName: groupName,
              listModels: listModel,
            };
            selectedArray.push(data);
          }
          return true;
        }
        return false;
      });
    });

    if (trimProjectSearhText) {
      this.accountList = selectedArray;
    }
  }

  getOptionText(option) {
    return option?.name;
  }

  getAccountList(): void {
    this.store
      .dispatch(new GetGroupList())
      .pipe(
        tap((res) => {
          this.accountList = res.common.accountGroupList;
          this.tempAccountGroupList = this.accountList;
          this.getList();
        })
      )
      .subscribe();
  }

  getDataFromHeader(data: any): void {
    this.listParameters = data;
    this.getList();
  }

  getAccountGroupAndType(element): void {
    this.store
      .dispatch(new GetAccountGroupAndType(element.accountValue.id))
      .subscribe((res) => {
        this.accountGroupAndTypeId = res.account.accountGroupAndType;
        element.isAdd =
          this.accountGroupAndTypeId.groupId === GroupNames.Debtors ||
          this.accountGroupAndTypeId.groupId === GroupNames.Creditors;
      });
  }

  accountSelect(event, element): void {
    if (element.isSelected) {
      this.reconciliationList.forEach((x) => {
        if (element.accountValue.id && x.isSelected) {
          x.accountValue = event.option.value;
          if (element.accountValue.id != '-1') {
            this.getAccountGroupAndType(x);
          }
        }
      });
    }
  }

  clickEdit(element: any): void {
    if (
      element.accountValue.id !== null &&
      element.accountValue.id !== undefined &&
      element.accountValue.id !== '-1'
    ) {
      this.getAccountGroupAndType(element);
    }
  }

  onCloseClick(element: any): void {
    element.isAdd = false;
    element.isSelected = false;
    if (element.accountId === (Guid.EMPTY as unknown as Guid)) {
      element.accountValue = '';
    } else {
      this.accountList.forEach((items) => {
        const data = items.listModels.find(
          (x: any) => x.id === element.accountId
        );
        if (data !== null && data !== undefined) {
          element.accountValue = data;
        }
      });
    }
  }

  checkIsCustom(event: any): void {
    this.isCustom = event.isCustom;
    this.customId = event.id;
  }

  clickReconciltionDetails(element: any): void {
    const obj = {
      customerName: element.accountValue.id,
      accountGroupId: this.accountGroupAndTypeId.groupId,
      amountReceivedValue: element.amount,
      isBankReconciliation: true,
    };
    this.dialog
      .open(BankReconciliationDetailsComponent, {
        data: obj,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          element.receiptItems = result;
        }
      });
  }

  onCancel(isExit: boolean): void {}

  onSave(): void {
    let isInValidData = false;
    const param: any = [];
    this.reconciliationList.forEach((element) => {
      if (element.isSelected) {
        if (
          element.accountValue.id !== undefined &&
          element.accountValue.id !== null &&
          element.accountValue.id !== ''
        ) {
          element.accountId = element.accountValue.id;
        } else {
          isInValidData = true;
        }
        param.push(element);
      }
    });

    if (param.length === 0) {
      this.commonService.onFailure(
        NotificationTextMessage.noSelectedBankErrorMessage
      );
      return;
    } else if (isInValidData) {
      this.commonService.onFailure(NotificationTextMessage.accountErrorMessage);
      return;
    }
    this.spinner.show();
    this.store.dispatch(new SaveBankReconciliation(param)).subscribe((res) => {
      this.spinner.hide();
      if (res !== null && res !== undefined) {
        this.getList();
      }
    });
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.getList();
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.getList();
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  scrollIntoView(element) {
    if (element.accountValue !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  receiptPaymentChange(event: any, data: any): void {
    this.commonService.onEditRouting(
      false,
      event.moduleId,
      data.id,
      true,
      undefined,
      false
    );
  }
}
