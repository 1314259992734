<div class="content-body">
  <form [formGroup]="recurringForm">
    <div class="add-form-wrap">
      <div class="form-field fund-name" *ngIf="branchList.length > 0">
        <p>Branch <span class="text-danger-300">*</span></p>
        <ng-select
          #branch
          (focus)="branch.open()"
          (valueChange)="branch.close()"
          [disableOptionCentering]="true"
          formControlName="branchId"
          dropdownPosition="bottom"
          (change)="onChangeBranch($event, false)"
        >
          <ng-option *ngFor="let bl of branchList" [value]="bl.id">
            <div title="{{ bl.name }}">{{ bl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="departmentList.length > 0">
        <p>Department <span class="text-danger-300">*</span></p>
        <ng-select
          #department
          (focus)="department.open()"
          (valueChange)="department.close()"
          [disableOptionCentering]="true"
          formControlName="departmentId"
          dropdownPosition="bottom"
        >
          <ng-option *ngFor="let dl of departmentList" [value]="dl.id">
            <div title="{{ dl.name }}">{{ dl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>
          {{
            (moduleId$ | async) === subModuleId.RecurringInvoice
              ? "Recurring Invoice No"
              : "Recurring Purchase No"
          }}
          <span class="text-danger-300">*</span>
        </p>
        <mat-form-field>
          <input
            matInput
            maxlength="{{ maxLength.maxLength100 }}"
            formControlName="recurringNo"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>
            {{
              (moduleId$ | async) === moduleEnum.RecurringInvoice
                ? "Customer Name"
                : "Supplier Name"
            }}
            <span class="text-danger-300">*</span>
          </p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isCustomerChangePermission"
            >
              <mat-icon (click)="gotoAddCustomer()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              mat-button
              disableRipple
              [disabled]="disabledEditButton || !isCustomerChangePermission"
            >
              <mat-icon
                (click)="gotoEditCustomer()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #customerName
          (focus)="customerName.open()"
          (valueChange)="customerName.close()"
          [disableOptionCentering]="true"
          formControlName="accountId"
          panelClass="mat-select-position"
          (change)="this.disabledEditButton = false"
        >
          <ng-option *ngFor="let option of accountList" [value]="option.id">
            <div title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field datepicker">
        <p>Due Term</p>
        <ng-select
          #dueTerm
          (focus)="dueTerm.open()"
          (valueChange)="dueTerm.close()"
          [disableOptionCentering]="true"
          formControlName="dueTermName"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let dt of dueTermList" [value]="dt.id">
            <div title="{{ dt.name }}">{{ dt.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" style="display:none;">
        <p>Account Name <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            formControlName="invoiceType"
            
            [matAutocomplete]="accountGroup"
            (keyup)="onAccountSearch($event)"
            (keydown.Tab)="onSearch($event)"
            (change)="onSearch($event)"
            #trigger="matAutocompleteTrigger"
            appAutocompletePosition="trigger"
            (mousedown)="resetAccountList()"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete
            #matAutocomplete
            [displayWith]="getOptionText"
            #accountGroup="matAutocomplete"
            (opened)="scrollIntoView(recurringForm.controls.invoiceType.value)"
          >
            <mat-optgroup
              *ngFor="let group of accountGroupList"
              [label]="group.groupName"
              title="{{ group.groupName }}"
            >
              <mat-option
                *ngFor="let names of group.listModels"
                [value]="names"
                title="{{ names.name }}"
                [class]="
                  this.recurringForm.controls.invoiceType.value?.id === names.id
                    ? 'mat-selected'
                    : ''
                "
              >
                {{ names.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Currency <span class="text-danger-300">*</span></p>
        <ng-select
          #currency
          (focus)="currency.open()"
          (valueChange)="currency.close()"
          [disableOptionCentering]="true"
          panelClass="mat-select-position"
          formControlName="currency"
        >
          <ng-option *ngFor="let cl of currencyList" [value]="cl.id">
            <div title="{{ cl.country }} - {{ cl.name }} - {{ cl.code }}">
              {{ cl.country }} - {{ cl.name }} - {{ cl.code }}
            </div>
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div class="form-field wp-100">
      <p>
        {{
          (moduleId$ | async) === moduleEnum.RecurringInvoice
            ? "Repeat This Invoice"
            : "Repeat This Purchase"
        }}
      </p>
      <div>
        <div class="form-field-wrapper d-flex align-items-center gap-10">
          <div class="schedule-list d-flex align-items-center gap-10">
            <ng-select
              #recurringTypeLists
              (focus)="recurringTypeLists.open()"
              (valueChange)="recurringTypeLists.close()"
              class="w-120"
              formControlName="recurringType"
            >
              <ng-option
                *ngFor="let type of recurringTypeList"
                [value]="type.value"
              >
                <div title="{{ type.name }}">{{ type.name }}</div>
              </ng-option>
            </ng-select>
            <p
              class="m-0"
              *ngIf="
                recurringForm.controls.recurringType.value !==
                  recurringType.Daily &&
                recurringForm.controls.recurringType.value !==
                  recurringType.Monthly
              "
            >
              Every
            </p>
            <p
              class="m-0"
              *ngIf="
                recurringForm.controls.recurringType.value ===
                recurringType.Monthly
              "
            >
              On The
            </p>
          </div>
          <div
            class="schedule-list d-flex align-items-center gap-10"
            *ngIf="
              recurringForm.controls.recurringType.value ===
              recurringType.Monthly
            "
          >
            <ng-select
              #daysList
              (focus)="daysList.open()"
              (valueChange)="daysList.close()"
              class="w-120"
              [disableOptionCentering]="true"
              formControlName="dayOfMonth"
            >
              <ng-option *ngFor="let day of dayList" [value]="day.value">
                <div title="{{ day.lable }}">{{ day.lable }}</div>
              </ng-option>
            </ng-select>
            <p class="m-0">Day Of The Month</p>
          </div>

          <div
            class="schedule-list d-flex align-items-center gap-10"
            *ngIf="
              recurringForm.controls.recurringType.value ===
              recurringType.Weekly
            "
          >
            <ng-select
              #selectDay
              (focus)="selectDay.open()"
              (valueChange)="selectDay.close()"
              class="w-120"
              [disableOptionCentering]="true"
              formControlName="dayOfWeek"
            >
              <ng-option
                *ngFor="let week of weekList; let i = index"
                [value]="i + 1"
              >
                <div title="{{ week }}">{{ week }}</div>
              </ng-option>
            </ng-select>
          </div>

          <div
            class="schedule-list d-flex align-items-center gap-10"
            *ngIf="
              recurringForm.controls.recurringType.value ===
              recurringType.Yearly
            "
          >
            <ng-select
              #selectMonth
              (focus)="selectMonth.open()"
              (valueChange)="selectMonth.close()"
              class="w-120"
              [disableOptionCentering]="true"
              formControlName="monthOfYear"
            >
              <ng-option
                *ngFor="let month of monthList; let i = index"
                [value]="i + 1"
              >
                <div title="{{ month }}">{{ month }}</div>
              </ng-option>
            </ng-select>
            <p
              class="m-0"
              *ngIf="
                recurringForm.controls.recurringType.value ===
                  recurringType.Yearly ||
                (recurringForm.controls.recurringType.value ===
                  recurringType.Custom &&
                  recurringForm.controls.scheduleSubPeriod.value ===
                    scheduleSubPeriod.Year)
              "
            >
              On The
            </p>
          </div>

          <div
            class="schedule-list d-flex align-items-center gap-10"
            *ngIf="
              recurringForm.controls.recurringType.value ===
              recurringType.Custom
            "
          >
            <mat-form-field class="w-50">
              <input
                matInput
                formControlName="separationCount"
                oninput="this.value = this.value.replace(/[^0-9.]/g,'').replace(/(\..*)\./g, '$1');"
                autocomplete="off"
                maxlength="{{ maxLength.maxLength500 }}"
                style="text-align: center"
              />
            </mat-form-field>
            <ng-select
              #selectSchedule
              (focus)="selectSchedule.open()"
              (valueChange)="selectSchedule.close()"
              class="w-120"
              [disableOptionCentering]="true"
              formControlName="scheduleSubPeriod"
            >
              <ng-option
                *ngFor="let type of scheduleSubPeriodList"
                [value]="type.value"
              >
                <div title="{{ type.name }}">{{ type.name }}</div>
              </ng-option>
            </ng-select>
            <p
              class="m-0"
              *ngIf="
                recurringForm.controls.recurringType.value ===
                  recurringType.Custom &&
                recurringForm.controls.scheduleSubPeriod.value ===
                  scheduleSubPeriod.Year
              "
            >
              in
            </p>
            <p
              class="m-0"
              *ngIf="
                recurringForm.controls.recurringType.value ===
                  recurringType.Custom &&
                recurringForm.controls.scheduleSubPeriod.value ===
                  scheduleSubPeriod.Week
              "
            >
              on
            </p>
            <p
              class="m-0"
              *ngIf="
                recurringForm.controls.recurringType.value ===
                  recurringType.Custom &&
                recurringForm.controls.scheduleSubPeriod.value ===
                  scheduleSubPeriod.Month
              "
            >
              on the
            </p>
          </div>
          <div
            class="schedule-list d-flex align-items-center gap-10"
            *ngIf="
              recurringForm.controls.recurringType.value ===
                recurringType.Custom &&
              recurringForm.controls.scheduleSubPeriod.value ===
                scheduleSubPeriod.Week
            "
          >
            <ng-select
              #selectDayOfWeek
              (focus)="selectDayOfWeek.open()"
              (valueChange)="selectDayOfWeek.close()"
              class="w-120"
              [disableOptionCentering]="true"
              formControlName="dayOfWeek"
            >
              <ng-option
                *ngFor="let week of weekList; let i = index"
                [value]="i"
              >
                <div title="{{ week }}">{{ week }}</div>
              </ng-option>
            </ng-select>
          </div>
          <div
            class="schedule-list d-flex align-items-center gap-10"
            *ngIf="
              recurringForm.controls.recurringType.value ===
                recurringType.Custom &&
              recurringForm.controls.scheduleSubPeriod.value ===
                scheduleSubPeriod.Year
            "
          >
            <ng-select
              #selectMonthOfYear
              (focus)="selectMonthOfYear.open()"
              (valueChange)="selectMonthOfYear.close()"
              class="w-120"
              [disableOptionCentering]="true"
              formControlName="monthOfYear"
            >
              <ng-option
                *ngFor="let month of monthList; let i = index"
                [value]="i + 1"
              >
                <div title="{{ month }}">{{ month }}</div>
              </ng-option>
            </ng-select>
            <p
              class="m-0"
              *ngIf="
                recurringForm.controls.recurringType.value ===
                  recurringType.Yearly ||
                (recurringForm.controls.recurringType.value ===
                  recurringType.Custom &&
                  recurringForm.controls.scheduleSubPeriod.value ===
                    scheduleSubPeriod.Year)
              "
            >
              on the
            </p>
          </div>
          <div
            class="schedule-list d-flex align-items-center gap-10"
            *ngIf="
              recurringForm.controls.recurringType.value ===
                recurringType.Yearly ||
              (recurringForm.controls.recurringType.value ===
                recurringType.Custom &&
                (recurringForm.controls.scheduleSubPeriod.value ===
                  scheduleSubPeriod.Month ||
                  recurringForm.controls.scheduleSubPeriod.value ===
                    scheduleSubPeriod.Year))
            "
          >
            <ng-select
              #selectDayOfMonth
              (focus)="selectDayOfMonth.open()"
              (valueChange)="selectDayOfMonth.close()"
              class="w-120"
              [disableOptionCentering]="true"
              formControlName="dayOfMonth"
            >
              <ng-option *ngFor="let day of dayList" [value]="day.value">
                <div title="{{ day.lable }}">{{ day.lable }}</div>
              </ng-option>
            </ng-select>
            <p class="m-0">Day Of The Month</p>
          </div>
        </div>
        <div class="form-field-wrapper d-flex align-items-center gap-10"></div>
      </div>
    </div>
    <div class="form-field wp-100">
      <p>
        {{
          (moduleId$ | async) === moduleEnum.RecurringInvoice
            ? "Create First Invoice On"
            : "Create First Purchase On"
        }}
        <span class="text-danger-300">*</span>
      </p>
      <div class="d-flex align-items-center gap-10">
        <div class="form-field-wrapper">
          <div class="schedule-list d-flex align-items-center gap-10">
            <mat-form-field class="w-150" appearance="fill">
              <input
                matInput
                [matDatepicker]="onpicker"
                formControlName="startDate"
                autocomplete="off"
                [min]="minStartDate"
                (dateChange)="setMinEndDate()"
                (change)="commonService.validateDate($event)"
                tabindex="-1"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="onpicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #onpicker></mat-datepicker>
            </mat-form-field>
            <p class="m-0">End</p>
            <mat-error
              *ngIf="
                commonService.checkDateValidation(
                  recurringForm.get('startDate')
                ).invalidFinancialYear
              "
            >
              {{ notificationMessage.validPeriodDateMessage }}
            </mat-error>

            <mat-error
              *ngIf="
                commonService.checkDateValidation(
                  recurringForm.get('startDate')
                ).lockedFinancialYear
              "
            >
              {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
            </mat-error>
          </div>
        </div>
        <div class="form-field-wrapper">
          <div class="schedule-list d-flex align-items-center gap-10">
            <div class="d-flex align-items-center gap-10">
              <ng-select
                #selectScheduleType
                (focus)="selectScheduleType.open()"
                (valueChange)="selectScheduleType.close()"
                class="w-150"
                [disableOptionCentering]="true"
                formControlName="scheduleType"
                (change)="setMinEndDate()"
              >
                <ng-option
                  *ngFor="let type of scheduleTypeList"
                  [value]="type.value"
                >
                  <div title="{{ type.name }}">{{ type.name }}</div>
                </ng-option>
              </ng-select>
              <mat-form-field
                *ngIf="
                  recurringForm.controls.scheduleType.value === scheduleType.On
                "
                class="w-150"
                appearance="fill"
              >
                <input
                  matInput
                  [matDatepicker]="onpicker"
                  formControlName="endDate"
                  autocomplete="off"
                  [min]="minEndDate"
                  (change)="commonService.validateDate($event)"
                  tabindex="-1"
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="onpicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #onpicker></mat-datepicker>
              </mat-form-field>
              <mat-error
                *ngIf="
                  commonService.checkDateValidation(
                    recurringForm.get('startDate')
                  ).invalidFinancialYear
                "
              >
                {{ notificationMessage.validPeriodDateMessage }}
              </mat-error>

              <mat-error
                *ngIf="
                  commonService.checkDateValidation(
                    recurringForm.get('startDate')
                  ).lockedFinancialYear
                "
              >
                {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
              </mat-error>
              <mat-form-field
                class="w-50"
                *ngIf="
                  recurringForm.controls.scheduleType.value ===
                  scheduleType.After
                "
              >
                <input
                  matInput
                  formControlName="maxNumOfOccurrences"
                  oninput="this.value = this.value.replace(/[^0-9.]/g,'').replace(/(\..*)\./g, '$1');"
                  autocomplete="off"
                  value="1"
                  maxlength="{{ maxLength.maxLength500 }}"
                  style="text-align: center"
                />
              </mat-form-field>
            </div>
            <p
              class="m-0"
              *ngIf="
                recurringForm.controls.scheduleType.value === scheduleType.After
              "
            >
              Invoices
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="form-field textarea">
      <p>Notes</p>
      <mat-form-field>
        <textarea
          matInput
          formControlName="notes"
          autocomplete="off"
          maxlength="{{ maxLength.maxLength2000 }}"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
