import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaxLength, Modules } from '@app/core/Enum';
import { Country, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CountryState,
  GetAllBranchList,
  GetCountryList,
  MenuState,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-contact-details',
  templateUrl: './add-contact-details.component.html',
  styleUrls: ['./add-contact-details.component.scss'],
})
export class AddContactDetailsComponent implements OnInit {
  contactDetailForm: FormGroup;
  moduleId = Modules;
  maxLength = MaxLength;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  countryList: Country[];
  branchList: any[] = [];
  chartOfAllAccountList: SideListModel[];

  isEdit = false;
  isChecked = true;
  isCisEnabled = false;

  @Input() triggerEditData: Observable<any>;

  @Input()
  getModuleName?: string;

  @Input()
  getModuleId: number;
  private destroy$ = new Subject<void>();

  constructor(private store: Store, private commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.getCountry();
    this.getBranchList();

    this.isEdit = false;
    this.isChecked = true;
    this.triggerEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isEdit = true;
        this.editContactDetail(data);
      });

    this.contactDetailForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.contactDetailForm.touched;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  editContactDetail(data): void {
    this.contactDetailForm.patchValue({
      name: data.name,
      code: data.code,
      tradingName: data.tradingName,
      country: data.countryId,
      addressLine1: data.address1,
      addressLine2: data.address2,
      addressLine3: data.address3,
      city: data.city,
      postCode: data.postalCode,
      email: data.email,
      mobileNumber: data.mobileNumber,
      phone: data.phone,
      fax: data.fax,
      branchId: data.branchId,
      cisEnabled: data.cisEnabled,
    });
    this.isCisEnabled = data.cisEnabled;
  }

  getCountry(): void {
    this.store
      .dispatch(new GetCountryList())
      .pipe(
        tap(() => {
          this.countryList = this.store.selectSnapshot(CountryState.getCountry);
          this.contactDetailForm.controls.country.setValue(
            this.countryList[205]?.id
          );
        })
      )
      .subscribe();
  }

  setForm(): void {
    this.contactDetailForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      tradingName: new FormControl(''),
      country: new FormControl(''),
      addressLine1: new FormControl(''),
      addressLine2: new FormControl(''),
      addressLine3: new FormControl(''),
      city: new FormControl(''),
      postCode: new FormControl(''),
      email: new FormControl(''),
      mobileNumber: new FormControl(''),
      phone: new FormControl(''),
      fax: new FormControl(''),
      branchId: new FormControl(''),
      cisEnabled: new FormControl(false),
    });
  }

  getBranchList(): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.contactDetailForm.controls.branchId.setValue(
              this.branchList[0].id
            );
            this.contactDetailForm.controls.branchId.setValidators(
              Validators.required
            );
            this.contactDetailForm.controls.branchId.updateValueAndValidity();
          } else {
            this.branchList = [];
          }
        })
      )
      .subscribe();
  }
}
