<div class="content button-with-pagination">
  <div class="main-list content mt-1">
    <div class="d-flex align-items-end wp-100 mb-1 gap-10">
      <div class="grid-search">
        <mat-form-field appearance="fill" class="search-field">
          <input
            matInput
            placeholder="Search here..."
            autocomplete="off"
            [(ngModel)]="searchText"
            (keyup.enter)="onSearch()"
          />
          <button
            mat-button
            disableRipple
            class="search-button"
            (click)="onSearch()"
          >
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="header-actions wp-100">
        <div
          class="align-items-end content-header d-flex header-count header-filters justify-space-between header-count-1"
        >
          <div class="d-flex align-items-center gap-10">
            <ng-select
              #clienttype
              (focus)="clienttype.open()"
              (valueChange)="clienttype.close()"
              [disableOptionCentering]="true"
              dropdownPosition="bottom"
              [(ngModel)]="companyType"
              (change)="onDropdownChange()"
            >
              <ng-option [value]="0">Client Type</ng-option>
              <ng-option [value]="3">Limited</ng-option>
              <ng-option [value]="1">Sole Trader</ng-option>
              <ng-option [value]="5">LLP</ng-option>
              <ng-option [value]="2">Partnership</ng-option>
              <ng-option [value]="4">Trust</ng-option>
            </ng-select>
            <p class="d-flex align-items-center gap-5 m-0">
              Continue with
              <a (click)="onChangeCompany(true, null)" class="link">
                {{ companyName }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="mat-table-wrapper mt-1"
    [ngClass]="
      totalSelectedRecords <= 0 && (totalRecord$ | async) > 0 ? 'mb-2' : ''
    "
  >
    <table
      mat-table
      [dataSource]="companyList"
      aria-describedby="Main Grid Table"
    >
      <ng-container matColumnDef="sNo">
        <th mat-header-cell *matHeaderCellDef>S.No.</th>
        <td mat-cell *matCellDef="let element">{{ element.sNo }}</td>
      </ng-container>
      <ng-container matColumnDef="companyRefNo">
        <th mat-header-cell *matHeaderCellDef>Client ID</th>
        <td mat-cell *matCellDef="let element">{{ element.companyRefNo }}</td>
      </ng-container>
      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef>Client Name</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <a
              (click)="onChangeCompany(false, element)"
              class="link clamp wmx-100 fw-bold text-primary"
              title="{{ element.companyName }}"
              ><span>{{ element.companyName }}</span></a
            >
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="companyTypeName">
        <th mat-header-cell *matHeaderCellDef>Client Type</th>
        <td mat-cell *matCellDef="let element">
          {{ element.companyTypeName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="vatNumber">
        <th mat-header-cell *matHeaderCellDef>VAT Number</th>
        <td mat-cell *matCellDef="let element">{{ element.vatNumber }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div *ngIf="noDataFound" class="text-align-center mt-1">
      No records found
    </div>
  </div>
  <div
    class="paginator-wrapper"
    *ngIf="totalSelectedRecords <= 0 && (totalRecord$ | async) > 0"
  >
    <app-custom-paginator
      *ngIf="(totalRecord$ | async) > 10"
      [length]="totalRecord$ | async"
      [listParameters]="listParameters"
      (pageChanged)="pageChanged($event)"
      (pageSizeVal)="pageSizeVal($event)"
      (togglePaginator)="togglePaginator($event)"
      [triggerPaginationChange]="triggerPaginationChange"
    >
    </app-custom-paginator>
  </div>
  <div class="norecord-chart" *ngIf="isNoRecord">
    <p class="pt-10">No data available yet!</p>
  </div>
</div>
<app-header *ngIf="reloadDashboard"></app-header>
