import { Injectable } from '@angular/core';
import { CommonService } from '@app/core/Services';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class GlobalComponent {
  constructor(
    private cookieService: CookieService,
    private commonService: CommonService
  ) {}

  public getLoggedInAuthorizedRole(): number {
    const authorizedRole = this.cookieService.get('authorizedRole');
    return +authorizedRole;
  }

  public getLoggedInUserId(): Guid {
    const userId = this.cookieService.get('userid');
    return !!this.commonService.convertStringToGuid(userId)
      ? this.commonService.convertStringToGuid(userId)
      : (Guid.EMPTY as unknown as Guid);
  }

  public getFinancialPeriod(): any {
    const financialPeriodStorage =
      this.commonService.getLocalStorage('financialPeriod');
    try {
      if (financialPeriodStorage === undefined) {
        return null;
      }
      const financialPeriod = JSON.parse(financialPeriodStorage);
      return financialPeriod;
    } catch (error) {
      return null;
    }
  }

  public getDefaultCurrency(): any {
    return JSON.parse(this.cookieService.get('defaultCurrency'));
  }

  public getBusinessId(): Guid {
    const businessid = this.cookieService.get('businessid');

    return !!this.commonService.convertStringToGuid(businessid)
      ? this.commonService.convertStringToGuid(businessid)
      : (Guid.EMPTY as unknown as Guid);
  }

  public getCompanyId(): Guid {
    const companyid = this.cookieService.get('companyid');

    return !!this.commonService.convertStringToGuid(companyid)
      ? this.commonService.convertStringToGuid(companyid)
      : (Guid.EMPTY as unknown as Guid);
  }

  public getDepartmentId(): Guid {
    const departmentid = this.cookieService.get('departmentId');

    return !!this.commonService.convertStringToGuid(departmentid)
      ? this.commonService.convertStringToGuid(departmentid)
      : (Guid.EMPTY as unknown as Guid);
  }

  public getBranchId(): Guid {
    const branchid = this.cookieService.get('branchId');

    return !!this.commonService.convertStringToGuid(branchid)
      ? this.commonService.convertStringToGuid(branchid)
      : (Guid.EMPTY as unknown as Guid);
  }

  public themecolor(): string {
    const themecolor = this.cookieService.get('themecolor');
    return themecolor;
  }

  public checkCapiumPayIntegrated(): string {
    return this.cookieService.get('showPaymentMailOption');
  }

  public getDeafultCompanyName(): string {
    const defaultCompanyName = this.cookieService.get('defaultCompanyName');
    return defaultCompanyName;
  }

  public getIsVatRegistered(): any {
    return JSON.parse(this.cookieService.get('isVatRegistered'));
  }
  public getIsTrailUser(): any {
    return JSON.parse(this.cookieService.get('isTrailUser'));
  }
  public isRegularUser(): boolean {
    const isRegularUser = this.cookieService.get('isRegularUser');
    return !!isRegularUser ? isRegularUser === 'true' : false;
  }
}
