import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import {
  CreditNoteModel,
  PaymentDetailsModel,
  ProductDetailsModel,
} from '@app/core/Models';

import { CommonService } from '@app/core/Services';
import {
  CreateCreditNote,
  GetBankAccountTransactionData,
  GetCreditNoteNumber,
  GetDataByCreditNoteId,
  GetTransactionLogData,
  MenuState,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  AddCreditNoteComponent,
  CreditNoteAllocationComponent,
  ProductDetailsComponent,
  TransactionsAttachmentComponent,
  TransactionsPaymentComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-credit-note',
  templateUrl: './credit-note.component.html',
  styleUrls: ['./credit-note.component.scss'],
})
export class CreditNoteComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isExit = false;

  moduleId = Modules.CreditNote;
  moduleName = ModuleName.CreditNote;

  creditNoteId = Guid.EMPTY as unknown as Guid;
  defaultGuid = Guid.EMPTY as unknown as Guid;
  balanceAmount = 0;
  amountReceivedTotal = 0;
  amountPaymentReceivedTotal = 0;
  headerText = 'Receipt';

  @Output()
  readonly reloadSideList = new EventEmitter<any>();
  @ViewChild(MatAccordion) accordion: MatAccordion;

  creditNoteData: CreditNoteModel;
  productItems: Array<ProductDetailsModel>;
  receiptItems: Array<PaymentDetailsModel>;
  creditAllocationItems = new Array<any>();

  @ViewChild(AddCreditNoteComponent, { static: true })
  creditNoteDetails;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  @ViewChild(ProductDetailsComponent, { static: true })
  productDetails;

  @ViewChild(TransactionsPaymentComponent, { static: true })
  receiptInfo;

  @ViewChild(CreditNoteAllocationComponent, { static: true })
  creditNoteAllocationInfo;

  triggerEditData: Subject<any> = new Subject<any>();
  triggereEditProductData: Subject<any> = new Subject<any>();
  triggerCustomer: Subject<any> = new Subject<any>();
  triggereEditPaymentData: Subject<any> = new Subject<any>();
  triggerReceiptDetail: Subject<any> = new Subject<any>();
  triggerOnVATChange: Subject<boolean> = new Subject<boolean>();
  triggerAccountSelectedOption: Subject<boolean> = new Subject<boolean>();
  triggerisVatPostponed: Subject<any> = new Subject<any>();
  triggerisVatIncluded: Subject<any> = new Subject<any>();
  triggerBranchChange: Subject<any> = new Subject<any>();
  subscriptionRouting: Subscription;
  triggerCurrencySelectedOption: Subject<boolean> = new Subject<boolean>();
  triggerCurrencyRefreshOption: Subject<boolean> = new Subject<boolean>();
  triggerSelectedCurrencyRate: Subject<number> = new Subject<number>();
  triggerTransactionLogData: Subject<any> = new Subject<any>();

  isFromBankImport = false;
  isManualBank = false;
  selectedBank: any;
  bankImportTransactionId = Guid.EMPTY as unknown as Guid;
  selectedCurrencyRate: number;
  selectedCurrencyId: number;
  receiptAccountSelectedCurrencyId: number;
  commonService: CommonService;
  store: Store;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  isReceiptChangePermission: boolean = true;
  public defaultGuidValue = Guid.EMPTY as unknown as Guid;
  constructor(
    private location: Location,
    private _Activatedroute: ActivatedRoute,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private renderer: Renderer2,
    private injector: Injector
  ) {
    this.commonService = injector.get<CommonService>(CommonService);
    this.store = injector.get<Store>(Store);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          const isFromBankImport = params.get('isFromBankImport');
          const isManualBank = params.get('isManualBank');

          if (isFromBankImport !== null && isManualBank !== null) {
            this.isFromBankImport = JSON.parse(atob(isFromBankImport));
            this.isManualBank = JSON.parse(atob(isManualBank));
            this.selectedBank = atob(params.get('customId')!) as unknown as any;
          }

          const id = atob(params.get('id')!) as unknown as Guid;

          if (this.isFromBankImport) {
            this.bankImportTransactionId = id;
            this.getCreditNoteNumber();

            if (this.isManualBank) {
              this.getTransactionLogData();
            } else {
              this.getBankAccountTransactionData();
            }
          } else {
            this.creditNoteId = id;
            this.editCrediteNote();
          }
        } else {
          this.getCreditNoteNumber();
        }
      }
    );
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isReceiptChangePermission = this.commonService.checkPermission(
        Modules.Sales,
        Modules.Receipt
      );
    }, 3000);
  }

  getTransactionLogData(): void {
    this.store
      .dispatch(new GetTransactionLogData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.creditNoteDetails.creditNoteForm.controls.entryDate.setValue(
          res.common.transactionLogData.entryDate
        );
        this.creditNoteDetails.creditNoteForm.controls.note.setValue(
          res.common.transactionLogData.note
        );
        this.creditNoteDetails.creditNoteForm.controls.amountReceived.setValue(
          res.common.transactionLogData.amount
        );
        this.creditNoteDetails.creditNoteForm.controls.amountReceived.disable();
        this.triggerTransactionLogData.next(res.common.transactionLogData);
      });
  }

  getBankAccountTransactionData(): void {
    this.store
      .dispatch(new GetBankAccountTransactionData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.creditNoteDetails.creditNoteForm.controls.entryDate.setValue(
          res.common.transactionLogData.transactionDate
        );
        this.creditNoteDetails.creditNoteForm.controls.note.setValue(
          res.common.transactionLogData.description
        );
        this.creditNoteDetails.creditNoteForm.controls.amountReceived.setValue(
          res.common.transactionLogData.amount
        );
        this.creditNoteDetails.creditNoteForm.controls.amountReceived.disable();
      });
  }

  onCustomerChange(): void {
    this.creditNoteAllocationInfo.clearForm();
    for (
      let i = 0;
      i < this.creditNoteAllocationInfo.creditDetailArray.length;
      i++
    ) {
      this.creditNoteAllocationInfo.creditDetailArray.controls[i]
        .get('invoiceReceipt')
        .setValue(0);
      this.creditNoteAllocationInfo.creditDetailArray.controls[i]
        .get('id')
        .setValue(Guid.EMPTY as unknown as Guid);
    }

    this.triggerCustomer.next(this.creditNoteDetails.creditNoteForm);
  }

  editCrediteNote(): void {
    this.store
      .dispatch(new GetDataByCreditNoteId(this.creditNoteId))
      .subscribe((res) => {
        this.creditNoteDetails.creditNoteForm.markAsUntouched();
        this.productDetails.formProductDetail.markAsUntouched();
        this.receiptInfo.paymentForm.markAsUntouched();
        this.creditNoteAllocationInfo.formCreditDetail.markAsUntouched();
        setTimeout(() => {
          this.triggerEditData.next(res.creditNote.creditNoteData);

          const data = {
            productData: res.creditNote.creditNoteData.creditNoteItems,
            isVATSelected: res.creditNote.creditNoteData.isVatPostponed,
          };

          this.triggereEditProductData.next(data);

          this.triggereEditPaymentData.next(
            res.creditNote.creditNoteData.receiptDetails
          );
          this.triggerReceiptDetail.next(
            res.creditNote.creditNoteData.invoiceAllocations
          );
          this.triggerisVatPostponed.next(res.bill.billData.isVatPostponed);
          this.triggerisVatIncluded.next(res.bill.billData.isVatIncluded);
        }, 500);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  triggerReloadSideList(): void {
    this.reloadSideList.emit();
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.creditNoteId)) {
      this.editCrediteNote();
    } else {
      this.creditNoteId = Guid.EMPTY as unknown as Guid;
      this.creditNoteDetails.ngOnInit();
      this.attachmentDetail.ngOnInit();
      this.productDetails.ngOnInit();
      this.receiptInfo.ngOnInit();
      this.creditNoteAllocationInfo.ngOnInit();
      this.getCreditNoteNumber();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.creditNoteId,
      isExit,
      Modules.CreditNote,
      RoutingPath.AddCreditNote
    );
  }
  dataSubmit(): boolean {
    this.spinner.show();
    this.selectedCurrencyId =
      this.creditNoteDetails.creditNoteForm.controls.currency.value === 0
        ? 123
        : this.creditNoteDetails.creditNoteForm.controls.currency.value;
    this.selectedCurrencyRate =
      this.creditNoteDetails.creditNoteForm.controls.currencyAmt.value;
    this.receiptAccountSelectedCurrencyId =
      this.receiptInfo.selectedAccountCurrencyId;
    try {
      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.productItems = new Array<ProductDetailsModel>();

      this.productDetails.productDetailArray?.getRawValue().forEach((x) => {
        this.productItems.push({
          id: x.id,
          productId: x.productName ? x.productName : this.defaultGuidValue,
          description: x.description,
          quantity: +x.qty,
          price: +x.price / this.selectedCurrencyRate,
          vatRateId: x.vatRate === -1 ? null : x.vatRate,
          vatAmount: +x.vatAmount / this.selectedCurrencyRate,
          totalAmount: +(x.amount / this.selectedCurrencyRate).toFixed(2),
          foreignVatAmount: +x.vatAmount,
          foreignPrice: +x.price,
          foreignTotalAmount:  +(+x.amount).toFixed(2),
          bookAccountId: x.invoiceType?.id
        });
      });

      this.receiptItems = new Array<PaymentDetailsModel>();

      this.receiptInfo.paymentArray?.getRawValue().forEach((x) => {
        const attachmentList: any = [];
        if (
          x.attachment !== null &&
          x.attachment !== undefined &&
          x.attachment !== '' &&
          x.attachment.length > 0
        ) {
          x.attachment.forEach((element) => {
            attachmentList.push(element);
          });
        }

        if (x.accountId !== '' && x.amount !== '') {
          this.receiptItems.push({
            id: x.id,
            receiptId: x.receiptId,
            transactionDate: this.datepipe
              .transform(x.receiptDate, 'yyyy-MM-dd')
              ?.toString(),
            amount:
              this.receiptAccountSelectedCurrencyId !== 123
                ? x.amount / this.selectedCurrencyRate
                : x.amount,
            postingAccountId: x.accountId,
            attachment: attachmentList,
            foreignAmount:
              this.receiptAccountSelectedCurrencyId !== 123
                ? x.amount
                : x.amount * this.selectedCurrencyRate,
            currencyRate: this.selectedCurrencyRate,
          });
        }
      });

      this.creditAllocationItems = new Array<any>();
      this.creditNoteAllocationInfo.creditDetailArray
        ?.getRawValue()
        .forEach((x) => {
          this.creditAllocationItems.push({
            id: x.id,
            toInvoiceId: x.invoiceReceipt,
            amount:
              this.selectedCurrencyId !== 123
                ? +x.amount / this.selectedCurrencyRate
                : +x.amount,
            transactionDate: this.datepipe
              .transform(x.receiptDate, 'yyyy-MM-dd')
              ?.toString(),
            toInvoiceTypeID: x.invoiceTypeId,
            foreignAmount: +x.amount,
          });
        });

      this.creditNoteData = {
        id: this.creditNoteId,
        entryNumber:
          this.creditNoteDetails.creditNoteForm.controls.creditNoteNo.value,
        accountId:
          this.creditNoteDetails.creditNoteForm.controls.customerName.value,
       // bookAccountId:
         // this.creditNoteDetails.creditNoteForm.controls.creditNoteType.value
         //   .id,
        entryDate: this.datepipe
          .transform(
            this.creditNoteDetails.creditNoteForm.controls.entryDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),

        currencyId:
          this.creditNoteDetails.creditNoteForm.controls.currency.value,
        currencyRate:
          this.creditNoteDetails.creditNoteForm.controls.currencyAmt.value,
        note: this.creditNoteDetails.creditNoteForm.controls.note.value,
        voucherNumber:
          this.creditNoteDetails.creditNoteForm.controls.invoiceRefNo.value,
        attachment: filedata,
        creditNoteItems: this.productItems,
        receiptDetails: this.receiptItems,
        invoiceAllocations: this.creditAllocationItems,
        isVatPostponed:
          this.creditNoteDetails.creditNoteForm.controls.isVatPostponed.value,
        transactionLogId: this.isFromBankImport
          ? this.bankImportTransactionId
          : (Guid.EMPTY as unknown as Guid),
        isVatIncluded:
          this.creditNoteDetails.creditNoteForm.controls.isVatInclude.value,
        branchId:
          this.creditNoteDetails.creditNoteForm.controls.branchId.value === ''
            ? null
            : this.creditNoteDetails.creditNoteForm.controls.branchId.value,
        departmentId:
          this.creditNoteDetails.creditNoteForm.controls.departmentId.value ===
          ''
            ? null
            : this.creditNoteDetails.creditNoteForm.controls.departmentId.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.creditNoteDetails.creditNoteForm.invalid) {
      this.commonService.addValidation(
        this.creditNoteDetails.creditNoteForm,
        this.renderer
      );
    } else if (
      !this.isFromBankImport &&
      this.receiptInfo.paymentArray.invalid
    ) {
      this.receiptInfo.paymentArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (
            x.controls.amount.value === '' ||
            x.controls.amount.value === '0'
          ) {
            this.commonService.onFailure(
              NotificationTextMessage.amtErrorMessage
            );
          }
          c.markAsTouched();
        });
      });
    } else if (
      !this.isFromBankImport &&
      this.creditNoteAllocationInfo.creditDetailArray.invalid
    ) {
      this.creditNoteAllocationInfo.creditDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (
            x.controls.amount.value === '' ||
            x.controls.amount.value === '0'
          ) {
            this.commonService.onFailure(
              NotificationTextMessage.amtErrorMessage
            );
          }
          c.markAsTouched();
        });
      });
    } else if (
      !this.isFromBankImport &&
      +((+this.productDetails.totalAmount).toFixed(2)) <
        +this.creditNoteAllocationInfo.totalAmount +
          +this.receiptInfo.totalReciptAmount
    ) {
      if (this.receiptInfo.paymentArray.length > 0) {
        this.receiptInfo.paymentArray.controls.forEach((x) => {
          (Object as any)
            .values(x.controls)
            .forEach((c) => c.markAsTouched(false));
          x.controls.amount.status = 'INVALID';
        });
      }

      if (this.creditNoteAllocationInfo.creditDetailArray.length > 0) {
        this.creditNoteAllocationInfo.creditDetailArray.controls.forEach(
          (x) => {
            (Object as any)
              .values(x.controls)
              .forEach((c) => c.markAsTouched(false));
            x.controls.amount.status = 'INVALID';
          }
        );
      }
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
    } else if (
      this.isFromBankImport &&
      +((+this.productDetails.totalAmount).toFixed(2)) <
        +this.creditNoteDetails.creditNoteForm.controls.amountReceived.value
    ) {
      this.commonService.onFailure(NotificationTextMessage.amountTotalError);
    } else if (this.productDetails.productDetailArray.invalid) {
      this.productDetails.productDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateCreditNote(this.creditNoteData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit && !this.isFromBankImport) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.commonService.setLocalStorage(
                    'selectedBank',
                    this.selectedBank
                  );
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  onAmountReceivedChange(): void {
    this.amountReceivedTotal = this.creditNoteAllocationInfo.totalAmount;
  }

  onAmountPaymentReceivedChange(): void {
    this.amountPaymentReceivedTotal = this.receiptInfo.totalReciptAmount;
  }

  onVATChange(isVATChecked: boolean) {
    this.triggerOnVATChange.next(isVATChecked);
  }

  onAccountSelectedOption(selectedOption: any) {
    this.triggerAccountSelectedOption.next(selectedOption);
  }

  isVatPostponedClick(event): void {
    this.triggerisVatPostponed.next(event);
  }

  isVatIncludedClick(event): void {
    this.triggerisVatIncluded.next(event);
  }

  onBranchChange(selectedOption: any) {
    this.triggerBranchChange.next(selectedOption);
  }
  onCurrencySelectedOption(selectedOption: any) {
    this.triggerCurrencySelectedOption.next(selectedOption);
  }
  onCurrencyRefreshOption(selectedOption: any) {
    this.triggerCurrencyRefreshOption.next(selectedOption);
  }
  onCurrencySelectedRate(selectedOption: any) {
    this.triggerSelectedCurrencyRate.next(selectedOption);
  }

  getCreditNoteNumber(): void {
    this.store.dispatch(new GetCreditNoteNumber()).subscribe((res) => {
      res.creditNote.creditNoteNo !== null
        ? this.creditNoteDetails.creditNoteForm.controls.creditNoteNo.disable()
        : this.creditNoteDetails.creditNoteForm.controls.creditNoteNo.enable();
      this.creditNoteDetails.creditNoteForm.controls.creditNoteNo.setValue(
        res.creditNote.creditNoteNo
      );
    });
  }

  reloadAutoNumberGenerator(): void {
    this.getCreditNoteNumber();
  }
}
