export enum ExportFormat {
  Unknown = 0,
  Csv = 1,
  Excel = 2,
  GoogleDrive = 3,
  PDF = 4,
}
export enum ExportType {
  UnKnown = 0,
  CSV = 1,
  Excel = 2,
  GoogleDrive = 3,
  PDF = 4,
}
