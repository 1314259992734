import { DatePipe } from '@angular/common';
import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActionType,
  ConfirmationType,
  DataType,
  FilteredStatus,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { ExportFormat } from '@app/core/Enum/export-type';
import {
  ExportType,
  MainListParameters,
  ViewParamModel,
} from '@app/core/Models';
import {
  CommonService,
  ModulePermission,
  NotificationService,
} from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  CommonState,
  Copy,
  Export,
  ExportReceipt,
  GetReportList,
  MenuState,
  SendEmail,
} from '@app/core/Store';
import {
  ConfirmationBoxComponent,
  EditAssetDetailComponent,
  EditDisposeAssetComponent,
  RollBackPopupComponent,
  TransactionHeaderComponent,
  ViewReceiptComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-report-list-with-actions',
  templateUrl: './report-list-with-actions.component.html',
  styleUrls: ['./report-list-with-actions.component.scss'],
})
export class ReportListWithActionsComponent implements OnInit {
  @Select(CommonState.columns)
  columns$: Observable<
    Array<{
      name: string;
      value: string;
      dataType?: DataType;
      allowSortBy?: boolean;
    }>
  >;

  isRowHighlighted = false;

  showPaginator = true;
  checkBoxValue = 0;
  filteredStatus = FilteredStatus;

  @Select(CommonState.columnNames)
  columnsNames$: Observable<Array<string>>;

  @Select(CommonState.reportActions)
  actions$: Observable<any>;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  listParameters: MainListParameters = new MainListParameters();
  totalSelectedRecords = 0;
  isAllSelected = false;
  isExpanded = true;
  noDataFound = false;
  ids: Array<Guid>;
  selectedRowData: Array<any> = [];
  mainList: any;
  dataSource = new MatTableDataSource();
  dataType = DataType;
  moduleId: number;
  moduleEnum = Modules;
  selectedRowIndex = -1;
  moreActionCount = 4;
  moduleName: string;

  triggerPaginationChange: Subject<any> = new Subject<any>();
  triggerSelectedRowData: Subject<any> = new Subject<any>();

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(MenuState.hasEditPermission)
  hasEditPermission$: Observable<boolean>;

  @Select(MenuState.hasDeletePermission)
  hasDeletePermission$: Observable<boolean>;

  @ViewChild(MatSort) sort: MatSort;

  dialog: MatDialog;
  store: Store;
  notify: NotificationService;
  commonService: CommonService;
  router: Router;
  highlightRow: HighlightRow;
  datepipe: DatePipe;
  spinner: NgxSpinnerService;
  modulePermission: ModulePermission;
  _Activatedroute: ActivatedRoute;
  showTransactionDetails = false;
  @ViewChild(TransactionHeaderComponent, { static: true })
  headerDetails;

  subscriptionRouting: Subscription;
  subscription: Subscription;
  receiptList: any[] = [];
  paymentList: any[] = [];
  @Input() triggerModuleId: Observable<any>;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(private injector: Injector) {
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.store = injector.get<Store>(Store);
    this.notify = injector.get<NotificationService>(NotificationService);
    this.commonService = injector.get<CommonService>(CommonService);
    this.router = injector.get<Router>(Router);
    this.highlightRow = injector.get<HighlightRow>(HighlightRow);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.modulePermission = injector.get<ModulePermission>(ModulePermission);
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.paymentList = this.commonService.paymentList;
    this.receiptList = this.commonService.receiptList;
  }

  ngOnInit(): void {
    this.subscription = this.triggerModuleId?.subscribe((res) => {
      this.moduleId = res;
      this.moduleName = this.commonService.getModuleName(this.moduleId);
      this.highlightRecord();
    });

    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.showTransactionDetails = JSON.parse(
            atob(params.get('details')!)
          );
          if (this.showTransactionDetails) {
            const id = atob(params.get('id')!) as unknown as any;
            this.listParameters.moduleId = id;
            this.moduleId = +atob(params.get('moduleId')!) as unknown as any;
            this.moduleName = this.commonService.getModuleName(this.moduleId);
            this.headerDetails.ngOnInit();
            this.highlightRecord();
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  highlightRecord(): void {
    if (this.highlightRow.mainListHighlighted.moduleId! > 0) {
      this.listParameters.sortBy = this.highlightRow.mainListHighlighted.sortBy;
      this.listParameters.sortOrder = false;
      this.getList(true);
    } else {
      this.getList(false);
    }
  }

  selectAll(event: any): void {
    this.mainList.forEach((x) => (x.isSelected = event.checked));
    this.showFooter();
  }

  showFooter(): void {
    this.selectedIds();
    this.totalSelectedRecords = this.ids.length;

    setTimeout(() => {
      this.triggerSelectedRowData.next(this.selectedRowData);
    }, 100);
  }

  deleteClick(): void {
    this.selectedIds();

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (this.ids.length > 0 && isValidPeriod) {
          this.dialog
            .open(ConfirmationBoxComponent, {
              data: {
                ids: this.ids,
                type: ConfirmationType.Delete,
                moduleId: this.moduleId,
                totalNumberOfRecordSelected: this.ids.length,
                headerText: NotificationHeader.deleteConfirmation,
                detailText: NotificationDetails.deleteAllDetailText,
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.getList(false);
                this.cancelSelectionClick();
              }
            });
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  export(format: number, isPrint: boolean = false): void {
    this.spinner.show();
    this.selectedIds(format);
    if (
      (this.moduleId === Modules.Invoices ||
        this.moduleId === Modules.Purchase ||
        this.moduleId === Modules.Quotation ||
        this.moduleId === Modules.CreditNote ||
        this.moduleId === Modules.DebitNote ||
        this.moduleId === Modules.FixedAssets ||
        this.moduleId === Modules.Journals ||
        this.moduleId === Modules.Receipt ||
        this.moduleId === Modules.Payment ||
        this.moduleId === Modules.FixedAssetDetail ||
        this.moduleId === Modules.BridgingVAT ||
        this.moduleId === Modules.FixedAssetsRegister) &&
      format === ExportFormat.PDF &&
      this.ids.length > 0
    ) {
      this.store
        .dispatch(new ExportReceipt(this.moduleId, this.ids, isPrint))
        .subscribe();
      this.cancelSelectionClick();
    } else {
      const queryParams = {
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: this.listParameters.filter,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        search: this.listParameters.search,
        moduleId: this.listParameters.moduleId,
        subModuleId: this.listParameters.subModuleId ?? -1,
        ids: this.ids,
        startDate:
          this.datepipe
            .transform(this.listParameters.startDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        endDate:
          this.datepipe
            .transform(this.listParameters.endDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        format: format,
        isPrint: isPrint,
      };

      this.store.dispatch(new Export(queryParams, this.moduleId)).subscribe();
      this.cancelSelectionClick();
    }
  }

  copyClick(): void {
    this.spinner.show();

    this.selectedIds();

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (isValidPeriod) {
          this.store
            .dispatch(new Copy(this.moduleId, this.ids))
            .subscribe((res) => {
              if (res.common.isCopied) {
                this.notify.success(
                  NotificationHeader.success,
                  NotificationTextMessage.recordCopySuccessfully
                );
                this.getList(false);
                this.cancelSelectionClick();
              }
            });
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  cancelSelectionClick(): void {
    this.deSelectAll();
  }

  deSelectAll(): void {
    this.mainList?.forEach((x) => (x.isSelected = false));
    this.isAllSelected = false;
    this.totalSelectedRecords = 0;
  }

  printClick(): void {
    this.export(ExportType.PDF, true);
    this.cancelSelectionClick();
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.getList(false);
  }

  onAddClick(element: any): void {
    this.router.navigate([
      RoutingPath.AddCustomAccounts,
      { accountId: btoa(element[0]) },
    ]);
  }

  archiveAndRestoreClick(isArchive: any): void {
    this.selectedIds();

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (this.ids.length > 0 && isValidPeriod) {
          this.dialog
            .open(ConfirmationBoxComponent, {
              data: {
                ids: this.ids,
                isArchive,
                type: ConfirmationType.Archive,
                moduleId: this.moduleId,
                totalNumberOfRecordSelected: this.ids.length,
                headerText: isArchive
                  ? NotificationHeader.archiveConfirmation
                  : NotificationHeader.restoreConfirmation,
                detailText: isArchive
                  ? NotificationDetails.archiveDetailText
                  : NotificationDetails.restoreDetailText,
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.ids = [];
                this.getList(false);
                this.cancelSelectionClick();
              }
            });
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  selectedIds(format?: number): void {
    this.ids = [];
    this.selectedRowData = [];

    this.mainList.forEach((value) => {
      if (value.isSelected) {
        if (this.moduleId !== Modules.FixedAssetsRegister) {
          this.ids.push(value[0].rowData);
          this.selectedRowData.push(value[this.mainList[0].length - 1].rowData);
        } else {
          if (format !== null && format === ExportFormat.PDF) {
            this.ids.push(value[6].rowData);
          } else {
            this.ids.push(value[0].rowData);
          }
          this.selectedRowData.push(value[this.mainList[0].length - 1].rowData);
        }
      }
    });
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.getList(false);
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.getList(false);
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  onCheckBoxSelected(element: any, event: any): void {
    element.isSelected = event.checked;

    this.isAllSelected = this.mainList.every(
      (item: any) => item.isSelected === true
    );
    this.showFooter();
  }

  onDeleteClick(id: any): void {
    this.ids = [];
    this.ids.push(id);
    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: this.moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getList(false);
          }
        });
    }
  }

  onButtonClick(id: any, actionType: ActionType): void {
    this.ids = [];
    this.ids.push(id.rowData);

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (
          isValidPeriod ||
          actionType === ActionType.Email ||
          actionType === ActionType.Export ||
          actionType === ActionType.View
        ) {
          switch (actionType) {
            case ActionType.Update:
              this.commonService.onEditRouting(true, this.moduleId, id.rowData);
              break;

            case ActionType.Delete:
              this.onDeleteClick(id.rowData);
              break;

            case ActionType.Export:
              this.ids = [];
              this.ids.push(id);
              this.store
                .dispatch(new ExportReceipt(this.moduleId, this.ids, false))
                .subscribe();
              break;

            case ActionType.View:
              const data: ViewParamModel = {
                moduleId: this.moduleId,
                id,
              };

              this.dialog
                .open(ViewReceiptComponent, {
                  data,
                  disableClose: true,
                })
                .afterClosed()
                .subscribe((result) => {});
              break;

            case ActionType.Email:
              this.store.dispatch(new SendEmail(this.moduleId, id)).subscribe();
              break;

            case ActionType.Activate:
              this.onDisposedActivateClick(id.rowData);
              break;
          }
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  onDisposedActivateClick(id: any): void {
    this.ids = [];
    this.ids.push(id);

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Activate,
            moduleId: this.moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.disposedConfirmation,
            detailText: NotificationDetails.disposedDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getList(false);
          }
        });
    }
  }

  getList(isRowHighlighted: boolean): void {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids:
        this.listParameters.ids !== undefined &&
        this.listParameters.ids.length > 0
          ? this.listParameters.ids
          : null,
      branchIds: this.listParameters.branchIds ?? [],
      departmentIds: this.listParameters.departmentIds ?? [],
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    this.spinner.show();
    this.isRowHighlighted = isRowHighlighted;
    this.headerDetails.isRowHighlighted = isRowHighlighted;

    this.store
      .dispatch(new GetReportList(queryParams, this.moduleId))
      .subscribe((res) => {
        this.highlightRow.mainListHighlighted.moduleId = 0;
        this.spinner.hide();

        this.actions$.subscribe((x) => {
          if (x !== undefined && x !== null && x.length > 4) {
            this.moreActionCount = 3;
          }
        });
        this.mainList = res.common.mainList.resultSet.data;
        this.noDataFound = this.mainList.length > 0 ? false : true;
        this.dataSource.data = this.mainList;
        this.checkBoxValue = this.dataSource.data.length;
        const param = {
          pageSize: this.listParameters.pageSize,
          totalRecord: res.common.totalRecord,
        };
        this.triggerPaginationChange.next(param);
      });
  }

  getDataFromHeader(data: any): void {
    if (data.search !== '') {
      this.ids = [];
    }
    data.ids = this.ids;
    if (data.search === '') {
      this.spinner.show();
    }
    if (this.moduleId === Modules.BankImportTransactionHistory) {
      data.moduleId = this.listParameters.moduleId;
    }
    this.listParameters = data;
    !this.listParameters.format
      ? this.getList(this.headerDetails.isRowHighlighted)
      : this.export(this.listParameters.format, this.listParameters.isPrint);
    this.cancelSelectionClick();
  }

  getDataFromBank(data: any): void {
    this.isExpanded = data.isExpanded;
    this.listParameters.moduleId = data.id;
    this.getList(false);
  }

  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
  }

  onToggleMatMenu(i): void {
    this.selectedRowIndex = i;
  }

  receiptPaymentChange(event: any, data: any): void {
    this.commonService.onEditRouting(
      false,
      event.moduleId,
      data[0].rowData,
      true,
      undefined,
      true
    );
  }

  redirect(id: any, moduleId: number): void {
    if (moduleId > 0) {
      if (moduleId === Modules.Customers || moduleId === Modules.Suppliers) {
        this.commonService.onEditRouting(
          true,
          Modules.AccountDetails,
          id,
          true,
          moduleId
        );
      } else if (
        moduleId === Modules.Invoices ||
        moduleId === Modules.Purchase ||
        moduleId === Modules.CreditNote ||
        moduleId === Modules.DebitNote ||
        moduleId === Modules.FixedAssets ||
        moduleId === Modules.Quotation ||
        moduleId === Modules.Journals ||
        moduleId === Modules.Receipt ||
        moduleId === Modules.Payment ||
        moduleId === Modules.FixedAssetDetail
      ) {
        const data = {
          moduleId,
          id,
        };

        let panelClass: string;

        if (moduleId === Modules.FixedAssetDetail) {
          panelClass = 'fixed-assest-detail';
        } else {
          panelClass = 'view-receipt';
        }

        this.dialog
          .open(ViewReceiptComponent, {
            data,
            disableClose: true,
            panelClass: panelClass,
          })
          .afterClosed()
          .subscribe((result) => {});
      } else {
        this.commonService.onEditRouting(true, moduleId, id);
      }
    }
  }

  onTOCButtonClick(element: any, actionType: ActionType): void {
    let id = element[0].rowData;

    switch (actionType) {
      case ActionType.Explain:
        this.dialog
          .open(EditAssetDetailComponent, {
            data: {
              id: btoa(id),
              moduleId: this.moduleId,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.getList(false);
          });
        break;

      case ActionType.Dispose:
      case ActionType.Sell:
        this.dialog
          .open(EditDisposeAssetComponent, {
            data: {
              id: btoa(id),
              actionType: actionType,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.getList(false);
          });
        break;

      case ActionType.RollBack:
        this.dialog
          .open(RollBackPopupComponent, {
            panelClass: 'roll-back-popup',
            data: {
              id: btoa(element[6].rowData),
              actionType: actionType,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.getList(false);
          });
        break;
    }
  }

  onSearch(input: any): void {
    const searchText: string = input.target.value;
    this.listParameters.search = searchText;
    this.getList(false);
  }

  exportReport(format: number, isPrint?: boolean): void {
    this.selectedIds();

    let totalRecordOfRecords = 0;

    this.totalRecord$.subscribe((x) => {
      totalRecordOfRecords = x;
    });

    let actionName;
    let param;

    actionName = Export;
    param = this.moduleId;

    if (actionName !== undefined && totalRecordOfRecords > 0) {
      this.spinner.show();
      const queryParams = {
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: this.listParameters.filter,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        search: this.listParameters.search,
        moduleId: this.listParameters.moduleId,
        subModuleId: this.listParameters.subModuleId ?? -1,
        ids: this.ids,
        branchIds: this.listParameters.branchIds ?? [],
        departmentIds: this.listParameters.departmentIds ?? [],
        startDate:
          this.datepipe
            .transform(this.listParameters.startDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        endDate:
          this.datepipe
            .transform(this.listParameters.endDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        format: format,
        isPrint: isPrint,
      };

      this.store.dispatch(new actionName(queryParams, param)).subscribe();
    } else {
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noDataToExport
      );
    }
  }

  dateChanged(date: any): void {
    this.listParameters.startDate = date.startDate;
    this.listParameters.endDate = date.endDate;
    this.getList(false);
  }
}
