<div class="mat-dialog-wrapper" style="width: 600px">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">
      {{
        (moduleId$ | async) === moduleId.Receipt
          ? "Edit Receipt"
          : "Edit Payment"
      }}
    </h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onNoClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="content-body">
      <form [formGroup]="receiptForm">
        <div class="d-flex gap-40">
          <div class="form-field datepicker">
            <p>
              {{
                (moduleId$ | async) === moduleId.Receipt
                  ? "Receipt Date"
                  : "Payment Date"
              }}
              <span class="text-danger-300">*</span>
            </p>
            <mat-form-field appearance="standard">
              <input
                matInput
                formControlName="receiptDate"
                [matDatepicker]="receiptDatePicker"
                autocomplete="off"
                (change)="commonService.validateDate($event)"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="receiptDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #receiptDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-error
              *ngIf="
                commonService.checkDateValidation(
                  receiptForm.get('receiptDate')
                ).invalidFinancialYear
              "
            >
              {{ notificationMessage.validPeriodDateMessage }}
            </mat-error>

            <mat-error
              *ngIf="
                commonService.checkDateValidation(
                  receiptForm.get('receiptDate')
                ).lockedFinancialYear
              "
            >
              {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
            </mat-error>
          </div>
        </div>
        <div class="d-flex gap-40">
          <div class="form-field">
            <p>
              {{
                (moduleId$ | async) === moduleId.Receipt
                  ? moduleName.Receipt
                  : moduleName.Payment
              }}
              No
            </p>
            <mat-form-field>
              <input
                matInput
                formControlName="code"
                maxlength="{{ maxLength.maxLength50 }}"
                autocomplete="off"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex gap-40">
          <div class="form-field">
            <p>Amount <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <button mat-button type="button" disableRipple matSuffix>
                <span class="symbol-align">{{ selectedCurrencySymbol }}</span>
              </button>
              <input
                allow_14_2_Decimal
                matInput
                zeroNotAllowed
                formControlName="amountReceived"
                autocomplete="off"
                allowDecimal
              />
            </mat-form-field>
          </div>
        </div>
        <div class="form-field textarea">
          <p>Notes</p>
          <mat-form-field>
            <textarea
              matInput
              maxlength="{{ maxLength.maxLength2000 }}"
              formControlName="note"
              autocomplete="off"
            ></textarea>
          </mat-form-field>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSaveClick()"
    >
      Save
    </button>
    <button
      class="action-button secondary-button"
      mat-dialog-close
      type="button"
      mat-button
      disableRipple
      (click)="onNoClick()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
