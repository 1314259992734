<div class="content-body">
  <form [formGroup]="cisInformationForm">
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Business Type <span class="text-danger-300">*</span></p>
        <ng-select
          #businessType
          (focus)="businessType.open()"
          (valueChange)="businessType.close()"
          [disableOptionCentering]="true"
          formControlName="businessTypeId"
          panelClass="mat-select-position"
          (change)="onBusinessTypeChange($event)"
        >
          <ng-option *ngFor="let cl of businessTypeList" [value]="cl.id">
            <div title="{{ cl.name }}">{{ cl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>Trading Type <span class="text-danger-300">*</span></p>
        <ng-select
          #tradingType
          (focus)="tradingType.open()"
          (valueChange)="tradingType.close()"
          [disableOptionCentering]="true"
          formControlName="tradingType"
          panelClass="mat-select-position"
          (change)="onTradingNameChange($event)"
          required
        >
          <ng-option *ngFor="let cl of tradingNameList" [value]="cl.id">
            <div title="{{ cl.name }}">{{ cl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex mr-40" *ngIf="tradingNameShow">
      <div class="form-field">
        <p>Trading Name <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="tradingName"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40" *ngIf="!tradingNameShow">
      <div class="form-field">
        <p>First Name <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="firstName"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
            unicodeCharacter
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Second Name</p>
        <mat-form-field>
          <input
            matInput
            formControlName="secondName"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
            unicodeCharacter
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40" *ngIf="!tradingNameShow">
      <div class="form-field">
        <p>Surname <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="surname"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
            unicodeCharacter
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40" *ngIf="patnerShipShow">
      <div class="form-field">
        <p>Partnership UTR</p>
        <mat-form-field>
          <input
            matInput
            formControlName="partnershipUTR"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Partnership Name</p>
        <mat-form-field>
          <input
            matInput
            formControlName="partnershipName"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Subcontractor UTR</p>
        <mat-form-field>
          <input
            matInput
            formControlName="subContractorUTR"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>NI Number</p>
        <mat-form-field>
          <input
            matInput
            formControlName="niNumber"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Company Number</p>
        <mat-form-field>
          <input
            matInput
            formControlName="companyNumber"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
            allowDecimal
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Work Reference</p>
        <mat-form-field>
          <input
            matInput
            formControlName="workReference"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <mat-panel-title>
      <div class="d-flex flex-column">
        <p>Verification Status</p>
      </div>
    </mat-panel-title>
    <div class="d-flex gap-40 align-items-center">
      <div class="form-field">
        <p>Verify</p>
        <ng-select
          #verify
          (focus)="verify.open()"
          (valueChange)="verify.close()"
          [disableOptionCentering]="true"
          formControlName="verify"
          panelClass="mat-select-position"
          (change)="onVerifyChange($event, true)"
        >
          <ng-option *ngFor="let cl of verifyList" [value]="cl.id">
            <div title="{{ cl.name }}">{{ cl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field m-0" *ngIf="toggleVerifyStatus">
        <button
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
          (click)="verifyClick(cisSubcontractorAction.Verify)"
        >
          Verify
        </button>
        <button
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
          (click)="verifyClick(cisSubcontractorAction.Match)"
        >
          Tax Treatment
        </button>
      </div>
    </div>
    <div class="d-flex gap-40" *ngIf="toggleVerifyStatus">
      <div class="form-field">
        <p>Verification Number <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="verificationNumber"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40" *ngIf="toggleVerifyStatus">
      <div class="form-field datepicker">
        <p>Last Verified</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="lastVerified"
            [matDatepicker]="lastVerifiedDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            tabindex="-1"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="lastVerifiedDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #lastVerifiedDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              cisInformationForm.get('lastVerified')
            ).invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              cisInformationForm.get('lastVerified')
            ).lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
      <div class="form-field">
        <p>Tax Treatment</p>
        <ng-select
          #taxTreatment
          (focus)="taxTreatment.open()"
          (valueChange)="taxTreatment.close()"
          [disableOptionCentering]="true"
          formControlName="taxTreatment"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let cl of taxTreatmentList" [value]="cl.id">
            <div title="{{ cl.name }}">{{ cl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div *ngIf="verificationStatus !== 0">
      <div class="d-flex gap-40">
        <div class="form-field">
          <p>User Name <span class="text-danger-300">*</span></p>
          <mat-form-field>
            <input
              matInput
              formControlName="userName"
              autocomplete="off"
              maxlength="{{ maxLength.maxLength25 }}"
              type="text"
              required
            />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Password <span class="text-danger-300">*</span></p>
          <mat-form-field>
            <input
              matInput
              formControlName="password"
              autocomplete="off"
              maxlength="{{ maxLength.maxLength25 }}"
              type="password"
              required
            />
          </mat-form-field>
        </div>
      </div>
      <div class="form-field m-0">
        <button
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
          (click)="onSubmit()"
        >
          Submit
        </button>
        <button
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
          (click)="cancleClick()"
        >
          Cancel
        </button>
      </div>
    </div>
    <div class="d-flex mr-40">
      <div class="form-field" *ngIf="toggleVerifyStatus && toggleStatus">
        <p>Status</p>
        <mat-form-field>
          <input
            matInput
            formControlName="status"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex mr-40">
      <div class="form-field">
        <p>Payment Frequency</p>
        <mat-form-field>
          <input
            matInput
            formControlName="paymentFrequency"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
