import { Injectable } from '@angular/core';
import { SideListModel } from '@app/core/Models';
import { UserService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreUser,
  DeleteUser,
  DeleteUserCompanyByUserId,
  ExportUser,
  GetAllUsers,
  GetUserClientsDetails,
  GetUserDataByUserId,
  GetUserDetailList,
  GetUserPermissionsList,
  GetUsersByPermission,
  InviteUser,
  SaveUser,
  SaveUserCompanies,
  SetUserDefaultState,
  SetUserId,
} from './user.action';

export class UserStateInfo {
  userList?: Array<any>;
  userId?: Guid;
  userData?: any;
  allUsers?: Array<any>;
  userResponse?: any;
  exported?: boolean;
  sideListModel?: Array<SideListModel>;
  isLastPage?: boolean;
  totalRecord?: number;
  userPermissionList?: Array<any>;
  highlightIDs?: Array<string>;
  importData?: any;
  isDataAvailable?: boolean;
  usersByPermissionList?: Array<SideListModel>;
  isSuccess?: boolean;
  isDelete?: boolean;
  userClientsData?: Array<any>;
  userClientsDataLength?: number;
  isUserAssignedToClient?: boolean;
  gridActions?: any[];
  totalRecordOfUserClient?: number;
}

@State<UserStateInfo>({
  name: 'user',
  defaults: {
    userList: [],
    isDataAvailable: true,
    allUsers: [],
    userClientsDataLength: 0,
  },
})
@Injectable()
export class UserState {
  constructor(private userService: UserService) {}

  @Selector()
  static getUserId(state: UserStateInfo) {
    return state.userId;
  }

  @Selector()
  static getUserResponse(state: UserStateInfo) {
    return state.userResponse;
  }

  @Selector()
  static getAllUsers(state: UserStateInfo) {
    return state.allUsers;
  }

  @Selector()
  static getUserList(state: UserStateInfo) {
    return state.sideListModel;
  }

  @Selector()
  static isLastPage(state: UserStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static totalRecord(state: UserStateInfo) {
    return state.totalRecord ?? 0;
  }

  @Selector()
  static reportActions(state: UserStateInfo): any {
    if (!state.userList) {
      return [];
    }

    return state.gridActions;
  }

  @Selector()
  static totalRecordOfUserClient(state: UserStateInfo) {
    return state.totalRecordOfUserClient;
  }

  @Selector()
  static getUserPermissions(state: UserStateInfo) {
    return state.userPermissionList;
  }

  @Selector()
  static getHighlightedIds(state: UserStateInfo) {
    return state.highlightIDs ?? [];
  }

  @Selector()
  static getImportData(state: UserStateInfo) {
    return state.importData;
  }

  @Selector()
  static isDataAvailable(state: UserStateInfo) {
    return state.isDataAvailable;
  }

  @Selector()
  static userData(state: UserStateInfo): any {
    return state.userData!;
  }

  @Selector()
  static getUserClientsDetails(state: UserStateInfo) {
    return state.userClientsData ?? [];
  }

  @Selector()
  static getUserClientsDataLength(state: UserStateInfo) {
    return state.userClientsDataLength;
  }

  @Selector()
  static getGridActions(state: UserStateInfo) {
    return state.gridActions;
  }

  @Action(GetAllUsers)
  getAllUsers(
    { patchState }: StateContext<UserStateInfo>,
    action: GetAllUsers
  ) {
    return this.userService.getAllUsers().pipe(
      tap((res) => {
        patchState({
          allUsers: res,
        });
      })
    );
  }

  @Action(GetUserDataByUserId)
  getUserDataByUserId(
    { getState, patchState }: StateContext<UserStateInfo>,
    action: GetUserDataByUserId
  ) {
    return this.userService.getUserDataByUserId(action.userId).pipe(
      tap((res) => {
        patchState({
          userId: action.userId,
          userData: res,
        });
      })
    );
  }

  @Action(DeleteUser)
  deleteUser(
    { getState, setState }: StateContext<UserStateInfo>,
    action: DeleteUser
  ) {
    return this.userService.deleteUsers(action.userIds).pipe(
      tap(() => {
        const state = getState();

        const filteredUser = state.userList?.filter(
          (item) =>
            !action.userIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.userIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        setState({
          ...state.userList,
          userList: filteredUser,
          sideListModel: filteredForSideList,
        });
      })
    );
  }
  @Action(ExportUser)
  exportUser(
    { getState, patchState }: StateContext<UserStateInfo>,
    action: ExportUser
  ) {
    return this.userService.exportUser(action.exportParams).pipe(
      switchMap((res) => {
        patchState({ exported: true });
        return EMPTY;
      })
    );
  }
  @Action(GetUserDetailList, { cancelUncompleted: true })
  getUserDetailList(
    { getState, setState }: StateContext<UserStateInfo>,
    action: GetUserDetailList
  ) {
    return this.userService.getUserDetailList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();
        const totalRecord = res.body.totalItemCount;
        setState({
          ...state,
          userList: res.body.data,
          totalRecord: totalRecord,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreUser)
  archiveRestoreUser(
    { getState, setState }: StateContext<UserStateInfo>,
    action: ArchiveAndRestoreUser
  ) {
    return this.userService
      .archiveAndRestoreUser(action.userIds, action.isArchive)
      .pipe(
        tap((res) => {
          const state = getState();

          setState({
            ...state.userList,
            userList: state.userList,
          });
        })
      );
  }

  @Action(SetUserId)
  setUserId(
    { getState, setState }: StateContext<UserStateInfo>,
    action: SetUserId
  ) {
    const state = getState();

    setState({
      userId: action.userId,
      userList: state.userList,
    });
  }

  @Action(GetUserPermissionsList)
  getUserPermissionsList({ patchState }: StateContext<UserStateInfo>) {
    return this.userService.getUserPermissions().pipe(
      tap((res) => {
        patchState({
          userPermissionList: res,
        });
      })
    );
  }

  @Action(SetUserDefaultState)
  setUserDefaultState({ patchState }: StateContext<UserStateInfo>) {
    patchState({
      highlightIDs: [],
    });
  }

  @Action(InviteUser)
  inviteUser({ patchState }: StateContext<UserStateInfo>, action: InviteUser) {
    return this.userService.inviteUser(action.id).pipe(
      tap((res) => {
        patchState({
          userResponse: res,
        });
      })
    );
  }

  @Action(SaveUser)
  saveUser({ patchState }: StateContext<UserStateInfo>, action: SaveUser) {
    return this.userService.saveUser(action.user).pipe(
      tap((res) => {
        patchState({
          isSuccess: res.isSuccess,
          userId: res.data,
          userResponse: res,
        });
      })
    );
  }

  @Action(GetUsersByPermission)
  getUsersByPermission({ patchState }: StateContext<UserStateInfo>) {
    return this.userService.getUsersByPermission().pipe(
      tap((res) => {
        patchState({
          usersByPermissionList: res,
        });
      })
    );
  }

  @Action(GetUserClientsDetails)
  getUserClientsDetails(
    { patchState }: StateContext<UserStateInfo>,
    action: GetUserClientsDetails
  ) {
    return this.userService.getUserCompanyDetails(action.queryParams).pipe(
      tap((res) => {
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const totalRecordOfUserClient = headers.TotalItemCount;
        patchState({
          totalRecordOfUserClient: totalRecordOfUserClient,
          userClientsData: res.body,
          userClientsDataLength: res.body.length,
        });
      })
    );
  }

  @Action(SaveUserCompanies)
  SaveUserCompanies(
    { patchState }: StateContext<UserStateInfo>,
    action: SaveUserCompanies
  ) {
    return this.userService
      .SaveUserCompanies(action.userId, action.userCompanies)
      .pipe(
        tap((res) => {
          patchState({
            isUserAssignedToClient: res,
          });
        })
      );
  }

  @Action(DeleteUserCompanyByUserId)
  DeleteUserCompanyByUserId(
    { patchState }: StateContext<UserStateInfo>,
    action: DeleteUserCompanyByUserId
  ) {
    return this.userService.DeleteUserCompanyByUserId(action.userId).pipe(
      tap((res) => {
        patchState({
          isDelete: res,
        });
      })
    );
  }
}
