import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '@app/core/Services';
import { GetAllGroupTypeList, GetBudgetAccountList } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-include-exclude-budgeting-popup',
  templateUrl: './include-exclude-budgeting-popup.component.html',
  styleUrls: ['./include-exclude-budgeting-popup.component.scss'],
})
export class IncludeExcludeBudgetingPopupComponent implements OnInit {
  allGroupTypeList: any;
  tempAllGroupTypeList: any;

  includeExcludeForm: FormGroup;

  budgetAccountList: any[] = [];
  selectedIds: any[] = [];

  isAllSelected = false;

  constructor(
    private includeExcludePopup: MatDialogRef<IncludeExcludeBudgetingPopupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private store: Store,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.getAllGroupType();
  }

  getAllGroupType(): void {
    this.spinner.show();
    this.store.dispatch(new GetAllGroupTypeList()).subscribe((res) => {
      this.spinner.hide();
      this.allGroupTypeList = res.budgeting.allGroupType;
      this.tempAllGroupTypeList = this.allGroupTypeList;
      this.includeExcludeForm.controls.accountType.setValue(
        this.allGroupTypeList[0].listModels[0]
      );
      this.getBudgetAccountList();
    });
  }

  scrollIntoView() {
    this.commonService.autoScrollMatAutoComplete(this.renderer);
  }

  getBudgetAccountList() {
    const param = {
      groupId: this.includeExcludeForm.controls.accountType.value.groupId,
      typeId: this.includeExcludeForm.controls.accountType.value.id,
      budgetId: this.data.budgetingId,
    };
    this.spinner.show();
    this.store.dispatch(new GetBudgetAccountList(param)).subscribe((res) => {
      this.spinner.hide();
      this.budgetAccountList = res.budgeting.budgetAccountList;
      this.selectAccount();
    });
  }

  getOptionText(option) {
    return option.name;
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAllGroupTypeList
    );
    this.allGroupTypeList = list;
  }

  setForm(): void {
    this.includeExcludeForm = new FormGroup({
      accountType: new FormControl(''),
    });
  }

  selectAccount(): void {
    this.selectedIds = [];
    this.budgetAccountList.forEach((element) => {
      if (element.isSelected) {
        this.selectedIds.push(element.id);
      }
    });
    this.isAllSelected = false;
    if (this.selectedIds.length === this.budgetAccountList.length) {
      this.isAllSelected = true;
    }
  }

  selectAllAccount(event): void {
    this.budgetAccountList.forEach((x) => (x.isSelected = event.checked));
    this.selectAccount();
  }

  onCancel(): void {
    this.includeExcludePopup.close();
  }

  onSave(): void {
    const param = {
      budgetingId: this.data.budgetingId,
      selectedIds: this.selectedIds,
      groupId: this.includeExcludeForm.controls.accountType.value.groupId,
      typeId: this.includeExcludeForm.controls.accountType.value.id,
    };
    this.includeExcludePopup.close(param);
  }
}
