import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  LinkQuickEntryModel,
  MatchQuickEntryModel,
} from '@app/core/Enum/quick-entry';
import { QuickEntryModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class QuickEntryService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getQuickEntryNumber(): Observable<any> {
    return this.http.get(
      `${environment.apiVersionUrl}QuickEntry/getQuickEntryNo`,
      {
        responseType: 'text',
      }
    );
  }
  getCustomerSupplierList(quickEntryType: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}QuickEntry/getCustomerSupplierList/${quickEntryType}`
    );
  }
  createQuickEntry(quickEntry: QuickEntryModel): Observable<Guid> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Guid>(
      `${environment.apiVersionUrl}QuickEntry/save`,
      JSON.stringify(quickEntry),
      headers
    );
  }
  getDataByQuickEntryId(quickEntryId: Guid): Observable<QuickEntryModel> {
    return this.http.get<QuickEntryModel>(
      `${environment.apiVersionUrl}QuickEntry/get/${quickEntryId}`
    );
  }
  deleteQuickEntry(quickEntryIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(quickEntryIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}QuickEntry/delete`,
      options
    );
  }
  archiveAndRestoreQuickEntry(
    quickEntryIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}QuickEntry/archive/${isArchive}`,
      JSON.stringify(quickEntryIds),
      options
    );
  }

  copyQuickEntry(quickEntryIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}QuickEntry/copyQuickEntry`,
      JSON.stringify(quickEntryIds),
      headers
    );
  }
  getMatchingQuickEntry(transactionId: Guid): Observable<MatchQuickEntryModel> {
    return this.http.get<MatchQuickEntryModel>(
      `${environment.apiVersionUrl}QuickEntry/getMatchingQuickEntry/${transactionId}`
    );
  }
  linkQuickEntry(linkQuickEntry: LinkQuickEntryModel): Observable<Guid> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Guid>(
      `${environment.apiVersionUrl}QuickEntry/linkQuickEntry`,
      this.commonService.trimObjectSpace(JSON.stringify(linkQuickEntry)),
      headers
    );
  }
}
