<div class="mat-dialog-wrapper quick-add">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">{{ headerText }}</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div
      [ngClass]="[
        showPaginator
          ? 'mat-table-wrapper-pagination'
          : 'mat-table-wrapper-pagination-full',
        (totalRecord$ | async)! <= 10 ? 'mat-table-wrapper-full-view' : ''
      ]"
    >
      <table aria-describedby="main-list" mat-table [dataSource]="dataSource">
        <ng-container
          *ngFor="let col of columns$ | async; let colIndex = index"
          matColumnDef="{{ col.name }}"
        >
          <th mat-header-cell *matHeaderCellDef [id]="col.name" fxFlex="50">
            <div>
              <span>
                {{ col.name }}
              </span>
            </div>
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="clamp">
              <span
                *ngIf="col.dataType === dataType.DateTime"
                title="{{ element[colIndex].rowData }}"
                [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
              >
                {{ element[colIndex].rowData | date: "dd-LLL-yyyy" }}</span
              >
              <span
                *ngIf="
                  col.dataType === dataType.String ||
                  col.dataType === dataType.Bool
                "
                title="{{ element[colIndex].rowData }}"
                [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
              >
                {{ element[colIndex].rowData }}
              </span>
              <span
                *ngIf="
                  (col.dataType === dataType.Int32 ||
                    col.dataType === dataType.Currency) &&
                  element[colIndex].rowData !== ''
                "
                title="{{ element[colIndex].rowData }}"
                [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
              >
                £ {{ element[colIndex].rowData | numberPipe }}</span
              >
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="columnsNames$ | async; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnsNames$ | async"></tr>
      </table>

      <div
        *ngIf="dataSource.data.length === 0 && noDataFound"
        class="text-align-center mt-1 fw-bold"
      >
        No records found
      </div>
    </div>
    <div class="paginator-wrapper" *ngIf="(totalRecord$ | async)! > 0">
      <app-custom-paginator
        *ngIf="(totalRecord$ | async)! > 10"
        [length]="(totalRecord$ | async)!"
        [listParameters]="listParameters"
        (pageChanged)="pageChanged($event)"
        (pageSizeVal)="pageSizeVal($event)"
        (togglePaginator)="togglePaginator($event)"
        [triggerPaginationChange]="triggerPaginationChange"
      >
      </app-custom-paginator>
    </div>
  </mat-dialog-content>
</div>

<app-custom-notifications></app-custom-notifications>
