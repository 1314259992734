export enum ComparitiveReportType {
  TrialBalance = 0,
  ProfitAndLoss = 1,
  BalanceSheet = 2,
}

export enum DateFilterType {
  Monthly = 1,
  Quarterly = 2,
  Yearly = 3,
  Custom = 4,
}
