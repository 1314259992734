<div class="mat-dialog-wrapper quick-add">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">Add Custom Report</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <div>
      <form [formGroup]="customReportForm">
        <div>
          <div class="form-field">
            <p>Name <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                autocomplete="off"
                formControlName="customReportName"
                appAutoFocusDirective
                required
              />
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="m-0">
    <app-buttons
      (triggerOnSaveClick)="onSave()"
      (triggerOnCancelClick)="onCancel()"
      [saveButtonShow]="true"
    >
    </app-buttons>
  </mat-dialog-actions>
</div>
