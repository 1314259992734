import { Guid } from 'guid-typescript';

export class CompanyModel {
  logo: string;
  id?: Guid;
  name: string;
  companyTypeId: number;
  registrationNo: string;
  companyNo: string;
  establishmentDate?: string;
  keepBillingDetailSeparate: boolean;
  billingDetail?: CompanyAddressDetailModel;
  addressDetail: CompanyAddressDetailModel;
  contactDetail: CompanyContactDetailModel;
  regionalDetail: CompanyRegionalDetailModel;
  accountingPeriods: Array<AccountingPeriodModel>;
  departments: Array<DepartmentModel>;
  branchs?: Array<BranchModel>;
  vatDetail?: CompanyVatDetailModel;
  accountingMethodId: number;
  tradingStatus: boolean;
  isVatRegistered: boolean;
  taxRefNo: string;
  accountOfficeRefNo: string;
  payeRefNo: string;
  companyRefNo: string;
  isBranchDepartmentAccounting?: boolean;
}

export class BranchModel {
  id: Guid;
  name: string;
  isDefault: boolean;
  srNo: number;
}

export class DepartmentModel {
  id: Guid;
  name: string;
  isDefault: boolean;
  brancheSrNos: Array<number>;
}

export class CompanyAddressDetailModel {
  universalId?: Guid;
  address1: string;
  address2: string;
  address3?: string;
  city: string;
  state?: string;
  country: string;
  postalCode: string;
  county?: string;
}

export class CompanyContactDetailModel {
  universalId?: Guid;
  name: string;
  phone1: string;
  phone2: string;
  email: string;
  alternateEmail: string;
  website: string;
}

export class CompanyRegionalDetailModel {
  currencyId: number;
  languageId?: number;
  timezoneId?: number;
}

export class CompanyVatDetailModel {
  id?: number;
  vatRegistrationNo: string;
  vatRegistrationDate?: Date | any;
  vatSchemeId: number;
  vatReturnTypeId: number;
}

export class Company {
  id: Guid;
  companyName: string;
  isDefault: boolean;
  companyType: string;
  financialPeriod: string;
  vATNumber: string;
  isSelected?: boolean;
  IsArchived?: boolean;
}

export class AccountingPeriodModel {
  id?: Guid;
  fromDate?: string;
  toDate?: string;
  isLocked?: boolean;
}
export class CompanyListModel {
  name: string;
  companyNumber?: string;
  address: string;
  city: string;
  county: string;
  country: string;
  countryId: number;
  postalCode: string;
}
export class AddressModel {
  universalId: Guid;
  clientId: Guid;
  addressType: string;
  address: string;
  city: string;
  county: string;
  countryId: number;
  postalCode: string;
}
export class Client {
  logo: string;
  id?: Guid;
  name: string;
  companyTypeId: number;
  registrationNo: string;
  companyNo: string;
  establishmentDate?: string;
  keepBillingDetailSeparate: boolean;
  isVatRegistered: boolean;
  billingDetail: any;
  //addresses: Array<AddressModel>;
  //contactDetail: Array<Contacts>;
  addressDetail: CompanyAddressDetailModel;
  contactDetail: CompanyContactDetailModel;
  regionalDetail: any;
  accountingPeriods: Array<AccountingPeriodModel>;
  departments: Array<DepartmentModel>;
  branchs?: Array<BranchModel>;
  vatDetail?: CompanyVatDetailModel;
  accountingMethodId: number;
  tradingStatus: boolean;
  taxRefNo: string;
  accountOfficeRefNo: string;
  companyRefNo: string;
  isBranchDepartmentAccounting?: boolean;
  addToAllFutureUsers?: boolean;
  addToAllExistingUsers?: boolean;

  businessStartDate?: string;
  bookStartDate?: string;
  yearEndMonth?: number;
  yearEndDate?: number;
  utrNumber?: string;
  vatSchemeId?: number | null;
  vatRegNumber?: string;
  vatRegistrationDate?: string;
  vatSubmitTypeId?: number | null;
  payeRefNo?: string;
  assignAsDirector?: string;
  isShowVat?: boolean;
  nationalInsuranceNo?: string;
  dateOfBirth?: string;
}
export class Contacts {
  universalId: string;
  name: string;
  email: string;
  phone: string;
  title: string;
  countryId?: number;
  isPrimary: boolean;
}
