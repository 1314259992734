import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  ChartOfAccountCreditorsGroupTypeName,
  GroupNames,
  MaxLength,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import { AccountNumber } from '@app/core/Enum/account-number';
import {
  ChartOfAccountListParam,
  FileUploadResponseModel,
  GlobalComponent,
  SideListModel,
} from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import {
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetGroupCustomBankAndCashAccount,
  GetGroupCustomBankAndCashAccountByBranch,
} from '@app/core/Store';
import { AttachmentPopupComponent, CleanAllLinesComponent } from '@app/modules';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-transactions-payment',
  templateUrl: './transactions-payment.component.html',
  styleUrls: ['./transactions-payment.component.scss'],
})
export class TransactionsPaymentComponent implements OnInit {
  @Input()
  headerText: string;

  @Input() totalCreditAmountReceived: number;

  @Input()
  totalReceiptAmount: number;

  @Input()
  moduleId: number;

  @Input()
  currencyRate: number;
  @Input()
  selectedCurrencyId: number;

  @Input() triggerNewProductAdded: Observable<any>;

  @Input() branchList: any[] = [];
  @Input() branchId = Guid.EMPTY as unknown as Guid;

  private destroy$ = new Subject<void>();

  paymentDetailForm: FormGroup;
  paymentArray: any=[];
  periodicDate: any;
  transactionTypeList: SideListModel[];
  accountList: any[] = [];
  receiptData: any[];
  paymentId = Guid.EMPTY as unknown as Guid;
  paymentAccountList: any[] = [];
  tempPaymentAccountList: any[] = [];
  maxLength = MaxLength;
  paymentForm: FormGroup;
  tableDataSource: MatTableDataSource<AbstractControl>;
  fileData: Array<FileUploadResponseModel>;

  displayPaymentsColumns: string[] = [
    'srNo',
    'receiptdate',
    'account',
    'amount',
    'closeButton',
  ];

  totalReciptAmount = 0;
  isAddMode = true;
  totalProductAmount = 1;
  selectedAccountCurrencyId: number;
  notificationMessage = NotificationTextMessage;
  @Output() totalPaymentReciptAmount = new EventEmitter<any>();
  @Input() triggereEditPaymentData: Observable<any>;
  isReceiptChangePermission: boolean = true;
  commonNotificationText = NotificationTextMessage;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setPaymentForm(false);
    this.triggereEditPaymentData
      .pipe(
        switchMap((data) => {
          return this.getAccountList(this.paymentArray.length).pipe(
            map(() => data)
          );
        })
      )
      .subscribe((data) => {
        this.editPayment(data);
      });
    this.paymentForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.paymentForm.touched;
    });
    this.triggerNewProductAdded?.subscribe((data) => {
      if (data && this.totalReceiptAmount != 0 && this.paymentArray?.controls?.length) {
        this.totalProductAmount = data;
        this.paymentArray.controls[0]
          .get('amount')
          ?.setValue(this.totalReceiptAmount / this.currencyRate)
          .toFixed(2);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (
        this.moduleId === Modules.Invoices ||
        this.moduleId === Modules.CreditNote
      ) {
        this.isReceiptChangePermission = this.commonService.checkPermission(
          Modules.Sales,
          Modules.Receipt
        );
      }

      if (
        this.moduleId === Modules.Purchase ||
        this.moduleId === Modules.DebitNote ||
        this.moduleId === Modules.FixedAssets
      ) {
        this.isReceiptChangePermission = this.commonService.checkPermission(
          Modules.Expenditure,
          Modules.Payment
        );
      }
    }, 3000);
  }

  accountTypeChange(isAddMode: boolean): void {
    this.getAccountList(this.paymentArray.length - 1).subscribe();

    if (isAddMode) {
      this.paymentArray.controls[this.paymentArray.length - 1]
        .get('id')
        ?.setValue(Guid.EMPTY as unknown as Guid);
    }
  }

  scrollIntoView() {
    this.commonService.autoScrollMatAutoComplete(this.renderer);
  }

  getAccountList(i: number): Observable<any> {
    let actionName;
    if (this.branchList.length > 0) {
      actionName = GetGroupCustomBankAndCashAccountByBranch;
    } else {
      actionName = GetGroupCustomBankAndCashAccount;
    }

    return this.store.dispatch(new actionName(this.branchId)).pipe(
      switchMap((res) => {
        if (
          this.paymentAccountList.length > 0 &&
          this.paymentAccountList[i] !== null &&
          this.paymentAccountList[i] !== undefined
        ) {
          this.paymentAccountList[i] =
            this.commonService.addGroupNameToListModels(
              res.common.customBankAndCashAccountGroupList
            );
          this.tempPaymentAccountList[i] =
            this.commonService.addGroupNameToListModels(
              res.common.customBankAndCashAccountGroupList
            );
        } else {
          this.paymentAccountList.push(
            this.commonService.addGroupNameToListModels(
              res.common.customBankAndCashAccountGroupList
            )
          );
          this.tempPaymentAccountList.push(
            this.commonService.addGroupNameToListModels(
              res.common.customBankAndCashAccountGroupList
            )
          );
        }
        if (this.selectedCurrencyId === 123) {
          this.paymentAccountList[i] = this.paymentAccountList[i].filter(
            (item) => item.currencyId === this.selectedCurrencyId
          );
        } else {
          this.paymentAccountList[i] = this.paymentAccountList[i].filter(
            (item) =>
              item.currencyId === 123 ||
              item.currencyId === this.selectedCurrencyId
          );
        }

        const param: ChartOfAccountListParam = {
          groupIds: [GroupNames.Creditors],
          typeIds: [
            ChartOfAccountCreditorsGroupTypeName.CreditorsLessThanOneYearTaxationAndSocialSecurity,
          ],
        };

        return this.store.dispatch(
          new GetGroupAccountsBasedOnGroupIdAndTypeId(param)
        );
      }),
      switchMap((secondRes) => {
        if (secondRes.common.accountGroupList.length > 0) {
          let accounts = this.commonService.addGroupNameToListModels(
            secondRes.common.accountGroupList
          );

          accounts = accounts.filter(
            (item) => item.name === AccountNumber.ACCOUNT_8214
          );
          this.paymentAccountList[i] =
            this.paymentAccountList[i].concat(accounts);
          this.tempPaymentAccountList[i] = this.paymentAccountList[i];
        }
        return of(secondRes);
      })
    );
  }

  getOptionText(option) {
    return option.name;
  }

  setPaymentForm(addNewRow: boolean): void {
    this.paymentForm = new FormGroup({
      paymentArray: new UntypedFormArray([]),
    });

    this.paymentArray = this.paymentForm.get(
      'paymentArray'
    ) as UntypedFormArray;

    this.setDataSource(this.paymentArray);
    if (addNewRow) this.createRow();
  }

  resetForm(): void {
    this.paymentForm.reset();
  }

  addNewRow(): void {
    if (this.totalReceiptAmount === 0) {
      this.commonService.onFailure(
        NotificationTextMessage.checkAmountErrorMessage
      );
      return;
    }
    if (
      this.moduleId !== Modules.CreditNote &&
      this.moduleId !== Modules.DebitNote
    ) {
      if (this.totalReceiptAmount <= this.totalReciptAmount) {
        this.commonService.onFailure(
          NotificationTextMessage.amountErrorMessage
        );
        return;
      }
    } else {
      if (
        this.totalReceiptAmount <=
        this.totalReciptAmount + +this.totalCreditAmountReceived
      ) {
        this.commonService.onFailure(
          NotificationTextMessage.amountErrorMessage
        );
        return;
      }
    }

    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  editPayment(paymentItems: any): void {
    this.receiptData = [];
    this.paymentArray = this.paymentForm.get(
      'paymentArray'
    ) as UntypedFormArray;

    this.paymentArray.clear();

    paymentItems.forEach((element) => {
      this.receiptData?.push(element);
    });
    this.receiptData.forEach((item, i) => {
      this.paymentArray.push(this.buildPaymentItemsForm(item));
      this.paymentAccountList[i] = this.paymentAccountList[0];
      this.paymentArray.controls[i].get('receiptDate')?.clearValidators();

      this.paymentArray.controls[i]
        .get('receiptDate')
        ?.setValidators([datePickerValidator(this.periodicDate)]);
      this.paymentArray.controls[i].updateValueAndValidity();
    });

    this.setDataSource(this.paymentArray);
    this.amountChanges();
  }

  buildPaymentItemsForm(item: any): FormGroup {
    return this.formBuilder.group({
      id: item.id,
      receiptId: item.receiptId,
      accountId: item.postingAccountId,
      amount: item.amount,
      receiptDate: item.transactionDate,
      attachment:
        item.attachment !== null
          ? this.formBuilder.array(
              item.attachment.map((x) => this.formBuilder.control(x))
            )
          : [],
    });
  }

  createRow(): void {
    this.paymentArray = this.paymentForm.get(
      'paymentArray'
    ) as UntypedFormArray;

    this.paymentArray.push(this.setForm());
    this.setDataSource(this.paymentArray);
    if (this.currencyRate && this.paymentArray.length === 1) {
      this.paymentArray.controls[0]
        .get('amount')
        ?.setValue((this.totalReceiptAmount / this.currencyRate).toFixed(2));
    }
    if (this.paymentArray.length > 0) {
      this.accountTypeChange(true);
    }
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      receiptId: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      receiptDate: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      accountId: new FormControl(''),
      amount: new FormControl<string | null>(''),
      attachment: new FormControl(),
    });
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.paymentForm.get(
            'paymentArray'
          ) as UntypedFormArray;

          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
            formArray.controls[i].get('receiptDate')?.setValue(new Date());
          }
        }
      });
  }

  onDeletePayment(index: number): void {
    this.paymentArray = this.paymentForm.get(
      'paymentArray'
    ) as UntypedFormArray;

    this.paymentArray.removeAt(index);
    this.setDataSource(this.paymentArray);
    this.amountChanges();
  }

  checkValidation(event: any): boolean {
    const amount = String.fromCharCode(event.keyCode);
    return +amount < +this.totalReceiptAmount;
  }

  amountChanges(): void {
    this.totalReciptAmount = 0;

    this.paymentArray = this.paymentForm.get(
      'paymentArray'
    ) as UntypedFormArray;

    this.paymentArray?.getRawValue().forEach((x) => {
      if (this.selectedAccountCurrencyId === 123) {
        this.totalReciptAmount =
          (+this.totalReciptAmount + +x.amount) * this.currencyRate;
      } else {
        this.totalReciptAmount = +this.totalReciptAmount + +x.amount;
      }
    });
    if (
      this.moduleId === Modules.CreditNote ||
      this.moduleId === Modules.DebitNote
    ) {
      this.totalPaymentReciptAmount.emit(+this.totalReciptAmount);
    }
  }

  clickAttachment(i: number): void {
    this.dialog
      .open(AttachmentPopupComponent, {
        data: this.paymentArray.controls[i]['controls'].attachment.value,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.fileData = result;

          const attachment: any = [];
          result.forEach((element) => {
            attachment.push(element.fileUrl);
          });

          this.paymentArray.controls[i].get('attachment').reset();
          this.paymentArray.controls[i].controls.attachment =
            this.formBuilder.control(attachment);
        }
      });
  }
  onAccountChange(accountId: any, index: any) {
    this.selectedAccountCurrencyId = this.paymentAccountList[0].find(
      (x) => x.id === accountId
    )?.currencyId;
    if (this.selectedAccountCurrencyId !== 123) {
      this.paymentArray.controls[index]
        .get('amount')
        ?.setValue(this.totalReceiptAmount.toFixed(2));
    } else {
      this.paymentArray.controls[index]
        .get('amount')
        ?.setValue((this.totalReceiptAmount / this.currencyRate).toFixed(2));
    }
  }
}
