<div class="mat-dialog-wrapper depreciation-list">
  <h2 mat-dialog-title>Depreciation</h2>
  <mat-dialog-content>
    <div class="mat-table-wrapper">
      <table
        aria-describedby="Depreciation-List"
        mat-table
        [dataSource]="fixedAssetRegisterList"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="w-40">
            <mat-checkbox
              [(ngModel)]="isAllSelected"
              (change)="selectAll($event)"
              disableRipple
            ></mat-checkbox>
            Name
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              <mat-checkbox [(ngModel)]="element.isSelected"> </mat-checkbox>
              {{ element.name }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef class="w-30">Amount</th>
          <td mat-cell *matCellDef="let element" class="widthemail">
            {{ element.amount }}
          </td>
        </ng-container>

        <ng-container matColumnDef="depreciationAmount">
          <th mat-header-cell *matHeaderCellDef class="w-30">
            Depreciation Amount
          </th>
          <td mat-cell *matCellDef="let element" class="widthemail">
            <mat-form-field floatLabel="never">
              <input
                allowDecimal
                matInput
                [(ngModel)]="element.depriciationAmount"
                autocomplete="off"
              />
            </mat-form-field>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell"
            [ngClass]="'text-align-center'"
            [attr.colspan]="displayedColumns.length"
          >
            <span>No records found</span>
          </td>
        </tr>
      </table>
    </div>
    <app-buttons
      [getModuleId]="moduleId"
      (triggerOnSaveClick)="onSave()"
      (triggerOnCancelClick)="onCancel()"
    >
    </app-buttons>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="close-button"
      type="button"
      (click)="onCloseClick()"
      mat-button
      disableRipple
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
