import { Guid } from 'guid-typescript';

export class VATCodesModel {
  id: Guid;
  name: string;
  rate: number;
  codeType: number;
  isStandard?: boolean;
  abbreviation: string;
  isArchived?: boolean;
  isDeleted?: boolean;
}
