import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { DividendsModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateDividends,
  GetDataByDividendsId,
  GetDividendNumber,
  MenuState,
} from '@app/core/Store';
import { AddClosePopupComponent, AddDividendComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-dividends',
  templateUrl: './dividends.component.html',
  styleUrls: ['./dividends.component.scss'],
})
export class DividendsComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;

  moduleId = Modules.Dividends;
  moduleName = ModuleName.Dividends;

  dividendsId = Guid.EMPTY as unknown as Guid;
  dividendsData: DividendsModel;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddDividendComponent, { static: true })
  dividendDetails;

  subscriptionRouting: Subscription;

  triggerEditData: Subject<any> = new Subject<any>();
  store: Store;
  commonService: CommonService;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private _Activatedroute: ActivatedRoute,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private location: Location,
    private renderer: Renderer2,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.dividendsId = atob(params.get('id')!) as unknown as Guid;
          this.editDividends();
        } else {
          this.getDividendNumber();
        }
      }
    );
  }

  getDividendNumber(): void {
    this.store.dispatch(new GetDividendNumber()).subscribe((res) => {
      this.dividendDetails.dividendsForm.markAsUntouched();

      this.dividendDetails.dividendsForm.controls.refNumber.setValue(
        res.dividends.dividendNumber
      );
    });
  }

  editDividends(): void {
    this.store
      .dispatch(new GetDataByDividendsId(this.dividendsId))
      .subscribe((res) => {
        this.triggerEditData.next(res.dividends.dividendsData);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.dividendsId,
      isExit,
      Modules.Dividends,
      RoutingPath.AddDividends
    );
  }

  onSave(isExit: boolean): void {
    if (this.dividendDetails.dividendsForm.invalid) {
      this.commonService.addValidation(
        this.dividendDetails.dividendsForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateDividends(this.dividendsData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  reloadAutoNumberGenerator(): void {
    this.getDividendNumber();
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.dividendsData = {
        id: this.dividendsId,
        shareholderId:
          this.dividendDetails.dividendsForm.controls.shareHolder.value,
        entryNumber:
          this.dividendDetails.dividendsForm.controls.refNumber.value,
        shareClassTypeId:
          this.dividendDetails.dividendsForm.controls.shareClassType.value,
        yearEndDate:
          this.dividendDetails.dividendsForm.controls.yearEndDate.value,
        declarationDate:
          this.dividendDetails.dividendsForm.controls.declarationDate.value,
        paymentDate:
          this.dividendDetails.dividendsForm.controls.paymentDate.value,
        dividendPerShare:
          +this.dividendDetails.dividendsForm.controls.dividendPerShare.value,

        excludeTaxCredit:
          this.dividendDetails.dividendsForm.controls.isTaxCredit.value,
        taxCredit: +this.dividendDetails.dividendsForm.controls.taxCredit.value,
        grossDividend:
          +this.dividendDetails.dividendsForm.controls.grossDividend.value,
        isPostJournal:
          this.dividendDetails.dividendsForm.controls.isPostJournal.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.dividendsId)) {
      this.editDividends();
    } else {
      this.dividendsId = Guid.EMPTY as unknown as Guid;
      this.dividendDetails.ngOnInit();
      this.getDividendNumber();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
