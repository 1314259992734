import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationTextMessage } from '@app/core/Enum';
import { LinkJournalModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { GetMatchingJournal, LinkJournal } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-match-journals',
  templateUrl: './match-journals.component.html',
  styleUrls: ['./match-journals.component.scss'],
})
export class MatchJournalsComponent implements OnInit {
  displayedColumns: string[] = ['refNo', 'date', 'amount', 'action'];
  transactionId: any;
  matchingJournalData: any;
  labelText: string;
  spinner: NgxSpinnerService;
  noDataFound = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    public dialogRef: MatDialogRef<MatchJournalsComponent>,
    private injector: Injector,
    public commonService: CommonService
  ) {
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
  }

  ngOnInit(): void {
    this.transactionId = atob(this.data.transactionId) as unknown as Guid;
    this.getData();
  }

  getData(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetMatchingJournal(this.transactionId))
      .pipe(
        tap((res) => {
          this.spinner.hide();
          this.matchingJournalData = res.journals.matchJournal;
          this.noDataFound =
            this.matchingJournalData.items.length > 0 ? false : true;
          this.labelText = this.matchingJournalData.isCredit
            ? 'Payment'
            : 'Receipt';
        })
      )
      .subscribe();
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onLinkClick(element: any): void {
    const linkJournal: LinkJournalModel = {
      id: element.journalId,
      transactionId: this.matchingJournalData.id,
      isManualBank: this.matchingJournalData.isManualBank,
    };
    this.store
      .dispatch(new LinkJournal(linkJournal))
      .pipe()
      .subscribe(
        (res) => {
          if (res !== undefined) {
            this.commonService.onSuccess(
              NotificationTextMessage.successMessage
            );
            this.dialogRef.close();
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        },
        (err) => {
          this.commonService.onFailure(err.error.Message);
        }
      );
  }
}
