import {
  CompanyParameters,
  CompanyPreference,
  MainListParameters,
} from '@app/core/Models';
import { DuplicateExistModel } from '@app/core/Models/common/title';
import { Guid } from 'guid-typescript';

export class CreateCompany {
  static readonly type = '[COMPANY] Create Company';

  constructor(public company: any) {}
}

export class GetCompanyList {
  static readonly type = '[COMPANY] Get Company';
  constructor(public params: any) {}
}

export class GetDepartmentList {
  static readonly type = '[COMPANY] Get Department List';
  constructor(public params: any) {}
}

export class GetBranchList {
  static readonly type = '[COMPANY] Get Branch List';
  constructor(public params: any) {}
}

export class GetAvailableCompanyCount {
  static readonly type = '[COMPANY] Get Available Company Count';
}

export class GetVatReturnTypeList {
  static readonly type = '[COMPANY] Get Vat Return Type List';
}

export class SetAsDefaultCompany {
  static readonly type = '[COMPANY] Get Default Company';

  constructor(public companyId: Guid) {}
}

export class SetAsDefaultDepartment {
  static readonly type = '[COMPANY] Get Default Department';

  constructor(public departmentId: Guid) {}
}

export class SetAsDefaultBranch {
  static readonly type = '[COMPANY] Get Default Branch';

  constructor(public branchId: Guid) {}
}

export class GetDepartmentListByBranch {
  static readonly type = '[COMPANY] Get Department List By Branch';

  constructor(public branchId: Guid, public queryParams: MainListParameters) {}
}

export class GetDepartmentListByBranchIds {
  static readonly type = '[COMPANY] Get Department List By Branch Ids';

  constructor(public branchIds: Array<Guid>) {}
}

export class GetDataByCompanyId {
  static readonly type = '[COMPANY] Get Company Data By Company Id';

  constructor(public companyId: Guid) {}
}

export class GetCompanyType {
  static readonly type = '[COMPANY] Get Company Type';
}

export class GetTimeZone {
  static readonly type = '[COMPANY] Get TimeZone';
}

export class GetLanguage {
  static readonly type = '[COMPANY] Get language';
}

export class CreateCompanyPreference {
  static readonly type = '[COMPANY] Create Company Preference';

  constructor(public company: CompanyPreference) {}
}

export class GetCompanyPreferenceData {
  static readonly type = '[COMPANY] Get Company Data By Module Id';

  constructor(public moduleId: number) {}
}

export class GetAccountingMethodList {
  static readonly type = '[COMPANY] Get Accounting Method';
}

export class GetVatSchemeList {
  static readonly type = '[COMPANY] Get Vat Scheme List';
}

export class SetBranchListForCompany {
  static readonly type = '[COMPANY] Set Branch List For Company ';

  constructor(public branchList: any) {}
}

export class DeleteFinancialYear {
  static readonly type = '[COMPANY] Delete Financial Year';

  constructor(public ids: Array<Guid>) {}
}

export class LockFinancialYear {
  static readonly type = '[COMPANY] Lock Financial Year';

  constructor(public ids: Array<Guid>, public isLocked: boolean) {}
}

export class DateBetweenAccountingPeriod {
  static readonly type = '[COMPANY] Date Between Accounting Period';

  constructor(public date: string) {}
}

export class GetCompanyNo {
  static readonly type = '[COMPANY] Get Company No';
}

export class GetCompanyVatSchemaId {
  static readonly type = '[COMPANY] Get Company Vat Schema Id';
}

export class GetCompanyTypeId {
  static readonly type = '[COMPANY] Get Company Type Id';
}

export class GetAllDepartmentListByBranch {
  static readonly type = '[COMPANY] Get All Department List By Branch';
  constructor(public branchId: Guid) {}
}

export class GetAllBranchList {
  static readonly type = '[COMPANY] Get All Branch List';
}
export class GetManageCompanyList {
  static readonly type = '[COMPANY] Get Manage Company List';

  constructor(public queryParams: CompanyParameters) {}
}

export class DeleteClient {
  static readonly type = '[Company] Delete Selected Client';

  constructor(public clientIds: Array<Guid>) {}
}

export class SetDefaultCompanyId {
  static readonly type = '[COMPANY] Set Default Company ID';
}

export class ArchiveAndRestoreClient {
  static readonly type = '[COMPANY] Archive and Restore Selected Client';

  constructor(public clientIds?: Array<Guid>, public isArchive?: boolean) {}
}
export class IsDuplicateExist {
  static readonly type = '[SETTINGS] Is Duplicate Exist';

  constructor(public duplicateExistModel: DuplicateExistModel) {}
}
