<div class="mat-dialog-wrapper match-journal">
  <h2 mat-dialog-title>VAT Payment Status</h2>
  <button
    class="close-button"
    type="button"
    (click)="onCloseClick()"
    mat-button
    disableRipple
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    <div class="content-body p-0">
      <table class="mat-table table-hover" aria-describedby="VAT Payment Table">
        <tbody class="main">
          <tr class="mat-row">
            <td class="mat-cell">Payment Date</td>
            <td class="mat-cell">:</td>
            <td class="mat-cell">
              {{ paymentStatusView.paymentDate | date: "dd-MMM-yyyy" }}
            </td>
          </tr>
          <tr class="mat-row">
            <td class="mat-cell">Reference</td>
            <td class="mat-cell">:</td>
            <td class="mat-cell">
              {{ paymentStatusView.reference }}
            </td>
          </tr>
          <tr class="mat-row">
            <td class="mat-cell">Journal No.</td>
            <td class="mat-cell">:</td>
            <td class="mat-cell">
              {{ paymentStatusView.entryNumber }}
            </td>
          </tr>
          <tr class="mat-row">
            <td class="mat-cell">Payment Account</td>
            <td class="mat-cell">:</td>
            <td class="mat-cell">
              {{ paymentStatusView.fromAccount }}
            </td>
          </tr>
          <tr class="mat-row">
            <td class="mat-cell">VAT Account</td>
            <td class="mat-cell">:</td>
            <td class="mat-cell">
              {{ paymentStatusView.toAccount }}
            </td>
          </tr>
          <tr class="mat-row">
            <td class="mat-cell">VAT Amount</td>
            <td class="mat-cell">:</td>
            <td class="mat-cell">
              £ {{ paymentStatusView.netVatDue | numberPipe }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="hr pt-1 mt-0 justify-content-end">
    <button
      class="action-button warning-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onRevertClick()"
    >
      <mat-icon>delete</mat-icon> Revert
    </button>
    <button
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onCloseClick()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
