<div class="mt-1">
  <p class="fw-bold mr-1 text-align-right">
    Total Debit
    <span class="fw-normal ml-1">£ {{ totalDebit | numberPipe }}</span>
  </p>
  <p class="fw-bold mr-1 mt-0 text-align-right">
    Total Credit
    <span class="fw-normal ml-1">£ {{ totalCredit | numberPipe }}</span>
  </p>
</div>
