import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Modules, NotificationTextMessage, RoutingPath } from '@app/core/Enum';
import { VatRateCodeType } from '@app/core/Enum/vat-rate-code-type';
import { VATCodesModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { GetVATCodes, SaveVATCodes } from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-vat-codes',
  templateUrl: './add-vat-codes.component.html',
  styleUrls: ['./add-vat-codes.component.scss'],
})
export class AddVatCodesComponent implements OnInit {
  moduleId = Modules.VatSettings;
  vatCodesForm: FormGroup;
  showFromTo: boolean = false;
  vatCodesData: VATCodesModel;
  vatCodeId = Guid.EMPTY as unknown as Guid;
  subscriptionRouting: Subscription;
  vatRateCodeTypes = VatRateCodeType;
  spinner: NgxSpinnerService;
  commonService: CommonService;
  store: Store;
  cookieService: CookieService;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private _Activatedroute: ActivatedRoute,
    private location: Location,
    private renderer: Renderer2,
    private router: Router,
    private injector: Injector
  ) {
    this.vatCodesForm?.markAsUntouched();
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.commonService = injector.get<CommonService>(CommonService);
    this.store = injector.get<Store>(Store);
    this.cookieService = injector.get<CookieService>(CookieService);
  }

  ngOnInit(): void {
    this.spinner.hide();
    this.setForm();

    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.vatCodeId = atob(params.get('id')!) as unknown as Guid;
          this.editVatCodesDetails();
        }
      }
    );

    this.vatCodesForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.vatCodesForm.touched;
    });
  }

  onCloseClick(): void {
    this.cookieService.set('selectedTab', '1');
    this.dialog.open(AddClosePopupComponent, {});
  }

  setForm(): void {
    this.vatCodesForm = new FormGroup({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      vatName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      abbreviation: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      vatType: new FormControl('', [Validators.required]),
      rate: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
        Validators.pattern(/^(?=.*[1-9])\d+(?:\.*\d\d?)?$/),
      ]),
    });
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.vatCodesData = {
        id: this.vatCodeId,
        name: this.vatCodesForm.controls.vatName.value,
        rate: +this.vatCodesForm.controls.rate.value,
        codeType: +this.vatCodesForm.controls.vatType.value,
        abbreviation: this.vatCodesForm.controls.abbreviation.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.vatCodesForm.invalid) {
      this.commonService.addValidation(this.vatCodesForm, this.renderer);
    } else {
      if (this.dataSubmit()) {
        this.store.dispatch(new SaveVATCodes(this.vatCodesData)).subscribe(
          (res) => {
            if (res !== undefined) {
              if (!isExit) {
                this.onCancel(false);
              } else {
                this.cookieService.set('selectedTab', '1');
                this.onCancel(false);
                this.location.back();
              }
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.message);
          }
        );
      }
    }
  }

  editVatCodesDetails(): void {
    this.store.dispatch(new GetVATCodes(this.vatCodeId)).subscribe((res) => {
      this.editData(res.vatCodes.vatCodeData);
    });
  }

  editData(data): void {
    this.vatCodesForm.patchValue({
      vatName: data.name,
      abbreviation: data.abbreviation,
      vatType: data.codeType,
      rate: data.rate,
    });
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.vatCodeId)) {
      this.editVatCodesDetails();
    } else {
      this.vatCodeId = Guid.EMPTY as unknown as Guid;
      this.ngOnInit();
      this.router.navigate([RoutingPath.AddVatCodes]);
    }
  }
}
