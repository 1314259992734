import { BankReconciliationModel, MainListParameters } from '@app/core/Models';

export class GetBankReconciliation {
  static readonly type = '[BANKRECONCILIAATION] Get Bank Reconciliation';

  constructor(public queryParams: MainListParameters) {}
}

export class SaveBankReconciliation {
  static readonly type = '[BANKRECONCILIAATION] Save Bank Reconciliation';

  constructor(public params: Array<BankReconciliationModel>) {}
}

export class GetAllBankAccount {
  static readonly type = '[BANKRECONCILIAATION] Get All Bank Account';

  constructor(public branchIds: Array<any>) {}
}

export class GetBankDetail {
  static readonly type = '[BANKRECONCILIAATION] Get Bank Detail';
}
