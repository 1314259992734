import { Component } from '@angular/core';
export interface PeriodicElement {
  name: string;
  company: string;
  type: string;
  receipt: string;
  date: string;
  amount: string;
  status: string;
  actionbtn: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    name: 'Client',
    company: 'Capium',
    type: '',
    receipt: '',
    date: '28-feb-2024',
    amount: '100',
    status: 'Pending',
    actionbtn: '',
  },
  {
    name: 'Client',
    company: 'Capium',
    type: '',
    receipt: '',
    date: '28-feb-2024',
    amount: '100',
    status: 'Recorded',
    actionbtn: '',
  },
  {
    name: 'Client',
    company: 'Capium',
    type: '',
    receipt: '',
    date: '28-feb-2024',
    amount: '100',
    status: 'Rejected',
    actionbtn: '',
  },
  {
    name: 'Client',
    company: 'Capium',
    type: '',
    receipt: '',
    date: '28-feb-2024',
    amount: '100',
    status: 'Pending',
    actionbtn: '',
  },
  {
    name: 'Client',
    company: 'Capium',
    type: '',
    receipt: '',
    date: '28-feb-2024',
    amount: '100',
    status: 'Recorded',
    actionbtn: '',
  },
  {
    name: 'Client',
    company: 'Capium',
    type: '',
    receipt: '',
    date: '28-feb-2024',
    amount: '100',
    status: 'Rejected',
    actionbtn: '',
  },
];

@Component({
  selector: 'app-capiscan-settings',
  templateUrl: './capiscan-settings.component.html',
  styleUrls: ['./capiscan-settings.component.scss'],
})
export class CapiscanSettingsComponent {
  displayedColumns: string[] = [
    'name',
    'company',
    'type',
    'receipt',
    'date',
    'amount',
    'status',
    'actionbtn',
  ];
  dataSource = ELEMENT_DATA;
}
