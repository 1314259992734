import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BillModel,
  OverviewFilterModel,
  SideListModel,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class BillService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createBill(bill: BillModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Purchase/save`,
      JSON.stringify(bill),
      headers
    );
  }

  getDataByBillId(billId: Guid): Observable<BillModel> {
    return this.http.get<BillModel>(
      `${environment.apiVersionUrl}Purchase/get/${billId}`
    );
  }

  deleteBill(billIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(billIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Purchase/delete`,
      options
    );
  }

  copyBill(billIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Purchase/copyPurchase`,
      JSON.stringify(billIds),
      headers
    );
  }

  archiveAndRestoreBill(
    billIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Purchase/archive/${isArchive}`,
      JSON.stringify(billIds),
      options
    );
  }

  getBillRefNo(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Purchase/all`
    );
  }

  getExpenditurePayableAgeingSummaryOverview(
    filterData: OverviewFilterModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/expense/payable
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getExpenditureExpenseBreakdownOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/expense/breakdown`,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getExpenditureFlowOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/expense/flow
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDueAmountByBillId(PurchaseId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Purchase/getPurchaseDueAmount/${PurchaseId}`
    );
  }
  getPaymentCurrencyByPurchaseId(purchaseId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Purchase/getPurchaseCurrencyId/${purchaseId}`
    );
  }
}
