import { VATSettingsModel } from '@app/core/Models';
export class GetVatDetail {
  static readonly type = '[VATSETTINGS] Get VAT deatil';
}

export class CreateVatDetail {
  static readonly type = '[VATSETTINGS] Create VAT Detail';

  constructor(public vatSettings: VATSettingsModel) {}
}
