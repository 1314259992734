<div class="content-body">
  <form [formGroup]="bankTransferForm">
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Transaction No. <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="bankTransferNumber"
            autocomplete="off"
          />
        </mat-form-field>
      </div>

      <div class="form-field datepicker">
        <p>Transfer Date<span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="transferDate"
            [matDatepicker]="transferDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="transferDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #transferDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              bankTransferForm.get('transferDate')
            ).invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              bankTransferForm.get('transferDate')
            ).lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Transferred From <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            formControlName="transferFrom"
            required
            [matAutocomplete]="transferFromGroup"
            #trigger="matAutocompleteTrigger"
            appAutocompletePosition="trigger"
            (keyup)="onAccountSearch($event, true)"
            (mousedown)="resetAccountList(true)"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete
            [displayWith]="getOptionText"
            (opened)="
              scrollIntoView(bankTransferForm.controls.transferFrom.value)
            "
            #transferFromGroup="matAutocomplete"
            (optionSelected)="onChangeTransferFromBank()"
          >
            <mat-optgroup
              *ngFor="let group of transferFromGroupList"
              [label]="group.groupName"
              title="{{ group.groupName }}"
            >
              <mat-option
                *ngFor="let names of group.listModels"
                [value]="names"
                title="{{ names.name }}"
                [class]="
                  this.bankTransferForm.controls.transferFrom.value?.id ===
                  names.id
                    ? 'mat-selected'
                    : ''
                "
              >
                {{ names.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="form-field">
        <p>Transferred To <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            formControlName="transferTo"
            required
            [matAutocomplete]="transferToGroup"
            #trigger="matAutocompleteTrigger"
            appAutocompletePosition="trigger"
            (keyup)="onAccountSearch($event, false)"
            (mousedown)="resetAccountList(false)"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete
            [displayWith]="getOptionText"
            (opened)="
              scrollIntoView(bankTransferForm.controls.transferTo.value)
            "
            #transferToGroup="matAutocomplete"
            (optionSelected)="onChangeTransferToBank()"
          >
            <mat-optgroup
              *ngFor="let group of transferToGroupList"
              [label]="group.groupName"
              title="{{ group.groupName }}"
            >
              <mat-option
                *ngFor="let names of group.listModels"
                [value]="names"
                title="{{ names.name }}"
                [class]="
                  this.bankTransferForm.controls.transferTo.value?.id ===
                  names.id
                    ? 'mat-selected'
                    : ''
                "
              >
                {{ names.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="isMultiCurrencyBank" class="d-flex gap-40">
      <div class="form-field">
        <p>Exchange Rate</p>
        <mat-form-field class="w-140 big-inputbox">
          <input
            matInput
            formControlName="currencyRate"
            allowDecimal
            autocomplete="off"
            (change)="onCurrencyRateEntered()"
          />
          <button
            mat-button
            type="button"
            disableRipple
            matSuffix
            (click)="onCurrencyRefresh()"
          >
            <mat-icon>refresh</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Amount <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <button mat-button type="button" disableRipple matPrefix>
            <span class="symbol-align-left">{{ selectedBankCurrencySymbol }}</span>
          </button>
          <input
            allow_14_2_Decimal
            matInput
            zeroNotAllowed
            formControlName="amount"
            autocomplete="off"
            required
            allowDecimal
            (change)="onAmountEntered()"
          />
        </mat-form-field>
      </div>
      <div *ngIf="isMultiCurrencyBank" class="form-field wap-40 mt-29">
        <mat-form-field>
          <button mat-button type="button" disableRipple matSuffix>
            <span class="symbol-align">{{ transferBankCurrencySymbol }}</span>
          </button>
          <input
            allow_14_2_Decimal
            matInput
            zeroNotAllowed
            formControlName="convertedGbpAmnt"
            autocomplete="off"
            allowDecimal
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Reference</p>
        <mat-form-field>
          <input
            maxlength="{{ maxLength.maxLength2000 }}"
            matInput
            formControlName="reference"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
