import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import { PreviewInvoiceComponent } from './preview-invoice/preview-invoice.component';

@Component({
  selector: 'app-templates-pdf',
  templateUrl: './templates-pdf.component.html',
  styleUrls: ['./templates-pdf.component.scss'],
})
export class TemplatesPdfComponent implements OnInit {
  constructor(private spinner: NgxSpinnerService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.spinner.show();
  }
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'backgroundColor',
        'strikeThrough',
        'subscript',
        'customClasses',
        'insertHorizontalRule',
        'superscript',
        'removeFormat',
        'toggleEditorMode',
        'heading',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'fonts',
      ],
    ],
  };

  slides = [
    {
      img: 'assets/images/invoice.jpg',
      title: 'BlueSky',
    },
    {
      img: 'assets/images/invoice.jpg',
      title: 'SeaGreen',
    },
    {
      img: 'assets/images/invoice.jpg',
      title: 'Classic',
    },
    {
      img: 'assets/images/invoice.jpg',
      title: 'Horizon',
    },
    {
      img: 'assets/images/invoice.jpg',
      title: 'Aqua',
    },
    {
      img: 'assets/images/invoice.jpg',
      title: 'Dusk',
    },
  ];

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  openPreviewInvoice() {
    const dialogRef = this.dialog.open(PreviewInvoiceComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
