import { CISInvoiceModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class GetCISInvoiceList {
  static readonly type = '[CISINVOICE] Get CISInvoice';
}

export class CreateCISInvoice {
  static readonly type = '[CISINVOICE] Create CISInvoice';

  constructor(public cisInvoice: CISInvoiceModel) {}
}

export class GetDataByCISInvoiceId {
  static readonly type = '[CISINVOICE] Get CISInvoice Data By CISInvoice Id';

  constructor(public cisInvoiceId: Guid) {}
}

export class ArchiveAndRestoreCISInvoice {
  static readonly type = '[CISINVOICE] Archive and Restore Selected CISInvoice';

  constructor(public cisInvoiceIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteCISInvoice {
  static readonly type = '[CISINVOICE] Delete Selected CISInvoice';

  constructor(public cisInvoiceIds: Array<Guid>) {}
}
export class GetDueAmountByCisInvoiceId {
  static readonly type =
    '[CISINVOICE] Get CISInvoice Due Amount By CISInvoice Id';

  constructor(public cisInvoiceId: Guid) {}
}
export class GetCisInvoiceCurrencyByCisInvoiceId {
  static readonly type =
    '[CISINVOICE] Get CISInvoice Currency By CISInvoice Id';

  constructor(public cisInvoiceId: Guid) {}
}
export class GetCisInvoiceLabourAndMaterialAmountByCisInvoiceId {
  static readonly type =
    '[CISINVOICE] Get CISInvoice Labour And Material Amount By CISInvoice Id';

  constructor(public cisInvoiceId: Guid) {}
}
