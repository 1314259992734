import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Modules } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { GetDataByBankTransferId } from '@app/core/Store/bank/bank-transfer';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-bank-transfer-view',
  templateUrl: './bank-transfer-view.component.html',
  styleUrls: ['./bank-transfer-view.component.scss'],
})
export class BankTransferViewComponent implements OnInit {
  isJournalChangePermission: boolean = true;
  isJournalChangePermissionEdit: boolean = true;
  bankTransfer: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BankTransferViewComponent>,
    public spinner: NgxSpinnerService,
    public commonService: CommonService,
    public store: Store
  ) {}

  ngOnInit(): void {
    this.getBankTransfer();
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  getBankTransfer(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetDataByBankTransferId(this.data.id))
      .subscribe((res) => {
        this.spinner.hide();
        this.bankTransfer = res.bankTransfer.bankTransferData;
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isJournalChangePermission = this.commonService.checkPermission(
        Modules.Bank,
        Modules.BankTransfer
      );

      this.isJournalChangePermissionEdit = this.commonService.checkPermission(
        Modules.Bank,
        Modules.BankTransfer,
        true
      );
    }, 500);
  }

  onEditClick(): void {
    this.dialogRef.close(false);
    this.commonService.onEditRouting(true, Modules.BankTransfer, this.data.id);
  }
}
