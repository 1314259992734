<app-menu-items></app-menu-items>
<div class="content-body content-body-scroll">
  <div class="sidenav-body-content">
    <div *ngIf="isDataAvailable$">
      <div class="main-list content">
        <div class="d-flex align-items-end wp-100 mb-1 gap-10">
          <button
            class="action-button primary-button border-radius-small w-150"
            mat-button
            #button
            disableRipple
            (click)="onAddClick()"
            name="{{ gridActionsText.Create }}"
            [disabled]="isViewPermission$ | async"
          >
            <mat-icon>add</mat-icon>
            <span>Add User </span>
          </button>
          <div class="grid-search">
            <mat-form-field appearance="fill" class="search-field">
              <input
                matInput
                placeholder="Search"
                autocomplete="off"
                (keyup)="onSearch($event)"
              />
              <button mat-button class="search-button">
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="header-actions wp-100">
            <div
              class="align-items-end content-header d-flex header-count header-filters justify-content-end header-count-1"
            >
              <div class="toolbar d-flex align-items-center gap-10">
                <ng-select
                  [(ngModel)]="defaultFilterStatus"
                  #type
                  (focus)="type.open()"
                  (valueChange)="type.close()"
                  panelClass="myPanelClass"
                  [disableOptionCentering]="true"
                  (change)="onFilterChange($event)"
                >
                  <ng-option
                    *ngFor="let sf of statusFilter | useEnumValues"
                    [value]="sf.key"
                  >
                    {{ sf.value }}
                  </ng-option>
                </ng-select>
                <button mat-button [matMenuTriggerFor]="menu" disableRipple>
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu
                  #menu="matMenu"
                  class="action-menu more-menu"
                  xPosition="before"
                >
                  <button
                    mat-menu-item
                    #button
                    (click)="export(exportType.Excel, false)"
                    disableRipple
                    name="{{ gridActionsText.Excel }}"
                    [disabled]="isViewPermission$ | async"
                    [ngClass]="
                      (isViewPermission$ | async) ? 'cursor-not-allowed' : ''
                    "
                  >
                    <mat-icon>border_all</mat-icon>Export to Excel
                  </button>
                  <button
                    mat-menu-item
                    #button
                    (click)="export(exportType.CSV, false)"
                    disableRipple
                    name="{{ gridActionsText.CSV }}"
                    [disabled]="isViewPermission$ | async"
                    [ngClass]="
                      (isViewPermission$ | async) ? 'cursor-not-allowed' : ''
                    "
                  >
                    <mat-icon fontSet="material-icons-outlined"
                      >description</mat-icon
                    >Export to CSV
                  </button>
                  <button
                    mat-menu-item
                    #button
                    (click)="export(exportType.PDF, false)"
                    disableRipple
                    name="{{ gridActionsText.PDF }}"
                    [disabled]="isViewPermission$ | async"
                    [ngClass]="
                      (isViewPermission$ | async) ? 'cursor-not-allowed' : ''
                    "
                  >
                    <mat-icon fontSet="material-icons-outlined"
                      >picture_as_pdf</mat-icon
                    >Export to PDF
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mat-table-wrapper"
        [ngClass]="[
          totalSelectedRecords > 0 ? 'mat-table-wrapper-full' : '',
          showPaginator && (totalRecord$ | async)! >= 10
            ? 'mat-table-wrapper-pagination'
            : ''
        ]"
      >
        <table
          mat-table
          [dataSource]="userList"
          matSort
          (matSortChange)="sorting(sort.active, sort.direction)"
          aria-describedby="User details table"
          class="grid-table"
        >
          <ng-container matColumnDef="userCode">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="w-80">
              <mat-checkbox
                [(ngModel)]="isAllSelected"
                (change)="selectAll($event)"
                (click)="$event.stopPropagation()"
              ></mat-checkbox>
              ID
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex align-items-center">
                <mat-checkbox
                  [(ngModel)]="element.isSelected"
                  (change)="onCheckBoxSelected($event)"
                ></mat-checkbox>
                <a
                  class="hyperlink onelineclamp"
                  (click)="onUserEdit(element.universalId)"
                  title="{{ element.userCode }}"
                  [ngClass]="!element.isArchived ? '' : 'link-disable'"
                  >{{ element.userCode }}
                </a>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="w-150">
              User
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex align-items-center">
                <span class="onelineclamp" title="{{ element.name }}">{{
                  element.name
                }}</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex align-items-center">
                <span class="onelineclamp" title="{{ element.email }}">{{
                  element.email
                }}</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="w-230">
              Status
            </th>
            <td mat-cell *matCellDef="let data; let i = index" class="status">
              <div class="d-flex align-items-center justify-space-between">
                <div class="clamp">
                  <a
                    *ngIf="
                      data.status === statusFilter['Resend Invitation'] ||
                      data.status === statusFilter['Invite']
                    "
                    class="hyperlink"
                    title="{{ statusFilter[data.status] }}"
                    (click)="onInviteClick(data)"
                  >
                    {{ statusFilter[data.status] }}
                  </a>

                  <span
                    *ngIf="
                      data.status === statusFilter['Active'] ||
                      data.status === statusFilter['Inactive']
                    "
                    [ngClass]="{
                      'status-active': data.status === statusFilter['Active'],
                      'status-archived':
                        data.status === statusFilter['Inactive']
                    }"
                  >
                    {{ statusFilter[data.status] }}
                  </span>
                </div>
                <div class="d-flex align-items-center grid-actions">
                  <button
                    mat-button
                    disableRipple
                    title="Edit"
                    (click)="onUserEdit(data.universalId)"
                  >
                    <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                  </button>
                  <button
                    mat-button
                    disableRipple
                    title="Delete"
                    (click)="onDeleteClick(data.universalId)"
                    [disabled]="isViewPermission$ | async"
                  >
                    <mat-icon fontSet="material-icons-outlined"
                      >delete_outline</mat-icon
                    >
                  </button>
                </div>
              </div>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns; let i = index"
            [ngClass]="[isRowHighlighted ? 'highlightRow' : '']"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td
              class="mat-cell"
              [ngClass]="'text-align-center'"
              [attr.colspan]="displayedColumns.length"
            >
              <span
                *ngIf="
                  listParameters &&
                  !(listParameters.search || listParameters.filter)
                "
                >{{ commonNotificationText.NoRecordsFound }}</span
              >
              <span
                *ngIf="
                  listParameters &&
                  (listParameters.search || listParameters.filter)
                "
                >{{ commonNotificationText.NoRecordsFoundForFilter }}</span
              >
            </td>
          </tr>
        </table>
      </div>
    </div>
    <app-dynamic-GIF
      *ngIf="!(isDataAvailable$ | async)"
      [moduleId]="moduleId"
      [moduleHeaderText]="moduleHeaderText"
    >
    </app-dynamic-GIF>

    <div
      class="paginator-wrapper"
      *ngIf="totalSelectedRecords <= 0 && (totalRecord$ | async)! > 0"
    >
      <app-custom-paginator
        *ngIf="(totalRecord$ | async)! >= 10"
        [length]="totalRecord$ | async"
        [listParameters]="listParameters"
        (pageChanged)="pageChanged($event)"
        (pageSizeVal)="pageSizeVal($event)"
        (togglePaginator)="togglePaginator($event)"
      >
      </app-custom-paginator>
    </div>
  </div>
</div>
<app-card-footer
  *ngIf="totalSelectedRecords > 0"
  [getModuleId]="moduleId"
  [totalSelectedRecords]="totalSelectedRecords"
  (deleteClick)="deleteClick()"
  (exportClick)="export($event, false)"
  (cancelSelectionClick)="cancelSelectionClick()"
  (printClick)="printClick()"
></app-card-footer>
