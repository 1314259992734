<div class="content-body content-body-scroll">
  <div class="content-header mb-1">
    <div class="content-header-input d-flex justify-space-between">
      <div class="d-flex align-items-center gap-20">
        <p class="text-primary font-size-20 fw-bold m-0">General Dashboard</p>
      </div>
    </div>
  </div>

  <div class="chart-wrapper d-flex gap-20 mt-2">
    <div class="card">
      <div
        class="d-flex align-items-center hr-bottom justify-space-between pb-10"
      >
        <h3 class="card-title card-title-without-dropdown">
          Return(s) in Current Month
        </h3>
      </div>
      <div *ngIf="generalDashboardPieChartData != null">
        <app-dashboard-current-month
          [generalDashboardPieChartData]="generalDashboardPieChartData"
        >
        </app-dashboard-current-month>
      </div>
    </div>
    <div class="card">
      <div
        class="d-flex align-items-center hr-bottom justify-space-between pb-10"
      >
        <h3 class="card-title card-title-without-dropdown">
          Monthly Submitted Return(s)
        </h3>
        <div class="d-flex gap-20">
          <mat-form-field appearance="fill" class="w-150">
            <mat-select
              panelClass="myPanelClass"
              [disableOptionCentering]="true"
              placeholder="2024"
              [(ngModel)]="year"
              (selectionChange)="onYearChange()"
            >
              <mat-option value="2024">2024</mat-option>
              <mat-option value="2023">2023</mat-option>
              <mat-option value="2022">2022</mat-option>
              <mat-option value="2021">2021</mat-option>
              <mat-option value="2020">2020</mat-option>
              <mat-option value="2019">2019</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="generalDashboardBarChartData != null">
        <app-dashboard-monthly-return
          [generalDashboardBarChartData]="generalDashboardBarChartData"
        >
        </app-dashboard-monthly-return>
      </div>
    </div>
  </div>

  <div class="chart-wrapper d-flex gap-20 mt-2 mb-2">
    <div class="card">
      <div class="company-preference-info">
        <mat-tab-group
          mat-align-tabs="start"
          disableRipple
          animationDuration="0ms"
          animationDuration="0ms"
          [(selectedIndex)]="selectedTab"
          (selectedTabChange)="onTabClick($event.index)"
        >
          <mat-tab label="All Clients">
            <app-dashboard-all-client
              *ngIf="selectedTab === 0"
            ></app-dashboard-all-client>
          </mat-tab>
          <mat-tab label="VAT Clients">
            <app-dashboard-vat-client
              *ngIf="selectedTab === 1"
              (onMonthChanged)="triggermonth($event)"
            ></app-dashboard-vat-client>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
