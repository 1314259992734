import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Modules, OverviewYear } from '@app/core/Enum';
import { MainListParameters } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetBranchList,
  GetCreditorDebtorReport,
  GetDepartmentListByBranchIds,
  MenuState,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-business-snapshot',
  templateUrl: './business-snapshot.component.html',
  styleUrls: ['./business-snapshot.component.scss'],
})
export class BusinessSnapshotComponent implements OnInit {
  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;
  moduleId = Modules.BusinessSnapshot;
  public branchmode: string;
  public branchfilterPlaceholder: string;
  public branchFields: Object = { text: 'name', value: 'id' };
  public branchWaterMark: string = 'Branch - All';
  public branchpopHeight: string = '350px';

  public departmentmode: string;
  public departmentfilterPlaceholder: string;
  public departmentFields: Object = { text: 'name', value: 'id' };
  public departmentWaterMark: string = 'Department - All';
  public departmentpopHeight: string = '350px';

  selectedBranchValues: any[] = [];
  selectedDepartmentValues: any[] = [];
  departmentList: any[] = [];
  branchList: any[] = [];
  departmentListParameters: MainListParameters = new MainListParameters();
  branchListParameters: MainListParameters = new MainListParameters();

  selectYear = OverviewYear;
  selectedYearValue = OverviewYear['This year'];
  showCalender = false;
  rangeFormGroup: FormGroup;
  dateRange: any;
  creditorsData: any;
  debitorsData: any;
  @Output()
  readonly triggerIncomeData = new EventEmitter<any>();

  @Output()
  readonly triggerExpenseData = new EventEmitter<any>();

  @Output()
  readonly triggerHeaderFilter = new EventEmitter<any>();

  @Output()
  readonly triggerIncomeComparison = new EventEmitter<any>();

  @Output()
  readonly triggerExpenseComparison = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    public datepipe: DatePipe,
    public commonService: CommonService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.dateRange = this.commonService.generateDateSeries(
      this.selectedYearValue
    );

    this.moduleId$.subscribe((moduleId) => {
      if (moduleId === Modules.BusinessSnapshot) {
        this.setForm();
      }
    });

    this.branchmode = 'CheckBox';
    this.branchfilterPlaceholder = 'Search Branch';
    this.departmentmode = 'CheckBox';
    this.departmentfilterPlaceholder = 'Search Department';
    this.getBranchList();
  }

  getDepartmentListByBranchIds(): void {
    this.store
      .dispatch(new GetDepartmentListByBranchIds(this.selectedBranchValues))
      .subscribe((res) => {
        this.departmentList = [];
        if (res.company.departmentListbyIds.length > 0) {
          res.company.departmentListbyIds.forEach((element) => {
            if (this.departmentList.length === 0) {
              this.departmentList.push(element);
            } else {
              const isExist = this.departmentList.filter(
                (x) => x.id === element.id
              );
              if (isExist.length === 0) {
                this.departmentList.push(element);
              }
            }
          });
        }
      });
  }

  onDepartmentMultiSelectChange(event: any): void {
    this.selectedDepartmentValues = event.value;

    this.onSelectionChange(this.selectedYearValue);
  }

  onBranchMultiSelectChange(event: any): void {
    this.selectedBranchValues = event.value;
    if (this.selectedBranchValues.length > 0) {
      this.getDepartmentListByBranchIds();
    } else {
      this.departmentList = [];
      this.selectedDepartmentValues = [];
    }

    this.onSelectionChange(this.selectedYearValue);
  }

  getBranchParamaters(): any {
    const queryParams = {
      sortBy: this.branchListParameters.sortBy,
      sortOrder: this.branchListParameters.sortOrder,
      search: this.branchListParameters.search,
    };

    return queryParams;
  }

  getBranchList(): void {
    this.store
      .dispatch(new GetBranchList(this.getBranchParamaters()))
      .pipe(
        tap((res) => {
          this.branchList = [];
          res.company.branch.forEach((element) => {
            this.branchList.push(element);
          });
        })
      )
      .subscribe();
  }

  onSelectionChange(val: any): void {
    this.showCalender = +val === 7;
    if (
      this.showCalender &&
      this.rangeFormGroup.controls.start.value !== null &&
      this.rangeFormGroup.controls.end.value !== null
    ) {
      this.getData();
    } else if (!this.showCalender) {
      this.getData();
    }
    this.dateRange = this.commonService.generateDateSeries(val);
  }

  setForm(): void {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.rangeFormGroup = this.formBuilder.group({
      start: [startDate],
      end: [new Date()],
    });

    this.getData();
  }

  getData(): void {
    const data = this.getFilter();
    this.triggerIncomeData.emit(data);
    this.triggerExpenseData.emit(data);
    this.triggerExpenseComparison.emit(data);
    this.triggerIncomeComparison.emit(data);
    this.getCreditorsDebitorsData();
  }

  getFilter(): any {
    this.dateRange = this.commonService.generateDateSeries(
      this.selectedYearValue
    );
    const filterData = {
      moduleId: 0,
      dateFilter: {
        filter: this.selectedYearValue,
        startDate:
          this.selectedYearValue < 7
            ? this.datepipe
                .transform(this.dateRange.startDate, 'yyyy-MM-dd')
                ?.toString()
            : this.datepipe
                .transform(
                  this.rangeFormGroup.controls.start.value,
                  'yyyy-MM-dd'
                )
                ?.toString(),
        branchIds: this.selectedBranchValues ?? [],
        departmentIds: this.selectedDepartmentValues ?? [],

        endDate:
          this.selectedYearValue < 7
            ? this.datepipe
                .transform(this.dateRange.endDate, 'yyyy-MM-dd')
                ?.toString()
            : this.datepipe
                .transform(this.rangeFormGroup.controls.end.value, 'yyyy-MM-dd')
                ?.toString(),
      },
    };

    return filterData;
  }

  getCreditorsDebitorsData(): void {
    const data = this.getFilter();
    const queryParams: any = {
      moduleId: Guid.EMPTY as unknown as Guid,
      subModuleId: -1,
      ids: null,
      startDate: data.dateFilter.startDate,
      endDate: data.dateFilter.endDate,
      branchIds: this.selectedBranchValues ?? [],
      departmentIds: this.selectedDepartmentValues ?? [],
    };

    this.store
      .dispatch(new GetCreditorDebtorReport(queryParams, Modules.Creditors))
      .pipe(
        switchMap((creditorRes) => {
          this.creditorsData = creditorRes.common.mainList.resultSet.data;
          return this.store.dispatch(
            new GetCreditorDebtorReport(queryParams, Modules.Debtors)
          );
        })
      )
      .subscribe((debtorRes) => {
        this.debitorsData = debtorRes.common.mainList.resultSet.data;
      });
  }
}
