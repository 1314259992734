import { Component, Inject, Injector, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import {
  ComparitiveReportParamerters,
  GlobalComponent,
  MainListParameters,
} from '@app/core/Models';
import { NotificationService } from '@app/core/Services';
import {
  CountryState,
  GetCountryList,
  GetDataByCompanyId,
  GetDataSecurityDetails,
  SendComparativeReportEmail,
  SendEmailCis300Verification,
  SendEmailInvoiceReport,
  ShareFile,
} from '@app/core/Store';
import { EmailTemplatesComponent } from '@app/modules/email-templates';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  emailForm: FormGroup;
  fileId: string;
  item: any;
  businessId = '8009693e-12b4-41fa-94d6-7f090cf3ba20';
  breadcrumbs: any = [];
  sendMailParamsRequest: MainListParameters = new MainListParameters();
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '7rem !important',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: false,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
  };

  spinner: NgxSpinnerService;
  isFormSubmitted = false;
  isShowLoader = false;
  isEnableDocPassword = false;
  masterPassword: string;
  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EmailTemplatesComponent>,
    public store: Store,
    private notifier: NotificationService,
    private globalComponent: GlobalComponent,
    private injector: Injector
  ) {
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
  }
  companyId: any;
  countryList: any=[];
  ngOnInit(): void {
    this.GetCountryListData();
    this.getDataSecurityDetails();
    this.emailForm = this.formBuilder.group({
      to: new FormControl('', [Validators.required, Validators.email]),
      cc: new FormControl(''),
      bcc: new FormControl(''),
      message: new FormControl('', Validators.required),
      subject: new FormControl('', Validators.required),
    });
    this.companyId = this.globalComponent.getCompanyId();
    setTimeout(() => {
      this.setMailTemplateDetails();
    }, 500);
  }
  setMailTemplateDetails(): void {
    this.store
      .dispatch(new GetDataByCompanyId(this.companyId))
      .subscribe((res) => {
        this.sendMailParamsRequest = this.data.sendMailParamsRequest;
        const subjectName = this.getModuleName(
          this.sendMailParamsRequest?.modId
        );
        const companyData = res.company.companyData;
        const countryDetails = this.countryList.filter(
          (x) => x.id == companyData?.addressDetail?.country
        );
        if(this.sendMailParamsRequest?.modId === 63 && this.isEnableDocPassword){
          this.emailForm.patchValue({
            subject: subjectName,
            message:
              '<p>Dear,</p><br><p>Please find attached the following ' +
              subjectName +
              '</p><p>Should you have any queries, please do not hesitate to contact us on the details below.</p><br><p> This document is password protected. Please give the below password to open this document.</p> <br><p> Master Password :'+this.masterPassword +
              '</p> </p><br><p>Regards,</p><p>' +
              companyData.name +
              '</p> <p>' +
              companyData?.addressDetail?.address1 +
              '</p> <p>' +
              countryDetails[0]?.description +
              '</p><p>' +
              companyData?.addressDetail?.postalCode +
              '</p>',
          });
       }else{
        this.emailForm.patchValue({
          subject: subjectName,
          message:
            '<p>Dear,</p><br><p>Please find attached the following ' +
            subjectName +
            '</p><p>Should you have any queries, please do not hesitate to contact us on the details below.</p><br> <p>Regards,</p><p>' +
            companyData.name +
            '</p> <p>' +
            companyData?.addressDetail?.address1 +
            '</p> <p>' +
            countryDetails[0]?.description +
            '</p><p>' +
            companyData?.addressDetail?.postalCode +
            '</p>',
        });
       }
      });
  }
  GetCountryListData(): void {
    this.store
      .dispatch(new GetCountryList())
      .pipe(
        tap(() => {
          this.countryList = this.store.selectSnapshot(CountryState.getCountry);
        })
      )
      .subscribe();
  }

  getModuleName(moduleId: number): any {
    switch (moduleId) {
      case Modules.ProfitAndLoss:
        return 'Profit & Loss Report';
      case Modules.TrialBalance:
        return 'TrialBalance Report';
      case Modules.BalanceSheet:
        return 'Balance Sheet Report';
      case Modules.ComparativeReport:
        return 'Comparative Report';
      case Modules.DayBook:
        return 'DayBook Report';
      case Modules.OpenInvoice:
        return 'Open Invoice Report';
      case Modules.UnpaidPurchases:
        return 'Unpaid Purchases Report';
      case Modules.AdvanceAgeingList:
        return 'AdvanceAgeingList Report';
      case Modules.AccountPayableList:
        return 'AccountPayableList Report';
      case Modules.AccountDetails:
        return 'AccountDetails Report';
      case Modules.NominalLedger:
        return 'NominalLedger Report';
      case Modules.BusinessSnapshot:
        return 'BusinessSnapshot Report';
      case Modules.AccountList:
        return 'AccountList Report';
      case Modules.AnnualReport:
        return 'AnnualReport Report';
      case Modules.TransactionListByDate:
        return 'TransactionListByDate Report';
      case Modules.RecentTransactions:
        return 'RecentTransactions Report';
      case Modules.BalanceSheetComparison:
        return 'BalanceSheetComparison Report';
      case Modules.BalanceSheetDetail:
        return 'BalanceSheetDetail Report';
      case Modules.CustomizeReport:
        return 'Customize Report';
      case Modules.CustomerAdvanceReport:
        return 'CustomerAdvance Report';
      case Modules.SalesReport:
        return 'Sales Report';
      case Modules.CustomerList:
        return 'CustomerList Report';
      case Modules.CustomerAgeingList:
        return 'CustomerAgeingList Report';
      case Modules.TransactionsByCustomer:
        return 'TransactionsByCustomer Report';
      case Modules.SalesProductList:
        return 'SalesProductList Report';
      case Modules.CustomerReceipts:
        return 'CustomerReceipts Report';
      case Modules.Debtors:
        return 'Debtors Report';
      case Modules.CustomerStatement:
        return 'CustomerStatement Report';
      case Modules.SalesInvoiceList:
        return 'SalesInvoiceList Report';
      case Modules.IncomeByCustomerSummary:
        return 'IncomeByCustomerSummary Report';
      case Modules.CustomerBalanceSummary:
        return 'CustomerBalanceSummary Report';
      case Modules.DepositDetails:
        return 'DepositDetails Report';
      case Modules.QuotationByCustomer:
        return 'QuotationByCustomer Report';
      case Modules.InvoiceAndReceivedPayments:
        return 'InvoiceAndReceivedPayments Report';
      case Modules.SalesByProductDetail:
        return 'SalesByProductDetail Report';
      case Modules.SalesByProductSummary:
        return 'SalesByProductSummary Report';
      case Modules.SupplierAdvanceReport:
        return 'SupplierAdvance Report';
      case Modules.PurchasesReport:
        return 'Purchases Report';
      case Modules.SupplierList:
        return 'SupplierList Report';
      case Modules.SupplierAgeingList:
        return 'SupplierAgeingList Report';
      case Modules.TransactionsBySupplier:
        return 'TransactionsBySupplier Report';
      case Modules.PaymentToSupplier:
        return 'PaymentToSupplier Report';
      case Modules.Creditors:
        return 'Creditors Report';
      case Modules.SupplierStatement:
        return 'SupplierStatement Report';
      case Modules.PurchasesInvoiceList:
        return 'PurchasesInvoiceList Report';
      case Modules.ExpensesBySupplierSummary:
        return 'ExpensesBySupplierSummary Report';
      case Modules.SupplierBalanceSummary:
        return 'SupplierBalanceSummary Report';
      case Modules.ChequeDetails:
        return 'ChequeDetails Report';
      case Modules.PurchaseAndAppliedPayments:
        return 'PurchaseAndAppliedPayments Report';
      case Modules.VAT:
        return 'VAT Report';
      case Modules.CISReportCISInvoice:
        return 'CIS Invoice';
      case Modules.SubcontractorYTDDetails:
        return 'SubContractor YTD Details Report';
      case Modules.CIS300Ceritification:
        return 'CIS300 Certification';
      case Modules.CashFlow:
        return 'Cash Flow';

      default:
        return 'Report';
    }
  }
  hideOrShowBCC = false;
  hideOrShowCC = false;
  HideorShowCC(): void {
    this.hideOrShowCC = true;
  }

  HideorShowBCC(): void {
    this.hideOrShowBCC = true;
  }

  get getFormControls(): any {
    return this.emailForm.controls;
  }

  onSend(): void {
    this.isFormSubmitted = true;
    const message = this.emailForm.controls.message.value;
    if (this.emailForm.invalid) {
      return;
    }

    this.isFormSubmitted = false;
    const toMailId = this.emailForm.controls.to.value;
    const ccMailId = this.emailForm.controls.cc.value;
    const bccMailId = this.emailForm.controls.bcc.value;
    const subject = this.emailForm.controls.subject.value;
    if (this.sendMailParamsRequest.modId === Modules.CISReportCISInvoice) {
      this.spinner.show();
      this.store
        .dispatch(
          new SendEmailInvoiceReport(
            this.sendMailParamsRequest,
            toMailId,
            ccMailId,
            bccMailId,
            message,
            subject
          )
        )
        .pipe(
          tap(() => {
            this.spinner.hide();
            this.notifier.success(
              NotificationHeader.success,
              NotificationTextMessage.sentMail
            );
          })
        )
        .subscribe(() => this.dialogRef.close(NotificationHeader.success));
    } else if (
      this.sendMailParamsRequest.modId === Modules.CIS300Ceritification
    ) {
      this.spinner.show();

      this.store
        .dispatch(
          new SendEmailCis300Verification(
            this.sendMailParamsRequest,
            toMailId,
            ccMailId,
            bccMailId,
            message,
            subject
          )
        )
        .pipe(
          tap(() => {
            this.spinner.hide();
            this.notifier.success(
              NotificationHeader.success,
              NotificationTextMessage.sentMail
            );
          })
        )
        .subscribe(() => this.dialogRef.close(NotificationHeader.success));
    } else if (this.sendMailParamsRequest.modId === Modules.ComparativeReport) {
      this.spinner.show();

      this.store
        .dispatch(
          new SendComparativeReportEmail(
            this
              .sendMailParamsRequest as unknown as ComparitiveReportParamerters,
            toMailId,
            ccMailId,
            bccMailId,
            message,
            subject
          )
        )
        .pipe(
          tap(() => {
            this.spinner.hide();
            this.notifier.success(
              NotificationHeader.success,
              NotificationTextMessage.sentMail
            );
          })
        )
        .subscribe(() => this.dialogRef.close(NotificationHeader.success));
    } else {
      this.store
        .dispatch(
          new ShareFile(
            this.sendMailParamsRequest,
            toMailId,
            ccMailId,
            bccMailId,
            message,
            subject
          )
        )
        .pipe(
          tap(() => {
            this.notifier.success(
              NotificationHeader.success,
              NotificationTextMessage.sentMail
            );
          })
        )
        .subscribe(() => this.dialogRef.close(NotificationHeader.success));
    }
  }

  getDataSecurityDetails(): void {
    this.store
      .dispatch(new GetDataSecurityDetails(this.globalComponent.getCompanyId()))
      .subscribe((res) => {

        if(res.datasecurity.dataSecurityData && res.datasecurity.dataSecurityData.isEnablePassword){
          this.isEnableDocPassword = true;
          this.masterPassword = res.datasecurity.dataSecurityData.masterPassword;
        }
      });
  }
}
