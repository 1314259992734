import { Guid } from 'guid-typescript';

export class FixedAssetDepriciationModel {
  id: Guid;
  amount: number;
}

export class FixedAssetExplainModel {
  id: Guid;
  name: string;
  referenceTypeId?: number;
  bookAccountId?: Guid;
  referenceId?: Guid;
  profitLossAccountTypeId?: number;
  methodTypeId?: number;
  rate?: number;
  isDepreciable: boolean;
  accumulatedDepreciationAmount?: number;
  isAutoDepreciate: boolean;
  entryDate?: string;
  depriciationStartDate?: string;
  depreciationUptoDate?: string;
}

export class FixedAssetDepreciationModel {
  id: Guid;
  fundId?: Guid;
  amount: number;
  name: string;
  acquiredDate: string;
  lastDepreciate?: string;
  entryDate: string;
  totalAmount: number;
  totalDepreciation: number;
  bookValue: number;
  journalId: Guid;
  accountingPeriodId: Guid;
  fixedAssetExplainId: Guid;
  rate: number;
  isTangible: boolean;
  method: string;
  methodTypeId: number;
  branchId?: Guid | null;
  departmentId?: Guid | null;
}

export class FixedAssetDisposeModel {
  id: Guid;
  name: string;
  referenceTypeId: number;
  bookAccountId: Guid;
  referenceId: Guid;
  methodTypeId?: number;
  rate?: number;
  isDepreciable: boolean;
  totalAmount: number;
  totalDepreciation: number;
  currentDepreciation: number;
  entryDate: string;
  isDisposed: boolean;
  disposalType?: number;
  disposeDate?: string;
  amountSold?: number;
  amountLoss?: number;
  description: string;
  transactionAccountId?: Guid;
  disposeJournalId?: Guid;
  transactionJournalId?: Guid;
  isVATApplicable: boolean;
  vatRateId?: Guid;
  vatAmount?: number;
  netAmount?: number;
}
