<div class="content-body">
  <div class="form-field-title">
    <p>Quick Entry Details</p>
  </div>
  <div class="sidenav-table sidenav-table-input quick-entry-grid wp-100">
    <div class="mat-table-wrapper product-detail-table">
      <form [formGroup]="formProductDetail">
        <ng-container formArrayName="productDetailArray">
          <table
            aria-describedby="product-list"
            mat-table
            [dataSource]="tableDataSource"
          >
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>
                Type <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field appearance="fill" class="w-100">
                  <mat-select
                    [disableOptionCentering]="true"
                    formControlName="type"
                    dropdownPosition="top"
                    panelClass="mat-select-position"
                    (selectionChange)="onTypeSelected(i)"
                    required
                  >
                    <mat-option
                      *ngFor="let option of quickEntryTypeList"
                      [value]="option.id"
                    >
                      <div class="clamp" title="{{ option.name }}">
                        {{ option.name }}
                      </div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="customerSupplierId">
              <th mat-header-cell *matHeaderCellDef>Customer / Supplier</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <ng-select
                  class="w-150"
                  #customerOrSupplierId
                  (focus)="customerOrSupplierId.open()"
                  (valueChange)="customerOrSupplierId.close()"
                  formControlName="customerSupplierId"
                  [disableOptionCentering]="true"
                  panelClass="mat-select-position"
                  appendTo="body"
                  (click)="getCustomerOrSupplierList(i)"
                >
                  <ng-option
                    *ngFor="let option of customerSupplierList"
                    [value]="option.id"
                  >
                    <div class="clamp" title="{{ option.name }}">
                      {{ option.name }}
                    </div>
                  </ng-option>
                </ng-select>
              </td>
            </ng-container>
            <ng-container matColumnDef="reference">
              <th mat-header-cell *matHeaderCellDef>Reference</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="widthemail"
              >
                <mat-form-field floatLabel="never" class="w-100">
                  <input
                    matInput
                    [value]="element.reference"
                    autocomplete="off"
                    formControlName="reference"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="account">
              <th mat-header-cell *matHeaderCellDef>
                Account <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field appearance="fill" class="w-150">
                  <input
                    type="text"
                    matInput
                    formControlName="account"
                    required
                    [matAutocomplete]="accountGroup"
                    (keyup)="onAccountSearch($event, i)"
                    (keydown.Tab)="onSearch($event, i)"
                    (change)="onSearch($event, i)"
                    #trigger="matAutocompleteTrigger"
                    appAutocompletePosition="trigger"
                    (click)="onClickAccountList(i)"
                    (mousedown)="resetAccountList(element, i)"
                  />
                  <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                  <mat-autocomplete
                    [displayWith]="getOptionText"
                    #accountGroup="matAutocomplete"
                    [disableOptionCentering]="true"
                    (opened)="scrollIntoView(element)"
                  >
                    <mat-optgroup
                      *ngFor="let group of accountGroupList"
                      [label]="group.groupName"
                      title="{{ group.groupName }}"
                    >
                      <mat-option
                        *ngFor="let names of group.listModels"
                        [value]="names"
                        title="{{ names.name }}"
                        [class]="
                          productDetailArray.controls[i].get('account').value
                            ?.id === names.id
                            ? 'mat-selected'
                            : ''
                        "
                      >
                        {{ names.name }}
                      </mat-option>
                    </mat-optgroup>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="widthemail"
              >
                <mat-form-field floatLabel="never" class="w-150">
                  <input
                    matInput
                    [value]="element.description"
                    autocomplete="off"
                    formControlName="description"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="invoiceDate">
              <th mat-header-cell *matHeaderCellDef>
                Invoice Date <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field appearance="standard" class="w-120">
                  <input
                    matInput
                    formControlName="invoiceDate"
                    [matDatepicker]="DatePicker"
                    autocomplete="off"
                    (dateChange)="onInvoiceDateEntered(i)"
                    (change)="commonService.validateDate($event)"
                    required
                    tabindex="-1"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="DatePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #DatePicker></mat-datepicker>
                </mat-form-field>
                <mat-error
                  *ngIf="
                    commonService.checkDateValidation(
                      productDetailArray.controls[i].get('invoiceDate')
                    ).invalidFinancialYear
                  "
                >
                  {{ notificationMessage.validPeriodDateMessage }}
                </mat-error>

                <mat-error
                  *ngIf="
                    commonService.checkDateValidation(
                      productDetailArray.controls[i].get('invoiceDate')
                    ).lockedFinancialYear
                  "
                >
                  {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
                </mat-error>
              </td>
            </ng-container>
            <ng-container matColumnDef="netAmount">
              <th mat-header-cell *matHeaderCellDef>
                Net Amount <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never" class="w-100">
                  <input
                    allow_14_2_Decimal
                    matInput
                    [value]="element.netAmount"
                    autocomplete="off"
                    formControlName="netAmount"
                    maxlength="10"
                    (change)="qtyChanges(i)"
                    required
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="vatrate" *ngIf="isVatRegistered">
              <th mat-header-cell *matHeaderCellDef>
                VAT Rate <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field appearance="fill" class="w-150">
                  <mat-select
                    [disableOptionCentering]="true"
                    formControlName="vatRate"
                    dropdownPosition="top"
                    panelClass="mat-select-position"
                    (selectionChange)="qtyChanges(i)"
                  >
                    <mat-option
                      *ngFor="let option of vatRateList"
                      [value]="option.id"
                    >
                      <div class="clamp" title="{{ option.name }}">
                        {{ option.name }}
                      </div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="vatammount" *ngIf="isVatRegistered">
              <th mat-header-cell *matHeaderCellDef>VAT Amount</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never" class="w-120">
                  <input
                    allow_14_2_Decimal
                    matInput
                    [value]="element.vatAmount"
                    autocomplete="off"
                    formControlName="vatAmount"
                    (change)="vatAmountChanges(i)"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>
                Total Amount <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never" class="w-120">
                  <input
                    allow_14_2_Decimal
                    matInput
                    [value]="element.amount"
                    autocomplete="off"
                    formControlName="amount"
                    (change)="qtyChanges(i)"
                    required
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="paymentDate">
              <th mat-header-cell *matHeaderCellDef>
                Payment Date <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field appearance="standard" class="w-120">
                  <input
                    matInput
                    formControlName="paymentDate"
                    [matDatepicker]="startDatePicker"
                    autocomplete="off"
                    (dateChange)="onPaymentDateEntered(i)"
                    (change)="commonService.validateDate($event)"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="startDatePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>
                <mat-error
                  *ngIf="
                    commonService.checkDateValidation(
                      productDetailArray.controls[i].get('paymentDate')
                    ).invalidFinancialYear
                  "
                >
                  {{ notificationMessage.validPeriodDateMessage }}
                </mat-error>

                <mat-error
                  *ngIf="
                    commonService.checkDateValidation(
                      productDetailArray.controls[i].get('paymentDate')
                    ).lockedFinancialYear
                  "
                >
                  {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
                </mat-error>
              </td>
            </ng-container>
            <ng-container matColumnDef="paymentAccount">
              <th mat-header-cell *matHeaderCellDef>Payment Account</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="widthemail"
              >
                <mat-form-field appearance="fill" class="w-120">
                  <mat-select
                    #accountId
                    [disableOptionCentering]="true"
                    (focus)="accountId.open()"
                    (valueChange)="accountId.close()"
                    formControlName="paymentAccountId"
                    panelClass="mat-select-position"
                  >
                    <mat-option
                      *ngFor="let option of paymentAccountList"
                      [value]="option.id"
                    >
                      <div title="{{ option.name }}">{{ option.name }}</div>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="closeButton">
              <th mat-header-cell *matHeaderCellDef class="w-15"></th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="text-align-center"
              >
                <button
                  class="close-btn"
                  type="button"
                  mat-button
                  disableRipple
                  (click)="onDeleteProductDetails(i)"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayProductDetailsColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayProductDetailsColumns"
            ></tr>
          </table>
        </ng-container>
      </form>
    </div>
    <div class="mb-1 mt-1 d-flex justify-space-between">
      <div>
        <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
          >Add New Line</a
        >
        <a
          class="link mr-1"
          *ngIf="productDetailArray?.length > 0"
          href="javascript:void(0)"
          (click)="clearForm()"
          >Clear All Lines</a
        >
      </div>
    </div>
    <div class="mt-1">
      <p class="fw-bold mr-1 mt-0 text-align-right">
        Total Receipt Amount
        <span class="fw-normal ml-1"
          >£ {{ totalReceiptValue | numberPipe }}</span
        >
      </p>
      <p class="fw-bold mr-1 text-align-right">
        Total Payment Amount
        <span class="fw-normal ml-1"
          >£ {{ totalPaymentValue | numberPipe }}</span
        >
      </p>
    </div>
  </div>
</div>
