import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-opening-balance-footer',
  templateUrl: './opening-balance-footer.component.html',
  styleUrls: ['./opening-balance-footer.component.scss'],
})
export class OpeningBalanceFooterComponent {
  @Input()
  totalCredit: number;

  @Input()
  totalDebit: number;
}
