import { CreditNoteModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class CreateCreditNote {
  static readonly type = '[CREDITNOTE] Create Credit Note';

  constructor(public creditNoteData: CreditNoteModel) {}
}

export class GetDataByCreditNoteId {
  static readonly type = '[CREDITNOTE] Get Credit Note Data By Credit Note Id';

  constructor(public creditNoteId: Guid) {}
}

export class ArchiveAndRestoreCreditNote {
  static readonly type =
    '[CREDITNOTE] Archive and Restore Selected Credit Note';

  constructor(public creditNoteIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteCreditNote {
  static readonly type = '[CREDITNOTE] Delete Selected Credit Note';

  constructor(public creditNoteIds: Array<Guid>) {}
}

export class GetCreditNoteNumber {
  static readonly type = '[CREDITNOTE] Get Credit Note Number';
}
