<div class="mat-dialog-wrapper" style="width: 600px">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">Select Time Period</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onNoClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content
    ><div class="financial-year">
      <div class="sidenav-table sidenav-table-input">
        <div class="mat-table-wrapper product-detail-table">
          <form [formGroup]="formFinancialYearDetail">
            <ng-container formArrayName="financialYearArray">
              <table
                aria-describedby="product-list"
                mat-table
                [dataSource]="tableDataSource"
              >
                <ng-container matColumnDef="fromDate">
                  <th mat-header-cell *matHeaderCellDef>
                    From Date <span class="text-danger-300">*</span>
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <mat-form-field appearance="standard">
                      <input
                        matInput
                        formControlName="fromDate"
                        [matDatepicker]="fromDatePicker"
                        autocomplete="off"
                        (dateChange)="dateChange(true, i)"
                        (change)="commonService.validateDate($event)"
                        required
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="fromDatePicker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #fromDatePicker></mat-datepicker>
                    </mat-form-field>
                  </td>
                </ng-container>
                <ng-container matColumnDef="toDate">
                  <th mat-header-cell *matHeaderCellDef>
                    To Date <span class="text-danger-300">*</span>
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <mat-form-field appearance="standard">
                      <input
                        matInput
                        formControlName="toDate"
                        [matDatepicker]="toDatePicker"
                        autocomplete="off"
                        (dateChange)="dateChange(false, i)"
                        (change)="commonService.validateDate($event)"
                        [min]="
                          financialYearArray.controls[i].get('fromDate').value
                        "
                        required
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="toDatePicker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #toDatePicker></mat-datepicker>
                    </mat-form-field>
                  </td>
                </ng-container>

                <ng-container matColumnDef="deleteButton">
                  <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                    class="text-align-center"
                  >
                    <button
                      class="close-btn"
                      type="button"
                      mat-button
                      disableRipple
                      (click)="onDeleteProductDetails(i, element)"
                    >
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayColumns; sticky: true"
                ></tr>
                <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
              </table>
            </ng-container>
          </form>
        </div>
        <div
          class="mb-1 mt-1 d-flex justify-space-between"
          *ngIf="financialYearArray.length < 5"
        >
          <div>
            <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
              >Add new period</a
            >
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="action-button primary-button"
      mat-dialog-close
      type="button"
      mat-button
      disableRipple
      (click)="onGenerateButtonClick()"
    >
      Generate
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
