<div class="content">
  <div class="content-body content-body-scroll">
    <div class="d-flex justify-space-between align-items-start">
      <div class="mb-15 d-flex flex-column gap-5">
        <p class="fw-bold m-0">Select Bank:</p>
        <ng-select
          class="w-200"
          #accountList
          (focus)="accountList.open()"
          (valueChange)="accountList.close()"
          [disableOptionCentering]="true"
          [(ngModel)]="selectedBank"
          required
          panelClass="mat-select-position"
          (change)="getUnExplainedBankTransactions()"
        >
          <ng-option
            *ngFor="let option of receiptAccountList"
            [value]="option.id"
          >
            <div title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <button
        mat-button
        class="header-text-close mt-5"
        disableRipple
        (click)="onCloseClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ejs-treegrid
      #treegrid
      [dataSource]="data"
      [treeColumnIndex]="0"
      childMapping="bankMatchingTransactions"
    >
      <e-columns>
        <e-column headerText="" width="25">
          <ng-template #headerTemplate>
            <mat-icon *ngIf="isShowCollapse" (click)="expandAll()" matSuffix
              >keyboard_arrow_right</mat-icon
            >
            <mat-icon *ngIf="!isShowCollapse" (click)="collapseAll()" matSuffix
              >keyboard_arrow_down</mat-icon
            >
          </ng-template>
        </e-column>
        <e-column
          field="entryDate"
          headerText="Date"
          textAlign="Left"
          width="90"
        >
          <ng-template #template let-row let-rowIndex>
            <div *ngIf="!hasChildren(row)" class="display-inline">
              <mat-checkbox
                type="checkbox"
                [checked]="row['isSelected']"
                [disabled]="row['isEnable'] && !row['isSelected']"
                (change)="radioButtonChange(row)"
              ></mat-checkbox>
            </div>
            {{ row["entryDate"] | date: "dd-MMM-yyyy" }}
          </ng-template>
        </e-column>
        <e-column
          field="refNo"
          headerText="Reference No."
          textAlign="Left"
          width="90"
        >
          <ng-template #template let-row let-rowIndex>
            <div title="{{ row['refNo'] }}" [ngClass]="'fw-bold'">
              <a class="link fw-bold wmx-100 clamp" (click)="redirect(row)">
                {{ row["refNo"] }}
              </a>
            </div>
          </ng-template>
        </e-column>
        <e-column
          field="description"
          headerText="Description"
          textAlign="Left"
          width="180"
        >
          <ng-template #template let-row let-rowIndex>
            <span *ngIf="hasChildren(row)">
              {{ row["reference"] }}
            </span>
            <span *ngIf="!hasChildren(row)">
              {{ row["notes"] }}
            </span>
          </ng-template>
        </e-column>
        <e-column
          field="reciepts"
          headerText="Paid In"
          textAlign="Left"
          format="yMd"
          width="90"
        >
          <ng-template #template let-row let-rowIndex>
            <span *ngIf="hasChildren(row)">
              £ {{ row["receipts"] | numberPipe }}
            </span>
            <span *ngIf="!hasChildren(row)">
              £ {{ row["reciepts"] | numberPipe }}
            </span>
          </ng-template>
        </e-column>
        <e-column
          field="payments"
          headerText="Paid Out"
          textAlign="Left"
          width="80"
        >
          <ng-template #template let-row let-rowIndex>
            <span title="£ {{ row['payments'] | numberPipe }}">
              £ {{ row["payments"] | numberPipe }}</span
            >
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-treegrid>
    <app-buttons
      *ngIf="listofSelectedids.length"
      [getModuleId]="modules.AutoBankReconcilation"
      (triggerOnSaveClick)="onSave($event)"
      (triggerOnCancelClick)="onCancel(true)"
    >
    </app-buttons>
  </div>
</div>
