<div class="mat-dialog-wrapper match-journal">
  <h2 mat-dialog-title>Flat Rate Adjustment Journal</h2>
  <button
    class="close-button"
    type="button"
    (click)="onCloseClick()"
    mat-button
    disableRipple
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    <div class="content-body p-0">
      <form [formGroup]="flatRateForm">
        <div class="form-field datepicker">
          <p>Date</p>
          <mat-form-field appearance="standard">
            <input
              matInput
              formControlName="date"
              [matDatepicker]="datePicker"
              autocomplete="off"
              (change)="commonService.validateDate($event)"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="datePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
          </mat-form-field>
          <mat-error
            *ngIf="
              commonService.checkDateValidation(flatRateForm.get('date'))
                .invalidFinancialYear
            "
          >
            {{ notificationMessage.validPeriodDateMessage }}
          </mat-error>

          <mat-error
            *ngIf="
              commonService.checkDateValidation(flatRateForm.get('date'))
                .lockedFinancialYear
            "
          >
            {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
          </mat-error>
        </div>

        <div class="form-field">
          <p>Flat Rate Savings</p>
          <mat-form-field class="amt-inputbox">
            <input
              currencyMask
              allow_10_2_Decimal
              matInput
              autocomplete="off"
              formControlName="savingsAmount"
              [disabled]="true"
            />
            <button mat-button type="button" disableRipple matSuffix>
              <span> £ </span>
            </button>
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>VAT on Purchases</p>
          <mat-form-field class="amt-inputbox">
            <input
              currencyMask
              allow_10_2_Decimal
              matInput
              autocomplete="off"
              formControlName="purchaseAmount"
              [disabled]="true"
            />
            <button mat-button type="button" disableRipple matSuffix>
              <span> £ </span>
            </button>
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Final Adjustment Amount</p>
          <mat-form-field class="amt-inputbox">
            <input
              currencyMask
              allow_10_2_Decimal
              matInput
              autocomplete="off"
              formControlName="adjustmentAmount"
              [disabled]="true"
            />
            <button mat-button type="button" disableRipple matSuffix>
              <span> £ </span>
            </button>
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Account <span class="text-danger-300">*</span></p>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              value=" VAT Flat Rate Adjustment"
              [disabled]="true"
            />
          </mat-form-field>
        </div>
        <div class="form-field textarea">
          <p>Reference</p>
          <mat-form-field>
            <textarea
              matInput
              formControlName="reference"
              autocomplete="off"
            ></textarea>
          </mat-form-field>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="hr pt-1 mt-0 justify-content-end">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave()"
    >
      Save
    </button>
    <button
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onCloseClick()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
