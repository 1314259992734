import { Location } from '@angular/common';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ConfirmationType,
  ModuleName,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { AccountProvider } from '@app/core/Enum/account-provider';
import { CompanyEmailSetting, GlobalComponent } from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  DefaultMail,
  GetCompanyEmailSettingsList,
  GetCompanyMailPreference,
  GetUserMailList,
  IntegrateCapiumPay,
  MenuState,
  PrepareGoogleOrOffice,
  SavecompanyEmailSetting,
  UpdateEmailConfig,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { EmailIntegrationsComponent } from '..';
import { ConfirmationBoxComponent } from '../common';
import { CapiumpayDialogComponent } from '../common/capiumpay-dialog/capiumpay-dialog.component';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
})
export class IntegrationsComponent implements OnInit {
  emailIntegrationForm: FormGroup;
  accountProvider = AccountProvider;

  isExpandAll = false;
  showForm = true;
  showGmailAccounts = false;
  showOutlookAccounts = false;
  isRadioChecked = false;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  userMailList: any;
  userOutlookList: any;

  moduleId = Modules.Integration;
  companyEmailSettingData: Array<CompanyEmailSetting>;
  displayGmailColumns: string[] = ['EmailId', 'Date', 'Default', 'Action'];
  displayStripColumns: string[] = ['EmailId', 'Date', 'Default', 'Action'];

  @ViewChild(EmailIntegrationsComponent, { static: true })
  emailSettingDetails;

  triggerEditData: Subject<any> = new Subject<any>();
  commonService: CommonService;
  store: Store;
  spinner: NgxSpinnerService;
  showCapiumPayAccounts = false;
  capiumPayList: any;
  companyId: any;
  cookieService: CookieService;
  showMailOption: any;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private _Activatedroute: ActivatedRoute,
    private notifier: NotificationService,
    private dialog: MatDialog,
    private location: Location,
    private injector: Injector,
    private globalComponent: GlobalComponent
  ) {
    this.commonService = injector.get<CommonService>(CommonService);
    this.store = injector.get<Store>(Store);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.cookieService = injector.get<CookieService>(CookieService);
  }

  ngOnInit(): void {
    this._Activatedroute.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        params['flag'] === '1'
          ? this.notifier.success(NotificationHeader.success, params['message'])
          : this.notifier.error(NotificationHeader.error, params['message']);
      }
    });

    this.setForm();
    this.getCompanyMailPreference();
    this.getCompanyEmailSettingsList();
  }

  getCompanyMailPreference(): void {
    this.store
      .dispatch(new GetCompanyMailPreference())
      .pipe()
      .subscribe((res) => {
        if (
          res.integration.integrationData !== null ||
          res.integration.integrationData !== undefined
        ) {
          this.emailIntegrationForm = this.formBuilder.group({
            isEmailSendFromCapium: [
              res.integration.integrationData ? 'true' : 'false',
            ],
          });
          this.showForm = res.integration.integrationData;

          this.getMailList();
        }
      });
  }

  getCompanyEmailSettingsList(): void {
    this.store
      .dispatch(new GetCompanyEmailSettingsList())
      .pipe()
      .subscribe((res) => {
        this.triggerEditData.next(res.integration.emailSettingData);
      });
  }

  getMailList(): void {
    if (!this.showForm) {
      //Get Mail List by default when page load
      this.getAllMail(AccountProvider.Google); // Get all Gmail
      this.getAllMail(AccountProvider.Office); // Get all Outlook
    }
  }

  setForm(): void {
    this.emailIntegrationForm = this.formBuilder.group({
      isEmailSendFromCapium: new FormControl('true'),
    });
  }

  getAllMail(providerId): void {
    this.store
      .dispatch(new GetUserMailList(providerId))
      .subscribe((res: any) => {
        if (providerId === AccountProvider.Google) {
          this.userMailList = res.integration.integrationData;
        }
        if (providerId === AccountProvider.Office) {
          this.userOutlookList = res.integration.integrationData;
        }
        if (providerId === AccountProvider.CapiumPay) {
          this.capiumPayList = res.integration.integrationData;
          if (this.capiumPayList === null || this.capiumPayList.length === 0) {
            this.showMailOption = 'false';
          } else {
            this.showMailOption = 'true';
          }
          this.cookieService.set('showPaymentMailOption', this.showMailOption);
        }
      });
  }

  onGmailDefaultChange(element): void {
    this.store.dispatch(new DefaultMail(element)).subscribe((res: any) => {
      if (res.integration.isDefault) {
        this.userMailList.forEach((data) => {
          data.isDefault = data.emailAddress === element.emailAddress;
        });
      }
    });
  }

  onOutlookDefaultChange(element): void {
    this.store.dispatch(new DefaultMail(element)).subscribe((res: any) => {
      if (res.integration.isDefault) {
        this.userOutlookList.forEach((data) => {
          data.isDefault = data.emailAddress === element.emailAddress;
        });
      }
    });
  }

  removeAccount(id): void {
    this.dialog
      .open(ConfirmationBoxComponent, {
        data: {
          id: id,
          type: ConfirmationType.Delete,
          moduleId: Modules.Integration,
          headerText: NotificationHeader.deleteYourAccount,
          detailText: NotificationDetails.deleteYourAccountText,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.integration.isDelete) {
          this.notifier.success(
            NotificationHeader.success,
            NotificationTextMessage.mailDeleteSucessfully
          );
        }
        this.getMailList();
      });
  }

  redirectToAccount(providerId): void {
    this.dialog.open(ConfirmationBoxComponent, {
      data: {
        id: [providerId],
        type: ConfirmationType.Mail,
        moduleId: Modules.Integration,
        headerText:
          providerId === AccountProvider.Google
            ? NotificationHeader.navigateMeToGmail
            : NotificationHeader.navigateMeToOutlook,
        detailText:
          providerId === AccountProvider.Google
            ? NotificationDetails.navigateMeToGmailText
            : NotificationDetails.navigateMeToOutlookText,
      },
    });
  }

  integrateGmail(type): void {
    let baseUrl = window.location.origin.toString();
    let provider = type === AccountProvider.Google ? 'google' : 'office';
    let payload = {
      ProviderId: AccountProvider.Google,
      configurationJson: '',
      emailAddress: '',
      returnUrl: baseUrl + '/manage/integrations',
      ClientId: '1',
      UserId: '1',
      ProviderType: provider,
    };

    this.store
      .dispatch(new PrepareGoogleOrOffice(payload))
      .subscribe((res: any) => {
        window.open(res.integration.integrationData, '_self');
      });
  }

  showGmailAccount() {
    this.showGmailAccounts = !this.showGmailAccounts;
  }

  showOutlookAccount() {
    this.showOutlookAccounts = !this.showOutlookAccounts;
  }

  onChange(showSection): void {
    this.isRadioChecked = true;
    this.showForm = showSection;
    this.getMailList();
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onSave(isExit: boolean): void {
    if (this.isRadioChecked) {
      this.store
        .dispatch(
          new UpdateEmailConfig(
            this.emailIntegrationForm.controls.isEmailSendFromCapium.value
          )
        )
        .pipe()
        .subscribe(
          (res) => {
            if (res) {
              if (!isExit) {
                this.onCancel();
              } else {
                this.onCancel();
                this.location.back();
              }
              this.commonService.isInitialValueChange = false;
              this.notifier.success(
                NotificationHeader.success,
                NotificationTextMessage.successMessage
              );
            } else {
              this.notifier.error(
                NotificationHeader.error,
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    } else if (this.emailSettingDetails.isCheckboxChecked) {
      this.emailSettingSave(isExit);
    }
  }

  emailSettingSave(isExit: boolean): void {
    if (this.dataSubmit()) {
      this.store
        .dispatch(new SavecompanyEmailSetting(this.companyEmailSettingData))
        .pipe()
        .subscribe(
          (res) => {
            if (res !== undefined) {
              if (!isExit) {
                this.onCancel();
              } else {
                this.onCancel();
                this.location.back();
              }
              this.commonService.isInitialValueChange = false;
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
              this.getCompanyEmailSettingsList();
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    }
  }

  dataSubmit(): boolean {
    this.companyEmailSettingData = new Array<CompanyEmailSetting>();

    try {
      this.emailSettingDetails.emailList.forEach((x) => {
        this.companyEmailSettingData.push({
          id: x.id,
          moduleId: x.moduleId,
          isActive: x.isActive,
        });
      });
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(): void {
    this.emailSettingDetails.ngOnInit();
    this.ngOnInit();
  }

  capiumPayIntegration() {
    this.getAllMail(5);
    this.showCapiumPayAccounts = !this.showCapiumPayAccounts;
    if (this.capiumPayList?.length === 0) {
      this.showCapiumPayAccounts = false;
      const dialogRef = this.dialog.open(CapiumpayDialogComponent);

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.integrationCapiumPay();
        }
      });
    }
  }
  integrationCapiumPay(): void {
    let redirectUrl =
      window.location.origin.toString() + '/manage/integrations';
    let provider = 'capiumPay';
    let configType = '';
    this.companyId = this.globalComponent.getCompanyId();
    let payload = {
      ProviderId: '1',
      configurationJson: configType,
      emailAddress: '',
      redirectUrl: redirectUrl,
      ProviderType: provider,
      companyId: this.companyId,
    };

    this.store
      .dispatch(new IntegrateCapiumPay(payload))
      .pipe()
      .subscribe((res) => {
        if (res) {
          window.open(res?.integration?.integrationData, '_blank');
        }
      });
  }
  integrateCapiumPay(): void {
    this.dialog.open(ConfirmationBoxComponent, {
      data: {
        id: [5],
        type: ConfirmationType.Mail,
        moduleName: ModuleName.CapiumPayIntegration,
        headerText: NotificationHeader.navigateMeToCapiumPay,
        detailText: NotificationDetails.navigateMeToCapiumPayText,
      },
    });
  }
}
