<div class="content-body content-body-scroll">
  <div class="chart-wrapper d-flex gap-20 mb-2">
    <div
      class="card"
      *ngIf="
        this.moduleId === module.IncomeOverview ||
        this.moduleId === module.ExpenseOverview ||
        this.moduleId === module.FixedAssetOverview
      "
    >
      <div class="d-flex align-items-center justify-space-between">
        <h3 class="card-title">
          {{
            (moduleId$ | async) === module.IncomeOverview
              ? moduleName.Sales
              : (moduleId$ | async) === module.ExpenseOverview
              ? moduleName.Expenditure
              : moduleName.FixedAssets
          }}
        </h3>
        <div class="content-header">
          <div class="year-picker">
            <div class="d-flex year-picker-content">
              <div>
                <mat-form-field appearance="fill" class="w-150">
                  <mat-select
                    placeholder="This Financial Year"
                    panelClass="myPanelClass"
                    [disableOptionCentering]="true"
                    [(ngModel)]="filter1"
                    (selectionChange)="onSelectionChange($event.value)"
                  >
                    <mat-option
                      *ngFor="let year of selectYear"
                      [value]="year.id"
                    >
                      {{ year.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <form
                *ngIf="showCalender && filter1 === 7"
                [formGroup]="rangeFormGroup"
              >
                <mat-form-field appearance="standard">
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      matInput
                      #start
                      (dateChange)="onSelectionChange(7)"
                      formControlName="start"
                    />
                    <input
                      matEndDate
                      matInput
                      #end
                      formControlName="end"
                      (dateChange)="onSelectionChange(7)"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matPrefix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
      <app-overview-incomes-chart
        [triggerOverviewData]="triggerOverviewData"
      ></app-overview-incomes-chart>
    </div>
    <div
      class="card"
      *ngIf="
        this.moduleId === module.IncomeOverview ||
        this.moduleId === module.ExpenseOverview
      "
    >
      <div class="d-flex align-items-center justify-space-between">
        <h3 class="card-title">Receivable Ageing Summary</h3>
        <div class="content-header">
          <div class="year-picker">
            <div class="d-flex year-picker-content">
              <div>
                <mat-form-field appearance="fill" class="w-150">
                  <mat-select
                    [(ngModel)]="filter2"
                    placeholder="This Financial Year"
                    panelClass="myPanelClass"
                    [disableOptionCentering]="true"
                    (selectionChange)="onSelectionChange($event.value)"
                  >
                    <mat-option
                      *ngFor="let year of selectYear"
                      [value]="year.id"
                    >
                      {{ year.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <form
                *ngIf="showCalender && filter1 === 7"
                [formGroup]="rangeFormGroup"
              >
                <mat-form-field appearance="standard">
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      matInput
                      #start
                      (dateChange)="onSelectionChange(7)"
                      formControlName="start"
                    />
                    <input
                      matEndDate
                      matInput
                      #end
                      formControlName="end"
                      (dateChange)="onSelectionChange(7)"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matPrefix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
      <app-overview-summary-chart
        [triggerOverviewData]="triggerOverviewData"
      ></app-overview-summary-chart>
    </div>
    <div class="card" *ngIf="this.moduleId === module.FixedAssetOverview">
      <div class="d-flex align-items-center justify-space-between">
        <h3 class="card-title">Product/Service</h3>
        <div>
          <mat-form-field appearance="fill" class="w-150">
            <mat-select
              placeholder="This Financial Year"
              panelClass="myPanelClass"
              [disableOptionCentering]="true"
              [(ngModel)]="filter2"
              (selectionChange)="onSelectionChange($event.value)"
            >
              <mat-option *ngFor="let year of selectYear" [value]="year.id">
                {{ year.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <app-overview-incomes-chart
        [triggerData]="triggerData"
      ></app-overview-incomes-chart>
    </div>
  </div>

  <div
    class="chart-wrapper d-flex gap-20 mb-2"
    *ngIf="
      this.moduleId === module.IncomeOverview ||
      this.moduleId === module.ExpenseOverview ||
      this.moduleId === module.FixedAssetOverview
    "
  >
    <div class="card">
      <div class="d-flex align-items-center justify-space-between">
        <h3 class="card-title">
          {{
            (moduleId$ | async) === module.IncomeOverview
              ? moduleName.Sales
              : (moduleId$ | async) === module.ExpenseOverview
              ? moduleName.Expenditure
              : moduleName.FixedAssets
          }}
          Flow
        </h3>
        <div class="content-header">
          <div class="year-picker">
            <div class="d-flex year-picker-content">
              <form
                *ngIf="showCalender && filter1 === 7"
                [formGroup]="rangeFormGroup"
              >
                <mat-form-field appearance="standard">
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      matInput
                      #start
                      (dateChange)="onSelectionChange(7)"
                      formControlName="start"
                    />
                    <input
                      matEndDate
                      matInput
                      #end
                      formControlName="end"
                      (dateChange)="onSelectionChange(7)"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matPrefix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
        <div class="content-header">
          <div class="year-picker">
            <div class="d-flex year-picker-content">
              <div>
                <mat-form-field appearance="fill" class="w-150">
                  <mat-select
                    [(ngModel)]="filter3"
                    placeholder="This Financial Year"
                    panelClass="myPanelClass"
                    [disableOptionCentering]="true"
                    (selectionChange)="onSelectionChange($event.value)"
                  >
                    <mat-option
                      *ngFor="let year of selectYear"
                      [value]="year.id"
                    >
                      {{ year.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <form
                *ngIf="showCalender && filter3 === 7"
                [formGroup]="rangeFormGroup"
              >
                <mat-form-field appearance="standard">
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      matInput
                      #start
                      (dateChange)="onSelectionChange(7)"
                      formControlName="start"
                    />
                    <input
                      matEndDate
                      matInput
                      #end
                      formControlName="end"
                      (dateChange)="onSelectionChange(7)"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matPrefix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
      <app-overview-flow-chart></app-overview-flow-chart>
    </div>
  </div>
</div>
