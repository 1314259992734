import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { APP_BASE_HREF } from '@angular/common';
import { AuthGuard } from './core/Guards/auth.guard';
import { AuthResolverService } from './core/Guards/auth.resolver';
import {
  ActivityComponent,
  AddBankAccountComponent,
  AddContractorSettingsComponent,
  AddUserComponent,
  AddVatCodesComponent,
  AnnualReportComponent,
  AutoBankReconcillationComponent,
  BalanceSheetComponent,
  BankFeedComponent,
  BankImportComponent,
  BankOverviewComponent,
  BankTransferComponent,
  BillComponent,
  BridgingVATComponent,
  BudgetingComponent,
  BulkEditComponent,
  BusinessSnapshotComponent,
  CashCodingComponent,
  CashFlowComponent,
  CisInvoiceComponent,
  CISInvoiceComponent,
  CisSubmissionComponent,
  CompanyPreferenceComponent,
  ComparitiveReportComponent,
  CreditNoteComponent,
  CurrencyComponent,
  CustomAccountsComponent,
  CustomerComponent,
  CustomizeReportComponent,
  DashboardComponentv1,
  DebitNoteComponent,
  DepreciationExpandedCollapseComponent,
  DetailListComponent,
  DirectorShareholderComponent,
  DividendsComponent,
  EmailTemplatesComponent,
  ExpenditureOverviewComponent,
  FixedAssestDisposeActiveComponent,
  FixedAssetsComponent,
  FixedAssetsOverviewComponent,
  ImportComponent,
  IncludeDataComponent,
  IncomeInvoiceComponent,
  IncomeOverviewV1Component,
  IntegrationsComponent,
  InventoryComponent,
  JournalsComponent,
  ManualImportComponent,
  MinutesOfMeetingsComponent,
  MonthlyReturnComponent,
  NewCompanyComponent,
  NominalLedgerComponent,
  OpeningBalanceComponent,
  ProductComponent,
  ProfitLossComparisonComponent,
  ProfitLossComponent,
  QuickEntryComponent,
  QuotationComponent,
  ReceiptComponent,
  RecurringModuleComponent,
  ReportCreditorDebtorComponent,
  ReportDashboardComponent,
  SchedulesNotesComponent,
  SubcontractorComponent,
  SubcontractorYtdDetailsComponent,
  SubmitVatComponent,
  SubmitVatListComponent,
  SupplierComponent,
  TicketComponent,
  VatSettingsComponent,
  ViewVatDetailComponent,
} from './modules';
import { AccessDeniedComponent } from './modules/access-denied/access-denied.component';
import { DynamicGridListComponent } from './modules/dynamic-grid-list/dynamic-grid-list.component';
import { GeneralDashboardComponent } from './modules/general-dashboard/general-dashboard.component';
import { AddClientComponent } from './modules/general-manage/clients/add-client/add-client.component';
import { ClientsListComponent } from './modules/general-manage/clients/clients-list/clients-list.component';
import { ExportSettingsComponent } from './modules/general-manage/export-settings/export-settings.component';
import { ImportSettingsComponent } from './modules/general-manage/import/import-settings/import-settings.component';
import { IntegrationSettingsComponent } from './modules/general-manage/integration-settings/integration-settings.component';
import { PermissionListComponent } from './modules/general-manage/permission-list/permission-list.component';

import { UsersListComponent } from './modules/general-manage/users/users-list/users-list.component';
import { InvoiceTemplatesComponent } from './modules/manage/invoice-templates/invoice-templates.component';
import { AccountSettingsComponent } from './modules/settings/account-settings/account-settings.component';
import { CapiscanSettingsComponent } from './modules/settings/capiscan-settings/capiscan-settings.component';
import { DefaultSettingsComponent } from './modules/settings/default-settings/default-settings.component';
import { ReportSettingsComponent } from './modules/settings/report-settings/report-settings.component';
import { VatTransactionDetailsComponent } from './modules/mtd/vat-transaction-details/vat-transaction-details.component';
import { DataSecurityComponent } from './modules/manage/data-security/data-security.component';

const routes: Routes = [
  { path: '', redirectTo: '/general-dashboard', pathMatch: 'full' },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: 'general-dashboard',
    pathMatch: 'full',
    component: GeneralDashboardComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    pathMatch: 'full',
    component: DashboardComponentv1,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'dashboard-v1',
    pathMatch: 'full',
    component: DashboardComponentv1,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'ticket',
    component: TicketComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'default-settings',
    component: DefaultSettingsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'account-settings',
    component: AccountSettingsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'capiscan-settings',
    component: CapiscanSettingsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'accounts/standard',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank/transfer',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank/transfer/add',
    pathMatch: 'full',
    component: BankTransferComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank/autoBankReconcillation',
    pathMatch: 'full',
    component: AutoBankReconcillationComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'accounts/custom',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'accounts/custom/add',
    pathMatch: 'full',
    component: CustomAccountsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'addCompany',
    pathMatch: 'full',
    component: NewCompanyComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'editClient',
    pathMatch: 'full',
    component: NewCompanyComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'manage/dataSecurity',
    pathMatch: 'full',
    component: DataSecurityComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'manage/invoiceTemplates',
    pathMatch: 'full',
    component: InvoiceTemplatesComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'manage/clientpreference',
    pathMatch: 'full',
    component: CompanyPreferenceComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'manage/openingBalance',
    pathMatch: 'full',
    component: OpeningBalanceComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'manage/trialBalance',
    pathMatch: 'full',
    component: OpeningBalanceComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'manage/import',
    pathMatch: 'full',
    component: ImportComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'manage/integrations',
    pathMatch: 'full',
    component: IntegrationsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'manage/templates',
    pathMatch: 'full',
    component: EmailTemplatesComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'manage/currency',
    pathMatch: 'full',
    component: CurrencyComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'manage/currency/add',
    pathMatch: 'full',
    component: AddUserComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'general-manage/clients',
    pathMatch: 'full',
    component: ClientsListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'general-manage/clients/add',
    pathMatch: 'full',
    component: AddClientComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'general-manage/users',
    pathMatch: 'full',
    component: UsersListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'general-manage/users/add',
    pathMatch: 'full',
    component: AddUserComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'general-manage/import',
    pathMatch: 'full',
    component: ImportSettingsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'general-manage/export',
    pathMatch: 'full',
    component: ExportSettingsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'general-manage/integrations',
    pathMatch: 'full',
    component: IntegrationSettingsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'general-manage/permission',
    pathMatch: 'full',
    component: PermissionListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'general-manage/report-settings',
    pathMatch: 'full',
    component: ReportSettingsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'activity',
    pathMatch: 'full',
    component: ActivityComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'contacts/customers',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'contacts/customers/add',
    pathMatch: 'full',
    component: CustomerComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'contacts/suppliers',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'contacts/suppliers/add',
    pathMatch: 'full',
    component: SupplierComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'contacts/director',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'contacts/director/add',
    pathMatch: 'full',
    component: DirectorShareholderComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'contacts/shareholder',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'contacts/shareholder/add',
    pathMatch: 'full',
    component: DirectorShareholderComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'sales/overview',
    pathMatch: 'full',
    component: IncomeOverviewV1Component,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'sales/invoices/add',
    pathMatch: 'full',
    component: IncomeInvoiceComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'sales/recurringInvoice',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'sales/recurringInvoice/add',
    pathMatch: 'full',
    component: RecurringModuleComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'sales/quotation',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'sales/quotation/add',
    pathMatch: 'full',
    component: QuotationComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'sales/creditNote',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'sales/creditNote/add',
    pathMatch: 'full',
    component: CreditNoteComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'sales/receipt',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'sales/receipt/add',
    pathMatch: 'full',
    component: ReceiptComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'sales/product',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'sales/product/add',
    pathMatch: 'full',
    component: ProductComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'contacts/customers',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'contacts/customers/add',
    pathMatch: 'full',
    component: CustomerComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'contacts/suppliers',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'contacts/suppliers/add',
    pathMatch: 'full',
    component: SupplierComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'Purchase/overview',
    pathMatch: 'full',
    component: ExpenditureOverviewComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'Purchase/purchase',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'Purchase/purchase/add',
    pathMatch: 'full',
    component: BillComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'Purchase/recurringPurchase',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'Purchase/recurringPurchase/add',
    pathMatch: 'full',
    component: RecurringModuleComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'Purchase/debitNote',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'Purchase/debitNote/add',
    pathMatch: 'full',
    component: DebitNoteComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'Purchase/payment',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'Purchase/payment/add',
    pathMatch: 'full',
    component: ReceiptComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'Purchase/cisInvoice',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'Purchase/cisInvoice/add',
    pathMatch: 'full',
    component: CISInvoiceComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'tasks/budgeting',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    data: { title: 'This is my title' },
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'tasks/budgeting/add',
    pathMatch: 'full',
    component: BudgetingComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'tasks/journals',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    data: { title: 'This is my title' },
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'tasks/dividends/add',
    pathMatch: 'full',
    component: DividendsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'tasks/dividends',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'tasks/journals/add',
    pathMatch: 'full',
    component: JournalsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'tasks/bulkEdit',
    pathMatch: 'full',
    component: BulkEditComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fixedAsset/overview',
    pathMatch: 'full',
    component: FixedAssetsOverviewComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fixedAsset/fixedAssets',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fixedAsset/fixedAssets/add',
    pathMatch: 'full',
    component: FixedAssetsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fixedAsset/fixedAssetsRegister',
    pathMatch: 'full',
    component: FixedAssestDisposeActiveComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'fixedAsset/fixedAssetsRegister/add',
    pathMatch: 'full',
    component: DepreciationExpandedCollapseComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'inventory',
    pathMatch: 'full',
    component: InventoryComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'quickEntry',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'quickEntry/add',
    pathMatch: 'full',
    component: QuickEntryComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'detailList',
    pathMatch: 'full',
    component: DetailListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank',
    pathMatch: 'full',
    component: AddBankAccountComponent,
    resolve: {
      result: AuthResolverService,
    },
  },

  {
    path: 'bankFeed',
    pathMatch: 'full',
    component: AddBankAccountComponent,
    resolve: {
      result: AuthResolverService,
    },
  },

  {
    path: 'sales/invoices',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bankTransaction',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bankImport',
    pathMatch: 'full',
    component: BankImportComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank/overview',
    pathMatch: 'full',
    component: BankOverviewComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank/cashCoding',
    pathMatch: 'full',
    component: CashCodingComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'bank/bankFeed',
    pathMatch: 'full',
    component: BankFeedComponent,
    resolve: {
      result: AuthResolverService,
    },
  },

  {
    path: 'bank/cashEntry',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank/cashEntry/add',
    pathMatch: 'full',
    component: ReceiptComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank/bankEntry',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'bank/bankEntry/add',
    pathMatch: 'full',
    component: ReceiptComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'reports/additional/customizedReport',
    pathMatch: 'full',
    component: CustomizeReportComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'report/accountDetails',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'reports/vat',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'reports',
    children: [
      {
        path: 'reportsdashboard',
        pathMatch: 'full',
        component: ReportDashboardComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },

      {
        path: 'additional',
        children: [
          {
            path: 'accountdetails',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'nominalledger',
            pathMatch: 'full',
            component: NominalLedgerComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'recenttransactions',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'businessSnapshot',
            pathMatch: 'full',
            component: BusinessSnapshotComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'balancesheetcomparison',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'accountlist',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'transactionlistbydate',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'balanceSheetDetail',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'profitandlossdetail',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'profitandlosssummary',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'profitandlosswithpercentage',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'profitandlosscomparision',
            pathMatch: 'full',
            component: ProfitLossComparisonComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'annualreport',
            children: [
              {
                path: 'companyoverview',
                pathMatch: 'full',
                component: AnnualReportComponent,
                resolve: {
                  result: AuthResolverService,
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'salesperformance',
                pathMatch: 'full',
                component: AnnualReportComponent,
                resolve: {
                  result: AuthResolverService,
                },
                canActivate: [AuthGuard],
              },
              {
                path: 'expensesperformance',
                pathMatch: 'full',
                component: AnnualReportComponent,
                resolve: {
                  result: AuthResolverService,
                },
                canActivate: [AuthGuard],
              },
            ],
          },
        ],
      },

      {
        path: 'management',
        children: [
          {
            path: 'profitandloss',
            pathMatch: 'full',
            component: ProfitLossComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'advanceAgeingList',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'accountPayableAgeing',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'balancesheet',
            pathMatch: 'full',
            component: BalanceSheetComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'trialbalance',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'comparativereport',
            pathMatch: 'full',
            component: ComparitiveReportComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'budgetreport',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'incomeAndExpenditure',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'summary',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'openinvoice',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'unpaidpurchases',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'quotationbycustomer',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'daybook',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'cashflow',
            pathMatch: 'full',
            component: CashFlowComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },
        ],
      },

      {
        path: 'customer',
        children: [
          {
            path: 'advancereport',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'salesreport',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'customerlist',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'customerageinglist',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'transactionsbycustomer',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'salesproductlist',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'customerreceipts',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'debtors',
            pathMatch: 'full',
            component: ReportCreditorDebtorComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'customerstatement',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'salesinvoicelist',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'invoiceandreceivedpayments',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'incomebycustomersummary',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'customerbalancesummary',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'salesbyproductdetail',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'salesbyproductsummary',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'depositdetails',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },
        ],
      },

      {
        path: 'supplier',
        children: [
          {
            path: 'advancereport',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'purchasesreport',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'supplierlist',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'supplierageinglist',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'transactionsbysupplier',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'paymenttosupplier',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'creditors',
            pathMatch: 'full',
            component: ReportCreditorDebtorComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'supplierstatement',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'purchasesinvoicelist',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'purchaseandappliedpayments',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'expensesbysuppliersummary',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'supplierbalancesummary',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },

          {
            path: 'chequedetail',
            pathMatch: 'full',
            component: DynamicGridListComponent,
            resolve: {
              result: AuthResolverService,
            },
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },

  {
    path: 'manualImport',
    pathMatch: 'full',
    component: ManualImportComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'schedules/notes',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'schedules/notes/add',
    pathMatch: 'full',
    component: SchedulesNotesComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'schedules/minutesofmeeting',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'schedules/minutesofmeeting/add',
    pathMatch: 'full',
    component: MinutesOfMeetingsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'mtd/viewVatDetails',
    pathMatch: 'full',
    component: ViewVatDetailComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'mtd/vatTransactionDetail',
    pathMatch: 'full',
    component: VatTransactionDetailsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'mtd/vatSettings',
    pathMatch: 'full',
    component: VatSettingsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'mtd/vatSettings/add',
    pathMatch: 'full',
    component: AddVatCodesComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'mtd/submitvat',
    pathMatch: 'full',
    component: SubmitVatListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'mtd/submitvat/add',
    pathMatch: 'full',
    component: SubmitVatComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'mtd/submitvat/incExcDetail',
    pathMatch: 'full',
    component: IncludeDataComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'mtd/bridgingvat',
    pathMatch: 'full',
    component: SubmitVatListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'mtd/bridgingvat/add',
    pathMatch: 'full',
    component: BridgingVATComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'cis/subcontractor',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'cis/subcontractor/add',
    pathMatch: 'full',
    component: SubcontractorComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'cis/contractorsettings',
    pathMatch: 'full',
    component: AddContractorSettingsComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'cis/cis300',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'cis/cis300/add',
    pathMatch: 'full',
    component: MonthlyReturnComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'month',
    pathMatch: 'full',
    component: MonthlyReturnComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'logs/email',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'logs/user',
    pathMatch: 'full',
    component: DynamicGridListComponent,
    resolve: {
      result: AuthResolverService,
    },
    canActivate: [AuthGuard],
  },

  {
    path: 'cis/reports',
    children: [
      {
        path: 'submission',
        pathMatch: 'full',
        component: CisSubmissionComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },

      {
        path: 'SubContractorYTDDetails',
        pathMatch: 'full',
        component: SubcontractorYtdDetailsComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },

      {
        path: 'CISInvoice',
        pathMatch: 'full',
        component: CisInvoiceComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
})
export class AppRoutingModule {}
