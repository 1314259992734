import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ActivityLogModel, AuthorisedMenuModel } from '@app/core/Models';
import { GetActivityList, GetAuthorizedMenus } from './activity.action';
import { ActivityService } from '@app/core/Services/activity/activity.service';

export class ActivityStateInfo {
  activities: Array<ActivityLogModel>;
  authorisedMenu?: Array<AuthorisedMenuModel>;
}

@State<ActivityStateInfo>({
  name: 'activity',
  defaults: {
    activities: [],
    authorisedMenu: [],
  },
})
@Injectable()
export class ActivityState {
  constructor(private activityService: ActivityService) {}

  @Action(GetActivityList)
  getActivityTasksList(
    { getState, setState }: StateContext<ActivityStateInfo>,
    action: GetActivityList
  ) {
    return this.activityService.getActivityList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();

        setState({
          ...state,
          activities: res,
        });
      })
    );
  }

  @Action(GetAuthorizedMenus)
  getAuthorizedMenus(
    { patchState }: StateContext<ActivityStateInfo>,
    action: GetAuthorizedMenus
  ) {
    return this.activityService.getAuthorizedMenus().pipe(
      tap((res) => {
        patchState({
          authorisedMenu: res,
        });
      })
    );
  }

}
