import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Modules,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { Account, BankFeeds } from '@app/core/Models';
import {
  BankDasboardService,
  CommonService,
  NotificationService,
} from '@app/core/Services';
import { CreateBankAccount } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-bank-account',
  templateUrl: './add-bank-account.component.html',
  styleUrls: ['./add-bank-account.component.scss'],
})
export class AddBankAccountComponent implements OnInit {
  subscriptionRouting: Subscription;
  bankAccountList: Array<Account>;

  stateId = Guid.EMPTY as unknown as Guid;
  selectedAccount: any;
  selectedIndex: number;
  selectedAccountIndex: number;

  moduleId = Modules.BankDashboard;

  bankAccountData: BankFeeds;

  constructor(
    public store: Store,
    private _Activatedroute: ActivatedRoute,
    private notifier: NotificationService,
    private bankDasboardService: BankDasboardService,
    public commonService: CommonService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.queryParams.subscribe(
      (params) => {
        if (params) {
          this.stateId = params['state'] ?? '0';
          this.getBankAccountTypeToAdd();
        }
      }
    );
  }

  getBankAccountTypeToAdd(): void {
    const param = {
      stateId: this.stateId,
    };

    this.bankDasboardService
      .getBankAccountTypeToAdd(param)
      .subscribe((res: any) => {
        this.bankAccountList = res.body;
      });
  }

  onAccountSelection(element: any, event, index): void {
    this.selectedAccount = element;
    this.selectedIndex = event.checked ? index : undefined;
    this.selectedAccountIndex = index;
  }

  onSave(isExit: boolean): void {
    this.bankAccountData = {
      accountId: this.selectedAccount.accountId,
      accountName: this.selectedAccount.displayName,
      accountType: this.selectedAccount.accountType,
      currency: this.selectedAccount.currency,
      accountNo: this.selectedAccount.accountNumber.number,
      branchCode: this.selectedAccount.accountNumber.swiftBic,
      accountCode: this.selectedAccount.accountNumber.sortCode,
      providerId: this.selectedAccount.provider.providerId,
      providerName: this.selectedAccount.provider.displayName,
      stateId: this.stateId,
    };

    this.store
      .dispatch(new CreateBankAccount(this.bankAccountData))
      .subscribe((res) => {
        if (res.bankDashboard.isBankAccoutAdded) {
          if (
            this.router.url.replace(/\?.*$/g, '') === RoutingPath.BankFeedBank
          ) {
            this.router.navigate([RoutingPath.BankFeed]);
          } else {
            this.router.navigate([RoutingPath.BankDashboard]);
          }
        } else {
          this.notifier.error(
            NotificationHeader.error,
            NotificationTextMessage.bankAccountAlreadyExits
          );
        }
      });
  }

  onCancel(): void {
    history.back();
  }
}
