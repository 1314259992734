<div class="chart-wrapper account-details-wrapper">
  <div class="d-flex gap-20">
    <div class="card">
      <div>
        <h3 class="card-title mb-1">
          <mat-icon class="mr-5">portrait</mat-icon>Contact Detail
        </h3>
        <div>
          <div class="d-flex gap-40">
            <div class="form-field">
              <p class="text-gray-300 fw-normal">Name</p>
              <span
                class="clamp"
                title="{{ detailViewData?.accountContactDetailModel?.name }}"
                >{{ detailViewData?.accountContactDetailModel?.name }}</span
              >
            </div>
            <div class="form-field">
              <p class="text-gray-300 fw-normal">Email</p>
              <span
                class="clamp"
                title="{{ detailViewData?.accountContactDetailModel?.email }}"
                >{{ detailViewData?.accountContactDetailModel?.email }}</span
              >
            </div>
          </div>
          <div class="d-flex gap-40">
            <div class="form-field">
              <p class="text-gray-300 fw-normal">Country</p>
              <span
                class="clamp"
                title="{{ detailViewData?.accountContactDetailModel?.country }}"
                >{{ detailViewData?.accountContactDetailModel?.country }}</span
              >
            </div>
            <div class="form-field">
              <p class="text-gray-300 fw-normal">Address Line 1</p>
              <span
                class="clamp"
                title="{{
                  detailViewData?.accountContactDetailModel?.address1
                }}"
                >{{ detailViewData?.accountContactDetailModel?.address1 }}</span
              >
            </div>
          </div>
          <div class="d-flex gap-40">
            <div class="form-field">
              <p class="text-gray-300 fw-normal">Address Line 2</p>
              <span
                class="clamp"
                title="{{
                  detailViewData?.accountContactDetailModel?.address2
                }}"
                >{{ detailViewData?.accountContactDetailModel?.address2 }}</span
              >
            </div>
            <div class="form-field">
              <p class="text-gray-300 fw-normal">City / Town</p>
              <span
                class="clamp"
                title="{{ detailViewData?.accountContactDetailModel?.city }}"
                >{{ detailViewData?.accountContactDetailModel?.city }}</span
              >
            </div>
          </div>
          <div class="d-flex gap-40">
            <div class="form-field">
              <p class="text-gray-300 fw-normal">Postcode</p>
              <span
                class="clamp"
                title="{{
                  detailViewData?.accountContactDetailModel?.postalCode
                }}"
                >{{
                  detailViewData?.accountContactDetailModel?.postalCode
                }}</span
              >
            </div>
            <div class="form-field m-0">
              <p class="text-gray-300 fw-normal">Phone</p>
              <span
                class="clamp"
                title="{{ detailViewData?.accountContactDetailModel?.phone }}"
                >{{ detailViewData?.accountContactDetailModel?.phone }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div>
        <h3 class="card-title mb-1">
          <mat-icon fontSet="material-icons-outlined" class="mr-5"
            >account_balance</mat-icon
          >Account Details
        </h3>
        <div>
          <div class="d-flex gap-40">
            <div class="form-field">
              <p class="fw-normal m-0">Total Due</p>
            </div>
            <div class="form-field">
              <span
                class="clamp"
                title="{{ detailViewData?.accountStatusModel?.totalDue }}"
                >{{
                  detailViewData?.accountStatusModel?.totalDue | numberPipe
                }}</span
              >
            </div>
          </div>
          <div class="d-flex gap-40">
            <div class="form-field">
              <p class="text-gray-300 fw-normal m-0">- Minus</p>
            </div>
            <div class="form-field">
              <span
                class="clamp"
                title="{{ detailViewData?.accountStatusModel?.amountPaid }}"
                >£
                {{
                  detailViewData?.accountStatusModel?.amountPaid | numberPipe
                }}</span
              >
            </div>
          </div>
          <hr class="hr" />
          <div class="d-flex gap-40 mt-2">
            <div class="form-field">
              <p class="fw-normal m-0">Balance</p>
            </div>
            <div class="form-field">
              <span
                class="clamp"
                title="{{ detailViewData?.accountStatusModel?.balance }}"
                >£
                {{
                  detailViewData?.accountStatusModel?.balance | numberPipe
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div>
        <h3 class="card-title mb-1">
          {{
            detailViewData?.accountTypeId !== 1
              ? "Invoice and Receipt"
              : "Purchase and Outstanding"
          }}
        </h3>
      </div>
      <app-report-bill-outstanding-chart
        [triggerBillOutstanding]="triggerBillOutstanding"
      ></app-report-bill-outstanding-chart>
    </div>
  </div>
</div>
