import { Component, Injector, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Modules } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import {
  ExportCisReturn,
  ExportSubContractorReturn,
  GetCISMonthlyReturn,
} from '@app/core/Store';
import { ShareComponent } from '@app/modules/reports';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-view-cis-monthly-return',
  templateUrl: './view-cis-monthly-return.component.html',
  styleUrls: ['./view-cis-monthly-return.component.scss'],
})
export class ViewCISMonthlyReturnComponent implements OnInit {
  noDataFound = false;
  dataSource = new MatTableDataSource();
  spinner: NgxSpinnerService;

  @Input() returnId;
  data: any;
  totalMaterialAmount = 0;
  totalCisDeduction = 0;
  totalNetPayment = 0;
  totalTaxAmount = 0;

  displayedColumns: string[] = [
    'name',
    'tradingName',
    'taxTreatment',
    'verificationNo',
    'rate',
    'totalPayments',
    'costOfMaterials',
    'totalDeducted',
    'export',
  ];
  constructor(
    private injector: Injector,
    public store: Store,
    public commonService: CommonService,
    private dialog: MatDialog
  ) {
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
  }

  ngOnInit(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetCISMonthlyReturn(this.returnId))
      .subscribe((res) => {
        this.spinner.hide();
        this.data = res.CIS.cisMonthlyReturnData;
        this.dataSource = this.data.cisMonthlyReturnDetails;
        this.data.cisMonthlyReturnDetails.forEach(
          (a) => (this.totalNetPayment += a.totalPayment)
        );
        this.data.cisMonthlyReturnDetails.forEach(
          (a) => (this.totalMaterialAmount += a.costofMaterial)
        );
        this.data.cisMonthlyReturnDetails.forEach(
          (a) => (this.totalCisDeduction += a.totalDeducted)
        );
      });
  }

  onDownloadClick(): void {
    this.spinner.show();
    let ids = [];
    ids.push(this.returnId);

    this.store.dispatch(new ExportCisReturn(ids)).subscribe();
  }
  onSubContractorReportDownloadClick(element: any): void {
    this.spinner.show();

    this.store
      .dispatch(
        new ExportSubContractorReturn(
          this.returnId,
          element.contactId,
          element.id
        )
      )
      .subscribe();
  }

  SendMail(element): void {
    this.dialog
      .open(ShareComponent, {
        data: {
          sendMailParamsRequest: this.sendMailParams(element),
        },
      })
      .afterClosed()
      .subscribe();
  }

  sendMailParams(element): any {
    const params = {
      cisId: this.returnId,
      contactId: element.contactId,
      cisReturnDetailId: element.id,
      modId: Modules.CIS300Ceritification,
    };
    return params;
  }
}
