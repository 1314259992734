<div id="chart" *ngIf="analyticsData.length > 0">
  <apx-chart
    [series]="chartOptions.series!"
    [chart]="chartOptions.chart!"
    [dataLabels]="chartOptions.dataLabels!"
    [plotOptions]="chartOptions.plotOptions!"
    [yaxis]="chartOptions.yaxis!"
    [xaxis]="chartOptions.xaxis!"
    [fill]="chartOptions.fill!"
    [title]="chartOptions.title!"
  ></apx-chart>
</div>
<div class="norecord-chart" *ngIf="analyticsData.length === 0">
  <img src="assets/images/column-icon.svg" alt="Pie Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
