import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaxLength } from '@app/core/Enum';
import { RepeatType } from '@app/core/Enum/repeat-type';
import { CommonService } from '@app/core/Services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-company-preference',
  templateUrl: './add-company-preference.component.html',
  styleUrls: ['./add-company-preference.component.scss'],
})
export class AddCompanyPreferenceComponent implements OnInit {
  isReadOnly = false;
  repeteType = RepeatType;
  maxLength = MaxLength;

  companyPreferenceForm: FormGroup;

  constructor(public commonService: CommonService) {}

  @Input() triggerEditData: Observable<any>;

  ngOnInit(): void {
    this.setForm();
    this.triggerEditData?.subscribe((data) => {
      if (data !== undefined && data !== null) {
        this.isReadOnly = !data.autoGenerate;
        if (this.isReadOnly) {
          this.companyPreferenceForm.controls.prefix.disable();
          this.companyPreferenceForm.controls.suffix.disable();
        }

        this.editCompanyPreferences(data);
      }
    });

    this.companyPreferenceForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange =
        this.companyPreferenceForm.touched;
    });
  }

  editCompanyPreferences(data: any): void {
    this.companyPreferenceForm.patchValue({
      autoGenerate: data.autoGenerate,
      prefix: data.prefix,
      suffix: data.suffix,
      repeatType: data.repeatType,
    });
  }

  setForm(): void {
    this.companyPreferenceForm = new FormGroup({
      autoGenerate: new FormControl(true),
      prefix: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      suffix: new FormControl(''),
      repeatType: new FormControl(),
    });
  }

  autoGenerateChange(evt): void {
    this.isReadOnly = !evt.value;
    if (!this.isReadOnly) {
      this.companyPreferenceForm.controls.prefix.enable();
      this.companyPreferenceForm.controls.suffix.enable();
    } else {
      this.companyPreferenceForm.controls.prefix.disable();
      this.companyPreferenceForm.controls.suffix.disable();
    }
  }
}
