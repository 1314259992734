<div class="mat-dialog-wrapper mat-dialog-wrapper-scroll">
  <p class="header-text d-flex justify-space-between align-items-center">
    <span>
      <span>Email {{ moduleName }}</span>
    </span>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </p>
  <mat-dialog-content>
    <div class="content-body">
      <form [formGroup]="emailForm">
        <div class="form-field matchip">
          <p>To</p>
          <mat-form-field>
            <mat-chip-list #to>
              <mat-chip
                [color]="item.invalid ? 'warn' : ''"
                selected
                *ngFor="let item of emailList; let i = index"
                [selectable]="true"
                [removable]="removable"
                (removed)="removeEmail(item, 1)"
                required
                name="to"
              >
                {{ item.value }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                [matChipInputFor]="to"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="add($event, 1)"
                formArrayName="to"
              />
            </mat-chip-list>
          </mat-form-field>
        </div>
        <div class="form-field matchip">
          <p>CC</p>
          <mat-form-field>
            <mat-chip-list #cc>
              <mat-chip
                selected
                *ngFor="let item of ccList; let i = index"
                [color]="item.invalid ? 'warn' : ''"
                [selectable]="true"
                [removable]="removable"
                (removed)="removeEmail(item, 2)"
                required
                name="cc"
              >
                {{ item.value }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                [matChipInputFor]="cc"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="add($event, 2)"
                formArrayName="cc"
              />
            </mat-chip-list>
          </mat-form-field>
        </div>
        <div class="form-field matchip">
          <p>BCC</p>
          <mat-form-field>
            <mat-chip-list #bcc>
              <mat-chip
                [color]="item.invalid ? 'warn' : ''"
                selected
                *ngFor="let item of bccList; let i = index"
                [selectable]="true"
                [removable]="removable"
                (removed)="removeEmail(item, 3)"
                required
                name="bcc"
              >
                {{ item.value }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                [matChipInputFor]="bcc"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="add($event, 3)"
                formArrayName="bcc"
              />
            </mat-chip-list>
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Subject</p>
          <mat-form-field>
            <input
              matInput
              formControlName="subject"
              autocomplete="off"
              maxlength="{{ maxLength.maxLength998 }}"
            />
          </mat-form-field>
        </div>
        <div [ngxSummernote]="configsummernote" formControlName="content"></div>
      </form>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-buttons
      [getModuleId]="moduleId"
      (triggerOnSendClick)="onSave()"
      (triggerOnCancelClick)="onCancel(false)"
      [isSend]="true"
    >
    </app-buttons>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
