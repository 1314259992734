import { MainListParameters } from '@app/core/Models';

export class SubContractorYTDDetailsReport {
  static readonly type = '[CISREPORT] SubContractor YTD Details Report';

  constructor(public queryParams: MainListParameters) {}
}
export class GetInvoiceReport {
  static readonly type = '[CISREPORT] Get Invoice Report ';

  constructor(public invoiceReportParameters: any) {}
}
export class SendEmailInvoiceReport {
  static readonly type = '[CISREPORT] Send Email Invoice Report';

  constructor(
    public attachmentParams: any,
    public toMailId: string,
    public ccMailId: string,
    public bccMailId: string,
    public message: string,
    public subject: string
  ) {}
}

export class CisSubmissionReport {
  static readonly type = '[CISREPORT] Cis Submission Report';
}
