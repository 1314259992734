import { CISInvoiceDetailsModel, PaymentDetailsModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CISInvoiceModel {
  id?: Guid;
  entryNumber?: string;
  entryDate?: string;
  dueDate?: string;
  currencyId: number;
  accountId: Guid;
  note: string;
  bookAccountId?: Guid;
  items: Array<CISInvoiceDetailsModel>;
  receiptDetails: Array<PaymentDetailsModel>;
  attachment?: Array<string>;
  advanceReceiptAmount?: number;
  branchId?: Guid | null;
  departmentId?: Guid | null;
  currencyRate?: number;
  isVatIncluded: boolean;
  isVatPostponed: boolean;
}
