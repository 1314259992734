import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Currency,
  CurrencyList,
  CurrencyModel,
  SaveCurrencyModel,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  constructor(private http: HttpClient, private commonService: CommonService) {}
  createCurrency(currency: SaveCurrencyModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}Currency/CreateCurrency`,
      this.commonService.trimObjectSpace(JSON.stringify(currency)),
      headers
    );
  }
  getClientCurrencyList(): Observable<Currency> {
    return this.http.get<Currency>(
      `${environment.apiVersionUrl}Currency/GetAllCurrency`
    );
  }
  deleteClientCurrency(id: Guid, currencyId: number): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Currency/DeleteCurrency/${id}/${currencyId}`,
      headers
    );
  }
  getRefreshCurrencyList(
    currencyId: Array<number>
  ): Observable<Array<CurrencyList>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<CurrencyList>>(
      `${environment.apiVersionUrl}Currency/GetLatestCurrency`,
      JSON.stringify(currencyId),
      headers
    );
  }
  saveCurrencyData(currencyData?: Array<CurrencyModel>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Currency/SaveCurrency`,
      JSON.stringify(currencyData),
      headers
    );
  }
}
