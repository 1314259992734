import { Guid } from 'guid-typescript';
export class OpeningBalance {
  id?: Guid;
  accountId: Guid;
  credit?: number;
  debit?: number;
}

export class SaveOpeningBalanceModel {
  accountingPeriodId: Guid;
  date?: string;
  openingBalanceData: Array<OpeningBalance>;
  branchId?: Guid;
}
