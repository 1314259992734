import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ComparitiveReportParamerters,
  MainList,
  MainListParameters,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  getProfitLossReport(queryParams: MainListParameters): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}Report/profitLoss`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getProfitLossComparisonReport(
    queryParams: MainListParameters
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}Report/profitLossComparison`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getBalanceSheetReport(queryParams: MainListParameters): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}Report/balanceSheet`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getCashFlow(queryParams: MainListParameters): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}Report/cashFlow`,
      JSON.stringify(queryParams),
      headers
    );
  }

  cashFlowTransaction(
    queryParams: MainListParameters,
    tagName: string
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}Report/cashFlowTransaction/${tagName}`,
      JSON.stringify(queryParams),
      headers
    );
  }

  shareFile(
    attachmentParams: MainListParameters,
    toMailId: string,
    ccMailId: string,
    bccMailId: string,
    message: string,
    subject: string
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const obj = {
      mainListParameters: attachmentParams,
      toMailId: toMailId,
      cc: ccMailId,
      bcc: bccMailId,
      message: message,
      subject: subject,
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CommonSendMail/reportEmail`,
      obj,
      options
    );
  }

  sendComparativeReportEmail(
    attachmentParams: ComparitiveReportParamerters,
    toMailId: string,
    ccMailId: string,
    bccMailId: string,
    message: string,
    subject: string
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const obj = {
      comparitiveReportParamerters: attachmentParams,
      toMailId: toMailId,
      cc: ccMailId,
      bcc: bccMailId,
      message: message,
      subject: subject,
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}report/comparitiveReport/email`,
      JSON.stringify(obj),
      options
    );
  }
}
