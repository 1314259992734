import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { Observable, Subject, Subscription } from 'rxjs';

import { DatePipe, Location } from '@angular/common';
import {
  DebitNoteModel,
  PaymentDetailsModel,
  ProductDetailsModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateDebitNote,
  GetBankAccountTransactionData,
  GetDataByDebitNoteId,
  GetTransactionLogData,
  MenuState,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  AddDebitNoteComponent,
  CreditNoteAllocationComponent,
  ProductDetailsComponent,
  TransactionsAttachmentComponent,
  TransactionsPaymentComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-fixed-asset-return',
  templateUrl: './fixed-asset-return.component.html',
  styleUrls: ['./fixed-asset-return.component.scss'],
})
export class FixedAssetReturnComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;

  moduleId = Modules.DebitNote;
  moduleName = ModuleName.DebitNote;

  debitNoteId = Guid.EMPTY as unknown as Guid;
  amount = 0;
  balanceAmount = 0;
  totalAmount = 0;
  vatAmount = 0;
  amountReceivedTotal = 0;
  amountPaymentReceivedTotal = 0;
  headerText = 'Receipt';

  debitNoteData: DebitNoteModel;
  productItems: Array<ProductDetailsModel>;
  receiptItems: Array<PaymentDetailsModel>;
  creditAllocationItems = new Array<any>();

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddDebitNoteComponent, { static: true })
  addDebitNoteDetails;

  @ViewChild(ProductDetailsComponent, { static: true })
  productDetails;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  @ViewChild(TransactionsPaymentComponent, { static: true })
  receiptInfo;

  @ViewChild(CreditNoteAllocationComponent, { static: true })
  creditNoteAllocationInfo;

  subscriptionRouting: Subscription;

  triggerEditData: Subject<any> = new Subject<any>();
  triggereDebitNoteEditData: Subject<any> = new Subject<any>();
  triggereEditProductData: Subject<any> = new Subject<any>();
  triggerCustomer: Subject<any> = new Subject<any>();
  triggereEditPaymentData: Subject<any> = new Subject<any>();
  triggerReceiptDetail: Subject<any> = new Subject<any>();
  triggerisVatIncluded: Subject<any> = new Subject<any>();
  triggerBranchChange: Subject<any> = new Subject<any>();
  triggerCurrencySelectedOption: Subject<boolean> = new Subject<boolean>();
  triggerCurrencyRefreshOption: Subject<boolean> = new Subject<boolean>();
  triggerSelectedCurrencyRate: Subject<number> = new Subject<number>();
  triggerTransactionLogData: Subject<any> = new Subject<any>();
  isFromBankImport = false;
  isManualBank = false;
  selectedBank: any;
  bankImportTransactionId = Guid.EMPTY as unknown as Guid;
  selectedCurrencyId: number;
  selectedCurrencyRate: number;
  receiptAccountSelectedCurrencyId: number;
  store: Store;
  commonService: CommonService;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    public dialog: MatDialog,
    private _Activatedroute: ActivatedRoute,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private location: Location,
    private renderer: Renderer2,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          const isFromBankImport = params.get('isFromBankImport');
          const isManualBank = params.get('isManualBank');

          if (isFromBankImport !== null && isManualBank !== null) {
            this.isFromBankImport = JSON.parse(atob(isFromBankImport));
            this.isManualBank = JSON.parse(atob(isManualBank));
            this.selectedBank = atob(params.get('customId')!) as unknown as any;
          }

          const id = atob(params.get('id')!) as unknown as Guid;

          if (this.isFromBankImport) {
            this.bankImportTransactionId = id;
            this.getTransactionLogData();
          } else {
            this.debitNoteId = id;
            this.editDebitNote();
          }
        }
      }
    );
  }

  getTransactionLogData(): void {
    this.store
      .dispatch(new GetTransactionLogData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.addDebitNoteDetails.debitNoteForm.controls.entryDate.setValue(
          res.common.transactionLogData.entryDate
        );
        this.addDebitNoteDetails.debitNoteForm.controls.note.setValue(
          res.common.transactionLogData.note
        );
        this.addDebitNoteDetails.debitNoteForm.controls.amountReceived.setValue(
          res.common.transactionLogData.amount
        );
        this.addDebitNoteDetails.debitNoteForm.controls.amountReceived.disable();
        this.triggerTransactionLogData.next(res.common.transactionLogData);
      });
  }

  getBankAccountTransactionData(): void {
    this.store
      .dispatch(new GetBankAccountTransactionData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.addDebitNoteDetails.debitNoteForm.controls.entryDate.setValue(
          res.common.transactionLogData.transactionDate
        );
        this.addDebitNoteDetails.debitNoteForm.controls.note.setValue(
          res.common.transactionLogData.description
        );
        this.addDebitNoteDetails.debitNoteForm.controls.amountReceived.setValue(
          res.common.transactionLogData.amount
        );
        this.addDebitNoteDetails.debitNoteForm.controls.amountReceived.disable();
      });
  }

  onSupplierChange(): void {
    this.creditNoteAllocationInfo.clearForm();
    for (
      let i = 0;
      i < this.creditNoteAllocationInfo.creditDetailArray.length;
      i++
    ) {
      this.creditNoteAllocationInfo.creditDetailArray.controls[i]
        .get('invoiceReceipt')
        .setValue(0);
      this.creditNoteAllocationInfo.creditDetailArray.controls[i]
        .get('id')
        .setValue(Guid.EMPTY as unknown as Guid);
    }

    this.triggerCustomer.next(this.addDebitNoteDetails.debitNoteForm);
  }

  editDebitNote(): void {
    this.store
      .dispatch(new GetDataByDebitNoteId(this.debitNoteId))
      .subscribe((res) => {
        this.addDebitNoteDetails.debitNoteForm.markAsUntouched();
        this.productDetails.formProductDetail.markAsUntouched();
        this.receiptInfo.paymentForm.markAsUntouched();
        this.creditNoteAllocationInfo.formCreditDetail.markAsUntouched();

        this.triggereDebitNoteEditData.next(res.debitNote.debitNoteData);
        setTimeout(() => {
          const data = {
            productData: res.debitNote.debitNoteData.debitNoteItems,
          };
          this.triggereEditProductData.next(data);
          this.triggerEditData.next(res.debitNote.debitNoteData);
          this.triggereEditPaymentData.next(
            res.debitNote.debitNoteData.receiptDetails
          );
          this.triggerReceiptDetail.next(
            res.debitNote.debitNoteData.invoiceAllocations
          );
          this.triggerisVatIncluded.next(
            res.debitNote.debitNoteData.isVatIncluded
          );
        }, 1000);
      });
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.debitNoteId,
      isExit,
      Modules.DebitNote,
      RoutingPath.AddDebitNote
    );
  }

  onSave(isExit: boolean): void {
    if (this.addDebitNoteDetails.debitNoteForm.invalid) {
      this.commonService.addValidation(
        this.addDebitNoteDetails.debitNoteForm,
        this.renderer
      );
    } else if (
      !this.isFromBankImport &&
      this.receiptInfo.paymentArray.invalid
    ) {
      this.receiptInfo.paymentArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (
            x.controls.amount.value === '' ||
            x.controls.amount.value === '0'
          ) {
            this.commonService.onFailure(
              NotificationTextMessage.amtErrorMessage
            );
          }
          c.markAsTouched();
        });
      });
    } else if (
      !this.isFromBankImport &&
      this.productDetails.totalAmount < this.receiptInfo.totalReciptAmount
    ) {
      this.receiptInfo.paymentArray.controls.forEach((x) => {
        (Object as any)
          .values(x.controls)
          .forEach((c) => c.markAsTouched(false));
        x.controls.amount.status = 'INVALID';
      });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
    } else if (
      !this.isFromBankImport &&
      this.creditNoteAllocationInfo.creditDetailArray.invalid
    ) {
      this.creditNoteAllocationInfo.creditDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (
            x.controls.amount.value === '' ||
            x.controls.amount.value === '0'
          ) {
            this.commonService.onFailure(
              NotificationTextMessage.amtErrorMessage
            );
          }
          c.markAsTouched();
        });
      });
    } else if (this.creditNoteAllocationInfo.creditDetailArray.invalid) {
      this.creditNoteAllocationInfo.creditDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (
            x.controls.amount.value === '' ||
            x.controls.amount.value === '0'
          ) {
            this.commonService.onFailure(
              NotificationTextMessage.amtErrorMessage
            );
          }
          c.markAsTouched();
        });
      });
    } else if (
      !this.isFromBankImport &&
      this.productDetails.totalAmount <
        +this.creditNoteAllocationInfo.totalAmount +
          +this.receiptInfo.totalReciptAmount
    ) {
      if (this.creditNoteAllocationInfo.creditDetailArray.length > 0) {
        this.creditNoteAllocationInfo.creditDetailArray.controls.forEach(
          (x) => {
            (Object as any)
              .values(x.controls)
              .forEach((c) => c.markAsTouched(false));
            x.controls.amount.status = 'INVALID';
          }
        );
      }

      if (this.receiptInfo.paymentArray.length > 0) {
        this.receiptInfo.paymentArray.controls.forEach((x) => {
          (Object as any)
            .values(x.controls)
            .forEach((c) => c.markAsTouched(false));
          x.controls.amount.status = 'INVALID';
        });
      }

      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
    } else if (
      this.isFromBankImport &&
      this.productDetails.totalAmount <
        +this.addDebitNoteDetails.debitNoteForm.controls.amountReceived.value
    ) {
      this.commonService.onFailure(NotificationTextMessage.amountTotalError);
    } else if (this.productDetails.productDetailArray.invalid) {
      this.productDetails.productDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateDebitNote(this.debitNoteData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit && !this.isFromBankImport) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.commonService.setLocalStorage(
                    'selectedBank',
                    this.selectedBank
                  );
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    this.selectedCurrencyId =
      this.addDebitNoteDetails.debitNoteForm.controls.currency.value === 0
        ? 123
        : this.addDebitNoteDetails.debitNoteForm.controls.currency.value;
    this.selectedCurrencyRate =
      this.addDebitNoteDetails.debitNoteForm.controls.currencyAmt.value;
    this.receiptAccountSelectedCurrencyId =
      this.receiptInfo.selectedAccountCurrencyId;
    try {
      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }
      this.productItems = new Array<ProductDetailsModel>();

      this.productDetails.productDetailArray?.getRawValue().forEach((x) => {
        const data = this.productDetails.productTypeData.filter(
          (a) => a.name === x.productName
        );
        if (data.length > 0) {
          x.productId = data[0].id;
        }

        this.productItems.push({
          id: x.id,
          productId: x.productName,
          description: x.description,
          quantity: +x.qty,
          price: +x.price / this.selectedCurrencyRate,
          vatRateId: x.vatRate === -1 ? null : x.vatRate,
          vatAmount: +x.vatAmount / this.selectedCurrencyRate,
          totalAmount: +x.amount / this.selectedCurrencyRate,
          foreignVatAmount: +x.vatAmount,
          foreignPrice: +x.price,
          foreignTotalAmount: +x.amount,
        });
      });

      this.receiptItems = new Array<PaymentDetailsModel>();

      this.receiptInfo.paymentArray?.getRawValue().forEach((x) => {
        const attachmentList: any = [];
        if (
          x.attachment !== null &&
          x.attachment !== undefined &&
          x.attachment !== '' &&
          x.attachment.length > 0
        ) {
          x.attachment.forEach((element) => {
            attachmentList.push(element);
          });
        }

        if (x.accountId !== '' && x.amount !== '') {
          this.receiptItems.push({
            id: x.id,
            receiptId: x.receiptId,
            transactionDate: this.datepipe
              .transform(x.receiptDate, 'yyyy-MM-dd')
              ?.toString(),
            amount:
              this.receiptAccountSelectedCurrencyId !== 123
                ? x.amount / this.selectedCurrencyRate
                : x.amount,
            postingAccountId: x.accountId,
            attachment: attachmentList,
            foreignAmount:
              this.receiptAccountSelectedCurrencyId !== 123
                ? x.amount
                : x.amount * this.selectedCurrencyRate,
            currencyRate: this.selectedCurrencyRate,
          });
        }
      });

      this.creditAllocationItems = new Array<any>();
      this.creditNoteAllocationInfo.creditDetailArray
        ?.getRawValue()
        .forEach((x) => {
          this.creditAllocationItems.push({
            id: x.id,
            toInvoiceId: x.invoiceReceipt,
            amount:
              this.selectedCurrencyId !== 123
                ? +x.amount / this.selectedCurrencyRate
                : +x.amount,
            transactionDate: this.datepipe
              .transform(x.receiptDate, 'yyyy-MM-dd')
              ?.toString(),
            toInvoiceTypeID: x.invoiceTypeId,
            foreignAmount: +x.amount,
          });
        });

      this.debitNoteData = {
        id: this.debitNoteId,
        entryNumber:
          this.addDebitNoteDetails.debitNoteForm.controls.debitNoteNo.value,
        accountId:
          this.addDebitNoteDetails.debitNoteForm.controls.accountId.value,
        bookAccountId:
          this.addDebitNoteDetails.debitNoteForm.controls.debitNoteType.value
            .id,
        entryDate: this.datepipe
          .transform(
            this.addDebitNoteDetails.debitNoteForm.controls.entryDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),

        currencyId:
          this.addDebitNoteDetails.debitNoteForm.controls.currency.value,
        currencyRate:
          this.addDebitNoteDetails.debitNoteForm.controls.currencyAmt.value,
        note: this.addDebitNoteDetails.debitNoteForm.controls.note.value,
        voucherNumber:
          this.addDebitNoteDetails.debitNoteForm.controls.billRefNo.value,
        attachment: filedata,
        debitNoteItems: this.productItems,
        receiptDetails: this.receiptItems,
        invoiceAllocations: this.creditAllocationItems,
        transactionLogId: this.isFromBankImport
          ? this.bankImportTransactionId
          : (Guid.EMPTY as unknown as Guid),
        isManualBank: this.isManualBank,
        isVatIncluded:
          this.addDebitNoteDetails.debitNoteForm.controls.isVatInclude.value,
        branchId:
          this.addDebitNoteDetails.debitNoteForm.controls.branchId.value === ''
            ? null
            : this.addDebitNoteDetails.debitNoteForm.controls.branchId.value,
        departmentId:
          this.addDebitNoteDetails.debitNoteForm.controls.departmentId.value ===
          ''
            ? null
            : this.addDebitNoteDetails.debitNoteForm.controls.departmentId
                .value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.debitNoteId)) {
      this.editDebitNote();
    } else {
      this.debitNoteId = Guid.EMPTY as unknown as Guid;
      this.addDebitNoteDetails.ngOnInit();
      this.productDetails.ngOnInit();
      this.attachmentDetail.ngOnInit();
      this.receiptInfo.ngOnInit();
      this.creditNoteAllocationInfo.ngOnInit();
    }
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  triggerReloadSideList(): void {
    this.reloadSideList.emit();
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  onAmountReceivedChange(): void {
    this.amountReceivedTotal = this.creditNoteAllocationInfo.totalAmount;
  }

  onAmountPaymentReceivedChange(): void {
    this.amountPaymentReceivedTotal = this.receiptInfo.totalReciptAmount;
  }

  isVatIncludedClick(event): void {
    this.triggerisVatIncluded.next(event);
  }

  onBranchChange(selectedOption: any) {
    this.triggerBranchChange.next(selectedOption);
  }
  onCurrencySelectedOption(selectedOption: any) {
    this.triggerCurrencySelectedOption.next(selectedOption);
  }
  onCurrencyRefreshOption(selectedOption: any) {
    this.triggerCurrencyRefreshOption.next(selectedOption);
  }
  onCurrencySelectedRate(selectedOption: any) {
    this.triggerSelectedCurrencyRate.next(selectedOption);
  }
}
