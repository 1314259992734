import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActionType,
  ConfirmationType,
  InvoiceType,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import {
  ExportType,
  MainListParameters,
  ViewParamModel,
} from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  CheckDepreciation,
  CommonState,
  ExportReceipt,
  ExportVatAudit,
  GetAccountTransactionList,
  MenuState,
  ReportExport,
} from '@app/core/Store';
import {
  BankTransferViewComponent,
  EditReceiptComponent,
  SchedulesNotesViewComponent,
} from '@app/modules';
import {
  AssignReceiptComponent,
  ConfirmationBoxComponent,
  ViewReceiptComponent,
} from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-contact-transaction',
  templateUrl: './contact-transaction.component.html',
  styleUrls: ['./contact-transaction.component.scss'],
})
export class ContactTransactionComponent implements OnInit {
  noDataFound: any;
  showPaginator = true;

  transactionData: any;
  selectedRowIndex = -1;
  ids: Array<Guid>;
  actions: any = [];
  listParameters: MainListParameters = new MainListParameters();

  triggerPaginationChange: Subject<any> = new Subject<any>();

  submitVatDisplayedColumns: string[] = [
    'date',
    'accountName',
    'reference',
    'type',
    'referenceNo',
    'debit',
    'credit',
    'balance',
  ];

  customerId: Guid;
  moduleId = 0;

  moreActionCount = 4;

  moduleEnum = Modules;

  exportType = ExportType;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  @Input()
  triggerCustomerId: Observable<any>;

  constructor(
    public spinner: NgxSpinnerService,
    public datepipe: DatePipe,
    public commonService: CommonService,
    public store: Store,
    public dialog: MatDialog,
    public notify: NotificationService
  ) {}

  ngOnInit(): void {
    this.actions = [
      {
        name: 'View',
        actionType: ActionType.View,
        icon: 'pageview',
      },
      {
        name: 'Export',
        actionType: ActionType.Export,
        icon: 'save_alt',
      },
      {
        name: 'Edit',
        actionType: ActionType.Update,
        icon: 'edit',
      },
      {
        name: 'Delete',
        actionType: ActionType.Delete,
        icon: 'delete_outline',
      },
      {
        name: 'Allocate Receipt',
        actionType: ActionType.AllocateReceipt,
        icon: 'receipt_long',
      },
      {
        name: 'Allocate Payment',
        actionType: ActionType.AllocateReceipt,
        icon: 'receipt_long',
      },
      {
        name: 'Unallocate Amount',
        actionType: ActionType.UnAllocateAmount,
        icon: 'receipt_long',
      },
    ];
    this.triggerCustomerId.subscribe((data) => {
      this.customerId = data.id;
      this.moduleId = data.moduleId;
      this.getList();
    });
  }

  getParamter(format?: number): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      branchIds: this.listParameters.branchIds ?? [],
      departmentIds: this.listParameters.departmentIds ?? [],
      ids: this.listParameters.ids ?? null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      format: format,
    };
    return queryParams;
  }

  getList(): void {
    this.spinner.show();
    this.store
      .dispatch(
        new GetAccountTransactionList(this.getParamter(), this.customerId)
      )
      .pipe()
      .subscribe((res) => {
        this.spinner.hide();
        this.transactionData = res.common.mainList.resultSet.data;
        this.noDataFound = this.transactionData.length > 0 ? false : true;

        const param = {
          pageSize: this.listParameters.pageSize,
          totalRecord: res.common.mainList.paginationModel.totalItemCount,
        };
        this.triggerPaginationChange.next(param);
      });
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;

    this.getList();
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;

      this.getList();
    }
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  onSearch(input: Event): void {
    const searchText: string = (input.target as HTMLInputElement).value;
    this.listParameters.search = searchText;
    this.getList();
  }

  redirect(moduleId: any, element: any): void {
    if (moduleId === Modules.AddCustomAccounts) {
      this.commonService.onEditRouting(
        true,
        Modules.ReportAccountDetail,
        element.accountId,
        false
      );
      this.commonService.setPermission(
        RoutingPath.ReportAccountDetailsPermission,
        element.accountId
      );
    } else if (
      moduleId === Modules.Customers ||
      moduleId === Modules.Suppliers
    ) {
      this.commonService.onEditRouting(true, moduleId, element.id);
    } else if (this.commonService.isAllowedOpenView(moduleId)) {
      this.commonService.openView(element.id, moduleId);
    } else {
      this.commonService.onEditRouting(true, moduleId, element.id);
    }
  }

  onToggleMatMenu(i): void {
    this.selectedRowIndex = i;
  }

  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
  }

  onButtonClick(element: any, actionType: ActionType): void {
    let id = element.id;
    let moduleId = element.moduleId;

    this.ids = [];
    this.ids.push(id);

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (
          isValidPeriod ||
          actionType === ActionType.Email ||
          actionType === ActionType.Export ||
          actionType === ActionType.View
        ) {
          switch (actionType) {
            case ActionType.View:
              this.openView(id, moduleId);
              break;

            case ActionType.Update:
              if (moduleId === Modules.FixedAssets) {
                let res = this.fixedAssetExplainCheck(id, moduleId).subscribe(
                  (res) => {
                    if (res) {
                      this.dialog
                        .open(ConfirmationBoxComponent, {
                          data: {
                            ids: this.ids,
                            type: ConfirmationType.FixedAssetExplainRemove,
                            moduleId: Modules.FixedAssets,
                            totalNumberOfRecordSelected: this.ids.length,
                            headerText: NotificationHeader.confirmation,
                            detailText: NotificationDetails.fixedAssetExplain,
                          },
                        })
                        .afterClosed()
                        .subscribe((result) => {
                          if (result) {
                            this.commonService.onEditRouting(
                              true,
                              moduleId,
                              id
                            );
                          }
                        });
                    } else {
                      this.commonService.onEditRouting(true, moduleId, id);
                    }
                  }
                );
              } else if (
                moduleId === Modules.Payment ||
                moduleId === Modules.Receipt
              ) {
                const parameter = {
                  moduleId: moduleId,
                  id: id,
                };
                this.dialog
                  .open(EditReceiptComponent, {
                    data: parameter,
                    disableClose: true,
                  })
                  .afterClosed()
                  .subscribe((result: boolean) => {
                    if (result) {
                      this.getList();
                    }
                  });
              } else {
                this.commonService.onEditRouting(true, moduleId, id);
              }
              break;

            case ActionType.Delete:
              this.onDeleteClick(id, moduleId);
              break;

            case ActionType.Export:
              if (this.moduleId !== Modules.SubmitVat) {
                this.store
                  .dispatch(new ExportReceipt(moduleId, this.ids, false))
                  .subscribe();
              } else {
                this.store.dispatch(new ExportVatAudit(id, false)).subscribe();
              }
              break;
            case ActionType.AllocateReceipt:
              const parameter = {
                moduleId: moduleId,
                id: id,
              };
              this.dialog
                .open(AssignReceiptComponent, {
                  data: parameter,
                  disableClose: true,
                })
                .afterClosed()
                .subscribe((result: boolean) => {
                  if (result) {
                    this.getList();
                  }
                });
              break;

            case ActionType.UnAllocateAmount:
              this.onUnAlocateClick(id, moduleId, element);
              break;
          }
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  openView(id: any, moduleId: any): void {
    const data: ViewParamModel = {
      moduleId: moduleId,
      id: id,
    };

    if (
      this.moduleId !== Modules.BankTransfer &&
      this.moduleId !== Modules.Notes
    ) {
      this.dialog
        .open(ViewReceiptComponent, {
          data,
          disableClose: true,
        })
        .afterClosed()
        .subscribe((result) => {});
    } else if (this.moduleId === Modules.Notes) {
      this.dialog
        .open(SchedulesNotesViewComponent, {
          data,
          disableClose: true,
        })
        .afterClosed()
        .subscribe((result) => {});
    } else {
      this.dialog
        .open(BankTransferViewComponent, {
          data,
          disableClose: true,
        })
        .afterClosed()
        .subscribe((result) => {});
    }
  }

  export(format: number, isPrint: boolean = false): void {
    this.spinner.show();

    {
      let totalRecordOfRecords = 0;

      this.totalRecord$.subscribe((x) => {
        totalRecordOfRecords = x;
      });

      if (totalRecordOfRecords > 0) {
        const queryParams: any = {
          pageNumber: this.listParameters.pageNumber,
          pageSize: this.listParameters.pageSize,
          filter: this.listParameters.filter,
          sortBy: this.listParameters.sortBy,
          sortOrder: this.listParameters.sortOrder,
          search: this.listParameters.search,
          moduleId: this.listParameters.moduleId,
          subModuleId: this.listParameters.subModuleId ?? -1,
          ids: this.ids,
          startDate:
            this.datepipe
              .transform(this.listParameters.startDate, 'yyyy-MM-dd')
              ?.toString() ?? null,
          endDate:
            this.datepipe
              .transform(this.listParameters.endDate, 'yyyy-MM-dd')
              ?.toString() ?? null,
          format: format,
          isPrint: isPrint,
        };
        this.store
          .dispatch(new ReportExport(queryParams, this.customerId))
          .subscribe(() => {});
      } else {
        this.spinner.hide();
        this.notify.error(
          NotificationHeader.error,
          NotificationTextMessage.noDataToExport
        );
      }
    }
  }

  fixedAssetExplainCheck(id: any, moduleId: any): Observable<boolean> {
    let actionName;
    if (moduleId === Modules.FixedAssets) {
      actionName = CheckDepreciation;
    } else {
    }
    let result = new Subject<boolean>();
    this.store.dispatch(new actionName(id)).subscribe((res) => {
      result.next(res.fixedAssets.isEdit);
    });

    return result.asObservable();
  }

  onDeleteClick(id: any, moduleId: number): void {
    this.ids = [];
    this.ids.push(id);

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getList();
          }
        });
    }
  }

  getActions(element: any): any {
    let actions = this.actions;

    if (
      element.invoiceTypeId !== InvoiceType.Receipt &&
      element.invoiceTypeId !== InvoiceType.Payment
    ) {
      actions = actions.filter(
        (action) => action.actionType !== ActionType.AllocateReceipt
      );
    } else if (element.invoiceTypeId === InvoiceType.Receipt) {
      actions = actions.filter(
        (action) =>
          action.name !== 'Allocate Payment' &&
          action.name !== 'Unallocate Amount'
      );
    } else if (element.invoiceTypeId === InvoiceType.Payment) {
      actions = actions.filter(
        (action) =>
          action.name !== 'Allocate Receipt' &&
          action.name !== 'Unallocate Amount'
      );
    }

    if (
      (element.invoiceTypeId === InvoiceType.Receipt ||
        element.invoiceTypeId === InvoiceType.Payment) &&
      !element.isAdvance
    ) {
      actions = actions.filter(
        (action) =>
          action.actionType !== ActionType.AllocateReceipt &&
          action.actionType !== ActionType.UnAllocateAmount
      );
    }

    if (element.canEdit) {
      if (element.isAllocate) {
        actions = actions.filter(
          (action) =>
            action.actionType !== ActionType.Update &&
            action.actionType !== ActionType.Delete
        );
      } else {
        actions = actions.filter(
          (action) =>
            action.actionType !== ActionType.Update &&
            action.actionType !== ActionType.Delete &&
            action.actionType !== ActionType.UnAllocateAmount
        );
      }
    } else {
      actions = actions.filter(
        (action) => action.actionType !== ActionType.UnAllocateAmount
      );
    }

    return actions;
  }

  onUnAlocateClick(id: any, moduleId: number, ele: any): void {
    console.log(ele);

    this.ids.push(id);

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            InvoiceTypeId: ele.invoiceTypeId,
            type: ConfirmationType.UnAllocateAmount,
            moduleId: moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.unalocateAmountConfirm,
            detailText: NotificationDetails.unallocateText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getList();
          }
        });
    }
  }
}
