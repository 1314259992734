import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountProvider, RoutingPath } from '@app/core/Enum';
import { MainListParameters } from '@app/core/Models';
import { GeneralDashboardCompany } from '@app/core/Models/general-dashboard/general-dashboard';
import { CommonService, DashboardService } from '@app/core/Services';
import {
  GeneralDashBoardState,
  GetGeneralDashboardCompanyList,
  GetUserMailList,
  SetAsDefaultCompany,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-all-client',
  templateUrl: './dashboard-all-client.component.html',
  styleUrls: ['./dashboard-all-client.component.scss'],
})
export class DashboardAllClientComponent implements OnInit {
  displayedColumns: string[] = [
    'sNo',
    'companyRefNo',
    'companyName',
    'companyTypeName',
    'vatNumber',
  ];
  store: Store;
  spinner: NgxSpinnerService;
  cookieService: CookieService;
  commonService: CommonService;
  listParameters: MainListParameters = new MainListParameters();
  companyList: Array<GeneralDashboardCompany>;
  companyName: string;
  showMailOption: any;
  capiumPayList: any;
  defaultCompanyId: any;
  noDataFound = false;
  isHeaderChange = false;
  showPaginator = true;
  companyType = 0;
  totalSelectedRecords = 0;
  searchText = '';
  reloadDashboard = false;
  isNoRecord = true;
  @Select(GeneralDashBoardState.totalRecord)
  totalRecord$: Observable<number>;

  triggerPaginationChange: Subject<any> = new Subject<any>();

  constructor(
    private injector: Injector,
    private dashboardService: DashboardService,
    private router: Router
  ) {
    this.store = injector.get<Store>(Store);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.cookieService = injector.get<CookieService>(CookieService);
    this.router = injector.get<Router>(Router);
    this.commonService = this.injector.get<CommonService>(CommonService);
  }
  ngOnInit(): void {
    this.getCompanyList();
  }
  getParamaters(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.searchText,
      companyType: this.companyType,
      isVatRegistered: false,
      status: 0,
      year: 0,
      month: 0,
      paymentStatus: 0,
    };

    return queryParams;
  }
  getCompanyList(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetGeneralDashboardCompanyList(this.getParamaters()))
      .pipe(
        tap((res) => {
          this.isHeaderChange = true;
          this.companyList = res.generalDashboard.dashBoardcompany;
          this.isNoRecord = this.companyList.length === 0;
          this.noDataFound = this.companyList.length > 0 ? false : true;
          const param = {
            pageSize: this.listParameters.pageSize,
            totalRecord: res.generalDashboard.totalRecord,
            isHeaderChange: this.isHeaderChange,
          };
          this.triggerPaginationChange.next(param);
          if (this.companyList.length) {
            this.companyName =
              this.companyList.find((item) => item.isDefault === true)
                ?.companyName ?? this.companyName;
            this.defaultCompanyId = this.companyList.find(
              (item) => item.isDefault === true
            )?.id;
            if (this.companyName === undefined) {
              this.companyName = this.companyList[0].companyName;
              this.setCompanyName(this.companyName);
            } else {
              this.setCompanyName(
                this.companyList.find((item) => item.isDefault === true)
                  ?.companyName
              );
            }
            if (this.defaultCompanyId === undefined) {
              this.defaultCompanyId = this.companyList[0].id;
              this.setCompanyId(this.defaultCompanyId);
            } else {
              this.setCompanyId(
                this.companyList.find((item) => item.isDefault === true)?.id
              );
            }
          }
          this.spinner.hide();
        })
      )
      .subscribe();
  }
  setCompanyId(companyId): void {
    if (
      companyId !== undefined &&
      companyId !== null &&
      companyId !== Guid.EMPTY
    ) {
      this.cookieService.set('companyid', companyId);
    }
  }
  setCompanyName(companyName: any): void {
    this.cookieService.set('defaultCompanyName', companyName);
  }
  onChangeCompany(isDefaultCompany: boolean, company: any): void {
    this.companyName = isDefaultCompany
      ? this.companyName
      : company.companyName;
    this.defaultCompanyId = isDefaultCompany
      ? this.defaultCompanyId
      : company.id;
    this.store
      .dispatch(new SetAsDefaultCompany(this.defaultCompanyId))
      .subscribe((x) => {
        this.setCompanyId(this.defaultCompanyId);
        this.setCompanyName(this.companyName);
        localStorage.setItem('defaultFinancialPeriod', '-1');
        this.dashboardService.setDashboardReload(true);
        this.spinner.show();
        this.getAllMail(5);
      });
    this.commonService.toggleMenu = true;
    this.router.navigate([RoutingPath.Dashboard]);
    this.reloadDashboard = true;
  }
  getAllMail(providerId): void {
    this.store
      .dispatch(new GetUserMailList(providerId))
      .subscribe((res: any) => {
        if (providerId === AccountProvider.CapiumPay) {
          this.capiumPayList = res.integration.integrationData;
          if (this.capiumPayList === null || this.capiumPayList.length === 0) {
            this.showMailOption = 'false';
          } else {
            this.showMailOption = 'true';
          }
          this.cookieService.set('showPaymentMailOption', this.showMailOption);
        }
      });
  }
  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.isHeaderChange = false;

      this.getCompanyList();
    }
  }
  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.isHeaderChange = false;

    this.getCompanyList();
  }
  togglePaginator(val: any): void {
    this.showPaginator = val;
  }
  onDropdownChange(): void {
    this.getCompanyList();
  }
  onSearch(): void {
    this.getCompanyList();
  }
}
