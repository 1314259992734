import { Injectable } from '@angular/core';
import { SideListModel } from '@app/core/Models';
import { ImportModel } from '@app/core/Models/common/import';
import { CommonService } from '@app/core/Services';
import { ImportAllService } from '@app/core/Services/import-all/import-all';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  DownloadImportedFile,
  GetCapiumImportSetting,
  GetImportHistory,
  GetImportTemplateAll,
  ImportModulesAll,
  SaveCapiumImportSetting,
  SaveImportAll,
} from './import-all.action';

export class ImportAllStateInfo {
  exported?: boolean;
  importData?: any;
  saveImportData?: any;
  importedFiles: Array<ImportModel>;
  capiumImportSetting: any;
  saveCapiumImportSettingData?: any;
  isDuplicateExist?: boolean;
  vATSchemeList: Array<SideListModel>;
}

@State<ImportAllStateInfo>({
  name: 'importAll',
})
@Injectable()
export class ImportStateAll {
  constructor(
    private importService: ImportAllService,
    private commonService: CommonService
  ) {}

  @Selector()
  static getImportData(state: ImportAllStateInfo) {
    return state.importData;
  }

  @Selector()
  static getImportedFiles(state: ImportAllStateInfo) {
    return state.importedFiles;
  }

  @Action(GetImportTemplateAll)
  getImportTemplate(
    { patchState }: StateContext<ImportAllStateInfo>,
    action: GetImportTemplateAll
  ) {
    return this.importService.getImportTemplate(action.moduleId).pipe(
      tap((res) => {
        patchState({
          exported: res,
        });
      })
    );
  }

  @Action(ImportModulesAll)
  importModules(
    { patchState }: StateContext<ImportAllStateInfo>,
    action: ImportModulesAll
  ) {
    return this.importService
      .importModules(action.fileImportRequestModel, action.moduleId)
      .pipe(
        tap((res) => {
          patchState({
            importData: res,
          });
        })
      );
  }

  @Action(SaveImportAll)
  saveImport(
    { patchState }: StateContext<ImportAllStateInfo>,
    action: SaveImportAll
  ) {
    return this.importService.saveImport(action.param, action.moduleId).pipe(
      tap((res) => {
        patchState({
          saveImportData: res,
        });
      })
    );
  }

  @Action(GetImportHistory)
  getImportHistory(
    { getState, setState }: StateContext<ImportAllStateInfo>,
    action: GetImportHistory
  ) {
    return this.importService.getImportHistory().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          importedFiles: res,
        });
      })
    );
  }

  @Action(DownloadImportedFile)
  downloadImportedFile(
    { patchState }: StateContext<ImportAllStateInfo>,
    action: DownloadImportedFile
  ) {
    return this.importService.downloadImportedFile(action.param).pipe(
      tap((res) => {
        patchState({
          exported: res,
        });
      })
    );
  }

  @Action(GetCapiumImportSetting)
  getCapiumImportSetting(
    { patchState }: StateContext<ImportAllStateInfo>,
    action: GetCapiumImportSetting
  ) {
    return this.importService.getCapiumImportSetting(action.moduleId).pipe(
      tap((res) => {
        patchState({
          capiumImportSetting: res,
        });
      })
    );
  }

  @Action(SaveCapiumImportSetting)
  saveCapiumImportSetting(
    { patchState }: StateContext<ImportAllStateInfo>,
    action: SaveCapiumImportSetting
  ) {
    return this.importService.saveCapiumImportSetting(action.param).pipe(
      tap((res) => {
        patchState({
          saveCapiumImportSettingData: res,
        });
      })
    );
  }
}
