import { Guid } from 'guid-typescript';

export class GeneralDashboardCompany {
  id: Guid;
  sNo: number;
  companyRefNo: string;
  companyName: string;
  isDefault: boolean;
  companyType: string;
  financialPeriod: string;
  vATNumber: string;
}

export class GeneralDashBoardChartQueryParams {
  month: string;
  year: string;
}

export class GeneralDashboardBarChart {
  month: string;
  vatReturnCount: number;
}

export class GeneralDashboardPieChartData {
  label: string;
  value: number;
}
