import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  AccountEntity,
  GroupNames,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
  RecurringType,
  ScheduleSubPeriod,
  ScheduleType,
} from '@app/core/Enum';
import {
  Currency,
  GlobalComponent,
  GroupListModel,
  SideListModel,
} from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import {
  CommonState,
  CreateAccount,
  GetAllBranchList,
  GetAllDepartmentListByBranch,
  GetCurrencyList,
  GetDataByAccountId,
  GetGroupAccountsBasedOnGroupId,
  GetNonStandardAccountList,
  GetNonStandardAccountListByBranch,
  GetTermsList,
  MenuState,
} from '@app/core/Store';
import { QuickAddComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-recurring-module',
  templateUrl: './add-recurring-module.component.html',
  styleUrls: ['./add-recurring-module.component.scss'],
})
export class AddRecurringModuleComponent implements OnInit {
  recurringForm: FormGroup;
  @ViewChild('accountGroup') matAutocomplete;

  currencyList: Currency[];
  accountList: SideListModel[];
  weekList: string[] = [];
  monthList: string[] = [];
  dayList: any[] = [];
  dueTermList: SideListModel[];
  accountGroupList: GroupListModel[];
  tempAccountGroupList: GroupListModel[];

  @Input() triggerEditData: Observable<any>;
  @Input() moduleId: number;
  subModuleId = Modules;
  @Input()
  getModuleId: number;
  subscriptionRouting: Subscription;
  notificationMessage = NotificationTextMessage;
  minEndDate: Date;
  minStartDate: Date;
  periodicDate: any;
  customerList: SideListModel[];
  recurringType = RecurringType;
  scheduleSubPeriod = ScheduleSubPeriod;
  scheduleType = ScheduleType;
  moduleEnum = Modules;
  maxLength = MaxLength;
  defaultCurrency: Guid;
  disabledEditButton = true;
  recurringTypeList: any[] = [
    {
      value: RecurringType.Daily,
      name: RecurringType[RecurringType.Daily],
    },
    {
      value: RecurringType.Weekly,
      name: RecurringType[RecurringType.Weekly],
    },
    {
      value: RecurringType.Monthly,
      name: RecurringType[RecurringType.Monthly],
    },
    {
      value: RecurringType.Yearly,
      name: RecurringType[RecurringType.Yearly],
    },
    {
      value: RecurringType.Custom,
      name: RecurringType[RecurringType.Custom],
    },
  ];

  scheduleSubPeriodList: any[] = [
    {
      value: ScheduleSubPeriod.Day,
      name: 'Day(s)',
    },
    {
      value: ScheduleSubPeriod.Week,
      name: 'Week(s)',
    },
    {
      value: ScheduleSubPeriod.Month,
      name: 'Month(s)',
    },
    {
      value: ScheduleSubPeriod.Year,
      name: 'Year(s)',
    },
  ];

  scheduleTypeList: any[] = [
    {
      value: ScheduleType.After,
      name: ScheduleType[ScheduleType.After],
    },
    {
      value: ScheduleType.On,
      name: ScheduleType[ScheduleType.On],
    },
    {
      value: ScheduleType.Never,
      name: ScheduleType[ScheduleType.Never],
    },
  ];
  departmentList: any[] = [];
  branchList: any[] = [];

  isReadOnly = false;
  private destroy$ = new Subject<void>();

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;
  @Output()
  readonly branchChange = new EventEmitter<any>();
  @Output()
  readonly isDiscountInPer = new EventEmitter<any>();
  isDiscountInPercentage = false;
  isCustomerChangePermission: boolean = true;
  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    public dialog: MatDialog,
    private renderer: Renderer2
  ) {
    this.minStartDate = new Date();
    this.minEndDate = new Date();
    this.minEndDate.setDate(this.minEndDate.getDate() + 1);
  }

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setForm();

    this.triggerEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isReadOnly = true;
        this.bindMonthList();
        this.bindWeekList();
        this.bindDayList();
        this.getCurrency();
       // this.getInvoiceType();
        this.getDueTermList();
        this.setMinEndDate();
        this.getBranchList(true);
        this.editRecurringData(data);
      });

    this.recurringForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.recurringForm.touched;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.moduleId === Modules.RecurringInvoice) {
        this.isCustomerChangePermission = this.commonService.checkPermission(
          Modules.Contacts,
          Modules.Suppliers
        );
      } else
        this.isCustomerChangePermission = this.commonService.checkPermission(
          Modules.Contacts,
          Modules.Customers
        );
    }, 3000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onDicountInPercentageSelected(event: any): void {
    this.isDiscountInPercentage = event.checked;
    this.isDiscountInPer.emit(this.isDiscountInPercentage);
  }

  resetAccountList(): void {
    this.accountGroupList = this.tempAccountGroupList;
    this.scrollIntoView(this.recurringForm.controls.invoiceType.value);
  }

  getInvoiceType(): void {
    const groupNames = new Array<number>();
    if (this.moduleId === Modules.RecurringInvoice) {
      groupNames.push(GroupNames.Turnover);
    } else if (this.moduleId === Modules.RecurringPurchase) {
      groupNames.push(GroupNames.CostOfSales);
      groupNames.push(GroupNames.SellingAndDistributionCosts);
      groupNames.push(GroupNames.AdministrativeExpenses);
    }

    this.store
      .dispatch(new GetGroupAccountsBasedOnGroupId(groupNames, this.moduleId))
      .pipe(
        tap((res) => {
          this.accountGroupList = res.common.accountGroupList;
          this.tempAccountGroupList = this.accountGroupList;
        })
      )
      .subscribe();
  }

  getOptionText(option) {
    return option.name;
  }

  onSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    if (list.length === 0) {
      this.recurringForm.controls.invoiceType.setValue('');
      this.accountGroupList = this.tempAccountGroupList;
    }
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    this.accountGroupList = list;
  }

  gotoAddCustomer(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId:
            this.moduleId === Modules.RecurringInvoice
              ? Modules.Customers
              : Modules.Suppliers,
          headerText: `Add 
          ${
            this.moduleId === Modules.RecurringInvoice
              ? ModuleName.Customers
              : ModuleName.Suppliers
          }`,
          saveActionName: CreateAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledEditButton = false;
          this.getAccoutList(id);
        }
      });
  }

  gotoEditCustomer(): void {
    if (this.accountList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId:
              this.moduleId === Modules.RecurringInvoice
                ? Modules.Customers
                : Modules.Suppliers,
            headerText: `Edit ${
              this.moduleId === Modules.RecurringInvoice
                ? ModuleName.Customers
                : ModuleName.Suppliers
            }`,
            id: this.recurringForm.controls.accountId.value,
            saveActionName: CreateAccount,
            getActionName: GetDataByAccountId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getAccoutList(id);
          }
        });
    }
  }

  editRecurringData(data): void {
    // let account;
    // this.accountGroupList.forEach((items) => {
    //   const accountId = items.listModels.find(
    //     (x: any) => x.id === data.bookAccountId
    //   );
    //   if (accountId !== null && accountId !== undefined) {
    //     account = accountId;
    //   }
    // });
    this.recurringForm.controls.recurringNo.disable();

    this.recurringForm.controls.recurringNo.disable();
    const x = this.accountList.filter((x) => x.id === data.accountId);
    this.recurringForm.patchValue({
      recurringNo: data.entryNumber,
      accountId: x.length > 0 ? data.accountId : '',
      dueTermName: data.dueTermId,
      currency: data.currencyId,
      notes: data.note,
      recurringType: data.recurringTypeId,
      scheduleSubPeriod: data.subRecurringTypeId,
      dayOfWeek: data.dayOfWeek,
      dayOfMonth: data.dayOfMonth,
      separationCount: data.separationCount,
      maxNumOfOccurrences: data.maxNumOfOccurrences,
      monthOfYear: data.monthOfYear,
      startDate: data.startDate,
      endDate: data.endDate,
      scheduleType: data.recurringEndTypeId,
      //invoiceType: account,
      branchId: data.branchId,
      departmentId: data.departmentId,
    });
    this.disabledEditButton =
      this.recurringForm.controls.accountId.value === '';
    if (data.branchId !== null && data.branchId !== undefined) {
      this.getDepartmentListByBranch(data.branchId);
    }
  }

  bindMonthList(): void {
    this.monthList = Array.from({ length: 12 }, (e, i) => {
      return new Date(0, i + 1, 0).toLocaleDateString('en', {
        month: 'long',
      });
    });
  }

  setMinEndDate(): void {
    if (this.recurringForm.controls.scheduleType.value === ScheduleType.On) {
      this.minEndDate = new Date(this.recurringForm.controls.startDate.value);
      this.minEndDate.setDate(this.minEndDate.getDate() + 1);
      this.recurringForm.controls.endDate.setValue(this.minEndDate);
    }
  }

  bindWeekList(): void {
    this.weekList = Array.from({ length: 7 }, (e, i) => {
      return new Date(0, 0, i).toLocaleDateString('en', {
        weekday: 'long',
      });
    });
  }

  bindDayList(): void {
    for (let i = 1; i <= 32; i++) {
      let label = '';
      if (i === 1) {
        label = 'First';
      } else if (i === 2) {
        label = 'Last';
      } else if (i === 3 || i === 23) {
        label = i - 2 + 'st';
      } else if (i === 4 || i === 24) {
        label = i - 2 + 'nd';
      } else if (i === 5 || i === 25) {
        label = i - 2 + 'rd';
      } else if (i >= 6) {
        label = i - 2 + 'th';
      }

      const obj = {
        value: i,
        lable: label,
      };
      this.dayList.push(obj);
    }
  }

  setForm(): void {
    this.recurringForm = new FormGroup({
      recurringNo: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      accountId: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      dueTermName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      currency: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      notes: new FormControl(''),
      recurringType: new FormControl<number | null>(1),
      scheduleSubPeriod: new FormControl<number | null>(1),
      dayOfWeek: new FormControl<number | null>(1),
      dayOfMonth: new FormControl<number | null>(1),
      separationCount: new FormControl<number | null>(1),
      maxNumOfOccurrences: new FormControl<number | null>(1),
      monthOfYear: new FormControl<number | null>(1),
      startDate: new FormControl<Date | null>(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      endDate: new FormControl<Date | null>(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      scheduleType: new FormControl<number | null>(1),
      invoiceType: new FormControl(''),
      branchId: new FormControl(''),
      departmentId: new FormControl(''),
    });

    this.bindMonthList();
    this.bindWeekList();
    this.bindDayList();
    this.getCurrency();
    //this.getInvoiceType();
    this.getDueTermList();
    this.setMinEndDate();
    this.getBranchList(true);
  }

  getCurrency(): void {
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          this.recurringForm.controls.currency.setValue(this.defaultCurrency);
        })
      )
      .subscribe();
  }

  getAccoutList(id?: Guid): void {
    const entityId = new Array<number>();
    this.moduleId$.subscribe((x) => {
      entityId.push(
        x === Modules.RecurringInvoice
          ? AccountEntity.Customer
          : AccountEntity.Supplier
      );
    });

    let actionName;
    if (this.branchList.length > 0) {
      actionName = GetNonStandardAccountListByBranch;
    } else {
      actionName = GetNonStandardAccountList;
    }

    this.store
      .dispatch(
        new actionName(entityId, this.recurringForm.controls.branchId.value)
      )

      .pipe(
        tap((res) => {
          this.accountList = res.common.accountList;
          if (this.accountList.length > 0) {
            if (id !== null && id !== undefined) {
              this.recurringForm.controls.accountId.setValue(id);
            }
          }
        })
      )
      .subscribe();
  }

  getDueTermList(): void {
    this.store
      .dispatch(new GetTermsList())
      .pipe(
        tap((res) => {
          this.dueTermList = res.account.termsList;
          this.recurringForm.controls.dueTermName.setValue(
            this.dueTermList[0].id
          );
        })
      )
      .subscribe();
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }
  getBranchList(isOnLoad): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.recurringForm.controls.branchId.setValue(
              this.branchList[0].id
            );
            this.recurringForm.controls.branchId.setValidators(
              Validators.required
            );
            this.recurringForm.controls.branchId.updateValueAndValidity();
            this.onChangeBranch(this.branchList[0].id, isOnLoad);
          } else {
            this.branchList = [];
            this.departmentList = [];
            this.getAccoutList();
          }
        })
      )
      .subscribe();
  }

  onChangeBranch(branch, isOnLoad): void {
    this.getDepartmentListByBranch(branch);
    this.getAccoutList();
    if (!isOnLoad) {
      this.recurringForm.controls.accountId.setValue('');
      this.branchChange.emit(branch);
    }
  }

  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .pipe(
          tap((res) => {
            this.departmentList = [];
            if (res.company.department.length > 0) {
              this.departmentList = res.company.department;
              this.recurringForm.controls.departmentId.setValue(
                this.departmentList[0].id
              );
              this.recurringForm.controls.departmentId.setValidators(
                Validators.required
              );
              this.recurringForm.controls.departmentId.updateValueAndValidity();
            } else {
              this.recurringForm.controls.departmentId.clearValidators();
              this.recurringForm.controls.departmentId.updateValueAndValidity();
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
      this.recurringForm.controls.departmentId.clearValidators();
      this.recurringForm.controls.departmentId.updateValueAndValidity();
    }
  }
}
