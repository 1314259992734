import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showWholeIfNoDecimal',
})
export class ShowWholeIfNoDecimalPipe implements PipeTransform {
  transform(value: number): string {
    if (value == null) {
      return '';
    }
    value = Number(value);
    const hasDecimal = value % 1 !== 0;
    const isNegative = value < 0;

    if (hasDecimal) {
      return isNegative
        ? `(${Math.abs(value).toFixed(2)})`
        : value.toFixed(2).toString();
    } else {
      return isNegative ? `(${Math.abs(value)})` : value.toString();
    }
  }
}
