<div class="mat-dialog-wrapper preview-details">
  <mat-dialog-content>
    <form>
      <div class="main-contain">
        <div class="sidenav-header">
          <div class="d-flex align-items-center justify-space-between mb-10">
            <h2 class="m-0">
              <span>Add Template</span>
            </h2>
            <div>
              <mat-icon class="close cursor-pointer" mat-dialog-close
                >close</mat-icon
              >
            </div>
          </div>
        </div>
      </div>

      <div class="content-body p-0">
        <div class="form-field">
          <p>Template Name <span class="text-danger-300">*</span></p>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              appAutoFocusDirective
            />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Bank</p>
          <ng-select
            #bank
            (focus)="bank.open()"
            (valueChange)="bank.close()"
            [disableOptionCentering]="true"
            panelClass="mat-select-position"
          >
            <ng-option>
              <div title="Select Bank">Select Bank</div>
            </ng-option>
          </ng-select>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="m-0 justify-content-end hr pt-1">
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      mat-dialog-close
    >
      Cancel
    </button>
    <button
      class="action-button primary-button"
      type="button"
      mat-button
      disableRipple
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
