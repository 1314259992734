import {
  FlatRateAdjustmentJournalModel,
  IncluceExcludeTransactionModel,
  MainListParameters,
  SubmitVATModel,
} from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class GetVatDetails {
  static readonly type = '[VATSETTINGS] Get VAT deatils';
  constructor(public id: Guid) {}
}

export class SetDefaultVatId {
  static readonly type = '[VATSETTINGS] Set Default VatId';
}

export class CreateVat {
  static readonly type = '[VATSETTINGS] Create VAT';

  constructor(public vatData: SubmitVATModel) {}
}

export class SetSubmitVatId {
  static readonly type = '[VATSETTINGS] Set Submit Vat Id';

  constructor(public id: Guid) {}
}

export class CreateVATReturnStatus {
  static readonly type = '[VATSETTINGS] Create VATReturnStatus';

  constructor(public vatReturnStatus: number, public id: Guid) {}
}

export class GetVatReturnDate {
  static readonly type = '[VATSETTINGS] Get Vat Return Date';
}

export class GetVatRemainingTransaction {
  static readonly type = '[VATSETTINGS] Get Vat Remaining Transaction List ';

  constructor(
    public queryParams: MainListParameters,
    public isHeaderVisible: boolean,
    public id: Guid
  ) {}
}

export class CheckVatNumber {
  static readonly type = '[VATSETTINGS] Check Vat Number ';
}

export class GetvatRemainingTransactionHeader {
  static readonly type = '[VATHEADERLIST] Get Vat Header List ';
}

export class SaveIncludeExclude {
  static readonly type = '[VATSUBMIT] Save Include Exclude submitlist';

  constructor(
    public params: IncluceExcludeTransactionModel,
    public id: Guid,
    public isInclude: boolean
  ) {}
}

export class DeleteSubmitVat {
  static readonly type = '[VATSUBMIT] Delete Selected Submit Vat';

  constructor(public ids: Array<Guid>) {}
}

export class DeleteBridgingVat {
  static readonly type = '[VATSUBMIT] Delete Selected Bridging Vat';

  constructor(public ids: Array<Guid>) {}
}

export class ExportVatAudit {
  static readonly type = '[VATSUBMIT] Export VAT Audit Report';

  constructor(public id: Guid, public isPrint: boolean) {}
}

export class GetSubmitVatList {
  static readonly type = '[VATSUBMIT] Get Submit Vat List';

  constructor(
    public queryParams: MainListParameters,
    public moduleId: number
  ) {}
}

export class UpdatePaymentStatus {
  static readonly type = '[VATSUBMIT] Update Payment Status';

  constructor(public paymentStatusUpdateModel: any) {}
}

export class GetVatPaymentStatusView {
  static readonly type = '[VATSUBMIT] Get Vat Payment Status View';

  constructor(public id: Guid) {}
}

export class GetFlatRateAdjustmentCalculation {
  static readonly type = '[VATSUBMIT] Get Flat Rate Adjustment Calculation';

  constructor(public id: Guid) {}
}

export class GetFinalReturn {
  static readonly type = '[VATSUBMIT] Get Final Return';
}

export class FinalVATUpdate {
  static readonly type = '[VATSUBMIT] Final VAT Update';
}

export class RevertPaymentStatus {
  static readonly type = '[VATSUBMIT] Revert Payment Status';

  constructor(public id: Guid) {}
}

export class VATFlatRatePosting {
  static readonly type = '[VATSUBMIT] VAT Flat Rate Posting';

  constructor(public model: FlatRateAdjustmentJournalModel) {}
}
