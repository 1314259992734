import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RetrieveSubscription } from '@app/core/Store';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-save-and-invite-popup',
  templateUrl: './save-and-invite-popup.component.html',
  styleUrls: ['./save-and-invite-popup.component.scss'],
})
export class SaveAndInvitePopupComponent implements OnInit {
  subscriptionModuleList: any = [];

  constructor(
    public dialogRef: MatDialogRef<SaveAndInvitePopupComponent>,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.retrieveSubscription();
  }
  onCloseClick(): void {
    this.dialogRef.close();
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }

  retrieveSubscription(): void {
    this.store.dispatch(new RetrieveSubscription()).subscribe(
      (res) => {
        this.subscriptionModuleList =
          res.subscription.retrieveSubscriptionList.listOfAddons ?? [];
      },
      (error) => {
        this.subscriptionModuleList = [];
      }
    );
  }
}
