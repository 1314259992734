import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ComponentName, DataType, Modules, RoutingPath } from '@app/core/Enum';
import {
  MenuModel,
  PermissionModel,
  SalesBillReportModel,
  VATReportModel,
} from '@app/core/Models';
import { CommonService, ModulePermission } from '@app/core/Services';
import {
  CommonState,
  GetVatReportDetailList,
  GetVatReportList,
  MenuState,
  SetModulePermission,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-vat-report',
  templateUrl: './vat-report.component.html',
  styleUrls: ['./vat-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('isExpanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'isExpanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class VatReportComponent implements OnInit, OnDestroy {
  vATReportList: VATReportModel[];
  salesVATList: SalesBillReportModel[];
  billVATList: SalesBillReportModel[];

  @Output()
  readonly triggerDataFromVatReport = new EventEmitter<any>();

  displayedColumns = ['name', 'netAmount', 'vatAmount', 'totalAmount'];

  @Input()
  listParameters: any;

  vatReportDetailList: any;
  vatId: number;
  currentRowSelected: any;
  dataType = DataType;

  noDataFound = false;
  showPaginator = true;

  dataSource = new MatTableDataSource();

  @Select(CommonState.vatColumns)
  columns$: Observable<
    Array<{
      name: string;
      value: string;
      dataType?: DataType;
      allowSortBy?: boolean;
      alignment: string;
    }>
  >;

  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;

  @Select(CommonState.vatColumnNames)
  columnsNames$: Observable<Array<string>>;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  triggerPaginationChange: Subject<any> = new Subject<any>();
  modulePermission: ModulePermission;

  @ViewChild(MatSort) sort: MatSort;
  @Input() triggerVatApi: Observable<any>;
  private destroy$ = new Subject<void>();
  constructor(
    public datepipe: DatePipe,
    public store: Store,
    public dialog: MatDialog,
    public commonService: CommonService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private injector: Injector
  ) {
    this.modulePermission = injector.get<ModulePermission>(ModulePermission);
  }

  ngOnInit(): void {
    this.triggerVatApi
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.getResponse();
        }
      });

    this.getResponse();
  }

  getResponse(): void {
    const queryParams = {
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      branchIds: this.listParameters.branchIds ?? [],
      departmentIds: this.listParameters.departmentIds ?? [],
    };

    this.store.dispatch(new GetVatReportList(queryParams)).subscribe((res) => {
      this.vATReportList = res.common.vatReportList.columnModel;
      this.salesVATList = res.common.vatReportList.salesVAT;
      this.billVATList = res.common.vatReportList.purchaseVAT;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  redirect(id: any, moduleId: number): void {
    if (moduleId > 0) {
      if (moduleId === Modules.AddCustomAccounts) {
        this.commonService.onEditRouting(
          true,
          Modules.ReportAccountDetail,
          id,
          false
        );
        this.setPermission(RoutingPath.ReportAccountDetailsPermission, id);
      } else if (
        moduleId === Modules.Customers ||
        moduleId === Modules.Suppliers
      ) {
        this.commonService.onEditRouting(true, moduleId, id);
      } else if (this.commonService.isAllowedOpenView(moduleId)) {
        this.commonService.openView(id, moduleId);
      } else {
        this.commonService.onEditRouting(true, moduleId, id);
      }
    }
  }

  emitPermissionData(data: any, componentName: any, id: any): void {
    const permissionData = new PermissionModel();
    permissionData.data = data;
    permissionData.componentName = componentName;
    this.modulePermission.permissionData.next(permissionData);
  }

  setData(event: any, data: any, componentName: any, id: any): void {
    data.forEach((x) => {
      if (x.url === event.slice(1) || x.addUrl === event.slice(1)) {
        this.store.dispatch(new SetModulePermission(x)).subscribe();
        this.emitPermissionData(x, componentName, id);
      } else {
        x.subMenu.map((y) => {
          if (
            y.url === event.slice(1) ||
            y.addUrl === event.slice(1).split(';')[0]
          ) {
            this.store.dispatch(new SetModulePermission(y)).subscribe();
            this.emitPermissionData(y, componentName, id);
          } else {
            y.subMenu.map((z) => {
              if (
                z.url === event.slice(1) ||
                z.addUrl === event.slice(1).split(';')[0]
              ) {
                this.store.dispatch(new SetModulePermission(z)).subscribe();
                this.emitPermissionData(z, componentName, id);
              } else {
                z.subMenu.map((z1) => {
                  if (
                    z1.url === event.slice(1) ||
                    z1.addUrl === event.slice(1).split(';')[0]
                  ) {
                    this.store
                      .dispatch(new SetModulePermission(z1))
                      .subscribe();
                    this.emitPermissionData(z1, componentName, id);
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  setPermission(url: any, id: any): void {
    setTimeout(() => {
      this.menuList$.subscribe((data) => {
        this.setData(url, data, ComponentName.DynamicGridListComponent, id);
      });
    }, 500);
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.getList();
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.getList();
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.getList();
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      branchIds: this.listParameters.branchIds ?? [],
      departmentIds: this.listParameters.departmentIds ?? [],
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    return queryParams;
  }

  expandCollapse(row, filter): void {
    if (this.currentRowSelected && this.currentRowSelected !== row) {
      this.currentRowSelected.isExpanded = false;
    }
    this.currentRowSelected = row;

    this.vatId = row.vatId;
    if (row.isExpanded) {
      row.isExpanded = false;
    } else {
      row.isExpanded = true;
      this.listParameters.filter = filter;
      this.getList();
    }
  }

  getList(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetVatReportDetailList(this.vatId, this.getParamter()))
      .subscribe((res) => {
        this.spinner.hide();
        this.vatReportDetailList =
          res.common.vatReportDetailList.resultSet.data;
        this.noDataFound = this.vatReportDetailList.length > 0 ? false : true;
        this.dataSource.data = this.vatReportDetailList;
      });
  }
}
