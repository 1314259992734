<div class="content-body">
  <div class="mb-1">
    <button
      class="action-button primary-button"
      mat-button
      disableRipple
      (click)="onAccountsClick()"
    >
      <mat-icon>add</mat-icon>
      <span>Include/Exclude Accounts</span>
    </button>
  </div>
  <div class="mat-table-wrapper budgeting-details-list">
    <table mat-table aria-describedby="main-list" [dataSource]="dataSource">
      <ng-container
        *ngFor="let col of columns$ | async; let colIndex = index"
        matColumnDef="{{ col.name }}"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [id]="col.name"
          [ngClass]="colIndex === 1 ? 'w-200' : ''"
        >
          <div class="d-flex align-items-center">
            <span [disabled]="!col.name">
              {{ col.name }}
            </span>
          </div>
        </th>

        <td mat-cell *matCellDef="let element; let i = index">
          <div
            class="d-flex align-items-center button-wrapper"
            [ngClass]="
              colIndex === (columns$ | async).length - 1
                ? 'justify-space-between'
                : ''
            "
          >
            <span
              class="fw-bold"
              *ngIf="
                element[element.length - 1] === 'true' &&
                colIndex === 1 &&
                element[1] !== 'Total'
              "
            >
              {{ element[1] }}
            </span>

            <span
              *ngIf="
                element[element.length - 1] === 'false' ||
                (element[element.length - 1] === 'true' &&
                  element[1] === 'Total')
              "
            >
              <span
                *ngIf="col.dataType === dataType.String"
                title="{{ element[colIndex] }}"
                [ngClass]="element[1] === 'Total' ? 'fw-bold' : ''"
              >
                {{ element[colIndex] }}
              </span>
              <span
                *ngIf="col.dataType === dataType.Bool"
                title="{{ element[colIndex] }}"
                [ngClass]="
                  element[colIndex] === 'Active'
                    ? 'status-active'
                    : 'status-archived'
                "
              >
                {{ element[colIndex] }}</span
              >
              <span
                *ngIf="col.dataType === dataType.DateTime"
                title="{{ element[colIndex] }}"
              >
                {{ element[colIndex] | date: "dd-LLL-yyyy" }}</span
              >
              <span
                *ngIf="
                  col.dataType === dataType.Int32 ||
                  col.dataType === dataType.Currency
                "
                title="{{ element[colIndex] | numberPipe }}"
              >
                £ {{ element[colIndex] | numberPipe }}</span
              >
              <span *ngIf="col.dataType === dataType.InputDecimal">
                <span
                  *ngIf="element[element.length - 1] === 'true'"
                  [ngClass]="element[1] === 'Total' ? 'fw-bold' : ''"
                >
                  {{ element[colIndex] | numberPipe }}
                </span>
                <span *ngIf="element[element.length - 1] !== 'true'">
                  <mat-form-field floatLabel="never">
                    <input
                      allowDecimal
                      matInput
                      [(ngModel)]="element[colIndex]"
                      (keyup)="onInputChange(colIndex, i)"
                      autocomplete="off"
                      maxlength="{{ maxLength.maxLength10 }}"
                    />
                  </mat-form-field>
                </span>
              </span>
              <span *ngIf="col.dataType === dataType.InputCalculator">
                <span
                  *ngIf="element[element.length - 1] === 'true'"
                  [ngClass]="element[1] === 'Total' ? 'fw-bold' : ''"
                >
                  {{ element[colIndex] | numberPipe }}
                </span>
                <span
                  *ngIf="element[element.length - 1] !== 'true'"
                  class="d-flex gap-10"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      allowDecimal
                      matInput
                      [(ngModel)]="element[colIndex]"
                      autocomplete="off"
                      (keyup)="onInputChange(colIndex, i)"
                      maxlength="{{ maxLength.maxLength10 }}"
                    />
                  </mat-form-field>
                  <button
                    (click)="calculateAmount(element, colIndex, i)"
                    mat-button
                    class="fw-bold text-primary"
                    disableRipple
                  >
                    <mat-icon>calculate</mat-icon>
                  </button>
                </span>
              </span>
            </span>
          </div>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="columnsNames$ | async; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsNames$ | async"
        [ngClass]="isRowHighlighted ? 'highlightRow' : ''"
      ></tr>
    </table>
  </div>
  <div
    *ngIf="dataSource.data.length === 0 && noDataFound"
    class="text-align-center mt-1 fw-bold"
  >
    No records found
  </div>
</div>
