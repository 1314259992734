import { Guid } from 'guid-typescript';

export class SaveInventoryLossOfStock {
  static readonly type = '[InventoryLossOfStock] SaveInventoryLossOfStock';

  constructor(public data: any) {}
}

export class GetInventoryLossOfStock {
  static readonly type = '[InventoryLossOfStock] Get Inventory Loss Of Stock';

  constructor(public id: Guid) {}
}

export class DeleteInventoryLossOfStock {
  static readonly type =
    '[InventoryLossOfStock] Delete Inventory Loss Of Stock';

  constructor(public ids: Array<Guid>) {}
}
