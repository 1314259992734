import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  HeaderTextForSideList,
  ImportStep,
  ModuleName,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { ImportModel } from '@app/core/Models/common/import';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  DownloadImportedFile,
  GetImportHistory,
  ImportStateAll,
  SaveImportAll,
} from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ImportDetailsSettingsComponent } from './import-details-settings/import-details-settings.component';

@Component({
  selector: 'app-import-settings',
  templateUrl: './import-settings.component.html',
  styleUrls: ['./import-settings.component.scss'],
})
export class ImportSettingsComponent implements OnInit {
  showImport = false;
  showImportSales = false;
  showImportButton = false;
  specialCar = '"!@#$%^&*():"<>?{}|[]"';

  displayedColumns: string[] = [
    'name',
    'function',
    'import',
    'imported',
    'status',
  ];
  moduleName = ModuleName;
  modules = Modules;
  moduleId: number | undefined;
  importedFileList: Array<ImportModel>;
  importHeaderText = '';

  triggerImport: Subject<boolean> = new Subject<boolean>();
  triggerImportDetail: Subject<boolean> = new Subject<boolean>();

  triggerSend: Subject<boolean> = new Subject<boolean>();
  triggerCancelSend: Subject<boolean> = new Subject<boolean>();

  @ViewChild(ImportDetailsSettingsComponent, { static: false })
  importDetailsData;
  triggerGetImportDetail: Subject<boolean> = new Subject<boolean>();
  constructor(
    private spinner: NgxSpinnerService,
    private notifier: NotificationService,
    private store: Store,
    public dialog: MatDialog,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getImportHistory();
  }

  onNextClick() {
    if (
      this.importDetailsData.myStepper?.selectedIndex === 0 &&
      !this.showImport
    ) {
      this.triggerGetImportDetail.next(true);
    }
    this.showImport = true;
    this.setHeaderTextToImport();
    this.triggerImport.next(true);
  }

  onCancelClick(isCloseClick) {
    this.moduleId = undefined;
    this.showImport = false;
    this.showImportButton = false;
    this.importDetailsData.selectedFile = '';
    this.importDetailsData.url = '';
    this.importDetailsData.fileInfo = '';
    this.importDetailsData.selectedFileName = '';
    this.importDetailsData.fileInput.nativeElement.value = '';
    this.importDetailsData.step = ImportStep.Select;
    this.importDetailsData.myStepper.selectedIndex = 0;
    this.importDetailsData.importErrorList.data = new MatTableDataSource([]);
    this.importDetailsData.importDuplicateList.data = new MatTableDataSource(
      []
    );
    this.importDetailsData.importSuccessList.data = new MatTableDataSource([]);
    if (isCloseClick) {
      this.getImportHistory();
    }
  }

  radioChange(event) {
    this.moduleId = event.value;
    this.showImportButton = true;
  }

  showSalesTeam(value): void {
    this.showImportSales = value;
  }

  getImportHistory(): void {
    this.store
      .dispatch(new GetImportHistory())
      .pipe(
        tap(() => {
          this.importedFileList = this.store.selectSnapshot(
            ImportStateAll.getImportedFiles
          );
        })
      )
      .subscribe();
  }

  triggerCloseClick(): void {
    this.showImportSales = false;
  }

  onSendClick() {
    this.showImport = true;

    this.triggerSend.next(true);
  }

  cancelClick() {
    this.triggerCancelSend.next(true);
  }

  downloadFile(url: string): void {
    this.spinner.show();
    const params = {
      fileUrl: url,
      attechmentType: 0,
    };

    this.store.dispatch(new DownloadImportedFile(params)).subscribe();
  }

  onPrevClick() {
    this.importDetailsData.step = ImportStep.Upload;
    this.importDetailsData.myStepper.selectedIndex = 0;
  }

  onSaveNextClick(): void {
    this.saveImport();
  }

  saveImport(): void {
    const succesList: any[] = [];
    this.importDetailsData.importSuccessList.data.forEach((element) => {
      if (
        this.moduleId === Modules.Clients ||
        // || this.moduleId === Modules.Tasks
        this.moduleId === Modules.Users
        // || this.moduleId === Modules.ExpenseType
      ) {
        succesList.push(element);
      }
    });
    if (succesList.length > 0) {
      const param = {
        successList: succesList,
        duplicateList:
          this.importDetailsData?.importDuplicateList.data.length > 0
            ? this.importDetailsData.importDuplicateList.data
            : [],
        errorList:
          this.importDetailsData.importErrorList.data.length > 0
            ? this.importDetailsData.importErrorList.data
            : [],
        importedFile: this.importDetailsData.importedFile
      };
      this.spinner.show();
      this.store
        .dispatch(new SaveImportAll(param, this.moduleId!))
        .subscribe((res) => {
          if (res) {
            this.spinner.hide();
            this.showImport = true;
            this.triggerImportDetail.next(res.importAll.saveImportData);
          }
        });
    } else {
      this.onCancelClick(false);
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.successList
      );
    }
  }

  onIgnoreClick(): void {
    this.dialog
      .open(AddClosePopupComponent, {
        data: {
          moduleId: Modules.Import,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.saveImport();
        }
      });
  }

  isRadioSelected(value): boolean {
    return this.moduleId === value;
  }

  setHeaderTextToImport(): void {
    switch (this.moduleId) {
      case Modules.Clients:
        this.importHeaderText = HeaderTextForSideList.Clients;
        break;

      // case Modules.Tasks:
      //   this.importHeaderText = HeaderTextForSideList.Tasks;
      //   break;

      case Modules.Users:
        this.importHeaderText = HeaderTextForSideList.Users;
        break;

      // case Modules.ExpenseType:
      //   this.importHeaderText = HeaderTextForSideList.ExpenseTypes;
      //   break;

      default:
        break;
    }
  }
}
