import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FixedAssetDisposeModel,
  FixedAssetExplainModel,
  MainList,
  MainListParameters,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class FixedAssetsRegisterService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  saveDepreciation(fixedAssetDepriciationData: any[]): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}FixedAsset/depreciation/save`,
      this.commonService.trimObjectSpace(
        JSON.stringify(fixedAssetDepriciationData)
      ),
      headers
    );
  }

  getFixedAssetRegisterDataList(
    queryParams: MainListParameters,
    moduleId: number
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}FixedAssetRegister/depreciation/list/${moduleId}`,
      this.commonService.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  getDepreciationTransaction(queryParams: any, id: any): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}FixedAssetRegister/depreciation/Transaction/${id}`,
      this.commonService.trimObjectSpace(JSON.stringify(queryParams)),
      headers
    );
  }

  getDepreciationRollBackTransaction(id: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}FixedAssetRegister/depreciation/RollBackTransaction/${id}`,
      headers
    );
  }

  createFixedAssetExplain(
    fixedAssetExplain: FixedAssetExplainModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}FixedAssetRegister/explain/save`,
      this.commonService.trimObjectSpace(JSON.stringify(fixedAssetExplain)),
      headers
    );
  }

  getDataByAssetDetailId(
    assetDetailId: Guid
  ): Observable<FixedAssetExplainModel> {
    return this.http.get<FixedAssetExplainModel>(
      `${environment.apiVersionUrl}FixedAssetRegister/getExplain/${assetDetailId}`
    );
  }

  getFixedAssetDispose(disposeId: Guid): Observable<FixedAssetExplainModel> {
    return this.http.get<FixedAssetExplainModel>(
      `${environment.apiVersionUrl}FixedAssetRegister/getFixedAssetDispose/${disposeId}`
    );
  }

  getDepreciationChargeByDate(disposeId: Guid, date: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}FixedAssetRegister/DepreciationAmount/${disposeId}`,
      JSON.stringify(date),
      headers
    );
  }

  createFixedAssetDispose(
    fixedAssetDispose: FixedAssetDisposeModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}FixedAssetRegister/dispose/save`,
      this.commonService.trimObjectSpace(JSON.stringify(fixedAssetDispose)),
      headers
    );
  }

  activeDisposeId(activeDisposeId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}FixedAssetRegister/dispose/activate`,
      this.commonService.trimObjectSpace(JSON.stringify(activeDisposeId)),
      headers
    );
  }
}
