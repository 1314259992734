<div class="content-body">
  <form [formGroup]="billForm">
    <div class="d-flex gap-40">
      <div class="form-field fund-name" *ngIf="branchList.length > 0">
        <p>Branch <span class="text-danger-300">*</span></p>
        <ng-select
          #branch
          (focus)="branch.open()"
          (valueChange)="branch.close()"
          [disableOptionCentering]="true"
          formControlName="branchId"
          dropdownPosition="bottom"
          (change)="onChangeBranch($event, false)"
        >
          <ng-option *ngFor="let bl of branchList" [value]="bl.id">
            <div title="{{ bl.name }}">{{ bl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="departmentList.length > 0">
        <p>Department <span class="text-danger-300">*</span></p>
        <ng-select
          #department
          (focus)="department.open()"
          (valueChange)="department.close()"
          [disableOptionCentering]="true"
          formControlName="departmentId"
          dropdownPosition="bottom"
        >
          <ng-option *ngFor="let dl of departmentList" [value]="dl.id">
            <div title="{{ dl.name }}">{{ dl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Purchase No <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="billNo"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength100 }}"
          />
        </mat-form-field>
      </div>
      <div class="form-field datepicker">
        <p>Purchase Date. <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="billDate"
            [matDatepicker]="billDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="billDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #billDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(billForm.get('billDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(billForm.get('billDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Supplier Name <span class="text-danger-300">*</span></p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isSupplierChangePermission"
            >
              <mat-icon (click)="gotoAddSupplier()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              mat-button
              disableRipple
              [disabled]="disabledEditButton || !isSupplierChangePermission"
            >
              <mat-icon
                (click)="gotoEditSupplier()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #suppliername
          (focus)="suppliername.open()"
          (valueChange)="suppliername.close()"
          [disableOptionCentering]="true"
          formControlName="supplierId"
          panelClass="mat-select-position"
          (change)="supplierChange($event)"
        >
          <ng-option *ngFor="let option of supplierList" [value]="option.id">
            <div title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field datepicker">
        <p>Due Date <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="dueDate"
            [matDatepicker]="dueDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dueDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #dueDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(billForm.get('dueDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(billForm.get('dueDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field" style="display:none;">
        <p>Account Name <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            formControlName="billType"
            
            [matAutocomplete]="accountGroup"
            #trigger="matAutocompleteTrigger"
            appAutocompletePosition="trigger"
            (keyup)="onAccountSearch($event)"
            (keydown.Tab)="onSearch($event)"
            (change)="onSearch($event)"
            (mousedown)="resetAccountList()"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete
            [displayWith]="getOptionText"
            #accountGroup="matAutocomplete"
            (opened)="scrollIntoView(billForm.controls.billType.value)"
            (optionSelected)="onOptionSelected($event)"
          >
            <mat-optgroup
              *ngFor="let group of accountGroupList"
              [label]="group.groupName"
              title="{{ group.groupName }}"
            >
              <mat-option
                *ngFor="let names of group.listModels"
                [value]="names"
                title="{{ names.name }}"
                [class]="
                  this.billForm.controls.billType.value?.id === names.id
                    ? 'mat-selected'
                    : ''
                "
              >
                {{ names.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Currency <span class="text-danger-300">*</span></p>
        <ng-select
          #currency
          (focus)="currency.open()"
          (valueChange)="currency.close()"
          [disableOptionCentering]="true"
          formControlName="currency"
          panelClass="mat-select-position"
          (change)="currencyChange($event)"
        >
          <ng-option
            *ngFor="let cl of clientCurrencyList"
            [value]="cl.currencyId"
          >
            <div title="{{ cl.currency }}">
              {{ cl.currency }}
            </div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="isMultiCurrencySelected">
        <p>&nbsp;</p>
        <mat-form-field class="w-140 big-inputbox">
          <input
            matInput
            formControlName="currencyAmt"
            allowDecimal
            autocomplete="off"
            (change)="onCurrencyRateEntered()"
          />
          <button
            mat-button
            type="button"
            disableRipple
            matSuffix
            (click)="onCurrencyRefresh()"
          >
            <mat-icon>refresh</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div
        class="form-field flex-column justify-contents-center"
        *ngIf="isVatRegistered"
      >
        <div>
          <mat-checkbox
            formControlName="isVatInclude"
            (change)="onCheckBoxSelected($event)"
          >
            Amount Including VAT
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox
            formControlName="isVatPostponed"
            (change)="isVatPostponedChecked($event)"
          >
            {{
              (moduleId$ | async) === moduleId.Purchase
                ? "Use postponed VAT accounting"
                : "Apply CIS VAT Domestic Reverse Charge"
            }}
          </mat-checkbox>
        </div>
      </div>
      <div class="form-field" *ngIf="isFromBankImport">
        <p>Import Bank Payment <span class="text-danger-300"></span></p>
        <mat-form-field class="amt-inputbox">
          <input
            currencyMask
            allow_10_2_Decimal
            matInput
            autocomplete="off"
            formControlName="bankImportAmount"
            [disabled]="true"
          />
          <button mat-button type="button" disableRipple matSuffix>
            <span>{{ bankImportCurrency }}</span>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="form-field textarea">
      <p>Notes</p>
      <mat-form-field>
        <textarea
          matInput
          formControlName="notes"
          autocomplete="off"
          maxlength="{{ maxLength.maxLength2000 }}"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
