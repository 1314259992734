<div class="mat-dialog-wrapper" style="width: 600px">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">
      Amount Refund To {{ !data.isReceipt ? "Supplier" : "Customer" }}
    </h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onNoClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="form-field datepicker">
      <p>Date <span class="text-danger-300">*</span></p>
      <mat-form-field appearance="standard">
        <input
          matInput
          [matDatepicker]="entryDate"
          autocomplete="off"
          (change)="commonService.validateDate($event)"
          tabindex="-1"
          [(ngModel)]="date"
          required
          disabled
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="entryDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #entryDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="form-field" *ngIf="departmentList.length > 0">
      <p class="fw-bold">Department <span class="text-danger-300">*</span></p>
      <ng-select
        #department
        (focus)="department.open()"
        (valueChange)="department.close()"
        [disableOptionCentering]="true"
        [(ngModel)]="departmentId"
        (change)="departmentChange()"
        dropdownPosition="bottom"
      >
        <ng-option *ngFor="let dl of departmentList" [value]="dl.id">
          <div title="{{ dl.name }}">{{ dl.name }}</div>
        </ng-option>
      </ng-select>
    </div>
    <div class="form-field">
      <div class="d-flex justify-space-between">
        <p>{{ !data.isReceipt ? "Supplier" : "Customer" }}</p>
      </div>
      <ng-select
        #customerName
        [disableOptionCentering]="true"
        (focus)="customerName.open()"
        (valueChange)="customerName.close()"
        [(ngModel)]="accountId"
        (change)="customerChange()"
        panelClass="mat-select-position"
      >
        <ng-option *ngFor="let option of accountList" [value]="option.id">
          <div title="{{ option.name }}">{{ option.name }}</div>
        </ng-option>
      </ng-select>
    </div>
    <div class="form-field">
      <p>Amount</p>
      <mat-form-field>
        <button mat-button type="button" disableRipple matSuffix>
          <span class="symbol-align">{{ currencySymbol }}</span>
        </button>
        <input
          allow_14_2_Decimal
          matInput
          zeroNotAllowed
          [(ngModel)]="amount"
          autocomplete="off"
          allowDecimal
          disabled
        />
      </mat-form-field>
    </div>
    <div class="form-field">
      <p>Currency <span class="text-danger-300">*</span></p>
      <ng-select
        #selectCurrency
        [disableOptionCentering]="true"
        (focus)="selectCurrency.open()"
        (valueChange)="selectCurrency.close()"
        [disableOptionCentering]="true"
        dropdownPosition="top"
        [(ngModel)]="selectedCurrencyId"
        panelClass="mat-select-position"
        (change)="currencyChange($event)"
        required
      >
        <ng-option
          *ngFor="let cl of clientCurrencyList"
          [value]="cl.currencyId"
        >
          <div title="{{ cl.currency }}">
            {{ cl.currency }}
          </div>
        </ng-option>
      </ng-select>
    </div>
    <div class="form-field" *ngIf="isMultiCurrencySelected">
      <mat-form-field class="cust-inputbox">
        <input
          matInput
          [(ngModel)]="currencyAmt"
          allowDecimal
          autocomplete="off"
          (change)="onCurrencyRateEntered()"
          allow_10_2_Decimal
        />
        <button
          mat-button
          type="button"
          disableRipple
          matSuffix
          (click)="onCurrencyRefresh()"
        >
          <mat-icon>refresh</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="mat-table-wrapper">
      <table
        mat-table
        [dataSource]="refundData"
        aria-describedby="Allocation-Refund-Data"
      >
        <ng-container matColumnDef="invoiceNo">
          <th mat-header-cell *matHeaderCellDef>Invoice .No</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="clamp">{{ element.entryNumber }}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount</th>
          <td mat-cell *matCellDef="let element">
            <span class="white-space-nowrap"
              >{{ element.symbol }} {{ element.amount | numberPipe }}</span
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="due">
          <th mat-header-cell *matHeaderCellDef>Due</th>
          <td mat-cell *matCellDef="let element">
            <span class="white-space-nowrap"
              >{{ element.symbol }} {{ element.dueAmount | numberPipe }}</span
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="amountToAllocate">
          <th mat-header-cell *matHeaderCellDef class="w-100">
            Amount To Allocate
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field>
              <input
                allow_10_2_Decimal
                matInput
                autocomplete="off"
                [(ngModel)]="element.allocatedAmount"
                (blur)="onAllocationAmountChange(element)"
                [max]="element.dueAmount"
              />
            </mat-form-field>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayAllocationRefundColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayAllocationRefundColumns"
        ></tr>
      </table>
      <div *ngIf="noDataFound" class="text-align-center mt-10">
        No records found
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="action-button secondary-button mr-1"
      mat-dialog-close
      type="button"
      mat-button
      disableRipple
      (click)="receiptPaymentChange()"
    >
      New {{ !data.isReceipt ? "Debit Note" : "Credit Note" }}
    </button>
    <button
      class="action-button primary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onSaveClick()"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
