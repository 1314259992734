<div class="content-body pt-0">
  <div class="sidenav-table sidenav-table-input wp-100">
    <div class="mat-table-wrapper product-detail-table">
      <form [formGroup]="formCashCodingDetail">
        <ng-container formArrayName="cashCodingDetailArray">
          <table
            aria-describedby="product-list"
            mat-table
            [dataSource]="tableDataSource"
          >
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>
                Date <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field appearance="standard">
                  <input
                    matInput
                    formControlName="date"
                    [matDatepicker]="DatePicker"
                    autocomplete="off"
                    (change)="commonService.validateDate($event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="DatePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #DatePicker></mat-datepicker>
                </mat-form-field>
                <mat-error
                  *ngIf="
                    commonService.checkDateValidation(
                      cashCodingDetailArray.controls[i].get('date')
                    ).invalidFinancialYear
                  "
                >
                  {{ notificationMessage.validPeriodDateMessage }}
                </mat-error>

                <mat-error
                  *ngIf="
                    commonService.checkDateValidation(
                      cashCodingDetailArray.controls[i].get('date')
                    ).lockedFinancialYear
                  "
                >
                  {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
                </mat-error>
              </td>
            </ng-container>
            <ng-container matColumnDef="account">
              <th mat-header-cell *matHeaderCellDef>
                Account <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <div class="d-flex align-items-center gap-10">
                  <mat-form-field appearance="fill">
                    <input
                      formControlName="accountId"
                      type="text"
                      matInput
                      required
                      [matAutocomplete]="accountGroup"
                      (keyup)="onAccountSearch($event)"
                      (mousedown)="resetAccountList(element)"
                    />
                    <mat-icon matSuffix class="cursor-pointer"
                      >keyboard_arrow_down</mat-icon
                    >
                    <mat-autocomplete
                      [displayWith]="getOptionText"
                      #accountGroup="matAutocomplete"
                      (opened)="scrollIntoView(element.accountId)"
                      (optionSelected)="accountSelect($event, element, i)"
                    >
                      <mat-optgroup
                        *ngFor="let group of accountList"
                        [label]="group.groupName"
                        title="{{ group.groupName }}"
                      >
                        <mat-option
                          *ngFor="let names of group.listModels"
                          [value]="names"
                          title="{{ names.name }}"
                          [class]="
                            cashCodingDetailArray.controls[i].get('accountId')
                              .value?.id === names.id
                              ? 'mat-selected'
                              : ''
                          "
                        >
                          {{ names.name }}
                        </mat-option>
                      </mat-optgroup>
                    </mat-autocomplete>
                  </mat-form-field>
                  <div
                    class="w-30"
                    *ngIf="cashCodingDetailArray.controls[i].get('isAdd').value"
                  >
                    <mat-icon
                      (click)="clickReceiptDetails(element, i)"
                      class="cursor-pointer"
                      fontSet="material-icons-outlined"
                      >add_circle_outline
                    </mat-icon>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="notes">
              <th mat-header-cell *matHeaderCellDef>Note</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    matInput
                    autocomplete="off"
                    formControlName="notes"
                    maxlength="{{ maxLength.maxLength2000 }}"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="transactionType">
              <th mat-header-cell *matHeaderCellDef>Transaction Type</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <ng-select
                  #accountType
                  (focus)="accountType.open()"
                  (valueChange)="accountType.close()"
                  [disableOptionCentering]="true"
                  formControlName="transactionType"
                  dropdownPosition="top"
                  panelClass="mat-select-position"
                  appendTo="body"
                >
                  <ng-option
                    *ngFor="let al of transactionType"
                    [value]="al.id"
                    title="{{ al.name }}"
                  >
                    <span class="clamp">{{ al.name }}</span>
                  </ng-option>
                </ng-select>
              </td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>
                Amount <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    matInput
                    formControlName="amount"
                    autocomplete="off"
                    allowDecimal
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="closeButton">
              <th mat-header-cell *matHeaderCellDef class="w-20"></th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="text-align-center"
              >
                <button
                  class="close-btn"
                  type="button"
                  mat-button
                  disableRipple
                  (click)="onDeleteCashCodingDetails(i)"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayCashCodingDetailsColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayCashCodingDetailsColumns"
            ></tr>
          </table>
        </ng-container>
      </form>
    </div>

    <div class="mb-1 mt-1 d-flex justify-space-between">
      <div>
        <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
          >Add New Line</a
        >
        <a
          class="link mr-1"
          *ngIf="cashCodingDetailArray.length > 0"
          href="javascript:void(0)"
          (click)="clearForm()"
          >Clear All Lines</a
        >
      </div>
    </div>
  </div>
</div>
