<div class="content">
  <div class="content-body content-body-scroll">
    <app-transactions-header
      (triggerDataFromHeader)="getDataFromHeader($event)"
      [listParameters]="listParameters"
    >
    </app-transactions-header>
    <app-header-actions
      *ngIf="this.moduleId > 0"
      (triggerDataFromHeader)="getDataFromHeader($event)"
      [listParameters]="listParameters"
      [parentModuleId]="moduleId"
    >
    </app-header-actions>
    <div class="mt-1">
      <app-expand-collapse
        [triggerExpandedCollapseList]="triggerExpandedCollapseList"
        [listParameters]="listParameters"
        [moduleId]="moduleId"
      >
      </app-expand-collapse>
    </div>
  </div>
</div>
