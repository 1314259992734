<div class="import-setting-wrapper bank-import import-grid">
  <div
    class="content-body content-body-scroll import-setting pb-0"
    [ngClass]="{
      'import-setting-with-pagination':
        (totalRecord$ | async) > 10 && showPaginator,
      'import-setting-without-pagination': !showPaginator
    }"
  >
    <p class="header-text d-flex justify-space-between align-items-center">
      Bank Import
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCloseClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>

    <div class="upload-detail">
      <ng-select
        class="w-270"
        #bankName
        (focus)="bankName.open()"
        (valueChange)="bankName.close()"
        [disableOptionCentering]="true"
        [(ngModel)]="customId"
        panelClass="mat-select-position"
        (change)="onbankNameChange($event)"
      >
        <ng-option *ngFor="let ba of bankAccountList" [value]="ba.id">
          <div title="{{ ba.name }}">{{ ba.name }}</div>
        </ng-option>
      </ng-select>

      <div class="import-leads mt-2 mb-2">
        <div class="file-box" style="width: -webkit-fill-available">
          <div class="drop-detail">
            <p>Drag and drop files to upload</p>
            <h4><span>or</span></h4>
            <button class="file-upload-button primary-button">
              <input
                type="file"
                #fileInput
                accept=".xls, .xlsx"
                (change)="onFileSelected(fileInput)"
                (click)="onFileOpen($event)"
              />Browse
            </button>
            <p class="file-info">{{ fileInfo }}</p>
            <div
              class="d-flex align-items-center justify-content-center gap-5 cursor-pointer"
              (click)="downloadTemplateFile()"
              *ngIf="fileInfo === ''"
            >
              <mat-icon fontSet="material-icons-outlined"
                >file_download</mat-icon
              >
              <p class="m-0 link">Download sample file</p>
            </div>
            <div class="file-formate">
              <p>File Format : XLSX</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fw-bold">
      <span>Import History</span>
      <div class="content-body">
        <div class="main-list">
          <div>
            <div class="report-grid">
              <div
                class="d-flex justify-space-between align-items-end wp-100 mb-1"
              >
                <div class="grid-search mr-10">
                  <mat-form-field appearance="fill" class="search-field">
                    <input
                      matInput
                      placeholder="Search here..."
                      autocomplete="off"
                      (change)="onSearch($event)"
                    />

                    <button mat-button disableRipple class="search-button">
                      <mat-icon>search</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>
              <ejs-grid
                *ngIf="columns.length > 0"
                #grid
                [dataSource]="gridData"
                [allowGrouping]="false"
                [allowReordering]="false"
                [allowSelection]="false"
                [allowPaging]="true"
                (dataStateChange)="dataStateChange($event)"
                [pageSettings]="{
                  pageSize: state.take,
                  pageSizes: [5, 10, 20, 100],
                  showInfo: true,
                  showNavigationButtons: true,
                  totalCount: totalRecords
                }"
                [allowFiltering]="false"
                [allowResizing]="false"
                [allowSorting]="false"
                [showColumnMenu]="false"
                [height]="gridHeight"
              >
                <e-columns>
                  <ng-container
                    *ngFor="let col of columns; let colIndex = index"
                  >
                    <e-column
                      [headerText]="col.name"
                      [field]="col.name + '.rowData'"
                      [hidden]="!col.isSelected"
                      [width]="columnWidth"
                      [allowSorting]="false"
                      [allowFiltering]="col.columnType"
                      [allowGrouping]="false"
                      [visible]="col.isSelected"
                      [isPrimaryKey]="col.name === 'Id'"
                      [showInColumnChooser]="false"
                    >
                      <ng-template #template let-row let-rowIndex>
                        <div
                          *ngIf="
                            (col.dataType === dataType.String ||
                              col.dataType === dataType.Bool ||
                              col.dataType === dataType.DateTime) &&
                            col.name !== 'Status'
                          "
                          class="{{ col.alignment }}"
                          [ngClass]="{
                            'fw-bold': row[col.name]?.isBold,
                            'pr-40 clamp':
                              moduleId !== moduleEnum.ComparativeReport
                          }"
                        >
                          <span title="{{ row[col.name]?.rowData }}">
                            {{ row[col.name]?.rowData }}</span
                          >
                        </div>

                        <div
                          *ngIf="
                            (col.dataType === dataType.Int32 ||
                              col.dataType === dataType.Currency) &&
                            col.name !== 'Status'
                          "
                          class="{{
                            col.alignment
                          }} clamp pr-40 white-space-nowrap"
                          [ngClass]="{
                            'fw-bold': row[col.name]?.isBold
                          }"
                        >
                          <span
                            title="£ {{ row[col.name]?.rowData | numberPipe }}"
                          >
                            £ {{ row[col.name]?.rowData | numberPipe }}</span
                          >
                        </div>
                        <div
                          *ngIf="
                            col.dataType === dataType.CurrencySymbol &&
                            col.name !== 'Status'
                          "
                          class="{{
                            col.alignment
                          }} clamp pr-40 white-space-nowrap"
                          [ngClass]="{
                            'fw-bold': row[col.name]?.isBold
                          }"
                        >
                          <div>
                            <span
                              title="{{ row[col.name]?.currencySymbol }} {{
                                row[col.name]?.rowData | numberPipe
                              }}"
                            >
                              {{ row[col.name]?.currencySymbol }}
                              {{ row[col.name]?.rowData | numberPipe }}</span
                            >
                          </div>
                        </div>

                        <div
                          *ngIf="
                            col.dataType === dataType.URL &&
                            col.name !== 'Status'
                          "
                          class="{{ col.alignment }}"
                          [ngClass]="{
                            'fw-bold': row[col.name]?.isBold
                          }"
                          title="{{ row[col.name]?.rowData }}"
                        >
                          <a
                            *ngIf="row[col.name]?.moduleId > 0"
                            class="link text-primary wmx-100 clamp"
                            [ngClass]="{
                              'ml-2':
                                moduleId ===
                                (moduleEnum.BalanceSheet ||
                                  moduleEnum.BalanceSheetComparison)
                            }"
                            (click)="redirect(col.name, row[col.name])"
                          >
                            {{ row[col.name]?.rowData }}
                          </a>
                        </div>

                        <div
                          *ngIf="
                            col.dataType === dataType.URL &&
                            row[col.name]?.id !== '' &&
                            row[col.name]?.id !== null &&
                            row[col.name]?.id !== undefined &&
                            row[col?.name]?.moduleId === 0 &&
                            col.name !== 'Status'
                          "
                          title="{{ row[col.name]?.rowData }}"
                          [ngClass]="row[col.name]?.isBold ? 'fw-bold' : ''"
                        >
                          <a
                            class="link fw-bold wmx-100 clamp"
                            (click)="
                              redirect(
                                row[col.name]?.id,
                                row[col.name]?.moduleId
                              )
                            "
                          >
                            {{ row[col.name]?.rowData }}
                          </a>
                        </div>

                        <div
                          class="clamp"
                          *ngIf="
                            col.dataType === dataType.URL &&
                            (row[col.name]?.id === '' ||
                              row[col.name]?.id === null ||
                              row[col.name]?.id === undefined) &&
                            row[col?.name]?.moduleId === 0 &&
                            col.name !== 'Status'
                          "
                          title="{{ row[col.name]?.rowData }}"
                          [ngClass]="row[col.name]?.isBold ? 'fw-bold' : ''"
                        >
                          {{ row[col.name]?.rowData }}
                        </div>

                        <div
                          *ngIf="col.name === 'Status'"
                          title="{{ row[col.name]?.rowData }}"
                          [ngClass]="
                            row[col.name]?.rowData === 'Active' ||
                            row[col.name]?.rowData === 'Paid'
                              ? 'status-active'
                              : 'status-archived'
                          "
                        >
                          {{ row[col.name]?.rowData }}
                        </div>

                        <span
                          *ngIf="colIndex === columns.length - 2"
                          class="grid-actions"
                          [ngClass]="
                            selectedRowIndex === rowIndex ? 'show-button' : ''
                          "
                        >
                          <app-more-grid-actions
                            [moduleId]="moduleId"
                            [moreActionCount]="moreActionCount"
                            [element]="row[col.name]?.rowData"
                            (triggerActionType)="onButtonClick($event, row)"
                            (triggerSelectedRowIndex)="
                              getSelectedRowIndex($event, rowIndex)
                            "
                          ></app-more-grid-actions>
                        </span>
                      </ng-template>
                    </e-column>
                  </ng-container>
                </e-columns>
              </ejs-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-buttons
    [getModuleId]="moduleId"
    (triggerOnSaveClick)="onSave()"
    (triggerOnCancelClick)="onCancel()"
  >
  </app-buttons>
</div>
