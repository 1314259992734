<div class="content-body">
  <form [formGroup]="invoiceForm">
    <div class="add-form-wrap">
      <div class="form-field fund-name" *ngIf="branchList.length > 0">
        <p>Branch <span class="text-danger-300">*</span></p>
        <ng-select
          #branch
          (focus)="branch.open()"
          (valueChange)="branch.close()"
          [disableOptionCentering]="true"
          formControlName="branchId"
          dropdownPosition="bottom"
          (change)="
            onChangeBranch($event, false);
            this.invoiceForm.controls.customerName.setValue('');
            this.invoiceForm.controls.bankId.setValue('')
          "
        >
          <ng-option *ngFor="let bl of branchList" [value]="bl.id">
            <div title="{{ bl.name }}">{{ bl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="departmentList.length > 0">
        <p>Department <span class="text-danger-300">*</span></p>
        <ng-select
          #department
          (focus)="department.open()"
          (valueChange)="department.close()"
          [disableOptionCentering]="true"
          formControlName="departmentId"
          dropdownPosition="bottom"
        >
          <ng-option *ngFor="let dl of departmentList" [value]="dl.id">
            <div title="{{ dl.name }}">{{ dl.name }}</div>
          </ng-option>
        </ng-select>
      </div>

      <div class="form-field">
        <p>Invoice No <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="invoiceNo"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength100 }}"
          />
        </mat-form-field>
      </div>
      <div class="form-field datepicker">
        <p>Invoice Date <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="invoiceDate"
            [matDatepicker]="invoiceDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            tabindex="-1"
            (dateChange)="dateChange()"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="invoiceDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #invoiceDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(invoiceForm.get('invoiceDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(invoiceForm.get('invoiceDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>

      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Customer Name <span class="text-danger-300">*</span></p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isCustomerChangePermission"
            >
              <mat-icon (click)="gotoAddCustomer()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="disabledEditButton || !isCustomerChangePermission"
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditCustomer()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #customerName
          [disableOptionCentering]="true"
          (focus)="customerName.open()"
          (valueChange)="customerName.close()"
          formControlName="customerName"
          panelClass="mat-select-position"
          (change)="customerChange($event)"
          required
        >
          <ng-option *ngFor="let option of customerList" [value]="option.id">
            <div title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field datepicker">
        <p>Due Date <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="dueDate"
            [matDatepicker]="dueDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dueDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #dueDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(invoiceForm.get('dueDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(invoiceForm.get('dueDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>

      <div class="form-field fund-name" style="display:none;">
        <p>Account Name <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            formControlName="invoiceType"
            
            [matAutocomplete]="accountGroup"
            (keyup)="onAccountSearch($event)"
            (keydown.Tab)="onSearch($event)"
            (change)="onSearch($event)"
            #trigger="matAutocompleteTrigger"
            appAutocompletePosition="trigger"
            (mousedown)="resetAccountList()"
          />
          <mat-icon class="downarrow" matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete
            [displayWith]="getOptionText"
            #accountGroup="matAutocomplete"
            (opened)="scrollIntoView(invoiceForm.controls.invoiceType.value)"
            (optionSelected)="onOptionSelected($event)"
          >
            <mat-optgroup
              *ngFor="let group of accountGroupList"
              [label]="group.groupName"
              title="{{ group.groupName }}"
            >
              <mat-option
                *ngFor="let names of group.listModels"
                [value]="names"
                title="{{ names.name }}"
                [class]="
                  this.invoiceForm.controls.invoiceType.value?.id === names.id
                    ? 'mat-selected'
                    : ''
                "
              >
                {{ names.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Currency <span class="text-danger-300">*</span></p>
        <ng-select
          #selectCurrency
          [disableOptionCentering]="true"
          (focus)="selectCurrency.open()"
          (valueChange)="selectCurrency.close()"
          [disableOptionCentering]="true"
          formControlName="currency"
          dropdownPosition="top"
          panelClass="mat-select-position"
          (change)="currencyChange($event)"
          required
        >
          <ng-option
            *ngFor="let cl of clientCurrencyList"
            [value]="cl.currencyId"
          >
            <div title="{{ cl.currency }}">
              {{ cl.currency }}
            </div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="isMultiCurrencySelected">
        <p>&nbsp;</p>
        <mat-form-field class="w-140 big-inputbox">
          <input
            matInput
            formControlName="currencyAmt"
            allowDecimal
            autocomplete="off"
            (change)="onCurrencyRateEntered()"
          />
          <button
            mat-button
            type="button"
            disableRipple
            matSuffix
            (click)="onCurrencyRefresh()"
          >
            <mat-icon>refresh</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Bank</p>
        </div>
        <ng-select
          #bank
          (focus)="bank.open()"
          (valueChange)="bank.close()"
          [disableOptionCentering]="true"
          formControlName="bankId"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let bl of bankList" [value]="bl.id">
            <div title="{{ bl.name }}">{{ bl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div
        class="form-field d-flex flex-column justify-content-center"
        *ngIf="isVatRegistered"
      >
        <div>
          <mat-checkbox
            formControlName="isVatInclude"
            (change)="onCheckBoxSelected($event)"
          >
            Amount Including VAT
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox
            formControlName="isVatPostponed"
            (change)="isVatPostponedChecked($event)"
            >Apply CIS VAT Domestic Reverse Charge</mat-checkbox
          >
        </div>
        <!-- <div>
          <mat-checkbox
            formControlName="isGlobalDiscount"
            (change)="isGlobalDiscountChecked($event)"
            >Apply Discount on Invoice Total</mat-checkbox
          >
        </div> -->
      </div>
    </div>

    <div class="d-flex gap-40">
      <div class="form-field" *ngIf="isFromBankImport">
        <p>Import Bank Payment <span class="text-danger-300"></span></p>
        <mat-form-field class="amt-inputbox">
          <input
            currencyMask
            allow_10_2_Decimal
            matInput
            autocomplete="off"
            formControlName="bankImportAmount"
            [disabled]="true"
          />
          <button mat-button type="button" disableRipple matSuffix>
            <span>{{ bankImportCurrency }}</span>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="form-field textarea mb-0">
      <p>Notes</p>
      <mat-form-field>
        <textarea
          matInput
          formControlName="notes"
          autocomplete="off"
          maxlength="{{ maxLength.maxLength2000 }}"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
