import { DataSecurityModel } from "@app/core/Models";
import { Guid } from "guid-typescript";

export class SaveDataSecurity {
  static readonly type = '[DataSecurity] Save DataSecurity';

  constructor(public dataSecurity: DataSecurityModel) {}
}

export class GetDataSecurityDetails {
  static readonly type = '[DataSecurity] Get DataSecurity Details by CompanyId';

  constructor(public companyId: Guid) {}
}
