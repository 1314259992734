<div
  class="content-body content-body-scroll"
>
  <div class="sidenav-body-content">
    <div class="main-list content">
      <div class="d-flex align-items-end wp-100 mb-1 gap-10">
        <div class="grid-search">
          <mat-form-field appearance="fill" class="search-field">
            <input
              matInput
              placeholder="Search"
              autocomplete="off"
              (keyup)="onSearch($event)"
            />
            <button mat-button disableRipple class="search-button">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="header-actions wp-100">
          <div class="align-items-end content-header d-flex header-count header-filters justify-content-end header-count-1"
          >
            <div class="toolbar d-flex align-items-center gap-10">
              <ng-select
                  class="w-150"
                  #selectType
                  (focus)="selectType.open()"
                  (valueChange)="selectType.close()"
                  [(ngModel)]="selectedModule"
                  panelClass="myPanelClass"
                  (change)="onActivitySelectionChange($event)"
                  [disableOptionCentering]="true"
                >
                  <ng-option [value]="0">All</ng-option>
                  <ng-option *ngFor="let al of moduleList" [value]="al.id">
                    All {{ al.name }}
                  </ng-option>
                </ng-select>

              <ng-select
                  class="w-120 mr-10"
                  panelClass="myPanelClass"
                  [disableOptionCentering]="true"
                  [(ngModel)]="filter"
                  (change)="onSelectionChange($event)"
                  #selectTime
                  (focus)="selectTime.open()"
                  (valueChange)="selectTime.close()"
                >
                <ng-option *ngFor="let filter of filterTypes" [value]="filter.id">
                  {{ filter.name }}
                </ng-option>
             </ng-select>
             <form [formGroup]="calendarForm" *ngIf="showCalendar">
              <div class="d-flex align-center year-picker">
                <mat-form-field  appearance="standard" (click)="picker.open()">
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      matInput
                      [value]="startDate.value"
                      formControlName="start"
                      #start
                      readonly
                    />
                    <input
                      matEndDate
                      matInput
                      [value]="endDate.value"
                      formControlName="end"
                      #end
                      readonly
                      (dateChange)="dateRangeChange(start.value, end.value)"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matPrefix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="notification">
      <div class="notification-list">
        <mat-accordion [multi]="true">
          <mat-expansion-panel [expanded]="true" *ngFor="let log of activities">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ log.month }} - {{ log.year }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-activity-details
            [getModuleId]="selectedModule"
            [triggerActivityDetails]="triggerActivityDetails"
            [activityDetails]="log"
            ></app-activity-details>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="text-align-center">
        <span *ngIf="!activities?.length">No records found</span>
      </div>
    </div>
  </div>
</div>
