import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ChartOfAccountCostOfSalesGroupTypeName,
  ChartOfAccountCreditorsGroupTypeName,
  GroupNames,
  InvoiceType,
  MaxLength,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { AccountNature } from '@app/core/Enum/account-nature';
import {
  ChartOfAccountListParam,
  MainListParameters,
  SideListModel,
  ViewParamModel,
} from '@app/core/Models';
import {
  BulkEditModel,
  SaveBulkEditModel,
} from '@app/core/Models/task/bulkedit/bulkedit';
import { NotificationService } from '@app/core/Services';
import {
  GetAccountListByNature,
  GetAccountsBasedOnGroupId,
  GetAccountsBasedOnGroupIdAndTypeId,
  MenuState,
} from '@app/core/Store';
import { GetBulkEditList, SaveBulkEdit } from '@app/core/Store/task/bulk-edit';
import { TransactionHeaderComponent } from '@app/modules';
import { ViewReceiptComponent } from '@app/modules/common';
import { HeaderActionsComponent } from '@app/modules/common/header-actions/header-actions.component';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-bulk-edit',
  templateUrl: './bulk-edit.component.html',
  styleUrls: ['./bulk-edit.component.scss'],
})
export class BulkEditComponent implements OnInit {
  @ViewChild(TransactionHeaderComponent, { static: true })
  transactionHeader;

  @ViewChild(HeaderActionsComponent, { static: true })
  headerAction;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  listParameters: MainListParameters = new MainListParameters();
  displayBulkEditColumns: string[] = [
    'srNo',
    'date',
    'reference',
    'account',
    'contactName',
    'referenceNo',
    'receipt',
    'payment',
  ];
  bulkEditList: BulkEditModel[] = [];
  isAllSelected = false;
  moduleId = Modules.BulkEdit;
  groupIds = new Array<number>();
  typeIds = new Array<number>();
  accountList: SideListModel[];
  tempAccountList: SideListModel[];
  accountValue = '-1';
  isRecordSelected = false;
  maxLength = MaxLength;
  invoiceType = InvoiceType;
  searchText = '';

  fromDate: any = new Date();
  toDate: any = new Date();
  accountId: any;
  search: any;

  invoiceAccountList: any;
  billAccountList: any;
  debitNoteAccountList: any;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private notifier: NotificationService,
    private store: Store,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.spinner.hide();
    this.getAccounts();
    this.getInvoiceList();
    this.getBillList();
    this.getDebitNoteList();
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.search,
      moduleId: this.accountId,
      subModuleId: -1,
      ids: null,
      startDate:
        this.datepipe.transform(this.fromDate, 'yyyy-MM-dd')?.toString() ??
        null,
      endDate:
        this.datepipe.transform(this.toDate, 'yyyy-MM-dd')?.toString() ?? null,
      branchIds: [],
      departmentIds: [],
    };
    return queryParams;
  }

  onInvoiceClick(element): void {
    const data: ViewParamModel = {
      moduleId: element.moduleId,
      id: element.id,
    };

    this.dialog
      .open(ViewReceiptComponent, {
        data,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {});
  }

  onSave(event): void {
    const param: SaveBulkEditModel[] = [];
    for (let bulkEdit of this.bulkEditList) {
      if (bulkEdit.isSelected) {
        param.push({
          id: bulkEdit.id,
          itemId: bulkEdit.itemId,
          bookAccountId:
            bulkEdit.accountValue && bulkEdit.accountValue !== '-1'
              ? bulkEdit.accountValue
              : this.listParameters.moduleId,
          reference: bulkEdit.reference,
          invoiceTypeId: bulkEdit.invoiceTypeId,
        });
      }
    }
    if (param.length > 0) {
      this.store.dispatch(new SaveBulkEdit(param)).subscribe((res) => {
        this.spinner.hide();
        this.isRecordSelected = false;
        if (res) {
          this.notifier.success(
            NotificationHeader.success,
            NotificationTextMessage.successMessage
          );
          this.getList();
        }
      });
    }
  }

  selectAll(event: any): void {
    this.bulkEditList.forEach((x) => (x.isSelected = event.checked));
    if (this.bulkEditList.length > 0) {
      this.isRecordSelected = event.checked;
    }
  }

  onAccoutChange(element: any): void {
    this.bulkEditList.forEach((y) => {
      if (y.isSelected && element.isSelected) {
        y.accountValue = element.accountValue;
      }
    });
  }

  onAccoutHeaderChange(): void {
    this.getList();
  }

  onCheckBoxChanges(event: any, element: any): void {
    element.isSelected = event.checked;
    let temp = this.bulkEditList.find((x) => {
      x.isSelected === true;
    });

    if (temp) {
      this.bulkEditList.forEach((y) => {
        if (y.isSelected) {
          y.accountValue = element.accountValue;
          y.referenceNo = element.referenceNo;
        }
      });
    }

    let isCheckedList = this.bulkEditList.filter(
      (x: any) => x.isSelected === true
    );
    this.isRecordSelected = isCheckedList.length > 0 ? true : false;
    this.isAllSelected =
      isCheckedList.length === this.bulkEditList.length ? true : false;
  }

  getList(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetBulkEditList(this.getParamter()))
      .subscribe((res) => {
        this.bulkEditList = res.bulkEdit.getBulkEdit;
      });
  }

  onSearch(): void {
    //   const inputValue = (input.target as HTMLInputElement).value;
    //   const searchText: string = (input.target as HTMLInputElement).value;
    //   this.listParameters.search = searchText;
    this.getList();
    //   this.getInvoiceTypeBasedOfAccount(this.listParameters.moduleId);
    // }
    // getDataFromHeader(data: any): void {
    //   this.headerAction.ngOnDestroy();
    //   this.listParameters = data;
    //   this.getList();
    //   this.getInvoiceTypeBasedOfAccount(this.listParameters.moduleId);
  }

  getOptionText(option) {
    return option?.name;
  }

  getAccounts(): void {
    const natures = new Array<string>();
    natures.push(AccountNature.Income);
    natures.push(AccountNature.Expenses);

    this.store
      .dispatch(new GetAccountListByNature(natures))
      .pipe(
        tap((res) => {
          if (res.common.chartOfAccountList.length > 0) {
            this.accountList = res.common.chartOfAccountList;
            this.accountId = this.accountList[0].id;
          }
        })
      )
      .subscribe();
  }

  getInvoiceList(): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Turnover);

    this.store
      .dispatch(new GetAccountsBasedOnGroupId(groupNames))
      .pipe(
        tap((res) => {
          if (res.common.accountList.length > 0) {
            this.invoiceAccountList = res.common.accountList;
          }
        })
      )
      .subscribe();
  }

  getBillList(): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.CostOfSales);
    groupNames.push(GroupNames.SellingAndDistributionCosts);
    groupNames.push(GroupNames.AdministrativeExpenses);

    this.store
      .dispatch(new GetAccountsBasedOnGroupId(groupNames))
      .pipe(
        switchMap((res) => {
          if (res.common.accountList.length > 0) {
            this.billAccountList = res.common.accountList;
          }

          const param: ChartOfAccountListParam = {
            groupIds: [GroupNames.Creditors],
            typeIds: [
              ChartOfAccountCreditorsGroupTypeName.CreditorsLessThanOneYearVAT,
            ],
          };

          return this.store.dispatch(
            new GetAccountsBasedOnGroupIdAndTypeId(param)
          );
        })
      )
      .subscribe((secondRes) => {
        if (secondRes.common.accountList.length > 0) {
          this.billAccountList = this.billAccountList.concat(
            secondRes.common.accountList
          );
        }
      });
  }

  getDebitNoteList(): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.CostOfSales);
    const typeNames = new Array<number>();
    typeNames.push(ChartOfAccountCostOfSalesGroupTypeName.Purchases);
    typeNames.push(ChartOfAccountCostOfSalesGroupTypeName.OtherDirectCosts);

    const param: ChartOfAccountListParam = {
      groupIds: groupNames,
      typeIds: typeNames,
    };

    this.store
      .dispatch(new GetAccountsBasedOnGroupIdAndTypeId(param))
      .pipe(
        tap((res) => {
          this.debitNoteAccountList = res.common.accountList;
        })
      )
      .subscribe();
  }
}
