import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActionType,
  ConfirmationType,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { VatScheme } from '@app/core/Enum/vat-scheme';
import { MainListParameters, MenuModel } from '@app/core/Models';
import { CommonService, ModulePermission } from '@app/core/Services';
import {
  CheckVatNumber,
  ExportReceipt,
  ExportVatAudit,
  FinalVATUpdate,
  GetCompanyVatSchemaId,
  GetFinalReturn,
  GetSubmitVatList,
  MenuState,
  SetDefaultVatId,
  SubmitVatState,
} from '@app/core/Store';
import {
  ConfirmationBoxComponent,
  FlatRateAdjustmentComponent,
  VatPaymentPopupComponent,
  VatPaymentViewPopupComponent,
  VatViewComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-submit-vat-list',
  templateUrl: './submit-vat-list.component.html',
  styleUrls: ['./submit-vat-list.component.scss'],
})
export class SubmitVatListComponent implements OnInit {
  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(SubmitVatState.totalRecord)
  totalRecord$: Observable<number>;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  commonService: CommonService;
  spinner: NgxSpinnerService;
  store: Store;
  datepipe: DatePipe;
  dialog: MatDialog;
  modulePermission: ModulePermission;
  moduleEnum = Modules;

  vatSubmit: any;
  vatSubmitNoDataFound: any;
  isCheckVatNumber: boolean = true;
  isFinalReturnSubmitted: boolean = false;
  listParameters: MainListParameters = new MainListParameters();
  ids: Array<Guid>;
  actions: any = [];
  moreActionCount = 4;
  selectedRowIndex = -1;
  showPaginator = true;
  finalReturn: any;
  moduleId: number;

  private _moduleIdSubscription;

  vatSchemaId: any;

  triggerPaginationChange: Subject<any> = new Subject<any>();

  submitVatDisplayedColumns: string[] = [
    'from',
    'to',
    'description',
    'vatPayable',
    'vatStatus',
    'paymentStatus',
    'action',
  ];

  constructor(private injector: Injector) {
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.commonService = injector.get<CommonService>(CommonService);
    this.store = injector.get<Store>(Store);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.modulePermission = injector.get<ModulePermission>(ModulePermission);
  }

  ngOnInit(): void {
    this.actions = [
      {
        name: 'View',
        actionType: ActionType.View,
        icon: 'pageview',
      },
      {
        name: 'Export',
        actionType: ActionType.Export,
        icon: 'save_alt',
      },
      {
        name: 'Edit',
        actionType: ActionType.Update,
        icon: 'edit',
      },
      {
        name: 'Delete',
        actionType: ActionType.Delete,
        icon: 'delete_outline',
      },
      {
        name: 'Flat Rate Adjustment',
        actionType: ActionType.FlatRateAdjustment,
        icon: 'calculate',
      },

      {
        name: 'Submit to HMRC',
        actionType: ActionType.SubmitToHMRC,
        icon: 'content_paste_go',
      },
    ];
    this.modulePermission.permissionData.subscribe((value) => {
      this.moduleId = value.data.id;
      this.loadData();
    });
  }

  loadData(): void {
    this.getFinalReturn();
    this.getVatSchemaId();
    this.getList();
  }
  getList(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetSubmitVatList(this.getParamter(), this.moduleId))
      .pipe()
      .subscribe((res) => {
        this.spinner.hide();
        this.vatSubmit = res.submitVat.getSubmitVatTransactions;
        this.vatSubmitNoDataFound = this.vatSubmit.length > 0 ? false : true;

        const param = {
          pageSize: this.listParameters.pageSize,
          totalRecord: res.submitVat.totalRecord,
        };
        this.triggerPaginationChange.next(param);
      });
  }

  getFinalReturn(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetFinalReturn())
      .pipe()
      .subscribe((res) => {
        this.spinner.hide();
        this.finalReturn = res.submitVat.finalReturn;
        this.isFinalReturnSubmitted = this.finalReturn.isFinalReturn;
      });
  }

  getParamter(format?: number): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      branchIds: this.listParameters.branchIds ?? [],
      departmentIds: this.listParameters.departmentIds ?? [],
      ids: this.listParameters.ids ?? null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      format: format,
    };
    return queryParams;
  }

  onAddClick(): void {
    this.commonService.toggleMenu = false;
    this.commonService.isInitialValueChange = false;

    this._moduleIdSubscription = this.moduleId$.subscribe((data: any) => {
      if (data === Modules.SubmitVat || data === Modules.BridgingVAT) {
        this.store.dispatch(new SetDefaultVatId());
        this.checkVatNumber(data);
      }
    });
    this._moduleIdSubscription.unsubscribe();
  }

  checkVatNumber(data: any): void {
    this.store.dispatch(new CheckVatNumber()).subscribe((res) => {
      this.isCheckVatNumber = res.submitVat.checkVatNumber;
      if (this.isCheckVatNumber) {
        this.commonService.onAddRouting(data);
      } else {
        this.commonService.onFailure(
          NotificationTextMessage.vatNumberErrorMessage
        );
      }
    });
  }

  statusText(id: Guid, status: number): string {
    let t = '';

    switch (status) {
      case 1:
        t = `<span class="badge badge-warning" style="width:150px; vertical-align: middle;" id="vatstatus_${id}">Get Response</span>`;
        break;
      case 2:
        t = `<span class="badge badge-success" style="width:150px; vertical-align: middle;" id="vatstatus_${id}">HMRC Submitted</span>`;
        break;
      case 3:
        t = `<span class="badge badge-darkpink" style="width:150px; vertical-align: middle;" id="vatstatus_${id}">Failed</span>`;
        break;
      case 5:
        t = `<span class="badge badge-darkpink" style="width:150px; background:#493b92;vertical-align: middle;" id="vatstatus_${id}">Capium Submitted</span>`;
        break;
      case 0:
      default:
        break;
    }

    return t;
  }

  paymentStatus(id: Guid, netVatDue: number) {
    const parameter = {
      id: id,
      netVatDue: netVatDue,
    };
    this.dialog
      .open(VatPaymentPopupComponent, {
        data: parameter,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        this.getList();
      });
  }

  paymentStatusView(id: Guid) {
    const parameter = {
      id: id,
    };
    this.dialog
      .open(VatPaymentViewPopupComponent, {
        data: parameter,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        this.getList();
      });
  }
  vatView(id: Guid) {
    const parameter = {
      id: id,
    };
    this.dialog
      .open(VatViewComponent, {
        data: parameter,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result: boolean) => {});
  }

  flatRateAdjustment(id: Guid) {
    const parameter = {
      id: id,
    };
    this.dialog
      .open(FlatRateAdjustmentComponent, {
        data: parameter,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        this.getList();
      });
  }

  finalVATUpdate() {
    this.store.dispatch(new FinalVATUpdate()).subscribe((res) => {
      if (res.submitVat.finalVATUpdated) {
        this.loadData();
        this.commonService.onSuccess(NotificationTextMessage.successMessage);
      } else {
        this.commonService.onFailure(NotificationTextMessage.errorMessage);
      }
    });
  }

  onButtonClick(element: any, actionType: ActionType): void {
    let id = element.id;

    this.ids = [];
    this.ids.push(id);

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (
          isValidPeriod ||
          actionType === ActionType.Email ||
          actionType === ActionType.Export ||
          actionType === ActionType.View
        ) {
          switch (actionType) {
            case ActionType.Update:
              this.commonService.toggleMenu = false;
              this.commonService.onEditRouting(true, this.moduleId, id);
              break;

            case ActionType.Delete:
              this.onDeleteClick(id, this.moduleId);
              break;

            case ActionType.Export:
              if (this.moduleId !== Modules.SubmitVat) {
                this.store
                  .dispatch(new ExportReceipt(this.moduleId, this.ids, false))
                  .subscribe();
              } else {
                this.store.dispatch(new ExportVatAudit(id, false)).subscribe();
              }
              break;

            case ActionType.FlatRateAdjustment:
              this.flatRateAdjustment(id);
              break;

            case ActionType.View:
              this.vatView(id);
              break;
            case ActionType.SubmitToHMRC:
              this.commonService.toggleMenu = false;
              this.commonService.onEditRouting(true, this.moduleId, id,null,null,null,null,true);
              break;
          }
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  onDeleteClick(id: any, moduleId: number): void {
    this.ids = [];
    this.ids.push(id);

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getList();
          }
        });
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;

    this.getList();
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;

      this.getList();
    }
  }
  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
  }

  onToggleMatMenu(i): void {
    this.selectedRowIndex = i;
  }

  async getVatSchemaId(): Promise<void> {
    await this.store
      .dispatch(new GetCompanyVatSchemaId())
      .toPromise()
      .then((res) => {
        this.vatSchemaId = res.company.companyVatSchemaId;
        this.vatSchemaId = +this.vatSchemaId;
      });
  }

  getActions(element: any): any {
    let actions = this.actions;

    if (
      (this.vatSchemaId !== VatScheme.FlatRateAccrualScheme &&
        this.vatSchemaId !== VatScheme.FlatRateCashBasedScheme) ||
      element.status === 0
    ) {
      actions = actions.filter(
        (action) => action.actionType !== ActionType.FlatRateAdjustment
      );
    }

    if (element.status !== 5) {
      actions = actions.filter(
        (action) => action.actionType !== ActionType.SubmitToHMRC
      );
    }

    if (this.moduleId === Modules.SubmitVat) {
      if (element.status === 5 || element.status === 2) {
        actions = actions.filter(
          (action) => action.actionType !== ActionType.Delete
        );
      }
    } else if (this.moduleId === Modules.BridgingVAT) {
      if (element.status === 2) {
        actions = actions.filter(
          (action) => action.actionType !== ActionType.Delete
        );
      }
    }

    if (element.status === 2) {
      actions = actions.filter(
        (action) => action.actionType !== ActionType.Update
      );
    }

    return actions;
  }
  onSubmitVATMoreInfoClick() {
    window.open(
      'https://www.loom.com/share/3c0b0b9d99ed432298a418f5724afe30',
      '_blank'
    );
  }
  onBridgingVATMoreInfoClick() {
    window.open(
      'https://www.loom.com/share/bb2687b37dc94566bc2930f48ef8245a',
      '_blank'
    );
  }
}
