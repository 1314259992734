<div class="mat-table-wrapper vat-summery">
  <table
    mat-table
    [dataSource]="vatSummaryData"
    aria-describedby="Main Grid Table"
  >
    <ng-container matColumnDef="fromDate">
      <td mat-cell *matCellDef="let element">
        {{ element.fromDate | date: "dd-MMM-yyyy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="toDate">
      <td mat-cell *matCellDef="let element">
        {{ element.toDate | date: "dd-MMM-yyyy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>
    <ng-container matColumnDef="vatAmount">
      <td mat-cell *matCellDef="let element">
        £ {{ element.vatAmount | numberPipe }}
      </td>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div class="norecord-chart" *ngIf="vatSummaryData?.length === 0">
    <img src="assets/images/column-icon.svg" alt="Pie Chart" />
    <p class="pt-10">No data available yet!</p>
  </div>
</div>
