import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';
import { AppModule } from './app/app.module';
fetch(
  `${environment.apiVersionUrl}Company/getAzureKey?keyName=SyncfusionLicenseKey`
).then(function (response) {
  if (response.ok) {
    response.text().then(function (res) {
      registerLicense(res);
    });
  }
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
