import { DirectorShareholderModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class CreateDirectorShareholder {
  static readonly type = '[DIRECTORSHAREHOLDER] Create Director Shareholder';

  constructor(public data: DirectorShareholderModel) {}
}

export class GetDataByDirectorShareholderId {
  static readonly type =
    '[DIRECTORSHAREHOLDER] Get DataBy Director Shareholder Id';

  constructor(public directorShareholderId: Guid) {}
}

export class ArchiveAndRestoreDirectorShareholder {
  static readonly type =
    '[DIRECTORSHAREHOLDER] Archive And Restore Director Shareholder Data';

  constructor(
    public directorShareholderId: Array<Guid>,
    public isArchive: boolean
  ) {}
}

export class DeleteDirectorShareholderData {
  static readonly type =
    '[DIRECTORSHAREHOLDER] Delete Director Shareholder Data';

  constructor(public directorShareholderId: Array<Guid>) {}
}
