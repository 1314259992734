import { ControlType } from '@app/core/Enum';
import { SideListModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class HeaderModel {
  id: Guid;
  name: string;
  controlType: ControlType;
  value: Array<SideListModel>;
  groupValue: Array<any>;
}

export class PermissionModel {
  data: string;
  componentName: any;
}
