<div class="mat-dialog-wrapper match-journal">
  <h2 mat-dialog-title>Match Journal</h2>
  <button
    class="close-button"
    type="button"
    (click)="onCloseClick()"
    mat-button
    disableRipple
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    <div class="content-body">
      <form>
        <div class="form-field">
          <p>Date:</p>
          <mat-form-field appearance="standard">
            <input
              matInput
              [matDatepicker]="accountingDatePicker"
              name="matchingJournalDate"
              autocomplete="off"
              [(ngModel)]="matchingJournalData.date"
              [disabled]="true"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="accountingDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #accountingDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>{{ labelText }} Amount</p>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              name="amount"
              [(ngModel)]="matchingJournalData.amount"
              [disabled]="true"
            />
          </mat-form-field>
        </div>
      </form>
      <div class="mat-table-wrapper w-636">
        <table
          mat-table
          [dataSource]="matchingJournalData.items"
          aria-describedby="Match-Journal"
        >
          <ng-container matColumnDef="refNo">
            <th mat-header-cell *matHeaderCellDef>Ref No</th>
            <td mat-cell *matCellDef="let element">
              {{ element.entryNumber }}
            </td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.entryDate | date: "dd-LLL-yyyy" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let element">
              {{ element.totalAmount }}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="w-50">Action</th>
            <td mat-cell *matCellDef="let element">
              <button
                type="button"
                (click)="onLinkClick(element)"
                title="Link"
                mat-button
                disableRipple
              >
                <mat-icon class="text-primary">add_link</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div *ngIf="noDataFound" class="text-align-center mt-1">
          No records found
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>

<app-custom-notifications></app-custom-notifications>
