<div class="content-body">
  <form [formGroup]="budgetingForm">
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Budget Name <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="budgetName"
            maxlength="{{ maxLength.maxLength200 }}"
            unicodeCharacter
            autocomplete="off"
            unicodeCharacter
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Start Period <span class="text-danger-300">*</span></p>
        <ng-select
          panelClass="mat-select-position"
          [disableOptionCentering]="true"
          formControlName="startPeriod"
          (change)="onSelectionChange()"
        >
          <ng-option *ngFor="let sp of startPeriodlist" [value]="sp.id">
            <span title="{{ sp.name }}">{{ sp.name }}</span>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>For Period <span class="text-danger-300">*</span></p>
        <ng-select
          panelClass="mat-select-position"
          [disableOptionCentering]="true"
          formControlName="forPeriod"
          (change)="onSelectionChange()"
          required
        >
          <ng-option *ngFor="let fp of forPeriodList" [value]="fp.id">
            <span title="{{ fp.name }}">{{ fp.name }}</span>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>Actual <span class="text-danger-300">*</span></p>
        <ng-select
          panelClass="mat-select-position"
          [disableOptionCentering]="true"
          formControlName="actual"
          (change)="onSelectionChange()"
          required
        >
          <ng-option *ngFor="let ac of actualPeriodList" [value]="ac.id">
            <span title="{{ ac.name }}">{{ ac.name }}</span>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field fund-name" *ngIf="branchList.length > 0">
        <p>Branch <span class="text-danger-300">*</span></p>
        <ng-select
          #branch
          (focus)="branch.open()"
          (valueChange)="branch.close()"
          [disableOptionCentering]="true"
          formControlName="branchId"
          dropdownPosition="bottom"
          (change)="onChangeBranch($event)"
        >
          <ng-option *ngFor="let bl of branchList" [value]="bl.id">
            <div title="{{ bl.name }}">{{ bl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="departmentList.length > 0">
        <p>Department <span class="text-danger-300">*</span></p>
        <ng-select
          #department
          (focus)="department.open()"
          (valueChange)="department.close()"
          [disableOptionCentering]="true"
          formControlName="departmentId"
          dropdownPosition="bottom"
        >
          <ng-option *ngFor="let dl of departmentList" [value]="dl.id">
            <div title="{{ dl.name }}">{{ dl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
  </form>
</div>
