import { Guid } from 'guid-typescript';

export class GetVatRetun {
  static readonly type = '[VATRETURN] Get VAT Return';

  constructor(public periodKey: Guid) {}
}

export class GetVatRetunDetails {
  static readonly type = '[VATRETURN] Get VAT Return Details';

  constructor(public periodKey: Guid) {}
}

export class GetVatSubmissionPeriods {
  static readonly type = '[VATRETURN] VAT Submission Periods';
}

export class GetAuthorized {
  static readonly type = '[VATRETURN] Get Authorized';
}

export class GetVatObligations {
  static readonly type = '[VATRETURN] Get Vat Obligations';

  constructor(public queryParams: any) {}
}

export class GetVatLiabilities {
  static readonly type = '[VATRETURN] Get Vat Liabilities';

  constructor(public queryParams: any) {}
}

export class GetVatPayments {
  static readonly type = '[VATRETURN] Get Vat Payments';

  constructor(public queryParams: any) {}
}
export class GetBridgingVatRetunDetails {
  static readonly type = '[VATRETURN] Get Bridging VAT Return Details';

  constructor(public periodKey: Guid) {}
}
