import { BudgetingModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class GetStartPeriodList {
  static readonly type = '[Budgeting] Get Start Period List';
}

export class GetAllGroupTypeList {
  static readonly type = '[Budgeting] Get All Group Type List';
}

export class CreateBudgeting {
  static readonly type = '[Budgeting] Create Budgeting';

  constructor(public budget: BudgetingModel) {}
}

export class GetDataByBudgetingId {
  static readonly type = '[Budgeting] Get Data By Budgeting Id';

  constructor(public budgetingId: Guid) {}
}

export class GetBudgetAccountList {
  static readonly type = '[Budgeting] Get Budget Account List';

  constructor(public param: any) {}
}

export class SaveBudgetDetail {
  static readonly type = '[Budgeting] Save Budget Detail';

  constructor(public param: any) {}
}

export class GetBudgetDetailList {
  static readonly type = '[Budgeting] Get Budget Detail List';

  constructor(public param: any) {}
}

export class ArchiveAndRestoreBudgeting {
  static readonly type = '[Budgeting] Archive and Restore Selected Budgeting';

  constructor(public budgetIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteBudgeting {
  static readonly type = '[Budgeting] Delete Selected Budgeting';

  constructor(public budgetIds: Array<Guid>) {}
}
