import { Component, Injector, OnInit, Renderer2 } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationHeader, NotificationTextMessage } from '@app/core/Enum';
import { TicketModules } from '@app/core/Enum/ticket';
import {
  Company,
  FileUploadRequestModel,
  FileUploadResponseModel,
  GlobalComponent,
  LetsConnectRequestModel,
  MainListParameters,
} from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import { GetCompanyList, SaveSupportTicket } from '@app/core/Store';

import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-ticket-dialog',
  templateUrl: './ticket-dialog.component.html',
  styleUrls: ['./ticket-dialog.component.scss'],
})
export class TicketDialogComponent implements OnInit {
  selectedFile: File[] = [];
  supportTicketForm: FormGroup;
  acceptedFileTypes = ['.gif', '.jpg', '.jpeg', '.png'];
  isImageSelected = false;
  fileList: any[] = [];
  selectedFiles: FileList;
  filesForm: FormGroup;
  fileUploadRequest: FileUploadRequestModel;
  fileUploadResponse: Array<FileUploadResponseModel>;
  defaultGuid = Guid.EMPTY as unknown as Guid;
  companyList: Array<Company>;
  externalUserGUID = this.cookieService.get(
    'externalUserGUID'
  ) as unknown as Guid;
  name = this.cookieService.get('name');
  email = this.cookieService.get('email');
  supportTicketData: LetsConnectRequestModel;

  listParameters: MainListParameters = new MainListParameters();
  commonService: CommonService;
  store: Store;
  notifier: NotificationService;
  constructor(
    private globalComponent: GlobalComponent,
    private cookieService: CookieService,
    private formBuilder: UntypedFormBuilder,

    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<TicketDialogComponent>,
    private renderer: Renderer2,
    private injector: Injector
  ) {
    this.commonService = this.injector.get<CommonService>(CommonService);
    this.notifier = this.injector.get<NotificationService>(NotificationService);
    this.store = this.injector.get<Store>(Store);
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.supportTicketForm = this.formBuilder.group({
      name: new FormControl<string | null>(this.name, [Validators.required]),
      email: new FormControl<string | null>(this.email, [
        Validators.required,
        Validators.pattern(
          '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$'
        ),
      ]),
      phone: new FormControl<string>('', Validators.required),
      client: new FormControl<string | null>(''),
      helpTopic: new FormControl<number | null>(9000140263),
      moduleId: new FormControl<string | null>('35'),
      subject: new FormControl<string | null>('', Validators.required),
      message: new FormControl<string | null>('', Validators.required),
    });

    this.getCompanyList();
  }

  saveFile(): void {
    this.fileUploadRequest = {
      file: this.fileList,
    };
    this.commonService.fileUpload(this.fileUploadRequest).subscribe(
      (data) => {
        this.fileUploadResponse = data;
      },
      (error) => {
        this.commonService.onFailure(NotificationTextMessage.errorMessage);
      }
    );
  }

  selectFiles(event: any): void {
    if (event) {
      this.selectedFiles = event.target.files;
      Array.prototype.forEach.call(this.selectedFiles, (file) => {
        if (
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/jpg'
        ) {
          if (file.size / 1024 / 1024 < 2) {
            this.fileList.push(file);
            this.selectedFile = this.fileList;
            this.saveFile();
          } else {
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.fileSize2mb
            );
          }
        } else {
          this.commonService.onFailure(NotificationTextMessage.fileJpgPng);
        }
      });
    }
  }

  onCancel(): void {
    this.filesForm.reset();
    this.fileList = [];
    this.fileUploadResponse = [];
  }

  removeFile(index: any): void {
    this.fileList.splice(index, 1);
    this.fileUploadResponse.splice(index, 1);
  }

  getParamaters(): any {
    const queryParams = {
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      pageNumber: 1,
      pageSize: 0,
    };

    return queryParams;
  }

  getCompanyList(): void {
    this.store
      .dispatch(new GetCompanyList(this.getParamaters()))
      .pipe(
        tap((res) => {
          this.companyList = res.company.company;
        })
      )
      .subscribe();
  }

  dataSubmit(): boolean {
    this.spinner.show();

    try {
      this.supportTicketData = {
        name: this.supportTicketForm.controls.name.value,
        emailId: this.supportTicketForm.controls.email.value,
        phoneNumber: this.supportTicketForm.controls.phone.value,
        clientName: this.supportTicketForm.controls.client.value,
        supportTopic: 'Support Group',
        modules: TicketModules.Bookkeeping.toString(),
        subject: this.supportTicketForm.controls.subject.value,
        message: this.supportTicketForm.controls.message.value,
        externalUserGuid: this.externalUserGUID,
        file: this.selectedFile,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(): void {
    if (this.supportTicketForm.invalid) {
      this.commonService.addValidation(this.supportTicketForm, this.renderer);
      this.commonService.onFailure(NotificationTextMessage.validationMessage);
    } else {
      if (this.dataSubmit() && this.supportTicketForm.dirty) {
        this.store
          .dispatch(new SaveSupportTicket(this.supportTicketData))
          .pipe()
          .subscribe(
            (res) => {
              if (res?.ticket.isSuccess) {
                this.commonService.onSuccess(
                  res?.ticket.response.valueResult.message
                );
                this.dialogRef.close(true);
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }
}
