import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import {
  ConfirmationType,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { CurrencyModel, MainListParameters } from '@app/core/Models';
import { CommonEmitterService, CommonService } from '@app/core/Services';
import {
  CurrencyState,
  GetClientCurrencyList,
  GetRefreshCurrencyData,
  MenuState,
  SaveCurrencyData,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmationBoxComponent } from '../common';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
})
export class CurrencyComponent implements OnInit, OnDestroy {
  isExpandAll = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  listParameters: MainListParameters = new MainListParameters();
  moduleId = Modules.Currency;
  dataSource: any[] = [];

  displayedCurrencyColumns: string[] = [
    'currency',
    'code',
    'symbol',
    'rate',
    'action',
  ];
  currencyList: any = [];
  totalCurrencyList: any = [];
  setClientCurrencyListEmitter;
  currencyIds: any = [];
  currencyRates: any = [];
  refreshedCurrencyList: any = [];
  isMultiCurrencyRefresh = true;
  showCancelBtnTimes = 0;
  currencyRate: number;
  isTotalCurrencyRefresh = false;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    public dialog: MatDialog,
    public commonService: CommonService,
    public store: Store,
    public datepipe: DatePipe,
    public spinner: NgxSpinnerService,
    private commonEmitterSerice: CommonEmitterService
  ) {}

  ngOnInit(): void {
    this.getCurrencyList();
    this.setClientCurrencyListEmitter =
      this.commonEmitterSerice.setClientCurrencyListEmitter.subscribe(
        (data) => {
          if (data) {
            this.getCurrencyList();
          }
        }
      );
  }
  isQuickAdd(event): void {
    if (event) {
      this.getCurrencyList();
    }
  }
  getCurrencyList(): void {
    this.store
      .dispatch(new GetClientCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CurrencyState.getCurrencyList
          );
          this.currencyList = this.currencyList.filter(
            (x) =>
              x.id != this.commonService.defaultGuidValue && x.currencyId != 123
          );
          this.totalCurrencyList = this.currencyList;
        })
      )
      .subscribe();
  }
  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }
  onSingleCurrencyRefresh(value, index): void {
    this.spinner.show();
    this.currencyIds = [];
    if (value.id) {
      this.currencyIds.push(value.currencyId);
    }
    this.store
      .dispatch(new GetRefreshCurrencyData(this.currencyIds))
      .subscribe((res) => {
        this.currencyIds = [];
        this.refreshedCurrencyList = res.currency.currencyList.data;

        this.refreshedCurrencyList.forEach((x) => {
          if (this.currencyList[index].currencyId === x.currencyId) {
            this.currencyList[index].rate = x.rate;
            this.currencyList[index].isRefresh = false;
            this.currencyList[index].isSave = true;
            this.showCancelBtnTimes += 1;
            this.isTotalCurrencyRefresh = false;
          }
        });
        this.spinner.hide();
      });
  }
  onSingleCurrencySave(value: any, index: number): void {
    const param: CurrencyModel[] = [];
    param.push({
      currencyId: value.currencyId,
      rate: this.currencyList[index].rate,
    });

    this.store.dispatch(new SaveCurrencyData(param)).subscribe((res) => {
      this.commonService.onSuccess(NotificationTextMessage.successMessage);

      if (value.currencyId === this.currencyList[index].currencyId) {
        this.currencyList[index].isRefresh = true;
        this.currencyList[index].isSave = false;
        this.showCancelBtnTimes -= 1;
      }
      this.spinner.hide();
    });
  }
  onTotalClientCurrencyRefresh(): void {
    this.spinner.show();
    this.isMultiCurrencyRefresh = false;
    this.currencyList.forEach((item) => {
      this.currencyIds.push(item.currencyId);
    });
    this.store
      .dispatch(new GetRefreshCurrencyData(this.currencyIds))
      .subscribe((res) => {
        this.currencyIds = [];
        this.refreshedCurrencyList = res.currency.currencyList.data;
        this.refreshedCurrencyList.forEach((x, index) => {
          if (this.currencyList[index].currencyId === x.currencyId) {
            this.currencyList[index].rate = x.rate;
            this.currencyList[index].isRefresh = false;
            this.currencyList[index].isSave = true;
            this.showCancelBtnTimes += 1;
            this.isTotalCurrencyRefresh = true;
          }
        });
        this.spinner.hide();
      });
  }
  onTotalCurrencySave(): void {
    this.spinner.show();
    const param: CurrencyModel[] = [];

    for (let currency of this.currencyList) {
      param.push({
        currencyId: currency.currencyId,
        rate: currency.rate,
      });
    }

    this.store.dispatch(new SaveCurrencyData(param)).subscribe((res) => {
      this.spinner.hide();
      this.isMultiCurrencyRefresh = true;
      this.commonService.onSuccess(NotificationTextMessage.successMessage);
      this.getCurrencyList();
      this.showCancelBtnTimes = 0;
    });
  }
  onCurrencyDelete(value): void {
    if (value.id != null) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            currencyRecordId: value.id,
            currencyId: value.currencyId,
            type: ConfirmationType.Delete,
            moduleId: this.moduleId,
            totalNumberOfRecordSelected: 1,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getCurrencyList();
          }
        });
    }
  }

  onCancel(): void {
    this.isMultiCurrencyRefresh = true;
    this.showCancelBtnTimes = 0;
    this.getCurrencyList();
  }

  ngOnDestroy(): void {
    if (
      this.setClientCurrencyListEmitter !== null &&
      this.setClientCurrencyListEmitter !== undefined
    ) {
      this.setClientCurrencyListEmitter.unsubscribe();
    }
  }
}
