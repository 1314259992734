import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NotificationHeader, NotificationTextMessage } from '@app/core/Enum';
import { FileImportRequestModel } from '@app/core/Enum/import';
import {
  FileUploadRequestModel,
  FileUploadResponseModel,
} from '@app/core/Models';
import {
  CommonService,
  HighlightRow,
  NotificationService,
} from '@app/core/Services';
import {
  BridgingVatState,
  GetImportTemplate,
  ImportVatBridging,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-vat-details',
  templateUrl: './vat-details.component.html',
  styleUrls: ['./vat-details.component.scss'],
})
export class VATDetailsComponent implements OnInit {
  bridgingVatForm: FormGroup;
  @ViewChild('fileInput')
  fileInput: ElementRef;

  @Input() triggerEditData: Observable<any>;

  defaultCurrency: Guid;
  periodicDate: any;
  notificationMessage = NotificationTextMessage;
  accept = ['.pdf', '.xls'];
  @Input() moduleId: number;
  fileName: string;
  fileList: any[] = [];
  fileUploadRequest: FileUploadRequestModel;
  fileUploadResponse: Array<FileUploadResponseModel>;
  isFileSelected = false;
  fileData: any;
  fileInfo: string = '';
  selectedFileName: string;
  selectedFile: File;
  url: any;
  fileImportRequestModel: FileImportRequestModel;
  isImportSuccess: boolean = false;

  constructor(
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private store: Store,
    private spinner: NgxSpinnerService,
    private notifier: NotificationService
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.bridgingVatForm = new FormGroup({
      file: new FormControl(''),
    });
  }

  downloadTemplateFile(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetImportTemplate(this.moduleId))
      .subscribe((res) => {});
  }

  onFileSelected(input: HTMLInputElement): void {
    const file = input.files![0];
    this.selectedFileName = file.name;
    if (file.size / 1024 / 1024 > 10) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.fileSizeExceeds
      );
      return;
    }

    if (this.checkSpecialChar(file)) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.filenameWithSpecialCharacter
      );
      return;
    }

    this.fileInfo = `${file.name} (${this.formatBytes(file.size)})`;

    if (!input.files || !input.files.length) {
      return;
    }

    this.selectedFile = input.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(input.files[0]);
    reader.onload = () => {
      this.url = reader.result;
    };
    this.isFileSelected = true;
  }

  onCancel(): void {
    this.fileInput.nativeElement.value = '';
    this.fileInfo = '';
    this.isFileSelected = false;
  }

  formatBytes(bytes: number): string {
    const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const factor = 1024;
    let index = 0;
    while (bytes >= factor) {
      bytes /= factor;
      index++;
    }
    return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
  }

  checkSpecialChar(selectedFile: any): any {
    const format = /[!@#$%^&*:"<>?{}|]/;
    return format.test(selectedFile.name);
  }

  onImport(): void {
    this.spinner.show();
    this.fileImportRequestModel = {
      file: this.selectedFile,
      step: 1,
    };

    this.store
      .dispatch(new ImportVatBridging(this.fileImportRequestModel))
      .subscribe(() => {
        const details = this.store.selectSnapshot(
          BridgingVatState.getImportData
        );
        this.spinner.hide();
        if (details) {
          this.isImportSuccess = true;
          this.onSuccess();
        } else {
          this.onError();
        }
      });
  }

  onError(): void {
    this.notifier.error(
      NotificationHeader.error,
      NotificationTextMessage.errorMessage
    );
  }

  onSuccess(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.fileUploaded
    );
  }
}
