import { Guid } from 'guid-typescript';

export class CISReturn {
  id: Guid;
  companyId?: Guid;
  fromDate?: string;
  toDate?: string;
  employmentStatus?: boolean;
  nillReturn: boolean;
  verification: boolean;
  inActivity: boolean;
  submittedCapium: boolean;
  submittedHMRC: boolean;
  isDeleted?: boolean;
  cISReturnDetails: Array<CISReturnDetail>;
}

export class CISReturnDetail {
  id: Guid;
  cISReturnId: Guid;
  accountId?: Guid;
  deductionRate: number;
  totalPayment: number;
  costofMaterial: number;
  isDeleted?: boolean;
}

export class SubmitCisReturn {
  cisReturnId: Guid;
  userName: string;
  password: string;
  isAgent: boolean;
}

export class ContractorSettings {
  businessName: string;
  businessType: string;
  utrNumber: string;
  payeReference: string;
  accountsOfficeReference: string;
  userId: string;
  password: string;
}
