import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaxLength } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import {
  GetAllBranchList,
  GetAllDepartmentListByBranch,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-schedules-notes',
  templateUrl: './add-schedules-notes.component.html',
  styleUrls: ['./add-schedules-notes.component.scss'],
})
export class AddSchedulesNotesComponent implements OnInit {
  scheduleNoteForm: FormGroup;

  @Input() triggerEditData: Observable<any>;

  maxLength = MaxLength;
  departmentList: any[] = [];
  branchList: any[] = [];

  constructor(private store: Store, private commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.getBranchList();

    this.triggerEditData?.subscribe((data) => {
      this.editNote(data);
    });

    this.scheduleNoteForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.scheduleNoteForm.touched;
    });
  }

  setForm(): void {
    this.scheduleNoteForm = new FormGroup({
      notesTitle: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      notesDescription: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      branchId: new FormControl(''),
      departmentId: new FormControl(''),
    });
  }

  editNote(data): void {
    this.scheduleNoteForm.patchValue({
      notesTitle: data.title,
      notesDescription: data.description,
      branchId: data.branchId,
      departmentId: data.departmentId,
    });
    if (data.branchId !== null && data.branchId !== undefined) {
      this.getDepartmentListByBranch(data.branchId);
    }
  }
  getBranchList(): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.scheduleNoteForm.controls.branchId.setValue(
              this.branchList[0].id
            );
            this.scheduleNoteForm.controls.branchId.setValidators(
              Validators.required
            );
            this.scheduleNoteForm.controls.branchId.updateValueAndValidity();
            this.getDepartmentListByBranch(this.branchList[0].id);
          } else {
            this.branchList = [];
            this.departmentList = [];
          }
        })
      )
      .subscribe();
  }

  onChangeBranch(branch): void {
    this.getDepartmentListByBranch(branch);
  }

  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .pipe(
          tap((res) => {
            this.departmentList = [];
            if (res.company.department.length > 0) {
              this.departmentList = res.company.department;
              this.scheduleNoteForm.controls.departmentId.setValue(
                this.departmentList[0].id
              );
              this.scheduleNoteForm.controls.departmentId.setValidators(
                Validators.required
              );
              this.scheduleNoteForm.controls.departmentId.updateValueAndValidity();
            } else {
              this.scheduleNoteForm.controls.departmentId.clearValidators();
              this.scheduleNoteForm.controls.departmentId.updateValueAndValidity();
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
      this.scheduleNoteForm.controls.departmentId.clearValidators();
      this.scheduleNoteForm.controls.departmentId.updateValueAndValidity();
    }
  }
}
