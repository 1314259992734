<div class="content">
  <div class="content-body">
    <p
      class="header-text d-flex justify-space-between align-items-center"
      *ngIf="showAccountDetails"
    >
      Contact Details
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCancel()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>
    <app-report-header-details
      [triggerCustomerId]="triggerCustomerId"
      *ngIf="showAccountDetails"
    ></app-report-header-details>

    <app-transactions-header
      (triggerDataFromHeader)="getDataFromHeader($event)"
      [showAccountDetails]="showAccountDetails"
      [listParameters]="listParameters"
      [isBankImport]="isBankImport"
    >
    </app-transactions-header>

    <p class="fw-bold mb-1 font-size-20">{{ reportText }}</p>

    <div
      class="mat-table-wrapper"
      [ngClass]="[
        totalSelectedRecords > 0 ? 'mat-table-wrapper-full' : '',
        moduleId === moduleEnum.CustomerList
          ? 'mat-table-wrapper-report-details'
          : '',
        showPaginator
          ? 'mat-table-wrapper-pagination'
          : 'mat-table-wrapper-pagination-full',
        (totalRecord$ | async) <= 10 ? 'mat-table-wrapper-full-view' : '',
        showAccountDetails ? 'mat-table-wrapper-account-details' : '',
        reportText ? 'mat-table-wrapper-header-text' : ''
      ]"
    >
      <table
        aria-describedby="main-list"
        mat-table
        matSort
        (matSortChange)="sorting(sort.active, sort.direction)"
        [dataSource]="dataSource"
      >
        <ng-container
          *ngFor="let col of columns$ | async; let colIndex = index"
          matColumnDef="{{ col.name }}"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            [id]="col.name"
            fxFlex="50"
            [ngClass]="[
              (col.dataType === dataType.String ||
                col.dataType === dataType.Currency ||
                col.dataType === dataType.Int32) &&
              col.alignment != null
                ? 'text-align-right'
                : ''
            ]"
          >
            <div>
              <span>
                {{ col.name }}
              </span>
            </div>
          </th>

          <td
            mat-cell
            *matCellDef="let element; let rowIndex = index"
            [ngClass]="[
              col.dataType === dataType.Int32 ||
              col.dataType === dataType.Currency
                ? 'text-align-right'
                : '',
              col.dataType === dataType.String &&
              element[colIndex].alignment != null
                ? 'text-align-right'
                : ''
            ]"
          >
            <div class="clamp">
              <span
                *ngIf="
                  col.dataType === dataType.String ||
                  col.dataType === dataType.Bool
                "
                title="{{ element[colIndex].rowData }}"
                [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
              >
                {{ element[colIndex].rowData }}
              </span>

              <span
                *ngIf="col.dataType === dataType.URL"
                title="{{ element[colIndex].rowData }}"
                [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
              >
                <a
                  *ngIf="moduleId === moduleEnum.Inventory"
                  class="link text-primary wmx-100 clamp"
                  (click)="redirectToDetailList(col.name, element[colIndex])"
                >
                  {{ element[colIndex].rowData }}
                </a>

                <span *ngIf="moduleId !== moduleEnum.Inventory">
                  <a
                    *ngIf="element[colIndex].moduleId > 0"
                    class="link text-primary wmx-100 clamp"
                    (click)="
                      redirect(element[colIndex].id, element[colIndex].moduleId)
                    "
                  >
                    {{ element[colIndex].rowData }}
                  </a>
                  <span
                    *ngIf="
                      element[colIndex].moduleId <= 0 ||
                      element[colIndex].moduleId === undefined
                    "
                  >
                    {{ element[colIndex].rowData }}
                  </span>
                </span>
              </span>

              <span
                *ngIf="col.dataType === dataType.DateTime"
                title="{{ element[colIndex].rowData }}"
                [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
              >
                {{ element[colIndex].rowData | date: "dd-LLL-yyyy" }}</span
              >

              <span
                *ngIf="
                  (col.dataType === dataType.Int32 ||
                    col.dataType === dataType.Currency) &&
                  element[colIndex].rowData !== ''
                "
                title="{{ element[colIndex].rowData }}"
                [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
              >
                £ {{ element[colIndex].rowData | numberPipe }}</span
              >

              <span *ngIf="col.dataType === dataType.Blob">
                <button
                  mat-button
                  (click)="downloadFile(element[colIndex].rowData)"
                  class="fw-bold text-primary"
                  disableRipple
                >
                  <mat-icon>file_download</mat-icon>
                </button></span
              >
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="columnsNames$ | async; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: columnsNames$ | async"></tr>
      </table>
      <div
        *ngIf="dataSource.data.length === 0 && noDataFound"
        class="text-align-center mt-1 fw-bold"
      >
        No records found
      </div>
      <div class="paginator-wrapper">
        <app-custom-paginator
          *ngIf="(totalRecord$ | async) > 10"
          [length]="totalRecord$ | async"
          [listParameters]="listParameters"
          (pageChanged)="pageChanged($event)"
          (pageSizeVal)="pageSizeVal($event)"
          (togglePaginator)="togglePaginator($event)"
          [triggerPaginationChange]="triggerPaginationChange"
        >
        </app-custom-paginator>
      </div>
      <app-vat-report
        [listParameters]="listParameters"
        (triggerDataFromVatReport)="getDataFromVatReport($event)"
        *ngIf="moduleId === moduleEnum.VAT"
      ></app-vat-report>
    </div>
  </div>
</div>
