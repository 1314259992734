import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MaxLength, NotificationTextMessage } from '@app/core/Enum';
import {
  CISBusinessType,
  CisSubcontractorAction,
  CISTaxTreatmentType,
  CISTraderType,
  CISVerifyType,
} from '@app/core/Enum/cis';
import { CommonService, HighlightRow } from '@app/core/Services';
import { Observable } from 'rxjs';
import { CisManualVerificationPopupComponent } from './cis-manual-verification-popup/cis-manual-verification-popup.component';

@Component({
  selector: 'app-add-CIS-information',
  templateUrl: './add-CIS-information.component.html',
  styleUrls: ['./add-CIS-information.component.scss'],
})
export class AddCISInformationComponent implements OnInit {
  cisInformationForm: FormGroup;

  businessTypeList: any[] = [];
  tradingNameList: any[] = [];
  verifyList: any[] = [];
  taxTreatmentList: any[] = [];
  verifyData: any;

  toggleVerifyStatus = true;
  toggleStatus = true;
  patnerShipShow = false;
  tradingNameShow = true;
  maxLength = MaxLength;
  verificationStatus = 0;

  notificationMessage = NotificationTextMessage;

  @Input() triggerEditData: Observable<any>;

  @Output()
  readonly triggerOnVerificationClick = new EventEmitter<any>();

  cisSubcontractorAction = CisSubcontractorAction;

  constructor(
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setForm();

    this.triggerEditData?.subscribe((data) => {
      this.editCISInfo(data);
    });

    this.cisInformationForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.cisInformationForm.touched;
    });
  }

  setForm(): void {
    this.cisInformationForm = new FormGroup({
      businessTypeId: new FormControl('', [Validators.required]),
      tradingName: new FormControl('', [Validators.required]),
      tradingType: new FormControl('', [Validators.required]),
      subContractorUTR: new FormControl('', [Validators.pattern(/[0-9]/)]),
      niNumber: new FormControl('', [
        Validators.pattern(
          /^[ABCEGHJKLMNOPRSTWXYZ][ABCEGHJKLMNPRSTWXYZ][0-9]{6}[A-D]{1}$/
        ),
      ]),
      companyNumber: new FormControl(''),
      workReference: new FormControl(''),
      verify: new FormControl(''),
      status: new FormControl({ value: '', disabled: true }),
      verificationNumber: new FormControl({ value: '', disabled: true }, [
        Validators.pattern('V[0-9]{10}[A-HJ-NP-Z]{0,2}'),
      ]),
      lastVerified: new FormControl({ value: new Date(), disabled: true }),
      taxTreatment: new FormControl({ value: '', disabled: true }),
      partnershipUTR: new FormControl('', [Validators.pattern(/[0-9]/)]),
      partnershipName: new FormControl(''),
      firstName: new FormControl('', [Validators.required]),
      secondName: new FormControl(''),
      surname: new FormControl('', [Validators.required]),
      paymentFrequency: new FormControl({ value: 'Monthly', disabled: true }),
      userName: new FormControl(''),
      password: new FormControl(''),
    });
    this.getBusinessType();
    this.getTradingNameList();
    this.getVerifyList();
    this.getTaxTreatmentList();
  }

  editCISInfo(data): void {
    this.cisInformationForm.patchValue({
      businessTypeId: data.cisDetail.businessTypeId.toString(),
      tradingName: data.cisDetail.tradingName,
      subContractorUTR: data.cisDetail.subContractorUTR,
      niNumber: data.cisDetail.niNumber,
      companyNumber: data.cisDetail.companyNumber,
      workReference: data.cisDetail.workReference,
      status: data.cisDetail.status,
      verificationNumber: data.cisDetail.verificationNumber,
      lastVerified: data.cisDetail.lastVerified,
      taxTreatment: +data.cisDetail.taxTreatmentTypeId,
      paymentFrequency: data.cisDetail.paymentFrequency,
      verify: data.cisDetail.verifyTypeId.toString(),
      partnershipName: data.cisDetail.partnershipName,
      partnershipUTR: data.cisDetail.partnershipUTR,
      surname: data.cisDetail.surName,
      secondName: data.cisDetail.secondName,
      firstName: data.cisDetail.firstName,
    });

    this.onVerifyChange(data.cisDetail.verifyTypeId.toString());
  }

  getBusinessType(): void {
    for (let key in CISBusinessType) {
      this.businessTypeList.push({ name: key, id: CISBusinessType[key] });
    }
    this.cisInformationForm.controls.businessTypeId.setValue(
      this.businessTypeList[0].id
    );
  }

  getTradingNameList(): void {
    for (let key in CISTraderType) {
      this.tradingNameList.push({ name: key, id: CISTraderType[key] });
    }
    this.cisInformationForm.controls.tradingType.setValue(
      this.tradingNameList[0].id
    );
    this.onTradingNameChange(this.tradingNameList[0].id);
  }

  getVerifyList(): void {
    this.verifyList = [];
    for (let key in CISVerifyType) {
      this.verifyList.push({ name: key, id: CISVerifyType[key] });
    }
    this.cisInformationForm.controls.verify.setValue(this.verifyList[0].id);
  }

  getTaxTreatmentList(): void {
    this.taxTreatmentList = [
      {
        id: CISTaxTreatmentType.Unmatched,
        name: CISTaxTreatmentType[CISTaxTreatmentType.Unmatched],
      },
      {
        id: CISTaxTreatmentType.Gross,
        name: CISTaxTreatmentType[CISTaxTreatmentType.Gross],
      },
      {
        id: CISTaxTreatmentType.Net,
        name: CISTaxTreatmentType[CISTaxTreatmentType.Net],
      },
    ];

    this.cisInformationForm.controls.taxTreatment.setValue(
      this.taxTreatmentList[0].id
    );
  }

  onVerifyChange(event, showPopup: boolean = false): void {
    if (event === CISVerifyType['Already Verified']) {
      this.toggleVerifyStatus = true;
      this.toggleStatus = false;
      this.cisInformationForm.controls.verificationNumber.enable();
      this.cisInformationForm.controls.lastVerified.enable();
      this.cisInformationForm.controls.taxTreatment.enable();
      this.cisInformationForm
        .get('verificationNumber')
        ?.setValidators(Validators.required);
      this.cisInformationForm
        .get('verificationNumber')
        ?.updateValueAndValidity();
      if (showPopup) {
        this.dialog
          .open(CisManualVerificationPopupComponent)
          .afterClosed()
          .subscribe();
      }
    } else if (event === CISVerifyType.Online) {
      this.toggleVerifyStatus = true;
      this.toggleStatus = true;
      this.cisInformationForm.controls.verificationNumber.disable();
      this.cisInformationForm.controls.lastVerified.disable();
      this.cisInformationForm.controls.taxTreatment.disable();
      this.cisInformationForm.get('verificationNumber')?.clearValidators();
      this.cisInformationForm
        .get('verificationNumber')
        ?.updateValueAndValidity();
    }
  }

  onBusinessTypeChange(event): void {
    if (event === CISBusinessType.Partnership) {
      this.patnerShipShow = true;
    } else {
      this.patnerShipShow = false;
    }
  }

  onTradingNameChange(event): void {
    if (event === CISTraderType['Personal Name']) {
      this.tradingNameShow = false;
      this.cisInformationForm
        .get('firstName')
        ?.setValidators(Validators.required);
      this.cisInformationForm
        .get('surname')
        ?.setValidators(Validators.required);
      this.cisInformationForm.get('tradingName')?.clearValidators();
    } else {
      this.cisInformationForm
        .get('tradingName')
        ?.setValidators(Validators.required);
      this.cisInformationForm.get('firstName')?.clearValidators();
      this.cisInformationForm.get('surname')?.clearValidators();
      this.tradingNameShow = true;
    }
    this.cisInformationForm.controls['tradingName'].updateValueAndValidity();
    this.cisInformationForm.controls['firstName'].updateValueAndValidity();
    this.cisInformationForm.controls['surname'].updateValueAndValidity();
  }

  verifyClick(verificationStatus: number): void {
    this.verificationStatus = verificationStatus;
  }

  onSubmit(): void {
    this.triggerOnVerificationClick.emit(this.verificationStatus);
  }

  cancleClick(): void {
    this.verificationStatus = 0;
  }
}
