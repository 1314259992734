import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  ConfirmationType,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { CommonService, HighlightRow } from '@app/core/Services';
import { SetBranchListForCompany } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';

@Component({
  selector: 'app-add-branch',
  templateUrl: './add-branch.component.html',
  styleUrls: ['./add-branch.component.scss'],
})
export class AddBranchComponent implements OnInit {
  formBranchDetail: UntypedFormGroup;
  branchArray: any;
  notificationMessage = NotificationTextMessage;

  displayColumns: string[] = ['srNo', 'name', 'closeButton'];
  branchData: any[] = [];

  tableDataSource: MatTableDataSource<AbstractControl>;

  @Input() triggerEditData: Observable<any>;
  @Input() triggerEditBranchData: Observable<any>;
  @Input() triggerSetDefaultBranch: Observable<any>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private highlightRow: HighlightRow,
    public datepipe: DatePipe,
    public commonService: CommonService,
    private store: Store,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setBranchDetailsForm(false);

    this.triggerEditBranchData?.subscribe((data) => {
      this.editBranch(data);
    });

    this.triggerSetDefaultBranch?.subscribe((data) => {
      if (data) {
        this.setDefaultBranch();
      }
    });

    this.formBranchDetail.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.formBranchDetail.touched;
    });
  }

  setDefaultBranch(): void {
    this.branchData = [];
    this.highlightRow.branchData = [];
    this.branchArray = this.formBranchDetail.get(
      'branchArray'
    ) as UntypedFormArray;

    this.branchArray.clear();
    const data = {
      srNo: 1,
      id: Guid.EMPTY as unknown as Guid,
      name: 'HQ',
      isDefault: true,
    };

    this.branchArray.push(this.buildOrderItemsForm(data));
    this.store.dispatch(new SetBranchListForCompany(this.branchArray?.value));

    this.setDataSource(this.branchArray);
  }

  editBranch(data: any): void {
    this.branchData = [];
    this.highlightRow.branchData = [];
    this.branchArray = this.formBranchDetail.get(
      'branchArray'
    ) as UntypedFormArray;

    this.branchArray.clear();

    data.forEach((element) => {
      this.branchData?.push(element);
    });

    this.branchData.forEach((item, i) => {
      this.branchArray.push(this.buildOrderItemsForm(item));
    });
    this.store.dispatch(new SetBranchListForCompany(this.branchData));
    this.setDataSource(this.branchArray);
  }

  buildOrderItemsForm(item: any): FormGroup {
    this.highlightRow.branchData.push({
      srNo: item.srNo,
      id: item.id,
      name: item.name,
      isDefault: item.isDefault,
    });

    return this.formBuilder.group({
      srNo: item.srNo,
      id: item.id,
      name: item.name,
      isDefault: item.isDefault,
    });
  }

  setBranchDetailsForm(addNewRow: boolean): void {
    this.formBranchDetail = new FormGroup({
      branchArray: new UntypedFormArray([]),
    });

    this.branchArray = this.formBranchDetail.get(
      'branchArray'
    ) as UntypedFormArray;

    this.setDataSource(this.branchArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.branchArray = this.formBranchDetail.get(
      'branchArray'
    ) as UntypedFormArray;

    this.branchArray.push(this.setForm());
    this.highlightRow.branchData = [];

    this.branchArray?.getRawValue().forEach((x) => {
      this.highlightRow.branchData.push({
        srNo: x.srNo,
        id: x.id,
        name: x.name,
        isDefault: x.isDefault,
      });
    });

    this.setDataSource(this.branchArray);
  }

  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  onBranchNameChange(event: any): void {
    this.store.dispatch(new SetBranchListForCompany(this.branchArray?.value));
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      srNo: new FormControl(this.branchArray.length + 1),
      id: new FormControl(Guid.EMPTY as unknown as Guid),
      name: new FormControl('', [
        this.commonService.whiteSpaceValidate,
        Validators.required,
      ]),
      isDefault: new FormControl(false),
    });
  }

  onDeleteBranchDetails(index: number): void {
    this.branchArray = this.formBranchDetail.get(
      'branchArray'
    ) as UntypedFormArray;
    if (this.branchArray.length > 1) {
      if (
        this.branchArray.value[index].id !== undefined &&
        this.branchArray.value[index].id !== null &&
        this.branchArray.value[index].id !== Guid.EMPTY
      ) {
        this.dialog
          .open(ConfirmationBoxComponent, {
            data: {
              ids: [this.branchArray.value[index].id],
              type: ConfirmationType.DeleteBranch,
              headerText: NotificationHeader.deleteConfirmation,
              detailText: NotificationDetails.deleteAllDetailText,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.branchArray = this.formBranchDetail.get(
                'branchArray'
              ) as UntypedFormArray;
              if (this.branchArray.length === 1) {
                return;
              }
              this.branchArray.removeAt(index);
              this.highlightRow.branchData.splice(index, 1);
              this.onBranchNameChange('');
              this.setDataSource(this.branchArray);
            }
          });
      } else {
        this.branchArray = this.formBranchDetail.get(
          'branchArray'
        ) as UntypedFormArray;
        if (this.branchArray.length === 1) {
          return;
        }
        this.branchArray.removeAt(index);
        this.highlightRow.branchData.splice(index, 1);
        this.onBranchNameChange('');
        this.setDataSource(this.branchArray);
      }
    } else {
      this.commonService.onFailure('Minimum one branch is required!');
    }
  }
}
