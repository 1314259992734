<div class="alert-message" *ngIf="isViewPermission$ | async">
  <mat-icon class="material-icons-outlined">error_outline</mat-icon>
  {{ commonNotificationText.ViewOnlyAccessMessage }}
</div>
<div
  class="content-body content-body-scroll"
  [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
>
  <form [formGroup]="vatSettingsForm" class="p-0">
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>VAT Scheme <span class="text-danger-300">*</span></p>
        <ng-select
          #vatScheme
          (focus)="vatScheme.open()"
          (valueChange)="vatScheme.close()"
          [disableOptionCentering]="true"
          formControlName="vatScheme"
          panelClass="mat-select-position"
          (change)="checkValue()"
          required
        >
          <ng-option *ngFor="let sl of vatSchemeList" [value]="sl.id">
            <div title="{{ sl.name }}">{{ sl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field datepicker">
        <p>Registration Date <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="registrationDate"
            [matDatepicker]="registrationDateDatePicker"
            autocomplete="off"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="registrationDateDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #registrationDateDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              vatSettingsForm.get('registrationDate')
            ).invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              vatSettingsForm.get('registrationDate')
            ).lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Registration No <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="registrationNo"
            required
            autocomplete="off"
            allowNumberOnly
            maxlength="{{ maxLength.maxLength9 }}"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>VAT Submit Type <span class="text-danger-300">*</span></p>
        <ng-select
          #activity
          (focus)="activity.open()"
          (valueChange)="activity.close()"
          [disableOptionCentering]="true"
          formControlName="vatSubmitType"
          panelClass="mat-select-position"
          required
        >
          <ng-option *ngFor="let rl of vatReturnTypeList" [value]="rl.id">
            <div title="{{ rl.name }}">{{ rl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div *ngIf="isFlatRate">
      <div class="d-flex gap-40">
        <div class="form-field">
          <p>Flat Rate</p>
          <mat-form-field>
            <input
              allow_14_2_Decimal
              matInput
              formControlName="flatRate"
              autocomplete="off"
              allowDecimal
            />
          </mat-form-field>
        </div>
        <div class="form-field m-0 d-flex align-items-center">
          <mat-checkbox
            formControlName="isDiscountPeriod"
            (change)="onCheckBoxSelected($event.checked)"
            >Discount Period
          </mat-checkbox>
        </div>
      </div>
      <div class="d-flex gap-40">
        <div class="form-field datepicker" *ngIf="isDiscountPeriod">
          <p>From</p>
          <mat-form-field appearance="standard">
            <input
              [min]="this.vatSettingsForm.controls.registrationDate.value"
              matInput
              formControlName="from"
              [matDatepicker]="fromDatePicker"
              autocomplete="off"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="fromDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-field datepicker" *ngIf="isDiscountPeriod">
          <p>To</p>
          <mat-form-field appearance="standard">
            <input
              [min]="this.vatSettingsForm.controls.from.value"
              matInput
              formControlName="to"
              [matDatepicker]="toDatePicker"
              autocomplete="off"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="toDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex mr-40">
        <div class="form-field" *ngIf="isDiscountPeriod">
          <p>Discounted Rate</p>
          <mat-form-field>
            <input
              matInput
              formControlName="discountedRate"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
