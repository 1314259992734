export enum DepreciationMethodType {
  'Reducing Balance' = 1,
  'Straight Line' = 2,
}

export enum AccountTypeList {
  'CapiumPay Control Account' = 1,
  'CapiumPay' = 2,
  'Cash in Hand' = 3,
}

export enum DisposalType {
  Sell = 1,
  Disposed = 2,
}
