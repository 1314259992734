import { Injectable } from '@angular/core';
import { PermissionType } from '@app/core/Enum/settings';
import { MenuModel, ProfileInfoModel } from '@app/core/Models';
import { MenuService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  GetAddMenu,
  GetMenu,
  GetProfileInfo,
  GetReportMenu,
  SetModulePermission,
} from './menu.action';

export class MenuStateInfo {
  Menu: Array<MenuModel>;
  hasEditPermission: boolean;
  hasAddPermission: boolean;
  hasDeletePermission: boolean;
  moduleName: string;
  listingPageURL: string;
  moduleId: number;
  profileInfo?: ProfileInfoModel;
  addMenu: Array<MenuModel>;
  userName: string;
  treeNode?: any;
  reportMenu: Array<MenuModel>;
  isViewPermission: boolean;
}

@State<MenuStateInfo>({
  name: 'Menu',
  defaults: {
    Menu: [],
    hasEditPermission: false,
    hasAddPermission: false,
    hasDeletePermission: false,
    moduleName: '',
    listingPageURL: '',
    moduleId: 0,
    addMenu: [],
    userName: '',
    reportMenu: [],
    isViewPermission: false,
  },
})
@Injectable()
export class MenuState {
  constructor(private menuService: MenuService) {}

  @Selector()
  static getSelectedMenuPermission(state: MenuStateInfo) {
    return state.isViewPermission ?? false;
  }

  @Selector()
  static menu(state: MenuStateInfo): Array<MenuModel> {
    return state.Menu;
  }

  @Selector()
  static userName(state: MenuStateInfo): string {
    return state.userName;
  }

  @Selector()
  static hasEditPermission(state: MenuStateInfo): boolean {
    return state.hasEditPermission;
  }

  @Selector()
  static hasAddPermission(state: MenuStateInfo): boolean {
    return state.hasAddPermission;
  }

  @Selector()
  static hasDeletePermission(state: MenuStateInfo): boolean {
    return state.hasDeletePermission;
  }

  @Selector()
  static moduleName(state: MenuStateInfo): string {
    return state.moduleName;
  }

  @Selector()
  static getListingURL(state: MenuStateInfo): string {
    return state.listingPageURL;
  }

  @Selector()
  static moduleId(state: MenuStateInfo): number {
    return state.moduleId;
  }

  @Selector()
  static treeNode(state: MenuStateInfo): any {
    return state.treeNode;
  }

  @Action(GetMenu)
  getMenu(
    { patchState }: StateContext<MenuStateInfo>,
    action: GetMenu
  ): Observable<any> {
    return this.menuService.getMenu().pipe(
      tap((res) => {
        patchState({
          Menu: res,
        });
      })
    );
  }

  @Action(GetReportMenu)
  getReportMenu(
    { patchState }: StateContext<MenuStateInfo>,
    action: GetReportMenu
  ): Observable<any> {
    return this.menuService.getReportMenu().pipe(
      tap((res) => {
        patchState({
          reportMenu: res,
        });
      })
    );
  }

  @Action(SetModulePermission)
  setModulePermission(
    { patchState }: StateContext<MenuStateInfo>,
    action: SetModulePermission
  ) {
    patchState({
      hasEditPermission: action.moduleData.hasEditPermission,
      hasAddPermission: action.moduleData.hasAddPermission,
      hasDeletePermission: action.moduleData.hasDeletePermission,
      moduleName: action.moduleData.name,
      moduleId: action.moduleData.id,
      listingPageURL: action.moduleData.url,
      treeNode: action.moduleData,
      isViewPermission: action.moduleData?.permissionId === PermissionType.View,
    });
  }

  @Action(GetProfileInfo)
  getProfileInfo({ patchState }: StateContext<MenuStateInfo>): Observable<any> {
    return this.menuService.getProfileInfo().pipe(
      tap((res) => {
        patchState({
          profileInfo: res,
          userName: res.fullName,
        });
      })
    );
  }

  @Action(GetAddMenu)
  getAddMenu(
    { patchState }: StateContext<MenuStateInfo>,
    action: GetAddMenu
  ): Observable<any> {
    return this.menuService.getAddMenu().pipe(
      tap((res) => {
        patchState({
          addMenu: res,
        });
      })
    );
  }
}
