import { Guid } from 'guid-typescript';

export class SubmitVATModel {
  id?: Guid;
  companyId?: Guid;
  periodKey?: string;
  fromDate?: string;
  toDate?: string;
  description?: string;
  finalReturn?: boolean;
  vatDueSales?: number;
  vatDueAcquisitions?: number;
  totalVatDue?: number;
  vatReclaimedCurrPeriod?: number;
  netVatDue?: number;
  totalValueSalesExVAT?: number;
  totalValuePurchasesExVAT?: number;
  totalValueGoodsSuppliedExVAT?: number;
  totalAcquisitionsExVAT?: number;
  submittedCapium?: boolean;
  submittedHMRC?: boolean;
  isBridgingVAT?: boolean;
  fileURL?: string;
  vatSchemeId?:number;
  subReturns?:[]
}
export class VATReturnDateModel {
  fromDate: Date;
  toDate: Date;
}

export class IncluceExcludeTransactionModel {
  id?: Guid;
  typeId?: number;
  isVatReturnIncluded?: boolean;
  vatReturnId?: string;
}

export class PaymentStatusViewModel {
  id: string;
  paymentStatus: boolean;
  paymentDate: string;
  paymentMode: string;
  reference: string;
  journalId: string;
  entryNumber: string;
  netVatDue: number;
  fromAccount: string;
  toAccount: string;
}

export class FlatRateAdjusmentDataModel {
  flatRateSavings: number;
  vATonPurchases: number;
  finalAdjustmentAmount: number;
  toDate: string;
}

export class FlatRateAdjustmentJournalModel {
  id: Guid;
  savingAmount: number;
  purchaseAmount: number;
  vatAmount: number;
  date?: string;
  reference: string;
}
