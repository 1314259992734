<div class="import-stepper content-body">
  <div class="d-flex flex-column">
    <mat-stepper #stepper>
      <mat-step disableRipple>
        <ng-template matStepLabel>Upload</ng-template>
        <form>
          <div class="upload-detail">
            <p class="import-title text-primary d-flex mt-1 mb-0 pb-5">
              Client
            </p>
            <span class="import-title mt-0 font-size-14">Choose a Type</span>
            <div class="import-leads">
              <div class="file-box">
                <div class="d-flex align-items-center gap-10">
                  <img src="assets/images/upload-icon.svg" alt="Icon" />
                  <p class="m-0">From File</p>
                </div>
                <div class="drop-detail">
                  <p>Drag and drop files to upload</p>
                  <h4><span>or</span></h4>
                  <button class="file-upload-button primary-button">
                    <input type="file" #fileInput accept=".xls, .xlsx" />Browse
                  </button>
                  <p class="file-info">Clients_11012024_0914.xlsx (23.35 kB)</p>
                  <div
                    class="d-flex align-items-center justify-content-center gap-5 cursor-pointer"
                  >
                    <mat-icon fontSet="material-icons-outlined"
                      >file_download</mat-icon
                    >
                    <p class="m-0 link">Download sample file</p>
                  </div>
                  <div class="file-formate">
                    <p>File Format : XLSX</p>
                    <p>Max Size : 10 MB</p>
                  </div>
                </div>
              </div>
              <div class="or-text"><span>or</span></div>
              <div class="other-box">
                <div class="d-flex align-items-center gap-10">
                  <img src="assets/images/time-icon.svg" alt="Icon" />
                  <p class="m-0">From Other Capium Modules</p>
                </div>
                <div class="pt-1 d-flex gap-40">
                  <div class="connect-wraper">
                    <img src="assets/images/connect-img.svg" alt="Connect" />
                    <div class="connect-details">
                      <p>
                        Want to import clients and users from any of your other
                        Capium modules?
                      </p>
                    </div>
                  </div>
                  <div>
                    <div class="form-field">
                      <p class="fw-normal">Module</p>
                      <div class="d-flex align-items-start gap-15">
                        <ng-select
                          class="w-200 import-dropdown"
                          panelClass="myPanelClass"
                          [disableOptionCentering]="true"
                          #module
                          (focus)="module.open()"
                          (valueChange)="module.close()"
                        >
                          <ng-option>--Select--</ng-option>
                          <ng-option>Time and Fees</ng-option>
                          <ng-option>Corporation Tax</ng-option>
                          <ng-option>SAMTD</ng-option>
                          <ng-option>Accounts Production</ng-option>
                          <ng-option>Charity</ng-option>
                          <ng-option>Bookkeeping</ng-option>
                        </ng-select>
                      </div>
                    </div>
                    <div class="form-field">
                      <p class="fw-normal">Client Type</p>
                      <mat-radio-group
                        aria-label="Select an option"
                        class="d-flex text-align-center gap-10"
                      >
                        <mat-radio-button value="0"
                          >One time import</mat-radio-button
                        >
                        <mat-radio-button value="1"
                          >Recurring import</mat-radio-button
                        >
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </mat-step>
      <mat-step disableRipple>
        <ng-template matStepLabel>Verify Data</ng-template>
        <div class="pt-2">
          <mat-accordion [multi]="true">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="d-flex flex-column">
                    <span>Success List (20)</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="mat-table-wrapper">
                <table
                  mat-table
                  [dataSource]="dataSource"
                  aria-describedby="Income by Client Table"
                >
                  <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef>ID</th>
                    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.name }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="contact">
                    <th mat-header-cell *matHeaderCellDef>Contact</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.contact }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="tasks">
                    <th mat-header-cell *matHeaderCellDef>Tasks</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.tasks }}
                      <mat-form-field appearance="fill" class="w-120">
                        <mat-select
                          placeholder="Select"
                          panelClass="myPanelClass"
                          [disableOptionCentering]="true"
                          #project
                          (focus)="project.open()"
                          (valueChange)="project.close()"
                        >
                          <mat-option value="0">Select</mat-option>
                          <mat-option value="1">Bookkeeping</mat-option>
                          <mat-option value="2">Timenfee</mat-option>
                          <mat-option value="3">CT</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="jobs">
                    <th mat-header-cell *matHeaderCellDef>Jobs</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.jobs }}
                    </td>
                  </ng-container>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>Error List (2)</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-step>
      <mat-step disableRipple>
        <ng-template matStepLabel>Details</ng-template>
        <form>
          <div class="end-details">
            <div class="list-detail">
              <p>Client Imported (10)</p>
              <p>No. of records duplicate (0)</p>
              <p>No. of error records (0)</p>
            </div>
          </div>
        </form>
      </mat-step>
    </mat-stepper>
  </div>
</div>
