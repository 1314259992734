<div class="content-body-scroll comparitive-report">
  <div class="main-list content content-body">
    <div class="report-grid">
      <div class="header-actions wp-100 header-actions-report mb-15">
        <div
          class="content-header d-flex align-items-end justify-space-between header-filters header-count header-count-2"
        >
          <div class="d-flex align-items-end gap-10">
            <div>
              <div>
                <div>
                  <div class="d-flex flex-column gap-5">
                    <span class="fw-bold mb-5">Type:</span>
                    <ng-select
                      #comparitiveReportList
                      (focus)="comparitiveReportList.open()"
                      (valueChange)="comparitiveReportList.close()"
                      class="w-120"
                      [disableOptionCentering]="true"
                      [(ngModel)]="comparitiveType"
                      (change)="typeChange()"
                    >
                      <ng-option
                        *ngFor="let day of comparitiveReportTypeList"
                        [value]="day.value"
                      >
                        <div title="{{ day.name }}">{{ day.name }}</div>
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <div class="d-flex flex-column gap-5">
                    <span class="fw-bold mb-5">Period:</span>
                    <ng-select
                      class="input-sm"
                      id="cboperiod"
                      [(ngModel)]="comparitivePeriod"
                      (change)="onPeriodChange()"
                    >
                      <ng-option
                        *ngFor="let day of comparitiveReportPeriodList"
                        [value]="day.value"
                      >
                        <div title="{{ day.name }}">{{ day.name }}</div>
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                accountingPeriodList.length > 0 ||
                multiaccountingPeriodList.length > 0
              "
            >
              <div>
                <div class="d-flex flex-column gap-5">
                  <span class="fw-bold mb-5">Year:</span>
                  <ng-select
                    #accountingPeriod
                    (focus)="accountingPeriod.open()"
                    (valueChange)="accountingPeriod.close()"
                    class="w-210"
                    [disableOptionCentering]="true"
                    [(ngModel)]="cboyear"
                    (change)="onYearChange()"
                    *ngIf="accountingPeriodList.length > 0"
                  >
                    <ng-option
                      *ngFor="let year of accountingPeriodList"
                      [value]="year"
                    >
                      <div title="{{ year.name }}">{{ year.name }}</div>
                    </ng-option>
                  </ng-select>
                  <ejs-multiselect
                    id="multiselect-checkbox"
                    class="w-230"
                    #multiAccountingPeriod
                    [fields]="multiAccountingPeriodFields"
                    [dataSource]="multiaccountingPeriodList"
                    [mode]="yearmode"
                    [placeholder]="yearfilterPlaceholder"
                    [showDropDownIcon]="true"
                    showSelectAll="true"
                    [(ngModel)]="cbomultiyear"
                    (change)="onYearChange($event)"
                    *ngIf="multiaccountingPeriodList.length > 0"
                  ></ejs-multiselect>
                </div>
              </div>
            </div>
            <div
              class="d-flex flex-column gap-5 mr-10"
              *ngIf="branchList.length > 0"
            >
              <span class="fw-bold">Branch:</span>
              <ejs-multiselect
                id="multiselect-checkbox"
                #branch
                [dataSource]="branchList"
                [placeholder]="branchWaterMark"
                [fields]="branchFields"
                [mode]="branchmode"
                [popupHeight]="branchpopHeight"
                [showDropDownIcon]="true"
                showSelectAll="true"
                [(ngModel)]="selectedBranchValues"
                [filterBarPlaceholder]="branchfilterPlaceholder"
                (change)="onBranchMultiSelectChange($event)"
              ></ejs-multiselect>
            </div>
            <div
              class="d-flex flex-column gap-5"
              *ngIf="departmentList.length > 0"
            >
              <span class="fw-bold">Department:</span>
              <ejs-multiselect
                id="multiselect-checkbox"
                #department
                [dataSource]="departmentList"
                [placeholder]="departmentWaterMark"
                [fields]="departmentFields"
                [mode]="departmentmode"
                [popupHeight]="departmentpopHeight"
                [showDropDownIcon]="true"
                showSelectAll="true"
                [filterBarPlaceholder]="departmentfilterPlaceholder"
                [(ngModel)]="selectedDepartmentValues"
                (change)="onDepartmentMultiSelectChange($event)"
              ></ejs-multiselect>
            </div>
          </div>
          <div class="toolbar">
            <button mat-button [matMenuTriggerFor]="menu" disableRipple>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu
              #menu="matMenu"
              class="action-menu more-menu"
              xPosition="before"
            >
              <button mat-menu-item (click)="sendMail()" disableRipple>
                <mat-icon fontSet="material-icons-outlined">mail</mat-icon>Send
                Mail
              </button>
              <button
                mat-menu-item
                (click)="export(exportType.PDF)"
                disableRipple
              >
                <mat-icon fontSet="material-icons-outlined"
                  >picture_as_pdf</mat-icon
                >Export As PDF
              </button>

              <button
                mat-menu-item
                (click)="export(exportType.Excel)"
                disableRipple
              >
                <mat-icon>border_all</mat-icon>Export to Excel
              </button>

              <button
                mat-menu-item
                (click)="export(exportType.CSV)"
                disableRipple
              >
                <mat-icon fontSet="material-icons-outlined"
                  >description</mat-icon
                >Export to CSV
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <ejs-grid
        *ngIf="columns.length > 0"
        #grid
        [dataSource]="gridData"
        [allowGrouping]="false"
        [allowReordering]="true"
        [allowSelection]="false"
        [allowPaging]="false"
        [allowFiltering]="false"
        [allowResizing]="false"
        [allowSorting]="false"
        showColumnMenu="false"
        [height]="gridHeight"
      >
        <e-columns>
          <ng-container *ngFor="let col of columns; let colIndex = index">
            <e-column
              [headerText]="col.name"
              [field]="col.name + '.rowData'"
              [hidden]="!col.isSelected"
              [width]="250"
              [allowSorting]="false"
              [allowFiltering]="col.columnType"
              [allowGrouping]="false"
              [visible]="col.isSelected"
              [isPrimaryKey]="col.name === 'Id'"
              [showInColumnChooser]="false"
              [customAttributes]="{ class: getClassName(col) }"
            >
              <ng-template #template let-row let-rowIndex>
                <div
                  *ngIf="
                    (col.dataType === dataType.String ||
                      col.dataType === dataType.Bool ||
                      col.dataType === dataType.DateTime) &&
                    col.name !== 'Status'
                  "
                  class="{{ col.alignment }}"
                  [ngClass]="{
                    'fw-bold': row[col.name]?.isBold
                  }"
                >
                  <span title="{{ row[col.name]?.rowData }}">
                    {{ row[col.name]?.rowData }}</span
                  >
                </div>

                <div
                  *ngIf="
                    col.dataType === dataType.Int32 && col.name !== 'Status'
                  "
                  class="{{ col.alignment }} clamp pr-40 white-space-nowrap"
                  [ngClass]="{
                    'fw-bold': row[col.name]?.isBold
                  }"
                >
                  <span
                    *ngIf="
                      row[col.name]?.rowData !== undefined &&
                      row[col.name]?.rowData !== null &&
                      row[col.name]?.rowData !== ''
                    "
                    title="{{ row[col.name]?.rowData | numberPipe }}"
                  >
                    {{ row[col.name]?.rowData | numberPipe }}</span
                  >
                </div>
                <div
                  *ngIf="
                    col.dataType === dataType.Currency && col.name !== 'Status'
                  "
                  class="{{ col.alignment }} clamp pr-40 white-space-nowrap"
                  [ngClass]="{
                    'fw-bold': row[col.name]?.isBold
                  }"
                >
                  <span
                    *ngIf="
                      row[col.name]?.rowData !== undefined &&
                      row[col.name]?.rowData !== null &&
                      row[col.name]?.rowData !== ''
                    "
                    title="£ {{ row[col.name]?.rowData | numberPipe }}"
                  >
                    £ {{ row[col.name]?.rowData | numberPipe }}</span
                  >
                </div>
                <div
                  *ngIf="
                    col.dataType === dataType.CurrencySymbol &&
                    col.name !== 'Status'
                  "
                  class="{{ col.alignment }} clamp pr-40 white-space-nowrap"
                  [ngClass]="{
                    'fw-bold': row[col.name]?.isBold
                  }"
                >
                  <div>
                    <span
                      title="{{ row[col.name]?.currencySymbol }} {{
                        row[col.name]?.rowData | numberPipe
                      }}"
                    >
                      {{ row[col.name]?.currencySymbol }}
                      {{ row[col.name]?.rowData | numberPipe }}</span
                    >
                  </div>
                </div>

                <div
                  *ngIf="col.dataType === dataType.URL && col.name !== 'Status'"
                  class="{{ col.alignment }}"
                  [ngClass]="{
                    'fw-bold': row[col.name]?.isBold
                  }"
                  title="{{ row[col.name]?.rowData }}"
                >
                  <a
                    *ngIf="row[col.name]?.moduleId > 0"
                    class="link text-primary wmx-100 clamp"
                    (click)="redirect(col.name, row[col.name])"
                  >
                    {{ row[col.name]?.rowData }}
                  </a>
                </div>

                <div
                  *ngIf="
                    col.dataType === dataType.URL &&
                    row[col.name]?.id !== '' &&
                    row[col.name]?.id !== null &&
                    row[col.name]?.id !== undefined &&
                    row[col?.name]?.moduleId === 0 &&
                    col.name !== 'Status'
                  "
                  title="{{ row[col.name]?.rowData }}"
                  [ngClass]="row[col.name]?.isBold ? 'fw-bold' : ''"
                >
                  <a
                    class="link fw-bold wmx-100 clamp"
                    (click)="
                      redirect(row[col.name]?.id, row[col.name]?.moduleId)
                    "
                  >
                    {{ row[col.name]?.rowData }}
                  </a>
                </div>

                <div
                  class="clamp"
                  *ngIf="
                    col.dataType === dataType.URL &&
                    (row[col.name]?.id === '' ||
                      row[col.name]?.id === null ||
                      row[col.name]?.id === undefined) &&
                    row[col?.name]?.moduleId === 0 &&
                    col.name !== 'Status'
                  "
                  title="{{ row[col.name]?.rowData }}"
                  [ngClass]="row[col.name]?.isBold ? 'fw-bold' : ''"
                >
                  {{ row[col.name]?.rowData }}
                </div>

                <div
                  *ngIf="col.name === 'Status'"
                  title="{{ row[col.name]?.rowData }}"
                  [ngClass]="
                    row[col.name]?.rowData === 'Active' ||
                    row[col.name]?.rowData === 'Paid'
                      ? 'status-active'
                      : 'status-archived'
                  "
                >
                  {{ row[col.name]?.rowData }}
                </div>
              </ng-template>
            </e-column>
          </ng-container>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</div>
