import { Injectable } from '@angular/core';
import { OpeningBalance } from '@app/core/Models';
import { OpeningBalanceService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  GetOpeningBalanceBasedOnAPId,
  GetOpeningBalanceList,
  GetOpeningBalanceListBasedOnBranchId,
  SaveOpeningBalance,
  SaveOpeningBalanceAdvance,
  SaveOpeningBalanceExplain,
  SaveTrialBalance,
} from './opening-balance.action';

export class OpeningBalanceStateInfo {
  openingBalanceData: Array<OpeningBalance>;
  openingBalanceBasedOnDate: any;
  isOpeningBalance: boolean;
  isTrialBalance: boolean;
  isOpeningBalanceExplain: boolean;
  isOpeningBalanceAdvance: boolean;
}

@State<OpeningBalanceStateInfo>({
  name: 'openingBalance',
  defaults: {
    openingBalanceData: [],
    openingBalanceBasedOnDate: [],
    isOpeningBalance: false,
    isTrialBalance: false,
    isOpeningBalanceExplain: false,
    isOpeningBalanceAdvance: false,
  },
})
@Injectable()
export class OpeningBalanceState {
  constructor(private openingBalanceService: OpeningBalanceService) {}

  @Selector()
  static getJournalAccounts(state: OpeningBalanceStateInfo) {
    return state.openingBalanceData;
  }

  @Action(GetOpeningBalanceList)
  getOpeningBalanceList(
    { patchState }: StateContext<OpeningBalanceStateInfo>,
    action: GetOpeningBalanceList
  ) {
    return this.openingBalanceService.getOpeningBalanceList().pipe(
      tap((res) => {
        patchState({
          openingBalanceData: res,
        });
      })
    );
  }

  @Action(GetOpeningBalanceListBasedOnBranchId)
  getOpeningBalanceListBasedOnBranchId(
    { patchState }: StateContext<OpeningBalanceStateInfo>,
    action: GetOpeningBalanceListBasedOnBranchId
  ) {
    return this.openingBalanceService
      .getOpeningBalanceListBasedOnBranchId(action.branchId)
      .pipe(
        tap((res) => {
          patchState({
            openingBalanceData: res,
          });
        })
      );
  }

  @Action(SaveOpeningBalance)
  saveOpeningBalance(
    { patchState }: StateContext<OpeningBalanceStateInfo>,
    action: SaveOpeningBalance
  ) {
    return this.openingBalanceService
      .saveOpeningBalance(action.openingBalance)
      .pipe(
        tap((res) => {
          patchState({
            isOpeningBalance: res,
          });
        })
      );
  }

  @Action(SaveOpeningBalanceExplain)
  saveOpeningBalanceExplain(
    { patchState }: StateContext<OpeningBalanceStateInfo>,
    action: SaveOpeningBalanceExplain
  ) {
    return this.openingBalanceService
      .saveOpeningBalanceExplain(
        action.openingBalanceExplain,
        action.accountTypeId
      )
      .pipe(
        tap((res) => {
          patchState({
            isOpeningBalanceExplain: res,
          });
        })
      );
  }

  @Action(SaveOpeningBalanceAdvance)
  saveOpeningBalanceAdvance(
    { patchState }: StateContext<OpeningBalanceStateInfo>,
    action: SaveOpeningBalanceAdvance
  ) {
    return this.openingBalanceService
      .saveOpeningBalanceAdvance(
        action.openingBalanceExplain,
        action.accountTypeId
      )
      .pipe(
        tap((res) => {
          patchState({
            isOpeningBalanceExplain: res,
          });
        })
      );
  }
  @Action(GetOpeningBalanceBasedOnAPId)
  getOpeningBalanceBasedOnAPId(
    { getState, patchState }: StateContext<OpeningBalanceStateInfo>,
    action: GetOpeningBalanceBasedOnAPId
  ) {
    return this.openingBalanceService
      .getOpeningBalanceBasedOnAPId(action.accoutingPeriodId)
      .pipe(
        tap((res) => {
          patchState({
            openingBalanceData: res,
          });
        })
      );
  }

  @Action(SaveTrialBalance)
  saveTrialBalance(
    { patchState }: StateContext<OpeningBalanceStateInfo>,
    action: SaveTrialBalance
  ) {
    return this.openingBalanceService
      .saveTrialBalance(action.trialBalance)
      .pipe(
        tap((res) => {
          patchState({
            isTrialBalance: res,
          });
        })
      );
  }
}
