import { Guid } from 'guid-typescript';

export class PaymentsModel {
  id: Guid;
  receiptNo: string;
  receiptDate: string;
  accountId: string;
  amount: string;
}

export class VatRateScheme {
  id: Guid;
  name: string;
  abbreviation?: string;
  rate: number;
  isStandard: boolean;
  codeType: number;
}

export class VatReturnResponseModel {
  public boxName: string;
  public description: string;
  public amount: number;
}
