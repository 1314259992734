import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationTextMessage, RoutingPath } from '@app/core/Enum';
import { DefaultSettings } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { GetDefaultSettingsData, SaveDefaultSettings } from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-default-settings',
  templateUrl: './default-settings.component.html',
  styleUrls: ['./default-settings.component.scss'],
})
export class DefaultSettingsComponent implements OnInit {
  defaultRows = 15;
  defaultTab = 0;
  defaultMonth = 0;
  isAutoRecall = false;
  defaultSettingsData: DefaultSettings;
  constructor(
    private spinner: NgxSpinnerService,
    private store: Store,
    public commonService: CommonService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getDefaultSettingsData();
  }
  getDefaultSettingsData(): void {
    this.store
      .dispatch(new GetDefaultSettingsData())
      .pipe(
        tap((res) => {
          this.defaultSettingsData = res.settings.defaultSettingsData;
          if (this.defaultSettingsData != null) {
            this.defaultRows = this.defaultSettingsData.defaultRows;
            this.defaultTab = this.defaultSettingsData.defaultTab;
            this.defaultMonth = this.defaultSettingsData.defaultMonth;
            this.isAutoRecall = this.defaultSettingsData.isAutoRecall;
          }
        })
      )
      .subscribe();
  }
  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.defaultSettingsData = {
        id:
          this.defaultSettingsData !== null
            ? this.defaultSettingsData.id
            : (Guid.EMPTY as unknown as Guid),
        userId:
          this.defaultSettingsData !== null
            ? this.defaultSettingsData.userId
            : (Guid.EMPTY as unknown as Guid),
        defaultRows: this.defaultRows,
        defaultTab: this.defaultTab,
        defaultMonth: this.defaultMonth,
        isAutoRecall: this.isAutoRecall,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.dataSubmit()) {
      this.store
        .dispatch(new SaveDefaultSettings(this.defaultSettingsData))
        .subscribe(
          (res) => {
            if (res !== undefined) {
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
              if (isExit) {
                this.router.navigate([RoutingPath.Dashboard]);
              }
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    }
  }
  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
