import { Guid } from 'guid-typescript';

export class JournalsModel {
  id?: Guid;
  entryNumber: string;
  entryDate?: string;
  note: string;
  includeInVatReturn: boolean;
  attachment: Array<string>;
  journalItems: Array<JournalItemModel>;
  transactionLogId?: Guid;
  branchId?: Guid | null;
  departmentId?: Guid | null;
  reverseEntryDate?: string | null;
  currencyId: number;
  currencyRate: number;
  isBadDebtsOff: boolean;
  invoiceId?: Guid | null;
}

export class JournalItemModel {
  id?: Guid;
  accountId: Guid;
  customerSupplierId?: Guid;
  receiptId?: Guid;
  vatRateId?: string;
  description: string;
  vatAmount: number;
  totalAmount: number;
  isCredit: boolean;
}

export class QuickJournalModel {
  entryDate?: string;
  fromAccountId: Guid;
  toAccountId: Guid;
  currencyId: number;
  vatRateId?: Guid | null;
  vatAmount: number;
  totalAmount: number;
  note: string;
  includeInVatReturn: boolean;
  attachment: Array<string>;
  isCredit: boolean;
  branchId?: Guid | null;
  departmentId?: Guid | null;
}

export interface MatchJournalModel {
  id: Guid;
  date: string;
  amount: number;
  isManualBank: boolean;
  isCredit: boolean;
  items: MatchJournalItemModel[];
}

export interface MatchJournalItemModel {
  journalId: Guid;
  entryNumber: string;
  entryDate: string;
  totalAmount: number;
  transactionId: Guid;
}

export interface LinkJournalModel {
  id: Guid;
  transactionId: Guid;
  isManualBank: boolean;
}
