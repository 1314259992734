import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ControlType,
  DetailListModules,
  HeaderFilter,
  Modules,
  MTD,
  NotificationHeader,
  NotificationTextMessage,
  ReportEnum,
} from '@app/core/Enum';
import {
  ExportType,
  GlobalComponent,
  HeaderModel,
  MainListParameters,
} from '@app/core/Models';
import {
  BankDasboardService,
  CommonService,
  NotificationService,
} from '@app/core/Services';
import {
  CommonState,
  GetDetailHeaderList,
  GetHeaderList,
  MenuState,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.scss'],
})
export class DetailHeaderComponent implements OnInit {
  defaultValue: any[] = [
    { id: -1 },
    { id: -1 },
    { id: -1 },
    { id: -1 },
    { id: -1 },
  ];

  @Output()
  readonly triggerDataFromHeader = new EventEmitter<any>();

  @Input() listParameters: any;
  @Input() detailListModulesId: number;
  @Input() showAccountDetails: boolean;

  @Select(MenuState.hasAddPermission)
  hasAddPermission$: Observable<boolean>;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(MenuState.menu)
  menu$: Observable<any>;

  @Select(MenuState.moduleName)
  moduleName$: Observable<string>;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  controlType = ControlType;
  periodicDateList: any;

  @Select(CommonState.headerList)
  headerList$: Observable<Array<HeaderModel>>;

  customDate = false;

  HeaderFilters = HeaderFilter;
  moduleEnum = Modules;
  @Input() moduleId: number;
  headerData: MainListParameters = new MainListParameters();
  exportType = ExportType;
  modules = Modules;
  detailListModules = DetailListModules;
  periodicId = '-1';
  moduleName = '';
  searchText = '';

  reportlist: any[] = [];
  detailListId: number;
  mtd = MTD;
  private _moduleIdSubscription;
  triggerResetDetailList: Subject<any> = new Subject<any>();
  commonService: CommonService;
  store: Store;

  constructor(
    private bankDasboardService: BankDasboardService,
    public dialog: MatDialog,
    private globalComponent: GlobalComponent,
    private cookieService: CookieService,
    private datepipe: DatePipe,
    public notify: NotificationService,
    private injector: Injector
  ) {
    this.commonService = injector.get<CommonService>(CommonService);
    this.store = injector.get<Store>(Store);
  }

  ngOnInit(): void {
    for (let key in ReportEnum) {
      this.reportlist.push({ number: key, word: ReportEnum[key] });
    }

    this.periodicDateList = this.globalComponent.getFinancialPeriod();
    this.detailListId = +this.cookieService.get('detailListId');

    if (
      this.moduleId !== Modules.DisposeTangibleFixedAssets &&
      this.moduleId !== Modules.DisposeLeasedFixedAssets &&
      this.moduleId !== Modules.DisposeIntangibleFixedAssets
    ) {
      this.store.dispatch(
        new GetDetailHeaderList(this.detailListId, this.detailListModulesId)
      );
    } else {
      this.store.dispatch(new GetHeaderList(this.moduleId));
    }
    this.headerList$.subscribe((data) => {
      data.forEach((item) => {
        if (item.value.length > 0) {
          this.commonService.defaultGuidValue = item.value[0].id;
          this.onDropdownChange(item.name, item.value[0].id);
        }
      });
    });

    this.customDate = false;
    this.moduleName$.subscribe((x) => {
      this.moduleName =
        x[x.length - 1] === 's' ? x.substring(0, x.length - 1) : x;
    });
  }

  onAddClick(): void {
    this._moduleIdSubscription = this.moduleId$.subscribe((data) => {
      if (data !== Modules.BankDashboard) {
        this.commonService.onAddRouting(data);
      }
    });
    this._moduleIdSubscription.unsubscribe();
  }

  dataChangeFromHeader(): void {
    const params = {
      startDate: this.headerData.startDate,
      endDate: this.headerData.endDate,
      search: this.headerData.search,
      moduleId: this.headerData.moduleId,
      subModuleId: this.headerData.subModuleId,
      format: this.headerData.format,
      isPrint: this.headerData.isPrint,
      filter: this.headerData.filter,
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      isCustomDate: this.headerData.isCustomDate,
    };

    this.triggerDataFromHeader.emit(params);
  }

  onSearch(): void {
    this.headerData.search = this.searchText;
    this.dataChangeFromHeader();
  }

  onSearchAll(): void {
    if (this.searchText === '') {
      this.onSearch();
    }
  }

  triggerDateChange(data: any): void {
    this.headerData.startDate = data.startDate;
    this.headerData.endDate = data.endDate;
    this.headerData.isCustomDate = data.isCustomDate;

    if (this.headerData.isCustomDate) {
      const endDate: any = this.datepipe
        .transform(data.endDate, 'yyyy-MM-dd')
        ?.toString();

      const startDate: any = this.datepipe
        .transform(data.startDate, 'yyyy-MM-dd')
        ?.toString();

      let dateValue: any = new Date(startDate);

      dateValue.setFullYear(dateValue.getFullYear() + 1);
      dateValue = this.datepipe.transform(dateValue, 'yyyy-MM-dd')?.toString();

      let date1Updated = new Date(dateValue.replace(/-/g, '/'));
      let date2Updated = new Date(endDate.replace(/-/g, '/'));

      if (date1Updated < date2Updated) {
        this.commonService.onFailure(NotificationTextMessage.exceedDateMessage);
        return;
      }
    }

    this.dataChangeFromHeader();
  }

  export(format: number, isPrint: boolean): void {
    let totalRecordOfRecords = 0;

    this.totalRecord$.subscribe((x) => {
      totalRecordOfRecords = x;
    });
    if (totalRecordOfRecords > 0) {
      const params = {
        startDate: this.headerData.startDate,
        endDate: this.headerData.endDate,
        filter: this.listParameters.filter,
        search: this.headerData.search,
        moduleId: this.headerData.moduleId,
        subModuleId: this.headerData.subModuleId,
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        format: format,
        isPrint: isPrint,
        isCustomDate: this.headerData.isCustomDate,
      };

      this.triggerDataFromHeader.emit(params);
    } else {
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noDataToExport
      );
    }
  }

  onDropdownChange(headerName: any, value: any): void {
    if (headerName === HeaderFilter.Filter) {
      this.headerData.filter = value;
    }
    if (headerName === HeaderFilter.Module) {
      this.headerData.moduleId = value;
    }
    if (headerName === HeaderFilter.SubModule) {
      this.headerData.subModuleId = value;
    }

    this.listParameters.pageNumber = 1;
    this.dataChangeFromHeader();
  }

  selectCustomDate(): void {
    this.triggerResetDetailList.next(true);
  }
}
