import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Injector, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { ModuleName, Modules, NotificationTextMessage } from '@app/core/Enum';
import { MenuModel } from '@app/core/Models';
import {
  BankDasboardService,
  CommonEmitterService,
  CommonService,
  ModulePermission,
} from '@app/core/Services';
import {
  CheckVatNumber,
  CreateCustomAccount,
  CreateUser,
  MenuState,
  SetDefaultVatId,
} from '@app/core/Store';
import { BankLinkComponent } from '@app/modules/bank';
import { AddClosePopupComponent, QuickAddComponent } from '@app/modules/common';
import { PermissionComponent } from '@app/modules/manage';
import { Select, Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss'],
})
export class MenuItemComponent {
  treeControl = new NestedTreeControl<MenuModel>((node) => node.subMenu);
  dataSource = new MatTreeNestedDataSource<MenuModel>();
  modules = Modules;
  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;

  @Select(MenuState.treeNode)
  treeNode$: Observable<any>;

  @Select(MenuState.moduleName)
  moduleName$: Observable<string>;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  activeNode;

  @Input() activeMenuLink = '';

  @ViewChild(PermissionComponent, { static: true })
  permission;

  private destroy$ = new Subject<void>();

  parentName: string;
  subparentName: string;
  store: Store;
  commonService: CommonService;
  tempMenuList: any = [];
  constructor(
    private router: Router,
    private modulePermission: ModulePermission,
    public cookieService: CookieService,
    public dialog: MatDialog,
    public commonEmitterService: CommonEmitterService,
    public bankDasboardService: BankDasboardService,
    private injector: Injector
  ) {
    this.menuList$.subscribe((menuList) => {
      localStorage.setItem('userPermission', JSON.stringify(menuList));
      let currentUrl = this.router.url;

      if (!currentUrl.startsWith('/general-manage')) {
        console.log('I am inside 1');
        this.tempMenuList = menuList.filter(
          (x) => x.id !== Modules.GeneralManage
        );

        this.tempMenuList.forEach((element) => {
          this.removeUsersMenu(element);
        });
      } else if (currentUrl.startsWith('/general-manage')) {
        console.log('Hi I am inside 2');
        const generalManageMenu = menuList.find(
          (x) => x.id === Modules.GeneralManage
        );
        if (generalManageMenu) {
          this.tempMenuList = generalManageMenu.subMenu;
        } else {
          this.tempMenuList = [];
        }
      }

      this.modulePermission.permissionData.subscribe((value) => {
        this.activeNode = value.data;
        this.activeMenuLink = value.data.url;
        this.toExpandMenu(this.tempMenuList, value.data.name);
      });
    });
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  removeUsersMenu(menuItem: any) {
    menuItem.subMenu = menuItem.subMenu.filter(
      (subItem) => subItem.id !== Modules.Permission
    );
  }

  toExpandMenu(data: MenuModel[], name: string): any {
    if (data !== undefined) {
      data.forEach((node) => {
        if (
          node.subMenu &&
          node.subMenu.find(
            (c) => c.name === name || c.subMenu.find((x) => x.name)
          )
        ) {
          this.onLeafNodeClick(name);
          this.toExpandMenu(this.treeControl.dataNodes, node.name);
        }
      });
    }
  }

  onAddClick(node: any): void {
    if (this.commonService.isInitialValueChange) {
      this.dialog
        .open(AddClosePopupComponent, {
          data: {
            isWarning: true,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.addRedirect(node);
          }
        });
    } else {
      this.addRedirect(node);
    }
  }

  addRedirect(node: any): void {
    let addURL;
    addURL = node.id === Modules.CompanyProfile ? node.url : node.addUrl;
    this.commonService.isInitialValueChange = false;
    if (node.id === Modules.SubmitVat || node.id === Modules.BridgingVAT) {
      this.checkVatNumber(addURL);
      this.store.dispatch(new SetDefaultVatId());
    } else if (node.id === Modules.Permission) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.Permission,
            headerText: `Add ${ModuleName.Permission}`,
            saveActionName: CreateUser,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.moduleId$.subscribe((moduleId) => {
              if (moduleId === Modules.Permission) {
                this.commonEmitterService.setUserList(true);
              }
            });
          }
        });
    } else if (node.id === Modules.BankDashboard) {
      this.gotoAddCustomAccount();
    } else if (node.id === Modules.BankFeed) {
      this.dialog
        .open(BankLinkComponent)
        .afterClosed()
        .subscribe(() => {});
    } else {
      addURL === this.router.url.slice(1)
        ? location.reload()
        : this.redirectTo(addURL);
    }
  }

  redirectTo(addURL: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([addURL]));
  }

  checkVatNumber(addURL: any): void {
    this.store.dispatch(new CheckVatNumber()).subscribe((res) => {
      if (res.submitVat.checkVatNumber) {
        addURL === this.router.url.slice(1)
          ? location.reload()
          : this.router.navigate([addURL]);
      } else {
        this.commonService.onFailure(
          NotificationTextMessage.vatNumberErrorMessage
        );
      }
    });
  }

  gotoAddCustomAccount(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.AddCustomAccounts,
          headerText: `Add ${ModuleName.AddCustomAccounts}`,
          saveActionName: CreateCustomAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
        }
      });
  }

  hasChild = (_: number, node: MenuModel) =>
    !!node.subMenu && node.subMenu.length > 0;

  getAncestors(array, name): any {
    if (typeof array !== 'undefined') {
      for (const element of array) {
        if (element.name === name && element.url === this.activeMenuLink) {
          return [element];
        }
        const a = this.getAncestors(element.subMenu, name);
        if (a !== null) {
          a.unshift(element);
          return a;
        }
      }
    }
    return null;
  }

  childClick(node: any): void {
    this.commonService.isInitialValueChange = false;
    if (node.id === Modules.CompanyProfile) {
      const companyId = this.cookieService.get('companyid');
      this.commonService.onEditRouting(true, Modules.Clients, companyId);
    } else {
      this.router.navigate([node.url]);
    }

    if (node.id === Modules.VatSettings) {
      this.cookieService.delete('selectedTab');
    } else if (node.id === Modules.SubmitVat) {
      this.store.dispatch(new SetDefaultVatId());
    }
  }

  onChildClick(node): void {
    if (this.commonService.isInitialValueChange) {
      this.dialog
        .open(AddClosePopupComponent, {
          data: {
            isWarning: true,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.childClick(node);
          }
        });
    } else {
      this.childClick(node);
    }
  }

  onLeafNodeClick(name): void {
    let array;
    this.menuList$.subscribe((x) => {
      array = x;
    });
    let currentUrl = this.router.url;
    if (!currentUrl.includes('/general-manage')) {
      array = array.filter((x) => x.id !== Modules.GeneralManage);
    } else if (currentUrl.includes('/general-manage')) {
      array = array.filter((x) => x.id === Modules.GeneralManage);
    }
    const ancestors = this.getAncestors(array, name);
    if (ancestors !== null && ancestors !== undefined) {
      ancestors.forEach((element) => {
        this.treeControl.expand(element);
      });
      this.parentName = ancestors[0].name;
      this.subparentName = ancestors[1] !== undefined ? ancestors[1].name : '';
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
