<div class="content-body product-detail-wrap">
   
    <div *ngIf="vatReturnData || editListData.length" class="sidenav-table sidenav-table-input wp-100">
      <div class="mat-table-wrapper product-detail-table">
        <form [formGroup]="formProductDetail">
          <ng-container formArrayName="productDetailArray">
            <table
              aria-describedby="product-list"
              mat-table
              [dataSource]="tableDataSource"
            >
            
           
              <ng-container matColumnDef="from">
                <th mat-header-cell *matHeaderCellDef>From</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                  class="widthemail"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      matInput
                      [value]="element.description"
                      autocomplete="off"
                      formControlName="from"
                     
                    />
                  </mat-form-field>
               
                </td>
              </ng-container>
            

              <ng-container matColumnDef="to">
                <th mat-header-cell *matHeaderCellDef>To</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                  class="widthemail"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      matInput
                      [value]="element.to"
                      autocomplete="off"
                      formControlName="to"
                     
                    />
                  </mat-form-field>
               
                </td>
              </ng-container>

              <ng-container matColumnDef="rate">
                <th mat-header-cell *matHeaderCellDef>Rate</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                  class="widthemail"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      matInput
                      [value]="element.rate"
                      autocomplete="off"
                      formControlName="rate"
                     
                    />
                  </mat-form-field>
               
                </td>
              </ng-container>
              
              <tr
                mat-header-row
                *matHeaderRowDef="displayProductDetailsColumns; sticky: true"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayProductDetailsColumns"
              ></tr>
            </table>
          </ng-container>
        </form>
      </div>
  
    </div>
  </div>
  