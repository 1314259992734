<div class="mat-dialog-wrapper preview-details">
  <mat-dialog-content>
    <form>
      <div class="main-contain">
        <div class="sidenav-header mb-10">
          <div
            class="d-flex align-items-center justify-space-between hr-bottom pb-10"
          >
            <h2 class="m-0">
              <span>CapiumPay</span>
            </h2>
            <div mat-dialog-close>
              <mat-icon class="close cursor-pointer">close</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="pay-details w-636">
        <h2 class="text-gray">Register online payments</h2>
        <p>
          If you want to accept online payments in
          <span class="fw-bold">Bookkeeping</span>, you’ll need to register your
          bank account with CapiumPay.
        </p>
        <p>
          It only takes a few minutes to complete the form on the CapiumPay.
          Once registered you’ll be asked to connect your account to Bookkeeping
          and as soon as you’ve done this we’ll be able to help you to
          automatically keep track of payments made via CapiumPay.
        </p>
      </div>
      <div
        class="contact-button d-flex justify-content-end align-items-center mt-2"
      >
        <button
          class="action-button primary-button"
          mat-button
          disableRipple
          (click)="onRegisterClick()"
        >
          <span>Register with CapiumPay</span>
        </button>
      </div>
    </form>
  </mat-dialog-content>
</div>

<app-custom-notifications></app-custom-notifications>
