import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MaxLength, Modules } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { GetDataByNoteId } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-schedules-notes-view',
  templateUrl: './schedules-notes-view.component.html',
  styleUrls: ['./schedules-notes-view.component.scss'],
})
export class SchedulesNotesViewComponent implements OnInit {
  isJournalChangePermission: boolean = true;
  isJournalChangePermissionEdit: boolean = true;
  scheduleNote: any;
  maxLength = MaxLength;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SchedulesNotesViewComponent>,
    public spinner: NgxSpinnerService,
    public commonService: CommonService,
    public store: Store
  ) {}

  ngOnInit(): void {
    this.getScheduleNote();
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  getScheduleNote(): void {
    this.spinner.show();
    this.store.dispatch(new GetDataByNoteId(this.data.id)).subscribe((res) => {
      this.spinner.hide();
      this.scheduleNote = res.scheduleNote.noteData;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isJournalChangePermission = this.commonService.checkPermission(
        Modules.Schedules,
        Modules.Notes
      );

      this.isJournalChangePermissionEdit = this.commonService.checkPermission(
        Modules.Schedules,
        Modules.Notes,
        true
      );
    }, 500);
  }

  onEditClick(): void {
    this.dialogRef.close(false);
    this.commonService.onEditRouting(true, Modules.Notes, this.data.id);
  }
}
