import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryParams, SideListModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/common.service';

@Injectable()
export class UserService {
  public userBasicInfoData: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  data: Observable<any> = this.userBasicInfoData.asObservable();

  constructor(
    private http: HttpClient,
    public datepipe: DatePipe,
    private commonService: CommonService
  ) {}

  getUserDetailList(queryParams: QueryParams): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}User/list`,
      queryParams,
      httpOptions
    );
  }

  exportUser(exportParams: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}exportUsers`,
        JSON.stringify(exportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  deleteUsers(userIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(userIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}User/deleteUsersByUnivesalId`,
      options
    );
  }

  archiveAndRestoreUser(
    userIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}User/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(userIds)),
      options
    );
  }

  getUserDataByUserId(userId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}User/getUserDetail/${userId}`
    );
  }

  getAllUsers(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.apiVersionUrl}getAllUsers`);
  }

  updateUserBasicInfo(userData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}updateUserBasicInfo`,
      JSON.stringify(userData),
      headers
    );
  }

  getUserPermissions(): Observable<Array<any>> {
    return this.http.get<Array<any>>(`${environment.apiVersionUrl}Role/all`);
  }

  inviteUser(id: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}User/inviteUser/${id}`,
      headers
    );
  }

  saveUser(user: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}User/saveUser`,
      JSON.stringify(user),
      headers
    );
  }

  getUsersByPermission(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}getUsersByPermission`
    );
  }

  getUserCompanyDetails(queryParams): Observable<HttpResponse<any>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}User/getUserClients`,
      queryParams,
      httpOptions
    );
  }

  SaveUserCompanies(userId: Guid, userClients: Array<any>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<boolean>(
      `${environment.apiVersionUrl}User/SaveUserClients/${userId}`,
      JSON.stringify(userClients),
      headers
    );
  }

  DeleteUserCompanyByUserId(userId: Guid): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(userId),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}company/deleteUserCompanyById`,
      options
    );
  }
}
