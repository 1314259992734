<div class="action-wrapper button-action-wrapper" *ngIf="!isSend">
  <div class="content-body">
    <button
      *ngIf="
        getModuleId !== modules.Import &&
        getModuleId !== modules.BulkEdit &&
        getModuleId !== modules.BankFeed &&
        getModuleId !== modules.Integration &&
        getModuleId !== modules.AccountDetails &&
        getModuleId !== modules.OpeningBalance &&
        getModuleId !== modules.EditTrialBalance &&
        getModuleId !== modules.BankImportHistory &&
        getModuleId !== modules.Permission &&
        getModuleId !== modules.Users &&
        getModuleId !== modules.ActiveTangibleFixedAssets &&
        getModuleId !== modules.ActiveLeasedFixedAssets &&
        getModuleId !== modules.ActiveIntangibleFixedAssets &&
        getModuleId !== modules.Clients &&
        getModuleId !== modules.FixedAssetsRegister &&
        getModuleId !== modules.AutoBankReconcilation &&
        !saveButtonShow
      "
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave(false)"
    >
      Save and New
    </button>
    <button
      *ngIf="
        getModuleId === modules.Import ||
        getModuleId === modules.BulkEdit ||
        getModuleId === modules.FixedAssetsRegister ||
        getModuleId === modules.BankFeed ||
        getModuleId === modules.Integration ||
        getModuleId === modules.AccountDetails ||
        getModuleId === modules.OpeningBalance ||
        getModuleId === modules.EditTrialBalance ||
        getModuleId === modules.BankImportHistory ||
        getModuleId === modules.Permission ||
        getModuleId === modules.Users ||
        getModuleId === modules.Clients ||
        getModuleId === modules.AutoBankReconcilation ||
        saveButtonShow
      "
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave(false)"
    >
      Save
    </button>
    <button
      *ngIf="
        getModuleId !== modules.Import &&
        getModuleId !== modules.BulkEdit &&
        getModuleId !== modules.FixedAssetsRegister &&
        getModuleId !== modules.BankFeed &&
        getModuleId !== modules.BankImportHistory &&
        getModuleId !== modules.Permission &&
        getModuleId !== modules.AutoBankReconcilation &&
        !saveButtonShow
      "
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave(true)"
    >
      Save and Exit
    </button>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      [ngShortcut]="'@saveAndInvite'"
      (click)="onSaveInvite(true)"
      *ngIf="getModuleId === modules.Users"
    >
      Save and Invite
    </button>
    <button
      *ngIf="
        getModuleId !== modules.BulkEdit &&
        getModuleId !== modules.BankImportHistory &&
        getModuleId !== modules.BankDashboard &&
        getModuleId !== modules.AutoBankReconcilation
      "
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onCancel()"
    >
      Clear Form
    </button>
    <button
      *ngIf="
        getModuleId === modules.BankImportHistory ||
        getModuleId === modules.BankDashboard ||
        getModuleId === modules.AutoBankReconcilation
      "
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>
</div>
<div class="action-wrapper button-action-wrapper" *ngIf="isSend">
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSend(false)"
    >
      Send
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>
</div>
