import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Modules } from '@app/core/Enum';
import { OverviewFilterModel } from '@app/core/Models';
import { OverviewModel } from '@app/core/Models/overview/overview-model';
import { MenuState } from '@app/core/Store';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OverviewService {
  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  constructor(private http: HttpClient, public datepipe: DatePipe) {}

  getOverviewData(
    body: OverviewFilterModel,
    moduleId: Modules
  ): Observable<OverviewModel> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<OverviewModel>(
      `${environment.apiVersionUrl}Dashboard/overview/module/${moduleId}`,
      JSON.stringify(body),
      headers
    );
  }
}
