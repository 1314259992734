<div class="d-flex h-100">
  <app-side-list
    [getModuleId]="moduleId"
    [getModuleName]="moduleName"
    (showSideListAction)="showSideListAction($event)"
    (toggleSideList)="toggleSideList($event)"
    [reloadSideList]="reloadSideList"
  ></app-side-list>

  <div
    class="form mb-4"
    [ngClass]="istoggleSideList ? 'hide-sidelist' : ''"
    [hidden]="isShowSideListAction"
  >
    <div class="content-body-wrapper">
      <div class="content-body">
        <p class="header-text d-flex justify-space-between align-items-center">
          <span>
            {{
              journalsId === commonService.defaultGuidValue
                ? "Add New Journal"
                : "Edit Journal"
            }}
          </span>

          <button
            mat-button
            class="header-text-close d-flex align-items-center"
            disableRipple
            (click)="onCloseClick()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </p>
        <div class="alert-message" *ngIf="isViewPermission$ | async">
          <mat-icon class="material-icons-outlined">error_outline</mat-icon>
          {{ commonNotificationText.ViewOnlyAccessMessage }}
        </div>
        <div>
          <div class="accordion-toggle">
            <mat-icon
              (click)="toggleAccordion(); accordion.openAll()"
              *ngIf="isExpandAll"
              >keyboard_double_arrow_down
            </mat-icon>
            <mat-icon
              (click)="toggleAccordion(); accordion.closeAll()"
              *ngIf="!isExpandAll"
              >keyboard_double_arrow_up
            </mat-icon>
          </div>
          <mat-accordion [multi]="true">
            <mat-expansion-panel
              [expanded]="true"
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >description</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Journal Basic Info.</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-add-journals
                [triggerEditData]="triggerEditData"
                [triggerTransactionLogData]="triggerTransactionLogData"
                (triggerOnCurrencyRateUpdate)="onCurrencyRateUpdate($event)"
              ></app-add-journals>
            </mat-expansion-panel>
            <mat-expansion-panel
              [expanded]="true"
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >description</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Journal Details</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-add-bulk-journal
                [triggerEditData]="triggerEditData"
                [triggerTransactionLogData]="triggerTransactionLogData"
                [triggerOnCurrencyRateUpdate]="triggerOnCurrencyRateUpdate"
                [triggerBadDebtsDataList]="triggerBadDebtsDataList"
              ></app-add-bulk-journal>
            </mat-expansion-panel>
            <mat-expansion-panel
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >attachment</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Attachment</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-transactions-attachment
                [triggerEditData]="triggerEditData"
              ></app-transactions-attachment>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="mtd-list mt-1">
          <div class="form-field mb-0">
            <p class="fw-bold"># Key Notes:</p>
          </div>
          <ul>
            <li>
              <p>Select customer for accounting codes (8016,8110) to give appropriate transaction effect.</p>
            </li>
            <li>
              <p>Select supplier for accounting codes (8210,8310) to give appropriate transaction effect.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <app-buttons
      *ngIf="!(isViewPermission$ | async)"
      [getModuleId]="moduleId"
      (triggerOnSaveClick)="onSave($event)"
      (triggerOnCancelClick)="onCancel(true)"
    ></app-buttons>
  </div>
</div>
