export enum Title {
  Mr = 1,
  Mrs = 2,
  Miss = 3,
  Ms = 4,
  Dr = 5,
  Sir = 6,
  Lord = 7,
  Lady = 8,
  Dame = 9,
}
