import { Injectable } from '@angular/core';
import { DashboardModel } from '@app/core/Models/overview/overview-model';
import { DashboardService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  GetDashboardAgeingSummaryOverview,
  GetDashboardCard,
  GetDashboardCashFlowOverview,
  GetDashboardData,
  GetDashboardExpenseByCategoriesOverview,
  GetDashboardExpenseComparision,
  GetDashboardIncomeByCategoriesOverview,
  GetDashboardIncomeComparision,
  GetDashboardIncomeVsExpensesOverview,
  GetDashboardVATSummary,
} from './dashboard.action';

export class DashboardStateInfo {
  dashboradData: DashboardModel;
  dashboardIncomeByCategoriesOverview?: any;
  dashboardExpenseByCategoriesOverview?: any;
  dashboardIncomeVsExpensesOverview?: any;
  dashboardAgeingSummaryOverview?: any;
  dashboardCashFlowOverview?: any;
  dashboardCard?: any;
  dashboardVATSummaryData?: any;
}

@State<DashboardStateInfo>({
  name: 'dashboard',
  defaults: {
    dashboradData: new DashboardModel(),
  },
})
@Injectable()
export class DashboardState {
  constructor(private dashboardService: DashboardService) {}

  @Selector()
  static getDashboardData(state: DashboardStateInfo) {
    return state.dashboradData;
  }

  @Action(GetDashboardData)
  getDashboardData({ patchState }: StateContext<DashboardStateInfo>) {
    return this.dashboardService.getDashboardData().pipe(
      tap((res) => {
        patchState({
          dashboradData: res,
        });
      })
    );
  }

  @Action(GetDashboardCard)
  getDashboardCard(
    { patchState }: StateContext<DashboardStateInfo>,
    action: GetDashboardCard
  ) {
    return this.dashboardService.getDashboardCard(action.filterData).pipe(
      tap((res) => {
        patchState({
          dashboardCard: res,
        });
      })
    );
  }

  @Action(GetDashboardIncomeByCategoriesOverview)
  getDashboardIncomeByCategoriesOverview(
    { patchState }: StateContext<DashboardStateInfo>,
    action: GetDashboardIncomeByCategoriesOverview
  ) {
    return this.dashboardService
      .getDashboardIncomeByCategoriesOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            dashboardIncomeByCategoriesOverview: res,
          });
        })
      );
  }

  @Action(GetDashboardExpenseByCategoriesOverview)
  getDashboardExpenseByCategoriesOverview(
    { patchState }: StateContext<DashboardStateInfo>,
    action: GetDashboardExpenseByCategoriesOverview
  ) {
    return this.dashboardService
      .getDashboardExpenseByCategoriesOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            dashboardExpenseByCategoriesOverview: res,
          });
        })
      );
  }

  @Action(GetDashboardIncomeVsExpensesOverview)
  getDashboardIncomeVsExpensesOverview(
    { patchState }: StateContext<DashboardStateInfo>,
    action: GetDashboardIncomeVsExpensesOverview
  ) {
    return this.dashboardService
      .getDashboardIncomeVsExpensesOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            dashboardIncomeVsExpensesOverview: res,
          });
        })
      );
  }

  @Action(GetDashboardAgeingSummaryOverview)
  getDashboardAgeingSummaryOverview(
    { patchState }: StateContext<DashboardStateInfo>,
    action: GetDashboardAgeingSummaryOverview
  ) {
    return this.dashboardService
      .getDashboardAgeingSummaryOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            dashboardAgeingSummaryOverview: res,
          });
        })
      );
  }

  @Action(GetDashboardCashFlowOverview)
  getDashboardCashFlowOverview(
    { patchState }: StateContext<DashboardStateInfo>,
    action: GetDashboardCashFlowOverview
  ) {
    return this.dashboardService
      .getDashboardCashFlowOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            dashboardCashFlowOverview: res,
          });
        })
      );
  }

  @Action(GetDashboardIncomeComparision)
  getDashboardIncomeComparision(
    { patchState }: StateContext<DashboardStateInfo>,
    action: GetDashboardIncomeComparision
  ) {
    return this.dashboardService
      .getDashboardIncomeComparision(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            dashboardCashFlowOverview: res,
          });
        })
      );
  }

  @Action(GetDashboardExpenseComparision)
  getDashboardExpenseComparision(
    { patchState }: StateContext<DashboardStateInfo>,
    action: GetDashboardExpenseComparision
  ) {
    return this.dashboardService
      .getDashboardExpenseComparision(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            dashboardCashFlowOverview: res,
          });
        })
      );
  }
  @Action(GetDashboardVATSummary)
  getDashboardVATSummary(
    { patchState }: StateContext<DashboardStateInfo>,
    action: GetDashboardVATSummary
  ) {
    return this.dashboardService
      .getDashboardVATSummaryData(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            dashboardVATSummaryData: res,
          });
        })
      );
  }
}
