import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GetDashboardData } from '@app/core/Store';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @Output()
  readonly triggerIncomeData = new EventEmitter<any>();

  @Output()
  readonly triggerExpensesData = new EventEmitter<any>();

  @Output()
  readonly triggerSummaryIncomeData = new EventEmitter<any>();

  @Output()
  readonly triggerSummaryExpensesData = new EventEmitter<any>();

  @Output()
  readonly triggerIncomeVsExpensesData = new EventEmitter<any>();

  @Output()
  readonly triggerCashFlow = new EventEmitter<any>();

  selectedTab = 0;
  dashboardData: any;
  incomeAmount = 0;
  totalIncomeAmount = 0;
  expenseAmount = 0;
  totalExpenseAmount = 0;
  bank = 0;
  cashInHand = 0;

  isloaded = false;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new GetDashboardData()).subscribe((x) => {
      this.isloaded = true;
      setTimeout(() => {
        this.dashboardData = x.dashboard.dashboradData;
        this.incomeAmount = this.dashboardData.dashboard.income.amount;
        this.totalIncomeAmount =
          this.dashboardData.dashboard.income.totalAmount;
        this.expenseAmount = this.dashboardData.dashboard.expense.amount;
        this.totalExpenseAmount =
          this.dashboardData.dashboard.expense.totalAmount;
        this.bank = this.dashboardData.dashboard.bank.totalAmount;
        this.cashInHand = this.dashboardData.dashboard.cashInHand.totalAmount;

        this.triggerIncomeData.emit(x.dashboard.dashboradData.income);
        this.triggerExpensesData.emit(x.dashboard.dashboradData.expense);
        this.triggerSummaryIncomeData.emit(
          x.dashboard.dashboradData.receivable
        );
        this.triggerIncomeVsExpensesData.emit(
          x.dashboard.dashboradData.incomeExpense
        );
        this.triggerCashFlow.emit(x.dashboard.dashboradData.cashFlow);
      }, 100);
    });
  }

  tabClick(event: any): void {
    this.selectedTab = event;
    window.dispatchEvent(new Event('resize'));
    this.selectedTab === 1
      ? this.triggerSummaryExpensesData.emit(this.dashboardData.payable)
      : this.triggerSummaryIncomeData.emit(this.dashboardData.receivable);
  }
}
