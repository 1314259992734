import { Injectable } from '@angular/core';
import { CISInvoiceModel, SideListModel } from '@app/core/Models';
import { CISInvoiceService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreCISInvoice,
  CreateCISInvoice,
  DeleteCISInvoice,
  GetCisInvoiceCurrencyByCisInvoiceId,
  GetDataByCISInvoiceId,
  GetDueAmountByCisInvoiceId,
} from './cis-invoice-details.action';

export class CISInvoiceStateInfo {
  cisInvoice: Array<CISInvoiceModel>;
  cisInvoiceId?: Guid;
  isCISInvoiceAdded?: boolean;
  isLastPage?: boolean;
  cisInvoiceData?: CISInvoiceModel;
  sideListModel: Array<SideListModel>;
  dueAmount: any;
  currencyId: number;
}

@State<CISInvoiceStateInfo>({
  name: 'cisInvoice',
  defaults: {
    cisInvoice: [],
    sideListModel: [],
    dueAmount: 0,
    currencyId: 0,
  },
})
@Injectable()
export class CISInvoiceState {
  constructor(public cisInvoiceService: CISInvoiceService) {}

  @Selector()
  static getCISInvoice(state: CISInvoiceStateInfo) {
    return state.cisInvoice;
  }

  @Selector()
  static isLastPage(state: CISInvoiceStateInfo) {
    return state.isLastPage;
  }

  @Action(CreateCISInvoice)
  createCISInvoice(
    { patchState }: StateContext<CISInvoiceStateInfo>,
    action: CreateCISInvoice
  ) {
    return this.cisInvoiceService.createCISInvoice(action.cisInvoice).pipe(
      tap((res) => {
        patchState({
          cisInvoiceId: res,
          isCISInvoiceAdded: true,
        });
      })
    );
  }

  @Action(GetDataByCISInvoiceId)
  getDataByCISInvoiceId(
    { patchState }: StateContext<CISInvoiceStateInfo>,
    action: GetDataByCISInvoiceId
  ) {
    return this.cisInvoiceService
      .getDataByCISInvoiceId(action.cisInvoiceId)
      .pipe(
        tap((res) => {
          patchState({
            cisInvoiceData: res,
          });
        })
      );
  }

  @Action(DeleteCISInvoice)
  deleteCISInvoice(
    { getState, patchState }: StateContext<CISInvoiceStateInfo>,
    action: DeleteCISInvoice
  ) {
    return this.cisInvoiceService.deleteCISInvoice(action.cisInvoiceIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredCISInvoice = state.cisInvoice.filter(
          (item) =>
            !action.cisInvoiceIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.cisInvoiceIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          cisInvoice: filteredCISInvoice,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreCISInvoice)
  archiveAndRestoreAccount(
    { getState }: StateContext<CISInvoiceStateInfo>,
    action: ArchiveAndRestoreCISInvoice
  ) {
    return this.cisInvoiceService.archiveAndRestoreCISInvoice(
      action.cisInvoiceIds,
      action.isArchive
    );
  }
  @Action(GetDueAmountByCisInvoiceId)
  getDueAmountByInvoiceId(
    { patchState }: StateContext<CISInvoiceStateInfo>,
    action: GetDueAmountByCisInvoiceId
  ) {
    return this.cisInvoiceService
      .getDueAmountByCisInvoiceId(action.cisInvoiceId)
      .pipe(
        tap((res) => {
          patchState({
            dueAmount: res,
          });
        })
      );
  }
  @Action(GetCisInvoiceCurrencyByCisInvoiceId)
  getCisInvoiceCurrencyByCisInvoiceId(
    { patchState }: StateContext<CISInvoiceStateInfo>,
    action: GetCisInvoiceCurrencyByCisInvoiceId
  ) {
    return this.cisInvoiceService
      .getCisInvoiceCurrencyByCisInvoiceId(action.cisInvoiceId)
      .pipe(
        tap((res) => {
          patchState({
            currencyId: res,
          });
        })
      );
  }
  @Action(GetCisInvoiceCurrencyByCisInvoiceId)
  getCisInvoiceLabourAndMaterialAmountByCisInvoiceId(
    { patchState }: StateContext<CISInvoiceStateInfo>,
    action: GetCisInvoiceCurrencyByCisInvoiceId
  ) {
    return this.cisInvoiceService
      .getCisInvoiceLabourAndMaterialAmountByCisInvoiceId(action.cisInvoiceId)
      .pipe(
        tap((res) => {
          patchState({
            cisInvoiceData: res,
          });
        })
      );
  }
}
