<div class="mat-dialog-wrapper" style="width: 600px">
  <div class="d-flex justify-space-between align-items-center header-text">
    <h2 mat-dialog-title class="m-0">Information</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="content-body">
      <p>
        Electronic Subcontractor Verification has been made mandatory from 6th
        April 2017 onward, hence if this Subcontractor has been verified
        manually (through telephone) then we recommend you to get the online
        verification done through Capium itself in order to avoid any error in
        CIS300 submission.
      </p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="m-0 d-flex gap-15 justify-content-end">
    <button
      class="mat-focus-indicator action-button primary-button mat-button mat-button-base"
      (click)="onCloseClick()"
    >
      Ok
    </button>
  </mat-dialog-actions>
</div>
