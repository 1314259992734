export enum ModuleName {
  Dashboard = 'Dashboard',
  ChartOfAccounts = 'Chart Of Accounts',
  Sales = 'Sales',
  Expenditure = 'Purchase',
  Tasks = 'Tasks',
  Contacts = 'Contacts',
  Bank = 'Bank',
  Reports = 'Reports',
  Manage = 'Manage',
  Logs = 'Logs',
  AddStandardAccounts = 'Standard Accounts',
  AddCustomAccounts = 'Custom Accounts',
  Invoices = 'Invoices',
  RecurringInvoice = 'Recurring Invoice',
  Quotation = 'Quotation',
  CreditNote = 'Credit Note',
  Receipt = 'Receipt',
  Purchase = 'Purchase',
  RecurringPurchase = 'Recurring Purchase',
  DebitNote = 'Credit Note',
  Payment = 'Payment',
  Capiscan = 'Capiscan',
  Journals = 'Journals',
  BulkEdit = 'BulkEdit',
  Activities = 'Activities',
  Report = 'Report',
  Customers = 'Customer',
  Suppliers = 'Suppliers',
  Shareholders = 'Shareholders',
  Directors = 'Directors',
  BankDashboard = 'Bank Dashboard',
  BankTransfer = 'Bank Transfer',
  BankFeed = 'Bank Feed',
  CashEntry = 'Cash Entry',
  FixedAssets = 'Fixed Assets',
  EmailLog = 'EmailLog',
  UserLog = 'UserLog',
  Clients = 'Clients',
  BalanceSheet = 'Balance Sheet',
  ManagementNotes = 'ManagementNotes',
  BankEntry = 'Bank Entry',
  Import = 'Import',
  NominalLedger = 'Nominal Ledger',
  Creditors = 'Creditors',
  Debtors = 'Debtors',
  TrialBalance = 'Trial Balance',
  OpeningBalance = 'Opening Balance',
  SubmitVat = 'SubmitVat',
  Inventory = 'Inventory',
  Product = 'Product',
  Notes = 'Notes',
  MinutesOfMeeting = 'Minutes Of Meeting',
  Dividends = 'Dividends',
  Budgeting = 'Budgeting',
  BridgingVAT = 'BridgingVAT',
  Subcontractor = 'Subcontractor',
  CISInvoice = 'CISInvoice',
  CashCoding = 'Cash Coding',
  BankImportTransactionHistory = 'Bank Import Transaction History',
  BankImport = 'Bank Import',
  Permission = 'Permission',
  salesPerformance = 'Sales Performance',
  expensePerformance = 'Expense Performance',
  companyOverview = 'Company Overview',
  MatchJournal = 'Match Journal',
  Currency = 'Currency',
  FixedAssetDetail = 'Fixed Asset Detail',
  CustomizeReport = 'Customize Report',
  ProfitAndLoss = 'Profit & Loss',
  ProfitAndLossComparison = 'Profit & Loss Comparison',
  QuickEntry = 'Quick Entry',
  SubcontractorYTDDetails = 'Sub Contractor YTD Details',
  CIS = 'CIS',
  MatchQuickEntry = 'Match Quick Entry',
  MultipleTransactions = 'Multiple Transactions',
  CapiumPayIntegration = 'capiumPayIntegration',
  GeneralManage = 'general-manage',
  Users = 'Users',
  Refund = 'Refund',
  CashFlow = 'Cash Flow',
}

export enum HeaderTextForSideList {
  Invoice = 'Invoices',
  Tasks = 'TASK LIST',
  BankTransfer = 'Bank Transfer',
  FixedAssets = 'Fixed Assets',
  Suppliers = 'Suppliers',
  Customers = 'Customers',
  Quotation = 'Quotations',
  CreditNote = 'Credit Notes',
  RecurringInvoice = 'Recurring Invoices',
  RecurringPurchase = 'Recurring Purchase',
  Receipt = 'Receipts',
  Purchase = 'Purchase',
  DebitNote = 'Credit Notes',
  Payment = 'Payments',
  Activities = 'Activities',
  Journals = 'Journals',
  CustomAccount = 'Accounts',
  CashEntry = 'Cash Entry',
  BankEntry = 'Bank Entry',
  Products = 'Products',
  Notes = 'Notes',
  MinutesOfMeeting = 'Minutes Of Meeting',
  Shareholders = 'Shareholders',
  Directors = 'Directors',
  Dividends = 'Dividends',
  Budgeting = 'Budgeting',
  Subcontractor = 'Subcontractor',
  CISInvoice = 'CIS Invoice',
  CustomizeReport = 'Custom Report',
  QuickEntry = 'Quick Entry',
  Users = 'Users',
  Clients = 'Clients',
}
