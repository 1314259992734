<div class="content client-list">
  <div class="content-body">
    <div class="mb-15 d-flex justify-space-between align-items-end wp-100">
      <app-transactions-header
        [hidden]="
          moduleId === moduleEnum.VAT ||
          moduleId === moduleEnum.DisposeTangibleFixedAssets ||
          moduleId === moduleEnum.DisposeLeasedFixedAssets ||
          moduleId === moduleEnum.DisposeIntangibleFixedAssets
        "
        (triggerDataFromHeader)="getDataFromHeader($event)"
        [listParameters]="listParameters"
        [transactionModuleId]="moduleId"
      >
      </app-transactions-header>
      <div class="grid-search mr-10">
        <mat-form-field
          *ngIf="
            moduleId !== moduleEnum.TrialBalance &&
            moduleId !== moduleEnum.AccountDetails &&
            moduleId !== moduleEnum.BalanceSheet &&
            moduleId !== moduleEnum.BalanceSheetComparison &&
            moduleId !== moduleEnum.ProfitAndLoss &&
            moduleId !== moduleEnum.BalanceSheetDetail &&
            moduleId !== moduleEnum.VAT
          "
          appearance="fill"
          class="search-field"
        >
          <input
            matInput
            placeholder="Search here..."
            autocomplete="off"
            (change)="onSearch($event)"
          />

          <button mat-button disableRipple class="search-button">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="header-actions wp-100">
        <app-header-actions
          (exportClick)="exportReport($event, false)"
          (dateChanged)="dateChanged($event)"
          (triggerDataFromHeader)="getDataFromHeader($event)"
          [listParameters]="listParameters"
          [transactionModuleId]="moduleId"
          [parentModuleId]="moduleId"
          [parentModuleName]="moduleName"
        >
        </app-header-actions>
      </div>
    </div>
    <div *ngIf="moduleId === moduleEnum.BankDashboard">
      <app-bank-dashboard
        (triggerDataFromBank)="getDataFromBank($event)"
      ></app-bank-dashboard>
    </div>
    <div
      class="mat-table-wrapper"
      [ngClass]="[
        totalSelectedRecords > 0 ? 'mat-table-wrapper-full' : '',
        showPaginator
          ? 'mat-table-wrapper-pagination'
          : 'mat-table-wrapper-pagination-full',
        (totalRecord$ | async) <= 10 ? 'mat-table-wrapper-full-view' : '',
        moduleId === moduleEnum.BankDashboard && isExpanded
          ? 'mt-1 mat-table-wrapper-accordian'
          : '',
        moduleId === moduleEnum.BankDashboard && !isExpanded
          ? 'mat-table-wrapper-accordian-full'
          : '',
        moduleId === moduleEnum.BankDashboard && !showPaginator
          ? 'mat-table-wrapper-accordian-pagination'
          : '',
        moduleId === moduleEnum.BankDashboard && !isExpanded && !showPaginator
          ? 'mat-table-wrapper-accordian-full-view'
          : ''
      ]"
    >
      <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
        <mat-icon class="material-icons-outlined">error_outline</mat-icon>
        {{ commonNotificationText.ViewOnlyAccessMessage }}
      </div>
      <table
        aria-describedby="main-list"
        mat-table
        matSort
        (matSortChange)="sorting(sort.active, sort.direction)"
        [dataSource]="dataSource"
      >
        <ng-container
          *ngFor="let col of columns$ | async; let colIndex = index"
          matColumnDef="{{ col.name }}"
        >
          <th mat-header-cell *matHeaderCellDef [id]="col.name" fxFlex="50">
            <div class="d-flex align-items-center">
              <span
                *ngIf="
                  moduleId !== moduleEnum.BankImportTransactionHistory &&
                  moduleId !== moduleEnum.DisposeTangibleFixedAssets &&
                  moduleId !== moduleEnum.DisposeLeasedFixedAssets &&
                  moduleId !== moduleEnum.DisposeIntangibleFixedAssets &&
                  checkBoxValue !== 0
                "
              >
                <mat-checkbox
                  [(ngModel)]="isAllSelected"
                  (change)="selectAll($event)"
                  *ngIf="colIndex === 1"
                >
                </mat-checkbox>
              </span>
              <span mat-sort-header [disabled]="!col.name">
                {{ col.name }}
              </span>
            </div>
          </th>

          <td mat-cell *matCellDef="let element; let i = index">
            <div
              class="d-flex align-items-center button-wrapper"
              [ngClass]="
                colIndex === (columns$ | async).length - 1
                  ? 'justify-space-between'
                  : ''
              "
            >
              <mat-checkbox
                *ngIf="
                  moduleId !== moduleEnum.BankImportTransactionHistory &&
                  moduleId !== moduleEnum.DisposeTangibleFixedAssets &&
                  moduleId !== moduleEnum.DisposeLeasedFixedAssets &&
                  moduleId !== moduleEnum.DisposeIntangibleFixedAssets &&
                  colIndex === 1
                "
                [(ngModel)]="element.isSelected"
                (change)="onCheckBoxSelected(element, $event)"
              >
              </mat-checkbox>
              <div class="clamp">
                <span
                  *ngIf="col.dataType === dataType.String"
                  title="{{ element[colIndex]?.rowData }}"
                  class="clamp"
                  [ngClass]="{
                    'text-danger-100':
                      col.name === 'Account' &&
                      (moduleId === moduleEnum.ActiveTangibleFixedAssets ||
                        moduleId === moduleEnum.ActiveLeasedFixedAssets ||
                        moduleId === moduleEnum.ActiveIntangibleFixedAssets) &&
                      element[element.length - 2].rowData == 'False'
                  }"
                >
                  {{ element[colIndex]?.rowData }}</span
                >

                <span
                  *ngIf="col.dataType === dataType.Bool"
                  title="{{ element[colIndex]?.rowData }}"
                  [ngClass]="
                    element[colIndex]?.rowData === 'Active'
                      ? 'status-active'
                      : 'status-archived'
                  "
                >
                  {{ element[colIndex]?.rowData }}
                </span>

                <span
                  *ngIf="
                    col.dataType === dataType.URL &&
                    element[colIndex].id !== '' &&
                    element[colIndex].moduleId !== 0
                  "
                  title="{{ element[colIndex]?.rowData }}"
                >
                  <a
                    class="link clamp wmx-100"
                    (click)="
                      redirect(element[colIndex].id, element[colIndex].moduleId)
                    "
                  >
                    {{ element[colIndex]?.rowData }}
                  </a>
                </span>

                <span
                  *ngIf="
                    col.dataType === dataType.URL &&
                    element[colIndex].id === '' &&
                    element[colIndex].moduleId === 0
                  "
                  title="{{ element[colIndex]?.rowData }}"
                  [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                  class="clamp"
                >
                  {{ element[colIndex]?.rowData }}
                </span>

                <span
                  *ngIf="col.dataType === dataType.DateTime"
                  title="{{ element[colIndex]?.rowData }}"
                >
                  {{ element[colIndex]?.rowData | date: "dd-LLL-yyyy" }}</span
                >

                <span *ngIf="col.dataType === dataType.DropDown">
                  <ng-select
                    class="w-150"
                    *ngIf="+element[colIndex]?.rowData === 0"
                    #receipt
                    (focus)="receipt.open()"
                    (valueChange)="receipt.close()"
                    [disableOptionCentering]="true"
                    panelClass="mat-select-position"
                    appendTo="body"
                    (change)="receiptPaymentChange($event, element)"
                  >
                    <ng-option *ngFor="let fn of receiptList" [value]="fn">
                      <div title="{{ fn.name }}">{{ fn.name }}</div>
                    </ng-option>
                  </ng-select>

                  <ng-select
                    class="w-150"
                    *ngIf="+element[colIndex]?.rowData === 1"
                    #payment
                    (focus)="payment.open()"
                    (valueChange)="payment.close()"
                    [disableOptionCentering]="true"
                    panelClass="mat-select-position"
                    appendTo="body"
                    (change)="receiptPaymentChange($event, element)"
                  >
                    <ng-option *ngFor="let fn of paymentList" [value]="fn">
                      <div title="{{ fn.name }}">{{ fn.name }}</div>
                    </ng-option>
                  </ng-select>
                </span>

                <span
                  *ngIf="col.dataType === dataType.Currency"
                  title="{{ element[colIndex]?.rowData }}"
                  class="clamp"
                >
                  £ {{ element[colIndex]?.rowData | numberPipe }}</span
                >
                <span
                  *ngIf="col.dataType === dataType.Int32"
                  title="{{ element[colIndex]?.rowData }}"
                  class="clamp"
                >
                  {{ element[colIndex]?.rowData | numberPipe }}</span
                >

                <span *ngIf="col.dataType === dataType.Blob">
                  <button
                    mat-button
                    (click)="onAddClick(element)"
                    class="fw-bold text-primary"
                    disableRipple
                  >
                    <mat-icon>add_circle_outline</mat-icon>
                  </button></span
                >
              </div>
              <div
                *ngIf="colIndex === (columns$ | async).length - 1"
                class="grid-actions"
                [ngClass]="selectedRowIndex === i ? 'show-button' : ''"
              >
                <div
                  class="d-flex align-items-center"
                  *ngFor="
                    let item of actions$ | async;
                    let permissionsIndex = index
                  "
                >
                  <button
                    *ngIf="
                      permissionsIndex < moreActionCount &&
                      moduleId !== moduleEnum.ActiveTangibleFixedAssets &&
                      moduleId !== moduleEnum.ActiveLeasedFixedAssets &&
                      moduleId !== moduleEnum.ActiveIntangibleFixedAssets
                    "
                    mat-button
                    disableRipple
                    title="{{ item.name }}"
                    (click)="onButtonClick(element[0], item.actionType)"
                  >
                    <mat-icon fontSet="material-icons-outlined">{{
                      item.icon
                    }}</mat-icon>
                  </button>
                  <button
                    *ngIf="
                      moduleId === moduleEnum.ActiveTangibleFixedAssets ||
                      moduleId === moduleEnum.ActiveLeasedFixedAssets ||
                      moduleId === moduleEnum.ActiveIntangibleFixedAssets
                    "
                    type="button"
                    mat-button
                    disableRipple
                    title="{{ item.name }}"
                    [disabled]="
                      (item.name === 'Sell' &&
                        element[element.length - 2].rowData !== 'True') ||
                      (item.name === 'RollBack' &&
                        element[element.length - 2].rowData !== 'True') ||
                      (item.name === 'Dispose' &&
                        element[element.length - 2].rowData !== 'True')
                    "
                    (click)="onTOCButtonClick(element, item.actionType)"
                  >
                    <mat-icon>{{ item.icon }}</mat-icon>
                  </button>

                  <button
                    mat-button
                    class="more-button"
                    (click)="onToggleMatMenu(i)"
                    [matMenuTriggerFor]="menu"
                    (menuClosed)="allowCloseOnClickOut()"
                    disableRipple
                  >
                    <mat-icon *ngIf="permissionsIndex === moreActionCount"
                      >more_vert</mat-icon
                    >
                  </button>
                </div>

                <mat-menu
                  #menu="matMenu"
                  xPosition="before"
                  class="more-button-menu"
                >
                  <ng-container
                    *ngFor="
                      let item of actions$ | async;
                      let permissionsIndex = index
                    "
                  >
                    <div *ngIf="permissionsIndex > 2">
                      <button
                        mat-menu-item
                        disableRipple
                        title="{{ item.name }}"
                        (click)="onButtonClick(element[0], item.actionType)"
                      >
                        <mat-icon fontSet="material-icons-outlined">{{
                          item.icon
                        }}</mat-icon>
                        <span>{{ item.name }}</span>
                      </button>
                    </div>
                  </ng-container>
                </mat-menu>
              </div>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="columnsNames$ | async; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columnsNames$ | async"
          [ngClass]="isRowHighlighted ? 'highlightRow' : ''"
        ></tr>
      </table>
      <div
        *ngIf="dataSource.data.length === 0 && noDataFound"
        class="text-align-center mt-1 fw-bold"
      >
        No records found
      </div>
    </div>
  </div>
  <div
    class="paginator-wrapper"
    *ngIf="totalSelectedRecords <= 0 && (totalRecord$ | async) > 0"
  >
    <app-custom-paginator
      *ngIf="(totalRecord$ | async) > 10"
      [length]="totalRecord$ | async"
      [listParameters]="listParameters"
      (pageChanged)="pageChanged($event)"
      (pageSizeVal)="pageSizeVal($event)"
      (togglePaginator)="togglePaginator($event)"
      [triggerPaginationChange]="triggerPaginationChange"
    >
    </app-custom-paginator>
  </div>
</div>

<app-card-footer
  *ngIf="totalSelectedRecords > 0"
  [totalSelectedRecords]="totalSelectedRecords"
  (deleteClick)="deleteClick()"
  (exportClick)="export($event, false)"
  (copyClick)="copyClick()"
  (cancelSelectionClick)="cancelSelectionClick()"
  (printClick)="printClick()"
  (archiveAndRestoreClick)="archiveAndRestoreClick($event)"
  [ngClass]="
    this.listParameters.filter === filteredStatus.Archive
      ? 'archive-records'
      : ''
  "
  [getModuleId]="moduleId"
  [triggerSelectedRowData]="triggerSelectedRowData"
></app-card-footer>
<ngx-spinner
  template="<img class='loader-image mr-1' src='assets/images/loader.svg' />
  <span class='loader'></span>"
></ngx-spinner>
