import {
  Component,
  EventEmitter,
  Injector,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ComponentName,
  DataType,
  Inventory,
  InventoryName,
  ModuleName,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import {
  CustomizeListParameters,
  MenuModel,
  PermissionModel,
  ViewParamModel,
} from '@app/core/Models';
import {
  CommonService,
  ModulePermission,
  NotificationService,
} from '@app/core/Services';
import {
  CommonState,
  CreateCustomReport,
  CustomReportExport,
  GetCustomizeReportList,
  GetCustomReportQuery,
  GetFilteredAccounts,
  MenuState,
  SetModulePermission,
} from '@app/core/Store';
import {
  AddCustomizeReportPopup,
  ViewReceiptComponent,
} from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import {
  DataStateChangeEventArgs,
  GridComponent,
} from '@syncfusion/ej2-angular-grids';
import {
  QueryBuilderComponent,
  RuleModel,
} from '@syncfusion/ej2-angular-querybuilder';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-customize-report',
  templateUrl: './customize-report.component.html',
  styleUrls: ['./customize-report.component.scss'],
})
export class CustomizeReportComponent implements OnInit, OnDestroy {
  @ViewChild('querybuilder', { static: false })
  public querybuilder: QueryBuilderComponent;
  @ViewChild('grid') grid!: GridComponent;

  @Select(CommonState.actions)
  actions$: Observable<any>;

  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;

  importRules: RuleModel = {};
  //   'condition': 'and',
  //   'rules': [
  //     {
  //       'label': "Credit",
  //       'type': "number",
  //       'field': 'Credit', // Replace with your actual field name
  //       'operator': 'equal',
  //       'value': 12,
  //     },
  //     // Add more rules as needed
  //   ],
  // }

  notify: NotificationService;

  data = {
    value: [],
  };
  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;
  columns: any = [];
  gridData: any = [];
  tempGridData: any[] = [];
  groupSettings: any = { columns: [] };
  selectedRowData: any = [];
  state: DataStateChangeEventArgs = {
    skip: 0,
    take: 20,
    sorted: [],
    group: [],
  };
  totalRecords = 0;
  skip = 0;
  dataType = DataType;
  moduleEnum = Modules;
  moduleId = Modules.CustomizeReport;
  moduleName = ModuleName.CustomizeReport;
  dataSource: any = [];
  moreActionCount = 4;
  selectedRowIndex: number = -1;

  customOperators = [{ value: 'in', key: 'In' }];

  public mulObj: MultiSelectComponent;
  public accountmode: string;
  public accountfilterPlaceholder: string;
  public accountWaterMark: string = 'Account-All';
  public accountpopHeight: string = '350px';

  values: Object[] = [];
  reportColumnList: any[] = [];
  tempReportColumnList: any[] = [];
  subscriptionAPIGetList: Subscription;

  listParameters: CustomizeListParameters = new CustomizeListParameters();

  istoggleSideList = false;
  isShowSideListAction = false;
  subscriptionRouting: Subscription;
  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  customizeReportId = Guid.EMPTY as unknown as Guid;
  isReset = false;
  customizeReportName = '';
  store: Store;
  commonService: CommonService;
  constructor(
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private router: Router,
    private cookieService: CookieService,
    private modulePermission: ModulePermission,
    private _Activatedroute: ActivatedRoute,
    private injector: Injector
  ) {
    this.notify = injector.get<NotificationService>(NotificationService);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.accountmode = 'CheckBox';
    this.accountfilterPlaceholder = 'Search Account';

    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.isReset = false;
          this.columns = [];
          this.customizeReportId = atob(params.get('id')!) as unknown as Guid;
          this.getFilteredAccount(true);
        } else {
          this.getFilteredAccount(false);
          this.customizeReportId = Guid.EMPTY as unknown as Guid;
          this.importRules = {};
          this.columns = [];
          this.isReset = true;
        }
      }
    );
  }

  getCustomReportQueryById(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetCustomReportQuery(this.customizeReportId))
      .subscribe((res) => {
        this.spinner.hide();
        this.customizeReportName = res.common.customReportQuery.name;
        this.importRules = { ...res.common.customReportQuery.query };
        this.isReset = true;
      });
  }

  saveReportClick(): void {
    if (this.customizeReportId !== (Guid.EMPTY as unknown as Guid)) {
      this.saveCustomReport();
    } else {
      this.dialog
        .open(AddCustomizeReportPopup)
        .afterClosed()
        .subscribe((res) => {
          if (res !== null && res !== undefined) {
            this.customizeReportName = res.name;
            this.saveCustomReport();
          }
        });
    }
  }

  saveCustomReport(): void {
    const customReportQuery: any = {
      id: this.customizeReportId,
      query: this.querybuilder.getRules(),
      name: this.customizeReportName,
    };

    if (customReportQuery !== undefined) {
      this.spinner.show();
      this.store
        .dispatch(new CreateCustomReport(customReportQuery))
        .subscribe((res) => {
          this.spinner.hide();
          if (res !== undefined) {
            this.customizeReportId = res.common.customReportId;
            this.reloadSideList.emit();
            this.commonService.onSuccess(
              NotificationTextMessage.successMessage
            );
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        });
    }
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  addNewLineItem(columnName, rowData): void {
    if (!columnName.some((e) => e.name === 'srNo')) {
      columnName.push({
        name: 'srNo',
        id: null,
        userId: null,
        dataType: 1,
        allowSortBy: true,
        alignment: null,
        isSelected: false,
        isLocked: false,
        moduleGridColumnId: 1,
        columnType: this.getDataType(1),
      });
      rowData = rowData.map(function (currentValue, index) {
        let extraField: any;
        extraField = {
          id: null,
          alignment: null,
          isBold: false,
          moduleId: 0,
          rowData: index,
        };

        currentValue.push(extraField);
        return currentValue;
      });
    }
  }

  getDataType(type: number): any {
    let dataType = '';

    switch (type) {
      case DataType.String:
        dataType = 'text';
        break;

      case DataType.Int32:
      case DataType.Int64:
      case DataType.Float:
      case DataType.Currency:
        dataType = `numeric`;
        break;

      case DataType.Bool:
        dataType = `boolean`;
        break;

      case DataType.Date:
      case DataType.DateTime:
        dataType = `date`;
        break;
      default:
        dataType = 'text';
        break;
    }

    return dataType;
  }

  getCustomiseReportList(): void {
    const rule = this.querybuilder.getSqlFromRules(this.querybuilder.rule);
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      branchIds: this.listParameters.branchIds ?? [],
      departmentIds: this.listParameters.departmentIds ?? [],
      queryBuilderRule: rule,
    };
    this.spinner.show();
    this.subscriptionAPIGetList = this.store
      .dispatch(new GetCustomizeReportList(queryParams))
      .subscribe((res) => {
        this.reportColumnList = [];

        let columnName = res.common.mainList.resultSet.columns;
        let rowData = res.common.mainList.resultSet.data;

        this.actions$.subscribe((x) => {
          if (x !== null && x !== undefined && x.length > 4) {
            this.moreActionCount = 3;
          }
        });

        this.addNewLineItem(columnName, rowData);

        let filteredColumnList;
        const excludedNames = ['ExtraValue', 'srNo'];

        filteredColumnList = columnName.filter(
          (i) => !excludedNames.includes(i.name)
        );

        const colArray: any = [];
        filteredColumnList.forEach((element, i) => {
          colArray.push({
            name: element.name,
            id: element.id,
            userId: element.userId,
            dataType: element.dataType,
            allowSortBy: element.allowSortBy,
            alignment: element.alignment,
            moduleId: element.moduleId,
            isSelected: element.name === 'Id' ? false : element.isSelected,
            isLocked: element.isLocked,
            moduleGridColumnId: element.moduleGridColumnId,
            columnType: this.getDataType(element.dataType),
          });
        });

        const filteredGridList = rowData.map((element) => {
          let e = {};

          Object.keys(element).forEach((key) => {
            const newKey = columnName[key].name;
            e[newKey] = element[key];
          });
          return e;
        });

        this.reportColumnList = rowData.map((element, i) => {
          let e = {};
          Object.keys(element).forEach((key, j) => {
            const newKey = columnName[key].name;
            e[newKey] = element[j];
          });
          return e;
        });

        this.spinner.hide();
        this.tempGridData = filteredGridList;
        if (
          this.columns.length > 0 &&
          this.columns.length !== colArray.length
        ) {
          this.columns = [];
          this.spinner.show();
          setTimeout(() => {
            this.columns = colArray;
            this.spinner.hide();
          }, 0);
        } else if (this.columns.length === 0) {
          this.columns = colArray;
        }

        this.totalRecords = res.common.mainList.paginationModel.totalItemCount;

        this.state.take = this.isPaginationAllowed(this.moduleId)
          ? this.totalRecords
          : this.listParameters.pageSize;

        this.gridData = {
          result: this.reportColumnList,
          count: this.totalRecords,
        };

        this.subscriptionAPIGetList.unsubscribe();
      });
  }

  isPaginationAllowed(moduleId): boolean {
    let isAllowed;

    switch (moduleId) {
      case Modules.TrialBalance:
      case Modules.BalanceSheet:
      case Modules.VAT:
      case Modules.ComparativeReport:
      case Modules.IncomeAndExpenditure:
      case Modules.ProfitAndLoss:
      case Modules.BalanceSheetComparison:
      case Modules.BalanceSheetDetail:
      case Modules.ProfitAndLossComparision:
      case Modules.ProfitAndLossSummary:
      case Modules.ProfitAndLossWithPercentage:
      case Modules.PurchasesReport:
        isAllowed = true;
        break;
      default:
        isAllowed = false;
        break;
    }

    return isAllowed;
  }

  ngOnDestroy(): void {
    this.subscriptionAPIGetList?.unsubscribe();
  }

  getFilteredAccount(isEdit: boolean): void {
    this.store.dispatch(new GetFilteredAccounts()).subscribe((res) => {
      if (res.account.accountsFilteredList.length > 0) {
        this.values = [];
        res.account.accountsFilteredList.forEach((element) => {
          const param = {
            text: element.name,
            value: element.id,
          };
          this.values.push(param);
        });
        if (isEdit) {
          this.getCustomReportQueryById();
        }
      }
    });
  }

  accountChange(e: any, ruleID: string): void {
    let elem: HTMLElement;
    elem = document.getElementById(ruleID).querySelector('.e-rule-value');
    this.querybuilder.notifyChange(e.value as string, elem, 'value');
  }

  onBeforeConditionChange(args: any): void {
    if (args.type === 'insertGroup') {
      // Prevent adding groups
      args.cancel = true;
    }
  }

  checkBoxChange(args): void {
    let data: any[] = (this.grid as GridComponent).getCurrentViewRecords();
    this.selectedRowData = [];
    if (args.selectedRowIndexes.length > 0) {
      args.selectedRowIndexes.forEach((element) => {
        this.selectedRowData.push(data[element]);
      });
    }
  }

  dataStateChange(state: DataStateChangeEventArgs): void {
    if (
      state.action.requestType === 'filtering' ||
      state.action.requestType === 'paging' ||
      state.action.requestType === 'sorting' ||
      state.action.requestType === 'grouping' ||
      state.action.requestType === 'ungrouping'
    ) {
      this.state = state;
      if (
        state.sorted !== null &&
        state.sorted !== undefined &&
        state.sorted.length > 0
      ) {
        this.listParameters.sortBy = state.sorted[0].name.replace(
          '.rowData',
          ''
        );
        this.listParameters.sortOrder =
          state.sorted[0].direction === 'ascending' ? true : false;
      }
      this.listParameters.pageNumber = state.skip / state.take + 1;
      this.listParameters.pageSize = state.take ?? 0;
      this.getCustomiseReportList();
    }
  }

  setPermission(url: any, id: any): void {
    setTimeout(() => {
      this.menuList$.subscribe((data) => {
        this.setData(url, data, ComponentName.DynamicGridListComponent, id);
      });
    }, 500);
  }

  emitPermissionData(data: any, componentName: any, id: any): void {
    const permissionData = new PermissionModel();
    permissionData.data = data;
    permissionData.componentName = componentName;
    this.modulePermission.permissionData.next(permissionData);
  }

  setData(event: any, data: any, componentName: any, id: any): void {
    data.forEach((x) => {
      if (x.url === event.slice(1) || x.addUrl === event.slice(1)) {
        this.store.dispatch(new SetModulePermission(x)).subscribe();
        this.emitPermissionData(x, componentName, id);
      } else {
        x.subMenu.map((y) => {
          if (
            y.url === event.slice(1) ||
            y.addUrl === event.slice(1).split(';')[0]
          ) {
            this.store.dispatch(new SetModulePermission(y)).subscribe();
            this.emitPermissionData(y, componentName, id);
          } else {
            y.subMenu.map((z) => {
              if (
                z.url === event.slice(1) ||
                z.addUrl === event.slice(1).split(';')[0]
              ) {
                this.store.dispatch(new SetModulePermission(z)).subscribe();
                this.emitPermissionData(z, componentName, id);
              } else {
                z.subMenu.map((z1) => {
                  if (
                    z1.url === event.slice(1) ||
                    z1.addUrl === event.slice(1).split(';')[0]
                  ) {
                    this.store
                      .dispatch(new SetModulePermission(z1))
                      .subscribe();
                    this.emitPermissionData(z1, componentName, id);
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  redirect(columnName: string, data: any): void {
    if (data.moduleId > 0) {
      if (data.moduleId === Modules.AddCustomAccounts) {
        this.commonService.onEditRouting(
          true,
          Modules.ReportAccountDetail,
          data.id,
          false
        );
        this.setPermission(RoutingPath.ReportAccountDetailsPermission, data.id);
      } else if (
        data.moduleId === Modules.Customers ||
        data.moduleId === Modules.Suppliers
      ) {
        this.commonService.onEditRouting(true, data.moduleId, data.id);
      } else if (this.isAllowedOpenView(data.moduleId)) {
        this.openView(data.id, data.moduleId);
      } else if (this.moduleId === Modules.Inventory) {
        if (columnName === InventoryName.Purchase) {
          this.cookieService.set('detailListId', Inventory.Purchase.toString());
        } else if (columnName === InventoryName.Sales) {
          this.cookieService.set('detailListId', Inventory.Sales.toString());
        }
        this.cookieService.set('productId', data.id);
        this.router.navigate([RoutingPath.DetailList]);
      } else {
        this.commonService.onEditRouting(true, data.moduleId, data.id);
      }
    }
  }

  openView(id: any, moduleId: any): void {
    const data: ViewParamModel = {
      moduleId: moduleId,
      id: id,
    };

    this.dialog
      .open(ViewReceiptComponent, {
        data,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {});
  }

  isAllowedOpenView(moduleId: number): boolean {
    let isAllowed;

    switch (moduleId) {
      case Modules.Invoices:
      case Modules.Purchase:
      case Modules.CreditNote:
      case Modules.DebitNote:
      case Modules.FixedAssets:
      case Modules.Quotation:
      case Modules.Journals:
      case Modules.Receipt:
      case Modules.Payment:
      case Modules.CIS:
      case Modules.FixedAssetDetail:
        isAllowed = true;
        break;
      default:
        isAllowed = false;
        break;
    }

    return isAllowed;
  }

  receiptPaymentChange(event: any, data: any): void {
    this.commonService.onEditRouting(
      false,
      event.moduleId,
      data.Id.rowData,
      true
    );
  }

  downloadFile(event: any): void {
    const list: any[] = [];
    list.push(event);
    const param = {
      fileURLs: list,
    };
    this.commonService.downloadFile(param).subscribe();
  }

  onButtonClick(actionType: any, data: any): void {
    this.onActionClick(actionType, data);
  }

  onActionClick(actionType: any, data: any): void {}

  getSelectedRowIndex(selectedRowIndex, rowIndex: number): void {
    this.selectedRowIndex =
      selectedRowIndex === -1 ? selectedRowIndex : rowIndex;
  }
  exportReport(format: number, isPrint?: boolean): void {
    if (this.totalRecords > 0) {
      const rule = this.querybuilder.getSqlFromRules(this.querybuilder.rule);
      const queryParams = {
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        branchIds: this.listParameters.branchIds ?? [],
        departmentIds: this.listParameters.departmentIds ?? [],
        queryBuilderRule: rule,
        format: format,
      };
      this.spinner.show();

      this.store.dispatch(new CustomReportExport(queryParams)).subscribe();
    } else {
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noDataToExport
      );
    }
  }
}
