<div id="chart" *ngIf="analyticsData.length > 0">
  <apx-chart
    [series]="chartOptions.series!"
    [chart]="chartOptions.chart!"
    [labels]="chartOptions.labels!"
    [fill]="chartOptions.fill!"
    [legend]="chartOptions.legend!"
    [dataLabels]="chartOptions.dataLabels!"
    [tooltip]="chartOptions.tooltip!"
    [responsive]="chartOptions.responsive!"
  ></apx-chart>
</div>
<div *ngIf="analyticsData.length === 0" class="norecord-chart">
  <img src="assets/images/pia-icon.svg" alt="Pie Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
