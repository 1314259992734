import { FlatTreeControl } from '@angular/cdk/tree';
import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { Router } from '@angular/router';
import {
  ConfirmationType,
  DataType,
  ModuleName,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { ExportType, MainListParameters } from '@app/core/Models';
import {
  CommonService,
  HighlightRow,
  ModulePermission,
  NotificationService,
} from '@app/core/Services';
import {
  CommonState,
  Copy,
  Export,
  GetMainList,
  MenuState,
} from '@app/core/Store';
import { ConfirmationBoxComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

interface FoodNode {
  data: Array<Array<string>>;
  children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
  {
    data: [['54aba809-5274-ed11-ac20-0050f217770d', 'INV-3']],
    children: [
      {
        data: [['54aba809-5274-ed11-ac20-0050f217770d', 'INV-3']],
      },
    ],
  },
];

interface ExampleFlatNode {
  expandable: boolean;
  data: Array<Array<string>>;
  level: number;
}

@Component({
  selector: 'app-tree-list',
  templateUrl: './tree-list.component.html',
  styleUrls: ['./tree-list.component.scss'],
})
export class TreeListComponent implements OnInit {
  private transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      data: node.data,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  @Select(CommonState.columns)
  columns$: Observable<
    Array<{
      name: string;
      value: string;
      dataType?: DataType;
      allowSortBy?: boolean;
    }>
  >;

  isRowHighlighted = false;

  showPaginator = true;
  checkBoxValue = 0;
  moduleName = ModuleName.BankDashboard;
  bankDashboardId = Modules.BankDashboard;

  @Select(CommonState.columnNames)
  columnsNames$: Observable<Array<string>>;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  listParameters: MainListParameters = new MainListParameters();
  totalSelectedRecords = 0;
  isAllSelected = false;
  ids: Array<Guid>;
  mainList: any;

  dataType = DataType;
  moduleId: number;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(MenuState.hasEditPermission)
  hasEditPermission$: Observable<boolean>;

  @Select(MenuState.hasDeletePermission)
  hasDeletePermission$: Observable<boolean>;

  @ViewChild(MatSort) sort: MatSort;

  dialog: MatDialog;
  store: Store;
  notify: NotificationService;
  commonService: CommonService;
  router: Router;
  highlightRow: HighlightRow;
  datepipe: DatePipe;
  spinner: NgxSpinnerService;
  modulePermission: ModulePermission;

  constructor(private injector: Injector) {
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.store = injector.get<Store>(Store);
    this.notify = injector.get<NotificationService>(NotificationService);
    this.commonService = injector.get<CommonService>(CommonService);
    this.router = injector.get<Router>(Router);
    this.highlightRow = injector.get<HighlightRow>(HighlightRow);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.modulePermission = injector.get<ModulePermission>(ModulePermission);
  }
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  ngOnInit(): void {
    this.dataSource.data = TREE_DATA;
    this.getList(true);
    this.modulePermission.permissionData.subscribe((data) => {
      this.moduleId = data.id;
    });
  }

  highlightRecord(): void {
    if (this.moduleId > 0) {
      if (this.highlightRow.mainListHighlighted.moduleId === this.moduleId) {
        this.listParameters.sortBy =
          this.highlightRow.mainListHighlighted.sortBy;
        this.listParameters.sortOrder = false;
        this.getList(true);
      } else {
        this.getList(false);
      }
    }
  }

  selectAll(event: any): void {
    this.mainList.forEach((x) => (x.isSelected = event.checked));
    this.showFooter();
  }

  showFooter(): void {
    this.selectedIds();
    this.totalSelectedRecords = this.ids.length;
  }

  deleteClick(): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: this.moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getList(false);
            this.cancelSelectionClick();
          }
        });
    }
  }

  export(format: number, isPrint?: boolean): void {
    this.spinner.show();
    this.selectedIds();

    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId,
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.ids,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      format: format,
      isPrint: isPrint,
    };

    this.store.dispatch(new Export(queryParams, this.moduleId)).subscribe();
    this.cancelSelectionClick();
  }

  copyClick(): void {
    if (this.moduleId !== Modules.CashEntry) {
      this.spinner.show();

      this.selectedIds();

      this.store
        .dispatch(new Copy(this.moduleId, this.ids))
        .subscribe((res) => {
          if (res.common.isCopied) {
            this.notify.success(
              NotificationHeader.success,
              NotificationTextMessage.recordCopySuccessfully
            );
            this.getList(false);
            this.cancelSelectionClick();
          }
        });
    }
  }

  cancelSelectionClick(): void {
    this.deSelectAll();
  }

  deSelectAll(): void {
    this.mainList.forEach((x) => (x.isSelected = false));
    this.isAllSelected = false;
    this.totalSelectedRecords = 0;
  }

  printClick(): void {
    this.export(ExportType.PDF, true);
    this.cancelSelectionClick();
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.getList(false);
  }

  onAddClick(element: any): void {
    this.router.navigate([
      RoutingPath.AddCustomAccounts,
      { accountId: btoa(element[0]) },
    ]);
  }

  archiveAndRestoreClick(isArchive: any): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            isArchive,
            type: ConfirmationType.Archive,
            moduleId: this.moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: isArchive
              ? NotificationHeader.archiveConfirmation
              : NotificationHeader.restoreConfirmation,
            detailText: isArchive
              ? NotificationDetails.archiveDetailText
              : NotificationDetails.restoreDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getList(false);
            this.cancelSelectionClick();
          }
        });
    }
  }

  selectedIds(): void {
    this.ids = [];
    this.mainList.forEach((value) =>
      value.isSelected ? this.ids.push(value[0] ?? 0) : ''
    );
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.getList(false);
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.getList(false);
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  onCheckBoxSelected(element: any, event: any): void {
    element.isSelected = event.checked;

    this.isAllSelected = this.mainList.every(
      (item: any) => item.isSelected === true
    );
    this.showFooter();
  }

  onDeleteClick(id: any): void {
    this.ids = [];
    this.ids.push(id);

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: this.moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getList(false);
          }
        });
    }
  }

  onEditClick(id: any): void {
    this.commonService.onEditRouting(true, this.moduleId, id);
  }

  getList(isRowHighlighted: boolean): void {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };

    this.store
      .dispatch(new GetMainList(queryParams, this.moduleId))
      .pipe(
        tap(() => {
          this.isRowHighlighted = isRowHighlighted;
        })
      )
      .subscribe((res) => {});
  }

  getDataFromHeader(data: any): void {
    if (data.search === '') {
      this.spinner.show();
    }
    this.cancelSelectionClick();
    this.listParameters = data;
    !this.listParameters.format
      ? this.getList(false)
      : this.export(this.listParameters.format, this.listParameters.isPrint);
  }
}
