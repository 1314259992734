import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import { ModuleName, Modules, NotificationTextMessage } from '@app/core/Enum';
import { Currency, GlobalComponent, SideListModel } from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import {
  CreateDirectorShareholder,
  GetAllShareClassType,
  GetAllShareHolder,
  GetDataByDirectorShareholderId,
  GetShareHolderDetailView,
} from '@app/core/Store';
import { QuickAddComponent } from '@app/modules';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-dividends',
  templateUrl: './add-dividends.component.html',
  styleUrls: ['./add-dividends.component.scss'],
})
export class AddDividendComponent implements OnInit {
  disabledEditButton = true;
  hidePostJournalCheckBox = false;

  dividendsForm: FormGroup;
  shareHolderList: Currency[];
  shareClassTypeList: SideListModel[];
  notificationMessage = NotificationTextMessage;

  @Input() triggerEditData: Observable<any>;

  periodicDate: any;

  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setForm();

    this.triggerEditData?.subscribe((data) => {
      setTimeout(() => {
        this.editDividend(data);
      }, 1500);
    });

    this.dividendsForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.dividendsForm.touched;
    });
  }

  editDividend(data): void {
    this.disabledEditButton = false;
    this.dividendsForm.patchValue({
      shareHolder: data.shareholderId,
      refNumber: data.entryNumber,
      shareClassType: data.shareClassTypeId,
      yearEndDate: data.yearEndDate,
      declarationDate: data.declarationDate,
      paymentDate: data.paymentDate,
      dividendPerShare: data.dividendPerShare,
      isTaxCredit: data.excludeTaxCredit,
      taxCredit: data.taxCredit,
      grossDividend: data.grossDividend,
      isPostJournal: data.isPostJournal,
    });

    if (this.dividendsForm.controls.isPostJournal.value) {
      this.hidePostJournalCheckBox = true;
    } else {
      this.hidePostJournalCheckBox = false;
    }

    this.getShareholderDetailView(data.shareholderId);
  }

  setForm(): void {
    this.dividendsForm = new FormGroup({
      shareHolder: new FormControl(),
      refNumber: new FormControl(''),
      shareType: new FormControl(''),
      shareClassType: new FormControl('', [Validators.required]),
      yearEndDate: new FormControl(new Date(new Date().setMonth(2, 31)), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      declarationDate: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      paymentDate: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      noOfShares: new FormControl(''),
      dividendPerShare: new FormControl(0),
      dividendPayable: new FormControl(0),
      isTaxCredit: new FormControl(false),
      taxCredit: new FormControl(0),
      grossDividend: new FormControl(''),
      isPostJournal: new FormControl(false),
    });
    this.hidePostJournalCheckBox = false;
    this.getShareHolder();
    this.getShareClassType();
    this.disableFeilds();
  }

  disableFeilds(): void {
    this.dividendsForm.controls.shareType.disable();
    this.dividendsForm.controls.noOfShares.disable();
    this.dividendsForm.controls.dividendPayable.disable();
    this.dividendsForm.controls.taxCredit.disable();
    this.dividendsForm.controls.grossDividend.disable();
    this.dividendsForm.controls.refNumber.disable();
  }

  onDividendPerShareChanges(): void {
    if (this.dividendsForm.controls.shareHolder.value === undefined) {
      this.dividendsForm.controls.dividendPerShare.setValue(0);
      this.commonService.onFailure(NotificationTextMessage.selectShareHolder);
      this.dividendsForm.controls.shareHolder.markAsTouched();
    } else {
      this.dividendsForm.controls.dividendPayable.setValue(
        +this.dividendsForm.controls.dividendPerShare.value *
          +this.dividendsForm.controls.noOfShares.value
      );
      this.onTaxCreditChange();
    }
  }

  onTaxCreditChange() {
    if (this.dividendsForm.controls.shareHolder.value === undefined) {
      this.commonService.onFailure(NotificationTextMessage.selectShareHolder);
      this.dividendsForm.controls.shareHolder.markAsTouched();
      this.dividendsForm.controls.isTaxCredit.setValue(false);
    } else {
      if (this.dividendsForm.controls.isTaxCredit.value) {
        this.dividendsForm.controls.taxCredit.setValue(0);
        this.dividendsForm.controls.grossDividend.setValue(
          (+this.dividendsForm.controls.dividendPayable.value).toFixed(2)
        );
      } else {
        this.dividendsForm.controls.taxCredit.setValue(
          (
            +(this.dividendsForm.controls.dividendPayable.value * 11.1111) / 100
          ).toFixed(2)
        );
        this.dividendsForm.controls.grossDividend.setValue(
          (
            +this.dividendsForm.controls.dividendPayable.value +
            +this.dividendsForm.controls.taxCredit.value
          ).toFixed(2)
        );
      }
    }
  }

  getShareHolder(id?: Guid): void {
    this.store
      .dispatch(new GetAllShareHolder())

      .pipe(
        tap((res) => {
          this.shareHolderList = res.dividends.shareHolderList;
          if (id !== null && id !== undefined) {
            this.dividendsForm.controls.shareHolder.setValue(id);
            this.getShareholderDetailView(
              this.dividendsForm.controls.shareHolder.value
            );
          }
        })
      )
      .subscribe();
  }

  getShareholderDetailView(shareHolderId: Guid): void {
    if (shareHolderId !== null && shareHolderId !== undefined) {
      this.store
        .dispatch(new GetShareHolderDetailView(shareHolderId))
        .pipe(
          tap((res) => {
            this.dividendsForm.controls.noOfShares.setValue(
              res.dividends.shareHolderDetails.noOfShare
            );
            this.dividendsForm.controls.shareType.setValue(
              res.dividends.shareHolderDetails.shareholderTypeName
            );
            this.dividendsForm.controls.grossDividend.setValue(
              (+this.dividendsForm.controls.dividendPayable.value)
                // +
                // +this.dividendsForm.controls.taxCredit.value
                .toFixed(2)
            );
            this.onDividendPerShareChanges();
          })
        )
        .subscribe();
    }
  }

  getShareClassType(): void {
    this.store
      .dispatch(new GetAllShareClassType())

      .pipe(
        tap((res) => {
          this.shareClassTypeList = res.dividends.shareClassTypeList;
        })
      )
      .subscribe();
  }

  gotoAddShareholder(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Shareholder,
          headerText: `Add ${ModuleName.Shareholders}`,
          saveActionName: CreateDirectorShareholder,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledEditButton = false;
          this.getShareHolder(id);
        }
      });
  }

  gotoEditShareholder(): void {
    if (this.shareHolderList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.Shareholder,
            headerText: `Edit ${ModuleName.Shareholders}`,
            id: this.dividendsForm.controls.shareHolder.value,
            saveActionName: CreateDirectorShareholder,
            getActionName: GetDataByDirectorShareholderId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getShareHolder(id);
          }
        });
    }
  }
}
