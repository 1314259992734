import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import {
  AuthorizedRole,
  CheckDuplicateBy,
  CompanyType,
  ImportStep,
  ModuleName,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
  RecurringType,
  ScheduleSubPeriod,
} from '@app/core/Enum';
import { TicketModules } from '@app/core/Enum/ticket';
import { Country, Currency } from '@app/core/Models';
import { FileImportRequestModel } from '@app/core/Models/common/import';
import {
  DuplicateExistModel,
  TitleModule,
} from '@app/core/Models/common/title';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  CompanyState,
  GetCapiumImportSetting,
  GetImportTemplateAll,
  GetUserPermissionsList,
  GetVatReturnTypeList,
  GetVatSchemeList,
  ImportModulesAll,
  ImportStateAll,
  IsDuplicateExist,
  // IsDuplicateExist,
  RetrieveSubscription,
  UserState,
} from '@app/core/Store';
import { MY_DATE_FORMATS } from '@app/modules/custom/custom-year-picker/custom-year-picker.component';
// import {
//   AuthorizedRole,
//   CheckDuplicateBy,
//   ClientType,
//   ImportStep,
//   ModuleName,
//   Modules,
//   NotificationHeader,
//   NotificationTextMessage,
//   RecurringType,
//   ScheduleSubPeriod,
// } from '@app/core/enums';
//import { TicketModules } from '@app/core/enums/ticket';
// import {
//   Country,
//   Currency,
//   DuplicateExistModel,
//   FileImportRequestModel,
//   TitleModule,
// } from '@app/core/models';
// import { CommonService, NotificationService } from '@app/core/services';
// import { MY_DATE_FORMATS } from '@app/core/sharedmodules';
// // import {
//   ClientState,
//   GetUserPermissionsList,
//   GetVATSchemeList,
//   GetVATSubmitTypeList,
//   IsDuplicateExist,
//   RetrieveSubscription,
//   UserState,
// } from '@app/core/store';
// import {
//   GetCapiumImportSetting,
//   GetImportTemplate,
//   ImportModules,
//   ImportStateAllAll,
// } from '@app/core/store/import';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';

import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-import-details-settings',
  templateUrl: './import-details-settings.component.html',
  styleUrls: ['./import-details-settings.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class ImportDetailsSettingsComponent implements OnInit, OnDestroy {
  file: string;
  fileInfo: string = '';
  showImportSales = false;
  selectedFile: File;
  selectedFileName: string;
  url: any;
  step: ImportStep;
  finalResponse: any;
  fileImportRequestModel: FileImportRequestModel;
  isRecurringImport = false;

  importSuccessList: any = new MatTableDataSource();
  importErrorList: any = new MatTableDataSource();
  importDuplicateList: any = new MatTableDataSource();
  importedFile: any;

  uploadFormGroup = this._formBuilder.group({
    isDuplicate: new FormControl(false),
  });
  finalFormGroup = this._formBuilder.group({
    thirdCtrl: ['', Validators.required],
  });
  tableDataSource: MatTableDataSource<AbstractControl>;
  importSubscription: Subscription;
  importDetailSubscription: Subscription;
  modules = Modules;

  formRecurringImport: FormGroup;
  recurringType = RecurringType;
  dayList: any[] = [];
  weekList: string[] = [];
  monthList: string[] = [];
  scheduleSubPeriod = ScheduleSubPeriod;
  selectedAddonId: any = '0';
  selectedClientType: string = 'Limited';
  clientType = CompanyType;
  authorizedRole = AuthorizedRole;

  countryList: Country[];
  currencyList: Currency[];
  titleList: TitleModule[];
  clientTypeList: any[] = [];
  displayedColumns: any[] = [];
  vatSchemeList: any[] = [];
  vatSubmitTypeList: any[] = [];
  permissionList: any[] = [];
  yearEndDayList: any[] = [];

  dropdownList: any = [
    { id: '1', name: 'Limited' },
    { id: '2', name: 'Individual' },
    { id: '3', name: 'Sole Trader' },
    { id: '4', name: 'Partnership' },
    { id: '5', name: 'LLP' },
    { id: '6', name: 'Trust' },
    { id: '7', name: 'Charity' },
  ];
  selectedClientTypeItems: any = [];
  selectedClientTypes: string[] = [];
  //dropdownSettings: IDropdownSettings = {};

  recurringTypeList: any[] = [
    {
      value: RecurringType.Daily,
      name: 'Daily',
    },
    {
      value: RecurringType.Weekly,
      name: 'Weekly',
    },
    {
      value: RecurringType.Monthly,
      name: 'Monthly',
    },
    {
      value: RecurringType.Yearly,
      name: 'Yearly',
    },
  ];

  timeHours = [
    {
      id: '',
      value: '',
    },
  ];

  @ViewChild('stepper') private myStepper: MatStepper;
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() moduleId: number | undefined;

  @Input() importHeaderText: string;

  @Input()
  triggerImport: Observable<any>;

  @Input()
  triggerImportDetail: Observable<any>;

  @Output()
  readonly triggerImportComplete = new EventEmitter<any>();

  @Output()
  readonly showSalesTeam = new EventEmitter<any>();

  @Output()
  readonly triggerLoader = new EventEmitter<any>();

  @Input()
  triggerGetDetail: any;
  subscriptionModuleList: any = [];
  defaultGuid = Guid.EMPTY as unknown as Guid;
  module = ModuleName;
  moduleName = '';
  isFromCapium3: boolean = false;
  @ViewChild('fileInput')
  myInputVariable: ElementRef;
  checkDuplicateBy = CheckDuplicateBy;
  @ViewChild('nationalInsuranceNumber') nationalInsuranceNumber: ElementRef;
  constructor(
    private notifier: NotificationService,
    private store: Store,
    private _formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.setScheduleForm();

    this.bindMonthList();
    this.bindWeekList();
    this.bindDayList();
    this.bindYearEndDayList();
    this.createHours();
    this.retrieveSubscription();

    this.step = ImportStep.Select;

    this.importDetailSubscription = this.triggerImportDetail.subscribe(
      (res) => {
        this.finalResponse = res;
        this.myStepper.next();
        if (!this.isFromCapium3) {
          this.checkValidFile(true);
        }
      }
    );

    this.importSubscription = this.triggerImport.subscribe(() => {
      if (this.step !== ImportStep.Select) {
        this.checkValidFile(false);
      }
    });

    this.triggerGetDetail?.subscribe((res) => {
      if (res) {
        this.setModuleName();
        this.getCapiumImportSetting();
      }
    });
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  onModuleSelection(): void {
    if (this.selectedAddonId !== '0') {
      this.step = ImportStep.Finish;
      this.isFromCapium3 = true;
      this.selectedFile = File[0];
      this.fileInfo = '';
      this.myInputVariable.nativeElement.value = '';
    } else {
      this.step = ImportStep.Select;
      this.isFromCapium3 = false;
    }
  }

  setModuleName() {
    switch (this.moduleId) {
      case Modules.Clients:
        this.moduleName = ModuleName.Clients;
        break;
      // case Modules.Tasks:
      //   this.moduleName = ModuleName.Tasks;
      //   break;
      case Modules.Users:
        this.moduleName = ModuleName.Users;
        break;
      // case Modules.ExpenseType:
      //   this.moduleName = ModuleName.ExpenseType;
      //   break;
      default:
        break;
    }
  }

  getCapiumImportSetting(): void {
    this.store
      .dispatch(new GetCapiumImportSetting(this.moduleId!))
      .subscribe((res) => {
        let capiumSetting = res.importAll.capiumImportSetting;
        if (capiumSetting.isRecurringImport) {
          this.selectedAddonId = capiumSetting.addonId.toString();
        }
      });
  }

  retrieveSubscription(): void {
    this.store.dispatch(new RetrieveSubscription()).subscribe(
      (res) => {
        if (res.subscription.retrieveSubscriptionList) {
          let subscriptionModuleList =
            res.subscription.retrieveSubscriptionList.listOfAddons ?? [];
          subscriptionModuleList.forEach((element) => {
            if (
              element.isDisplay === true &&
              element.isDisabled === false &&
              element.addonId !== TicketModules.TimeandFees.toString()
            ) {
              this.subscriptionModuleList.push(element);
            }
          });
        } else {
          this.subscriptionModuleList = [];
        }
      },
      (error) => {
        this.subscriptionModuleList = [];
      }
    );
  }

  setScheduleForm(): void {
    this.formRecurringImport = this._formBuilder.group({
      recurringType: new FormControl<number | null>(2),
      scheduleSubPeriod: new FormControl<number | null>(1),
      dayOfWeek: new FormControl<number | null>(1),
      dayOfMonth: new FormControl<number | null>(1),
      separationCount: new FormControl<number | null>(1),
      maxNumOfOccurrences: new FormControl<number | null>(1),
      monthOfYear: new FormControl<number | null>(1),
      startDate: new FormControl<Date | null>(
        new Date(Date.now() + 3600 * 1000 * 24)
      ),
      endDate: new FormControl<Date | null>(new Date()),
      scheduleType: new FormControl<number | null>(0),
      timesheetDueTime: new FormControl('17:00'),
      estimatesAutoGenId: new FormControl<number | null>(1),
    });
  }

  formatBytes(bytes: number): string {
    const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const factor = 1024;
    let index = 0;
    while (bytes >= factor) {
      bytes /= factor;
      index++;
    }
    return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
  }

  onFileClick(event): void {
    event.target.value = '';
  }

  onFileSelected(input: HTMLInputElement): void {
    const file = input.files![0];
    this.selectedFileName = file.name;
    if (file.size / 1024 / 1024 > 10) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.fileSizeExceeds
      );
      return;
    }

    if (this.checkSpecialChar(file)) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.filenameWithSpecialCharacter
      );
      return;
    }

    this.fileInfo = `${file.name} (${this.formatBytes(file.size)})`;

    if (!input.files || !input.files.length) {
      return;
    }

    this.selectedFile = input.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(input.files[0]);
    reader.onload = () => {
      this.url = reader.result;
    };
    this.step = ImportStep.Finish;
    this.selectedAddonId = '0';
    this.isFromCapium3 = false;
  }

  checkSpecialChar(selectedFile: any): any {
    const format = /[!@#$%^&*:"<>?{}|]/;
    return format.test(selectedFile.name);
  }

  downloadTemplateFile(): void {
    this.spinner.show();
    if (this.moduleId) {
      this.store.dispatch(new GetImportTemplateAll(this.moduleId)).subscribe();
    } else {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.selectModule
      );
    }
  }

  onConnectclick() {
    this.showSalesTeam.emit(true);
    this.showImportSales = true;
  }

  ngOnDestroy(): void {
    this.importDetailSubscription?.unsubscribe();
    this.importSubscription?.unsubscribe();
  }

  checkValidFile(isFinalResponse: boolean): void {
    this.selectedClientTypes = [];
    this.selectedClientTypeItems?.forEach((element) => {
      this.selectedClientTypes.push(element.name);
    });

    if (this.isFromCapium3) {
      this.selectedClientTypes.push('Limited');
    }

    this.fileImportRequestModel = {
      file: this.selectedFile ?? null,
      step: isFinalResponse ? ImportStep.Finish : ImportStep.Upload,
      isFromCapium3: this.isFromCapium3,
      addonId: this.selectedAddonId,
      isRecurringImport: this.isRecurringImport,
      clientTypes: this.selectedClientTypes,
    };
    console.log(this.fileImportRequestModel, ' this.fileImportRequestModel ');
    this.spinner.show();
    this.store
      .dispatch(
        new ImportModulesAll(this.fileImportRequestModel, +this.moduleId!)
      )
      .subscribe(
        () => {
          if (!isFinalResponse) {
            const details: any = this.store.selectSnapshot(
              ImportStateAll.getImportData
            );
            this.spinner.hide();
            if (details.status === 'Failed' && details.status !== undefined) {
              this.commonService.onFailure(details.message);
            } else {
              this.loadData(details);
            }
          }
        },
        (error) => {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
          this.spinner.hide();
        }
      );
  }

  loadData(detail: any): void {
    switch (this.moduleId) {
      case Modules.Clients:
        this.loadClientDropdownValues(detail);
        break;

      case Modules.Users:
        this.loadUserDropdownValues(detail);
        break;

      default:
        break;
    }
  }

  loadClientDropdownValues(detail: any): void {
    this.getClientTypes();
    this.getVatScheme();
    this.getVatSubmitType();

    this.setData(detail);
  }

  loadUserDropdownValues(detail: any): void {
    this.store
      .dispatch(new GetUserPermissionsList())
      .pipe(
        tap(() => {
          this.permissionList = this.store.selectSnapshot(
            UserState.getUserPermissions
          )!;
          this.setData(detail);
        })
      )
      .subscribe();
  }

  setData(details: any): void {
    this.displayedColumns = details.columns;
    this.importSuccessList.data = details.successList;
    this.importErrorList.data = details.errorList;
    this.importDuplicateList.data = details.duplicateList;
    this.importedFile = details.importedFile;
    this.myStepper.next();
    this.commonService.onSuccess(NotificationTextMessage.fileUploaded);
  }

  checkClientNameValid(
    element: any,
    index: number,
    isFromError: boolean
  ): void {
    if (element.companyName) {
      if (element.isDuplicateClient && element.companyName) {
        element.isDuplicateClient = false;
      }
      if (!element.isDuplicateClient) {
        if (isFromError) {
          this.importErrorList.data.splice(index, 1);
          this.importErrorList.data = [...this.importErrorList.data];
          this.importErrorList = new MatTableDataSource(
            this.importErrorList.data
          );
        }

        this.checkDuplicateExist(
          element,
          index,
          isFromError,
          CheckDuplicateBy.Name
        );
      }
    }
  }

  checkPhoneNumber(element, index: number): void {
    if (element.isInValidPhone && element.phoneNumber) {
      const regex = /^[0-9]{10,15}$/;

      if (regex.test(element.phoneNumber)) {
        this.commonService.isPhoneValid = true;
      } else {
        this.commonService.isPhoneValid = false;
      }
    }

    if (element.phoneNumber !== '') {
      if (this.commonService.isPhoneValid) {
        element.isInValidPhone = false;
      } else {
        element.isInValidPhone = true;
      }
    }
    if (!element.isInValidPhone) {
      if (this.moduleId === Modules.Users) {
        this.checkUserValidElement(element, index);
      } else if (this.moduleId === Modules.Clients) {
        this.checkClientValidElement(element, index);
      }
    }
  }

  checkEmailValid(element: any, index: number): void {
    if (
      element.email !== null &&
      element.email !== undefined &&
      element.email !== ''
    ) {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (regex.test(element.email)) {
        element.isInValidEmail = false;
      } else {
        element.isInValidEmail = true;
      }
    }

    if (!element.isInValidEmail) {
      if (this.moduleId === Modules.Users) {
        this.checkUserValidElement(element, index);
      } else if (this.moduleId === Modules.Clients) {
        this.checkClientValidElement(element, index);
      }
    }
  }

  checkClientValidElement(
    element,
    index: number,
    isFromDuplicate?: boolean
  ): void {
    if (element.isInValidCompanyType && element.clientTypeId !== null) {
      element.isInValidCompanyType = false;
    }

    if (
      element.isInValidUtrnumber &&
      element.utrnumber &&
      !isNaN(element.utrnumber)
    ) {
      element.isInValidUtrnumber = false;
    }

    if (
      element.isInValidVatregNumber &&
      element.vatregNumber &&
      !isNaN(element.vatregNumber)
    ) {
      element.isInValidVatregNumber = false;
    }

    if (
      element.isInValidAccountOfficeRefNumber &&
      element.accountOfficeRefNumber
    ) {
      element.isInValidAccountOfficeRefNumber = false;
    }

    if (element.isInValidPayeRefNumber && element.payeRefNumber) {
      element.isInValidPayeRefNumber = false;
    }

    this.checkClientNationalInsuranceNo(element);

    if (element.isInValidVatschemeId && element.vatschemeId !== null) {
      element.isInValidVatschemeId = false;
    }

    if (element.isInValidVatsubmitTypeId && element.vatsubmitTypeId !== null) {
      element.isInValidVatsubmitTypeId = false;
    }

    if (element.isInValidContactName && element.contactName) {
      element.isInValidContactName = false;
    }

    if (element.isInValidBusinessStartDate && element.businessStartDate) {
      element.isInValidBusinessStartDate = false;
    }

    if (element.isInValidBookStartDate && element.bookStartDate) {
      element.isInValidBookStartDate = false;
    }

    if (element.isInValidVatsubmitTypeId && element.vatsubmitTypeId) {
      element.isInValidVatsubmitTypeId = false;
    }

    if (element.isInValidDateOfBirth && element.dateOfBirth) {
      element.isInValidDateOfBirth = false;
    }
    if (element.isInValidClientCode && element.clientCode) {
      if (
        !this.checkFieldsDuplicateByList(
          element,
          index,
          isFromDuplicate,
          CheckDuplicateBy.Code
        )
      ) {
        this.checkFieldsDuplicateByDB(
          element,
          index,
          isFromDuplicate,
          CheckDuplicateBy.Code
        );
      }
    }
    if (element.isInValidVatregistrationDate && element.vatregistrationDate) {
      element.isInValidVatregistrationDate = false;
    }
    if (element.isInValidCompanyName && element.companyName) {
      // this.checkClientNameValid(element, index, true)

      if (
        !this.checkFieldsDuplicateByList(
          element,
          index,
          isFromDuplicate,
          CheckDuplicateBy.Name
        )
      ) {
        this.checkFieldsDuplicateByDB(
          element,
          index,
          isFromDuplicate,
          CheckDuplicateBy.Name
        );
      }
    }
    if (element.isInValidRegistrationNumber && element.registrationNumber) {
      const regex = /^[0-9]{8,8}$/;

      if (regex.test(element.registrationNumber)) {
        element.isInValidRegistrationNumber = false;
      }
    }

    this.confirmClientValidElement(element, index, isFromDuplicate);
  }
  checkFieldsDuplicateByList(
    element,
    index: number,
    isFromDuplicate,
    checkBy?: number
  ): boolean {
    let errorList = this.importErrorList.data;
    let successList = this.importSuccessList.data;
    let duplicateList = this.importDuplicateList.data;

    switch (checkBy) {
      case CheckDuplicateBy.Code:
        if (isFromDuplicate) {
          if (
            errorList.some((item) => item.clientCode === element.clientCode) ||
            successList.some(
              (item1) => item1.clientCode === element.clientCode
            ) ||
            duplicateList.some(
              (item2, ind) =>
                item2.clientCode === element.clientCode && ind !== index
            )
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          if (
            errorList.some(
              (item, ind) =>
                item.clientCode === element.clientCode && ind !== index
            ) ||
            successList.some(
              (item1) => item1.clientCode === element.clientCode
            ) ||
            duplicateList.some(
              (item2) => item2.clientCode === element.clientCode
            )
          ) {
            return true;
          } else {
            return false;
          }
        }

        break;
      case CheckDuplicateBy.Name:
        if (isFromDuplicate) {
          if (
            errorList.some(
              (item) => item.companyName === element.companyName
            ) ||
            successList.some(
              (item1) => item1.companyName === element.companyName
            ) ||
            duplicateList.some(
              (item2, ind) =>
                item2.companyName === element.companyName && ind !== index
            )
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          if (
            errorList.some(
              (item, ind) =>
                item.companyName === element.companyName && ind !== index
            ) ||
            successList.some(
              (item1) => item1.companyName === element.companyName
            ) ||
            duplicateList.some(
              (item2) => item2.companyName === element.companyName
            )
          ) {
            return true;
          } else {
            return false;
          }
        }

        break;
    }
    return false;
  }

  checkFieldsDuplicateByDB(
    element,
    index: number,
    isFromDuplicate?: boolean,
    checkBy?: number
  ): void {
    const requestModel: DuplicateExistModel = {
      entityName: this.getEntityName(checkBy, element) ?? '',
      moduleId: this.moduleId!,
      entityId: this.commonService.defaultGuidValue,
      checkBy: checkBy,
    };
    this.store.dispatch(new IsDuplicateExist(requestModel)).subscribe((res) => {
      if (!res.company.isDuplicateExist) {
        if (checkBy === 1) {
          element.isInValidCompanyName = false;
        } else if (checkBy === 2) {
          element.isInValidClientCode = false;
        }

        this.confirmClientValidElement(element, index, isFromDuplicate);
      } else {
        this.onError();
      }
    });
  }
  checkClientNationalInsuranceNo(element): void {
    if (
      element.isInValidNationalInsuranceNo &&
      element.nationalInsuranceNo &&
      element.nationalInsuranceNo.length <=
        this.nationalInsuranceNumber.nativeElement.maxLength
    ) {
      element.isInValidNationalInsuranceNo = false;
    }
  }

  confirmClientValidElement(
    element,
    index: number,
    isFromDuplicate?: boolean
  ): void {
    if (
      !element.isInValidCompanyType &&
      !element.isInValidUtrnumber &&
      !element.isInValidVatregNumber &&
      !element.isInValidAccountOfficeRefNumber &&
      !element.isInValidPayeRefNumber &&
      !element.isInValidNationalInsuranceNo &&
      !element.isInValidVatschemeId &&
      !element.isInValidPhone &&
      !element.isInValidEmail &&
      !element.isInValidCompanyName &&
      !element.isInValidRegistrationNumber &&
      !element.isInValidPostalCode &&
      !element.isInValidContactName &&
      !element.isInValidClientCode &&
      !element.isInValidBusinessStartDate &&
      !element.isInValidBookStartDate &&
      !element.isInValidYearEndDate &&
      !element.isInValidYearEndMonth &&
      !element.isInValidVatschemeId &&
      !element.isInValidVatregNumber &&
      !element.isInValidVatregistrationDate &&
      !element.isInValidVatsubmitTypeId &&
      !element.isInValidAccountOfficeRefNumber &&
      !element.isInValidPayeRefNumber &&
      !element.isInValidNationalInsuranceNo &&
      !element.isInValidDateOfBirth
    ) {
      this.importErrorList.data.splice(index, 1);
      this.importErrorList.data = [...this.importErrorList.data];
      this.importErrorList = new MatTableDataSource(this.importErrorList.data);
      this.importSuccessList.data.push(element);
      this.importSuccessList.data = [...this.importSuccessList.data];
      this.importSuccessList = new MatTableDataSource(
        this.importSuccessList.data
      );

      if (isFromDuplicate) {
        this.importDuplicateList.data.splice(index, 1);
        this.importDuplicateList.data = [...this.importDuplicateList.data];
        this.importDuplicateList = new MatTableDataSource(
          this.importDuplicateList.data
        );
      }

      this.commonService.onSuccess(NotificationTextMessage.moveToSuccessList);
    }
  }

  checkNameValid(element: any, index: number): void {
    if (element.isInValidName && element.name) {
      element.isInValidName = false;
    }

    if (!element.isInValidName) {
      this.importErrorList.data.splice(index, 1);
      this.importErrorList.data = [...this.importErrorList.data];
      this.importErrorList = new MatTableDataSource(this.importErrorList.data);

      this.checkDuplicateExist(element, index, true, CheckDuplicateBy.Name);
    }
  }

  checkDuplicateExist(
    element,
    index: number,
    isFromError = false,
    checkBy?: number
  ): void {
    if (
      element.name !== '' ||
      element.email !== '' ||
      element.companyName !== ''
    ) {
      const requestModel: DuplicateExistModel = {
        entityName: this.getEntityName(checkBy, element) ?? '',
        moduleId: this.moduleId!,
        entityId: this.commonService.defaultGuidValue,
        checkBy: checkBy,
      };
      this.store
        .dispatch(new IsDuplicateExist(requestModel))
        .subscribe((res) => {
          if (!res.company.isDuplicateExist) {
            this.importSuccessList.data.push(element);
            this.importSuccessList.data = [...this.importSuccessList.data];
            this.importSuccessList = new MatTableDataSource(
              this.importSuccessList.data
            );

            if (!isFromError) {
              this.importDuplicateList.data.splice(index, 1);
              this.importDuplicateList.data = [
                ...this.importDuplicateList.data,
              ];
              this.importDuplicateList = new MatTableDataSource(
                this.importDuplicateList.data
              );
            }

            this.commonService.onSuccess(
              NotificationTextMessage.moveToSuccessList
            );
          } else {
            this.onError();

            if (isFromError) {
              this.importDuplicateList.data.push(element);
              this.importDuplicateList.data = [
                ...this.importDuplicateList.data,
              ];
              this.importDuplicateList = new MatTableDataSource(
                this.importDuplicateList.data
              );
            }
          }
        });
    }
  }

  getEntityName(checkBy, element) {
    let entityName = '';
    switch (checkBy) {
      case CheckDuplicateBy.Name:
        entityName =
          this.moduleId === Modules.Clients
            ? element.companyName
            : element.name;
        break;
      case CheckDuplicateBy.Code:
        entityName = element.clientCode;
        break;
      case CheckDuplicateBy.Email:
        entityName = element.email;
        break;
      default:
        break;
    }

    return entityName;
  }

  onError(): void {
    let errorMessage = '';
    switch (this.moduleId) {
      case Modules.Clients:
        errorMessage = NotificationTextMessage.clientExist;
        break;

      case Modules.Users:
        errorMessage = NotificationTextMessage.userExist;
        break;

      default:
        break;
    }

    this.commonService.onFailure(errorMessage);
  }

  checkUserValidElement(element: any, index: number): void {
    if (element.isInValidCapacity && element.capacity) {
      element.capacity = '00:00';
      element.isInValidCapacity = false;
    }

    if (
      element.isInValidBillableRate &&
      element.billableRate &&
      !isNaN(element.billableRate)
    ) {
      element.isInValidBillableRate = false;
    }

    if (
      element.isInValidCostRate &&
      element.costRate &&
      !isNaN(element.costRate)
    ) {
      element.isInValidCostRate = false;
    }

    if (element.isInValidFirstName && element.firstName) {
      element.isInValidFirstName = false;
    }

    if (element.isInValidMiddleName && element.middleName) {
      element.isInValidMiddleName = false;
    }

    if (element.isInValidLastName && element.lastName) {
      element.isInValidLastName = false;
    }

    if (
      !element.isInValidCapacity &&
      !element.isInValidBillableRate &&
      !element.isInValidCostRate &&
      !element.isInValidEmail &&
      !element.isInValidFirstName &&
      !element.isInValidMiddleName &&
      !element.isInValidLastName &&
      !element.isInValidPhone
    ) {
      this.importErrorList.data.splice(index, 1);
      this.importErrorList.data = [...this.importErrorList.data];
      this.importErrorList = new MatTableDataSource(this.importErrorList.data);

      this.checkDuplicateExist(element, index, true, CheckDuplicateBy.Email);
    }
  }

  checkTaskValidElement(element: any, index: number): void {
    if (
      element.isInValidFixedFeeAmount &&
      element.fixedFeeAmount &&
      !isNaN(element.fixedFeeAmount)
    ) {
      element.isInValidFixedFeeAmount = false;
    }

    if (
      element.isInValidTimeAndFeesAmount &&
      element.timeAndFeesAmount &&
      !isNaN(element.timeAndFeesAmount)
    ) {
      element.isInValidTimeAndFeesAmount = false;
    }

    if (element.isInValidEstimatedTime && element.estimatedTime) {
      element.isInValidEstimatedTime = false;
    }

    if (element.isInValidName && element.name) {
      element.isInValidName = false;
    }

    if (element.isInValidSubTask1Name && element.subTask1Name) {
      element.isInValidSubTask1Name = false;
    }

    if (element.isInValidSubTask2Name && element.subTask2Name) {
      element.isInValidSubTask2Name = false;
    }

    if (element.isInValidSubTask3Name && element.subTask3Name) {
      element.isInValidSubTask3Name = false;
    }

    if (element.isInValidSubTask4Name && element.subTask4Name) {
      element.isInValidSubTask4Name = false;
    }

    if (element.isInValidSubTask5Name && element.subTask5Name) {
      element.isInValidSubTask5Name = false;
    }

    if (element.isInValidSubTask6Name && element.subTask6Name) {
      element.isInValidSubTask6Name = false;
    }

    if (element.isInValidSubTask7Name && element.subTask7Name) {
      element.isInValidSubTask7Name = false;
    }

    if (element.isInValidSubTask8Name && element.subTask8Name) {
      element.isInValidSubTask8Name = false;
    }

    if (element.isInValidSubTask9Name && element.subTask9Name) {
      element.isInValidSubTask9Name = false;
    }

    if (element.isInValidSubTask10Name && element.subTask10Name) {
      element.isInValidSubTask10Name = false;
    }

    if (
      !element.isInValidFixedFeeAmount &&
      !element.isInValidTimeAndFeesAmount &&
      !element.isInValidEstimatedTime &&
      !element.isInValidName &&
      !element.isInValidSubTask1Name &&
      !element.isInValidSubTask2Name &&
      !element.isInValidSubTask3Name &&
      !element.isInValidSubTask4Name &&
      !element.isInValidSubTask5Name &&
      !element.isInValidSubTask6Name &&
      !element.isInValidSubTask7Name &&
      !element.isInValidSubTask8Name &&
      !element.isInValidSubTask9Name &&
      !element.isInValidSubTask10Name
    ) {
      this.importErrorList.data.splice(index, 1);
      this.importErrorList.data = [...this.importErrorList.data];
      this.importErrorList = new MatTableDataSource(this.importErrorList.data);

      this.checkDuplicateExist(element, index, true, CheckDuplicateBy.Name);
    }
  }

  onSelectionChange(data: string): void {
    if (data === '1') {
      this.isRecurringImport = false;
    } else {
      this.isRecurringImport = true;
    }
  }

  bindMonthList(): void {
    this.monthList = Array.from({ length: 12 }, (e, i) => {
      return new Date(0, i + 1, 0).toLocaleDateString('en', {
        month: 'long',
      });
    });
  }

  bindWeekList(): void {
    this.weekList = Array.from({ length: 7 }, (e, i) => {
      return new Date(0, 0, i).toLocaleDateString('en', {
        weekday: 'long',
      });
    });
  }

  bindDayList(): void {
    for (let i = 1; i <= 32; i++) {
      let label = '';
      if (i === 1) {
        label = 'First';
      } else if (i === 2) {
        label = 'Last';
      } else if (i === 3 || i === 23) {
        label = i - 2 + 'st';
      } else if (i === 4 || i === 24) {
        label = i - 2 + 'nd';
      } else if (i === 5 || i === 25) {
        label = i - 2 + 'rd';
      } else if (i >= 6) {
        label = i - 2 + 'th';
      }

      const obj = {
        value: i,
        lable: label,
      };
      this.dayList.push(obj);
    }
    this.formRecurringImport.controls.dayOfMonth.setValue(
      this.dayList[0].value
    );
  }

  createHours(): void {
    this.timeHours = [];
    for (let index = 1; index <= 24; index++) {
      if (index < 10) {
        this.timeHours.push({
          id: '0' + index + ':' + '00',
          value: '0' + index + ':' + '00',
        });
      } else {
        this.timeHours.push({
          id: index + ':' + '00',
          value: index + ':' + '00',
        });
      }
    }
  }

  getClientTypes(): void {
    this.clientTypeList = [
      {
        id: '1',
        name: 'Sole Trader',
      },
      {
        id: '2',
        name: 'Partnership',
      },
      {
        id: '3',
        name: 'Limited',
      },
      {
        id: '4',
        name: 'Trust',
      },
      {
        id: '5',
        name: 'Limited Liability Partnership',
      },
      {
        id: '6',
        name: 'Individual',
      },
    ];
  }

  getVatScheme(): void {
    this.store
      .dispatch(new GetVatSchemeList())
      .pipe(
        tap(() => {
          this.vatSchemeList = this.store.selectSnapshot(
            CompanyState.getVatSchemesList
          );
        })
      )
      .subscribe();
  }

  getVATSubmitValue(item: string): string {
    let vatype = '';
    switch (item) {
      case '1':
        vatype = 'Monthly';
        break;
      case '2':
        vatype = 'Quarterly';
        break;
      case '3':
        vatype = 'Yearly';
        break;
      default:
        vatype = '';
    }
    return vatype;
  }

  getVatSubmitType(): void {
    this.store
      .dispatch(new GetVatReturnTypeList())
      .pipe(
        tap((res) => {
          this.vatSubmitTypeList = res.company.vatReturnTypeList;
        })
      )
      .subscribe();
  }

  bindYearEndDayList(): void {
    for (let i = 1; i <= 31; i++) {
      const obj = {
        value: i,
        lable: i,
      };
      this.yearEndDayList.push(obj);
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\()\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateInput(event: any): void {
    const pastedValue = (
      event.clipboardData || window['clipboardData']
    ).getData('text');
    if (!pastedValue.match(/^[\d()+\- ]+$/)) {
      event.preventDefault();
    }
  }
  showVat(ele: string): boolean {
    return ele === 'Yes' ? true : false;
  }
  getVatName(id: number): string {
    const obj = this.vatSchemeList.find((item: any) => item.id === +id);
    return obj ? obj['name'] : '';
  }
}
