<div class="d-flex h-100">
  <app-side-list
    [getModuleId]="moduleId"
    [getModuleName]="moduleName"
    (showSideListAction)="showSideListAction($event)"
    (toggleSideList)="toggleSideList($event)"
    [reloadSideList]="reloadSideList"
  ></app-side-list>
  <div
    class="form mb-4"
    [ngClass]="istoggleSideList ? 'hide-sidelist' : ''"
    [hidden]="isShowSideListAction"
  >
    <div class="content-body-wrapper">
      <div class="content-body">
        <div>
          <p
            class="header-text d-flex justify-space-between align-items-center"
          >
            <span>
              {{
                budgetingId === commonService.defaultGuidValue
                  ? "Add New Budget"
                  : "Edit Budget"
              }}
            </span>
            <button
              mat-button
              class="header-text-close d-flex align-items-center"
              disableRipple
              (click)="onCloseClick()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </p>
          <div class="alert-message" *ngIf="isViewPermission$ | async">
            <mat-icon class="material-icons-outlined">error_outline</mat-icon>
            {{ commonNotificationText.ViewOnlyAccessMessage }}
          </div>
          <div class="accordion-toggle">
            <mat-icon
              (click)="toggleAccordion(); accordion.openAll()"
              *ngIf="isExpandAll"
              >keyboard_double_arrow_down
            </mat-icon>
            <mat-icon
              (click)="toggleAccordion(); accordion.closeAll()"
              *ngIf="!isExpandAll"
              >keyboard_double_arrow_up
            </mat-icon>
          </div>
          <mat-accordion [multi]="true">
            <mat-expansion-panel
              [expanded]="true"
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >description</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Budgeting Details</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-add-budgeting
                (triggerFilterData)="triggerGetDataList($event)"
                [triggerEditData]="triggerEditData"
              ></app-add-budgeting>
            </mat-expansion-panel>
            <mat-expansion-panel
              [ngClass]="[
                budgetingId === tempBudgetingId ? 'hidden' : '',
                (isViewPermission$ | async) ? 'is-readonly' : ''
              ]"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >receipt</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Budgeting List</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-budgeting-detail-list
                [triggerGetList]="triggerGetList"
                [budgetingDetails]="budgetingDetails"
                [budgetingId]="budgetingId"
              >
              </app-budgeting-detail-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
    <app-buttons
      *ngIf="!(isViewPermission$ | async)"
      [getModuleId]="moduleId"
      (triggerOnSaveClick)="onSave($event)"
      (triggerOnCancelClick)="onCancel(true)"
    >
    </app-buttons>
  </div>
</div>
