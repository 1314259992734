import { Guid } from 'guid-typescript';

export class BudgetingModel {
  id?: Guid;
  name: string;
  startPeriod: any;
  forPeriod: number;
  actual: number;
  grid: Array<any>;
  branchId?: Guid | null;
  departmentId?: Guid | null;
}
