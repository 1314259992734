<div class="content-body">
  <form [formGroup]="creditNoteForm">
    <div class="d-flex gap-40">
      <div class="form-field fund-name" *ngIf="branchList.length > 0">
        <p>Branch <span class="text-danger-300">*</span></p>
        <ng-select
          #branch
          (focus)="branch.open()"
          (valueChange)="branch.close()"
          [disableOptionCentering]="true"
          formControlName="branchId"
          dropdownPosition="bottom"
          (change)="onChangeBranch($event, false)"
        >
          <ng-option *ngFor="let bl of branchList" [value]="bl.id">
            <div title="{{ bl.name }}">{{ bl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="departmentList.length > 0">
        <p>Department <span class="text-danger-300">*</span></p>
        <ng-select
          #department
          (focus)="department.open()"
          (valueChange)="department.close()"
          [disableOptionCentering]="true"
          formControlName="departmentId"
          dropdownPosition="bottom"
        >
          <ng-option *ngFor="let dl of departmentList" [value]="dl.id">
            <div title="{{ dl.name }}">{{ dl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Credit Note No <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="creditNoteNo"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength100 }}"
            appAutoFocusDirective
          />
        </mat-form-field>
      </div>
      <div class="form-field datepicker">
        <p>Date <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="entryDate"
            [matDatepicker]="entryDate"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            tabindex="-1"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="entryDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #entryDate></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(creditNoteForm.get('entryDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(creditNoteForm.get('entryDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Customer Name <span class="text-danger-300">*</span></p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isCustomerChangePermission"
            >
              <mat-icon (click)="gotoAddCustomer()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="disabledEditButton || !isCustomerChangePermission"
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditCustomer()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #customerName
          [disableOptionCentering]="true"
          (focus)="customerName.open()"
          (valueChange)="customerName.close()"
          formControlName="customerName"
          panelClass="mat-select-position"
          (change)="onCustomerChange(); this.disabledEditButton = false"
        >
          <ng-option *ngFor="let option of customerList" [value]="option.id">
            <div title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>Invoice Ref No</p>
        <mat-form-field>
          <input
            matInput
            formControlName="invoiceRefNo"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength100 }}"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field" style="display:none;">
        <p>Account Name <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="fill">
          <input
            type="text"
            matInput
            formControlName="creditNoteType"
            
            [matAutocomplete]="accountGroup"
            (keyup)="onAccountSearch($event)"
            (keydown.Tab)="onSearch($event)"
            (change)="onSearch($event)"
            #trigger="matAutocompleteTrigger"
            appAutocompletePosition="trigger"
            (mousedown)="resetAccountList()"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete
            [displayWith]="getOptionText"
            #accountGroup="matAutocomplete"
            (opened)="
              scrollIntoView(
                this.creditNoteForm.controls.creditNoteType.value.id
              )
            "
            (optionSelected)="onOptionSelected($event)"
          >
            <mat-optgroup
              *ngFor="let group of creditNoteTypeList"
              [label]="group.groupName"
              title="{{ group.groupName }}"
            >
              <mat-option
                *ngFor="let names of group.listModels"
                [value]="names"
                title="{{ names.name }}"
                [class]="
                  this.creditNoteForm.controls.creditNoteType.value?.id ===
                  names.id
                    ? 'mat-selected'
                    : ''
                "
              >
                {{ names.name }}
              </mat-option>
            </mat-optgroup>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Currency <span class="text-danger-300">*</span></p>
        <ng-select
          #selectCurrency
          (focus)="selectCurrency.open()"
          (valueChange)="selectCurrency.close()"
          [disableOptionCentering]="true"
          formControlName="currency"
          panelClass="mat-select-position"
          (change)="currencyChange($event)"
        >
          <ng-option
            *ngFor="let cl of clientCurrencyList"
            [value]="cl.currencyId"
          >
            <div title="{{ cl.currency }}">
              {{ cl.currency }}
            </div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="isMultiCurrencySelected">
        <p>&nbsp;</p>
        <mat-form-field class="w-140 big-inputbox">
          <input
            matInput
            formControlName="currencyAmt"
            allowDecimal
            autocomplete="off"
            (change)="onCurrencyRateEntered()"
          />
          <button
            mat-button
            type="button"
            disableRipple
            matSuffix
            (click)="onCurrencyRefresh()"
          >
            <mat-icon>refresh</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex mr-40">
      <div
        class="form-field flex-column justify-contents-center"
        *ngIf="isVatRegistered"
      >
        <div>
          <mat-checkbox
            formControlName="isVatInclude"
            (change)="onCheckBoxSelected($event)"
          >
            Amount Including VAT
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox
            formControlName="isVatPostponed"
            (change)="isVatPostponedChecked($event)"
          >
            Apply CIS VAT Domestic Reverse Charge
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="d-flex mr-40">
      <div *ngIf="this.isFromBankImport" class="form-field">
        <p>Import Bank payment</p>
        <mat-form-field>
          <input matInput formControlName="amountReceived" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>
    <div class="form-field textarea">
      <p>Notes</p>
      <mat-form-field>
        <textarea
          matInput
          formControlName="note"
          autocomplete="off"
          maxlength="{{ maxLength.maxLength2000 }}"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
