import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonEmitterService implements OnDestroy {
  setUserListEmitter = new EventEmitter();
  setClientCurrencyListEmitter = new EventEmitter();
  subscriptionVariable: Subscription;

  setUserList(data: boolean) {
    this.setUserListEmitter.emit(data);
  }
  setClientCurrencyList(data: boolean) {
    this.setClientCurrencyListEmitter.emit(data);
  }

  ngOnDestroy(): void {
    if (this.subscriptionVariable) {
      this.subscriptionVariable.unsubscribe();
    }
  }
}
