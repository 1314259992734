import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { NotificationTextMessage } from '@app/core/Enum';
import { MenuState } from '@app/core/Store';
import { CapiumpayDialogComponent } from '@app/modules/common/capiumpay-dialog/capiumpay-dialog.component';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DailyDialogComponent } from './daily-dialog/daily-dialog.component';
export interface PeriodicElement {
  reference: string;
  paid: string;
  iddate: string;
  customer: string;
  method: string;
  charged: string;
  fee: string;
  net: string;
  chargedon: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    reference: 'INV-3',
    paid: 'Paid into bank',
    iddate: 'ID: 260536 on 14/04/2023',
    customer: 'srikanth.g@capium.com',
    method: '****-1774',
    charged: '£1.20',
    fee: '-£0.23',
    net: '£0.97',
    chargedon: '04/04/2023',
  },
  {
    reference: 'INV-3',
    paid: 'Paid into bank',
    iddate: 'ID: 260536 on 14/04/2023',
    customer: 'srikanth.g@capium.com',
    method: '****-1774',
    charged: '£1.20',
    fee: '-£0.23',
    net: '£0.97',
    chargedon: '04/04/2023',
  },
  {
    reference: 'INV-3',
    paid: 'Paid into bank',
    iddate: 'ID: 260536 on 14/04/2023',
    customer: 'srikanth.g@capium.com',
    method: '****-1774',
    charged: '£1.20',
    fee: '-£0.23',
    net: '£0.97',
    chargedon: '04/04/2023',
  },
  {
    reference: 'INV-3',
    paid: 'Paid into bank',
    iddate: 'ID: 260536 on 14/04/2023',
    customer: 'srikanth.g@capium.com',
    method: '****-1774',
    charged: '£1.20',
    fee: '-£0.23',
    net: '£0.97',
    chargedon: '04/04/2023',
  },
  {
    reference: 'INV-3',
    paid: 'Paid into bank',
    iddate: 'ID: 260536 on 14/04/2023',
    customer: 'srikanth.g@capium.com',
    method: '****-1774',
    charged: '£1.20',
    fee: '-£0.23',
    net: '£0.97',
    chargedon: '04/04/2023',
  },
];

export interface PeriodicElement {
  reference: string;
  paid: string;
  iddate: string;
  customer: string;
  method: string;
  charged: string;
  fee: string;
  net: string;
  chargedon: string;
}

const disputes_DATA: PeriodicElement[] = [
  {
    reference: 'INV-3',
    paid: 'Paid into bank',
    iddate: 'ID: 260536 on 14/04/2023',
    customer: 'srikanth.g@capium.com',
    method: '****-1774',
    charged: '£1.20',
    fee: '-£0.23',
    net: '£0.97',
    chargedon: '04/04/2023',
  },
  {
    reference: 'INV-3',
    paid: 'Paid into bank',
    iddate: 'ID: 260536 on 14/04/2023',
    customer: 'srikanth.g@capium.com',
    method: '****-1774',
    charged: '£1.20',
    fee: '-£0.23',
    net: '£0.97',
    chargedon: '04/04/2023',
  },
  {
    reference: 'INV-3',
    paid: 'Paid into bank',
    iddate: 'ID: 260536 on 14/04/2023',
    customer: 'srikanth.g@capium.com',
    method: '****-1774',
    charged: '£1.20',
    fee: '-£0.23',
    net: '£0.97',
    chargedon: '04/04/2023',
  },
  {
    reference: 'INV-3',
    paid: 'Paid into bank',
    iddate: 'ID: 260536 on 14/04/2023',
    customer: 'srikanth.g@capium.com',
    method: '****-1774',
    charged: '£1.20',
    fee: '-£0.23',
    net: '£0.97',
    chargedon: '04/04/2023',
  },
  {
    reference: 'INV-3',
    paid: 'Paid into bank',
    iddate: 'ID: 260536 on 14/04/2023',
    customer: 'srikanth.g@capium.com',
    method: '****-1774',
    charged: '£1.20',
    fee: '-£0.23',
    net: '£0.97',
    chargedon: '04/04/2023',
  },
];

@Component({
  selector: 'app-integration-settings',
  templateUrl: './integration-settings.component.html',
  styleUrls: ['./integration-settings.component.scss'],
})
export class IntegrationSettingsComponent implements OnInit {
  isExpandAll = false;
  isRadioChecked = false;
  showForm = true;
  showGmailAccounts = false;
  showOutlookAccounts = false;
  capiumPayList: any;
  userMailList: any;
  userOutlookList: any;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  displayGmailColumns: string[] = ['EmailId', 'Date', 'Default', 'Action'];
  displayStripColumns: string[] = ['EmailId', 'Date', 'Default', 'Action'];
  constructor(public dialog: MatDialog) {}
  displayedPaymentColumns: string[] = [
    'reference',
    'paid',
    'iddate',
    'customer',
    'method',
    'charged',
    'fee',
    'net',
    'chargedon',
  ];
  paymentdataSource = ELEMENT_DATA;

  displayedDisputesColumns: string[] = [
    'reference',
    'paid',
    'iddate',
    'customer',
    'method',
    'charged',
    'fee',
    'net',
    'chargedon',
  ];
  disputesdataSource = disputes_DATA;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  isPermission: boolean = false;
  ngOnInit(): void {}
  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }
  onChange(showSection): void {
    this.isRadioChecked = true;
    this.showForm = showSection;
  }
  showGmailAccount() {
    this.showGmailAccounts = !this.showGmailAccounts;
  }
  showOutlookAccount() {
    this.showOutlookAccounts = !this.showOutlookAccounts;
  }

  capiumPayIntegration() {
    const dialogRef = this.dialog.open(CapiumpayDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  onDailyclick() {
    const dialogRef = this.dialog.open(DailyDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
