<div class="header-actions">
  <div class="content-header mb-15">
    <div
      class="content-header-input"
      [ngClass]="customDate ? 'content-header-custom-date' : ''"
    >
      <div class="d-flex justify-space-between wp-100">
        <div
          class="d-flex wp-100 gap-10 header-filters header-count header-count-{{
            (headerList$ | async).length
          }}"
        >
          <div
            *ngFor="let item of headerList$ | async; let i = index"
            class="{{ item.name }}"
            [ngClass]="
              item.name === 'Datetime' && customDate ? 'custom-date' : ''
            "
          >
            <mat-form-field
              *ngIf="item.controlType === controlType.TextBox"
              appearance="fill"
              class="search-field"
            >
              <input
                matInput
                placeholder="Search here..."
                id="{{ item.id }}"
                autocomplete="off"
                (keyup)="onSearch($event)"
              />
              <button mat-button class="search-button">
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
            <div
              *ngIf="item.controlType === controlType.DateTimePicker"
              class="year-picker"
            >
              <app-custom-year-picker
                [moduleId]="moduleId"
                [triggerResetDetailList]="triggerResetDetailList"
                (triggerDateChange)="triggerDateChange($event)"
              ></app-custom-year-picker>
            </div>
            <div
              class="content-right-button d-flex align-items-center gap-10"
              *ngIf="item.controlType === controlType.Dropdown"
            >
              <mat-form-field>
                <mat-select
                  panelClass="mat-select-position"
                  [disableOptionCentering]="true"
                  [(value)]="
                    item.name === HeaderFilters.Module
                      ? commonService.defaultGuidValue
                      : defaultValue[i].id
                  "
                  (selectionChange)="onDropdownChange(item.name, $event.value)"
                >
                  <mat-option *ngFor="let cl of item.value" [value]="cl.id">
                    <span title="{{ cl.name }}">{{ cl.name }}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="toolbar" *ngIf="detailListId === mtd.SubmitVat">
        <button mat-button [matMenuTriggerFor]="menu" disableRipple>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu
          #menu="matMenu"
          class="action-menu more-menu"
          xPosition="before"
        >
          <button
            mat-menu-item
            (click)="export(exportType.Excel, false)"
            disableRipple
          >
            <mat-icon>border_all</mat-icon>Export to excel
            {{ showAccountDetails }}
          </button>
          <button
            mat-menu-item
            (click)="export(exportType.CSV, false)"
            disableRipple
          >
            <mat-icon fontSet="material-icons-outlined">description</mat-icon
            >Export to CSV
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
