import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit, Renderer2 } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { CustomEmail } from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSummernoteModule } from 'ngx-summernote';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-email-popup',
  templateUrl: './email-popup.component.html',
  styleUrls: ['./email-popup.component.scss'],
})
export class EmailPopupComponent implements OnInit {
  emailForm: FormGroup;
  fb: FormBuilder = new FormBuilder();
  moduleName = '';
  moduleId = 0;
  subscriptionRouting: Subscription;
  emailArray: any;
  emailData: any;

  configsummernote: NgxSummernoteModule = {
    placeholder: '',
    tabsize: 2,
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table']],
    ],
    fontNames: [
      'Helvetica',
      'Arial',
      'Arial Black',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
    ],
  };

  content = '';
  ids;

  maxLength = MaxLength;
  public Editor = ClassicEditor;

  removable = true;
  public emailList = [];
  public ccList = [];
  public bccList = [];
  public separatorKeysCodes = [ENTER, COMMA];
  commonService: CommonService;
  store: Store;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<EmailPopupComponent>,
    private renderer: Renderer2,
    private injector: Injector
  ) {
    this.commonService = injector.get<CommonService>(CommonService);
    this.store = injector.get<Store>(Store);
  }

  ngOnInit(): void {
    this.setForm();
    this.ids = this.data.ids;

    switch (this.data.moduleId) {
      case Modules.Receipt:
        this.moduleId = Modules.Receipt;
        this.moduleName = ModuleName.Receipt;
        break;

      case Modules.Invoices:
        this.moduleId = Modules.Invoices;
        this.moduleName = ModuleName.Invoices;
        break;

      case Modules.Quotation:
        this.moduleId = Modules.Quotation;
        this.moduleName = ModuleName.Quotation;
        break;

      case Modules.CreditNote:
        this.moduleId = Modules.CreditNote;
        this.moduleName = ModuleName.CreditNote;
        break;

      case Modules.DebitNote:
        this.moduleId = Modules.DebitNote;
        this.moduleName = ModuleName.DebitNote;
        break;
    }
  }

  removeEmail(data: any, type): void {
    if (type === 1) {
      if (this.emailList.indexOf(data) >= 0) {
        this.emailList.splice(this.emailList.indexOf(data), 1);
      }
    } else if (type === 2) {
      if (this.ccList.indexOf(data) >= 0) {
        this.ccList.splice(this.ccList.indexOf(data), 1);
      }
    }
    if (this.bccList.indexOf(data) >= 0) {
      this.bccList.splice(this.bccList.indexOf(data), 1);
    }
  }

  add(event, type): void {
    if (event.value) {
      if (type === 1) {
        if (this.validateEmail(event.value)) {
          this.emailList.push({ value: event.value, invalid: false });
        } else {
          this.emailList.push({ value: event.value, invalid: true });
        }
      } else if (type === 2) {
        if (this.validateEmail(event.value)) {
          this.ccList.push({ value: event.value, invalid: false });
        } else {
          this.ccList.push({ value: event.value, invalid: true });
        }
      } else {
        if (this.validateEmail(event.value)) {
          this.bccList.push({ value: event.value, invalid: false });
        } else {
          this.bccList.push({ value: event.value, invalid: true });
        }
      }
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  private validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false },
      };
    }
    return null;
  }

  private validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  setForm(): void {
    this.emailForm = this.fb.group({
      to: this.fb.array(
        [],
        new FormControl([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ])
      ),
      cc: this.fb.array([], new FormControl()),
      bcc: this.fb.array([], new FormControl()),
      subject: new FormControl(null, Validators.required),
      content: new FormControl(),
    });
  }

  dataSubmit(): any {
    if (
      this.emailForm.controls.content.value === null ||
      this.emailForm.controls.content.value === undefined ||
      this.emailForm.controls.content.value === ''
    ) {
      this.commonService.onFailure(NotificationTextMessage.emptyBody);
      return false;
    }

    if (
      this.ccList.filter((x: any) =>
        this.emailList.some((y: any) => y.value === x.value)
      ).length !== 0 ||
      this.bccList.filter((x: any) =>
        this.emailList.some((y: any) => y.value === x.value)
      ).length !== 0 ||
      this.ccList.filter((x: any) =>
        this.bccList.some((y: any) => y.value === x.value)
      ).length !== 0
    ) {
      this.commonService.onFailure(NotificationTextMessage.duplicateMail);
      return false;
    }

    if (
      this.emailList.filter((x: any) => x.invalid === true).length === 0 &&
      this.ccList.filter((x: any) => x.invalid === true).length === 0 &&
      this.bccList.filter((x: any) => x.invalid === true).length === 0
    ) {
      this.spinner.show();
      const toEmailList: any[] = [];
      const ccEmailList: any[] = [];
      const bccEmailList: any[] = [];
      this.emailList.forEach((element: any) => {
        toEmailList.push(element.value);
      });
      this.ccList.forEach((element: any) => {
        ccEmailList.push(element.value);
      });
      this.bccList.forEach((element: any) => {
        bccEmailList.push(element.value);
      });

      try {
        const emailObj: any = {
          toEmail: toEmailList,
          ccEmail: ccEmailList,
          bccEmail: bccEmailList,
          subject: this.emailForm.controls.subject.value,
          message: this.emailForm.controls.content.value,
          ids: this.ids,
        };

        this.emailData = <JSON>emailObj;
        return true;
      } catch (error) {
        this.spinner.hide();
        this.commonService.onFailure(NotificationTextMessage.errorMessage);
        return false;
      }
    } else {
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
  }

  onSave(): void {
    if (this.emailForm.invalid) {
      this.commonService.addValidation(this.emailForm, this.renderer);
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CustomEmail(this.moduleId, this.emailData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.onCancel(true);
                this.commonService.onSuccess(
                  NotificationTextMessage.mailSentSuccessfully
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.error.Message);
            }
          );
      }
    }
  }

  onCancel(res: boolean): void {
    this.dialogRef.close(res);
  }
}
