import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DataType } from '@app/core/Enum';
import { MainListParameters } from '@app/core/Models';
import { CashFlowTransaction, ReportState } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-cash-flow-details',
  templateUrl: './cash-flow-details.component.html',
  styleUrls: ['./cash-flow-details.component.scss'],
})
export class CashFlowDetailsComponent implements OnInit {
  headerText: string = '';
  dataSource = new MatTableDataSource();

  @Select(ReportState.columns)
  columns$: Observable<
    Array<{
      name: string;
      value: string;
      dataType?: DataType;
      allowSortBy?: boolean;
    }>
  >;

  @Select(ReportState.totalRecord)
  totalRecord$: Observable<number>;

  @Select(ReportState.columnNames)
  columnsNames$: Observable<Array<string>>;

  listParameters: MainListParameters = new MainListParameters();
  triggerPaginationChange: Subject<any> = new Subject<any>();
  isRowHighlighted = false;
  mainList: any;
  noDataFound = false;
  dataType = DataType;
  showPaginator = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialog: MatDialogRef<CashFlowDetailsComponent>,
    private datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.headerText = this.dialogData.data.name;
    this.listParameters = this.dialogData.listParameters;
    this.getList();
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.getList();
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.getList();
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  getList(): void {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids:
        this.listParameters.ids !== undefined &&
        this.listParameters.ids.length > 0
          ? this.listParameters.ids
          : null,
      branchIds: this.listParameters.branchIds ?? [],
      departmentIds: this.listParameters.departmentIds ?? [],
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    this.spinner.show();

    this.store
      .dispatch(
        new CashFlowTransaction(queryParams, this.dialogData.data.tagName)
      )
      .subscribe((res) => {
        this.mainList = res.report.cashFlowDetails.resultSet.data;
        this.noDataFound = this.mainList.length === 0;
        this.dataSource.data = this.mainList;
        const param = {
          pageSize: this.listParameters.pageSize,
          totalRecord: res.report.totalRecord,
        };
        this.triggerPaginationChange.next(param);
      });
  }

  onCloseClick(): void {
    this.dialog.close();
  }
}
