import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { InventoryLossOfStockService } from '@app/core/Services';
import {
  DeleteInventoryLossOfStock,
  GetInventoryLossOfStock,
  SaveInventoryLossOfStock,
} from './inventoryLossOfStock.action';

export class InventoryLossOfStockStateInfo {
  id?: number;
  isAdded: boolean;
  inventoryLossOfStockData?: any;
}

@State<InventoryLossOfStockStateInfo>({
  name: 'inventoryLossOfStock',
})
@Injectable()
export class InventoryLossOfStockState {
  constructor(
    private inventoryLossOfStockService: InventoryLossOfStockService
  ) {}

  @Action(SaveInventoryLossOfStock)
  saveInventoryLossOfStock(
    { patchState }: StateContext<InventoryLossOfStockStateInfo>,
    action: SaveInventoryLossOfStock
  ) {
    return this.inventoryLossOfStockService
      .saveInventoryLossOfStock(action.data)
      .pipe(
        tap((res) => {
          patchState({
            id: res,
            isAdded: true,
          });
        })
      );
  }

  @Action(GetInventoryLossOfStock)
  getInventoryLossOfStock(
    { patchState }: StateContext<InventoryLossOfStockStateInfo>,
    action: GetInventoryLossOfStock
  ) {
    return this.inventoryLossOfStockService
      .getInventoryLossOfStock(action.id)
      .pipe(
        tap((res) => {
          patchState({
            inventoryLossOfStockData: res,
          });
        })
      );
  }

  @Action(DeleteInventoryLossOfStock)
  deleteInventoryLossOfStock(
    { getState, patchState }: StateContext<InventoryLossOfStockStateInfo>,
    action: DeleteInventoryLossOfStock
  ) {
    return this.inventoryLossOfStockService
      .deleteInventoryLossOfStock(action.ids)
      .pipe(tap((res) => {}));
  }
}
