<div class="content-header">
  <div
    class="content-header-input"
    [ngClass]="{
      'content-header-custom-date': customDate,
      'd-flex justify-content-end mb-1':
        (moduleId$ | async) === modules.FixedAssetsRegister
    }"
  >
    <div
      class="toolbar mr-10"
      *ngIf="
        (moduleId$ | async) !== moduleEnum.Inventory &&
        this.moduleId !== moduleEnum.DisposeTangibleFixedAssets &&
        this.moduleId !== moduleEnum.DisposeLeasedFixedAssets &&
        this.moduleId !== moduleEnum.DisposeIntangibleFixedAssets
      "
    >
      <button matTooltip="Refresh" mat-button (click)="selectCustomDate()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div
      class="d-flex justify-space-between wp-100"
      *ngIf="
        this.moduleId !== moduleEnum.DisposeTangibleFixedAssets &&
        this.moduleId !== moduleEnum.DisposeLeasedFixedAssets &&
        this.moduleId !== moduleEnum.DisposeIntangibleFixedAssets
      "
    >
      <button
        class="action-button primary-button"
        mat-button
        disableRipple
        (click)="onAddClick()"
        *ngIf="
          detailListId !== mtd.Obligations &&
          detailListId !== mtd.ViewVATReturn &&
          detailListId !== mtd.RetrieveVATLiabilities &&
          detailListId !== mtd.RetrieveVATPayments
        "
      >
        <mat-icon>add</mat-icon>
        <span>Add {{ moduleName }}</span>
      </button>
      <div
        class="d-flex wp-100 header-filters  header-count header-count-{{
          (headerList$ | async).length
        }} gap-15"
      >
        <div
          *ngFor="let item of headerList$ | async; let i = index"
          class="{{ item.name }}"
          [ngClass]="
            item.name === 'Datetime' && customDate ? 'custom-date' : ''
          "
        >
          <mat-form-field
            *ngIf="item.controlType === controlType.TextBox"
            appearance="fill"
            class="search-field"
          >
            <input
              matInput
              placeholder="Search here..."
              autocomplete="off"
              [(ngModel)]="searchText"
              (ngModelChange)="onSearchAll()"
              (keyup.enter)="onSearch()"
            />
            <button mat-button class="search-button" (click)="onSearch()">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
          <div
            *ngIf="item.controlType === controlType.DateTimePicker"
            class="year-picker"
          >
            <app-custom-year-picker
              [moduleId]="moduleId"
              [triggerResetDetailList]="triggerResetDetailList"
              (triggerDateChange)="triggerDateChange($event)"
            ></app-custom-year-picker>
          </div>
          <div
            class="content-right-button d-flex align-items-center"
            *ngIf="item.controlType === controlType.Dropdown"
          >
            <span class="fw-bold"> Select Periodic: </span>
            <mat-form-field
              [ngClass]="[
                (moduleId$ | async) === modules.BankFeed ? 'w-200' : '',
                (moduleId$ | async) === modules.AddStandardAccounts
                  ? 'w-200'
                  : ''
              ]"
            >
              <mat-select
                panelClass="mat-select-position"
                [disableOptionCentering]="true"
                [(value)]="
                  item.name === HeaderFilters.Module
                    ? commonService.defaultGuidValue
                    : defaultValue[i].id
                "
                (selectionChange)="onDropdownChange(item.name, $event.value)"
              >
                <mat-option *ngFor="let cl of item.value" [value]="cl.id">
                  <span title="{{ cl.name }}">{{ cl.name }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div
      class="toolbar"
      *ngIf="detailListModulesId !== detailListModules.Inventory"
    >
      <button mat-button [matMenuTriggerFor]="menu" disableRipple>
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu
        #menu="matMenu"
        class="action-menu more-menu"
        xPosition="before"
      >
        <button
          mat-menu-item
          (click)="export(exportType.Excel, false)"
          disableRipple
        >
          <mat-icon>border_all</mat-icon>Export to excel
          {{ showAccountDetails }}
        </button>
        <button
          mat-menu-item
          (click)="export(exportType.CSV, false)"
          disableRipple
        >
          <mat-icon fontSet="material-icons-outlined">description</mat-icon
          >Export to CSV
        </button>
      </mat-menu>
    </div>
  </div>
</div>
