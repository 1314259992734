<div class="content-body-scroll">
  <div class="content-body">
    <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
      <mat-icon class="material-icons-outlined">error_outline</mat-icon>
      {{ commonNotificationText.ViewOnlyAccessMessage }}
    </div>
    <div class="accordion-toggle">
      <mat-icon
        (click)="toggleAccordion(); accordion.openAll()"
        *ngIf="isExpandAll"
        >keyboard_double_arrow_down
      </mat-icon>
      <mat-icon
        (click)="toggleAccordion(); accordion.closeAll()"
        *ngIf="!isExpandAll"
        >keyboard_double_arrow_up
      </mat-icon>
    </div>
    <mat-accordion [multi]="true">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title> Email </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-body pt-0">
          <h2 class="common-title font-size-16">
            Default method to send email
          </h2>
          <form [formGroup]="emailIntegrationForm">
            <div class="form-field horizontal-radio-button">
              <mat-radio-group formControlName="isEmailSendFromCapium">
                <mat-radio-button
                  value="true"
                  (change)="onChange(true)"
                  [checked]="true"
                >
                  <h4>
                    Capium’s Company
                    <span *ngIf="showForm"> (Current Method)</span>
                  </h4>
                  <h5>
                    Emails will be sent from capium's Company for which your
                    clients cannot reply.
                  </h5>
                </mat-radio-button>
                <mat-radio-button value="false" (change)="onChange(false)">
                  <h4>
                    Gmail, Outlook<span *ngIf="!showForm">
                      (Current Method)</span
                    >
                  </h4>
                  <h5>
                    Connect your Gmail or Outlook account and emails to your
                    clients will be sent from that address. Each email will
                    appear in the “Sent” folder in Gmail or Outlook.
                  </h5>
                </mat-radio-button>
              </mat-radio-group>
              <h5 class="pl-2">
                <strong>Note:</strong> Capium’s Company cannot track emails sent
                via Gmail or Outlook.
              </h5>
            </div>

            <div class="white-box" *ngIf="!showForm">
              <div class="send-box">
                <div
                  class="d-flex align-items-center justify-space-between send-box-details"
                >
                  <div
                    class="d-flex align-items-center justify-space-between gap-20"
                  >
                    <img src="assets/images/gmail.svg" alt="Gmail" />
                    <div>
                      <p>Gmail</p>
                    </div>
                  </div>
                  <button mat-button disableRipple (click)="showGmailAccount()">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </button>
                </div>
                <div
                  class="sidenav-table wp-100 mt-1"
                  *ngIf="showGmailAccounts"
                >
                  <div class="mat-table-wrapper contact-detail-table">
                    <form>
                      <table
                        mat-table
                        [dataSource]="userMailList"
                        aria-describedby="Config settings table"
                      >
                        <ng-container matColumnDef="UserImage">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="w-50 text-align-center"
                          ></th>
                          <td
                            mat-cell
                            *matCellDef="let element; let i = index"
                            class="text-align-center"
                          >
                            <div *ngIf="element.photo" class="w-50">
                              <img
                                class="rounded-img"
                                [src]="element.photo"
                                alt="User"
                              />
                            </div>
                            <div *ngIf="!element.photo" class="rounded-border">
                              {{ element.emailAddress | shortName: 2 }}
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="EmailId">
                          <th mat-header-cell *matHeaderCellDef>Email ID</th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <span>{{ element.emailAddress }}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Date">
                          <th mat-header-cell *matHeaderCellDef class="w-100">
                            Date
                          </th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <span>{{
                              element.createdOn | date: "dd LLL yyyy"
                            }}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Default">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="w-100 text-align-center"
                          >
                            Default
                          </th>
                          <td
                            mat-cell
                            *matCellDef="let element; let i = index"
                            class="text-align-center"
                          >
                            <mat-radio-group aria-label="Select an option">
                              <mat-radio-button
                                value="{{ element.id }}"
                                [checked]="element.isDefault"
                                (change)="onGmailDefaultChange(element)"
                                [disabled]="isViewPermission$ | async"
                              >
                              </mat-radio-button>
                            </mat-radio-group>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Action">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="w-50"
                          ></th>
                          <td
                            mat-cell
                            *matCellDef="let element; let i = index"
                            class="text-align-right"
                          >
                            <div class="grid-actions">
                              <button
                                mat-button
                                (click)="removeAccount(element.id)"
                                disableRipple
                                title="Delete"
                                [disabled]="isViewPermission$ | async"
                              >
                                <mat-icon>delete_outline</mat-icon>
                              </button>
                            </div>
                          </td>
                        </ng-container>

                        <tr
                          mat-header-row
                          *matHeaderRowDef="displayGmailColumns; sticky: true"
                        ></tr>
                        <tr
                          mat-row
                          *matRowDef="let row; columns: displayGmailColumns"
                        ></tr>
                      </table>
                    </form>
                  </div>
                  <div class="add-account pt-1">
                    <button
                      mat-button
                      disableRipple
                      (click)="redirectToAccount(accountProvider.Google)"
                    >
                      <mat-icon class="text-gray-300 mr-1"
                        >person_pin
                      </mat-icon>
                      <span class="text-gray-300 font-size-16"
                        >Use Another Account</span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="white-box" *ngIf="!showForm">
              <div class="send-box">
                <div
                  class="d-flex align-items-center justify-space-between send-box-details"
                >
                  <div
                    class="d-flex align-items-center justify-space-between gap-20"
                  >
                    <img src="assets/images/outlook.svg" alt="Outlook" />
                    <div>
                      <p>Outlook</p>
                    </div>
                  </div>
                  <button
                    mat-button
                    disableRipple
                    (click)="showOutlookAccount()"
                  >
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </button>
                </div>
                <div
                  class="sidenav-table wp-100 mt-1"
                  *ngIf="showOutlookAccounts"
                >
                  <div class="mat-table-wrapper contact-detail-table">
                    <form>
                      <table
                        mat-table
                        [dataSource]="userOutlookList"
                        aria-describedby="Config settings table"
                      >
                        <ng-container matColumnDef="UserImage">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="w-50 text-align-center"
                          ></th>
                          <td
                            mat-cell
                            *matCellDef="let element; let i = index"
                            class="text-align-center"
                          >
                            <div *ngIf="element.photo" class="w-50">
                              <img
                                class="rounded-img"
                                [src]="element.photo"
                                alt="User"
                              />
                            </div>
                            <div *ngIf="!element.photo" class="rounded-border">
                              {{ element.emailAddress | shortName: 2 }}
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="EmailId">
                          <th mat-header-cell *matHeaderCellDef>Email ID</th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <span>{{ element.emailAddress }}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Date">
                          <th mat-header-cell *matHeaderCellDef class="w-100">
                            Date
                          </th>
                          <td mat-cell *matCellDef="let element; let i = index">
                            <span>{{
                              element.createdOn | date: "dd LLL yyyy"
                            }}</span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Default">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="w-100 text-align-center"
                          >
                            Default
                          </th>
                          <td
                            mat-cell
                            *matCellDef="let element; let i = index"
                            class="text-align-center"
                          >
                            <mat-radio-group aria-label="Select an option">
                              <mat-radio-button
                                value="{{ element.id }}"
                                [checked]="element.isDefault"
                                (change)="onOutlookDefaultChange(element)"
                                [disabled]="isViewPermission$ | async"
                              >
                              </mat-radio-button>
                            </mat-radio-group>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="Action">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="w-50"
                          ></th>
                          <td
                            mat-cell
                            *matCellDef="let element; let i = index"
                            class="text-align-right"
                          >
                            <div class="grid-actions">
                              <button
                                mat-button
                                (click)="removeAccount(element.id)"
                                disableRipple
                                title="Delete"
                                [disabled]="isViewPermission$ | async"
                              >
                                <mat-icon>delete_outline</mat-icon>
                              </button>
                            </div>
                          </td>
                        </ng-container>

                        <tr
                          mat-header-row
                          *matHeaderRowDef="displayGmailColumns; sticky: true"
                        ></tr>
                        <tr
                          mat-row
                          *matRowDef="let row; columns: displayGmailColumns"
                        ></tr>
                      </table>
                    </form>
                  </div>
                  <div class="add-account pt-1">
                    <button
                      mat-button
                      disableRipple
                      (click)="redirectToAccount(accountProvider.Office)"
                    >
                      <mat-icon class="text-gray-300 mr-1"
                        >person_pin
                      </mat-icon>
                      <span class="text-gray-300 font-size-16"
                        >Use Another Account</span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel
        [expanded]="true"
        [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
      >
        <mat-expansion-panel-header>
          <mat-panel-title> Send Emails </mat-panel-title>
        </mat-expansion-panel-header>
        <app-email-integrations
          [triggerEditData]="triggerEditData"
        ></app-email-integrations>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="false" class="cdk-visually-hidden">
        <mat-expansion-panel-header>
          <mat-panel-title> Payment </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="sidenav-body-content pt-0 pb-0">
          <div class="white-box">
            <div class="send-box">
              <div
                class="d-flex align-items-center justify-space-between send-box-details"
              >
                <div
                  class="d-flex align-items-center justify-space-between gap-20"
                >
                  <img
                    style="width: 34px; height: 34px"
                    src="https://www.capium.com/wp-content/themes/Capium/fav/favicon-96x96.png"
                    alt="Capium"
                  />
                  <div>
                    <p>Pay</p>
                  </div>
                </div>
                <button
                  mat-button
                  disableRipple
                  (click)="capiumPayIntegration()"
                >
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
              </div>
              <div
                class="sidenav-table wp-100 mt-1"
                *ngIf="showCapiumPayAccounts"
              >
                <div class="mat-table-wrapper contact-detail-table">
                  <form>
                    <table
                      class="p-0"
                      mat-table
                      [dataSource]="capiumPayList"
                      aria-describedby="Config Settings Table"
                    >
                      <ng-container matColumnDef="EmailId">
                        <th mat-header-cell *matHeaderCellDef>Account ID</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                          <span>{{ element.emailAddress }}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Date">
                        <th mat-header-cell *matHeaderCellDef class="w-100">
                          Date
                        </th>
                        <td mat-cell *matCellDef="let element; let i = index">
                          <span>{{
                            element.createdOn | date: "dd LLL yyyy"
                          }}</span>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Default">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          class="w-100 text-align-center"
                        >
                          Default
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element; let i = index"
                          class="text-align-center"
                        >
                          <mat-radio-group aria-label="Select an option">
                            <mat-radio-button
                              value="1"
                              checked="true"
                            ></mat-radio-button>
                          </mat-radio-group>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                        <td
                          mat-cell
                          *matCellDef="let element; let i = index"
                          class="text-align-right"
                        ></td>
                      </ng-container>

                      <tr
                        mat-header-row
                        *matHeaderRowDef="displayStripColumns; sticky: true"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayStripColumns"
                      ></tr>
                    </table>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <app-buttons
    *ngIf="!(isViewPermission$ | async)"
    [getModuleId]="moduleId"
    (triggerOnSaveClick)="onSave($event)"
    (triggerOnCancelClick)="onCancel()"
  >
  </app-buttons>
</div>
