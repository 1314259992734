<div class="content-body-wrapper content-body-scroll data-security">
  <div class="content-body">
    <p class="header-text d-flex justify-space-between align-items-center mb-0">
      Invoice Templates
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCloseClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>
    <div>
      <mat-tab-group
        mat-align-tabs="start"
        disableRipple
        animationDuration="0ms"
        [selectedIndex]="selectedIndex"
        (selectedTabChange)="tabClick($event)"
      >
        <mat-tab label="Templates (Pdf)">
          <app-templates-pdf></app-templates-pdf>
        </mat-tab>
        <mat-tab label="Templates (Doc)">
          <app-templates-docs></app-templates-docs>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
