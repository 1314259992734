export enum DashboardOverview {
  All = 0,
  IncomeByCategories = 1,
  ExpensesByCategories = 2,
  FundByTypes = 3,
  DonationByTypes = 4,
  IncomeVsExpenses = 5,
  AgeingSummary = 6,
  CashFlow = 7,
}

export enum IncomeOverview {
  All = 0,
  IncomeBreakDown = 1,
  ReceivableAgeingSummary = 2,
  IncomeFlow = 3,
}

export enum OverviewYear {
  'Last 30 days' = 0,
  'This month' = 1,
  'This quarter' = 2,
  'This year' = 3,
  'Last month' = 4,
  'Last quarter' = 5,
  'Last year' = 6,
  'Custom' = 7,
}

export enum ExpenseOverview {
  All = 0,
  ExpenseBreakdown = 1,
  PayableAgeingSummary = 2,
  ExpenditureFlow = 3,
}
