import { SaveInventoryModel } from '@app/core/Models';

export class SaveInventory {
  static readonly type = '[INVENTORY] Save Inventory';

  constructor(public inventory: SaveInventoryModel) {}
}

export class GetInventory {
  static readonly type = '[INVENTORY] Get Inventory ';

  constructor(public dataParam: any) {}
}

export class GetInventoryTransaction {
  static readonly type = '[INVENTORY] Get Inventory Transaction';

  constructor(public dataParam: any) {}
}
