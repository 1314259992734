import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Modules, viewVatReturnBox } from '@app/core/Enum';
import { VatScheme } from '@app/core/Enum/vat-scheme';
import { GlobalComponent, MainListParameters } from '@app/core/Models';
import {
  BridgingVatState,
  CommonState,
  GetBridgingVatRetunDetails,
  GetCompanyVatSchemaId,
  GetVatRetunDetails,
  MenuState,
  SubmitVatState,
} from '@app/core/Store';
import { GetVatRetun } from '@app/core/Store/mtd';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-vat-return',
  templateUrl: './vat-return.component.html',
  styleUrls: ['./vat-return.component.scss'],
})
export class VatReturnComponent implements OnInit {
  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;
  @Input()isSubmitHmrc:boolean;
  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  listParameters: MainListParameters = new MainListParameters();
  totalSelectedRecords = 0;
  showPaginator = true;
  moduleEnum = Modules;
  dataSource = new MatTableDataSource();
  periodicDateList: any;
  vatSchemaId: any;
  noDataFound = false;
  moduleId: number;

  vatReturnList: any = [];
  vatReturnDetailsList: any = [];
  id = Guid.EMPTY as unknown as Guid;
  ELEMENT_DATA: any[] = [
    {
      boxNo: 'Box 1',
      vatDesc: 'VAT due on sales and other outputs',
      vatAmount: 0,
    },
    {
      boxNo: 'Box 2',
      vatDesc:
        'VAT due in the period on acquisitions of goods made in Northern Ireland from EU Member States',
      vatAmount: 0,
    },
    {
      boxNo: 'Box 3',
      vatDesc: 'Total VAT due (Sum of Box 1 & 2)',
      vatAmount: 0,
    },
    {
      boxNo: 'Box 4',
      vatDesc:
        'VAT reclaimed in the period on purchases and other inputs (including acquisitions in Northern Ireland from EU member states)',
      vatAmount: 0,
    },
    {
      boxNo: 'Box 5',
      vatDesc: 'VAT payable or reclaimable (Difference of Box 3 & 4)',
      vatAmount: 0,
    },
    { boxNo: 'Box 6', vatDesc: 'Total sales excluding VAT', vatAmount: 0 },
    { boxNo: 'Box 7', vatDesc: 'Total purchases excluding VAT', vatAmount: 0 },
    {
      boxNo: 'Box 8',
      vatDesc:
        'Total value of dispatches of goods and related costs (excluding VAT) from Northern Ireland to EU Member States',
      vatAmount: 0,
    },
    {
      boxNo: 'Box 9',
      vatDesc:
        'Total value of acquisitions of goods and related costs (excluding VAT) made in Northern Ireland from EU Member States',
      vatAmount: 0,
    },
  ];

  REVIEW_ELEMENT_DATA: any[] = [];

  displayedColumns: string[] = ['boxNo', 'vatDesc', 'vatAmount'];

  @Input() submitVatId: number;

  triggerPaginationChange: Subject<any> = new Subject<any>();

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private globalComponent: GlobalComponent
  ) {}

  ngOnInit(): void {
    this.periodicDateList = this.globalComponent.getFinancialPeriod();

    this.moduleId$.subscribe((moduleId) => {
      this.moduleId = moduleId;
    });
    if (this.moduleId === Modules.SubmitVat) {
      this.getList();
    } else if (this.moduleId === Modules.BridgingVAT) {
      if(!this.isSubmitHmrc){
      this.getVatSchemaId().then(() => {
        this.vatReturnList = this.store.selectSnapshot(
          BridgingVatState.getImportData
        );
        this.setData();
      });
    }else{
      this.getBridgingList();
    }

     
    }
  }

  async getVatSchemaId(): Promise<void> {
    await this.store
      .dispatch(new GetCompanyVatSchemaId())
      .toPromise()
      .then((res) => {
        this.vatSchemaId = res.company.companyVatSchemaId;
      });
  }

  getDataFromHeader(data: any): void {
    this.listParameters = data;
    this.getList();
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.getList();
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.getList();
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  getList(): void {
    this.spinner.show();
    this.vatReturnList = [];
    this.vatReturnDetailsList = [];

    if (this.submitVatId === Modules.SubmitVat) {
      this.id =
        this.store.selectSnapshot(SubmitVatState.vatDataId) ??
        (Guid.EMPTY as unknown as Guid);

      this.store
        .dispatch(
          new GetVatRetunDetails(this.id ?? (Guid.EMPTY as unknown as Guid))
        )
        .subscribe(
          (res) => {
            this.vatReturnDetailsList =
              res.viewVatDetails.vatReturnDetailsData.resultSet.data;

            this.noDataFound =
              this.vatReturnDetailsList.length > 0 ? false : true;
            this.setData();
          },
          (error) => this.onError(error)
        );
    } else {
      this.store
        .dispatch(
          new GetVatRetun(
            this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid)
          )
        )
        .subscribe(
          (res) => {
            this.vatReturnList = res.viewVatDetails.vatReturnData;
            this.noDataFound = this.vatReturnList.length > 0 ? false : true;
            this.setData();
          },
          (error) => this.onError(error)
        );
    }
  }

  onError(error) {
    if (error.error.StatusCode === 404) {
      this.noDataFound =
        this.vatReturnList !== null && this.vatReturnList !== undefined
          ? false
          : true;
    }
  }

  setData(): void {
    if (this.moduleId === Modules.SubmitVat) {
      this.vatReturnDetailsList.forEach((item) => {
        this.REVIEW_ELEMENT_DATA.push({
          boxNo: item[0],
          vatDesc: item[1],
          vatAmount: +item[2],
        });
      });
      this.dataSource.data = this.REVIEW_ELEMENT_DATA;
    } else {
      if(this.isSubmitHmrc){
      this.vatReturnDetailsList.forEach((item) => {
        this.REVIEW_ELEMENT_DATA.push({
          boxNo: item[0],
          vatDesc: item[1],
          vatAmount: +item[2],
        });
      });
      this.dataSource.data = this.REVIEW_ELEMENT_DATA;
    }else{
      this.ELEMENT_DATA.forEach((element, i) => {
        if (element.boxNo === viewVatReturnBox.Box1) {
          element.vatAmount = this.vatReturnList.vatDueSales;
        } else if (element.boxNo === viewVatReturnBox.Box2) {
          element.vatAmount = this.vatReturnList.vatDueAcquisitions;
        } else if (element.boxNo === viewVatReturnBox.Box3) {
          element.vatAmount = this.vatReturnList.totalVatDue;
        } else if (element.boxNo === viewVatReturnBox.Box4) {
          element.vatAmount = this.vatReturnList.vatReclaimedCurrPeriod;
        } else if (element.boxNo === viewVatReturnBox.Box5) {
          element.vatAmount = this.vatReturnList.netVatDue;
        } else if (element.boxNo === viewVatReturnBox.Box6) {
          if (
            +this.vatSchemaId === VatScheme.FlatRateAccrualScheme ||
            +this.vatSchemaId === VatScheme.FlatRateCashBasedScheme
          ) {
            element.vatDesc = 'Total sales including VAT';
          }
          element.vatAmount = this.vatReturnList.totalValueSalesExVAT;
        } else if (element.boxNo === viewVatReturnBox.Box7) {
          element.vatAmount = this.vatReturnList.totalValuePurchasesExVAT;
        } else if (element.boxNo === viewVatReturnBox.Box8) {
          element.vatAmount = this.vatReturnList.totalValueGoodsSuppliedExVAT;
        } else if (element.boxNo === viewVatReturnBox.Box9) {
          element.vatAmount = this.vatReturnList.totalAcquisitionsExVAT;
        }
      });
      this.dataSource.data = this.ELEMENT_DATA;
    }
    }
  }

  getBridgingList(): void {
    this.spinner.show();
    this.vatReturnList = [];
    this.vatReturnDetailsList = [];

   
      this.id =
        this.store.selectSnapshot(BridgingVatState.vatDataId) ??
        (Guid.EMPTY as unknown as Guid);

      this.store
        .dispatch(
          new GetBridgingVatRetunDetails(this.id ?? (Guid.EMPTY as unknown as Guid))
        )
        .subscribe(
          (res) => {
            this.vatReturnDetailsList =
              res.viewVatDetails.vatReturnDetailsData.resultSet.data;

            this.noDataFound =
              this.vatReturnDetailsList.length > 0 ? false : true;
            this.setData();
          },
          (error) => this.onError(error)
        );
    } 
  
}
