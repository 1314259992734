import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  ActionType,
  ComponentName,
  ConfirmationType,
  DataType,
  ExportFormat,
  FilteredStatus,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import {
  ExportType,
  HighLightModel,
  MainListParameters,
  ViewParamModel,
} from '@app/core/Models';
import {
  CommonService,
  HighlightRow,
  ModulePermission,
  NotificationService,
} from '@app/core/Services';
import {
  CommonState,
  Copy,
  Export,
  ExportReceipt,
  GetMainList,
  MenuState,
  SendEmail,
} from '@app/core/Store';
import {
  BankDashboardComponent,
  ConfirmationBoxComponent,
  EditAssetDetailComponent,
  EditDisposeAssetComponent,
  EmailPopupComponent,
  TransactionHeaderComponent,
  ViewReceiptComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
@Component({
  selector: 'app-main-list',
  templateUrl: './main-list.component.html',
  styleUrls: ['./main-list.component.scss'],
})
export class MainListComponent implements OnInit {
  @Select(CommonState.columns)
  columns$: Observable<
    Array<{
      name: string;
      value: string;
      dataType?: DataType;
      allowSortBy?: boolean;
    }>
  >;

  isRowHighlighted = false;

  showPaginator = true;
  checkBoxValue = 0;
  filteredStatus = FilteredStatus;

  @Select(CommonState.columnNames)
  columnsNames$: Observable<Array<string>>;

  @Select(CommonState.actions)
  actions$: Observable<any>;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  listParameters: MainListParameters = new MainListParameters();
  totalSelectedRecords = 0;
  selectedRowIndex = -1;
  moreActionCount = 4;

  isAllSelected = false;
  isHeaderChange = false;
  isExpanded = true;
  isCustom = false;
  noDataFound = false;
  isEdit = false;

  ids: Array<Guid>;
  mainList: any;
  customId: any;
  multipleModulesIds: any;

  dataSource = new MatTableDataSource();
  selectedRowData: Array<any> = [];
  dataType = DataType;
  moduleId: number;
  moduleEnum = Modules;

  triggerPaginationChange: Subject<any> = new Subject<any>();
  triggerSelectedRowData: Subject<any> = new Subject<any>();

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(MenuState.hasEditPermission)
  hasEditPermission$: Observable<boolean>;

  @Select(MenuState.hasDeletePermission)
  hasDeletePermission$: Observable<boolean>;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(TransactionHeaderComponent, { static: true })
  headerDetails;

  @ViewChild(BankDashboardComponent, { static: true })
  dashboard;

  dialog: MatDialog;
  store: Store;
  notify: NotificationService;
  commonService: CommonService;
  router: Router;
  highlightRow: HighlightRow;
  datepipe: DatePipe;
  spinner: NgxSpinnerService;
  modulePermission: ModulePermission;

  constructor(private injector: Injector) {
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.store = injector.get<Store>(Store);
    this.notify = injector.get<NotificationService>(NotificationService);
    this.commonService = injector.get<CommonService>(CommonService);
    this.router = injector.get<Router>(Router);
    this.highlightRow = injector.get<HighlightRow>(HighlightRow);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.modulePermission = injector.get<ModulePermission>(ModulePermission);
  }

  ngOnInit(): void {
    this.modulePermission.permissionData.subscribe((value) => {
      this.moduleId = value.data.id;
      if (
        (value.data.url === this.router.url.slice(1) &&
          value.componentName === ComponentName.MainListComponent) ||
        value.componentName === ComponentName.VatSettings ||
        value.componentName === ComponentName.fixedAssetRegister
      ) {
        this.highlightRecord();
      }
    });
  }

  checkIsCustom(event: any): void {
    this.isCustom = event.isCustom;
    this.customId = event.id;
  }

  highlightRecord(): void {
    if (this.moduleId > 0) {
      if (this.highlightRow.mainListHighlighted.moduleId === this.moduleId) {
        this.listParameters.sortBy =
          this.highlightRow.mainListHighlighted.sortBy;
        this.listParameters.sortOrder = false;
        this.getList(true);
      } else {
        this.getList(false);
      }
    }
  }

  selectAll(event: any): void {
    this.mainList.forEach((x) => (x.isSelected = event.checked));
    this.showFooter();
  }

  showFooter(): void {
    this.selectedIds();
    this.totalSelectedRecords = this.ids.length;

    setTimeout(() => {
      this.triggerSelectedRowData.next(this.selectedRowData);
    }, 100);
  }

  deleteClick(): void {
    this.selectedIds();

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (this.ids.length > 0 && isValidPeriod) {
          this.dialog
            .open(ConfirmationBoxComponent, {
              data: {
                ids: this.ids,
                type: ConfirmationType.Delete,
                moduleId: this.moduleId,
                totalNumberOfRecordSelected: this.ids.length,
                headerText: NotificationHeader.deleteConfirmation,
                detailText: NotificationDetails.deleteAllDetailText,
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.listParameters.pageNumber = this.isAllSelected
                  ? 1
                  : this.listParameters.pageNumber;
                this.getList(false);
                this.cancelSelectionClick();
              }
            });
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  export(format: number, isPrint: boolean = false): void {
    this.spinner.show();
    this.selectedIds();
    if (
      (this.moduleId === Modules.Invoices ||
        this.moduleId === Modules.Purchase ||
        this.moduleId === Modules.Quotation ||
        this.moduleId === Modules.CreditNote ||
        this.moduleId === Modules.DebitNote ||
        this.moduleId === Modules.FixedAssets ||
        this.moduleId === Modules.Journals ||
        this.moduleId === Modules.Receipt ||
        this.moduleId === Modules.Payment ||
        this.moduleId === Modules.Dividends ||
        this.moduleId === Modules.Budgeting ||
        this.moduleId === Modules.BridgingVAT) &&
      format === ExportFormat.PDF &&
      this.ids.length > 0
    ) {
      this.store
        .dispatch(new ExportReceipt(this.moduleId, this.ids, isPrint))
        .subscribe();
      this.cancelSelectionClick();
    } else {
      const queryParams = {
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: this.listParameters.filter,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        search: this.listParameters.search,
        moduleId: this.listParameters.moduleId,
        subModuleId: this.listParameters.subModuleId ?? -1,
        ids: this.ids,
        startDate:
          this.datepipe
            .transform(this.listParameters.startDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        endDate:
          this.datepipe
            .transform(this.listParameters.endDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        format: format,
        isPrint: isPrint,
      };

      this.store.dispatch(new Export(queryParams, this.moduleId)).subscribe();
      this.cancelSelectionClick();
    }
  }

  copyClick(): void {
    this.spinner.show();
    this.selectedIds();

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (isValidPeriod) {
          if (Object.keys(this.multipleModulesIds).length > 0) {
            Object.keys(this.multipleModulesIds).forEach((element) => {
              this.store
                .dispatch(new Copy(+element, this.multipleModulesIds[+element]))
                .subscribe((res) => {
                  if (res.common.isCopied) {
                    this.notify.success(
                      NotificationHeader.success,
                      NotificationTextMessage.recordCopySuccessfully
                    );
                    this.isHeaderChange = false;
                    this.getList(false);
                    this.cancelSelectionClick();
                  }
                });
            });
          } else {
            this.store
              .dispatch(new Copy(this.moduleId, this.ids))
              .subscribe((res) => {
                if (res.common.isCopied) {
                  this.notify.success(
                    NotificationHeader.success,
                    NotificationTextMessage.recordCopySuccessfully
                  );
                  this.isHeaderChange = false;
                  this.getList(false);
                  this.cancelSelectionClick();
                }
              });
          }
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  cancelSelectionClick(): void {
    this.deSelectAll();
  }

  deSelectAll(): void {
    this.mainList?.forEach((x) => (x.isSelected = false));
    this.isAllSelected = false;
    this.totalSelectedRecords = 0;
    this.ids = [];
  }

  printClick(): void {
    this.export(ExportType.PDF, true);
    this.cancelSelectionClick();
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.getList(false);
  }

  onAddClick(element: any): void {
    this.router.navigate([
      RoutingPath.AddCustomAccounts,
      { accountId: btoa(element[0]) },
    ]);
  }

  archiveAndRestoreClick(isArchive: any): void {
    this.selectedIds();

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (this.ids.length > 0 && isValidPeriod) {
          this.dialog
            .open(ConfirmationBoxComponent, {
              data: {
                ids: this.ids,
                isArchive,
                multipleModulesIds: this.multipleModulesIds,
                type: ConfirmationType.Archive,
                moduleId: this.moduleId,
                totalNumberOfRecordSelected: this.ids.length,
                headerText: isArchive
                  ? NotificationHeader.archiveConfirmation
                  : NotificationHeader.restoreConfirmation,
                detailText: isArchive
                  ? NotificationDetails.archiveDetailText
                  : NotificationDetails.restoreDetailText,
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.ids = [];
                this.isHeaderChange = false;
                this.getList(false);
                this.cancelSelectionClick();
              }
            });
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  getColumnDataType(dataType: number): any {
    let colType = 'text';

    switch (dataType) {
      case DataType.String:
        colType = `text`;
        break;
      case DataType.Date:
      case DataType.DateTime:
        colType = `date`;
        break;

      case DataType.Int32:
      case DataType.Int64:
      case DataType.InputDecimal:
        colType = `numeric`;
        break;
    }
    return colType;
  }

  getMultipleModuleId(element): void {
    let moduleId = +element[element.length - 1];
    if (!this.multipleModulesIds[moduleId]) {
      this.multipleModulesIds[moduleId] = [];
    }

    this.multipleModulesIds[moduleId].push(element[0]);
  }

  selectedIds(): void {
    this.ids = [];
    this.multipleModulesIds = {};
    this.selectedRowData = [];

    this.mainList.forEach((element) => {
      if (element.isSelected) {
        if (
          this.moduleId === Modules.CashEntry ||
          this.moduleId === Modules.BankEntry
        ) {
          this.getMultipleModuleId(element);
        }

        this.ids.push(element[0]);

        if (
          this.moduleId === Modules.CashEntry ||
          this.moduleId === Modules.BankEntry ||
          this.moduleId === Modules.VatSettings
        ) {
          //This is done because in  CashEntry & BankEntry last row is for moduleId not status
          this.selectedRowData.push(element[this.mainList[0].length - 2]);
        } else {
          this.selectedRowData.push(element[this.mainList[0].length - 1]);
        }
      }
    });
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.getList(false);
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.getList(false);
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  onCheckBoxSelected(element: any, event: any): void {
    element.isSelected = event.checked;

    this.isAllSelected = this.mainList.every(
      (item: any) => item.isSelected === true
    );
    this.showFooter();
  }

  onDeleteClick(id: any, moduleId: number): void {
    this.isCustom = true;
    this.ids = [];
    this.ids.push(id);

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getList(false);
          }
        });
    }
  }

  setDynamicModuleId(element: any): number {
    let moduleId = this.moduleId;
    if (
      this.moduleId === Modules.CashEntry ||
      this.moduleId === Modules.BankEntry
    ) {
      moduleId = +element[element.length - 1];
    }

    return moduleId;
  }

  onButtonClick(element: any, actionType: ActionType): void {
    let id = element[0];
    let moduleId = this.setDynamicModuleId(element);

    this.ids = [];
    this.ids.push(id);

    if (actionType === ActionType.Export || actionType === ActionType.View) {
      switch (actionType) {
        case ActionType.Export:
          this.ids = [];
          this.ids.push(id);
          this.spinner.show();
          this.store
            .dispatch(new ExportReceipt(moduleId, this.ids, false))
            .subscribe();
          break;

        case ActionType.View:
          const data: ViewParamModel = {
            moduleId: moduleId,
            id,
          };

          this.dialog
            .open(ViewReceiptComponent, {
              data,
              disableClose: true,
            })
            .afterClosed()
            .subscribe((result) => {});
          break;
      }
    } else {
      this.commonService
        .isDataInLockedPeriod(this.ids, this.moduleId)
        .subscribe((isValidPeriod) => {
          if (isValidPeriod || actionType === ActionType.Email) {
            switch (actionType) {
              case ActionType.Update:
                this.commonService.toggleMenu = false;
                this.commonService.onEditRouting(true, moduleId, id);
                break;

              case ActionType.Delete:
                this.onDeleteClick(id, moduleId);
                break;

              case ActionType.Email:
                this.store
                  .dispatch(new SendEmail(this.moduleId, id))
                  .subscribe();
                this.notify.success(
                  NotificationHeader.success,
                  NotificationTextMessage.emailMessage
                );
                break;
            }
          } else {
            this.commonService.onFailure(
              NotificationTextMessage.dataInLockedPeriodList
            );
          }
        });
    }
  }

  getList(isRowHighlighted: boolean): void {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids:
        this.listParameters.ids !== undefined &&
        this.listParameters.ids.length > 0
          ? this.listParameters.ids
          : null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      branchIds: this.listParameters.branchIds ?? [],
      departmentIds: this.listParameters.departmentIds ?? [],
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };

    this.spinner.show();
    this.isRowHighlighted = isRowHighlighted;
    this.headerDetails.isRowHighlighted = isRowHighlighted;
    if (this.moduleId === Modules.VAT) {
    } else {
      this.store
        .dispatch(new GetMainList(queryParams, this.moduleId))
        .subscribe((res) => {
          this.spinner.hide();
          this.mainList = res.common.mainList.resultSet.data;
          this.noDataFound = this.mainList.length > 0 ? false : true;
          this.dataSource.data = this.mainList;
          this.checkBoxValue = this.dataSource.data.length;
          this.highlightRow.mainListHighlighted = new HighLightModel();

          this.actions$.subscribe((x) => {
            if (x !== null && x !== undefined && x.length > 4) {
              this.moreActionCount = 3;
            }
          });

          const param = {
            pageSize: this.listParameters.pageSize,
            totalRecord: res.common.totalRecord,
          };
          this.triggerPaginationChange.next(param);
        });
    }
  }

  emailClick(): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(EmailPopupComponent, {
          data: {
            ids: this.ids,
            moduleId: this.moduleId,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.notify.success(
              NotificationHeader.success,
              NotificationTextMessage.mailSentSuccessfully
            );
            this.cancelSelectionClick();
          }
        });
    }
  }

  isQuickAdd(event): void {
    if (event) {
      this.dashboard.getBankAccountList();
    }
  }

  getDataFromHeader(data: any): void {
    data.ids = this.ids;
    if (data.search === '') {
      this.spinner.show();
    }
    this.listParameters = data;
    !this.listParameters.format
      ? this.getList(this.headerDetails.isRowHighlighted)
      : this.export(this.listParameters.format, this.listParameters.isPrint);
    this.cancelSelectionClick();
  }

  getDataFromBank(data: any): void {
    this.isExpanded = data.isExpanded;
    this.listParameters.pageNumber = 1;
    this.listParameters.moduleId = data.id;
    this.headerDetails.headerData.moduleId = data.id;
    this.getList(false);
  }

  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
  }

  onToggleMatMenu(i): void {
    this.selectedRowIndex = i;
  }

  onTOCButtonClick(element: any, actionType: ActionType): void {
    let id = element[0];

    switch (actionType) {
      case ActionType.Explain:
        this.dialog
          .open(EditAssetDetailComponent, {
            data: {
              id: btoa(id),
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.getList(false);
          });
        break;

      case ActionType.Dispose:
      case ActionType.Sell:
        this.dialog
          .open(EditDisposeAssetComponent, {
            data: {
              id: btoa(id),
              actionType: actionType,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.getList(false);
          });
        break;
    }
  }
}
