import { Guid } from 'guid-typescript';

export class BankTransferModel {
  id?: Guid;
  entryNumber?: string;
  entryDate?: string;
  note: string;
  transferredFromId: Guid;
  transferredToId: Guid;
  amount: number;
  transactionLogId?: Guid;
  isManualBank?: boolean;
  currencyRate?: number;
  foreignAmount?: number;
  currencyId?: number;
  isReceipt?: boolean;
  mergeTransaction?: boolean;
}

export class BankFeedDuplicateTransactionModel {
  transferredFromId?: Guid;
  transferredToId?: Guid;
  transactionLogId: Guid;
}
