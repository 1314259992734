import { Guid } from "guid-typescript";

export class FileUploadResponseModel {
  isSuccess?: boolean;
  fileUrl?: string;
  fileName?: string;
  fileSize?: string;
}

export class FileUploadRequestModel {
  file: Array<File>;
  attachmentType?: number;
}

export class MultipleFileDownloadModel {
  fileURLs: Array<string>;
}

export class FileDownloadRequest {
  userId?: Guid;
  fileUrl: string;
}
