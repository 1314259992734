import { Guid } from 'guid-typescript';

export class ContactDetailModel {
  id: Guid;
  customerName: string;
  customerCode: string;
  tradingName: string;
  country: string;
  addressLine3: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postCode: string;
  email: string;
  mobileNumber: string;
  phone: string;
  fax: string;
}
