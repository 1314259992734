import { Injectable } from '@angular/core';
import { BankAccount, BankFeeds } from '@app/core/Models';
import { BankDasboardService } from '@app/core/Services';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  CreateBankAccount,
  DeleteBankAccount,
  GetBankAccountList,
  GetBankAccountTypeToAdd,
  ManualImportBank,
  ReconfirmBankAccount,
  RefreshBankAccountList,
  SetBankDefault,
} from './bank-dashbaord.action';
export class BankDashboardStateInfo {
  bankAccountData?: Array<BankAccount>;
  bankAccountDetailList?: Array<BankFeeds>;
  bankAccountTypeList?: any;
  isBankAccoutAdded?: any;
  isDefault?: any;
  response?: any;
}

@State<BankDashboardStateInfo>({
  name: 'bankDashboard',
  defaults: {
    bankAccountData: [],
    bankAccountDetailList: [],
    bankAccountTypeList: [],
    isBankAccoutAdded: false,
    isDefault: false,
  },
})
@Injectable()
export class BankDashboardState {
  constructor(private bankDasboardService: BankDasboardService) {}

  @Action(GetBankAccountList)
  getBankAccountList({
    patchState,
  }: StateContext<BankDashboardStateInfo>): any {
    return this.bankDasboardService.getBankAccount().pipe(
      tap((res) => {
        patchState({
          bankAccountData: res,
        });
      })
    );
  }

  @Action(RefreshBankAccountList)
  refreshBankAccountList(
    { getState, setState }: StateContext<BankDashboardStateInfo>,
    action: RefreshBankAccountList
  ): any {
    return this.bankDasboardService.refreshBankAccountList(action.accountId);
  }

  @Action(DeleteBankAccount)
  deleteSelectedClient(
    { getState, setState }: StateContext<BankDashboardStateInfo>,
    action: DeleteBankAccount
  ) {
    return this.bankDasboardService.deleteBankAccount(action.ids);
  }

  @Action(GetBankAccountTypeToAdd)
  getBankAccountTypeToAdd(
    { getState, patchState }: StateContext<BankDashboardStateInfo>,
    action: GetBankAccountTypeToAdd
  ): any {
    this.bankDasboardService.getBankAccountTypeToAdd(action.param).pipe(
      tap((res) => {
        patchState({
          bankAccountTypeList: res,
        });
      })
    );
  }

  @Action(CreateBankAccount)
  createBankAccount(
    { getState, patchState }: StateContext<BankDashboardStateInfo>,
    action: CreateBankAccount
  ) {
    return this.bankDasboardService.createBankAccount(action.bankAccount).pipe(
      tap((res) => {
        patchState({
          isBankAccoutAdded: res,
        });
      })
    );
  }

  @Action(SetBankDefault)
  setBankDefault(
    { getState, patchState }: StateContext<BankDashboardStateInfo>,
    action: SetBankDefault
  ) {
    return this.bankDasboardService.setBankDefault(action.id).pipe(
      tap((res) => {
        patchState({
          isDefault: res,
        });
      })
    );
  }

  @Action(ManualImportBank)
  manualImportBank(
    { getState, patchState }: StateContext<BankDashboardStateInfo>,
    action: ManualImportBank
  ) {
    return this.bankDasboardService
      .manualImportBank(action.manualImports, action.customId)
      .pipe(
        tap((res) => {
          patchState({
            response: res,
          });
        })
      );
  }

  @Action(ReconfirmBankAccount)
  reconfirmBankAccount(
    { getState, patchState }: StateContext<BankDashboardStateInfo>,
    action: ReconfirmBankAccount
  ): any {
    return this.bankDasboardService
      .reconfirmBankAccount(action.accountId, action.userData)
      .pipe(
        tap((res) => {
          patchState({
            response: res,
          });
        })
      );
  }
}
