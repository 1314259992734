<div class="mb-4">
  <form [formGroup]="cisReturnDetailsForm">
    <div class="d-flex gap-40">
      <div class="form-field wp-100">
        <p>Return Period</p>
        <div class="d-flex align-items-center gap-10">
          <div class="form-field-wrapper">
            <div class="schedule-list d-flex align-items-center gap-10">
              <div class="d-flex align-items-center gap-10">
                <ng-select
                  #selectMonth
                  (focus)="selectMonth.open()"
                  (valueChange)="selectMonth.close()"
                  class="w-120"
                  [disableOptionCentering]="true"
                  formControlName="month"
                  (change)="onMonthYearChange()"
                >
                  <ng-option
                    *ngFor="let month of monthList; let i = index"
                    [value]="i + 1"
                  >
                    <div title="{{ month }}">{{ month }}</div>
                  </ng-option>
                </ng-select>
                <ng-select
                  #selectYear
                  (focus)="selectYear.open()"
                  (valueChange)="selectYear.close()"
                  class="w-120"
                  [disableOptionCentering]="true"
                  formControlName="year"
                  (change)="onMonthYearChange()"
                >
                  <ng-option *ngFor="let year of years" [value]="year">
                    <div title="{{ year }}">{{ year }}</div>
                  </ng-option>
                </ng-select>
                {{ startDate }} - {{ endDate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-field wp-100">
      <p>Nil Return</p>
      <div class="form-field d-flex align-items-center wp-100">
        <mat-checkbox
          formControlName="nilReturn"
          (change)="onCheckBoxChanges($event)"
          [(ngModel)]="nillReturn"
        ></mat-checkbox>
        <span
          >Can you confirm that no payments have been made to subcontractors in
          the construction industry in this period?</span
        >
      </div>
    </div>
    <h2>Declaration</h2>
    <div class="form-field wp-100">
      <p>Employment Status</p>
      <div class="form-field d-flex align-items-center wp-100">
        <mat-checkbox
          disableRipple
          formControlName="employmentStatus"
          [disabled]="isDisabled"
          [(ngModel)]="isEmploymentStatusChecked"
        ></mat-checkbox>
        <span
          >Can you confirm that the employment status of each individual
          included on this return has been considered and payments have not been
          made under contracts of employment?</span
        >
      </div>
    </div>
    <div class="form-field wp-100">
      <p>Verification</p>
      <div class="form-field d-flex align-items-center wp-100">
        <mat-checkbox
          disableRipple
          formControlName="verification"
          [disabled]="isDisabled"
          [(ngModel)]="isVerificationChecked"
        ></mat-checkbox>
        <span
          >Can you confirm that every subcontractor included on this return has
          either been verified with HM Revenue & Customs, or has been included
          in previous CIS return in this, or the previous two tax years?</span
        >
      </div>
    </div>
    <div class="form-field wp-100">
      <p>Inactivity</p>
      <div class="form-field d-flex align-items-center wp-100">
        <mat-checkbox
          formControlName="inactivity"
          [(ngModel)]="isActivity"
        ></mat-checkbox>
        <span
          >Please indicate if you do not anticipate paying subcontractors in the
          next six months</span
        >
      </div>
    </div>
    <div class="form-field wp-100">
      <p>Information Correct</p>
      <div class="form-field d-flex align-items-center wp-100">
        <mat-checkbox
          disableRipple
          formControlName="informationCorrect"
          [disabled]="true"
          [(ngModel)]="informationCorrect"
        ></mat-checkbox>
        <span
          >Can you confirm that the information given on this return is correct
          and complete to the best of your knowledge and belief?</span
        >
      </div>
    </div>
  </form>
</div>
