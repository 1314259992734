import { ProductDetailsModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class RecurringModule {
  id: Guid;
  entryNumber: string;
  currencyId: number;
  accountId?: Guid;
  bookAccountId?: Guid;
  dueTermId: Guid;
  startDate?: string;
  endDate?: string;
  maxNumOfOccurrences: number;
  recurringTypeId: number;
  separationCount: number;
  recurringEndTypeId: number;
  subRecurringTypeId: number;
  dayOfWeek: number;
  dayOfMonth: number;
  weekOfMonth: number;
  monthOfYear: number;
  note: string;
  items: Array<ProductDetailsModel>;
  branchId?: Guid | null;
  departmentId?: Guid | null;
  discount: number;
  isDiscountPercentage: boolean;
}
