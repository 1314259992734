import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationTextMessage } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-custom-period-popup',
  templateUrl: './custom-period-popup.component.html',
  styleUrls: ['./custom-period-popup.component.scss'],
})
export class CustomPeriodPopupComponent implements OnInit {
  formFinancialYearDetail: UntypedFormGroup;
  financialYearArray: any;
  isNewRow = false;
  notificationMessage = NotificationTextMessage;
  displayColumns: string[] = ['fromDate', 'toDate', 'deleteButton'];
  financialYearData: any[] = [];
  tableDataSource: MatTableDataSource<AbstractControl>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CustomPeriodPopupComponent>,
    private formBuilder: UntypedFormBuilder,
    public datepipe: DatePipe,
    public commonService: CommonService,
    public store: Store
  ) {}

  ngOnInit(): void {
    this.setJournalDetailsForm(true);

    this.formFinancialYearDetail.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange =
        this.formFinancialYearDetail.touched;
    });
  }

  onNoClick(): void {
    this.dialogRef.close([]);
  }

  onGenerateButtonClick(): void {
    this.dialogRef.close(this.financialYearData);
  }

  setJournalDetailsForm(addNewRow: boolean): void {
    this.formFinancialYearDetail = new FormGroup({
      financialYearArray: new UntypedFormArray([]),
    });

    this.financialYearArray = this.formFinancialYearDetail.get(
      'financialYearArray'
    ) as UntypedFormArray;

    this.setDataSource(this.financialYearArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.financialYearArray = this.formFinancialYearDetail.get(
      'financialYearArray'
    ) as UntypedFormArray;

    this.financialYearArray.push(this.setForm());

    this.financialYearData = [];

    this.financialYearArray?.getRawValue().forEach((x) => {
      this.financialYearData.push({
        fromDate: this.datepipe.transform(x.fromDate, 'yyyy-MM-dd'),
        toDate: this.datepipe.transform(x.toDate, 'yyyy-MM-dd'),
      });
    });

    this.setDataSource(this.financialYearArray);
  }

  addNewRow(): void {
    this.isNewRow = true;
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  dateChange(isfromDate: boolean, index: number): void {
    let toDate;
    if (isfromDate) {
      this.financialYearArray.controls[index]
        .get('minDate')
        ?.setValue(
          this.financialYearArray.controls[index].get('fromDate')?.value
        );

      toDate = new Date(
        this.financialYearArray.controls[index].get('fromDate')?.value
      );
      toDate.setFullYear(toDate.getFullYear() + 1);
      toDate.setDate(toDate.getDate() - 1);
      this.financialYearArray.controls[index].get('toDate')?.setValue(toDate);
    }
    this.financialYearData = [];

    this.financialYearArray?.getRawValue().forEach((x) => {
      this.financialYearData.push({
        fromDate: this.datepipe.transform(x.fromDate, 'yyyy-MM-dd'),
        toDate: this.datepipe.transform(x.toDate, 'yyyy-MM-dd'),
      });
    });
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  setForm(): FormGroup {
    let fromDate;
    let toDate;
    if (this.isNewRow) {
      if (this.financialYearArray.length > 0) {
        fromDate = new Date(
          this.financialYearArray.controls[
            this.financialYearArray.length - 1
          ].get('fromDate').value
        );
        fromDate.setFullYear(fromDate.getFullYear() + 1);
      } else {
        fromDate = new Date();
      }
    } else {
      fromDate = new Date();
    }

    toDate = new Date(fromDate);
    toDate.setFullYear(toDate.getFullYear() + 1);
    toDate.setDate(toDate.getDate() - 1);

    return this.formBuilder.group({
      fromDate: new FormControl(fromDate, Validators.required),
      toDate: new FormControl(toDate, Validators.required),
      attachment: new FormControl(),
      minDate: new FormControl(new Date()),
      maxDate: new FormControl(new Date()),
    });
  }

  onDeleteProductDetails(index: number, element): void {
    if (this.financialYearArray.length > 1) {
      {
        this.financialYearArray = this.formFinancialYearDetail.get(
          'financialYearArray'
        ) as UntypedFormArray;
        if (this.financialYearArray.length === 1) {
          return;
        }
        this.financialYearArray.removeAt(index);
        this.setDataSource(this.financialYearArray);
      }
    } else {
      this.commonService.onFailure('Minimum one time period is required!');
    }
  }
}
