export enum ConfirmationType {
  Archive = 1,
  Delete = 2,
  Copy = 2,
  Mail = 4,
  Activate = 5,
  MergeTrailBalance = 6,
  TrailBalanceErrorList = 7,
  FixedAssetExplainRemove = 7,
  BankTransferExist = 8,
  SubmitNillVAT = 9,
  DeleteBranch = 10,
  DeleteDepartment = 11,
  DeleteLossOfStock = 11,
  UnAllocateAmount = 12,
}
