import { Guid } from 'guid-typescript';
export class ProductsModel {
  id?: Guid;
  name: string;
  code: string;
  description: string;
  vatRateId?: number;
  price: number;
  openingBalanceQuantity: number;
  openingBalancePrice: number;
  branchId?: Guid | null;
  departmentId?: Guid | null;
  productType?: number;
  trackProductInInvetory: boolean;
}
