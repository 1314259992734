<div class="content-body">
  <form [formGroup]="receiptForm">
    <div class="d-flex gap-40">
      <div class="form-field fund-name" *ngIf="branchList.length > 0">
        <p>Branch <span class="text-danger-300">*</span></p>
        <ng-select
          #branch
          (focus)="branch.open()"
          (valueChange)="branch.close()"
          [disableOptionCentering]="true"
          formControlName="branchId"
          dropdownPosition="bottom"
          (change)="onChangeBranch($event, false)"
        >
          <ng-option *ngFor="let bl of branchList" [value]="bl.id">
            <div title="{{ bl.name }}">{{ bl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="departmentList.length > 0">
        <p>Department <span class="text-danger-300">*</span></p>
        <ng-select
          #department
          (focus)="department.open()"
          (valueChange)="department.close()"
          [disableOptionCentering]="true"
          formControlName="departmentId"
          dropdownPosition="bottom"
        >
          <ng-option *ngFor="let dl of departmentList" [value]="dl.id">
            <div title="{{ dl.name }}">{{ dl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field datepicker">
        <p>
          {{
            (moduleId$ | async) === moduleId.Receipt
              ? "Receipt Date"
              : "Payment Date"
          }}
          <span class="text-danger-300">*</span>
        </p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="receiptDate"
            [matDatepicker]="receiptDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="receiptDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #receiptDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(receiptForm.get('receiptDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(receiptForm.get('receiptDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
      <div class="form-field wap-40">
        <p>{{ accountLabelText }} <span class="text-danger-300">*</span></p>

        <ng-select
          #accountList
          [items]="receiptAccountList"
          (focus)="accountList.open()"
          (valueChange)="accountList.close()"
          [disableOptionCentering]="true"
          formControlName="accountId"
          bindLabel="name"
          bindValue="id"
          groupBy="groupName"
          panelClass="mat-select-position"
          appendTo="body"
          (change)="onAccountDataChanged($event)"
          [ngClass]="this.isEdit ? 'ng-select-disabled' : ''"
        >
          <ng-template ng-option-tmp let-item="item">
            <span class="ng-option-label" [attr.title]="item.name">{{
              item.name
            }}</span>
          </ng-template>
        </ng-select>
      </div>

      <div
        class="form-field wap-40"
        *ngIf="
          (getModuleName === moduleName.Receipt ||
            getModuleId === moduleId.Payment) &&
          isMultiCurrencySelected
        "
      >
        <p>Currency<span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="foreignCurrency"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="form-field" *ngIf="isMultiCurrencySelected">
        <p>&nbsp;</p>
        <mat-form-field class="w-140 big-inputbox">
          <input
            matInput
            formControlName="currencyAmt"
            allowDecimal
            autocomplete="off"
            (change)="onCurrencyRateEntered()"
          />
          <button
            mat-button
            type="button"
            disableRipple
            matSuffix
            (click)="onCurrencyRefresh()"
          >
            <mat-icon>refresh</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div
        *ngIf="
          getModuleId !== moduleId.BankEntry &&
          getModuleId !== moduleId.CashEntry
        "
        class="form-field quick-add-buttons"
      >
        <div class="d-flex justify-space-between">
          <p>{{ customerLabelText }} <span class="text-danger-300">*</span></p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isCustomerChangePermission"
            >
              <mat-icon (click)="gotoAddCustomer()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="disabledEditButton || !isCustomerChangePermission"
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditCustomer()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          *ngIf="
            getModuleId !== moduleId.BankEntry &&
            getModuleId !== moduleId.CashEntry
          "
          #customerName
          [disableOptionCentering]="true"
          (focus)="customerName.open()"
          (valueChange)="customerName.close()"
          formControlName="customerName"
          (change)="onCustomerChange($event)"
          panelClass="mat-select-position"
          [ngClass]="this.isEdit ? 'ng-select-disabled' : ''"
        >
          <ng-option *ngFor="let option of customerList" [value]="option.id">
            <div title="{{ option.name }}">{{ option.name }}</div>
          </ng-option>
        </ng-select>
      </div>

      <div class="form-field">
        <p>{{ getModuleName }} No</p>
        <mat-form-field>
          <input
            matInput
            formControlName="code"
            maxlength="{{ maxLength.maxLength50 }}"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div [hidden]="!showReceiptDetails" class="form-field">
        <p>Invoice Currency <span class="text-danger-300">*</span></p>
        <ng-select
          #currency
          (focus)="currency.open()"
          (valueChange)="currency.close()"
          [disableOptionCentering]="true"
          formControlName="currency"
          dropdownPosition="top"
          panelClass="mat-select-position"
          (change)="currencyChange($event)"
          required
        >
          <ng-option
            *ngFor="let cl of clientCurrencyList"
            [value]="cl.currencyId"
          >
            <div title="{{ cl.currency }}">
              {{ cl.currency }}
            </div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p *ngIf="getModuleName !== moduleName.Payment">
          Amount Received <span class="text-danger-300">*</span>
        </p>
        <p *ngIf="getModuleName === moduleName.Payment">
          Amount Paid <span class="text-danger-300">*</span>
        </p>
        <mat-form-field>
          <button mat-button type="button" disableRipple matSuffix>
            <span class="symbol-align">{{ selectedCurrencySymbol }}</span>
          </button>
          <input
            allow_14_2_Decimal
            matInput
            zeroNotAllowed
            formControlName="amountReceived"
            autocomplete="off"
            allowDecimal
            (change)="onAmountReceivedChange()"
          />
        </mat-form-field>
      </div>
      <div *ngIf="isConvertedAmountShow" class="form-field wap-40 mt-29">
        <mat-form-field>
          <button mat-button type="button" disableRipple matSuffix>
            <span class="symbol-align">{{ defaultCurrencySymbol }}</span>
          </button>
          <input
            allow_14_2_Decimal
            matInput
            zeroNotAllowed
            formControlName="convertedAmtRcvd"
            autocomplete="off"
            allowDecimal
          />
        </mat-form-field>
      </div>
      <div
        class="form-field m-0 d-flex align-items-center"
        *ngIf="
          getModuleId !== moduleId.BankEntry &&
          getModuleId !== moduleId.CashEntry
        "
      >
        <mat-checkbox
          formControlName="isAdvance"
          (change)="onCheckBoxSelected($event)"
        >
          {{
            (moduleId$ | async) === moduleId.Receipt
              ? "Make Advance Receipt"
              : "Make Advance Payment"
          }}
        </mat-checkbox>
      </div>
    </div>
    <div class="form-field textarea">
      <p>Notes</p>
      <mat-form-field>
        <textarea
          matInput
          maxlength="{{ maxLength.maxLength2000 }}"
          formControlName="note"
          autocomplete="off"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<div [hidden]="!showReceiptDetails">
  <app-receipt-details
    (amountReceived)="onAmountReceivedChange()"
    [triggerCustomer]="triggerCustomer"
    [triggerReceiptDetail]="triggerReceiptDetail"
    [getModuleId]="getModuleId"
    [amountReceivedValue]="amountReceivedValue"
    [triggerAccountSelectedOption]="triggerAccountSelectedOption"
    [triggerCurrencyRefreshOption]="triggerCurrencyRefreshOption"
    (totalamountDue)="onTotalDueAmountBalanceChange()"
    [triggerTransactionLogData]="triggerTransactionLogData"
  ></app-receipt-details>
</div>
