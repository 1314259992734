import { DatePipe } from '@angular/common';
import { Component, Injector, Input, OnInit, Renderer2 } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import { GroupNames, MaxLength, Modules, NotificationTextMessage } from '@app/core/Enum';
import { quickEntryType } from '@app/core/Enum/quick-entry';
import { VatRateCodeType } from '@app/core/Enum/vat-rate-code-type';
import { GlobalComponent, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetCustomBankAndCashAccount,
  GetCustomerSupplierList,
  GetGroupAccountsBasedOnGroupId,
  GetVatRateList,
} from '@app/core/Store';
import { CleanAllLinesComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-quickentry-details',
  templateUrl: './quickentry-details.component.html',
  styleUrls: ['./quickentry-details.component.scss'],
})
export class QuickentryDetailsComponent implements OnInit {
  formProductDetail: UntypedFormGroup;
  tableDataSource: MatTableDataSource<AbstractControl>;
  productDetailArray: any;
  paymentAccountList: any[] = [];
  customerSupplierList: SideListModel[];
  customerList: SideListModel[];
  supplierList: SideListModel[];
  vatRateList: any[] = [];
  maxLength = MaxLength;
  branchId: Guid | null = null;
  accountGroupList: any[] = [];
  tempAccountGroupList: any[] = [];
  customVatAmount: number = 0;
  vatRate: number;
  totalReceiptValue = 0;
  totalPaymentValue = 0;
  defaultBankAccountId = Guid.EMPTY as unknown as Guid;
  defaultGuid = Guid.EMPTY as unknown as Guid;
  isDefaultBankSelected = false;
  isPaydateSameAsInvoiceDate = false;
  isPaymentDateEntered = false;
  quickEntrySelectedType: number;
  isGrossToNet = false;
  isNetToGross = false;
  periodicDate: any;
  notificationMessage = NotificationTextMessage;
  isEdit = false;
  receiptAccountGroupList: any[] = [];
  paymentAccountGroupList: any[] = [];
  quickEntryTypeList: any[] = [
    {
      id: quickEntryType.Receipt,
      name: 'Receipt',
    },
    {
      id: quickEntryType.Payment,
      name: 'Payment',
    },
  ];
  displayProductDetailsColumns: string[] = [
    'type',
    'customerSupplierId',
    'reference',
    'account',
    'description',
    'invoiceDate',
    'vatrate',
    'vatammount',
    'netAmount',
    'amount',
    'paymentDate',
    'paymentAccount',
    'closeButton',
  ];
  @Input() triggerEditData: Observable<any>;
  @Input() triggerDefaultBankSelectedOption: Observable<any>;
  @Input() triggerPaydateSameAsInvoiceDateSelected: Observable<boolean>;
  @Input() triggerDefaultVatAmountSelectedOption: Observable<any>;
  store: Store;
  commonService: CommonService;
  isVatRegistered: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private renderer: Renderer2,
    private globalComponent: GlobalComponent,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    if (!this.isVatRegistered) {
      this.displayProductDetailsColumns =
        this.displayProductDetailsColumns.filter(
          (column) =>
            column !== 'vatrate' &&
            column !== 'vatammount' &&
            column !== 'amount'
        );
    }
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setProductDetailsForm(true);
    this.triggerDefaultBankSelectedOption?.subscribe((data) => {
      this.defaultBankAccountId = data;
      this.isDefaultBankSelected = true;
      this.productDetailArray.controls.forEach((x, index) => {
        (Object as any).values(x.controls).forEach((c) => {
          let paymentDate =
            this.productDetailArray.controls[index].get('paymentDate')?.value;
          if (paymentDate !== null) {
            this.isPaymentDateEntered = true;
          }
          if (this.isPaymentDateEntered) {
            x.controls.paymentAccountId.setValue(data);
          }
        });
      });
    });
    this.triggerPaydateSameAsInvoiceDateSelected?.subscribe((data) => {
      this.isPaydateSameAsInvoiceDate = true;
      this.productDetailArray.controls.forEach((x, index) => {
        (Object as any).values(x.controls).forEach((c) => {
          let invoiceDate =
            this.productDetailArray.controls[index].get('invoiceDate')?.value;
          this.productDetailArray.controls[index]
            .get('paymentDate')
            ?.setValue(invoiceDate);
          this.isPaymentDateEntered = true;
        });
      });
    });
    this.triggerDefaultVatAmountSelectedOption?.subscribe((data) => {
      this.productDetailArray.controls.forEach((x, index) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (data === 1) {
            this.isNetToGross = true;
            this.isGrossToNet = false;
            this.productDetailArray.controls[index].get('amount').disable();
            this.productDetailArray.controls[index].get('netAmount').enable();
          } else if (data === 2) {
            this.isGrossToNet = true;
            this.isNetToGross = false;
            this.productDetailArray.controls[index].get('netAmount').disable();
            this.productDetailArray.controls[index].get('amount').enable();
          }
        });
      });
    });
    this.triggerEditData?.subscribe((data) => {
      this.isEdit = true;
      this.isPaydateSameAsInvoiceDate =
        data.defaultPayDate !== null ? true : false;
      this.isDefaultBankSelected = data.bookAccountId !== null ? true : false;
      this.defaultBankAccountId = data.bookAccountId;
      this.isNetToGross = data.vatAmountType === 1 ? true : false;
      this.isGrossToNet = data.vatAmountType === 2 ? true : false;
      this.loadDropdownValues().then(() => {
        this.editQuickEntryProductDetails(data.quickEntryItems);
      });
    });
  }
  setForm(): FormGroup {
    return this.formBuilder.group({
      type: new FormControl<number | null>(null, [Validators.required]),
      customerSupplierId: new FormControl<number | null>(null),
      reference: new FormControl<string | null>(''),
      account: new FormControl('', [Validators.required]),
      description: new FormControl<string | null>(''),
      invoiceDate: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      netAmount: new FormControl<number | null>(null, [Validators.required]),
      vatRate: new FormControl<number | null>(null),
      vatAmount: new FormControl<number | null>(null),
      amount: new FormControl<number | null>(null, [Validators.required]),
      paymentDate: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      paymentAccountId: new FormControl<number | null>(null),
    });
  }
  setProductDetailsForm(addNewRow: boolean): void {
    this.formProductDetail = new FormGroup({
      productDetailArray: new UntypedFormArray([]),
    });

    this.productDetailArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;

    this.setDataSource(this.productDetailArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.productDetailArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;

    this.productDetailArray.push(this.setForm());

    if (this.productDetailArray.length > 0) {
      this.loadDropdownValues();
      this.setDisable(this.productDetailArray.length - 1);
    }
    this.setDataSource(this.productDetailArray);
  }
  setDisable(index: number): void {
    this.productDetailArray.controls[index]
      .get('type')
      ?.setValue(this.quickEntryTypeList[0].id);
    if (this.isNetToGross) {
      this.productDetailArray.controls[index].get('amount').disable();
      this.productDetailArray.controls[index].get('netAmount').enable();
    } else if (this.isGrossToNet) {
      this.productDetailArray.controls[index].get('netAmount').disable();
      this.productDetailArray.controls[index].get('amount').enable();
    }
  }
  async loadDropdownValues(): Promise<void> {
    this.getCustomerList();
    this.getSupplierList();
    this.getVatRateList();
    this.getReceiptAccountGroupList();
    this.getPaymentAccountGroupList();
    this.getPaymentAccountList();
  }
  getVatRateList(): void {
    this.spinner.show();
    this.store.dispatch(new GetVatRateList()).subscribe((res) => {
      this.spinner.hide();
      this.vatRateList = res.common.vatRate;
    });
  }
  getPaymentAccountList(): void {
    this.spinner.show();
    this.store.dispatch(new GetCustomBankAndCashAccount()).subscribe((res) => {
      this.spinner.hide();
      this.paymentAccountList = res.common.customBankAndCashAccount;
    });
  }
  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }
  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formProductDetail.get(
            'productDetailArray'
          ) as UntypedFormArray;
          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
            formArray.controls[i]
              .get('id')
              ?.setValue(Guid.EMPTY as unknown as Guid);
            formArray.controls[i].get('type')?.setValue('');
            formArray.controls[i].get('customerSupplierId')?.setValue(null);
            formArray.controls[i].get('reference')?.setValue('');
            formArray.controls[i].get('account')?.setValue(null);
            formArray.controls[i].get('description')?.setValue('');
            formArray.controls[i].get('invoiceDate')?.setValue('');
            formArray.controls[i].get('netAmount')?.setValue(0);
            formArray.controls[i].get('vatRate')?.setValue(null);
            formArray.controls[i].get('vatAmount')?.setValue(0);
            formArray.controls[i].get('amount')?.setValue(0);
            formArray.controls[i].get('paymentDate')?.setValue('');
            formArray.controls[i].get('paymentAccountId')?.setValue(null);
            this.setValidation(formArray, i);
          }
        }
      });
  }
  setValidation(formArray, index): void {
    formArray.controls[index].get('type')?.setValidators(Validators.required);
    formArray.controls[index]
      .get('account')
      ?.setValidators(Validators.required);
    formArray.controls[index]
      .get('invoiceDate')
      ?.setValidators(Validators.required);
    formArray.controls[index]
      .get('netAmount')
      ?.setValidators(Validators.required);
    formArray.controls[index].get('amount')?.setValidators(Validators.required);
    formArray.controls[index]
      .get('paymentDate')
      ?.setValidators(Validators.required);

    formArray.controls[index].get('type')?.updateValueAndValidity();
    formArray.controls[index].get('account')?.updateValueAndValidity();
    formArray.controls[index].get('invoiceDate')?.updateValueAndValidity();
    formArray.controls[index].get('netAmount')?.updateValueAndValidity();
    formArray.controls[index].get('amount')?.updateValueAndValidity();
    formArray.controls[index].get('paymentDate')?.updateValueAndValidity();
  }
  onDeleteProductDetails(index: number): void {
    this.productDetailArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;
    if (this.productDetailArray.length === 1) {
      return;
    }
    this.productDetailArray.removeAt(index);
    this.setDataSource(this.productDetailArray);
    this.amountChange();
  }
  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }
  qtyChanges(index: any): void {
    const netAmount =
      this.productDetailArray.controls[index].get('netAmount').value;
    if (
      this.productDetailArray.controls[index].get('vatRate').value !==
        undefined &&
      this.productDetailArray.controls[index].get('vatRate').value != null &&
      this.productDetailArray.controls[index].get('vatRate').value !== -1
    ) {
      const data = this.vatRateList.filter(
        (x) =>
          x.id === this.productDetailArray.controls[index].get('vatRate').value
      );

      if (data[0].codeType === VatRateCodeType.Custom) {
        this.productDetailArray.controls[index].get('vatAmount').enable();
        this.customVatAmount =
          this.productDetailArray.controls[index].get('vatAmount').value;
      } else {
        this.productDetailArray.controls[index].get('vatAmount').disable();
      }

      if (data.length > 0) {
        this.vatRate = data[0].rate;
      }

      let vatAmount;
      let amount;
      let vatRate;
      let totalNetAmount;

      vatRate = this.vatRate === undefined ? 1 : this.vatRate;
      vatAmount = !(data[0].codeType === VatRateCodeType.Custom)
        ? (+netAmount * vatRate) / 100
        : this.customVatAmount;
      amount = +netAmount + vatAmount;
      totalNetAmount = +netAmount;
      if (this.isGrossToNet) {
        amount = Number(
          this.productDetailArray.controls[index].get('amount').value
        );
        vatAmount = Number(((+amount * vatRate) / (100 + vatRate)).toFixed(2));
        totalNetAmount = amount - vatAmount;
      }

      this.productDetailArray.controls[index]
        .get('netAmount')
        ?.setValue(totalNetAmount);

      this.productDetailArray.controls[index]
        .get('vatAmount')
        ?.setValue(vatAmount.toFixed(2));

      this.productDetailArray.controls[index]
        .get('amount')
        ?.setValue(amount.toFixed(2));
      this.amountChange();
    } else {
      if (this.isGrossToNet) {
        let amount;
        amount = Number(
          this.productDetailArray.controls[index].get('amount').value
        );
        this.productDetailArray.controls[index]
          .get('netAmount')
          ?.setValue(amount);
      } else {
        const amount = netAmount;
        this.productDetailArray.controls[index].get('amount')?.setValue(amount);
        this.productDetailArray.controls[index]
          .get('netAmount')
          ?.setValue(amount);
      }
      this.productDetailArray.controls[index].get('vatAmount')?.setValue(0);
      this.amountChange();
    }
  }
  vatAmountChanges(index: any): void {
    const VatAmount =
      this.productDetailArray.controls[index].get('vatAmount').value;
    const NetAmount =
      this.productDetailArray.controls[index].get('netAmount').value;
    if (this.isGrossToNet) {
      let amount;
      let totalNetAmount;
      amount = Number(
        this.productDetailArray.controls[index].get('amount').value
      );
      totalNetAmount = amount - VatAmount;
      this.productDetailArray.controls[index]
        .get('netAmount')
        ?.setValue(totalNetAmount);
    } else {
      const amount = +VatAmount + +NetAmount;
      this.productDetailArray.controls[index].get('amount')?.setValue(amount);
    }
    this.amountChange();
  }
  amountChange(): void {
    const formArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;
    this.totalReceiptValue = 0;
    this.totalPaymentValue = 0;
    formArray?.getRawValue().forEach((x) => {
      if (x.type === 2) {
        this.totalReceiptValue = this.totalReceiptValue + Number(x.amount);
      } else {
        this.totalPaymentValue = this.totalPaymentValue + Number(x.amount);
      }
    });
  }
  onTypeSelected(index: number): void {
    this.spinner.show();
    const type = this.quickEntryTypeList.filter(
      (x) => x.id === this.productDetailArray.controls[index].get('type')?.value
    );
    this.quickEntrySelectedType = type[0].id;

    if (this.quickEntrySelectedType === 1) {
      this.accountGroupList = this.paymentAccountGroupList;
      this.customerSupplierList = this.supplierList;
    } else {
      this.accountGroupList = this.receiptAccountGroupList;
      this.customerSupplierList = this.customerList;
    }
    let invoiceDate =
      this.productDetailArray.controls[index].get('invoiceDate')?.value;
    if (this.isPaydateSameAsInvoiceDate) {
      this.productDetailArray.controls[index]
        .get('paymentDate')
        ?.setValue(invoiceDate);
      this.isPaymentDateEntered = true;
    }
    if (this.isDefaultBankSelected && invoiceDate !== null) {
      this.productDetailArray.controls[index]
        .get('paymentAccountId')
        ?.setValue(this.defaultBankAccountId);
      this.productDetailArray.controls[index].get('paymentAccountId').enable();
    } else {
      this.productDetailArray.controls[index]
        .get('paymentAccountId')
        ?.setValue(null);
    }
    this.spinner.hide();
  }
  onInvoiceDateEntered(index: any): void {
    let invoiceDate =
      this.productDetailArray.controls[index].get('invoiceDate')?.value;
    if (this.isPaydateSameAsInvoiceDate) {
      this.productDetailArray.controls[index]
        .get('paymentDate')
        ?.setValue(invoiceDate);
      this.isPaymentDateEntered = true;
    }
    if (!this.isEdit) {
      if (this.isDefaultBankSelected && invoiceDate !== null) {
        this.productDetailArray.controls[index]
          .get('paymentAccountId')
          ?.setValue(this.defaultBankAccountId);
        this.productDetailArray.controls[index]
          .get('paymentAccountId')
          .enable();
      } else {
        this.productDetailArray.controls[index]
          .get('paymentAccountId')
          ?.setValue(null);
        this.productDetailArray.controls[index]
          .get('paymentAccountId')
          .disable();
      }
    }
  }
  onPaymentDateEntered(index: any): void {
    this.isPaymentDateEntered = true;
    let paymentDate =
      this.productDetailArray.controls[index].get('paymentDate')?.value;
    if (this.isDefaultBankSelected) {
      this.productDetailArray.controls[index]
        .get('paymentAccountId')
        ?.setValue(this.defaultBankAccountId);
      this.productDetailArray.controls[index].get('paymentAccountId').enable();
    } else if (paymentDate !== null) {
      this.productDetailArray.controls[index].get('paymentAccountId').enable();
    } else if (paymentDate === null) {
      this.productDetailArray.controls[index].get('paymentAccountId').disable();
    }
  }
  editQuickEntryProductDetails(data: any): void {
    this.productDetailArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;

    this.productDetailArray.clear();

    data.forEach((item, i) => {
      this.spinner.show();
      this.productDetailArray.push(this.buildOrderItemsForm(item));
      setTimeout(() => {
        let account;
        if (item.type === 1) {
          this.accountGroupList = this.paymentAccountGroupList;
        } else {
          this.accountGroupList = this.receiptAccountGroupList;
        }
        this.accountGroupList.forEach((items) => {
          const data = items.listModels.find(
            (x: any) => x.id === item.accountId
          );
          if (data !== null && data !== undefined) {
            account = data;
          }
        });
        this.productDetailArray.controls[i].get('account').setValue(account);
        if (this.isNetToGross) {
          this.productDetailArray.controls[i].get('amount').disable();
          this.productDetailArray.controls[i].get('netAmount').enable();
        } else if (this.isGrossToNet) {
          this.productDetailArray.controls[i].get('netAmount').disable();
          this.productDetailArray.controls[i].get('amount').enable();
        }
        this.spinner.hide();
      }, 1500);
    });

    this.setDataSource(this.productDetailArray);
    this.amountChange();
  }
  buildOrderItemsForm(item: any): FormGroup {
    const form = this.formBuilder.group({
      id: item.id,
      type: item.type,
      customerSupplierId: item.customerSupplierId,
      reference: item.reference,
      account: item.accountId,
      description: item.description,
      invoiceDate: item.invoiceDate,
      netAmount: item.netAmount,
      vatRate: item.vatRateId !== null ? item.vatRateId : null,
      vatAmount: item.vatAmount.toFixed(2),
      amount: item.totalAmount,
      paymentDate: item.payDate,
      paymentAccountId:
        item.paymentAccountId !== this.defaultGuid
          ? item.paymentAccountId
          : null,
    });
    this.quickEntrySelectedType = item.type;
    return form;
  }

  onSearch(event: any, i: number): void {
    this.accountGroupList = [];
    this.quickEntrySelectedType =
      this.productDetailArray.controls[i].get('type')?.value;
    if (this.quickEntrySelectedType === 1) {
      this.tempAccountGroupList = this.paymentAccountGroupList;
    } else {
      this.tempAccountGroupList = this.receiptAccountGroupList;
    }
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    if (list.length === 0) {
      this.productDetailArray.controls[i].get('account').setValue(null);
      this.accountGroupList = this.tempAccountGroupList;
    } else {
      this.productDetailArray.controls[i]
        .get('account')
        .setValue(list[0].listModels[0]);
    }
  }
  resetAccountList(element: any, index: number): void {
    this.quickEntrySelectedType =
      this.productDetailArray.controls[index].get('type')?.value;
    if (this.quickEntrySelectedType === 1) {
      this.accountGroupList = this.paymentAccountGroupList;
    } else {
      this.accountGroupList = this.receiptAccountGroupList;
    }
    if (
      element.account !== null &&
      element.account !== undefined &&
      element.account.value !== null &&
      element.account.value !== undefined
    ) {
      this.scrollIntoView(element.account.value);
    }
  }

  onAccountSearch(event: any, index: number): void {
    this.accountGroupList = [];
    this.quickEntrySelectedType =
      this.productDetailArray.controls[index].get('type')?.value;
    if (this.quickEntrySelectedType === 1) {
      this.tempAccountGroupList = this.paymentAccountGroupList;
    } else {
      this.tempAccountGroupList = this.receiptAccountGroupList;
    }
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    this.accountGroupList = list;
  }
  getOptionText(option) {
    return option?.name;
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }
  getCustomerList(): void {
    let quickEntryType = 2;
    if (this.quickEntrySelectedType !== null) {
      this.spinner.show();
      this.store
        .dispatch(new GetCustomerSupplierList(quickEntryType))
        .subscribe((res) => {
          this.customerList = res.quickentry.customerSupplierList;
          this.customerSupplierList = this.customerList;
          this.spinner.hide();
        });
    }
  }
  getSupplierList(): void {
    let quickEntryType = 1;
    if (this.quickEntrySelectedType !== null) {
      this.spinner.show();
      this.store
        .dispatch(new GetCustomerSupplierList(quickEntryType))
        .subscribe((res) => {
          this.supplierList = res.quickentry.customerSupplierList;
          this.customerSupplierList = this.supplierList;
          this.spinner.hide();
        });
    }
  }
  getCustomerOrSupplierList(index: number): void {
    this.quickEntrySelectedType =
      this.productDetailArray.controls[index].get('type')?.value;
    if (this.quickEntrySelectedType === 1) {
      this.customerSupplierList = this.supplierList;
    } else {
      this.customerSupplierList = this.customerList;
    }
  }
  onClickAccountList(index: number): void {
    this.quickEntrySelectedType =
      this.productDetailArray.controls[index].get('type')?.value;
    if (this.quickEntrySelectedType === 1) {
      this.accountGroupList = this.paymentAccountGroupList;
    } else {
      this.accountGroupList = this.receiptAccountGroupList;
    }
  }
  async getReceiptAccountGroupList(): Promise<void> {
    this.spinner.show();
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Turnover);
    groupNames.push(GroupNames.OtherInterestReceivableAndSimilarIncome);
    groupNames.push(GroupNames.OtherOperatingIncome);

    await this.store
      .dispatch(new GetGroupAccountsBasedOnGroupId(groupNames, Modules.Receipt))
      .toPromise()
      .then((res) => {
        if (res.common.accountGroupList.length > 0) {
          this.receiptAccountGroupList = res.common.accountGroupList;
          this.accountGroupList = res.common.accountGroupList;
          this.spinner.hide();
        }
      });
  }
  async getPaymentAccountGroupList(): Promise<void> {
    this.spinner.show();
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.CostOfSales);
    groupNames.push(GroupNames.SellingAndDistributionCosts);
    groupNames.push(GroupNames.AdministrativeExpenses);
    groupNames.push(GroupNames.InterestPayableAndSimilarCharges);
    await this.store
      .dispatch(new GetGroupAccountsBasedOnGroupId(groupNames, Modules.Payment))
      .toPromise()
      .then((res) => {
        if (res.common.accountGroupList.length > 0) {
          this.paymentAccountGroupList = res.common.accountGroupList;
          this.accountGroupList = res.common.accountGroupList;
          this.spinner.hide();
        }
      });
  }
}
