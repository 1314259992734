import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuotationModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuotationService {
  constructor(private http: HttpClient) {}

  createQuotation(quotation: QuotationModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Quotation/save`,
      JSON.stringify(quotation),
      headers
    );
  }

  getDataByQuotationId(quotationId: Guid): Observable<QuotationModel> {
    return this.http.get<QuotationModel>(
      `${environment.apiVersionUrl}Quotation/get/${quotationId}`
    );
  }

  deleteQuotation(quotationIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(quotationIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Quotation/delete`,
      options
    );
  }

  copyQuotation(quotationIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Quotation/copyQuotation`,
      JSON.stringify(quotationIds),
      headers
    );
  }

  archiveAndRestoreQuotation(
    quotationIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Quotation/archive/${isArchive}`,
      JSON.stringify(quotationIds),
      options
    );
  }

  getQuotationNumber(): Observable<any> {
    return this.http.get(
      `${environment.apiVersionUrl}Quotation/getQuotationNo`,
      {
        responseType: 'text',
      }
    );
  }
}
