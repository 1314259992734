<div class="mat-dialog-wrapper" style="width: 600px">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">
      Amount Assign To {{ !receiptData.isCredit ? "Supplier" : "Customer" }}
    </h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onNoClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div>
      <table aria-describedby="Assign Receipt Table">
        <tr>
          <td class="wp-50">
            <table class="mat-table" aria-describedby="Assign Receipt Table">
              <tbody>
                <tr class="mat-row">
                  <td class="mat-cell w-120">
                    {{ !receiptData.isCredit ? "Supplier" : "Customer" }} Name
                  </td>
                  <td class="mat-cell">:</td>
                  <td class="mat-cell">{{ receiptData.accountName }}</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td class="wp-50">
            <table class="mat-table" aria-describedby="Assign Receipt Table">
              <tbody>
                <tr class="mat-row">
                  <td class="mat-cell w-120">Total Amount</td>
                  <td class="mat-cell">:</td>
                  <td class="mat-cell text-align-right">
                    £ {{ receiptData.foreignTotalAmount | numberPipe }}
                  </td>
                </tr>
                <tr class="mat-row">
                  <td class="mat-cell">Advance Amount</td>
                  <td class="mat-cell">:</td>
                  <td class="mat-cell text-align-right">
                    £ {{ receiptData.foreignDueAmount | numberPipe }}
                  </td>
                </tr>

                <tr class="mat-row">
                  <td class="mat-cell">Date</td>
                  <td class="mat-cell">:</td>
                  <td class="mat-cell text-align-right">
                    {{ receiptData.receiptDate | date: "dd-MMM-yyyy" }}
                  </td>
                </tr>
                <tr class="mat-row">
                  <td class="mat-cell">Ref. No</td>
                  <td class="mat-cell">:</td>
                  <td class="mat-cell text-align-right">
                    {{ receiptData.code }}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </table>
    </div>

    <div class="d-flex gap-40 mt-10">
      <div class="form-field wp-50">
        <ng-select
          #selectCurrency
          (focus)="selectCurrency.open()"
          (valueChange)="selectCurrency.close()"
          [disableOptionCentering]="true"
          [(ngModel)]="selectedCurrency"
          dropdownPosition="bottom"
          panelClass="mat-select-position"
          (change)="getDueInvoiceRefund()"
          required
        >
          <ng-option
            *ngFor="let cl of clientCurrencyList"
            [value]="cl.currencyId"
          >
            <div title="{{ cl.currency }}">
              {{ cl.currency }}
            </div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <mat-accordion>
      <mat-expansion-panel
        [expanded]="true"
        *ngIf="receiptData.receiptItems.length > 0"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-icons-outlined" class="text-primary"
              >assured_workload</mat-icon
            >
            <div class="d-flex flex-column">
              <span>Invoices Allocated</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <table
            mat-table
            class="mat-table"
            [dataSource]="receiptData.receiptItems"
            aria-describedby="Allocated-Data"
          >
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>Date</th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div class="clamp">
                  {{ element.invoiceEntryDate | date: "dd-MMM-yyyy" }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="invoiceNo">
              <th mat-header-cell *matHeaderCellDef>Invoice No</th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div class="clamp">
                  {{ element.invoiceNo }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="totalAmount">
              <th mat-header-cell *matHeaderCellDef>Total Amount</th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div class="clamp">
                  {{ receiptData.symbol }}
                  {{ element.invoiceForeignTotalAmount | numberPipe }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="allocated">
              <th mat-header-cell *matHeaderCellDef>Allocated</th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div class="clamp">
                  {{ receiptData.symbol }}
                  {{ element.foreignAmount | numberPipe }}
                </div>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayAllocatedInvoiceColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayAllocatedInvoiceColumns"
            ></tr>
          </table>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-icons-outlined" class="text-primary"
              >euro</mat-icon
            >
            <div class="d-flex flex-column">
              <span>Invoices Due</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mat-table">
          <table
            mat-table
            [dataSource]="dueInvoicesReceipt"
            aria-describedby="Allocation-Refund-Data"
          >
            <ng-container matColumnDef="invoiceNo">
              <th mat-header-cell *matHeaderCellDef>Invoice No</th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div class="clamp">
                  {{ element.invoiceNo }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>Amount</th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div class="clamp">
                  {{ element.totalAmount | numberPipe }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="due">
              <th mat-header-cell *matHeaderCellDef>Due</th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div class="clamp">
                  {{ element.dueAmount | numberPipe }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="amountToAllocate">
              <th mat-header-cell *matHeaderCellDef class="w-100">
                Amount To Allocate
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-form-field>
                  <input
                    allow_10_2_Decimal
                    matInput
                    autocomplete="off"
                    [(ngModel)]="element.allocatedAmount"
                    (blur)="onAllocationAmountChange(element)"
                    [max]="element.dueAmount"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayAllocationRefundColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayAllocationRefundColumns"
            ></tr>
          </table>
          <div *ngIf="dueInvoiceNoDataFound" class="text-align-center mt-10">
            No records found
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="mt-10 pull-right">
      <table class="table table-hover" aria-describedby="Assign Receipt Table">
        <tbody>
          <tr>
            <td>Total Allocated Amount</td>
            <td>
              <span class="fw-bold ml-5">
                {{ totalAllocatedAmount | numberPipe }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      class="action-button primary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onSaveClick()"
    >
      Save
    </button>
    <button
      class="action-button secondary-button mr-1"
      mat-dialog-close
      type="button"
      mat-button
      disableRipple
      (click)="onNoClick()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
