import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModuleName, Modules } from '@app/core/Enum';
import { FontSize } from '@app/core/Enum/font-size';
import { GlobalComponent } from '@app/core/Models';
import { AnnualReportModel } from '@app/core/Models/report/annual-report/annual-report';
import { CommonService, ModulePermission } from '@app/core/Services';
import { GetAnnualReportContent } from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import {
  DocumentEditorComponent,
  DocumentEditorContainerComponent,
  EditorHistoryService,
  EditorService,
  FormatType,
  PrintService,
  SelectionService,
  SfdtExportService,
  ToolbarService,
} from '@syncfusion/ej2-angular-documenteditor';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-annual-report',
  templateUrl: './annual-report.component.html',
  styleUrls: ['./annual-report.component.scss'],
  providers: [
    ToolbarService,
    PrintService,
    SelectionService,
    EditorService,
    EditorHistoryService,
    SfdtExportService,
  ],
})
export class AnnualReportComponent implements OnInit {
  @ViewChild('iframe') iframe: ElementRef;
  annualForm: FormGroup;
  fontColors: any = '#6d41a1';
  annualReportContent = '';
  isIframeHidden = true;

  moduleId: number;
  headerText: string;

  accountingPeriod: Guid;
  periodicDateList: any;
  accountingId: any;
  showDocument = false;

  id = Guid.EMPTY as unknown as Guid;
  annualData: AnnualReportModel;

  fontSizeList = FontSize;
  fontFamilies = [
    'Arial',
    'sans-serif',
    'Times New Roman',
    'Verdana',
    'Courier New',
  ];

  hostUrl: string =
    'https://services.syncfusion.com/angular/production/api/documenteditor/';

  @ViewChild('documenteditor_default')
  public container!: DocumentEditorContainerComponent;

  store: Store;
  commonService: CommonService;

  constructor(
    private modulePermission: ModulePermission,
    public dialog: MatDialog,
    private router: Router,
    private spinner: NgxSpinnerService,
    private renderer: Renderer2,
    private globalComponent: GlobalComponent,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.setForm();
    this.setPeriodDate();
    this.spinner.hide();
    this.modulePermission.permissionData.subscribe((value) => {
      this.moduleId = value.data.id;

      if (value.data.url === this.router.url.slice(1)) {
        this.getHeaderText();
      }
    });
  }

  setForm(): void {
    this.annualForm = new FormGroup({
      fontSize: new FormControl(FontSize.font16, [Validators.required]),
      fontFamily: new FormControl(this.fontFamilies[0], [Validators.required]),
      fontColor: new FormControl(this.fontColors, [Validators.required]),
    });
  }

  getHeaderText(): void {
    switch (this.moduleId) {
      case Modules.CompanyOverview:
        this.headerText = ModuleName.companyOverview;
        break;
      case Modules.SalesPerformance:
        this.headerText = ModuleName.salesPerformance;
        break;
      case Modules.ExpensePerformance:
        this.headerText = ModuleName.expensePerformance;
        break;
    }
  }

  onAccountPeriodChange(): void {
    this.accountingId = this.accountingPeriod;
  }

  setPeriodDate(): void {
    this.periodicDateList = this.globalComponent.getFinancialPeriod();
    this.accountingPeriod = this.periodicDateList[0].id;
  }

  generateReport(): void {
    if (this.annualForm.invalid) {
      this.commonService.addValidation(this.annualForm, this.renderer);
    } else {
      this.spinner.show();
      this.accountingId = this.accountingPeriod;
      this.annualData = {
        fontSize: this.annualForm.controls.fontSize.value.toString(),
        fontFamily: this.annualForm.controls.fontFamily.value,
        color: this.annualForm.controls.fontColor.value,
      };

      this.store
        .dispatch(
          new GetAnnualReportContent(
            this.accountingId,
            this.annualData,
            this.moduleId
          )
        )
        .pipe()
        .subscribe((res) => {
          this.showDocument = true;
          setTimeout(() => {
            this.container?.documentEditor.open(res.annualReport.getContent);
          }, 500);
        });
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  onPrint(): void {
    (this.container.documentEditor as DocumentEditorComponent).print();
  }

  onDownloadClick(format: string = 'Docx'): void {
    this.container.documentEditor.save(
      this.container.documentEditor.documentName === ''
        ? 'sample'
        : this.container.documentEditor.documentName,
      format as FormatType
    );
  }
}
