import { UserModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CreateUser {
  static readonly type = '[Permission] Create User';

  constructor(public user: UserModel) {}
}

export class GetUser {
  static readonly type = '[Permission] Get User';
}

export class GetRole {
  static readonly type = '[Permission] Get Role';
}

export class GetPermissionDataByUserId {
  static readonly type = '[Permission] Get Permission Data By UserId';

  constructor(public userId: Guid) {}
}

export class SaveUserModulePermission {
  static readonly type = '[Permission] Save User Module Permission';

  constructor(public data: Array<any>, public userId: Guid) {}
}
