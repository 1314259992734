import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import {
  CheckDuplicateBy,
  CompanyType,
  MaxLength,
  ModuleName,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import {
  AccountingPeriodModel,
  Client,
  CompanyAddressDetailModel,
  CompanyContactDetailModel,
  CompanyListModel,
  CompanyParameters,
  CompanyRegionalDetailModel,
  CompanyVatDetailModel,
  Contacts,
  Country,
  Currency,
  MainListParameters,
  SideListModel,
  VatRateScheme,
} from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import { DuplicateExistModel } from '@app/core/Models/common/title';
import {
  CommonState,
  CompanyState,
  CountryState,
  CreateCompany,
  FileUpload,
  GetAccountingMethodList,
  GetCompanyNo,
  GetCompanyType,
  GetCountryList,
  GetCurrencyList,
  GetDataByCompanyId,
  GetFileAsByte,
  GetManageCompanyList,
  GetVatReturnTypeList,
  GetVatSchemeList,
  IsDuplicateExist,
  MenuState,
} from '@app/core/Store';
import {
  AddBranchComponent,
  AddClosePopupComponent,
  AddDepartmentComponent,
  AddFinancialYearComponent,
} from '@app/modules/common';

import { Select, Store } from '@ngxs/store';
import { Buffer } from 'buffer';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

export const MY_DATE_FORMATS = {
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss'],
})
export class AddClientComponent implements OnInit {
  isCommon: boolean = false;
  addVATrecord: boolean = false;
  isTrader: boolean = false;

  clientId = Guid.EMPTY as unknown as Guid;
  defaultUniversalId = Guid.EMPTY as unknown as Guid;
  companyTypeId = 1;
  moduleId = Modules.Clients;
  moduleName = ModuleName.Clients;
  nextCode = '';
  isShowSideListAction = false;
  istoggleSideList = false;
  clientForm: FormGroup;
  listParameters: MainListParameters = new MainListParameters();
  @Output()
  readonly reloadSideList = new EventEmitter<any>();
  clientData: Client;
  companyList: CompanyListModel[];
  companySearchText = '';
  clientTypeList: any[] = [];
  countryList: Country[];
  commonNotificationText = NotificationTextMessage;
  vatReturnTypeList: SideListModel[];
  vatSchemeList: VatRateScheme[];
  companyData: Client;
  addressDetail: CompanyAddressDetailModel;
  contactDetail: CompanyContactDetailModel;
  vatDetail: CompanyVatDetailModel;
  regionalDetail: CompanyRegionalDetailModel;
  titleList: any[] = [];
  maxLength = MaxLength;
  primaryContact = {
    id: 0,
  };
  emailPattern = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$';
  emailRegExp =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  selectedYear: number = new Date().getFullYear();
  monthList: string[] = [];
  dayList: any[] = [];
  companyType = CompanyType;
  accountingPeriods: AccountingPeriodModel[] = [];
  department: any[] = [];
  branch: any[] = [];
  accountingMethodList: SideListModel[];
  currencyList: Currency[];
  isRegisteredForVat = false;
  selectedFile: File;
  fileList: File[];
  fileUploadRequestModel: any;
  fileUploadResponseModel: any;
  fileURL = '';
  acceptedFileTypes = ['.jpg', '.jpeg', '.png'];
  @ViewChild('fileupload') fileUpload: ElementRef<HTMLInputElement>;
  isDuplicateClientCode = false;

  //#region branch and department
  isAddBranchDep = false;
  tooggleBranchDepText = 'No';
  //#endregion

  //#region address details
  formAddressDetail: UntypedFormGroup;
  addressDetailArray: any;
  addressTableDataSource: MatTableDataSource<AbstractControl>;
  triggerEditFinancialData: Subject<any> = new Subject<any>();
  triggerEditDepartmentData: Subject<any> = new Subject<any>();
  triggerEditBranchData: Subject<any> = new Subject<any>();
  triggerSetDefaultBranch: Subject<any> = new Subject<any>();
  tooggleText = 'No';

  displayAddressDetailColumns: string[] = [
    'addressType',
    'address',
    'city',
    'county',
    'country',
    'postalCode',
  ];
  //#endregion

  //#region contact information
  formContactDetail: UntypedFormGroup;
  contactDetailArray: any;
  contactTableDataSource: MatTableDataSource<AbstractControl>;

  contactDetailIds: Array<number> = [];
  displayContactDetailColumns: string[] = ['name', 'email', 'phone'];
  contactDetailList?: Contacts[];
  //#endregion
  subscriptionRouting: Subscription;
  headerText = '';

  @ViewChild(AddFinancialYearComponent, { static: false })
  financialYearDetail;

  @ViewChild(AddBranchComponent, { static: false })
  branchDetails;

  @ViewChild(AddDepartmentComponent, { static: false })
  departmentDetails;
  isImageSelected = false;
  url: any;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  spinner: NgxSpinnerService;
  commonService: CommonService;
  businessStartDate:Date=new Date();
  notifier: NotificationService;
  constructor(
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private _Activatedroute: ActivatedRoute,
    private renderer: Renderer2,
    private injector: Injector
  ) {
    this.commonService = injector.get<CommonService>(CommonService);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.notifier = injector.get<NotificationService>(NotificationService);
    
  }

  ngOnInit(): void {
    this.getClientNumber();
    this.getVatSchemeList();
    this.getVatReturnType();
    this.setForm();
    this.bindMonthList();
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.clientId = Buffer.from(
            params.get('id')!,
            'base64'
          ).toString() as unknown as Guid;

          if (
            this.clientId !== undefined &&
            this.clientId !== null &&
            this.clientId !== (Guid.EMPTY as unknown as Guid)
          ) {
            this.editClient();
          }
        } else {
          this.clientId = Guid.EMPTY as unknown as Guid;
          this.headerText = 'Add New Client';
        }
      }
    );
  }

  editClient(): void {
    this.store
      .dispatch(new GetDataByCompanyId(this.clientId))
      .subscribe((res) => {
        this.headerText = `${res.company.companyData.name} (${res.company.companyData.companyRefNo})`;
        this.setEditClientData(res.company.companyData);
        if(res.company.companyData.logo){
          this.fileURL = res.company.companyData.logo;
          this.isImageSelected = true;
          this.store.dispatch(new GetFileAsByte(this.fileURL)).subscribe(
            (res) => {
              this.url = res.common.fileByteUrl;
            },
            (error) => {
              this.url = '';
              this.isImageSelected = false;
            }
          );
        }else{
          this.url = '';
          this.isImageSelected = false;
        }
        this.triggerEditFinancialData.next(
          res.company.companyData.accountingPeriods
        );
        this.triggerEditBranchData.next(res?.company?.companyData?.branchs);
        this.triggerEditDepartmentData.next(
          res.company.companyData.departments
        );
      });
  }

  setEditClientData(res): void {
    this.clientId = res?.id;
    this.isAddBranchDep = res.isBranchDepartmentAccounting;
    this.isRegisteredForVat = res?.isVatRegistered;
    this.tooggleBranchDepText = this.isAddBranchDep ? 'Yes' : 'No';
    this.tooggleText = this.isRegisteredForVat ? 'Yes' : 'No';
    this.businessStartDate=res?.establishmentDate;
    this.updateVatValidation();
    this.clientForm.patchValue({
      // fullName:res.name,
      address: res?.address,
      companyNumber: res?.registrationNo,
      companyTypeId: res?.companyTypeId,
      companyName: res.name,
      //  vatNumber: res?.vatnumber,
      clientCode: res?.companyRefNo,
      //paymentDue: res?.paymentDueTermsId,
      name: res?.contactDetail?.name,
      email: res?.contactDetail?.email,
      phone: res?.contactDetail?.phone1,
      title: res?.title,
      currency: res?.regionalDetail?.currencyId,
      taxRefNo: res?.taxRefNo,
      accountOfficeRefNumber: res?.accountOfficeRefNo,
      payeRefNumber: res?.payeRefNo,
      addToAllFutureUsers: res?.addToAllFutureUsers ?? false,
      addToAllExistingUsers: res?.addToAllExistingUsers,
      businessStartDate: res?.establishmentDate,
      bookStartDate: res?.bookStartDate,
      yearEndMonth: res?.yearEndMonth,
      yearEndDate: res?.yearEndDate,
      utrNumber: res?.utrnumber,
      vatSchemeId: res?.vatDetail?.vatSchemeId,
      vatRegistrationNo: res?.vatDetail?.vatRegistrationNo,
      vatRegistrationDate: res?.vatDetail?.vatRegistrationDate,
      vatReturnTypeId: res?.vatDetail?.vatReturnTypeId,
      assignAsDirector: res?.assignAsDirector,
      isShowVat: res?.isShowVat,
      nationalInsuranceNo: res?.nationalInsuranceNo,
      // dateOfBirth: res?.dateOfBirth,
    });

    this.companyTypeId = res?.companyTypeId;

    this.editContactDetails(res?.contactDetail);
    this.editAddressDetails(res?.addressDetail);
  }

  setForm(): void {
    this.clientForm = this.formBuilder.group({
      clientCode: new FormControl<string | null>('', Validators.required),
      companyName: new FormControl<string | null>('', Validators.required),
      businessStartDate: new FormControl(new Date(), Validators.required),
      bookStartDate: new FormControl(new Date(), Validators.required),
      yearEndMonth: new FormControl<number | null>(3),
      yearEndDate: new FormControl<number | null>(31),
      companyTypeId: new FormControl<number | null>(1),
      companyNumber: new FormControl<string | null>('', [
        Validators.maxLength(8),
        Validators.pattern(/^(?:\d{8}|[a-zA-Z]{2}\d{6})$/),
      ]),
      utrNumber: new FormControl<string | null>(null),
      vatSchemeId: new FormControl(),
      vatReturnTypeId: new FormControl(),
      vatRegistrationNo: new FormControl(''),
      taxRefNo: new FormControl('', [
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
      accountOfficeRefNumber: new FormControl(
        '',
        Validators.pattern(
          /^(((?!000)[0-9]{3}P[A-Z]([0-9]){8})|(961P[A-Z]0[0-9]{6}X))$/
        )
      ),
      payeRefNumber: new FormControl<string | null>(
        '',
        Validators.pattern(/^\d{3}\/[A-Z0-9]{1,10}$/)
      ),
      vatRegistrationDate: new FormControl(new Date()),
      vatSubmitTypeId: new FormControl<number | null>(null),
      nationalInsuranceNo: new FormControl<string | null>(''),
      dateOfBirth: new FormControl<string | null>(null),
      accountingMethodId: new FormControl(Validators.required),
      currency: new FormControl({ value: '', disabled: true }),
      addToAllFutureUsers: new FormControl<boolean>(false),
      addToAllExistingUsers: new FormControl<boolean>(false),
    });
    this.getTitle();
    this.getClientTypes();
    this.getCountry();
    this.getCurrency();
    this.getAccountingMethod();
    this.getCompanyList(this.companySearchText);
    this.setContactForm(true);
    this.setAddressForm(true);
  }

  getClientNumber(): void {
    this.store.dispatch(new GetCompanyNo()).subscribe((res) => {
      this.nextCode = res.company.companyNo;
      this.clientForm.controls.clientCode.setValue(res.company.companyNo);
    });
  }

  getClientTypes(): void {
    this.store.dispatch(new GetCompanyType()).subscribe((res) => {
      this.clientTypeList = res.company.companyType;
      if (this.clientTypeList.length > 0) {
        this.companyTypeId = this.clientTypeList[0].id;
      }
    });
  }

  getCurrency(): void {
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          if (this.currencyList.length > 0) {
            this.clientForm.controls.currency.setValue(
              this.currencyList[122].id
            );
          }
        })
      )
      .subscribe();
  }

  getAccountingMethod(): void {
    this.store
      .dispatch(new GetAccountingMethodList())
      .pipe(
        tap((res) => {
          this.accountingMethodList = res.company.accountingMethod;
          if (this.accountingMethodList.length > 0) {
            this.clientForm.controls.accountingMethodId.setValue(
              this.accountingMethodList[0].id
            );
          }
        })
      )
      .subscribe();
  }

  //#region address details

  getCountry(): void {
    this.store
      .dispatch(new GetCountryList())
      .pipe(
        tap(() => {
          this.countryList = this.store.selectSnapshot(CountryState.getCountry);
        })
      )
      .subscribe();
  }

  onCompanySelection(event: any): void {
    this.clientForm.controls.companyNumber.setValue(
      this.companyList.find((x) => x.name === event.option.value)?.companyNumber
    );

    this.addressDetailArray.controls[0]['controls'].address.setValue(
      this.companyList.find((x) => x.name === event.option.value)?.address
    );

    this.addressDetailArray.controls[0]['controls'].city.setValue(
      this.companyList.find((x) => x.name === event.option.value)?.city
    );

    this.addressDetailArray.controls[0]['controls'].county.setValue(
      this.companyList.find((x) => x.name === event.option.value)?.county
    );

    const countryid = this.companyList.find(
      (x) => x.name === event.option.value
    )?.countryId;

    this.addressDetailArray.controls[0]['controls'].country.setValue(
      this.countryList.find((x) => x.id === countryid)?.id ?? 206
    );

    this.addressDetailArray.controls[0]['controls'].postalCode.setValue(
      this.companyList.find((x) => x.name === event.option.value)?.postalCode
    );
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  addValidationsForContact(isPrimary: boolean, index): void {
    if (isPrimary) {
      this.contactDetailArray.controls[index]['controls'].name.setValidators([
        Validators.required,
      ]);
      this.contactDetailArray.controls[index][
        'controls'
      ].name.updateValueAndValidity();
      this.contactDetailArray.controls[index]['controls'].email.setValidators([
        Validators.required,
        Validators.pattern(this.emailRegExp),
      ]);
      this.contactDetailArray.controls[index][
        'controls'
      ].email.updateValueAndValidity();
      this.contactDetailArray.controls[index]['controls'].phone.setValidators([
        Validators.required,
      ]);
      this.contactDetailArray.controls[index][
        'controls'
      ].phone.updateValueAndValidity();
    } else {
      this.contactDetailArray.controls[index][
        'controls'
      ].name.clearValidators();
      this.contactDetailArray.controls[index][
        'controls'
      ].name.updateValueAndValidity();
      this.contactDetailArray.controls[index][
        'controls'
      ].email.clearValidators();
      this.contactDetailArray.controls[index][
        'controls'
      ].email.updateValueAndValidity();
      this.contactDetailArray.controls[index][
        'controls'
      ].phone.clearValidators();
      this.contactDetailArray.controls[index][
        'controls'
      ].phone.updateValueAndValidity();
    }
  }

  isPrimarySelected(index): boolean {
    return this.primaryContact.id === index;
  }

  onSelectionChange(event: any): void {
    if (event.value === '0') {
      this.isTrader = false;
    }
    if (event.value === '1') {
      this.isTrader = true;
    }
    if (event.value === '2') {
      this.isTrader = false;
    }
    if (event.value === '3') {
      this.isTrader = false;
    }

    this.companyTypeId = +event.value;
  }

  getTitle(): void {
    this.titleList = [
      {
        id: '1',
        name: 'Mr',
      },
      {
        id: '2',
        name: 'Mrs',
      },
      {
        id: '3',
        name: 'Miss',
      },
      {
        id: '4',
        name: 'Ms',
      },
    ];
  }

  onVatDetails(): void {
    this.addVATrecord = !this.addVATrecord;
  }

  getCompanyList(companySearchText: string): void {
    this.store
      .dispatch(
        new GetManageCompanyList(this.getCompanyParameters(companySearchText))
      )
      .subscribe((res) => {
        this.companyList = res.company.manageCompany;
      });
  }

  getVatSchemeList(): void {
    this.store
      .dispatch(new GetVatSchemeList())
      .pipe(
        tap((res) => {
          this.vatSchemeList = res.company.vatSchemeList;

          if (this.vatSchemeList.length > 0) {
            this.clientForm.controls.vatSchemeId.setValue(
              this.vatSchemeList[0].id
            );
          }
        })
      )
      .subscribe();
  }

  getVatReturnType(): void {
    this.store
      .dispatch(new GetVatReturnTypeList())
      .pipe(
        tap((res) => {
          this.vatReturnTypeList = res.company.vatReturnTypeList;

          if (this.vatReturnTypeList.length > 0) {
            this.clientForm.controls.vatReturnTypeId.setValue(
              this.vatReturnTypeList[0].id
            );
          }
        })
      )
      .subscribe();
  }

  getCompanyParameters(companySearchText: string) {
    const queryParams: CompanyParameters = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      companyName: companySearchText,
    };

    return queryParams;
  }

  onCompanySearch(event: any): void {
    this.companySearchText = this.clientForm.controls.companyName.value;
    this.listParameters.pageNumber = 1;
    this.getCompanyList(this.companySearchText);
  }

  dataSubmit(): boolean {
    this.spinner.show();

    try {
      this.contactDetailArray?.getRawValue().forEach((x) => {
        this.contactDetail = {
          name: x.name,
          phone1: x.phone,
          phone2: '',
          email: x.email,
          alternateEmail: '',
          website: '',
        };
      });

      this.addressDetailArray?.getRawValue().forEach((x) => {
        this.addressDetail = {
          address1: x.address,
          address2: '',
          address3: '',
          city: x.city,
          country: x.country,
          postalCode: x.postalCode,
          county: x.county,
        };
      });

      this.accountingPeriods = [];
      this.department = [];
      this.branch = [];
      this.financialYearDetail.financialYearArray
        ?.getRawValue()
        .forEach((element) => {
          if (element.id || (element.startDate && element.endDate)) {
            let data: AccountingPeriodModel = {
              id: element.id,
              fromDate: this.datepipe
                .transform(element.startDate, 'yyyy-MM-dd')
                ?.toString(),
              toDate: this.datepipe
                .transform(element.endDate, 'yyyy-MM-dd')
                ?.toString(),
              isLocked: element.isLocked,
            };
            this.accountingPeriods.push(data);
          }
        });
      if (this.isAddBranchDep) {
        this.departmentDetails.departmentArray
          ?.getRawValue()
          .forEach((element) => {
            if (element.name !== '') {
              let data: any = {
                id: element.id,
                name: element.name,
                isDefault: element.isDefault,
                brancheSrNos: element.branchName,
              };
              this.department.push(data);
            }
          });
      }

      if (this.isAddBranchDep) {
        this.branchDetails.branchArray?.getRawValue().forEach((element) => {
          if (element.name !== '') {
            let data: any = {
              id: element.id,
              name: element.name,
              isDefault: element.isDefault,
              srNo: element.srNo,
            };
            this.branch.push(data);
          }
        });
      }

      this.regionalDetail = {
        currencyId: +this.clientForm.controls.currency.value,
      };

      if (this.isRegisteredForVat) {
        this.vatDetail = {
          vatRegistrationNo: this.clientForm.controls.vatRegistrationNo.value,
          vatRegistrationDate: this.datepipe.transform(
            this.clientForm.controls.vatRegistrationDate.value,
            'yyyy-MM-dd'
          ),
          vatSchemeId: +this.clientForm.controls.vatSchemeId.value,
          vatReturnTypeId: +this.clientForm.controls.vatReturnTypeId.value,
        };
      }

      this.companyData = {
        id: this.clientId,
        logo: this.fileUploadResponseModel === undefined
        ? this.fileURL
        : this.fileUploadResponseModel.fileUrl,
        name: this.clientForm.controls.companyName.value,
        companyTypeId: this.companyTypeId,
        establishmentDate: this.datepipe
          .transform(
            this.clientForm.controls.businessStartDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        companyRefNo: this.clientForm.controls.clientCode.value,
        registrationNo: this.clientForm.controls.companyNumber.value,
        taxRefNo: this.clientForm.controls.taxRefNo.value,
        accountOfficeRefNo:
          this.clientForm.controls.accountOfficeRefNumber.value,
        payeRefNo: this.clientForm.controls.payeRefNumber.value,
        isVatRegistered: this.isRegisteredForVat,
        companyNo: '',
        keepBillingDetailSeparate: false,
        tradingStatus: true,
        addressDetail: this.addressDetail,
        billingDetail: null,
        contactDetail: this.contactDetail,
        regionalDetail: this.regionalDetail,
        vatDetail: this.vatDetail,
        accountingMethodId: +this.clientForm.controls.accountingMethodId.value,
        accountingPeriods: this.accountingPeriods,
        departments: this.department,
        branchs: this.branch,
        isBranchDepartmentAccounting: this.isAddBranchDep,
        addToAllFutureUsers: this.clientForm.controls.addToAllFutureUsers.value,
        addToAllExistingUsers:
          this.clientForm.controls.addToAllExistingUsers.value,
        bookStartDate: this.datepipe
          .transform(this.clientForm.controls.bookStartDate.value, 'yyyy-MM-dd')
          ?.toString(),
        yearEndMonth: this.clientForm.controls.yearEndMonth.value,
        yearEndDate: this.clientForm.controls.yearEndDate.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.clientForm.invalid) {
      this.commonService.addValidation(this.clientForm, this.renderer);
      this.commonService.onFailure(NotificationTextMessage.validationMessage);
    } else if (!this.checkContactValidation()) {
      this.commonService.addValidation(this.formContactDetail, this.renderer);
      this.commonService.onFailure(NotificationTextMessage.validationMessage);
    } else if (this.isAddBranchDep && this.branchDetails.branchArray.invalid) {
      this.branchDetails.branchArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else if (
      this.isAddBranchDep &&
      this.departmentDetails.departmentArray.invalid
    ) {
      this.departmentDetails.departmentArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else if (this.financialYearDetail.financialYearArray.invalid) {
      this.financialYearDetail.financialYearArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else {
      if (
        this.dataSubmit() &&
        this.checkContactValidation() &&
        (this.clientForm.dirty ||
          this.contactDetailArray.dirty ||
          this.addressDetailArray.dirty ||
          this.financialYearDetail.financialYearArray.dirty ||
          this.financialYearDetail.financialYearArray.valueChanges ||
          this.branchDetails.branchArray.dirty ||
          this.departmentDetails.departmentArray.dirty)
      ) {
        this.onClientSave(isExit);
      } else {
        this.spinner.hide();
        if (
          !this.clientForm.dirty &&
          !this.contactDetailArray.dirty &&
          !this.addressDetailArray.dirty &&
          !this.financialYearDetail.financialYearArray.dirty &&
          !this.branchDetails.branchArray.dirty &&
          !this.departmentDetails.departmentArray.dirty
        ) {
          this.commonService.onSuccess(NotificationTextMessage.successMessage);

          if (isExit) {
            this.setHighlightData(isExit);
            this.onCancel(false);
            this.commonService.onListRouting(this.moduleId);
          }
        }
      }
    }
  }

  onClientSave(isExit): void {
    this.store
      .dispatch(new CreateCompany(this.companyData))
      .pipe()
      .subscribe(
        (res) => {
          if (res?.company.isCompanyAdded) {
            this.onSuccesResponse(isExit);
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        },
        (err) => {
          this.commonService.onFailure(err.message);
        }
      );
  }

  onSuccesResponse(isExit): void {
    let msg: string;

    if (
      this.clientId !== undefined &&
      this.clientId !== null &&
      this.clientId !== this.defaultUniversalId
    ) {
      msg = NotificationTextMessage.clientUpdatedSuccess;
    } else {
      msg = NotificationTextMessage.clientCreatedSuccess;
    }
    if (!isExit) {
      this.setHighlightData(isExit);
      this.onCancel(true);
      this.commonService.onEditRouting(true, this.moduleId, this.clientId);

      this.reloadSideList.next(null);
    } else {
      this.setHighlightData(isExit);
      this.onCancel(false);
      this.commonService.onListRouting(this.moduleId);
    }
    //this.commonService.onSuccess(NotificationTextMessage.clientCreatedSuccess);
    if (
      this.clientId !== undefined &&
      this.clientId !== null &&
      this.clientId !== this.defaultUniversalId
    ) {
      this.commonService.onSuccess(msg);
      if (isExit) {
        history.back();
      }
    } else {
      this.commonService.onSuccess(msg);
      this.commonService.onListRouting(this.moduleId);
    }
  }

  onCancel(isCancelClick: boolean): void {
    this.clientId =
      this.store.selectSnapshot(CompanyState.getCompanyId) ??
      this.defaultUniversalId;
    if (
      isCancelClick &&
      this.clientId !== undefined &&
      this.clientId !== null &&
      this.clientId !== (Guid.EMPTY as unknown as Guid)
    ) {
      this.editClient();
    } else {
      this.clientId = Guid.EMPTY as unknown as Guid;
      this.ngOnInit();
    }
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.clientId,
      isExit,
      Modules.Clients,
      RoutingPath.AddClient
    );
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  //#region address details
  setAddressForm(addNewRow: boolean): void {
    this.formAddressDetail = new FormGroup({
      addressDetailArray: new UntypedFormArray([]),
    });

    this.addressDetailArray = this.formAddressDetail.get(
      'addressDetailArray'
    ) as UntypedFormArray;

    this.setAddressDataSource(this.addressDetailArray);
    if (addNewRow) this.createAddressDetailRow();
  }

  createAddressDetailRow(): void {
    this.addressDetailArray = this.formAddressDetail.get(
      'addressDetailArray'
    ) as UntypedFormArray;

    this.addressDetailArray.push(this.createAddressItem());

    this.setAddressDataSource(this.addressDetailArray);
  }

  createAddressItem(): FormGroup {
    return this.formBuilder.group({
      universalId: new FormControl(Guid.EMPTY as unknown as Guid),
      addressType: new FormControl<string | null>('Business'),
      address: new FormControl<string | null>(''),
      city: new FormControl<string | null>(''),
      county: new FormControl<string | null>(''),
      country: new FormControl<number | null>(206),
      postalCode: new FormControl<number | null>(null),
    });
  }

  setAddressDataSource(array: UntypedFormArray): void {
    this.addressTableDataSource = new MatTableDataSource(array.controls);
  }

  editAddressDetails(data: any): void {
    this.resetAddressForm();
    this.addressDetailArray = this.formAddressDetail.get(
      'addressDetailArray'
    ) as UntypedFormArray;

    this.addressDetailArray.push(this.buildAddressForm(data));

    this.setAddressDataSource(this.addressDetailArray);
  }

  buildAddressForm(item: CompanyAddressDetailModel): FormGroup {
    return this.formBuilder.group({
      universalId: item.universalId,
      addressType: 'Business',
      address: item.address1,
      city: item.city,
      county: item.county,
      country: item.country,
      postalCode: item.postalCode,
    });
  }

  addNewArressDetailRow(): void {
    for (let i = 0; i < 1; i++) {
      this.createAddressDetailRow();
    }
  }

  onDeleteAddressDetail(index: number, element: any): void {
    this.addressDetailArray = this.formAddressDetail.get(
      'addressDetailArray'
    ) as UntypedFormArray;
    this.addressDetailArray.removeAt(index);
    this.setAddressDataSource(this.addressDetailArray);
    this.addressDetailArray.markAsDirty();
  }

  resetAddressForm(): void {
    const frmArray = this.formAddressDetail.get(
      'addressDetailArray'
    ) as UntypedFormArray;
    frmArray.clear();
  }
  //#endregion

  //#region User Contact Details
  setContactForm(addNewRow: boolean): void {
    this.formContactDetail = new FormGroup({
      contactDetailArray: new UntypedFormArray([]),
    });

    this.contactDetailArray = this.formContactDetail.get(
      'contactDetailArray'
    ) as UntypedFormArray;

    this.setDataSource(this.contactDetailArray);
    if (addNewRow) this.createRow();
  }

  editContactDetails(data: any): void {
    this.resetContactForm();
    if (data) {
      this.contactDetailArray = this.formContactDetail.get(
        'contactDetailArray'
      ) as UntypedFormArray;

      this.contactDetailArray.push(this.buildOrderItemsForm(data));

      this.setDataSource(this.contactDetailArray);
    } else {
      this.createRow();
    }
  }

  resetContactForm(): void {
    const frmArray = this.formContactDetail.get(
      'contactDetailArray'
    ) as UntypedFormArray;
    frmArray.clear();
  }

  createRow(): void {
    this.contactDetailArray = this.formContactDetail.get(
      'contactDetailArray'
    ) as UntypedFormArray;

    this.contactDetailArray.push(this.createItem());

    this.setDataSource(this.contactDetailArray);
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(Guid.EMPTY as unknown as Guid),
      title: new FormControl<string | null>('Mr', Validators.required),
      name: new FormControl<string | null>(''),
      email: new FormControl<string | null>(''),
      country: new FormControl<number | null>(206),
      phone: new FormControl(''),
      isPrimary: new FormControl<boolean | null>(false, Validators.required),
      contactId: new FormControl<number | null>(0, Validators.required),
    });
  }

  setDataSource(array: UntypedFormArray): void {
    this.contactTableDataSource = new MatTableDataSource(array.controls);
  }

  get getContactFormControls(): any {
    let formArray = this.formContactDetail.get(
      'contactDetailArray'
    ) as UntypedFormArray;
    return formArray.controls;
  }

  buildOrderItemsForm(item: CompanyContactDetailModel): FormGroup {
    return this.formBuilder.group({
      id: item.universalId,
      title: 'Mr',
      name: new FormControl<string | null>(item.name),
      email: new FormControl<string | null>(item.email),
      country: 206,
      phone: new FormControl<string>(item.phone1),
    });
  }

  onDeleteContactDetail(index: number, element: any): void {
    if (element && element.controls.id.value > 0) {
      const id = element.controls.id.value;
      this.contactDetailIds.push(id);
    }
    this.contactDetailArray = this.formContactDetail.get(
      'contactDetailArray'
    ) as UntypedFormArray;
    if (this.contactDetailArray.length > 1) {
      this.contactDetailArray.removeAt(index);
    }
    this.setDataSource(this.contactDetailArray);
    this.primaryClick(0);
    this.contactDetailArray.markAsDirty();
  }

  primaryClick(index, isPrimaryClick?: boolean): void {
    this.primaryContact.id = index;
    this.contactDetailArray?.getRawValue().forEach((item, i) => {
      this.contactDetailArray.controls[i]['controls'].isPrimary.setValue(false);
      if (i === index) {
        item.isPrimary = true;
        this.addValidationsForContact(item.isPrimary, i);
        this.contactDetailArray.controls[i]['controls'].isPrimary.setValue(
          true
        );
      } else {
        this.addValidationsForContact(false, i);
      }
    });
    if (isPrimaryClick) {
      this.contactDetailArray.markAsDirty();
    }
  }

  addNewContactDetailRow(): void {
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\()\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateInput(event: any): void {
    const pastedValue = (
      event.clipboardData || window['clipboardData']
    ).getData('text');
    if (!pastedValue.match(/^[\d()+\- ]+$/)) {
      event.preventDefault();
    }
  }

  //#endregion

  valueChange(selectedId, event): void {
    if (!event.checked) {
      event.source.checked = true;
    }
    this.clientForm.markAsDirty();
    this.companyTypeId = +selectedId;
  }

  bindMonthList(): void {
    this.monthList = Array.from({ length: 12 }, (e, i) => {
      return new Date(0, i + 1, 0).toLocaleDateString('en', {
        month: 'long',
      });
    });

    this.bindDayList(1);
  }

  bindDayList(event: number): void {
    this.dayList = [];

    const numberOfDays = new Date(this.selectedYear, event, 0).getDate();

    for (let i = 1; i <= numberOfDays; i++) {
      this.dayList.push({ value: i, label: i });
    }
  }

  checkContactValidation(): boolean {
    let isValid = true;

    this.contactDetailArray?.getRawValue().forEach((x, i) => {
      if (this.contactDetailArray.controls[i].invalid) {
        this.spinner.hide();
        this.commonService.addValidation(
          this.contactDetailArray.controls[i],
          this.renderer
        );
        this.commonService.onFailure(NotificationTextMessage.validationMessage);
        isValid = false;
      }
    });

    return isValid;
  }

  addBranchDep(): void {
    this.isAddBranchDep = !this.isAddBranchDep;
    this.tooggleBranchDepText = this.isAddBranchDep ? 'Yes' : 'No';
    this.triggerSetDefaultBranch.next(this.isAddBranchDep);
  }

  registeredForVat(): void {
    this.isRegisteredForVat = !this.isRegisteredForVat;
    this.tooggleText = this.isRegisteredForVat ? 'Yes' : 'No';
    this.updateVatValidation();
  }

  updateVatValidation(): void {
    if (!this.isRegisteredForVat) {
      this.clientForm.controls.vatRegistrationNo.clearValidators();
      this.clientForm.controls.vatReturnTypeId.clearValidators();
      this.clientForm.controls.vatSchemeId.clearValidators();
      this.clientForm.controls.vatRegistrationDate.clearValidators();
      this.clientForm.controls.vatRegistrationNo.updateValueAndValidity();
      this.clientForm.controls.vatReturnTypeId.updateValueAndValidity();
      this.clientForm.controls.vatSchemeId.updateValueAndValidity();
      this.clientForm.controls.vatRegistrationDate.updateValueAndValidity();
    } else {
      this.clientForm.controls.vatRegistrationNo.setValidators(
        Validators.required
      );
      this.clientForm.controls.vatReturnTypeId.setValidators(
        Validators.required
      );
      this.clientForm.controls.vatSchemeId.setValidators(Validators.required);
      this.clientForm.controls.vatRegistrationDate.setValidators(
        Validators.required
      );
      this.clientForm.controls.vatRegistrationNo.updateValueAndValidity();
      this.clientForm.controls.vatReturnTypeId.updateValueAndValidity();
      this.clientForm.controls.vatSchemeId.updateValueAndValidity();
      this.clientForm.controls.vatRegistrationDate.updateValueAndValidity();
    }
  }
  businessStartdateChange():void{
   
    this.businessStartDate=this.clientForm.value.businessStartDate;
  }

  get accept(): string {
    return this.acceptedFileTypes?.join(', ') ?? '';
  }

  onFileSelected(event: any): void {
    if (!event.target.files || !event.target.files.length) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.fileAcceptMsg
      );
    }
    if (
      event.target.files[0].type !== 'image/png' &&
      event.target.files[0].type !== 'image/jpeg' &&
      event.target.files[0].type !== 'image/jpg'
    ) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.fileAcceptMsg
      );
    }
    this.selectedFile = event.target.files[0];
    if (this.selectedFile.size / 1024 / 1024 < 5) {
      this.isImageSelected = true;
      this.saveFile(event.target.files[0]);

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.url = reader.result;
      };
    } else {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.fileSize5mb
      );
    }
  }

  saveFile(file: File): void {
    this.fileList = [];
    this.fileList.push(file);

    this.fileUploadRequestModel = {
      file: this.fileList,
      attachmentType: 109,
    };

    this.store
      .dispatch(new FileUpload(this.fileUploadRequestModel))
      .pipe(
        tap((data) => {
          this.fileUploadResponseModel = data.common.fileUploadRequestModel[0];
        })
      )
      .subscribe();
  }

  onDeleteLogo(): void {
    this.isImageSelected = false;
    this.fileUpload.nativeElement.value = '';
    this.url = '';
    this.fileURL = '';
    if (this.fileUploadResponseModel) {
      this.fileUploadResponseModel.fileUrl = '';
    }
  }

  checkClietCodeDuplicateExist(): void {
    this.isDuplicateClientCode = false;
    const requestModel: DuplicateExistModel = {
      entityName: this.clientForm.controls.clientCode.value ?? '',
      moduleId: this.moduleId,
      entityId: this.clientId ?? this.commonService.defaultGuidValue,
      checkBy: CheckDuplicateBy.Code,
    };
    this.store.dispatch(new IsDuplicateExist(requestModel)).subscribe((res) => {
      this.isDuplicateClientCode = res.company.isDuplicateExist;
      if (this.isDuplicateClientCode) {
        this.commonService.onFailure(NotificationTextMessage.clientIdExist);
      }
    });
  }
  
}
