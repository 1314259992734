<div class="content-body product-detail-wrap">
  <div class="form-field-title">
    <p>Product Details</p>
  </div>
  <div class="sidenav-table sidenav-table-input wp-100">
    <div class="mat-table-wrapper product-detail-table">
      <form [formGroup]="formProductDetail">
        <ng-container formArrayName="productDetailArray">
          <table
            aria-describedby="product-list"
            mat-table
            [dataSource]="tableDataSource"
          >
            <ng-container matColumnDef="srNo">
              <th mat-header-cell *matHeaderCellDef class="w-20">Sr No.</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <div class="d-flex mt-10">
                  {{ i + 1 }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="product">
              <th mat-header-cell *matHeaderCellDef>
                {{ productText }}
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <ng-select
                  class="custwidth w-150"
                  #selectProduct
                  (focus)="selectProduct.open()"
                  (valueChange)="selectProduct.close()"
                  [disableOptionCentering]="true"
                  formControlName="productName"
                  [appendTo]="'.sidenav-table'"
                  panelClass="mat-select-position"
                  (change)="onProductChange($event, i)"
                >
                  <ng-option
                    *ngFor="let option of productTypeList"
                    [value]="option.id"
                  >
                    <span title="{{ option.name }}">
                      {{ option.name }}
                    </span>
                  </ng-option>
                </ng-select>
                <div *ngIf="discountIndex.includes(i)" class="mt-10">
                  <ng-select
                    class="custwidth w-150"
                    #selectDiscountType
                    [disableOptionCentering]="true"
                    (focus)="selectDiscountType.open()"
                    (valueChange)="selectDiscountType.close()"
                    [disableOptionCentering]="true"
                    formControlName="discountType"
                    [appendTo]="'.sidenav-table'"
                    panelClass="mat-select-position"
                    (change)="qtyChanges(i)"
                    required
                  >
                    <ng-option
                      *ngFor="let dt of discountTypeList"
                      [value]="dt.id"
                    >
                      <span title="{{ dt.name }}">
                        {{ dt.name }}
                      </span>
                    </ng-option>
                  </ng-select>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef class="w-150">Description</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="widthemail"
              >
                <mat-form-field floatLabel="never">
                  <input
                    matInput
                    [value]="element.description"
                    autocomplete="off"
                    formControlName="description"
                    maxlength="{{ maxLength.maxLength200 }}"
                  />
                </mat-form-field>
                <mat-form-field
                  class="mt-10"
                  floatLabel="never"
                  *ngIf="discountIndex.includes(i)"
                >
                  <input
                    matInput
                    [value]="element.discountDescription"
                    autocomplete="off"
                    formControlName="discountDescription"
                    maxlength="{{ maxLength.maxLength200 }}"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="qty">
              <th mat-header-cell *matHeaderCellDef>Qty</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never" class="w-60">
                  <input
                    allow_13_2_Decimal
                    matInput
                    [value]="element.qty"
                    autocomplete="off"
                    formControlName="qty"
                    (change)="qtyChanges(i)"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef class="w-150">
                Price <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field class="w-60">
                  <input
                    allow_10_2_Decimal
                    zeroNotAllowed
                    matInput
                    [value]="element.price"
                    autocomplete="off"
                    formControlName="price"
                    (change)="qtyChanges(i)"
                  />
                </mat-form-field>
                <mat-form-field
                  class="mt-10 w-60"
                  *ngIf="
                    discountIndex.includes(i) &&
                    productDetailArray.controls[i].get('discountType').value ===
                      discountTypeEnum.Value
                  "
                >
                  <input
                   
                    matInput
                    [value]="element.discountValue"
                    autocomplete="off"
                    formControlName="discountValue"
                    (change)="qtyChanges(i)"
                  />
                </mat-form-field>
                <mat-form-field
                  class="mt-10 w-60"
                  *ngIf="
                    discountIndex.includes(i) &&
                    productDetailArray.controls[i].get('discountType').value ===
                      discountTypeEnum.Percentage
                  "
                >
                  <input
                    allow_10_2_Decimal
                    zeroNotAllowed
                    appPercentageDirective
                    matInput
                    [value]="element.discountValue"
                    autocomplete="off"
                    formControlName="discountValue"
                    (change)="qtyChanges(i)"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="netAmount">
              <th mat-header-cell *matHeaderCellDef>Net Amount</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    currencyMask
                    allow_14_2_Decimal
                    matInput
                    [value]="element.netAmount"
                    autocomplete="off"
                    formControlName="netAmount"
                    maxlength="10"
                  />
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="invoiceType" >
              <th mat-header-cell *matHeaderCellDef class="w-160">
                Account Name <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
              <ng-container *ngIf="!isFixedAsset">
              <mat-form-field appearance="fill" class="w-160">
                <input
                  type="text"
                  matInput
                  formControlName="invoiceType"
                  required
                  [matAutocomplete]="accountGroup"
                  (keyup)="onAccountSearch($event,i)"
                  (keydown.Tab)="onSearch($event,i)"
                  (change)="onSearch($event,i)"
                  #trigger="matAutocompleteTrigger"
                  appAutocompletePosition="trigger"
                  (mousedown)="resetAccountListByAccount(i)"
                />
                <mat-icon class="downarrow" matSuffix>keyboard_arrow_down</mat-icon>
                <mat-autocomplete
                  [displayWith]="getOptionText"
                  #accountGroup="matAutocomplete"
                  (opened)="scrollIntoViewByAccount(i)"
                  (optionSelected)="onOptionSelected(i)"
                >
                  <mat-optgroup
                    *ngFor="let group of accountGroupList"
                    [label]="group.groupName"
                    title="{{ group.groupName }}"
                  >
                    <mat-option
                      *ngFor="let names of group.listModels"
                      [value]="names"
                      title="{{ names.name }}"
                      [class]="
                      
                      productDetailArray.controls[i]?.get('invoiceType').value?.id === names.id
                          ? 'mat-selected'
                          : ''
                      "
                    >
                      {{ names.name }}
                    </mat-option>
                  </mat-optgroup>
                </mat-autocomplete>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="isFixedAsset">
              <ng-select
              class="w-160"
              #invoiceType
              [items]="fixedAssetsTypeList"
              (focus)="invoiceType.open()"
              (valueChange)="invoiceType.close()"
              [disableOptionCentering]="true"
              formControlName="invoiceType"
              bindLabel="name"
              bindValue="id"
              groupBy="groupName"
              panelClass="mat-select-position"
              appendTo="body"
            >
              <ng-template ng-option-tmp let-item="item">
                <span class="ng-option-label" [attr.title]="item.name">{{
                  item.name
                }}</span>
              </ng-template>
            </ng-select>
            </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="vatrate" *ngIf="isVatRegistered">
              <th mat-header-cell *matHeaderCellDef>
                VAT Rate <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <ng-select
                  class="custwidth w-160"
                  [disableOptionCentering]="true"
                  formControlName="vatRate"
                  panelClass="mat-select-position"
                  [appendTo]="'.sidenav-table'"
                  (change)="qtyChanges(i);"
                  required
                >
                  <ng-option
                    *ngFor="let option of vatRateList[i]"
                    [value]="option.id"
                  >
                    <span class="clamp" title="{{ option.name }}">
                      {{ option.name }}
                    </span>
                  </ng-option>
                </ng-select>
              </td>
            </ng-container>
            <ng-container matColumnDef="vatammount" *ngIf="isVatRegistered">
              <th mat-header-cell *matHeaderCellDef>VAT Amount</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    allow_14_2_Decimal
                    matInput
                    [value]="element.vatAmount"
                    autocomplete="off"
                    formControlName="vatAmount"
                    (change)="vatAmountChanges(i)"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>
                Gross Amount <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never">
                  <input
                    currencyMask
                    allow_14_2_Decimal
                    matInput
                    [value]="element.amount"
                    autocomplete="off"
                    formControlName="amount"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="closeButton">
              <th mat-header-cell *matHeaderCellDef class="w-50"></th>
              <td
                mat-cell
                *matCellDef="let element; let i = index; let j = index"
                [formGroupName]="i"
                class="text-align-center"
              >
                <div
                  class="d-flex align-item-center justify-content-end gap-10"
                >
                  <button
                    *ngIf="
                      moduleId === moduleEnum.Invoices ||
                      moduleId === moduleEnum.RecurringInvoice ||
                      moduleId === moduleEnum.Purchase ||
                      moduleId === moduleEnum.RecurringPurchase ||
                      moduleId === moduleEnum.Quotation ||
                      moduleId === moduleEnum.FixedAssets
                    "
                    class="close-btn"
                    type="button"
                    mat-button
                    disableRipple
                    [disabled]="
                      productDetailArray.controls[i].get('price').value === 0 ||
                      productDetailArray.controls[i].get('price').value === '' 
                    "
                    (click)="onAddDiscountOnProductDetails(i)"
                    title="Add Discount"
                  >
                    <mat-icon fontSet="material-icons-outlined"
                      >discount</mat-icon
                    >
                  </button>
                  <button
                    class="close-btn"
                    type="button"
                    mat-button
                    disableRipple
                    (click)="onDeleteProductDetails(i)"
                  >
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </div>
                <div class="text-align-right">
                  <button
                    *ngIf="discountIndex.includes(i)"
                    class="close-btn mt-1"
                    type="button"
                    mat-button
                    disableRipple
                    (click)="onDeleteDiscountOnProductDetails(i)"
                    title="Delete Discount"
                  >
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayProductDetailsColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayProductDetailsColumns"
            ></tr>
          </table>
        </ng-container>
      </form>
    </div>
    <div class="mb-1 mt-1 d-flex justify-space-between">
      <div>
        <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
          >Add New Line</a
        >
        <a
          class="link mr-1"
          *ngIf="productDetailArray?.length > 0"
          href="javascript:void(0)"
          (click)="clearForm()"
          >Clear All Lines</a
        >
        <a class="link mr-1" href="javascript:void(0)" (click)="addNewProduct()"
          >Add Product</a
        >
      </div>
    </div>
  </div>
</div>
