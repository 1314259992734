import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { BudgetingModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateBudgeting,
  GetDataByBudgetingId,
  MenuState,
} from '@app/core/Store';
import {
  AddBudgetingComponent,
  BudgetingDetailListComponent,
} from '@app/modules';
import { AddClosePopupComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-budgeting',
  templateUrl: './budgeting.component.html',
  styleUrls: ['./budgeting.component.scss'],
})
export class BudgetingComponent implements OnInit {
  istoggleSideList = false;
  isShowSideListAction = false;
  isExpandAll = false;

  budgetingData: BudgetingModel;

  moduleId = Modules.Budgeting;
  moduleName = ModuleName.Budgeting;

  budgetingId = Guid.EMPTY as unknown as Guid;
  tempBudgetingId = Guid.EMPTY as unknown as Guid;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(AddBudgetingComponent, { static: true })
  budgetingDetails;

  @ViewChild(BudgetingDetailListComponent, { static: true })
  budgetingDetailsList;

  subscriptionRouting: Subscription;

  triggerEditData: Subject<any> = new Subject<any>();
  triggerGetList: Subject<any> = new Subject<any>();
  store: Store;
  commonService: CommonService;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private _Activatedroute: ActivatedRoute,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private location: Location,
    public datepipe: DatePipe,
    private renderer: Renderer2,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.budgetingId = atob(params.get('id')!) as unknown as Guid;
          this.editBudgeting();
        }
      }
    );
  }

  editBudgeting() {
    this.spinner.show();
    this.store
      .dispatch(new GetDataByBudgetingId(this.budgetingId))
      .subscribe((res) => {
        this.budgetingDetails.budgetingForm.markAsUntouched();

        this.spinner.hide();
        this.triggerEditData.next(res.budgeting.budgetingData);
      });
  }

  triggerGetDataList(event: any): void {
    this.triggerGetList.next(event);
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onSave(isExit: boolean): void {
    if (this.budgetingDetails.budgetingForm.invalid) {
      this.commonService.addValidation(
        this.budgetingDetails.budgetingForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        this.spinner.show();
        this.store
          .dispatch(new CreateBudgeting(this.budgetingData))
          .pipe()
          .subscribe(
            (res) => {
              this.spinner.hide();
              if (res !== undefined) {
                this.setHighlightData(isExit);
                if (!isExit) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.budgetingId,
      isExit,
      Modules.Budgeting,
      RoutingPath.AddBudgeting
    );
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      const gridArray: any = [];
      if (this.budgetingDetailsList.dataSource.data.length > 0) {
        this.budgetingDetailsList.dataSource.data.forEach((element) => {
          if (element[element.length - 1] === 'false') {
            gridArray.push(element);
          }
        });
      }

      this.budgetingData = {
        id: this.budgetingDetails.budgetingForm.controls.id.value,
        name: this.budgetingDetails.budgetingForm.controls.budgetName.value,
        startPeriod: this.datepipe
          .transform(
            this.budgetingDetails.budgetingForm.controls.startPeriod.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        forPeriod: this.budgetingDetails.budgetingForm.controls.forPeriod.value,
        actual: this.budgetingDetails.budgetingForm.controls.actual.value,
        branchId:
          this.budgetingDetails.budgetingForm.controls.branchId.value === ''
            ? null
            : this.budgetingDetails.budgetingForm.controls.branchId.value,
        departmentId:
          this.budgetingDetails.budgetingForm.controls.departmentId.value === ''
            ? null
            : this.budgetingDetails.budgetingForm.controls.departmentId.value,
        grid: gridArray,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.budgetingId)) {
      this.editBudgeting();
    } else {
      this.budgetingId = Guid.EMPTY as unknown as Guid;
      this.budgetingDetails.ngOnInit();
    }
  }
}
