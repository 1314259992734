<div
  class="d-flex align-items-center"
  [ngClass]="selectedRowIndex === 3 ? 'show-button' : ''"
>
  <ng-container *ngFor="let item of actions; let permissionsIndex = index">
    <button
      *ngIf="permissionsIndex < moreActionCount"
      (click)="actionClick(item.actionType)"
      mat-button
      disableRipple
      title="{{ item.name }}"
      [ngClass]="
        (((item.actionType === actionTypeEnum.Update ||
          item.actionType === actionTypeEnum.Delete) &&
          elements?.rowData === 'True') ||
        (item.actionType === actionTypeEnum.Update && elements === 'True')
          ? 'hidden'
          : '') ||
        ((this.moduleId === this.modules.Invoices ||
          this.moduleId === this.modules.Purchase ||
          this.moduleId === this.modules.CISInvoice) &&
        (item.actionType === actionTypeEnum.AddReceipt ||
          item.actionType === actionTypeEnum.BadDebtsEntry ||
          (item.actionType === actionTypeEnum.EmailWithPaymentLink &&
            this.showMailOption === 'false') ||
          item.actionType === actionTypeEnum.AddPayment) &&
        row[colName]?.rowData === 'Paid'
          ? 'hidden'
          : '') ||
        ((this.moduleId === this.modules.CreditNote ||
          this.moduleId === this.modules.DebitNote) &&
        item.actionType === actionTypeEnum.AllocateRefund &&
        row[colName]?.rowData === 'Allocated'
          ? 'hidden'
          : '')
      "
      [disabled]="
        (isViewPermission$ | async) &&
        (item.actionType === actionTypeEnum.Delete ||
          item.actionType === actionTypeEnum.Export ||
          item.actionType === actionTypeEnum.DownloadCertificate)
      "
    >
      <mat-icon fontSet="material-icons-outlined">{{ item.icon }} </mat-icon>
    </button>
    <button
      *ngIf="permissionsIndex === moreActionCount"
      mat-button
      (click)="$event.stopPropagation()"
      (menuClosed)="allowCloseOnClickOut()"
      (click)="onButtonClick(permissionsIndex)"
      [matMenuTriggerFor]="menu"
      disableRipple
    >
      <mat-icon>more_vert </mat-icon>
    </button>
  </ng-container>
  <mat-menu #menu="matMenu" xPosition="before" class="more-button-menu">
    <ng-container *ngFor="let item of actions; let permissionsIndex = index">
      <div *ngIf="permissionsIndex > 2">
        <button
          mat-menu-item
          disableRipple
          title="{{ item.name }}"
          (click)="actionClick(item.actionType)"
          [ngClass]="[
            (((item.actionType === actionTypeEnum.Update ||
              item.actionType === actionTypeEnum.Delete) &&
              elements?.rowData === 'True') ||
            (item.actionType === actionTypeEnum.FixedAssetReturn &&
              elements1?.rowData === 'True') ||
            (item.actionType === actionTypeEnum.Update && elements === 'True')
              ? 'hidden'
              : '') ||
              ((this.moduleId === this.modules.Invoices ||
                this.moduleId === this.modules.Purchase ||
                this.moduleId === this.modules.CISInvoice) &&
              (item.actionType === actionTypeEnum.AddReceipt ||
                item.actionType === actionTypeEnum.BadDebtsEntry ||
                (item.actionType === actionTypeEnum.EmailWithPaymentLink &&
                  this.showMailOption === 'false') ||
                item.actionType === actionTypeEnum.AddPayment) &&
              row[colName]?.rowData === 'Paid'
                ? 'hidden'
                : '') ||
              ((this.moduleId === this.modules.CreditNote ||
                this.moduleId === this.modules.DebitNote) &&
              item.actionType === actionTypeEnum.AllocateRefund &&
              row[colName]?.rowData === 'Allocated'
                ? 'hidden'
                : ''),
            (isViewPermission$ | async) ||
            (item.actionType === actionTypeEnum.AddReceipt &&
              !isReceiptChangePermission) ||
            (item.actionType === actionTypeEnum.BadDebtsEntry &&
              !isJournalChangePermission) ||
            (item.actionType === actionTypeEnum.AddPayment &&
              !isPaymentChangePermission)
              ? 'cursor-not-allowed'
              : ''
          ]"
          [disabled]="
            (isViewPermission$ | async) ||
            (item.actionType === actionTypeEnum.AddReceipt &&
              !isReceiptChangePermission) ||
            (item.actionType === actionTypeEnum.BadDebtsEntry &&
              !isJournalChangePermission) ||
            (item.actionType === actionTypeEnum.AddPayment &&
              !isPaymentChangePermission)
          "
        >
          <mat-icon fontSet="material-icons-outlined"
            >{{ item.icon }}
          </mat-icon>
          <span>{{ item.name }} </span>
        </button>
      </div>
    </ng-container>
  </mat-menu>
</div>
