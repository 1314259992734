import { Component, ComponentFactoryResolver, Injector } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Modules, RoutingPath } from './core/Enum';
import { AuthResolverService } from './core/Guards/auth.resolver';
import { GlobalComponent, MenuModel, PermissionModel } from './core/Models';
import { CommonService, ModulePermission } from './core/Services';
import { CompanyState, MenuState, SetModulePermission } from './core/Store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @Select(MenuState.moduleName)
  moduleName$: Observable<string>;
  moduleId$: Observable<number>;
  moduleId = Modules;

  routingPath = RoutingPath;
  activeRouteLink = '';
  userId?: Guid;

  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;

  @Select(CompanyState.companyCount)
  companyCount$: Observable<number>;

  private destroy$ = new Subject<void>();
  store: Store;
  spinner: NgxSpinnerService;
  commonService: CommonService;
  cookieService: CookieService;
  subscriptionRouting: Subscription;
  toggleIcon = true;
  _Activatedroute: ActivatedRoute;
  constructor(
    private router: Router,
    private globalComponent: GlobalComponent,
    private modulePermission: ModulePermission,
    private resolver: ComponentFactoryResolver,
    public authResolverService: AuthResolverService,
    public oidcSecurityService: OidcSecurityService,
    private injector: Injector
  ) {
    this._Activatedroute = this.injector.get<ActivatedRoute>(ActivatedRoute);
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeRouteLink = event.url.slice(1).split(';')[0];
      }
    });
    this.userId = this.globalComponent.getLoggedInUserId();
    this.store = injector.get<Store>(Store);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.commonService = injector.get<CommonService>(CommonService);
    this.cookieService = injector.get<CookieService>(CookieService);
  }

  ngOnInit(): void {
    const refreshInterval = 2 * 60 * 1000;

    interval(refreshInterval).subscribe(() => {
      const tempToken = this.cookieService.get('newToken')
        ? this.cookieService.get('newToken')
        : this.oidcSecurityService.getToken();
      if (this.isTokenExpired(tempToken)) {
        this.authResolverService.resolve();
      }
    });
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        let baseUrl = window.location.pathname.toString();
        const currentUrl = this._Activatedroute.snapshot['_routerState'].url;
        if (
          !currentUrl.includes('/general-dashboard') &&
          !baseUrl.includes('/general-dashboard') &&
          !currentUrl.includes('/activity') &&
          !baseUrl.includes('/activity')
        ) {
          this.commonService.toggleMenu = true;
          this.toggleIcon = true;
        } else {
          this.commonService.toggleMenu = false;
          this.toggleIcon = false;
        }
      }
    );
    this.moduleId$?.subscribe((moduleId) => {
      if (moduleId === Modules.Import) {
        this.commonService.toggleMenu = false;
      }
    });
  }

  changeOfRoutes(componentRef): void {
    this.commonService.toggleMenu = true;
    const navUrl = this.router.url.slice(0).split(';')[0];
    const splitUrl = navUrl.split('/');
    if (
      RoutingPath.ToggleImport === navUrl ||
      RoutingPath.ToggleGeneralDashboard === navUrl ||
      RoutingPath.Activity === navUrl
    ) {
      this.commonService.toggleMenu = false;
    }
    if (splitUrl[splitUrl.length - 1] === 'add') {
      this.commonService.toggleMenu = false;
    }
    let baseUrl = window.location.pathname.toString();
    if (!baseUrl.includes('/general-dashboard') && !baseUrl.includes('/activity')) {
      this.toggleIcon = true;
    } else {
      this.toggleIcon = false;
    }

    this.spinner.show();
    this.menuList$
      .pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.setData(
          this.router.url.slice(0).split(';')[0],
          data,
          this.resolver.resolveComponentFactory(componentRef.constructor)
            .selector
          // this.viewContainerRef.createComponent(componentRef.constructor)
        );
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  emitPermissionData(data: any, componentName: any): void {
    const permissionData = new PermissionModel();
    permissionData.data = data;
    permissionData.componentName = componentName;

    this.modulePermission.permissionData.next(permissionData);
  }

  setData(event: any, data: any, componentName: any): void {
    data.forEach((x) => {
      if (x.url === event.slice(1) || x.addUrl === event.slice(1)) {
        this.store.dispatch(new SetModulePermission(x)).subscribe();
        this.emitPermissionData(x, componentName);
      } else {
        x.subMenu.map((y) => {
          if (
            y.url === event.slice(1) ||
            y.addUrl === event.slice(1).split(';')[0]
          ) {
            this.store.dispatch(new SetModulePermission(y)).subscribe();
            this.emitPermissionData(y, componentName);
          } else {
            y.subMenu.map((z) => {
              if (
                z.url === event.slice(1) ||
                z.addUrl === event.slice(1).split(';')[0]
              ) {
                this.store.dispatch(new SetModulePermission(z)).subscribe();
                this.emitPermissionData(z, componentName);
              } else {
                z.subMenu.map((z1) => {
                  if (
                    z1.url === event.slice(1) ||
                    z1.addUrl === event.slice(1).split(';')[0]
                  ) {
                    this.store
                      .dispatch(new SetModulePermission(z1))
                      .subscribe();
                    this.emitPermissionData(z1, componentName);
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  onIconClick(): void {
    this.commonService.toggleMenu = !this.commonService.toggleMenu;
  }

  isTokenExpired(token: string): boolean {
    const decodedToken = this.decodeToken(token);
    if (!decodedToken || !decodedToken.exp) {
      return true; // Token is invalid or doesn't have an expiration
    }

    const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds
    return decodedToken.exp < currentTime;
  }

  decodeToken(token: string): any {
    try {
      const payload = token.split('.')[1];
      const decodedPayload = atob(payload);
      return JSON.parse(decodedPayload);
    } catch (error) {
      return null; // Token decoding failed
    }
  }
}
