import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  DiscountType,
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import {
  FinancialData,
  InvoiceModel,
  PaymentDetailsModel,
  ProductDetailsModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  CreateInvoice,
  GetBankAccountTransactionData,
  GetDataByInvoiceId,
  GetInvoiceNumber,
  GetTransactionLogData,
  MenuState,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  AddIncomeInvoiceComponent,
  AdvancePaymentComponent,
  ProductDetailsComponent,
  ProductDetailsFooterComponent,
  TransactionsAttachmentComponent,
  TransactionsPaymentComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-income-invoice',
  templateUrl: './income-invoice.component.html',
  styleUrls: ['./income-invoice.component.scss'],
})
export class IncomeInvoiceComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isFromBankImport = false;
  isManualBank = false;
  selectedBank: any;

  moduleId = Modules.Invoices;
  moduleName = ModuleName.Invoices;

  invoiceId = Guid.EMPTY as unknown as Guid;
  bankImportTransactionId = Guid.EMPTY as unknown as Guid;

  invoiceData: InvoiceModel;
  productItems: Array<ProductDetailsModel>;
  receiptItems: Array<PaymentDetailsModel>;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddIncomeInvoiceComponent, { static: true })
  incomeInvoiceDetails;

  @ViewChild(ProductDetailsComponent, { static: true })
  invoiceProductDetails;

  @ViewChild(TransactionsPaymentComponent, { static: true })
  invoiceReceiptDetails;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  @ViewChild(AdvancePaymentComponent, { static: true })
  advancePaymentDetails;

  @ViewChild(AddIncomeInvoiceComponent, { static: true })
  invoiceCurrencyDetails;

  @ViewChild(ProductDetailsFooterComponent, { static: true })
  productDetailsFooter;

  subscriptionRouting: Subscription;
  headerText = 'Receipt';
  filteredVatRates = [];
  checkVatRateValue: boolean = false;
  hasAdvanceAmount = false;
  selectedCurrencyId: number;
  selectedCurrencyRate: number;
  receiptAccountSelectedCurrencyId: number;
  selectedCustomerId = Guid.EMPTY as unknown as Guid;

  triggerEditData: Subject<any> = new Subject<any>();
  triggereEditProductData: Subject<any> = new Subject<any>();
  triggereEditPaymentData: Subject<any> = new Subject<any>();
  triggerOnVATChange: Subject<boolean> = new Subject<boolean>();
  triggerAccountSelectedOption: Subject<boolean> = new Subject<boolean>();
  triggerCurrencySelectedOption: Subject<boolean> = new Subject<boolean>();
  triggerCurrencyRefreshOption: Subject<boolean> = new Subject<boolean>();
  triggerAdvancePayment: Subject<any> = new Subject<any>();
  triggerisVatPostponed: Subject<any> = new Subject<any>();
  triggerisVatIncluded: Subject<any> = new Subject<any>();
  triggerBranchChange: Subject<any> = new Subject<any>();
  triggerNewProductAdded: Subject<any> = new Subject<any>();
  triggerSelectedCurrencyRate: Subject<number> = new Subject<number>();
  triggerTransactionLogData: Subject<any> = new Subject<any>();
  triggerDiscountData: Subject<any> = new Subject<any>();
  triggerDiscountChangeVal: Subject<any> = new Subject<any>();
  triggerisHlobalDiscountChecked: Subject<any> = new Subject<any>();
  @Select(CommonState.periodicDate)
  periodicDate$: Observable<Array<FinancialData>>;
  store: Store;
  commonService: CommonService;
  isFromQuotation = false;
  grossAmount: number = 0;
 isVatrateEqual:Subject<boolean> = new Subject<boolean>();;
  @Output()
  readonly triggerGrossTotal = new EventEmitter<any>();

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  isReceiptChangePermission: boolean = true;
  public defaultGuidValue = Guid.EMPTY as unknown as Guid;
  constructor(
    private _Activatedroute: ActivatedRoute,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private location: Location,
    private renderer: Renderer2,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          const isFromBankImport = params.get('isFromBankImport');
          const isManualBank = params.get('isManualBank');
          const isFromQuotation = params.get('isFromQuotation');

          if (isFromBankImport !== null && isManualBank !== null) {
            this.isFromBankImport = JSON.parse(atob(isFromBankImport));
            this.isManualBank = JSON.parse(atob(isManualBank));
            this.selectedBank = atob(params.get('customId')!) as unknown as any;
          }

          const id = atob(params.get('id')!) as unknown as Guid;
          if (isFromQuotation !== null) {
            this.isFromQuotation = JSON.parse(atob(isFromQuotation));
          }
          if (this.isFromQuotation) {
            this.invoiceId = id;
            this.editInvoice();
          }

          if (this.isFromBankImport) {
            this.bankImportTransactionId = id;
            this.getInvoiceNumber();

            if (this.isManualBank) {
              this.getTransactionLogData();
            } else {
              this.getBankAccountTransactionData();
            }
          } else {
            this.invoiceId = id;
            this.editInvoice();
          }
        } else {
          this.getInvoiceNumber();
        }
      }
    );
    // if(this.defaultGuidValue === this.invoiceId){
    //   setTimeout(() => {
    //     this.checkVatRateList();
    //   }, 500);
    // }
    
   
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isReceiptChangePermission = this.commonService.checkPermission(
        Modules.Sales,
        Modules.Receipt
      );
    }, 3000);
  }

  getTransactionLogData(): void {
    this.store
      .dispatch(new GetTransactionLogData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.incomeInvoiceDetails.invoiceForm.controls.invoiceDate.setValue(
          res.common.transactionLogData.entryDate
        );
        this.incomeInvoiceDetails.invoiceForm.controls.notes.setValue(
          res.common.transactionLogData.note
        );
        this.triggerTransactionLogData.next(res.common.transactionLogData);
      });
  }

  getBankAccountTransactionData(): void {
    this.store
      .dispatch(new GetBankAccountTransactionData(this.bankImportTransactionId))
      .subscribe((res) => {
        this.incomeInvoiceDetails.invoiceForm.controls.invoiceDate.setValue(
          res.common.transactionLogData.transactionDate
        );
        this.incomeInvoiceDetails.invoiceForm.controls.notes.setValue(
          res.common.transactionLogData.description
        );
      });
  }

  getInvoiceNumber(): void {
    this.store.dispatch(new GetInvoiceNumber()).subscribe((res) => {
      res.invoice.invoiceNo !== null
        ? this.incomeInvoiceDetails.invoiceForm.controls.invoiceNo.disable()
        : this.incomeInvoiceDetails.invoiceForm.controls.invoiceNo.enable();
      this.incomeInvoiceDetails.invoiceForm.controls.invoiceNo.setValue(
        res.invoice.invoiceNo
      );
    });
  }

  editInvoice(): void {
    this.store
      .dispatch(new GetDataByInvoiceId(this.invoiceId))
      .subscribe((res) => {
        this.incomeInvoiceDetails.invoiceForm.markAsUntouched();
        this.invoiceProductDetails.formProductDetail.markAsUntouched();
        this.invoiceReceiptDetails.paymentForm.markAsUntouched();
        setTimeout(() => {
          this.triggerEditData.next(res.invoice.invoiceData);

          const data = {
            productData: res.invoice.invoiceData.items,
            isVATSelected: res.invoice.invoiceData.isVatPostponed,
          };
          this.triggereEditProductData.next(data);

          this.triggereEditPaymentData.next(
            res.invoice.invoiceData.receiptDetails
          );
          this.triggerAdvancePayment.next(res.invoice.invoiceData.accountId);
          this.triggerisVatPostponed.next(
            res.invoice.invoiceData.isVatPostponed
          );
          this.triggerisVatIncluded.next(res.invoice.invoiceData.isVatIncluded);
          let discountData = {
            discountValue: res.invoice.invoiceData.foreignDiscount,
            discountType: res.invoice.invoiceData.isDiscountPercentage
              ? DiscountType.Percentage
              : DiscountType.Value,
          };
          this.triggerDiscountData.next(discountData);
        }, 500);
        //this.checkVatRateList();

      
      });

      // setTimeout(() => {
      //   this.checkVatRateList();
      // }, 3000);
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  reloadAutoNumberGenerator(): void {
    this.getInvoiceNumber();
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.invoiceId,
      isExit,
      Modules.Invoices,
      RoutingPath.AddInvoice
    );
  }

  onSave(isExit: boolean): void {
    if (this.incomeInvoiceDetails.invoiceForm.invalid) {
      this.commonService.addValidation(
        this.incomeInvoiceDetails.invoiceForm,
        this.renderer
      );
    } else if (
      !this.isFromBankImport &&
      this.invoiceReceiptDetails.paymentArray.invalid
    ) {
      this.invoiceReceiptDetails.paymentArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          if (
            x.controls.amount.value === '' ||
            x.controls.amount.value === '0'
          ) {
            this.commonService.onFailure(
              NotificationTextMessage.amtErrorMessage
            );
          }
          c.markAsTouched();
        });
      });
    } else if (
      !this.isFromBankImport &&
      +((+this.productDetailsFooter.grossTotal).toFixed(2)) <
      (+(this.invoiceReceiptDetails.totalReciptAmount).toFixed(2))
    ) {
      this.invoiceReceiptDetails.paymentArray.controls.forEach((x) => {
        (Object as any)
          .values(x.controls)
          .forEach((c) => c.markAsTouched(false));
        x.controls.amount.status = 'INVALID';
      });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
    } else if (this.invoiceProductDetails.productDetailArray.invalid) {
      this.invoiceProductDetails.productDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else if (this.advancePaymentDetails.advancePaymentDetailForm.invalid) {
      this.advancePaymentDetails.advancePaymentDetailForm.controls[
        'allocateAdvance'
      ].setErrors({ incorrect: true });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    } else if (
      this.advancePaymentDetails.advancePaymentDetailForm.controls
        .allocateAdvance.value > +((+this.productDetailsFooter.grossTotal).toFixed(2))
    ) {
      this.advancePaymentDetails.advancePaymentDetailForm.controls[
        'allocateAdvance'
      ].setErrors({ incorrect: true });
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    } else if (
      +this.invoiceReceiptDetails.totalReciptAmount +
        +this.advancePaymentDetails.allocatedAdvanceAmount >
      +((+this.productDetailsFooter.grossTotal).toFixed(2))
    ) {
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateInvoice(this.invoiceData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit && !this.isFromBankImport) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.commonService.setLocalStorage(
                    'selectedBank',
                    this.selectedBank
                  );
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  dataSubmit(): boolean {
    this.receiptAccountSelectedCurrencyId =
      this.invoiceReceiptDetails.selectedAccountCurrencyId;
    this.selectedCurrencyId =
      this.incomeInvoiceDetails.invoiceForm.controls.currency.value === 0
        ? 123
        : this.incomeInvoiceDetails.invoiceForm.controls.currency.value;
    this.selectedCurrencyRate =
      this.incomeInvoiceDetails.invoiceForm.controls.currencyAmt.value;
    this.spinner.show();
    try {
      this.productItems = new Array<ProductDetailsModel>();
      this.receiptItems = new Array<PaymentDetailsModel>();

      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.invoiceProductDetails.productDetailArray
        ?.getRawValue()
        .forEach((x) => {
          this.productItems.push({
            id: x.id,
            productId: x.productName ? x.productName : this.defaultGuidValue,
            description: x.description,
            quantity: +x.qty,
            price: +x.price / this.selectedCurrencyRate,
            vatRateId: x.vatRate === -1 ? null : x.vatRate,
            vatAmount: +x.vatAmount / this.selectedCurrencyRate,
            totalAmount: +(x.amount / this.selectedCurrencyRate).toFixed(2),
            foreignVatAmount: +x.vatAmount,
            foreignPrice: +x.price,
            foreignTotalAmount: +x.amount,
            discount:
              x.discountType === DiscountType.Percentage
                ? +x.discountValue
                : +x.discountValue / this.selectedCurrencyRate,
            isDiscountPercentage: x.discountType === DiscountType.Percentage,
            discountDescription: x.discountDescription,
            foreignDiscount: +x.discountValue,
            bookAccountId: x.invoiceType?.id,
         
          });
        });

      this.invoiceReceiptDetails.paymentArray?.getRawValue().forEach((x) => {
        const attachmentList: any = [];
        if (
          x.attachment !== null &&
          x.attachment !== undefined &&
          x.attachment !== '' &&
          x.attachment.length > 0
        ) {
          x.attachment.forEach((element) => {
            attachmentList.push(element);
          });
        }

        if (x.accountId !== '' && x.amount !== '') {
          this.receiptItems.push({
            id: x.id,
            receiptId: x.receiptId,
            transactionDate: this.datepipe
              .transform(x.receiptDate, 'yyyy-MM-dd')
              ?.toString(),
            amount:
              this.receiptAccountSelectedCurrencyId !== 123
                ? x.amount / this.selectedCurrencyRate
                : x.amount,
            postingAccountId: x.accountId,
            attachment: attachmentList,
            foreignAmount:
              this.receiptAccountSelectedCurrencyId !== 123
                ? x.amount
                : x.amount * this.selectedCurrencyRate,
            currencyRate: this.selectedCurrencyRate,
          });
        }
      });

      this.invoiceData = {
        id: this.invoiceId,
        entryNumber:
          this.incomeInvoiceDetails.invoiceForm.controls.invoiceNo.value,
        entryDate: this.datepipe
          .transform(
            this.incomeInvoiceDetails.invoiceForm.controls.invoiceDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        dueDate: this.datepipe
          .transform(
            this.incomeInvoiceDetails.invoiceForm.controls.dueDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        currencyId:
          this.incomeInvoiceDetails.invoiceForm.controls.currency.value === 0
            ? 123
            : this.incomeInvoiceDetails.invoiceForm.controls.currency.value,
        currencyRate:
          this.incomeInvoiceDetails.invoiceForm.controls.currencyAmt.value,
       accountId:
         this.incomeInvoiceDetails.invoiceForm.controls.customerName.value,
        note: this.incomeInvoiceDetails.invoiceForm.controls.notes.value,
        items: this.productItems,
        receiptDetails: this.receiptItems,
        //bookAccountId:
         // this.incomeInvoiceDetails.invoiceForm.controls.invoiceType.value.id,
        bankAccountId:
          this.incomeInvoiceDetails.invoiceForm.controls.bankId.value === ''
            ? null
            : this.incomeInvoiceDetails.invoiceForm.controls.bankId.value,
        attachment: filedata,
        isVatPostponed:
          this.incomeInvoiceDetails.invoiceForm.controls.isVatPostponed.value,
        transactionLogId: this.isFromBankImport
          ? this.bankImportTransactionId
          : (Guid.EMPTY as unknown as Guid),
        advanceReceiptAmount:
          +this.advancePaymentDetails.advancePaymentDetailForm.controls
            .allocateAdvance.value,
        isVatIncluded:
          this.incomeInvoiceDetails.invoiceForm.controls.isVatInclude.value,
        isManualBank: this.isManualBank,
        branchId:
          this.incomeInvoiceDetails.invoiceForm.controls.branchId.value === ''
            ? null
            : this.incomeInvoiceDetails.invoiceForm.controls.branchId.value,
        departmentId:
          this.incomeInvoiceDetails.invoiceForm.controls.departmentId.value ===
          ''
            ? null
            : this.incomeInvoiceDetails.invoiceForm.controls.departmentId.value,
        discount:
          this.productDetailsFooter.discountType === DiscountType.Percentage
            ? this.productDetailsFooter.discountValue
            : this.productDetailsFooter.discountValue /
              this.selectedCurrencyRate,
        isDiscountPercentage:
          this.productDetailsFooter.discountType === DiscountType.Percentage,
        foreignDiscount: this.productDetailsFooter.discountValue,
      //  isInvoiceDiscount:
        //  this.incomeInvoiceDetails.invoiceForm.controls.isGlobalDiscount.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.invoiceId)) {
      this.editInvoice();
    } else {
      this.invoiceId = Guid.EMPTY as unknown as Guid;
      this.incomeInvoiceDetails.ngOnInit();
      this.invoiceProductDetails.ngOnInit();
      this.invoiceReceiptDetails.ngOnInit();
      this.advancePaymentDetails.ngOnInit();
      this.attachmentDetail.ngOnInit();
      this.getInvoiceNumber();
      this.productDetailsFooter.grossTotal = 0;
      this.productDetailsFooter.total = 0;
      this.productDetailsFooter.balanceAmount = 0;
      this.productDetailsFooter.postponedVatAmount = 0;
      this.productDetailsFooter.discountedValue = 0;
      this.hasAdvanceAmount = false;
      this.productDetailsFooter.discountType = DiscountType.Value;
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  onVATChange(isVATChecked: boolean) {
    this.triggerOnVATChange.next(isVATChecked);
  }

  onAccountSelectedOption(selectedOption: any) {
    this.triggerAccountSelectedOption.next(selectedOption);
  }

  customerClickChange(event) {
    this.triggerAdvancePayment.next(event);
    this.selectedCustomerId = event;
  }

  isVatPostponedClick(event): void {
    this.triggerisVatPostponed.next(event);
  }

  onHasAdvanceAmountUpdated(updatedValue: boolean): void {
    this.hasAdvanceAmount = updatedValue;
  }

  isVatIncludedClick(event): void {
    this.triggerisVatIncluded.next(event);
  }

  isGlobalChecked(event):void{
  
    this.triggerisHlobalDiscountChecked.next(event);
    //this.checkVatRateList();
    
  }
  onCurrencySelectedOption(selectedOption: any) {
    this.triggerCurrencySelectedOption.next(selectedOption);
    this.triggerAdvancePayment.next(this.selectedCustomerId);
  }
  onCurrencyRefreshOption(selectedOption: any) {
    this.triggerCurrencyRefreshOption.next(selectedOption);
  }
  onBranchChange(selectedOption: any) {
    this.triggerBranchChange.next(selectedOption);
  }
  onAddedNewProductDetails(selectedOption: any) {
    this.triggerNewProductAdded.next(selectedOption);
    //this.checkVatRateList();
  }
  onCurrencySelectedRate(selectedOption: any) {
    this.triggerSelectedCurrencyRate.next(selectedOption);
  }

//   checkVatRateList():void{
//     let list= this.invoiceProductDetails.productDetailArray
//     ?.getRawValue();
// if(list?.length > 1){
//   let isEqual= list.every( (item:any) => item.vatRate === list[0]['vatRate'] )   // true
//   let disableIndDiscount:boolean;
//         if(isEqual ){
//           this.incomeInvoiceDetails.invoiceForm.controls.isGlobalDiscount.enable();
//         if(this.incomeInvoiceDetails.invoiceForm.controls.isGlobalDiscount.value){

//           disableIndDiscount= true;
//         }else{
//           disableIndDiscount= false;
//         }
//         }else{
//           disableIndDiscount= false;
//           this.incomeInvoiceDetails.invoiceForm.controls.isGlobalDiscount.setValue(false);
//           this.incomeInvoiceDetails.invoiceForm.controls.isGlobalDiscount.disable();
//         }

//   this.isVatrateEqual.next(disableIndDiscount);
// }else{
//   this.isVatrateEqual.next(false);
// }

   
  
//   }
  onVatRateChange(event:any):void{
    //this.checkVatRateList();
  }
  onDiscountChange(event:any):void{
    console.log(event,'evv');
    if(event['value']){
      this.triggerDiscountChangeVal.next(event);
    }

  }
}
