<div class="content-body">
  <form [formGroup]="momForm">
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Meeting Type <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="meetingType"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength100 }}"
          />
        </mat-form-field>
      </div>
      <div class="form-field datepicker">
        <p>Meeting Date <span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            readonly
            [ngxMatDatetimePicker]="picker"
            formControlName="meetingDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="$any(picker)"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #picker
            [showSpinners]="showSpinners"
            [showSeconds]="showSeconds"
            [stepHour]="stepHour"
            [stepMinute]="stepMinute"
            [stepSecond]="stepSecond"
            [touchUi]="touchUi"
            [enableMeridian]="enableMeridian"
          >
            <ng-template>
              <span>OK</span>
            </ng-template>
          </ngx-mat-datetime-picker>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field fund-name" *ngIf="branchList.length > 0">
        <p>Branch <span class="text-danger-300">*</span></p>
        <ng-select
          #branch
          (focus)="branch.open()"
          (valueChange)="branch.close()"
          [disableOptionCentering]="true"
          formControlName="branchId"
          dropdownPosition="bottom"
          (change)="onChangeBranch($event)"
        >
          <ng-option *ngFor="let bl of branchList" [value]="bl.id">
            <div title="{{ bl.name }}">{{ bl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="departmentList.length > 0">
        <p>Department <span class="text-danger-300">*</span></p>
        <ng-select
          #department
          (focus)="department.open()"
          (valueChange)="department.close()"
          [disableOptionCentering]="true"
          formControlName="departmentId"
          dropdownPosition="bottom"
        >
          <ng-option *ngFor="let dl of departmentList" [value]="dl.id">
            <div title="{{ dl.name }}">{{ dl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div>
      <div
        [ngxSummernote]="configsummernote"
        formControlName="summaryNote"
      ></div>
    </div>
  </form>
</div>
