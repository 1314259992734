<div class="mat-dialog-wrapper view-receipt-dialog">
  <h2 mat-dialog-title>{{ headerText }}</h2>
  <mat-dialog-content>
    <button
      class="export-button"
      type="button"
      (click)="onDownloadClick()"
      mat-button
      disableRipple
      [disabled]="
        (isViewPermission$ | async) ||
        (!isJournalChangePermission && moduleId === modules.Journals) ||
        (!isRecieptChangePermission && moduleId === modules.Receipt) ||
        (!isPaymentChangePermission && moduleId === modules.Payment)
      "
    >
      <mat-icon>save_alt</mat-icon>
    </button>
    <button
      class="edit-button"
      type="button"
      (click)="onEditClick()"
      mat-button
      disableRipple
      [disabled]="
        (!isJournalChangePermissionEdit && moduleId === modules.Journals) ||
        (!isRecieptChangePermissionEdit && moduleId === modules.Receipt) ||
        (!isPaymentChangePermissionEdit && moduleId === modules.Payment)
      "
    >
      <mat-icon class="text-primary" fontset="material-icons-outlined"
        >edit</mat-icon
      >
    </button>
    <div
      *ngIf="isRender"
      [innerHTML]="moduleViewDetails | viewHtmlContent"
    ></div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="close-button"
      type="button"
      (click)="onCancel()"
      mat-button
      disableRipple
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
