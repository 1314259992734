import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modules } from '@app/core/Enum';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent {
  @Input() getModuleId: number;
  @Input() saveButtonShow: boolean;
  @Input() isSend: boolean = false;

  @Output()
  readonly triggerOnSaveClick = new EventEmitter<any>();

  @Output()
  readonly triggerOnSaveInviteClick = new EventEmitter<any>();

  @Output()
  readonly triggerOnSendClick = new EventEmitter<any>();

  @Output()
  readonly triggerOnCancelClick = new EventEmitter<any>();

  modules = Modules;

  onSave(isExit: boolean): void {
    this.triggerOnSaveClick.next(isExit);
  }

  onSaveInvite(isExit: boolean): void {
    this.triggerOnSaveInviteClick.next(isExit);
  }

  onCancel(): void {
    this.triggerOnCancelClick.next();
  }

  onSend(isExit: boolean): void {
    this.triggerOnSendClick.next(isExit);
  }
}
