import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaxLength } from '@app/core/Enum';
import { GlobalComponent } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetAllBranchList,
  GetAllDepartmentListByBranch,
  GetVatRateList,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent implements OnInit {
  vatRateList: any[];
  productForm: FormGroup;
  maxLength = MaxLength;
  @Input() triggerEditData: Observable<any>;

  departmentList: any[] = [];
  branchList: any[] = [];
  isVatRegistered: any;

  constructor(
    private store: Store,
    private commonService: CommonService,
    public globalComponent: GlobalComponent
  ) {}

  ngOnInit(): void {
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    this.setForm();
    this.getVatRateList();
    this.getBranchList();

    this.triggerEditData?.subscribe((data) => {
      this.getVatRateList();
      setTimeout(() => {
        this.editProduct(data);
      }, 1000);
    });

    this.productForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.productForm.touched;
    });
  }

  calAmount(): void {
    if (
      this.productForm.controls.openingBalancePrice.value !== undefined &&
      this.productForm.controls.openingBalancePrice.value !== null &&
      this.productForm.controls.openingBalancePrice.value !== '' &&
      this.productForm.controls.openingBalanceQuantity.value !== undefined &&
      this.productForm.controls.openingBalanceQuantity.value !== null &&
      this.productForm.controls.openingBalanceQuantity.value !== ''
    ) {
      const amount =
        this.productForm.controls.openingBalancePrice.value *
        this.productForm.controls.openingBalanceQuantity.value;
      this.productForm.controls.productAmount.setValue(+amount);
    }
  }

  editProduct(data: any): void {
    this.productForm.patchValue({
      productName: data.name,
      productCode: data.code,
      vatRate: data.vatRateId,
      productPrice: data.price,
      openingBalanceQuantity: data.openingBalanceQuantity,
      openingBalancePrice: data.openingBalancePrice,
      productAmount: data.openingBalanceQuantity * data.openingBalancePrice,
      productDescription: data.description,
      branchId: data.branchId,
      departmentId: data.departmentId,
      trackProductInInvetory: data.trackProductInInvetory,
    });
  }

  setForm(): void {
    this.productForm = new FormGroup({
      productName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      productCode: new FormControl(''),
      vatRate: new FormControl(null),
      productPrice: new FormControl('', [Validators.required]),
      openingBalanceQuantity: new FormControl('', [
        Validators.pattern('[0-9]+'),
      ]),
      openingBalancePrice: new FormControl(''),
      productAmount: new FormControl({ value: '', disabled: true }),
      productDescription: new FormControl(''),
      branchId: new FormControl(''),
      departmentId: new FormControl(''),
      trackProductInInvetory: new FormControl(false),
    });
  }

  getVatRateList(): void {
    this.store.dispatch(new GetVatRateList()).subscribe((res) => {
      this.vatRateList = res.common.vatRate;
    });
  }

  getBranchList(): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.productForm.controls.branchId.setValidators(
              Validators.required
            );
            this.productForm.controls.branchId.updateValueAndValidity();
            this.onChangeBranch(this.branchList[0].id);
          } else {
            this.branchList = [];
            this.departmentList = [];
          }
        })
      )
      .subscribe();
  }

  onChangeBranch(branch): void {
    this.getDepartmentListByBranch(branch);
  }

  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .pipe(
          tap((res) => {
            this.departmentList = [];
            if (res.company.department.length > 0) {
              this.departmentList = res.company.department;
              this.productForm.controls.departmentId.setValidators(
                Validators.required
              );
              this.productForm.controls.departmentId.updateValueAndValidity();
            } else {
              this.productForm.controls.departmentId.clearValidators();
              this.productForm.controls.departmentId.updateValueAndValidity();
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
      this.productForm.controls.departmentId.clearValidators();
      this.productForm.controls.departmentId.updateValueAndValidity();
    }
  }
}
