import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import {
  AccountProvider,
  CompanyFilteredStatus,
  ConfirmationType,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { Company, ExportType, MainListParameters } from '@app/core/Models';
import { CommonService, DashboardService } from '@app/core/Services';
import { Export, GetUserMailList, MenuState } from '@app/core/Store';
import {
  CompanyState,
  GetCompanyList,
  SetAsDefaultCompany,
  SetDefaultCompanyId,
} from '@app/core/Store/company';
import { ConfirmationBoxComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss'],
})
export class ClientsListComponent implements OnInit {
  displayedColumns: string[] = [
    'clientCode',
    'companyName',
    'contactName',
    'email',
    'phone',
    // 'pay',
    'isArchived',
  ];
  companyList: Array<Company>;
  listParameters: MainListParameters = new MainListParameters();
  defaultFilterStatus = CompanyFilteredStatus['All Status'];
  statusFilter = CompanyFilteredStatus;
  moduleId = Modules.Clients;
  isAllSelected = false;
  totalSelectedRecords = 0;
  ids?: Array<Guid>;
  exportType = ExportType;
  showMailOption: any;
  capiumPayList: any;
  showPaginator = true;
  isHeaderChange = false;

  private searchSubject = new Subject<string>();
  private searchSubscription: Subscription;

  @ViewChild(MatSort) sort: MatSort;

  @Select(CompanyState.totalRecord)
  totalRecord$: Observable<number>;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  commonNotificationText = NotificationTextMessage;

  constructor(
    private commonService: CommonService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private store: Store,
    private router: Router,
    private cookieService: CookieService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.spinner.hide();
    this.getList();

    this.searchSubscription = this.searchSubject
      .pipe(debounceTime(900))
      .subscribe(() => {
        this.getList();
      });
  }

  ngOnDestroy(): void {
    this.searchSubscription.unsubscribe();
  }

  printClick(): void {
    this.export(ExportType.PDF, true);
  }

  getParamaters(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      filter: this.listParameters.filter,
    };

    return queryParams;
  }

  getList(): void {
    this.store
      .dispatch(new GetCompanyList(this.getParamaters()))
      .pipe(
        tap((res) => {
          this.companyList = res.company.company;
        })
      )
      .subscribe();
  }

  onClientDelete(data?: any): void {
    this.getSelectedIds(data);

    if (this.ids!.length > 0) {
      let detailText: string = '';
      if (data === undefined) {
        detailText = `${NotificationDetails.deleteAllDetailText}`;
      } else {
        const companyName = data !== undefined ? data.companyName : '';
        detailText = `${NotificationDetails.deleteDetailText} ${companyName} ${NotificationDetails.questionMark}`;
      }

      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: this.moduleId,
            totalNumberOfRecordSelected: this.ids!.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: detailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.deSelectAll();
            const updatedTotalPages = Math.ceil(
              this.companyList.length / this.listParameters.pageSize
            );
            if (this.listParameters.pageNumber > updatedTotalPages) {
              this.listParameters.pageNumber = 1;
            }
            this.getList();
          }
        });
    } else {
      this.commonService.onFailure(
        NotificationTextMessage.selectOneOrMoreRecordToDelete
      );
    }
  }

  onAddClick(): void {
    this.store.dispatch(SetDefaultCompanyId);
    this.commonService.onAddRouting(this.moduleId);
  }

  onSearch(event: any): void {
    this.listParameters.search = event.target.value;
    this.listParameters.pageNumber = 1;
    this.searchSubject.next(event.target.value);
  }

  onFilterChange(val: any): void {
    this.listParameters.filter = val;
    this.listParameters.pageNumber = 1;
    this.cancelSelectionClick();
    this.getList();
  }

  cancelSelectionClick(): void {
    this.deSelectAll();
  }

  deSelectAll(): void {
    this.companyList.forEach((x) => (x.isSelected = false));
    this.isAllSelected = false;
    this.totalSelectedRecords = 0;
  }

  getSelectedIds(data?: any): void {
    this.ids = [];

    if (data === undefined) {
      this.companyList.forEach((x) =>
        x.isSelected ? this.ids!.push(x.id!) : (Guid.EMPTY as unknown as Guid)
      );
    } else {
      this.ids.push(data.id);
    }
  }

  export(format: number, isPrint: boolean): void {
    this.getSelectedIds();

    if (this.companyList.length > 0) {
      this.spinner.show();
      const exportParams: MainListParameters = {
        format,
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: this.listParameters.filter,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        search: this.listParameters.search,
        isPrint,
        ids: this.ids,
      };

      this.store.dispatch(new Export(exportParams, this.moduleId)).subscribe(
        () => {
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      );

      this.cancelSelectionClick();
    } else {
      this.commonService.onFailure(NotificationTextMessage.noRecordFoundText);
    }
  }

  sorting(sortBy: string, sortOrder: string): void {
    if (sortOrder) {
      this.listParameters.sortOrder = sortOrder === 'asc';
      this.listParameters.sortBy = sortBy;
    } else {
      this.listParameters.sortOrder = true;
      this.listParameters.sortBy = '';
    }
    this.getList();
    this.deSelectAll();
  }

  selectAll(event: any): void {
    this.companyList.forEach((x) => (x.isSelected = event.checked));
    this.showFooter();
  }

  onCheckBoxSelected(element: any, event: any): void {
    element.isSelected = event.checked;

    this.isAllSelected = this.companyList.every(
      (item: any) => item.isSelected === true
    );

    this.showFooter();
  }

  showFooter(): void {
    this.getSelectedIds();
    this.totalSelectedRecords = this.ids!.length;
  }

  onClientEdit(data): void {
    this.commonService.onEditRouting(true, this.moduleId, data.id);
  }

  onClientChange(element: any): void {
    const companyName = element.companyName;
    const defaultCompanyId = element.id;
    this.store
      .dispatch(new SetAsDefaultCompany(defaultCompanyId))
      .subscribe((x) => {
        this.setCompanyId(defaultCompanyId);
        this.setCompanyName(companyName);
        localStorage.setItem('defaultFinancialPeriod', '-1');
        this.dashboardService.setDashboardReload(true);
        this.spinner.show();
        this.getAllMail(5);
      });
    this.commonService.toggleMenu = true;
    this.router.navigate([RoutingPath.Dashboard]);
  }

  setCompanyId(companyId): void {
    if (
      companyId !== undefined &&
      companyId !== null &&
      companyId !== Guid.EMPTY
    ) {
      this.cookieService.set('companyid', companyId);
    }
  }

  setCompanyName(companyName: any): void {
    this.cookieService.set('defaultCompanyName', companyName);
  }

  getAllMail(providerId): void {
    this.store
      .dispatch(new GetUserMailList(providerId))
      .subscribe((res: any) => {
        if (providerId === AccountProvider.CapiumPay) {
          this.capiumPayList = res.integration.integrationData;
          if (this.capiumPayList === null || this.capiumPayList.length === 0) {
            this.showMailOption = 'false';
          } else {
            this.showMailOption = 'true';
          }
          this.cookieService.set('showPaymentMailOption', this.showMailOption);
        }
      });
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.isHeaderChange = false;

      this.getList();
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.isHeaderChange = false;

    this.getList();
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }
}
