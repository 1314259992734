import { Injectable } from '@angular/core';
import {
  LiabilityModel,
  SideListModel,
  VATObligationModel,
  VATPaymentModel,
} from '@app/core/Models';
import { ViewVatDeatilsService } from '@app/core/Services';

import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  GetAuthorized,
  GetBridgingVatRetunDetails,
  GetVatLiabilities,
  GetVatObligations,
  GetVatPayments,
  GetVatRetun,
  GetVatRetunDetails,
  GetVatSubmissionPeriods,
} from './view-vat-details.action';

export class ViewVatDetailsStateInfo {
  vatReturnData?: Array<any>;
  vatReturnDetailsData?: Array<any>;
  totalRecord?: number;
  actions?: any;
  isAuthorized?: boolean;
  vatObligations?: VATObligationModel[];
  vatLiabilities?: LiabilityModel[];
  vatPayments?: VATPaymentModel[];
  vatSubmissionPeriods?: SideListModel[];
}

@State<ViewVatDetailsStateInfo>({
  name: 'viewVatDetails',
  defaults: {},
})
@Injectable()
export class ViewVatDetailsState {
  constructor(private viewVatDeatilsService: ViewVatDeatilsService) {}

  @Action(GetVatRetun, { cancelUncompleted: true })
  getVatRetun(
    { patchState }: StateContext<ViewVatDetailsStateInfo>,
    action: GetVatRetun
  ) {
    return this.viewVatDeatilsService.getVatReturn(action.periodKey).pipe(
      tap({
        next: (res) => {
          patchState({
            vatReturnData: res,
          });
        },
      })
    );
  }

  @Action(GetVatRetunDetails, { cancelUncompleted: true })
  getVatRetunDetails(
    { patchState }: StateContext<ViewVatDetailsStateInfo>,
    action: GetVatRetunDetails
  ) {
    return this.viewVatDeatilsService.getVatRetunDetails(action.periodKey).pipe(
      tap({
        next: (res) => {
          patchState({
            vatReturnDetailsData: res,
          });
        },
      })
    );
  }

  @Action(GetVatSubmissionPeriods, { cancelUncompleted: true })
  getVatSubmissionPeriods(
    { patchState }: StateContext<ViewVatDetailsStateInfo>,
    action: GetVatSubmissionPeriods
  ) {
    return this.viewVatDeatilsService.getVatSubmissionPeriods().pipe(
      tap({
        next: (res) => {
          patchState({
            vatSubmissionPeriods: res,
          });
        },
      })
    );
  }

  @Action(GetAuthorized)
  getAuthorized(
    { patchState }: StateContext<ViewVatDetailsStateInfo>,
    action: GetAuthorized
  ) {
    return this.viewVatDeatilsService.getAuthorized().pipe(
      tap((res) => {
        patchState({
          isAuthorized: res,
        });
      })
    );
  }

  @Action(GetVatObligations, { cancelUncompleted: true })
  getVatObligations(
    { patchState }: StateContext<ViewVatDetailsStateInfo>,
    action: GetVatObligations
  ) {
    return this.viewVatDeatilsService
      .getVatObligations(action.queryParams)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              vatObligations: result,
            });
          },
        })
      );
  }

  @Action(GetVatLiabilities, { cancelUncompleted: true })
  getVatLiabilities(
    { patchState }: StateContext<ViewVatDetailsStateInfo>,
    action: GetVatLiabilities
  ) {
    return this.viewVatDeatilsService
      .getVatLiabilities(action.queryParams)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              vatLiabilities: result,
            });
          },
        })
      );
  }

  @Action(GetVatPayments, { cancelUncompleted: true })
  getVatPayments(
    { patchState }: StateContext<ViewVatDetailsStateInfo>,
    action: GetVatPayments
  ) {
    return this.viewVatDeatilsService.getPayments(action.queryParams).pipe(
      tap({
        next: (result) => {
          patchState({
            vatPayments: result,
          });
        },
      })
    );
  }

  @Action(GetBridgingVatRetunDetails, { cancelUncompleted: true })
  getBridgingVatRetunDetails(
    { patchState }: StateContext<ViewVatDetailsStateInfo>,
    action: GetBridgingVatRetunDetails
  ) {
    return this.viewVatDeatilsService.getBridgingVatRetunDetails(action.periodKey).pipe(
      tap({
        next: (res) => {
          patchState({
            vatReturnDetailsData: res,
          });
        },
      })
    );
  }
}
