<app-menu-items></app-menu-items>
<div class="import-section general-import">
  <div class="sidenav-body-content mb-4">
    <div *ngIf="!showImportSales">
      <div class="import-head d-flex align-items-center justify-space-between">
        <p class="m-0">Import Data</p>
        <div class="d-flex align-items-center justify-space-between">
          <button
            mat-button
            (click)="$event.stopPropagation()"
            [matMenuTriggerFor]="menu"
            disableRipple
          >
            <mat-icon class="text-yellow-200 highlight-icon"
              >emoji_objects</mat-icon
            >
          </button>
          <mat-menu #menu="matMenu" class="highlight" xPosition="before">
            <div class="highlight-wrapper">
              <p class="font-size-16 fw-bold">Things to consider</p>
              <p class="font-size-16 fw-bold">Requirements</p>
              <ul>
                <li>Accepted file type : EXCEL</li>
                <li>Size Limit : 10 MB</li>
                <li>
                  File names cannot contain special characters, such as
                  {{ specialCar }}
                </li>
              </ul>
              <p class="font-size-16 fw-bold">Notes</p>
              <ul>
                <li>Duplicate records will not be added twice</li>
                <li>
                  The logged in user will be considered as the creator of
                  records in the imported file
                </li>
              </ul>
            </div>
          </mat-menu>
        </div>
      </div>
      <div class="hr"></div>

      <div [hidden]="showImport">
        <span class="import-title">Select Category</span>
        <div class="import-wrapper">
          <mat-radio-group aria-label="Select an option">
            <div
              class="import-card"
              [ngClass]="moduleId === modules.Clients ? 'active' : ''"
            >
              <div class="d-flex align-center">
                <mat-radio-button
                  [value]="modules.Clients"
                  [checked]="isRadioSelected(modules.Clients)"
                  (change)="radioChange($event)"
                  disableRipple
                ></mat-radio-button>
              </div>
              <div class="import-body d-flex align-items-center">
                <div class="import-img">
                  <mat-icon>person_outline</mat-icon>
                </div>
                <div class="import-text">
                  <h2>Clients</h2>
                </div>
              </div>
            </div>

            <div
              class="import-card"
              [ngClass]="moduleId === modules.Users ? 'active' : ''"
            >
              <div class="import-check text-align-right">
                <mat-radio-button
                  [value]="modules.Users"
                  [checked]="isRadioSelected(modules.Users)"
                  (change)="radioChange($event)"
                ></mat-radio-button>
              </div>
              <div class="import-body d-flex align-items-center">
                <div class="import-img">
                  <mat-icon>people_outline</mat-icon>
                </div>
                <div class="import-text">
                  <h2>Users</h2>
                </div>
              </div>
            </div>
          </mat-radio-group>
        </div>
        <span class="import-title text-primary">Import History</span>
        <div class="sidenav-table">
          <div class="mat-table-wrapper">
            <table
              mat-table
              [dataSource]="importedFileList"
              aria-describedby="Import history table"
            >
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>File Name</th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex">
                    <span
                      class="onelineclamp fw-bold text-primary display-inline"
                      title="{{ element.fileName }}"
                      >{{ element.fileName }}</span
                    >
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="function">
                <th mat-header-cell *matHeaderCellDef>Category</th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex">
                    <span
                      class="onelineclamp"
                      title="{{ modules[element.moduleId] }}"
                      >{{ modules[element.moduleId] }}</span
                    >
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="import">
                <th mat-header-cell *matHeaderCellDef>Imported By</th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex">
                    <span
                      class="onelineclamp"
                      title="{{ element.createdByUserName }}"
                      >{{ element.createdByUserName }}</span
                    >
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="imported">
                <th mat-header-cell *matHeaderCellDef>Imported On</th>
                <td mat-cell *matCellDef="let element">
                  <span class="white-space-nowrap">{{
                    element.createdOn | date: "dd-LLL-yyyy"
                  }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex align-items-center justify-space-between">
                    <div>
                      <div *ngIf="!element.status" class="status-archived">
                        Error
                      </div>
                      <div *ngIf="element.status" class="status-active">
                        Success
                      </div>
                    </div>
                    <div>
                      <div class="d-flex align-items-center grid-actions">
                        <a (click)="downloadFile(element.filePath)">
                          <mat-icon>save_alt</mat-icon>
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td
                  class="mat-cell"
                  [ngClass]="'text-align-center'"
                  [attr.colspan]="displayedColumns.length"
                >
                  <span>No records found</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <app-import-details-settings
        [hidden]="!showImport"
        [moduleId]="moduleId"
        [importHeaderText]="importHeaderText"
        [triggerImport]="triggerImport"
        [triggerImportDetail]="triggerImportDetail"
        (showSalesTeam)="showSalesTeam($event)"
        [triggerGetImportDetail]="triggerGetImportDetail"
      ></app-import-details-settings>
    </div>
  </div>
</div>
<div class="action-wrapper" *ngIf="showImportButton">
  <div class="content-body">
    <div class="button-container">
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        *ngIf="
          !showImportSales &&
          importDetailsData.myStepper?.selectedIndex !== 1 &&
          importDetailsData.myStepper?.selectedIndex !== 2
        "
        (click)="onNextClick()"
      >
        Next
      </button>
      <button
        *ngIf="
          importDetailsData.myStepper?.selectedIndex === 1 &&
          importDetailsData?.importSuccessList &&
          (importDetailsData.importErrorList ||
            importDetailsData.importDuplicateList)
        "
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onPrevClick()"
      >
        Back
      </button>
      <button
        *ngIf="
          importDetailsData.myStepper?.selectedIndex === 1 &&
          importDetailsData?.importSuccessList?.data?.length > 0 &&
          importDetailsData?.importErrorList?.data?.length === 0 &&
          importDetailsData?.importDuplicateList?.data?.length === 0
        "
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onSaveNextClick()"
      >
        Save And Next
      </button>
      <button
        *ngIf="
          importDetailsData.myStepper?.selectedIndex === 1 &&
          (importDetailsData.importErrorList?.data?.length !== 0 ||
            importDetailsData.importDuplicateList?.data?.length !== 0)
        "
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onIgnoreClick()"
      >
        Ignore And Continue
      </button>
      <button
        class="action-button secondary-button"
        type="button"
        mat-button
        disableRipple
        (click)="
          onCancelClick(importDetailsData.myStepper?.selectedIndex === 2)
        "
      >
        {{
          importDetailsData.myStepper?.selectedIndex === 2 ? "Done" : "Cancel"
        }}
      </button>
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        *ngIf="showImportSales"
        (click)="onSendClick()"
      >
        Send
      </button>
      <button
        class="action-button secondary-button"
        type="button"
        mat-button
        disableRipple
        *ngIf="showImportSales"
        (click)="cancelClick()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
