import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CISInvoiceModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CISInvoiceService {
  constructor(private http: HttpClient) {}

  createCISInvoice(cisInvoice: CISInvoiceModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}CisInvoice/save`,
      JSON.stringify(cisInvoice),
      headers
    );
  }

  getDataByCISInvoiceId(cisInvoiceId: Guid): Observable<CISInvoiceModel> {
    return this.http.get<CISInvoiceModel>(
      `${environment.apiVersionUrl}CisInvoice/get/${cisInvoiceId}`
    );
  }

  deleteCISInvoice(cisInvoiceIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(cisInvoiceIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}CisInvoice/delete`,
      options
    );
  }

  copyCISInvoice(cisInvoiceIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}CisInvoice/copyPurchase`,
      JSON.stringify(cisInvoiceIds),
      headers
    );
  }

  archiveAndRestoreCISInvoice(
    cisInvoiceIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}CisInvoice/archive/${isArchive}`,
      JSON.stringify(cisInvoiceIds),
      options
    );
  }
  getDueAmountByCisInvoiceId(cisInvoiceId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CisInvoice/getCisInvoiceDueAmount/${cisInvoiceId}`
    );
  }
  getCisInvoiceCurrencyByCisInvoiceId(cisInvoiceId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CisInvoice/getCisInvoiceCurrencyId/${cisInvoiceId}`
    );
  }
  getCisInvoiceLabourAndMaterialAmountByCisInvoiceId(
    cisInvoiceId: Guid
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}CisInvoice/getCisInvoiceLMAmount/${cisInvoiceId}`
    );
  }
}
