import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MaxLength } from '@app/core/Enum';
import { payDateType, VatAmountType } from '@app/core/Enum/quick-entry';
import { GlobalComponent } from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import {
  GetAllBranchList,
  GetAllDepartmentListByBranch,
  GetCustomBankAndCashAccount,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-quickentry',
  templateUrl: './add-quickentry.component.html',
  styleUrls: ['./add-quickentry.component.scss'],
})
export class AddQuickentryComponent implements OnInit {
  quickEntryForm: FormGroup;
  periodicDate: any;
  paymentAccountList: any[] = [];
  maxLength = MaxLength;
  departmentList: any[] = [];
  branchList: any[] = [];
  vatAmountTypeList: any[] = [
    {
      id: VatAmountType.Nettogross,
      name: 'Net to Gross',
    },
    {
      id: VatAmountType.GrosstoNet,
      name: 'Gross to Net',
    },
  ];
  payDateList: any[] = [
    {
      id: payDateType.SameAsInvoiceDate,
      name: 'Same as Invoice Date',
    },
  ];
  @Input() triggerEditData: Observable<any>;

  @Output() triggerDefaultBankSelectedOption: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() triggerPaydateSameAsInvoiceDateSelected: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() triggerDefaultVatAmountSelectedOption: EventEmitter<number> =
    new EventEmitter<number>();
  private destroy$ = new Subject<void>();
  isVatRegistered: any;
  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    public dialog: MatDialog,
    private renderer: Renderer2,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    this.setForm();
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.triggerEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.editQuickEntry(data);
      });
    this.quickEntryForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.quickEntryForm.touched;
    });
  }

  setForm(): void {
    this.quickEntryForm = new FormGroup({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      quickEntryNo: new FormControl({ value: '', disabled: true }, [
        Validators.required,
      ]),
      vatAmountType: new FormControl<number | null>(null),
      payDateType: new FormControl<number | null>(null),
      accountId: new FormControl<number | null>(null),
      reference: new FormControl(''),
      branchId: new FormControl(''),
      departmentId: new FormControl(''),
    });
    this.getAccountList();
    this.getBranchList();
  }
  getBranchList(): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.quickEntryForm.controls.branchId.setValue(
              this.branchList[0].id
            );
            this.quickEntryForm.controls.branchId.setValidators(
              Validators.required
            );
            this.quickEntryForm.controls.branchId.updateValueAndValidity();
            this.getDepartmentListByBranch(this.branchList[0].id);
          } else {
            this.branchList = [];
            this.departmentList = [];
          }
        })
      )
      .subscribe();
  }
  onChangeBranch(branch): void {
    this.getDepartmentListByBranch(branch);
  }
  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .pipe(
          tap((res) => {
            this.departmentList = [];
            if (res.company.department.length > 0) {
              this.departmentList = res.company.department;
              this.quickEntryForm.controls.departmentId.setValue(
                this.departmentList[0].id
              );
              this.quickEntryForm.controls.departmentId.setValidators(
                Validators.required
              );
              this.quickEntryForm.controls.departmentId.updateValueAndValidity();
            } else {
              this.quickEntryForm.controls.departmentId.clearValidators();
              this.quickEntryForm.controls.departmentId.updateValueAndValidity();
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
      this.quickEntryForm.controls.departmentId.clearValidators();
      this.quickEntryForm.controls.departmentId.updateValueAndValidity();
    }
  }
  getAccountList(): void {
    this.store.dispatch(new GetCustomBankAndCashAccount()).subscribe((res) => {
      this.paymentAccountList = res.common.customBankAndCashAccount;
    });
  }
  onDefaultBankSelected(event: any) {
    this.triggerDefaultBankSelectedOption.emit(event);
  }
  onPaydateSelected(event: any) {
    this.triggerPaydateSameAsInvoiceDateSelected.emit(event);
  }
  onDefaultVatTypeSelected(event: any) {
    this.triggerDefaultVatAmountSelectedOption.emit(event);
  }
  editQuickEntry(data): void {
    this.quickEntryForm.patchValue({
      id: data.id,
      quickEntryNo: data.entryNumber,
      vatAmountType: data?.vatAmountType !== 0 ? data?.vatAmountType : null,
      payDateType: data.defaultPayDate !== 0 ? data.defaultPayDate : null,
      accountId: data.bookAccountId,
      reference: data.note,
      branchId: data.branchId,
      departmentId: data.departmentId,
    });
    if (data.branchId !== null && data.branchId !== undefined) {
      this.getDepartmentListByBranch(data.branchId);
    }
  }
}
