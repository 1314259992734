<div class="d-flex h-100">
  <div class="content-body content-body-scroll">
    <app-transactions-header
      (triggerUserAdd)="isQuickAdd($event)"
      [listParameters]="listParameters"
    >
    </app-transactions-header>
    <div class="accordion-toggle">
      <mat-icon
        (click)="toggleAccordion(); accordion.openAll()"
        *ngIf="isExpandAll"
        >keyboard_double_arrow_down
      </mat-icon>
      <mat-icon
        (click)="toggleAccordion(); accordion.closeAll()"
        *ngIf="!isExpandAll"
        >keyboard_double_arrow_up
      </mat-icon>
    </div>
    <mat-accordion [multi]="true">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-icons-outlined" class="text-primary"
              >description</mat-icon
            >
            <div class="d-flex flex-column">
              <span>User Details</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-body">
          <div class="mat-table-wrapper">
            <table
              mat-table
              [dataSource]="dataSource"
              aria-describedby="Permission Table"
            >
              <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef>User Name</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <div class="d-flex align-items-center button-wrapper">
                    <mat-radio-group [(ngModel)]="selectedIndex">
                      <mat-radio-button
                        (change)="onUserSelect(element, $event, i)"
                        [value]="i"
                      >
                      </mat-radio-button>
                    </mat-radio-group>

                    <span>{{ element?.name }}</span>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedUserColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedUserColumns"
              ></tr>
            </table>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel
        *ngIf="selectedUserId !== commonService.defaultHeaderGuidValue"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-icons-outlined" class="text-primary"
              >receipt</mat-icon
            >
            <div class="d-flex flex-column">
              <span>Permission</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-body">
          <div class="mat-table-wrapper">
            <table
              mat-table
              [dataSource]="permissionData"
              aria-describedby="Permission Table"
            >
              <ng-container matColumnDef="permission">
                <th mat-header-cell *matHeaderCellDef>Module Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.moduleName }}
                </td>
              </ng-container>
              <ng-container matColumnDef="access">
                <th mat-header-cell *matHeaderCellDef>Access</th>
                <td mat-cell *matCellDef="let element">
                  <mat-checkbox
                    [(ngModel)]="element.removeAccess"
                    (change)="isDataUpdated = true"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="displayedPermissionColumns"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedPermissionColumns"
              ></tr>
            </table>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <app-buttons
      *ngIf="isDataUpdated"
      [getModuleId]="moduleId"
      (triggerOnSaveClick)="onSave()"
      (triggerOnCancelClick)="onCancel()"
    >
    </app-buttons>
  </div>
</div>
