import { DatePipe, Location } from '@angular/common';
import { Component, Injector, Input, OnInit, Renderer2 } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AccountEntity,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import {
  GlobalComponent,
  MainListParameters,
  OpeningBalance,
  SaveOpeningBalanceModel,
} from '@app/core/Models';
import { NumberPipe } from '@app/core/Pipes/number-pipe.pipes';
import { CommonService, ModulePermission } from '@app/core/Services';
import {
  AccountState,
  GetAllBranchList,
  GetExplain,
  GetJournalAccounts,
  MenuState,
} from '@app/core/Store';
import {
  GetOpeningBalanceBasedOnAPId,
  GetOpeningBalanceList,
  GetOpeningBalanceListBasedOnBranchId,
  OpeningBalanceState,
  SaveOpeningBalance,
  SaveTrialBalance,
} from '@app/core/Store/opening-balance/opening-balance';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AddClosePopupComponent, CleanAllLinesComponent } from '../common';
import { OpeningBalanceExplainContractComponent } from './opening-balance-explain-contract/opening-balance-explain-contract.component';

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

@Component({
  selector: 'app-opening-balance',
  templateUrl: './opening-balance.component.html',
  styleUrls: ['./opening-balance.component.scss'],
})
export class OpeningBalanceComponent implements OnInit {
  @Input()
  getModuleId: number;

  openingBalanceForm: FormGroup;
  formOpeningBalance: UntypedFormGroup;
  isNewUser = false;
  openingBalanceArray: any;
  openingBalanceData: OpeningBalance[] = [];
  totalCredit = 0;
  totalDebit = 0;
  statusValue: any;
  moduleId = Modules.EditTrialBalance;
  tableDataSource: MatTableDataSource<AbstractControl>;
  subscriptionRouting: Subscription;
  displayOpeningBalanceColumns: string[] = [
    'srNo',
    'account',
    'debit',
    'credit',
    'closeButton',
  ];
  displayOpeningBalanceColumns2: string[] =
    this.displayOpeningBalanceColumns.map((x) => `f2_${x}`);
  openingBalanceAccountingDateData: SaveOpeningBalanceModel;
  trialBalanceAccountingDateData: SaveOpeningBalanceModel;

  selectedIds: any[] = [];
  accountGroupConstList: any[] = [];
  accountGroupList: any[] = [];
  openingBalanceBasedOnAPIdList: any[] = [];
  tempAccountGroupList: any[] = [];
  accountList: any = [];
  isEdit = false;
  maxLength = MaxLength;

  TotalDifference: any = 0;
  accountingDate: any;
  startDate: any;
  endDate: any;
  periodicDateList: any;
  accountingDateId: Guid;
  triggerResetDetailList: Subject<any> = new Subject<any>();

  moduleEnum = Modules;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  headerText: string;
  branchList: any[] = [];
  branchListParameters: MainListParameters = new MainListParameters();
  selectedBranchValue: any;
  router: Router;
  store: Store;
  commonService: CommonService;
  dialog: MatDialog;
  spinner: NgxSpinnerService;
  datepipe: DatePipe;
  modulePermission: ModulePermission;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private _Activatedroute: ActivatedRoute,
    private renderer: Renderer2,
    private globalComponent: GlobalComponent,
    private numberPipe: NumberPipe,
    private injector: Injector
  ) {
    this.formOpeningBalance?.markAsUntouched();
    this.router = injector.get<Router>(Router);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.modulePermission = injector.get<ModulePermission>(ModulePermission);
  }

  ngOnInit(): void {
    this.modulePermission.permissionData.subscribe((value) => {
      this.moduleId = value.data.id;
      this.getHeaderText();
      if (this.moduleId === Modules.OpeningBalance) {
        this.getBranchList();
      }
    });
    this.setOpeningBalanceForm(true);
    this.setPeriodDate();
    this.accountingDate = new Date();
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.isNewUser = JSON.parse(atob(params.get('isNewUser')!));
        }
      }
    );

    this.formOpeningBalance.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.formOpeningBalance.touched;
    });
  }

  getBranchList(): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.selectedBranchValue = this.branchList[0].id;
          } else {
            this.branchList = [];
          }
          this.getGroupAccountList();
        })
      )
      .subscribe();
  }

  onChangeBranch(branch): void {
    this.getGroupAccountList();
  }

  getHeaderText(): void {
    this.headerText =
      this.moduleId === Modules.OpeningBalance
        ? ModuleName.OpeningBalance
        : ModuleName.TrialBalance;
  }

  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.addDropdown();
    }
  }

  addDropdown(): void {
    const i = this.openingBalanceArray.length + 1;

    let tempArray: any[] = [];
    for (let data of this.accountGroupConstList) {
      const response: any = this.checkIdsExist(
        data.listModels,
        this.selectedIds
      );
      if (response !== null && response !== undefined) {
        let list: any[] = [];
        data.listModels.forEach((element) => {
          const isExist = response.filter((x) => x === element.id);
          if (isExist.length === 0) {
            list.push(element);
          }
        });
        const param = {
          groupId: 0,
          groupName: data.groupName,
          listModels: list,
        };
        if (param.listModels.length > 0) {
          tempArray.push(param);
        }
      } else {
        tempArray.push(data);
      }
    }
    this.accountGroupList.push(tempArray);
    this.tempAccountGroupList.push(tempArray);

    this.createRow();
  }

  setDropDown(dataDisplay: any, isReset: boolean): void {
    this.accountGroupList = [];
    this.tempAccountGroupList = [];
    this.selectedIds = [];

    dataDisplay.forEach((item) => {
      this.selectedIds.push(isReset ? item.account.id : item.accountId);
    });

    dataDisplay.forEach((item, i) => {
      let selectedIds: any[] = [];
      this.selectedIds.forEach((element, index) => {
        if (i !== index) {
          selectedIds.push(element);
        }
      });

      let tempArray: any[] = [];
      for (let data of this.accountGroupConstList) {
        const response: any = this.checkIdsExist(data.listModels, selectedIds);
        if (
          response !== null &&
          response !== undefined &&
          response.length > 0
        ) {
          let list: any[] = [];
          data.listModels.forEach((element) => {
            const removedata = response.find((x) => x === element.id);
            if (removedata === null || removedata === undefined) {
              list.push(element);
            }
          });
          const param = {
            groupId: 0,
            groupName: data.groupName,
            listModels: list,
          };
          if (param.listModels.length > 0) {
            tempArray.push(param);
          }
        } else {
          tempArray.push(data);
        }
      }
      this.accountGroupList.push(tempArray);
      this.tempAccountGroupList.push(tempArray);
    });

    if (!isReset) {
      this.editOpeningBalance(dataDisplay);
    }
  }

  checkIdsExist(listModels, selectedIds): any {
    let dataReponse: any[] = [];
    listModels.forEach((element) => {
      const data = selectedIds.find((x) => x === element.id);
      if (data !== null && data !== undefined) {
        dataReponse.push(data);
      }
    });
    return dataReponse;
  }

  resetForm(): void {
    const formArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;
    formArray.clear();
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formOpeningBalance.get(
            'openingBalanceArray'
          ) as UntypedFormArray;
          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
            formArray.controls[i]
              .get('id')
              ?.setValue(Guid.EMPTY as unknown as Guid);
            formArray.controls[i].get('debit')?.setValue(null);
            formArray.controls[i].get('credit')?.setValue(null);
            formArray.controls[i].get('account')?.setValue('');
            formArray.controls[i]
              .get('account')
              ?.setValidators(Validators.required);
            formArray.controls[i]
              .get('credit')
              ?.setValidators(Validators.required);
            formArray.controls[i]
              .get('debit')
              ?.setValidators(Validators.required);
            formArray.controls[i].get('account')?.updateValueAndValidity();
            formArray.controls[i].get('debit')?.updateValueAndValidity();
            formArray.controls[i].get('credit')?.updateValueAndValidity();
            formArray.controls[i].updateValueAndValidity();
          }
          this.totalCredit = 0;
          this.totalDebit = 0;
        }
      });
  }

  setOpeningBalanceForm(addNewRow: boolean): void {
    this.formOpeningBalance = new FormGroup({
      openingBalanceArray: new UntypedFormArray([]),
    });

    this.openingBalanceArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;

    this.setDataSource(this.openingBalanceArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.openingBalanceArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;

    this.openingBalanceArray.push(this.setForm());

    this.setDataSource(this.openingBalanceArray);
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      debit: new FormControl<number | null>(null, [Validators.required]),
      credit: new FormControl<number | null>(null, [Validators.required]),
      account: new FormControl<string | null>('', [Validators.required]),
      isExplain: new FormControl(false),
      isUpdated: new FormControl(false),
      isExplainSave: new FormControl(false),
      accountTypeId: new FormControl(0),
      explainList: new FormControl(),
      isAdvance: new FormControl(false),
    });
  }

  accountSelect(event, element, index: number): void {
    this.resetDropDown();
    this.getAccountGroupAndType(event.option.value, element, index);
  }

  getAccountGroupAndType(event, element, index): void {
    this.store.dispatch(new GetExplain(event.id)).subscribe((res) => {
      element.isExplain =
        res.account.explain.accountTypeId === AccountEntity.Supplier ||
        res.account.explain.accountTypeId === AccountEntity.Customer;
      element.isAdvance = res.account.explain.isAdvance;
      this.openingBalanceArray.controls[index]
        .get('accountTypeId')
        .setValue(
          res.account.explain.accountTypeId === AccountEntity.Supplier
            ? AccountEntity.Supplier
            : AccountEntity.Customer
        );
      this.openingBalanceArray.controls[index]
        .get('isExplain')
        .setValue(element.isExplain);
      this.openingBalanceArray.controls[index]
        .get('isAdvance')
        .setValue(element.isAdvance);
    });
  }

  onExplainContacts(index: number, element: any): void {
    const openingBalance = +element.value.credit + +element.value.debit;
    const param = {
      totalOpeningBalance: openingBalance,
      accountTypeId:
        this.openingBalanceArray.controls[index].get('accountTypeId').value,
      accountingPeriodId: this.accountingDateId,
      accountingDate: this.datepipe
        .transform(this.accountingDate, 'yyyy-MM-dd')
        ?.toString(),
      indexData: this.openingBalanceArray.controls[index].value,
      branchId: this.selectedBranchValue,
      isAdvance: this.openingBalanceArray.controls[index].value.isAdvance,
    };
    this.dialog
      .open(OpeningBalanceExplainContractComponent, {
        data: param,
        disableClose: true,
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.openingBalanceArray.controls[index]
            .get('explainList')
            ?.setValue(res.explainList);

          this.openingBalanceArray.controls[index]
            .get('isExplainSave')
            ?.setValue(res.isExplainSave);
        }
      });
  }

  onDeleteOpeningBalance(index: number): void {
    this.openingBalanceArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;

    this.accountGroupList.splice(index, 1);
    this.tempAccountGroupList.splice(index, 1);
    this.selectedIds.splice(index, 1);
    this.openingBalanceArray.removeAt(index);
    this.totalCredit = 0;
    this.totalDebit = 0;
    this.openingBalanceArray?.getRawValue().forEach((x) => {
      this.totalDebit += +x.debit;
      this.totalCredit += +x.credit;
    });
    this.setDataSource(this.openingBalanceArray);
    this.resetDropDown();
  }

  resetDropDown(): void {
    this.accountGroupList = [];
    this.tempAccountGroupList = [];
    this.selectedIds = [];
    this.setDropDown(this.openingBalanceArray.value, true);
  }

  editOpeningBalance(data: any): void {
    this.openingBalanceArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;

    this.openingBalanceArray.clear();

    let account: any;
    data.forEach((item, i) => {
      this.openingBalanceArray.push(this.buildOrderItemsForm(item));
      this.accountGroupList[i].forEach((items) => {
        const data = items.listModels.find((x: any) => x.id === item.accountId);
        if (data !== null && data !== undefined) {
          account = data;
        }
      });
      this.openingBalanceArray.controls[i].get('account').setValue(account);

      this.setFormValidity(i);
    });
    this.openingBalanceArray?.getRawValue().forEach((x) => {
      this.totalCredit += +x.credit;
      this.totalDebit += +x.debit;
    });
    this.setDataSource(this.openingBalanceArray);
    this.spinner.hide();
  }

  buildOrderItemsForm(item: any): FormGroup {
    const data = this.formBuilder.array(
      item.explainList.map((x) => this.formBuilder.control(x))
    );
    return this.formBuilder.group({
      id: item.id,
      account: item.accountId,
      debit: item.debit === 0 ? null : item.debit,
      credit: item.credit === 0 ? null : item.credit,
      isExplain: item.isExplain,
      accountTypeId: item.accountTypeId,
      isUpdated: false,
      isExplainSave: false,
      explainList: this.formBuilder.control(data.value),
      isAdvance: item.isAdvance,
    });
  }

  displayFn(account: any): string {
    return account && account.name ? account.name : '';
  }

  creditChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      this.totalCredit = 0;
      const formArray = this.formOpeningBalance.get(
        'openingBalanceArray'
      ) as UntypedFormArray;
      formArray.controls[index].get('debit')?.setValue(null);
      formArray.controls[index].get('debit')?.clearValidators();
      formArray.controls[index]
        .get('account')
        ?.setValidators(Validators.required);
      formArray.controls[index]
        .get('credit')
        ?.setValidators(Validators.required);
      if (this.isEdit) {
        formArray.controls[index].get('isUpdated')?.setValue(true);
        formArray.controls[index].get('isExplainSave')?.setValue(true);
      }
      formArray.controls[index].get('debit')?.updateValueAndValidity();
      formArray.controls[index].get('credit')?.updateValueAndValidity();
      formArray.controls[index].updateValueAndValidity();
    }
  }

  debitChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      this.totalDebit = 0;

      const formArray = this.formOpeningBalance.get(
        'openingBalanceArray'
      ) as UntypedFormArray;
      formArray.controls[index].get('credit')?.setValue(null);
      formArray.controls[index].get('credit')?.clearValidators();
      formArray.controls[index]
        .get('account')
        ?.setValidators(Validators.required);
      formArray.controls[index]
        .get('debit')
        ?.setValidators(Validators.required);
      if (this.isEdit) {
        formArray.controls[index].get('isUpdated')?.setValue(true);
        formArray.controls[index].get('isExplainSave')?.setValue(true);
      }
      formArray.controls[index].get('credit')?.updateValueAndValidity();
      formArray.controls[index].get('debit')?.updateValueAndValidity();
      formArray.controls[index].updateValueAndValidity();
    }
  }

  calAmount(): void {
    const formArray = this.formOpeningBalance.get(
      'openingBalanceArray'
    ) as UntypedFormArray;
    this.totalCredit = 0;
    this.totalDebit = 0;
    this.openingBalanceArray?.getRawValue().forEach((x) => {
      this.totalDebit += +x.debit;
      this.totalCredit += +x.credit;
    });
  }

  setFormValidity(index: number): void {
    this.openingBalanceArray.controls[index].clearValidators();
    if (+this.openingBalanceArray.controls[index].get('debit')?.value > 0) {
      this.openingBalanceArray.controls[index].get('credit')?.clearValidators();
      this.openingBalanceArray.controls[index]
        .get('debit')
        ?.setValidators(Validators.required);
    } else if (
      +this.openingBalanceArray.controls[index].get('credit')?.value > 0
    ) {
      this.openingBalanceArray.controls[index].get('debit')?.clearValidators();
      this.openingBalanceArray.controls[index]
        .get('credit')
        ?.setValidators(Validators.required);
    } else if (
      +this.openingBalanceArray.controls[index].get('debit')?.value === 0 &&
      +this.openingBalanceArray.controls[index].get('credit')?.value === 0
    ) {
      this.openingBalanceArray.controls[index]
        .get('debit')
        ?.setValidators(Validators.required);
      this.openingBalanceArray.controls[index]
        .get('credit')
        ?.setValidators(Validators.required);
    }
    this.openingBalanceArray.controls[index]
      .get('account')
      ?.setValidators(Validators.required);
    this.openingBalanceArray.controls[index]
      .get('debit')
      ?.updateValueAndValidity();
    this.openingBalanceArray.controls[index]
      .get('credit')
      ?.updateValueAndValidity();
    this.openingBalanceArray.controls[index].updateValueAndValidity();
  }

  getOptionText(option) {
    return option.name;
  }

  dataSubmit(): void {
    this.openingBalanceData = [];
    if (this.openingBalanceArray.length > 0) {
      this.openingBalanceArray?.getRawValue().forEach((x) => {
        const param: OpeningBalance = {
          id: x.id,
          accountId: x.account.id,
          credit: +x.credit,
          debit: +x.debit,
        };
        this.openingBalanceData.push(param);
        this.openingBalanceAccountingDateData = {
          accountingPeriodId: this.accountingDateId,
          date: this.datepipe
            .transform(this.accountingDate, 'yyyy-MM-dd')
            ?.toString(),
          branchId: this.selectedBranchValue,
          openingBalanceData: this.openingBalanceData,
        };
      });
    } else {
      this.openingBalanceAccountingDateData = {
        accountingPeriodId: this.accountingDateId,
        date: this.datepipe
          .transform(this.accountingDate, 'yyyy-MM-dd')
          ?.toString(),
        branchId: this.selectedBranchValue,
        openingBalanceData: this.openingBalanceData,
      };
    }
  }

  isUpdated(isExit: boolean): void {
    if (this.formOpeningBalance.invalid) {
      this.openingBalanceArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
          this.commonService.onFailure(NotificationTextMessage.blankLineError);
        });
      });
      return;
    }
    if (this.isEdit) {
      const isExplain = this.openingBalanceArray
        ?.getRawValue()
        .filter((x) => x.isExplain === true);

      let sumOfAllDrCr = 0;
      let sumOfTotalCrDrExplain = 0;
      this.openingBalanceArray?.getRawValue().filter((element, i) => {
        if (
          isExplain.length > 0 &&
          element.explainList !== null &&
          element.explainList !== undefined &&
          element.explainList.length > 0 &&
          element.isExplain === true
        ) {
          let total = +element.credit! + +element.debit!;

          sumOfAllDrCr = +sumOfAllDrCr + +total;

          element.explainList.forEach((element) => {
            let totalExplain = +element.credit! + +element.debit!;

            sumOfTotalCrDrExplain = +sumOfTotalCrDrExplain + +totalExplain;
          });
        }
      });

      if (isExplain.length > 0 && sumOfAllDrCr !== sumOfTotalCrDrExplain) {
        this.dialog
          .open(AddClosePopupComponent, {
            data: {
              moduleId: this.moduleId,
              isEdit: false,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.isDraftCheck(isExit);
            }
          });
      } else {
        this.isDraftCheck(isExit);
      }
    } else {
      this.isDraftCheck(isExit);
    }
  }

  isDraftCheck(isExit: boolean): void {
    if (this.totalCredit !== this.totalDebit) {
      this.dialog
        .open(AddClosePopupComponent, {
          data: {
            moduleId: this.moduleId,
            isEdit: true,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.onSave(isExit);
          }
        });
    } else {
      this.onSave(isExit);
    }
  }

  onSave(isExit: boolean): void {
    if (this.formOpeningBalance.invalid) {
      this.openingBalanceArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
      return;
    } else if (
      this.accountingDate === null &&
      this.moduleId === this.moduleEnum.EditTrialBalance
    ) {
      this.commonService.onFailure(
        NotificationTextMessage.selectAccountingPeriod
      );
      return;
    } else if (
      this.accountingDate === null ||
      this.accountingDate === undefined
    ) {
      this.commonService.onFailure(
        NotificationTextMessage.validPeriodDateMessage
      );
      return;
    }
    this.dataSubmit();
    if (this.moduleId === Modules.EditTrialBalance) {
      this.store
        .dispatch(new SaveTrialBalance(this.openingBalanceAccountingDateData))
        .subscribe((res) => {
          if (res.openingBalance.isTrialBalance) {
            this.commonService.onSuccess(
              NotificationTextMessage.successMessage
            );
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
          this.commonService.isInitialValueChange = false;
          if (isExit && !this.isNewUser) {
            this.location.back();
          }
        });
    } else if (this.moduleId === Modules.OpeningBalance) {
      this.store
        .dispatch(new SaveOpeningBalance(this.openingBalanceAccountingDateData))
        .subscribe((res) => {
          if (res.openingBalance.isOpeningBalance) {
            this.commonService.onSuccess(
              NotificationTextMessage.successMessage
            );
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
          this.commonService.isInitialValueChange = false;
          if (isExit && !this.isNewUser) {
            this.location.back();
          }
        });
    }
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    this.ngOnInit();
    this.calAmount();
    this.getGroupAccountList(this.accountingDateId);
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  onSearch(event: any, i: number): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList[i]
    );
    if (list.length === 0) {
      this.openingBalanceArray.controls[i].get('account').setValue('');
      this.accountGroupList[i] = this.tempAccountGroupList[i];
    }
  }

  resetAccountList(element: any, index: any): void {
    this.accountGroupList[index] = this.tempAccountGroupList[index];
    setTimeout(() => {
      this.scrollIntoView(element);
    }, 0);
  }

  onAccountSearch(event: any, index: number): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList[index]
    );
    this.accountGroupList[index] = list;
  }

  scrollIntoView(element) {
    if (element.value.account !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  countDifferenceOfDrCr(columnName: string) {
    columnName = columnName.replace('f2_', '').replace('f3_', '');

    let returnValue;
    if (columnName == this.displayOpeningBalanceColumns[0]) {
      returnValue = 'Difference';
    } else if (columnName == this.displayOpeningBalanceColumns[1]) {
      returnValue = '';
    } else if (columnName == this.displayOpeningBalanceColumns[2]) {
      if (this.totalCredit > this.totalDebit) {
        returnValue = this.numberPipe.transform(this.calDifferenceAmount());
      } else {
        returnValue = this.numberPipe.transform('0');
      }
    } else if (columnName == this.displayOpeningBalanceColumns[3]) {
      if (this.totalCredit < this.totalDebit) {
        returnValue = this.numberPipe.transform(this.calDifferenceAmount());
      } else {
        returnValue = this.numberPipe.transform('0');
      }
    }
    return returnValue;
  }

  calDifferenceAmount(): any {
    this.TotalDifference = this.totalCredit - this.totalDebit;
    if (this.TotalDifference < 0) {
      this.TotalDifference = this.TotalDifference.toString().replace('-', '');
    }
    return this.TotalDifference;
  }

  triggerDateChange(data: any): void {
    this.startDate = this.datepipe
      .transform(data.startDate, 'yyyy-MM-dd')
      ?.toString();
    this.endDate = this.datepipe
      .transform(data.endDate, 'yyyy-MM-dd')
      ?.toString();
    this.periodicDateList.forEach((element) => {
      if (
        this.datepipe.transform(element.fromDate, 'yyyy-MM-dd') ===
          this.startDate &&
        this.datepipe.transform(element.toDate, 'yyyy-MM-dd') === this.endDate
      ) {
        this.accountingDateId = element.id;
      }
    });
    this.getGroupAccountList(this.accountingDateId);
  }

  setPeriodDate(): void {
    this.periodicDateList = this.globalComponent.getFinancialPeriod();
  }

  getOpeningBalanceBasedOnAPId(openingBalanceBasedOnAPIdList): void {
    this.accountingDate = openingBalanceBasedOnAPIdList.accountingPeriodId;
    if (openingBalanceBasedOnAPIdList.openingBalanceData.length > 0) {
      this.isEdit = true;
      this.totalCredit = 0;
      this.totalDebit = 0;

      this.accountGroupList.push(this.accountGroupConstList);
      this.tempAccountGroupList.push(this.accountGroupConstList);

      this.setDropDown(openingBalanceBasedOnAPIdList.openingBalanceData, false);
    } else {
      this.accountGroupList = [];
      this.tempAccountGroupList = [];
      this.selectedIds = [];

      this.accountGroupList.push(this.accountGroupConstList);
      this.tempAccountGroupList.push(this.accountGroupConstList);

      this.totalCredit = 0;
      this.totalDebit = 0;

      this.openingBalanceArray = this.formOpeningBalance.get(
        'openingBalanceArray'
      ) as UntypedFormArray;

      this.openingBalanceArray.clear();
      this.setOpeningBalanceForm(true);
    }
  }

  getOpeningBalanceBasedOnDate(openingBalanceBasedOnAPIdList): void {
    this.accountingDate = openingBalanceBasedOnAPIdList.date;
    if (openingBalanceBasedOnAPIdList.openingBalanceData.length > 0) {
      this.isEdit = true;
      this.totalCredit = 0;
      this.totalDebit = 0;

      this.accountGroupList.push(this.accountGroupConstList);
      this.tempAccountGroupList.push(this.accountGroupConstList);

      this.setDropDown(openingBalanceBasedOnAPIdList.openingBalanceData, false);
    } else {
      this.accountGroupList = [];
      this.tempAccountGroupList = [];
      this.selectedIds = [];

      this.accountGroupList.push(this.accountGroupConstList);
      this.tempAccountGroupList.push(this.accountGroupConstList);

      this.totalCredit = 0;
      this.totalDebit = 0;

      this.openingBalanceArray = this.formOpeningBalance.get(
        'openingBalanceArray'
      ) as UntypedFormArray;

      this.openingBalanceArray.clear();
      this.setOpeningBalanceForm(true);
    }
  }

  dateChange(data): void {
    this.accountingDate = moment(data.value.valueOf()).format('YYYY-MM-DD');
  }

  getGroupAccountList(accountingDateId?: any): void {
    const journalAccounts = this.store.dispatch(new GetJournalAccounts());
    let openingBalanceBasedOnAPId: any;

    if (this.moduleId === Modules.OpeningBalance) {
      if (this.branchList.length > 0) {
        openingBalanceBasedOnAPId = this.store.dispatch(
          new GetOpeningBalanceListBasedOnBranchId(this.selectedBranchValue)
        );
      } else {
        openingBalanceBasedOnAPId = this.store.dispatch(
          new GetOpeningBalanceList()
        );
      }
    } else if (this.moduleId === Modules.EditTrialBalance) {
      openingBalanceBasedOnAPId = this.store.dispatch(
        new GetOpeningBalanceBasedOnAPId(accountingDateId)
      );
    }

    forkJoin([journalAccounts, openingBalanceBasedOnAPId]).subscribe((res) => {
      this.accountList = this.store.selectSnapshot(
        AccountState.getJournalAccounts
      );
      this.accountGroupConstList = this.accountList;

      this.openingBalanceBasedOnAPIdList = this.store.selectSnapshot(
        OpeningBalanceState.getJournalAccounts
      );

      if (this.moduleId === Modules.OpeningBalance) {
        this.getOpeningBalanceBasedOnDate(this.openingBalanceBasedOnAPIdList);
      } else {
        this.getOpeningBalanceBasedOnAPId(this.openingBalanceBasedOnAPIdList);
      }
    });
  }
}
