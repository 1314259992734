export enum ImportStep {
  Select = -1,
  Upload = 0,
  MapField = 1,
  Finish = 2,
}

export class FileImportRequestModel {
  file: File;
  step: ImportStep;
}
export enum CheckDuplicateBy {
  Name = 1,
  Code = 2,
  Email = 3,
}
