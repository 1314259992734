<form [formGroup]="companyPreferenceForm">
  <div>
    <div class="form-field">
      <mat-radio-group
        aria-label="Select an option"
        formControlName="autoGenerate"
        (change)="autoGenerateChange($event)"
      >
        <mat-radio-button [value]="true" class="mr-3"
          >Auto Generate</mat-radio-button
        >
        <mat-radio-button [value]="false">Add Manually</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="form-wrapper d-flex gap-20">
      <div class="form-field">
        <p>Prefix</p>
        <mat-form-field class="w-150">
          <input
            matInput
            formControlName="prefix"
            maxlength="{{ maxLength.maxLength5 }}"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Suffix</p>
        <mat-form-field class="w-150">
          <input
            matInput
            formControlName="suffix"
            maxlength="{{ maxLength.maxLength5 }}"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="form-field">
      <mat-radio-group
        aria-label="Select an option"
        formControlName="repeatType"
      >
        <mat-radio-button [value]="repeteType.Daily" class="mr-1"
          >Daily</mat-radio-button
        >
        <mat-radio-button [value]="repeteType.Weekly" class="mr-1"
          >Weekly</mat-radio-button
        >
        <mat-radio-button [value]="repeteType.Monthly" class="mr-1"
          >Monthly</mat-radio-button
        >
        <mat-radio-button [value]="repeteType.Yearly">Yearly</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</form>
