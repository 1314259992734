import { Component, Input, ViewChild } from '@angular/core';
import { Modules } from '@app/core/Enum';
import { GetIncomeReceivableAgeingSummaryOverview } from '@app/core/Store';
import { Store } from '@ngxs/store';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexNoData,
  ApexPlotOptions,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { Observable } from 'rxjs';

export type ReceivableSummaryChart = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  noData: ApexNoData;
  colors: string[];
};

@Component({
  selector: 'app-overview-receivable-summary-chart',
  templateUrl: './overview-receivable-summary-chart.component.html',
  styleUrls: ['./overview-receivable-summary-chart.component.scss'],
})
export class OverviewReceivableSummaryChartComponent {
  @ViewChild('chart') chart: ChartComponent;
  public ReceivableSummaryChart: Partial<ReceivableSummaryChart>;

  @Input() triggerHeaderFilter: Observable<any>;
  reportList: any;

  @Input()
  getModuleId: number;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.triggerHeaderFilter?.subscribe((filterData) => {
      if (this.getModuleId === Modules.IncomeOverview) {
        this.store
          .dispatch(new GetIncomeReceivableAgeingSummaryOverview(filterData))
          .subscribe((x) => {
            this.reportList = x.invoice.incomeBreakdownOverview;
            this.getReceivableSummary(filterData);
          });
      }
    });
  }

  getReceivableSummary(filterData?: any): void {
    const tempCategories: any = [];
    const tempSeries: any = [];

    for (const barchart of this.reportList[`categories`]) {
      tempCategories.push(barchart);
    }

    for (const barchart of this.reportList[`series`]) {
      tempSeries.push(barchart.data);
    }

    this.ReceivableSummaryChart = {
      series: [
        {
          name: 'serie',
          data: [1500, 2200, 500, 1200, 2250],
        },
      ],
      chart: {
        type: 'bar',
        height: 300,
        toolbar: {
          show: false,
        },
      },
      noData: {
        text: 'No Data to Display',
        align: 'center',
        verticalAlign: 'middle',
        style: {
          color: '#6d41a1',
          fontSize: '18px',
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '10%',
          barHeight: '40%',
          distributed: true,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: '12px',
          colors: ['#fff'],
        },
      },
      legend: {
        show: false,
        markers: {
          fillColors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397', '#7A5295'],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff'],
      },
      xaxis: {
        labels: {
          show: true,
        },
      },
      tooltip: {
        marker: {
          show: false,
        },
        enabled: true,
        fillSeriesColor: false,
      },
      fill: {
        colors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397', '#7A5295'],
      },
    };
  }
}
