export enum GroupNames {
  Turnover = 1,
  CostOfSales = 2,
  SellingAndDistributionCosts = 3,
  AdministrativeExpenses = 4,
  OtherOperatingIncome = 5,
  Income = 6,
  NonOperatingProfitLoss = 7,
  AmountsWrittenOffInvestments = 8,
  OtherInterestReceivableAndSimilarIncome = 9,
  InterestPayableAndSimilarCharges = 10,
  Taxation = 11,
  FixedAssetsIntangibles = 12,
  FixedAssetsLeased = 13,
  Investments = 14,
  FixedAssetsTangibles = 15,
  PrepaymentsAndAccruedIncome = 16,
  Creditors = 17,
  FixedAssetsInvestments = 18,
  ProvisionsForLiabilities = 19,
  AccrualsAndDeferredIncome = 20,
  CapitalAndReserves = 21,
  Suspense = 22,
  MembersRemunerationCharged = 23,
  CashAtBankInHand = 24,
  LoansAndOtherDebtsDueToMembers = 25,
  MembersOtherInterests = 26,
  IncomeFromSharesInGroupUndertakings = 27,
  Inventories = 28,
  Debtors = 29,
  IncomeFromParticipatingInterests = 30,
  IncomeFromOtherCurrentAssetInvestments = 31,
  IncomeFromOtherFixedAssetInvestments = 32,
  ProfitAndLossAppropriations = 33,
  CapitalAccount = 34,
  RevaluationReserve = 35,
  FixedAssetsInvestmentProperty = 36,
}
