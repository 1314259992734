<div class="content">
  <div class="content-body content-body-scroll">
    <app-transactions-header
      (triggerDataFromHeader)="getDataFromHeader($event)"
      [listParameters]="listParameters"
    >
    </app-transactions-header>
    <div class="header-actions wp-100">
      <app-header-actions
        (triggerDataFromHeader)="getDataFromHeader($event)"
        [listParameters]="listParameters"
        [parentModuleId]="this.moduleEnum.NominalLedger"
      >
      </app-header-actions>
    </div>
    <div class="mt-1">
      <app-expand-collapse
        [triggerExpandedCollapseList]="triggerExpandedCollapseList"
        [listParameters]="listParameters"
        [moduleId]="moduleId"
      >
      </app-expand-collapse>
    </div>
  </div>
</div>
