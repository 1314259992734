import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ActionType,
  ConfirmationType,
  DataType,
  ModuleName,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { FileImportRequestModel, ImportStep } from '@app/core/Enum/import';
import { MainListParameters } from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  CommonState,
  ExportReceipt,
  ExportVatAudit,
  GetBankImportTemplate,
  GetCustomBankAccountList,
  GetReportList,
  ImportBank,
  ImportState,
  SendEmail,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  AddReceiptPaymentPopupComponent,
  ConfirmationBoxComponent,
} from '@app/modules/common';
import { DynamicGridListComponent } from '@app/modules/dynamic-grid-list/dynamic-grid-list.component';
import { Select, Store } from '@ngxs/store';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-grids';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-bank-import',
  templateUrl: './bank-import.component.html',
  styleUrls: ['./bank-import.component.scss'],
})
export class BankImportComponent implements OnInit {
  selectedFileName: string;
  fileInfo: string = '';
  isExpandAll = false;
  selectedFile: any;
  url: any;
  bankAccountList: any;
  dataType = DataType;
  moduleEnum = Modules;
  columnWidth: number = 100;
  totalRecords = 0;
  gridData: any = [];
  tempGridData: any[] = [];
  reportColumnList: any[] = [];
  tempReportColumnList: any[] = [];
  ids: Array<Guid> = [];
  datepipe: DatePipe;
  listParameters: MainListParameters = new MainListParameters();
  state: DataStateChangeEventArgs = {
    skip: 0,
    take: 20,
    sorted: [],
    group: [],
  };

  gridHeight: number;
  columns: any = [];
  moduleId = Modules.BankImportHistory;
  moduleName = ModuleName.BankImportTransactionHistory;
  customId = Guid.EMPTY as unknown as Guid;
  moreActionCount = 4;
  selectedRowIndex: number = -1;

  @Select(CommonState.actions)
  actions$: Observable<any>;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(DynamicGridListComponent, { static: true })
  reportList;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  notify: NotificationService;

  fileImportRequestModel: FileImportRequestModel;

  showPaginator = true;

  constructor(
    public dialog: MatDialog,
    private store: Store,
    private notifier: NotificationService,
    private spinner: NgxSpinnerService,
    public commonService: CommonService,
    private injector: Injector,
    private _Activatedroute: ActivatedRoute
  ) {
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.notify = injector.get<NotificationService>(NotificationService);
  }

  ngOnInit(): void {
    this.commonService.toggleMenu = false;
    this.spinner.hide();
    this.getBankAccountList().then(() => {
      this._Activatedroute.paramMap.subscribe((params) => {
        if (params.keys.length > 0) {
          this.customId = atob(params.get('customId')!) as unknown as any;
          this.listParameters.moduleId = this.customId;
          this.getList();
        }
      });
    });
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  onSave(): void {
    this.fileImportRequestModel = {
      file: this.selectedFile,
      step: ImportStep.Upload,
    };

    this.spinner.show();
    this.store
      .dispatch(new ImportBank(this.fileImportRequestModel, this.customId))
      .subscribe(() => {
        const details = this.store.selectSnapshot(ImportState.getImportData);
        this.spinner.hide();
        if (details.StatusCode === 0 || details.StatusCode !== undefined) {
          this.commonService.onFailure(details.Message);
        } else {
          this.commonService.onSuccess(NotificationTextMessage.fileUploaded);
          this.getList();
          this.onCancel();
        }
        (error) => {
          this.commonService.onFailure(error.message);
          this.spinner.hide();
        };
      });
  }

  onCancel(): void {
    this.selectedFile = '';
    this.url = '';
    this.fileInfo = '';
    this.selectedFileName = '';
    history.back();
  }

  downloadTemplateFile(): void {
    this.spinner.show();
    this.store.dispatch(new GetBankImportTemplate()).subscribe((res) => {});
  }

  onFileOpen(event): void {
    event.target.value = null;
  }

  checkSpecialChar(selectedFile: any): any {
    const format = /[!@#$%^&*:"<>?{}|]/;
    return format.test(selectedFile.name);
  }

  formatBytes(bytes: number): string {
    const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const factor = 1024;
    let index = 0;
    while (bytes >= factor) {
      bytes /= factor;
      index++;
    }
    return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
  }

  onFileSelected(input: HTMLInputElement): void {
    const file = input.files![0];
    this.selectedFileName = file.name;
    if (file.size / 1024 / 1024 > 10) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.fileSizeExceeds
      );
      return;
    }

    if (this.checkSpecialChar(file)) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.filenameWithSpecialCharacter
      );
      return;
    }

    this.fileInfo = `${file.name} (${this.formatBytes(file.size)})`;

    if (!input.files || !input.files.length) {
      return;
    }

    this.selectedFile = input.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(input.files[0]);
    reader.onload = () => {
      this.url = reader.result;
    };
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCloseClick(): void {
    this.dialog
      .open(AddClosePopupComponent, {})
      .afterClosed()
      .subscribe((result) => {
        this.commonService.setLocalStorage('selectedBank', this.customId);
      });
  }

  onbankNameChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.listParameters.moduleId = this.customId;
      this.getList();
    }
  }

  async getBankAccountList(): Promise<void> {
    this.spinner.show();
    await this.store
      .dispatch(new GetCustomBankAccountList())
      .toPromise()
      .then((res) => {
        this.bankAccountList = res.common.customBankAccount;
        if (this.commonService.isEmpty(this.customId)) {
          this.customId = this.bankAccountList[0].id;
          this.listParameters.moduleId = this.customId;
          this.getList();
        }
      });
  }

  redirect(columnName: string, data: any): void {
    this.commonService.onEditRouting(
      true,
      Modules.BankImportTransactionHistory,
      data.id,
      true,
      Modules.BankImportTransactionHistory
    );
  }

  calculateGridHeight(): void {
    if (this.moduleId !== undefined) {
      if (this.moduleId !== Modules.VAT) {
        const screenHeight = window.innerHeight;
        const headerHeight = 56;
        const gridHeaderHeight = 75;
        const tableHeaderHeight = 56;
        const contentPadding = 15;
        const groupableSectionHeight = 48;
        const gridHeaderTitle = 34;
        let paginationHeight = 0;

        if (
          this.moduleId === Modules.TransactionListByDate ||
          this.moduleId === Modules.RecentTransactions ||
          this.moduleId === Modules.DayBook ||
          this.moduleId === Modules.OpenInvoice ||
          this.moduleId === Modules.UnpaidPurchases ||
          this.moduleId === Modules.AccountList ||
          this.moduleId === Modules.CustomerAdvanceReport ||
          this.moduleId === Modules.CustomerList ||
          this.moduleId === Modules.CustomerAgeingList ||
          this.moduleId === Modules.TransactionsByCustomer ||
          this.moduleId === Modules.SalesProductList ||
          this.moduleId === Modules.CustomerStatement ||
          this.moduleId === Modules.SalesInvoiceList ||
          this.moduleId === Modules.IncomeByCustomerSummary ||
          this.moduleId === Modules.CustomerBalanceSummary ||
          this.moduleId === Modules.SupplierAdvanceReport ||
          this.moduleId === Modules.SupplierList ||
          this.moduleId === Modules.SupplierAgeingList ||
          this.moduleId === Modules.TransactionsBySupplier ||
          this.moduleId === Modules.SupplierStatement ||
          this.moduleId === Modules.PurchasesInvoiceList ||
          this.moduleId === Modules.AccountDetails
        ) {
          this.gridHeight =
            screenHeight -
            (headerHeight +
              gridHeaderHeight +
              tableHeaderHeight +
              contentPadding +
              groupableSectionHeight +
              gridHeaderTitle +
              paginationHeight);
        } else {
          this.gridHeight =
            screenHeight -
            (headerHeight +
              paginationHeight +
              gridHeaderHeight +
              tableHeaderHeight +
              contentPadding +
              groupableSectionHeight);
        }
      }
    }
  }

  onSearch(input: Event): void {
    const searchText: string = (input.target as HTMLInputElement).value;
    this.listParameters.search = searchText;
    this.getList();
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      branchIds: this.listParameters.branchIds ?? [],
      departmentIds: this.listParameters.departmentIds ?? [],
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids:
        this.listParameters.ids !== undefined &&
        this.listParameters.ids.length > 0
          ? this.listParameters.ids
          : null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      type:
        this.moduleId === Modules.ComparativeReport
          ? this.listParameters.type === undefined
            ? -1
            : this.listParameters.type
          : 0,
    };

    return queryParams;
  }

  getList(isGridActionsSet: boolean = false): void {
    const params = this.getParamter();

    if (params !== null && params !== undefined && params.moduleId !== 0) {
      this.spinner.show();
      this.store
        .dispatch(new GetReportList(params, Modules.BankImportHistory))
        .subscribe((res) => {
          this.reportColumnList = [];

          let columnName = res.common.mainList.resultSet.columns;
          let rowData = res.common.mainList.resultSet.data;

          this.actions$.subscribe((x) => {
            if (x !== null && x !== undefined && x.length > 4) {
              this.moreActionCount = 3;
            }
          });

          this.addNewLineItem(columnName, rowData);

          let filteredColumnList;
          const excludedNames = ['ExtraValue', 'srNo'];

          filteredColumnList = columnName.filter(
            (i) => !excludedNames.includes(i.name)
          );

          const colArray: any = [];
          filteredColumnList.forEach((element, i) => {
            colArray.push({
              name: element.name,
              id: element.id,
              userId: element.userId,
              dataType: element.dataType,
              allowSortBy: element.allowSortBy,
              alignment: element.alignment,
              moduleId: element.moduleId,
              isSelected: element.name === 'Id' ? false : element.isSelected,
              isLocked: element.isLocked,
              moduleGridColumnId: element.moduleGridColumnId,
              columnType: this.getDataType(element.dataType),
            });
          });

          const filteredGridList = rowData.map((element) => {
            let e = {};

            Object.keys(element).forEach((key) => {
              const newKey = columnName[key].name;
              e[newKey] = element[key];
            });
            return e;
          });

          this.reportColumnList = rowData.map((element, i) => {
            let e = {};
            Object.keys(element).forEach((key, j) => {
              const newKey = columnName[key].name;
              e[newKey] = element[j];
            });
            return e;
          });

          this.spinner.hide();
          this.tempGridData = filteredGridList;
          if (
            this.columns.length > 0 &&
            this.columns.length !== colArray.length
          ) {
            this.columns = [];
            this.spinner.show();
            setTimeout(() => {
              this.columns = colArray;
              this.spinner.hide();
            }, 0);
          } else if (this.columns.length === 0) {
            this.columns = colArray;
          }

          this.totalRecords =
            res.common.mainList.paginationModel.totalItemCount;

          this.state.take = this.listParameters.pageSize;

          this.gridData = {
            result: this.reportColumnList,
            count: this.totalRecords,
          };
        });
    }
  }

  addNewLineItem(columnName, rowData): void {
    if (!columnName.some((e) => e.name === 'srNo')) {
      columnName.push({
        name: 'srNo',
        id: null,
        userId: null,
        dataType: 1,
        allowSortBy: true,
        alignment: null,
        isSelected: false,
        isLocked: false,
        moduleGridColumnId: 1,
        columnType: this.getDataType(1),
      });
      rowData = rowData.map(function (currentValue, index) {
        let extraField: any;
        extraField = {
          id: null,
          alignment: null,
          isBold: false,
          moduleId: 0,
          rowData: index,
        };

        currentValue.push(extraField);
        return currentValue;
      });
    }
  }

  getDataType(type: number): any {
    let dataType = '';

    switch (type) {
      case DataType.String:
        dataType = 'text';
        break;

      case DataType.Int32:
      case DataType.Int64:
      case DataType.Float:
      case DataType.Currency:
        dataType = `numeric`;
        break;

      case DataType.Bool:
        dataType = `boolean`;
        break;

      case DataType.Date:
      case DataType.DateTime:
        dataType = `date`;
        break;
      default:
        dataType = 'text';
        break;
    }

    return dataType;
  }

  getSelectedRowIndex(selectedRowIndex, rowIndex: number): void {
    this.selectedRowIndex =
      selectedRowIndex === -1 ? selectedRowIndex : rowIndex;
  }

  onButtonClick(actionType: any, data: any): void {
    let id = data.Id.rowData;
    this.ids = [];
    this.ids.push(id);

    let moduleId =
      this.moduleId === Modules.CashEntry || this.moduleId === Modules.BankEntry
        ? +data.ExtraValue
        : this.moduleId;

    this.commonService
      .isDataInLockedPeriod(this.ids, this.moduleId)
      .subscribe((isValidPeriod) => {
        if (
          isValidPeriod ||
          actionType === ActionType.Email ||
          actionType === ActionType.Export ||
          actionType === ActionType.View
        ) {
          switch (actionType) {
            case ActionType.Update:
              this.commonService.onEditRouting(true, moduleId, id);

              break;

            case ActionType.Delete:
              this.onDeleteClick(id, moduleId);
              break;

            case ActionType.Export:
              this.ids = [];
              this.ids.push(id);
              if (moduleId !== Modules.SubmitVat) {
                this.store
                  .dispatch(new ExportReceipt(moduleId, this.ids, false))
                  .subscribe();
              } else {
                this.store.dispatch(new ExportVatAudit(id, false)).subscribe();
              }
              break;

            case ActionType.Email:
              this.store
                .dispatch(new SendEmail(moduleId, id))
                .subscribe((result: any) => {
                  if (result.common.isMailSent) {
                    this.notify.success(
                      NotificationHeader.success,
                      NotificationTextMessage.emailMessage
                    );
                  } else {
                    this.notify.error(
                      NotificationHeader.error,
                      NotificationTextMessage.emailNotSendMessage
                    );
                  }
                });
              break;

            case ActionType.AddReceipt:
              const params = {
                isReceipt: true,
                id: id,
              };
              this.dialog
                .open(AddReceiptPaymentPopupComponent, {
                  data: params,
                  disableClose: true,
                })
                .afterClosed()
                .subscribe((result: boolean) => {
                  if (result) {
                    this.getList();
                  }
                });
              break;

            case ActionType.AddPayment:
              const param = {
                isReceipt: false,
                id: id,
              };
              this.dialog
                .open(AddReceiptPaymentPopupComponent, {
                  data: param,
                  disableClose: true,
                })
                .afterClosed()
                .subscribe((result: boolean) => {
                  if (result) {
                    this.getList();
                  }
                });
              break;
          }
        } else {
          this.commonService.onFailure(
            NotificationTextMessage.dataInLockedPeriod
          );
        }
      });
  }

  onDeleteClick(id: any, moduleId: number): void {
    this.ids = [];
    this.ids.push(id);

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.getList();
          }
        });
    }
  }

  dataStateChange(state: DataStateChangeEventArgs): void {
    if (
      state.action.requestType === 'filtering' ||
      state.action.requestType === 'paging' ||
      state.action.requestType === 'sorting' ||
      state.action.requestType === 'grouping' ||
      state.action.requestType === 'ungrouping'
    ) {
      this.state = state;
      if (
        state.sorted !== null &&
        state.sorted !== undefined &&
        state.sorted.length > 0
      ) {
        this.listParameters.sortBy = state.sorted[0].name.replace(
          '.rowData',
          ''
        );
        this.listParameters.sortOrder =
          state.sorted[0].direction === 'ascending' ? true : false;
      }
      this.listParameters.pageNumber = state.skip / state.take + 1;
      this.listParameters.pageSize = state.take ?? 0;
      this.getList();
    }
  }
}
