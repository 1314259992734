import { Component, Input, OnInit } from '@angular/core';
import { Modules } from '@app/core/Enum';
import {
  GetDashboardExpenseByCategoriesOverview,
  GetDashboardIncomeByCategoriesOverview,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import {
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexResponsive,
} from 'ng-apexcharts';
import { Observable } from 'rxjs';

export type businessSnapshotPieChartComponent = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
  tooltip: any;
};

@Component({
  selector: 'app-business-snapshot-pie-chart',
  templateUrl: './business-snapshot-pie-chart.component.html',
  styleUrls: ['./business-snapshot-pie-chart.component.scss'],
})
export class BusinessSnapshotPieChartComponent implements OnInit {
  @Input()
  triggerIncomeData: Observable<any>;
  @Input()
  triggerExpenseData: Observable<any>;
  @Input() getModuleId: number;
  public businessSnapshotPieChartComponent: Partial<businessSnapshotPieChartComponent>;
  isloadData = false;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.triggerIncomeData?.subscribe((filterData) => {
      if (this.getModuleId === Modules.BusinessSnapshot) {
        this.store
          .dispatch(new GetDashboardIncomeByCategoriesOverview(filterData))
          .subscribe((res) => {
            this.getChartData(
              res.dashboard.dashboardIncomeByCategoriesOverview
            );
          });
      }
    });
    this.triggerExpenseData?.subscribe((filterData) => {
      if (this.getModuleId === Modules.BusinessSnapshot) {
        this.store
          .dispatch(new GetDashboardExpenseByCategoriesOverview(filterData))
          .subscribe((res) => {
            this.getChartData(
              res.dashboard.dashboardExpenseByCategoriesOverview
            );
          });
      }
    });
  }

  setlabel(data): any {
    let label: any[] = [];
    data.forEach((element) => {
      label.push(element.name + ' £ ' + element.data);
    });
    return label;
  }

  getChartData(filterData): void {
    this.isloadData = true;
    let overViewData = filterData;
    if (overViewData.series !== undefined && overViewData.series !== null) {
      const labels = this.setlabel(overViewData.series);
      let series: any[] = [];
      overViewData.series.forEach((element) => {
        series.push(element.data);
      });
      this.businessSnapshotPieChartComponent = {
        series: series,
        chart: {
          width: 560,
          height: 400,
          type: 'pie',
          offsetX: -50,
          offsetY: 0,
        },
        fill: {
          colors: ['#7B76D3', '#BA5397', '#F4C64D', '#00E396', '#FF0000'],
        },
        legend: {
          show: true,
          position: 'right',
          horizontalAlign: 'right',
          offsetX: -40,
          offsetY: 30,
          fontSize: '14px',
          fontFamily: 'Source Sans Pro, sans-serif',
          markers: {
            fillColors: ['#7B76D3', '#BA5397', '#F4C64D', '#00E396', '#FF0000'],
            offsetY: 3,
            offsetX: -5,
          },
          itemMargin: {
            vertical: 2,
          },
        },
        tooltip: {
          theme: 'light',
          fillSeriesColor: false,
          style: {
            fontFamily: '"Source Sans Pro", sans-serif',
            fontSize: '14px',
          },
          marker: {
            show: false,
          },
          fixed: {
            position: 'topLeft',
          },
          y: {
            formatter: function (val) {
              return '';
            },
            title: {
              formatter: function (seriesName) {
                return seriesName;
              },
            },
          },
        },

        dataLabels: {
          enabled: false,
        },
        labels: labels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      };
    }
  }
}
