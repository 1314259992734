import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddClosePopupComponent } from '@app/modules/common';

@Component({
  selector: 'app-invoice-templates',
  templateUrl: './invoice-templates.component.html',
  styleUrls: ['./invoice-templates.component.scss'],
})
export class InvoiceTemplatesComponent implements OnInit {
  selectedIndex = 0;
  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
  tabClick(tab) {
    this.selectedIndex = tab.index;
    window.dispatchEvent(new Event('resize'));
  }

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}
}
