import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { CurrencyService } from '@app/core/Services/currency/currency.service';
import {
  CreateCurrency,
  DeleteClientCurrency,
  GetClientCurrencyList,
  GetRefreshCurrencyData,
  SaveCurrencyData,
} from './currency.action';

export class CurrencyStateInfo {
  id?: number;
  isCurrencyAdded: boolean;
  currencyList?: any;
  isCurrencyDeleted: boolean;
}

@State<CurrencyStateInfo>({
  name: 'currency',
})
@Injectable()
export class CurrencyState {
  constructor(private currencyService: CurrencyService) {}
  @Selector()
  static getCurrencyList(state: CurrencyStateInfo) {
    return state.currencyList;
  }

  @Action(CreateCurrency)
  createCurrency(
    { patchState }: StateContext<CurrencyStateInfo>,
    action: CreateCurrency
  ) {
    return this.currencyService.createCurrency(action.currency).pipe(
      tap((res) => {
        patchState({
          isCurrencyAdded: res,
        });
      })
    );
  }
  @Action(GetClientCurrencyList)
  getClientCurrencyList(
    { patchState }: StateContext<CurrencyStateInfo>,
    action: GetClientCurrencyList
  ) {
    return this.currencyService.getClientCurrencyList().pipe(
      tap((res) => {
        patchState({
          currencyList: res,
        });
      })
    );
  }
  @Action(DeleteClientCurrency)
  deleteCurrency(
    { patchState }: StateContext<CurrencyStateInfo>,
    action: DeleteClientCurrency
  ) {
    return this.currencyService
      .deleteClientCurrency(action.id, action.currencyId)
      .pipe(
        tap((res) => {
          patchState({
            isCurrencyDeleted: res,
          });
        })
      );
  }
  @Action(GetRefreshCurrencyData)
  getRefreshCurrencyList(
    { patchState }: StateContext<CurrencyStateInfo>,
    action: GetRefreshCurrencyData
  ) {
    return this.currencyService.getRefreshCurrencyList(action.currencyIds).pipe(
      tap((res) => {
        patchState({
          currencyList: res,
        });
      })
    );
  }
  @Action(SaveCurrencyData, { cancelUncompleted: true })
  saveCurrencyData(
    { patchState }: StateContext<CurrencyStateInfo>,
    action: SaveCurrencyData
  ) {
    return this.currencyService.saveCurrencyData(action.currency).pipe(
      tap((res) => {
        patchState({
          isCurrencyAdded: true,
        });
      })
    );
  }
}
