export const environment = {
  production: false,
  apiUrl: 'https://bookkeepingapi.dev.capium.co.uk/',
  uiUrl: 'https://ui-angular-bookkeping-dev.azurewebsites.net/',
  appDomain: 'dev-bookkepping',
  stsServerUrl: 'https://identity.dev.capium.co.uk',
  identityLoginUrl: 'https://identity.dev.capium.co.uk/Account/Login  ',
  clientId: 'capium_timeandfee_angular',
  cdnUrl: 'https://cdn.dev.capium.co.uk/shared/',
  apiVersionUrl: 'https://bookkeepingapi.dev.capium.co.uk/api/v1/',
  apiVersionUrl_V2: 'https://bookkeepingapi.dev.capium.co.uk/api/v2/',
  communicationFrameworkApiUrl:
    'https://communicationframworkapi.dev.capium.co.uk/api',
  // apiVersionUrl: 'https://localhost:7009/api/v1/',
  // apiVersionUrl_V2: 'https://localhost:7009/api/v2/',
  identityDomain: 'identity.dev',
  platform: 'dev',
  domain: 'capium.co.uk',
  helpDeskUrl: 'https://capium.freshdesk.com/support/home',
};
