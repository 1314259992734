import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivityLogModel, ActivityTaskParameters, AuthorisedMenuModel, GlobalComponent } from '@app/core/Models';
import { GetActivityList, GetAuthorizedMenus } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
  activityList: any = [];
  activityTaskParameters: ActivityTaskParameters;
  activities: Array<ActivityLogModel>;
  search = '';
  selectedModule = 0;
  start: string = '';
  end: string = '';
  moduleList: Array<AuthorisedMenuModel>;
  isRegularUser = this.globalComponent.isRegularUser();
  calendarForm: FormGroup;
  filterTypes = [
    { id: 1, name: 'This Week' },
    { id: 2, name: 'Last Week' },
    { id: 3, name: 'This Month' },
    { id: 4, name: 'Last Month' },
    { id: 5, name: 'This Quarter' },
    { id: 6, name: 'Last Quarter' },
    { id: 7, name: 'This Year' },
    { id: 8, name: 'Last Year' },
    { id: 9, name: 'Custom' },
  ];

  filter = 1;
  showCalendar = false;
  startDate = new FormControl({ value: new Date(), disabled: true });
  endDate = new FormControl({ value: new Date(), disabled: true });

  triggerActivityDetails: Subject<any> = new Subject<any>();
 

  constructor(
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private store: Store,
    private globalComponent: GlobalComponent,
  ) { }

  ngOnInit(): void {
    this.setForm();
    this.getAuthorizedMenus();
  }
  
  setForm(): void {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.calendarForm = this.formBuilder.group({
      start: [startDate],
      end: [new Date()],
    });
  }

  onSelectionChange(val: any): void {
    this.filter = +val;
    if (this.filter === 9) {
      this.showCalendar = true;
    } else {
      this.showCalendar = false;
    }
    this.dataChangeFromHeader();
  }

  dataChangeFromHeader(): void {
    this.end =
      this.filter < 9
        ? ''
        : this.datepipe.transform(
            this.calendarForm.controls.end.value,
            'yyyy-MM-dd'
          )!;
    this.start =
      this.filter < 9
        ? ''
        : this.datepipe.transform(
            this.calendarForm.controls.start.value,
            'yyyy-MM-dd'
          )!;

    this.getList();
  }

  dateRangeChange(dateRangeStart: any, dateRangeEnd: any): void {
    if (dateRangeStart !== '' && dateRangeEnd !== '') {
      this.dataChangeFromHeader();
    }
  }

  getEmunKeyByValue(value: number, enumName: any): string {
    const indexOfS = Object.values(enumName).indexOf(
      value as unknown as InstanceType<typeof enumName>
    );
    const key = Object.keys(enumName)[indexOfS];

    return key;
  }

  onSearch(event: any): void {
    this.search = event.target.value;
    this.getList();
  }
  
  getAuthorizedMenus(): void {
    this.store
      .dispatch(new GetAuthorizedMenus())
      .pipe(
        tap((res) => {
          if (res.activity.authorisedMenu) {
            this.moduleList = res.activity.authorisedMenu;
            if (this.isRegularUser) {
              this.selectedModule = this.moduleList[0].id;
            }
          }
          this.getList();
        })
      )
      .subscribe();
  }

  onActivitySelectionChange(activityId: any): void {
    this.selectedModule = activityId;
    this.getList();
  }

  getList(): void {
    this.activityList = [];

    this.activityTaskParameters = {
      moduleId: this.selectedModule,
      search: this.search,
      entityId: Guid.EMPTY as unknown as Guid,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
      dateFilter: this.filter ?? 0,
      pageNumber: 0,
      pageSize: 0,
    };

    this.store
      .dispatch(new GetActivityList(this.activityTaskParameters))
      .pipe(
        tap((res) => {
          this.activities = res.activity.activities;
          setTimeout(() => {
            this.triggerActivityDetails.next();
          }, 500);
        })
      )
      .subscribe();
  }

}
