import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationHeader, NotificationTextMessage } from '@app/core/Enum';
@Component({
  selector: 'app-clean-all-lines',
  templateUrl: './clean-all-lines.component.html',
  styleUrls: ['./clean-all-lines.component.scss'],
})
export class CleanAllLinesComponent {
  headerText = NotificationHeader.clearAllLinesHeaderText;
  bodyText = '';
  constructor(
    public datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CleanAllLinesComponent>
  ) {
    this.bodyText = NotificationTextMessage.clearAllLinesBodyText;
    if (this.data?.isDelete) {
      const startDate = this.datepipe
        .transform(this.data.selectedData.startDate, 'yyyy-MM-dd')
        ?.toString();
      const endDate = this.datepipe
        .transform(this.data.selectedData.endDate, 'yyyy-MM-dd')
        ?.toString();

      this.headerText = NotificationHeader.confirmation;
      this.bodyText =
        NotificationTextMessage.deleteFinanicalPeriod +
        ' ' +
        startDate +
        ' to ' +
        endDate +
        '?';
    }
  }
  onYesClick(): void {
    this.dialogRef.close(true);
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
