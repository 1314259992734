<div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
  <mat-icon class="material-icons-outlined">error_outline</mat-icon>
  {{ commonNotificationText.ViewOnlyAccessMessage }}
</div>
<table
  mat-table
  matSort
  aria-describedby="Import Success List"
  [dataSource]="cisSubContactorList"
>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <span class="clamp display-inline wmx-100" title="{{ element.name }}">
        {{ element.name }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="tredingName">
    <th mat-header-cell *matHeaderCellDef>Trading Name</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <span
        class="clamp display-inline wmx-100"
        title="{{ element.tradingName }}"
      >
        {{ element.tradingName }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="taxTreatment">
    <th mat-header-cell *matHeaderCellDef>Tax Treatment</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <span
        class="clamp display-inline wmx-100"
        title="{{ element.taxTreatment }}"
      >
        {{ element.taxTreatment }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="verificationNo">
    <th mat-header-cell *matHeaderCellDef>Verification No</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <span
        class="clamp display-inline wmx-100"
        title="{{ element.verificationNo }}"
      >
        {{ element.verificationNo }}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="deductionRate">
    <th mat-header-cell *matHeaderCellDef>Deduction Rate</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <mat-form-field>
        <input
          matInput
          [(ngModel)]="element.deductionRate"
          autocomplete="off"
          allowDecimal
          [disabled]="isDisable"
        />
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="totalPayment">
    <th mat-header-cell *matHeaderCellDef>Total Payment</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <mat-form-field>
        <input
          matInput
          [(ngModel)]="element.totalPayment"
          autocomplete="off"
          (change)="onTotalPaymentChange(i)"
          allowDecimal
        />
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="costOfMaterials">
    <th mat-header-cell *matHeaderCellDef>Cost Of Materials</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <mat-form-field>
        <input
          matInput
          [(ngModel)]="element.costofMaterial"
          autocomplete="off"
          (change)="onTotalPaymentChange(i)"
          allowDecimal
        />
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="netAmount">
    <th mat-header-cell *matHeaderCellDef>Net Amount</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <mat-form-field>
        <input
          matInput
          [(ngModel)]="element.netAmount"
          autocomplete="off"
          [disabled]="isDisable"
        />
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="totalDeducted">
    <th mat-header-cell *matHeaderCellDef>Total Deducted</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <mat-form-field>
        <input
          matInput
          [(ngModel)]="element.totalDeducted"
          autocomplete="off"
          [disabled]="isDisable"
        />
      </mat-form-field>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
