<div class="content-body">
  <div class="content-body content-body-scroll">
    <div class="content-header">
      <div class="content-header-input">
        <div class="d-flex justify-space-between align-items-end wp-100 mb-15">
          <div
            class="content-header d-flex align-items-end justify-space-between header-filters header-count header-count-1"
          >
            <div class="d-flex align-items-end">
              <div class="content-right-button d-flex flex-column gap-5 mr-10">
                <span class="fw-bold"> From : </span>
                <div>
                  <mat-form-field appearance="standard" class="ml-0">
                    <input
                      [(ngModel)]="dateFrom"
                      [matDatepicker]="fromPicker"
                      matInput
                      autocomplete="off"
                      tabindex="-1"
                      required
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="fromPicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #fromPicker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="content-right-button d-flex flex-column gap-5 mr-10">
                <span class="fw-bold"> To : </span>
                <div>
                  <mat-form-field appearance="standard" class="ml-0">
                    <input
                      [(ngModel)]="dateTo"
                      [matDatepicker]="toPicker"
                      [min]="dateFrom"
                      matInput
                      autocomplete="off"
                      tabindex="-1"
                      required
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="toPicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #toPicker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="toolbar mr-10">
                <button
                  matTooltip="Search"
                  mat-button
                  (click)="getInventoryData()"
                >
                  <mat-icon>search</mat-icon>
                </button>
              </div>
              <button
                class="action-button primary-button"
                type="button"
                mat-button
                disableRipple
                (click)="onLossOfStockClick()"
                [disabled]="isViewPermission$ | async"
              >
                Loss of Stock
              </button>
            </div>
          </div>
        </div>
        <div
          class="mat-table-wrapper"
          [ngClass]="{
            'mat-table-wrapper-noData': noDataFound
          }"
        >
          <table
            mat-table
            [dataSource]="data"
            aria-describedby="Inventory"
            mat-table
            matSort
          >
            <ng-container matColumnDef="item">
              <th mat-header-cell *matHeaderCellDef>Item</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="clamp"
                title="{{ element.productName }}"
              >
                {{ element.productName }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="openingStock">
              <th mat-header-cell *matHeaderCellDef>Opening Stock</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="clamp"
                title="{{ element.openingBalance }}"
              >
                <a
                  class="link text-primary wmx-100 clamp"
                  (click)="inventoryPopup('open', element.productId)"
                >
                  {{ element.openingBalance }}
                </a>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="purchase">
              <th mat-header-cell *matHeaderCellDef>Purchase</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="clamp"
                title="{{ element.purchase }}"
              >
                <a
                  class="link text-primary wmx-100 clamp"
                  (click)="inventoryPopup('pur', element.productId)"
                >
                  {{ element.purchase }}
                </a>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="sales">
              <th mat-header-cell *matHeaderCellDef>Sales</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="clamp"
                title="{{ element.sales }}"
              >
                <a
                  class="link text-primary wmx-100 clamp"
                  (click)="inventoryPopup('sale', element.productId)"
                >
                  {{ element.sales }}
                </a>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="lossOfStock">
              <th mat-header-cell *matHeaderCellDef>Loss Of Stock</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="clamp"
                title="{{ element.lossOfStock }}"
              >
                <a
                  class="link text-primary wmx-100 clamp"
                  (click)="inventoryPopup('loss', element.productId)"
                >
                  {{ element.lossOfStock }}
                </a>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="closingStock">
              <th mat-header-cell *matHeaderCellDef>Closing Stock</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="clamp"
                title="{{ element.closingBalance }}"
              >
                {{ element.closingBalance }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="valuation">
              <th mat-header-cell *matHeaderCellDef>Valuation</th>
              <td mat-cell *matCellDef="let element" class="clamp">
                <ng-select
                  #valudation
                  (focus)="valudation.open()"
                  (valueChange)="valudation.close()"
                  [disableOptionCentering]="true"
                  panelClass="mat-select-position"
                  [(ngModel)]="element.valuation"
                  (ngModelChange)="enablePrice(element)"
                  appendTo="body"
                >
                  <ng-container>
                    <ng-option
                      *ngFor="let type of valuationList"
                      [value]="type.value"
                    >
                      <div title="{{ type.name }}">{{ type.name }}</div>
                    </ng-option>
                  </ng-container>
                </ng-select>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>Price</th>
              <td mat-cell *matCellDef="let element" class="clamp">
                <mat-form-field>
                  <input
                    allow_14_2_Decimal
                    matInput
                    zeroNotAllowed
                    [(ngModel)]="element.price"
                    autocomplete="off"
                    [disabled]="!element.isPriceDisabled"
                    (change)="calculation(element)"
                    (keyup)="checkAmtLength(element)"
                    allowDecimal
                  />
                </mat-form-field>
              </td>
              <td mat-footer-cell *matFooterCellDef>Total Amount</td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>Amount</th>
              <td mat-cell *matCellDef="let element" class="clamp">
                <mat-form-field>
                  <input
                    allow_14_2_Decimal
                    matInput
                    zeroNotAllowed
                    [(ngModel)]="element.amount"
                    autocomplete="off"
                    allowDecimal
                    [disabled]="true"
                  />
                </mat-form-field>
              </td>
              <td mat-footer-cell *matFooterCellDef>{{ total }}</td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr
              mat-footer-row
              *matFooterRowDef="displayedColumns"
              [hidden]="!showFooter"
            ></tr>
          </table>
          <div *ngIf="noDataFound" class="text-align-center mt-1">
            No records found
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="action-wrapper button-action-wrapper">
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave()"
    >
      Save
    </button>
  </div>
</div>
