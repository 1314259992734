import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaxLength, Modules, NotificationTextMessage } from '@app/core/Enum';
import { Country } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { CountryState, GetCountryList, MenuState } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export enum IsShareholder {
  Yes = '2',
  No = '1',
}

@Component({
  selector: 'app-add-director-shareholder-detail',
  templateUrl: './add-director-shareholder-detail.component.html',
  styleUrls: ['./add-director-shareholder-detail.component.scss'],
})
export class AddDirectorShareholderDetailComponent implements OnInit {
  directorShareholderForm: FormGroup;
  countryList: Country[];
  moduleId = Modules;
  isShareholder = IsShareholder;
  notificationMessage = NotificationTextMessage;

  isEdit = false;
  maxLength = MaxLength;
  @Input() triggerEditData: Observable<any>;

  @Input()
  getModuleName?: string;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Input()
  getModuleId: number;

  constructor(private store: Store, public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.getCountry();

    this.isEdit = false;

    this.triggerEditData?.subscribe((data) => {
      this.isEdit = true;
      if (this.getModuleId === Modules.Director) {
        this.editDirectorDetail(data);
      } else {
        this.editShareholderDetail(data);
      }
    });

    this.directorShareholderForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange =
        this.directorShareholderForm.touched;
    });
  }

  editDirectorDetail(data): void {
    setTimeout(() => {
      this.directorShareholderForm.patchValue({
        firstName: data.firstName,
        lastName: data.lastName,
        addressLine1: data.address1,
        addressLine2: data.address2,
        isCreateAccount: data.isCreateAccount,
        directorCurrentAccount: data.directorCurrentAccount,
        isCreateLoanAccount: data.isCreateLoanAccount,
        directorLoanAccount: data.directorLoanAccount,
        city: data.city,
        postCode: data.postalCode,
        addressLine3: data.address3,
        countryId: data.countryId,
        email: data.email,
        phone: data.phone,
        shareholderId: data.isShareHolder
          ? IsShareholder.Yes
          : IsShareholder.No,
        valuePerShare: data.valuePerShare,
        noOfShare: data.noOfShare,
        total: data.total,
        appointmentDate: data.appointmentDate,
        terminationDate: data.terminationDate,
        notes: data.notes,
      });

      if (data.isCreateAccount) {
        this.directorShareholderForm.controls.isCreateAccount.disable();
      } else {
        this.directorShareholderForm.controls.isCreateAccount.enable();
      }

      if (data.isCreateLoanAccount) {
        this.directorShareholderForm.controls.isCreateLoanAccount.disable();
      } else {
        this.directorShareholderForm.controls.isCreateLoanAccount.enable();
      }

      if (data.isShareHolder) this.onChange();
    }, 1000);
  }

  editShareholderDetail(data): void {
    setTimeout(() => {
      this.directorShareholderForm.patchValue({
        firstName: data.firstName,
        lastName: data.lastName,
        addressLine1: data.address1,
        addressLine2: data.address2,
        city: data.city,
        postCode: data.postalCode,
        addressLine3: data.address3,
        countryId: data.countryId,
        email: data.email,
        phone: data.phone,
        valuePerShare: data.valuePerShare,
        noOfShare: data.noOfShare,
        total: data.total,
        shareType: data.shareType.toString(),
        notes: data.notes,
        isShareHolder: data.isShareHolder,
      });
      this.onChange();
    }, 1000);
  }

  getCountry(): void {
    this.store
      .dispatch(new GetCountryList())
      .pipe(
        tap(() => {
          this.countryList = this.store.selectSnapshot(CountryState.getCountry);
          this.directorShareholderForm.controls.country.setValue(
            this.countryList[205]?.id
          );
        })
      )
      .subscribe();
  }

  setForm(): void {
    this.directorShareholderForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl(''),
      isCreateAccount: new FormControl(false),
      directorCurrentAccount: new FormControl(''),
      isCreateLoanAccount: new FormControl(false),
      directorLoanAccount: new FormControl(''),
      addressLine1: new FormControl(''),
      addressLine2: new FormControl(''),
      city: new FormControl(''),
      postCode: new FormControl(''),
      addressLine3: new FormControl(''),
      country: new FormControl(''),
      email: new FormControl('', [Validators.email]),
      phone: new FormControl(''),
      shareholderId: new FormControl('1'),
      appointmentDate: new FormControl(new Date(), [Validators.required]),
      terminationDate: new FormControl(''),
      valuePerShare: new FormControl(''),
      noOfShare: new FormControl(''),
      total: new FormControl({ value: 0.0, disabled: true }),
      shareType: new FormControl('1'),
      notes: new FormControl(''),
    });

    if (this.getModuleId === Modules.Shareholder) {
      this.directorShareholderForm
        .get('valuePerShare')
        ?.addValidators(Validators.required);
      this.directorShareholderForm
        .get('noOfShare')
        ?.addValidators(Validators.required);
      this.directorShareholderForm.get('appointmentDate')?.clearValidators();
    }

    if (this.getModuleId === Modules.Director) {
      this.directorShareholderForm
        .get('appointmentDate')
        ?.addValidators(Validators.required);
      this.directorShareholderForm.get('valuePerShare')?.clearValidators();
      this.directorShareholderForm.get('noOfShare')?.clearValidators();
    }

    this.directorShareholderForm.controls[
      'valuePerShare'
    ].updateValueAndValidity();
    this.directorShareholderForm.controls['noOfShare'].updateValueAndValidity();
    this.directorShareholderForm.controls[
      'appointmentDate'
    ].updateValueAndValidity();
  }

  onSelectionChange(event): void {
    if (event == IsShareholder.Yes) {
      this.directorShareholderForm
        .get('valuePerShare')
        ?.addValidators(Validators.required);
      this.directorShareholderForm
        .get('noOfShare')
        ?.addValidators(Validators.required);
    } else {
      this.directorShareholderForm.get('valuePerShare')?.clearValidators();
      this.directorShareholderForm.get('noOfShare')?.clearValidators();
    }
    this.directorShareholderForm.controls[
      'valuePerShare'
    ].updateValueAndValidity();
    this.directorShareholderForm.controls['noOfShare'].updateValueAndValidity();
  }

  onChange(): void {
    const valuePerShare =
      this.directorShareholderForm.get('valuePerShare')?.value;
    const noOfShare = this.directorShareholderForm.get('noOfShare')?.value;
    const total = (valuePerShare * noOfShare).toFixed(2);
    this.directorShareholderForm.get('total')?.setValue(total);
  }
}
