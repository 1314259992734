import { DatePipe } from '@angular/common';
import {
  Component,
  Inject,
  Injector,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  AccountEntity,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
  TransactionStatus,
} from '@app/core/Enum';
import { VatScheme } from '@app/core/Enum/vat-scheme';
import {
  Currency,
  GlobalComponent,
  QuickJournalModel,
  SideListModel,
  VatRateScheme,
} from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import {
  CommonState,
  GetAllBranchList,
  GetAllDepartmentListByBranch,
  GetCompanyVatSchemaId,
  GetCurrencyList,
  GetCustomBankAccountList,
  GetJournalAccounts,
  GetVatRateList,
  MenuState,
  QuickJournal,
} from '@app/core/Store';
import { ConfirmationBoxComponent } from '@app/modules/common';
import { TransactionsAttachmentComponent } from '@app/modules/transactions';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-quick-journal',
  templateUrl: './quick-journal.component.html',
  styleUrls: ['./quick-journal.component.scss'],
})
export class QuickJournalComponent implements OnInit {
  quickJournalForm: FormGroup;
  receiptId = Guid.EMPTY as unknown as Guid;

  quickJournalData: QuickJournalModel;

  customerList: SideListModel[];
  accountTypeList: SideListModel[];
  accountList: any[] = [];
  currencyList: Currency[];
  receiptAccountList: any[] = [];
  tempReceiptAccountList: any[];
  getListId: Guid;
  vatRateList: VatRateScheme[];

  @Input() bankId;

  accountGroupList: any[] = [];
  tempAccountGroupList: any[];

  subscriptionRouting: Subscription;
  moduleName = ModuleName;
  moduleId = Modules;
  disabledFundEditButton = true;

  amountReceivedValue = 0;
  amountReceivedTotal = 0;
  isAdvance = false;
  departmentList: any[] = [];
  branchList: any[] = [];

  @Input()
  getModuleName?: string;

  @Input()
  getModuleId: number;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(CommonState.defaultCurrency)
  defaultCurrency$: Observable<Guid>;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  transactionType = [
    {
      id: TransactionStatus.Receipt,
      name: TransactionStatus[TransactionStatus.Receipt],
    },
    {
      id: TransactionStatus.Payment,
      name: TransactionStatus[TransactionStatus.Payment],
    },
  ];

  accountLabelText: string;
  amountLabelText: string;

  defaultCurrency: Guid;
  periodicDate: any;

  isEdit = false;
  disabledEditButton = true;
  showReceiptDetails = false;

  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;
  accountingMehtodId: any;

  private destroy$ = new Subject<void>();
  store: Store;
  commonService: CommonService;
  spinner: NgxSpinnerService;
  dialog: MatDialog;
  datepipe: DatePipe;

  vatSchemaId: any;
  isVatRegistered: boolean = false;
  vatReturnCheckboxVisibility: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
    private _Activatedroute: ActivatedRoute,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.datepipe = injector.get<DatePipe>(DatePipe);
  }

  ngOnInit(): void {
    this.bankId = atob(this.data.customId) as unknown as Guid;
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();

    if (this.isVatRegistered) {
      this.getVatSchemaId().then(() => {
        if (
          +this.vatSchemaId === VatScheme.CashBased ||
          +this.vatSchemaId === VatScheme.FlatRateCashBasedScheme
        ) {
          this.vatReturnCheckboxVisibility = true;
        }
      });
    }
    this.isEdit = false;
    this.setForm();
    this.loadDropdownValues();

    this.quickJournalForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.quickJournalForm.touched;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setLabelText(): void {
    this.amountLabelText =
      this.quickJournalForm.controls.transactionType.value ===
      TransactionStatus.Payment
        ? 'Paid'
        : 'Received';

    this.accountLabelText =
      this.quickJournalForm.controls.transactionType.value ===
      TransactionStatus.Payment
        ? 'Payment'
        : 'Receipt';
  }

  bindCurrency(): void {
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          if (this.currencyList.length > 0) {
            this.quickJournalForm.controls.currency.setValue(
              this.defaultCurrency
            );
          }
        })
      )
      .subscribe();
  }

  loadDropdownValues(): void {
    this.getAccounts();
    this.bindCurrency();
    this.getBankAccountList();
    this.getVatRateList();
    this.getBranchList();
  }

  onTransactionTypeChange(): void {
    this.setLabelText();
  }

  getAccounts(): void {
    this.spinner.show();
    this.store.dispatch(new GetJournalAccounts()).subscribe((res) => {
      this.spinner.hide();

      this.accountGroupList = this.addGroupNameToListModels(
        res.account.accounts
      );
      this.accountGroupList = this.accountGroupList.filter(
        (x) => x.id !== this.bankId
      );
    });
  }

  addGroupNameToListModels(data: any[]): any[] {
    let result: any = [];

    data.forEach((group) => {
      group.listModels.forEach((listModel) => {
        let listModelWithGroup = {
          ...listModel,
          groupName: group.groupName,
        } as any;
        result.push(listModelWithGroup);
      });
    });

    return result;
  }

  getBankAccountList(): void {
    this.store
      .dispatch(new GetCustomBankAccountList())
      .pipe(
        tap((res) => {
          this.receiptAccountList = res.common.customBankAccount;
          this.tempReceiptAccountList = this.receiptAccountList;
          if (!this.commonService.isEmpty(this.bankId)) {
            this.quickJournalForm.controls.bankAccountId.setValue(this.bankId);
            this.quickJournalForm.controls.bankAccountId.disable();
          }
        })
      )
      .subscribe();
  }

  getVatRateList(): void {
    this.store.dispatch(new GetVatRateList()).subscribe((res) => {
      this.vatRateList = res.common.vatRate;
    });
  }

  calVatAmount(): void {
    let amount = 0;
    let vatRate = 0;
    if (this.vatRateList.length > 0) {
      this.vatRateList.forEach((element) => {
        if (element.id === this.quickJournalForm.controls.vat.value) {
          vatRate = element.rate;
        }
      });

      amount =
        +this.quickJournalForm.controls.amountReceived.value -
        (+this.quickJournalForm.controls.amountReceived.value * 100) /
          (100 + vatRate);

      this.quickJournalForm.controls.vatRate.setValue(+amount.toFixed(2));
    }
  }

  setForm(): void {
    this.quickJournalForm = new FormGroup(
      {
        accountTypeId: new FormControl(0),
        bankAccountId: new FormControl('', [Validators.required]),
        transactionType: new FormControl(TransactionStatus.Receipt, [
          Validators.required,
        ]),
        receiptDate: new FormControl(
          new Date(),
          datePickerValidator(this.periodicDate)
        ),
        currency: new FormControl({ value: '', disabled: true }, [
          Validators.required,
        ]),
        amountReceived: new FormControl('', [Validators.required]),
        note: new FormControl(''),
        accountId: new FormControl('', [Validators.required]),
        vat: new FormControl(''),
        vatRate: new FormControl({ value: 0, disabled: true }),
        branchId: new FormControl(''),
        departmentId: new FormControl(''),
        includeInVatReturnCheckbox: new FormControl(true),
      },
      {
        validators: this.commonService.compareFields(
          'bankAccountId',
          'accoutListBank',
          this.getModuleId
        ),
      }
    );

    this.setLabelText();
  }

  accountGroupSelected(event: any, isEdit): void {
    const entityType = [AccountEntity.Customer, AccountEntity.Supplier];

    this.showReceiptDetails = entityType.includes(
      isEdit ? event.accountTypeId : +event.option.value.accountTypeId
    );

    if (!isEdit && this.showReceiptDetails) {
      this.quickJournalForm.controls.customerName.setValue(
        event.option.value.id
      );
      this.onCustomerChange('');
    }

    if (this.getModuleId === Modules.BankEntry) {
      this.receiptAccountList = this.tempReceiptAccountList.filter(
        (i) => i.id !== event.id
      );
    }
  }

  getBranchList(): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.quickJournalForm.controls.branchId.setValue(
              this.branchList[0].id
            );
            this.quickJournalForm.controls.branchId.setValidators(
              Validators.required
            );
            this.quickJournalForm.controls.branchId.updateValueAndValidity();
            this.getDepartmentListByBranch(this.branchList[0].id);
          } else {
            this.branchList = [];
            this.departmentList = [];
          }
        })
      )
      .subscribe();
  }

  onChangeBranch(branch): void {
    this.getDepartmentListByBranch(branch);
  }

  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .pipe(
          tap((res) => {
            this.departmentList = [];
            if (res.company.department.length > 0) {
              this.departmentList = res.company.department;
              this.quickJournalForm.controls.departmentId.setValue(
                this.departmentList[0].id
              );
              this.quickJournalForm.controls.departmentId.setValidators(
                Validators.required
              );
              this.quickJournalForm.controls.departmentId.updateValueAndValidity();
            } else {
              this.quickJournalForm.controls.departmentId.clearValidators();
              this.quickJournalForm.controls.departmentId.updateValueAndValidity();
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
      this.quickJournalForm.controls.departmentId.clearValidators();
      this.quickJournalForm.controls.departmentId.updateValueAndValidity();
    }
  }

  onCustomerChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledEditButton = false;
    } else {
      this.disabledEditButton = true;
    }
  }

  getCustomBankAccountList(): void {
    this.store
      .dispatch(new GetCustomBankAccountList())
      .pipe(
        tap((res) => {
          this.accountList = res.common.accountList;

          if (this.accountList.length > 0) {
            this.quickJournalForm.controls.accountId.setValue(
              this.accountList[0].id
            );
          } else {
            this.quickJournalForm.controls.accountId.reset();
          }
        })
      )
      .subscribe();
  }

  onCheckBoxSelected(event: any): void {
    this.isAdvance = event.checked;
    this.showReceiptDetails = !event.checked;
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onCancel(): void {
    this.ngOnInit();
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.quickJournalData = {
        entryDate: this.datepipe
          .transform(
            this.quickJournalForm.controls.receiptDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        fromAccountId: this.quickJournalForm.controls.bankAccountId.value,
        toAccountId: this.quickJournalForm.controls.accountId.value,
        currencyId: this.quickJournalForm.controls.currency.value,
        vatRateId:
          this.quickJournalForm.controls.vat.value == ''
            ? null
            : this.quickJournalForm.controls.vat.value,
        vatAmount: this.quickJournalForm.controls.vatRate.value,
        totalAmount: this.quickJournalForm.controls.amountReceived.value,
        note: this.quickJournalForm.controls.note.value,
        includeInVatReturn:
          this.quickJournalForm.controls.includeInVatReturnCheckbox.value,
        isCredit:
          this.quickJournalForm.controls.transactionType.value > 0
            ? true
            : false,
        branchId:
          this.quickJournalForm.controls.branchId.value === ''
            ? null
            : this.quickJournalForm.controls.branchId.value,
        departmentId:
          this.quickJournalForm.controls.departmentId.value === ''
            ? null
            : this.quickJournalForm.controls.departmentId.value,
        attachment: filedata,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.quickJournalForm.invalid) {
      this.quickJournalForm.markAllAsTouched();
      return;
    } else {
      if (this.dataSubmit()) {
        if (this.quickJournalData) {
          this.store
            .dispatch(new QuickJournal(this.quickJournalData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  if (isExit) {
                    this.commonService.onSuccess(
                      NotificationTextMessage.successMessage
                    );
                    this.dialogRef.close();
                  } else {
                    this.onCancel();
                  }
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    }
  }

  async getVatSchemaId(): Promise<void> {
    await this.store
      .dispatch(new GetCompanyVatSchemaId())
      .toPromise()
      .then((res) => {
        this.vatSchemaId = res.company.companyVatSchemaId;
      });
  }
}
