import { DividendsModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CreateDividends {
  static readonly type = '[DIVIDENDS] Create Dividends';

  constructor(public dividends: DividendsModel) {}
}

export class GetDataByDividendsId {
  static readonly type = '[DIVIDENDS] Get Dividends Data By Dividends Id';

  constructor(public dividendsId: Guid) {}
}

export class ArchiveAndRestoreDividends {
  static readonly type = '[DIVIDENDS] Archive and Restore Selected Dividends';

  constructor(public dividendsIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteDividends {
  static readonly type = '[DIVIDENDS] Delete Selected Dividends';

  constructor(public dividendsIds: Array<Guid>) {}
}

export class GetAllShareClassType {
  static readonly type = '[DIVIDENDS] Get All Share Class Type';
}

export class GetAllShareHolder {
  static readonly type = '[DIVIDENDS] Get All Share Holder';
}

export class GetShareHolderDetailView {
  static readonly type = '[DIVIDENDS] Get All Share Holder Detail View';
  constructor(public shareHolderId: Guid) {}
}

export class GetDividendNumber {
  static readonly type = '[DIVIDENDS] Get Refrence Number';
}
