<div class="mat-dialog-wrapper depreciation-list">
  <h2 *ngIf="disposeSellType !== actionType.Sell" mat-dialog-title>
    Dispose Asset
  </h2>
  <h2 *ngIf="disposeSellType === actionType.Sell" mat-dialog-title>
    Sale Asset
  </h2>
  <mat-dialog-content>
    <form [formGroup]="assetDisposeForm">
      <p class="text-primary fw-bold">Asset Summary</p>
      <div class="d-flex gap-40 align-items-center">
        <div class="form-field datepicker">
          <p>Acquired Date</p>
          <mat-form-field appearance="standard">
            <input
              matInput
              formControlName="acquiredDate"
              [matDatepicker]="acquiredDatePicker"
              autocomplete="off"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="acquiredDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #acquiredDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex gap-40 align-items-center">
        <div class="form-field">
          <p>Purchase Price</p>
          <mat-form-field>
            <input
              matInput
              formControlName="price"
              autocomplete="off"
              appAutoFocusDirective
              unicodeCharacter
            />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex gap-40 align-items-center">
        <div class="form-field">
          <p>Depreciation Method</p>
          <ng-select
            #method
            (focus)="method.open()"
            (valueChange)="method.close()"
            [disableOptionCentering]="true"
            formControlName="depreciationMethod"
            panelClass="mat-select-position"
          >
            <ng-option
              *ngFor="let font of methodTypeList | useEnumValues"
              [value]="font.key"
              title="{{ font.value }}"
            >
              <div title="{{ font.value }}">{{ font.value }}</div>
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="d-flex gap-40 align-items-center">
        <div class="form-field">
          <p>Rate (%):</p>
          <mat-form-field>
            <input matInput formControlName="rate" autocomplete="off" />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex gap-40 align-items-center">
        <div class="form-field">
          <p>Depreciation Charged</p>
          <mat-form-field>
            <input
              matInput
              formControlName="depreciationCharged"
              autocomplete="off"
              appAutoFocusDirective
              unicodeCharacter
            />
          </mat-form-field>
        </div>
      </div>
      <div class="mt-1">
        <p class="text-primary fw-bold">Assets Detail</p>
      </div>
      <div class="d-flex gap-40 align-items-center">
        <div class="form-field datepicker">
          <p>Date <span class="text-danger-300">*</span></p>
          <mat-form-field appearance="standard">
            <input
              matInput
              formControlName="date"
              [matDatepicker]="transferDatePicker"
              autocomplete="off"
              [min]="minStartDate"
              [max]="maxEndDate"
              (dateChange)="getDepreciationChargeByDate()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="transferDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #transferDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex gap-40 align-items-center">
        <div class="form-field">
          <p>Depreciation Charge</p>
          <mat-form-field>
            <input
              [(ngModel)]="depreciationCharge"
              matInput
              formControlName="depreciationCharge"
              autocomplete="off"
              allowDecimal
              (change)="getTotalProfitLoss()"
            />
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="disposeSellType === actionType.Sell">
        <div class="d-flex gap-40 align-items-center">
          <div class="form-field">
            <p>Account <span class="text-danger-300">*</span></p>
            <ng-select
              #accountList
              (focus)="accountList.open()"
              (valueChange)="accountList.close()"
              [disableOptionCentering]="true"
              formControlName="account"
              panelClass="mat-select-position"
            >
              <ng-option
                *ngFor="let option of accountsList"
                [value]="option.id"
              >
                <div title="{{ option.name }}">{{ option.name }}</div>
              </ng-option>
            </ng-select>
          </div>
        </div>
        <div class="d-flex gap-40 align-items-center">
          <div class="form-field">
            <p>
              Sale Price <span *ngIf="showVATApplicable">(Including VAT)</span
              ><span class="text-danger-300">*</span>
            </p>
            <mat-form-field>
              <input
                matInput
                formControlName="salePrice"
                autocomplete="off"
                appAutoFocusDirective
                allowDecimal
                (change)="getTotalProfitLoss()"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex gap-40 align-items-center">
          <div class="d-flex gap-40 align-items-center">
            <div class="checkbox mb-0" *ngIf="isVatRegistered">
              <ul>
                <li>
                  <mat-checkbox
                    formControlName="isVATApplicable"
                    [(ngModel)]="showVATApplicable"
                    (change)="onDepreciableAsset($event)"
                  >
                    VAT Applicable
                  </mat-checkbox>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="d-flex gap-40 align-items-center" *ngIf="showVATApplicable">
          <div class="form-field">
            <p>VAT</p>
            <ng-select
              #method
              (focus)="method.open()"
              (valueChange)="method.close()"
              [disableOptionCentering]="true"
              formControlName="vat"
              panelClass="mat-select-position"
              (change)="calVatAmount()"
            >
              <ng-option
                *ngFor="let font of vatRateList"
                [value]="font.id"
                title="{{ font.name }}"
              >
                <div title="{{ font.name }}">{{ font.name }}</div>
              </ng-option>
            </ng-select>
          </div>
          <mat-form-field>
            <input
              matInput
              formControlName="vatRate"
              autocomplete="off"
              appAutoFocusDirective
              allowDecimal
              (change)="vatAmountChanges()"
            />
          </mat-form-field>
        </div>
        <div class="d-flex gap-40 align-items-center" *ngIf="showVATApplicable">
          <div class="form-field">
            <p>Net Sale Price</p>
            <mat-form-field>
              <input
                matInput
                formControlName="netSalePrice"
                autocomplete="off"
                appAutoFocusDirective
                allowDecimal
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="d-flex gap-40 align-items-center">
        <div class="form-field">
          <p>Notes</p>
          <mat-form-field>
            <input
              matInput
              formControlName="notes"
              autocomplete="off"
              maxlength="{{ maxLength.maxLength2000 }}"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex gap-40 align-items-center">
        <div class="form-field">
          <p>Total {{ lossProfitLable }}</p>
          <mat-form-field>
            <input
              matInput
              formControlName="totalProfit"
              autocomplete="off"
              appAutoFocusDirective
              allowDecimal
            />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <app-buttons
    [getModuleId]="moduleId"
    (triggerOnSaveClick)="onSave()"
    (triggerOnCancelClick)="onCancel()"
  >
  </app-buttons>
  <mat-dialog-actions>
    <button
      class="close-button"
      type="button"
      (click)="onCloseClick()"
      mat-button
      disableRipple
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
