import { Guid } from 'guid-typescript';

export class DirectorShareholderModel {
  id?: Guid;
  companyId?: string;
  firstName?: string;
  lastName?: string;
  isCreateAccount?: boolean;
  directorCurrentAccount?: Guid;
  isCreateLoanAccount?: boolean;
  directorLoanAccount?: Guid;
  address1?: string;
  address2?: string;
  city?: string;
  countryId?: number;
  address3?: string;
  postalCode?: string;
  phone?: string;
  email?: string;
  isShareHolder?: boolean;
  appointmentDate?: string;
  terminationDate?: string;
  valuePerShare?: number;
  noOfShare?: number;
  total?: number;
  shareType?: number;
  notes?: string;
  isClient?: boolean;
  shareholderId?: number;
}
