import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { AccountModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { CreateAccount, GetDataByAccountId, MenuState } from '@app/core/Store';
import {
  AddClosePopupComponent,
  AddCommericalDetailsComponent,
  AddContactDetailsComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit {
  customerId = Guid.EMPTY as unknown as Guid;

  selectedIndex = 0;
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;

  moduleName = ModuleName.Customers;
  moduleId = Modules.Customers;

  getModuleName?: string;
  accountData: AccountModel;
  headerText = '';

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @Output()
  readonly triggerAccountDetails = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddContactDetailsComponent, { static: true })
  contactDetails;

  @ViewChild(AddCommericalDetailsComponent, { static: true })
  commericalDetails;

  triggerEditData: Subject<any> = new Subject<any>();

  subscriptionRouting: Subscription;
  store: Store;
  commonService: CommonService;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private _Activatedroute: ActivatedRoute,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private location: Location,
    private renderer: Renderer2,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.customerId = atob(params.get('id')!) as unknown as Guid;
          this.editCustomer();
        } else {
          this.customerId = Guid.EMPTY as unknown as Guid;
          this.headerText = 'Add New Customer';
        }
      }
    );
  }

  tabClick(tab) {
    this.selectedIndex = tab.index;
    window.dispatchEvent(new Event('resize'));
  }

  editCustomer(): void {
    this.store
      .dispatch(new GetDataByAccountId(this.customerId))
      .subscribe((res) => {
        this.headerText = res.account.accountData.name;
        this.contactDetails.contactDetailForm.markAsUntouched();
        this.commericalDetails.commericalDetailsForm.markAsUntouched();
        this.triggerEditData.next(res.account.accountData);
      });
    setTimeout(() => {
      const data = {
        id: this.customerId,
        moduleId: this.moduleId,
      };

      this.triggerAccountDetails.emit(data);
    }, 0);
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.customerId,
      isExit,
      Modules.Customers,
      RoutingPath.AddCustomers
    );
  }

  onSave(isExit: boolean): void {
    if (this.contactDetails.contactDetailForm.invalid) {
      this.commonService.addValidation(
        this.contactDetails.contactDetailForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateAccount(this.accountData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.accountData = {
        id: this.customerId,
        name: this.contactDetails.contactDetailForm.controls.name.value,
        tradingName:
          this.contactDetails.contactDetailForm.controls.tradingName.value,
        address1:
          this.contactDetails.contactDetailForm.controls.addressLine1.value,
        address2:
          this.contactDetails.contactDetailForm.controls.addressLine2.value,
        city: this.contactDetails.contactDetailForm.controls.city.value,
        address3:
          this.contactDetails.contactDetailForm.controls.addressLine3.value,
        countryId: this.contactDetails.contactDetailForm.controls.country.value,
        postalCode:
          this.contactDetails.contactDetailForm.controls.postCode.value,
        phone: this.contactDetails.contactDetailForm.controls.phone.value,
        email: this.contactDetails.contactDetailForm.controls.email.value,
        mobileNumber:
          this.contactDetails.contactDetailForm.controls.mobileNumber.value,
        fax: this.contactDetails.contactDetailForm.controls.fax.value,
        vatRegistrationNumber:
          this.commericalDetails.commericalDetailsForm.controls
            .vatRegistrationNumber.value,
        bankAccountNumber:
          this.commericalDetails.commericalDetailsForm.controls.accountNumber
            .value,
        bankAccountName:
          this.commericalDetails.commericalDetailsForm.controls.accountName
            .value,
        bankBranchCode:
          this.commericalDetails.commericalDetailsForm.controls.branchCode
            .value,
        bankAccountIBANCode:
          this.commericalDetails.commericalDetailsForm.controls.accountIBANCode
            .value,
        termId:
          this.commericalDetails.commericalDetailsForm.controls.terms.value,
        branchId:
          this.contactDetails.contactDetailForm.controls.branchId.value === ''
            ? null
            : this.contactDetails.contactDetailForm.controls.branchId.value,
        accountTypeId: this.commonService.getEntityTypeId(this.moduleId),
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.customerId)) {
      this.editCustomer();
    } else {
      this.customerId = Guid.EMPTY as unknown as Guid;
      this.contactDetails.ngOnInit();
      this.commericalDetails.ngOnInit();
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
