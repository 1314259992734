import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Modules, NotificationTextMessage } from '@app/core/Enum';
import {
  FileUploadRequestModel,
  FileUploadResponseModel,
  MultipleFileDownloadModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { FileDowload, FileUpload } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-transactions-attachment',
  templateUrl: './transactions-attachment.component.html',
  styleUrls: ['./transactions-attachment.component.scss'],
})
export class TransactionsAttachmentComponent implements OnInit {
  dragAreaClass: string;
  showTable = false;
  selectedFiles: FileList;
  attachmentForm: FormGroup;

  fileList: any[] = [];

  fileUploadResponse: Array<FileUploadResponseModel>;
  fileUploadRequest: FileUploadRequestModel;
  multipleFileDownloadModel: MultipleFileDownloadModel;

  @Input() triggerEditData: Observable<any>;

  private destroy$ = new Subject<void>();

  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event: any) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();
    this.onFileSelected(event.dataTransfer.files);
  }

  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.fileUploadResponse = [];
    this.showTable = false;

    this.triggerEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.fileUploadResponse = [];
        if (data.attachment) {
          data.attachment.forEach((element) => {
            this.fileUploadResponse.push({
              fileName: element.replace(/^.*[\\\/]/, ''),
              fileUrl: element,
            });
          });
        }
        this.showTable = this.fileUploadResponse.length !== 0;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCancel(): void {
    this.attachmentForm.reset();
  }

  onFileSelected(event: any): void {
    if (event.addedFiles.length > 0) {
      this.selectedFiles = event.addedFiles;
      Array.prototype.forEach.call(this.selectedFiles, (file) => {
        this.fileList.push(file);
      });
      this.saveFile();
    }
  }

  saveFile(): void {
    this.spinner.show();
    this.fileUploadRequest = {
      file: this.fileList,
      attachmentType: Modules.Budgeting,
    };

    this.store.dispatch(new FileUpload(this.fileUploadRequest)).subscribe(
      (data) => {
        if (data !== undefined) {
          data.common.fileUploadRequestModel.forEach((element) => {
            this.fileUploadResponse.push(element);
          });
          this.showTable = data.common.fileUploadRequestModel.length !== 0;
          this.fileList = [];
        } else {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      },
      (err) => {
        this.commonService.onFailure(err.message);
      }
    );
  }

  dowloadFile(fileUrl: any): void {
    const filedata: any[] = [];

    filedata.push(fileUrl);

    this.multipleFileDownloadModel = {
      fileURLs: filedata,
    };

    this.store
      .dispatch(new FileDowload(this.multipleFileDownloadModel))
      .subscribe();
  }

  setForm(): void {
    this.attachmentForm = new FormGroup({
      attachment: new FormControl(''),
    });
  }

  removeFile(index: any): void {
    this.fileUploadResponse.splice(index, 1);
    this.showTable = this.fileUploadResponse.length !== 0;
  }
}
