import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddJournalComponent } from '../add-journal/add-journal.component';
import { AddPaymentComponent } from '../add-payment/add-payment.component';
import { AddReceiptsComponent } from '../add-receipts/add-receipts.component';
export interface PeriodicElement {
  type: string;
  amount: string;
  action: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    type: '',
    amount: '',
    action: '',
  },
];

@Component({
  selector: 'app-multiple-transaction',
  templateUrl: './multiple-transaction.component.html',
  styleUrls: ['./multiple-transaction.component.scss'],
})
export class MultipleTransactionComponent implements OnInit {
  displayedColumns: string[] = ['type', 'amount', 'action'];
  dataSource = ELEMENT_DATA;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MultipleTransactionComponent>
  ) {}

  ngOnInit(): void {}

  onCloseClick(): void {
    this.dialogRef.close();
  }
  AddReceipt() {
    const dialogRef = this.dialog.open(AddReceiptsComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  AddPayment() {
    const dialogRef = this.dialog.open(AddPaymentComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  AddJournal() {
    const dialogRef = this.dialog.open(AddJournalComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
