import { QuotationModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class CreateQuotation {
  static readonly type = '[QUOTATION] Create Quotation';

  constructor(public quotation: QuotationModel) {}
}

export class GetDataByQuotationId {
  static readonly type = '[QUOTATION] Get Quotation Data By Quotation Id';

  constructor(public quotationId: Guid) {}
}

export class ArchiveAndRestoreQuotation {
  static readonly type = '[QUOTATION] Archive and Restore Selected Quotation';

  constructor(public quotationIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteQuotation {
  static readonly type = '[QUOTATION] Delete Selected Quotation';

  constructor(public quotationIds: Array<Guid>) {}
}

export class GetQuotationNumber {
  static readonly type = '[QUOTATION] Get Quotation Number';
}
