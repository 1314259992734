import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaxLength, NotificationTextMessage } from '@app/core/Enum';
import { CommonService, HighlightRow } from '@app/core/Services';
import { MenuState } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { DiscountFlatRateComponent } from './discount-flat-rate/discount-flat-rate.component';

@Component({
  selector: 'app-add-submit-vat',
  templateUrl: './add-submit-vat.component.html',
  styleUrls: ['./add-submit-vat.component.scss'],
})
export class AddSubmitVatComponent implements OnInit {
  submitVatForm: FormGroup;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  @Input() triggerEditData: Observable<any>;
  @Input() vatReturnData:any;
  gridList:any=[];
  
  maxLength = MaxLength;

  defaultCurrency: Guid;
  periodicDate: any;
  notificationMessage = NotificationTextMessage;
  showGrid:boolean=false;
  fromDate:any;
  toDate:any;
  @ViewChild(DiscountFlatRateComponent, { static: false })
  discountGrid;
  editListData:any[]=[];
  constructor(
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.triggerEditData?.subscribe((data) => {
      this.editListData=data.subReturns;
      this.editVatDetails(data);
    });
    
  }

  editVatDetails(data): void {
    this.submitVatForm.patchValue({
      fromDate: data.fromDate,
      toDate: data.toDate,
      description: data.description,
      finalReturn: data.finalReturn,
    });
    if((data.vatSchemeId === 3 || data.vatSchemeId === 4) && data.subReturns.length){
    this.showGrid=true;
    }else{
      this.showGrid=false;
    }
    this.submitVatForm.patchValue({
      flatRate: this.showGrid,
    
    });
  }

  setForm(): void {
    this.submitVatForm = new FormGroup({
      fromDate: new FormControl(new Date(), [Validators.required]),
      toDate: new FormControl(new Date(), [Validators.required]),
      description: new FormControl(''),
      finalReturn: new FormControl(false),
      flatRate: new FormControl(false),
    });
  }

  displayGrid():void{
 
    let status:boolean = true;
        if (new Date(this.vatReturnData.discountFromDate) && new Date(this.vatReturnData.discountToDate)) {
            let from = new Date(this.vatReturnData.fromDate).getTime(), to = new Date(this.vatReturnData.toDate).getTime(),
                dfrom = new Date(this.vatReturnData.discountFromDate).getTime(), dto = new Date(this.vatReturnData.discountToDate).getTime();
 
            if ((from < dfrom && to < dfrom) || (from > dto && to > dto)) {
                status = false;
            }
            //whole vat period has only discounted flat rate
            if ((from >= dfrom && from <= dto) && (to >= dfrom && to <= dto)) {
                status = false;
            }
        }
        this.showGrid=status;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['vatReturnData']) {
      if(this.vatReturnData){
        this.fromDate=this.vatReturnData['fromDate'];
        this.toDate=this.vatReturnData['toDate'];
        this.displayGrid();
      }
      
    }
  }
  updateDates(type:number):void{
    if(type === 1){
      this.vatReturnData['fromDate']=new Date(this.submitVatForm.value.fromDate);
      this.fromDate=new Date(this.submitVatForm.value.fromDate);
    }else{
      this.vatReturnData['toDate']=new Date(this.submitVatForm.value.toDate);
      this.toDate=new Date(this.submitVatForm.value.toDate);
    }
    this.displayGrid();
   
   
  }

  getGridData(event:any):void{
    
    if(event){
    
      setTimeout(() => {
        
        this.gridList=this.discountGrid.mainList;
       
      }, 100);
    }

   
  }
}
