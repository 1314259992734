import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-view-cis-monthly-return-popup',
  templateUrl: './view-cis-monthly-return-popup.component.html',
  styleUrls: ['./view-cis-monthly-return-popup.component.scss'],
})
export class ViewCISMonthlyReturnPopupComponent implements OnInit {
  returnId: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewCISMonthlyReturnPopupComponent>
  ) {}

  ngOnInit(): void {
    this.returnId = atob(this.data.returnId) as unknown as Guid;
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
