import { Injectable } from '@angular/core';
import {
  Company,
  CompanyListModel,
  CompanyModel,
  CompanyPreference,
  MainListParameters,
  SideListModel,
} from '@app/core/Models';
import { CompanyService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreClient,
  CreateCompany,
  CreateCompanyPreference,
  DateBetweenAccountingPeriod,
  DeleteClient,
  DeleteFinancialYear,
  GetAccountingMethodList,
  GetAllBranchList,
  GetAllDepartmentListByBranch,
  GetAvailableCompanyCount,
  GetBranchList,
  GetCompanyList,
  GetCompanyNo,
  GetCompanyPreferenceData,
  GetCompanyType,
  GetCompanyTypeId,
  GetCompanyVatSchemaId,
  GetDataByCompanyId,
  GetDepartmentList,
  GetDepartmentListByBranch,
  GetDepartmentListByBranchIds,
  GetLanguage,
  GetManageCompanyList,
  GetTimeZone,
  GetVatReturnTypeList,
  GetVatSchemeList,
  IsDuplicateExist,
  LockFinancialYear,
  SetAsDefaultBranch,
  SetAsDefaultCompany,
  SetAsDefaultDepartment,
  SetBranchListForCompany,
  SetDefaultCompanyId,
} from './company.action';

export class CompanyStateInfo {
  companyId: Guid;
  companyCount: number;
  isCompanyAdded: boolean;
  departmentId: Guid;
  isDepartmentAdded: boolean;
  branchId: Guid;
  isBranchAdded: boolean;
  isFinancialYearDeleted: boolean;
  isFinancialYearLocked: boolean;
  isDateBetweenAccountingPeriod: boolean;
  company: Array<Company>;
  department: Array<any>;
  branch: Array<any>;
  companyData?: CompanyModel;
  getCompanyPreferenceData?: CompanyPreference;
  companyType: Array<SideListModel>;
  timeZone: Array<SideListModel>;
  language: Array<SideListModel>;
  financialPeriod: Array<SideListModel>;
  vatReturnTypeList: Array<SideListModel>;
  vatSchemeList: Array<SideListModel>;
  branchList: Array<SideListModel>;
  accountingMethod: Array<SideListModel>;
  departmentList?: MainListParameters;
  departmentListbyIds?: MainListParameters;
  companyNo: string;
  companyVatSchemaId: number;
  companyTypeId: number;
  sideListModel: Array<SideListModel>;
  manageCompany?: CompanyListModel[];
  totalRecord?: number;
  isDuplicateExist?: boolean;
}

@State<CompanyStateInfo>({
  name: 'company',
  defaults: {
    companyId: Guid.EMPTY as unknown as Guid,
    companyCount: -1,
    isCompanyAdded: false,
    departmentId: Guid.EMPTY as unknown as Guid,
    isDepartmentAdded: false,
    branchId: Guid.EMPTY as unknown as Guid,
    isBranchAdded: false,
    isFinancialYearDeleted: false,
    isFinancialYearLocked: false,
    isDateBetweenAccountingPeriod: false,
    company: [],
    department: [],
    branch: [],
    companyType: [],
    timeZone: [],
    language: [],
    financialPeriod: [],
    vatReturnTypeList: [],
    accountingMethod: [],
    vatSchemeList: [],
    branchList: [],
    companyNo: '',
    companyVatSchemaId: 0,
    companyTypeId: 0,
    sideListModel: [],
  },
})
@Injectable()
export class CompanyState {
  constructor(private companyService: CompanyService) {}

  @Selector()
  static companyCount(state: CompanyStateInfo): number {
    return state.companyCount;
  }

  @Selector()
  static branchList(state: CompanyStateInfo): any {
    return state.branchList;
  }

  @Selector()
  static getVatSchemesList(state: CompanyStateInfo) {
    return state.vatSchemeList ?? [];
  }

  @Selector()
  static getCompanyId(state: CompanyStateInfo): any {
    return state.companyId;
  }

  @Selector()
  static totalRecord(state: CompanyStateInfo) {
    return state.totalRecord;
  }

  @Action(CreateCompany)
  createCompany(
    { patchState }: StateContext<CompanyStateInfo>,
    action: CreateCompany
  ) {
    return this.companyService.createCompany(action.company).pipe(
      tap((res) => {
        patchState({
          companyId: res,
          isCompanyAdded: true,
        });
      })
    );
  }

  @Action(GetCompanyList)
  getCompanyList(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: GetCompanyList
  ) {
    return this.companyService.getCompanyList(action.params).pipe(
      tap((res) => {
        const state = getState();

        const totalRecord = res.totalItemCount;

        patchState({
          ...state,
          company: res.data,
          totalRecord,
        });
      })
    );
  }

  @Action(GetDepartmentList)
  getDepartmentList(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: GetDepartmentList
  ) {
    return this.companyService.getDepartmentList(action.params).pipe(
      tap((res) => {
        patchState({
          department: res,
        });
      })
    );
  }

  @Action(GetBranchList)
  getBranchList(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: GetBranchList
  ) {
    return this.companyService.getBranchList(action.params).pipe(
      tap((res) => {
        patchState({
          branch: res,
        });
      })
    );
  }

  @Action(GetAvailableCompanyCount)
  getAvailableCompanyCount({ patchState }: StateContext<CompanyStateInfo>) {
    return this.companyService.getAvailableCompanyCount().pipe(
      tap((res) => {
        patchState({
          companyCount: res,
        });
      })
    );
  }

  @Action(GetVatReturnTypeList)
  getVatReturnTypeList(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: GetVatReturnTypeList
  ) {
    return this.companyService.getVatReturnTypeList().pipe(
      tap((res) => {
        patchState({
          vatReturnTypeList: res,
        });
      })
    );
  }

  @Action(SetAsDefaultCompany, { cancelUncompleted: true })
  setAsDefaultCompany(
    { patchState }: StateContext<CompanyStateInfo>,
    action: SetAsDefaultCompany
  ) {
    return this.companyService.setAsDefaultCompany(action.companyId).pipe(
      tap((res) => {
        patchState({
          companyId: res,
          isCompanyAdded: true,
        });
      })
    );
  }

  @Action(SetAsDefaultDepartment, { cancelUncompleted: true })
  setAsDefaultDepartment(
    { patchState }: StateContext<CompanyStateInfo>,
    action: SetAsDefaultDepartment
  ) {
    return this.companyService.setAsDefaultDepartment(action.departmentId).pipe(
      tap((res) => {
        patchState({
          departmentId: res,
          isDepartmentAdded: true,
        });
      })
    );
  }

  @Action(SetAsDefaultBranch, { cancelUncompleted: true })
  setAsDefaultBranch(
    { patchState }: StateContext<CompanyStateInfo>,
    action: SetAsDefaultBranch
  ) {
    return this.companyService.setAsDefaultBranch(action.branchId).pipe(
      tap((res) => {
        patchState({
          branchId: res,
          isBranchAdded: true,
        });
      })
    );
  }

  @Action(GetDepartmentListByBranch, { cancelUncompleted: true })
  getDepartmentListByBranch(
    { patchState }: StateContext<CompanyStateInfo>,
    action: GetDepartmentListByBranch
  ) {
    return this.companyService
      .getDepartmentListByBranch(action.branchId, action.queryParams)
      .pipe(
        tap((res) => {
          patchState({
            departmentList: res,
          });
        })
      );
  }

  @Action(GetDepartmentListByBranchIds, { cancelUncompleted: true })
  getDepartmentListByBranchIds(
    { patchState }: StateContext<CompanyStateInfo>,
    action: GetDepartmentListByBranchIds
  ) {
    return this.companyService
      .getDepartmentListByBranchIds(action.branchIds)
      .pipe(
        tap((res) => {
          patchState({
            departmentListbyIds: res,
          });
        })
      );
  }

  @Action(GetDataByCompanyId)
  getDataByCompanyId(
    { patchState }: StateContext<CompanyStateInfo>,
    action: GetDataByCompanyId
  ) {
    return this.companyService.getDataByCompanyId(action.companyId).pipe(
      tap((res) => {
        patchState({
          companyData: res,
        });
      })
    );
  }

  @Action(GetCompanyType)
  getCompanyType({ getState, patchState }: StateContext<CompanyStateInfo>) {
    return this.companyService.getCompanyType().pipe(
      tap((res) => {
        patchState({
          companyType: res,
        });
      })
    );
  }

  @Action(GetTimeZone)
  getTimeZone({ getState, patchState }: StateContext<CompanyStateInfo>) {
    return this.companyService.getTimeZone().pipe(
      tap((res) => {
        patchState({
          timeZone: res,
        });
      })
    );
  }

  @Action(GetLanguage)
  getLanguage({ getState, patchState }: StateContext<CompanyStateInfo>) {
    return this.companyService.getLanguage().pipe(
      tap((res) => {
        patchState({
          language: res,
        });
      })
    );
  }

  @Action(CreateCompanyPreference)
  createCompanyPreference(
    { patchState }: StateContext<CompanyStateInfo>,
    action: CreateCompanyPreference
  ) {
    return this.companyService.createCompanyPreference(action.company).pipe(
      tap((res) => {
        patchState({
          companyId: res,
          isCompanyAdded: true,
        });
      })
    );
  }

  @Action(GetCompanyPreferenceData)
  getCompanyPreferenceData(
    { patchState }: StateContext<CompanyStateInfo>,
    action: GetCompanyPreferenceData
  ) {
    return this.companyService.getCompanyPreferenceData(action.moduleId).pipe(
      tap((res) => {
        patchState({
          getCompanyPreferenceData: res,
        });
      })
    );
  }

  @Action(GetAccountingMethodList)
  getAccountingMethodList({ patchState }: StateContext<CompanyStateInfo>) {
    return this.companyService.getAccountingMethodList().pipe(
      tap((res) => {
        patchState({
          accountingMethod: res,
        });
      })
    );
  }

  @Action(GetVatSchemeList)
  getVatSchemeList(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: GetVatSchemeList
  ) {
    return this.companyService.getVatSchemeList().pipe(
      tap((res) => {
        patchState({
          vatSchemeList: res,
        });
      })
    );
  }

  @Action(SetBranchListForCompany, { cancelUncompleted: true })
  SetBranchListForCompany(
    { patchState }: StateContext<CompanyStateInfo>,
    action: SetBranchListForCompany
  ) {
    patchState({
      branchList: action.branchList,
    });
  }

  @Action(DeleteFinancialYear)
  deleteFinancialYear(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: DeleteFinancialYear
  ) {
    return this.companyService.deleteFinancialYear(action.ids).pipe(
      tap((res) => {
        patchState({
          isFinancialYearDeleted: res,
        });
      })
    );
  }

  @Action(LockFinancialYear)
  lockFinancialYear(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: LockFinancialYear
  ) {
    return this.companyService
      .lockFinancialYear(action.ids, action.isLocked)
      .pipe(
        tap((res) => {
          patchState({
            isFinancialYearLocked: res,
          });
        })
      );
  }

  @Action(DateBetweenAccountingPeriod)
  dateBetweenAccountingPeriod(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: DateBetweenAccountingPeriod
  ) {
    return this.companyService.dateBetweenAccountingPeriod(action.date).pipe(
      tap((res) => {
        patchState({
          isDateBetweenAccountingPeriod: res,
        });
      })
    );
  }

  @Action(GetCompanyNo)
  getCompanyNo(
    { patchState }: StateContext<CompanyStateInfo>,
    action: GetCompanyNo
  ) {
    return this.companyService.getCompanyNo().pipe(
      tap((res) => {
        patchState({
          companyNo: res,
        });
      })
    );
  }

  @Action(GetCompanyVatSchemaId)
  getCompanyVatSchemaId(
    { patchState }: StateContext<CompanyStateInfo>,
    action: GetCompanyVatSchemaId
  ) {
    return this.companyService.getCompanyVatSchemaId().pipe(
      tap((res) => {
        patchState({
          companyVatSchemaId: res,
        });
      })
    );
  }

  @Action(GetCompanyTypeId)
  getCompanyTypeId(
    { patchState }: StateContext<CompanyStateInfo>,
    action: GetCompanyTypeId
  ) {
    return this.companyService.getCompanyTypeId().pipe(
      tap((res) => {
        patchState({
          companyTypeId: res,
        });
      })
    );
  }

  @Action(GetAllDepartmentListByBranch)
  getAllDepartmentListByBranch(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: GetAllDepartmentListByBranch
  ) {
    return this.companyService
      .getAllDepartmentListByBranch(action.branchId)
      .pipe(
        tap((res) => {
          patchState({
            department: res,
          });
        })
      );
  }

  @Action(GetAllBranchList)
  getAllBranchList(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: GetAllBranchList
  ) {
    return this.companyService.getAllBranchList().pipe(
      tap((res) => {
        patchState({
          branch: res,
        });
      })
    );
  }
  @Action(DeleteClient)
  deleteClient(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: DeleteClient
  ) {
    return this.companyService.deleteClient(action.clientIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredClient = state.company.filter(
          (item) =>
            !action.clientIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.clientIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          company: filteredClient,
          sideListModel: filteredForSideList,
        });
      })
    );
  }
  @Action(GetManageCompanyList, { cancelUncompleted: true })
  getManageCompanyList(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: GetManageCompanyList
  ) {
    return this.companyService.getManageCompanyList(action.queryParams).pipe(
      tap((res) => {
        patchState({
          manageCompany: res,
        });
      })
    );
  }

  @Action(SetDefaultCompanyId)
  setDefaultCompanyId({ patchState }: StateContext<CompanyStateInfo>) {
    patchState({
      companyId: Guid.EMPTY as unknown as Guid,
    });
  }

  @Action(ArchiveAndRestoreClient)
  archiveAndRestoreClient(
    { getState, patchState }: StateContext<CompanyStateInfo>,
    action: ArchiveAndRestoreClient
  ) {
    return this.companyService
      .archiveAndRestoreClient(action.clientIds, action.isArchive)
      .pipe(
        tap((res) => {
          const state = getState();

          patchState({
            ...state.company,
            company: state.company,
          });
        })
      );
  }

  @Action(IsDuplicateExist)
  isDuplicateExist(
    { getState, setState }: StateContext<CompanyStateInfo>,
    action: IsDuplicateExist
  ) {
    return this.companyService
      .isDuplicateExist(action.duplicateExistModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            isDuplicateExist: res,
          });
        })
      );
  }
}
