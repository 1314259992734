import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductsModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getTrackedProducts(): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}Product/getTrackedProducts`
    );
  }

  createProduct(product: ProductsModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Product/save`,
      this.commonService.trimObjectSpace(JSON.stringify(product)),
      headers
    );
  }

  getDataByProductId(productId: Guid): Observable<ProductsModel> {
    return this.http.get<ProductsModel>(
      `${environment.apiVersionUrl}Product/id/${productId}`
    );
  }

  deleteProduct(productIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(productIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Product/delete`,
      options
    );
  }

  copyProduct(productIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Product/copyProduct`,
      JSON.stringify(productIds),
      headers
    );
  }

  archiveAndRestoreProduct(
    productIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Product/archive/${isArchive}`,
      JSON.stringify(productIds),
      options
    );
  }
}
