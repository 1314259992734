import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { QuickEntryItemModel, QuickEntryModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateQuickEntry,
  GetDataByQuickEntryId,
  GetQuickEntryNumber,
  MenuState,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { AddClosePopupComponent } from '../common';
import { AddQuickentryComponent } from './add-quickentry/add-quickentry.component';
import { QuickentryDetailsComponent } from './quickentry-details/quickentry-details.component';
@Component({
  selector: 'app-quick-entry',
  templateUrl: './quick-entry.component.html',
  styleUrls: ['./quick-entry.component.scss'],
})
export class QuickEntryComponent implements OnInit {
  moduleId = Modules.QuickEntry;
  moduleName = ModuleName.QuickEntry;
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  quickEntryId = Guid.EMPTY as unknown as Guid;
  quickEntryData: QuickEntryModel;
  quickEntryDetailData: Array<QuickEntryItemModel>;
  subscriptionRouting: Subscription;

  triggerDefaultBankSelectedOption: Subject<any> = new Subject<any>();
  triggerPaydateSameAsInvoiceDateSelected: Subject<boolean> =
    new Subject<boolean>();
  triggerDefaultVatAmountSelectedOption: Subject<number> =
    new Subject<number>();
  triggerEditData: Subject<any> = new Subject<any>();

  @Output()
  readonly reloadSideList = new EventEmitter<any>();
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddQuickentryComponent, { static: true })
  addQuickEntry;
  @ViewChild(QuickentryDetailsComponent, { static: true })
  quickEntryDetails;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private store: Store,
    public dialog: MatDialog,
    public commonService: CommonService,
    private spinner: NgxSpinnerService,
    private location: Location,
    private _Activatedroute: ActivatedRoute,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          const id = atob(params.get('id')!) as unknown as Guid;
          if (params.get('id') !== undefined && params.get('id') !== null) {
            this.quickEntryId = id;
            this.editQuickEntry();
          }
        } else {
          this.getQuickEntryNumber();
        }
      }
    );
  }
  editQuickEntry(): void {
    this.store
      .dispatch(new GetDataByQuickEntryId(this.quickEntryId))
      .subscribe((res) => {
        this.triggerEditData.next(res.quickentry.quickEntryData);
      });
  }
  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }
  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }
  reloadAutoNumberGenerator(): void {
    this.getQuickEntryNumber();
  }
  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }
  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.quickEntryId,
      isExit,
      Modules.QuickEntry,
      RoutingPath.AddQuickEntry
    );
  }
  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
  getQuickEntryNumber(): void {
    this.store.dispatch(new GetQuickEntryNumber()).subscribe((res) => {
      res.quickentry.quickEntryNo !== null
        ? this.addQuickEntry.quickEntryForm.controls.quickEntryNo.disable()
        : this.addQuickEntry.quickEntryForm.controls.quickEntryNo.enable();
      this.addQuickEntry.quickEntryForm.controls.quickEntryNo.setValue(
        res.quickentry.quickEntryNo
      );
    });
  }
  onDefaultBankAccountSelectedOption(selectedOption: any) {
    this.triggerDefaultBankSelectedOption.next(selectedOption);
  }
  onPaydateSameAsInvoiceDateSelectedOption(selectedOption: any) {
    this.triggerPaydateSameAsInvoiceDateSelected.next(selectedOption);
  }
  onDefaultVatAmountSelectedOption(selectedOption: any) {
    this.triggerDefaultVatAmountSelectedOption.next(selectedOption);
  }
  onSave(isExit: boolean): void {
    if (this.quickEntryDetails.productDetailArray.invalid) {
      this.quickEntryDetails.productDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateQuickEntry(this.quickEntryData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }
  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.quickEntryId)) {
      this.editQuickEntry();
    } else {
      this.quickEntryId = Guid.EMPTY as unknown as Guid;
      this.addQuickEntry.ngOnInit();
      this.quickEntryDetails.ngOnInit();
      this.getQuickEntryNumber();
      this.quickEntryDetails.totalReceiptValue = 0;
      this.quickEntryDetails.totalPaymentValue = 0;
    }
  }
  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.quickEntryData = new QuickEntryModel();
      this.quickEntryDetailData = new Array<QuickEntryItemModel>();
      this.quickEntryDetails.productDetailArray?.getRawValue().forEach((x) => {
        this.quickEntryDetailData.push({
          id: x.id,
          type: x.type,
          customerSupplierId: x.customerSupplierId,
          reference: x.reference,
          accountId: x.account.id,
          description: x.description,
          invoiceDate: this.datepipe
            .transform(x.invoiceDate, 'yyyy-MM-dd')
            ?.toString(),
          netAmount: x.netAmount,
          vatRateId: +x.vatRate === -1 ? null : x.vatRate,
          vatAmount: x.vatAmount,
          totalAmount: x.amount,
          payDate: this.datepipe
            .transform(x.paymentDate, 'yyyy-MM-dd')
            ?.toString(),
          paymentAccountId: x.paymentAccountId,
          isNetToGross:
            this.addQuickEntry.quickEntryForm.controls.vatAmountType.value === 1
              ? true
              : false,
        });
      });

      this.quickEntryData = {
        id: this.addQuickEntry.quickEntryForm.controls.id.value,
        branchId:
          this.addQuickEntry.quickEntryForm.controls.branchId.value === ''
            ? null
            : this.addQuickEntry.quickEntryForm.controls.branchId.value,
        departmentId:
          this.addQuickEntry.quickEntryForm.controls.departmentId.value === ''
            ? null
            : this.addQuickEntry.quickEntryForm.controls.departmentId.value,
        entryNumber:
          this.addQuickEntry.quickEntryForm.controls.quickEntryNo.value,
        note: this.addQuickEntry.quickEntryForm.controls.reference.value,
        transactionLogId: Guid.EMPTY as unknown as Guid,
        quickEntryItems: this.quickEntryDetailData,
        bookAccountId:
          this.addQuickEntry.quickEntryForm.controls.accountId.value,
        vatAmountType:
          this.addQuickEntry.quickEntryForm.controls.vatAmountType.value,
        defaultPayDate:
          this.addQuickEntry.quickEntryForm.controls.payDateType.value,
        totalReceiptAmount: this.quickEntryDetails.totalReceiptValue,
        totalPaymentAmount: this.quickEntryDetails.totalPaymentValue,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }
}
