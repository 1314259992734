import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Role, UserModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private http: HttpClient, private commonService: CommonService) {}
  createUser(userData: UserModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}User/saveUserBasicInfo`,
      this.commonService.trimObjectSpace(JSON.stringify(userData)),
      headers
    );
  }

  getUser(): Observable<UserModel> {
    return this.http.get<UserModel>(`${environment.apiVersionUrl}User`);
  }

  getRole(): Observable<Role> {
    return this.http.get<Role>(`${environment.apiVersionUrl}Role/all`);
  }

  getPermissionDataByUserId(userId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}ModuleRolePermission/getUserModulePermission/${userId}`
    );
  }

  saveUserModulePermission(data: Array<any>, userId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}ModuleRolePermission/saveUserModulePermission/${userId}`,
      JSON.stringify(data),
      headers
    );
  }
}
