import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  AccountEntity,
  DataType,
  ModuleName,
  Modules,
  RoutingPath,
} from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  GetCreditorDebtorTransaction,
  GetNominalLedgerTransaction,
} from '@app/core/Store';
import { ViewReceiptComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-expand-collapse',
  templateUrl: './expand-collapse.component.html',
  styleUrls: ['./expand-collapse.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('isExpanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'isExpanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ExpandCollapseComponent implements OnInit {
  expandedCollapseList: any[] = [];
  expandedCollapseDetailList: any;

  @Input() listParameters: any;
  @Input() moduleId: number;
  @Input() triggerExpandedCollapseList: Observable<any>;

  displayedColumns: any[] = [];
  displayedColumnsName: any[] = [];

  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource();

  @Select(CommonState.expandedCollapseColumnNames)
  columnsNames$: Observable<Array<string>>;

  @Select(CommonState.expandedCollapseColumns)
  columns$: Observable<
    Array<{
      name: string;
      value: string;
      dataType?: DataType;
      allowSortBy?: boolean;
      alignment: string;
    }>
  >;

  dataType = DataType;
  noDataFound = false;
  expandId: any;
  isGuidExist: any;
  currentRowSelected: any;
  reportText: string;

  showPaginator = true;
  triggerPaginationChange: Subject<any> = new Subject<any>();

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  constructor(
    public store: Store,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    public commonService: CommonService,
    public router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.triggerExpandedCollapseList?.subscribe((res) => {
      this.expandedCollapseList = res.data;
      this.noDataFound = this.expandedCollapseList.length === 0 ? true : false;
      this.displayedColumns = [];
      this.displayedColumns.push({
        id: 0,
        name: ' ',
        dataType: 1,
        allowSortBy: true,
        alignment: '',
      });
      res.columns.forEach((element) => {
        this.displayedColumns.push(element);
      });
      this.displayedColumnsName = this.displayedColumns.map((x) => x.name);
      this.setReportHeader();
    });
  }

  setReportHeader(): void {
    switch (this.moduleId) {
      case Modules.Debtors:
        this.reportText = ModuleName.Debtors;
        break;
      case Modules.Creditors:
        this.reportText = ModuleName.Creditors;
        break;
      case Modules.NominalLedger:
        this.reportText = ModuleName.NominalLedger;
        break;
    }
  }

  redirect(id: any, moduleId: number): void {
    if (moduleId > 0) {
      if (moduleId === Modules.AddCustomAccounts) {
        const params = { id: btoa(id), details: btoa('true') };
        this.router.navigate([RoutingPath.AccountDetails, params]);
      } else if (
        moduleId === Modules.Invoices ||
        moduleId === Modules.CreditNote ||
        moduleId === Modules.DebitNote ||
        moduleId === Modules.FixedAssets ||
        moduleId === Modules.Quotation ||
        moduleId === Modules.Purchase ||
        moduleId === Modules.Receipt ||
        moduleId === Modules.Payment ||
        moduleId === Modules.FixedAssetDetail ||
        moduleId === Modules.QuickEntry
      ) {
        const data = {
          moduleId,
          id,
        };

        this.dialog
          .open(ViewReceiptComponent, {
            data,
            disableClose: true,
          })
          .afterClosed()
          .subscribe((result) => {});
      } else if (
        moduleId === Modules.Customers ||
        moduleId === Modules.Suppliers
      ) {
        this.commonService.onEditRouting(
          true,
          Modules.AccountDetails,
          id,
          true,
          moduleId
        );
      } else {
        this.commonService.onEditRouting(true, moduleId, id);
      }
    }
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.fetchList();
  }

  expandCollapse(row, filter): void {
    if (this.currentRowSelected && this.currentRowSelected !== row) {
      this.currentRowSelected.isExpanded = false;
    }
    this.currentRowSelected = row;

    this.expandId = row[0];
    if (row.isExpanded) {
      row.isExpanded = false;
    } else {
      row.isExpanded = true;
      this.listParameters.filter = filter;
      this.listParameters.pageNumber = 1;
      this.listParameters.pageSize = 20;
      this.fetchList();
    }
  }

  getParamter(): any {
    this.isGuidExist =
      AccountEntity.Supplier === +this.expandId ||
      AccountEntity.Customer === +this.expandId
        ? true
        : false;

    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: !this.isGuidExist
        ? this.expandId
        : this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      branchIds: this.listParameters.branchIds ?? [],
      departmentIds: this.listParameters.departmentIds ?? [],
      subModuleId: this.isGuidExist
        ? this.expandId
        : this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    return queryParams;
  }

  getList(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetNominalLedgerTransaction(this.getParamter()))
      .subscribe((res) => {
        this.spinner.hide();
        this.expandedCollapseDetailList =
          res.common.expandedCollapseDetailList.resultSet.data;
        this.noDataFound =
          this.expandedCollapseDetailList.length > 0 ? false : true;
        this.dataSource.data = this.expandedCollapseDetailList;

        const param = {
          pageSize: this.listParameters.pageSize,
          totalRecord: res.common.totalRecord,
        };
        this.triggerPaginationChange.next(param);
      });
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.getList();
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.getList();
  }

  fetchList(): void {
    if (
      Modules.Creditors !== this.moduleId &&
      Modules.Debtors !== this.moduleId
    ) {
      this.getList();
    } else {
      this.getCreditorDebtorTransaction();
    }
  }

  getCreditorDebtorTransaction(): void {
    this.spinner.show();
    this.store
      .dispatch(
        new GetCreditorDebtorTransaction(
          this.getParamter(),
          this.moduleId,
          this.expandId
        )
      )
      .subscribe((res) => {
        this.spinner.hide();
        this.expandedCollapseDetailList =
          res.common.expandedCollapseDetailList.resultSet.data;
        this.noDataFound =
          this.expandedCollapseDetailList.length > 0 ? false : true;
        this.dataSource.data = this.expandedCollapseDetailList;
      });
  }
}
