export enum NotificationHeader {
  deleteConfirmation = 'Confirm Delete',
  disposedConfirmation = 'Confirm re-Activate Disposed',
  archiveConfirmation = 'Confirm Inactive',
  restoreConfirmation = 'Confirm Active',
  navigateMeToGmail = 'Navigate me to gmail',
  navigateMeToOutlook = 'Navigate me to outlook',
  success = 'Success!',
  error = 'Error!',
  clearAllLinesHeaderText = 'Clear',
  deleteYourAccount = 'Delete your account',
  reauthenticateYourAccount = 'Reauthenticate Your Account',
  confirmation = 'Confirmation',
  navigateMeToCapiumPay = 'Navigate Me to Capium Payment Gateway',
  unalocateAmountConfirm = 'Un-allocate Confirmation',
}

export enum NotificationDetails {
  deleteDetailText = 'Are you sure you want to delete <strong> ',
  questionMark = ' </strong> ?',
  archiveDetailText = 'Are you sure you want to inactive the data? ',
  disposedDetailText = 'Are you sure to re-activate the disposed asset?',
  restoreDetailText = 'Are you sure you want to active the data? ',
  deleteYourAccountText = 'Are you sure you want to delete your account?',
  navigateMeToGmailText = 'Are you sure you want to navigate to your gmail account.',
  navigateMeToOutlookText = 'Are you sure you want to navigate to your outlook account.',
  deleteAllDetailText = 'Please confirm you want to delete these things. Deleting cannot be undone.',
  mergeTrailBalanceText = 'Are you sure you want to merge the values in the same account?',
  trialBalanceErrorText = 'Following accounts does not have any values so we can not import in the <b> Trial Balance </b>.',
  fixedAssetExplain = 'One or more Fixed Assets are associated with this transaction.<br> Updating this transaction will reset the Fixed Assets completely and the assets shall be explained again.  ',
  navigateMeToCapiumPayText = 'Are you sure you want to navigate to <br/> CapiumPay',
  deleteSelectedDetailText = 'Are you sure you want to delete selected record(s)? ',
  submitNillVAT = 'Are you sure you want to submit Nill VAT?',
  unallocateText = 'Do you want to unallocate this amount',
}

export enum NotificationAddClosePopUpMessage {
  defaultHeaderText = 'Terminate process',
  defaultBodyText = 'Sure, you want to terminate process.',
  saveOpeningBalanceHeaderText = 'Confirmation',
  informHeaderText = 'Information',
  saveOpeningBalanceBodyText = 'This Opening Balance will get saved as draft as the amount in Debit and Credit Columns are not equal.',
  saveJournalBodyText = 'This journal will be saved as a draft because the amounts in the debit and credit columns are not equal. You must balance this journal for its proper impact on the final accounts.',
  informBodyText = 'All Explained amount of Supplier will be washed out.',
  importBodyText = 'Sure, you want to ignore and continue.',
  saveWarningBodyText = 'Are you sure you want to navigate without saving the changes?',
}

export enum NotificationTextMessage {
  qtyErrorMessage = 'Quantity should be greater than 0',
  amtErrorMessage = 'Amount should be greater than 0',
  validPeriodDateMessage = 'Date should be between accounting period.',
  lockedAccoutingPeriodDateMessage = 'Accounting period is locked.',
  successMessage = 'The operation completed Successfully!',
  emailMessage = 'Email sent Successfully!',
  emailNotSendMessage = 'Oops! We encountered an issue while trying to send your email. Please try again later.',
  errorMessage = 'There might be some error occured!',
  bankAccountAlreadyExits = 'Bank account already exits!',
  recordCopySuccessfully = 'Record copied successfully',
  recordArchivedSuccessfully = 'Record inactivated successfully',
  recordRestoredSuccessfully = 'Record activated successfully',
  recordDeletedSuccessfully = 'Record deleted successfully',
  noRecordFoundText = 'No record found.',
  amountErrorMessage = 'Entered Amount Exceed Limit',
  fileSizeExceeds = 'File should be less than 10 MB',
  mailSentSuccessfully = 'Mail Sent successfully',
  duplicateMail = 'to email, cc email or bcc email cannot be same',
  filenameWithSpecialCharacter = 'File name should not contain special character',
  fileUploaded = 'File uploaded successfully',
  vatRateErrorMessage = 'Please select valid vat rate',
  exceedDateMessage = 'End Date should not be more than one year',
  capiumSaveMessage = 'VAT return has been submitted to Capium successfully',
  vatTransactionImported = 'Vat transaction imported successfully',
  clearAllLinesBodyText = 'Sure, you want to clear all the lines.',
  totalPayment = 'Cost of material cannot be greater than total payment',
  totalInvoiceAmount = 'Amount cannot be greater than invoice amount or due amount',
  totalPaymentAmount = 'Amount cannot be greater than payment amount or due amount',
  explainOpeningBalance = 'Amount can`t be less than opening balance',
  succesList = 'No records are updated',
  moveToSuccessList = 'Record move to success list',
  invoiceNoIsExist = 'Invoice number is exists',
  invoiceNoValidateSuccessfully = 'Invoice number validated successfully',
  mailDeleteSucessfully = 'Mail deleted successfully',
  dateDoesNotExist = 'Date does not exist in current accounting period',
  dateValidateSuccessfully = 'Date validated successfully',
  appointmentDateError = 'Date does not exist in comapny creation period',
  deleteFinanicalPeriod = 'Are you sure you want to delete the financial period',
  NoSelectedErrorMessage = 'You must select at least one checkbox to depreciate assets.',
  transferErrorMessage = 'Sorry, cannot be transferred to the same account',
  dataInLockedPeriod = 'Sorry, Accounting period is locked for the selected date.',
  dataInLockedPeriodList = 'Sorry, Accounting period is locked for the given date.',
  noDataToExport = 'Sorry, There is no data to export.',
  noDataToEmail = 'Sorry, There is no data to email.',
  errorWrongMessage = 'Something went wrong. Please try again later.',
  noSelectedBankErrorMessage = 'Please select atleast one checkbox to save changes.',
  accountErrorMessage = 'Please select account to save changes.',
  receiptSelectError = 'No invoice available for receipt generation.',
  checkAmountErrorMessage = 'Amount has to be greater than 0 to generate a Receipt.',
  blankLineError = 'Please remove the blank line to save the data',
  errorDebitCreditMessage = 'Debit amount and credit amount should be same to save changes.',
  amountTotalError = 'Entered total amount should be greater then import bank payment',
  selectShareHolder = 'Please select Shareholder!',
  emptyBody = 'Empty email body is not allowed!',
  standardVATCodeErrorMessage = 'Sorry, you cannot perform any action on the standard VAT code.',
  selectAccountingPeriod = 'Please select Accounting Period Date',
  vatNumberErrorMessage = 'Please enter a valid VAT registration number.',
  recordActivateSuccessfully = 'Record re-Activate successfully',
  trailBalanceZeroValueValidation = 'Sorry you cannot merge this account as the amount gets zero after merging.',
  fileSize5mb = 'File should be less than 5 MB',
  fileSize2mb = 'File should be less than 2 MB',
  fileJpgPng = 'File should be .png or .jpg extension',
  validationMessage = 'Please fill all the mandatory fields.',
  canNotEdit = 'This record can not be edited!',
  currencyValidation = 'This record has been already added!',
  sentMail = 'Mail sent successfully!',
  alreadyInactive = 'Selected record has already inactive record',
  alreadyRestore = 'Selected record has already active record',
  canNotDelete = 'You do not have permission to delete selected records',
  canNotInactive = 'You do not have permission to inactive selected records',
  NoRecordsFound = 'No records found',
  NoRecordsFoundForFilter = 'No records found for the given search/filter values',
  permissionChangeAndNotSave = 'Please save your permission changes to edit user permissions.',
  ViewOnlyAccessMessage = 'You have view only access for this section. Please contact your manager for edit access.',
  NoImportAccesssMessage = 'You do not have access for import this module. Please contact your manager for edit access.',
  selectOneOrMoreRecordToDelete = 'Select one or more records to delete',
  successList = 'No records are updated',
  selectModule = 'Select module to download template',
  clientExist = 'Client is already exist.',
  userExist = 'User is already exist.',
  clientCreatedSuccess = 'Client created successfully',
  unallocateSuccessfully = 'Amount unallocated successfully',
  clientUpdatedSuccess = 'Client updated successfully',
  fileAcceptMsg = 'Please upload .jpg,.png,.jpeg files only',
  clientIdExist = 'Client ID is already exist.',
}

export enum CommonNotificationText {
  NoRecordFoundText = 'No records found',
  NoRecordsFoundForFilter = 'No records found for the given search/filter values',
  Active = 'Active',
  Archived = 'Archived',
  ViewOnlyAccessMessage = 'You have view only access for manage section. Please contact your manager for edit access.',
}
