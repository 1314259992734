import { ActivityTaskParameters } from "@app/core/Models";

export class GetActivityList {
  static readonly type = '[ACTIVITY] Get Activity';

  constructor(public queryParams: ActivityTaskParameters) {}
}

export class GetAuthorizedMenus {
  static readonly type = '[ACTIVITY] Get authorised menu';
}