<div class="content-body">
  <table class="wp-100" aria-describedby="Return Table">
    <tbody>
      <tr valign="top">
        <td class="wp-45">
          <table class="wp-100" cellpadding="3" aria-describedby="Return Table">
            <tr>
              <td>
                <span><strong>Period :</strong></span>
              </td>
              <td>{{ data.toDate | date: "MMM-yyyy" }}</td>
            </tr>
            <tr>
              <td>
                <span><strong>Nil Return :</strong></span>
              </td>
              <td>{{ data.employmentStatus ? "Yes" : "No" }}</td>
            </tr>
            <tr>
              <td>
                <span><strong>Employment Status :</strong></span>
              </td>
              <td>{{ data.employmentStatus ? "Yes" : "No" }}</td>
            </tr>
          </table>
        </td>
        <td class="wp-45">
          <table class="wp-100" cellpadding="3" aria-describedby="Return Table">
            <tbody>
              <tr>
                <td>
                  <span><strong>Verification :</strong></span>
                </td>
                <td>{{ data.verification ? "Yes" : "No" }}</td>
              </tr>
              <tr>
                <td>
                  <span><strong>Inactivity :</strong></span>
                </td>
                <td>{{ data.inActivity ? "Yes" : "No" }}</td>
              </tr>
            </tbody>
          </table>
        </td>
        <td class="wp-10">
          <button
            mat-button
            class="fw-bold text-primary no-box-shadow"
            type="button"
            (click)="onDownloadClick()"
            disableRipple
          >
            <mat-icon>picture_as_pdf</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div>
    <mat-table #table [dataSource]="dataSource" aria-describedby="Return Table">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="tradingName">
        <mat-header-cell *matHeaderCellDef> Trading Name </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.tradingName }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="taxTreatment">
        <mat-header-cell *matHeaderCellDef> Tax Treatment</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.taxTreatment }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="verificationNo">
        <mat-header-cell *matHeaderCellDef> Verification No. </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.verificationNo }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="rate">
        <mat-header-cell *matHeaderCellDef> Rate(%) </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.rate | numberPipe }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="totalPayments">
        <mat-header-cell *matHeaderCellDef> Total Payments</mat-header-cell>
        <mat-cell *matCellDef="let element">
          £ {{ element.totalPayment | numberPipe }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>{{
          totalNetPayment | numberPipe
        }}</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="costOfMaterials">
        <mat-header-cell *matHeaderCellDef>Cost Of Materials </mat-header-cell>
        <mat-cell *matCellDef="let element">
          £ {{ element.costofMaterial | numberPipe }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>{{
          totalMaterialAmount | numberPipe
        }}</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="totalDeducted">
        <mat-header-cell *matHeaderCellDef> Total Deducted </mat-header-cell>
        <mat-cell *matCellDef="let element">
          £ {{ element.totalDeducted | numberPipe }}
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>{{
          totalCisDeduction | numberPipe
        }}</mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="export">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element"
          ><button
            mat-button
            title="Download this subcontractor report in PDF Format"
            class="fw-bold text-primary no-box-shadow"
            type="button"
            (click)="onSubContractorReportDownloadClick(element)"
            disableRipple
          >
            <mat-icon>picture_as_pdf</mat-icon></button
          ><button
            mat-button
            title="Email this subcontractor report"
            class="fw-bold text-primary no-box-shadow"
            type="button"
            (click)="SendMail(element)"
            disableRipple
          >
            <mat-icon>mail</mat-icon>
          </button>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
      </ng-container>
      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <mat-footer-row
        *matFooterRowDef="displayedColumns; sticky: true"
        [hidden]="noDataFound"
      ></mat-footer-row>
    </mat-table>
    <div *ngIf="noDataFound" class="text-align-center mt-1 fw-bold">
      No Data Found
    </div>
  </div>
  <div class="mt-2">
    <p>Declarations</p>
    <p>
      The information given on this return is correct and complete to the best
      of my knowledge and belief.
    </p>
  </div>
</div>
