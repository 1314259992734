import { DatePipe } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  ActionType,
  ConfirmationType,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { MainListParameters } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetBankDetail,
  MenuState,
  RefreshBankAccountList,
} from '@app/core/Store';
import {
  ConfirmationBoxComponent,
  ReconfirmPopupComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-bank-feed',
  templateUrl: './bank-feed.component.html',
  styleUrls: ['./bank-feed.component.scss'],
})
export class BankFeedComponent implements OnInit {
  formReconciliationDetail: UntypedFormGroup;
  reconciliationDetailArray: any;
  reconciliationData: any[];
  accountList: any[] = [];
  tempAccountGroupList: any[] = [];
  reconciliationList: any[] = [];
  accountId = Guid.EMPTY as unknown as Guid;
  listParameters: MainListParameters = new MainListParameters();
  accountListValue = '-1';
  showAddButton = false;
  isExpanded = true;
  isExpired: any;
  isCustom = true;
  customId: any;
  datepipe: DatePipe;
  accountGroupAndTypeId: any;
  selectedRowIndex = -1;

  actions: any = [];

  ids: Array<Guid>;

  displayReconciliationColumns_v1: string[] = [
    'bankProvider',
    'daysToReconfirm',
    'from',
    'accountNumber',
    'type',
    'feedsStartDate',
    'bankAccountName',
  ];
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  constructor(
    public dialog: MatDialog,
    private store: Store,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.getList();

    this.actions = [
      {
        name: 'Refresh',
        actionType: ActionType.Refresh,
        icon: 'cached',
      },
      {
        name: 'Reconfirm',
        actionType: ActionType.Reconfirm,
        icon: 'task_alt',
      },
      {
        name: 'Delete',
        actionType: ActionType.Delete,
        icon: 'delete_outline',
      },
    ];
  }

  //onButtonClick(element: any, actionType: ActionType): void {}
  onButtonClick(element: any, actionType: ActionType): void {
    let id = element.id;

    this.ids = [];
    this.ids.push(id);

    switch (actionType) {
      case ActionType.Delete:
        this.onDeleteClick(id);
        break;
      case ActionType.Refresh:
        this.onAccountRefresh(element);
        break;
      case ActionType.Reconfirm:
        const params = {
          customId: btoa(element.accountId),
        };
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = params;
        this.dialog
          .open(ReconfirmPopupComponent, dialogConfig)
          .afterClosed()
          .subscribe((result) => {
            this.getList();
          });
        break;
    }
  }
  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
  }

  onToggleMatMenu(i): void {
    this.selectedRowIndex = i;
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      startDate: null,
      endDate: null,
    };
    return queryParams;
  }

  getList(): void {
    this.spinner.show();
    this.store.dispatch(new GetBankDetail()).subscribe((res) => {
      this.reconciliationList = res.bankReconciliation.allBankAccount;
    });
  }

  resetAccountList(element: any): void {
    this.accountList = this.tempAccountGroupList;
    this.scrollIntoView(element.accountValue);
  }

  onActionChange(event: any, data: any): void {}

  getOptionText(option) {
    return option?.name;
  }

  onCloseClick(element: any): void {
    element.isAdd = false;
    element.isSelected = false;
    if (element.accountId === (Guid.EMPTY as unknown as Guid)) {
      element.accountValue = '';
    } else {
      this.accountList.forEach((items) => {
        const data = items.listModels.find(
          (x: any) => x.id === element.accountId
        );
        if (data !== null && data !== undefined) {
          element.accountValue = data;
        }
      });
    }
  }

  scrollIntoView(element) {
    if (element.accountValue !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  onDeleteClick(id: any): void {
    this.ids = [];
    this.ids.push(id);

    this.dialog
      .open(ConfirmationBoxComponent, {
        data: {
          ids: this.ids,
          type: ConfirmationType.Delete,
          moduleId: Modules.BankFeed,
          totalNumberOfRecordSelected: this.ids.length,
          headerText: NotificationHeader.deleteConfirmation,
          detailText: NotificationDetails.deleteAllDetailText,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.getList();
        }
      });
  }

  onAccountRefresh(element: any): void {
    this.store
      .dispatch(new RefreshBankAccountList(element.accountId))
      .pipe(
        tap((res) => {
          if (res) {
            this.commonService.onSuccess(
              NotificationTextMessage.successMessage
            );
          } else {
            this.commonService.onFailure(
              'Server is busy, please try again later.We sincerely apologize for the inconvenience caused to you.'
            );
          }
        })
      )
      .subscribe();
  }
}
