import { Injectable } from '@angular/core';
import { FinancialData, HighLightModel } from '@app/core/Models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HighlightRow {
  mainListHighlighted: HighLightModel;
  sideListHighlighted: HighLightModel;
  financialData: Array<FinancialData>;
  departmentData: Array<any>;
  branchData: Array<any>;
  defaultColor: Subject<string>;

  constructor() {
    this.financialData = new Array<FinancialData>();
    this.departmentData = new Array<any>();
    this.branchData = new Array<any>();
    this.sideListHighlighted = new HighLightModel();
    this.mainListHighlighted = new HighLightModel();
    this.defaultColor = new Subject<string>();
  }
}
