import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MaxLength } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-budgeting-calculation-popup',
  templateUrl: './budgeting-calculation-popup.component.html',
  styleUrls: ['./budgeting-calculation-popup.component.scss'],
})
export class BudgetingCalculationPopupComponent implements OnInit {
  budgetingCalculationForm: FormGroup;
  budgetingTypeList: any[] = [];
  maxLength = MaxLength;

  constructor(
    private calAmountPopup: MatDialogRef<BudgetingCalculationPopupComponent>,
    private store: Store,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.budgetingTypeList = this.commonService.budgetingCalculation;
    this.setForm();
  }

  setForm(): void {
    this.budgetingCalculationForm = new FormGroup({
      calculationType: new FormControl(''),
      amount: new FormControl(0),
    });

    this.budgetingCalculationForm.controls.calculationType.setValue(
      this.budgetingTypeList[0].id
    );
  }

  onCancel(): void {
    this.calAmountPopup.close();
  }

  onSave(): void {
    const param = {
      calculationType:
        this.budgetingCalculationForm.controls.calculationType.value,
      amount: this.budgetingCalculationForm.controls.amount.value,
    };
    this.calAmountPopup.close(param);
  }
}
