import {
  CISReturn,
  CISReturnDetail,
  ContractorSettings,
  MainListParameters,
  SubmitCisReturn,
} from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class SaveSubContractorDetails {
  static readonly type = '[CIS] Save Sub Contractor Details';

  constructor(public saveSubContractorDetailsData: CISReturnDetail) {}
}

export class SaveCISReturnDetails {
  static readonly type = '[CIS] Save CIS Return Details';

  constructor(public saveCISReturnDetailsData: CISReturn) {}
}

export class SubmitCISReturn {
  static readonly type = '[CIS] Submit CIS Return';

  constructor(public submitCISReturnData: SubmitCisReturn) {}
}

export class SaveHMRCGatewayDetails {
  static readonly type = '[CIS]  Save HMRC Gateway Details';

  constructor(public saveHMRCGatewayDetailsData: any) {}
}

export class GetCIS300Details {
  static readonly type = '[CIS]  Get CIS 300 Details';
  constructor(public id: Guid) {}
}

export class DeleteCIS300 {
  static readonly type = '[CIS] Delete Selected CIS 300';

  constructor(public cis300Ids: Array<Guid>) {}
}

export class GetNextPeriod {
  static readonly type = '[CIS] Get Next Period';
}

export class GetSubContractorDetailsList {
  static readonly type = '[CIS]  Get Sub Contractor Details List';
  constructor(
    public queryParams: MainListParameters,
    public cisReturnId: Guid
  ) {}
}

export class SaveVerifyData {
  static readonly type = '[CIS] Save Verify Data';

  constructor(public saveVerifyData: any) {}
}

export class SaveContratorSetting {
  static readonly type = '[CONTRACTORSETTING]  Save Contrator Setting';
  constructor(public contractorSettingsData: ContractorSettings) {}
}

export class GetContratorSetting {
  static readonly type = '[CONTRACTORSETTING]  Get Contrator Setting';
}

export class ExportCisReturn {
  static readonly type = '[CIS] Export CIS Return';

  constructor(public ids: Array<Guid>) {}
}

export class ExportSubContractorReturn {
  static readonly type = '[CIS] Export SubContractor Return';

  constructor(
    public cisReturnId: Guid,
    public contactId: Guid,
    public cisReturnDetailId: Guid
  ) {}
}

export class GetCISMonthlyReturn {
  static readonly type = '[CIS]  Get CIS Monthly Return';
  constructor(public id: Guid) {}
}

export class SendEmailCis300Verification {
  static readonly type = '[CISREPORT] Send Email Cis300Verification';

  constructor(
    public attachmentParams: any,
    public toMailId: string,
    public ccMailId: string,
    public bccMailId: string,
    public message: string,
    public subject: string
  ) {}
}

export class GetHMRCCredentials {
  static readonly type = '[CIS] Get HMRC Credentials';
}
