import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { TicketDialogComponent } from './common/ticket-dialog/ticket-dialog.component';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent implements OnInit {
  constructor(private spinner: NgxSpinnerService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.spinner.hide();
  }

  onAddClick(): void {
    this.dialog
      .open(TicketDialogComponent)
      .afterClosed()
      .subscribe((result) => {});
  }
}
