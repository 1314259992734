import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AccountTypeImport,
  FileImportRequestModel,
  Modules,
} from '@app/core/Enum';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonService } from '../../common/common.service';

@Injectable()
export class ImportService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getImportTemplate(moduleId: number): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}common/getImportTemplate/${moduleId}`,
        headers
      )
      .pipe(
        switchMap((response) => {
          this.commonService.download(response);

          return of(true);
        })
      );
  }

  getImportModuleServiceName(moduleId: number): string {
    let serviceName;

    switch (moduleId) {
      case Modules.Invoices:
        serviceName = 'invoice';
        break;

      case Modules.Purchase:
        serviceName = 'purchase';
        break;

      case Modules.CreditNote:
        serviceName = 'creditNote';
        break;

      case Modules.DebitNote:
        serviceName = 'debitNote';
        break;

      case Modules.Customers:
        serviceName = 'account/' + AccountTypeImport.Customer;
        break;

      case Modules.Suppliers:
        serviceName = 'account/' + AccountTypeImport.Supplier;
        break;

      case Modules.TrialBalance:
      case Modules.OpeningBalance:
        serviceName = 'openingBalance/' + moduleId;
        break;

      case Modules.Product:
        serviceName = 'Product';
        break;

      case Modules.Dividends:
        serviceName = 'dividend';
        break;

      case Modules.Director:
        serviceName = 'DirectorShareholder/director';
        break;

      case Modules.Shareholder:
        serviceName = 'DirectorShareholder/shareholder';
        break;

      case Modules.BridgingVAT:
        serviceName = 'bridgingVat';
        break;

      case Modules.FixedAssets:
        serviceName = 'fixedAsset';
        break;

      case Modules.CIS:
        serviceName = 'CisReport';
        break;
      case Modules.QuickEntry:
        serviceName = 'QuickEntry';
        break;

      case Modules.Journals:
        serviceName = 'Journal';
        break;
    }

    return serviceName;
  }

  importModules(
    fileImportRequestModel: FileImportRequestModel,
    moduleId: number
  ): Observable<boolean> {
    const formData = new FormData();

    formData.append('file', fileImportRequestModel.file);
    formData.append('step', fileImportRequestModel.step.toString());

    return this.http.post<boolean>(
      `${environment.apiVersionUrl}${this.getImportModuleServiceName(
        moduleId
      )}/import`,
      formData
    );
  }

  saveImport(param: any, moduleId: number): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}${this.getImportModuleServiceName(
        moduleId
      )}/saveImport`,
      JSON.stringify(param),
      headers
    );
  }

  dateValidation(date: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}AccountingPeriod/dateBetweenAccountingPeriod`,
      JSON.stringify(date),
      headers
    );
  }

  checkAppoinmentDate(date: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}DirectorShareholder/checkAppoinmentDate`,
      JSON.stringify(date),
      headers
    );
  }

  getBankImportTemplate(): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.commonService
      .getExportRequest(
        `${environment.apiVersionUrl}BankEntry/GetImportTemplate`,
        headers
      )
      .pipe(
        switchMap((response) => {
          this.commonService.download(response);

          return of(true);
        })
      );
  }

  importBank(
    fileImportRequestModel: FileImportRequestModel,
    customId: number
  ): Observable<boolean> {
    const formData = new FormData();

    formData.append('file', fileImportRequestModel.file);
    formData.append('step', fileImportRequestModel.step.toString());

    return this.http.post<boolean>(
      `${environment.apiVersionUrl}BankEntry/Import/${customId}`,
      formData
    );
  }
}
