import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-journal',
  templateUrl: './add-journal.component.html',
  styleUrls: ['./add-journal.component.scss'],
})
export class AddJournalComponent {
  constructor(public dialogRef: MatDialogRef<AddJournalComponent>) {}

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
