import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  DiscountType,
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { ProductDetailsModel, RecurringModule } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateRecurringModule,
  GetDataByRecurringModuleId,
  GetRecurringNumber,
  MenuState,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  AddRecurringModuleComponent,
  ProductDetailsComponent,
  ProductDetailsFooterComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-recurring-module',
  templateUrl: './recurring-module.component.html',
  styleUrls: ['./recurring-module.component.scss'],
})
export class RecurringModuleComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isSales:boolean;
  recurringId = Guid.EMPTY as unknown as Guid;
  invoiceId = Guid.EMPTY as unknown as Guid;

  amount = 0;
  balanceAmount = 0;
  moduleId = 0;
  moduleName = '';

  recurringData: RecurringModule;
  productItems: Array<ProductDetailsModel>;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();
  @ViewChild(MatAccordion) accordion: MatAccordion;

  triggerEditData: Subject<any> = new Subject<any>();
  triggerBranchChange: Subject<any> = new Subject<any>();
  @ViewChild(AddRecurringModuleComponent, { static: true })
  recurringDetails;

  @ViewChild(ProductDetailsComponent, { static: true })
  productDetails;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  triggereEditProductData: Subject<any> = new Subject<any>();

  subscriptionRouting: Subscription;
  module = Modules;
  store: Store;
  commonService: CommonService;
  @ViewChild(ProductDetailsFooterComponent, { static: true })
  productDetailsFooter;
  triggerDiscountData: Subject<any> = new Subject<any>();
  triggerNewProductAdded: Subject<any> = new Subject<any>();
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  public defaultGuidValue = Guid.EMPTY as unknown as Guid;
  constructor(
    private _Activatedroute: ActivatedRoute,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private location: Location,
    private renderer: Renderer2,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        const currentUrl = this._Activatedroute.snapshot['_routerState'].url;

        if (currentUrl.includes(RoutingPath.AddRecurringPurchase)) {
          this.moduleId = Modules.RecurringPurchase;
          this.moduleName = ModuleName.RecurringPurchase;
          this.isSales=false;
        } else {
          this.moduleId = Modules.RecurringInvoice;
          this.moduleName = ModuleName.RecurringInvoice;
          this.isSales=true;
        }

        if (params.keys.length > 0) {
          this.recurringId = atob(params.get('id')!) as unknown as Guid;
          this.editRecurringData();
        } else {
          this.getRecurringNumber(this.moduleId);
        }
      }
    );
  }

  onAddedNewProductDetails(selectedOption: any) {
    this.triggerNewProductAdded.next(selectedOption);
  }

  getRecurringNumber(moduleId): void {
    this.store.dispatch(new GetRecurringNumber(moduleId)).subscribe((res) => {
      res.recurring.recurringNo !== null
        ? this.recurringDetails.recurringForm.controls.recurringNo.disable()
        : this.recurringDetails.recurringForm.controls.recurringNo.enable();
      this.recurringDetails.recurringForm.controls.recurringNo.setValue(
        res.recurring.recurringNo
      );
    });
  }

  editRecurringData(): void {
    this.store
      .dispatch(new GetDataByRecurringModuleId(this.recurringId))
      .subscribe((res) => {
        this.recurringDetails.recurringForm.markAsUntouched();
        this.productDetails.formProductDetail.markAsUntouched();

        this.recurringDetails.isReadOnly = true;
        setTimeout(() => {
          this.triggerEditData.next(res.recurring.recurringData);

          const data = {
            productData: res.recurring.recurringData.items,
          };
          this.triggereEditProductData.next(data);
        }, 500);
        let discountData = {
          discountValue: res.recurring.recurringData.discount,
          discountType: res.recurring.recurringData.isDiscountPercentage
            ? DiscountType.Percentage
            : DiscountType.Value,
        };
        this.triggerDiscountData.next(discountData);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  reloadAutoNumberGenerator(): void {
    this.getRecurringNumber(this.moduleId);
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  triggerReloadSideList(): void {
    this.reloadSideList.emit();
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.recurringId,
      isExit,
      this.moduleId === Modules.RecurringInvoice
        ? Modules.RecurringInvoice
        : Modules.RecurringPurchase,
      this.moduleId === Modules.RecurringInvoice
        ? RoutingPath.AddRecurringInvoice
        : RoutingPath.AddRecurringPurchase
    );
  }

  onSave(isExit: boolean): void {
    if (this.recurringDetails.recurringForm.invalid) {
      this.commonService.addValidation(
        this.recurringDetails.recurringForm,
        this.renderer
      );
    } else if (this.productDetails.productDetailArray.invalid) {
      this.productDetails.productDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(
            new CreateRecurringModule(this.recurringData, this.moduleId)
          )
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.productItems = new Array<ProductDetailsModel>();

      this.productDetails.productDetailArray?.getRawValue().forEach((x) => {
        const data = this.productDetails.productTypeData.filter(
          (a) => a.name === x.productName
        );
        if (data.length > 0) {
          x.productId = data[0].id;
        }

        this.productItems.push({
          id: x.id,
          productId: x.productName ? x.productName : this.defaultGuidValue,
          description: x.description,
          quantity: +x.qty,
          price: +x.price,
          vatRateId: x.vatRate === -1 ? null : x.vatRate,
          vatAmount: +x.vatAmount,
          totalAmount: +(+x.amount).toFixed(2),
          discount: +x.discountValue,
          isDiscountPercentage: x.discountType === DiscountType.Percentage,
          discountDescription: x.discountDescription,
          bookAccountId: x.invoiceType?.id,
        });
      });

      this.recurringData = {
        id: this.recurringId,
        entryNumber:
          this.recurringDetails.recurringForm.controls.recurringNo.value,
        accountId: this.recurringDetails.recurringForm.controls.accountId.value,
        currencyId: this.recurringDetails.recurringForm.controls.currency.value,
        dueTermId:
          this.recurringDetails.recurringForm.controls.dueTermName.value,
        note: this.recurringDetails.recurringForm.controls.notes.value,
        //bookAccountId:
        //  this.recurringDetails.recurringForm.controls.invoiceType.value.id,
        startDate: this.datepipe
          .transform(
            this.recurringDetails.recurringForm.controls.startDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),
        endDate: this.datepipe
          .transform(
            this.recurringDetails.recurringForm.controls.endDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),

        maxNumOfOccurrences:
          +this.recurringDetails.recurringForm.controls.maxNumOfOccurrences
            .value,
        recurringTypeId:
          this.recurringDetails.recurringForm.controls.recurringType.value,
        dayOfWeek: this.recurringDetails.recurringForm.controls.dayOfWeek.value,
        dayOfMonth:
          this.recurringDetails.recurringForm.controls.dayOfMonth.value,
        weekOfMonth:
          this.recurringDetails.recurringForm.controls.monthOfYear.value,
        monthOfYear:
          this.recurringDetails.recurringForm.controls.monthOfYear.value,

        separationCount:
          +this.recurringDetails.recurringForm.controls.separationCount.value,
        recurringEndTypeId:
          this.recurringDetails.recurringForm.controls.scheduleType.value,
        subRecurringTypeId:
          this.recurringDetails.recurringForm.controls.scheduleSubPeriod.value,
        items: this.productItems,
        branchId:
          this.recurringDetails.recurringForm.controls.branchId.value === ''
            ? null
            : this.recurringDetails.recurringForm.controls.branchId.value,
        departmentId:
          this.recurringDetails.recurringForm.controls.departmentId.value === ''
            ? null
            : this.recurringDetails.recurringForm.controls.departmentId.value,
        discount: this.productDetailsFooter.discountValue,
        isDiscountPercentage:
          this.productDetailsFooter.discountType === DiscountType.Percentage,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.invoiceId)) {
      this.editRecurringData();
    } else {
      this.invoiceId = Guid.EMPTY as unknown as Guid;
      this.recurringDetails.ngOnInit();
      this.productDetails.ngOnInit();
      this.getRecurringNumber(this.moduleId);
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  onBranchChange(selectedOption: any) {
    this.triggerBranchChange.next(selectedOption);
  }
}
