<div class="content profit-loss">
  <div class="content-body content-body-scroll">
    <div class="mb-15 d-flex flex-column gap-5">
      <app-transactions-header
        (triggerDataFromHeader)="getDataFromHeader($event)"
        [listParameters]="listParameters"
      >
      </app-transactions-header>

      <div class="header-actions wp-100">
        <app-header-actions
          (triggerDataFromHeader)="getDataFromHeader($event)"
          [listParameters]="listParameters"
          [parentModuleId]="moduleId"
          (exportClick)="exportReport($event, false)"
        >
        </app-header-actions>
      </div>
    </div>
    <div>
      <p class="fw-bold mb-10 mt-10 font-size-20">
        {{ moduleName }}
      </p>
    </div>
    <ejs-grid #grid [dataSource]="data" (queryCellInfo)="queryCellInfo($event)">
      <e-columns>
        <e-column field="account" headerText="Account" textAlign="Left">
          <ng-template #template let-row let-rowIndex>
            <div
              *ngIf="
                row?.id !== '' &&
                row?.id !== null &&
                row?.id !== undefined &&
                row?.id !== commonService.defaultGuidValue
              "
              title="{{ row?.name }}"
              class="ml-2"
              [ngClass]="row?.isHeader ? 'fw-bold' : ''"
            >
              <a
                class="link wmx-100 clamp"
                (click)="redirect(row?.id, modules.AddCustomAccounts)"
              >
                {{ row.name }}
              </a>
            </div>
            <span
              *ngIf="row?.isHeader || row?.isTotal"
              class="fw-bold"
              [ngClass]="row?.isTotal ? 'ml-2' : ''"
            >
              {{ row?.name }}
            </span>
          </ng-template>
        </e-column>
        <e-column field="amount" headerText="" textAlign="right">
          <ng-template #template let-row let-rowIndex>
            <span
              *ngIf="row?.amount != 0"
              [ngClass]="
                row?.alignment !== undefined && row?.alignment !== null
                  ? row?.alignment
                  : ''
              "
            >
              £ {{ row.amount | numberPipe }}
            </span>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </div>
</div>
