import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DirectorShareholderModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DirectorShareholderService {
  constructor(private http: HttpClient) {}

  createDirectorShareholder(data: DirectorShareholderModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}DirectorShareholder/save`,
      JSON.stringify(data),
      headers
    );
  }

  getDataByDirectorShareholderId(
    directorShareholderId: Guid
  ): Observable<DirectorShareholderModel> {
    return this.http.get<DirectorShareholderModel>(
      `${environment.apiVersionUrl}DirectorShareholder/get/${directorShareholderId}`
    );
  }

  archiveAndRestoreDirectorShareholder(
    directorShareholderId?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}DirectorShareholder/archive/${isArchive}`,
      JSON.stringify(directorShareholderId),
      options
    );
  }

  deleteDirectorShareholderData(
    directorShareholderId?: Array<Guid>
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(directorShareholderId),
    };

    return this.http.delete<any>(
      `${environment.apiVersionUrl}DirectorShareholder/delete`,
      options
    );
  }

  copyDirectorShareholderData(
    directorShareholderId?: Array<Guid>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}DirectorShareholder/copy`,
      JSON.stringify(directorShareholderId),
      headers
    );
  }
}
