import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  ChartOfAccountCreditorsGroupTypeName,
  GroupNames,
  NotificationTextMessage,
} from '@app/core/Enum';
import { AccountNumber } from '@app/core/Enum/account-number';
import { ChartOfAccountListParam, GlobalComponent } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetGroupCustomBankAndCashAccount,
  UpdatePaymentStatus,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-vat-payment-popup',
  templateUrl: './vat-payment-popup.component.html',
  styleUrls: ['./vat-payment-popup.component.scss'],
})
export class VatPaymentPopupComponent implements OnInit {
  vatId: any;
  vatPaymentForm: FormGroup;
  isPostJournal: boolean = false;
  periodicDate: any;
  receiptAccountList: any[] = [];
  tempReceiptAccountList: any[];
  notificationMessage = NotificationTextMessage;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private renderer: Renderer2,
    private store: Store,
    public datepipe: DatePipe,
    public commonService: CommonService,
    public dialogRef: MatDialogRef<VatPaymentPopupComponent>,
    private globalComponent: GlobalComponent
  ) {}

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setForm();
    this.vatId = this.data.id;
    this.vatPaymentForm.controls.vatAmount.setValue(this.data.netVatDue);
  }

  setForm(): void {
    this.vatPaymentForm = new FormGroup({
      paymentDate: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      reference: new FormControl(''),
      postJournal: new FormControl(false),
      accountId: new FormControl(''),
      vatAmount: new FormControl({ value: 0, disabled: true }),
    });
    this.onAccountTypeChange();
  }

  onAccountTypeChange(): void {
    let actionName;

    actionName = GetGroupCustomBankAndCashAccount;

    this.vatPaymentForm.controls.accountId.setValue('');

    this.store
      .dispatch(new actionName())
      .pipe(
        switchMap((res) => {
          this.receiptAccountList = [];
          this.receiptAccountList = this.commonService.addGroupNameToListModels(
            res.common.customBankAndCashAccountGroupList
          );
          this.tempReceiptAccountList = this.receiptAccountList;

          const param: ChartOfAccountListParam = {
            groupIds: [GroupNames.Creditors],
            typeIds: [
              ChartOfAccountCreditorsGroupTypeName.CreditorsLessThanOneYearTaxationAndSocialSecurity,
            ],
          };

          return this.store.dispatch(
            new GetGroupAccountsBasedOnGroupIdAndTypeId(param)
          );
        }),
        switchMap((secondRes) => {
          if (secondRes.common.accountGroupList.length > 0) {
            let accounts = this.commonService.addGroupNameToListModels(
              secondRes.common.accountGroupList
            );

            accounts = accounts.filter(
              (item) => item.name === AccountNumber.ACCOUNT_8214
            );
            this.receiptAccountList = this.receiptAccountList.concat(accounts);
            this.tempReceiptAccountList = this.receiptAccountList;
          }
          return of(secondRes);
        })
      )
      .subscribe();
  }

  onCheckBoxSelected(event: any): void {
    this.isPostJournal = event.checked;
    if (this.isPostJournal) {
      this.vatPaymentForm.get('accountId')?.setValidators(Validators.required);
      this.vatPaymentForm.get('accountId')?.updateValueAndValidity();
    } else {
      this.vatPaymentForm.get('accountId')?.clearValidators();
      this.vatPaymentForm.get('accountId')?.updateValueAndValidity();
    }
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.vatPaymentForm.invalid) {
      this.commonService.addValidation(this.vatPaymentForm, this.renderer);
    } else {
      this.store
        .dispatch(new UpdatePaymentStatus(this.dataSubmit()))
        .pipe()
        .subscribe(
          (res) => {
            if (res !== undefined) {
              this.onCloseClick();
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.message);
          }
        );
    }
  }

  dataSubmit(): any {
    let data = {
      id: this.vatId,
      paymentDate: this.datepipe
        .transform(this.vatPaymentForm.controls.paymentDate.value, 'yyyy-MM-dd')
        ?.toString(),
      description: this.vatPaymentForm.controls.reference.value,
      isPostJournal: this.vatPaymentForm.controls.postJournal.value,
      fromAccount:
        this.vatPaymentForm.controls.accountId.value !== undefined &&
        this.vatPaymentForm.controls.accountId.value !== null &&
        this.vatPaymentForm.controls.accountId.value !== ''
          ? this.vatPaymentForm.controls.accountId.value
          : null,
    };

    return data;
  }
}
