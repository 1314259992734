export enum CISBusinessType {
  Company = '1',
  'Sole Trader' = '2',
  Partnership = '3',
  Trust = '4',
}
export enum CISTraderType {
  'Trading Name' = '1',
  'Personal Name' = '2',
}
export enum CISVerifyType {
  Online = '1',
  'Already Verified' = '2',
}
export enum CISTaxTreatmentType {
  Unmatched = 1,
  Gross = 2,
  Net = 3,
}

export enum CisSubcontractorAction {
  Verify = 1,
  Match = 2,
}
