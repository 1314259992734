import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileImportRequestModel } from '@app/core/Enum/import';
import { SubmitVATModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BridgingVatService {
  constructor(private http: HttpClient) {}

  importVatBridging(
    fileImportRequestModel: FileImportRequestModel
  ): Observable<boolean> {
    const formData = new FormData();

    formData.append('file', fileImportRequestModel.file);
    formData.append('step', fileImportRequestModel.step.toString());

    return this.http.post<boolean>(
      `${environment.apiVersionUrl}MTD/bridgingVat/import`,
      formData
    );
  }

  saveBridgingVAT(data: SubmitVATModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}MTD/bridgingVat/save`,
      JSON.stringify(data),
      headers
    );
  }
}
