<div class="mat-dialog-wrapper link-account">
  <h2 mat-dialog-title>Link Bank Account</h2>
  <button
    class="close-button"
    type="button"
    (click)="onCloseClick()"
    mat-button
    disableRipple
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    <div class="stepper-wrapper">
      <mat-horizontal-stepper linear #horizontalStepper>
        <mat-step label="1">
          <ng-template matStepLabel>Bank Account </ng-template>
          <div class="content-body">
            <form [formGroup]="linkBankForm">
              <div class="d-flex gap-40">
                <div class="form-field">
                  <p>Link Bank Account</p>
                  <ng-select
                    #accountList
                    (focus)="accountList.open()"
                    (valueChange)="accountList.close()"
                    [disableOptionCentering]="true"
                    formControlName="bankAccountId"
                    panelClass="mat-select-position"
                    appendTo="body"
                  >
                    <ng-option
                      *ngFor="let al of bankAccountList"
                      [value]="al.id"
                    >
                      <div class="clamp" title="{{ al.name }}">
                        {{ al.name }}
                      </div>
                    </ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="d-flex gap-40">
                <div class="form-field m-0">
                  <mat-checkbox formControlName="checkAgree">
                    <span class="text"
                      >I agree to the
                      <a
                        class="link"
                        target="_blank"
                        href="https://truelayer.com/enduser_tos/"
                        >terms and conditions.</a
                      ></span
                    >
                  </mat-checkbox>
                </div>
              </div>
            </form>
          </div>
        </mat-step>
        <mat-step label="2" label="Bank / Provider" [disabled]="isEditable">
          <ng-template matStepLabel>Bank / Provider</ng-template>
        </mat-step>
        <mat-step label="3" [disabled]="isEditable">
          <ng-template matStepLabel>Account Credentials</ng-template>
        </mat-step>
        <mat-step label="4" [disabled]="isEditable">
          <ng-template matStepLabel>Commit</ng-template>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="action-button primary-button mr-1"
      type="button"
      (click)="onAcceptClick()"
      mat-button
      disableRipple
    >
      Next
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
