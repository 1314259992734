<div class="d-flex h-100 mb-4">
  <div class="content-body content-body-scroll">
    <app-transactions-header
      (triggerCurrencyAdd)="isQuickAdd($event)"
      [listParameters]="listParameters"
    >
    </app-transactions-header>
    <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
      <mat-icon class="material-icons-outlined">error_outline</mat-icon>
      {{ commonNotificationText.ViewOnlyAccessMessage }}
    </div>
    <div class="accordion-toggle">
      <mat-icon
        (click)="toggleAccordion(); accordion.openAll()"
        *ngIf="isExpandAll"
        >keyboard_double_arrow_down
      </mat-icon>
      <mat-icon
        (click)="toggleAccordion(); accordion.closeAll()"
        *ngIf="!isExpandAll"
        >keyboard_double_arrow_up
      </mat-icon>
    </div>
    <mat-accordion [multi]="true">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-icons-outlined" class="text-primary"
              >description</mat-icon
            >
            <div class="d-flex flex-column">
              <span>Currency Details</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div
          class="content-body"
          [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
        >
          <div class="mat-table-wrapper">
            <table
              mat-table
              [dataSource]="currencyList"
              aria-describedby="Currency Table"
            >
              <ng-container matColumnDef="currency">
                <th mat-header-cell *matHeaderCellDef>Currency</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ element.currency }}
                </td>
              </ng-container>
              <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef>Code</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ element.code }}
                </td>
              </ng-container>
              <ng-container matColumnDef="symbol">
                <th mat-header-cell *matHeaderCellDef>Symbol</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ element.symbol }}
                </td>
              </ng-container>
              <ng-container matColumnDef="rate">
                <th mat-header-cell *matHeaderCellDef>Rate</th>

                <td mat-cell *matCellDef="let element; let i = index">
                  <div *ngIf="!element.isSave">
                    {{ element.rate }}
                  </div>

                  <div *ngIf="element.isSave">
                    <mat-form-field appearance="fill">
                      <input
                        type="number"
                        matInput
                        [(ngModel)]="element.rate"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </mat-form-field>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="w-50">Action</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  class="text-align-center"
                >
                  <div class="d-flex gap-5">
                    <button
                      *ngIf="
                        element.isRefresh || element.isRefresh === undefined
                      "
                      class="close-btn"
                      type="button"
                      mat-button
                      disableRipple
                      (click)="onSingleCurrencyRefresh(element, i)"
                      [disabled]="isViewPermission$ | async"
                    >
                      <mat-icon
                        class="font-size-20"
                        fontSet="material-icons-outlined"
                        >refresh</mat-icon
                      >
                    </button>
                    <button
                      *ngIf="element.isSave && !isTotalCurrencyRefresh"
                      class="close-btn"
                      type="button"
                      mat-button
                      disableRipple
                      (click)="onSingleCurrencySave(element, i)"
                      [disabled]="isViewPermission$ | async"
                    >
                      <mat-icon
                        class="font-size-20"
                        fontSet="material-icons-outlined"
                        >save</mat-icon
                      >
                    </button>
                    <button
                      class="close-btn"
                      type="button"
                      mat-button
                      disableRipple
                      (click)="onCurrencyDelete(element)"
                      [disabled]="isViewPermission$ | async"
                    >
                      <mat-icon
                        class="font-size-20"
                        fontSet="material-icons-outlined"
                        >delete_outline</mat-icon
                      >
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedCurrencyColumns; sticky: true"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedCurrencyColumns"
              ></tr>
            </table>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div
  class="action-wrapper button-action-wrapper"
  *ngIf="!(isViewPermission$ | async)"
>
  <div class="content-body">
    <button
      *ngIf="isMultiCurrencyRefresh && currencyList.length > 0"
      class="action-button primary-button refresh-btn-algn"
      type="button"
      mat-button
      disableRipple
      (click)="onTotalClientCurrencyRefresh()"
    >
      <mat-icon>refresh</mat-icon>
      Latest Rate
    </button>

    <button
      *ngIf="!isMultiCurrencyRefresh"
      class="action-button primary-button save-btn-algn mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onTotalCurrencySave()"
    >
      <mat-icon>save</mat-icon>
      Save
    </button>
    <button
      *ngIf="showCancelBtnTimes > 0"
      class="action-button primary-button cancel-btn-algn"
      type="button"
      mat-button
      disableRipple
      (click)="onCancel()"
    >
      <mat-icon>cancel</mat-icon>
      Cancel
    </button>
  </div>
</div>
