import { Component } from '@angular/core';
export interface Periodictime {
  id: string;
  name: string;
  contact: string;
  tasks: string;
  jobs: string;
}

const ELEMENT_DATA: Periodictime[] = [
  {
    id: 'CLI693',
    name: 'Abc Ltd',
    contact: 'abc@gmail.com',
    tasks: 'Bookkeeping (+1)',
    jobs: 'Job 1',
  },
  {
    id: 'CLI694',
    name: 'Abc Ltd',
    contact: 'abc@gmail.com',
    tasks: 'Bookkeeping (+1)',
    jobs: 'Job 1',
  },
];

@Component({
  selector: 'app-import-setting-details',
  templateUrl: './import-setting-details.component.html',
  styleUrls: ['./import-setting-details.component.scss'],
})
export class ImportSettingDetailsComponent {
  displayedColumns: string[] = ['id', 'name', 'contact', 'tasks', 'jobs'];
  dataSource = ELEMENT_DATA;
  dropdownList: any[] = [];
  selectedItems: any[] = [];

  ngOnInit(): void {
    this.dropdownList = [
      { item_id: 1, item_text: 'Customers' },
      { item_id: 2, item_text: 'Customers' },
      { item_id: 3, item_text: 'Customers' },
      { item_id: 4, item_text: 'Customers' },
      { item_id: 5, item_text: 'Customers' },
    ];
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
}
