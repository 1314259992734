import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Modules } from '@app/core/Enum';
import { ReceiptModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class ReceiptService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createReceipt(receipt: ReceiptModel): Observable<any> {
    let url;

    switch (receipt.moduleId) {
      case Modules.CashEntry:
        url = `CashEntry/save`;
        break;
      case Modules.BankEntry:
        url = `BankEntry/save`;
        break;
      default:
        url = `Receipt/save`;
        break;
    }

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}${url}`,
      this.commonService.trimObjectSpace(JSON.stringify(receipt)),
      headers
    );
  }

  getDataByReceiptId(
    receiptId: Guid,
    moduleId: number
  ): Observable<ReceiptModel> {
    let url;

    switch (moduleId) {
      case Modules.CashEntry:
        url = 'CashEntry/get';
        break;
      case Modules.BankEntry:
        url = `BankEntry/get`;
        break;
      default:
        url = `Receipt/Get`;
        break;
    }

    return this.http.get<ReceiptModel>(
      `${environment.apiVersionUrl}${url}/${receiptId}`
    );
  }

  getInvoiceReceipt(invoiceId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Invoice/getInvoice/${invoiceId}`
    );
  }
  getReceiptPayment(accountId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Receipt/getReceipts/${accountId}`
    );
  }

  getBillReceipt(billId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}purchase/getPurchase/${billId}`
    );
  }

  getBillBasedOnCustomer(supplierId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}purchase/all/supplier/${supplierId}`
    );
  }

  getInvoiceBasedOnCustomer(customerId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Invoice/all/customer/${customerId}`
    );
  }

  deleteReceipt(receiptIds: Array<Guid>, moduleId: number): Observable<any> {
    let url;

    switch (moduleId) {
      case Modules.CashEntry:
        url = 'CashEntry/Delete';
        break;
      case Modules.BankEntry:
        url = `BankEntry/Delete`;
        break;
      default:
        url = `Receipt/Delete`;
        break;
    }

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(receiptIds),
    };
    return this.http.delete<any>(`${environment.apiVersionUrl}${url}`, options);
  }

  copyReceipt(receiptIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Receipt/copyReceipt`,
      JSON.stringify(receiptIds),
      headers
    );
  }

  archiveAndRestoreReceipt(
    receiptIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Receipt/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(receiptIds)),
      options
    );
  }

  getBillBasedOnSupplier(supplierId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}common/all/supplier/${supplierId}`
    );
  }

  getBillDetail(billId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Common/getPurchase/${billId}`
    );
  }

  getBankAccountListForBankEntryAndCashEntry(entityId: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Account/groupAllFilteredAndNonStandard`,
      this.commonService.trimObjectSpace(JSON.stringify(entityId)),
      headers
    );
  }

  archiveAndRestoreCashEntry(
    cashEntryIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}CashEntry/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(cashEntryIds)),
      options
    );
  }

  archiveAndRestoreBankEntry(
    bankEntryIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}BankEntry/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(bankEntryIds)),
      options
    );
  }

  quickReceiptAdd(quickReceipt: any): Observable<Guid> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Guid>(
      `${environment.apiVersionUrl}Receipt/quickSave`,
      this.commonService.trimObjectSpace(JSON.stringify(quickReceipt)),
      headers
    );
  }
  getInvoicesBasedOnCustomerCurrency(quickReceipt: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Invoice/all/customercurrencyinvoices`,
      this.commonService.trimObjectSpace(JSON.stringify(quickReceipt)),
      headers
    );
  }
  getPurchasesBasedOnSupplierCurrency(quickReceipt: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}common/all/suppliercurrencyinvoices`,
      this.commonService.trimObjectSpace(JSON.stringify(quickReceipt)),
      headers
    );
  }
  getInvoicesDataBasedOnAccountAndCurrency(quickReceipt: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Invoice/getInvoiceData`,
      this.commonService.trimObjectSpace(JSON.stringify(quickReceipt)),
      headers
    );
  }
  getBillDataBasedOnAccountAndCurrency(quickpayment: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}common/getPurchaseData`,
      this.commonService.trimObjectSpace(JSON.stringify(quickpayment)),
      headers
    );
  }
  getCreditNoteInvoicesDataBasedOnCurrency(creditNote: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Invoice/getCreditNoteInvoiceData`,
      this.commonService.trimObjectSpace(JSON.stringify(creditNote)),
      headers
    );
  }
  getDebitNotePurchasesBasedOnCurrency(quickpayment: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}common/getDebitNotePurchaseData`,
      this.commonService.trimObjectSpace(JSON.stringify(quickpayment)),
      headers
    );
  }

  quickEditReceipt(receipt: ReceiptModel): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Receipt/quickEdit`,
      JSON.stringify(receipt),
      options
    );
  }

  allocateAdvanceReceipt(id: Guid, assignData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Receipt/allocateAdvanceReceipt/${id}`,
      this.commonService.trimObjectSpace(JSON.stringify(assignData)),
      headers
    );
  }
}
