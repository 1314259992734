import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Guid } from 'guid-typescript';


@Component({
  selector: 'app-discount-flat-rate',
  templateUrl: './discount-flat-rate.component.html',
  styleUrls: ['./discount-flat-rate.component.scss']
})
export class DiscountFlatRateComponent implements OnInit {
  productDetailForm: FormGroup;
  formProductDetail: UntypedFormGroup;
  productDetailArray: any;
  tableDataSource: MatTableDataSource<AbstractControl>;
  displayProductDetailsColumns: string[] = [
    'from',
    'to',
    'rate',
    
  ];

 @Input() vatReturnData:any;
 @Input() fromDate:any;
 @Input() toDate:any;
 @Input() editListData:any[];
 isEdit:boolean=false;

    @Output()
  readonly triggerGridUpdate = new EventEmitter<any>();
  constructor( private formBuilder: UntypedFormBuilder,
    public  datepipe: DatePipe,
    private _Activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
 

 //edit discount/flat rate grid start
            if(this.editListData.length){
                 let list:Array<any>=[];
            this.editListData.forEach(element => {

              let obj1={from:element.fromDate,to:element.toDate,rate:element.flateRate};
              list.push(obj1);
            
            });
                this.mainList=[];
                this.mainList=this.editListData;

                 this.renderGrid(list);
            }
      //edit discount/flat rate grid end
  }

  renderGrid(data: any): void {
    this.formProductDetail = new FormGroup({
      productDetailArray: new UntypedFormArray([]),
    });
    this.productDetailArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;

    this.productDetailArray.clear();

    data.forEach((item, i) => {
      this.productDetailArray.push(this.buildOrderItemsForm(item));
 
    });

    this.setDataSource(this.productDetailArray);
    this.triggerGridUpdate.emit(true);
  }

  buildOrderItemsForm(item: any): FormGroup {
    const form = this.formBuilder.group({
      from: this.datepipe
      .transform(
        item.from,
        'dd/MM/yyyy'
      ),
      to: this.datepipe
      .transform(
        item.to,
        'dd/MM/yyyy'
      ),
      rate: item.rate,
     
    });
    form.disable();
    
    return form;
  }
  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }
  mainList:any=[];
  setSubPeriodValues():void {
    
    let list:Array<any>=[];
    let from = new Date(this.vatReturnData.fromDate).getTime(), to = new Date(this.vatReturnData.toDate).getTime(),
    dfrom = new Date(this.vatReturnData.discountFromDate).getTime(), dto = new Date(this.vatReturnData.discountToDate).getTime();
    //vat period is normal vat period. vat period is not falling in discounted flat rate period.
    if ((from < dfrom && to < dfrom) || (from > dto && to > dto)) {

    }
    //whole vat period has only discounted flat rate
    if ((from >= dfrom && from <= dto) && (to >= dfrom && to <= dto)) {

    }
    else
        //VAT Period has two flat rate:1. Normal Flat Rate 2. Discounted Flat Rate
        if ((from <= dfrom && to >= dfrom && to <= dto)) {
          let todate=new Date(this.vatReturnData.discountFromDate).addDays(-1);
          let obj1={from:this.vatReturnData.fromDate,to:todate,rate:this.vatReturnData.flatRate};
          list.push(obj1);

          let obj2={from:this.vatReturnData.discountFromDate,to:this.vatReturnData.toDate,rate:this.vatReturnData.discountedRate};
          list.push(obj2);

           
        } 
            //VAT Period has two flat rate:1. Discounted Flat Rate 2. Normal Flat Rate
        else if (from >= dfrom && from <= dto && to > dto) {
          let obj1={from:this.vatReturnData.fromDate,to:this.vatReturnData.discountToDate,rate:this.vatReturnData.discountedRate};
          list.push(obj1);

          let from=new Date(this.vatReturnData.discountToDate).addDays(1);
          let obj2={from:from,to:this.vatReturnData.toDate,rate:this.vatReturnData.flatRate};
          list.push(obj2);

          
           
            
        }else{
          //VAT Period has three flat rate:1. Normal Flat Rate 2.Discounted Flat Rate 3. Normal Flat Rate
          
          let todate=new Date(this.vatReturnData.discountFromDate).addDays(-1);
          let obj1={from:this.vatReturnData.fromDate,to:todate,rate:this.vatReturnData.flatRate};
          list.push(obj1);

          let obj2={from:this.vatReturnData.discountFromDate,to:this.vatReturnData.discountToDate,rate:this.vatReturnData.discountedRate};
          list.push(obj2);

          let fromdate=new Date(this.vatReturnData.discountToDate).addDays(1);
          let obj3={from:fromdate,to:this.vatReturnData.toDate,rate:this.vatReturnData.flatRate};
          list.push(obj3);

        }
            
        
this.mainList=[];
this.mainList=list;

  this.renderGrid(list);
}


//add discount/flat rate grid start
ngOnChanges(changes: SimpleChanges) {
  if (changes['fromDate']) {
    if(this.vatReturnData && this.fromDate){
      this.vatReturnData['fromDate']=this.fromDate;
      this.setSubPeriodValues();
    }
    
  }else if(changes['toDate']&& this.toDate){
    this.vatReturnData['toDate']=this.toDate;
    this.setSubPeriodValues();
  }
}

//Add discount/flat rate grid start
}
