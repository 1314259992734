import { BillModel, OverviewFilterModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class CreateBill {
  static readonly type = '[BILL] Create Expenditure Bill';

  constructor(public bill: BillModel) {}
}

export class GetDataByBillId {
  static readonly type =
    '[BILL] Get Expenditure Bill Data By Expenditure Bill Id';

  constructor(public billId: Guid) {}
}

export class ArchiveAndRestoreBill {
  static readonly type = '[BILL] Archive and Restore Selected Bill';

  constructor(public billIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteBill {
  static readonly type = '[BILL] Delete Selected Bill';

  constructor(public billIds: Array<Guid>) {}
}

export class GetBillRefNo {
  static readonly type = '[BILL] Get Bill Ref No';
}

export class GetExpenditureExpenseBreakdownOverview {
  static readonly type = '[BILL] Get Expenditure Expense Breakdown Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetExpenditurePayableAgeingSummaryOverview {
  static readonly type =
    '[BILL] Get Expenditure Payable Ageing Summary Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetExpenditureFlowOverview {
  static readonly type = '[BILL] Get Expenditure Flow Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetDueAmountByBillId {
  static readonly type =
    '[BILL] Get Expenditure Bill Due Amount By Expenditure Bill Id';

  constructor(public billId: Guid) {}
}
export class GetPaymentCurrencyByPurchaseId {
  static readonly type =
    '[BILL] Get Expenditure Purchase Currency By Purchase Id';

  constructor(public purchaseId: Guid) {}
}
