import { Injectable } from '@angular/core';
import { FixedAssetsRegisterService } from '@app/core/Services';
import { CommonService } from '@app/core/Services/common/common.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { DataType } from '@app/core/Enum';
import { FixedAssetExplainModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';
import {
  ActiveDisposeId,
  CreateFixedAssetDispose,
  CreateFixedAssetExplain,
  GetDataByAssetDetailId,
  GetDepreciationChargeByDate,
  GetDepreciationRollBackTransaction,
  GetDepreciationTransaction,
  GetFixedAssetDispose,
  GetFixedAssetRegisterDataList,
  GetFixedAssetRegisterList,
  SaveDepreciation,
} from './fixed-assets-register.action';

export class FixedAssetsRegisterStateInfo {
  fixedAssetRegisterList: any;
  fixedAssetRegisterDataList: any;
  isFixedAssetsRegisterAdded: boolean;
  totalRecord?: number;
  actions?: any;
  expandedCollapseDepreciationDetailList?: any;
  isLoading?: boolean;
  fixedAssetExplainId?: Guid;
  isfixedAssetExplainIdAdded?: boolean;
  assetDetailData?: FixedAssetExplainModel;
  disposeDetailData?: any;
  depreciationChargeDate?: any;
  fixedAssetDisposeId?: Guid;
  isfixedAssetDisposeIdAdded?: boolean;
  activeDisposeId?: Guid;
}

@State<FixedAssetsRegisterStateInfo>({
  name: 'fixedAssetRegister',
  defaults: {
    fixedAssetRegisterList: [],
    fixedAssetRegisterDataList: [],
    isFixedAssetsRegisterAdded: false,
    fixedAssetExplainId: Guid.EMPTY as unknown as Guid,
    isfixedAssetExplainIdAdded: false,
  },
})
@Injectable()
export class FixedAssetsRegisterState {
  constructor(
    private commonService: CommonService,
    private fixedAssetsRegisterService: FixedAssetsRegisterService
  ) {}

  @Selector()
  static expandedCollapseColumnNames(
    state: FixedAssetsRegisterStateInfo
  ): Array<string> {
    if (!state.expandedCollapseDepreciationDetailList) {
      return [];
    }

    return state.expandedCollapseDepreciationDetailList.resultSet.columns.map(
      (x) => x.name
    );
  }

  @Selector()
  static expandedCollapseColumns(state: FixedAssetsRegisterStateInfo): Array<{
    name: string;
    id: Guid;
    dataType?: DataType;
    allowSortBy: boolean;
  }> {
    if (!state.expandedCollapseDepreciationDetailList) {
      return [];
    }
    return state.expandedCollapseDepreciationDetailList.resultSet.columns;
  }

  @Action(GetFixedAssetRegisterList, { cancelUncompleted: true })
  getFixedAssetRegisterList(
    { patchState }: StateContext<FixedAssetsRegisterStateInfo>,
    action: GetFixedAssetRegisterList
  ) {
    return this.commonService.getFixedAssetRegisterList().pipe(
      tap({
        next: (result) => {
          patchState({
            fixedAssetRegisterList: result,
          });
        },
      })
    );
  }

  @Action(SaveDepreciation)
  saveDepreciation(
    { patchState }: StateContext<FixedAssetsRegisterStateInfo>,
    action: SaveDepreciation
  ) {
    return this.fixedAssetsRegisterService
      .saveDepreciation(action.fixedAssetDepriciationData)
      .pipe(
        tap((res) => {
          patchState({
            isFixedAssetsRegisterAdded: true,
          });
        })
      );
  }

  @Action(GetFixedAssetRegisterDataList, { cancelUncompleted: true })
  getFixedAssetRegisterDataList(
    { patchState }: StateContext<FixedAssetsRegisterStateInfo>,
    action: GetFixedAssetRegisterDataList
  ) {
    return this.fixedAssetsRegisterService
      .getFixedAssetRegisterDataList(action.queryParams, action.moduleId)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              fixedAssetRegisterDataList: result.resultSet.data,
              totalRecord: result.paginationModel.totalItemCount,
              actions: result.actions,
            });
          },
        })
      );
  }

  @Action(GetDepreciationTransaction, { cancelUncompleted: true })
  getDepreciationTransaction(
    { patchState }: StateContext<FixedAssetsRegisterStateInfo>,
    action: GetDepreciationTransaction
  ) {
    return this.fixedAssetsRegisterService
      .getDepreciationTransaction(action.queryParams, action.id)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              expandedCollapseDepreciationDetailList: result,
              totalRecord: result.paginationModel.totalItemCount,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(CreateFixedAssetExplain)
  createFixedAssetExplain(
    { patchState }: StateContext<FixedAssetsRegisterStateInfo>,
    action: CreateFixedAssetExplain
  ) {
    return this.fixedAssetsRegisterService
      .createFixedAssetExplain(action.fixedAssetExplain)
      .pipe(
        tap((res) => {
          patchState({
            fixedAssetExplainId: res,
            isfixedAssetExplainIdAdded: true,
          });
        })
      );
  }

  @Action(GetDataByAssetDetailId)
  getDataByAssetDetailId(
    { patchState }: StateContext<FixedAssetsRegisterStateInfo>,
    action: GetDataByAssetDetailId
  ) {
    return this.fixedAssetsRegisterService
      .getDataByAssetDetailId(action.assetDetailId)
      .pipe(
        tap((res) => {
          patchState({
            assetDetailData: res,
          });
        })
      );
  }

  @Action(GetFixedAssetDispose)
  getFixedAssetDispose(
    { patchState }: StateContext<FixedAssetsRegisterStateInfo>,
    action: GetFixedAssetDispose
  ) {
    return this.fixedAssetsRegisterService
      .getFixedAssetDispose(action.disposeId)
      .pipe(
        tap((res) => {
          patchState({
            disposeDetailData: res,
          });
        })
      );
  }

  @Action(GetDepreciationChargeByDate)
  getDepreciationChargeByDate(
    { patchState }: StateContext<FixedAssetsRegisterStateInfo>,
    action: GetDepreciationChargeByDate
  ) {
    return this.fixedAssetsRegisterService
      .getDepreciationChargeByDate(action.disposeId, action.date)
      .pipe(
        tap((res) => {
          patchState({
            depreciationChargeDate: res,
          });
        })
      );
  }

  @Action(CreateFixedAssetDispose)
  createFixedAssetDispose(
    { patchState }: StateContext<FixedAssetsRegisterStateInfo>,
    action: CreateFixedAssetDispose
  ) {
    return this.fixedAssetsRegisterService
      .createFixedAssetDispose(action.fixedAssetDispose)
      .pipe(
        tap((res) => {
          patchState({
            fixedAssetDisposeId: res,
            isfixedAssetDisposeIdAdded: true,
          });
        })
      );
  }

  @Action(ActiveDisposeId)
  activeDisposeId(
    { patchState }: StateContext<FixedAssetsRegisterStateInfo>,
    action: ActiveDisposeId
  ) {
    return this.fixedAssetsRegisterService
      .activeDisposeId(action.activeDisposeId)
      .pipe(
        tap((res) => {
          patchState({
            activeDisposeId: res,
          });
        })
      );
  }

  @Action(GetDepreciationRollBackTransaction, { cancelUncompleted: true })
  getDepreciationRollBackTransaction(
    { patchState }: StateContext<FixedAssetsRegisterStateInfo>,
    action: GetDepreciationRollBackTransaction
  ) {
    return this.fixedAssetsRegisterService
      .getDepreciationRollBackTransaction(action.id)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              expandedCollapseDepreciationDetailList: result,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }
}
