import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export function datePickerValidator(periodicDate: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let isInvalidDate = false;
    let invalidFinancialYear = false;
    let isLockedFinancialYear = false;

    if (control.value) {
      const dateValue = Date.parse(control.value);

      if (periodicDate) {
        let periodicDateList = periodicDate.find((element) => {
          const start = Date.parse(element.fromDate);
          const end = Date.parse(element.toDate);

          return (
            moment(dateValue.valueOf()).format('YYYY-MM-DD') >=
              moment(start.valueOf()).format('YYYY-MM-DD') &&
            moment(dateValue.valueOf()).format('YYYY-MM-DD') <=
              moment(end.valueOf()).format('YYYY-MM-DD')
          );
        });

        if (periodicDateList === undefined) {
          isInvalidDate = true;
          invalidFinancialYear = true;
        } else if (periodicDateList.isLocked) {
          isInvalidDate = true;
          isLockedFinancialYear = true;
        }
      } else {
        isInvalidDate = true;
        invalidFinancialYear = true;
      }
    }

    return isInvalidDate
      ? {
          invalidFinancialYear: invalidFinancialYear,
          lockedFinancialYear: isLockedFinancialYear,
        }
      : null;
  };
}
