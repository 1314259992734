import { AccountModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class CreateAccount {
  static readonly type = '[ACCOUNT] Create Account';

  constructor(public account: AccountModel) {}
}

export class GetDataByAccountId {
  static readonly type = '[ACCOUNT] Get Account Data By Account Id';

  constructor(public accountId: Guid) {}
}

export class GetChartOfAccountGroupList {
  static readonly type = '[ACCOUNT] Get Chart Of Account Group List';
}

export class GetChartOfAccountTypeList {
  static readonly type = '[ACCOUNT] Get Chart Of Account Type List';

  constructor(public groupId?: number) {}
}

export class GetChartOfAllAccountsList {
  static readonly type = '[ACCOUNT] Get Chart Of Account Name List';

  constructor(public groupId?: number, public typeId?: number) {}
}

export class GetJournalAccounts {
  static readonly type = '[ACCOUNT] Get Journal Account List';
}

export class GetFilteredAccounts {
  static readonly type = '[ACCOUNT] Get Filtered Accounts List';
}

export class GetBusinessTypeList {
  static readonly type = '[ACCOUNT] Get Business Type List';
}

export class GetTermsList {
  static readonly type = '[ACCOUNT] Get Terms List';
}

export class ArchiveAndRestoreAccount {
  static readonly type = '[ACCOUNT] Archive and Restore Selected Account';

  constructor(public accountIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteAccount {
  static readonly type = '[ACCOUNT] Delete Selected Account';

  constructor(public accountIds: Array<Guid>) {}
}

export class GetSupplierList {
  static readonly type = '[ACCOUNT] Get Supplier';

  constructor(public entityId: number) {}
}

export class GetAccountType {
  static readonly type = '[ACCOUNT] Get Account type';

  constructor(public accountTypeId: number) {}
}
export class GetAccountGroupAndType {
  static readonly type = '[ACCOUNT] Get Account Group And Type';

  constructor(public accountGroupAndTypeId: Guid) {}
}

export class GetExplain {
  static readonly type = '[ACCOUNT] Get Explain';

  constructor(public id: Guid) {}
}

export class GetExplainListBasedOnAccountingPeriod {
  static readonly type = '[ACCOUNT] Get Explain List';

  constructor(public accountTypeId: number, public accountingPeriodId: Guid) {}
}

export class GetExplainListBasedOnBranchId {
  static readonly type = '[ACCOUNT] Get Explain List based on branch Id';

  constructor(public accountTypeId: number, public branchId: Guid) {}
}

export class GetExplainList {
  static readonly type = '[ACCOUNT] Get Explain List ';

  constructor(public accountTypeId: number) {}
}

export class GetAdvanceList {
  static readonly type = '[ACCOUNT] Get Advance List ';

  constructor(public accountTypeId: number) {}
}

export class GetAdvanceListBasedOnBranchId {
  static readonly type = '[ACCOUNT] Get Advance List based on branch Id';

  constructor(public accountTypeId: number, public branchId: Guid) {}
}

export class GetAccountsForBankFeed {
  static readonly type = '[ACCOUNT] Get Accounts for Bank Feed';
}

export class GetFixedAssetAdditionList {
  static readonly type = '[ACCOUNT] Get Fixed Asset Addition List';
}

export class GetSubcontractorList {
  static readonly type = '[ACCOUNT] Get Subcontractor List';
}
