<div class="side-list-actions footer-list-action">
  <div class="content-body">
    <button
      mat-button
      class="close-button"
      disableRipple
      (click)="cancelSelection()"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
    <div class="d-flex align-items-center">
      <div class="mr-4">
        <div class="mb-1">
          <p class="text-gray-200 fw-bold m-0">Selected items</p>
        </div>
        <div class="d-flex align-items-center justify-space-between">
          <p class="font-size-16 fw-bold m-0 mr-2">
            <span class="text-gray-200 font-size-18">Records:</span>
            {{ totalSelectedRecords }}
          </p>
          <a class="link" href="javascript:void(0)" (click)="cancelSelection()"
            >Cancel selection</a
          >
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div
          class="mr-2 action-list"
          *ngIf="
            getModuleId !== moduleId.FixedAssetsRegister &&
            getModuleId !== moduleId.UserLogs &&
            getModuleId !== moduleId.AddStandardAccounts &&
            getModuleId !== moduleId.SubmitVat &&
            getModuleId !== moduleId.BridgingVAT &&
            getModuleId !== moduleId.EmailLogs
          "
        >
          <p class="font-size-16 fw-bold">Edit</p>
          <div class="d-flex align-items-center action-list-border">
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="delete()"
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon>delete_outline</mat-icon>
              <p>Delete</p>
            </button>
            <button
              class="button-icon copy-button"
              type="button"
              mat-button
              disableRipple
              (click)="copy()"
              [disabled]="isViewPermission$ | async"
              *ngIf="
                getModuleId !== moduleId.Receipt &&
                getModuleId !== moduleId.Payment &&
                getModuleId !== moduleId.BankEntry &&
                getModuleId !== moduleId.CashEntry &&
                getModuleId !== moduleId.FixedAssetsRegister &&
                getModuleId !== moduleId.BankDashboard &&
                getModuleId !== moduleId.SubmitVat &&
                getModuleId !== moduleId.BridgingVAT &&
                getModuleId !== moduleId.CIS300 &&
                getModuleId !== moduleId.VatSettings &&
                getModuleId !== moduleId.Clients &&
                getModuleId !== moduleId.Users
              "
            >
              <mat-icon>content_copy</mat-icon>
              <p>Copy</p>
            </button>
          </div>
        </div>
        <div class="mr-2 action-list">
          <p class="font-size-16 fw-bold">Export</p>
          <div class="d-flex align-items-center action-list-border">
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="export(exportFormat.Excel)"
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon>border_all</mat-icon>
              <p>Excel</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="export(exportFormat.Csv)"
              *ngIf="getModuleId !== moduleId.BankDashboard"
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon fontSet="material-icons-outlined">description</mat-icon>
              <p>CSV</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="export(exportFormat.PDF)"
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon fontSet="material-icons-outlined"
                >picture_as_pdf</mat-icon
              >
              <p>PDF</p>
            </button>
          </div>
        </div>
        <div class="mr-2 action-list">
          <p class="font-size-16 fw-bold action-list-border">Options</p>
          <div class="d-flex align-items-center">
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="print()"
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon fontSet="material-icons-outlined">print</mat-icon>
              <p>Print</p>
            </button>
            <button
              [disabled]="disableInActiveAction"
              *ngIf="
                getModuleId !== moduleId.FixedAssetsRegister &&
                getModuleId !== moduleId.BankDashboard &&
                getModuleId !== moduleId.AddStandardAccounts &&
                getModuleId !== moduleId.SubmitVat &&
                getModuleId !== moduleId.BridgingVAT &&
                getModuleId !== moduleId.UserLogs &&
                getModuleId !== moduleId.EmailLogs &&
                getModuleId !== moduleId.CIS300 &&
                getModuleId !== moduleId.Clients &&
                getModuleId !== moduleId.Users
              "
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="archiveAndRestore(true)"
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon fontSet="material-icons-outlined">archive</mat-icon>
              <p>Inactive</p>
            </button>
            <button
              [disabled]="disableActiveAction"
              *ngIf="
                getModuleId !== moduleId.FixedAssetsRegister &&
                getModuleId !== moduleId.BankDashboard &&
                getModuleId !== moduleId.AddStandardAccounts &&
                getModuleId !== moduleId.SubmitVat &&
                getModuleId !== moduleId.BridgingVAT &&
                getModuleId !== moduleId.UserLogs &&
                getModuleId !== moduleId.EmailLogs &&
                getModuleId !== moduleId.CIS300 &&
                getModuleId !== moduleId.Clients &&
                getModuleId !== moduleId.Users
              "
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="archiveAndRestore(false)"
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon fontSet="material-icons-outlined">unarchive</mat-icon>
              <p>Active</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="email()"
              *ngIf="
                getModuleId === moduleId.Receipt ||
                getModuleId === moduleId.Invoices ||
                getModuleId === moduleId.CreditNote ||
                getModuleId === moduleId.DebitNote ||
                getModuleId === moduleId.Quotation
              "
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon fontSet="material-icons-outlined">email</mat-icon>
              <p>Email</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
