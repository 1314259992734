import { ProductDetailsModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class QuotationModel {
  id: Guid;
  entryNumber?: number;
  entryDate?: string;
  currencyId: number;
  accountId: Guid;
  note: string;
  bookAccountId?: Guid;
  quotationItems: Array<ProductDetailsModel>;
  branchId?: Guid | null;
  departmentId?: Guid | null;
  currencyRate?: number;
  attachment?: Array<string>;
  discount: number;
  isDiscountPercentage: boolean;
  foreignDiscount: number;
}
