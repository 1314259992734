import { Guid } from 'guid-typescript';

export class ScheduleNote {
  id?: Guid;
  companyId?: string;
  title: string;
  description: string;
  isArchived?: boolean;
  isDeleted?: boolean;
  createdBy?: string;
  updatedBy?: string;
  branchId?: Guid | null;
  departmentId?: Guid | null;
}
