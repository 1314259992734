import { Injectable } from '@angular/core';
import { InventoryService } from '@app/core/Services';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  GetInventory,
  GetInventoryTransaction,
  SaveInventory,
} from './inventory.action';

export class InventoryStateInfo {
  isLoading?: boolean;
  isInventorySaved?: boolean;
  inventoryData: Array<any>;
  inventoryTransactionData: Array<any>;
}

@State<InventoryStateInfo>({
  name: 'inventory',
  defaults: {
    isInventorySaved: false,
    inventoryData: [],
    inventoryTransactionData: [],
  },
})
@Injectable()
export class InventoryState {
  constructor(private inventoryService: InventoryService) {}

  @Action(SaveInventory)
  saveInventory(
    { patchState }: StateContext<InventoryStateInfo>,
    action: SaveInventory
  ) {
    return this.inventoryService.saveInventory(action.inventory).pipe(
      tap((res) => {
        patchState({
          isInventorySaved: true,
        });
      })
    );
  }

  @Action(GetInventory, { cancelUncompleted: true })
  getInventory(
    { patchState }: StateContext<InventoryStateInfo>,
    action: GetInventory
  ) {
    return this.inventoryService.getInventory(action.dataParam).pipe(
      tap({
        next: (result) => {
          patchState({
            inventoryData: result,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(GetInventoryTransaction, { cancelUncompleted: true })
  getInventoryTransaction(
    { patchState }: StateContext<InventoryStateInfo>,
    action: GetInventoryTransaction
  ) {
    return this.inventoryService.getInventoryTransaction(action.dataParam).pipe(
      tap({
        next: (result) => {
          patchState({
            inventoryTransactionData: result,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }
}
