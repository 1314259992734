import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddTemplatesComponent } from './add-templates/add-templates.component';
import { UploadTemplatesComponent } from './upload-templates/upload-templates.component';
export interface PeriodicElement {
  templatename: string;
  default: string;
  invoice: string;
  creditnote: string;
  dividend: string;
  quotation: string;
  updated: string;
  bank: string;
  action: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    templatename: 'Default Template',
    default: '',
    invoice: 'Invoice.docx',
    creditnote: 'CreditNote.docx',
    dividend: 'Dividend.docx',
    quotation: 'Quotation.docx',
    updated: '-',
    bank: 'N/A',
    action: '',
  },
];

@Component({
  selector: 'app-templates-docs',
  templateUrl: './templates-docs.component.html',
  styleUrls: ['./templates-docs.component.scss'],
})
export class TemplatesDocsComponent implements OnInit {
  displayedColumns: string[] = [
    'templatename',
    'default',
    'invoice',
    'creditnote',
    'dividend',
    'quotation',
    'updated',
    'bank',
    'action',
  ];
  dataSource = ELEMENT_DATA;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openAddTemplate() {
    const dialogRef = this.dialog.open(AddTemplatesComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openUploadTemplate() {
    const dialogRef = this.dialog.open(UploadTemplatesComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
