import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DividendsModel, SideListModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DividendsService {
  constructor(private http: HttpClient) {}

  createDividends(dividends: DividendsModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Dividend/save`,
      JSON.stringify(dividends),
      headers
    );
  }

  getDataByDividendsId(dividendsId: Guid): Observable<DividendsModel> {
    return this.http.get<DividendsModel>(
      `${environment.apiVersionUrl}Dividend/get/${dividendsId}`
    );
  }

  deleteDividends(invoiceIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(invoiceIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Dividend/delete`,
      options
    );
  }

  copyDividends(dividendsIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Dividend/copyDividends`,
      JSON.stringify(dividendsIds),
      headers
    );
  }

  archiveAndRestoreDividends(
    dividendsIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Dividend/archive/${isArchive}`,
      JSON.stringify(dividendsIds),
      options
    );
  }

  getAllShareHolder(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}DirectorShareholder/allShareholder`
    );
  }

  getAllShareClassType(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Shareclasstype/all`
    );
  }

  getAllShareHolderDetailView(
    shareHolderId: Guid
  ): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}DirectorShareholder/getShareholderDetailView/${shareHolderId}`
    );
  }

  getDividendNumber(): Observable<any> {
    return this.http.get(`${environment.apiVersionUrl}Dividend/getDividendNo`, {
      responseType: 'text',
    });
  }
}
