<div
  class="content-body mb-4 task-details assign-client"
  *ngIf="!showAddClient"
>
  <div class="header-text d-flex justify-space-between align-items-center">
    <span>Assign Client</span>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="content-header">
    <div class="d-flex align-items-end gap-15 mb-1 mt-1">
      <button
        class="primary-button"
        type="button"
        mat-button
        disableRipple
        (click)="addClient()"
      >
        <mat-icon>add</mat-icon>
        <span>Add Client</span>
      </button>
      <div class="grid-search mr-10">
        <mat-form-field appearance="fill" class="search-field">
          <input
            matInput
            placeholder="Search"
            autocomplete="off"
            (keyup)="onSearch($event)"
          />
          <button mat-button disableRipple class="search-button">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>

  <app-user-clients
    *ngIf="getModuleId === moduleIdEnum.Users"
    [displayedUserClientColumns]="displayedUserClientColumns"
    [triggerDataFromHeader]="triggerDataFromHeader"
    [triggerReloadList]="triggerReloadList"
    [showAssignClients]="true"
  ></app-user-clients>
</div>

<div class="action-wrapper button-action-wrapper" *ngIf="!showAddClient">
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      (click)="onSaveClick()"
      type="button"
      mat-button
      disableRipple
    >
      Save
    </button>
    <button
      class="action-button secondary-button"
      (click)="onCloseClick()"
      type="button"
      mat-button
      disableRipple
    >
      Cancel
    </button>
  </div>
</div>

<div
  class="mat-dialog-wrapper form-list-content mb-4 p-0 task-details"
  *ngIf="showAddClient"
>
  <div>
    <div class="back-button-wrapper sidenav-body-content pb-0">
      <button
        class="back-button"
        mat-button
        disableRipple
        (click)="onBackLinkClick()"
      >
        <mat-icon fontSet="material-icons-outlined">arrow_circle_left</mat-icon>
        <span class="hyperlink" *ngIf="this.getModuleId === moduleIdEnum.Users"
          >Back to User</span
        >
      </button>
    </div>
    <div class="mb-1">
      <app-add-client
        [isFromAssignClient]="true"
        [triggerAddClient]="triggerAddClient"
        [triggerSaveClient]="triggerSaveClient"
        [triggerCancelClient]="triggerCancelClient"
      >
      </app-add-client>
    </div>
    <div class="action-wrapper">
      <div class="sidenav-body-content">
        <button
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
          (click)="onSaveClient()"
        >
          Save
        </button>
        <button
          class="action-button secondary-button"
          type="button"
          mat-button
          disableRipple
          (click)="onCancelClient()"
        >
          Cancel Changes
        </button>
      </div>
    </div>
  </div>
</div>
