import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  AccountEntity,
  ChartOfAccountCreditorsGroupTypeName,
  GroupNames,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
  TransactionStatus,
} from '@app/core/Enum';
import { AccountNumber } from '@app/core/Enum/account-number';
import {
  ChartOfAccountListParam,
  Currency,
  GlobalComponent,
  GroupListModel,
  ReceiptModel,
  SideListModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  CommonState,
  CreateAccount,
  CurrencyState,
  GetAllBranchList,
  GetAllDepartmentListByBranch,
  GetClientCurrencyList,
  GetCurrencyList,
  GetCustomBankAccountList,
  GetDataByAccountId,
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetGroupCustomBankAndCashAccount,
  GetGroupCustomBankAndCashAccountByBranch,
  GetNonStandardAccountList,
  GetNonStandardAccountListByBranch,
  GetRefreshCurrencyData,
  MenuState,
} from '@app/core/Store';
import { QuickAddComponent, ReceiptDetailsComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { EMPTY, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-receipt',
  templateUrl: './add-receipt.component.html',
  styleUrls: ['./add-receipt.component.scss'],
})
export class AddReceiptComponent implements OnInit {
  receiptForm: FormGroup;
  receiptId = Guid.EMPTY as unknown as Guid;

  receiptData: ReceiptModel;

  customerList: SideListModel[];
  accountTypeList: SideListModel[];
  accountList: any[] = [];
  currencyList: Currency[];
  receiptAccountList: any[] = [];
  tempReceiptAccountList: any[];
  getListId: Guid;

  accountGroupList: GroupListModel[];
  tempAccountGroupList: any[];

  subscriptionRouting: Subscription;
  moduleName = ModuleName;
  moduleId = Modules;

  amountReceivedValue = 0;
  amountReceivedTotal = 0;
  totalAmountDue = 0;
  amountReceived = 0;
  amountReceivedConverted = 0;
  isAdvance = false;

  @Input() bankId;

  @Input()
  getModuleName?: string;

  @Input()
  getModuleId: number;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @ViewChild(ReceiptDetailsComponent, { static: true })
  receiptInfo;

  triggerCustomer: Subject<any> = new Subject<any>();
  triggerReceiptDetail: Subject<any> = new Subject<any>();
  triggerAccountSelectedOption: Subject<any> = new Subject<any>();

  @Input() triggerEditData: Observable<any>;
  @Input() triggerTransactionLogData: Observable<any>;

  @Output() triggerCurrencySelectedOption: EventEmitter<number> =
    new EventEmitter<number>();

  @Output() triggerCurrencyRefreshOption: EventEmitter<number> =
    new EventEmitter<number>();

  transactionType = [
    {
      id: TransactionStatus.IsCredit,
      name: TransactionStatus[TransactionStatus.IsCredit],
    },
    {
      id: TransactionStatus.IsDebit,
      name: TransactionStatus[TransactionStatus.IsDebit],
    },
  ];

  customerLabelText: string;
  accountLabelText: string;

  defaultCurrency: Guid;
  periodicDate: any;

  isEdit = false;
  disabledEditButton = true;
  showReceiptDetails = false;

  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;
  departmentList: any[] = [];
  branchList: any[] = [];
  clientCurrencyList: any = [];
  isMultiCurrencySelected = false;
  currencyIds: any = [];
  selectedCurrencyId: number;
  refreshedCurrencyData: any = [];
  refreshedCurrencyRate: number;
  selectedCurrencySymbol: string;
  selectedCurrencyRate: number;
  defaultCurrencySymbol: string;
  currencyName: string;
  isConvertedAmountShow = false;
  isCashInHand = false;
  accountCurrencySymbol: string;
  accountSelectedCurrencyId: number;
  tempCurrencyList: any = [];
  isFromBankImport = false;

  private destroy$ = new Subject<void>();
  isCustomerChangePermission: boolean = true;
  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.selectedCurrencySymbol = '£';
    this.defaultCurrencySymbol = '£';
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.isEdit = false;
    this.setForm().then(() => {
      this.getCurrencyList();
      this.loadDropdownValues();
    });

    this.triggerTransactionLogData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isFromBankImport = true;
        this.receiptForm.controls.accountId.setValue(data.accountId);
        this.receiptForm.controls.amountReceived.setValue(data.amount);
        this.receiptForm.controls.receiptDate.setValue(data.entryDate);
        this.receiptForm.controls.note.setValue(data.note);
        this.receiptForm.controls.receiptDate.disable();
        this.receiptForm.controls.amountReceived.disable();
        this.receiptForm.controls.accountId.disable();
        this.accountSelectedCurrencyId = data.currencyId;
        this.selectedCurrencyRate = this.clientCurrencyList.find(
          (x) => x.currencyId === data.currencyId
        )?.rate;
        this.currencyName = this.clientCurrencyList.find(
          (x) => x.currencyId === data.currencyId
        )?.currency;
        this.selectedCurrencySymbol = this.clientCurrencyList.find(
          (x) => x.currencyId === data.currencyId
        )?.symbol;
        if (data.currencyId === 123) {
          this.receiptForm.controls.currencyAmt.setValue(1);
        } else {
          this.isMultiCurrencySelected = true;
          this.isConvertedAmountShow = true;
          this.receiptForm.controls.convertedAmtRcvd.setValue(
            (
              this.receiptForm.controls.amountReceived.value /
              this.selectedCurrencyRate
            ).toFixed(2)
          );
          this.receiptForm.controls.currencyAmt.setValue(
            this.selectedCurrencyRate
          );
          this.receiptForm.controls.foreignCurrency.setValue(this.currencyName);
          this.clientCurrencyList = this.clientCurrencyList.filter(
            (item) =>
              item.currencyId === 123 || item.currencyId === data.currencyId
          );
        }
      });

    this.triggerEditData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isEdit = true;
        this.loadDropdownValues();
        this.spinner.show();
        setTimeout(() => {
          this.editReceipt(data);
          this.spinner.hide();
        }, 2000);
      });

    this.receiptForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.receiptForm.touched;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isCustomerChangePermission = this.commonService.checkPermission(
        Modules.Contacts,
        Modules.Customers
      );
    }, 3000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setLabelText(): void {
    if (
      this.getModuleId === Modules.Payment ||
      this.receiptForm.controls.transactionType.value ===
        TransactionStatus.IsDebit
    ) {
      this.customerLabelText = 'Supplier Name';
    } else if (
      this.getModuleId === Modules.Receipt ||
      this.receiptForm.controls.transactionType.value ===
        TransactionStatus.IsCredit
    ) {
      this.customerLabelText = 'Customer Name';
    }
  }

  bindCustomerSupplier(id?: Guid): void {
    const entityId = new Array<number>();

    if (
      this.getModuleId === Modules.Payment ||
      this.receiptForm.controls.transactionType.value ===
        TransactionStatus.IsDebit
    ) {
      entityId.push(AccountEntity.Supplier);
    } else if (
      this.getModuleId === Modules.Receipt ||
      this.receiptForm.controls.transactionType.value ===
        TransactionStatus.IsCredit
    ) {
      entityId.push(AccountEntity.Customer);
    }
    let actionName;
    if (this.branchList.length > 0) {
      actionName = GetNonStandardAccountListByBranch;
    } else {
      actionName = GetNonStandardAccountList;
    }

    this.store
      .dispatch(
        new actionName(entityId, this.receiptForm.controls.branchId.value)
      )
      .pipe(
        tap(() => {
          this.customerList = this.store.selectSnapshot(
            CommonState.accountList
          );

          if (this.customerList.length > 0 && !this.isEdit) {
            this.disabledEditButton = false;
          }
          if (id !== null && id !== undefined) {
            this.receiptForm.controls.customerName.setValue(id);
          }
          this.triggerCustomer.next(this.receiptForm);
        })
      )
      .subscribe();
  }

  bindCurrency(): void {
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          if (this.currencyList.length > 0) {
            this.receiptForm.controls.currency.setValue(this.defaultCurrency);
          }
        })
      )
      .subscribe();
  }

  loadDropdownValues(): void {
    this.onAccountTypeChange();
    this.showReceiptDetails = true;
    this.bindCustomerSupplier();
  }

  gotoAddCustomer(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId:
            this.getModuleId === Modules.Receipt
              ? Modules.Customers
              : Modules.Suppliers,
          headerText: `Add ${
            this.getModuleId === Modules.Receipt
              ? ModuleName.Customers
              : ModuleName.Suppliers
          }`,
          saveActionName: CreateAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.disabledEditButton = false;
          this.bindCustomerSupplier(id);
        }
      });
  }

  gotoEditCustomer(): void {
    if (this.customerList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId:
              this.getModuleId === Modules.Receipt
                ? Modules.Customers
                : Modules.Suppliers,
            headerText: `Edit ${
              this.getModuleId === Modules.Receipt
                ? ModuleName.Customers
                : ModuleName.Suppliers
            }`,
            id: this.receiptForm.controls.currency.value,
            saveActionName: CreateAccount,
            getActionName: GetDataByAccountId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.bindCustomerSupplier(id);
            if (id !== null && id !== undefined) {
              this.receiptForm.controls.currency.setValue(id);
            }
          }
        });
    }
  }

  editReceipt(data: any): void {
    let accoutListBank: any = '';
    this.showReceiptDetails = !data.isAdvance;
    {
      this.receiptForm.controls.accountId.disable();
      this.clientCurrencyList = this.tempCurrencyList;
      this.selectedCurrencyId = this.receiptAccountList.find(
        (x) => x.id === data.postingAccountId
      )?.currencyId;
      if (this.selectedCurrencyId === 123 || this.selectedCurrencyId === 0) {
        this.isConvertedAmountShow = false;
        this.isMultiCurrencySelected = false;
        this.selectedCurrencySymbol = '£';
        this.selectedCurrencyRate = 1;
      } else {
        this.isMultiCurrencySelected = true;
        this.isConvertedAmountShow = true;
        this.accountSelectedCurrencyId = this.selectedCurrencyId;
        this.clientCurrencyList = this.clientCurrencyList.filter(
          (item) =>
            item.currencyId === 123 ||
            item.currencyId === this.selectedCurrencyId
        );
        this.currencyName = this.clientCurrencyList.find(
          (x) => x.currencyId === this.selectedCurrencyId
        )?.currency;
        this.selectedCurrencyRate = this.clientCurrencyList.find(
          (x) => x.currencyId === this.selectedCurrencyId
        )?.rate;
        this.selectedCurrencySymbol = this.clientCurrencyList.find(
          (x) => x.currencyId === this.selectedCurrencyId
        )?.symbol;
        this.receiptForm.controls.foreignCurrency.setValue(this.currencyName);
        this.receiptForm.controls.currencyAmt.setValue(
          this.selectedCurrencyRate
        );
      }
      this.receiptForm.controls.customerName.disable();
      this.receiptForm.controls.branchId.disable();
      this.receiptForm.controls.departmentId.disable();
    }

    this.receiptForm.controls.code.disable();
    this.disabledEditButton = false;
    this.receiptForm.patchValue({
      code: data.code,
      accountId: data.postingAccountId,
      accoutListBank: accoutListBank,
      customerName: data.accountId,
      receiptDate: data.receiptDate,
      note: data.note,
      amountReceived:
        data.currencyId !== 123 ? data.foreignTotalAmount : data.totalAmount,
      currency: data.invoiceCurrencyId,
      transactionType: data.isCredit ? 1 : 0,
      isAdvance: data.isAdvance,
      branchId: data.branchId,
      departmentId: data.departmentId,
      currencyAmt: data.currencyRate,
    });
    if (data.currencyId != 123) {
      this.isMultiCurrencySelected = true;
      this.currencyChange(data.currencyId);
    } else {
      this.isMultiCurrencySelected = false;
      this.selectedCurrencyRate = 1;
      this.selectedCurrencySymbol = '£';
    }
    this.setLabelText();
    this.triggerReceiptDetail.next(data);

    if (data.branchId !== null && data.branchId !== undefined) {
      this.getDepartmentListByBranch(data.branchId);
    }
  }

  onAmountReceivedChange(): void {
    if (this.showReceiptDetails && !this.isFromBankImport) {
      this.amountReceivedTotal = this.receiptInfo.totalAmount;
      if (
        this.getModuleId === Modules.Receipt ||
        this.getModuleId === Modules.Payment
      ) {
        this.receiptForm.controls.amountReceived.setValue(
          +this.amountReceivedTotal
        );
        this.receiptForm.controls.convertedAmtRcvd.setValue(
          (+this.amountReceivedTotal / this.selectedCurrencyRate).toFixed(2)
        );
      }
      this.amountReceivedValue = this.receiptForm.controls.amountReceived.value;
    } else {
      this.amountReceivedValue = this.receiptInfo.totalAmount;
      this.receiptForm.controls.convertedAmtRcvd.setValue(
        (
          this.receiptForm.controls.amountReceived.value /
          this.selectedCurrencyRate
        ).toFixed(2)
      );
    }
  }

  onTransactionTypeChange(): void {
    this.bindCustomerSupplier();

    this.setLabelText();
    if (this.showReceiptDetails) {
      this.triggerCustomer.next(this.receiptForm);
    }
  }

  onAccountTypeChange(): void {
    let actionName;
    if (this.branchList.length > 0) {
      actionName = GetGroupCustomBankAndCashAccountByBranch;
    } else {
      actionName = GetGroupCustomBankAndCashAccount;
    }

    this.receiptForm.controls.accountId.setValue('');

    this.store
      .dispatch(new actionName(this.receiptForm.controls.branchId.value))
      .pipe(
        switchMap((res) => {
          this.receiptAccountList = [];
          this.receiptAccountList = this.commonService.addGroupNameToListModels(
            res.common.customBankAndCashAccountGroupList
          );
          this.tempReceiptAccountList = this.receiptAccountList;

          if (!this.commonService.isEmpty(this.bankId)) {
            this.receiptForm.controls.accountId.setValue(this.bankId);
            this.receiptForm.controls.accountId.disable();
            return EMPTY;
          } else {
            if (this.receiptAccountList.length > 0 && !this.isFromBankImport) {
              this.receiptForm.controls.accountId.setValue(
                this.receiptAccountList[0].id
              );

              if (this.receiptAccountList[0].currencyId !== 123) {
                this.clientCurrencyList = this.clientCurrencyList.filter(
                  (item) =>
                    item.currencyId === 123 ||
                    item.currencyId === this.receiptAccountList[0].currencyId
                );
                this.isMultiCurrencySelected = true;
                this.isConvertedAmountShow = true;
                this.isCashInHand = false;
                this.currencyName = this.clientCurrencyList.find(
                  (x) => x.currencyId === this.receiptAccountList[0].currencyId
                )?.currency;
                this.selectedCurrencyRate = this.clientCurrencyList.find(
                  (x) => x.currencyId === this.receiptAccountList[0].currencyId
                )?.rate;
                this.selectedCurrencySymbol = this.clientCurrencyList.find(
                  (x) => x.currencyId === this.receiptAccountList[0].currencyId
                )?.symbol;
                this.receiptForm.controls.foreignCurrency.setValue(
                  this.currencyName
                );
                this.receiptForm.controls.currencyAmt.setValue(
                  this.selectedCurrencyRate
                );
              } else {
                this.clientCurrencyList = this.tempCurrencyList;
              }

              this.accountSelectedCurrencyId =
                this.receiptAccountList[0].currencyId;
              this.receiptForm.controls.accountSelectedCurrencyId.setValue(
                this.accountSelectedCurrencyId
              );
              this.triggerAccountSelectedOption.next(this.receiptForm);
            }

            const param: ChartOfAccountListParam = {
              groupIds: [GroupNames.Creditors],
              typeIds: [
                ChartOfAccountCreditorsGroupTypeName.CreditorsLessThanOneYearTaxationAndSocialSecurity,
              ],
            };

            return this.store.dispatch(
              new GetGroupAccountsBasedOnGroupIdAndTypeId(param)
            );
          }
        }),
        switchMap((secondRes) => {
          if (secondRes.common.accountGroupList.length > 0) {
            let accounts = this.commonService.addGroupNameToListModels(
              secondRes.common.accountGroupList
            );

            accounts = accounts.filter(
              (item) => item.name === AccountNumber.ACCOUNT_8214
            );
            this.receiptAccountList = this.receiptAccountList.concat(accounts);
            this.tempReceiptAccountList = this.receiptAccountList;
          }
          return of(secondRes);
        })
      )
      .subscribe();
  }

  getOptionText(option) {
    return option.name;
  }

  async setForm(): Promise<void> {
    this.receiptForm = new FormGroup(
      {
        accountTypeId: new FormControl(0),
        accountId: new FormControl('', [Validators.required]),
        code: new FormControl(''),
        customerName: new FormControl('', [Validators.required]),
        transactionType: new FormControl(TransactionStatus.IsCredit),
        receiptDate: new FormControl(new Date(), [
          datePickerValidator(this.periodicDate),
          Validators.required,
        ]),
        currency: new FormControl('', [Validators.required]),
        amountReceived: new FormControl('', [Validators.required]),
        isAdvance: new FormControl(false),
        note: new FormControl(''),
        accoutListBank: new FormControl('', [Validators.required]),
        branchId: new FormControl(''),
        departmentId: new FormControl(''),
        currencyAmt: new FormControl(''),
        foreignCurrency: new FormControl({ value: '', disabled: true }),
        convertedAmtRcvd: new FormControl({ value: '', disabled: true }),
        accountSelectedCurrencyId: new FormControl(''),
      },
      {
        validators: this.commonService.compareFields(
          'accountId',
          'accoutListBank',
          this.getModuleId
        ),
      }
    );
    if (
      this.getModuleId === Modules.Receipt ||
      this.getModuleId === Modules.Payment
    ) {
      this.receiptForm.controls.amountReceived.disable();
    }
    if (
      this.getModuleId === Modules.BankEntry ||
      this.getModuleId === Modules.CashEntry
    ) {
      this.receiptForm.controls.customerName.clearValidators();
      this.receiptForm.controls.customerName.updateValueAndValidity();
    } else {
      this.receiptForm.controls.accoutListBank.clearValidators();
      this.receiptForm.controls.accoutListBank.updateValueAndValidity();
    }
    this.accountLabelText =
      this.getModuleId === Modules.BankEntry ? 'Bank' : 'Account';

    this.setLabelText();
    await this.getBranchList(true);
  }

  async getBranchList(isOnLoad): Promise<void> {
    await this.store
      .dispatch(new GetAllBranchList())
      .toPromise()
      .then((res) => {
        this.branchList = [];
        if (res.company.branch.length > 0) {
          this.branchList = res.company.branch;
          this.receiptForm.controls.branchId.setValue(this.branchList[0].id);
          this.receiptForm.controls.branchId.setValidators(Validators.required);
          this.receiptForm.controls.branchId.updateValueAndValidity();
          this.onChangeBranch(this.branchList[0].id, isOnLoad);
        } else {
          this.branchList = [];
          this.departmentList = [];
          this.bindCustomerSupplier();
        }
      });
  }

  async onChangeBranch(branch, isOnLoad): Promise<void> {
    await this.getDepartmentListByBranch(branch);
    this.bindCustomerSupplier();
    this.onAccountTypeChange();
    if (!isOnLoad) {
      this.receiptForm.controls.customerName.setValue('');
    }
  }

  async getDepartmentListByBranch(branch: any): Promise<void> {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .toPromise()
        .then((res) => {
          this.departmentList = [];
          if (res.company.department.length > 0) {
            this.departmentList = res.company.department;
            if (!this.isEdit) {
              this.receiptForm.controls.departmentId.setValue(
                this.departmentList[0].id
              );
            }
            this.receiptForm.controls.departmentId.setValidators(
              Validators.required
            );
            this.receiptForm.controls.departmentId.updateValueAndValidity();
          } else {
            this.receiptForm.controls.departmentId.clearValidators();
            this.receiptForm.controls.departmentId.updateValueAndValidity();
          }
        });
    } else {
      this.departmentList = [];
      this.receiptForm.controls.departmentId.clearValidators();
      this.receiptForm.controls.departmentId.updateValueAndValidity();
    }
  }

  onCustomerChange(event: any): void {
    if (event !== null && event !== undefined) {
      this.disabledEditButton = false;
    } else {
      this.disabledEditButton = true;
    }

    if (this.showReceiptDetails) {
      for (let i = 0; i < this.receiptInfo.receiptDetailArray.length; i++) {
        this.receiptInfo.receiptDetailArray.controls[i]
          .get('invoiceReceipt')
          .setValue(0);
        this.receiptInfo.receiptDetailArray.controls[i]
          .get('id')
          .setValue(Guid.EMPTY as unknown as Guid);
      }

      this.triggerCustomer.next(this.receiptForm);
    }
  }

  getCustomBankAccountList(): void {
    this.store
      .dispatch(new GetCustomBankAccountList())
      .pipe(
        tap((res) => {
          this.accountList = res.common.accountList;

          if (this.accountList.length > 0) {
            this.receiptForm.controls.accountId.setValue(
              this.accountList[0].id
            );
          } else {
            this.receiptForm.controls.accountId.reset();
          }
        })
      )
      .subscribe();
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  onCheckBoxSelected(event: any): void {
    this.isAdvance = event.checked;
    this.showReceiptDetails = !event.checked;

    if (event.checked) {
      this.amountReceived = this.receiptForm.controls.amountReceived.value;
      this.amountReceivedConverted =
        this.receiptForm.controls.convertedAmtRcvd.value;
      if (!this.isFromBankImport) {
        this.receiptForm.controls.amountReceived.setValue(0);
        this.receiptForm.controls.convertedAmtRcvd.setValue(0);
        this.receiptForm.controls.amountReceived.enable();
      }
    } else {
      if (this.isFromBankImport) {
        this.receiptForm.controls.amountReceived.setValue(this.amountReceived);
        this.receiptForm.controls.convertedAmtRcvd.setValue(
          this.amountReceivedConverted
        );
        this.receiptForm.controls.amountReceived.disable();
      } else {
        this.receiptForm.controls.amountReceived.setValue(
          this.amountReceivedTotal
        );
        this.receiptForm.controls.amountReceived.disable();
      }
    }
  }

  getCurrencyList(): void {
    this.store
      .dispatch(new GetClientCurrencyList())
      .pipe(
        tap(() => {
          this.clientCurrencyList = this.store.selectSnapshot(
            CurrencyState.getCurrencyList
          );
          if (this.clientCurrencyList.length > 0) {
            this.receiptForm.controls.currency.setValue(
              this.clientCurrencyList[0].currencyId
            );
            if (!this.isFromBankImport) {
              this.receiptForm.controls.currencyAmt.setValue(1);
            }
            this.tempCurrencyList = this.clientCurrencyList;
          }
        })
      )
      .subscribe();
  }
  currencyChange(value: any): void {
    if (this.showReceiptDetails && !this.isFromBankImport) {
      this.receiptForm.controls.amountReceived.setValue(0.0);
      this.receiptForm.controls.convertedAmtRcvd.setValue(0.0);
      if (value !== undefined && value !== null) {
        if (
          !this.isCashInHand &&
          (this.accountSelectedCurrencyId !== 123 || value !== 123)
        ) {
          this.isMultiCurrencySelected = true;

          this.currencyName = this.clientCurrencyList.find(
            (x) =>
              x.currencyId ===
              (this.accountSelectedCurrencyId !== 123
                ? this.accountSelectedCurrencyId
                : value)
          )?.currency;
          this.selectedCurrencyRate = this.clientCurrencyList.find(
            (x) =>
              x.currencyId ===
              (this.accountSelectedCurrencyId !== 123
                ? this.accountSelectedCurrencyId
                : value)
          )?.rate;
          if (this.accountSelectedCurrencyId === 123) {
            this.selectedCurrencySymbol = this.clientCurrencyList.find(
              (x) => x.currencyId === this.accountSelectedCurrencyId
            )?.symbol;
          }

          this.receiptForm.controls.foreignCurrency.setValue(this.currencyName);
          this.receiptForm.controls.currencyAmt.setValue(
            this.selectedCurrencyRate
          );
          this.receiptForm.controls.accountSelectedCurrencyId.setValue(
            this.accountSelectedCurrencyId
          );
        } else if (this.isCashInHand && value !== 123) {
          this.isMultiCurrencySelected = true;

          this.currencyName = this.clientCurrencyList.find(
            (x) => x.currencyId === value
          )?.currency;
          this.selectedCurrencyRate = this.clientCurrencyList.find(
            (x) => x.currencyId === value
          )?.rate;

          this.selectedCurrencySymbol = this.clientCurrencyList.find(
            (x) => x.currencyId === this.accountSelectedCurrencyId
          )?.symbol;

          this.receiptForm.controls.foreignCurrency.setValue(this.currencyName);
          this.receiptForm.controls.currencyAmt.setValue(
            this.selectedCurrencyRate
          );
        } else {
          this.isConvertedAmountShow = false;
          this.isMultiCurrencySelected = false;
          this.selectedCurrencyId = 123;
          this.selectedCurrencySymbol = '£';
          this.selectedCurrencyRate = 1;
          this.receiptForm.controls.currencyAmt.setValue(1);
        }
      }
    }
    this.triggerCurrencySelectedOption.emit(this.selectedCurrencyRate);
    this.triggerCustomer.next(this.receiptForm);
  }
  onCurrencyRefresh(): void {
    this.currencyIds = [];
    this.currencyIds.push(this.selectedCurrencyId);

    this.store
      .dispatch(new GetRefreshCurrencyData(this.currencyIds))
      .subscribe((res) => {
        this.currencyIds = [];
        this.refreshedCurrencyData = res.currency.currencyList.data;

        this.refreshedCurrencyData.forEach((x) => {
          this.refreshedCurrencyRate = x.rate;
        });
        this.receiptForm.controls.currencyAmt.setValue(
          this.refreshedCurrencyRate
        );
        this.selectedCurrencyRate = this.refreshedCurrencyRate;
        this.triggerCurrencyRefreshOption.emit(this.selectedCurrencyRate);
      });
  }
  onCurrencyRateEntered() {
    this.selectedCurrencyRate = this.receiptForm.controls.currencyAmt.value;
    this.triggerCurrencyRefreshOption.emit(this.selectedCurrencyRate);
  }
  onAccountDataChanged(value: any) {
    this.clientCurrencyList = this.tempCurrencyList;
    this.receiptForm.controls.amountReceived.setValue(0.0);
    this.receiptForm.controls.convertedAmtRcvd.setValue(0.0);
    this.selectedCurrencyId = this.receiptAccountList.find(
      (x) => x.id === value.id
    )?.currencyId;

    if (this.selectedCurrencyId !== 123) {
      this.clientCurrencyList = this.clientCurrencyList.filter(
        (item) =>
          item.currencyId === 123 || item.currencyId === this.selectedCurrencyId
      );
    } else {
      this.clientCurrencyList = this.tempCurrencyList;
    }
    this.receiptForm.controls.currency.setValue(
      this.clientCurrencyList[0].currencyId
    );
    if (this.selectedCurrencyId === 123) {
      this.isConvertedAmountShow = false;
      this.isMultiCurrencySelected = false;
      this.selectedCurrencySymbol = '£';
      this.selectedCurrencyRate = 1;
    } else {
      this.isMultiCurrencySelected = true;
      this.isConvertedAmountShow = true;
      this.currencyName = this.clientCurrencyList.find(
        (x) => x.currencyId === this.selectedCurrencyId
      )?.currency;
      this.selectedCurrencyRate = this.clientCurrencyList.find(
        (x) => x.currencyId === this.selectedCurrencyId
      )?.rate;
      this.selectedCurrencySymbol = this.clientCurrencyList.find(
        (x) => x.currencyId === this.selectedCurrencyId
      )?.symbol;
      this.receiptForm.controls.foreignCurrency.setValue(this.currencyName);
      this.receiptForm.controls.currencyAmt.setValue(this.selectedCurrencyRate);
    }
    this.accountSelectedCurrencyId = this.selectedCurrencyId;
    this.receiptForm.controls.accountSelectedCurrencyId.setValue(
      this.accountSelectedCurrencyId
    );
    this.triggerAccountSelectedOption.next(this.receiptForm);
    this.triggerCustomer.next(this.receiptForm);
  }
  onTotalDueAmountBalanceChange(): void {
    if (this.showReceiptDetails) {
      this.totalAmountDue = this.receiptInfo.totalDueAmount;
    }
  }
}
