<div class="mat-dialog-wrapper view-explain-contacts">
  <h2 mat-dialog-title>{{ headerText }}</h2>
  <mat-dialog-content>
    <div class="form-field d-flex align-items-center gap-20 pt-1">
      <p class="white-space-nowrap m-0">Opening Balance</p>
      <mat-form-field floatLabel="never" class="w-300">
        <input
          matInput
          autocomplete="off"
          [(ngModel)]="data.totalOpeningBalance"
          disabled
        />
      </mat-form-field>
      <button
        *ngIf="this.data.accountTypeId === accountEntity.Customer"
        class="action-button primary-button"
        mat-button
        (click)="gotoAddCustomer()"
        disableRipple
      >
        <mat-icon>add</mat-icon>
        <span>Add Customer</span>
      </button>
      <button
        *ngIf="this.data.accountTypeId === accountEntity.Supplier"
        class="action-button primary-button"
        mat-button
        (click)="gotoAddSupplier()"
        disableRipple
      >
        <mat-icon>add</mat-icon>
        <span>Add Supplier</span>
      </button>
    </div>
    <div class="sidenav-table sidenav-table-input wp-100">
      <div class="mat-table-wrapper">
        <form [formGroup]="formOpeningBalanceExplain">
          <ng-container formArrayName="openingBalanceExplainArray">
            <table
              aria-describedby="account-list"
              mat-table
              [dataSource]="tableDataSource"
            >
              <ng-container matColumnDef="srNo">
                <th mat-header-cell *matHeaderCellDef class="w-20">Sr No.</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <div class="d-flex">
                    {{ i + 1 }}
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="account">
                <th mat-header-cell *matHeaderCellDef class="w-300">
                  Account
                  <span class="text-danger-300">*</span>
                </th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                  class="widthemail"
                >
                  <ng-select
                    #account
                    [disableOptionCentering]="true"
                    (focus)="account.open()"
                    (valueChange)="account.close()"
                    formControlName="account"
                    panelClass="mat-select-position"
                    required
                    appendTo="body"
                  >
                    <ng-option
                      *ngFor="let option of customerList"
                      [value]="option.id"
                    >
                      <div class="clamp" title="{{ option.name }}">
                        {{ option.name }}
                      </div>
                    </ng-option>
                  </ng-select>
                </td>
              </ng-container>
              <ng-container matColumnDef="debit">
                <th mat-header-cell *matHeaderCellDef>Debit</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field floatLabel="never">
                    <input
                      allowDecimal
                      matInput
                      [value]="element.debit"
                      autocomplete="off"
                      formControlName="debit"
                      (keypress)="
                        debitChanges(i, $event);
                        calAmount(i, openingBalanceExplain.Debit)
                      "
                      maxlength="{{ maxLength.maxLength10 }}"
                    />
                  </mat-form-field>
                </td>
              </ng-container>
              <ng-container matColumnDef="credit">
                <th mat-header-cell *matHeaderCellDef>Credit</th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field>
                    <input
                      allowDecimal
                      matInput
                      [value]="element.credit"
                      autocomplete="off"
                      formControlName="credit"
                      (keypress)="
                        creditChanges(i, $event);
                        calAmount(i, openingBalanceExplain.Credit)
                      "
                      maxlength="{{ maxLength.maxLength10 }}"
                    />
                  </mat-form-field>
                </td>
              </ng-container>
              <ng-container matColumnDef="closeButton">
                <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                  class="text-align-center"
                >
                  <div
                    class="d-flex align-items-center justify-content-center gap-10"
                  >
                    <button
                      class="close-btn"
                      type="button"
                      mat-button
                      disableRipple
                      title="Delete"
                      (click)="onDeleteOpeningBalanceExplain(i)"
                    >
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="
                  displayOpeningBalanceExplainColumns;
                  sticky: true
                "
              ></tr>
              <tr
                mat-row
                *matRowDef="
                  let row;
                  columns: displayOpeningBalanceExplainColumns
                "
              ></tr>
            </table>
          </ng-container>
        </form>
      </div>
      <div class="mb-1 mt-1 d-flex justify-space-between">
        <div class="mt-1">
          <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
            >Add New Line</a
          >
          <a
            class="link mr-1"
            *ngIf="openingBalanceExplainArray.length > 0"
            href="javascript:void(0)"
            (click)="clearForm()"
            >Clear All Lines</a
          >
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave()"
    >
      Save
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onCancel()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
