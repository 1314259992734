<div class="content client-list">
  <div
    class="content-body"
    [ngClass]="{ 'pl-5 pr-5': moduleId === moduleEnum.FixedAssetsRegister }"
  >
    <div class="content-header wp-100 mb-1">
      <div class="content-header-input d-flex wp-100 gap-10 align-items-end">
        <app-transactions-header
          [hidden]="moduleId === moduleEnum.VAT"
          (triggerDataFromHeader)="getDataFromHeader($event)"
          (triggerQuickAdd)="isQuickAdd($event)"
          [listParameters]="listParameters"
          [isCustom]="isCustom"
          [customId]="customId"
        >
        </app-transactions-header>
        <div class="header-actions wp-100">
          <app-header-actions class="wp-100">
            (exportClick)="export($event, false)"
            (dateChanged)="dateChanged($event)"
            (triggerDataFromHeader)="getDataFromHeader($event)"
            [listParameters]="listParameters" [parentModuleId]="(moduleId$ |
            async)"
          </app-header-actions>
        </div>
      </div>
    </div>
    <div [hidden]="moduleId !== moduleEnum.BankDashboard">
      <app-bank-dashboard
        (triggerDataFromBank)="getDataFromBank($event)"
        (triggerIsCustomFlag)="checkIsCustom($event)"
      ></app-bank-dashboard>
    </div>
    <div
      class="mat-table-wrapper"
      [ngClass]="[
        totalSelectedRecords > 0 ? 'mat-table-wrapper-full' : '',
        showPaginator
          ? 'mat-table-wrapper-pagination'
          : 'mat-table-wrapper-pagination-full',
        (totalRecord$ | async) <= 10 ? 'mat-table-wrapper-full-view' : '',
        moduleId === moduleEnum.BankDashboard && isExpanded
          ? 'mt-1 mat-table-wrapper-accordian'
          : '',
        moduleId === moduleEnum.BankDashboard && !isExpanded
          ? 'mat-table-wrapper-accordian-full'
          : '',
        moduleId === moduleEnum.BankDashboard && !showPaginator
          ? 'mat-table-wrapper-accordian-pagination'
          : '',
        moduleId === moduleEnum.BankDashboard && !isExpanded && !showPaginator
          ? 'mat-table-wrapper-accordian-full-view'
          : ''
      ]"
    >
      <table
        aria-describedby="main-list"
        mat-table
        matSort
        (matSortChange)="sorting(sort.active, sort.direction)"
        [dataSource]="dataSource"
      >
        <ng-container
          *ngFor="let col of columns$ | async; let colIndex = index"
          matColumnDef="{{ col.name }}"
        >
          <th mat-header-cell *matHeaderCellDef [id]="col.name" fxFlex="50">
            <div class="d-flex align-items-center">
              <span *ngIf="checkBoxValue !== 0">
                <mat-checkbox
                  [(ngModel)]="isAllSelected"
                  (change)="selectAll($event)"
                  *ngIf="colIndex === 1 && moduleId !== moduleEnum.VatSettings"
                >
                </mat-checkbox>
              </span>
              <span mat-sort-header [disabled]="!col.name">
                {{ col.name }}
              </span>
            </div>
          </th>

          <td mat-cell *matCellDef="let element; let i = index">
            <div
              class="d-flex align-items-center button-wrapper"
              [ngClass]="
                colIndex === (columns$ | async).length - 1
                  ? 'justify-space-between'
                  : ''
              "
            >
              <mat-checkbox
                [ngClass]="
                  moduleId === moduleEnum.VatSettings &&
                  element[dataSource.data.length - 1] !== '0'
                    ? 'disable-checkbox'
                    : ''
                "
                *ngIf="colIndex === 1"
                [(ngModel)]="element.isSelected"
                (change)="onCheckBoxSelected(element, $event)"
              >
              </mat-checkbox>
              <div class="clamp">
                <span
                  *ngIf="col.dataType === dataType.String"
                  title="{{ element[colIndex] }}"
                >
                  {{ element[colIndex] }}</span
                >

                <span
                  *ngIf="col.dataType === dataType.Bool"
                  title="{{ element[colIndex] }}"
                  [ngClass]="
                    element[colIndex] === 'Active' ||
                    element[colIndex] === 'Paid'
                      ? 'status-active'
                      : 'status-archived'
                  "
                >
                  {{ element[colIndex] }}</span
                >

                <span
                  *ngIf="col.dataType === dataType.DateTime"
                  title="{{ element[colIndex] }}"
                >
                  {{ element[colIndex] | date: "dd-LLL-yyyy" }}</span
                >

                <span
                  *ngIf="
                    col.dataType === dataType.Int32 ||
                    col.dataType === dataType.Currency
                  "
                  title="{{ element[colIndex] | numberPipe }}"
                >
                  £ {{ element[colIndex] | numberPipe }}</span
                >

                <span *ngIf="col.dataType === dataType.Blob">
                  <button
                    mat-button
                    (click)="onAddClick(element)"
                    class="fw-bold text-primary"
                    disableRipple
                  >
                    <mat-icon>add_circle_outline</mat-icon>
                  </button></span
                >
              </div>

              <div
                *ngIf="colIndex === (columns$ | async).length - 1"
                class="grid-actions"
                [ngClass]="selectedRowIndex === i ? 'show-button' : ''"
              >
                <div
                  class="d-flex align-items-center"
                  *ngFor="
                    let item of actions$ | async;
                    let permissionsIndex = index
                  "
                >
                  <span
                    *ngIf="
                      (moduleId === moduleEnum.VatSettings &&
                        element[dataSource.data.length - 1] === '0') ||
                      moduleId !== moduleEnum.VatSettings
                    "
                  >
                    <button
                      *ngIf="
                        (permissionsIndex < moreActionCount &&
                          moduleId !== moduleEnum.FixedAssetsRegister) ||
                        (moduleId === moduleEnum.BridgingVAT &&
                          element[element.length - 1] === 'False') ||
                        (moduleId === moduleEnum.SubmitVat &&
                          element[element.length - 1] === 'False')
                      "
                      mat-button
                      disableRipple
                      title="{{ item.name }}"
                      (click)="onButtonClick(element, item.actionType)"
                    >
                      <mat-icon fontSet="material-icons-outlined">{{
                        item.icon
                      }}</mat-icon>
                    </button>
                  </span>
                  <button
                    *ngIf="moduleId === moduleEnum.FixedAssetsRegister"
                    type="button"
                    mat-button
                    disableRipple
                    title="{{ item.name }}"
                    [disabled]="
                      (item.name === 'Sell' &&
                        element[element.length - 2] !== 'True') ||
                      (item.name === 'Dispose' &&
                        element[element.length - 2] !== 'True')
                    "
                    (click)="onTOCButtonClick(element, item.actionType)"
                  >
                    <mat-icon>{{ item.icon }}</mat-icon>
                  </button>
                  <button
                    mat-button
                    class="more-button"
                    (click)="onToggleMatMenu(i)"
                    [matMenuTriggerFor]="menu"
                    (menuClosed)="allowCloseOnClickOut()"
                    disableRipple
                  >
                    <mat-icon *ngIf="permissionsIndex === moreActionCount"
                      >more_vert</mat-icon
                    >
                  </button>
                </div>
                <mat-menu
                  #menu="matMenu"
                  xPosition="before"
                  class="more-button-menu"
                >
                  <ng-container
                    *ngFor="
                      let item of actions$ | async;
                      let permissionsIndex = index
                    "
                  >
                    <div *ngIf="permissionsIndex > 2">
                      <button
                        mat-menu-item
                        disableRipple
                        title="{{ item.name }}"
                        (click)="onButtonClick(element, item.actionType)"
                      >
                        <mat-icon fontSet="material-icons-outlined">{{
                          item.icon
                        }}</mat-icon>
                        <span>{{ item.name }} </span>
                      </button>
                    </div>
                  </ng-container>
                </mat-menu>
              </div>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="columnsNames$ | async; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columnsNames$ | async"
          [ngClass]="isRowHighlighted ? 'highlightRow' : ''"
        ></tr>
      </table>
      <div
        *ngIf="dataSource.data.length === 0 && noDataFound"
        class="text-align-center mt-1 fw-bold"
      >
        No records found
      </div>
    </div>
  </div>
  <div
    class="paginator-wrapper"
    *ngIf="totalSelectedRecords <= 0 && (totalRecord$ | async) > 0"
  >
    <app-custom-paginator
      *ngIf="(totalRecord$ | async) > 10"
      [length]="totalRecord$ | async"
      [listParameters]="listParameters"
      (pageChanged)="pageChanged($event)"
      (pageSizeVal)="pageSizeVal($event)"
      (togglePaginator)="togglePaginator($event)"
      [triggerPaginationChange]="triggerPaginationChange"
    >
    </app-custom-paginator>
  </div>
</div>

<app-card-footer
  *ngIf="totalSelectedRecords > 0"
  [totalSelectedRecords]="totalSelectedRecords"
  (deleteClick)="deleteClick()"
  (exportClick)="export($event, false)"
  (copyClick)="copyClick()"
  (cancelSelectionClick)="cancelSelectionClick()"
  (printClick)="printClick()"
  (archiveAndRestoreClick)="archiveAndRestoreClick($event)"
  [getModuleId]="moduleId"
  (emailClick)="emailClick()"
  [triggerSelectedRowData]="triggerSelectedRowData"
  [ngClass]="
    this.listParameters.filter === filteredStatus.Archive
      ? 'archive-records'
      : ''
  "
></app-card-footer>
<ngx-spinner
  template="<img class='loader-image mr-1' src='assets/images/loader.svg' />
  <span class='loader'></span>"
></ngx-spinner>
