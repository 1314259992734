<div class="content-body">
  <form [formGroup]="userForm">
    <div class="form-field attached">
      <p>Profile Image</p>
      <img
        class="mr-1"
        [src]="url"
        *ngIf="url"
        height="100"
        width="100px"
        alt="Logo"
      />
      <a class="link hyperlink" (click)="deletePhoto()" *ngIf="isImageSelected"
        >Delete Photo</a
      >
      <div class="attach-file" [ngClass]="isImageSelected ? 'hidden' : ''">
        <mat-icon fontSet="material-icons-outlined">cloud_upload</mat-icon>
        <div class="attach-file-label">
          <input
            #fileupload
            id="selectFile"
            type="file"
            accept="{{ accept }}"
            accept="image/jpg,image/png,image/jpeg"
            (change)="onFileSelected($event)"
            autocomplete="off"
            maxlength="100"
            formControlName="photo"
            class="cursor-pointer"
          />
          <label for="file" class="cursor-pointer"
            ><strong>Choose a file</strong> or drag it here</label
          >
        </div>
      </div>
    </div>
    <div class="d-flex gap-30">
      <div class="form-field">
        <p>First Name <span class="text-danger-300">*</span></p>
        <div class="form-field-name d-flex">
          <ng-select
            class="w-80 mr-10"
            formControlName="title"
            [readonly]="isUserActive"
            (change)="onSelectionChange($event)"
          >
            <ng-option
              *ngFor="let prefix of titleList | useEnumValues"
              [value]="prefix.value"
              >{{ prefix.value }}</ng-option
            >
          </ng-select>
          <mat-form-field appearance="fill">
            <input
              matInput
              cdkFocusInitial
              formControlName="firstName"
              autocomplete="off"
              required
              maxlength="255"
              (keyup)="onKeyUpEvent()"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="form-field">
        <p>Middle Name</p>
        <mat-form-field appearance="fill" class="w-295">
          <input
            matInput
            formControlName="middleName"
            autocomplete="off"
            maxlength="255"
            (keyup)="onKeyUpEvent()"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Last Name</p>
        <mat-form-field appearance="fill" class="w-295">
          <input
            matInput
            formControlName="lastName"
            autocomplete="off"
            maxlength="255"
            (keyup)="onKeyUpEvent()"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-30">
      <div class="form-field">
        <div class="form-field">
          <p>Email Id<span class="text-danger-300">*</span></p>
          <mat-form-field appearance="fill" class="w-295">
            <input
              matInput
              type="email"
              formControlName="email"
              autocomplete="off"
              required
              maxlength="320"
              (keyup)="onEmailKeyUpEvent()"
            />
          </mat-form-field>
        </div>
      </div>
      <div>
        <div
          class="form-field align-items-baseline"
          *ngIf="getFormControls.permissions.value !== authorizedRole.Owner"
        >
          <p>Permissions <span class="text-danger-300">*</span></p>
          <div class="form-field permissions m-0">
            <ng-select
              class="w-295"
              #permissions
              [disableOptionCentering]="true"
              (focus)="permissions.open()"
              (valueChange)="permissions.close()"
              formControlName="permissions"
              dropdownPosition="top"
              [ngClass]="{
                'is-invalid':
                  isFormSubmitted && getFormControls.permissions.errors
              }"
            >
              <ng-option *ngFor="let pl of permissionList" [value]="pl.id">
                {{ pl.name }}</ng-option
              >
            </ng-select>
          </div>
        </div>
        <div
          class="form-field"
          *ngIf="getFormControls.permissions.value === authorizedRole.Owner"
        >
          <p>Permissions</p>
          <div class="side-info">
            <mat-form-field appearance="fill" class="w-295">
              <input
                class="text-lightgray"
                matInput
                value="Owner"
                autocomplete="off"
                disabled
              />
            </mat-form-field>
          </div>
        </div>
        <div
          class="mb-1 mt-0 d-flex align-items-center gap-5 permission-click"
          *ngIf="
            loggedInUserId !== userId && permissionId !== authorizedRole.Owner
          "
        >
          <a
            href="javascript:void(0)"
            (click)="openDialog()"
            class="d-flex align-items-center"
          >
            <mat-icon
              fontSet="material-icons-outlined"
              title="edit"
              class="text-primary"
              >edit</mat-icon
            >
            <span class="hyperlink"> Click here</span>
          </a>
          to edit user permission
        </div>
      </div>
    </div>    

    <div class="content-box hr" [ngClass]="isUserActive ? 'is-readonly' : ''">
      <div class="form-field-title mb-1">
        <p>Contact Details</p>
      </div>
      <div class="sidenav-table sidenav-table-input wp-100">
        <div class="mat-table-wrapper contact-detail-table">
          <form [formGroup]="formContactDetail">
            <ng-container formArrayName="contactDetailArray">
              <table
                mat-table
                [dataSource]="contactTableDataSource"
                aria-describedby="User Contact Table"
              >
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef class="w-200">
                    Full Name
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <div class="d-flex">
                      <ng-select
                        #selectType
                        (focus)="selectType.open()"
                        (valueChange)="selectType.close()"
                        formControlName="title"
                        class="w-80 mr-10"
                        [readonly]="true"
                      >
                        <ng-option
                          *ngFor="let prefix of titleList | useEnumValues"
                          [value]="prefix.key"
                          >{{ prefix.value }}
                        </ng-option>
                      </ng-select>
                      <mat-form-field floatLabel="never" class="w-200 light-bg">
                        <input
                          matInput
                          [value]="element.name"
                          formControlName="name"
                          maxlength="255"
                          autocomplete="off"
                          [readonly]="true"
                        />
                      </mat-form-field>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef>Email</th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                    class="widthemail"
                  >
                    <mat-form-field floatLabel="never" class="wp-100 light-bg">
                      <input
                        matInput
                        [value]="element.email"
                        autocomplete="off"
                        formControlName="email"
                        maxlength="320"
                        [readonly]="true"
                      />
                    </mat-form-field>
                  </td>
                </ng-container>
                <ng-container matColumnDef="phone">
                  <th mat-header-cell *matHeaderCellDef class="w-300">Phone</th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <div class="d-flex">
                      <ng-select
                        #selectPhoneCode
                        (focus)="selectPhoneCode.open()"
                        (valueChange)="selectPhoneCode.close()"
                        formControlName="country"
                        class="w-100 mr-10"
                      >
                        <ng-option
                          *ngFor="let cl of countryList"
                          [value]="cl.phoneCode"
                          >{{ cl.phoneCode }}
                        </ng-option>
                      </ng-select>
                      <mat-form-field floatLabel="never" class="w-300">
                        <input
                          matInput
                          [value]="element.phone"
                          autocomplete="off"
                          type="tel"
                          minlength="0"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          maxlength="15"
                          formControlName="phone"
                        />
                      </mat-form-field>
                    </div>
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayContactDetailColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayContactDetailColumns"
                ></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td
                    class="mat-cell"
                    [ngClass]="'text-align-center'"
                    [attr.colspan]="displayContactDetailColumns.length"
                  >
                    {{ commonNotificationText.NoRecordFoundText }}
                  </td>
                </tr>
              </table>
            </ng-container>
          </form>
        </div>
      </div>
    </div>

    <div class="content-box hr" [ngClass]="isUserActive ? 'is-readonly' : ''">
      <div class="form-field-title">
        <p>Address Details</p>
        <span class="font-size-14">This will show as User Address.</span>
      </div>
      <div class="sidenav-table sidenav-table-input wp-100">
        <div class="mat-table-wrapper contact-detail-table">
          <form [formGroup]="formAddressDetail">
            <ng-container formArrayName="addressDetailArray">
              <table
                mat-table
                [dataSource]="addressTableDataSource"
                aria-describedby="User Addresses Table"
              >
                <ng-container matColumnDef="addressType">
                  <th mat-header-cell *matHeaderCellDef class="w-100"></th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <ng-select
                      #addresstype
                      (focus)="addresstype.open()"
                      (blur)="addresstype.close()"
                      class="wp-100 detail-column"
                      panelClass="myPanelClass"
                      [disableOptionCentering]="true"
                      formControlName="addressType"
                    >
                      <ng-option value="Business">Business</ng-option>
                      <ng-option value="Postal">Postal</ng-option>
                      <ng-option value="Personal">Personal</ng-option>
                      <ng-option value="Registered">Registered</ng-option>
                    </ng-select>
                  </td>
                </ng-container>
                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef>Address</th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                    class="widthemail"
                  >
                    <mat-form-field floatLabel="never" class="wp-100">
                      <input
                        matInput
                        [value]="element.address"
                        autocomplete="off"
                        formControlName="address"
                        maxlength="1000"
                      />
                    </mat-form-field>
                  </td>
                </ng-container>
                <ng-container matColumnDef="city">
                  <th mat-header-cell *matHeaderCellDef>City</th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <mat-form-field floatLabel="never" class="wp-100">
                      <input
                        matInput
                        [value]="element.city"
                        autocomplete="off"
                        formControlName="city"
                        maxlength="100"
                      />
                    </mat-form-field>
                  </td>
                </ng-container>
                <ng-container matColumnDef="county">
                  <th mat-header-cell *matHeaderCellDef>County</th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <mat-form-field floatLabel="never" class="wp-100">
                      <input
                        matInput
                        [value]="element.county"
                        autocomplete="off"
                        formControlName="county"
                        maxlength="255"
                      />
                    </mat-form-field>
                  </td>
                </ng-container>
                <ng-container matColumnDef="country">
                  <th mat-header-cell *matHeaderCellDef class="w-150">
                    Country
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <ng-select
                      #country
                      (focus)="country.open()"
                      (blur)="country.close()"
                      class="w-150 detail-column"
                      panelClass="myPanelClass"
                      [disableOptionCentering]="true"
                      formControlName="country"
                    >
                      <ng-option *ngFor="let cl of countryList" [value]="cl.id"
                        >{{ cl.description }}
                      </ng-option>
                    </ng-select>
                  </td>
                </ng-container>
                <ng-container matColumnDef="postalCode">
                  <th mat-header-cell *matHeaderCellDef class="w-100">
                    ZIP/Post Code
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let element; let i = index"
                    [formGroupName]="i"
                  >
                    <mat-form-field floatLabel="never" class="w-100">
                      <input
                        matInput
                        [value]="element.postalCode"
                        autocomplete="off"
                        type="tel"
                        minlength="0"
                        maxlength="10"
                        formControlName="postalCode"
                      />
                    </mat-form-field>
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayAddressDetailColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayAddressDetailColumns"
                ></tr>
              </table>
            </ng-container>
          </form>
          <div
            class="text-align-center space-top"
            *ngIf="!addressDetailArray.length"
          >
            {{ commonNotificationText.NoRecordFoundText }}
          </div>
        </div>
      </div>
    </div>

    <div class="hr pt-1 mt-1">
      <div class="form-field mb-0">
        <p>Assign to client</p>
        <mat-checkbox
          class="example-margin"
          formControlName="addToAllFutureClients"
          >Assign this to all existing client
        </mat-checkbox>
        <br />
        <mat-checkbox
          class="example-margin"
          formControlName="addToAllExistingClients"
          >Assign this all future client
        </mat-checkbox>
      </div>
    </div>
  </form>
</div>
