import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GroupNames, ModuleName, Modules, OverviewYear } from '@app/core/Enum';
import { ReportFilterTypes } from '@app/core/Enum/report-filter-type';
import {
  FilterDateRange,
  MainListParameters,
  SideListModel,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetBranchList,
  GetChartOfAccountTypeList,
  GetDepartmentListByBranchIds,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-expenditure-overview',
  templateUrl: './expenditure-overview.component.html',
  styleUrls: ['./expenditure-overview.component.scss'],
})
export class ExpenditureOverviewComponent implements OnInit {
  moduleId = Modules.ExpenseOverview;

  @Output()
  readonly triggerHeaderFilterForExpenseBreakdown = new EventEmitter<any>();

  @Output()
  readonly triggerHeaderFilter = new EventEmitter<any>();

  filter1 = ReportFilterTypes.Last30Days;
  rangeFormGroup: FormGroup;
  selectYear = OverviewYear;
  selectedYearValue = OverviewYear['This year'];

  expenseBreakdownList: SideListModel[];
  expenseBreakdownId: number;

  showCalender = false;
  dateRange: any;
  module = Modules;
  moduleName = ModuleName;

  @ViewChild('branch')
  public mulObj: MultiSelectComponent;
  public branchmode: string;
  public branchfilterPlaceholder: string;
  public branchFields: Object = { text: 'name', value: 'id' };
  public branchWaterMark: string = 'Branch - All';
  public branchpopHeight: string = '350px';

  public departmentmode: string;
  public departmentfilterPlaceholder: string;
  public departmentFields: Object = { text: 'name', value: 'id' };
  public departmentWaterMark: string = 'Department - All';
  public departmentpopHeight: string = '350px';

  selectedBranchValues: any[] = [];
  selectedDepartmentValues: any[] = [];
  departmentList: any[] = [];
  branchList: any[] = [];
  departmentListParameters: MainListParameters = new MainListParameters();
  branchListParameters: MainListParameters = new MainListParameters();

  constructor(
    public datepipe: DatePipe,
    private formBuilder: FormBuilder,
    private store: Store,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.dateRange = this.commonService.generateDateSeries(
      this.selectedYearValue
    );
    this.setForm();
    this.branchmode = 'CheckBox';
    this.branchfilterPlaceholder = 'Search Branch';
    this.departmentmode = 'CheckBox';
    this.departmentfilterPlaceholder = 'Search Department';
    this.getBranchList();
  }

  getDepartmentListByBranchIds(): void {
    this.store
      .dispatch(new GetDepartmentListByBranchIds(this.selectedBranchValues))
      .subscribe((res) => {
        this.departmentList = [];
        if (res.company.departmentListbyIds.length > 0) {
          res.company.departmentListbyIds.forEach((element) => {
            if (this.departmentList.length === 0) {
              this.departmentList.push(element);
            } else {
              const isExist = this.departmentList.filter(
                (x) => x.id === element.id
              );
              if (isExist.length === 0) {
                this.departmentList.push(element);
              }
            }
          });
        }
      });
  }

  onDepartmentMultiSelectChange(event: any): void {
    this.selectedDepartmentValues = event.value;

    this.onSelectionChange(this.selectedYearValue);
  }

  onBranchMultiSelectChange(event: any): void {
    this.selectedBranchValues = event.value;
    if (this.selectedBranchValues.length > 0) {
      this.getDepartmentListByBranchIds();
    } else {
      this.departmentList = [];
      this.selectedDepartmentValues = [];
    }

    this.onSelectionChange(this.selectedYearValue);
  }

  getBranchParamaters(): any {
    const queryParams = {
      sortBy: this.branchListParameters.sortBy,
      sortOrder: this.branchListParameters.sortOrder,
      search: this.branchListParameters.search,
    };

    return queryParams;
  }

  getBranchList(): void {
    this.store
      .dispatch(new GetBranchList(this.getBranchParamaters()))
      .pipe(
        tap((res) => {
          this.branchList = [];
          res.company.branch.forEach((element) => {
            this.branchList.push(element);
          });
        })
      )
      .subscribe();
  }

  setForm(): void {
    this.getChartOfAccountTypeList();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.rangeFormGroup = this.formBuilder.group({
      start: [startDate],
      end: [new Date()],
    });
  }

  onSelectionChange(val: any): void {
    this.showCalender = +val === 7;

    this.dateRange = this.commonService.generateDateSeries(val);
    if (
      this.showCalender &&
      this.rangeFormGroup.controls.start.value !== null &&
      this.rangeFormGroup.controls.end.value !== null
    ) {
      this.dateRange = new FilterDateRange();
      this.dateRange.startDate = new Date(
        this.rangeFormGroup.controls.start.value
      );
      this.dateRange.endDate = new Date(this.rangeFormGroup.controls.end.value);
      this.getData();
    } else if (!this.showCalender) {
      this.getData();
    }
  }

  getChartOfAccountTypeList(): void {
    this.store
      .dispatch(new GetChartOfAccountTypeList(GroupNames.CostOfSales))
      .pipe(
        tap((res) => {
          this.expenseBreakdownList = res.account.chartOfAccountTypeList;
          this.expenseBreakdownId = this.expenseBreakdownList[0].id;
          this.getData();
        })
      )
      .subscribe();
  }

  onExpenseBreakdownChangeForExpensesBreakDown(): void {
    const data = this.getFilter();
    data.moduleId = this.expenseBreakdownId;
    this.triggerHeaderFilterForExpenseBreakdown.emit(data);
  }

  getFilter(): any {
    const filterData = {
      moduleId: 0,
      dateFilter: {
        branchIds: this.selectedBranchValues ?? [],
        departmentIds: this.selectedDepartmentValues ?? [],
        filter: this.selectedYearValue,
        startDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(
                  this.rangeFormGroup.controls.start.value,
                  'yyyy-MM-dd'
                )
                ?.toString(),

        endDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(this.rangeFormGroup.controls.end.value, 'yyyy-MM-dd')
                ?.toString(),
      },
    };

    return filterData;
  }

  getData(): void {
    const data = this.getFilter();

    setTimeout(() => {
      this.triggerHeaderFilter.emit(data);
      data.moduleId = this.expenseBreakdownId;
      this.triggerHeaderFilterForExpenseBreakdown.emit(data);
    }, 500);
  }
}
