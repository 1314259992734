<div class="content-body">
  <form [formGroup]="fixedAssetsForm">
    <div class="add-form-wrap">
      <div class="form-field fund-name" *ngIf="branchList.length > 0">
        <p>Branch <span class="text-danger-300">*</span></p>
        <ng-select
          #branch
          (focus)="branch.open()"
          (valueChange)="branch.close()"
          [disableOptionCentering]="true"
          formControlName="branchId"
          dropdownPosition="bottom"
          (change)="onChangeBranch($event, false)"
        >
          <ng-option *ngFor="let bl of branchList" [value]="bl.id">
            <div title="{{ bl.name }}">{{ bl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="departmentList.length > 0">
        <p>Department <span class="text-danger-300">*</span></p>
        <ng-select
          #department
          (focus)="department.open()"
          (valueChange)="department.close()"
          [disableOptionCentering]="true"
          formControlName="departmentId"
          dropdownPosition="bottom"
        >
          <ng-option *ngFor="let dl of departmentList" [value]="dl.id">
            <div title="{{ dl.name }}">{{ dl.name }}</div>
          </ng-option>
        </ng-select>
      </div>

      <div class="form-field">
        <p>Purchase No. <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="billNo"
            autocomplete="off"
            required
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
            appAutoFocusDirective
          />
        </mat-form-field>
      </div>
      <div class="form-field datepicker">
        <p>Purchase Date<span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="invoiceDate"
            [matDatepicker]="invoiceDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            tabindex="-1"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="invoiceDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #invoiceDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              fixedAssetsForm.get('invoiceDate')
            ).invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              fixedAssetsForm.get('invoiceDate')
            ).lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>

      <!-- <div class="form-field">
        <p>Fixed Asset Type <span class="text-danger-300">*</span></p>
        <ng-select
          #invoiceType
          [items]="fixedAssetsTypeList"
          (focus)="invoiceType.open()"
          (valueChange)="invoiceType.close()"
          [disableOptionCentering]="true"
          formControlName="invoiceType"
          bindLabel="name"
          bindValue="id"
          groupBy="groupName"
          panelClass="mat-select-position"
          appendTo="body"
        >
          <ng-template ng-option-tmp let-item="item">
            <span class="ng-option-label" [attr.title]="item.name">{{
              item.name
            }}</span>
          </ng-template>
        </ng-select>
      </div> -->
      <div class="form-field datepicker">
        <p>Due Date<span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="dueDate"
            [matDatepicker]="dueDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            tabindex="-1"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dueDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #dueDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(fixedAssetsForm.get('dueDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(fixedAssetsForm.get('dueDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>

      <div class="form-field quick-add-buttons">
        <div class="d-flex justify-space-between">
          <p>Supplier Name <span class="text-danger-300">*</span></p>
          <span>
            <button
              mat-button
              disableRipple
              [disabled]="!isSupplierChangePermission"
            >
              <mat-icon (click)="gotoAddSupplier()" title="add"
                >add_circle_outline</mat-icon
              >
            </button>
            <button
              [disabled]="disabledEditButton || !isSupplierChangePermission"
              mat-button
              disableRipple
            >
              <mat-icon
                (click)="gotoEditSupplier()"
                fontSet="material-icons-outlined"
                title="edit"
                >edit</mat-icon
              >
            </button>
          </span>
        </div>
        <ng-select
          #supplierName
          (focus)="supplierName.open()"
          (valueChange)="supplierName.close()"
          [disableOptionCentering]="true"
          formControlName="accountId"
          panelClass="mat-select-position"
          (change)="supplierChange($event)"
        >
          <ng-option *ngFor="let cl of supplierList" [value]="cl.id">
            <span class="clamp" title="{{ cl.name }}">{{ cl.name }}</span>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>Currency</p>
        <ng-select
          #currency
          (focus)="currency.open()"
          (valueChange)="currency.close()"
          [disableOptionCentering]="true"
          formControlName="currencyId"
          dropdownPosition="top"
          panelClass="mat-select-position"
          (change)="currencyChange($event)"
          required
        >
          <ng-option
            *ngFor="let cl of clientCurrencyList"
            [value]="cl.currencyId"
          >
            <div title="{{ cl.currency }}">
              {{ cl.currency }}
            </div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="isMultiCurrencySelected">
        <p>&nbsp;</p>
        <mat-form-field class="w-140 big-inputbox">
          <input
            matInput
            formControlName="currencyAmt"
            allowDecimal
            autocomplete="off"
            (change)="onCurrencyRateEntered()"
          />
          <button
            mat-button
            type="button"
            disableRipple
            matSuffix
            (click)="onCurrencyRefresh()"
          >
            <mat-icon>refresh</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex mr-40">
      <div
        class="form-field flex-column justify-contents-center"
        *ngIf="isVatRegistered"
      >
        <div>
          <mat-checkbox
            formControlName="isVatInclude"
            (change)="onCheckBoxSelected($event)"
          >
            Amount Including VAT
          </mat-checkbox>
        </div>
        <!-- <div>
          <mat-checkbox
            formControlName="isVatPostponed"
            (change)="isVatPostponedChecked($event)"
          >
            Apply CIS VAT Domestic Reverse Charge
          </mat-checkbox>
        </div> -->
      </div>
    </div>
    <div class="form-field textarea">
      <p>Notes</p>
      <mat-form-field>
        <textarea
          matInput
          formControlName="note"
          autocomplete="off"
          maxlength="{{ maxLength.maxLength2000 }}"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
