import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Guid } from 'guid-typescript';
import { DataSecurityModel } from '@app/core/Models';

@Injectable({
  providedIn: 'root',
})
export class DataSecurityService {
  constructor(private http: HttpClient) {}

  saveDataSecurity(dataSecurity: DataSecurityModel): Observable<Guid> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Guid>(
      `${environment.apiVersionUrl}DataSecurity/saveDataSecurity`,
      JSON.stringify(dataSecurity),
      headers
    );
  }

  getDataSecurityDetails(companyId: Guid): Observable<DataSecurityModel> {
    return this.http.get<DataSecurityModel>(
      `${environment.apiVersionUrl}DataSecurity/getDataSecurityDetails/${companyId}`
    );
  }
}
