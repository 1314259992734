<div class="mat-dialog-wrapper">
  <mat-dialog-content>
    <form [formGroup]="attachmentForm">
      <div>
        <div class="form-field attached">
          <p>Attach</p>
          <div
            class="attachFile"
            draggable="true"
            ngClass="{{ dragAreaClass }}"
          >
            <mat-icon fontSet="material-icons-outlined">cloud_upload</mat-icon>
            <input
              #input
              multiple
              type="file"
              [accept]="acceptedFileTypes"
              formControlName="attachment"
              autocomplete="off"
              (change)="onFileSelected($event)"
              id="file"
              maxlength="{{ maxLength.maxLength100 }}"
            />

            <label for="file"><strong>Drop a file/image here</strong></label>
          </div>
        </div>
      </div>
      <div *ngIf="showTable" class="note-table">
        <table aria-describedby="attachment-list" class="table-border">
          <tr>
            <th></th>
          </tr>
          <tr
            class="clickable"
            *ngFor="let file of fileUploadResponse; let i = index"
          >
            <td>
              <span class="clamp" title="{{ file.fileName }}">{{
                file.fileName
              }}</span>
            </td>
            <td class="text-align-right">
              <button
                class="mr-5"
                type="button"
                mat-button
                disableRipple
                (click)="dowloadFile(file.fileUrl)"
              >
                <mat-icon class="text-gray-100">get_app</mat-icon>
              </button>
              <button
                type="button"
                mat-button
                disableRipple
                (click)="removeFile(i)"
              >
                <mat-icon class="text-danger-300">highlight_off</mat-icon>
              </button>
            </td>
          </tr>
        </table>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave()"
    >
      Save changes
    </button>
    <button
      class="action-button secondary-button"
      type="button"
      (click)="onCancel()"
      mat-button
      disableRipple
    >
      Clear Form
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
