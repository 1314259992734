<div class="content-body-scroll">
  <div
    [ngClass]="[
      moduleId === moduleEnum.VAT || this.showAccountDetails
        ? 'content-scrol'
        : 'content',
      showAccountDetails ? 'content-account-details mb-4' : ''
    ]"
    class="main-list content-body"
  >
    <div>
      <div [hidden]="!showAccountDetails" class="content-body">
        <app-report-header-details
          [triggerCustomerId]="triggerCustomerId"
        ></app-report-header-details>
      </div>

      <!--Start Report list Grid Implementation -->
      <div class="report-grid" *ngIf="isReport && !isTreeList">
        <div class="d-flex justify-space-between align-items-end wp-100 mb-1">
          <app-transactions-header
            #headerRef
            (triggerQuickAdd)="isQuickAdd($event)"
            [isCustom]="isCustom"
            [customId]="customId"
            [listParameters]="listParameters"
            [showAddButton]="showAddButton"
            [transactionModuleId]="moduleId"
            [showAccountDetails]="showAccountDetails"
            [isBankImport]="isBankImport"
          >
          </app-transactions-header>

          <div class="grid-search mr-10">
            <mat-form-field
              *ngIf="
                moduleId !== moduleEnum.TrialBalance &&
                moduleId !== moduleEnum.AccountDetails &&
                moduleId !== moduleEnum.BalanceSheet &&
                moduleId !== moduleEnum.BalanceSheetComparison &&
                moduleId !== moduleEnum.ProfitAndLoss &&
                moduleId !== moduleEnum.BalanceSheetDetail &&
                moduleId !== moduleEnum.VAT
              "
              appearance="fill"
              class="search-field"
            >
              <input
                matInput
                placeholder="Search here..."
                autocomplete="off"
                (change)="onSearch($event)"
              />

              <button mat-button disableRipple class="search-button">
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div
            class="header-actions wp-100"
            [ngClass]="{ 'header-actions-report': isReport }"
          >
            <app-header-actions
              [isCustom]="isCustom"
              [customId]="customId"
              (exportClick)="exportReport($event, false)"
              (dateChanged)="dateChanged($event)"
              (triggerDataFromHeader)="getDataFromHeader($event)"
              [listParameters]="listParameters"
              [showAccountDetails]="showAccountDetails"
              [transactionModuleId]="moduleId"
              [parentModuleId]="moduleId$ | async"
              [parentModuleName]="moduleName"
              [isBankImport]="isBankImport"
              [triggerResetBankModuleId]="triggerResetBankModuleId"
              (triggerQuickAdd)="isQuickAdd($event)"
              [removeBranchSelection]="removeBranchSelection"
              (triggerUpdateLossOfStock)="updateList()"
            >
            </app-header-actions>
          </div>
        </div>
        <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
          <mat-icon class="material-icons-outlined">error_outline</mat-icon>
          {{ commonNotificationText.ViewOnlyAccessMessage }}
        </div>
        <div
          *ngIf="
            moduleId !== moduleEnum.Customers &&
            moduleId !== moduleEnum.Suppliers &&
            moduleId !== moduleEnum.Invoices &&
            moduleId !== moduleEnum.RecurringInvoice &&
            moduleId !== moduleEnum.Quotation &&
            moduleId !== moduleEnum.CreditNote &&
            moduleId !== moduleEnum.Receipt &&
            moduleId !== moduleEnum.Purchase &&
            moduleId !== moduleEnum.RecurringPurchase &&
            moduleId !== moduleEnum.DebitNote &&
            moduleId !== moduleEnum.CISInvoice &&
            moduleId !== moduleEnum.Payment &&
            moduleId !== moduleEnum.Import &&
            moduleId !== moduleEnum.QuickEntry &&
            moduleId !== moduleEnum.Journals &&
            moduleId !== moduleEnum.BankTransfer &&
            moduleId !== moduleEnum.CIS300
          "
        >
          <p class="fw-bold mb-10 mt-10 font-size-20">{{ moduleName }}</p>
        </div>
        <ejs-grid
          *ngIf="columns.length > 0"
          #grid
          [dataSource]="gridData"
          [allowGrouping]="isReportGridGroupingAllowed(moduleId)"
          [groupSettings]="groupSettings"
          [allowReordering]="true"
          [allowSelection]="true"
          [allowPaging]="isReportGridPaginationAllowed(moduleId)"
          (checkBoxChange)="checkBoxChange($event)"
          (dataStateChange)="dataStateChange($event)"
          [pageSettings]="{
            pageSize: state.take,
            pageSizes: [5, 10, 20, 100],
            showInfo: true,
            showNavigationButtons: true,
            totalCount: totalRecords
          }"
          (rowDataBound)="rowDataBound($event)"
          [selectedRowIndex]="selectedRowData.id"
          (columnVisibilityChange)="onVisibilityChange($event)"
          [allowFiltering]="true"
          [allowResizing]="true"
          [allowSorting]="true"
          [selectionSettings]="{
            type: 'Multiple',
            checkboxOnly: true,
            allowColumnSelection: true
          }"
          [filterSettings]="{ type: 'Menu' }"
          showColumnMenu="true"
          [height]="gridHeight"
          [skip]="skip"
          (columnMenuClick)="columnMenuClick($event)"
          (queryCellInfo)="queryCellInfo($event)"
          [ngClass]="{
            'module-trial-balance-grid': moduleId === moduleEnum.TrialBalance,
            'module-account-details-grid':
              moduleId === moduleEnum.AccountDetails,
            'module-sales-report-grid':
              moduleId === moduleEnum.SalesReport ||
              moduleId === moduleEnum.PurchasesReport,
            'module-sales-product-list-grid':
              moduleId === moduleEnum.SalesProductList
          }"
        >
          <e-columns>
            <e-column
              *ngIf="
                (moduleId === moduleEnum.Customers ||
                  moduleId === moduleEnum.Suppliers ||
                  moduleId === moduleEnum.Invoices ||
                  moduleId === moduleEnum.RecurringInvoice ||
                  moduleId === moduleEnum.Quotation ||
                  moduleId === moduleEnum.CreditNote ||
                  moduleId === moduleEnum.Receipt ||
                  moduleId === moduleEnum.Purchase ||
                  moduleId === moduleEnum.RecurringPurchase ||
                  moduleId === moduleEnum.DebitNote ||
                  moduleId === moduleEnum.CISInvoice ||
                  moduleId === moduleEnum.Payment ||
                  moduleId === moduleEnum.QuickEntry ||
                  moduleId === moduleEnum.Journals ||
                  moduleId === moduleEnum.BankTransfer ||
                  moduleId === moduleEnum.Notes ||
                  moduleId === moduleEnum.FixedAssets ||
                  moduleId === moduleEnum.CIS300) &&
                !showAccountDetails
              "
              type="checkbox"
              width="45"
              headerText=""
              textAlign="Center"
            ></e-column>
            <ng-container *ngFor="let col of columns; let colIndex = index">
              <e-column
                [headerText]="col.name"
                [customAttributes]="{ class: getClassName(col) }"
                [field]="col.name + '.rowData'"
                [hidden]="!col.isSelected"
                [width]="columnWidth"
                [allowSorting]="true"
                [allowFiltering]="col.columnType"
                [allowGrouping]="isReportGridGroupingAllowed(moduleId)"
                [visible]="col.isSelected"
                [isPrimaryKey]="col.name === 'Id'"
                [showInColumnChooser]="
                  removeBranchSelection ||
                  moduleId === moduleEnum.ProfitAndLoss ||
                  moduleId === moduleEnum.BalanceSheet ||
                  moduleId === moduleEnum.ComparativeReport ||
                  moduleId === moduleEnum.VAT ||
                  col.name === 'Id'
                    ? false
                    : true
                "
              >
                <ng-template #template let-row let-rowIndex>
                  <div
                    *ngIf="
                      (col.dataType === dataType.String ||
                        col.dataType === dataType.Bool) &&
                      col.name !== 'Status'
                    "
                    class="{{ col.alignment }}"
                    [ngClass]="{
                      'fw-bold': row[col.name]?.isBold,
                      'pr-40 clamp': moduleId !== moduleEnum.ComparativeReport,
                      'status-active':
                        row[col.name]?.rowData === 'Paid' ||
                        row[col.name]?.rowData === 'Allocated',
                      'status-archived':
                        row[col.name]?.rowData === 'Due' ||
                        row[col.name]?.rowData === 'OverDue' ||
                        row[col.name]?.rowData === 'Unallocated'
                    }"
                  >
                    <span title="{{ row[col.name]?.rowData }}">
                      {{ row[col.name]?.rowData }}</span
                    >
                  </div>

                  <div
                    *ngIf="
                      col.dataType === dataType.DateTime &&
                      col.name !== 'Status'
                    "
                    class="{{ col.alignment }}"
                    [ngClass]="{
                      'fw-bold': row[col.name]?.isBold,
                      'pr-40 white-space-nowrap':
                        moduleId !== moduleEnum.ComparativeReport
                    }"
                  >
                    <span title="{{ row[col.name]?.rowData }}">
                      {{ row[col.name]?.rowData }}</span
                    >
                  </div>

                  <div
                    *ngIf="
                      col.dataType === dataType.Currency &&
                      col.name !== 'Status' &&
                      row[col.name]?.rowData !== undefined &&
                      row[col.name]?.rowData !== null &&
                      row[col.name]?.rowData !== ''
                    "
                    class="{{ col.alignment }} clamp pr-40 white-space-nowrap"
                    [ngClass]="{
                      'fw-bold': row[col.name]?.isBold
                    }"
                  >
                    <span title="£ {{ row[col.name]?.rowData | numberPipe }}">
                      £ {{ row[col.name]?.rowData | numberPipe }}</span
                    >
                  </div>

                  <div
                    *ngIf="
                      col.dataType === dataType.Percentage &&
                      col.name !== 'Status'
                    "
                    class="{{ col.alignment }} clamp pr-40 white-space-nowrap"
                    [ngClass]="{
                      'fw-bold': row[col.name]?.isBold
                    }"
                  >
                    <span title="{{ row[col.name]?.rowData | numberPipe }} %">
                      {{ row[col.name]?.rowData | numberPipe }} %</span
                    >
                  </div>

                  <div
                    *ngIf="
                      col.dataType === dataType.Int32 && col.name !== 'Status'
                    "
                    class="{{ col.alignment }} clamp pr-40 white-space-nowrap"
                    [ngClass]="{
                      'fw-bold': row[col.name]?.isBold
                    }"
                  >
                    <span
                      title="£ {{
                        row[col.name]?.rowData | showWholeIfNoDecimal
                      }}"
                    >
                      {{ row[col.name]?.rowData | showWholeIfNoDecimal }}</span
                    >
                  </div>
                  <div
                    *ngIf="
                      col.dataType === dataType.CurrencySymbol &&
                      col.name !== 'Status'
                    "
                    class="{{ col.alignment }} clamp pr-40 white-space-nowrap"
                    [ngClass]="{
                      'fw-bold': row[col.name]?.isBold
                    }"
                  >
                    <div>
                      <span
                        title="{{ row[col.name]?.currencySymbol }} {{
                          row[col.name]?.rowData | numberPipe
                        }}"
                      >
                        {{ row[col.name]?.currencySymbol }}
                        {{ row[col.name]?.rowData | numberPipe }}</span
                      >
                    </div>
                  </div>

                  <div
                    *ngIf="
                      col.dataType === dataType.URL && col.name !== 'Status'
                    "
                    class="{{ col.alignment }}"
                    [ngClass]="{
                      'fw-bold': row[col.name]?.isBold
                    }"
                    title="{{ row[col.name]?.rowData }}"
                  >
                    <a
                      *ngIf="row[col.name]?.moduleId > 0"
                      class="link text-primary wmx-100 clamp"
                      [ngClass]="{
                        'ml-2':
                          moduleId === moduleEnum.BalanceSheet ||
                          moduleId === moduleEnum.BalanceSheetComparison
                      }"
                      (click)="redirect(col.name, row[col.name])"
                    >
                      {{ row[col.name]?.rowData }}
                    </a>
                  </div>

                  <div
                    *ngIf="
                      col.dataType === dataType.URL &&
                      row[col.name]?.id !== '' &&
                      row[col.name]?.id !== null &&
                      row[col.name]?.id !== undefined &&
                      row[col?.name]?.moduleId === 0 &&
                      col.name !== 'Status'
                    "
                    title="{{ row[col.name]?.rowData }}"
                    [ngClass]="row[col.name]?.isBold ? 'fw-bold' : ''"
                  >
                    <a
                      class="link fw-bold wmx-100 clamp"
                      (click)="
                        redirect(row[col.name]?.id, row[col.name]?.moduleId)
                      "
                    >
                      {{ row[col.name]?.rowData }}
                    </a>
                  </div>

                  <span *ngIf="col.dataType === dataType.DropDown">
                    <ng-select
                      class="w-150"
                      *ngIf="+row[col.name]?.rowData === 0"
                      #receipt
                      (focus)="receipt.open()"
                      (valueChange)="receipt.close()"
                      [disableOptionCentering]="true"
                      panelClass="mat-select-position"
                      appendTo="body"
                      (change)="
                        receiptPaymentChange(
                          $event,
                          this.dataSource.data[rowIndex]
                        )
                      "
                    >
                      <ng-option *ngFor="let fn of receiptList" [value]="fn">
                        <div title="{{ fn.name }}">{{ fn.name }}</div>
                      </ng-option>
                    </ng-select>

                    <ng-select
                      class="w-150"
                      *ngIf="+row[col.name]?.rowData === 1"
                      #payment
                      (focus)="payment.open()"
                      (valueChange)="payment.close()"
                      [disableOptionCentering]="true"
                      panelClass="mat-select-position"
                      appendTo="body"
                      (change)="
                        receiptPaymentChange(
                          $event,
                          this.dataSource.data[rowIndex]
                        )
                      "
                    >
                      <ng-option *ngFor="let fn of paymentList" [value]="fn">
                        <div title="{{ fn.name }}">{{ fn.name }}</div>
                      </ng-option>
                    </ng-select>
                  </span>

                  <div
                    class="clamp"
                    *ngIf="
                      col.dataType === dataType.URL &&
                      (row[col.name]?.id === '' ||
                        row[col.name]?.id === null ||
                        row[col.name]?.id === undefined) &&
                      row[col?.name]?.moduleId === 0 &&
                      col.name !== 'Status'
                    "
                    title="{{ row[col.name]?.rowData }}"
                    [ngClass]="row[col.name]?.isBold ? 'fw-bold' : ''"
                  >
                    {{ row[col.name]?.rowData }}
                  </div>

                  <div
                    *ngIf="
                      col.dataType === dataType.Blob && col.name !== 'Status'
                    "
                  >
                    <button
                      mat-button
                      (click)="downloadFile(row[col.name].rowData)"
                      class="fw-bold text-primary"
                      disableRipple
                    >
                      <mat-icon>file_download</mat-icon>
                    </button>
                  </div>

                  <div
                    *ngIf="col.name === 'Status'"
                    title="{{ row[col.name]?.rowData }}"
                    [ngClass]="
                      row[col.name]?.rowData === 'Active' ||
                      row[col.name]?.rowData === 'Paid'
                        ? 'status-active'
                        : 'status-archived'
                    "
                  >
                    {{ row[col.name]?.rowData }}
                  </div>

                  <div
                    *ngIf="
                      colIndex === columns.length - 2 &&
                      row['Ref. No.']?.moduleId !== 134 &&
                      !showAccountDetails
                    "
                    class="grid-actions"
                    [ngClass]="
                      selectedRowIndex === rowIndex ? 'show-button' : ''
                    "
                  >
                    <app-more-grid-actions
                      [moduleId]="moduleId"
                      [moreActionCount]="moreActionCount"
                      [elements]="row.ExtraValue"
                      [elements1]="row.ExtraValue1"
                      [row]="row"
                      [colIndex]="colIndex"
                      [colName]="col.name"
                      (triggerActionType)="onButtonClick($event, row)"
                      (triggerSelectedRowIndex)="
                        getSelectedRowIndex($event, rowIndex)
                      "
                    ></app-more-grid-actions>
                  </div>
                </ng-template>
              </e-column>
            </ng-container>
          </e-columns>
        </ejs-grid>
      </div>
      <!--End Report list Grid Implementation-->

      <!--Start Main List Grid Implementation -->
      <div *ngIf="!isReport && !isTreeList">
        <div class="d-flex justify-space-between align-items-end wp-100 mb-1">
          <app-transactions-header
            #headerRef
            [hidden]="moduleId === moduleEnum.VAT"
            (triggerQuickAdd)="isQuickAdd($event)"
            [isCustom]="isCustom"
            [customId]="customId"
            [listParameters]="listParameters"
          >
          </app-transactions-header>
          <div class="grid-search mr-10">
            <mat-form-field appearance="fill" class="search-field">
              <input
                matInput
                placeholder="Search here..."
                autocomplete="off"
                (change)="onSearch($event)"
              />

              <button mat-button class="search-button">
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div class="header-actions wp-100">
            <app-header-actions
              [hidden]="moduleId === moduleEnum.VAT || isBankImport"
              [isCustom]="isCustom"
              [customId]="customId"
              (exportClick)="export($event, false)"
              (dateChanged)="dateChanged($event)"
              (triggerDataFromHeader)="getDataFromHeader($event)"
              [listParameters]="listParameters"
              [triggerResetBankModuleId]="triggerResetBankModuleId"
              [parentModuleId]="moduleId$ | async"
              [parentModuleName]="moduleName"
            >
            </app-header-actions>
          </div>
        </div>
        <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
          <mat-icon class="material-icons-outlined">error_outline</mat-icon>
          {{ commonNotificationText.ViewOnlyAccessMessage }}
        </div>
        <ejs-grid
          #grid
          *ngIf="columns.length > 0"
          [dataSource]="gridData"
          [allowGrouping]="true"
          [allowReordering]="true"
          [allowSelection]="true"
          [allowPaging]="true"
          (checkBoxChange)="checkBoxChange($event)"
          (dataStateChange)="dataStateChange($event)"
          [pageSettings]="{
            pageSize: state.take,
            pageSizes: [5, 10, 20, 100],
            showInfo: true,
            showNavigationButtons: true,
            totalCount: totalRecords
          }"
          [selectedRowIndex]="selectedRowData.id"
          (columnVisibilityChange)="onVisibilityChange($event)"
          [allowFiltering]="true"
          [allowResizing]="true"
          [allowSorting]="true"
          [selectionSettings]="{
            type: 'Multiple',
            checkboxOnly: true,
            persistSelection: true
          }"
          [filterSettings]="{ type: 'Menu' }"
          showColumnMenu="true"
          [skip]="skip"
          [height]="gridHeight"
          (columnMenuClick)="columnMenuClick($event)"
        >
          <ng-template #toolbarTemplate>
            <div class="e-appbar-spacer"></div>
          </ng-template>

          <div class="mat-table-wrapper">
            <e-columns>
              <e-column
                type="checkbox"
                width="45"
                headerText=""
                textAlign="Center"
              ></e-column>

              <ng-container *ngFor="let col of columns; let colIndex = index">
                <e-column
                  [hidden]="!col.isSelected"
                  [field]="col.name"
                  [customAttributes]="{ class: getClassName(col) }"
                  [headerText]="col.name"
                  [width]="100"
                  [visible]="col.isSelected"
                  [allowSorting]="true"
                  [allowFiltering]="col.columnType"
                  [isPrimaryKey]="col.name === 'Id'"
                  [showInColumnChooser]="col.name === 'Id' ? false : true"
                >
                  <ng-template #template let-data let-rowIndex="rowIndex">
                    <!-- Template for cell rendering -->
                    <div
                      *ngIf="moduleId !== moduleEnum.BankTransfer"
                      [ngClass]="{
                        'd-flex align-items-center justify-space-between gap-10':
                          colIndex === columns.length - 1
                      }"
                    >
                      <div
                        *ngIf="
                          col.name !== 'Status' &&
                          (col.dataType === dataType.String ||
                            col.dataType === dataType.Bool ||
                            col.dataType === dataType.DateTime ||
                            col.dataType === dataType.Date)
                        "
                        class="clamp"
                      >
                        <span title="{{ data[col.name] }}">{{
                          data[col.name]
                        }}</span>
                      </div>

                      <div
                        class="text-align-right clamp pr-40 white-space-nowrap"
                        *ngIf="col.dataType === dataType.Currency"
                      >
                        <span title="£ {{ data[col.name] | numberPipe }}"
                          >£ {{ data[col.name] | numberPipe }}</span
                        >
                      </div>
                      <div *ngIf="col.dataType === dataType.Int32">
                        <span
                          title="{{ data[col.name] | showWholeIfNoDecimal }}"
                          >{{ data[col.name] | showWholeIfNoDecimal }}</span
                        >
                      </div>

                      <div
                        *ngIf="col.name === 'Status'"
                        title="{{ data[col.name] }}"
                        [ngClass]="{
                          'status-active':
                            data[col.name] === 'Active' ||
                            data[col.name] === 'Paid' ||
                            data[col.name] === 'Success' ||
                            data[col.name] === 'Capium Submitted',
                          'status-archived':
                            data[col.name] !== 'Active' &&
                            data[col.name] !== 'Paid' &&
                            data[col.name] !== 'Success' &&
                            data[col.name] !== 'Capium Submitted'
                        }"
                      >
                        {{ data[col.name] }}
                      </div>

                      <span *ngIf="col.dataType === dataType.Blob">
                        <button
                          mat-button
                          (click)="onAddClick(data)"
                          class="fw-bold text-primary"
                          disableRipple
                          [disabled]="isViewPermission$ | async"
                        >
                          <mat-icon>add_circle_outline</mat-icon>
                        </button>
                      </span>
                    </div>
                    <div
                      *ngIf="moduleId === moduleEnum.BankTransfer"
                      [ngClass]="{
                        'd-flex align-items-center justify-space-between gap-10':
                          colIndex === columns.length
                      }"
                    >
                      <div
                        *ngIf="
                          col.name !== 'Status' &&
                          (col.dataType === dataType.String ||
                            col.dataType === dataType.Bool ||
                            col.dataType === dataType.DateTime ||
                            col.dataType === dataType.Date)
                        "
                        class="clamp"
                      >
                        <span title="{{ data[col.name] }}">{{
                          data[col.name]
                        }}</span>
                      </div>
                      <div
                        *ngIf="
                          col.dataType === dataType.Int32 ||
                          col.dataType === dataType.Currency
                        "
                      >
                        <span title="{{ data[col.name] }}">{{
                          data[col.name]
                        }}</span>
                      </div>

                      <div
                        *ngIf="col.name === 'Status'"
                        title="{{ data[col.name] }}"
                        [ngClass]="{
                          'status-active':
                            data[col.name] === 'Active' ||
                            data[col.name] === 'Paid' ||
                            data[col.name] === 'Success' ||
                            data[col.name] === 'Capium Submitted',
                          'status-archived':
                            data[col.name] !== 'Active' &&
                            data[col.name] !== 'Paid' &&
                            data[col.name] !== 'Success' &&
                            data[col.name] !== 'Capium Submitted'
                        }"
                      >
                        {{ data[col.name] }}
                      </div>

                      <span *ngIf="col.dataType === dataType.Blob">
                        <button
                          mat-button
                          (click)="onAddClick(data)"
                          class="fw-bold text-primary"
                          disableRipple
                        >
                          <mat-icon>add_circle_outline</mat-icon>
                        </button>
                      </span>
                    </div>

                    <div
                      *ngIf="colIndex === columns.length - 1"
                      class="grid-actions"
                      [ngClass]="
                        selectedRowIndex === rowIndex ? 'show-button' : ''
                      "
                    >
                      <app-more-grid-actions
                        [ngClass]="{
                          hidden:
                            ((moduleId === moduleEnum.SubmitVat ||
                              moduleId === moduleEnum.BridgingVAT) &&
                              data.ExtraValue === 'True') ||
                            (moduleId === moduleEnum.VatSettings &&
                              data.ExtraValue !== '0')
                        }"
                        [elements]="data.ExtraValue"
                        [moduleId]="moduleId"
                        [element]="data"
                        [moreActionCount]="moreActionCount"
                        (triggerActionType)="onButtonClick($event, data)"
                        (triggerSelectedRowIndex)="
                          getSelectedRowIndex($event, rowIndex)
                        "
                      ></app-more-grid-actions>
                    </div>
                  </ng-template>
                </e-column>
              </ng-container>
            </e-columns>
          </div>
        </ejs-grid>
      </div>
      <!--End Main List Grid Implementation -->

      <!-- Start Tree View -->
      <div *ngIf="isTreeList && !showAccountDetails" class="tree-wrapper">
        <div class="d-flex justify-space-between wp-100">
          <div class="header-actions">
            <app-header-actions
              [hidden]="moduleId === moduleEnum.VAT"
              [isCustom]="isCustom"
              [customId]="customId"
              (exportClick)="export($event, false)"
              (dateChanged)="dateChanged($event)"
              (triggerDataFromHeader)="getDataFromHeader($event)"
              [listParameters]="listParameters"
              [triggerResetBankModuleId]="triggerResetBankModuleId"
              [parentModuleId]="moduleId$ | async"
              [parentModuleName]="moduleName"
              (triggerUpdateLossOfStock)="updateList()"
            >
            </app-header-actions>
          </div>
        </div>
        <div>
          <p class="fw-bold mb-2 mt-2 pb-10 font-size-20">{{ moduleName }}</p>
        </div>
        <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
          <mat-icon class="material-icons-outlined">error_outline</mat-icon>
          {{ commonNotificationText.ViewOnlyAccessMessage }}
        </div>
        <ejs-treegrid
          #treegrid
          *ngIf="columns.length > 0"
          [dataSource]="gridData"
          [allowPaging]="isTreeGridPaginationAllowed(moduleId)"
          childMapping="subtasks"
          enableCollapseAll="true"
          [height]="gridHeight"
        >
          <e-columns>
            <ng-container *ngFor="let col of columns; let colIndex = index">
              <e-column
                *ngIf="col.isSelected"
                [field]="col.name"
                [customAttributes]="{ class: getClassName(col) }"
                [headerText]="col.name"
                [width]="100"
                [visible]="col.isSelected"
                [allowSorting]="true"
                [allowFiltering]="col.columnType"
                [isPrimaryKey]="col.name === 'Id'"
              >
                <ng-template #template let-row let-rowIndex>
                  <span
                    *ngIf="
                      (col.dataType === dataType.String ||
                        col.dataType === dataType.Bool ||
                        col.dataType === dataType.DateTime ||
                        (col.dataType === dataType.URL &&
                          hasChildren(row) &&
                          colIndex == 1)) &&
                      col.name !== 'Status'
                    "
                    class="{{ col.alignment }}"
                    [ngClass]="{
                      'fw-bold': row[col.name]?.isBold
                    }"
                    title=" {{ row[col.name]?.rowData }}"
                  >
                    <span class="displat-inline wmx-100 clamp">{{
                      row[col.name]?.rowData
                    }}</span>
                  </span>

                  <span
                    *ngIf="
                      col.dataType === dataType.Currency &&
                      col.name !== 'Status' &&
                      row[col.name]?.rowData !== undefined &&
                      row[col.name]?.rowData !== null &&
                      row[col.name]?.rowData !== ''
                    "
                    class="{{
                      col.alignment
                    }} clamp white-space-nowrap display-block"
                    [ngClass]="{
                      'fw-bold': row[col.name]?.isBold
                    }"
                    title="£ {{ row[col.name]?.rowData | numberPipe }}"
                  >
                    £ {{ row[col.name]?.rowData | numberPipe }}
                  </span>
                  <span
                    *ngIf="
                      col.dataType === dataType.Int32 && col.name !== 'Status'
                    "
                    class="{{
                      col.alignment
                    }} clamp white-space-nowrap display-block"
                    [ngClass]="{
                      'fw-bold': row[col.name]?.isBold
                    }"
                    title="{{ row[col.name]?.rowData | showWholeIfNoDecimal }}"
                  >
                    {{ row[col.name]?.rowData | showWholeIfNoDecimal }}
                  </span>

                  <span
                    *ngIf="
                      col.dataType === dataType.URL &&
                      col.name !== 'Status' &&
                      !hasChildren(row)
                    "
                    class="{{ col.alignment }}"
                    [ngClass]="{
                      'fw-bold': row[col.name]?.isBold
                    }"
                    title="{{ row[col.name]?.rowData }}"
                  >
                    <a
                      *ngIf="row[col.name]?.moduleId > 0"
                      class="link text-primary wmx-100 clamp"
                      [ngClass]="{
                        'ml-2': moduleId === moduleEnum.BalanceSheet
                      }"
                      (click)="redirect(col.name, row[col.name])"
                      >{{ row[col.name]?.rowData }}
                    </a>
                  </span>

                  <span
                    *ngIf="
                      col.dataType === dataType.URL &&
                      row[col.name]?.id !== '' &&
                      row[col.name]?.id !== null &&
                      row[col.name]?.id !== undefined &&
                      row[col?.name]?.moduleId === 0 &&
                      col.name !== 'Status' &&
                      !hasChildren(row)
                    "
                    title="{{ row[col.name]?.rowData }}"
                    [ngClass]="row[col.name]?.isBold ? 'fw-bold' : ''"
                  >
                    <a
                      class="link fw-bold wmx-100 clamp"
                      (click)="
                        redirect(row[col.name]?.id, row[col.name]?.moduleId)
                      "
                    >
                      {{ row[col.name]?.rowData }}
                    </a>
                  </span>

                  <span *ngIf="col.dataType === dataType.DropDown">
                    <ng-select
                      class="w-150"
                      *ngIf="+row[col.name]?.rowData === 0"
                      #receipt
                      (focus)="receipt.open()"
                      (valueChange)="receipt.close()"
                      [disableOptionCentering]="true"
                      panelClass="mat-select-position"
                      appendTo="body"
                      (change)="
                        receiptPaymentChange(
                          $event,
                          this.dataSource.data[rowIndex]
                        )
                      "
                    >
                      <ng-option *ngFor="let fn of receiptList" [value]="fn">
                        <div title="{{ fn.name }}">{{ fn.name }}</div>
                      </ng-option>
                    </ng-select>

                    <ng-select
                      class="w-150"
                      *ngIf="+row[col.name]?.rowData === 1"
                      #payment
                      (focus)="payment.open()"
                      (valueChange)="payment.close()"
                      [disableOptionCentering]="true"
                      panelClass="mat-select-position"
                      appendTo="body"
                      (change)="
                        receiptPaymentChange(
                          $event,
                          this.dataSource.data[rowIndex]
                        )
                      "
                    >
                      <ng-option *ngFor="let fn of paymentList" [value]="fn">
                        <div title="{{ fn.name }}">{{ fn.name }}</div>
                      </ng-option>
                    </ng-select>
                  </span>

                  <span
                    class="clamp"
                    *ngIf="
                      col.dataType === dataType.URL &&
                      (row[col.name]?.id === '' ||
                        row[col.name]?.id === null ||
                        row[col.name]?.id === undefined) &&
                      row[col?.name]?.moduleId === 0 &&
                      col.name !== 'Status'
                    "
                    title="{{ row[col.name]?.rowData }}"
                    [ngClass]="row[col.name]?.isBold ? 'fw-bold' : ''"
                  >
                    {{ row[col.name]?.rowData }}
                  </span>

                  <span
                    *ngIf="
                      col.dataType === dataType.Blob && col.name !== 'Status'
                    "
                  >
                    <button
                      mat-button
                      (click)="downloadFile(row[col.name].rowData)"
                      class="fw-bold text-primary"
                      disableRipple
                    >
                      <mat-icon>file_download</mat-icon>
                    </button>
                  </span>

                  <span
                    *ngIf="col.name === 'Status'"
                    title="{{ row[col.name]?.rowData }}"
                    [ngClass]="
                      row[col.name]?.rowData === 'Active' ||
                      row[col.name]?.rowData === 'Paid'
                        ? 'status-active'
                        : 'status-archived'
                    "
                  >
                    {{ row[col.name]?.rowData }}
                  </span>

                  <span
                    *ngIf="colIndex === columns.length - 2"
                    class="grid-actions"
                    [ngClass]="
                      selectedRowIndex === rowIndex ? 'show-button' : ''
                    "
                  >
                    <app-more-grid-actions
                      [moduleId]="moduleId"
                      [moreActionCount]="moreActionCount"
                      [element]="row[col.name]?.rowData"
                      (triggerActionType)="onButtonClick($event, row)"
                      (triggerSelectedRowIndex)="
                        getSelectedRowIndex($event, rowIndex)
                      "
                    ></app-more-grid-actions>
                  </span>
                </ng-template>
              </e-column>
            </ng-container>
          </e-columns>
        </ejs-treegrid>
      </div>
      <!-- End Tree View -->

      <app-vat-report
        [listParameters]="listParameters"
        [triggerVatApi]="triggerVatApi"
        (triggerDataFromVatReport)="getDataFromVatReport($event)"
        *ngIf="moduleId === moduleEnum.VAT"
      ></app-vat-report>
    </div>
  </div>
</div>

<app-card-footer
  #cardFooter
  *ngIf="selectedRowData.length > 0"
  [totalSelectedRecords]="selectedRowData.length"
  (deleteClick)="deleteClick()"
  (exportClick)="exportType($event, false)"
  (copyClick)="copyClick()"
  (cancelSelectionClick)="cancelSelectionClick()"
  (printClick)="printClick()"
  (archiveAndRestoreClick)="archiveAndRestoreClick($event)"
  [getModuleId]="moduleId"
  (emailClick)="emailClick()"
  [triggerSelectedRowData]="triggerSelectedRowData"
  [triggerBadDebtsData]="triggerBadDebtsData"
  [ngClass]="
    this.listParameters.filter === filteredStatus.Archive
      ? 'archive-records'
      : ''
  "
></app-card-footer>
