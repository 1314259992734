import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ModuleName,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { SaveInventoryModel } from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import { GetInventory, MenuState, SaveInventory } from '@app/core/Store';
import { LossOfStockComponent } from '@app/modules/common/loss-of-stock/loss-of-stock.component';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { InventoryTransactionPopupComponent } from '.';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
})
export class InventoryComponent implements OnInit {
  noDataFound = true;
  dateTo = new Date();
  dateFrom = new Date();
  moduleId = Modules.Inventory;
  moduleName = ModuleName.Inventory;
  total = 0;
  showFooter = false;

  data: any;
  valuationList: any[] = [
    {
      value: 1,
      name: 'Cost',
    },
    {
      value: 2,
      name: 'NRV',
    },
  ];

  displayedColumns: string[] = [
    'item',
    'openingStock',
    'purchase',
    'sales',
    'lossOfStock',
    'closingStock',
    'valuation',
    'price',
    'amount',
  ];

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  constructor(
    private store: Store,
    private notify: NotificationService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getInventoryData();
  }

  getInventoryData(): void {
    if (this.dateFrom === undefined || this.dateFrom === null) {
      this.notify.error(NotificationHeader.error, 'Please enter From Date');
    } else if (this.dateTo === undefined || this.dateTo === null) {
      this.notify.error(NotificationHeader.error, 'Please enter To Date');
    } else {
      this.store
        .dispatch(new GetInventory(this.getParameter()))
        .pipe()
        .subscribe((res) => {
          this.data = res.inventory.inventoryData;
          this.noDataFound = this.data.length > 0 ? false : true;
          this.showFooter = this.data.length > 0 ? true : false;
          this.getTotalAmout();
        });
    }
  }

  getParameter(): any {
    const queryParams = {
      startDate: this.datepipe
        .transform(this.dateFrom, 'yyyy-MM-dd')
        ?.toString(),
      endDate: this.datepipe.transform(this.dateTo, 'yyyy-MM-dd')?.toString(),
    };
    return queryParams;
  }

  onCancel(): void {}

  getTotalAmout(): void {
    this.total = 0;
    for (let i = 0; i < this.data.length; i++) {
      this.total = this.total + parseFloat(this.data[i].amount);
    }
  }

  enablePrice(element: any): void {
    if (element.valuation == 1) {
      element.isPriceDisabled = false;
    } else {
      element.isPriceDisabled = true;
    }
  }

  checkAmtLength(element: any): void {
    const t = /^[-+]?[0-9]+(\.[0-9]+)?$/.test(element.price);
    if (t) {
      let rate = parseFloat(element.price).toFixed(2);
      if (rate.toString().length > 9) {
        element.price = rate;
        this.commonService.onFailure('Please enter no more than 9 characters.');
      }
    }
  }

  calculation(element: any): void {
    let selValuation = element.valuation;
    let closeStock = element.closingBalance;
    if (selValuation == 1) {
      let costPrice = element.price;
      element.price = costPrice;
      element.amount = closeStock * costPrice;
    } else if (selValuation == 2) {
      let nrvPrice = element.price;
      element.amount = closeStock * nrvPrice;
    }
    this.getTotalAmout();
  }

  onLossOfStockClick(): void {
    this.dialog
      .open(LossOfStockComponent, {
        data: { id: Guid.EMPTY as unknown as Guid },
      })
      .afterClosed()
      .subscribe((result) => {
        this.getInventoryData();
      });
  }

  onSave(): void {
    const param: SaveInventoryModel = new SaveInventoryModel();
    param.fromDate = this.datepipe
      .transform(this.dateFrom, 'yyyy-MM-dd')
      ?.toString();
    param.toDate = this.datepipe
      .transform(this.dateTo, 'yyyy-MM-dd')
      ?.toString();

    param.items = this.data;

    if (param.items.length > 0) {
      this.spinner.show();
      this.store
        .dispatch(new SaveInventory(param))
        .pipe()
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res !== undefined) {
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    }
  }

  inventoryPopup(type: string, productId: Guid): void {
    this.spinner.show();

    const parameters = {
      type: type,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      productId: productId,
    };
    this.dialog
      .open(InventoryTransactionPopupComponent, {
        data: parameters,
        disableClose: true,
      })
      .afterClosed()
      .subscribe();
  }
}
