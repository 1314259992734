import { Injectable } from '@angular/core';
import { BillModel, SideListModel } from '@app/core/Models';
import { BillService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreBill,
  CreateBill,
  DeleteBill,
  GetBillRefNo,
  GetDataByBillId,
  GetDueAmountByBillId,
  GetExpenditureExpenseBreakdownOverview,
  GetExpenditureFlowOverview,
  GetExpenditurePayableAgeingSummaryOverview,
  GetPaymentCurrencyByPurchaseId,
} from './bill.action';

export class BillStateInfo {
  billData?: BillModel;
  bill: Array<BillModel>;
  billId?: Guid;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  billRefNo?: Array<SideListModel>;
  isBillAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
  expenditurePayableAgeingSummaryOverview?: any;
  expenditureExpenseBreakdownOverview?: any;
  expenditureFlowOverview?: any;
  dueAmount?: any;
  currencyId?: number;
}

@State<BillStateInfo>({
  name: 'bill',
  defaults: {
    bill: [],
    billId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isBillAdded: false,
    dueAmount: 0,
    currencyId: 0,
  },
})
@Injectable()
export class BillState {
  constructor(private billService: BillService) {}

  @Selector()
  static getBillId(state: BillStateInfo) {
    return state.billId;
  }

  @Selector()
  static getBillData(state: BillStateInfo) {
    return state.billData;
  }

  @Selector()
  static isLastPage(state: BillStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static billRefNo(state: BillStateInfo) {
    return state.billRefNo;
  }

  @Action(CreateBill)
  createBill({ patchState }: StateContext<BillStateInfo>, action: CreateBill) {
    return this.billService.createBill(action.bill).pipe(
      tap((res) => {
        patchState({
          billId: res,
          isBillAdded: true,
        });
      })
    );
  }

  @Action(GetDataByBillId)
  getDataByBillId(
    { patchState }: StateContext<BillStateInfo>,
    action: GetDataByBillId
  ) {
    return this.billService.getDataByBillId(action.billId).pipe(
      tap((res) => {
        patchState({
          billData: res,
        });
      })
    );
  }

  @Action(DeleteBill)
  deleteQuotation(
    { getState, setState }: StateContext<BillStateInfo>,
    action: DeleteBill
  ) {
    return this.billService.deleteBill(action.billIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredBill = state.bill.filter(
          (item) =>
            !action.billIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.billIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        setState({
          ...state.bill,
          bill: filteredBill,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreBill)
  archiveAndRestorebill(
    { getState }: StateContext<BillStateInfo>,
    action: ArchiveAndRestoreBill
  ) {
    return this.billService.archiveAndRestoreBill(
      action.billIds,
      action.isArchive
    );
  }

  @Action(GetBillRefNo)
  getBillRefNo(
    { getState, patchState }: StateContext<BillStateInfo>,
    action: GetBillRefNo
  ) {
    return this.billService.getBillRefNo().pipe(
      tap((res) => {
        patchState({
          billRefNo: res,
        });
      })
    );
  }

  @Action(GetExpenditurePayableAgeingSummaryOverview)
  getExpenditurePayableAgeingSummaryOverview(
    { patchState }: StateContext<BillStateInfo>,
    action: GetExpenditurePayableAgeingSummaryOverview
  ) {
    return this.billService
      .getExpenditurePayableAgeingSummaryOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            expenditurePayableAgeingSummaryOverview: res,
          });
        })
      );
  }

  @Action(GetExpenditureExpenseBreakdownOverview)
  getExpenditureExpenseBreakdownOverview(
    { patchState }: StateContext<BillStateInfo>,
    action: GetExpenditureExpenseBreakdownOverview
  ) {
    return this.billService
      .getExpenditureExpenseBreakdownOverview(action.filterData)
      .pipe(
        tap((res) => {
          patchState({
            expenditureExpenseBreakdownOverview: res,
          });
        })
      );
  }

  @Action(GetExpenditureFlowOverview)
  getExpenditureFlowOverview(
    { patchState }: StateContext<BillStateInfo>,
    action: GetExpenditureFlowOverview
  ) {
    return this.billService.getExpenditureFlowOverview(action.filterData).pipe(
      tap((res) => {
        patchState({
          expenditureFlowOverview: res,
        });
      })
    );
  }

  @Action(GetDueAmountByBillId)
  getDueAmountByBillId(
    { patchState }: StateContext<BillStateInfo>,
    action: GetDueAmountByBillId
  ) {
    return this.billService.getDueAmountByBillId(action.billId).pipe(
      tap((res) => {
        patchState({
          dueAmount: res,
        });
      })
    );
  }
  @Action(GetPaymentCurrencyByPurchaseId)
  getReceiptCurrencyByInvoiceId(
    { patchState }: StateContext<BillStateInfo>,
    action: GetPaymentCurrencyByPurchaseId
  ) {
    return this.billService
      .getPaymentCurrencyByPurchaseId(action.purchaseId)
      .pipe(
        tap((res) => {
          patchState({
            currencyId: res,
          });
        })
      );
  }
}
