export enum TransactionStatus {
  IsCredit = 1,
  IsDebit = 0,
  Receipt,
  Payment,
}

export enum TransactionType {
  Bank = 1,
  Cash = 2,
  JV = 3,
}
