export enum ChartOfAccountCurrentAssetGroupTypeName {
  DebtorsMoreThanOneYearTradeDebtors = 1,
  DebtorsLessThanOneYearTradeDebtors = 2,
  DebtorsLessThanOneYearOtherDebtors = 3,
  DebtorsLessThanOneYearDirectorsCurrentAccount = 4,
  DebtorsLessThanOneYearAmountsOwedByGroupUndertakings = 5,
  DebtorsLessThanOneYearCalledupShareCapitalNotPaid = 6,
  DebtorsLessThanOneYearPrepaymentsAndAccruedIncome = 7,
  Investments = 8,
}

export enum ChartOfAccountCurrentLiabilitiesGroupTypeName {
  CreditorsLessThanOneYearTradeCreditors = 1,
  CreditorsLessThanOneYearBankloansAndOverdrafts = 2,
  CreditorsLessThanOneYearTaxationandSocialSecurity = 3,
  CreditorsLessThanOneYearVAT = 4,
  CreditorsLessThanOneYearOtherCreditors = 5,
  CreditorsLessThanOneYearDebentureLoans = 6,
  CreditorsLessThanOneYear = 7,
  CreditorsLessThanOneYearAccrualsandDeferredIncome = 8,
  CreditorsMoreThanOneYearTradeCreditors = 9,
}

export enum ChartOfAccountCostOfSalesGroupTypeName {
  Closingstock = 1,
  DepreciationCharges = 2,
  ExceptionalItems = 3,
  ExchangeRateLossesAndGains = 4,
  ImpairmentLosses = 5,
  OpeningStock = 6,
  OperatingLeaseCosts = 7,
  OtherDirectCosts = 8,
  OtherExceptionalItems = 9,
  ProfitLossOnSaleOfFixedAssets = 10,
  Purchases = 11,
  ResearchAndDevelopmentCosts = 12,
  Staffcosts = 13,
  StaffcostsDirectorsEmoluments = 14,
}

export enum ChartOfAccountCreditorsGroupTypeName {
  CreditorsLessThanOneYearTradeCreditors = 1,
  CreditorsLessThanOneYearBankLoansAndOverdrafts = 2,
  CreditorsLessThanOneYearTaxationAndSocialSecurity = 3,
  CreditorsLessThanOneYearVAT = 4,
  CreditorsLessThanOneYearOtherCreditors = 5,
  CreditorsLessThanOneYearDebentureLoans = 6,
  CreditorsLessThanOneYear = 7,
  CreditorsLessThanOneYearAccrualsAndDeferredIncome = 8,
  CreditorsMoreThanOneYearTradeCreditors = 9,
  CreditorsMoreThanOneYearBankLoansAndOverdrafts = 10,
  CreditorsMoreThanOneYearTaxationAndSocialSecurity = 11,
  CreditorsMoreThanOneYearVAT = 12,
  CreditorsMoreThanOneYearOtherCreditors = 13,
  CreditorsMoreThanOneYearDirectorsLoanAccount = 14,
  CreditorsMoreThanOneYearDebentureLoans = 15,
  CreditorsMoreThanOneYear = 16,
  CreditorsMoreThanOneYearAccrualsAndDeferredIncome = 17,
}
