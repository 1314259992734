import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { MinuteOfMeeting } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateMeeting,
  GetDataByMeetingId,
  GetDefaultTemplate,
  MenuState,
} from '@app/core/Store';

import {
  AddClosePopupComponent,
  AddMinutesOfMeetingsComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-minutes-of-meetings',
  templateUrl: './minutes-of-meetings.component.html',
  styleUrls: ['./minutes-of-meetings.component.scss'],
})
export class MinutesOfMeetingsComponent implements OnInit {
  customerId = Guid.EMPTY as unknown as Guid;
  minutesOfMeetingsData: MinuteOfMeeting;
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  minutesOfMeetingsId = Guid.EMPTY as unknown as Guid;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddMinutesOfMeetingsComponent, { static: true })
  minutesOfMeetingsDetails;

  triggerDefaultData: Subject<any> = new Subject<any>();
  triggerEditData: Subject<any> = new Subject<any>();

  moduleName = ModuleName.MinutesOfMeeting;
  moduleId = Modules.MinutesOfMeeting;
  subscriptionRouting: Subscription;
  store: Store;
  commonService: CommonService;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private _Activatedroute: ActivatedRoute,
    public dialog: MatDialog,
    private location: Location,
    public datepipe: DatePipe,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.minutesOfMeetingsId = atob(params.get('id')!) as unknown as Guid;
          this.editMinutesOfMeetings();
        } else {
          this.getTemplate();
        }
      }
    );
  }

  editMinutesOfMeetings(): void {
    this.store
      .dispatch(new GetDataByMeetingId(this.minutesOfMeetingsId))
      .subscribe((res) => {
        this.minutesOfMeetingsDetails.momForm.markAsUntouched();

        this.triggerEditData.next(res.industryType.minutesOfMeetingData);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  getTemplate(): void {
    this.store.dispatch(new GetDefaultTemplate()).subscribe((res: any) => {
      this.triggerDefaultData.next(res.industryType.industryType);
    });
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.minutesOfMeetingsData = {
        id: this.minutesOfMeetingsId,
        meetingType:
          this.minutesOfMeetingsDetails.momForm.controls.meetingType.value,
        meetingDate: this.datepipe
          .transform(
            this.minutesOfMeetingsDetails.momForm.controls.meetingDate.value,
            'yyyy-MM-ddTHH:mm:ss'
          )
          ?.toString(),
        description:
          this.minutesOfMeetingsDetails.momForm.controls.summaryNote.value,
        branchId:
          this.minutesOfMeetingsDetails.momForm.controls.branchId.value === ''
            ? null
            : this.minutesOfMeetingsDetails.momForm.controls.branchId.value,
        departmentId:
          this.minutesOfMeetingsDetails.momForm.controls.departmentId.value ===
          ''
            ? null
            : this.minutesOfMeetingsDetails.momForm.controls.departmentId.value,
      };
    } catch (error) {
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (
      isCancelClick &&
      !this.commonService.isEmpty(this.minutesOfMeetingsId)
    ) {
      this.editMinutesOfMeetings();
    } else {
      this.minutesOfMeetingsId = Guid.EMPTY as unknown as Guid;
      this.minutesOfMeetingsDetails.ngOnInit();
      this.getTemplate();
    }
  }

  onSave(isExit: boolean): void {
    if (this.minutesOfMeetingsDetails.momForm.invalid) {
      this.commonService.addValidation(
        this.minutesOfMeetingsDetails.momForm,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateMeeting(this.minutesOfMeetingsData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.minutesOfMeetingsId,
      isExit,
      Modules.MinutesOfMeeting,
      RoutingPath.AddMinutesOfMeeting
    );
  }
}
