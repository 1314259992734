import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationTextMessage } from '@app/core/Enum';
import { PaymentStatusViewModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { GetVatPaymentStatusView, RevertPaymentStatus } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-vat-payment-view-popup',
  templateUrl: './vat-payment-view-popup.component.html',
  styleUrls: ['./vat-payment-view-popup.component.scss'],
})
export class VatPaymentViewPopupComponent implements OnInit {
  spinner: NgxSpinnerService;

  vatId: any;
  paymentStatusView: PaymentStatusViewModel;
  store: Store;

  constructor(
    public dialogRef: MatDialogRef<VatPaymentViewPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
  }

  ngOnInit(): void {
    this.vatId = this.data.id;
    this.getPaymentStatusData();
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }
  onRevertClick(): void {
    this.store
      .dispatch(new RevertPaymentStatus(this.vatId))
      .pipe()
      .subscribe(
        (res) => {
          if (res !== undefined) {
            this.onCloseClick();
            this.commonService.onSuccess(
              NotificationTextMessage.successMessage
            );
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        },
        (err) => {
          this.commonService.onFailure(err.message);
        }
      );
  }

  getPaymentStatusData(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetVatPaymentStatusView(this.vatId))
      .subscribe((res) => {
        this.spinner.hide();
        this.paymentStatusView = res.submitVat.paymentStatusView;
      });
  }
}
