export enum TypeNames {
  Stocks = 1,
  DebtorsLessThanOneYearTradeDebtors = 2,
  DebtorsLessThanOneYearOtherDebtors = 3,
  DebtorsLessThanOneYearDirectorsCurrentAccount = 4,
  DebtorsLessThanOneYearAmountsOwedByGroupUndertakings = 5,
  DebtorsLessThanOneYearCalledupShareCapitalNotPaid = 6,
  DebtorsLessThanOneYearPrepaymentsAndAccruedIncome = 7,
  Investments = 8,
}
