import { Injectable } from '@angular/core';
import { MinuteOfMeeting, SideListModel } from '@app/core/Models';
import { MinutesOfMeetingsService } from '@app/core/Services';
import { Action, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreMinutesOfMeeting,
  CreateMeeting,
  DeleteMinutesOfMeeting,
  GetDataByMeetingId,
  GetDefaultTemplate,
} from './minutes-of-meetings.action';

export class MinutesOfMeetingsStateInfo {
  industryType?: any;
  minutesOfMeetingId?: Guid;
  isMinutesOfMeetingAdded?: boolean;
  minutesOfMeetingData?: MinuteOfMeeting;
  minutesOfMeeting: Array<MinuteOfMeeting>;
  sideListModel: Array<SideListModel>;
}

@State<MinutesOfMeetingsStateInfo>({
  name: 'industryType',
  defaults: {
    minutesOfMeeting: [],
    sideListModel: [],
  },
})
@Injectable()
export class MinutesOfMeetingsState {
  constructor(private minutesOfMeetingsService: MinutesOfMeetingsService) {}

  @Action(GetDefaultTemplate)
  getDefaultTemplate({ patchState }: StateContext<GetDefaultTemplate>) {
    return this.minutesOfMeetingsService.getDefaultTemplate().pipe(
      tap((res: any) => {
        patchState({
          industryType: res,
        });
      })
    );
  }

  @Action(CreateMeeting)
  createMeeting(
    { patchState }: StateContext<MinutesOfMeetingsStateInfo>,
    action: CreateMeeting
  ) {
    return this.minutesOfMeetingsService.createMeeting(action.data).pipe(
      tap((res) => {
        patchState({
          minutesOfMeetingId: res,
          isMinutesOfMeetingAdded: true,
        });
      })
    );
  }

  @Action(GetDataByMeetingId)
  getDataByMeetingId(
    { patchState }: StateContext<MinutesOfMeetingsStateInfo>,
    action: GetDataByMeetingId
  ) {
    return this.minutesOfMeetingsService
      .getDataByMeetingId(action.minutesOfMeetingId)
      .pipe(
        tap((res) => {
          patchState({
            minutesOfMeetingData: res,
          });
        })
      );
  }

  @Action(DeleteMinutesOfMeeting)
  deleteMinutesOfMeeting(
    { getState, patchState }: StateContext<MinutesOfMeetingsStateInfo>,
    action: DeleteMinutesOfMeeting
  ) {
    return this.minutesOfMeetingsService
      .deleteMinutesOfMeeting(action.minutesOfMeetingIds)
      .pipe(
        tap((res) => {
          const state = getState();

          const filteredMinutesOfMeetingIds = state.minutesOfMeeting.filter(
            (item) =>
              !action.minutesOfMeetingIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          const filteredForSideList = state.sideListModel?.filter(
            (item) =>
              !action.minutesOfMeetingIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          patchState({
            minutesOfMeeting: filteredMinutesOfMeetingIds,
            sideListModel: filteredForSideList,
          });
        })
      );
  }

  @Action(ArchiveAndRestoreMinutesOfMeeting)
  archiveAndRestoreAccount(
    { getState }: StateContext<MinutesOfMeetingsStateInfo>,
    action: ArchiveAndRestoreMinutesOfMeeting
  ) {
    return this.minutesOfMeetingsService.archiveAndRestoreMinutesOfMeeting(
      action.minutesOfMeetingIds,
      action.isArchive
    );
  }
}
