<app-menu-items></app-menu-items>
<div class="sidenav-body-content general-import">
  <div class="import-head d-flex align-items-center justify-space-between">
    <p class="m-0">Export Data</p>
  </div>
  <div class="hr"></div>
  <div class="alert-message" *ngIf="isViewPermission$ | async">
    <mat-icon class="material-icons-outlined">error_outline</mat-icon>
    {{ commonNotificationText.ViewOnlyAccessMessage }}
  </div>
  <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
    <p class="fw-bold font-size-16 mt-1">Select Date Range</p>
    <div class="content-header p-0">
      <div class="d-flex align-item-center year-picker export-data">
        <ng-select
          class="w-150"
          #status
          (focus)="status.open()"
          (valueChange)="status.close()"
          panelClass="myPanelClass"
        >
          <ng-option>Last Week</ng-option>
          <ng-option>This Month</ng-option>
          <ng-option>Last Month</ng-option>
          <ng-option>This Quarter</ng-option>
          <ng-option>Last Quarter</ng-option>
          <ng-option>This Year</ng-option>
          <ng-option>Last Year</ng-option>
          <ng-option>Custom</ng-option>
        </ng-select>
      </div>
    </div>
    <div class="mt-2">
      <p class="fw-bold font-size-16">Select Category</p>
      <div class="import-wrapper">
        <mat-radio-group aria-label="Select an option">
          <div class="import-card">
            <div class="d-flex align-center">
              <mat-radio-button
                disableRipple
                value="0"
                [checked]="true"
              ></mat-radio-button>
            </div>
            <div class="import-body d-flex align-items-center">
              <div class="import-img">
                <mat-icon>person_outline</mat-icon>
              </div>
              <div class="import-text">
                <h2>Clients</h2>
              </div>
            </div>
          </div>
          <div class="import-card">
            <div class="import-check text-align-right">
              <mat-radio-button
                disableRipple
                value="1"
                [checked]="false"
              ></mat-radio-button>
            </div>
            <div class="import-body d-flex align-items-center">
              <div class="import-img">
                <mat-icon>people_outline</mat-icon>
              </div>
              <div class="import-text">
                <h2>Users</h2>
              </div>
            </div>
          </div>
        </mat-radio-group>
      </div>
    </div>
    <div class="mt-1 mb-2">
      <p class="fw-bold font-size-16 pb-10">Download as</p>
      <div class="d-flex align-items-center">
        <button class="button-icon" type="button" mat-button disableRipple>
          <mat-icon fontSet="material-icons-outlined">border_all</mat-icon>
          <p>Excel</p>
        </button>
        <button class="button-icon" type="button" mat-button disableRipple>
          <mat-icon fontSet="material-icons-outlined">description</mat-icon>
          <p>CSV</p>
        </button>
        <button class="button-icon" type="button" mat-button disableRipple>
          <mat-icon fontSet="material-icons-outlined">picture_as_pdf</mat-icon>
          <p>PDF</p>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="action-wrapper wp-100" *ngIf="!(isViewPermission$ | async)">
  <div class="content-body m-0">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
    >
      Export
    </button>

    <button
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
    >
      Cancel Changes
    </button>
  </div>
</div>
