<div class="content-body">
  <form [formGroup]="journalsForm">
    <div class="d-flex gap-40">
      <div class="form-field fund-name" *ngIf="branchList.length > 0">
        <p>Branch <span class="text-danger-300">*</span></p>
        <ng-select
          #branch
          (focus)="branch.open()"
          (valueChange)="branch.close()"
          [disableOptionCentering]="true"
          formControlName="branchId"
          dropdownPosition="bottom"
          (change)="onChangeBranch($event)"
        >
          <ng-option *ngFor="let bl of branchList" [value]="bl.id">
            <div title="{{ bl.name }}">{{ bl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field" *ngIf="departmentList.length > 0">
        <p>Department <span class="text-danger-300">*</span></p>
        <ng-select
          #department
          (focus)="department.open()"
          (valueChange)="department.close()"
          [disableOptionCentering]="true"
          formControlName="departmentId"
          dropdownPosition="bottom"
        >
          <ng-option *ngFor="let dl of departmentList" [value]="dl.id">
            <div title="{{ dl.name }}">{{ dl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Journal No. <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input matInput formControlName="journalNo" autocomplete="off" />
        </mat-form-field>
      </div>
      <div class="form-field datepicker">
        <p>Date<span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="date"
            [matDatepicker]="journalDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            tabindex="-1"
            rrquired
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="journalDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #journalDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(journalsForm.get('date'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(journalsForm.get('date'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>
    <div class="d-flex gap-40 align-items-center">
      <div class="form-field datepicker" *ngIf="hideReveringDate">
        <p>Auto Reversing Date</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="reversingDate"
            [matDatepicker]="journalReverseDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            tabindex="-1"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="journalReverseDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #journalReverseDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(journalsForm.get('reversingDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(journalsForm.get('reversingDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
      <div class="form-field m-0" *ngIf="vatReturnCheckboxVisibility">
        <div>
          <mat-checkbox formControlName="includeInVatReturnCheckbox">
            Include in VAT return
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div *ngIf="isManualBank" class="d-flex gap-40">
      <div class="form-field">
        <p>Transfer Amount</p>
        <mat-form-field>
          <button mat-button type="button" disableRipple matSuffix>
            <span class="symbol-align">{{ transferAmountSymbol }}</span>
          </button>
          <input
            allow_14_2_Decimal
            matInput
            zeroNotAllowed
            formControlName="transferAmount"
            autocomplete="off"
            required
            allowDecimal
          />
        </mat-form-field>
      </div>
      <div *ngIf="isMultiCurrencyBank" class="form-field">
        <p>Exchange Rate</p>
        <mat-form-field class="w-140 big-inputbox">
          <input
            matInput
            formControlName="currencyRate"
            allowDecimal
            autocomplete="off"
            (change)="onCurrencyRateEntered()"
          />
          <button
            mat-button
            type="button"
            disableRipple
            matSuffix
            (click)="onCurrencyRefresh()"
          >
            <mat-icon>refresh</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="isManualBank && isMultiCurrencyBank" class="d-flex gap-40">
      <div class="form-field">
        <p>Transferred Amount</p>
        <mat-form-field>
          <button mat-button type="button" disableRipple matSuffix>
            <span class="symbol-align">{{ transferredAmtSymbol }}</span>
          </button>
          <input
            allow_14_2_Decimal
            matInput
            zeroNotAllowed
            formControlName="convertedGbpAmnt"
            autocomplete="off"
            required
            allowDecimal
          />
        </mat-form-field>
      </div>
    </div>
    <div class="form-field textarea">
      <p>Reference</p>
      <mat-form-field>
        <textarea
          matInput
          formControlName="reference"
          maxlength="{{ maxLength.maxLength2000 }}"
          autocomplete="off"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
