import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationTextMessage } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import {
  GetAllocationData,
  GetCustomBankAndCashAccount,
  SaveAllocation,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-allocate-refund-popup',
  templateUrl: './add-allocate-refund-popup.html',
  styleUrls: ['./add-allocate-refund-popup.scss'],
})
export class AddAllocateRefundPopupComponent implements OnInit {
  AllocateRefundList: any[] = [];
  paymentAccountList: any[] = [];
  displayAllocationRefundColumns: string[] = [
    'invoiceNo',
    'date',
    'amount',
    'due',
    'amountToAllocate',
  ];
  allocationData: any;
  refundAccountId: any;
  refundDate: any;
  refundAmount: number = 0;
  creditNoteSymbol: string;
  creditNoteCurrencyId: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddAllocateRefundPopupComponent>,
    public commonService: CommonService,
    private store: Store,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getAllocationData();
    this.getAccountList();
  }

  getAccountList(): void {
    this.store.dispatch(new GetCustomBankAndCashAccount()).subscribe((res) => {
      this.paymentAccountList = res.common.customBankAndCashAccount;
    });
  }

  getAllocationData(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetAllocationData(this.data.id, this.data.moduleId))
      .subscribe((res) => {
        this.allocationData = res.common.allocationData;
        this.creditNoteSymbol = this.allocationData.invoiceList[0].symbol;
        this.creditNoteCurrencyId =
          this.allocationData.invoiceList[0].currencyId;
        this.spinner.hide();
      });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onAllocationAmountChange(element: any): void {
    if (+element.allocatedAmount > +element.dueAmount) {
      element.allocatedAmount = 0;
    } else {
      if (
        this.allocationData.invoiceList
          .filter(
            (x) => x.allocatedAmount !== null && x.allocatedAmount !== undefined
          )
          .reduce((x, y) => +x + +y.allocatedAmount, 0) >
        this.allocationData.foreignDueAmount
      ) {
        element.allocatedAmount = 0;
      } else if (
        this.allocationData.invoiceList
          .filter(
            (x) => x.allocatedAmount !== null && x.allocatedAmount !== undefined
          )
          .reduce((x, y) => +x + +y.allocatedAmount, 0) +
          +this.refundAmount >
        this.allocationData.foreignDueAmount
      ) {
        element.allocatedAmount = 0;
      }
    }
  }

  onRefundAmountChange(): void {
    if (+this.refundAmount > this.allocationData.dueAmount) {
      this.refundAmount = 0;
    } else if (
      this.allocationData.invoiceList
        .filter(
          (x) => x.allocatedAmount !== null && x.allocatedAmount !== undefined
        )
        .reduce((x, y) => +x + +y.allocatedAmount, 0) +
        +this.refundAmount >
      this.allocationData.dueAmount
    ) {
      this.refundAmount = 0;
    }
  }

  onSaveClick(): void {
    if (
      (this.allocationData.invoiceList.filter(
        (x) => x.allocatedAmount !== null && x.allocatedAmount !== undefined
      ).length === 0 &&
        this.refundAmount === 0) ||
      (this.allocationData.invoiceList
        .filter(
          (x) => x.allocatedAmount !== null && x.allocatedAmount !== undefined
        )
        .reduce((x, y) => +x + +y.allocatedAmount, 0) === 0 &&
        this.refundAmount === 0)
    ) {
      this.commonService.onFailure('Please allocate /refund to proceed');
    } else if (
      this.allocationData.invoiceList
        .filter(
          (x) => x.allocatedAmount !== null && x.allocatedAmount !== undefined
        )
        .reduce((x, y) => +x + +y.allocatedAmount, 0) +
        +this.refundAmount >
      +this.allocationData.foreignDueAmount
    ) {
      this.commonService.onFailure(
        'Allocation / Refund cannot be greater than due amount!'
      );
    } else if (
      +this.refundAmount > 0 &&
      (this.refundAccountId === null || this.refundAccountId === undefined)
    ) {
      this.commonService.onFailure('Please select a refund account');
    } else if (
      +this.refundAmount > 0 &&
      (this.refundDate === null || this.refundDate === undefined)
    ) {
      this.commonService.onFailure('Please select a refund Date');
    } else {
      const creditNoteAllocationSave = {
        id: this.data.id,
        receiptAccountId: this.refundAccountId,
        receiptDate: this.refundDate,
        receiptAmount: this.refundAmount,
        invoices: this.allocationData.invoiceList.filter(
          (x) => +x.allocatedAmount > 0
        ),
      };
      this.spinner.show();
      this.store
        .dispatch(
          new SaveAllocation(creditNoteAllocationSave, this.data.moduleId)
        )
        .pipe()
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res !== undefined) {
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
              this.dialogRef.close(true);
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    }
  }
}
