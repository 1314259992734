import { InvoiceModel, OverviewFilterModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class CreateInvoice {
  static readonly type = '[INVOICE] Create Invoice';

  constructor(public invoice: InvoiceModel) {}
}

export class GetDataByInvoiceId {
  static readonly type = '[INVOICE] Get Invoice Data By Invoice Id';

  constructor(public invoiceId: Guid) {}
}

export class ArchiveAndRestoreInvoice {
  static readonly type = '[INVOICE] Archive and Restore Selected Invoice';

  constructor(public invoiceIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteInvoice {
  static readonly type = '[INVOICE] Delete Selected Invoice';

  constructor(public invoiceIds: Array<Guid>) {}
}

export class GetInvoiceNoList {
  static readonly type = '[INVOICE] Get Invoice No List';
}

export class GetInvoiceNumber {
  static readonly type = '[INVOICE] Get Invoice Number';
}

export class GetIncomeFlowOverview {
  static readonly type = '[INVOICE] Get Income Flow Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetIncomeBreakdownOverview {
  static readonly type = '[INVOICE] Get Income Breakdown Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetIncomeReceivableAgeingSummaryOverview {
  static readonly type =
    '[INVOICE] Get Income Receivable Ageing Summary Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetDueAmountByInvoiceId {
  static readonly type = '[INVOICE] Get Invoice Due Amount By Invoice Id';

  constructor(public invoiceId: Guid) {}
}

export class GetReceiptCurrencyByInvoiceId {
  static readonly type = '[INVOICE] Get Invoice Receipt Currency By Invoice Id';

  constructor(public invoiceId: Guid) {}
}
