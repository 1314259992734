<div [ngClass]="customDate ? 'content-header-custom-date' : ''">
  <div class="d-flex justify-space-between wp-100">
    <button
      class="action-button primary-button mr-10"
      mat-button
      disableRipple
      *ngIf="
        (hasAddPermission$ | async) &&
        (moduleId$ | async) !== modules.Import &&
        (moduleId$ | async) !== modules.BulkEdit &&
        (moduleId$ | async) !== modules.Inventory &&
        (moduleId$ | async) !== modules.Permission &&
        (moduleId$ | async) !== modules.Currency &&
        !this.isBankImport &&
        showAddButton &&
        !showAccountDetails &&
        this.transactionModuleId !== modules.BankImportTransactionHistory
      "
      (click)="onAddClick()"
      [disabled]="isViewPermission$ | async"
    >
      <mat-icon>add</mat-icon>
      <span
        *ngIf="
          (moduleId$ | async) !== modules.FixedAssetsRegister &&
          (moduleId$ | async) !== modules.BankDashboard &&
          (moduleId$ | async) !== modules.BankFeed
        "
      >
        <span>Add {{ moduleName }}</span>
      </span>

      <span *ngIf="(moduleId$ | async) === modules.BankDashboard">
        Add Bank Account</span
      >
      <span *ngIf="(moduleId$ | async) === modules.BankFeed">
        Link Bank Account</span
      >
      <span
        *ngIf="
          transactionModuleId === modules.ActiveTangibleFixedAssets ||
          transactionModuleId === modules.ActiveLeasedFixedAssets
        "
      >
        Depreciation
      </span>
      <span *ngIf="transactionModuleId === modules.ActiveIntangibleFixedAssets">
        Amortization
      </span>
    </button>

    <!-- New user Quick add  -->
    <button
      *ngIf="(moduleId$ | async) === modules.Permission"
      class="action-button primary-button mr-10"
      mat-button
      disableRipple
      (click)="gotoAddUser()"
    >
      <mat-icon>add</mat-icon>
      Add User
    </button>

    <!-- Add Currency -->
    <button
      *ngIf="(moduleId$ | async) === modules.Currency"
      class="action-button primary-button mr-10"
      mat-button
      disableRipple
      (click)="gotoAddCurrency()"
      [disabled]="isViewPermission$ | async"
    >
      <mat-icon>add</mat-icon>
      Add Currency
    </button>
  </div>
</div>
