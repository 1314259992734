import { Component } from '@angular/core';
import { NotificationTextMessage } from '@app/core/Enum';
import { MenuState } from '@app/core/Store';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-export-settings',
  templateUrl: './export-settings.component.html',
  styleUrls: ['./export-settings.component.scss'],
})
export class ExportSettingsComponent {
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
}
