<div class="company-preference content-body-wrapper">
  <div class="content-body">
    <p class="header-text d-flex justify-space-between align-items-center">
      Company Preference
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCloseClick()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </p>
    <div class="company-preference-info">
      <mat-tab-group
        mat-align-tabs="start"
        disableRipple
        animationDuration="0ms"
        animationDuration="0ms "
        [(selectedIndex)]="selectedTab"
        (selectedTabChange)="onTabClick($event.index)"
      >
        <mat-tab label="Invoice">
          <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
            <app-add-company-preference
              *ngIf="selectedTab === 0"
              [triggerEditData]="triggerEditData"
            >
            </app-add-company-preference>
          </div>
        </mat-tab>
        <mat-tab label="Quotation">
          <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
            <app-add-company-preference
              *ngIf="selectedTab === 1"
              [triggerEditData]="triggerEditData"
            >
            </app-add-company-preference>
          </div>
        </mat-tab>
        <mat-tab label="Recurring Invoice">
          <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
            <app-add-company-preference
              *ngIf="selectedTab === 2"
              [triggerEditData]="triggerEditData"
            >
            </app-add-company-preference>
          </div>
        </mat-tab>
        <mat-tab label="Credit Note">
          <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
            <app-add-company-preference
              *ngIf="selectedTab === 3"
              [triggerEditData]="triggerEditData"
            >
            </app-add-company-preference>
          </div>
        </mat-tab>
        <mat-tab label="Recurring Purchase">
          <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
            <app-add-company-preference
              *ngIf="selectedTab === 4"
              [triggerEditData]="triggerEditData"
            >
            </app-add-company-preference>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <app-buttons
    *ngIf="!(isViewPermission$ | async)"
    [getModuleId]="moduleId"
    (triggerOnSaveClick)="onSave($event)"
    (triggerOnCancelClick)="onCancel()"
  >
  </app-buttons>
</div>
