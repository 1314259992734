export class VATObligationModel {
  periodKey: string;
  startDate: string;
  endDate: string;
  dueDate: string;
  receivedDate: string;
  status: string;
}

export class TaxPeriodModel {
  public from: string;
  public to: string;
}

export class LiabilityModel {
  public taxPeriod: TaxPeriodModel;
  public type: string;
  public originalAmount: number;
  public outstandingAmount: number;
  public due: string;
}

export class VATPaymentModel {
  amount: number;
  received: string;
}
