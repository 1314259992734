export class GetMenu {
  static readonly type = '[MENU] Get';
}

export class SetModulePermission {
  static readonly type = '[MENU] Set Module Permission ';

  constructor(public moduleData: any) {}
}

export class GetProfileInfo {
  static readonly type = '[PROFILE] Get Profile Info';
}

export class GetAddMenu {
  static readonly type = '[ADDMENU] Get Add menu';
}

export class GetReportMenu {
  static readonly type = '[GetReportMenu] Get Report Menu';
}
