<div class="content-body mt-2">
  <div>
    <div class="report-title">
      <h4 class="fw-bold text-primary">Sales VAT</h4>
    </div>
    <table
      mat-table
      [dataSource]="salesVATList"
      multiTemplateDataRows="true"
      aria-describedby="User Task Table"
    >
      <th></th>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="d-flex align-items-center gap-20">
            <div>
              <a
                href="javascript:void(0)"
                *ngIf="!element.isExpanded"
                class="text-primary"
              >
                <mat-icon>add</mat-icon>
              </a>
              <a
                href="javascript:void(0)"
                *ngIf="element.isExpanded"
                class="text-primary"
              >
                <mat-icon>remove</mat-icon>
              </a>
            </div>
            <div>
              {{ element.vatName }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="netAmount">
        <mat-header-cell *matHeaderCellDef class="justify-content-end">
          Net Amount
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="justify-content-end">
          {{ element.netAmount | numberPipe }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vatAmount">
        <mat-header-cell *matHeaderCellDef class="justify-content-end">
          Vat Amount
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="justify-content-end">
          {{ element.vatAmount | numberPipe }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalAmount">
        <mat-header-cell *matHeaderCellDef class="justify-content-end">
          Total Amount
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="justify-content-end">
          {{ element.totalAmount | numberPipe }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let element" class="flex-column">
          <table
            aria-describedby="main-list"
            mat-table
            matSort
            (matSortChange)="sorting(sort.active, sort.direction)"
            [dataSource]="dataSource"
          >
            <ng-container
              *ngFor="let col of columns$ | async; let colIndex = index"
              matColumnDef="{{ col.name }}"
            >
              <th mat-header-cell *matHeaderCellDef [id]="col.name" fxFlex="50">
                <div
                  class="d-flex align-items-center"
                  [ngClass]="[
                    (col.dataType === dataType.String ||
                      col.dataType === dataType.Currency ||
                      col.dataType === dataType.Int32) &&
                    col.alignment != null
                      ? 'justify-content-end'
                      : ''
                  ]"
                >
                  <span mat-sort-header>
                    {{ col.name }}
                  </span>
                </div>
              </th>

              <td mat-cell *matCellDef="let element; let rowIndex = index">
                <div
                  class="d-flex align-items-center"
                  [ngClass]="[
                    col.dataType === dataType.Int32 ||
                    col.dataType === dataType.Currency
                      ? 'justify-content-end pr-2'
                      : '',
                    colIndex === (columns$ | async).length - 1
                      ? 'justify-space-between'
                      : ''
                  ]"
                >
                  <div class="clamp">
                    <span
                      *ngIf="
                        col.dataType === dataType.String ||
                        col.dataType === dataType.Bool
                      "
                      title="{{ element[colIndex].rowData }}"
                      [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                    >
                      {{ element[colIndex].rowData }}
                    </span>

                    <span
                      *ngIf="
                        col.dataType === dataType.URL &&
                        element[colIndex].id !== '' &&
                        element[colIndex].moduleId !== 0
                      "
                      title="{{ element[colIndex].rowData }}"
                      [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                    >
                      <a
                        class="link fw-bold"
                        (click)="
                          redirect(
                            element[colIndex].id,
                            element[colIndex].moduleId
                          )
                        "
                      >
                        {{ element[colIndex].rowData }}
                      </a>
                    </span>

                    <span
                      *ngIf="
                        col.dataType === dataType.URL &&
                        element[colIndex].id === '' &&
                        element[colIndex].moduleId === 0
                      "
                      title="{{ element[colIndex].rowData }}"
                      [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                    >
                      {{ element[colIndex].rowData }}
                    </span>

                    <span
                      *ngIf="col.dataType === dataType.DateTime"
                      title="{{ element[colIndex].rowData }}"
                      [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                    >
                      {{
                        element[colIndex].rowData | date: "dd-LLL-yyyy"
                      }}</span
                    >

                    <span
                      *ngIf="
                        (col.dataType === dataType.Int32 ||
                          col.dataType === dataType.Currency) &&
                        element[colIndex].rowData !== ''
                      "
                      title="{{ element[colIndex].rowData }}"
                      [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                    >
                      £ {{ element[colIndex].rowData | numberPipe }}</span
                    >
                  </div>
                </div>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="columnsNames$ | async; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: columnsNames$ | async"
            ></tr>
          </table>
          <div *ngIf="noDataFound" class="text-align-center mt-1 mb-1 fw-bold">
            No records found
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        matRipple
        class="element-row"
        [class.expanded]="row.isExpanded"
        (click)="expandCollapse(row, 0)"
      ></mat-row>

      <mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        [@detailExpand]="row.isExpanded == true ? 'expanded' : 'collapsed'"
        style="overflow: hidden"
      >
      </mat-row>
    </table>
  </div>

  <div class="mt-2">
    <div class="report-title">
      <h4 class="fw-bold text-primary">Purchase VAT</h4>
    </div>
    <table
      mat-table
      [dataSource]="billVATList"
      multiTemplateDataRows="true"
      aria-describedby="User Task Table"
    >
      <th></th>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="d-flex align-items-center gap-20">
            <div>
              <a
                href="javascript:void(0)"
                *ngIf="!element.isExpanded"
                class="text-primary"
              >
                <mat-icon>add</mat-icon>
              </a>
              <a
                href="javascript:void(0)"
                *ngIf="element.isExpanded"
                class="text-primary"
              >
                <mat-icon>remove</mat-icon>
              </a>
            </div>
            <div>
              {{ element.vatName }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="netAmount">
        <mat-header-cell *matHeaderCellDef class="justify-content-end">
          Net Amount
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="justify-content-end">
          {{ element.netAmount | numberPipe }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="vatAmount">
        <mat-header-cell *matHeaderCellDef class="justify-content-end">
          Vat Amount
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="justify-content-end">
          {{ element.vatAmount | numberPipe }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalAmount">
        <mat-header-cell *matHeaderCellDef class="justify-content-end">
          Total Amount
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="justify-content-end">
          {{ element.totalAmount | numberPipe }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let element" class="flex-column">
          <table
            aria-describedby="main-list"
            mat-table
            matSort
            (matSortChange)="sorting(sort.active, sort.direction)"
            [dataSource]="dataSource"
          >
            <ng-container
              *ngFor="let col of columns$ | async; let colIndex = index"
              matColumnDef="{{ col.name }}"
            >
              <th mat-header-cell *matHeaderCellDef [id]="col.name" fxFlex="50">
                <div
                  class="d-flex align-items-center"
                  [ngClass]="[
                    (col.dataType === dataType.String ||
                      col.dataType === dataType.Currency ||
                      col.dataType === dataType.Int32) &&
                    col.alignment != null
                      ? 'justify-content-end'
                      : ''
                  ]"
                >
                  <span mat-sort-header>
                    {{ col.name }}
                  </span>
                </div>
              </th>

              <td mat-cell *matCellDef="let element; let rowIndex = index">
                <div
                  class="d-flex align-items-center"
                  [ngClass]="[
                    col.dataType === dataType.Int32 ||
                    col.dataType === dataType.Currency
                      ? 'justify-content-end pr-2'
                      : '',
                    colIndex === (columns$ | async).length - 1
                      ? 'justify-space-between'
                      : ''
                  ]"
                >
                  <div class="clamp">
                    <span
                      *ngIf="
                        col.dataType === dataType.String ||
                        col.dataType === dataType.Bool
                      "
                      title="{{ element[colIndex].rowData }}"
                      [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                    >
                      {{ element[colIndex].rowData }}
                    </span>

                    <span
                      *ngIf="
                        col.dataType === dataType.URL &&
                        element[colIndex].id !== '' &&
                        element[colIndex].moduleId !== 0
                      "
                      title="{{ element[colIndex].rowData }}"
                      [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                    >
                      <a
                        class="link fw-bold"
                        (click)="
                          redirect(
                            element[colIndex].id,
                            element[colIndex].moduleId
                          )
                        "
                      >
                        {{ element[colIndex].rowData }}
                      </a>
                    </span>

                    <span
                      *ngIf="
                        col.dataType === dataType.URL &&
                        element[colIndex].id === '' &&
                        element[colIndex].moduleId === 0
                      "
                      title="{{ element[colIndex].rowData }}"
                      [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                    >
                      {{ element[colIndex].rowData }}
                    </span>

                    <span
                      *ngIf="col.dataType === dataType.DateTime"
                      title="{{ element[colIndex].rowData }}"
                      [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                    >
                      {{
                        element[colIndex].rowData | date: "dd-LLL-yyyy"
                      }}</span
                    >

                    <span
                      *ngIf="
                        (col.dataType === dataType.Int32 ||
                          col.dataType === dataType.Currency) &&
                        element[colIndex].rowData !== ''
                      "
                      title="{{ element[colIndex].rowData }}"
                      [ngClass]="element[colIndex].isBold ? 'fw-bold' : ''"
                    >
                      £ {{ element[colIndex].rowData | numberPipe }}</span
                    >
                  </div>
                </div>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="columnsNames$ | async; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: columnsNames$ | async"
            ></tr>
          </table>
          <div *ngIf="noDataFound" class="text-align-center mt-1 mb-1 fw-bold">
            No records found
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        matRipple
        class="element-row"
        [class.expanded]="row.isExpanded"
        (click)="expandCollapse(row, 1)"
      ></mat-row>

      <mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        [@detailExpand]="row.isExpanded == true ? 'expanded' : 'collapsed'"
        style="overflow: hidden"
      >
      </mat-row>
    </table>
  </div>
</div>
