import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import { MaxLength, Modules, NotificationTextMessage } from '@app/core/Enum';
import { GlobalComponent, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetCreditNoteInvoices,
  GetDebitNotePurchases,
  GetInvoicesBasedOnCustomerCurrency,
  GetPurchasesBasedOnSupplierCurrency,
} from '@app/core/Store';
import { CleanAllLinesComponent } from '@app/modules';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

export function scrollFactory12(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}
@Component({
  selector: 'app-credit-note-allocation',
  templateUrl: './credit-note-allocation.component.html',
  styleUrls: ['./credit-note-allocation.component.scss'],
})
export class CreditNoteAllocationComponent implements OnInit {
  creditDetailForm: FormGroup;
  formCreditDetail: UntypedFormGroup;

  invoiceList: SideListModel[] = [];
  creditData: any[];

  creditDetailArray: any;
  creditNoteDetails: any;
  debitNoteDetails: any;

  @Input() triggerCreditCustomer: Observable<any>;
  @Input() triggerDebitCustomer: Observable<any>;
  @Input() totalPaymentAmountReceived: number;
  @Input() totalReceiptAmount: number;
  @Input() getModuleId: number;
  @Input() triggerReceiptDetail: Observable<any>;
  @Input() amountReceivedValue: number;

  @Output() amountReceived = new EventEmitter<any>();

  private destroy$ = new Subject<void>();

  totalAmount = 0;
  customerId = Guid.EMPTY as unknown as Guid;

  moduleId = Modules;
  invoiceText: string;
  amountText: string;
  headerText: string;
  isCredit = true;
  isReceiptDetailValid = true;
  periodicDate: any;
  maxLength = MaxLength;
  notificationMessage = NotificationTextMessage;
  //#endregion

  tableDataSource: MatTableDataSource<AbstractControl>;
  displayProductDetailsColumns: string[] = [
    'invoiceNo',
    'invoice',
    'date',
    'amount',
    'due',
    'allocationAmount',
    'closeButton',
  ];
  isEdit = false;
  accountGroupId: number;
  currencyId: number;
  dueAmountSymbol: string;
  amountSymbol: string;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    public commonService: CommonService,
    private globalComponent: GlobalComponent,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.currencyId = 123;
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setReceiptDetailsForm(false);

    this.triggerCreditCustomer?.subscribe((data: any) => {
      this.setData(data);
    });

    this.triggerDebitCustomer?.subscribe((data: any) => {
      this.setData(data);
    });

    this.triggerReceiptDetail
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isEdit = true;
        this.editReceiptDetail(data);
      });

    this.formCreditDetail?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.formCreditDetail.touched;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setData(data: any): void {
    const receiptLength = this.creditDetailArray.length;
    if (this.getModuleId === Modules.DebitNote) {
      this.customerId =
        data.controls.accountId.value !== this.commonService.defaultGuidValue
          ? data.controls.accountId.value == ''
            ? null
            : data.controls.accountId.value
          : this.commonService.defaultGuidValue;
      this.currencyId = data.controls.currency.value;
    } else {
      this.customerId =
        data.controls.customerName.value !== this.commonService.defaultGuidValue
          ? data.controls.customerName.value === ''
            ? null
            : data.controls.customerName.value
          : this.commonService.defaultGuidValue;
      this.currencyId = data.controls.currency.value;
    }
    for (let i = 0; i < receiptLength; i++) {
      this.creditDetailArray.removeAt(i);
      this.setDataSource(this.creditDetailArray);
    }
    this.setReceiptDetailsForm(false);
    this.setLabelText();
  }

  setLabelText(): void {
    if (this.getModuleId === Modules.DebitNote) {
      this.invoiceText = 'Purchase No';
      if (this.customerId !== null && this.customerId !== undefined) {
        this.getBillbySupplier();
      }
    } else if (this.getModuleId === Modules.CreditNote) {
      this.invoiceText = 'Invoice No';
      if (this.customerId !== null && this.customerId !== undefined) {
        this.getInvoicebyCustomer();
      }
    }
  }

  editReceiptDetail(data: any): void {
    this.creditData = [];

    this.creditDetailArray = this.formCreditDetail.get(
      'creditDetailArray'
    ) as UntypedFormArray;

    this.creditDetailArray.clear();

    data.forEach((element) => {
      this.creditData?.push(element);
    });

    this.creditData.forEach((item, i) => {
      this.creditDetailArray.push(this.buildOrderItemsForm(item));
      this.onInvoiceChange(item.toInvoiceId, i);
    });

    this.onAmountChange();
    this.setDataSource(this.creditDetailArray);
  }

  buildOrderItemsForm(item: any): FormGroup {
    return this.formBuilder.group({
      id: item.id,
      invoiceReceipt: item.toInvoiceId,
      receiptDate: item.transactionDate,
      invoiceAmount: 0,
      dueAmount: 0,
      amount: item.amount,
      invoiceTypeId: item.toInvoiceTypeID,
    });
  }

  setDefaultData(): void {
    if (this.invoiceList.length > 0) {
      const lastIndex = this.creditDetailArray.length - 1;

      if (lastIndex >= 0) {
        const control = this.creditDetailArray.controls[lastIndex];
        control.get('invoiceReceipt')?.setValue(this.invoiceList[0].id);
        this.onInvoiceChange(this.invoiceList[0].id, lastIndex);
      }
    }
  }

  getBillbySupplier(): void {
    const emptyCheck: any = this.customerId;
    if (
      emptyCheck !== '' &&
      this.customerId !== undefined &&
      this.customerId !== null &&
      this.currencyId !== null
    ) {
      this.debitNoteDetails = {
        supplierId: this.customerId,
        currencyId: this.currencyId,
      };

      this.store
        .dispatch(
          new GetPurchasesBasedOnSupplierCurrency(this.debitNoteDetails)
        )
        .subscribe((res) => {
          this.invoiceList = res.receipt.billByCustomer;
          this.setDefaultData();
        });
    }
  }

  getInvoicebyCustomer(): void {
    const emptyCheck: any = this.customerId;
    if (
      emptyCheck !== '' &&
      this.customerId !== undefined &&
      this.customerId !== null &&
      this.currencyId !== null
    ) {
      this.creditNoteDetails = {
        customerId: this.customerId,
        currencyId: this.currencyId,
      };
      this.store
        .dispatch(
          new GetInvoicesBasedOnCustomerCurrency(this.creditNoteDetails)
        )
        .subscribe((res) => {
          this.invoiceList = res.receipt.invoiceByCustomer;
          this.setDefaultData();
        });
    }
  }

  onDeleteReceiptDetails(index: number): void {
    this.creditDetailArray = this.formCreditDetail.get(
      'creditDetailArray'
    ) as UntypedFormArray;

    this.creditDetailArray.removeAt(index);
    this.setDataSource(this.creditDetailArray);
    this.onAmountChange();
  }

  onAmountChange(): void {
    const formArray = this.formCreditDetail.get(
      'creditDetailArray'
    ) as UntypedFormArray;

    this.totalAmount = 0;

    if (formArray.length > 0) {
      formArray?.getRawValue().forEach((x) => {
        this.totalAmount = this.totalAmount + Number(x.amount);
      });
    }

    this.amountReceived.emit(+this.totalAmount);
  }

  onInvoiceChange(event: any, index: number): void {
    if (event !== null && event !== undefined) {
      if (this.getModuleId === Modules.DebitNote) {
        const emptyCheck: any = this.customerId;
        if (
          emptyCheck !== '' &&
          this.customerId !== undefined &&
          this.customerId !== null &&
          this.currencyId !== null
        ) {
          this.debitNoteDetails = {
            purchaseId: event,
            currencyId: this.currencyId,
          };
          this.store
            .dispatch(new GetDebitNotePurchases(this.debitNoteDetails))
            .subscribe((res) => {
              this.setDataDisable(res.receipt.billReceiptData, index);
            });
        }
      } else if (this.getModuleId === Modules.CreditNote) {
        const emptyCheck: any = this.customerId;
        if (
          emptyCheck !== '' &&
          this.customerId !== undefined &&
          this.customerId !== null &&
          this.currencyId !== null
        ) {
          this.creditNoteDetails = {
            invoiceId: event,
            currencyId: this.currencyId,
          };
          this.store
            .dispatch(new GetCreditNoteInvoices(this.creditNoteDetails))
            .subscribe((res) => {
              this.setDataDisable(res.receipt.invoiceReceiptData, index);
            });
        }
      }
    }
  }

  setDataDisable(data: any, index: number): void {
    this.creditDetailArray.controls[index]
      .get('invoiceTypeId')
      ?.setValue(data.invoiceTypeId);
    this.creditDetailArray.controls[index]
      .get('invoiceAmount')
      ?.setValue(data.totalAmount);
    this.creditDetailArray.controls[index]
      .get('dueAmount')
      ?.setValue(data.dueAmount);
    this.dueAmountSymbol = data.dueSymbol;
    this.amountSymbol = data.symbol;
    this.creditDetailArray.controls[index].get('invoiceAmount').disable();
    this.creditDetailArray.controls[index].get('dueAmount').disable();
  }

  resetForm(): void {
    const frmArray = this.formCreditDetail.get(
      'creditDetailArray'
    ) as UntypedFormArray;
    frmArray.clear();
  }

  setReceiptDetailsForm(addNewRow: boolean): void {
    this.formCreditDetail = new FormGroup({
      creditDetailArray: new UntypedFormArray([]),
    });

    this.creditDetailArray = this.formCreditDetail.get(
      'creditDetailArray'
    ) as UntypedFormArray;
    this.setDataSource(this.creditDetailArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.creditDetailArray = this.formCreditDetail.get(
      'creditDetailArray'
    ) as UntypedFormArray;
    this.creditDetailArray.push(this.createItem());

    if (
      this.creditDetailArray.length > 0 &&
      !this.commonService.isEmpty(this.customerId)
    ) {
      if (this.getModuleId === Modules.CreditNote) {
        if (this.customerId !== null && this.customerId !== undefined) {
          this.getInvoicebyCustomer();
        }
      } else {
        if (this.customerId !== null && this.customerId !== undefined) {
          this.getBillbySupplier();
        }
      }
    }

    this.setDataSource(this.creditDetailArray);
  }

  clearAllLines(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.clearForm();
        }
      });
  }

  clearForm(): void {
    const formArray = this.formCreditDetail.get(
      'creditDetailArray'
    ) as UntypedFormArray;

    for (let i = 0; i < formArray.length; i++) {
      formArray.controls[i].reset();
      formArray.controls[i].get('invoiceAmount')?.setValue(0);
      formArray.controls[i].get('dueAmount')?.setValue(0);
      formArray.controls[i].get('amount')?.setValue(0);
    }
    this.totalAmount = 0;
  }

  addNewRow(): void {
    if (this.totalReceiptAmount === 0) {
      this.commonService.onFailure(
        NotificationTextMessage.checkAmountErrorMessage
      );
      return;
    }

    if (
      this.totalReceiptAmount <=
      this.totalAmount + +this.totalPaymentAmountReceived
    ) {
      this.commonService.onFailure(NotificationTextMessage.amountErrorMessage);
      return;
    }

    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid>(Guid.EMPTY as unknown as Guid),
      invoiceNo: new FormControl<string | null>(''),
      invoiceReceipt: new FormControl('', Validators.required),
      receiptDate: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      invoiceAmount: new FormControl<number | null>(0, Validators.required),
      dueAmount: new FormControl<number | null>(0, Validators.required),
      amount: new FormControl<string | null>('', Validators.required),
      invoiceTypeId: new FormControl<number | null>(0),
    });
  }
}
