<div class="content">
  <div class="content-body content-body-scroll">
    <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
      <mat-icon class="material-icons-outlined">error_outline</mat-icon>
      {{ commonNotificationText.ViewOnlyAccessMessage }}
    </div>
    <div
      class="mat-table-wrapper"
      [ngClass]="{ 'mat-table-wrapper-noData': noDataFound }"
    >
      <table
        mat-table
        [dataSource]="expandedCollapseList"
        multiTemplateDataRows="true"
        aria-describedby="CIS Table"
      >
        <ng-container matColumnDef="period">
          <th mat-header-cell *matHeaderCellDef>Period</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex align-items-center gap-20">
              <a
                href="javascript:void(0)"
                *ngIf="!element.isExpanded"
                class="text-primary"
              >
                <mat-icon>add</mat-icon>
              </a>
              <a
                href="javascript:void(0)"
                *ngIf="element.isExpanded"
                class="text-primary"
              >
                <mat-icon>remove</mat-icon>
              </a>
              <span>{{ element.period }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="totalSubcontractor">
          <th mat-header-cell *matHeaderCellDef>Total Subcontractor</th>
          <td mat-cell *matCellDef="let element">
            {{ element.totalSubcontractor }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <span
              [innerHTML]="
                statusText(element.id, element.status) | viewHtmlContent
              "
            ></span>
          </td>
        </ng-container>
        <ng-container matColumnDef="acceptedOn">
          <th mat-header-cell *matHeaderCellDef>Accepted On</th>
          <td mat-cell *matCellDef="let element">
            {{ element.accepted }}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-button
              class="fw-bold text-primary no-box-shadow"
              type="button"
              (click)="onDownloadClick(element, $event)"
              disableRipple
            >
              <mat-icon>picture_as_pdf</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            class="flex-column"
            colspan="5"
          >
            <app-view-cis-monthly-return
              *ngIf="element.isExpanded"
              [returnId]="element.id"
            >
            </app-view-cis-monthly-return>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          matRipple
          class="element-row"
          [class.expanded]="row.isExpanded"
          (click)="expandCollapse(row)"
        ></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          [@detailExpand]="row.isExpanded == true ? 'expanded' : 'collapsed'"
          style="overflow: hidden"
        ></tr>
      </table>
      <div *ngIf="noDataFound" class="text-align-center mt-1">
        No records found
      </div>
    </div>
  </div>
</div>
