import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  AccountEntity,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
  OpeningBalanceExplain,
} from '@app/core/Enum';
import { OpeningBalance, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  AdvanceAllotmentAccounts,
  AdvanceAllotmentAccountsByBranch,
  CommonState,
  CreateAccount,
  ExplainAllotmentAccounts,
  ExplainAllotmentAccountsByBranch,
  GetAdvanceList,
  GetAdvanceListBasedOnBranchId,
  GetExplainList,
  GetExplainListBasedOnBranchId,
  SaveOpeningBalanceAdvance,
  SaveOpeningBalanceExplain,
} from '@app/core/Store';
import { CleanAllLinesComponent, QuickAddComponent } from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-opening-balance-explain-contract',
  templateUrl: './opening-balance-explain-contract.component.html',
  styleUrls: ['./opening-balance-explain-contract.component.scss'],
})
export class OpeningBalanceExplainContractComponent implements OnInit {
  formOpeningBalanceExplain: UntypedFormGroup;
  openingBalanceExplainArray: any;
  explainContractList: any[] = [];
  openingBalanceExplainData: OpeningBalance[] = [];
  tableDataSource: MatTableDataSource<AbstractControl>;
  totalExplainedAmount = 0;
  displayOpeningBalanceExplainColumns: string[] = [
    'srNo',
    'account',
    'debit',
    'credit',
    'closeButton',
  ];
  customerList: SideListModel[];
  openingBalanceExplain = OpeningBalanceExplain;
  headerText = '';
  maxLength = MaxLength;
  accountEntity = AccountEntity;
  constructor(
    public dialog: MatDialog,
    private explainContactPopup: MatDialogRef<OpeningBalanceExplainContractComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private spinner: NgxSpinnerService
  ) {
    if (this.data.accountTypeId === AccountEntity.Customer) {
      this.headerText = 'Explain Customer';
    } else {
      this.headerText = 'Explain Supplier';
    }
  }

  ngOnInit(): void {
    this.setOpeningBalanceExplainForm(true);
    this.getName();
  }

  getName(): void {
    const entityId: number[] = [
      this.data.accountTypeId === AccountEntity.Customer
        ? AccountEntity.Customer
        : AccountEntity.Supplier,
    ];

    let dispatchAction;

    if (this.data.isAdvance) {
      dispatchAction = this.data.branchId
        ? new AdvanceAllotmentAccountsByBranch(entityId, this.data.branchId)
        : new AdvanceAllotmentAccounts(entityId);
    } else {
      dispatchAction = this.data.branchId
        ? new ExplainAllotmentAccountsByBranch(entityId, this.data.branchId)
        : new ExplainAllotmentAccounts(entityId);
    }

    this.store.dispatch(dispatchAction).subscribe((res) => {
      this.customerList = this.store.selectSnapshot(
        CommonState.allotmentAccountsList
      );
      if (!this.data.indexData.isExplainSave) {
        if (!this.data.isAdvance) {
          this.getExplainList();
        } else {
          this.getAdvanceList();
        }
      }
    });
  }

  setOpeningBalanceExplainForm(addNewRow: boolean): void {
    this.formOpeningBalanceExplain = new FormGroup({
      openingBalanceExplainArray: new UntypedFormArray([]),
    });

    this.openingBalanceExplainArray = this.formOpeningBalanceExplain.get(
      'openingBalanceExplainArray'
    ) as UntypedFormArray;

    this.setDataSource(this.openingBalanceExplainArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.openingBalanceExplainArray = this.formOpeningBalanceExplain.get(
      'openingBalanceExplainArray'
    ) as UntypedFormArray;

    this.openingBalanceExplainArray.push(this.setForm());

    this.setDataSource(this.openingBalanceExplainArray);
  }

  gotoAddCustomer(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Customers,
          headerText: `Add ${ModuleName.Customers}`,
          saveActionName: CreateAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.getName();
        }
      });
  }

  gotoAddSupplier(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Suppliers,
          headerText: `Add ${ModuleName.Suppliers}`,
          saveActionName: CreateAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.getName();
        }
      });
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      account: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      debit: new FormControl<number | null>({
        value: null,
        disabled:
          this.data.indexData.debit !== null &&
          this.data.indexData.debit !== undefined
            ? false
            : true,
      }),
      credit: new FormControl<number | null>({
        value: null,
        disabled:
          this.data.indexData.credit !== null &&
          this.data.indexData.credit !== undefined
            ? false
            : true,
      }),
    });
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  onDeleteOpeningBalanceExplain(index: number): void {
    this.openingBalanceExplainArray = this.formOpeningBalanceExplain.get(
      'openingBalanceExplainArray'
    ) as UntypedFormArray;

    this.openingBalanceExplainArray.removeAt(index);
    this.setDataSource(this.openingBalanceExplainArray);

    this.totalExplainedAmount = 0;
    this.openingBalanceExplainArray?.getRawValue().forEach((x) => {
      this.totalExplainedAmount += +x.debit + +x.credit;
    });
  }

  getExplainList(): void {
    if (this.data.branchId) {
      this.store
        .dispatch(
          new GetExplainListBasedOnBranchId(
            this.data.accountTypeId,
            this.data.branchId
          )
        )
        .subscribe((res) => {
          this.explainContractList = res.account.explainList;
          if (res.account.explainList.length > 0) {
            this.editOpeningBalanceExplain(res.account.explainList);
          }
        });
    } else {
      this.store
        .dispatch(new GetExplainList(this.data.accountTypeId))
        .subscribe((res) => {
          this.explainContractList = res.account.explainList;
          if (res.account.explainList.length > 0) {
            this.editOpeningBalanceExplain(res.account.explainList);
          }
        });
    }
  }

  getAdvanceList(): void {
    if (this.data.branchId) {
      this.store
        .dispatch(
          new GetAdvanceListBasedOnBranchId(
            this.data.accountTypeId,
            this.data.branchId
          )
        )
        .subscribe((res) => {
          this.explainContractList = res.account.advanceList;
          if (res.account.advanceList.length > 0) {
            this.editOpeningBalanceExplain(res.account.advanceList);
          }
        });
    } else {
      this.store
        .dispatch(new GetAdvanceList(this.data.accountTypeId))
        .subscribe((res) => {
          this.explainContractList = res.account.advanceList;
          if (res.account.advanceList.length > 0) {
            this.editOpeningBalanceExplain(res.account.advanceList);
          }
        });
    }
  }

  editOpeningBalanceExplain(data: any): void {
    this.openingBalanceExplainArray = this.formOpeningBalanceExplain.get(
      'openingBalanceExplainArray'
    ) as UntypedFormArray;

    this.openingBalanceExplainArray.clear();

    data.forEach((item, i) => {
      const existsInCustomerList = this.checkCustomerExist(item);

      if (!existsInCustomerList) {
        item.accountId = '';
      }
      this.openingBalanceExplainArray.push(this.buildOrderItemsForm(item));
      this.setFormValidity(i);
    });
    this.setDataSource(this.openingBalanceExplainArray);
    this.spinner.hide();
  }

  checkCustomerExist(item): boolean {
    const obj = this.customerList.find((obj) => obj.id === item.accountId);

    if (obj) {
      return this.customerList.some((obj) => obj.id === item.accountId);
    }

    return false;
  }

  setFormValidity(index: number): void {
    this.openingBalanceExplainArray.controls[index].clearValidators();
    this.openingBalanceExplainArray.controls[index]
      .get('account')
      ?.setValidators([
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]);

    this.data.indexData.debit !== null &&
    this.data.indexData.debit !== undefined
      ? this.openingBalanceExplainArray.controls[index].get('debit').enable()
      : this.openingBalanceExplainArray.controls[index].get('debit').disable();

    this.data.indexData.credit !== null &&
    this.data.indexData.credit !== undefined
      ? this.openingBalanceExplainArray.controls[index].get('credit').enable()
      : this.openingBalanceExplainArray.controls[index].get('credit').disable();

    this.openingBalanceExplainArray.controls[index].updateValueAndValidity();
  }

  calAmount(index: number, type: number): void {
    setTimeout(() => {
      const formArray = this.formOpeningBalanceExplain.get(
        'openingBalanceExplainArray'
      ) as UntypedFormArray;
      this.totalExplainedAmount = 0;
      this.openingBalanceExplainArray?.getRawValue().forEach((x) => {
        this.totalExplainedAmount += +x.debit + +x.credit;
      });

      if (this.totalExplainedAmount > this.data.totalOpeningBalance) {
        if (type === OpeningBalanceExplain.Debit) {
          formArray.controls[index].get('debit')?.setValue(null);
        } else if (type === OpeningBalanceExplain.Credit) {
          formArray.controls[index].get('credit')?.setValue(null);
        }
        formArray.controls[index].updateValueAndValidity();
        this.commonService.onFailure(
          NotificationTextMessage.explainOpeningBalance
        );
        return;
      }
    }, 200);
  }

  buildOrderItemsForm(item: any): FormGroup {
    return this.formBuilder.group({
      id: item.id,
      account: item.accountId,
      debit: item.debit === 0 ? null : item.debit,
      credit: item.credit === 0 ? null : item.credit,
    });
  }

  addNewRow(): void {
    if (this.totalExplainedAmount >= this.data.totalOpeningBalance) {
      this.commonService.onFailure(
        NotificationTextMessage.explainOpeningBalance
      );
      return;
    }
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formOpeningBalanceExplain.get(
            'openingBalanceExplainArray'
          ) as UntypedFormArray;
          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
            formArray.controls[i]
              .get('id')
              ?.setValue(Guid.EMPTY as unknown as Guid);
            formArray.controls[i].get('debit')?.setValue(null);
            formArray.controls[i].get('credit')?.setValue(null);
            formArray.controls[i].get('account')?.setValue('');
            this.setFormValidity(i);
          }
        }
      });
  }

  onCancel(): void {
    this.explainContactPopup.close();
  }

  dataSubmit(): void {
    this.openingBalanceExplainArray?.getRawValue().forEach((x) => {
      const param: any = {
        id: x.id,
        accountId: x.account,
        debit: +x.debit,
        credit: +x.credit,
        accountingPeriodId: this.data.accountingPeriodId,
        date: this.data.accountingDate,
        branchId: this.data.branchId,
      };
      this.openingBalanceExplainData.push(param);
    });
  }

  creditChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      const formArray = this.formOpeningBalanceExplain.get(
        'openingBalanceExplainArray'
      ) as UntypedFormArray;
      formArray.controls[index].get('debit')?.setValue(null);
      formArray.controls[index].get('debit')?.clearValidators();
      formArray.controls[index]
        .get('account')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);

      formArray.controls[index].updateValueAndValidity();
    }
  }

  debitChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      const formArray = this.formOpeningBalanceExplain.get(
        'openingBalanceExplainArray'
      ) as UntypedFormArray;
      formArray.controls[index].get('credit')?.setValue(null);
      formArray.controls[index].get('credit')?.clearValidators();
      formArray.controls[index]
        .get('account')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);
      formArray.controls[index].updateValueAndValidity();
    }
  }

  onSave(): void {
    let saveActionName: any;
    if (this.data.isAdvance) {
      saveActionName = SaveOpeningBalanceAdvance;
    } else {
      saveActionName = SaveOpeningBalanceExplain;
    }
    if (this.totalExplainedAmount === 0) {
      this.totalExplainedAmount = 0;
      this.openingBalanceExplainArray?.getRawValue().forEach((x) => {
        this.totalExplainedAmount += +x.debit + +x.credit;
      });
    }
    if (this.formOpeningBalanceExplain.invalid) {
      this.openingBalanceExplainArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
      return;
    } else if (this.totalExplainedAmount !== this.data.totalOpeningBalance) {
      this.commonService.onFailure(
        NotificationTextMessage.explainOpeningBalance
      );
      return;
    }
    this.spinner.show();
    this.dataSubmit();
    this.store
      .dispatch(
        new saveActionName(
          this.openingBalanceExplainData,
          this.data.accountTypeId
        )
      )
      .subscribe((res) => {
        this.spinner.hide();
        if (res.openingBalance.isOpeningBalanceExplain) {
          this.commonService.onSuccess(NotificationTextMessage.successMessage);
          const data = {
            sumOfAllDrCr: this.getSumOfAllDrCr(),
            isExplainSave: true,
            explainList: this.openingBalanceExplainData,
          };
          this.explainContactPopup.close(true);
        } else {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      });
  }

  getSumOfAllDrCr(): number {
    let sumOfAllDrCr = 0;

    this.openingBalanceExplainData.forEach((element) => {
      let total = +element.debit! + +element.credit!;

      sumOfAllDrCr = +total;
    });

    return sumOfAllDrCr;
  }
}
