import {
  Component,
  Injector,
  Input,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import {
  AccountEntity,
  ChartOfAccountCurrentAssetGroupTypeName,
  ChartOfAccountCurrentLiabilitiesGroupTypeName,
  GroupNames,
  MaxLength,
  NotificationTextMessage,
} from '@app/core/Enum';
import { AccountNumber } from '@app/core/Enum/account-number';
import { VatRateCodeType } from '@app/core/Enum/vat-rate-code-type';
import { GlobalComponent, InvoiceModel } from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import {
  CommonState,
  GetAccountGroupAndType,
  GetJournalAccounts,
  GetNonStandardAccountList,
  GetVatRateList,
} from '@app/core/Store';
import { CleanAllLinesComponent } from '@app/modules';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-bulk-journal',
  templateUrl: './add-bulk-journal.component.html',
  styleUrls: ['./add-bulk-journal.component.scss'],
})
export class AddBulkJournalComponent implements OnInit {
  formJournalDetail: UntypedFormGroup;
  journalDetailArray: any;

  displayJournalDetailsColumns: string[] = [
    'account',
    'description',
    'customer/supplier',
    'vatRate',
    'vatAmount',
    'debit',
    'credit',
    'closeButton',
  ];

  accountList: any[] = [];
  accountData: any[] = [];
  customerSupplierList: any[] = [];
  receiptPaymentList: any[];
  vatRateList: any[] = [];
  listModelsIds: any[];
  accountGroupList: any[] = [];
  tempAccountGroupList: any[] = [];
  customVatAmount: number = 0;

  accountGroupAndTypeId: any;
  totalDebitValue = 0;
  totalCreditValue = 0;
  vatRate = 0;
  vatCodeType = VatRateCodeType.Normal;
  transferAmount = 0;
  primaryContact = {
    id: 0,
  };
  maxLength = MaxLength;
  isAddMode = true;
  isFromBankImport = false;
  isCredit = true;
  isVatRegistered: any;
  isDataFromBadDebts = false;

  notificationMessage = NotificationTextMessage;

  defaultCurrency: Guid;
  subscriptionRouting: Subscription;
  badDebtsDataList: any;
  tableDataSource: MatTableDataSource<AbstractControl>;
  badDebtsData: any;
  store: Store;
  commonService: CommonService;
  @Input()
  triggerBadDebtsDataList: any;

  @Input() triggerEditData: Observable<any>;
  @Input() triggerTransactionLogData: Observable<any>;
  @Input() triggerOnCurrencyRateUpdate: Observable<any>;
  @Input() triggerBadDebtsData: Observable<any>;
  private destroy$ = new Subject<void>();
  spinner: NgxSpinnerService;
  constructor(
    private _Activatedroute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public highlightRow: HighlightRow,

    private renderer: Renderer2,
    private injector: Injector,
    public globalComponent: GlobalComponent
  ) {
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
    this.customerSupplierList = [];
    this.receiptPaymentList = [];
    this.vatRateList = [];
  }

  invoiceId = Guid.EMPTY as unknown as Guid;
  invoiceData: InvoiceModel;
  ngOnInit(): void {
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    if (!this.isVatRegistered) {
      this.displayJournalDetailsColumns =
        this.displayJournalDetailsColumns.filter(
          (column) => column !== 'vatRate' && column !== 'vatAmount'
        );
    }
    this.setJournalDetailsForm(true);
    this.isAddMode = true;
    this.isFromBankImport = false;

    this.triggerBadDebtsData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.badDebtsData = data;
      });

    this.triggerTransactionLogData
      ?.pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((item) => {
        this.getAccounts(true, item);
      });
    this.triggerOnCurrencyRateUpdate?.subscribe((data) => {
      if (data) {
        if (this.isCredit) {
          this.journalDetailArray.controls[0]
            .get('debit')
            .setValue((this.transferAmount / data).toFixed(2));
        } else {
          this.journalDetailArray.controls[0]
            .get('credit')
            .setValue((this.transferAmount / data).toFixed(2));
        }
        this.totalCreditValue = 0;
        this.totalDebitValue = 0;
        this.journalDetailArray?.getRawValue().forEach((x) => {
          this.totalCreditValue += +x.credit;
          this.totalDebitValue += +x.debit;
        });
      }
    });
    this.triggerEditData?.subscribe((data) => {
      this.isAddMode = false;
      this.customerSupplierList = [];
      this.vatRateList = [];
      this.isFromBankImport = false;
      const typeId: any = [];
      typeId.push(AccountEntity.Customer);
      if (data.invoiceId != null) {
        this.isDataFromBadDebts = true;
      }
      setTimeout(() => {
        data.journalItems?.forEach((item, i) => {
          this.spinner.show();
          const customerSupplier = this.store.dispatch(
            new GetNonStandardAccountList(typeId)
          );

          forkJoin([customerSupplier]).subscribe((res) => {
            this.spinner.hide();
            const customerSupplierInfo = this.store.selectSnapshot(
              CommonState.accountList
            );

            this.setCustomerSupplierList(customerSupplierInfo, i);
          });
        });
      }, 1500);

      this.loadDropdownValues(false).then(() => {
        this.editJournal(data.journalItems);
      });
    });

    this.formJournalDetail?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.formJournalDetail.touched;
    });
  }

  isBadDebt = false;
  ngOnChanges(changes: SimpleChanges) {
    if (
      this.triggerBadDebtsDataList &&
      this.triggerBadDebtsDataList.dueAmount != null
    ) {
      if (changes['triggerBadDebtsDataList']) {
        this.badDebtsDataList = this.triggerBadDebtsDataList;
        this.isBadDebt = true;
      }
    }
  }

  setJournalsDetails(badDebtsData: any, index: number): void {
    let dueAmountCredit;
    let dueAmountDebit;

    this.journalDetailArray.controls[index].get('isDisable').setValue(true);
    this.journalDetailArray.controls[index].disable();

    if (index == 0) {
      dueAmountDebit = badDebtsData.dueAmount;
      dueAmountCredit = 0;
      this.journalDetailArray.controls[index].get('description').enable();
      this.journalDetailArray.controls[index].get('customerSupplier').enable();
      this.journalDetailArray.controls[index]
        .get('vatAmount')
        .setValue(badDebtsData.vatRateAmount);
    } else {
      dueAmountDebit = 0;
      dueAmountCredit = badDebtsData.dueAmount;
      this.journalDetailArray.controls[index].get('description').enable();
      this.journalDetailArray.controls[index].get('account').enable();
    }

    this.journalDetailArray.controls[index]
      .get('debit')
      .setValue(Number(dueAmountDebit).toFixed(2));
    this.journalDetailArray.controls[index]
      .get('credit')
      .setValue(Number(dueAmountCredit).toFixed(2));

    this.journalDetailArray.controls[index]
      .get('vatRate')
      .setValue(badDebtsData.vatRateId);
  }

  buildBadDebtsItemsForm(item: any): FormGroup {
    return this.formBuilder.group({
      id: item.id,
      account: item.accountId,
      description: item.description,
      customerSupplier: item.customerSupplierId,
      vatRate: '',
      vatAmount: item.vatAmount,
      debit: item.isCredit ? null : item.totalAmount,
      credit: item.isCredit ? item.totalAmount : null,
      isDisable: false,
    });
  }

  setAccountDataFromBankOverview(item: any): void {
    this.isFromBankImport = true;
    this.isCredit = item.isCredit ? false : true;
    let account;
    this.accountGroupList.forEach((items) => {
      const data = items.listModels.find((x: any) => x.id === item.accountId);
      if (data !== null && data !== undefined) {
        account = data;
      }
    });
    this.transferAmount = item.amount;
    if (this.isCredit) {
      this.journalDetailArray.controls[0]
        .get('debit')
        .setValue((item.amount / item.currencyRate).toFixed(2));
    } else {
      this.journalDetailArray.controls[0]
        .get('credit')
        .setValue((item.amount / item.currencyRate).toFixed(2));
    }
    this.journalDetailArray.controls[0].get('isDisable').setValue(true);
    this.journalDetailArray.controls[0].get('account').setValue(account);
    this.journalDetailArray.controls[0].disable();
    this.journalDetailArray?.getRawValue().forEach((x) => {
      this.totalCreditValue += +x.credit;
      this.totalDebitValue += +x.debit;
    });
    this.checkModuleType(account, 0);
  }
  accountBadDebtsEntry: any;
  setAccountDataFromBadDebts(): void {
    this.isDataFromBadDebts = true;
    let account;
    this.accountGroupList.forEach((items) => {
      const data = items.listModels.find(
        (x: any) => x.name === '1527 - Bad Debts Written Off '
      );
      if (data !== null && data !== undefined) {
        account = data;
        this.accountBadDebtsEntry = data;
      }
    });

    this.journalDetailArray.controls[0].get('account').setValue(account);

    let tradeAccount;
    this.accountGroupList.forEach((items) => {
      const data = items.listModels.find(
        (x: any) => x.name === '8016 - Trade debtors'
      );
      if (data !== null && data !== undefined) {
        tradeAccount = data;
        this.accountBadDebtsEntry = data;
      }
    });

    this.journalDetailArray.controls[1].get('account').setValue(tradeAccount);

    const typeId: any = [];
    typeId.push(AccountEntity.Customer);

    this.journalDetailArray.controls.forEach((x, index) => {
      this.forkJoinCall(typeId, account.id, index);
      this.journalDetailArray.controls[index].get('customerSupplier').disable();
    });
  }

  async loadDropdownValues(isAddMode: boolean): Promise<void> {
    await this.getAccounts(isAddMode);
  }

  scrollIntoView(element) {
    if (element.value.account !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  async getAccounts(isAddMode: boolean, items?: any): Promise<void> {
    this.spinner.show();
    await this.store
      .dispatch(new GetJournalAccounts())
      .toPromise()
      .then((res) => {
        this.spinner.hide();
        this.accountGroupList = res.account.accounts;
        this.tempAccountGroupList = this.accountGroupList;
        if (this.accountGroupList.length > 0 && isAddMode) {
          this.accountGroupList.forEach((items) => {
            items.listModels.forEach((item) => {
              this.listModelsIds = item;
            });
          });
          this.checkModuleType(
            this.listModelsIds,
            this.journalDetailArray.length - 1
          );
        }
        if (
          this.accountGroupList.length > 0 &&
          items !== null &&
          items !== undefined
        ) {
          this.setAccountDataFromBankOverview(items);
        }

        if (this.accountGroupList.length > 0 && this.isBadDebt) {
          this.setAccountDataFromBadDebts();
        }
      });
  }

  displayFn(account: any): string {
    return account && account.name ? account.name : '';
  }

  onSearch(event: any, i: number): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );

    if (list.length === 0) {
      this.journalDetailArray.controls[i].get('account').setValue('');
      this.accountGroupList = this.tempAccountGroupList;
    } else {
      this.journalDetailArray.controls[i]
        .get('account')
        .setValue(list[0].listModels[0]);
    }
  }

  resetAccountList(element: any): void {
    this.accountGroupList = this.tempAccountGroupList;
    if (
      element.account !== null &&
      element.account !== undefined &&
      element.account.value !== null &&
      element.account.value !== undefined
    ) {
      this.scrollIntoView(element.account.value);
    }
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    this.accountGroupList = list;
  }

  getOptionText(option) {
    return option?.name;
  }

  async checkModuleType(event: any, index: number): Promise<void> {
    await this.verifyModuleType(event, index);
  }

  async verifyModuleType(event: any, index: number): Promise<void> {
    await this.store
      .dispatch(new GetAccountGroupAndType(event.id))
      .toPromise()
      .then((res) => {
        if (res.account.accountGroupAndType) {
          this.accountGroupAndTypeId = res.account.accountGroupAndType;

          if (
            this.accountGroupAndTypeId.groupId === GroupNames.Turnover ||
            this.accountGroupAndTypeId.groupId === GroupNames.CostOfSales ||
            this.accountGroupAndTypeId.groupId ===
              GroupNames.SellingAndDistributionCosts ||
            this.accountGroupAndTypeId.groupId ===
              GroupNames.AdministrativeExpenses ||
            this.accountGroupAndTypeId.groupId ===
              GroupNames.FixedAssetsIntangibles ||
            this.accountGroupAndTypeId.groupId ===
              GroupNames.FixedAssetsLeased ||
            this.accountGroupAndTypeId.groupId ===
              GroupNames.FixedAssetsTangibles ||
            this.accountGroupAndTypeId.groupId ===
              GroupNames.FixedAssetsInvestments ||
            this.accountGroupAndTypeId.groupId ===
              GroupNames.FixedAssetsInvestmentProperty
          ) {
            this.getVatRateList(index);
            this.setValidation(index, true);
          } else if (
            this.accountGroupAndTypeId.groupId === GroupNames.Debtors &&
            this.accountGroupAndTypeId.typeId ===
              ChartOfAccountCurrentAssetGroupTypeName.DebtorsLessThanOneYearTradeDebtors
          ) {
            const typeId: any = [];

            typeId.push(AccountEntity.Customer);
            this.forkJoinCall(typeId, event.id, index);
            this.setValidation(index, false);
          } else if (
            this.accountGroupAndTypeId.groupId === GroupNames.Debtors &&
            this.accountGroupAndTypeId.typeId ===
              ChartOfAccountCurrentAssetGroupTypeName.DebtorsLessThanOneYearPrepaymentsAndAccruedIncome &&
            event.name === AccountNumber.ACCOUNT_8035
          ) {
            const typeId: any = [];

            typeId.push(AccountEntity.Customer);
            this.forkJoinCall(typeId, event.id, index);
            this.setValidation(index, false);
          } else if (
            this.accountGroupAndTypeId.groupId === GroupNames.Debtors &&
            this.accountGroupAndTypeId.typeId ===
              ChartOfAccountCurrentAssetGroupTypeName.DebtorsMoreThanOneYearTradeDebtors &&
            event.name === AccountNumber.ACCOUNT_8110
          ) {
            const typeId: any = [];

            typeId.push(AccountEntity.Customer);
            this.forkJoinCall(typeId, event.id, index);
            this.setValidation(index, false);
          } else if (
            this.accountGroupAndTypeId.groupId === GroupNames.Creditors &&
            this.accountGroupAndTypeId.typeId ===
              ChartOfAccountCurrentLiabilitiesGroupTypeName.CreditorsLessThanOneYearTradeCreditors
          ) {
            const typeId: any = [];
            typeId.push(AccountEntity.Supplier);
            this.forkJoinCall(typeId, event.id, index);
            this.setValidation(index, false);
          } else if (
            this.accountGroupAndTypeId.groupId === GroupNames.Creditors &&
            this.accountGroupAndTypeId.typeId ===
              ChartOfAccountCurrentLiabilitiesGroupTypeName.CreditorsLessThanOneYearAccrualsandDeferredIncome &&
            event.name === AccountNumber.ACCOUNT_8239
          ) {
            const typeId: any = [];
            typeId.push(AccountEntity.Supplier);
            this.forkJoinCall(typeId, event.id, index);
            this.setValidation(index, false);
          } else if (
            this.accountGroupAndTypeId.groupId === GroupNames.Creditors &&
            this.accountGroupAndTypeId.typeId ===
              ChartOfAccountCurrentLiabilitiesGroupTypeName.CreditorsMoreThanOneYearTradeCreditors &&
            event.name === AccountNumber.ACCOUNT_8310
          ) {
            const typeId: any = [];
            typeId.push(AccountEntity.Supplier);
            this.forkJoinCall(typeId, event.id, index);
            this.setValidation(index, false);
          } else {
            this.journalDetailArray.controls[index].get('vatRate').disable();
            this.journalDetailArray.controls[index]
              .get('customerSupplier')
              .disable();
            this.journalDetailArray.controls[index]
              .get('customerSupplier')
              .setValue(null);
            this.journalDetailArray.controls[index]
              .get('vatRate')
              .setValue(null);
          }
        }
      });
  }

  setValidation(index: number, isVatScheme): void {
    if (isVatScheme && !this.isDataFromBadDebts) {
      this.journalDetailArray.controls[index]?.clearValidators();
      this.journalDetailArray.controls[index].get('vatRate').enable();
      this.journalDetailArray.controls[index]
        .get('customerSupplier')
        .setValue(null);
      this.journalDetailArray.controls[index].get('customerSupplier').disable();
      this.journalDetailArray.controls[index]?.updateValueAndValidity();
    } else if (isVatScheme && this.isDataFromBadDebts) {
      this.journalDetailArray.controls[index].get('customerSupplier').disable();
      this.journalDetailArray.controls[index].get('vatRate').disable();
      this.journalDetailArray.controls[1].get('vatRate').setValue(null);
    } else if (this.isDataFromBadDebts) {
      this.journalDetailArray.controls[index].get('customerSupplier').disable();
      this.journalDetailArray.controls[index].get('vatRate').disable();
      this.journalDetailArray.controls[1].get('vatRate').setValue(null);
    } else {
      this.journalDetailArray.controls[index]?.clearValidators();
      this.journalDetailArray.controls[index].get('vatRate').disable();
      this.journalDetailArray.controls[index].get('vatRate').setValue(null);
      this.journalDetailArray.controls[index].get('customerSupplier').enable();
      this.journalDetailArray.controls[index]
        .get('customerSupplier')
        ?.updateValueAndValidity();
      this.journalDetailArray.controls[index]?.updateValueAndValidity();
    }
  }

  forkJoinCall(entityId: any, accountId: any, i: number): void {
    this.spinner.show();
    const customerSupplier = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );

    forkJoin([customerSupplier]).subscribe((res) => {
      this.spinner.hide();
      const customerSupplierInfo = this.store.selectSnapshot(
        CommonState.accountList
      );

      this.setCustomerSupplierList(customerSupplierInfo, i);
    });
  }

  setCustomerSupplierList(customerSupplierInfo: any, i: number): void {
    if (
      this.customerSupplierList.length > 0 &&
      this.customerSupplierList[i] !== null &&
      this.customerSupplierList[i] !== undefined
    ) {
      this.customerSupplierList[i] = customerSupplierInfo;
      if (
        this.isBadDebt ||
        this.accountName?.trim() === 'Bad Debts Written Off'
      ) {
        this.journalDetailArray.controls[i]
          .get('customerSupplier')
          .setValue(this.badDebtsDataList.customerId);
      } else {
        let customerSupplierValue =
          this.journalDetailArray.controls[i].get('customerSupplier')?.value;
        if (
          customerSupplierValue !== null &&
          customerSupplierValue !== undefined &&
          customerSupplierValue !== '' &&
          customerSupplierValue !== this.commonService.defaultGuidValue
        ) {
          this.journalDetailArray.controls[i]
            .get('customerSupplier')
            .setValue(customerSupplierValue);
        } else {
          this.journalDetailArray.controls[i]
            .get('customerSupplier')
            .setValue('');
        }
      }
      if (!this.isBadDebt) this.vatRateList[i] = [];
    } else {
      this.customerSupplierList.push(customerSupplierInfo);
      if (
        this.isBadDebt ||
        this.accountName?.trim() === 'Bad Debts Written Off'
      ) {
        this.journalDetailArray.controls[i]
          .get('customerSupplier')
          .setValue(this.badDebtsDataList.customerId);
      }
      this.vatRateList.push([]);
    }
  }

  async getVatRateList(i: number): Promise<void> {
    this.spinner.show();
    await this.store
      .dispatch(new GetVatRateList())
      .toPromise()
      .then((res) => {
        this.spinner.hide();
        if (
          this.vatRateList.length > 0 &&
          this.vatRateList[i] !== null &&
          this.vatRateList[i] !== undefined
        ) {
          this.vatRateList[i] = res.common.vatRate;
          this.receiptPaymentList[i] = [];
          this.customerSupplierList[i] = [];
        } else {
          this.vatRateList.push(res.common.vatRate);
          this.receiptPaymentList.push([]);
          this.customerSupplierList.push([]);
        }
      });
  }

  creditChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      const formArray = this.formJournalDetail.get(
        'journalDetailArray'
      ) as UntypedFormArray;

      formArray.controls[index].get('debit')?.setValue(null);
      formArray.controls[index].get('debit')?.clearValidators();
      formArray.controls[index]
        .get('credit')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
          Validators.pattern(/^(?=.*[1-9])\d+(?:\.*\d\d?)?$/),
        ]);

      formArray.controls[index].get('credit')?.updateValueAndValidity();
      formArray.controls[index].get('debit')?.updateValueAndValidity();
    }
  }

  debitChanges(index: any, event: any): void {
    if (event.keyCode !== 9) {
      const formArray = this.formJournalDetail.get(
        'journalDetailArray'
      ) as UntypedFormArray;

      formArray.controls[index].get('credit')?.setValue(null);
      formArray.controls[index].get('credit')?.clearValidators();
      formArray.controls[index]
        .get('debit')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
          Validators.pattern(/^(?=.*[1-9])\d+(?:\.*\d\d?)?$/),
        ]);
      formArray.controls[index].get('credit')?.updateValueAndValidity();
      formArray.controls[index].get('debit')?.updateValueAndValidity();
    }
  }

  calVatAmount(index: number): void {
    if (this.vatRateList[0]) {
      let data = this.vatRateList[index].filter((x) => {
        return (
          x.id === this.journalDetailArray.controls[index].get('vatRate').value
        );
      });

      if (data.length > 0) {
        this.vatRate = data[0].rate;
        this.vatCodeType = data[0].codeType;
        if (data[0].codeType === VatRateCodeType.Custom) {
          this.journalDetailArray.controls[index].get('vatAmount').enable();
          this.customVatAmount = Number(
            this.journalDetailArray.controls[index].get('vatAmount').value
          );
        } else {
          this.journalDetailArray.controls[index].get('vatAmount').disable();
        }
      } else {
        this.vatRate = 0;
        this.vatCodeType = VatRateCodeType.Normal;
      }
    }

    const vatRate = this.vatRate;

    if (+this.journalDetailArray.controls[index].get('credit').value !== 0) {
      const credit =
        +this.journalDetailArray.controls[index].get('credit').value === 0
          ? 0
          : +this.journalDetailArray.controls[index].get('credit').value;

      const vatAmount = !(this.vatCodeType === VatRateCodeType.Custom)
        ? (credit * (vatRate / 100)) / (1 + vatRate / 100)
        : this.customVatAmount;

      this.journalDetailArray.controls[index]
        .get('vatAmount')
        ?.setValue(vatAmount.toFixed(2));
    } else {
      const debit =
        +this.journalDetailArray.controls[index].get('debit').value === 0
          ? 0
          : +this.journalDetailArray.controls[index].get('debit').value;

      const vatAmount = !(this.vatCodeType === VatRateCodeType.Custom)
        ? (debit * (vatRate / 100)) / (1 + vatRate / 100)
        : this.customVatAmount;

      this.journalDetailArray.controls[index]
        .get('vatAmount')
        ?.setValue(vatAmount.toFixed(2));
    }

    this.totalCreditValue = 0;
    this.totalDebitValue = 0;
    this.journalDetailArray?.getRawValue().forEach((x) => {
      this.totalCreditValue += +x.credit;
      this.totalDebitValue += +x.debit;
    });
  }

  resetForm(): void {
    const formArray = this.formJournalDetail.get(
      'journalDetailArray'
    ) as UntypedFormArray;
    formArray.clear();
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formJournalDetail.get(
            'journalDetailArray'
          ) as UntypedFormArray;
          for (let i = 0; i < formArray.length; i++) {
            if (!formArray.controls[0].value.isDisable) {
              formArray.controls[i].reset();
              formArray.controls[i]
                .get('id')
                ?.setValue(Guid.EMPTY as unknown as Guid);
              formArray.controls[i].get('account')?.setValue('');
              formArray.controls[i]
                .get('account')
                ?.setValidators(Validators.required);
              formArray.controls[i].get('account')?.updateValueAndValidity();
              this.setValidation(i, false);
            }
          }
        }
      });
  }

  setJournalDetailsForm(addNewRow: boolean): void {
    this.formJournalDetail = new FormGroup({
      journalDetailArray: new UntypedFormArray([]),
    });

    this.journalDetailArray = this.formJournalDetail.get(
      'journalDetailArray'
    ) as UntypedFormArray;

    this.setDataSource(this.journalDetailArray);
    if (addNewRow && this.isBadDebt) {
      this.getVatRateList(0);

      for (let i = 0; i < 2; i++) {
        this.createRow();
        this.setJournalsDetails(this.badDebtsDataList, i);
      }
      this.totalCreditValue = this.badDebtsDataList.dueAmount;
      this.totalDebitValue = this.badDebtsDataList.dueAmount;
    }
    if (addNewRow && !this.isBadDebt) this.createRow();
  }

  createRow(): void {
    this.journalDetailArray = this.formJournalDetail.get(
      'journalDetailArray'
    ) as UntypedFormArray;

    this.journalDetailArray.push(this.setForm());

    if (this.journalDetailArray.length > 0) {
      this.loadDropdownValues(true);
    }

    this.setDataSource(this.journalDetailArray);
  }

  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  setForm(): FormGroup {
    const form = this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      account: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      customerSupplier: new FormControl(''),
      vatRate: new FormControl(''),
      vatAmount: new FormControl({ value: 0.0, disabled: true }),
      debit: new FormControl(null),
      credit: new FormControl(null),
      isDisable: new FormControl(false),
    });

    if (this.isFromBankImport) {
      if (this.isCredit) {
        form.controls.debit.disable();
      } else {
        form.controls.credit.disable();
      }
    }

    return form;
  }

  onDeleteProductDetails(index: number): void {
    this.journalDetailArray = this.formJournalDetail.get(
      'journalDetailArray'
    ) as UntypedFormArray;
    if (this.journalDetailArray.length === 1) {
      return;
    }

    this.journalDetailArray.removeAt(index);

    this.customerSupplierList.splice(index, 1);
    this.receiptPaymentList.splice(index, 1);
    this.vatRateList.splice(index, 1);

    this.setDataSource(this.journalDetailArray);
  }
  accountName: any;
  editJournal(data: any): void {
    this.totalCreditValue = 0;
    this.totalDebitValue = 0;
    this.journalDetailArray = this.formJournalDetail.get(
      'journalDetailArray'
    ) as UntypedFormArray;

    this.journalDetailArray.clear();
    this.accountName = data[0]?.accountName;
    data?.forEach((item, i) => {
      if (this.vatRateList.length === 0) {
        this.vatRateList.push([]);
      } else {
        if (this.accountName?.trim() != 'Bad Debts Written Off')
          this.vatRateList[i] = [];
      }
      if (this.customerSupplierList.length === 0) {
        this.customerSupplierList.push([]);
      } else {
        if (this.accountName?.trim() != 'Bad Debts Written Off')
          this.customerSupplierList[i] = [];
      }
      this.journalDetailArray.push(this.buildOrderItemsForm(item));
      let account;
      this.accountGroupList.forEach((items) => {
        const data = items.listModels.find((x: any) => x.id === item.accountId);
        if (data !== null && data !== undefined) {
          account = data;
        }
      });
      if (this.accountName?.trim() === 'Bad Debts Written Off') {
        this.journalDetailArray.controls[i].disable();
      }
      this.journalDetailArray.controls[i].get('account').setValue(account);
      this.journalDetailArray.controls[i].get('vatAmount').disable();
      this.checkModuleType(account, i);
      this.journalDetailArray.controls[i]
        .get('vatRate')
        .setValue(item.vatRateId);
    });

    this.journalDetailArray?.getRawValue().forEach((x) => {
      this.totalCreditValue += +x.credit;
      this.totalDebitValue += +x.debit;
    });
    this.setDataSource(this.journalDetailArray);
  }

  buildOrderItemsForm(item: any): FormGroup {
    return this.formBuilder.group({
      id: item.id,
      account: item.accountId,
      description: item.description,
      customerSupplier: item.customerSupplierId,
      vatRate: '',
      vatAmount: item.vatAmount,
      debit: item.isCredit ? null : item.totalAmount,
      credit: item.isCredit ? item.totalAmount : null,
      isDisable: false,
    });
  }
}
