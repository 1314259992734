import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AccountEntity,
  AccountTypeImport,
  ComponentName,
  DetailListModules,
  ModuleName,
  Modules,
  MTD,
  NotificationHeader,
  OverviewYear,
  RoutingPath,
  SortOrdering,
} from '@app/core/Enum';
import { PermissionType } from '@app/core/Enum/settings';
import {
  AccountDetailViewModel,
  ChartOfAccountListParam,
  ComparitiveReportParamerters,
  Country,
  Currency,
  CustomEmailModel,
  DefaultCurrency,
  FileDownloadRequest,
  FileUploadRequestModel,
  FilterDateRange,
  FinancialData,
  GlobalStandardPermission,
  GroupListModel,
  HeaderModel,
  MainList,
  MainListParameters,
  MenuModel,
  MultipleFileDownloadModel,
  PermissionModel,
  ProductDetailsModel,
  ProductModel,
  QueryParams,
  RefundInputParameters,
  SideListModel,
  TshqRequestOptions,
  VatRateScheme,
  VATReportModel,
  ViewDetails,
  ViewParamModel,
} from '@app/core/Models';
import { MenuState, SetModulePermission } from '@app/core/Store';
import {
  DepreciationExpandedCollapseComponent,
  ViewReceiptComponent,
} from '@app/modules';
import { environment } from '@environments/environment';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { HighlightRow, ModulePermission } from '..';
import { NotificationService } from './notification-service.service';

@Injectable()
export class CommonService {
  public isPhoneValid = true;
  budgetingCalculation = [
    {
      id: 1,
      name: 'Adjust by fixed amount for each month:',
    },
    {
      id: 2,
      name: 'Adjust by fixed incremented amount for each month:',
    },
    {
      id: 3,
      name: 'Adjust amount by fixed percentage:',
    },
  ];
  forPeriodList = [
    {
      id: 3,
      name: '3 Months',
    },
    {
      id: 6,
      name: '6 Months',
    },
    {
      id: 12,
      name: '12 Months',
    },
    {
      id: 24,
      name: '24 Months',
    },
  ];

  actualPeriodList = [
    {
      id: 0,
      name: 'Not Required',
    },
    {
      id: 1,
      name: '1 Months',
    },
    {
      id: 2,
      name: '2 Months',
    },
    {
      id: 3,
      name: '3 Months',
    },
    {
      id: 4,
      name: '4 Months',
    },
    {
      id: 5,
      name: '5 Months',
    },
    {
      id: 6,
      name: '6 Months',
    },
    {
      id: 7,
      name: '7 Months',
    },
    {
      id: 8,
      name: '8 Months',
    },
    {
      id: 9,
      name: '9 Months',
    },
    {
      id: 10,
      name: '10 Months',
    },
    {
      id: 11,
      name: '11 Months',
    },
    {
      id: 12,
      name: '12 Months',
    },
  ];

  public receiptList: any[] = [
    {
      id: 1,
      name: ModuleName.MatchJournal,
      moduleId: Modules.MatchJournal,
    },
    {
      id: 2,
      name: ModuleName.Journals,
      moduleId: Modules.Journals,
    },
    {
      id: 3,
      name: ModuleName.BankTransfer,
      moduleId: Modules.BankTransfer,
    },
    {
      id: 4,
      name: ModuleName.Invoices,
      moduleId: Modules.Invoices,
    },
    {
      id: 5,
      name: ModuleName.Receipt,
      moduleId: Modules.Receipt,
    },

    {
      id: 7,
      name: ModuleName.DebitNote,
      moduleId: Modules.DebitNote,
    },
    {
      id: 8,
      name: ModuleName.MatchQuickEntry,
      moduleId: Modules.MatchQuickEntry,
    },
    {
      id: 9,
      name: ModuleName.MultipleTransactions,
      moduleId: Modules.MultipleTransactions,
    },
    {
      id: 10,
      name: ModuleName.Refund,
      moduleId: Modules.Refund,
    },
  ];

  public paymentList: any[] = [
    {
      id: 1,
      name: ModuleName.Journals,
      moduleId: Modules.Journals,
    },
    {
      id: 2,
      name: ModuleName.MatchJournal,
      moduleId: Modules.MatchJournal,
    },
    {
      id: 3,
      name: ModuleName.Payment,
      moduleId: Modules.Payment,
    },
    {
      id: 4,
      name: ModuleName.BankTransfer,
      moduleId: Modules.BankTransfer,
    },
    {
      id: 5,
      name: ModuleName.Purchase,
      moduleId: Modules.Purchase,
    },
    {
      id: 6,
      name: ModuleName.CreditNote,
      moduleId: Modules.CreditNote,
    },
    {
      id: 7,
      name: ModuleName.MatchQuickEntry,
      moduleId: Modules.MatchQuickEntry,
    },
    {
      id: 8,
      name: ModuleName.MultipleTransactions,
      moduleId: Modules.MultipleTransactions,
    },
    {
      id: 9,
      name: ModuleName.Refund,
      moduleId: Modules.Refund,
    },
  ];

  public isEmailValid = true;
  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;
  store: Store;
  modulePermission: ModulePermission;

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private notifier: NotificationService,
    private router: Router,
    private highlightRow: HighlightRow,
    public cookieService: CookieService,
    public dialog: MatDialog
  ) {
    this.store = injector.get<Store>(Store);
  }

  private tenantName = 'bookkeeping';
  private selectedSubject = new Subject<any>();
  public defaultGuidValue = Guid.EMPTY as unknown as Guid;
  public defaultHeaderGuidValue = Guid.EMPTY as unknown as Guid;
  public setParamId = null;
  public isInitialValueChange = false;
  public toggleMenu = true;

  checkPermission(
    mainMenu: number,
    childMenu: number,
    isCheckViewOnly?: boolean
  ): boolean {
    let isChangePermission = true;
    this.menuList$.pipe(take(1)).subscribe((data) => {
      for (const menu of data) {
        if (menu.id === mainMenu && menu.subMenu) {
          const isSubMenuPresent = menu.subMenu.some(
            (submenu) => submenu.id === childMenu
          );

          if (!isSubMenuPresent && mainMenu !== Modules.GeneralManage) {
            isChangePermission = false;
            return;
          }

          for (const submenu of menu.subMenu) {
            if (isCheckViewOnly) {
              if (
                submenu.id === childMenu &&
                submenu.permissionId === PermissionType.None
              ) {
                isChangePermission = false;
                return;
              }
            } else {
              if (
                submenu.id === childMenu &&
                submenu.permissionId !== PermissionType.Full
              ) {
                isChangePermission = false;
                return;
              }
            }
          }
        }
      }
    });

    return isChangePermission;
  }

  setTenantName(name: string): void {
    this.tenantName = name;
  }

  getTenantName(): any {
    return this.tenantName;
  }

  isEmpty(id: any): boolean {
    return id === this.defaultGuidValue;
  }

  getPermissions(
    searchText?: string
  ): Observable<Array<GlobalStandardPermission>> {
    return this.http.get<Array<GlobalStandardPermission>>(
      `${environment.apiVersionUrl}ModuleRolePermission/getBusinessPermission?search=${searchText}`
    );
  }

  saveBusinessPermission(
    standardPermission: Array<GlobalStandardPermission>,
    userId: Guid
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}ModuleRolePermission/saveBusinessPermission?userId=${userId}`,
      JSON.stringify(standardPermission),
      headers
    );
  }

  getTitleList(): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}Salutation/all`
    );
  }

  getFileAsByte(fileUrl: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}FileUpload/getFileAsByte`,
      JSON.stringify(fileUrl),
      headers
    );
  }

  getCountryList(): Observable<Array<Country>> {
    return this.http.get<Array<Country>>(
      `${environment.apiVersionUrl}Country/all`
    );
  }

  postExportRequest(
    endpoint: string,
    params?: any | null,
    options?: TshqRequestOptions
  ): Observable<HttpResponse<Blob>> {
    return this.http.post(endpoint, params, {
      ...options,
      responseType: 'blob',
      observe: 'response',
    });
  }

  addGroupNameToListModels(data: any[]): any[] {
    let result: any = [];

    data.forEach((group) => {
      group.listModels.forEach((listModel) => {
        let listModelWithGroup = {
          ...listModel,
          groupName: group.groupName,
        } as any;
        result.push(listModelWithGroup);
      });
    });

    return result;
  }

  print(body: Blob): void {
    const file = new Blob([body], {
      type: 'application/pdf',
    });
    const blobUrl = URL.createObjectURL(file);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow?.print();
  }

  download(response: HttpResponse<Blob>): void {
    let fileName = response.headers
      .get('Content-Disposition')
      ?.split(';')
      .map((x) => (x ?? '').trimLeft().split('='))
      .find((x) => x[0] === 'filename')
      ?.pop();

    const a = document.createElement('a');
    let navigator: any;
    navigator = window.navigator;

    if (fileName !== undefined && fileName !== null) {
      fileName = fileName.replace(/"/g, '');
    }

    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(response, fileName);
    } else {
      a.href = URL.createObjectURL(response.body || new Blob());
      a.download = fileName ?? '';
      a.click();
    }
  }

  fileUpload(fileUploadRequestModel: FileUploadRequestModel): Observable<any> {
    const formData = new FormData();

    fileUploadRequestModel.file.forEach((x) =>
      formData.append('files', x, x.name)
    );

    formData.append(
      'attachmentType',
      fileUploadRequestModel.attachmentType!.toString()
    );

    return this.http.post<any>(
      `${environment.apiVersionUrl}FileUpload/multipleUpload`,
      formData
    );
  }

  reportExport(queryParams: MainListParameters, id: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.postExportRequest(
      `${environment.apiVersionUrl}Account/accountTransactions/export/${id}`,
      JSON.stringify(queryParams),
      headers
    ).pipe(
      switchMap((response: any) => {
        const body: Blob = response.body || new Blob();
        if (queryParams.isPrint) {
          this.print(body);
        } else {
          this.download(response);
        }
        return of(true);
      })
    );
  }

  exportSubcontractorVerification(id: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.postExportRequest(
      `${environment.apiVersionUrl}Account/subcontractorVerification/export/${id}`,
      headers
    ).pipe(
      switchMap((response: any) => {
        this.download(response);
        return of(true);
      })
    );
  }

  getSideList(
    queryParams: QueryParams,
    moduleId: Modules
  ): Observable<HttpResponse<Array<SideListModel>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Common/allList?moduleId=${moduleId}`,
      queryParams,
      httpOptions
    );
  }

  getNominalLedgerReport(queryParams: MainListParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}report/nominalLedgerReport`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getMainList(
    queryParams: MainListParameters,
    moduleId: Modules
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}Common/list?moduleId=${moduleId}`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getVatViewData(
    queryParams: MainListParameters,
    detailListId: any
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let url = '';

    switch (detailListId) {
      case MTD.Obligations:
        url = `MTD/vatObligations`;
        break;
      case MTD.RetrieveVATLiabilities:
        url = `MTD/vatLiabilities`;
        break;
      case MTD.RetrieveVATPayments:
        url = `MTD/vatPayments`;
        break;
      default:
        break;
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}${url}`,
      JSON.stringify(queryParams),
      headers
    );
  }

  prepareUrl(): Observable<any> {
    const payload = {
      returnUrl: window.location.origin.toString() + this.router.url,
    };

    const url = `${environment.apiVersionUrl}MTD/prepareUrl`;
    return this.http
      .post(url, payload, { responseType: 'text' })
      .pipe(map((body) => body));
  }

  getDetailList(
    queryParams: MainListParameters,
    dataParam: any
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let url = '';

    if (dataParam.detailListModulesId === DetailListModules.Inventory) {
      url = `Inventory/transaction/${dataParam.detailListId}/${dataParam.productId}`;
    }

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}${url}`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getAccountTransactionList(
    queryParams: MainListParameters,
    id: Guid
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}Account/accountTransactions/${id}`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getCustomizeReportList(queryParams: any): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}CustomReport/report`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getReportList(
    queryParams: MainListParameters,
    moduleId: Modules
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}Common/report?moduleId=${moduleId}`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getComparitiveReport(
    queryParams: ComparitiveReportParamerters
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}Report/comparitiveReport`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getComparitiveReportExport(
    queryParams: ComparitiveReportParamerters
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.postExportRequest(
      `${environment.apiVersionUrl}Report/comparitiveReport/export`,
      JSON.stringify(queryParams),
      headers
    ).pipe(
      switchMap((response: any) => {
        const body: Blob = response.body || new Blob();
        if (queryParams.isPrint) {
          this.print(body);
        } else {
          this.download(response);
        }
        return of(true);
      })
    );
  }

  getVatReportList(
    queryParams: MainListParameters
  ): Observable<VATReportModel> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<VATReportModel>(
      `${environment.apiVersionUrl}Report/vatReportDetail`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getNominalLedgerTransaction(queryParams: any): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}report/nominalLedgerTransaction`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getVatReportDetailList(
    vatId: Modules,
    queryParams: any
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}Report/vatReportTransaction?vatRateId=${vatId}`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getHeaderList(moduleId: Modules): Observable<Array<HeaderModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<HeaderModel>>(
      `${environment.apiVersionUrl}Common/listHeader?moduleId=${moduleId}`,
      headers
    );
  }

  getDetailHeaderList(
    mtdId: MTD,
    detailListModulesId: number
  ): Observable<Array<HeaderModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let url = '';
    if (detailListModulesId === DetailListModules.Inventory) {
      url = `Inventory/transaction/listHeader`;
    }

    return this.http.post<Array<HeaderModel>>(
      `${environment.apiVersionUrl}${url}`,
      headers
    );
  }

  reportDetailView(id: Guid) {
    return this.http.get<AccountDetailViewModel>(
      `${environment.apiVersionUrl}Account/detailView/${id}`
    );
  }

  export(queryParams: MainListParameters, moduleId: Modules): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.postExportRequest(
      `${environment.apiVersionUrl}Common/export?moduleId=${moduleId}`,
      JSON.stringify(queryParams),
      headers
    ).pipe(
      switchMap((response: any) => {
        const body: Blob = response.body || new Blob();
        if (queryParams.isPrint) {
          this.print(body);
        } else {
          this.download(response);
        }
        return of(true);
      })
    );
  }

  exportReceipt(
    ids: Array<Guid>,
    moduleId: Modules,
    isPrint: boolean
  ): Observable<any> {
    let url;

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    switch (moduleId) {
      case Modules.Invoices:
        url = 'Invoice/export';
        break;

      case Modules.Purchase:
        url = 'Purchase/export';
        break;

      case Modules.CreditNote:
        url = 'CreditNote/export';
        break;

      case Modules.DebitNote:
        url = 'DebitNote/export';
        break;

      case Modules.Quotation:
        url = 'Quotation/export';
        break;

      case Modules.Journals:
        url = 'Journal/export';
        break;

      case Modules.FixedAssets:
        url = 'FixedAsset/export';
        break;

      case Modules.Receipt:
      case Modules.Payment:
        url = 'Receipt/export';
        break;

      case Modules.Dividends:
        url = 'dividend/export';
        break;

      case Modules.BridgingVAT:
        url = 'MTD/bridgingVat/export';
        break;
      case Modules.CISInvoice:
        url = 'CisInvoice/export';
        break;

      case Modules.Budgeting:
        url = 'Budget/export';
        break;

      case Modules.FixedAssetDetail:
      case Modules.FixedAssetsRegister:
        url = 'FixedAssetRegister/detailView/export';
        break;
      case Modules.QuickEntry:
        url = 'QuickEntry/export';
        break;
    }

    return this.postExportRequest(
      `${environment.apiVersionUrl}${url}`,
      JSON.stringify(ids),
      headers
    ).pipe(
      switchMap((response: any) => {
        const body: Blob = response.body || new Blob();
        if (isPrint) {
          this.print(body);
        } else {
          this.download(response);
        }
        return of(true);
      })
    );
  }

  getCurrencyList(): Observable<Array<Currency>> {
    return this.http.get<Array<Currency>>(
      `${environment.apiVersionUrl}Currency/all`
    );
  }

  getProductData(productId: Guid): Observable<ProductDetailsModel> {
    return this.http.get<ProductDetailsModel>(
      `${environment.apiVersionUrl}Product/id/${productId}`
    );
  }

  getProductList(): Observable<Array<ProductModel>> {
    return this.http.get<Array<ProductModel>>(
      `${environment.apiVersionUrl}Product/all`
    );
  }

  addValidation(form: any, renderer: any): void {
    (Object as any).values(form.controls).forEach((c) => c.markAsTouched());
    this.autoScrollToInvalidControl(renderer);
  }

  whiteSpaceValidate: any = (control: FormControl) => {
    return (control.value || '').trim().length ? null : { whitespace: true };
  };

  autoScrollToInvalidControl(renderer: any): void {
    const fragment = 'ng-invalid';
    const element = renderer.selectRootElement(`.${fragment}`, true);
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  onSuccess(message: any): void {
    this.notifier.success(NotificationHeader.success, message);
  }

  checkCustomerSupplierDuplicate(
    name: string,
    email: string,
    moduleId: number
  ): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}${this.getImportModuleServiceName(
        moduleId
      )}/checkDuplicate?name=${name}&email=${email}`
    );
  }

  checkEntryNumber(entryNumber: string, moduleId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}${this.getImportModuleServiceName(
        moduleId
      )}/checkEntryNumber/${entryNumber}`
    );
  }

  isTreeList(moduleId: number): boolean {
    let isTreeList;

    switch (moduleId) {
      case Modules.AdvanceAgeingList:
      case Modules.AccountPayableList:
      case Modules.QuotationByCustomer:
      case Modules.InvoiceAndReceivedPayments:
      case Modules.PurchaseAndAppliedPayments:
      case Modules.SalesByProductDetail:
      case Modules.BalanceSheetDetail:
      case Modules.ChequeDetails:
      case Modules.DepositDetails:
        isTreeList = true;
        break;
      default:
        isTreeList = false;
        break;
    }

    return isTreeList;
  }

  isReportModule(moduleId: number): boolean {
    let isReport;

    switch (moduleId) {
      case Modules.TrialBalance:
      case Modules.SupplierAdvanceReport:
      case Modules.PurchasesReport:
      case Modules.SupplierList:
      case Modules.SupplierAgeingList:
      case Modules.TransactionsBySupplier:
      case Modules.PaymentToSupplier:
      case Modules.Creditors:
      case Modules.SupplierStatement:
      case Modules.PurchasesInvoiceList:
      case Modules.CustomerAdvanceReport:
      case Modules.SalesReport:
      case Modules.CustomerList:
      case Modules.CustomerAgeingList:
      case Modules.TransactionsByCustomer:
      case Modules.SalesProductList:
      case Modules.CustomerReceipts:
      case Modules.CustomerStatement:
      case Modules.SalesInvoiceList:
      case Modules.Customers:
      case Modules.Suppliers:
      case Modules.Import:
      case Modules.AccountDetails:
      case Modules.BalanceSheet:
      case Modules.ComparativeReport:
      case Modules.Inventory:
      case Modules.BudgetReport:
      case Modules.SalesDayBook:
      case Modules.PurchaseDayBook:
      case Modules.BankImportHistory:
      case Modules.IncomeAndExpenditure:
      case Modules.BankImportTransactionHistory:
      case Modules.VAT:
      case Modules.ProfitAndLoss:
      case Modules.OpenInvoice:
      case Modules.UnpaidPurchases:
      case Modules.IncomeByCustomerSummary:
      case Modules.CustomerBalanceSummary:
      case Modules.SupplierBalanceSummary:
      case Modules.ExpensesBySupplierSummary:
      case Modules.UnpaidPurchases:
      case Modules.SalesByProductSummary:
      case Modules.BalanceSheetComparison:
      case Modules.AccountList:
      case Modules.TransactionListByDate:
      case Modules.RecentTransactions:
      case Modules.Invoices:
      case Modules.RecurringInvoice:
      case Modules.Quotation:
      case Modules.CreditNote:
      case Modules.Receipt:
      case Modules.Purchase:
      case Modules.RecurringPurchase:
      case Modules.DebitNote:
      case Modules.Payment:
      case Modules.ProfitAndLossComparision:
      case Modules.ProfitAndLossSummary:
      case Modules.ProfitAndLossWithPercentage:
      case Modules.ProfitAndLossDetail:
      case Modules.DayBook:
      case Modules.CISInvoice:
      case Modules.QuickEntry:
      case Modules.CIS300:
      case Modules.FixedAssets:
      case Modules.Journals:
      case Modules.BankTransfer:
      case Modules.Notes:
        isReport = true;
        break;
      default:
        isReport = false;
        break;
    }

    return isReport;
  }

  isAllowedOpenView(moduleId: number): boolean {
    let isAllowed;

    switch (moduleId) {
      case Modules.Invoices:
      case Modules.Purchase:
      case Modules.CreditNote:
      case Modules.DebitNote:
      case Modules.FixedAssets:
      case Modules.Quotation:
      case Modules.Journals:
      case Modules.Receipt:
      case Modules.Payment:
      case Modules.CIS:
      case Modules.FixedAssetDetail:
      case Modules.QuickEntry:
        isAllowed = true;
        break;
      default:
        isAllowed = false;
        break;
    }

    return isAllowed;
  }

  openView(id: any, moduleId: any): void {
    const data: ViewParamModel = {
      moduleId: moduleId,
      id: id,
    };

    this.dialog
      .open(ViewReceiptComponent, {
        data,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {});
  }

  getImportModuleServiceName(moduleId: number): string {
    let serviceName;

    switch (moduleId) {
      case Modules.Invoices:
        serviceName = 'invoice';
        break;

      case Modules.Purchase:
        serviceName = 'purchase';
        break;

      case Modules.CreditNote:
        serviceName = 'creditNote';
        break;

      case Modules.DebitNote:
        serviceName = 'debitNote';
        break;

      case Modules.Customers:
        serviceName = 'account/' + AccountTypeImport.Customer;
        break;

      case Modules.Suppliers:
        serviceName = 'account/' + AccountTypeImport.Supplier;
        break;

      case Modules.TrialBalance:
        serviceName = 'openingBalance';
        break;

      case Modules.Director:
        serviceName = 'DirectorShareholder/director';
        break;

      case Modules.Shareholder:
        serviceName = 'DirectorShareholder/shareholder';
        break;

      case Modules.FixedAssets:
        serviceName = 'fixedAsset';
        break;
    }

    return serviceName;
  }

  convertStringToGuid(guid: string): Guid {
    return guid as unknown as Guid; // maybe add validation that the parameter is an actual guid ?
  }

  setHighlightData(
    id: Guid,
    isExit: boolean,
    moduleId: number,
    routingPath: RoutingPath
  ): void {
    if (!isExit) {
      if (id === this.defaultGuidValue) {
        this.highlightRow.sideListHighlighted.isHighlighted = true;
        this.highlightRow.sideListHighlighted.sortBy = SortOrdering.createdOn;
      } else {
        this.highlightRow.sideListHighlighted.isHighlighted = false;
        this.highlightRow.sideListHighlighted.sortBy = '';

        this.router.navigate([routingPath], {
          queryParamsHandling: 'preserve',
        });
      }
    } else {
      this.highlightRow.mainListHighlighted.isHighlighted = true;
      this.highlightRow.mainListHighlighted.sortBy =
        id === this.defaultGuidValue
          ? SortOrdering.createdOn
          : SortOrdering.updatedOn;
      this.highlightRow.mainListHighlighted.moduleId = moduleId;
    }
  }

  onFailure(errorMessage: any): void {
    this.notifier.error(NotificationHeader.error, errorMessage);
  }

  downloadFile(fileData: MultipleFileDownloadModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.postExportRequest(
      `${environment.apiVersionUrl}FileUpload/multipledownload`,
      fileData,
      headers
    ).pipe(
      switchMap((response) => {
        this.download(response);
        return of();
      })
    );
  }

  getEntityTypeId(moduleId?: number): any {
    if (moduleId === Modules.Customers) {
      return AccountEntity.Customer;
    }

    if (moduleId === Modules.Suppliers || moduleId === Modules.Subcontractor) {
      return AccountEntity.Supplier;
    }
  }

  //#region transactions
  getVatRateList(): Observable<Array<VatRateScheme>> {
    return this.http.get<Array<VatRateScheme>>(
      `${environment.apiVersionUrl}Vat/all/rate`
    );
  }

  getAccountList(entityId: Array<number>): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/all`,
      JSON.stringify(entityId),
      headers
    );
  }

  getAccountListByNature(
    entityId: Array<string>
  ): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/allByNature`,
      JSON.stringify(entityId),
      headers
    );
  }

  getChartOfAccountsBasedOnGroupId(
    groupIds: Array<number>
  ): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}ChartOfAccount/getAccounts`,
      JSON.stringify(groupIds),
      headers
    );
  }

  getChartOfAccountsBasedOnGroupIdAndTypeId(
    param: ChartOfAccountListParam
  ): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}ChartOfAccount/getAccountsBasedOnGroupIdAndTypeId`,
      JSON.stringify(param),
      headers
    );
  }

  isDataInLockedPeriod(
    ids: Array<Guid>,
    moduleId: Modules
  ): Observable<boolean> {
    let isValidPeriod = new Subject<boolean>();

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    this.http
      .post<any>(
        `${environment.apiVersionUrl}common/validateAccountingPeriod?moduleId=${moduleId}`,
        this.trimObjectSpace(JSON.stringify(ids)),
        headers
      )
      .subscribe((res) => {
        isValidPeriod.next(!res);
      });

    return isValidPeriod.asObservable();
  }

  getAccountsBasedOnGroupId(
    groupIds: Array<number>
  ): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/getAccounts`,
      JSON.stringify(groupIds),
      headers
    );
  }

  getAccountsBasedOnGroupIdAndTypeId(
    param: ChartOfAccountListParam
  ): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/getAccountsBasedOnGroupIdAndTypeId`,
      JSON.stringify(param),
      headers
    );
  }

  checkDateValidation(formControl: any): any {
    let validations = {
      invalidFinancialYear: formControl.hasError('invalidFinancialYear'),
      lockedFinancialYear: formControl.hasError('lockedFinancialYear'),
    };

    return validations;
  }
  autoScrollMatAutoComplete(renderer): void {
    const fragment = 'mat-selected';
    const element = renderer.selectRootElement(`.${fragment}`, true);
    element.scrollIntoView({ behavior: 'auto', block: 'nearest' });
  }

  getProductUnit(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}ProductUnit/all`
    );
  }
  //#endregion

  // onEditRouting(moduleId: number, id: any): void {
  //   const params = { id: btoa(id) };
  onEditRouting(
    isEdit: boolean,
    moduleId: number,
    id: any,
    details?: any,
    parentModuleId?: any,
    isManualBank?: any,
    customId?: any,
    isSubmit?:boolean
  ): void {

   
    let params: any;
    if (isEdit) {
      if(isSubmit){
        params = {
          id: btoa(id),
          details: btoa(details),
          moduleId: btoa(parentModuleId),
          isSubmit: isSubmit
          
        };
      }else{
        params = {
          id: btoa(id),
          details: btoa(details),
          moduleId: btoa(parentModuleId),
        };
      }
      
    } else {
      params = {
        id: btoa(id),
        isFromBankImport: btoa(details),
        isManualBank: btoa(isManualBank),
        customId: btoa(customId),
      };
    }

    switch (moduleId) {
      case Modules.Customers:
        this.router.navigate([RoutingPath.AddCustomers, params]);
        break;
      case Modules.Suppliers:
        this.router.navigate([RoutingPath.AddSuppliers, params]);
        break;

      case Modules.Journals:
        this.router.navigate([RoutingPath.AddJournals, params]);
        break;
      case Modules.Quotation:
        this.router.navigate([RoutingPath.AddQuotation, params]);
        break;
      case Modules.Invoices:
        this.router.navigate([RoutingPath.AddInvoice, params]);
        break;
      case Modules.DebitNote:
        this.router.navigate([RoutingPath.AddDebitNote, params]);
        break;
      case Modules.AddCustomAccounts:
        this.router.navigate([RoutingPath.AddCustomAccounts, params]);
        break;
      case Modules.Purchase:
        this.router.navigate([RoutingPath.AddPurchase, params]);
        break;
      case Modules.CreditNote:
        this.router.navigate([RoutingPath.AddCreditNote, params]);
        break;
      case Modules.RecurringInvoice:
        this.router.navigate([RoutingPath.AddRecurringInvoice, params]);
        break;
      case Modules.RecurringPurchase:
        this.router.navigate([RoutingPath.AddRecurringPurchase, params]);
        break;
      case Modules.Receipt:
        this.router.navigate([RoutingPath.AddReceipt, params]);
        break;
      case Modules.Payment:
        this.router.navigate([RoutingPath.AddPayment, params]);
        break;
      case Modules.CashEntry:
        this.router.navigate([RoutingPath.AddCashEntry, params]);
        break;
      case Modules.BankEntry:
        this.router.navigate([RoutingPath.AddBankEntry, params]);
        break;
      case Modules.Dividends:
        this.router.navigate([RoutingPath.AddDividends, params]);
        break;
      case Modules.FixedAssets:
        this.router.navigate([RoutingPath.AddFixedAssets, params]);
        break;
      case Modules.AccountDetails:
        this.router.navigate([RoutingPath.AccountDetails, params]);
        break;
      case Modules.Product:
        this.router.navigate([RoutingPath.AddProduct, params]);
        break;
      case Modules.Notes:
        this.router.navigate([RoutingPath.AddSchedules, params]);
        break;
      case Modules.MinutesOfMeeting:
        this.router.navigate([RoutingPath.AddMinutesOfMeeting, params]);
        break;
      case Modules.Director:
        this.router.navigate([RoutingPath.AddDirector, params]);
        break;
      case Modules.Shareholder:
        this.router.navigate([RoutingPath.AddShareholder, params]);
        break;
      case Modules.VatSettings:
        this.router.navigate([RoutingPath.AddVatCodes, params]);
        break;
      case Modules.SubmitVat:
        this.router.navigate([RoutingPath.AddSubmitVAT, params]);
        break;
      case Modules.BridgingVAT:
        this.router.navigate([RoutingPath.AddBridgingVAT, params]);
        break;
      case Modules.Budgeting:
        this.router.navigate([RoutingPath.AddBudgeting, params]);
        break;
      case Modules.Subcontractor:
        this.router.navigate([RoutingPath.AddSubcontractor, params]);
        break;
      case Modules.CIS300:
        this.router.navigate([RoutingPath.AddCIS300, params]);
        break;
      case Modules.CISInvoice:
        this.router.navigate([RoutingPath.AddCISInvoice, params]);
        break;
      case Modules.ReportAccountDetail:
        this.router.navigate([RoutingPath.ReportAccountDetails, params]);
        break;
      case Modules.BankImportTransactionHistory:
        this.router.navigate([RoutingPath.BankTransaction, params]);
        break;
      case Modules.BankTransfer:
        this.router.navigate([RoutingPath.AddBankTransfer, params]);
        break;
      case Modules.CustomizeReport:
        this.router.navigate([RoutingPath.CustomizeReport, params]);
        break;
      case Modules.QuickEntry:
        this.router.navigate([RoutingPath.AddQuickEntry, params]);
        break;
      case Modules.Users:
        this.router.navigate([RoutingPath.AddUser, params]);
        break;
      case Modules.Clients:
        this.router.navigate([RoutingPath.AddClient, params]);
        break;
      default:
        this.router.navigate([RoutingPath.Dashboard]);
        break;
    }
  }

  onAddRoutingFromSideList(moduleId: number): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.onAddRouting(moduleId);
  }

  onAddRouting(moduleId: number): void {
    switch (moduleId) {
      case Modules.Customers:
        this.router.navigate([RoutingPath.AddCustomers]);
        break;
      case Modules.Suppliers:
        this.router.navigate([RoutingPath.AddSuppliers]);
        break;
      case Modules.BankTransfer:
        this.router.navigate([RoutingPath.AddBankTransfer]);
        break;
      case Modules.Journals:
        this.router.navigate([RoutingPath.AddJournals]);
        break;
      case Modules.Quotation:
        this.router.navigate([RoutingPath.AddQuotation]);
        break;
      case Modules.Invoices:
        this.router.navigate([RoutingPath.AddInvoice]);
        break;
      case Modules.DebitNote:
        this.router.navigate([RoutingPath.AddDebitNote]);
        break;
      case Modules.AddCustomAccounts:
        this.router.navigate([RoutingPath.AddCustomAccounts]);
        break;
      case Modules.Purchase:
        this.router.navigate([RoutingPath.AddPurchase]);
        break;
      case Modules.CreditNote:
        this.router.navigate([RoutingPath.AddCreditNote]);
        break;
      case Modules.RecurringInvoice:
        this.router.navigate([RoutingPath.AddRecurringInvoice]);
        break;
      case Modules.RecurringPurchase:
        this.router.navigate([RoutingPath.AddRecurringPurchase]);
        break;
      case Modules.Receipt:
        this.router.navigate([RoutingPath.AddReceipt]);
        break;
      case Modules.Payment:
        this.router.navigate([RoutingPath.AddPayment]);
        break;
      case Modules.CashEntry:
        this.router.navigate([RoutingPath.AddCashEntry]);
        break;
      case Modules.BankEntry:
        this.router.navigate([RoutingPath.AddBankEntry]);
        break;
      case Modules.Dividends:
        this.router.navigate([RoutingPath.AddDividends]);
        break;
      case Modules.FixedAssets:
        this.router.navigate([RoutingPath.AddFixedAssets]);
        break;
      case Modules.FixedAssetsRegister:
        this.dialog.open(DepreciationExpandedCollapseComponent, {});
        break;
      case Modules.Product:
        this.router.navigate([RoutingPath.AddProduct]);
        break;
      case Modules.BankDashboard:
        this.router.navigate([RoutingPath.BankDashboard]);
        break;
      case Modules.Notes:
        this.router.navigate([RoutingPath.AddSchedules]);
        break;
      case Modules.MinutesOfMeeting:
        this.router.navigate([RoutingPath.AddMinutesOfMeeting]);
        break;
      case Modules.VatSettings:
        this.router.navigate([RoutingPath.AddVatCodes]);
        break;
      case Modules.SubmitVat:
        this.router.navigate([RoutingPath.AddSubmitVAT]);
        break;
      case Modules.BridgingVAT:
        this.router.navigate([RoutingPath.AddBridgingVAT]);
        break;
      case Modules.Shareholder:
        this.router.navigate([RoutingPath.AddShareholder]);
        break;
      case Modules.Director:
        this.router.navigate([RoutingPath.AddDirector]);
        break;
      case Modules.Budgeting:
        this.router.navigate([RoutingPath.AddBudgeting]);
        break;
      case Modules.Subcontractor:
        this.router.navigate([RoutingPath.AddSubcontractor]);
        break;
      case Modules.CIS300:
        this.router.navigate([RoutingPath.AddCIS300]);
        break;
      case Modules.CISInvoice:
        this.router.navigate([RoutingPath.AddCISInvoice]);
        break;
      case Modules.CustomizeReport:
        this.router.navigate([RoutingPath.CustomizeReport]);
        break;
      case Modules.QuickEntry:
        this.router.navigate([RoutingPath.AddQuickEntry]);
        break;
      case Modules.Users:
        this.router.navigate([RoutingPath.AddUser]);
        break;
      case Modules.Clients:
        this.router.navigate([RoutingPath.AddClient]);
        break;
    }
  }

  editReceipt(ids: Array<Guid>, moduleId: any): Observable<any> {
    let url;

    switch (moduleId) {
      case Modules.Invoices:
        url = `Invoice/canEditInvoice/${ids}`;
        break;

      case Modules.CreditNote:
        url = `creditNote/canEditCreditNote/${ids}`;
        break;

      case Modules.DebitNote:
        url = `debitNote/canEditDebitNote/${ids}`;
        break;

      case Modules.Purchase:
        url = `purchase/canEditPurchase/${ids}`;
        break;

      case Modules.FixedAssets:
        url = `fixedAsset/canEditFixedAsset/${ids}`;
        break;

      case Modules.Journals:
        url = `journal/canEdit/${ids}`;
        break;
    }

    return this.http.get<Array<Guid>>(`${environment.apiVersionUrl}${url}`);
  }

  getDefaultCurrency(): Observable<Array<DefaultCurrency>> {
    return this.http.get<Array<DefaultCurrency>>(
      `${environment.apiVersionUrl}company/currency`
    );
  }

  getFinancialPeriod(): Observable<Array<FinancialData>> {
    return this.http.get<Array<FinancialData>>(
      `${environment.apiVersionUrl}accountingPeriod/all`
    );
  }

  getModuleDetailView(param: ViewParamModel): Observable<ViewDetails> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<ViewDetails>(
      `${environment.apiVersionUrl}Common/view`,
      JSON.stringify(param),
      headers
    );
  }

  getStandardAccountList(
    entityId: Array<number>
  ): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/allStandard`,
      JSON.stringify(entityId),
      headers
    );
  }

  advanceAllotmentAccounts(entityId: Array<number>): Observable<Array<any>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<any>>(
      `${environment.apiVersionUrl}Account/advance-allotment/accounts`,
      JSON.stringify(entityId),
      headers
    );
  }

  advanceAllotmentAccountsByBranch(
    entityId: Array<number>,
    branchId: any
  ): Observable<Array<any>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<any>>(
      `${environment.apiVersionUrl}Account/advance-allotment/accounts/${branchId}`,
      JSON.stringify(entityId),
      headers
    );
  }

  explainAllotmentAccounts(entityId: Array<number>): Observable<Array<any>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<any>>(
      `${environment.apiVersionUrl}Account/explain-allotment/accounts`,
      JSON.stringify(entityId),
      headers
    );
  }

  explainAllotmentAccountsByBranch(
    entityId: Array<number>,
    branchId: any
  ): Observable<Array<any>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<any>>(
      `${environment.apiVersionUrl}Account/explain-allotment/accounts/${branchId}`,
      JSON.stringify(entityId),
      headers
    );
  }

  getNonStandardAccountList(
    entityId: Array<number>
  ): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/allNonStandard`,
      JSON.stringify(entityId),
      headers
    );
  }

  getNonStandardAccountListByBranch(
    branchId: Guid,
    entityId: Array<number>
  ): Observable<Array<SideListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/allNonStandardByBranch/${branchId}`,
      JSON.stringify(entityId),
      headers
    );
  }

  getCustomBankAccountList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/getCustomBankAccount`
    );
  }

  getGroupList(): Observable<Array<GroupListModel>> {
    return this.http.get<Array<GroupListModel>>(
      `${environment.apiVersionUrl}Account/getGroupAllList`
    );
  }

  getGroupAccountsBasedOnGroupId(
    groupIds: Array<number>,
    moduleId: Number
  ): Observable<Array<GroupListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<GroupListModel>>(
      `${environment.apiVersionUrl}Account/getGroupAccounts?moduleId=${moduleId}`,
      JSON.stringify(groupIds),
      headers
    );
  }

  getGroupAccountsBasedOnGroupIdAndTypeId(
    param: ChartOfAccountListParam
  ): Observable<Array<GroupListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<GroupListModel>>(
      `${environment.apiVersionUrl}Account/getGroupAccountsBasedOnGroupIdAndTypeId`,
      JSON.stringify(param),
      headers
    );
  }

  getGroupAccountList(
    entityId: Array<number>
  ): Observable<Array<GroupListModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<GroupListModel>>(
      `${environment.apiVersionUrl}Account/allGroup`,
      JSON.stringify(entityId),
      headers
    );
  }

  getCustomBankAndCashAccount(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/getCustomBankAndCashAccount`
    );
  }

  selectedDateValidator(periodicDate: any, selectedDate: any): any {
    let isInvalidDate = false;
    let invalidFinancialYear = false;
    let isLockedFinancialYear = false;

    if (selectedDate) {
      const dateValue = Date.parse(selectedDate);

      let periodicDateList = periodicDate.find((element) => {
        const start = Date.parse(element.fromDate);
        const end = Date.parse(element.toDate);

        return (
          moment(dateValue.valueOf()).format('YYYY-MM-DD') >=
            moment(start.valueOf()).format('YYYY-MM-DD') &&
          moment(dateValue.valueOf()).format('YYYY-MM-DD') <=
            moment(end.valueOf()).format('YYYY-MM-DD')
        );
      });

      if (periodicDateList === undefined) {
        isInvalidDate = true;
        invalidFinancialYear = true;
      } else if (periodicDateList.isLocked) {
        isInvalidDate = true;
        isLockedFinancialYear = true;
      }
    }
    return isInvalidDate
      ? {
          invalidFinancialYear: invalidFinancialYear,
          lockedFinancialYear: isLockedFinancialYear,
        }
      : null;
  }

  validateDate(event): void {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46, 109, 111, 191, 189];

    if (
      !(
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        excludedKeys.includes(keyCode)
      )
    ) {
      event.preventDefault();
    }
  }

  getCreditorDebtorReport(
    queryParams: MainListParameters,
    moduleId: number
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}${this.getReportModuleURL(moduleId)}`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getReportModuleURL(moduleId: number): string {
    let serviceName;

    switch (moduleId) {
      case Modules.Creditors:
        serviceName = 'report/creditorsReport';
        break;

      case Modules.Debtors:
        serviceName = 'report/debtorsReport';
        break;
    }

    return serviceName;
  }

  getCreditorDebtorTransaction(
    queryParams: any,
    moduleId: number,
    id: any
  ): Observable<MainList> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<MainList>(
      `${environment.apiVersionUrl}${this.getReportTransactionModuleURL(
        moduleId
      )}${id}`,
      JSON.stringify(queryParams),
      headers
    );
  }

  getReportTransactionModuleURL(moduleId: number): string {
    let serviceName;

    switch (moduleId) {
      case Modules.Creditors:
        serviceName = 'report/creditorTransaction/';
        break;

      case Modules.Debtors:
        serviceName = 'report/debtorTransaction/';
        break;
    }

    return serviceName;
  }

  onAccountSearch(event: any, tempAccountGroupList: any): any {
    let accountGroupList = tempAccountGroupList;
    const projectSearhText = event.currentTarget.value;
    const trimProjectSearhText = projectSearhText.trimStart();
    const selectedArray: GroupListModel[] = [];
    let listModel: any;
    accountGroupList.filter((item) => {
      let groupName = item.groupName;
      if (
        item.groupName
          .toLowerCase()
          .includes(trimProjectSearhText.toLowerCase())
      ) {
        selectedArray.push(item);
      }
      listModel = [];
      item.listModels.filter((items) => {
        if (
          items.name.toLowerCase().includes(trimProjectSearhText.toLowerCase())
        ) {
          let groupNameExist = selectedArray.filter(
            (x) => x.groupName === groupName
          );
          let tempGroupNameExist = tempAccountGroupList.filter(
            (x) => x.groupName === groupName
          );
          if (groupNameExist.length > 0) {
            if (
              tempGroupNameExist[0].listModels.length !==
              groupNameExist[0].listModels.length
            ) {
              groupNameExist[0].listModels.push(items);
            }
          } else {
            listModel.push(items);
            const data = {
              groupName: groupName,
              listModels: listModel,
            };
            selectedArray.push(data);
          }
        }
      });
    });
    if (trimProjectSearhText) {
      accountGroupList = selectedArray;
    }
    return accountGroupList;
  }

  customEmail(
    moduleId: Modules,
    emailData: any
  ): Observable<Array<CustomEmailModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<CustomEmailModel>>(
      `${environment.apiVersionUrl}Common/customEmail/${moduleId}`,
      JSON.stringify(emailData),
      headers
    );
  }

  trimObjectSpace(obj) {
    let data = JSON.parse(obj);

    Object.keys(data).forEach((key) => {
      if (typeof data[key] === 'string') {
        data[key] = data[key].trim();
      }
    });

    return JSON.stringify(data);
  }

  getFixedAssetRegisterList(queryParams?: MainListParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}FixedAssetRegister/list`,
      headers
    );
  }

  updateThemeColor(themecolor: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put<any>(
      `${environment.apiVersionUrl}User/updateThemeColor`,
      JSON.stringify(themecolor),
      headers
    );
  }

  sendEmail(
    id: Guid,
    moduleId: Modules,
    paymentLink: boolean = false
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let url;

    switch (moduleId) {
      case Modules.Invoices:
        url = 'Invoice';
        break;

      case Modules.CreditNote:
        url = 'CreditNote';
        break;

      case Modules.DebitNote:
        url = 'DebitNote';
        break;

      case Modules.Quotation:
        url = 'Quotation';
        break;

      case Modules.Receipt:
        url = 'Receipt';
        break;
    }

    let apiUrl = `${environment.apiVersionUrl}${url}/sendEmail/${id}`;

    if (moduleId === Modules.Invoices) {
      apiUrl += `/${paymentLink}`;
    }

    return this.http.post<any>(apiUrl, headers);
  }

  getModuleName(moduleId): string {
    let moduleName;

    switch (moduleId) {
      case Modules.Customers:
        moduleName = ModuleName.Customers;
        break;

      case Modules.Suppliers:
        moduleName = ModuleName.Suppliers;
        break;

      case Modules.Invoices:
        moduleName = ModuleName.Invoices;
        break;

      case Modules.BankTransfer:
        moduleName = ModuleName.BankTransfer;
        break;

      case Modules.FixedAssets:
        moduleName = ModuleName.FixedAssets;
        break;

      case Modules.RecurringInvoice:
        moduleName = ModuleName.RecurringInvoice;
        break;

      case Modules.RecurringPurchase:
        moduleName = ModuleName.RecurringPurchase;
        break;

      case Modules.Quotation:
        moduleName = ModuleName.Quotation;
        break;

      case Modules.CreditNote:
        moduleName = ModuleName.CreditNote;
        break;

      case Modules.Receipt:
        moduleName = ModuleName.Receipt;
        break;

      case Modules.Purchase:
        moduleName = ModuleName.Purchase;
        break;

      case Modules.DebitNote:
        moduleName = ModuleName.DebitNote;
        break;

      case Modules.Payment:
        moduleName = ModuleName.Payment;
        break;

      case Modules.Journals:
        moduleName = ModuleName.Journals;
        break;

      case Modules.Purchase:
        moduleName = ModuleName.Purchase;
        break;

      case Modules.CashEntry:
        moduleName = ModuleName.CashEntry;
        break;

      case Modules.BankEntry:
        moduleName = ModuleName.BankEntry;
        break;

      case Modules.BankImportTransactionHistory:
        moduleName = ModuleName.BankImportTransactionHistory;
        break;

      case Modules.QuickEntry:
        moduleName = ModuleName.QuickEntry;
        break;
    }

    return moduleName;
  }

  onThemeMessage(): Observable<any> {
    return this.selectedSubject.asObservable();
  }
  clearThemeMessages() {
    this.selectedSubject.next();
  }

  getCashAccountList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Account/getCashAccount`
    );
  }

  compareFields(field1, field2, moduleId): ValidatorFn {
    return (control: any): ValidationErrors | null => {
      if (moduleId === Modules.BankEntry) {
        if (!control.get(field1)?.value || !control.get(field2)?.value.id)
          return null;

        if (control.get(field1)?.value === control.get(field2)?.value.id) {
          control.get(field1).status = 'INVALID';
          control.get(field2).status = 'INVALID';
          return { matchingFields: true };
        } else {
          control.get(field1).status = 'VALID';
          control.get(field2).status = 'VALID';
          return null;
        }
      } else {
        return null;
      }
    };
  }

  getExportRequest(
    endpoint: string,
    params?: any | null,
    options?: TshqRequestOptions
  ): Observable<HttpResponse<Blob>> {
    return this.http.get(endpoint, {
      ...options,
      responseType: 'blob',
      observe: 'response',
    });
  }

  getTransactionLogData(id: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<any>(
      `${environment.apiVersionUrl}TransactionLog/get/${id}`,
      headers
    );
  }

  getGroupCustomBankAndCashAccount(): Observable<Array<GroupListModel>> {
    return this.http.get<Array<GroupListModel>>(
      `${environment.apiVersionUrl}Account/getGroupCustomBankAndCashAccount`
    );
  }

  getGroupCustomBankAndCashAccountByBranch(
    branchId: Guid
  ): Observable<Array<GroupListModel>> {
    return this.http.get<Array<GroupListModel>>(
      `${environment.apiVersionUrl}Account/getGroupCustomBankAndCashAccountByBranch/${branchId}`
    );
  }

  deleteBankImportTransactionHistory(ids?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.trimObjectSpace(JSON.stringify(ids)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}TransactionLog/delete`,
      options
    );
  }

  deleteBankImport(ids?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.trimObjectSpace(JSON.stringify(ids)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}BankEntry/import/delete`,
      options
    );
  }

  saveColumnsUserLevel(data?: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}ModuleGridColumn/SaveUserModuleGridColumn`,
      JSON.stringify(data),
      headers
    );
  }

  generateDateSeries(filter: OverviewYear): any {
    let dateRange: FilterDateRange = new FilterDateRange();

    switch (filter) {
      case OverviewYear['This month']:
        dateRange.startDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        );
        dateRange.endDate = new Date(
          dateRange.startDate.getFullYear(),
          dateRange.startDate.getMonth(),
          new Date(
            dateRange.startDate.getFullYear(),
            dateRange.startDate.getMonth() + 1,
            0
          ).getDate()
        );
        break;

      case OverviewYear['This quarter']:
        let thisQuarter = Math.floor(new Date().getMonth() / 3);
        dateRange.startDate = new Date(
          new Date().getFullYear(),
          thisQuarter * 3,
          1
        );
        dateRange.endDate = new Date(
          dateRange.startDate.getFullYear(),
          dateRange.startDate.getMonth() + 3,
          0
        );
        break;
      case OverviewYear['This year']:
        dateRange.startDate = new Date(new Date().getFullYear(), 0, 1);
        dateRange.endDate = new Date(new Date().getFullYear(), 11, 31);
        break;

      case OverviewYear['Last month']:
        let date = new Date();
        dateRange.startDate = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        dateRange.endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        break;

      case OverviewYear['Last quarter']:
        let lastQuarter = Math.floor(new Date().getMonth() / 3) - 1;
        dateRange.startDate = new Date(
          new Date().getFullYear(),
          lastQuarter * 3,
          1
        );
        dateRange.endDate = new Date(
          dateRange.startDate.getFullYear(),
          dateRange.startDate.getMonth() + 3,
          0
        );
        break;

      case OverviewYear['Last year']:
        let date1 = new Date();
        dateRange.startDate = new Date(date1.getFullYear() - 1, 0, 1);
        dateRange.endDate = new Date(date1.getFullYear() - 1, 11, 31);

        break;

      case OverviewYear.Custom:
      case OverviewYear['Last 30 days']:
        dateRange.endDate = new Date();
        dateRange.startDate = new Date(
          new Date().setDate(new Date().getDate() - 30)
        );
        break;

      default:
        dateRange.endDate = new Date();
        dateRange.startDate = new Date(dateRange.endDate.setDate(-30));
        break;
    }

    return dateRange;
  }

  getAdvancePaymentList(accountId: Guid, currencyId: number): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<any>(
      `${environment.apiVersionUrl}receipt/advanceAmount/${accountId}/${currencyId}`,
      headers
    );
  }

  getDueInvoicesReceipt(receiptId: Guid, currencyId: number): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<any>(
      `${environment.apiVersionUrl}receipt/getDueInvoicesReceipt/${receiptId}/${currencyId}`,
      headers
    );
  }

  getBankAccountTransactionData(id: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<any>(
      `${environment.apiVersionUrl}BankAccount/getBankAccountTransaction/${id}`,
      headers
    );
  }

  getProductListByProductType(
    productType?: number
  ): Observable<Array<ProductModel>> {
    const apiUrl = productType
      ? `Product/allBasedOnProductType/${productType}`
      : 'Product/allBasedOnProductType/'; // Handle the case when productType is e
    return this.http.get<Array<ProductModel>>(
      `${environment.apiVersionUrl}${apiUrl}`
    );
  }

  getProductListByProductTypeByBranch(
    productType?: number,
    branchId?: Guid
  ): Observable<Array<ProductModel>> {
    const apiUrl = productType
      ? `Product/allBasedOnProductTypeByBranch/${branchId}/${productType}`
      : `Product/allBasedOnProductTypeByBranch/${branchId}/`; // Handle the case when productType is e
    return this.http.get<Array<ProductModel>>(
      `${environment.apiVersionUrl}${apiUrl}`
    );
  }

  //#region Local Storage
  setLocalStorage(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getLocalStorage(key: string): any {
    const item = localStorage.getItem(key);
    if (item !== undefined && item !== null && item !== 'undefined') {
      return JSON.parse(item || '{}');
    } else {
      return null;
    }
  }

  removeItemLocalStorage(key: string): any {
    return localStorage.removeItem(key);
  }

  getAllocationData(id: Guid, moduleId: number): Observable<any> {
    let url;

    switch (moduleId) {
      case Modules.CreditNote:
        url = `creditNote/getAllocationData`;
        break;

      case Modules.DebitNote:
        url = `debitNote/getAllocationData`;
        break;
    }
    return this.http.get<any>(`${environment.apiVersionUrl}${url}/${id}`);
  }

  getRefundData(
    refundInpurParameters: RefundInputParameters,
    moduleId: number
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let url;

    switch (moduleId) {
      case Modules.CreditNote:
        url = `creditNote/getCreditNoteForRefund`;
        break;

      case Modules.DebitNote:
        url = `debitNote/getDebitNoteForRefund`;
        break;
    }
    return this.http.post<any>(
      `${environment.apiVersionUrl}${url}`,
      this.trimObjectSpace(JSON.stringify(refundInpurParameters)),
      headers
    );
  }

  saveReconciliation(reconcilationData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}bankEntry/saveReconciliation`,
      this.trimObjectSpace(JSON.stringify(reconcilationData)),
      headers
    );
  }

  saveAllocation(allocationData: any, moduleId: number): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let url;

    switch (moduleId) {
      case Modules.CreditNote:
        url = `CreditNote/saveCreditNoteAllocation`;
        break;

      case Modules.DebitNote:
        url = `DebitNote/saveDebitNoteAllocation`;
        break;
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}${url}`,
      this.trimObjectSpace(JSON.stringify(allocationData)),
      headers
    );
  }

  getUnExplainedBankTransactions(postingAccountId: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}bankEntry/getUnExplainedBankTransactions/${postingAccountId}`,
      headers
    );
  }

  createCustomReport(customReportQuery: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}customReport/save`,
      JSON.stringify(customReportQuery),
      headers
    );
  }

  getCustomReportQuery(id: Guid) {
    return this.http.get<any>(
      `${environment.apiVersionUrl}customReport/Get/${id}`
    );
  }

  deleteCustomReport(ids?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(ids),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}customReport/delete`,
      options
    );
  }

  copyCustomReport(ids?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}customReport/copyCustomReport`,
      JSON.stringify(ids),
      headers
    );
  }

  customReportExport(queryParams: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.postExportRequest(
      `${environment.apiVersionUrl}customReport/export`,
      JSON.stringify(queryParams),
      headers
    ).pipe(
      switchMap((response: any) => {
        const body: Blob = response.body || new Blob();
        if (queryParams.isPrint) {
          this.print(body);
        } else {
          this.download(response);
        }
        return of(true);
      })
    );
  }

  getAccountBranchId(id: Guid): Observable<Guid> {
    return this.http.get<Guid>(
      `${environment.apiVersionUrl}Account/getAccountBranchId/${id}`
    );
  }

  convertToInvoice(id: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}Quotation/createInvoice`,
      JSON.stringify(id),
      headers
    );
  }

  getClientTypes(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Common/getClientTypes`
    );
  }

  onListRouting(moduleId: number): void {
    if (moduleId === Modules.Clients) {
      this.router.navigate([RoutingPath.Clients]);
    }
  }

  getIsVatRegistered(): Observable<any> {
    return this.http.get<boolean>(
      `${environment.apiVersionUrl}Company/companyIsVatRegistered`
    );
  }

  saveUserPermission(standardPermission: any, userId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}ModuleRolePermission/saveUserPermission/${userId}`,
      JSON.stringify(standardPermission),
      headers
    );
  }

  getUserBusinessPermission(userId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}ModuleRolePermission/getUserPermission/${userId}`
    );
  }

  setButtonPermissions(buttons, gridActions) {
    buttons.forEach((button: any) => {
      const buttonElement = button._elementRef
        .nativeElement as HTMLButtonElement;
      const buttonName = buttonElement.name;
      gridActions.forEach((element) => {
        if (element.name === buttonName && element.isDisabled) {
          buttonElement.disabled = element.isDisabled;
          buttonElement.classList.add('mat-button-disabled');
        }
      });
    });
  }

  emitPermissionData(data: any, componentName: any, id: any): void {
    const permissionData = new PermissionModel();
    permissionData.data = data;
    permissionData.componentName = componentName;
    this.modulePermission.permissionData.next(permissionData);
  }
  setData(event: any, data: any, componentName: any, id: any): void {
    data.forEach((x) => {
      if (x.url === event.slice(1) || x.addUrl === event.slice(1)) {
        this.store.dispatch(new SetModulePermission(x)).subscribe();
        this.emitPermissionData(x, componentName, id);
      } else {
        x.subMenu.map((y) => {
          if (
            y.url === event.slice(1) ||
            y.addUrl === event.slice(1).split(';')[0]
          ) {
            this.store.dispatch(new SetModulePermission(y)).subscribe();
            this.emitPermissionData(y, componentName, id);
          } else {
            y.subMenu.map((z) => {
              if (
                z.url === event.slice(1) ||
                z.addUrl === event.slice(1).split(';')[0]
              ) {
                this.store.dispatch(new SetModulePermission(z)).subscribe();
                this.emitPermissionData(z, componentName, id);
              } else {
                z.subMenu.map((z1) => {
                  if (
                    z1.url === event.slice(1) ||
                    z1.addUrl === event.slice(1).split(';')[0]
                  ) {
                    this.store
                      .dispatch(new SetModulePermission(z1))
                      .subscribe();
                    this.emitPermissionData(z1, componentName, id);
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  setPermission(url: any, id: any): void {
    setTimeout(() => {
      this.menuList$.subscribe((data) => {
        this.setData(url, data, ComponentName.DynamicGridListComponent, id);
      });
    }, 500);
  }

  deleteBranch(branchIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(branchIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Branch/delete`,
      options
    );
  }

  deleteDepartment(departmentIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(departmentIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Department/delete`,
      options
    );
  }

  deleteAccountingPeriod(accountingPeriodIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(accountingPeriodIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}AccountingPeriod/delete`,
      options
    );
  }
  unallocateAmount(id: Guid, typeId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}Receipt/deallocate/${id}/${typeId}`
    );
  }

  downloadSingleFile(fileDownloadRequest: FileDownloadRequest): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.postExportRequest(
      `${environment.apiVersionUrl}FileUpload/download`,
      fileDownloadRequest,
      headers
    ).pipe(
      switchMap((response) => {
        this.download(response);
        return of();
      })
    );
  }
}
