import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
  Stepper,
  VATReturnStatus,
} from '@app/core/Enum';
import {
  CISReturn,
  CISReturnDetail,
  GlobalComponent,
  SubmitCisReturn,
} from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';

import {
  AddClosePopupComponent,
  CISReturnDetailsComponent,
  HMRCGatewayDetailsComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

import { MenuState } from '@app/core/Store';
import {
  GetCIS300Details,
  GetHMRCCredentials,
  SaveCISReturnDetails,
  SubmitCISReturn,
} from '@app/core/Store/CIS/CIS.action';
import { SubContractorDetailsComponent } from '../sub-contractor-details/sub-contractor-details.component';

@Component({
  selector: 'app-monthlyreturn',
  templateUrl: './monthlyreturn.component.html',
  styleUrls: ['./monthlyreturn.component.scss'],
})
export class MonthlyReturnComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  isFirstStepDone = false;
  isSecondStepDone = false;
  isThirdStepDone = false;
  capiumButtonShow = false;
  hmrcButtonShow = false;
  isDisable = true;
  isSubmittedCapium = false;
  isSubmittedHMRC = false;
  isContinueForHMRCSubmission = false;
  stepperEnum = Stepper;
  vatReturnStatus = VATReturnStatus;
  monthlyReturnData: CISReturn;
  cisReturnDetailData: Array<CISReturnDetail>;
  monthlyReturnId = Guid.EMPTY as unknown as Guid;
  hmrcGatewayDetails: SubmitCisReturn;
  submitCISReturnId: Guid;
  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(SubContractorDetailsComponent, { static: false })
  subContractorDetails;

  @ViewChild(CISReturnDetailsComponent, { static: false })
  cisReturnDetailsComponent;

  @ViewChild(HMRCGatewayDetailsComponent, { static: false })
  hmrcGatewayDetailsComponent;

  @ViewChild('horizontalStepper') stepper: MatStepper;

  subscriptionRouting: Subscription;

  triggerEditData: Subject<any> = new Subject<any>();
  triggerEditSubContractorData: Subject<any> = new Subject<any>();
  triggerHmrcCredentialsData: Subject<any> = new Subject<any>();

  isEdit = false;
  commonService: CommonService;
  store: Store;
  notifier: NotificationService;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  isDisableNextButton = false;
  isTrailUser: boolean = false;
  router: Router;
  constructor(
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private spinner: NgxSpinnerService,

    private _Activatedroute: ActivatedRoute,
    private renderer: Renderer2,
    private injector: Injector,
    private globalComponent: GlobalComponent
  ) {
    this.router = injector.get<Router>(Router);
    this.commonService = injector.get<CommonService>(CommonService);
    this.store = injector.get<Store>(Store);
    this.notifier = injector.get<NotificationService>(NotificationService);
  }

  ngOnInit(): void {
    this.spinner.hide();
    this.isTrailUser = this.globalComponent.getIsTrailUser();
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.monthlyReturnId = atob(params.get('id')!) as unknown as Guid;
          this.editMonthlyReturn();
        }
      }
    );
    this.getHMRCCredentials();
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  editMonthlyReturn(): void {
    this.store
      .dispatch(new GetCIS300Details(this.monthlyReturnId))
      .subscribe((res) => {
        this.triggerEditData.next(res.CIS.CIS300Details);
        this.triggerEditSubContractorData.next(
          res.CIS.CIS300Details.cisReturnDetails
        );
      });
  }
  getHMRCCredentials(): void {
    this.store
      .dispatch(new GetHMRCCredentials())
      .pipe()
      .subscribe(
        (res) => {
          this.triggerHmrcCredentialsData.next(res.CIS.hmrcCredentialsData);
        },
        (err) => {
          this.isDisableNextButton = true;
        }
      );
  }
  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.cisReturnDetailData = new Array<CISReturnDetail>();
      this.subContractorDetails.cisSubContactorList.forEach((x) => {
        this.cisReturnDetailData.push({
          id: Guid.EMPTY as unknown as Guid,
          cISReturnId: x.id,
          accountId: x.accountId,
          deductionRate: x.deductionRate,
          totalPayment: x.totalPayment,
          costofMaterial: x.costofMaterial,
        });
      });

      this.monthlyReturnData = {
        id:
          this.isSubmittedHMRC === true
            ? this.submitCISReturnId
            : this.monthlyReturnId,
        fromDate: this.datepipe
          .transform(this.cisReturnDetailsComponent.startDate, 'yyyy-MM-dd')
          ?.toString(),
        toDate: this.datepipe
          .transform(this.cisReturnDetailsComponent.endDate, 'yyyy-MM-dd')
          ?.toString(),
        employmentStatus: this.cisReturnDetailsComponent.employmentStatus,
        nillReturn: this.cisReturnDetailsComponent.nillReturn,
        verification:
          this.cisReturnDetailsComponent.cisReturnDetailsForm.controls
            .verification.value,
        inActivity:
          this.cisReturnDetailsComponent.cisReturnDetailsForm.controls
            .inactivity.value,
        submittedCapium: this.isSubmittedCapium,
        submittedHMRC: this.isSubmittedHMRC,
        cISReturnDetails: this.cisReturnDetailData,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    this.spinner.hide();
    return true;
  }

  onNextClick(isExit: boolean) {
    if (this.stepper.selectedIndex === 0) {
      if (this.cisReturnDetailsComponent.cisReturnDetailsForm.invalid) {
        this.commonService.addValidation(
          this.cisReturnDetailsComponent.cisReturnDetailsForm,
          this.renderer
        );
      } else {
        this.isFirstStepDone = true;
        this.stepper.next();
        this.capiumButtonShow = true;
      }
    } else if (this.stepper.selectedIndex === 1) {
      this.isSecondStepDone = true;
      this.capiumButtonShow = true;
      this.stepper.linear = false;
      this.stepper.selectedIndex = 2;
      setTimeout(() => {
        this.stepper.linear = true;
      });
    }
  }

  submitToCapium(capiumHMRC: boolean): void {
    if (capiumHMRC) {
      this.isSubmittedCapium = true;
      this.isSubmittedHMRC = false;
    } else {
      this.isSubmittedHMRC = true;
      this.isSubmittedCapium = true;
    }
    if (!this.subContractorDetails.isTotalPaymentValid) {
      this.commonService.onFailure(NotificationTextMessage.totalPayment);
      return;
    }
    if (this.dataSubmit()) {
      this.spinner.show();
      this.store
        .dispatch(new SaveCISReturnDetails(this.monthlyReturnData))
        .pipe()
        .subscribe(
          (res) => {
            if (res !== undefined) {
              if (capiumHMRC) {
                this.capiumButtonShow = false;
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
                this.hmrcButtonShow = true;
                this.submitCISReturnId = res.CIS.saveCISReturnDetailsData;
              } else {
                this.isSecondStepDone = true;
                this.stepper.next();
                this.isContinueForHMRCSubmission = true;
              }
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.message);
          }
        );
    }
  }

  continueForHMRCSubmission(): void {
    if (this.cisReturnDetailsComponent.cisReturnDetailsForm.invalid) {
      return;
    } else {
      this.dataSubmitForHMRC();
      this.store
        .dispatch(new SubmitCISReturn(this.hmrcGatewayDetails))
        .pipe()
        .subscribe(
          (res) => {
            if (res !== undefined) {
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.message);
          }
        );
      this.stepper.next();
    }
  }

  dataSubmitForHMRC(): boolean {
    this.spinner.show();
    try {
      this.hmrcGatewayDetails = {
        cisReturnId: this.submitCISReturnId,
        userName:
          this.hmrcGatewayDetailsComponent.hmrcGatewayDetailsForm.controls
            .userName.value,
        password:
          this.hmrcGatewayDetailsComponent.hmrcGatewayDetailsForm.controls
            .password.value,
        isAgent:
          this.hmrcGatewayDetailsComponent.hmrcGatewayDetailsForm.controls
            .isAgent.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    this.spinner.hide();
    return true;
  }

  cancelClick(isCancelClick: boolean) {
    if (!isCancelClick) {
      this.router.navigate([RoutingPath.CIS300List]);
    }
  }

  onPrevClick(stepper) {
    this.capiumButtonShow = true;
    if (this.monthlyReturnId) {
      this.editMonthlyReturn();
    }
    this.isSecondStepDone = false;
    stepper.previous();
  }

  onCapiuamSuccess(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.capiumSaveMessage
    );
  }
}
