import { DatePipe } from '@angular/common';
import {
  Component,
  Inject,
  Injector,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  ChartOfAccountCreditorsGroupTypeName,
  GroupNames,
  MaxLength,
  NotificationTextMessage,
} from '@app/core/Enum';
import { AccountNumber } from '@app/core/Enum/account-number';
import {
  ChartOfAccountListParam,
  Currency,
  GlobalComponent,
} from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CurrencyState,
  GetAccountsBasedOnGroupIdAndTypeId,
  GetCisInvoiceCurrencyByCisInvoiceId,
  GetCisInvoiceLabourAndMaterialAmountByCisInvoiceId,
  GetClientCurrencyList,
  GetCustomBankAndCashAccount,
  GetDueAmountByBillId,
  GetDueAmountByCisInvoiceId,
  GetDueAmountByInvoiceId,
  GetPaymentCurrencyByPurchaseId,
  GetReceiptCurrencyByInvoiceId,
  GetRefreshCurrencyData,
  QuickReceiptAdd,
} from '@app/core/Store';
import { TransactionsAttachmentComponent } from '@app/modules/transactions';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-receipt-payment-popup',
  templateUrl: './add-receipt-payment-popup.html',
  styleUrls: ['./add-receipt-payment-popup.scss'],
})
export class AddReceiptPaymentPopupComponent implements OnInit {
  form: FormGroup;
  paymentAccountList: any[] = [];
  headerText = '';
  currencyList: Currency[];
  defaultCurrency: Guid;
  maxLength = MaxLength;
  quickReceiptData: any;
  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;
  currencyIds: any = [];
  currencyId: number;
  currencySymbol: string;
  invoiceCurrencyList: any = [];
  dueAmount: number;
  isForeignCurrency = false;
  isCurrencyShow = false;
  currencyRate: number;
  originalAmount: number;
  subscriptionRouting: Subscription;
  isPaymentFromCisInv = false;
  labourAmount = 0;
  materialAmount = 0;
  selectedAccountCurrencyId: number;
  convertedAmountSymbol: string;
  commonService: CommonService;
  store: Store;
  spinner: NgxSpinnerService;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddReceiptPaymentPopupComponent>,
    private renderer: Renderer2,
    private globalComponent: GlobalComponent,
    public datepipe: DatePipe,
    private _Activatedroute: ActivatedRoute,
    private injector: Injector
  ) {
    this.headerText = this.data.isReceipt ? 'Add Receipt' : 'Add Payment';
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    this.commonService = injector.get<CommonService>(CommonService);
    this.store = injector.get<Store>(Store);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        const currentUrl = this._Activatedroute.snapshot['_routerState'].url;
        if (currentUrl.includes('Purchase/cisInvoice')) {
          this.isPaymentFromCisInv = true;
        }
      }
    );
    this.setForm();
    this.getAccountList();
  }

  getAccountList(): void {
    setTimeout(() => {
      this.store
        .dispatch(new GetCustomBankAndCashAccount())
        .pipe(
          switchMap((res) => {
            this.paymentAccountList = res.common.customBankAndCashAccount;
            if (this.currencyId !== 123) {
              this.paymentAccountList = this.paymentAccountList.filter(
                (x) => x.currencyId === this.currencyId || x.currencyId === 123
              );
            }

            const param: ChartOfAccountListParam = {
              groupIds: [GroupNames.Creditors],
              typeIds: [
                ChartOfAccountCreditorsGroupTypeName.CreditorsLessThanOneYearTaxationAndSocialSecurity,
              ],
            };

            return this.store.dispatch(
              new GetAccountsBasedOnGroupIdAndTypeId(param)
            );
          }),
          switchMap((secondRes) => {
            if (secondRes.common.accountList.length > 0) {
              let accounts = secondRes.common.accountList;

              accounts = accounts.filter(
                (item) => item.name === AccountNumber.ACCOUNT_8214
              );
              this.paymentAccountList =
                this.paymentAccountList.concat(accounts);
            }
            return of(secondRes);
          })
        )
        .subscribe();
    }, 1000);
  }

  setForm(): void {
    this.form = new FormGroup({
      dueAmount: new FormControl({ value: 0, disabled: true }),
      receiptDate: new FormControl(new Date(), Validators.required),
      receiptNo: new FormControl(''),
      accountId: new FormControl('', Validators.required),
      amount: new FormControl('', Validators.required),
      reference: new FormControl(''),
      currency: new FormControl({ value: '', disabled: true }),
      branchId: new FormControl(''),
      departmentId: new FormControl(''),
      currencyRate: new FormControl('', Validators.required),
      convertedAmount: new FormControl(0),
      labourAmount: new FormControl(0),
      materialAmount: new FormControl(0),
    });

    this.getReceiptCurrencyById();
    this.getDueAmountById();

    if (this.isPaymentFromCisInv) {
      this.getCisLabourAndMaterialAmount();
    }
  }

  getDueAmountById(): void {
    if (this.data.isReceipt) {
      this.store
        .dispatch(new GetDueAmountByInvoiceId(this.data.id))
        .subscribe((res) => {
          this.setDueAmount(res.invoice.dueAmount);
          this.dueAmount = res.invoice.dueAmount;
        });
    } else if (this.isPaymentFromCisInv) {
      this.store
        .dispatch(new GetDueAmountByCisInvoiceId(this.data.id))
        .subscribe((res) => {
          this.setDueAmount(res.cisInvoice.dueAmount);
          this.dueAmount = res.cisInvoice.dueAmount;
        });
    } else {
      this.store
        .dispatch(new GetDueAmountByBillId(this.data.id))
        .subscribe((res) => {
          this.setDueAmount(res.bill.dueAmount);
          this.dueAmount = res.bill.dueAmount;
        });
    }
  }

  setDueAmount(data): void {
    this.form.controls.dueAmount.setValue(+data);
    this.form.controls.amount.setValue(+data);
    this.form.controls.amount.clearValidators();
    this.form.controls.amount.setValidators([
      Validators.required,
      Validators.max(+data),
    ]);
    this.form.controls.amount.updateValueAndValidity();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const data = /^(?=.*[1-9])\d+(?:\.*\d\d?)?$/.test(control.value)
      ? null
      : { invalid: true };

    if (data?.invalid) {
      control.setValue(null);
      control.markAsTouched();
    }
    this.currencyRate = this.form.controls.currencyRate.value;
    this.originalAmount = this.form.controls.amount.value;
    this.form.controls.convertedAmount.setValue(
      this.currencyRate * this.originalAmount
    );
    return data;
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.quickReceiptData = {
        code: this.form.controls.receiptNo.value,
        receiptDate: this.datepipe
          .transform(this.form.controls.receiptDate.value, 'yyyy-MM-dd')
          ?.toString(),
        invoiceId: this.data.id,
        postingAccountId: this.form.controls.accountId.value,
        currencyId: this.form.controls.currency.value,
        totalAmount:
          this.currencyId !== 123
            ? this.form.controls.convertedAmount.value
            : +this.form.controls.amount.value,
        note: this.form.controls.reference.value,
        isCredit: this.data.isReceipt ? true : false,
        attachment: filedata,
        currencyRate: this.form.controls.currencyRate.value,
        foreignTotalAmount:
          this.currencyId !== 123
            ? +this.form.controls.amount.value
            : this.form.controls.convertedAmount.value,
        foreignCurrencyRate: this.form.controls.currencyRate.value,
        invoiceAccountTypeId: this.isPaymentFromCisInv ? 9 : 0,
        labourAmount: this.form.controls.labourAmount.value,
        materialAmount: this.form.controls.materialAmount.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onYesClick(): void {
    if (this.form.invalid) {
      this.commonService.addValidation(this.form, this.renderer);
      return;
    } else {
      if (this.dataSubmit()) {
        if (this.quickReceiptData) {
          this.store
            .dispatch(new QuickReceiptAdd(this.quickReceiptData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.commonService.onSuccess(
                    NotificationTextMessage.successMessage
                  );
                  this.dialogRef.close(true);
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.error.Message);
              }
            );
        }
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  getReceiptCurrencyById(): void {
    if (this.data.isReceipt) {
      this.store
        .dispatch(new GetReceiptCurrencyByInvoiceId(this.data.id))
        .subscribe((res) => {
          this.currencyId = res.invoice.currencyId;
          this.getCurrencyData();
        });
    } else if (this.isPaymentFromCisInv) {
      this.store
        .dispatch(new GetCisInvoiceCurrencyByCisInvoiceId(this.data.id))
        .subscribe((res) => {
          this.currencyId = res.cisInvoice.currencyId;
          this.getCurrencyData();
        });
    } else {
      this.store
        .dispatch(new GetPaymentCurrencyByPurchaseId(this.data.id))
        .subscribe((res) => {
          this.currencyId = res.bill.currencyId;
          this.getCurrencyData();
        });
    }
  }
  getCurrencyData(): void {
    this.store
      .dispatch(new GetClientCurrencyList())
      .pipe(
        tap(() => {
          this.invoiceCurrencyList = this.store.selectSnapshot(
            CurrencyState.getCurrencyList
          );
          if (this.invoiceCurrencyList.length > 0) {
            this.currencySymbol = this.invoiceCurrencyList.find(
              (x) => x.currencyId === this.currencyId
            )?.symbol;

            if (this.currencyId !== 123 && this.currencyId !== undefined) {
              this.form.controls.amount.disable();
              this.isForeignCurrency = true;
              this.isCurrencyShow = true;
              this.convertedAmountSymbol = '£';
              this.currencyRate = this.invoiceCurrencyList.find(
                (x) => x.currencyId === this.currencyId
              )?.rate;
              this.form.controls.convertedAmount.setValue(
                (this.dueAmount / this.currencyRate).toFixed(2)
              );
              this.form.controls.currency.setValue(this.currencyId);
              this.form.controls.currencyRate.setValue(this.currencyRate);
            } else {
              this.form.controls.amount.enable();
              this.isForeignCurrency = false;
              this.isCurrencyShow = false;
              this.form.controls.currencyRate.setValue(1);
              this.form.controls.currency.setValue(123);
            }
          }
        })
      )
      .subscribe();
  }
  onCurrencyRateRefresh(): void {
    this.spinner.show();
    this.currencyIds = [];
    if (this.currencyId === 123 && this.selectedAccountCurrencyId !== 123) {
      this.currencyIds.push(this.selectedAccountCurrencyId);
    } else {
      this.currencyIds.push(this.currencyId);
    }
    this.store
      .dispatch(new GetRefreshCurrencyData(this.currencyIds))
      .subscribe((res) => {
        this.currencyIds = [];
        this.form.controls.currencyRate.setValue(
          res.currency.currencyList.data[0].rate
        );
        if (this.currencyId === 123) {
          this.form.controls.convertedAmount.setValue(
            (this.dueAmount * res.currency.currencyList.data[0].rate).toFixed(2)
          );
        } else {
          this.form.controls.convertedAmount.setValue(
            (this.dueAmount / res.currency.currencyList.data[0].rate).toFixed(2)
          );
        }
        this.spinner.hide();
      });
  }
  onCurrencyRateEntered() {
    if (this.currencyId === 123 || this.selectedAccountCurrencyId === 123) {
      this.form.controls.convertedAmount.setValue(
        (this.dueAmount * this.form.controls.currencyRate.value).toFixed(2)
      );
    } else {
      this.form.controls.convertedAmount.setValue(
        (this.dueAmount / this.form.controls.currencyRate.value).toFixed(2)
      );
    }
  }
  getCisLabourAndMaterialAmount(): void {
    setTimeout(() => {
      this.store
        .dispatch(
          new GetCisInvoiceLabourAndMaterialAmountByCisInvoiceId(this.data.id)
        )
        .subscribe((res) => {
          this.labourAmount = res.cisInvoice.cisInvoiceData.LabourAmount;
          this.materialAmount = res.cisInvoice.cisInvoiceData.MaterialAmount;
          this.form.controls.labourAmount.setValue(this.labourAmount);
          this.form.controls.materialAmount.setValue(this.materialAmount);
        });
    }, 1000);
  }
  onAccountChange(accountId: any) {
    this.selectedAccountCurrencyId = this.paymentAccountList.find(
      (x) => x.id === accountId
    )?.currencyId;
    this.currencyRate = this.paymentAccountList.find(
      (x) => x.id === accountId
    )?.currencyRate;

    if (this.currencyId === 123 && this.selectedAccountCurrencyId !== 123) {
      this.form.controls.amount.disable();
      this.isForeignCurrency = true;
      this.isCurrencyShow = true;
      this.convertedAmountSymbol = this.paymentAccountList.find(
        (x) => x.id === accountId
      )?.symbol;
      this.form.controls.currency.setValue(this.selectedAccountCurrencyId);
      this.form.controls.currencyRate.setValue(this.currencyRate);
      this.originalAmount = this.form.controls.amount.value;
      this.form.controls.convertedAmount.setValue(
        this.currencyRate * this.originalAmount
      );
    } else if (
      this.currencyId === 123 &&
      this.selectedAccountCurrencyId === 123
    ) {
      this.form.controls.amount.enable();
      this.isForeignCurrency = false;
      this.isCurrencyShow = false;
      this.form.controls.convertedAmount.setValue(this.dueAmount);
      this.form.controls.currency.setValue(this.currencyId);
      this.form.controls.currencyRate.setValue(1);
    }
  }
}
