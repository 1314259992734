<div class="mat-dialog-wrapper">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">Inventory Transactions</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onNoClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="content-body">
      <div class="content-header">
        <div class="content-header-input">
          <div
            class="mat-table-wrapper"
            [ngClass]="{
              'mat-table-wrapper-noData': noDataFound
            }"
          >
            <table
              mat-table
              [dataSource]="transactionData"
              aria-describedby="Inventory-Transaction"
              mat-table
              matSort
            >
              <ng-container matColumnDef="refNo">
                <th mat-header-cell *matHeaderCellDef>Ref. No.</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="clamp"
                  title="{{ element.entryNumber }}"
                >
                  {{ element.entryNumber }}
                </td>
              </ng-container>
              <ng-container matColumnDef="itemName">
                <th mat-header-cell *matHeaderCellDef>Item Name</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="clamp"
                  title="{{ element.productName }}"
                >
                  {{ element.productName }}
                </td>
              </ng-container>
              <ng-container matColumnDef="invoiceDate">
                <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="clamp"
                  title="{{ element.entryDate }}"
                >
                  {{ element.entryDate | date: "dd-LL-yyyy" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="purchaseQty">
                <th mat-header-cell *matHeaderCellDef>Purchase Qty</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="clamp"
                  title="{{ element.purchase }}"
                >
                  {{ element.purchase }}
                </td>
              </ng-container>
              <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef class="text-align-right">
                  Price Per Unit
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="clamp text-align-right"
                  title="{{ element.price }}"
                >
                  {{ element.price | numberPipe }}
                </td>
              </ng-container>
              <ng-container matColumnDef="saleQty">
                <th mat-header-cell *matHeaderCellDef>Sale Qty</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="clamp"
                  title="{{ element.sale }}"
                >
                  {{ element.sale }}
                </td>
              </ng-container>
              <ng-container matColumnDef="lossQty">
                <th mat-header-cell *matHeaderCellDef>Loss Qty</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="clamp"
                  title="{{ element.loss }}"
                >
                  {{ element.loss }}
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <div
                    class="grid-actions justify-content-end"
                    *ngIf="
                      element.id !== undefined &&
                      element.id !== null &&
                      element.id !== '' &&
                      element.id !== commonService.defaultGuidValue &&
                      element.type === inventoryEnum.Loss
                    "
                    [ngClass]="{
                      'show-button': selectedRowIndex === i,
                      'hide-edit-button': !element.isManual
                    }"
                  >
                    <div
                      class="d-flex align-items-center"
                      *ngFor="let item of actions; let permissionsIndex = index"
                    >
                      <span>
                        <button
                          mat-button
                          disableRipple
                          title="{{ item.name }}"
                          (click)="onButtonClick(element, item.actionType)"
                        >
                          <mat-icon fontSet="material-icons-outlined">{{
                            item.icon
                          }}</mat-icon>
                        </button>
                      </span>
                    </div>

                    <mat-menu
                      #menu="matMenu"
                      xPosition="before"
                      class="more-button-menu"
                    >
                      <ng-container
                        *ngFor="
                          let item of actions;
                          let permissionsIndex = index
                        "
                      >
                        <div *ngIf="permissionsIndex > 2">
                          <button
                            mat-menu-item
                            disableRipple
                            title="{{ item.name }}"
                            (click)="onButtonClick(element, item.actionType)"
                          >
                            <mat-icon fontSet="material-icons-outlined">{{
                              item.icon
                            }}</mat-icon>
                            <span>{{ item.name }} </span>
                          </button>
                        </div>
                      </ng-container>
                    </mat-menu>
                  </div>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <div *ngIf="noDataFound" class="text-align-center mt-1">
              No records found
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>

<app-custom-notifications></app-custom-notifications>
