import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataType, MaxLength } from '@app/core/Enum';
import { MainListParameters } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  BudgetingState,
  GetBudgetDetailList,
  SaveBudgetDetail,
} from '@app/core/Store';
import {
  BudgetingCalculationPopupComponent,
  IncludeExcludeBudgetingPopupComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-budgeting-detail-list',
  templateUrl: './budgeting-detail-list.component.html',
  styleUrls: ['./budgeting-detail-list.component.scss'],
})
export class BudgetingDetailListComponent implements OnInit {
  dataType = DataType;

  mainList: any;
  noDataFound = false;
  isRowHighlighted = false;
  maxLength = MaxLength;

  @Select(BudgetingState.columns)
  columns$: Observable<
    Array<{
      name: string;
      value: string;
      dataType?: DataType;
      allowSortBy?: boolean;
    }>
  >;

  @Select(BudgetingState.columnNames)
  columnsNames$: Observable<Array<string>>;

  @Select(BudgetingState.actions)
  actions$: Observable<any>;

  @Select(BudgetingState.totalRecord)
  totalRecord$: Observable<number>;

  @Input() budgetingId: Guid;
  @Input() budgetingDetails: any;
  @Input() triggerGetList: Observable<any>;

  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource();
  listParameters: MainListParameters = new MainListParameters();

  getListParam: any;

  constructor(
    public dialog: MatDialog,
    private store: Store,
    public datepipe: DatePipe,
    public spinner: NgxSpinnerService,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.triggerGetList.subscribe((x: any) => {
      this.getListParam = {
        BudgetId: x.id,
        StartPeriod: x.startPeriod,
        ForPeriod: x.forPeriod,
        Actual: x.actual,
      };
      this.getList(false);
    });
  }

  onAccountsClick(): void {
    const accountPopup = this.dialog.open(
      IncludeExcludeBudgetingPopupComponent,
      {
        data: {
          budgetingId: this.budgetingId,
        },
        autoFocus: false,
        disableClose: true,
      }
    );
    accountPopup.afterClosed().subscribe((result) => {
      if (result) {
        this.saveBudgetDetail(result);
      }
    });
  }

  calculateAmount(element: any, colIndex: number, rowIndex: number): void {
    const calAmountPopup = this.dialog.open(BudgetingCalculationPopupComponent);

    calAmountPopup.afterClosed().subscribe((result) => {
      if (result) {
        this.calculation(element, colIndex, result, rowIndex);
      }
    });
  }

  onInputChange(colIndex: number, rowIndex: number): void {
    let isTotalField = false;
    let total = 0;

    this.mainList.forEach((x, i) => {
      if (i <= rowIndex) {
        total = Number(total) + Number(x[colIndex]);
        if (x[1] === 'Total') {
          total = 0;
        }
      }
      if (i === rowIndex) {
        isTotalField = i === rowIndex;
      }

      if (isTotalField) {
        if (x[1] === 'Total') {
          x[colIndex] = total;
          isTotalField = false;
        }
      }
    });
  }

  calculation(
    element: any,
    index: number,
    result: any,
    rowIndex: number
  ): void {
    const oldValue = +element[index];
    const total = +result.amount;
    let calTotal = 0;
    let totalRowCount: any[] = [];

    for (let i = index; i < element.length - 1; i++) {
      totalRowCount.push(i);
      const calculationType = this.commonService.budgetingCalculation.filter(
        (x) => x.id == result.calculationType
      );
      if (calculationType[0].id === 1) {
        element[i] = total.toString();
      } else if (calculationType[0].id === 2) {
        if (oldValue !== 0) {
          if (index !== i) {
            element[i] = (total + +element[i - 1]).toString();
          }
        } else {
          element[i] = (total + calTotal).toString();
          calTotal = total + calTotal;
        }
      } else {
        if (oldValue !== 0) {
          if (index !== i) {
            element[i] = ((element[i - 1] / 100) * total + +element[i - 1])
              .toFixed(2)
              .toString();
          }
        } else {
          element[i] = '0';
        }
      }
    }

    totalRowCount.forEach((colIndex) => {
      this.onInputChange(colIndex, rowIndex);
    });
  }

  saveBudgetDetail(data: any): void {
    const param = {
      accountIds: data.selectedIds,
      groupId: data.groupId,
      typeId: data.typeId,
      budgetId: data.budgetingId,
    };
    this.spinner.show();
    this.store.dispatch(new SaveBudgetDetail(param)).subscribe((res) => {
      this.spinner.hide();
      if (res.budgeting.isBudgetDetailSave) {
        this.getList(false);
      }
    });
  }

  getList(isRowHighlighted: boolean): void {
    this.spinner.show();
    this.store
      .dispatch(new GetBudgetDetailList(this.getListParam))
      .pipe(
        tap(() => {
          this.isRowHighlighted = isRowHighlighted;
        })
      )
      .subscribe((res) => {
        this.spinner.hide();
        this.mainList = res.budgeting.mainList.resultSet.data;

        this.noDataFound = this.mainList.length > 0 ? false : true;
        this.dataSource.data = this.mainList;
      });
  }
}
