import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vat-transaction-details',
  templateUrl: './vat-transaction-details.component.html',
  styleUrls: ['./vat-transaction-details.component.scss']
})
export class VatTransactionDetailsComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
  moduleId: number;
  triggerDateChange(data: any): void {
  }

}
