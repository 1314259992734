<div class="mat-dialog-wrapper" style="width: 600px">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">Fixed Asset Return</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onNoClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="d-flex justify-space-between align-items-center mb-2">
      <div>
        <div class="d-flex gap-40">
          <div class="form-field datepicker">
            <p>Purchase Return Date<span class="text-danger-300">*</span></p>
            <mat-form-field appearance="standard">
              <input
                matInput
                [matDatepicker]="returnDatePicker"
                autocomplete="off"
                (change)="commonService.validateDate($event)"
                tabindex="-1"
                [(ngModel)]="returnDate"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="returnDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #returnDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex gap-40">
          <div class="form-field">
            <p>Purchase No. <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                [(ngModel)]="fixedAsset.entryNumber"
                autocomplete="off"
                required
                maxlength="{{ maxLength.maxLength25 }}"
                type="text"
                [disabled]="true"
              />
            </mat-form-field>
          </div>
          <div class="form-field datepicker">
            <p>Purchase Date<span class="text-danger-300">*</span></p>
            <mat-form-field appearance="standard">
              <input
                matInput
                [(ngModel)]="fixedAsset.entryDate"
                [matDatepicker]="invoiceDatePicker"
                autocomplete="off"
                (change)="commonService.validateDate($event)"
                tabindex="-1"
                [disabled]="true"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="invoiceDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #invoiceDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex gap-40">
          <div class="form-field">
            <p>Fixed Asset Type <span class="text-danger-300">*</span></p>
            <ng-select
              #invoiceType
              [items]="fixedAssetsTypeList"
              (focus)="invoiceType.open()"
              (valueChange)="invoiceType.close()"
              [disableOptionCentering]="true"
              [(ngModel)]="fixedAsset.bookAccountId"
              bindLabel="name"
              bindValue="id"
              groupBy="groupName"
              panelClass="mat-select-position"
              appendTo="body"
              [disabled]="true"
            >
              <ng-template ng-option-tmp let-item="item">
                <span class="ng-option-label" [attr.title]="item.name">{{
                  item.name
                }}</span>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <mat-accordion [multi]="true">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-icons-outlined" class="text-primary"
              >inventory_2</mat-icon
            >
            <div class="d-flex flex-column">
              <span>Product Details</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mat-table">
          <table
            mat-table
            [dataSource]="fixedAsset.items"
            aria-describedby="Allocation-Refund-Data"
          >
            <ng-container matColumnDef="srNo">
              <th mat-header-cell *matHeaderCellDef>Sr No.</th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div class="clamp">{{ i + 1 }}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="qty">
              <th mat-header-cell *matHeaderCellDef>Qty</th>
              <td mat-cell *matCellDef="let element; let i = index">
                <mat-form-field floatLabel="never" class="w-60">
                  <input
                    allow_13_2_Decimal
                    matInput
                    [(ngModel)]="element.quantity"
                    autocomplete="off"
                    [disabled]="true"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="product">
              <th mat-header-cell *matHeaderCellDef>
                Product <span class="text-danger-300">*</span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index">
                <ng-select
                  #selectProduct
                  [disableOptionCentering]="true"
                  (focus)="selectProduct.open()"
                  (valueChange)="selectProduct.close()"
                  [disableOptionCentering]="true"
                  [(ngModel)]="element.productId"
                  dropdownPosition="top"
                  panelClass="mat-select-position"
                  [disabled]="true"
                >
                  <ng-option
                    *ngFor="let option of productTypeList"
                    [value]="option.id"
                  >
                    <div title="{{ option.name }}">
                      {{ option.name }}
                    </div>
                  </ng-option>
                </ng-select>
              </td>
            </ng-container>

            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>
                Price <span class="text-danger-300">*</span>
              </th>
              <td mat-cell *matCellDef="let element; let i = index">
                <mat-form-field class="w-60">
                  <input
                    allow_10_2_Decimal
                    zeroNotAllowed
                    matInput
                    [(ngModel)]="element.price"
                    autocomplete="off"
                    [disabled]="true"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="returnQty">
              <th mat-header-cell *matHeaderCellDef class="w-100">
                Return Qty
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-form-field>
                  <input
                    allow_10_2_Decimal
                    matInput
                    autocomplete="off"
                    [(ngModel)]="element.returnQuantity"
                    (blur)="onReturnQuantityChange(element)"
                    [max]="element.quantity"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayProductDetailsColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayProductDetailsColumns"
            ></tr>
            <tr class="mat-row" *matNoDataRow>
              <td
                class="mat-cell"
                [ngClass]="'text-align-center'"
                [attr.colspan]="displayProductDetailsColumns.length"
              >
                <span *ngIf="false">No Records Found</span>
              </td>
            </tr>
          </table>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion [multi]="true">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-icons-outlined" class="text-primary"
              >attachment</mat-icon
            >
            <div class="d-flex flex-column">
              <span>Attachment</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-transactions-attachment> </app-transactions-attachment>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion [multi]="true" *ngIf="showRefund">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-icons-outlined" class="text-primary"
              >receipt</mat-icon
            >
            <div class="d-flex flex-column">
              <span>Receipt Allocation</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mt-1 mb-10" *ngIf="showRefund">
          <p class="fw-bold font-size-18">Refund</p>
          <div class="d-flex align-items-center gap-10">
            <div class="form-field datepicker mb-0">
              <p>Date</p>
              <mat-form-field appearance="standard">
                <input
                  matInput
                  [(ngModel)]="refundDate"
                  [matDatepicker]="receiptDate"
                  autocomplete="off"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="receiptDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #receiptDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="form-field mb-0">
              <p>Account</p>
              <ng-select
                #accountId
                [disableOptionCentering]="true"
                (focus)="accountId.open()"
                (valueChange)="accountId.close()"
                [(ngModel)]="refundAccountId"
                panelClass="mat-select-position"
                required
              >
                <ng-option
                  *ngFor="let option of paymentAccountList"
                  [value]="option.id"
                >
                  <div title="{{ option.name }}">{{ option.name }}</div>
                </ng-option>
              </ng-select>
            </div>
            <div class="form-field mb-0">
              <p>Amount</p>
              <mat-form-field>
                <input
                  allow_10_2_Decimal
                  matInput
                  autocomplete="off"
                  [(ngModel)]="dueAmount"
                  [disabled]="true"
                />
              </mat-form-field>
            </div>
          </div></div
      ></mat-expansion-panel>
    </mat-accordion>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSaveClick()"
    >
      Save
    </button>
    <button
      class="action-button secondary-button"
      mat-dialog-close
      type="button"
      mat-button
      disableRipple
      (click)="onNoClick()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
