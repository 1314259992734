import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-templates',
  templateUrl: './upload-templates.component.html',
  styleUrls: ['./upload-templates.component.scss'],
})
export class UploadTemplatesComponent implements OnInit {
  fileInfo: string;
  constructor() {}

  ngOnInit(): void {}

  onFileSelect(input: HTMLInputElement): void {
    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;

      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }

      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }

    const file = input.files[0];
    this.fileInfo = `${file.name} (${formatBytes(file.size)})`;
  }
}
