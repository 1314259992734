import { OverviewFilterModel } from '@app/core/Models';

export class GetDashboardData {
  static readonly type = '[DASHBOARD] Get Dashboard Data';
}

export class GetDashboardCard {
  static readonly type = '[DASHBOARD] Get Dashboard Card';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetDashboardIncomeByCategoriesOverview {
  static readonly type =
    '[DASHBOARD] Get Dashboard Income By Categoriesr Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetDashboardExpenseByCategoriesOverview {
  static readonly type =
    '[DASHBOARD] Get Dashboard Expense By Categories Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetDashboardIncomeVsExpensesOverview {
  static readonly type =
    '[DASHBOARD] Get Dashboard Income vs Expenses Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetDashboardAgeingSummaryOverview {
  static readonly type = '[DASHBOARD] Get Dashboard Ageing Summary Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetDashboardCashFlowOverview {
  static readonly type = '[DASHBOARD] Get Dashboard Cash Flow Overview';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetDashboardIncomeComparision {
  static readonly type = '[DASHBOARD] Get Dashboard Income Comparison';
  constructor(public filterData: OverviewFilterModel) {}
}

export class GetDashboardExpenseComparision {
  static readonly type = '[DASHBOARD] Get Dashboard Expense Comparison';
  constructor(public filterData: OverviewFilterModel) {}
}
export class GetDashboardVATSummary {
  static readonly type = '[DASHBOARD] Get Dashboard VAT Summary';
  constructor(public filterData: OverviewFilterModel) {}
}
