<div>
  <table
    aria-describedby="main-list"
    mat-table
    matSort
    (matSortChange)="sorting(sort.active, sort.direction)"
    [dataSource]="dataSource"
  >
    <ng-container
      *ngFor="let col of columns$ | async; let colIndex = index"
      matColumnDef="{{ col.name }}"
    >
      <th mat-header-cell *matHeaderCellDef [id]="col.name" fxFlex="50">
        <div class="d-flex align-items-center">
          <span mat-sort-header [disabled]="!col.name">
            {{ col.name }}
          </span>
        </div>
      </th>

      <td mat-cell *matCellDef="let element; let i = index">
        <div
          [ngClass]="
            colIndex === (columns$ | async).length - 1
              ? 'justify-space-between'
              : ''
          "
        >
          <span
            class="fw-bold"
            *ngIf="element[element.length - 1] === 'True' && colIndex === 1"
          >
            {{ element[0] }}
          </span>
          <span
            *ngIf="element[element.length - 1] === 'False'"
            class="d-flex align-items-center"
          >
            <div class="clamp">
              <span
                *ngIf="col.dataType === dataType.String"
                title="{{ element[colIndex] }}"
              >
                {{ element[colIndex] }}</span
              >

              <span
                *ngIf="col.dataType === dataType.Bool"
                title="{{ element[colIndex] }}"
                [ngClass]="
                  element[colIndex] === 'Active'
                    ? 'status-active'
                    : 'status-archived'
                "
              >
                {{ element[colIndex] }}</span
              >

              <span
                *ngIf="col.dataType === dataType.DateTime"
                title="{{ element[colIndex] }}"
              >
                {{ element[colIndex] | date: "dd-LLL-yyyy" }}</span
              >

              <span
                *ngIf="
                  col.dataType === dataType.Int32 ||
                  col.dataType === dataType.Currency
                "
                title="{{ element[colIndex] | numberPipe }}"
              >
                £ {{ element[colIndex] | numberPipe }}</span
              >
            </div>
          </span>
        </div>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="columnsNames$ | async; sticky: true"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: columnsNames$ | async"></tr>
  </table>
  <div
    *ngIf="dataSource.data.length === 0 && noDataFound"
    class="text-align-center mt-1 fw-bold"
  >
    No records found
  </div>
</div>
