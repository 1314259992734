import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  ComponentName,
  DataType,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { MenuModel, PermissionModel, ViewParamModel } from '@app/core/Models';
import { CommonService, ModulePermission } from '@app/core/Services';
import {
  GetCustomBankAccountList,
  GetUnExplainedBankTransactions,
  MenuState,
  SaveReconciliation,
  SetModulePermission,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  ViewReceiptComponent,
} from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { TreeGridComponent } from '@syncfusion/ej2-angular-treegrid';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'app-auto-bank-reconcillation',
  templateUrl: './auto-bank-reconcillation.component.html',
  styleUrls: ['./auto-bank-reconcillation.component.scss'],
})
export class AutoBankReconcillationComponent implements OnInit {
  @ViewChild('treegrid', { static: false }) public treegrid: TreeGridComponent;
  subscriptionRouting: Subscription;
  selectedBank: any;
  receiptAccountList: any[] = [];
  tempReceiptAccountList: any[];
  dataType = DataType;
  data: any[] = [];
  modules = Modules;
  reconciliationData: any;
  listofSelectedids: any[] = [];
  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;
  isShowCollapse = false;
  constructor(
    private _Activatedroute: ActivatedRoute,
    private store: Store,
    private commonService: CommonService,
    private dialog: MatDialog,
    private modulePermission: ModulePermission,
    private spinner: NgxSpinnerService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.selectedBank = atob(params.get('id')!) as unknown as Guid;
          this.getBankAccountList();
        }
      }
    );
  }

  getBankAccountList(): void {
    this.store
      .dispatch(new GetCustomBankAccountList())
      .pipe(
        tap((res) => {
          this.receiptAccountList = res.common.customBankAccount;
          this.tempReceiptAccountList = this.receiptAccountList;
          this.getUnExplainedBankTransactions();
        })
      )
      .subscribe();
  }

  getUnExplainedBankTransactions(): void {
    this.store
      .dispatch(new GetUnExplainedBankTransactions(this.selectedBank))
      .subscribe((res) => {
        this.data = res.common.unExplainedBankTransactions;
      });
  }

  redirect(data: any): void {
    if (data.moduleId > 0) {
      if (data.moduleId === Modules.AddCustomAccounts) {
        this.commonService.onEditRouting(
          true,
          Modules.ReportAccountDetail,
          data.id,
          false
        );
        this.setPermission(RoutingPath.ReportAccountDetailsPermission, data.id);
      } else if (
        data.moduleId === Modules.Customers ||
        data.moduleId === Modules.Suppliers
      ) {
        this.commonService.onEditRouting(true, data.moduleId, data.id);
      } else if (this.isAllowedOpenView(data.moduleId)) {
        this.openView(data.id, data.moduleId);
      } else {
        this.commonService.onEditRouting(true, data.moduleId, data.id);
      }
    }
  }

  emitPermissionData(data: any, componentName: any, id: any): void {
    const permissionData = new PermissionModel();
    permissionData.data = data;
    permissionData.componentName = componentName;
    this.modulePermission.permissionData.next(permissionData);
  }

  setData(event: any, data: any, componentName: any, id: any): void {
    data.forEach((x) => {
      if (x.url === event.slice(1) || x.addUrl === event.slice(1)) {
        this.store.dispatch(new SetModulePermission(x)).subscribe();
        this.emitPermissionData(x, componentName, id);
      } else {
        x.subMenu.map((y) => {
          if (
            y.url === event.slice(1) ||
            y.addUrl === event.slice(1).split(';')[0]
          ) {
            this.store.dispatch(new SetModulePermission(y)).subscribe();
            this.emitPermissionData(y, componentName, id);
          } else {
            y.subMenu.map((z) => {
              if (
                z.url === event.slice(1) ||
                z.addUrl === event.slice(1).split(';')[0]
              ) {
                this.store.dispatch(new SetModulePermission(z)).subscribe();
                this.emitPermissionData(z, componentName, id);
              } else {
                z.subMenu.map((z1) => {
                  if (
                    z1.url === event.slice(1) ||
                    z1.addUrl === event.slice(1).split(';')[0]
                  ) {
                    this.store
                      .dispatch(new SetModulePermission(z1))
                      .subscribe();
                    this.emitPermissionData(z1, componentName, id);
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  setPermission(url: any, id: any): void {
    setTimeout(() => {
      this.menuList$.subscribe((data) => {
        this.setData(url, data, ComponentName.DynamicGridListComponent, id);
      });
    }, 500);
  }

  openView(id: any, moduleId: any): void {
    const data: ViewParamModel = {
      moduleId: moduleId,
      id: id,
    };

    this.dialog
      .open(ViewReceiptComponent, {
        data,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {});
  }

  isAllowedOpenView(moduleId: number): boolean {
    let isAllowed;

    switch (moduleId) {
      case Modules.Invoices:
      case Modules.Purchase:
      case Modules.CreditNote:
      case Modules.DebitNote:
      case Modules.FixedAssets:
      case Modules.Quotation:
      case Modules.Journals:
      case Modules.Receipt:
      case Modules.Payment:
      case Modules.CIS:
      case Modules.QuickEntry:
        isAllowed = true;
        break;
      default:
        isAllowed = false;
        break;
    }

    return isAllowed;
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.reconciliationData = [];
      this.data.forEach((element) => {
        if (element.bankMatchingTransactions.length > 0) {
          element.bankMatchingTransactions.forEach((el) => {
            if (el.isSelected) {
              const param = {
                transactionId: element.id,
                referenceId: el.id,
                referenceTypeId: el.invoiceTypeId,
                isManual: element.isManual,
                isBankFeed: element.isBankFeed,
                referenceItemId: el.itemId,
              };
              this.reconciliationData.push(param);
            }
          });
        }
      });
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.dataSubmit()) {
      if (this.reconciliationData.length > 0) {
        this.spinner.show();
        this.store
          .dispatch(new SaveReconciliation(this.reconciliationData))
          .subscribe((res) => {
            this.spinner.hide();
            this.commonService.setLocalStorage(
              'selectedBank',
              this.selectedBank
            );
            this.location.back();
          });
      }
    }
  }

  hasChildren = (item: any): boolean => {
    return (
      item.bankMatchingTransactions && item.bankMatchingTransactions.length > 0
    );
  };

  onCancel(isCancelClick: boolean): void {
    this.getUnExplainedBankTransactions();
  }

  radioButtonChange(selectedData: any): void {
    console.log(selectedData, 'selectedData');
    const data = this.data;
    data.forEach((item) => {
      if (item.id === selectedData.parentItem.id) {
        item.bankMatchingTransactions.forEach((element) => {
          if (
            element.id === selectedData.id &&
            element.itemId === selectedData.itemId
          ) {
            element.isSelected = !element.isSelected;
          } else {
            element.isSelected = false;
          }
        });
      }
    });
    this.data = [...data];
    this.checkDisabeIds();
  }

  collapseAll(): void {
    this.isShowCollapse = true;
    this.treegrid.collapseAll();
  }

  expandAll(): void {
    this.isShowCollapse = false;
    this.treegrid.expandAll();
  }

  checkDisabeIds(): void {
    this.listofSelectedids = [];

    this.data.forEach((element) => {
      element.bankMatchingTransactions.forEach((el) => {
        if (el.isSelected) {
          this.listofSelectedids.push(el.itemId);
        }
      });
    });

    this.data.forEach((element) => {
      element.bankMatchingTransactions.forEach((el) => {
        const filtered = this.listofSelectedids.filter((x) => x === el.itemId);
        if (filtered.length > 0) {
          el.isEnable = true;
        } else {
          el.isEnable = false;
        }
      });
    });
  }
  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
