import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import {
  AccountEntity,
  ChartOfAccountCreditorsGroupTypeName,
  CheckValidDate,
  ConfirmationType,
  GroupNames,
  ImportDateName,
  MaxLength,
  ModuleName,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { AccountNumber } from '@app/core/Enum/account-number';
import { FileImportRequestModel, ImportStep } from '@app/core/Enum/import';
import { IncomeTypeName } from '@app/core/Enum/income-types-name';
import { quickEntryType } from '@app/core/Enum/quick-entry';
import { VatName } from '@app/core/Enum/vat-name';
import { VatRateCodeType } from '@app/core/Enum/vat-rate-code-type';
import {
  ChartOfAccountListParam,
  Country,
  Currency,
  GlobalComponent,
  GroupListModel,
  MainListParameters,
  SideListModel,
  VatRateScheme,
} from '@app/core/Models';
import { CommonService, NotificationService } from '@app/core/Services';
import {
  AccountState,
  CheckAppoinmentDate,
  CheckCustomerSupplierDuplicate,
  CheckEntryNumber,
  CommonState,
  CountryState,
  CreateAccount,
  CreateCustomAccount,
  DividendsState,
  GetAllBranchList,
  GetAllShareClassType,
  GetAllShareHolder,
  GetBranchList,
  GetCountryList,
  GetCustomBankAndCashAccount,
  GetCustomerSupplierList,
  GetDepartmentListByBranch,
  GetFixedAssetAdditionList,
  GetGroupAccountsBasedOnGroupId,
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetImportTemplate,
  GetJournalAccounts,
  GetNonStandardAccountList,
  GetSubcontractorList,
  GetVatRateList,
  ImportModules,
  ImportState,
} from '@app/core/Store';
import {
  ConfirmationBoxComponent,
  QuickAddComponent,
} from '@app/modules/common';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-import-file-data',
  templateUrl: './import-file-data.component.html',
  styleUrls: ['./import-file-data.component.scss'],
})
export class ImportFileDataComponent implements OnInit, OnDestroy {
  selectedFileName: string;
  fileInfo: string = '';
  selectedFile: File;
  step: ImportStep;
  checkValidDateEnum = CheckValidDate;
  url: any;
  showAccountName = false;
  showIncomeAccount = false;
  showDepositAccount = false;
  accountObj: any = null;
  incomeAccountObj: any = null;
  depositAccountObj: any = null;
  maxLength = MaxLength;
  importForm: FormGroup;
  totalCredit = 0;
  totalDebit = 0;
  TotalDifference: any = 0;

  @Input() importDetailList: any;
  @Input() panelHeaderTitle: string;
  @Input() selectedModuleId: number;
  @Input()
  triggerImport: Observable<any>;
  @Input()
  triggerImportDetail: Observable<any>;
  @Input()
  triggerCancel: Observable<any>;
  module = Modules;
  selectedValue: Guid;
  currencyList: Currency[];
  customerList: SideListModel[];
  supplierList: SideListModel[];
  customeOrSupplierList: SideListModel[];
  accountGroupList: GroupListModel[];
  tempAccountGroupList: GroupListModel[];
  countryList: Country[];
  depositToList: SideListModel[];
  notificationMessage = NotificationTextMessage;
  importDateName = ImportDateName;
  periodicDate: any;

  uploadFormGroup = this._formBuilder.group({
    isDuplicate: new FormControl(false),
  });

  importSubscription: Subscription;
  cancelSubscription: Subscription;
  fileImportRequestModel: FileImportRequestModel;

  @ViewChild('stepper') private myStepper: MatStepper;

  displayedColumns: any[] = [];
  displayedColumns2: string[] = [];
  maxDate = new Date();

  importSuccessList: any = new MatTableDataSource();
  importErrorList: any = new MatTableDataSource();
  importDuplicateList: any = new MatTableDataSource();
 // vatRateList: VatRateScheme[];
  vatRateList: any[]=[];
  
  originalVatRateList: any[]=[];
  shareClassTypeList: any;
  shareHolderList: any;
  finalResponse: any;
  periodicDateList: any;
  startDate = new Date();
  endDate = new Date();
  departmentList: any[] = [];
  branchList: any[] = [];
  departmentListParameters: MainListParameters = new MainListParameters();
  branchListParameters: MainListParameters = new MainListParameters();
  openingbranchList: any[] = [];
  subcontractorList: any[] = [];
  paymentAccountList: any[] = [];
  selectedBranchValue: any;
  notifier: NotificationService;
  store: Store;
  spinner: NgxSpinnerService;
  commonService: CommonService;
  quickEntryTypeList: any[] = [
    {
      id: quickEntryType.Receipt,
      name: 'Receipt',
    },
    {
      id: quickEntryType.Payment,
      name: 'Payment',
    },
  ];
  receiptAccountGroupList: any[] = [];
  paymentAccountGroupList: any[] = [];
  constructor(
    private _formBuilder: FormBuilder,
    private renderer: Renderer2,
    private changeDetectorRefs: ChangeDetectorRef,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private globalComponent: GlobalComponent,
    private injector: Injector
  ) {
    this.notifier = injector.get<NotificationService>(NotificationService);
    this.store = injector.get<Store>(Store);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.setForm();
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setPeriodDate();
    this.step = ImportStep.Select;

    this.triggerImportDetail.subscribe((res) => {
      this.finalResponse = res;
      this.myStepper.next();
    });

    this.importSubscription = this.triggerImport.subscribe(() => {
      if (this.step !== ImportStep.Select) {
        this.checkValidFile();
      }
    });
    this.cancelSubscription = this.triggerCancel?.subscribe(() => {
      this.step = ImportStep.Select;
    });
  }

  setForm(): void {
    this.importForm = new FormGroup({
      accountObj: new FormControl(),
      incomeAccountObj: new FormControl(),
      depositAccountObj: new FormControl(),
      accountingPeriod: new FormControl(),
      accountingDate: new FormControl(new Date()),
      branchId: new FormControl(),
      departmentId: new FormControl(),
      openingBranchId: new FormControl(),
    });
    this.setPeriodDate();
  }

  checkCustomerValidElement(element, index: number): void {
    if (
      element.countryId !== this.commonService.defaultGuidValue &&
      element.countryId !== 0
    ) {
      element.countryError = false;
    }
    if (
      element.contactName !== null &&
      element.contactName !== undefined &&
      element.contactName !== ''
    ) {
      element.contactNameError = false;
    }
    if (!element.emailError) {
      this.commonService.isEmailValid = true;
    }
    if (
      element.email !== null &&
      element.email !== undefined &&
      element.email !== ''
    ) {
      if (this.commonService.isEmailValid) {
        element.emailError = false;
      } else {
        element.emailError = true;
      }
    }
    if (
      element.firstName !== null &&
      element.firstName !== undefined &&
      element.firstName !== ''
    ) {
      element.firstNameError = false;
    }
    if (element.noOfShare > 0) {
      element.noOfShareError = false;
    }
    if (element.valuePerShare > 0) {
      element.valuePerShareError = false;
    }
    if (element.shareType !== 0) {
      element.shareTypeError = false;
    }

    if (
      !element.countryError &&
      !element.contactNameError &&
      !element.emailError &&
      !element.firstNameError &&
      !element.noOfShareError &&
      !element.valuePerShareError &&
      !element.shareTypeError &&
      !element.appointmentDateError
    ) {
      this.importSuccessList.data.push(element);
      this.importSuccessList.data = [...this.importSuccessList.data];
      this.importSuccessList = new MatTableDataSource(
        this.importSuccessList.data
      );

      this.importErrorList.data.splice(index, 1);
      this.importErrorList.data = [...this.importErrorList.data];
      this.importErrorList = new MatTableDataSource(this.importErrorList.data);

      this.notifier.success(
        NotificationHeader.success,
        NotificationTextMessage.moveToSuccessList
      );
    }
  }

  setPeriodDate(): void {
    this.periodicDateList = this.globalComponent.getFinancialPeriod();
    this.selectedValue = this.periodicDateList[0].id;
    this.onAccountPeriodChange();
  }

  onAccountPeriodChange(): void {
    let accountingId = this.importForm.get('accountingPeriod')?.value;
    this.periodicDateList.forEach((element) => {
      if (element.id === accountingId) {
        this.startDate = element.fromDate;
        this.endDate = element.toDate;
      }
    });
  }

  checkProductName(element, index: number): void {
    if (
      element.productName !== '' &&
      element.productName !== null &&
      element.productName !== undefined
    ) {
      element.productNameError = false;
      this.moveDuplicatetoSuccessList(element, index);
    }
  }

  moveDuplicatetoSuccessList(element, index): void {
    if (
      !element.entryNumberError &&
      !element.productNameError &&
      !element.yearEndDateError &&
      !element.declarationDateError &&
      !element.paymentDateError &&
      !element.entryDateError &&
      !element.dueDateError &&
      !element.appointmentDateError
    ) {
      this.importSuccessList.data.push(element);
      this.importSuccessList.data = [...this.importSuccessList.data];
      this.importSuccessList = new MatTableDataSource(
        this.importSuccessList.data
      );

      this.importDuplicateList.data.splice(index, 1);
      this.importDuplicateList.data = [...this.importDuplicateList.data];
      this.importDuplicateList = new MatTableDataSource(
        this.importDuplicateList.data
      );

      this.notifier.success(
        NotificationHeader.success,
        NotificationTextMessage.moveToSuccessList
      );
    }
  }

  checkErrorEntryNumber(element, index: number): void {
    if (element.entryNumber !== '') {
      this.store
        .dispatch(
          new CheckEntryNumber(element.entryNumber, this.selectedModuleId)
        )
        .subscribe((res) => {
          if (!res.common.isValidEntryNo) {
            element.entryNumberError = false;
            this.checkValidElement(element, index);

            this.notifier.success(
              NotificationHeader.success,
              NotificationTextMessage.invoiceNoValidateSuccessfully
            );
          } else {
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.invoiceNoIsExist
            );
          }
        });
    }
  }

  checkValidElement(element, index: number, isFromSucessList?: boolean): void {
    if (element.accountId !== this.commonService.defaultGuidValue) {
      element.accountError = false;
    }
    if (element.shareholderId !== this.commonService.defaultGuidValue) {
      element.shareholderError = false;
    }
    if (
      element.shareClassTypeId !== this.commonService.defaultGuidValue &&
      element.shareClassTypeId !== 0
    ) {
      element.shareClassTypeIdError = false;
    }
    if (element.vatRateId !== this.commonService.defaultGuidValue) {
      element.vatRateError = false;
    }
    if (this.selectedModuleId === Modules.QuickEntry) {
      if (element.accountId !== this.commonService.defaultGuidValue) {
        element.accountIdError = false;
      }
    }
    if (
      !element.accountError &&
      !element.entryNumberError &&
      !element.vatRateError &&
      !element.shareClassTypeIdError &&
      !element.yearEndDateError &&
      !element.declarationDateError &&
      !element.paymentDateError &&
      !element.entryDateError &&
      !element.dueDateError &&
      !element.appointmentDateError &&
      !element.amountError &&
      !element.productNameError &&
      !element.shareholderError &&
      !element.priceError &&
      !element.accountIdError &&
      !element.invoiceDateError &&
      ! element.bookAccountError
    ) {
      if (
        this.selectedModuleId === Modules.TrialBalance ||
        this.selectedModuleId === Modules.OpeningBalance
      ) {
        const foundItem = this.importSuccessList.data.find(
          (item) => item.accountId === element.accountId
        );

        let foundIndex = this.importSuccessList.data.findIndex(
          (item) => item.accountId === element.accountId
        );

        if (foundItem) {
          this.dialog
            .open(ConfirmationBoxComponent, {
              data: {
                type: ConfirmationType.MergeTrailBalance,
                moduleId: Modules.TrialBalance,
                headerText: NotificationHeader.confirmation,
                detailText: NotificationDetails.mergeTrailBalanceText,
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                element.debit = +element.debit + +foundItem.debit ?? 0;
                element.credit = +element.credit + +foundItem.credit ?? 0;

                if (element.credit > element.debit) {
                  element.credit = +element.credit - +element.debit;
                  element.debit = 0;
                } else {
                  element.debit = +element.debit - +element.credit;
                  element.credit = 0;
                }

                this.importSuccessList.data.splice(foundIndex, 1);

                if (+element.credit + +element.debit > 0) {
                  if (!isFromSucessList) {
                    this.pushDataToSuccessList(element, index);
                  } else {
                    this.importSuccessList.data = [
                      ...this.importSuccessList.data,
                    ];
                  }
                } else {
                  this.notifier.error(
                    NotificationHeader.error,
                    NotificationTextMessage.trailBalanceZeroValueValidation
                  );
                }

                this.pushDataToSuccessList(element, index);
              }
            });
        } else {
          this.qtyChanges(index,true);
          setTimeout(() => {
            this.pushDataToSuccessList(element, index);
          }, 500);
          
        }
      } else {
      

        this.qtyChanges(index,true);
        setTimeout(() => {
          this.pushDataToSuccessList(element, index);
        }, 500);
      }
    }
  }

  pushDataToSuccessList(element: any, index: number): void {
    this.importSuccessList.data.push(element);
    this.importSuccessList.data = [...this.importSuccessList.data];
    this.importSuccessList = new MatTableDataSource(
      this.importSuccessList.data
    );

    this.importErrorList.data.splice(index, 1);
    this.importErrorList.data = [...this.importErrorList.data];
    this.importErrorList = new MatTableDataSource(this.importErrorList.data);
    this.calAmount();
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.moveToSuccessList
    );
  }

  checkValidErrorDate(
    element: any,
    index: number,
    isFromErrorList: boolean,
    selectedDate: any,
    importDateId: number
  ): void {
    const date: any = this.datepipe
      .transform(selectedDate, 'yyyy-MM-dd')
      ?.toString();

    const isDateError = this.commonService.selectedDateValidator(
      this.periodicDate,
      date
    );

    if (isDateError === null) {
      switch (importDateId) {
        case ImportDateName.invoiceDate:
          element.entryDateError = false;
          element.invoiceDateError = false;
          break;
        case ImportDateName.dueDate:
          element.dueDateError = false;
          break;
        case ImportDateName.billDate:
          element.entryDateError = false;
          break;
        case ImportDateName.creditNoteDate:
          element.entryDateError = false;
          break;
        case ImportDateName.debitNoteDate:
          element.entryDateError = false;
          break;
        case ImportDateName.fixedAssetDate:
          element.entryDateError = false;
          break;
        case ImportDateName.yearEndDate:
          element.yearEndDateError = false;
          break;
        case ImportDateName.declarationDate:
          element.declarationDateError = false;
          break;
        case ImportDateName.paymentDate:
          element.paymentDateError = false;
          break;
      }
      if (isFromErrorList) {
        this.checkValidElement(element, index);
      } else {
        this.moveDuplicatetoSuccessList(element, index);
      }
    } else if (isDateError.invalidFinancialYear) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.validPeriodDateMessage
      );
    } else if (isDateError.lockedFinancialYear) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.lockedAccoutingPeriodDateMessage
      );
    }
  }

  checkValidAppointmentDate(element, index: number, dateType: any): void {
    let appoinmentDate = '';
    switch (dateType) {
      case ImportDateName.appointmentDate:
        appoinmentDate = element.appointmentDate;
        break;
    }

    if (
      appoinmentDate !== null &&
      appoinmentDate !== undefined &&
      appoinmentDate !== ''
    ) {
      const selectedappoinmentDate: any = this.datepipe
        .transform(appoinmentDate, 'yyyy-MM-dd')
        ?.toString();
      this.store
        .dispatch(new CheckAppoinmentDate(selectedappoinmentDate))
        .subscribe((res) => {
          if (res.import.isAppoinmentDate) {
            switch (dateType) {
              case CheckValidDate.AppointmentDate:
                element.appointmentDateError = false;
                break;
            }
            this.checkValidElement(element, index);

            this.notifier.success(
              NotificationHeader.success,
              NotificationTextMessage.dateValidateSuccessfully
            );
          } else {
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.appointmentDateError
            );
          }
        });
    }
  }

  debitChanges(event: any, element: any): void {
    if (event.keyCode !== 9) {
      element.credit = null;
    }
  }
  creditChanges(event: any, element: any): void {
    if (event.keyCode !== 9) {
      element.debit = null;
    }
  }

  checkDebitCreditAmount(element: any, isDebit: boolean, index: number): void {
    if (isDebit) {
      element.credit = null;
      element.amountError = +element.debit > 0 ? false : true;
    } else {
      element.debit = null;
      element.amountError = +element.credit > 0 ? false : true;
    }
    this.checkValidElement(element, index);
  }

  checkCustomerDuplicate(element, index: number): void {
    let name = '';
    if (
      this.selectedModuleId === Modules.Shareholder ||
      this.selectedModuleId === Modules.Director
    ) {
      name = element.firstName;
    } else {
      name = element.contactName;
    }
    if (name !== '' && name !== '') {
      this.store
        .dispatch(
          new CheckCustomerSupplierDuplicate(
            name,
            element.email,
            this.selectedModuleId
          )
        )
        .subscribe((res) => {
          if (!res.common.isValidCustomerSupplier) {
            this.importSuccessList.data.push(element);
            this.importSuccessList.data = [...this.importSuccessList.data];
            this.importSuccessList = new MatTableDataSource(
              this.importSuccessList.data
            );

            this.importDuplicateList.data.splice(index, 1);
            this.importDuplicateList.data = [...this.importDuplicateList.data];
            this.importDuplicateList = new MatTableDataSource(
              this.importDuplicateList.data
            );

            this.notifier.success(
              NotificationHeader.success,
              NotificationTextMessage.moveToSuccessList
            );
          } else {
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.invoiceNoIsExist
            );
          }
        });
    }
  }

  checkEntryNumber(element, index: number): void {
    if (element.entryNumber !== '') {
      this.store
        .dispatch(
          new CheckEntryNumber(element.entryNumber, this.selectedModuleId)
        )
        .subscribe((res) => {
          if (!res.common.isValidEntryNo) {
            element.entryNumberError = false;
            this.moveDuplicatetoSuccessList(element, index);
          } else {
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.invoiceNoIsExist
            );
          }
        });
    }
  }

  checkErrorProductName(element, index: number): void {
    if (
      element.productName !== '' &&
      element.productName !== null &&
      element.productName !== undefined
    ) {
      element.productNameError = false;
      this.checkValidElement(element, index);
    }
  }

  checkErrorProductPrice(element, index: number): void {
    if (
      element.price !== null &&
      element.price !== undefined &&
      element.price > 0
    ) {
      element.priceError = false;
      this.checkValidElement(element, index);
    }
  }

  checkValidFile(): void {
    this.fileImportRequestModel = {
      file: this.selectedFile,
      step: ImportStep.Upload,
    };

    this.spinner.show();
    this.store
      .dispatch(
        new ImportModules(this.fileImportRequestModel, this.selectedModuleId)
      )
      .subscribe(
        () => {
          const details = this.store.selectSnapshot(ImportState.getImportData);
          this.spinner.hide();
          if (details.StatusCode === 0 || details.StatusCode !== undefined) {
            this.commonService.onFailure(details.Message);
          } else {
            if (details.errorValues.length == 0) {
              this.loadData(details);
            } else {
              const errorValuesList = details.errorValues;
              let errorValuesItem = '';

              for (let i = 0; i < errorValuesList.length; i++) {
                errorValuesItem += `<li>${errorValuesList[i]}</li>`;
              }
              this.dialog
                .open(ConfirmationBoxComponent, {
                  data: {
                    type: ConfirmationType.TrailBalanceErrorList,
                    moduleId: Modules.TrialBalance,
                    headerText: NotificationHeader.confirmation,
                    detailText:
                      NotificationDetails.trialBalanceErrorText +
                      "<ul class='mt-1'>" +
                      errorValuesItem +
                      '</ul>' +
                      "<p class='fw-bold font-size-16'>Are you sure you want to continue?</p>",
                  },
                })
                .afterClosed()
                .subscribe((result) => {
                  if (result) {
                    this.loadData(details);
                  }
                });
            }
          }
        },
        (error) => {
          this.commonService.onFailure(error.message);
          this.spinner.hide();
        }
      );
  }

  onChangeOpeningBranch(): void {
    this.importSuccessList.data.forEach((element) => {
      element.branchId = this.importForm.controls.openingBranchId.value;
    });

    this.importErrorList.data.forEach((element) => {
      element.branchId = this.importForm.controls.openingBranchId.value;
    });

    this.importDuplicateList.data.forEach((element) => {
      element.branchId = this.importForm.controls.openingBranchId.value;
    });
  }

  setData(details: any): void {
    this.displayedColumns = details.columns;
    this.displayedColumns2 = this.displayedColumns.map((x) => `f2_${x}`);
    details.successList.forEach(element => {
      element.accountData={id: element.bookAccountId,
  name: element.bookAccountName}
      });
    this.importSuccessList.data = details.successList;
    if (
      this.selectedModuleId === Modules.TrialBalance ||
      this.selectedModuleId === Modules.OpeningBalance
    ) {
      this.importSuccessList.data.forEach((element) => {
        element.branchId = this.importForm.controls.openingBranchId.value;
      });
    }

    this.showAccountName = false;
    this.showIncomeAccount = false;
    this.showDepositAccount = false;

    if (this.displayedColumns.some((x: string) => x === 'accountName')) {
      if (this.selectedModuleId === Modules.QuickEntry) {
        this.showAccountName = false;
      } else {
        this.showAccountName = false;
      }
    }

    if (this.displayedColumns.some((x: string) => x === 'incomeAccount')) {
      this.showIncomeAccount = true;
    }

    if (this.displayedColumns.some((x: string) => x === 'depositAccount')) {
      this.showDepositAccount = true;
    }
  

   details.errorList.forEach((element,index) => {
    element.accountData={id: element.bookAccountId,
name: element.bookAccountName};
this.vatRateList[index]=this.originalVatRateList;
    });
    this.importErrorList.data = details.errorList;
  
    if (
      this.selectedModuleId === Modules.TrialBalance ||
      this.selectedModuleId === Modules.OpeningBalance
    ) {
      this.importErrorList.data.forEach((element) => {
        element.branchId = this.importForm.controls.openingBranchId.value;
      });
    }
    details.duplicateList.forEach((element,index) => {
      element.accountData={id: element.bookAccountId,
  name: element.bookAccountName};
  this.vatRateList[index]=this.originalVatRateList;
      });
    this.importDuplicateList.data = details.duplicateList;
    if (
      this.selectedModuleId === Modules.TrialBalance ||
      this.selectedModuleId === Modules.OpeningBalance
    ) {
      this.importDuplicateList.data.forEach((element) => {
        element.branchId = this.importForm.controls.openingBranchId.value;
      });
    }
    this.myStepper.next();
    this.commonService.onSuccess(NotificationTextMessage.successMessage);
  }

  loadInvoiceDropdownValues(detail: any): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Turnover);

    const typeId = new Array<number>();
    typeId.push(
      IncomeTypeName.CharitableActivities,
      IncomeTypeName.Othertradingactivities
    );

    const entityId = new Array<number>();
    entityId.push(AccountEntity.Customer);

    const getInvoiceType = this.store.dispatch(
      new GetGroupAccountsBasedOnGroupId(groupNames, Modules.Invoices)
    );

    const getCustomer = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );

    const vatRateList = this.store.dispatch(new GetVatRateList());

    forkJoin([getInvoiceType, getCustomer, vatRateList]).subscribe((res) => {
      this.customerList = this.store.selectSnapshot(CommonState.accountList);

      const accountGroupList = this.store.selectSnapshot(
        CommonState.accountGroupList
      );

      //this.vatRateList = this.store.selectSnapshot(CommonState.getVatRate);
      this.originalVatRateList=this.store.selectSnapshot(CommonState.getVatRate);
      if (accountGroupList.length > 0) {
        this.accountGroupList = accountGroupList;
        this.tempAccountGroupList = this.accountGroupList;
      }
      this.setData(detail);
    });
    this.getBranchList();
  }
  async loadJournalDropdownValues(detail: any): Promise<void> {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Turnover);

    const typeId = new Array<number>();
    typeId.push(
      IncomeTypeName.CharitableActivities,
      IncomeTypeName.Othertradingactivities
    );

    const entityId = new Array<number>();
    entityId.push(AccountEntity.Customer);

    const getInvoiceType = this.store.dispatch(
      new GetGroupAccountsBasedOnGroupId(groupNames, Modules.Journals)
    );

    const getCustomer = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );

    const vatRateList = this.store.dispatch(new GetVatRateList());

    forkJoin([getInvoiceType, getCustomer, vatRateList]).subscribe((res) => {
      this.customerList = this.store.selectSnapshot(CommonState.accountList);

      this.originalVatRateList = this.store.selectSnapshot(CommonState.getVatRate);
    });
    this.getAccounts(detail);
  }
  async getAccounts(detail: any): Promise<void> {
    this.spinner.show();
    await this.store
      .dispatch(new GetJournalAccounts())
      .toPromise()
      .then((res) => {
        this.spinner.hide();
        this.accountGroupList = res.account.accounts;
        this.tempAccountGroupList = this.accountGroupList;
        this.setData(detail);
      });
  }

  loadBillDropdownValues(detail: any): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.CostOfSales);
    groupNames.push(GroupNames.SellingAndDistributionCosts);
    groupNames.push(GroupNames.AdministrativeExpenses);

    this.store
      .dispatch(new GetGroupAccountsBasedOnGroupId(groupNames, Modules.Purchase))
      .pipe(
        switchMap((res) => {
          if (res.common.accountGroupList.length > 0) {
            this.accountGroupList = res.common.accountGroupList;
            this.tempAccountGroupList = this.accountGroupList;
          }

          const param: ChartOfAccountListParam = {
            groupIds: [GroupNames.Creditors],
            typeIds: [
              ChartOfAccountCreditorsGroupTypeName.CreditorsLessThanOneYearVAT,
            ],
          };

          return this.store.dispatch(
            new GetGroupAccountsBasedOnGroupIdAndTypeId(param)
          );
        })
      )
      .subscribe((secondRes) => {
        if (secondRes.common.accountGroupList.length > 0) {
          this.accountGroupList = this.accountGroupList.concat(
            secondRes.common.accountGroupList
          );
          this.tempAccountGroupList = this.accountGroupList;
        }
      });

    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);

    const getSupplierList = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );
    const vatRateList = this.store.dispatch(new GetVatRateList());
    forkJoin([getSupplierList, vatRateList]).subscribe((res: any) => {
      this.supplierList = this.store.selectSnapshot(CommonState.accountList);
      const accountGroupList = this.store.selectSnapshot(
        CommonState.accountGroupList
      );

      this.originalVatRateList = this.store.selectSnapshot(CommonState.getVatRate);

      this.setData(detail);
    });
    this.getBranchList();
  }

  loadCreditNoteDropdownValues(detail: any): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Customer);

    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Turnover);

    const getCustomer = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );

    const vatRateList = this.store.dispatch(new GetVatRateList());

    const accountList = this.store.dispatch(
      new GetGroupAccountsBasedOnGroupId(groupNames, Modules.CreditNote)
    );

    forkJoin([getCustomer, vatRateList, accountList]).subscribe((res) => {
      this.customerList = this.store.selectSnapshot(CommonState.accountList);
      this.originalVatRateList = this.store.selectSnapshot(CommonState.getVatRate);
      const accountGroupList = this.store.selectSnapshot(
        CommonState.accountGroupList
      );

      if (accountGroupList.length > 0) {
        this.accountGroupList = accountGroupList;
        this.tempAccountGroupList = this.accountGroupList;
      }
      this.setData(detail);
    });
    this.getBranchList();
  }

  loadDebitNoteDropdownValues(detail: any): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);

    const groupNames = new Array<number>();
    groupNames.push(GroupNames.AdministrativeExpenses);

    const getSupplier = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );

    const vatRateList = this.store.dispatch(new GetVatRateList());

    const accountList = this.store.dispatch(
      new GetGroupAccountsBasedOnGroupId(groupNames, Modules.DebitNote)
    );

    forkJoin([getSupplier, vatRateList, accountList]).subscribe((res) => {
      this.supplierList = this.store.selectSnapshot(CommonState.accountList);
      this.originalVatRateList = this.store.selectSnapshot(CommonState.getVatRate);
      const accountGroupList = this.store.selectSnapshot(
        CommonState.accountGroupList
      );

      if (accountGroupList.length > 0) {
        this.accountGroupList = accountGroupList;
        this.tempAccountGroupList = this.accountGroupList;
      }
      this.setData(detail);
    });
    this.getBranchList();
  }

  loadFixedAssetDropdownValues(detail: any): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);

    const getSupplier = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );

    const vatRateList = this.store.dispatch(new GetVatRateList());

    const accountList = this.store.dispatch(new GetFixedAssetAdditionList());

    forkJoin([getSupplier, vatRateList, accountList]).subscribe((res) => {
      this.supplierList = this.store.selectSnapshot(CommonState.accountList);
      this.originalVatRateList = this.store.selectSnapshot(CommonState.getVatRate);
      const accountGroupList = this.store.selectSnapshot(
        AccountState.getFixedAssetAdditionList
      );

      if (accountGroupList.length > 0) {
        this.accountGroupList = accountGroupList;
        this.tempAccountGroupList = this.accountGroupList;
      }
      this.setData(detail);
    });
    this.getBranchList();
  }

  loadCustomerDropdownValues(detail: any): void {
    this.store
      .dispatch(new GetCountryList())
      .pipe(
        tap(() => {
          this.countryList = this.store.selectSnapshot(CountryState.getCountry);
          this.setData(detail);
        })
      )
      .subscribe();
  }

  loadTrailBalanceDropdownValues(detail: any, isOnLoad: boolean): void {
    this.store
      .dispatch(new GetJournalAccounts())
      .pipe(
        tap((res) => {
          if (res.account.accounts.length > 0) {
            this.accountGroupList = this.addGroupNameToListModels(
              res.account.accounts
            );
            this.tempAccountGroupList = this.accountGroupList;
          }
          if (isOnLoad) {
            this.setData(detail);
          }
        })
      )
      .subscribe();
  }

  loadProductDropdownValues(detail: any): void {
    this.store
      .dispatch(new GetVatRateList())
      .pipe(
        tap((res) => {
          this.originalVatRateList = res.common.vatRate;
          this.setData(detail);
        })
      )
      .subscribe();

    this.getBranchList();
  }

  loadDirectorDropdownValues(detail: any): void {
    const county = this.store.dispatch(new GetCountryList());

    forkJoin([county]).subscribe((res) => {
      this.countryList = this.store.selectSnapshot(CountryState.getCountry);
      this.setData(detail);
    });
  }

  loadShareholderDropdownValues(detail: any): void {
    const county = this.store.dispatch(new GetCountryList());

    forkJoin([county]).subscribe((res) => {
      this.countryList = this.store.selectSnapshot(CountryState.getCountry);
      this.setData(detail);
    });
  }

  loadCISDropdownValues(detail: any): void {
    this.store
      .dispatch(new GetSubcontractorList())
      .pipe(
        tap((res) => {
          this.subcontractorList = res.account.account;
          this.setData(detail);
        })
      )
      .subscribe();
  }

  loadDividendsDropdownValues(detail: any): void {
    const shareHolderName = this.store.dispatch(new GetAllShareHolder());

    const ShareClassType = this.store.dispatch(new GetAllShareClassType());

    forkJoin([shareHolderName, ShareClassType]).subscribe((res) => {
      this.shareHolderList = this.store.selectSnapshot(
        DividendsState.getShareholderName
      );

      this.shareClassTypeList = this.store.selectSnapshot(
        DividendsState.getShareClassTypeList
      );

      this.setData(detail);
    });
  }
  loadQuickEntryDropdownValues(detail: any): void {
    this.getCustomerList();
    this.getSupplierList();
    this.getReceiptAccountGroupList();
    this.getPaymentAccountGroupList();
    const groupNames = new Array<number>();
    const entityId = new Array<number>();

    this.importErrorList.data = detail.errorList;
    this.importErrorList.data.forEach((element) => {
      if (element.type === 1) {
        entityId.push(AccountEntity.Supplier);
        groupNames.push(GroupNames.CostOfSales);
        groupNames.push(GroupNames.SellingAndDistributionCosts);
        groupNames.push(GroupNames.AdministrativeExpenses);
        groupNames.push(GroupNames.InterestPayableAndSimilarCharges);
      } else if (element.type === 2) {
        entityId.push(AccountEntity.Customer);
        groupNames.push(GroupNames.Turnover);
        groupNames.push(GroupNames.OtherInterestReceivableAndSimilarIncome);
        groupNames.push(GroupNames.OtherOperatingIncome);
      }
    });

    const getInvoiceType = this.store.dispatch(
      new GetGroupAccountsBasedOnGroupId(groupNames, Modules.QuickEntry)
    );

    const getCustomerSupplierList = this.store.dispatch(
      new GetNonStandardAccountList(entityId)
    );

    const vatRateList = this.store.dispatch(new GetVatRateList());

    this.store.dispatch(new GetCustomBankAndCashAccount()).subscribe((res) => {
      this.paymentAccountList = res.common.customBankAndCashAccount;
    });

    forkJoin([getInvoiceType, getCustomerSupplierList, vatRateList]).subscribe(
      (res) => {
        this.customeOrSupplierList = this.store.selectSnapshot(
          CommonState.accountList
        );

        const accountGroupList = this.store.selectSnapshot(
          CommonState.accountGroupList
        );

        this.originalVatRateList = this.store.selectSnapshot(CommonState.getVatRate);

        if (accountGroupList.length > 0) {
          this.accountGroupList =
            this.addGroupNameToListModels(accountGroupList);
          this.tempAccountGroupList = this.accountGroupList;
        }
        this.setData(detail);
      }
    );
  }

  loadData(detail: any): void {
    if (this.selectedModuleId === Modules.Invoices) {
      this.loadInvoiceDropdownValues(detail);
    } else if (this.selectedModuleId === Modules.Purchase) {
      this.loadBillDropdownValues(detail);
    } else if (this.selectedModuleId === Modules.CreditNote) {
      this.loadCreditNoteDropdownValues(detail);
    } else if (this.selectedModuleId === Modules.DebitNote) {
      this.loadDebitNoteDropdownValues(detail);
    } else if (this.selectedModuleId === Modules.FixedAssets) {
      this.loadFixedAssetDropdownValues(detail);
    } else if (
      this.selectedModuleId === Modules.Customers ||
      this.selectedModuleId === Modules.Suppliers ||
      this.selectedModuleId === Modules.Shareholder
    ) {
      this.loadCustomerDropdownValues(detail);
      if (
        this.selectedModuleId === Modules.Customers ||
        this.selectedModuleId === Modules.Suppliers
      ) {
        this.openingbranchApiList(detail);
      }
    } else if (
      this.selectedModuleId === Modules.TrialBalance ||
      this.selectedModuleId === Modules.OpeningBalance
    ) {
      this.setPeriodDate();
      this.loadTrailBalanceDropdownValues(detail, true);
      this.openingbranchApiList(detail);
    } else if (this.selectedModuleId === Modules.Dividends) {
      this.loadDividendsDropdownValues(detail);
    } else if (this.selectedModuleId === Modules.Director) {
      this.loadDirectorDropdownValues(detail);
    } else if (this.selectedModuleId === Modules.Product) {
      this.loadProductDropdownValues(detail);
    } else if (this.selectedModuleId === Modules.Shareholder) {
      this.loadShareholderDropdownValues(detail);
    } else if (this.selectedModuleId === Modules.CIS) {
      this.loadCISDropdownValues(detail);
    } else if (this.selectedModuleId === Modules.QuickEntry) {
      this.loadQuickEntryDropdownValues(detail);
    } else if (this.selectedModuleId === Modules.Journals) {
      this.loadJournalDropdownValues(detail);
    }
  }

  openingbranchApiList(detail: any): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.openingbranchList = [];
          if (res.company.branch.length > 0) {
            this.openingbranchList = res.company.branch;
            this.importForm.controls.openingBranchId.setValue(
              this.openingbranchList[0].id
            );
            this.importForm.controls.openingBranchId.setValidators(
              Validators.required
            );
            this.importForm.controls.openingBranchId.updateValueAndValidity();
          } else {
            this.openingbranchList = [];
            this.importForm.controls.openingBranchId.clearValidators();
            this.importForm.controls.openingBranchId.updateValueAndValidity();
          }
        })
      )
      .subscribe();
  }

  checkSpecialChar(selectedFile: any): any {
    const format = /[!@#$%^&*:"<>?{}|]/;
    return format.test(selectedFile.name);
  }

  formatBytes(bytes: number): string {
    const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const factor = 1024;
    let index = 0;
    while (bytes >= factor) {
      bytes /= factor;
      index++;
    }
    return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
  }

  downloadTemplateFile(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetImportTemplate(this.selectedModuleId))
      .subscribe((res) => {});
  }

  onFileSelected(input: HTMLInputElement): void {
    const file = input.files![0];
    this.selectedFileName = file.name;
    if (file.size / 1024 / 1024 > 10) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.fileSizeExceeds
      );
      return;
    }

    if (this.checkSpecialChar(file)) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.filenameWithSpecialCharacter
      );
      return;
    }

    this.fileInfo = `${file.name} (${this.formatBytes(file.size)})`;

    if (!input.files || !input.files.length) {
      return;
    }

    this.selectedFile = input.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(input.files[0]);
    reader.onload = () => {
      this.url = reader.result;
    };
    this.step = ImportStep.Finish;
  }

  resetElementAccountList(element): void {
    this.accountGroupList = this.tempAccountGroupList;
    this.scrollIntoView(element.accountId);
  }

  onAccountSearch(event: any): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    this.accountGroupList = list;
  }

  onFileOpen(event): void {
    event.target.value = null;
  }

  getOptionText(option) {
    return option?.name;
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  ngOnDestroy(): void {
    this.importSubscription?.unsubscribe();
    this.cancelSubscription?.unsubscribe();
  }

  gotoAddCustomer(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Customers,
          headerText: `Add ${ModuleName.Customers}`,
          saveActionName: CreateAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.getCustomer();
        }
      });
  }

  getCustomer(): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Customer);

    this.store
      .dispatch(new GetNonStandardAccountList(entityId))
      .pipe(
        tap((res) => {
          this.customerList = res.common.accountList;
        })
      )
      .subscribe();
  }

  gotoAddSupplier(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Suppliers,
          headerText: `Add ${ModuleName.Suppliers}`,
          saveActionName: CreateAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.getSupplier();
        }
      });
  }

  getSupplier(): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);

    this.store
      .dispatch(new GetNonStandardAccountList(entityId))
      .pipe(
        tap((res) => {
          this.supplierList = res.common.accountList;
        })
      )
      .subscribe();
  }

  calAmount(): void {
    this.totalCredit = 0;
    this.totalDebit = 0;
    this.importSuccessList.data.forEach((x) => {
      this.totalDebit += +x.debit;
      this.totalCredit += +x.credit;
    });
  }

  countDifferenceOfDrCr(columnName: string) {
    columnName = columnName.replace('f2_', '');

    let returnValue;
    if (columnName == this.displayedColumns[0]) {
      returnValue = 'Difference';
    } else if (columnName == this.displayedColumns[1]) {
      if (this.totalCredit < this.totalDebit) {
        returnValue = this.calDifferenceAmount();
      } else {
        returnValue = '0';
      }
    } else if (columnName == this.displayedColumns[2]) {
      if (this.totalCredit > this.totalDebit) {
        returnValue = this.calDifferenceAmount();
      } else {
        returnValue = '0';
      }
    }
    return returnValue;
  }

  calDifferenceAmount(): any {
    this.TotalDifference = this.totalCredit - this.totalDebit;
    if (this.TotalDifference < 0) {
      this.TotalDifference = this.TotalDifference.toString().replace('-', '');
    }
    return this.TotalDifference;
  }

  gotoAddCustomAccount(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.AddCustomAccounts,
          headerText: `Add ${ModuleName.AddCustomAccounts}`,
          saveActionName: CreateCustomAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.loadTrailBalanceDropdownValues('', false);
        }
      });
  }

  getDepartmentParamaters(): any {
    const queryParams = {
      sortBy: this.departmentListParameters.sortBy,
      sortOrder: this.departmentListParameters.sortOrder,
      search: this.departmentListParameters.search,
    };

    return queryParams;
  }

  getBranchParamaters(): any {
    const queryParams = {
      sortBy: this.branchListParameters.sortBy,
      sortOrder: this.branchListParameters.sortOrder,
      search: this.branchListParameters.search,
    };

    return queryParams;
  }

  onBranchSearch(event: any): void {
    this.branchListParameters.search = event.target.value;
    this.getBranchList();
  }

  getBranchList(): void {
    this.store
      .dispatch(new GetBranchList(this.getBranchParamaters()))
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.importForm.controls.branchId.setValue(this.branchList[0].id);
            this.importForm.controls.branchId.setValidators(
              Validators.required
            );
            this.importForm.controls.branchId.updateValueAndValidity();
            this.getDepartmentListByBranch(this.branchList[0].id);
          } else {
            this.branchList = [];
            this.departmentList = [];
            this.importForm.controls.branchId.clearValidators();
            this.importForm.controls.branchId.updateValueAndValidity();
          }
        })
      )
      .subscribe();
  }

  onChangeBranch(branch): void {
    this.getDepartmentListByBranch(branch);
  }

  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(
          new GetDepartmentListByBranch(branch, this.getDepartmentParamaters())
        )
        .pipe(
          tap((res) => {
            this.departmentList = [];
            this.departmentList = res.company.departmentList;
            if (this.departmentList.length > 0) {
              this.importForm.controls.departmentId.setValue(
                this.departmentList[0].id
              );
              this.importForm.controls.departmentId.setValidators(
                Validators.required
              );
              this.importForm.controls.departmentId.updateValueAndValidity();
            } else {
              this.importForm.controls.departmentId.clearValidators();
              this.importForm.controls.departmentId.updateValueAndValidity();
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
      this.importForm.controls.departmentId.clearValidators();
      this.importForm.controls.departmentId.updateValueAndValidity();
    }
  }

  addGroupNameToListModels(data: any[]): any[] {
    let result: any = [];

    data.forEach((group) => {
      group.listModels.forEach((listModel) => {
        let listModelWithGroup = {
          ...listModel,
          groupName: group.groupName,
        } as any;
        result.push(listModelWithGroup);
      });
    });

    return result;
  }
  onClickAccountList(index: number): void {
    if (this.importErrorList.data[index].type === 1) {
      this.accountGroupList = this.paymentAccountGroupList;
    } else {
      this.accountGroupList = this.receiptAccountGroupList;
    }
  }
  getCustomerOrSupplierList(index: number): void {
    if (this.importErrorList.data[index].type === 1) {
      this.customeOrSupplierList = this.supplierList;
    } else {
      this.customeOrSupplierList = this.customerList;
    }
  }
  getCustomerList(): void {
    let quickEntryType = 2;
    this.spinner.show();
    this.store
      .dispatch(new GetCustomerSupplierList(quickEntryType))
      .subscribe((res) => {
        this.customerList = res.quickentry.customerSupplierList;
        this.customeOrSupplierList = this.customerList;
        this.spinner.hide();
      });
  }
  getSupplierList(): void {
    let quickEntryType = 1;
    this.spinner.show();
    this.store
      .dispatch(new GetCustomerSupplierList(quickEntryType))
      .subscribe((res) => {
        this.supplierList = res.quickentry.customerSupplierList;
        this.customeOrSupplierList = this.supplierList;
        this.spinner.hide();
      });
  }
  async getReceiptAccountGroupList(): Promise<void> {
    this.spinner.show();
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Turnover);
    groupNames.push(GroupNames.OtherInterestReceivableAndSimilarIncome);
    groupNames.push(GroupNames.OtherOperatingIncome);

    await this.store
      .dispatch(new GetGroupAccountsBasedOnGroupId(groupNames, Modules.Receipt))
      .toPromise()
      .then((res) => {
        if (res.common.accountGroupList.length > 0) {
          this.receiptAccountGroupList = this.addGroupNameToListModels(
            res.common.accountGroupList
          );
          this.accountGroupList = this.receiptAccountGroupList;
          this.spinner.hide();
        }
      });
  }
  async getPaymentAccountGroupList(): Promise<void> {
    this.spinner.show();
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.CostOfSales);
    groupNames.push(GroupNames.SellingAndDistributionCosts);
    groupNames.push(GroupNames.AdministrativeExpenses);
    groupNames.push(GroupNames.InterestPayableAndSimilarCharges);
    await this.store
      .dispatch(new GetGroupAccountsBasedOnGroupId(groupNames, Modules.Payment))
      .toPromise()
      .then((res) => {
        if (res.common.accountGroupList.length > 0) {
          this.paymentAccountGroupList = this.addGroupNameToListModels(
            res.common.accountGroupList
          );
          this.accountGroupList = this.paymentAccountGroupList;
          this.spinner.hide();
        }
      });
  }
  gotoAddCustomerOrSupplier(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.QuickEntry,
          headerText: `Add`,
          saveActionName: CreateAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.getCustomer();
          this.getSupplier();
        }
      });
  }

  onOptionSelected(i:number,isError:boolean) {
    const selectedValue = this.importErrorList.data[i]['accountData']; // Get the selected value from the event
    let accountName:string = selectedValue.name;
    if(isError){
        this.importErrorList.data[i]['vatRateId']='';
        this.importErrorList.data[i]['vatRateError']=true;
        if(this.importErrorList.data[i]['accountData']){
          this.importErrorList.data[i]['bookAccountError']=false;
        }
    }else{
        this.importDuplicateList[i]['vatRateId']='';
        this.importDuplicateList[i]['vatRateError']=true;
    }
   
      //this.productDetailArray.controls[i].get('vatAmount')?.setValue(0);
      // if (
      //   Object.values(AccountNumber).some(
      //     (constant) => constant === accountName
      //   )
      // ) {
      //   this.productDetailArray.controls.forEach((x, index) => {
      //     this.qtyChanges(index);
      //   });
      // }

      this.filterVatRatesBasedOnAccount(i,accountName);
      // if (!this.isVATChecked) {
      //   this.filterVatRatesBasedOnAccount(i);
      // } else {
      //   this.filterVatRatesBasedOnCISVAT();
      //   this.filterVatRatesBasedOnAccount(i);
      // }

  }

  filterVatRatesBasedOnAccount(i:number,accountName:string): void {

    // if(this.isVATChecked){
    //   this.vatRateList[i] = this.originalVatRateList.filter(
    //     (item) =>
    //       item.isStandard &&
    //       (item.rate === 5 || item.rate === 20) &&
    //       item.codeType === VatRateCodeType.Normal
    //   );
    

  
    // }else{
     

    if (AccountNumber.ACCOUNT_8215 === accountName) {
      this.vatRateList[i] = this.originalVatRateList.filter(
        (item) =>
          item.isStandard &&
          item.codeType === 5 &&
          item.rate === 0 &&
          item.abbreviation === VatName.Import_Vat
      );
    } else if (
      AccountNumber.ACCOUNT_1274 === accountName ||
      AccountNumber.ACCOUNT_1014 === accountName
    ) {
      this.vatRateList[i] = this.originalVatRateList.filter(
        (item) =>
          item.isStandard &&
          item.codeType === 3 &&
          ((item.rate === 0 && item.abbreviation === VatName.EU) ||
            (item.rate === 20 && item.abbreviation === VatName.EU_G))
      );
    } else if (
      AccountNumber.ACCOUNT_1275 === accountName ||
      AccountNumber.ACCOUNT_1016 === accountName
    ) {
      this.vatRateList[i] = this.originalVatRateList.filter(
        (item) =>
          item.isStandard &&
          item.codeType === 3 &&
          ((item.rate === 0 && item.abbreviation === VatName.EU) ||
            (item.rate === 20 && item.abbreviation === VatName.EU_S))
      );
    } else {
      this.vatRateList[i] = this.originalVatRateList.filter(
        (item) =>
          !(
            (item.rate === 0 &&
              item.codeType === 5 &&
              item.abbreviation === VatName.Import_Vat) ||
            (item.isStandard &&
              item.codeType === 3 &&
              ((item.rate === 0 && item.abbreviation === VatName.EU) ||
                (item.rate === 20 &&
                  (item.abbreviation === VatName.EU_G ||
                    item.abbreviation === VatName.EU_S))))
          )
      );
    }
  }
  //}

  isVATChecked:boolean;
  isVatIncuded:boolean;
    qtyChanges(index: number,isError:boolean): void {
      let disValue = 0;
      let vatName;
      let upvatRate;
      if(isError){
        const price = this.importErrorList.data[index]['price'];
        const quantity =this.importErrorList.data[index]['quantity'];
        let vatamount;
        let totalamount;
        let vatRate;
        let customVatAmount;

        if (
          this.importErrorList.data[index]['vatRateId'] !==
            undefined &&
            this.importErrorList.data[index]['vatRateId'] != null &&
            this.importErrorList.data[index]['vatRateId'] !== -1
        ) {
          const data = this.vatRateList[index].filter(
            (x) =>
              x.id === this.importErrorList.data[index]['vatRateId']
          );
    
          if (data.length > 0) {
            if (data[0].codeType === VatRateCodeType.Custom) {
             // this.productDetailArray.controls[index].get('vatAmount').enable();
              customVatAmount = Number(
                this.importErrorList.data[index]['vatAmount']
              );
            } else {
              //this.productDetailArray.controls[index].get('vatAmount').disable();
            }
    
           upvatRate = data[0].rate;
           vatName = data[0].name;
          } else {
            //this.productDetailArray.controls[index].get('vatRate')?.setValue(null);
          }
    
          let vatAmount;
          let postponedVatAmount;
          let amount;
          let vatRate;
          let totalNetAmount;
          if (this.isVatIncuded) {
            vatRate = vatRate === undefined ? 1 : vatRate;
            vatAmount = this.isVATChecked
              ? 0
              : !(data[0].codeType === VatRateCodeType.Custom)
              ? (
                  ((+price * +quantity - disValue) * vatRate) /
                  (100 + vatRate)
                ).toFixed(2)
              : customVatAmount;
    
            postponedVatAmount = this.isVATChecked
              ? !(data[0].codeType === VatRateCodeType.Custom)
                ? ((+price * +quantity - disValue) * vatRate) / 100
                : 0
              : 0;
            amount = (+quantity * +price - disValue).toFixed(2);
            totalNetAmount = (+price * +quantity - disValue - vatAmount).toFixed(2);
          } else {
            vatRate =
            upvatRate === undefined
                ? 1
                : vatName !== 'Import Reverse Charge(20.00%)' &&
                  vatName !== 'Reverse Charge(20.00%)'
                ? upvatRate
                : 0;
            vatAmount = this.isVATChecked
              ? 0
              : upvatRate === undefined ||
              upvatRate === null ||
              upvatRate === -1
              ? 0
              : !(data[0].codeType === VatRateCodeType.Custom)
              ? ((+price * +quantity - disValue) * vatRate) / 100
              : customVatAmount;
            postponedVatAmount = this.isVATChecked
              ? !(data[0].codeType === VatRateCodeType.Custom)
                ? ((+price * +quantity - disValue) * vatRate) / 100
                : 0
              : 0;
           
            amount = (+quantity * +price -(+disValue)) + vatAmount;
            this.importErrorList.data[index]['totalAmount']=amount.toFixed(2);
            this.importErrorList.data[index]['vatAmount']=vatAmount.toFixed(2);
           
          }
    
          
        } else {
          const amount = quantity * price - disValue;
    
         
        }
       
        }else{
          const price = this.importDuplicateList.data[index]['price'];
          const quantity =this.importErrorList.data[index]['quantity'];
        }


      
  
      
    }
  
}
