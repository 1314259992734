import { Injectable } from '@angular/core';
import { BankReconciliationModel } from '@app/core/Models';
import { BankReconciliationService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  GetAllBankAccount,
  GetBankDetail,
  GetBankReconciliation,
  SaveBankReconciliation,
} from './bank-reconciliation.action';

export class BankReconciliationStateInfo {
  getBankReconciliation?: Array<BankReconciliationModel>;
  totalRecord?: number;
  actions?: any;
  allBankAccount?: any;
}

@State<BankReconciliationStateInfo>({
  name: 'bankReconciliation',
  defaults: {},
})
@Injectable()
export class BankReconciliationState {
  constructor(private bankReconciliationService: BankReconciliationService) {}

  @Selector()
  static totalRecord(state: BankReconciliationStateInfo): number {
    return state.totalRecord ?? 0;
  }

  @Action(SaveBankReconciliation, { cancelUncompleted: true })
  saveBankReconciliation(
    { patchState }: StateContext<BankReconciliationStateInfo>,
    action: SaveBankReconciliation
  ) {
    return this.bankReconciliationService
      .saveBankReconciliation(action.params)
      .pipe(
        tap((res) => {
          patchState({});
        })
      );
  }

  @Action(GetBankReconciliation, { cancelUncompleted: true })
  getBankReconciliation(
    { patchState }: StateContext<BankReconciliationStateInfo>,
    action: GetBankReconciliation
  ) {
    return this.bankReconciliationService
      .getBankReconciliation(action.queryParams)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              getBankReconciliation: result.resultSet.data,
              totalRecord: result.paginationModel.totalItemCount,
              actions: result.actions,
            });
          },
        })
      );
  }

  @Action(GetAllBankAccount, { cancelUncompleted: true })
  getAllBankAccount(
    { patchState }: StateContext<BankReconciliationStateInfo>,
    action: GetAllBankAccount
  ) {
    return this.bankReconciliationService
      .getAllBankAccount(action.branchIds)
      .pipe(
        tap((res) => {
          patchState({
            allBankAccount: res,
          });
        })
      );
  }

  @Action(GetBankDetail, { cancelUncompleted: true })
  getBankDetail(
    { patchState }: StateContext<BankReconciliationStateInfo>,
    action: GetBankDetail
  ) {
    return this.bankReconciliationService.getBankDetail().pipe(
      tap((res) => {
        patchState({
          allBankAccount: res,
        });
      })
    );
  }
}
