import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ComponentName,
  ControlType,
  HeaderFilter,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
  ReportEnum,
  RoutingPath,
} from '@app/core/Enum';
import {
  ExportType,
  GlobalComponent,
  HeaderModel,
  MainListParameters,
} from '@app/core/Models';
import {
  CommonService,
  ModulePermission,
  NotificationService,
} from '@app/core/Services';
import {
  CommonState,
  GetBranchList,
  GetDepartmentListByBranch,
  GetDepartmentListByBranchIds,
  GetHeaderList,
  MenuState,
} from '@app/core/Store';
import { QuickJournalComponent } from '@app/modules/bank/quick-journal/quick-journal.component';
import { ShareComponent } from '@app/modules/reports';
import { Select, Store } from '@ngxs/store';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LossOfStockComponent } from '../loss-of-stock/loss-of-stock.component';

@Component({
  selector: 'app-header-actions',
  templateUrl: './header-actions.component.html',
  styleUrls: ['./header-actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderActionsComponent implements OnInit, OnDestroy {
  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(CommonState.headerList)
  headerList$: Observable<Array<HeaderModel>>;

  exportType = ExportType;
  controlType = ControlType;
  moduleEnum = Modules;
  reportEnum = ReportEnum;
  datepipe: DatePipe;
  @ViewChild('branch')
  public mulObj: MultiSelectComponent;
  public branchmode: string;
  public branchfilterPlaceholder: string;
  public branchFields: Object = { text: 'name', value: 'id' };
  public branchWaterMark: string = 'Branch-All';
  public branchpopHeight: string = '350px';

  public departmentmode: string;
  public departmentfilterPlaceholder: string;
  public departmentFields: Object = { text: 'name', value: 'id' };
  public departmentWaterMark: string = 'Department-All';
  public departmentpopHeight: string = '350px';

  selectedBranchValues: any[] = [];
  selectedDepartmentValues: any[] = [];
  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  @Output()
  readonly exportClick = new EventEmitter<any>();

  @Output()
  readonly dateChanged = new EventEmitter<any>();

  @Output()
  readonly getDataFromHeader = new EventEmitter<any>();

  departmentListParameters: MainListParameters = new MainListParameters();
  branchListParameters: MainListParameters = new MainListParameters();

  headerData: MainListParameters = new MainListParameters();

  branchId = Guid.EMPTY as unknown as Guid;
  departmentId = Guid.EMPTY as unknown as Guid;

  defaultValue: any[] = [
    { id: -1 },
    { id: -1 },
    { id: -1 },
    { id: -1 },
    { id: -1 },
  ];
  HeaderFilters = HeaderFilter;

  @Input() customId: any;

  @Input() dataSource: any;

  moduleId: number;

  @Input() removeBranchSelection;

  @Input() isCustom: boolean = true;

  @Input() showAccountDetails: boolean = false;

  @Input() isBankImport: boolean = false;
  @Input() isForeignBank: boolean = false;

  moduleName = '';

  @Input() transactionModuleId: number;
  @Input() parentModuleId: number;
  @Input() parentModuleName: string = '';

  @Output()
  readonly triggerDataFromHeader = new EventEmitter<any>();

  @Output()
  readonly triggerUpdateLossOfStock = new EventEmitter<any>();

  @Output()
  readonly triggerQuickAdd = new EventEmitter<any>();

  @Input() triggerResetBankModuleId: Observable<any>;

  @Input() listParameters: any;
  periodicDateList: any;
  periodicId = '-1';
  isSelected = true;
  isRowHighlighted: boolean = false;
  subscriptionRouting: Subscription;
  subscriptionHeaderList: Subscription;
  subscriptionAPIGetList: Subscription;
  headerList: any;
  accountGroupList: any[] = [];
  departmentList: any[] = [];
  branchList: any[] = [];
  private unsubscribe$ = new Subject<void>();
  notify: NotificationService;
  commonService: CommonService;
  store: Store;
  spinner: NgxSpinnerService;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  isImportChangePermission: boolean = true;
  isJournalChangePermission: boolean = true;
  constructor(
    private router: Router,
    private modulePermission: ModulePermission,
    private globalComponent: GlobalComponent,
    private _Activatedroute: ActivatedRoute,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private injector: Injector
  ) {
    this.notify = injector.get<NotificationService>(NotificationService);
    this.commonService = injector.get<CommonService>(CommonService);
    this.store = injector.get<Store>(Store);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
  }

  ngOnInit(): void {
    this.periodicDateList = this.globalComponent.getFinancialPeriod();
    if (
      !this.isBankImport &&
      this.transactionModuleId !== Modules.BankImportTransactionHistory
    ) {
      this.moduleId = this.parentModuleId;
      this.moduleName = this.parentModuleName;
      this.headerData.moduleId = Guid.EMPTY as unknown as Guid;
      this.commonService.defaultHeaderGuidValue =
        this.commonService.defaultGuidValue;
      if (this.moduleId !== undefined && this.moduleId !== null) {
        this.getHeaderList(this.moduleId);
      }

      this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
        (params) => {
          const currentUrl = this._Activatedroute.snapshot['_routerState'].url;
          if (currentUrl.includes('bank/cashEntry')) {
            this.moduleId = Modules.CashEntry;
            this.getHeaderList(this.moduleId);
          }
          if (currentUrl.includes('reports/management/accountPayableAgeing')) {
            this.moduleId = Modules.AccountPayableList;
          }
          if (currentUrl.includes('reports/management/advanceAgeingList')) {
            this.moduleId = Modules.AdvanceAgeingList;
          }
          if (currentUrl.includes('reports/additional/balanceSheetDetail')) {
            this.moduleId = Modules.BalanceSheetDetail;
          }
          if (
            currentUrl.includes('reports/customer/invoiceandreceivedpayments')
          ) {
            this.moduleId = Modules.InvoiceAndReceivedPayments;
          }
          if (currentUrl.includes('reports/customer/salesbyproductdetail')) {
            this.moduleId = Modules.SalesByProductDetail;
          }
          if (currentUrl.includes('reports/management/quotationbycustomer')) {
            this.moduleId = Modules.QuotationByCustomer;
          }
          if (currentUrl.includes('reports/customer/depositdetails')) {
            this.moduleId = Modules.DepositDetails;
          }
          if (currentUrl.includes('reports/supplier/chequedetail')) {
            this.moduleId = Modules.ChequeDetails;
          }
          if (
            currentUrl.includes('reports/supplier/purchaseandappliedpayments')
          ) {
            this.moduleId = Modules.PurchaseAndAppliedPayments;
          }

          if (params.keys.length > 0) {
            this.commonService.setParamId = atob(
              params.get('id')!
            ) as unknown as any;
          } else {
            this.commonService.setParamId = null;
          }
        }
      );

      this.getBranchList();

      this.triggerResetBankModuleId?.subscribe((data) => {
        this.headerData.moduleId = data;
      });

      this.branchmode = 'CheckBox';
      this.branchfilterPlaceholder = 'Search Branch';
      this.departmentmode = 'CheckBox';
      this.departmentfilterPlaceholder = 'Search Department';
    } else if (this.isBankImport) {
      this.moduleId = Modules.BankImportHistory;
      this.getHeaderList(this.moduleId);
    } else if (
      (this.transactionModuleId = Modules.BankImportTransactionHistory)
    ) {
      this.moduleId = Modules.BankImportTransactionHistory;
      this.getHeaderList(this.moduleId);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isJournalChangePermission = this.commonService.checkPermission(
        Modules.Tasks,
        Modules.Journals
      );
      this.isImportChangePermission = this.commonService.checkPermission(
        Modules.Manage,
        Modules.Import
      );
    }, 3000);
  }

  onLossOfStockClick(): void {
    this.dialog
      .open(LossOfStockComponent, {
        data: { id: Guid.EMPTY as unknown as Guid },
      })
      .afterClosed()
      .subscribe((result) => {
        this.triggerUpdateLossOfStock.emit(true);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$?.next();
    this.unsubscribe$?.complete();
    this.subscriptionRouting?.unsubscribe();
    this.subscriptionHeaderList?.unsubscribe();
    this.subscriptionAPIGetList?.unsubscribe();
  }

  isCallHeaderList(componentName: any): boolean {
    let isAllow;

    switch (componentName) {
      case ComponentName.MainListComponent:
      case ComponentName.DynamicGridListComponent:
      case ComponentName.BankReconciliationComponent:
      case ComponentName.ImportListComponent:
      case ComponentName.ReportListComponent:
      case ComponentName.NominalLedgerComponent:
      case ComponentName.ReportCreditorDebtorComponent:
      case ComponentName.BulkEdit:
      case ComponentName.VatSettings:
      case ComponentName.ReportListWithActionsComponent:
      case ComponentName.BankOverviewComponent:
      case ComponentName.fixedAssetRegister:
      case ComponentName.ProfitLossComponent:
      case ComponentName.ProfitLossComparisonComponent:
        isAllow = true;
        break;
      case false:
        isAllow = false;
        break;
    }

    return isAllow;
  }

  isOverView(id: any): boolean {
    let isOverView;

    switch (id) {
      case Modules.Dashboard:
      case Modules.IncomeOverview:
      case Modules.ExpenseOverview:
      case Modules.FixedAssetOverview:
        isOverView = true;
        break;
      case false:
        isOverView = false;
        break;
    }

    return isOverView;
  }

  addGroupNameToListModels(data: any[]): any[] {
    let result: any = [];

    data.forEach((group) => {
      group.listModels.forEach((listModel) => {
        let listModelWithGroup = {
          ...listModel,
          groupName: group.groupName,
        } as any;
        result.push(listModelWithGroup);
      });
    });

    return result;
  }

  getHeaderList(id): void {
    if (!this.isOverView(id)) {
      this.subscriptionAPIGetList = this.store
        .dispatch(new GetHeaderList(id))
        .subscribe((data) => {
          this.headerList = data.common.headerList;
          this.headerList.forEach((item) => {
            if (item.value.length > 0) {
              this.commonService.defaultHeaderGuidValue =
                this.commonService.setParamId !== null &&
                this.commonService.setParamId !== undefined
                  ? this.commonService.setParamId
                  : item.value[0].id;

              this.setDataFortheHeaderParamters(
                item.name,
                this.commonService.defaultHeaderGuidValue
              );

              if (item.name === HeaderFilter.Module) {
                this.headerData.moduleId =
                  this.commonService.defaultHeaderGuidValue;
              }
            }

            if (item.groupValue.length > 0) {
              this.commonService.defaultHeaderGuidValue =
                this.commonService.setParamId !== null &&
                this.commonService.setParamId !== undefined
                  ? this.commonService.setParamId
                  : item.groupValue[0].id;

              this.onDropdownChange(
                item.name,
                this.commonService.defaultHeaderGuidValue,
                false
              );
            }
          });

          if (
            this.moduleId !== Modules.TrialBalance &&
            this.moduleId !== Modules.BalanceSheet &&
            this.moduleId !== Modules.IncomeAndExpenditure &&
            this.moduleId !== Modules.PurchaseDayBook &&
            this.moduleId !== Modules.SalesDayBook &&
            this.moduleId !== Modules.AccountDetails
          ) {
            this.dataChangeFromHeader(false);
          }
          this.subscriptionAPIGetList.unsubscribe();
        });
    }
  }

  setDataFortheHeaderParamters(headerName: any, value: any): void {
    if (headerName === HeaderFilter.Filter) {
      this.headerData.filter = value;
    }
    if (headerName === HeaderFilter.Module) {
      this.headerData.moduleId = value;
    }
    if (headerName === HeaderFilter.SubModule) {
      this.headerData.subModuleId = value;
    }
  }

  sendMailParamsRequest: any;
  SendMail(moduleId: any): void {
    this.sendMailParams(4, moduleId);
    this.dialog
      .open(ShareComponent, {
        data: {
          sendMailParamsRequest: this.sendMailParamsRequest,
        },
      })
      .afterClosed()
      .subscribe();
  }
  sendMailParams(format: number, moduleId: any): void {
    const params = {
      startDate:
        this.datepipe
          .transform(this.headerData.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.headerData.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      filter: this.listParameters.filter,
      search: this.headerData.search,
      moduleId: this.headerData.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.headerData.subModuleId,
      pageNumber: this.listParameters.pageNumber,
      pageSize: 50000,
      format: format,
      isPrint: false,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      branchIds: this.headerData.branchIds ?? [],
      departmentIds: this.headerData.departmentIds ?? [],
      modId: moduleId,
    };
    this.sendMailParamsRequest = params;
  }

  dataChangeFromHeader(onLoad: boolean): void {
    const params = {
      startDate: this.headerData.startDate,
      endDate: this.headerData.endDate,
      search: this.headerData.search,
      moduleId: this.headerData.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.headerData.subModuleId,
      format: this.headerData.format,
      isPrint: this.headerData.isPrint,
      filter: this.headerData.filter,
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      sortBy: onLoad ? this.listParameters.sortBy : '',
      sortOrder: onLoad ? this.listParameters.sortOrder : true,
      branchIds: this.headerData.branchIds ?? [],
      departmentIds: this.headerData.departmentIds ?? [],
      type: this.listParameters.type,
    };
    this.isRowHighlighted = onLoad;
    this.triggerDataFromHeader.emit(params);
  }

  triggerDateChange(data: any): void {
    if (data.value === '-1') {
      this.headerData.startDate = '';
      this.headerData.endDate = '';
    } else {
      this.headerData.startDate = data.startDate;
      this.headerData.endDate = data.endDate;
    }
    this.dataChangeFromHeader(false);
  }

  import(): void {
    this.moduleId$.subscribe((moduleId) => {
      this.moduleId = moduleId;
    });

    if (this.moduleId !== Modules.BankDashboard) {
      const params = {
        moduleId: btoa(
          this.moduleId === Modules.SubcontractorYTDDetails
            ? Modules.CIS.toString()
            : this.moduleId.toString()
        ),
      };
      this.router.navigate([RoutingPath.Import, params]);
    } else {
      const params = { customId: btoa(this.customId.toString()) };
      this.router.navigate([RoutingPath.BankImport, params]);
    }
  }

  export(format: number): void {
    if (
      this.moduleId === Modules.Creditors ||
      this.moduleId === Modules.Debtors ||
      this.moduleId === Modules.NominalLedger
    ) {
      this.exportCreditorDebtor(format);
    } else {
      this.exportClick.emit(format);
    }
  }

  exportCreditorDebtor(format: number, isPrint: boolean = false): void {
    let totalRecordOfRecords = 0;

    this.totalRecord$.subscribe((x) => {
      totalRecordOfRecords = x;
    });

    if (
      format === ExportType.ExportAsExcelOutstandingBal ||
      format === ExportType.ExportAsCSVOutstandingBal ||
      format === ExportType.ExportAsPDFOutstandingBal
    ) {
      this.listParameters.filter = 0;
    } else if (
      (format === ExportType.Excel ||
        format === ExportType.CSV ||
        format === ExportType.PDF) &&
      (this.moduleId === Modules.Debtors || this.moduleId === Modules.Creditors)
    ) {
      this.listParameters.filter = -1;
    }

    if (format === ExportType.ExportAsExcelOutstandingBal) {
      format = ExportType.Excel;
    } else if (format === ExportType.ExportAsCSVOutstandingBal) {
      format = ExportType.CSV;
    } else if (format === ExportType.ExportAsPDFOutstandingBal) {
      format = ExportType.PDF;
    }

    if (totalRecordOfRecords > 0) {
      const params = {
        startDate:
          this.datepipe
            .transform(this.headerData.startDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        endDate: this.headerData.endDate,
        filter: this.listParameters.filter,
        search: this.headerData.search,
        moduleId: this.headerData.moduleId ?? (Guid.EMPTY as unknown as Guid),
        subModuleId: this.headerData.subModuleId,
        pageNumber: this.listParameters.pageNumber,
        pageSize: totalRecordOfRecords,
        format: format,
        isPrint: isPrint,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        branchIds: this.headerData.branchIds ?? [],
        departmentIds: this.headerData.departmentIds ?? [],
      };
      this.triggerDataFromHeader.emit(params);
    } else {
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noDataToExport
      );
    }
  }

  onDateChanged(data: any): void {
    const date = {
      startDate: data.startDate,
      endDate: data.endDate,
    };

    this.dateChanged.emit(date);
  }

  onDropdownChange(headerName: any, value: any, onLoad: boolean): void {
    this.setDataFortheHeaderParamters(headerName, value);
    this.listParameters.pageNumber = 1;
    this.dataChangeFromHeader(onLoad);
  }

  manualImportClick(): void {
    const params = { customId: btoa(this.customId.toString()) };
    this.router.navigate([RoutingPath.BankManualImport, params]);
  }

  bankEntryClick(isBankEntry): void {
    const params = {
      customId: btoa(this.customId),
    };

    if (isBankEntry) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = params;

      this.dialog
        .open(QuickJournalComponent, dialogConfig)
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            const params = {
              customId: this.customId,
            };
            this.triggerQuickAdd.emit(params);
          }
        });
    } else {
      this.router.navigate([RoutingPath.CashCoding, params]);
    }
  }

  getBranchParamaters(): any {
    const queryParams = {
      sortBy: this.branchListParameters.sortBy,
      sortOrder: this.branchListParameters.sortOrder,
      search: this.branchListParameters.search,
    };

    return queryParams;
  }

  getBranchList(): void {
    this.store
      .dispatch(new GetBranchList(this.getBranchParamaters()))
      .pipe(
        tap((res) => {
          this.branchList = [];
          res.company.branch.forEach((element) => {
            this.branchList.push(element);
          });
        })
      )
      .subscribe();
  }

  setBranchId(branchId): void {
    localStorage.setItem('branchId', branchId);
  }

  setDepartmentId(departmentId): void {
    localStorage.setItem('departmentId', departmentId);
  }

  getDepartmentParamaters(): any {
    const queryParams = {
      sortBy: this.departmentListParameters.sortBy,
      sortOrder: this.departmentListParameters.sortOrder,
      search: this.departmentListParameters.search,
    };

    return queryParams;
  }

  getDepartmentListByBranch(branch: any): void {
    this.store
      .dispatch(
        new GetDepartmentListByBranch(branch, this.getDepartmentParamaters())
      )
      .pipe(
        tap((res) => {
          this.departmentList = [];

          this.departmentList = res.company.departmentList;
        })
      )
      .subscribe();
  }

  getDepartmentListByBranchIds(): void {
    this.store
      .dispatch(new GetDepartmentListByBranchIds(this.selectedBranchValues))
      .subscribe((res) => {
        this.departmentList = [];
        if (res.company.departmentListbyIds.length > 0) {
          res.company.departmentListbyIds.forEach((element) => {
            if (this.departmentList.length === 0) {
              this.departmentList.push(element);
            } else {
              const isExist = this.departmentList.filter(
                (x) => x.id === element.id
              );
              if (isExist.length === 0) {
                this.departmentList.push(element);
              }
            }
          });
        }
      });
  }

  onDepartmentMultiSelectChange(event: any): void {
    this.selectedDepartmentValues = event.value;

    this.onChangeBranchDepartment();
  }

  onBranchMultiSelectChange(event: any): void {
    this.selectedBranchValues = event.value;
    if (this.selectedBranchValues.length > 0) {
      this.getDepartmentListByBranchIds();
    } else {
      this.departmentList = [];
      this.selectedDepartmentValues = [];
    }

    this.onChangeBranchDepartment();
  }

  onChangeBranch(branch): void {
    this.setBranchId(branch);
    if (
      branch !== undefined &&
      branch !== null &&
      branch !== this.commonService.defaultGuidValue
    ) {
      this.getDepartmentListByBranch(branch);
    } else {
      this.setBranchId(this.commonService.defaultGuidValue);
    }
    if (branch === this.commonService.defaultGuidValue) {
      this.headerData.branchIds = undefined;
      this.headerData.departmentIds = undefined;
    } else {
      this.departmentList = [];
      this.headerData.branchIds = branch;
    }
    this.dataChangeFromHeader(false);
  }

  onChangeDepartment(department): void {
    this.setDepartmentId(department);
    if (department === this.commonService.defaultGuidValue) {
      this.headerData.departmentIds = undefined;
    } else {
      this.headerData.departmentIds = department;
    }
    this.dataChangeFromHeader(false);
  }

  onChangeBranchDepartment(): void {
    this.headerData.departmentIds = this.selectedDepartmentValues ?? [];
    this.headerData.branchIds = this.selectedBranchValues ?? [];

    this.dataChangeFromHeader(false);
  }
  onComparitiveReportTypeChange(
    headerName: any,
    value: any,
    onLoad: boolean
  ): void {
    this.setDataFortheHeaderParamters(headerName, value);
    this.listParameters.pageNumber = 1;
    this.listParameters.type = value;
    this.dataChangeFromHeader(onLoad);
  }
}
