import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MaxLength, Modules } from '@app/core/Enum';
import {
  FileUploadRequestModel,
  FileUploadResponseModel,
  MultipleFileDownloadModel,
  ReceiptModel,
} from '@app/core/Models';
import { FileDowload, FileUpload } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-attachment-popup',
  templateUrl: './attachment-popup.component.html',
  styleUrls: ['./attachment-popup.component.scss'],
})
export class AttachmentPopupComponent implements OnInit {
  dragAreaClass: string;

  showTable = false;

  selectedFiles: FileList;
  attachmentForm: FormGroup;
  receiptData: ReceiptModel;
  maxLength = MaxLength;

  fileUploadResponse: Array<FileUploadResponseModel>;
  fileUploadRequest: FileUploadRequestModel;

  fileList: any[] = [];
  multipleFileDownloadModel: MultipleFileDownloadModel;
  acceptedFileTypes = ['.gif', '.jpg', '.jpeg', '.png'];

  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event: any) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();
    this.onFileSelected(event.dataTransfer.files);
  }

  constructor(
    private store: Store,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AttachmentPopupComponent>
  ) {}

  ngOnInit(): void {
    this.setForm();

    this.dragAreaClass = 'dragarea';
    if (this.data !== null && this.data !== undefined) {
      this.onEdit(this.data);
    }
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onEdit(data: any): void {
    this.fileUploadResponse = [];
    if (data.length > 0) {
      setTimeout(() => {
        data.forEach((element) => {
          this.fileUploadResponse.push({
            fileName: element.replace(/^.*[\\\/]/, ''),
            fileUrl: element,
          });
        });
        this.showTable = this.fileUploadResponse.length !== 0;
      }, 500);
    }
  }

  onFileSelected(event: any): void {
    if (event.target.files) {
      this.selectedFiles = event.target.files;
      Array.prototype.forEach.call(this.selectedFiles, (file) => {
        this.fileList.push(file);
      });
      this.saveFile();
    }
  }

  saveFile(): void {
    this.fileUploadRequest = {
      file: this.fileList,
      attachmentType: Modules.Invoices,
    };

    this.store
      .dispatch(new FileUpload(this.fileUploadRequest))
      .pipe(
        tap((data) => {
          this.fileUploadResponse = data.common.fileUploadRequestModel;
          if (this.data !== null) {
            this.data.forEach((element) => {
              this.fileUploadResponse.push({
                fileName: element.replace(/^.*[\\\/]/, ''),
                fileUrl: element,
              });
            });
          }
          this.showTable = data.common.fileUploadRequestModel.length !== 0;
        })
      )
      .subscribe();
  }

  onSave(): void {
    this.dialogRef.close(this.fileUploadResponse);
  }

  setForm(): void {
    this.attachmentForm = new FormGroup({
      attachment: new FormControl(''),
    });
  }

  removeFile(index: any): void {
    this.fileUploadResponse.splice(index, 1);
    this.fileList.splice(index, 1);
    this.showTable = this.fileUploadResponse.length !== 0;
  }

  dowloadFile(fileUrl: any): void {
    const filedata: any[] = [];

    filedata.push(fileUrl);

    this.multipleFileDownloadModel = {
      fileURLs: filedata,
    };

    this.store
      .dispatch(new FileDowload(this.multipleFileDownloadModel))
      .subscribe();
  }
}
