import { Injectable } from '@angular/core';
import { DividendsModel, SideListModel } from '@app/core/Models';
import { DividendsService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreDividends,
  CreateDividends,
  DeleteDividends,
  GetAllShareClassType,
  GetAllShareHolder,
  GetDataByDividendsId,
  GetDividendNumber,
  GetShareHolderDetailView,
} from './dividends.action';

export class DividendsStateInfo {
  dividendsData?: DividendsModel;
  dividends: Array<DividendsModel>;
  dividendsId?: Guid;
  sideListModel: Array<SideListModel>;
  shareClassTypeList?: Array<SideListModel>;
  shareHolderList?: Array<SideListModel>;
  shareHolderDetails?: any;
  isDividendsAdded?: boolean;
  dividendNumber?: any;
  isLastPage?: boolean;
}

@State<DividendsStateInfo>({
  name: 'dividends',
  defaults: {
    dividends: [],
    dividendsId: Guid.EMPTY as unknown as Guid,
    sideListModel: [],
    isDividendsAdded: false,
  },
})
@Injectable()
export class DividendsState {
  constructor(private dividendsService: DividendsService) {}

  @Selector()
  static getDividendsId(state: DividendsStateInfo) {
    return state.dividendsId;
  }

  @Selector()
  static getDividendsData(state: DividendsStateInfo) {
    return state.dividendsData;
  }

  @Selector()
  static getShareholderName(state: DividendsStateInfo) {
    return state.shareHolderList;
  }

  @Selector()
  static getShareClassTypeList(state: DividendsStateInfo) {
    return state.shareClassTypeList;
  }

  @Selector()
  static isLastPage(state: DividendsStateInfo) {
    return state.isLastPage;
  }

  @Action(CreateDividends)
  createDividends(
    { patchState }: StateContext<DividendsStateInfo>,
    action: CreateDividends
  ) {
    return this.dividendsService.createDividends(action.dividends).pipe(
      tap((res) => {
        patchState({
          dividendsId: res,
          isDividendsAdded: true,
        });
      })
    );
  }

  @Action(GetDataByDividendsId)
  getDataByDividendsId(
    { patchState }: StateContext<DividendsStateInfo>,
    action: GetDataByDividendsId
  ) {
    return this.dividendsService.getDataByDividendsId(action.dividendsId).pipe(
      tap((res) => {
        patchState({
          dividendsData: res,
        });
      })
    );
  }

  @Action(DeleteDividends)
  deleteDividends(
    { getState, patchState }: StateContext<DividendsStateInfo>,
    action: DeleteDividends
  ) {
    return this.dividendsService.deleteDividends(action.dividendsIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredDividends = state.dividends.filter(
          (item) =>
            !action.dividendsIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.dividendsIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          dividends: filteredDividends,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreDividends)
  archiveAndRestoreAccount(
    { getState }: StateContext<DividendsStateInfo>,
    action: ArchiveAndRestoreDividends
  ) {
    return this.dividendsService.archiveAndRestoreDividends(
      action.dividendsIds,
      action.isArchive
    );
  }

  @Action(GetAllShareClassType)
  getAllShareClassType(
    { patchState }: StateContext<DividendsStateInfo>,
    action: GetAllShareClassType
  ) {
    return this.dividendsService.getAllShareClassType().pipe(
      tap((res) => {
        patchState({
          shareClassTypeList: res,
        });
      })
    );
  }

  @Action(GetAllShareHolder)
  getAllShareHolder(
    { patchState }: StateContext<DividendsStateInfo>,
    action: GetAllShareHolder
  ) {
    return this.dividendsService.getAllShareHolder().pipe(
      tap((res) => {
        patchState({
          shareHolderList: res,
        });
      })
    );
  }

  @Action(GetShareHolderDetailView)
  getAllShareHolderDetailView(
    { patchState }: StateContext<DividendsStateInfo>,
    action: GetShareHolderDetailView
  ) {
    return this.dividendsService
      .getAllShareHolderDetailView(action.shareHolderId)
      .pipe(
        tap((res) => {
          patchState({
            shareHolderDetails: res,
          });
        })
      );
  }

  @Action(GetDividendNumber)
  getDividendNumber({ patchState }: StateContext<DividendsStateInfo>) {
    return this.dividendsService.getDividendNumber().pipe(
      tap((res) => {
        patchState({
          dividendNumber: res,
        });
      })
    );
  }
}
