<div class="d-flex h-100">
  <div
    class="form mb-4 wp-100"
    [ngClass]="istoggleSideList ? 'hide-sidelist' : ''"
  >
    <div class="content-body-wrapper">
      <div class="content-body">
        <p class="header-text d-flex justify-space-between align-items-center">
          <span> Contractor Settings </span>
          <button
            mat-button
            class="header-text-close d-flex align-items-center"
            disableRipple
            (click)="onCloseClick()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </p>
        <div class="alert-message" *ngIf="isViewPermission$ | async">
          <mat-icon class="material-icons-outlined">error_outline</mat-icon>
          {{ commonNotificationText.ViewOnlyAccessMessage }}
        </div>
        <div>
          <div class="accordion-toggle">
            <mat-icon
              (click)="toggleAccordion(); accordion.openAll()"
              *ngIf="isExpandAll"
              >keyboard_double_arrow_down
            </mat-icon>
            <mat-icon
              (click)="toggleAccordion(); accordion.closeAll()"
              *ngIf="!isExpandAll"
              >keyboard_double_arrow_up
            </mat-icon>
          </div>
          <mat-accordion [multi]="true">
            <mat-expansion-panel
              [expanded]="true"
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >description</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span> Business Information</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="content-body">
                <form [formGroup]="contractorSettingsForm">
                  <div class="d-flex gap-40">
                    <div class="form-field">
                      <p>
                        Business Name <span class="text-danger-300">*</span>
                      </p>
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="businessName"
                          autocomplete="off"
                          required
                          unicodeCharacter
                          maxlength="{{ maxLength.maxLength160 }}"
                        />
                      </mat-form-field>
                    </div>
                    <div class="form-field">
                      <p>Business Type</p>
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="businessType"
                          autocomplete="off"
                          [disabled]="true"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="d-flex gap-40">
                    <div class="form-field">
                      <p>UTR Number <span class="text-danger-300">*</span></p>
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="utrNumber"
                          autocomplete="off"
                          required
                          allowNumberOnly
                          maxlength="{{ maxLength.maxLength10 }}"
                          type="text"
                        />
                      </mat-form-field>
                    </div>
                    <div class="form-field">
                      <p>
                        PAYE Reference <span class="text-danger-300">*</span>
                      </p>
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="payeReference"
                          autocomplete="off"
                          required
                          maxlength="{{ maxLength.maxLength20 }}"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="d-flex gap-40">
                    <div class="form-field">
                      <p>
                        Accounts Office Reference
                        <span class="text-danger-300">*</span>
                      </p>
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="accountOfficeRef"
                          autocomplete="off"
                          required
                          maxlength="{{ maxLength.maxLength20 }}"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </form>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel
              [expanded]="true"
              [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >description</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span> HMRC Submission Credentials</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="content-body">
                <form [formGroup]="hmrcCredentialsForm">
                  <div class="d-flex gap-40">
                    <div class="form-field">
                      <p>User Id</p>
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="userId"
                          autocomplete="off"
                          type="text"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="d-flex gap-40">
                    <div class="form-field">
                      <p>Password</p>
                      <mat-form-field>
                        <input
                          matInput
                          formControlName="password"
                          autocomplete="off"
                          type="password"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </form>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
    <app-buttons
      *ngIf="!(isViewPermission$ | async)"
      [getModuleId]="moduleId"
      [saveButtonShow]="true"
      (triggerOnSaveClick)="onSave($event)"
      (triggerOnCancelClick)="onCancel(true)"
    >
    </app-buttons>
  </div>
</div>
