import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import {
  AccountEntity,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import { Currency, GlobalComponent, SideListModel } from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import {
  CommonState,
  CreateAccount,
  CurrencyState,
  GetAllBranchList,
  GetAllDepartmentListByBranch,
  GetClientCurrencyList,
  GetCurrencyList,
  GetDataByAccountId,
  GetFixedAssetAdditionList,
  GetNonStandardAccountList,
  GetNonStandardAccountListByBranch,
  GetRefreshCurrencyData,
} from '@app/core/Store';
import { QuickAddComponent } from '@app/modules';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-fixed-assests',
  templateUrl: './add-fixed-assests.component.html',
  styleUrls: ['./add-fixed-assests.component.scss'],
})
export class AddFixedAssestsComponent implements OnInit {
  fixedAssetsForm: FormGroup;
  departmentList: any[] = [];
  branchList: any[] = [];

  fixedAssetsTypeList: any[] = [];
  currencyList: Currency[];
  supplierList: SideListModel[];
  subscriptionRouting: Subscription;
  customerList: SideListModel[];
  defaultCurrency: Guid;
  periodicDate: any;
  disabledEditButton = true;
  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;
  isVatAmountIncluded = false;
  clientCurrencyList: any = [];
  isMultiCurrencySelected = false;
  currencyIds: any = [];
  selectedCurrencyId: number;
  refreshedCurrencyData: any = [];
  refreshedCurrencyRate: number;
  selectedCurrencySymbol: string;
  selectedCurrencyRate: number;
  isVatRegistered: any;

  @Input() triggerEditData: Observable<any>;

  @Output() triggerOnVATChange = new EventEmitter<boolean>();

  @Output() triggerAccountSelectedOption: EventEmitter<string> =
    new EventEmitter<string>();

  @Output()
  readonly supplierClick = new EventEmitter<any>();

  @Output()
  readonly isVatIncludedClick = new EventEmitter<any>();

  @Output()
  readonly branchChange = new EventEmitter<any>();

  @Output() triggerCurrencySelectedOption: EventEmitter<number> =
    new EventEmitter<number>();

  @Output() triggerCurrencyRefreshOption: EventEmitter<number> =
    new EventEmitter<number>();

  @Output() triggerSelectedCurrencyRate: EventEmitter<number> =
    new EventEmitter<number>();
  isSupplierChangePermission: boolean = true;
  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    public dialog: MatDialog,
    private globalComponent: GlobalComponent,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.selectedCurrencyRate = 1;
    this.selectedCurrencyId = 123;
    this.selectedCurrencySymbol = '£';
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    this.setForm();

    this.triggerEditData?.subscribe((data) => {
      this.getSupplier();
      this.getCurrencyList();
      this.getInvoiceType();
      setTimeout(() => {
        this.editfixedAssets(data);
      }, 1500);
    });

    this.fixedAssetsForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.fixedAssetsForm.touched;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isSupplierChangePermission = this.commonService.checkPermission(
        Modules.Contacts,
        Modules.Suppliers
      );
    }, 3000);
  }

  gotoAddSupplier(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Suppliers,
          headerText: `Add ${ModuleName.Suppliers}`,
          saveActionName: CreateAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.getSupplier(id);
        }
      });
  }

  gotoEditSupplier(): void {
    if (this.supplierList.length > 0) {
      this.dialog
        .open(QuickAddComponent, {
          data: {
            moduleId: Modules.Suppliers,
            headerText: `Edit ${ModuleName.Suppliers}`,
            id: this.fixedAssetsForm.controls.accountId.value,
            saveActionName: CreateAccount,
            getActionName: GetDataByAccountId,
          },
        })
        .afterClosed()
        .subscribe((id) => {
          if (!this.commonService.isEmpty(id)) {
            this.getSupplier(id);
          }
        });
    }
  }

  editfixedAssets(data): void {
    this.disabledEditButton = false;
    const x = this.supplierList.filter((x) => x.id === data.accountId);
    this.fixedAssetsForm.controls.billNo.disable();
    this.fixedAssetsForm.patchValue({
      billNo: data.entryNumber,
      invoiceDate: data.entryDate,
      currencyId: data.currencyId,
      accountId: x.length > 0 ? data.accountId : '',
      dueDate: data.dueDate,
      note: data.note,
      //invoiceType: data.bookAccountId,
      isVatPostponed: data.isVatPostponed,
      isVatInclude: data.isVatIncluded,
      branchId: data.branchId,
      departmentId: data.departmentId,
      currencyAmt: data.currencyRate,
    });
    if (data.currencyId != 123) {
      this.triggerSelectedCurrencyRate.emit(data.currencyRate);
      this.isMultiCurrencySelected = true;
      this.selectedCurrencyRate = this.clientCurrencyList.find(
        (x) => x.currencyId === data.currencyId
      )?.rate;
      this.selectedCurrencyId = this.clientCurrencyList.find(
        (x) => x.currencyId === data.currencyId
      )?.currencyId;
      this.selectedCurrencySymbol = this.clientCurrencyList.find(
        (x) => x.currencyId === data.currencyId
      )?.symbol;
    } else {
      this.isMultiCurrencySelected = false;
      this.selectedCurrencyRate = 1;
      this.selectedCurrencySymbol = '£';
    }
    if (data.isVatIncluded) {
      this.isVatIncludedClick.emit(data.isVatIncluded);
    }
    if (data.isVatPostponed) {
      this.fixedAssetsForm.controls.isVatInclude.setValue(false);
      this.fixedAssetsForm.controls.isVatInclude.disable();
      this.isVatAmountIncluded =
        this.fixedAssetsForm.controls.isVatInclude.value;
    } else {
      this.fixedAssetsForm.controls.isVatInclude.setValue(data.isVatIncluded);
      this.fixedAssetsForm.controls.isVatInclude.enable();
      this.isVatAmountIncluded =
        this.fixedAssetsForm.controls.isVatInclude.value;
    }
  }

  getInvoiceType(): void {
    this.store
      .dispatch(new GetFixedAssetAdditionList())
      .pipe(
        tap((res) => {
          this.fixedAssetsTypeList =
            this.commonService.addGroupNameToListModels(res.account.accounts);
        })
      )
      .subscribe();
  }

  getCurrency(): void {
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          this.fixedAssetsForm.controls.currencyId.setValue(
            this.defaultCurrency
          );
        })
      )
      .subscribe();
  }

  getSupplier(id?: Guid): void {
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);
    let actionName;
    if (this.branchList.length > 0) {
      actionName = GetNonStandardAccountListByBranch;
    } else {
      actionName = GetNonStandardAccountList;
    }

    this.store
      .dispatch(
        new actionName(entityId, this.fixedAssetsForm.controls.branchId.value)
      )
      .pipe(
        tap((res) => {
          this.supplierList = res.common.accountList;

          if (this.supplierList.length > 0) {
            if (id !== null && id !== undefined) {
              this.fixedAssetsForm.controls.accountId.setValue(id);
            }
          }
        })
      )
      .subscribe();
  }

  setForm(): void {
    this.fixedAssetsForm = new FormGroup({
      invoiceType: new FormControl(''),
      billNo: new FormControl(''),
      accountId: new FormControl('', [Validators.required]),
      invoiceDate: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      currencyId: new FormControl('', [Validators.required]),
      dueDate: new FormControl(new Date(), [
        datePickerValidator(this.periodicDate),
        Validators.required,
      ]),
      note: new FormControl(''),
      isVatPostponed: new FormControl(false),
      isVatInclude: new FormControl(false),
      branchId: new FormControl(''),
      departmentId: new FormControl(''),
      currencyAmt: new FormControl(''),
    });

    this.getInvoiceType();
    this.getCurrencyList();
    this.getBranchList(true);
  }

  isVatPostponedChecked(event: any): void {
    if (event.checked) {
      this.fixedAssetsForm.controls.isVatInclude.setValue(false);
      this.fixedAssetsForm.controls.isVatInclude.disable();
      this.isVatAmountIncluded =
        this.fixedAssetsForm.controls.isVatInclude.value;
      this.isVatIncludedClick.emit(this.isVatAmountIncluded);
    } else {
      this.fixedAssetsForm.controls.isVatInclude.enable();
    }
    this.triggerOnVATChange.emit(event.checked);
  }

  onCheckBoxSelected(event: any): void {
    this.isVatAmountIncluded = event.checked;
    this.isVatIncludedClick.emit(this.isVatAmountIncluded);
  }

  supplierChange(event: any): void {
    this.supplierClick.emit(event);
    if (event !== null && event !== undefined) {
      this.disabledEditButton = false;
    } else {
      this.disabledEditButton = true;
    }
  }

  getBranchList(isOnLoad): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.fixedAssetsForm.controls.branchId.setValue(
              this.branchList[0].id
            );
            this.fixedAssetsForm.controls.branchId.setValidators(
              Validators.required
            );
            this.fixedAssetsForm.controls.branchId.updateValueAndValidity();
            this.onChangeBranch(this.branchList[0].id, isOnLoad);
          } else {
            this.branchList = [];
            this.departmentList = [];
            this.getSupplier();
          }
        })
      )
      .subscribe();
  }

  onChangeBranch(branch, isOnLoad): void {
    this.getDepartmentListByBranch(branch);
    this.getSupplier();
    if (!isOnLoad) {
      this.fixedAssetsForm.controls.accountId.setValue('');
      this.branchChange.emit(branch);
    }
  }

  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .pipe(
          tap((res) => {
            this.departmentList = [];
            if (res.company.department.length > 0) {
              this.departmentList = res.company.department;
              this.fixedAssetsForm.controls.departmentId.setValue(
                this.departmentList[0].id
              );
              this.fixedAssetsForm.controls.departmentId.setValidators(
                Validators.required
              );
              this.fixedAssetsForm.controls.departmentId.updateValueAndValidity();
            } else {
              this.fixedAssetsForm.controls.departmentId.clearValidators();
              this.fixedAssetsForm.controls.departmentId.updateValueAndValidity();
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
      this.fixedAssetsForm.controls.departmentId.clearValidators();
      this.fixedAssetsForm.controls.departmentId.updateValueAndValidity();
    }
  }
  getCurrencyList(): void {
    this.store
      .dispatch(new GetClientCurrencyList())
      .pipe(
        tap(() => {
          this.clientCurrencyList = this.store.selectSnapshot(
            CurrencyState.getCurrencyList
          );
          if (this.clientCurrencyList.length > 0) {
            this.fixedAssetsForm.controls.currencyId.setValue(
              this.clientCurrencyList[0].currencyId
            );
            this.fixedAssetsForm.controls.currencyAmt.setValue(1);
          }
        })
      )
      .subscribe();
  }
  currencyChange(value: any): void {
    this.selectedCurrencyRate = this.clientCurrencyList.find(
      (x) => x.currencyId === value
    )?.rate;
    this.selectedCurrencyId = this.clientCurrencyList.find(
      (x) => x.currencyId === value
    )?.currencyId;
    this.selectedCurrencySymbol = this.clientCurrencyList.find(
      (x) => x.currencyId === value
    )?.symbol;
    this.triggerCurrencySelectedOption.emit(this.selectedCurrencyRate);
    if (value === 123) {
      this.isMultiCurrencySelected = false;
      this.fixedAssetsForm.controls.currencyAmt.setValue(1);
    } else {
      this.fixedAssetsForm.controls.currencyAmt.setValue(
        this.selectedCurrencyRate
      );
      this.isMultiCurrencySelected = true;
    }
  }
  onCurrencyRefresh(): void {
    this.currencyIds = [];
    this.currencyIds.push(this.selectedCurrencyId);

    this.store
      .dispatch(new GetRefreshCurrencyData(this.currencyIds))
      .subscribe((res) => {
        this.currencyIds = [];
        this.refreshedCurrencyData = res.currency.currencyList.data;

        this.refreshedCurrencyData.forEach((x) => {
          this.refreshedCurrencyRate = x.rate;
        });
        this.fixedAssetsForm.controls.currencyAmt.setValue(
          this.refreshedCurrencyRate
        );
        this.selectedCurrencyRate = this.refreshedCurrencyRate;
        this.triggerCurrencyRefreshOption.emit(this.selectedCurrencyRate);
      });
  }
  onCurrencyRateEntered() {
    this.selectedCurrencyRate = this.fixedAssetsForm.controls.currencyAmt.value;
    this.triggerCurrencyRefreshOption.emit(this.selectedCurrencyRate);
  }
}
