export class VATSettingsModel {
  vatRegistrationNo: string;
  vatRegistrationDate: Date;
  vatSchemeId: number;
  vatReturnTypeId: number;
  flatRate?: number | null;
  isDiscountPeriod?: boolean | null;
  fromDate?: string | null;
  toDate?: string | null;
  discountedRate?: number | null;
}
