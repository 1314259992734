export enum InvoiceType {
  Income = 1,
  Expenditure = 2,
  CreditNote = 3,
  DebitNote = 4,
  FixedAssets = 5,
  Receipt = 6,
  Payment = 7,
  Journal = 8,
  CISInvoice = 9,
}

export enum DiscountTypeName {
  Value = 'Discount Value',
  Percentage = 'Discount Percentage',
}

export enum DiscountType {
  Value = 1,
  Percentage = 2,
}
