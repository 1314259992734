import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BudgetingModel, SideListModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BudgetingService {
  constructor(private http: HttpClient) {}

  getStartPeriodList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}Budget/getBudgetPeriod`
    );
  }

  GetAllGroupTypeList(): Observable<Array<any>> {
    return this.http.get<Array<any>>(
      `${environment.apiVersionUrl}ChartOfAccount/getAllGroupType`
    );
  }

  getBudgetAccountList(param: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Budget/getAccounts`,
      JSON.stringify(param),
      headers
    );
  }

  saveBudgetDetail(param?: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Budget/budgetdetailAccount `,
      JSON.stringify(param),
      options
    );
  }

  getBudgetDetailList(param?: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Budget/budgetdetail/mainlist`,
      JSON.stringify(param),
      options
    );
  }

  createBudgeting(budget: BudgetingModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Budget/save`,
      JSON.stringify(budget),
      headers
    );
  }

  getDataByBudgetingId(budgetingId: Guid): Observable<BudgetingModel> {
    return this.http.get<BudgetingModel>(
      `${environment.apiVersionUrl}Budget/get/${budgetingId}`
    );
  }

  deleteBudgeting(budgetIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(budgetIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}Budget/delete`,
      options
    );
  }

  copyBudget(budgetIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}Budget/copyBudget`,
      JSON.stringify(budgetIds),
      headers
    );
  }

  archiveAndRestoreBudgeting(
    budgetIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}Budget/archive/${isArchive}`,
      JSON.stringify(budgetIds),
      options
    );
  }
}
