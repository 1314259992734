<div class="content-body">
  <div class="sidenav-table sidenav-table-input wp-100">
    <div class="mat-table-wrapper product-detail-table">
      <form [formGroup]="formCreditDetail">
        <ng-container formArrayName="creditDetailArray">
          <table
            aria-describedby="receipt-list"
            mat-table
            [dataSource]="tableDataSource"
          >
            <ng-container matColumnDef="invoiceNo">
              <th mat-header-cell *matHeaderCellDef class="w-20">Sr No.</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <div class="d-flex">
                  {{ i + 1 }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>
                Date <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field appearance="standard">
                  <input
                    matInput
                    formControlName="receiptDate"
                    [matDatepicker]="picker"
                    autocomplete="off "
                    (change)="commonService.validateDate($event)"
                    required
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-error
                  *ngIf="
                    commonService.checkDateValidation(
                      creditDetailArray.controls[i].get('receiptDate')
                    ).invalidFinancialYear
                  "
                >
                  {{ notificationMessage.validPeriodDateMessage }}
                </mat-error>

                <mat-error
                  *ngIf="
                    commonService.checkDateValidation(
                      creditDetailArray.controls[i].get('receiptDate')
                    ).lockedFinancialYear
                  "
                >
                  {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
                </mat-error>
              </td>
            </ng-container>
            <ng-container matColumnDef="invoice">
              <th mat-header-cell *matHeaderCellDef>{{ invoiceText }}</th>

              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="widthemail"
              >
                <ng-select
                  #invoiceReceipt
                  (focus)="invoiceReceipt.open()"
                  (valueChange)="invoiceReceipt.close()"
                  [disableOptionCentering]="true"
                  formControlName="invoiceReceipt"
                  dropdownPosition="top"
                  (change)="onInvoiceChange($event, i)"
                  appAutoFocusDirective
                >
                  <ng-option *ngFor="let tt of invoiceList" [value]="tt.id">
                    <div title="{{ tt.name }}">{{ tt.name }}</div>
                  </ng-option>
                </ng-select>
              </td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>Amount</th>
              <td
                class="purDueAmt"
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <button mat-button type="button" disableRipple matSuffix>
                  <span class="symbol-align">{{ amountSymbol }}</span>
                </button>
                <mat-form-field floatLabel="never" class="wp-80">
                  <input
                    allowDecimal
                    matInput
                    [value]="element.invoiceAmount"
                    autocomplete="off"
                    formControlName="invoiceAmount"
                    maxlength="{{ maxLength.maxLength10 }}"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="due">
              <th mat-header-cell *matHeaderCellDef>Due</th>
              <td
                class="purDueAmt"
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <button mat-button type="button" disableRipple matSuffix>
                  <span class="symbol-align">{{ dueAmountSymbol }}</span>
                </button>
                <mat-form-field floatLabel="never" class="wp-80">
                  <input
                    allowDecimal
                    matInput
                    [value]="element.dueAmount"
                    autocomplete="off"
                    formControlName="dueAmount"
                    maxlength="{{ maxLength.maxLength10 }}"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="allocationAmount">
              <th mat-header-cell *matHeaderCellDef>Amount To Allocation</th>
              <td
                class="purDueAmt"
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <button mat-button type="button" disableRipple matSuffix>
                  <span class="symbol-align">{{ dueAmountSymbol }}</span>
                </button>
                <mat-form-field floatLabel="never" class="wp-80">
                  <input
                    matInput
                    [value]="element.amount"
                    autocomplete="off"
                    formControlName="amount"
                    maxlength="{{ maxLength.maxLength10 }}"
                    allowDecimal
                    zeroNotAllowed
                    required
                    (input)="onAmountChange()"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="closeButton">
              <th mat-header-cell *matHeaderCellDef class="w-15"></th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="text-align-center"
              >
                <button
                  class="close-btn"
                  type="button"
                  mat-button
                  disableRipple
                  (click)="onDeleteReceiptDetails(i)"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayProductDetailsColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayProductDetailsColumns"
            ></tr>
          </table>
        </ng-container>
      </form>
    </div>
    <div class="mb-1 mt-1 d-flex justify-space-between">
      <div>
        <a class="link" href="javascript:void(0)" (click)="addNewRow()"
          >Add New Line</a
        >
        <a
          class="link"
          (click)="clearAllLines()"
          *ngIf="creditDetailArray.length > 0"
          href="javascript:void(0)"
          >Clear All Lines</a
        >
      </div>
    </div>
  </div>
</div>
