import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DiscountType, DiscountTypeName, Modules } from '@app/core/Enum';
import { MenuState } from '@app/core/Store';
import { AdvancePaymentComponent } from '@app/modules/common';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TransactionsPaymentComponent } from '../../transactions-payment/transactions-payment.component';
import { ProductDetailsComponent } from '../product-details.component';

@Component({
  selector: 'app-product-details-footer',
  templateUrl: './product-details-footer.component.html',
  styleUrls: ['./product-details-footer.component.scss'],
})
export class ProductDetailsFooterComponent implements OnInit {
  @Input()
  total: number = 0.0;

  @Input()
  balanceAmount: number;
  @Input() currencySymbol: string;
  @Input() grossTotal: number = 0;
  isPostponedVat: boolean = false;
  @Input() triggerOnVATChange: Observable<any>;
  @Input() postponedVatAmount: number = 0;
  @Output() triggerDiscountChange: EventEmitter<any> =
  new EventEmitter<any>();
  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;
  moduleId: number;

  discountTypeList = [
    { id: 1, name: DiscountTypeName.Value },
    { id: 2, name: DiscountTypeName.Percentage },
  ];
  discountType: any = DiscountType.Value;
  discountTypeEnum = DiscountType;
  discountValue: number = 0;
  discountedValue: any = 0;
  @Output() triggerGrossAmount: EventEmitter<number> =
    new EventEmitter<number>();

  @ViewChild(ProductDetailsComponent, { static: true })
  invoiceProductDetails;

  @ViewChild(TransactionsPaymentComponent, { static: true })
  invoiceReceiptDetails;

  @ViewChild(AdvancePaymentComponent, { static: true })
  advancePaymentDetails;
  moduleEnum = Modules;
  @Input() triggerDiscountData: Observable<any>;
  @Input() triggerNewProductAdded: Observable<any>;
  selectedCurrencyRate: number = 1;
  previousSelectedCurrencyRate: number;
  @Input() triggerCurrencySelectedOption: Observable<any>;
  @Input() triggerSelectedCurrencyRate: Observable<any>;
  @Input() isVatrateEqual: Observable<any>;
  isPreviousRate: boolean;
  isVatListSame:boolean=false;
  ngOnInit(): void {
    this.moduleId$.subscribe((x) => {
      if (x > 0) {
        this.moduleId = x;
      }
    });

    this.triggerOnVATChange?.subscribe((data) => {
      this.isPostponedVat = data;
    });

   

    this.triggerNewProductAdded?.subscribe((data) => {
      if (data) {
        this.total = data;
        this.grossTotal = data;
        setTimeout(() => {
          this.onDiscountChange();
        }, 1000);
      } else {
        this.total = data.toFixed(2);
      }
    });

    this.triggerSelectedCurrencyRate?.subscribe((data) => {
      if (data) {
        this.previousSelectedCurrencyRate = data;
      }
    });

    this.triggerCurrencySelectedOption?.subscribe((data) => {
      if (data) {
        this.selectedCurrencyRate = data;
        this.onCurrencyChanges();
        this.previousSelectedCurrencyRate = this.selectedCurrencyRate;
      }
    });

    // this.isVatrateEqual.subscribe((data) => {
   
    //   this.isVatListSame=data;
    //   if(!this.isVatListSame){
        
    //     this.discountType = DiscountType.Value;
    //     this.discountValue = 0;
    //   }
   
    // });

    this.triggerDiscountData?.subscribe((data) => {
     
      this.discountedValue = data?.discountValue;
      this.discountValue = data?.discountValue;
      this.discountType = data?.discountType;
    });
  }

  onCurrencyChanges(): void {
    if (this.previousSelectedCurrencyRate != undefined) {
      this.isPreviousRate = true;
    } else {
      this.isPreviousRate = false;
    }

    this.discountValue = this.isPreviousRate
      ? (this.discountValue / this.previousSelectedCurrencyRate) *
        this.selectedCurrencyRate
      : this.discountValue * this.selectedCurrencyRate;
    this.discountedValue = this.discountValue;
  }

  onDiscountChange(): void {
    
    
    if (this.discountType === DiscountType.Value) {
      this.grossTotal = this.total - this.discountValue;
      this.discountedValue = this.discountValue;
    
    } else {
      let calDis = 0;
      if (this.discountValue > 100) {
        this.discountValue = 0;
      } else {
        calDis = (this.total * this.discountValue) / 100;
      }

     this.grossTotal = this.total - calDis;
     this.discountedValue = calDis;
    }
   // let obj={value:this.discountValue,type:this.discountType};
    //this.triggerDiscountChange.emit(obj)
  }
}
