import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { DirectorShareholderModel } from '@app/core/Models';

import { CommonService } from '@app/core/Services';
import {
  CreateDirectorShareholder,
  GetDataByDirectorShareholderId,
  MenuState,
} from '@app/core/Store';
import { AddDirectorShareholderDetailComponent } from '@app/modules';
import { AddClosePopupComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-director-shareholder',
  templateUrl: './director-shareholder.component.html',
  styleUrls: ['./director-shareholder.component.scss'],
})
export class DirectorShareholderComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;
  triggerEditData: Subject<any> = new Subject<any>();

  @ViewChild(AddDirectorShareholderDetailComponent, { static: true })
  directorShareholderDetail;

  moduleId = 0;
  moduleName = '';
  directorShareholdeId = Guid.EMPTY as unknown as Guid;
  directorShareholderData: DirectorShareholderModel;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  subscriptionRouting: Subscription;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    public dialog: MatDialog,
    private location: Location,
    private store: Store,
    private spinner: NgxSpinnerService,
    public commonService: CommonService,
    private _Activatedroute: ActivatedRoute,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        const currentUrl = this._Activatedroute.snapshot['_routerState'].url;

        if (currentUrl.includes(RoutingPath.AddDirector)) {
          this.moduleId = Modules.Director;
          this.moduleName = ModuleName.Directors;
        } else {
          this.moduleId = Modules.Shareholder;
          this.moduleName = ModuleName.Shareholders;
        }

        if (params.keys.length > 0) {
          this.directorShareholdeId = atob(
            params.get('id')!
          ) as unknown as Guid;
          this.editDirectorShareholder();
        }
      }
    );
  }

  editDirectorShareholder(): void {
    this.store
      .dispatch(new GetDataByDirectorShareholderId(this.directorShareholdeId))
      .subscribe((res) => {
        this.directorShareholderDetail.directorShareholderForm.markAsUntouched();

        this.triggerEditData.next(
          res.directorShareholder.directorShareholderData
        );
      });
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.directorShareholdeId,
      isExit,
      this.moduleId === Modules.Director
        ? Modules.Director
        : Modules.Shareholder,
      this.moduleId === Modules.Director
        ? RoutingPath.AddDirector
        : RoutingPath.AddShareholder
    );
  }

  dataSubmitForDirector(): boolean {
    this.spinner.show();
    try {
      if (this.moduleId === Modules.Director) {
        this.directorShareholderData = {
          id: this.directorShareholdeId,
          firstName:
            this.directorShareholderDetail.directorShareholderForm.controls
              .firstName.value,
          lastName:
            this.directorShareholderDetail.directorShareholderForm.controls
              .lastName.value,
          address1:
            this.directorShareholderDetail.directorShareholderForm.controls
              .addressLine1.value,
          address2:
            this.directorShareholderDetail.directorShareholderForm.controls
              .addressLine2.value,
          isCreateAccount:
            this.directorShareholderDetail.directorShareholderForm.controls
              .isCreateAccount.value,
          directorCurrentAccount:
            this.directorShareholderDetail.directorShareholderForm.controls
              .directorCurrentAccount.value !== ''
              ? this.directorShareholderDetail.directorShareholderForm.controls
                  .directorCurrentAccount.value
              : null,
          isCreateLoanAccount:
            this.directorShareholderDetail.directorShareholderForm.controls
              .isCreateLoanAccount.value,
          directorLoanAccount:
            this.directorShareholderDetail.directorShareholderForm.controls
              .directorLoanAccount.value !== ''
              ? this.directorShareholderDetail.directorShareholderForm.controls
                  .directorLoanAccount.value
              : null,
          city: this.directorShareholderDetail.directorShareholderForm.controls
            .city.value,
          postalCode:
            this.directorShareholderDetail.directorShareholderForm.controls
              .postCode.value,
          address3:
            this.directorShareholderDetail.directorShareholderForm.controls
              .addressLine3.value,
          countryId:
            this.directorShareholderDetail.directorShareholderForm.controls
              .country.value,
          email:
            this.directorShareholderDetail.directorShareholderForm.controls
              .email.value,
          phone:
            this.directorShareholderDetail.directorShareholderForm.controls
              .phone.value,
          isShareHolder:
            this.directorShareholderDetail.directorShareholderForm.controls
              .shareholderId.value === '1'
              ? false
              : true,
          valuePerShare:
            this.directorShareholderDetail.directorShareholderForm.controls
              .valuePerShare.value === ''
              ? undefined
              : this.directorShareholderDetail.directorShareholderForm.controls
                  .valuePerShare.value,
          noOfShare:
            this.directorShareholderDetail.directorShareholderForm.controls
              .noOfShare.value === ''
              ? undefined
              : this.directorShareholderDetail.directorShareholderForm.controls
                  .noOfShare.value,
          total:
            this.directorShareholderDetail.directorShareholderForm.controls
              .total.value === ''
              ? undefined
              : this.directorShareholderDetail.directorShareholderForm.controls
                  .total.value,
          appointmentDate:
            this.directorShareholderDetail.directorShareholderForm.controls
              .appointmentDate.value,
          terminationDate:
            this.directorShareholderDetail.directorShareholderForm.controls
              .terminationDate.value === ''
              ? undefined
              : this.directorShareholderDetail.directorShareholderForm.controls
                  .terminationDate.value,
          notes:
            this.directorShareholderDetail.directorShareholderForm.controls
              .notes.value,
        };
      } else {
        this.directorShareholderData = {
          id: this.directorShareholdeId,
          firstName:
            this.directorShareholderDetail.directorShareholderForm.controls
              .firstName.value,
          lastName:
            this.directorShareholderDetail.directorShareholderForm.controls
              .lastName.value,
          address1:
            this.directorShareholderDetail.directorShareholderForm.controls
              .addressLine1.value,
          address2:
            this.directorShareholderDetail.directorShareholderForm.controls
              .addressLine2.value,
          city: this.directorShareholderDetail.directorShareholderForm.controls
            .city.value,
          postalCode:
            this.directorShareholderDetail.directorShareholderForm.controls
              .postCode.value,
          address3:
            this.directorShareholderDetail.directorShareholderForm.controls
              .addressLine3.value,
          countryId:
            this.directorShareholderDetail.directorShareholderForm.controls
              .country.value,
          email:
            this.directorShareholderDetail.directorShareholderForm.controls
              .email.value,
          phone:
            this.directorShareholderDetail.directorShareholderForm.controls
              .phone.value,
          valuePerShare:
            this.directorShareholderDetail.directorShareholderForm.controls
              .valuePerShare.value,
          noOfShare:
            this.directorShareholderDetail.directorShareholderForm.controls
              .noOfShare.value,
          total:
            this.directorShareholderDetail.directorShareholderForm.controls
              .total.value,
          shareType:
            this.directorShareholderDetail.directorShareholderForm.controls
              .shareType.value,
          notes:
            this.directorShareholderDetail.directorShareholderForm.controls
              .notes.value,
          isShareHolder: true,
        };
      }
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.directorShareholderDetail.directorShareholderForm.invalid) {
      this.commonService.addValidation(
        this.directorShareholderDetail.directorShareholderForm,
        this.renderer
      );
    } else {
      if (this.dataSubmitForDirector()) {
        this.store
          .dispatch(new CreateDirectorShareholder(this.directorShareholderData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (
      isCancelClick &&
      !this.commonService.isEmpty(this.directorShareholdeId)
    ) {
      this.editDirectorShareholder();
    } else {
      this.directorShareholdeId = Guid.EMPTY as unknown as Guid;
      this.directorShareholderDetail.ngOnInit();
    }
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(): void {
    this.istoggleSideList = !this.istoggleSideList;
  }
}
