import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentsModel, SideListModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(private http: HttpClient) {}

  createPayments(payments: PaymentsModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Payments/create`,
      JSON.stringify(payments),
      headers
    );
  }

  getDataByPaymentsId(paymentsId: Guid): Observable<PaymentsModel> {
    return this.http.get<PaymentsModel>(
      `${environment.apiVersionUrl}Payments/get/${paymentsId}`
    );
  }

  getTransactionTypeList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}TransactionType/all`
    );
  }
}
