import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaxLength } from '@app/core/Enum';
import { SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetAllBranchList,
  GetAllDepartmentListByBranch,
  GetStartPeriodList,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-budgeting',
  templateUrl: './add-budgeting.component.html',
  styleUrls: ['./add-budgeting.component.scss'],
})
export class AddBudgetingComponent implements OnInit {
  budgetingForm: FormGroup;
  isAddForm = true;

  startPeriodlist: SideListModel[] = [];
  maxLength = MaxLength;
  forPeriodList = this.commonService.forPeriodList;
  actualPeriodList = this.commonService.actualPeriodList;
  departmentList: any[] = [];
  branchList: any[] = [];

  @Output()
  readonly triggerFilterData = new EventEmitter<any>();

  @Input() triggerEditData: Observable<any>;

  constructor(
    private store: Store,
    private commonService: CommonService,
    public spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getStartPeriod();
    this.setForm();
    this.getBranchList();

    this.triggerEditData?.subscribe((data) => {
      setTimeout(() => {
        this.editBudgeting(data);
      }, 100);
    });

    this.budgetingForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.budgetingForm.touched;
    });
  }

  editBudgeting(data): void {
    this.isAddForm = false;
    this.budgetingForm.patchValue({
      id: data.id,
      budgetName: data.name,
      startPeriod: data.startPeriod,
      forPeriod: data.forPeriod,
      actual: data.actual,
      branchId: data.branchId,
      departmentId: data.departmentId,
    });

    this.onSelectionChange();
    if (data.branchId !== null && data.branchId !== undefined) {
      this.getDepartmentListByBranch(data.branchId);
    }
  }

  onSelectionChange(): void {
    if (!this.isAddForm && this.budgetingForm.controls.startPeriod.value !== null) {
      this.triggerFilterData.emit(this.budgetingForm.value);
    }
  }

  getStartPeriod(): void {
    this.spinner.show();
    this.store.dispatch(new GetStartPeriodList()).subscribe((res) => {
      this.spinner.hide();
      this.startPeriodlist = res.budgeting.startPeriodList;

      this.budgetingForm.controls.startPeriod.setValue(
        this.startPeriodlist[0].id
      );
      this.budgetingForm.controls.forPeriod.setValue(this.forPeriodList[1].id);
      this.budgetingForm.controls.actual.setValue(this.actualPeriodList[3].id);
    });
  }

  setForm(): void {
    this.budgetingForm = new FormGroup({
      id: new FormControl(Guid.EMPTY as unknown as Guid),
      budgetName: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      startPeriod: new FormControl('', Validators.required),
      forPeriod: new FormControl('', [Validators.required]),
      actual: new FormControl('', [Validators.required]),
      branchId: new FormControl(''),
      departmentId: new FormControl(''),
    });
  }
  getBranchList(): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.budgetingForm.controls.branchId.setValue(
              this.branchList[0].id
            );
            this.budgetingForm.controls.branchId.setValidators(
              Validators.required
            );
            this.budgetingForm.controls.branchId.updateValueAndValidity();
            this.getDepartmentListByBranch(this.branchList[0].id);
          } else {
            this.branchList = [];
            this.departmentList = [];
          }
        })
      )
      .subscribe();
  }

  onChangeBranch(branch): void {
    this.getDepartmentListByBranch(branch);
  }

  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .pipe(
          tap((res) => {
            this.departmentList = [];
            if (res.company.department.length > 0) {
              this.departmentList = res.company.department;
              this.budgetingForm.controls.departmentId.setValue(
                this.departmentList[0].id
              );
              this.budgetingForm.controls.departmentId.setValidators(
                Validators.required
              );
              this.budgetingForm.controls.departmentId.updateValueAndValidity();
            } else {
              this.budgetingForm.controls.departmentId.clearValidators();
              this.budgetingForm.controls.departmentId.updateValueAndValidity();
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
      this.budgetingForm.controls.departmentId.clearValidators();
      this.budgetingForm.controls.departmentId.updateValueAndValidity();
    }
  }
}
