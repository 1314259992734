<div class="content-body">
  <form [formGroup]="subscriptionForm">
    <div class="d-flex gap-40">
      <div class="form-field">
        <mat-radio-group
          aria-label="Select an option"
          formControlName="isActive"
        >
          <mat-radio-button value="1" class="mr-1">Active</mat-radio-button>
          <mat-radio-button value="0">Inactive</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field datepicker">
        <p>Start Date<span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="startDate"
            [matDatepicker]="startDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            tabindex="-1"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(subscriptionForm.get('startDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(subscriptionForm.get('startDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
      <div class="form-field datepicker">
        <p>End Date<span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="endDate"
            [matDatepicker]="endDatePicker"
            autocomplete="off"
            (change)="commonService.validateDate($event)"
            tabindex="-1"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(subscriptionForm.get('endDate'))
              .invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(subscriptionForm.get('endDate'))
              .lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>
    <div class="form-field textarea">
      <p>Notes</p>
      <mat-form-field>
        <textarea
          matInput
          formControlName="note"
          autocomplete="off"
          maxlength="{{ maxLength.maxLength2000 }}"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
