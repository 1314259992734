<div class="mat-dialog-wrapper" style="width: 600px">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">Credit Allocation</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onNoClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="d-flex justify-space-between align-items-center mb-2">
      <div>
        <div class="d-flex gap-10 mb-1">
          <p class="m-0 w-100 fw-bold">Customer Name</p>
          <p class="m-0">: {{allocationData.customerName}}</p>
        </div>
        <div class="d-flex gap-10 mb-1">
          <p class="m-0 w-100 fw-bold">Date</p>
          <p class="m-0">: {{allocationData.date| date: "dd-LLL-yyyy" }}</p>
        </div>
        <div class="d-flex gap-10">
          <p class="m-0 w-100 fw-bold">Ref No.</p>
          <p class="m-0">: {{allocationData.entryNumber}}</p>
        </div>
      </div>

      <div>
        <div
          class="d-flex gap-10 mb-1"
          *ngIf="creditNoteCurrencyId !== 123 && creditNoteCurrencyId !== null"
        >
          <p class="m-0 w-100 fw-bold font-size-18 white-space-nowrap">
            Due Amount:
          </p>
          <p class="m-0 font-size-18">
            {{creditNoteSymbol}} {{allocationData.foreignDueAmount |
            numberPipe}}
          </p>
        </div>
        <div class="d-flex gap-10">
          <p class="m-0 w-100 fw-bold font-size-18 white-space-nowrap">
            Due Amount:
          </p>
          <p class="m-0 font-size-18">
            £ {{allocationData.dueAmount | numberPipe}}
          </p>
        </div>
      </div>
    </div>
    <div class="mat-table-wrapper">
      <table
        mat-table
        [dataSource]="allocationData.invoiceList"
        aria-describedby="Allocation-Refund-Data"
      >
        <ng-container matColumnDef="invoiceNo">
          <th mat-header-cell *matHeaderCellDef>Invoice .No</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="clamp">{{ element.entryNumber }}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element">
            <div>{{ element.entryDate| date: "dd-LLL-yyyy" }}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount</th>
          <td mat-cell *matCellDef="let element">
            <span class="white-space-nowrap"
              >{{element.symbol}} {{ element.totalAmount | numberPipe }}</span
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="due">
          <th mat-header-cell *matHeaderCellDef>Due</th>
          <td mat-cell *matCellDef="let element">
            <span class="white-space-nowrap"
              >{{element.symbol}} {{ element.dueAmount | numberPipe }}</span
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="amountToAllocate">
          <th mat-header-cell *matHeaderCellDef class="w-100">
            Amount To Allocate
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field>
              <span matPrefix class="pl-10">{{element.symbol}}</span>
              <input
                allow_10_2_Decimal
                matInput
                autocomplete="off"
                [(ngModel)]="element.allocatedAmount"
                (blur)="onAllocationAmountChange(element)"
                [max]="element.dueAmount"
              />
            </mat-form-field>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayAllocationRefundColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayAllocationRefundColumns"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell"
            [ngClass]="'text-align-center'"
            [attr.colspan]="displayAllocationRefundColumns.length"
          >
            <span *ngIf="false">No Records Found</span>
          </td>
        </tr>
      </table>
    </div>
    <div class="mt-1 mb-10">
      <p class="fw-bold font-size-18">Refund</p>
      <div class="d-flex align-items-center gap-10">
        <div class="form-field datepicker mb-0">
          <p>Date</p>
          <mat-form-field appearance="standard">
            <input
              matInput
              [(ngModel)]="refundDate"
              [matDatepicker]="receiptDate"
              autocomplete="off"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="receiptDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #receiptDate></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-field mb-0">
          <p>Account</p>
          <ng-select
            #accountId
            [disableOptionCentering]="true"
            dropdownPosition="top"
            (focus)="accountId.open()"
            (valueChange)="accountId.close()"
            [(ngModel)]="refundAccountId"
            panelClass="mat-select-position"
            required
          >
            <ng-option
              *ngFor="let option of paymentAccountList"
              [value]="option.id"
            >
              <div title="{{ option.name }}">{{ option.name }}</div>
            </ng-option>
          </ng-select>
        </div>
        <div class="form-field mb-0">
          <p>Amount</p>
          <mat-form-field>
            <input
              allow_10_2_Decimal
              matInput
              autocomplete="off"
              [(ngModel)]="refundAmount"
              (blur)="onRefundAmountChange()"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSaveClick()"
    >
      Save
    </button>
    <button
      class="action-button secondary-button"
      mat-dialog-close
      type="button"
      mat-button
      disableRipple
      (click)="onNoClick()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
