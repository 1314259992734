import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NoteDataModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  constructor(private http: HttpClient, public commonService: CommonService) {}

  createScheduleNote(note: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}ScheduleNote/save`,
      this.commonService.trimObjectSpace(JSON.stringify(note)),
      headers
    );
  }

  getDataByNoteId(noteId: Guid): Observable<NoteDataModel> {
    return this.http.get<NoteDataModel>(
      `${environment.apiVersionUrl}ScheduleNote/get/${noteId}`
    );
  }

  deleteNote(noteIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(noteIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}ScheduleNote/delete`,
      options
    );
  }

  copyScheduleNote(noteIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}ScheduleNote/copyCreditNote`,
      JSON.stringify(noteIds),
      headers
    );
  }

  archiveAndRestoreNote(
    noteIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}ScheduleNote/archive/${isArchive}`,
      JSON.stringify(noteIds),
      options
    );
  }
}
