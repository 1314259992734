import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Account,
  BankAccount,
  ExtendConnectionTokenUserDataPayload,
  ManualImportItemModel,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BankDasboardService {
  constructor(private http: HttpClient) {}

  prepareUrl(returnUrl: string, bankAccountId: any): Observable<any> {
    const payload = {
      returnUrl: returnUrl,
      bankAccountId: bankAccountId,
    };

    const url = `${environment.apiVersionUrl}BankAccount/PrepareUrl`;
    return this.http
      .post(url, payload, { responseType: 'text' })
      .pipe(map((body) => body));
  }

  getBankAccount(): Observable<Array<BankAccount>> {
    return this.http.get<Array<BankAccount>>(
      `${environment.apiVersionUrl}BankAccount/GetAllBankAndCustomAccount`
    );
  }

  refreshBankAccountList(accountId: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}BankAccount/refreshAccountTransation/${accountId}`,
      headers
    );
  }

  deleteBankAccount(ids: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(ids),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}BankAccount/DeleteBank`,
      options
    );
  }

  getBankAccountTypeToAdd(param): Observable<HttpResponse<Array<Account>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}BankAccount/getBankAccounts`,
      param,
      httpOptions
    );
  }

  createBankAccount(bank: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}BankAccount/CreateBankAccount`,
      JSON.stringify(bank),
      headers
    );
  }

  setBankDefault(id: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}BankAccount/setDefault/${id}`,
      headers
    );
  }

  manualImportBank(
    manualImports: Array<ManualImportItemModel>,
    customId: number
  ): Observable<boolean> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<boolean>(
      `${environment.apiVersionUrl}BankEntry/manualImport/${customId}`,
      JSON.stringify(manualImports),
      headers
    );
  }

  reconfirmBankAccount(
    accountId: string,
    userData: ExtendConnectionTokenUserDataPayload
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}BankAccount/reconfirmAccount/${accountId}`,
      JSON.stringify(userData),
      headers
    );
  }
}
