<div class="content-body content-body-scroll">
  <div class="chart-header">
    <div class="d-flex align-items-center justify-space-between mb-1">
      <div class="d-flex align-items-center gap-20">
        <p class="text-primary font-size-20 fw-bold m-0">
          {{ dateRange.startDate | date: "dd-LLL-yyyy" }} to
          {{ dateRange.endDate | date: "dd-LLL-yyyy" }}
        </p>
      </div>
      <div class="d-flex align-items-end gap-10">
        <div class="d-flex flex-column" *ngIf="branchList.length > 0">
          <ejs-multiselect
            id="multiselect-checkbox"
            #branch
            [dataSource]="branchList"
            [placeholder]="branchWaterMark"
            [fields]="branchFields"
            [mode]="branchmode"
            [popupHeight]="branchpopHeight"
            [showDropDownIcon]="true"
            showSelectAll="true"
            [(ngModel)]="selectedBranchValues"
            [filterBarPlaceholder]="branchfilterPlaceholder"
            (change)="onBranchMultiSelectChange($event)"
          ></ejs-multiselect>
        </div>
        <div class="d-flex flex-column" *ngIf="departmentList.length > 0">
          <ejs-multiselect
            id="multiselect-checkbox"
            #department
            [dataSource]="departmentList"
            [placeholder]="departmentWaterMark"
            [fields]="departmentFields"
            [mode]="departmentmode"
            [popupHeight]="departmentpopHeight"
            [showDropDownIcon]="true"
            showSelectAll="true"
            [filterBarPlaceholder]="departmentfilterPlaceholder"
            [(ngModel)]="selectedDepartmentValues"
            (change)="onDepartmentMultiSelectChange($event)"
          ></ejs-multiselect>
        </div>
        <div class="content-header m-0">
          <div class="year-picker">
            <div class="d-flex year-picker-content">
              <div>
                <mat-form-field appearance="fill" class="w-150">
                  <mat-select
                    panelClass="myPanelClass"
                    [disableOptionCentering]="true"
                    [(ngModel)]="selectedYearValue"
                    (selectionChange)="onSelectionChange($event.value)"
                  >
                    <mat-option
                      *ngFor="let year of selectYear | useEnumValues"
                      [value]="year.key"
                      title="{{ year.value }}"
                    >
                      {{ year.value }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <form *ngIf="showCalender" [formGroup]="rangeFormGroup">
                <mat-form-field appearance="standard">
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      matInput
                      #start
                      (dateChange)="onSelectionChange(7)"
                      formControlName="start"
                    />
                    <input
                      matEndDate
                      matInput
                      #end
                      formControlName="end"
                      (dateChange)="onSelectionChange(7)"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matPrefix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="chart-wrapper d-flex gap-20 mt-2">
    <div class="card">
      <div
        class="d-flex align-items-center hr-bottom justify-space-between pb-10"
      >
        <h3 class="card-title">Sales Breakdown</h3>
        <div class="d-flex flex-column">
          <ejs-multiselect
            class="w-250"
            id="multiselect-checkbox"
            #incomeBreakdown
            [dataSource]="incomeBreakdownList"
            [placeholder]="breakdownWaterMark"
            [fields]="branchFields"
            [mode]="branchmode"
            [popupHeight]="branchpopHeight"
            [showDropDownIcon]="true"
            showSelectAll="true"
            [(ngModel)]="selectedSalesBreakValues"
            [filterBarPlaceholder]="breakdownPlaceholder"
            (change)="onBreakdownMultiSelectChange($event)"
          ></ejs-multiselect>
        </div>
        <!-- <ng-select
          #incomeBreakdown
          (focus)="incomeBreakdown.open()"
          (valueChange)="incomeBreakdown.close()"
          [disableOptionCentering]="true"
          [(ngModel)]="incomeBreakdownId"
          (change)="onIncomeBreakdownTypeChange()"
          panelClass="mat-select-position"
        >
          <ng-option
            *ngFor="let income of incomeBreakdownList"
            [value]="income.id"
          >
            <div class="clamp" title="{{ income.name }}">{{ income.name }}</div>
          </ng-option>
        </ng-select> -->
      </div>
      <app-overview-incomes-breckdown-v1
        [getModuleId]="moduleId"
        [triggerHeaderFilterForIncomeBreakdown]="
          triggerHeaderFilterForIncomeBreakdown
        "
        [reportList]="reportList"
      ></app-overview-incomes-breckdown-v1>
    </div>
    <div class="card">
      <div
        class="d-flex align-items-center hr-bottom justify-space-between pb-10"
      >
        <h3 class="card-title card-title-without-dropdown">
          Receivable Ageing Summary
        </h3>
      </div>
      <app-overview-summary-chart-v1
        [triggerHeaderFilter]="triggerHeaderFilter"
        [getModuleId]="moduleId"
      ></app-overview-summary-chart-v1>
    </div>
  </div>
  <div class="chart-wrapper d-flex gap-20 mt-2">
    <div class="card">
      <div
        class="d-flex align-items-center hr-bottom justify-space-between pb-10"
      >
        <h3 class="card-title card-title-without-dropdown">Sales Flow</h3>
      </div>
      <app-overview-flow-chart-v1
        [triggerHeaderFilter]="triggerHeaderFilter"
        [getModuleId]="moduleId"
      >
      </app-overview-flow-chart-v1>
    </div>
  </div>
</div>
