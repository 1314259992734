import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GroupNames, MaxLength } from '@app/core/Enum';
import { Currency, GlobalComponent, SideListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  CustomAccountState,
  GetAccountTypeList,
  GetAllBranchList,
  GetChartOfAccountGroupList,
  GetChartOfAccountTypeList,
  GetCurrencyList,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-custom-accounts',
  templateUrl: './add-custom-accounts.component.html',
  styleUrls: ['./add-custom-accounts.component.scss'],
})
export class AddCustomAccountsComponent implements OnInit {
  isReadOnly = false;

  customAccountId = Guid.EMPTY as unknown as Guid;
  customAccountForm: FormGroup;

  chartOfAccountGroupList: SideListModel[];
  chartOfAccountTypeList: SideListModel[];
  accountTypeList: SideListModel[];
  branchList: any[] = [];

  @Input() triggerEditCustomData: Observable<any>;
  @Input() triggereAddCustomAccount: Observable<any>;

  @Output()
  readonly accountGroupChange = new EventEmitter<any>();

  @Output()
  readonly accountTypeChange = new EventEmitter<any>();

  accountGroup: number;
  accountType: number;
  standardAccount: Guid;
  maxLength = MaxLength;
  isStandandAccount = true;
  currencyList: Currency[];
  defaultCurrency: Guid;
  isCashInHandGroup = false;
  isAddMode = true;
  isFixedAssestsGroup = false;
  constructor(
    private store: Store,
    private commonService: CommonService,
    private globalComponent: GlobalComponent
  ) {}

  ngOnInit(): void {
    this.defaultCurrency = this.globalComponent.getDefaultCurrency();
    this.setForm();
    this.getAccountTypeList();
    this.getChartOfAccountGroupList();
    this.getCurrency();

    this.triggerEditCustomData?.subscribe((data) => {
      this.isStandandAccount = false;
      this.customAccountForm.patchValue({
        accountName: data.name,
        accountCode: data.code,
      });

      this.accountGroup = data.chartOfAccountGroupId;
      this.accountType = data.chartOfAccountTypeId;
      this.standardAccount = data.chartOfAccountId;
      this.isReadOnly = true;

      this.editCustomAccount(data);
    });

    this.triggereAddCustomAccount?.subscribe((data) => {
      this.isStandandAccount = true;
      this.accountGroup = data.groupId;
      this.accountType = data.typeId;
      this.standardAccount = data.id;
      this.isReadOnly = true;

      this.editCustomAccount(data);
    });

    this.customAccountForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.customAccountForm.touched;
    });
  }

  getAccountTypeList(): void {
    this.store
      .dispatch(new GetAccountTypeList())
      .pipe(
        tap(() => {
          this.accountTypeList = this.store.selectSnapshot(
            CustomAccountState.getAccountType
          );
          this.accountTypeChange.emit(this.accountTypeList[0].id);
        })
      )
      .subscribe();
  }

  editCustomAccount(data): void {
    this.isAddMode = false;
    this.customAccountForm.controls.accountCode.disable();
    this.onChartOfAccountGroupChange(data.chartOfAccountGroupId);

    this.customAccountForm.patchValue({
      chartOfAccountGroup: this.accountGroup,
      chartOfAccountType: this.isFixedAssestsGroup
        ? data.name
        : this.accountType,
      bankAccountType: data.bankAccountTypeId,
      currency: data.currencyId,
      sortBranchCode: data.bankBranchCode,
      accountNo: data.bankAccountNumber,
      branchId: data.branchId,
      bankAccountIBANCode: data.bankAccountIBANCode,
    });
  }

  setForm(): void {
    this.customAccountForm = new FormGroup({
      accountName: new FormControl('', Validators.required),
      accountCode: new FormControl('', [
        Validators.required,
        this.commonService.whiteSpaceValidate,
      ]),
      chartOfAccountType: new FormControl(''),
      chartOfAccountGroup: new FormControl(''),
      sortBranchCode: new FormControl(''),
      accountNo: new FormControl(''),
      currency: new FormControl({ value: '', disabled: true }),
      bankAccountType: new FormControl(''),
      branchId: new FormControl(''),
      bankAccountIBANCode: new FormControl(''),
    });
  }

  getChartOfAccountTypeList(accountGroupId?: number): void {
    if (accountGroupId !== undefined) {
      this.store
        .dispatch(new GetChartOfAccountTypeList(accountGroupId))
        .pipe(
          tap((res) => {
            this.chartOfAccountTypeList = res.account.chartOfAccountTypeList;
            if (accountGroupId == 10) {
              this.chartOfAccountTypeList.splice(0, 1);
            }
            this.customAccountForm.controls.chartOfAccountType.setValue(
              this.isReadOnly
                ? this.accountType
                : this.chartOfAccountTypeList[0].id
            );
            this.checkIsCashInHandGroup();
          })
        )
        .subscribe();
    }
  }

  checkIsCashInHandGroup(): void {
    if (
      this.customAccountForm.controls.chartOfAccountGroup.value ===
      GroupNames.CashAtBankInHand
    ) {
      this.isCashInHandGroup = true;
      this.getBranchList();
    } else {
      this.isCashInHandGroup = false;
      this.branchList = [];
      this.customAccountForm.controls.branchId.clearValidators();
    }
  }

  onChartOfAccountGroupChange(accountGroupId?: number): void {
    this.accountGroupChange.emit(accountGroupId);
    this.customAccountForm.controls.chartOfAccountGroup.setValue(
      accountGroupId
    );

    this.checkIsCashInHandGroup();
    if (
      accountGroupId === GroupNames.FixedAssetsIntangibles ||
      accountGroupId === GroupNames.FixedAssetsTangibles ||
      accountGroupId === GroupNames.FixedAssetsInvestments ||
      accountGroupId === GroupNames.FixedAssetsInvestmentProperty ||
      accountGroupId === GroupNames.FixedAssetsLeased
    ) {
      this.setValidation(true);
    } else {
      this.getChartOfAccountTypeList(accountGroupId);
      this.setValidation(false);
    }
  }

  setValidation(isFixesAssest: boolean): void {
    if (isFixesAssest) {
      this.isFixedAssestsGroup = true;
      this.customAccountForm.clearValidators();
      this.customAccountForm.controls.chartOfAccountType.setValue(
        this.customAccountForm.controls.accountName.value
      );
      if (!this.isAddMode) {
        this.customAccountForm.controls.chartOfAccountGroup.disable();
      }
      this.customAccountForm.get('accountName')?.clearValidators();
      this.customAccountForm.get('accountCode')?.clearValidators();
      this.customAccountForm.get('accountName')?.updateValueAndValidity();
      this.customAccountForm.get('accountCode')?.updateValueAndValidity();
      this.customAccountForm.updateValueAndValidity();
    } else {
      this.isFixedAssestsGroup = false;
      this.customAccountForm.clearValidators();
      this.customAccountForm
        .get('accountName')
        ?.setValidators(Validators.required);
      this.customAccountForm
        .get('accountCode')
        ?.setValidators([
          Validators.required,
          this.commonService.whiteSpaceValidate,
        ]);
      this.customAccountForm.controls.chartOfAccountGroup.enable();
      this.customAccountForm.get('accountName')?.updateValueAndValidity();
      this.customAccountForm.get('accountCode')?.updateValueAndValidity();
      this.customAccountForm.updateValueAndValidity();
    }
  }

  getCurrency(): void {
    this.store
      .dispatch(new GetCurrencyList())
      .pipe(
        tap(() => {
          this.currencyList = this.store.selectSnapshot(
            CommonState.getCurrency
          );
          if (this.isStandandAccount && this.currencyList.length > 0) {
            this.customAccountForm.controls.currency.setValue(
              this.defaultCurrency
            );
          }
        })
      )
      .subscribe();
  }

  getChartOfAccountGroupList(): void {
    this.store
      .dispatch(new GetChartOfAccountGroupList())
      .pipe(
        tap((res) => {
          const dataList = res.account.chartOfAccountGroupList;
          this.chartOfAccountGroupList = [];

          dataList.forEach((element) => {
            this.chartOfAccountGroupList.push(element);
          });

          this.onChartOfAccountGroupChange(
            this.isReadOnly
              ? this.accountGroup
              : this.chartOfAccountGroupList[0].id
          );

          this.customAccountForm.controls.chartOfAccountGroup.setValue(
            this.isReadOnly
              ? this.accountGroup
              : this.chartOfAccountGroupList[0].id
          );
        })
      )
      .subscribe();
  }

  getBranchList(): void {
    this.store
      .dispatch(new GetAllBranchList())
      .pipe(
        tap((res) => {
          this.branchList = [];
          if (res.company.branch.length > 0) {
            this.branchList = res.company.branch;
            this.customAccountForm.controls.branchId.setValue(
              this.branchList[0].id
            );
            this.customAccountForm.controls.branchId.setValidators(
              Validators.required
            );
            this.customAccountForm.controls.branchId.updateValueAndValidity();
          } else {
            this.branchList = [];
          }
        })
      )
      .subscribe();
  }
}
