import { Injectable } from '@angular/core';
import { BridgingVatService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ImportVatBridging,
  SaveBridgingVAT,
  SetBridgingVatId,
} from './bridging-vat.action';

export class BridgingVatStateInfo {
  importData?: any;
  bridgingVatId?: Guid;
  vatDataId? = Guid.EMPTY as unknown as Guid;
}

@State<BridgingVatStateInfo>({
  name: 'bridgingVat',
})
@Injectable()
export class BridgingVatState {
  constructor(private bridgingVatService: BridgingVatService) {}

  @Selector()
  static getImportData(state: BridgingVatStateInfo) {
    return state.importData;
  }

  @Selector()
  static vatDataId(state: BridgingVatStateInfo): any {
    return state.vatDataId;
  }

  @Action(ImportVatBridging)
  importVatBridging(
    { patchState }: StateContext<BridgingVatStateInfo>,
    action: ImportVatBridging
  ) {
    return this.bridgingVatService
      .importVatBridging(action.fileImportRequestModel)
      .pipe(
        tap((res) => {
          patchState({
            importData: res,
          });
        })
      );
  }

  @Action(SaveBridgingVAT)
  saveBridgingVAT(
    { patchState }: StateContext<BridgingVatStateInfo>,
    action: SaveBridgingVAT
  ) {
    return this.bridgingVatService.saveBridgingVAT(action.data).pipe(
      tap((res) => {
        patchState({
          bridgingVatId: res,
          vatDataId:res,
        });
      })
    );
  }

  @Action(SetBridgingVatId)
  setBridgingVatId(
    { patchState }: StateContext<BridgingVatStateInfo>,
    action: SetBridgingVatId
  ) {
    return patchState({
      vatDataId: action.id,
    });
  }
}
