<div class="mat-dialog-wrapper view-explain-contacts">
  <h2 mat-dialog-title>Loss of Stock</h2>
  <mat-dialog-content>
    <div class="content-body-wrapper">
      <div class="content-body">
        <div class="alert-message" *ngIf="isViewPermission$ | async">
          <mat-icon class="material-icons-outlined">error_outline</mat-icon>
          {{ commonNotificationText.ViewOnlyAccessMessage }}
        </div>
        <div>
          <div class="accordion-toggle">
            <mat-icon
              (click)="toggleAccordion(); accordion.openAll()"
              *ngIf="isExpandAll"
              >keyboard_double_arrow_down
            </mat-icon>
            <mat-icon
              (click)="toggleAccordion(); accordion.closeAll()"
              *ngIf="!isExpandAll"
              >keyboard_double_arrow_up
            </mat-icon>
          </div>
          <mat-accordion [multi]="true">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >description</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Loss of Stock Details</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="sidenav-table sidenav-table-input wp-100">
                <div
                  class="content-body"
                  [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
                >
                  <form [formGroup]="stockFrom">
                    <div class="d-flex gap-40">
                      <div
                        class="form-field fund-name"
                        *ngIf="branchList.length > 0"
                      >
                        <p>Branch <span class="text-danger-300">*</span></p>
                        <ng-select
                          #branch
                          (focus)="branch.open()"
                          (valueChange)="branch.close()"
                          [disableOptionCentering]="true"
                          formControlName="branchId"
                          dropdownPosition="bottom"
                          (change)="onChangeBranch($event, false)"
                        >
                          <ng-option
                            *ngFor="let bl of branchList"
                            [value]="bl.id"
                          >
                            <div title="{{ bl.name }}">{{ bl.name }}</div>
                          </ng-option>
                        </ng-select>
                      </div>
                      <div class="form-field" *ngIf="departmentList.length > 0">
                        <p>Department <span class="text-danger-300">*</span></p>
                        <ng-select
                          #department
                          (focus)="department.open()"
                          (valueChange)="department.close()"
                          [disableOptionCentering]="true"
                          formControlName="departmentId"
                          dropdownPosition="bottom"
                        >
                          <ng-option
                            *ngFor="let dl of departmentList"
                            [value]="dl.id"
                          >
                            <div title="{{ dl.name }}">{{ dl.name }}</div>
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>
                    <div class="d-flex mr-40">
                      <div class="form-field">
                        <div class="form-field datepicker">
                          <p>Date <span class="text-danger-300">*</span></p>
                          <mat-form-field appearance="standard">
                            <input
                              matInput
                              formControlName="date"
                              [matDatepicker]="billDatePicker"
                              autocomplete="off"
                              required
                            />
                            <mat-datepicker-toggle
                              matSuffix
                              [for]="billDatePicker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #billDatePicker></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <div class="form-field textarea">
                      <p>Notes</p>
                      <mat-form-field>
                        <textarea
                          matInput
                          formControlName="notes"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength2000 }}"
                        ></textarea>
                      </mat-form-field>
                    </div>
                  </form>
                  <div class="form-field-title">
                    <p>Product Details</p>
                  </div>
                  <div class="mat-table-wrapper">
                    <form [formGroup]="formLossOfStock">
                      <ng-container formArrayName="lossOfStockArray">
                        <table
                          aria-describedby="account-list"
                          mat-table
                          [dataSource]="tableDataSource"
                        >
                          <ng-container matColumnDef="srNo">
                            <th mat-header-cell *matHeaderCellDef class="w-20">
                              Sr No.
                            </th>
                            <td
                              mat-cell
                              *matCellDef="let element; let i = index"
                              [formGroupName]="i"
                            >
                              <div class="d-flex">
                                {{ i + 1 }}
                              </div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="product">
                            <th mat-header-cell *matHeaderCellDef class="w-300">
                              Product
                              <span class="text-danger-300">*</span>
                            </th>
                            <td
                              mat-cell
                              *matCellDef="let element; let i = index"
                              [formGroupName]="i"
                              class="widthemail"
                            >
                              <ng-select
                                #prod
                                [disableOptionCentering]="true"
                                (valueChange)="prod.close()"
                                formControlName="product"
                                panelClass="mat-select-position"
                                required
                                appendTo="body"
                                (change)="onProductChange($event, i)"
                              >
                                <ng-option
                                  *ngFor="let option of trackedProductList"
                                  [value]="option.id"
                                >
                                  <div class="clamp" title="{{ option.name }}">
                                    {{ option.name }}
                                  </div>
                                </ng-option>
                              </ng-select>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="unitLost">
                            <th mat-header-cell *matHeaderCellDef>
                              Unit lost <span class="text-danger-300">*</span>
                            </th>
                            <td
                              mat-cell
                              *matCellDef="let element; let i = index"
                              [formGroupName]="i"
                            >
                              <mat-form-field floatLabel="never">
                                <input
                                  allowDecimal
                                  matInput
                                  autocomplete="off"
                                  formControlName="unitLost"
                                  maxlength="{{ maxLength.maxLength10 }}"
                                  required
                                  (change)="onUnitChanges(i)"
                                />
                              </mat-form-field>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="pricePerUnit">
                            <th mat-header-cell *matHeaderCellDef>
                              Price Per Unit
                              <span class="text-danger-300">*</span>
                            </th>
                            <td
                              mat-cell
                              *matCellDef="let element; let i = index"
                              [formGroupName]="i"
                            >
                              <mat-form-field>
                                <input
                                  allowDecimal
                                  matInput
                                  autocomplete="off"
                                  formControlName="pricePerUnit"
                                  maxlength="{{ maxLength.maxLength10 }}"
                                  required
                                  (change)="onUnitChanges(i)"
                                />
                              </mat-form-field>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef>Amount</th>
                            <td
                              mat-cell
                              *matCellDef="let element; let i = index"
                              [formGroupName]="i"
                            >
                              <mat-form-field>
                                <input
                                  allowDecimal
                                  matInput
                                  autocomplete="off"
                                  formControlName="amount"
                                  maxlength="{{ maxLength.maxLength10 }}"
                                />
                              </mat-form-field>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="closeButton">
                            <th
                              mat-header-cell
                              *matHeaderCellDef
                              class="w-50"
                            ></th>
                            <td
                              mat-cell
                              *matCellDef="let element; let i = index"
                              [formGroupName]="i"
                              class="text-align-center"
                            >
                              <div
                                class="d-flex align-items-center justify-content-center gap-10"
                              >
                                <button
                                  class="close-btn"
                                  type="button"
                                  mat-button
                                  disableRipple
                                  (click)="onDelete(i)"
                                  title="Delete"
                                >
                                  <mat-icon>delete_outline</mat-icon>
                                </button>
                              </div>
                            </td>
                          </ng-container>
                          <tr
                            mat-header-row
                            *matHeaderRowDef="displayColumns; sticky: true"
                          ></tr>
                          <tr
                            mat-row
                            *matRowDef="let row; columns: displayColumns"
                          ></tr>
                        </table>
                      </ng-container>
                    </form>
                  </div>
                  <div class="mb-1 mt-1 d-flex justify-space-between">
                    <div class="mt-1">
                      <a
                        class="link mr-1"
                        href="javascript:void(0)"
                        (click)="addNewRow()"
                        >Add New Line</a
                      >
                      <a
                        class="link mr-1"
                        *ngIf="lossOfStockArray.length > 0"
                        href="javascript:void(0)"
                        (click)="clearForm()"
                        >Clear All Lines</a
                      >
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="fw-bold mr-1 mt-0 text-align-right">
                      Total Loss
                      <span class="fw-normal ml-1"
                        >£ {{ totalLoss | numberPipe }}</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="text-primary"
                    >attachment</mat-icon
                  >
                  <div class="d-flex flex-column">
                    <span>Attachment</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''">
                <app-transactions-attachment></app-transactions-attachment>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="!(isViewPermission$ | async)">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave()"
    >
      Save
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onCancel()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
