export enum RoutingPath {
  Dashboard = 'dashboard',
  AddStandardAccounts = 'accounts/standard',
  AddCustomAccounts = 'accounts/custom/add',
  AddInvoice = 'sales/invoices/add',
  AddRecurringInvoice = 'sales/recurringInvoice/add',
  AddQuotation = 'sales/quotation/add',
  AddCreditNote = 'sales/creditNote/add',
  AddReceipt = 'sales/receipt/add',
  AddPurchase = 'Purchase/purchase/add',
  AddRecurringPurchase = 'Purchase/recurringPurchase/add',
  AddDebitNote = 'Purchase/debitNote/add',
  AddPayment = 'Purchase/payment/add',
  AddCapiscan = 'Purchase/capiscan/add',
  AddJournals = 'tasks/journals/add',
  AddBulkEdit = 'tasks/bulkEdit/add',
  AddActivity = 'tasks/activities/add',
  AddCustomers = 'contacts/customers/add',
  AddSuppliers = 'contacts/suppliers/add',
  AddBankTransfer = 'bank/transfer/add',
  AddCashEntry = 'bank/cashEntry/add',
  AddBankEntry = 'bank/bankEntry/add',
  AddFixedAssets = 'fixedAsset/fixedAssets/add',
  AddFixedAssetsRegister = 'fixedAsset/fixedAssetsRegister/add',
  FixedAssetsRegister = 'fixedAssets/FixedAssetsRegister',
  AddCompany = 'addCompany',
  CompanyPreference = 'manage/companypreference',
  EditCompany = 'editClient',
  AddEmailLog = 'logs/email/add',
  AddUserLog = 'logs/user/add',
  AddCPI = 'contact/CPI',
  AddProduct = 'sales/product/add',
  Manage = 'manage',
  IncomeOverview = 'sales/overview',
  ExpenditureOverview = 'Purchase/overview',
  BankDashboard = 'bank/overview',
  ReceiptList = 'sales/receipt',
  GetInvoiceList = 'sales/list',
  QuotationList = 'sales/quotation',
  PurchaseList = 'Purchase/purchase',
  RecurringPurchaseList = 'Purchase/recurringPurchase',
  PaymentList = 'Purchase/payment',
  CashEntryList = 'bank/cashEntry',
  BankEntryList = 'bank/bankEntry',
  MainList = 'accounts/standard',
  InoviceMainList = 'sales/invoices',
  CustomerList = 'contacts/customers',
  SupplierList = 'contacts/suppliers',
  RecurringInvoiceList = 'sales/recurringInvoice',
  CreditNoteList = 'sales/creditNote',
  DebitNoteList = 'Purchase/debitNote',
  JournalsList = 'tasks/journals',
  ActivityList = 'tasks/activities',
  ProductMainList = 'sales/product',
  FixedAssetsList = 'fixedAsset/fixedAssets',
  OpeningBalance = 'manage/openingBalance',
  AccountDetails = 'report/accountDetails',
  ManagementNote1 = 'reports/management/notes/note1',
  ManagementNote2 = 'reports/management/notes/note2',
  ManagementNote2_2 = 'reports/management/notes/note2-2',
  ManagementNote3 = 'reports/management/notes/note3',
  ManagementNote4 = 'reports/management/notes/note4',
  ManagementNote5 = 'reports/management/notes/note5',
  ManagementNote6 = 'reports/management/notes/note6',
  ManagementNote7 = 'reports/management/notes/note7',
  ManagementNote8 = 'reports/management/notes/note8',
  ManagementNote9 = 'reports/management/notes/note9',
  ManagementNote10 = 'reports/management/notes/note10',
  ManagementNote11 = 'reports/management/notes/note11',
  ManagementNote12 = 'reports/management/notes/note12',
  ManagementNote13 = 'reports/management/notes/note13',
  ManagementNote14 = 'reports/management/notes/note14',
  ManagementNote15 = 'reports/management/notes/note15',
  ManagementNote16 = 'reports/management/notes/note16',
  ManagementNote17 = 'reports/management/notes/note17',
  ManagementNote18 = 'reports/management/notes/note18',
  ManagementNote19 = 'reports/management/notes/note19',
  ManagementNote20 = 'reports/management/notes/note20',
  ManagementNote21 = 'reports/management/notes/note21',
  ManagementNote22 = 'reports/management/notes/note22',
  ManagementNote23 = 'reports/management/notes/note23',
  ManagementNote24 = 'reports/management/notes/note24',
  ManagementNote25 = 'reports/management/notes/note25',
  ManagementNote26 = 'reports/management/notes/note26',
  ManagementNote27_1 = 'reports/management/notes/note27-1',
  ManagementNote27_2 = 'reports/management/notes/note27-2',
  ManagementNote27_3 = 'reports/management/notes/note27-3',
  ManagementNote28 = 'reports/management/notes/note28',
  ManagementNote29 = 'reports/management/notes/note29',
  Import = 'manage/import',
  NominalLedger = 'reports/additional/nominalledger',
  SchedulesNotes = 'schedules/notes',
  AddSchedules = 'schedules/notes/add',
  MinutesOfMeeting = 'schedules/minutesofmeeting',
  AddMinutesOfMeeting = 'schedules/minutesofmeeting/add',
  DetailList = 'detailList',
  ViewVatDetails = 'mtd/viewVatDetails',
  VatSettings = 'mtd/vatSettings',
  AddVatCodes = 'mtd/vatSettings/add',
  SubmitVAT = 'mtd/submitvat',
  AddSubmitVAT = 'mtd/submitvat/add',
  Budgeting = 'tasks/budgeting',
  AddBudgeting = 'tasks/budgeting/add',
  IncludeExcludeDetail = 'mtd/submitvat/incExcDetail',
  AddShareholder = 'contacts/shareholder/add',
  AddDirector = 'contacts/director/add',
  AddDividends = 'tasks/dividends/add',
  BridgingVAT = 'mtd/bridgingvat',
  AddBridgingVAT = 'mtd/bridgingvat/add',
  AddSubcontractor = 'cis/subcontractor/add',
  AddCIS300 = 'cis/cis300/add',
  CIS300List = 'cis/cis300',
  AddCISInvoice = 'Purchase/cisInvoice/add',
  ReportAccountDetails = 'reports/additional/accountdetails',
  ReportAccountDetailsPermission = '/reports/additional/accountdetails',
  BankImport = 'bankImport',
  BankTransaction = 'bankTransaction',
  BankFeedBank = '/bankFeed',
  BankFeed = 'bank/bankFeed',
  CashCoding = 'bank/cashCoding',
  ToggleImport = '/manage/import',
  EditTrialBalance = 'manage/trialBalance',
  BankManualImport = 'manualImport',
  AutoBankReconcillation = 'bank/autoBankReconcillation',
  CustomizeReport = 'reports/additional/customizedReport',
  QuickEntryMainList = 'quickEntry',
  AddQuickEntry = 'quickEntry/add',
  GeneralDashboard = 'general-dashboard',
  ToggleGeneralDashboard = '/general-dashboard',
  ToggleGeneralManage = '/general-manage',
  Users = 'general-manage/users',
  AddUser = 'general-manage/users/add',
  Clients = 'general-manage/clients',
  AddClient = 'general-manage/clients/add',
  accessDenied = 'access-denied',
  Activity = '/activity'
}
