<div class="mat-dialog-wrapper preview-invoice">
  <mat-dialog-content>
    <form>
      <div class="main-contain">
        <div class="sidenav-header">
          <div class="d-flex align-items-center justify-space-between mb-10">
            <h2 class="m-0">
              <span>Preview Invoice</span>
            </h2>
            <div>
              <mat-icon class="close cursor-pointer" mat-dialog-close
                >close</mat-icon
              >
            </div>
          </div>
        </div>
      </div>

      <div class="content-body p-0">
        <img src="assets/images/invoice.jpg" alt="Preview Invoice" />
      </div>
    </form>
  </mat-dialog-content>
</div>
