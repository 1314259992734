import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { datePickerValidator } from '@app/core/Directives/datepicker-validator';
import { MaxLength, NotificationTextMessage } from '@app/core/Enum';
import { GlobalComponent, GroupListModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  GetGroupCustomBankAndCashAccount,
  GetRefreshCurrencyData,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-bank-transfer',
  templateUrl: './add-bank-transfer.component.html',
  styleUrls: ['./add-bank-transfer.component.scss'],
})
export class AddBankTransferComponent implements OnInit {
  bankTransferForm: FormGroup;
  maxLength = MaxLength;

  transferToGroupList: any;
  transferFromGroupList: any;
  periodicDate: any;

  @Input() triggerTransactionLogData: Observable<any>;
  @Input() triggerEditData: Observable<any>;
  notificationMessage = NotificationTextMessage;
  tempGroupList: GroupListModel[];

  private destroy$ = new Subject<void>();

  isBankImport = false;
  isCredit = false;
  ReceiptsTransferFromBankCurrencyId: number;
  isMultiCurrencyBank = false;
  selectedBankCurrencySymbol: string;
  currencyIds: any = [];
  refreshedCurrencyData: any = [];
  refreshedCurrencyRate: number;
  bankTransferAmount: number;
  defaultBankCurrencyId: number;
  defaultBankCurrencyRate: number;
  transferBankCurrencySymbol: string;
  paymentTransferToBankCurrencyId: number;
  constructor(
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    private globalComponent: GlobalComponent,
    private store: Store,
    private renderer: Renderer2,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.periodicDate = this.globalComponent.getFinancialPeriod();
    this.setForm();

    this.triggerTransactionLogData
      .pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isBankImport = true;
        this.isCredit = data.isCredit;
        this.bankTransferAmount = data.amount;
        this.defaultBankCurrencyId = data.currencyId;
        this.defaultBankCurrencyRate = data.currencyRate;
        this.selectedBankCurrencySymbol = data.symbol;
        this.transferBankCurrencySymbol = data.symbol;
        if (data.currencyId !== 123) {
          this.isMultiCurrencyBank = true;
          this.selectedBankCurrencySymbol = data.symbol;
          this.bankTransferForm.controls.currencyRate.setValue(
            data.currencyRate
          );
          this.bankTransferForm.controls.convertedGbpAmnt.setValue(
            data.amount.toFixed(2)
          );
        } else {
          this.isMultiCurrencyBank = false;
          this.bankTransferForm.controls.currencyRate.setValue(1);
        }
        if (!data.isCredit) {
          let transferTo;
          this.transferToGroupList.forEach((items) => {
            const transferToId = items.listModels.find(
              (x: any) => x.id === data.accountId
            );
            if (transferToId !== null && transferToId !== undefined) {
              transferTo = transferToId;
            }
          });
          if (data.currencyId === 123) {
            this.transferFromGroupList.forEach((items, index) => {
              this.transferFromGroupList[index].listModels =
                items.listModels.filter((item) => item.id !== data.accountId);
            });
          } else {
            this.transferFromGroupList.forEach((items, index) => {
              this.transferFromGroupList[index].listModels =
                items.listModels.filter((item) => item.currencyId === 123);
            });
          }
          this.bankTransferForm.controls.transferTo.setValue(transferTo);
          this.bankTransferForm.controls.transferTo.disable();
        } else {
          let transferFrom;
          this.transferFromGroupList.forEach((items) => {
            const transferFromId = items.listModels.find(
              (x: any) => x.id === data.accountId
            );
            if (transferFromId !== null && transferFromId !== undefined) {
              transferFrom = transferFromId;
            }
          });
          if (data.currencyId === 123) {
            this.transferToGroupList.forEach((items, index) => {
              this.transferToGroupList[index].listModels =
                items.listModels.filter((item) => item.id !== data.accountId);
            });
          } else {
            this.transferToGroupList.forEach((items, index) => {
              this.transferToGroupList[index].listModels =
                items.listModels.filter((item) => item.currencyId === 123);
            });
          }

          this.bankTransferForm.controls.transferFrom.setValue(transferFrom);
          this.bankTransferForm.controls.transferFrom.disable();
        }
      });

    this.triggerEditData
      .pipe(debounceTime(700), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.editBankTransfer(data);
      });

    this.bankTransferForm?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.bankTransferForm.touched;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  editBankTransfer(data): void {
    if (data.fromCurrencyId === 0) {
      data.fromCurrencyId = 123;
      data.fromSymbol = '£';
    } else if (data.toCurrencyId === 0) {
      data.toCurrencyId = 123;
      data.toSymbol = '£';
    }
    if (data.fromCurrencyId !== 123 || data.toCurrencyId !== 123) {
      this.isMultiCurrencyBank = true;
      if (data.isReceipt) {
        this.selectedBankCurrencySymbol = data.toSymbol;
        this.transferBankCurrencySymbol = data.fromSymbol;
      } else {
        this.selectedBankCurrencySymbol = data.fromSymbol;
        this.transferBankCurrencySymbol = data.toSymbol;
      }
    } else {
      this.selectedBankCurrencySymbol = data.toSymbol;
    }
    let transferFrom;
    this.transferFromGroupList.forEach((items) => {
      const transferFromId = items.listModels.find(
        (x: any) => x.id === data.transferredFromId
      );
      if (transferFromId !== null && transferFromId !== undefined) {
        transferFrom = transferFromId;
      }
    });

    let transferTo;
    this.transferToGroupList.forEach((items) => {
      const transferToId = items.listModels.find(
        (x: any) => x.id === data.transferredToId
      );
      if (transferToId !== null && transferToId !== undefined) {
        transferTo = transferToId;
      }
    });

    this.bankTransferForm.patchValue({
      bankTransferNumber: data.entryNumber,
      transferDate: data.entryDate,
      transferFrom: transferFrom,
      transferTo: transferTo,
      reference: data.note,
      amount: data.isReceipt
        ? data.toCurrencyId !== 123
          ? data.foreignAmount
          : data.amount
        : data.toCurrencyId !== 123
        ? data.amount
        : data.foreignAmount,
      convertedGbpAmnt: data.isReceipt
        ? data.toCurrencyId !== 123
          ? data.amount
          : data.foreignAmount
        : data.toCurrencyId !== 123
        ? data.foreignAmount
        : data.amount,
      currencyRate: data.currencyRate,
    });
    if (data.isReceipt) {
      if (this.isBankImport) {
        this.bankTransferForm.controls.transferTo.disable();
      } else {
        this.bankTransferForm.controls.transferTo.enable();
      }
      if (data.toCurrencyId === 123) {
        this.transferFromGroupList.forEach((items, index) => {
          this.transferFromGroupList[index].listModels =
            items.listModels.filter((item) => item.id !== data.transferredToId);
        });
      } else {
        this.transferFromGroupList.forEach((items, index) => {
          this.transferFromGroupList[index].listModels =
            items.listModels.filter((item) => item.currencyId === 123);
        });
      }
    } else {
      if (this.isBankImport) {
        this.bankTransferForm.controls.transferFrom.disable();
      } else {
        this.bankTransferForm.controls.transferFrom.enable();
      }
      if (data.fromCurrencyId === 123) {
        this.transferToGroupList.forEach((items, index) => {
          this.transferToGroupList[index].listModels = items.listModels.filter(
            (item) => item.id !== data.transferredFromId
          );
        });
      } else {
        this.transferToGroupList.forEach((items, index) => {
          this.transferToGroupList[index].listModels = items.listModels.filter(
            (item) => item.currencyId === 123
          );
        });
      }
    }
  }

  resetAccountList(isFromTransfer: boolean): void {
    let data;
    if (isFromTransfer) {
      data = this.bankTransferForm.controls.transferFrom.value;
      this.transferFromGroupList = this.tempGroupList;
    } else {
      data = this.bankTransferForm.controls.transferTo.value;
      this.transferToGroupList = this.tempGroupList;
    }
    this.scrollIntoView(data);
  }

  onAccountSearch(event: any, isFromTransfer: boolean): void {
    const list = this.commonService.onAccountSearch(event, this.tempGroupList);
    if (isFromTransfer) {
      this.transferFromGroupList = list;
    } else {
      this.transferToGroupList = list;
    }
  }

  getOptionText(option) {
    return option.name;
  }

  scrollIntoView(element) {
    if (element !== '') {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  setForm(): void {
    this.bankTransferForm = new FormGroup(
      {
        bankTransferNumber: new FormControl('', Validators.required),
        transferDate: new FormControl(new Date(), [
          datePickerValidator(this.periodicDate),
          Validators.required,
        ]),
        transferFrom: new FormControl('', [Validators.required]),
        transferTo: new FormControl('', [Validators.required]),
        amount: new FormControl('', [Validators.required]),
        reference: new FormControl(''),
        convertedGbpAmnt: new FormControl({ value: '', disabled: true }),
        currencyRate: new FormControl(''),
      },
      { validators: this.compareValidator('transferFrom', 'transferTo') }
    );

    this.bankTransferForm.controls.bankTransferNumber.disable();
    this.bankTransferForm.controls.currencyRate.setValue(1);
    this.bankTransferForm.controls.convertedGbpAmnt.setValue(0);
    this.getTransferList();
  }

  compareValidator(transferFrom, transferTo): ValidatorFn {
    return (control: any): ValidationErrors | null => {
      if (!control.get(transferFrom)?.value || !control.get(transferTo)?.value)
        return null;

      if (control.get(transferFrom)?.value === control.get(transferTo)?.value) {
        if (this.isBankImport) {
          if (!this.isCredit) {
            control.controls.transferFrom.status = 'INVALID';
            control.controls.transferTo.status = 'DISABLED';
          } else {
            control.controls.transferTo.status = 'INVALID';
            control.controls.transferFrom.status = 'DISABLED';
          }
        } else {
          control.controls.transferFrom.status = 'INVALID';
          control.controls.transferTo.status = 'INVALID';
        }
        return { matchingFields: true };
      } else {
        if (this.isBankImport) {
          if (!this.isCredit) {
            control.controls.transferTo.status = 'DISABLED';
          } else {
            control.controls.transferFrom.status = 'DISABLED';
          }
        } else {
          control.controls.transferFrom.status = 'VALID';
          control.controls.transferTo.status = 'VALID';
        }
        return null;
      }
    };
  }

  getTransferList(): void {
    this.store
      .dispatch(new GetGroupCustomBankAndCashAccount())
      .pipe(
        tap((res) => {
          this.transferFromGroupList =
            res.common.customBankAndCashAccountGroupList;
          this.transferToGroupList =
            res.common.customBankAndCashAccountGroupList;

          this.tempGroupList = this.transferFromGroupList;
        })
      )
      .subscribe();
  }
  onChangeTransferFromBank(): void {
    this.ReceiptsTransferFromBankCurrencyId =
      this.bankTransferForm.controls.transferFrom.value.currencyId;
    this.transferBankCurrencySymbol =
      this.bankTransferForm.controls.transferFrom.value.symbol;
    if (!this.isBankImport) {
      this.selectedBankCurrencySymbol = this.transferBankCurrencySymbol;
    }
    if (
      this.ReceiptsTransferFromBankCurrencyId !== 123 &&
      this.ReceiptsTransferFromBankCurrencyId !== undefined
    ) {
      this.isMultiCurrencyBank = true;
      this.bankTransferForm.controls.currencyRate.setValue(
        this.bankTransferForm.controls.transferFrom.value.currencyRate
      );
      this.bankTransferForm.controls.convertedGbpAmnt.setValue(
        (
          this.bankTransferForm.controls.amount.value *
          this.bankTransferForm.controls.transferFrom.value.currencyRate
        ).toFixed(2)
      );
    } else if (
      this.defaultBankCurrencyId !== 123 &&
      this.defaultBankCurrencyId !== undefined
    ) {
      this.isMultiCurrencyBank = true;
      this.bankTransferForm.controls.currencyRate.setValue(
        this.defaultBankCurrencyRate
      );
      this.bankTransferForm.controls.convertedGbpAmnt.setValue(
        (
          this.bankTransferForm.controls.amount.value /
          this.defaultBankCurrencyRate
        ).toFixed(2)
      );
    } else if (
      this.paymentTransferToBankCurrencyId !== 123 &&
      this.paymentTransferToBankCurrencyId !== undefined
    ) {
      this.isMultiCurrencyBank = true;
      this.bankTransferForm.controls.currencyRate.setValue(
        this.bankTransferForm.controls.transferTo.value.currencyRate
      );
    } else {
      this.isMultiCurrencyBank = false;
      this.bankTransferForm.controls.currencyRate.setValue(1);
      this.bankTransferForm.controls.convertedGbpAmnt.setValue(
        this.bankTransferForm.controls.amount.value
      );
    }
  }
  onCurrencyRateEntered(): void {
    this.bankTransferForm.controls.convertedGbpAmnt.setValue(
      (
        this.bankTransferForm.controls.amount.value *
        this.bankTransferForm.controls.currencyRate.value
      ).toFixed(2)
    );
  }
  onCurrencyRefresh(): void {
    this.currencyIds = [];
    if (this.isCredit) {
      this.currencyIds.push(this.paymentTransferToBankCurrencyId);
    } else {
      this.currencyIds.push(this.ReceiptsTransferFromBankCurrencyId);
    }

    this.store
      .dispatch(new GetRefreshCurrencyData(this.currencyIds))
      .subscribe((res) => {
        this.currencyIds = [];
        this.refreshedCurrencyData = res.currency.currencyList.data;

        this.refreshedCurrencyData.forEach((x) => {
          this.refreshedCurrencyRate = x.rate;
        });
        this.bankTransferForm.controls.currencyRate.setValue(
          this.refreshedCurrencyRate.toFixed(2)
        );
        this.bankTransferForm.controls.convertedGbpAmnt.setValue(
          (
            this.bankTransferForm.controls.amount.value *
            this.bankTransferForm.controls.currencyRate.value
          ).toFixed(2)
        );
      });
  }
  onChangeTransferToBank(): void {
    this.paymentTransferToBankCurrencyId =
      this.bankTransferForm.controls.transferTo.value.currencyId;
    this.transferBankCurrencySymbol =
      this.bankTransferForm.controls.transferTo.value.symbol;

    if (
      this.paymentTransferToBankCurrencyId !== 123 &&
      this.paymentTransferToBankCurrencyId !== undefined
    ) {
      this.isMultiCurrencyBank = true;
      this.bankTransferForm.controls.currencyRate.setValue(
        this.bankTransferForm.controls.transferTo.value.currencyRate
      );
      this.bankTransferForm.controls.convertedGbpAmnt.setValue(
        (
          this.bankTransferForm.controls.amount.value *
          this.bankTransferForm.controls.transferTo.value.currencyRate
        ).toFixed(2)
      );
    } else if (
      this.defaultBankCurrencyId !== 123 &&
      this.defaultBankCurrencyId !== undefined
    ) {
      this.isMultiCurrencyBank = true;
      this.bankTransferForm.controls.currencyRate.setValue(
        this.defaultBankCurrencyRate
      );
      this.bankTransferForm.controls.convertedGbpAmnt.setValue(
        (
          this.bankTransferForm.controls.amount.value /
          this.defaultBankCurrencyRate
        ).toFixed(2)
      );
    } else if (
      this.ReceiptsTransferFromBankCurrencyId !== 123 &&
      this.ReceiptsTransferFromBankCurrencyId !== undefined
    ) {
      this.isMultiCurrencyBank = true;
      this.bankTransferForm.controls.currencyRate.setValue(
        this.bankTransferForm.controls.transferFrom.value.currencyRate
      );
    } else {
      this.isMultiCurrencyBank = false;
      this.bankTransferForm.controls.currencyRate.setValue(1);
      this.bankTransferForm.controls.convertedGbpAmnt.setValue(
        this.bankTransferForm.controls.amount.value
      );
    }
  }
  onAmountEntered() {
    if (
      this.ReceiptsTransferFromBankCurrencyId === 123 &&
      this.paymentTransferToBankCurrencyId === 123
    ) {
      this.bankTransferForm.controls.convertedGbpAmnt.setValue(
        this.bankTransferForm.controls.amount.value
      );
    } else if (
      this.ReceiptsTransferFromBankCurrencyId === 123 &&
      this.paymentTransferToBankCurrencyId !== 123
    ) {
      this.isMultiCurrencyBank = true;
      this.bankTransferForm.controls.convertedGbpAmnt.setValue(
        (
          this.bankTransferForm.controls.amount.value *
          this.bankTransferForm.controls.currencyRate.value
        ).toFixed(2)
      );
    } else {
      this.isMultiCurrencyBank = true;
      this.bankTransferForm.controls.convertedGbpAmnt.setValue(
        (
          this.bankTransferForm.controls.amount.value /
          this.bankTransferForm.controls.currencyRate.value
        ).toFixed(2)
      );
    }
  }
}
