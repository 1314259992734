import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { DataType, Modules, NotificationTextMessage } from '@app/core/Enum';
import { MainListParameters, MenuModel } from '@app/core/Models';
import { CommonService, ModulePermission } from '@app/core/Services';
import {
  CISReportState,
  Export,
  GetInvoiceReport,
  MenuState,
  SubContractorYTDDetailsReport,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-subcontractor-ytd-details',
  templateUrl: './subcontractor-ytd-details.component.html',
  styleUrls: ['./subcontractor-ytd-details.component.scss'],
})
export class SubcontractorYtdDetailsComponent implements OnInit {
  dialog: MatDialog;
  spinner: NgxSpinnerService;
  showPaginator = true;
  totalSelectedRecords = 0;
  listParameters: MainListParameters = new MainListParameters();
  isRowHighlighted = false;
  noDataFound = false;
  moduleEnum = Modules;
  isHeaderChange = false;
  selectedRowIndex = -1;
  modulePermission: ModulePermission;
  columns: any = [];
  columnWidth: number = 100;

  dataType = DataType;

  subcontractorDetails: any;
  totalMaterialAmount = 0;
  totalLabourAmount = 0;
  totalCisDeduction = 0;
  totalNetPayment = 0;
  totalTaxAmount = 0;
  datepipe: DatePipe;

  displayedColumns: string[] = [
    'payPeriod',
    'utr',
    'customerName',
    'materialAmount',
    'labourAmount',
    'rateOfCIS',
    'taxAmount',
    'cISDeducation',
    'netPay',
  ];

  @Select(CISReportState.totalRecord)
  totalRecord$: Observable<number>;

  @Select(MenuState.menu)
  menuList$: Observable<Array<MenuModel>>;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  triggerHeaderChange: Subject<any> = new Subject<any>();

  @ViewChild(MatSort) sort: MatSort;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private injector: Injector,
    private store: Store,
    public commonService: CommonService
  ) {
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
  }

  ngOnInit(): void {}

  getParamter(format?: number): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      branchIds: this.listParameters.branchIds ?? [],
      departmentIds: this.listParameters.departmentIds ?? [],
      ids: this.listParameters.ids ?? null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      format: format,
    };
    return queryParams;
  }

  getList(): void {
    this.store
      .dispatch(new SubContractorYTDDetailsReport(this.getParamter()))
      .pipe()
      .subscribe((res) => {
        if (
          this.listParameters.moduleId !== undefined &&
          this.listParameters.moduleId !== null &&
          this.listParameters.moduleId === this.commonService.defaultGuidValue
        ) {
          this.displayedColumns = this.displayedColumns.filter(
            (column) => column !== 'payPeriod'
          );
        } else {
          if (!this.displayedColumns.includes('payPeriod')) {
            this.displayedColumns = ['payPeriod', ...this.displayedColumns];
          }
        }
        this.subcontractorDetails = res.CISReport.cisInvoice.data;
        this.totalMaterialAmount = res.CISReport.cisInvoice.totalMaterialAmount;
        this.totalLabourAmount = res.CISReport.cisInvoice.totalLabourAmount;
        this.totalCisDeduction = res.CISReport.cisInvoice.totalCisDeduction;
        this.totalNetPayment = res.CISReport.cisInvoice.totalNetPayment;
        this.totalTaxAmount = res.CISReport.cisInvoice.totalTaxAmount;
        this.noDataFound = this.subcontractorDetails.length === 0;
      });
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.isHeaderChange = false;
    this.getList();
  }

  getDataFromHeader(data: any): void {
    this.getFilterData(data);
  }

  getFilterData(data: any): void {
    if (data.search === '') {
      this.spinner.show();
    }
    this.isHeaderChange = true;
    this.listParameters = data;

    this.triggerHeaderChange.next(this.listParameters);

    !this.listParameters.format
      ? this.getList()
      : this.export(this.listParameters.format, this.listParameters.isPrint);
  }

  export(format: number, isPrint?: boolean): void {
    this.spinner.show();
    this.store
      .dispatch(
        new Export(
          this.getParamter(format),
          this.moduleEnum.SubcontractorYTDDetails
        )
      )
      .subscribe();
  }

  dateChanged(date: any): void {
    this.listParameters.startDate = date.startDate;
    this.listParameters.endDate = date.endDate;
    this.getList();
  }

  onDownloadClick(element: any): void {
    this.spinner.show();

    this.store
      .dispatch(new GetInvoiceReport(this.sendPdfParams(element)))
      .subscribe();
  }

  sendPdfParams(element): any {
    let id = this.commonService.defaultGuidValue;
    let contactId = this.commonService.defaultGuidValue;
    if (
      this.listParameters.moduleId !== undefined &&
      this.listParameters.moduleId !== null &&
      this.listParameters.moduleId === this.commonService.defaultGuidValue
    ) {
      contactId = element.id;
    } else {
      id = element.id;
      contactId =
        this.listParameters.moduleId ?? this.commonService.defaultGuidValue;
    }

    const params = {
      cisId: id,
      contactId: contactId,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    return params;
  }
}
