<div class="mat-dialog-wrapper reconfirm-popup">
  <button
    class="back-button"
    type="button"
    (click)="onBackClick()"
    mat-button
    disableRipple
    *ngIf="isContentShow"
  >
    <mat-icon>arrow_back</mat-icon>
    Back
  </button>
  <button
    class="close-button"
    type="button"
    (click)="onCloseClick()"
    mat-button
    disableRipple
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    <div class="d-flex align-items-center justify-content-center header-icon">
      <div class="capium-logo">
        <img
          src="assets/images/favicon.png"
          width="18"
          height="18"
          alt="Capium Logo"
        />
      </div>
      <div class="truelayer-logo">
        <mat-icon class="text-yellow-100">layers</mat-icon>
      </div>
      <div class="bank-logo">
        <mat-icon class="text-gray-100"> account_balance </mat-icon>
      </div>
    </div>
    <div *ngIf="!isContentShow">
      <div class="text-align-center mt-2">
        <h3 class="font-size-22">
          TrueLayer needs your permission to access your <br />
          account data and share it with capium
        </h3>
        <p class="font-size-16">
          Capium partners with TrueLayer to securely retrieve your account data
        </p>
      </div>
      <div>
        <mat-tab-group mat-align-tabs="start">
          <mat-tab label="What data am I sharing?">
            <div class="content-body">
              <p class="mb-1">For 90 days capium will be able to read your</p>
              <ul class="pl-2 ml-10 mb-1">
                <li>Full name</li>
                <li>Accounts</li>
                <li>Balance</li>
                <li>Transactions, direct debits and standing orders</li>
              </ul>
              <p class="mb-1">
                You can ask capium to stop accessing your data at any time
              </p>
              <p class="mb-1">
                TrueLayer are FCA-regulated, and won’t share or use your
                personal data for anything else.
              </p>
              <p class="mb-1">
                To keep your information secure, TrueLayer connects to your
                account using bank-grade encryption.
              </p>
            </div>
          </mat-tab>
          <mat-tab label="How does this work?">
            <div class="content-body">
              <p class="mb-1">
                When you click 'Allow', we will pass you over to Mock to
                authorise access to your account data
              </p>
              <p class="mb-1">
                TrueLayer uses bank-grade encryption to connect to your bank
              </p>
              <p class="mb-1">
                Your login details are never shared with capium or third parties
              </p>
              <p class="mb-1">
                TrueLayer is authorised by the Financial Conduct Authority
                <span class="text-primary">FRN 901096</span>
              </p>
            </div>
          </mat-tab>
          <mat-tab label="How is my data used?">
            <div class="content-body">
              <p class="mb-1">
                Capium will use your data to provide its services
              </p>
              <p class="mb-1">
                You can ask capium to stop accessing your data at any time
              </p>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <div *ngIf="isContentShow">
      <div class="text-align-center mt-2">
        <h3 class="font-size-22">Enter your bank login details</h3>
        <p class="font-size-16">
          To connect your account with capium, some additional information is
          required before giving your permission.
        </p>
      </div>
      <div class="mt-3">
        <form [formGroup]="reconfirmForm">
          <div class="form-field">
            <p>User ID/Personal ID <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                formControlName="name"
                autocomplete="off"
                unicodeCharacter
              />
            </mat-form-field>
          </div>
          <div class="form-field">
            <p>Phone Number <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                autocomplete="off"
                formControlName="mobileNumber"
                maxlength="{{ maxLength.maxLength25 }}"
                type="text"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              />
            </mat-form-field>
          </div>
          <div class="form-field">
            <p>Email <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                formControlName="email"
                emailValidator
                autocomplete="off"
                type="text"
              />
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div *ngIf="!isContentShow">
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onAllowClick()"
      >
        Allow
      </button>
      <button
        class="action-button secondary-button"
        mat-dialog-close
        type="button"
        mat-button
        disableRipple
        (click)="onCancelClick()"
      >
        Cancel
      </button>
    </div>
    <div *ngIf="isContentShow">
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onProceedClick()"
      >
        Proceed
      </button>
    </div>
  </mat-dialog-actions>
  <p
    class="mt-1 d-flex align-items-center justify-content-center gap-5"
    *ngIf="!isContentShow"
  >
    By continuing you agree to
    <a class="link" href="https://truelayer.com/enduser_tos/" target="_blank"
      >TrueLayer's Terms of Service</a
    >
    and
    <a class="link" href="https://truelayer.com/privacy/" target="_blank"
      >Privacy Policy</a
    >
  </p>
  <p
    class="mt-1 d-flex align-items-center justify-content-center gap-5"
    *ngIf="isContentShow"
  >
    <a class="link" href="https://truelayer.com/enduser_tos/" target="_blank"
      >TrueLayer's Terms of Service</a
    >
    and
    <a class="link" href="https://truelayer.com/privacy/" target="_blank"
      >Privacy Policy</a
    >
    FCA License Number:
    <a
      class="link"
      href="https://register.fca.org.uk/s/firm?id=0014G00002UxKoUQAV"
      target="_blank"
      >901096</a
    >
  </p>
</div>

<app-custom-notifications></app-custom-notifications>
