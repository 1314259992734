import {
  BankFeeds,
  ExtendConnectionTokenUserDataPayload,
  ManualImportItemModel,
} from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class GetBankAccountList {
  static readonly type = '[BANKDASHBOARD] Get Bank Account List';
}

export class DeleteBankAccount {
  static readonly type = '[BANKDASHBOARD] Delete Bank Account';

  constructor(public ids: Array<Guid>) {}
}

export class RefreshBankAccountList {
  static readonly type = '[BANKDASHBOARD] Get Refresh Bank Account List';

  constructor(public accountId: any) {}
}

export class GetBankAccountTypeToAdd {
  static readonly type = '[BANKDASHBOARD] Get Bank Account Type To Add';

  constructor(public param: any) {}
}

export class CreateBankAccount {
  static readonly type = '[BANKDASHBOARD] Create BankAccount';

  constructor(public bankAccount: BankFeeds) {}
}

export class SetBankDefault {
  static readonly type = '[BANKDASHBOARD] Set Bank Default';

  constructor(public id: Guid) {}
}

export class ManualImportBank {
  static readonly type = '[BANKDASHBOARD] Manual Import Bank';

  constructor(
    public manualImports: Array<ManualImportItemModel>,
    public customId: any
  ) {}
}

export class ReconfirmBankAccount {
  static readonly type = '[BANKDASHBOARD] Reconfirm Bank Account';

  constructor(
    public accountId: any,
    public userData: ExtendConnectionTokenUserDataPayload
  ) {}
}
