<div class="content">
  <div
    class="content-body content-body-scroll"
    [ngClass]="{ 'mb-4': (totalRecord$ | async) > 10 && showPaginator }"
  >
    <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
      <mat-icon class="material-icons-outlined">error_outline</mat-icon>
      {{ commonNotificationText.ViewOnlyAccessMessage }}
    </div>
    <div class="content-header">
      <div class="content-header-input">
        <div class="d-flex justify-space-between align-items-end wp-100 mb-15">
          <app-transactions-header #headerRef [listParameters]="listParameters">
          </app-transactions-header>
          <div class="header-actions wp-100">
            <app-header-actions
              (exportClick)="export($event, false)"
              (dateChanged)="dateChanged($event)"
              (triggerDataFromHeader)="getDataFromHeader($event)"
              [listParameters]="listParameters"
              [parentModuleId]="this.moduleEnum.CISReportCISInvoice"
              [removeBranchSelection]="true"
            >
            </app-header-actions>
          </div>
        </div>
        <div
          class="mat-table-wrapper"
          [ngClass]="{ 'mat-table-wrapper-noData': noDataFound }"
        >
          <table
            mat-table
            [dataSource]="cisInvoices"
            aria-describedby="CIS-Invoice"
            mat-table
            matSort
            (matSortChange)="sorting(sort.active, sort.direction)"
          >
            <ng-container matColumnDef="payPeriod">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                Pay Period
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.transDate | date: "dd-LLL-yyyy" }}
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="customerName">
              <th mat-header-cell *matHeaderCellDef>Customer Name</th>
              <td mat-cell *matCellDef="let element">
                <span title="{{ element.customerName }}">
                  {{ element.customerName }}</span
                >
              </td>
              <td mat-footer-cell *matFooterCellDef>Page Total</td>
            </ng-container>

            <ng-container matColumnDef="materialAmount">
              <th mat-header-cell *matHeaderCellDef class="text-align-right">
                Material Amount
              </th>
              <td mat-cell *matCellDef="let element" class="text-align-right">
                {{ element.materialAmount | numberPipe }}
              </td>
              <td mat-footer-cell *matFooterCellDef class="text-align-right">
                £ {{ this.totalMaterialAmount | numberPipe }}
              </td>
            </ng-container>

            <ng-container matColumnDef="labourAmount">
              <th mat-header-cell *matHeaderCellDef class="text-align-right">
                Labour Amount
              </th>
              <td mat-cell *matCellDef="let element" class="text-align-right">
                {{ element.labourAmount | numberPipe }}
              </td>
              <td mat-footer-cell *matFooterCellDef class="text-align-right">
                £ {{ this.totalLabourAmount | numberPipe }}
              </td>
            </ng-container>

            <ng-container matColumnDef="rateOfCIS">
              <th mat-header-cell *matHeaderCellDef class="text-align-right">
                Rate of CIS
              </th>
              <td mat-cell *matCellDef="let element" class="text-align-right">
                {{ element.rate | numberPipe }}
              </td>
              <td
                mat-footer-cell
                *matFooterCellDef
                class="text-align-right"
              ></td>
            </ng-container>

            <ng-container matColumnDef="taxAmount">
              <th mat-header-cell *matHeaderCellDef class="text-align-right">
                Tax Amount
              </th>
              <td mat-cell *matCellDef="let element" class="text-align-right">
                {{ element.taxAmount | numberPipe }}
              </td>
              <td mat-footer-cell *matFooterCellDef class="text-align-right">
                £ {{ this.totalTaxAmount | numberPipe }}
              </td>
            </ng-container>

            <ng-container matColumnDef="cISDeducation">
              <th mat-header-cell *matHeaderCellDef class="text-align-right">
                CIS Deduction
              </th>
              <td mat-cell *matCellDef="let element" class="text-align-right">
                {{ element.cISDeduction | numberPipe }}
              </td>
              <td mat-footer-cell *matFooterCellDef class="text-align-right">
                £ {{ this.totalCisDeduction | numberPipe }}
              </td>
            </ng-container>

            <ng-container matColumnDef="netPay">
              <th mat-header-cell *matHeaderCellDef class="text-align-right">
                Net pay
              </th>
              <td mat-cell *matCellDef="let element" class="text-align-right">
                {{ element.nETPay | numberPipe }}
              </td>
              <td mat-footer-cell *matFooterCellDef class="text-align-right">
                £ {{ this.totalCisDeduction | numberPipe }}
              </td>
            </ng-container>

            <ng-container matColumnDef="invoice">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="w-50 text-align-center"
              >
                Invoice
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                class="text-align-center"
              >
                <button
                  mat-button
                  class="fw-bold text-primary no-box-shadow"
                  type="button"
                  (click)="onDownloadClick(element)"
                  disableRipple
                >
                  <mat-icon>picture_as_pdf</mat-icon>
                </button>
              </td>

              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="w-50 text-align-center"
              >
                Email
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                class="text-align-center"
              >
                <button
                  mat-button
                  class="fw-bold text-primary no-box-shadow"
                  type="button"
                  (click)="SendMail(element)"
                  disableRipple
                >
                  <mat-icon>mail</mat-icon>
                </button>
              </td>

              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              [ngClass]="isRowHighlighted ? 'highlightRow' : ''"
            ></tr>

            <tr
              mat-footer-row
              *matFooterRowDef="displayedColumns; sticky: true"
              [hidden]="noDataFound"
            ></tr>
          </table>
          <div *ngIf="noDataFound" class="text-align-center mt-1">
            No records found
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
