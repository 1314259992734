<div class="d-flex year-picker-content">
  <div>
    <mat-form-field>
      <mat-select
        [value]="selectedOption"
        (selectionChange)="onDropdownChange($event)"
        panelClass="mat-select-position"
        [disableOptionCentering]="true"
      >
        <mat-option
          *ngIf="
            this.moduleId !== moduleEnum.AccountDetails &&
            this.moduleId !== moduleEnum.TrialBalance &&
            this.moduleId !== moduleEnum.EditTrialBalance &&
            this.moduleId !== moduleEnum.BalanceSheet &&
            this.moduleId !== moduleEnum.BalanceSheetComparison &&
            this.moduleId !== moduleEnum.IncomeAndExpenditure &&
            this.moduleId !== moduleEnum.ComparativeReport &&
            this.moduleId !== moduleEnum.VAT &&
            this.moduleId !== moduleEnum.ProfitAndLoss &&
            this.moduleId !== moduleEnum.ProfitAndLossComparision &&
            this.moduleId !== moduleEnum.ProfitAndLossSummary &&
            this.moduleId !== moduleEnum.ProfitAndLossWithPercentage &&
            this.moduleId !== moduleEnum.ProfitAndLossDetail &&
            this.moduleId !== moduleEnum.BalanceSheetDetail &&
            this.moduleId !== moduleEnum.CashFlow
          "
          value="-1"
          >All Years
        </mat-option>
        <mat-option
          *ngFor="let year of yearsListPair"
          [value]="year"
          title="{{ year.name }}"
        >
          {{ year.name }}
        </mat-option>
        <mat-option
          value="1"
          title="Custom dates"
          *ngIf="this.moduleId !== moduleEnum.EditTrialBalance"
          >Custom dates</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <form
    class="ml-10"
    *ngIf="selectedOption === '1'"
    [formGroup]="rangeFormGroup"
  >
    <mat-form-field
      appearance="standard"
      [ngClass]="selectedOption === '1' ? 'ml-0' : ''"
    >
      <mat-date-range-input [rangePicker]="picker">
        <input
          matStartDate
          matInput
          #start
          (dateChange)="onCustomDate()"
          formControlName="start"
          [required]="this.moduleId === moduleEnum.AccountDetails"
        />
        <input
          matEndDate
          matInput
          #end
          formControlName="end"
          (dateChange)="onCustomDate()"
          (change)="commonService.validateDate($event)"
          [required]="this.moduleId === moduleEnum.AccountDetails"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>
</div>
