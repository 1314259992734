export enum Modules {
  Dashboard = 1,
  Sales = 2,
  Expenditure = 3,
  Tasks = 4,
  Inventory = 5,
  Contacts = 6,
  Schedules = 7,
  Bank = 8,
  Report = 11,
  ChartOfAccounts = 12,
  Manage = 13,
  Logs = 14,
  IncomeOverview = 15,
  Invoices = 16,
  RecurringInvoice = 17,
  Quotation = 18,
  CreditNote = 19,
  Receipt = 20,
  Product = 21,
  ExpenseOverview = 22,
  Purchase = 23,
  RecurringPurchase = 24,
  DebitNote = 25,
  Payment = 26,
  Capiscan = 27,
  Journals = 28,
  BulkEdit = 29,
  Budgeting = 30,
  Dividends = 31,
  Customers = 32,
  Suppliers = 33,
  Director = 34,
  Shareholder = 35,
  MinutesOfMeeting = 36,
  Notes = 37,
  BankDashboard = 38,
  BankTransfer = 39,
  BankFeed = 40,
  CashEntry = 41,
  BankEntry = 42,
  CashCoding = 43,
  FixedAssets = 44,
  FixedAssetsRegister = 45,
  SubmitVat = 46,
  BridgingVAT = 47,
  ViewVATDetails = 48,
  VatSettings = 49,
  AddStandardAccounts = 50,
  AddCustomAccounts = 51,
  CompanyProfile = 52,
  ClientsPreference = 53,
  OpeningBalance = 54,
  Import = 55,
  EmailLogs = 56,
  UserLogs = 57,
  ReportDashboard = 58,
  Management = 59,
  Additional = 60,
  VAT = 63,
  TrialBalance = 64,
  BalanceSheet = 65,
  ComparativeReport = 66,
  BudgetReport = 67,
  DayBook = 68,
  IncomeAndExpenditure = 69,
  AccountDetails = 70,
  NominalLedger = 71,
  CustomerAdvanceReport = 72,
  SalesReport = 73,
  CustomerList = 74,
  CustomerAgeingList = 75,
  TransactionsByCustomer = 76,
  SalesProductList = 77,
  CustomerReceipts = 78,
  Debtors = 79,
  CustomerStatement = 80,
  SalesInvoiceList = 81,
  SupplierAdvanceReport = 82,
  PurchasesReport = 83,
  SupplierList = 84,
  SupplierAgeingList = 85,
  TransactionsBySupplier = 86,
  PaymentToSupplier = 87,
  Creditors = 88,
  SupplierStatement = 89,
  PurchasesInvoiceList = 90,
  SalesDayBook = 91,
  PurchaseDayBook = 92,
  CIS = 93,
  Subcontractor = 94,
  CIS300 = 95,
  CISInvoice = 96,
  FixedAssetOverview = 97,
  Integration = 98,
  Permission = 99,
  ContractorSettings = 100,
  ProfitAndLoss = 102,
  OpenInvoice = 103,
  UnpaidPurchases = 104,
  IncomeByCustomerSummary = 105,
  ProfitAndLossComparision = 106,
  CustomerBalanceSummary = 107,
  DepositDetails = 108,
  ExpensesBySupplierSummary = 109,
  SupplierBalanceSummary = 110,
  BusinessSnapshot = 111,
  AccountList = 112,
  AnnualReport = 113,
  CompanyOverview = 114,
  SalesPerformance = 115,
  ExpensePerformance = 116,
  AdvanceAgeingList = 117,
  AccountPayableList = 118,
  TransactionListByDate = 119,
  QuotationByCustomer = 120,
  RecentTransactions = 121,
  ChequeDetails = 122,
  ProfitAndLossSummary = 123,
  InvoiceAndReceivedPayments = 124,
  PurchaseAndAppliedPayments = 125,
  ProfitAndLossWithPercentage = 126,
  BalanceSheetComparison = 127,
  SalesByProductDetail = 128,
  SalesByProductSummary = 129,
  ProfitAndLossDetail = 130,
  BalanceSheetDetail = 131,
  EditTrialBalance = -1,
  CustomizeReport = 132,
  Currency = 133,
  QuickEntry = 134,
  CisReports = 135,
  CISSubmission = 136,
  SubcontractorYTDDetails = 137,
  CISReportCISInvoice = 138,
  CashFlow = 139,
  GeneralManage = 140,
  Clients = 141,
  Users = 142,
  ManageImport = 143,
  ManageExport = 144,
  UserPermissions = 146,
  ReportDetailsList = 200,
  FinancialYear = 201,
  ReportAccountDetail = 202,
  CustomerDetailHeader = 203,
  SupplierDetailHeader = 204,
  BankImportHistory = 205,
  BankImportTransactionHistory = 206,
  FixedAssetsDispose = 207,
  SupportTicket = 208,
  ClientTicket = 209,
  MatchJournal = 212,
  AutoBankReconcilation = 213,
  FixedAssetDetail = 214,
  FixedAssetRollBack = 215,
  ActiveTangibleFixedAssets = 216,
  ActiveLeasedFixedAssets = 217,
  ActiveIntangibleFixedAssets = 218,
  DisposeTangibleFixedAssets = 219,
  DisposeLeasedFixedAssets = 220,
  DisposeIntangibleFixedAssets = 221,
  MatchQuickEntry = 222,
  CIS300Ceritification = 223,
  MultipleTransactions = 224,

  Refund = 225,
  UserClients = 300,
}

export enum ReportEnum {
  TrialBalance = 64,
  BalanceSheet = 65,
  BudgetReport = 67,
  DayBook = 68,
  ProfitAndLoss = 102,
}

export enum ProjectName {
  charity = 'charity',
  diyboox = 'diyboox',
}

export enum ComponentName {
  MainListComponent = 'app-main-list',
  ReportListComponent = 'app-report-list',
  BankReconciliationComponent = 'app-bank-reconciliation',
  NominalLedgerComponent = 'app-nominal-ledger',
  ImportListComponent = 'app-import',
  ReportCreditorDebtorComponent = 'app-report-creditor-debitor',
  DynamicGridListComponent = 'app-dynamic-grid-list',
  CisInvoiceComponent = 'app-cis-invoice',
  ProfitLossComponent = 'app-profit-loss',
  ProfitLossComparisonComponent = 'app-profit-loss-comparison',
  BulkEdit = 'app-bulk-edit',
  VatSettings = 'app-vat-settings',
  ReportListWithActionsComponent = 'app-report-list-with-actions',
  Dashboardv1 = 'app-dashboard-v1',
  fixedAssetRegister = 'app-fixed-assest-dispose-active',
  BankOverviewComponent = 'app-bank-overview',
  ShareComponent = 'app-share',
}

export enum VatSettings {
  VatSettings = 'VAT Settings',
  VatCodes = 'VAT Codes',
}

export enum InventoryName {
  Purchase = 'Purchase',
  Sales = 'Sales',
  LossOfStock = 'Loss Of Stock',
}

export enum Inventory {
  Purchase = 1,
  Sales = 2,
  Loss = 3,
  Opening = 4,
}

export enum MTD {
  Obligations = 1,
  ViewVATReturn = 2,
  RetrieveVATLiabilities = 3,
  RetrieveVATPayments = 4,
  VATCodes = 5,
  SubmitVat = 6,
}

export enum DetailListModules {
  Inventory = 1,
}

export enum AccountTypeImport {
  Supplier = 1,
  Customer = 2,
}

export enum OpeningBalanceExplain {
  Debit = 1,
  Credit = 2,
}

export enum CheckValidDate {
  YearEndDate = 1,
  DeclarationDate = 2,
  PaymentDate = 3,
  EntryDate = 4,
  DueDate = 5,
  BillDate = 6,
  FixedAssetDate = 7,
  CreditNoteDate = 8,
  DebitNoteDate = 9,
  AppointmentDate = 10,
}

export enum ImportDateName {
  invoiceDate = 1,
  dueDate = 2,
  billDate = 3,
  creditNoteDate = 4,
  debitNoteDate = 5,
  fixedAssetDate = 6,
  yearEndDate = 7,
  declarationDate = 8,
  paymentDate = 9,
  appointmentDate = 10,
  journalDate = 11,
}

export enum DashboardAgeingSummaryType {
  Receivables = 1,
  Payable = 2,
}
