import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  DiscountType,
  DiscountTypeName,
  GroupNames,
  MaxLength,
  ModuleName,
  Modules,
  NotificationTextMessage,
} from '@app/core/Enum';
import { AccountNumber } from '@app/core/Enum/account-number';
import { ProductType } from '@app/core/Enum/product-type';
import { VatName } from '@app/core/Enum/vat-name';
import { VatRateCodeType } from '@app/core/Enum/vat-rate-code-type';
import { GlobalComponent, GroupListModel, ProductModel, VatRateScheme } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CommonState,
  CreateProduct,
  GetFixedAssetAdditionList,
  GetGroupAccountsBasedOnGroupId,
  GetProductData,
  GetProductListByProductType,
  GetProductListByProductTypeByBranch,
  GetVatRateList,
  MenuState,
  ProductState,
} from '@app/core/Store';
import { CleanAllLinesComponent, QuickAddComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { forkJoin, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
  productDetailForm: FormGroup;
  formProductDetail: UntypedFormGroup;
  isproductDetailValid = true;

  productTypeList: ProductModel[];
  productTypeData: ProductModel[];
  productDetailArray: any;
  linkText: string;
  maxLength = MaxLength;
  primaryContact = {
    id: 0,
  };
  customVatAmount: number = 0;
  postponedVatAmount: number = 0;
  branchId: Guid | null = null;
  isVatIncuded: boolean = false;
  isGlobalChecked:boolean=false;
  // isDiscountInPercentage: boolean = false;

  discountTypeList = [
    { id: 1, name: DiscountTypeName.Value },
    { id: 2, name: DiscountTypeName.Percentage },
  ];

  discountTypeEnum = DiscountType;

  @Input() triggereEditProductData: Observable<any>;
  @Input() triggerOnVATChange: Observable<any>;
  @Input() triggerAccountSelectedOption: Observable<any>;
  @Input() triggerCurrencySelectedOption: Observable<any>;
  @Input() triggerCurrencyRefreshOption: Observable<any>;
  @Input() triggerisVatIncluded: Observable<any>;
  @Input() triggerisHlobalDiscountChecked: Observable<any>;
  
  @Input() triggerOnBranchChange: Observable<any>;
  @Input() triggerSelectedCurrencyRate: Observable<any>;
  @Output() triggerNewProductAdded: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() triggerVatRateChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  tableDataSource: MatTableDataSource<AbstractControl>;
  @Input() triggerOnDiscountChange: Observable<any>;
  
  @ViewChild('auto') matAutocompleteRef: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger: MatAutocompleteTrigger;

  displayProductDetailsColumns: string[] = [
    'srNo',
    'product',
    'description',
    'qty',
    'price',
    'invoiceType',
    'vatrate',
    'vatammount',
    'netAmount',
    'amount',
    'closeButton',
  ];
  vatRateList: any[]=[];
  totalAmount = 0;
  vatRate: number;
  vatName: string;
  originalVatRateList: any[]=[];
  isVATChecked = false;
  accountName: string;
  productText = '';
  productType = undefined;
  isMultiCurrencyOption = true;
  isVatRegistered: any;

  private destroy$ = new Subject<void>();

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  //moduleId: number;
  @Input() moduleId: number;
  selectedCurrencyRate: number = 1;
  previousSelectedCurrencyRate: number;
  isCurrencyRefresh = false;
  latestPrice: number;
  isPreviousRate: boolean;
  discountIndex: any[] = [];
  moduleEnum = Modules;
  public defaultGuidValue = Guid.EMPTY as unknown as Guid;
  accountGroupList: GroupListModel[];
  tempAccountGroupList: GroupListModel[];
  @Input() isSales:boolean;
  @Input() isFixedAsset:boolean;
  fixedAssetsTypeList: any[] = [];
  discountVal:number;
  discountPers:number;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store,
    private renderer: Renderer2,
    private commonService: CommonService,
    public dialog: MatDialog,
    public globalComponent: GlobalComponent
  ) {}

  ngOnInit(): void {
    if(this.isFixedAsset){
      this.getFixedInvoiceType();
    }else{
      this.getInvoiceType();
    }
  
    
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    if (!this.isVatRegistered) {
      this.displayProductDetailsColumns =
        this.displayProductDetailsColumns.filter(
          (column) =>
            column !== 'vatrate' &&
            column !== 'vatammount' &&
            column !== 'amount'
        );
    }
    this.moduleId$.subscribe((x) => {
      if (x > 0) {
        this.productText =
          x === Modules.FixedAssets ? 'Product' : 'Product / Service';
        this.moduleId = x;
        this.setProductDetailsForm(true);
      }
    });

    this.triggerisVatIncluded?.subscribe((data) => {
      this.isVatIncuded = data;
      this.UpdateVatProduct();
    });

    this.triggerisHlobalDiscountChecked?.subscribe((data) => {
      this.isGlobalChecked = data;
      
    });

    this.triggerOnBranchChange?.subscribe((data) => {
      this.branchId = data;
      this.loadDropdownValues(false, true);
    });

    this.totalAmount = 0;

    this.triggereEditProductData
      ?.pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data.isVATSelected !== undefined && data.isVATSelected !== null) {
          this.isVATChecked = data.isVATSelected;
        }
        if (
          this.moduleId === Modules.RecurringInvoice ||
          this.moduleId === Modules.RecurringPurchase
        ) {
          this.isMultiCurrencyOption = false;
        }
        
        this.loadDropdownValues(false);
        setTimeout(() => {
          this.editProduct(data.productData);
        }, 500);
       
      });

    // this.triggerAccountSelectedOption?.subscribe((data) => {
    //   this.accountName = data.name;
    //   this.vatRateList = this.originalVatRateList;
    //   this.productDetailArray.controls.forEach((x, index) => {
    //     (Object as any).values(x.controls).forEach((c) => {
    //       x.controls.vatRate.setValue(null);
    //       x.controls.vatAmount.setValue(0);
    //     });
    //   });
    //   if (
    //     Object.values(AccountNumber).some(
    //       (constant) => constant === this.accountName
    //     )
    //   ) {
    //     this.productDetailArray.controls.forEach((x, index) => {
    //       this.qtyChanges(index);
    //     });
    //   }
    //   if (!this.isVATChecked) {
    //     this.filterVatRatesBasedOnAccount();
    //   } else {
    //     this.filterVatRatesBasedOnCISVAT();
    //     this.filterVatRatesBasedOnAccount();
    //   }
    // });
    this.triggerSelectedCurrencyRate?.subscribe((data) => {
      if (data) {
        this.previousSelectedCurrencyRate = data;
      }
    });
    this.triggerCurrencySelectedOption?.subscribe((data) => {
      if (data) {
        this.selectedCurrencyRate = data;
        this.productDetailArray.controls.forEach((x, index) => {
          this.onCurrencyChanges(index);
        });
        this.previousSelectedCurrencyRate = this.selectedCurrencyRate;
      }
    });
    this.triggerCurrencyRefreshOption?.subscribe((data) => {
      if (data) {
        this.selectedCurrencyRate = data;
        this.isCurrencyRefresh = true;
        this.productDetailArray.controls.forEach((x, index) => {
          this.onCurrencyChanges(index);
        });
        this.previousSelectedCurrencyRate = this.selectedCurrencyRate;
      }
    });

    this.triggerOnVATChange?.subscribe((data) => {
      this.isVATChecked = data;
      if (data) {
        this.filterVatRatesBasedOnCISVAT();
setTimeout(() => {
  this.productDetailArray.controls.forEach((x, index) => {
    (Object as any).values(x.controls).forEach((c) => {
     // console.log(this.vatRateList[index][0]['id'],'this.vatRateList[index][0]')
      x.controls.vatRate.setValue(this.vatRateList[index][0]['id']);
      //x.controls.vatAmount.setValue(0);
    });
    this.qtyChanges(index);
  });
}, 500);
        
      } else {
        // this.vatRateList[0] = this.originalVatRateList;
        // if (
        //   Object.values(AccountNumber).some(
        //     (constant) => constant === this.accountName
        //   )
        // ) {
        //   this.filterVatRatesBasedOnAccount(0);
        // }
        this.productDetailArray.controls.forEach((x, index) => {
          (Object as any).values(x.controls).forEach((c) => {
            this.onOptionSelected(index)
            //this.qtyChanges(index);
          });
        });
      }
    });

    this.formProductDetail?.valueChanges?.subscribe((value) => {
      this.commonService.isInitialValueChange = this.formProductDetail.touched;
    });

    // this.triggerOnDiscountChange?.subscribe((data) => {

      
    //   if((data['value'] !== this.discountVal) || (data['type'] !== this.discountPers)){
    //     this.discountVal=data['value'];
    //     this.discountPers=data['type'];
    //     this.discountCalc(data);
    //   }
    
    // });
   
  }

  addNewProduct(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Product,
          headerText: `Add ${ModuleName.Product}`,
          saveActionName: CreateProduct,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.loadDropdownValues(false);
        }
      });
  }

  filterVatRatesBasedOnCISVAT(): void {
    this.productDetailArray.controls.forEach((x, index) => {
      (Object as any).values(x.controls).forEach((c) => {
        this.vatRateList[index] = this.originalVatRateList.filter(
          (item) =>
            item.isStandard &&
            (item.rate === 5 || item.rate === 20) &&
            item.codeType === VatRateCodeType.Normal
        );
      });
     
    });

   
  }

  filterVatRatesBasedOnAccount(i:number,vatId?:any): void {

    if(this.isVATChecked){
      this.vatRateList[i] = this.originalVatRateList.filter(
        (item) =>
          item.isStandard &&
          (item.rate === 5 || item.rate === 20) &&
          item.codeType === VatRateCodeType.Normal
      );
    

  
    }else{
     

    if (AccountNumber.ACCOUNT_8215 === this.accountName) {
      this.vatRateList[i] = this.originalVatRateList.filter(
        (item) =>
          item.isStandard &&
          item.codeType === 5 &&
          item.rate === 0 &&
          item.abbreviation === VatName.Import_Vat
      );
    } else if (
      AccountNumber.ACCOUNT_1274 === this.accountName ||
      AccountNumber.ACCOUNT_1014 === this.accountName
    ) {
      this.vatRateList[i] = this.originalVatRateList.filter(
        (item) =>
          item.isStandard &&
          item.codeType === 3 &&
          ((item.rate === 0 && item.abbreviation === VatName.EU) ||
            (item.rate === 20 && item.abbreviation === VatName.EU_G))
      );
    } else if (
      AccountNumber.ACCOUNT_1275 === this.accountName ||
      AccountNumber.ACCOUNT_1016 === this.accountName
    ) {
      this.vatRateList[i] = this.originalVatRateList.filter(
        (item) =>
          item.isStandard &&
          item.codeType === 3 &&
          ((item.rate === 0 && item.abbreviation === VatName.EU) ||
            (item.rate === 20 && item.abbreviation === VatName.EU_S))
      );
    } else {
      this.vatRateList[i] = this.originalVatRateList.filter(
        (item) =>
          !(
            (item.rate === 0 &&
              item.codeType === 5 &&
              item.abbreviation === VatName.Import_Vat) ||
            (item.isStandard &&
              item.codeType === 3 &&
              ((item.rate === 0 && item.abbreviation === VatName.EU) ||
                (item.rate === 20 &&
                  (item.abbreviation === VatName.EU_G ||
                    item.abbreviation === VatName.EU_S))))
          )
      );
    }
  }

  if(vatId){
    this.productDetailArray.controls[i].get('vatRate').setValue(vatId);
  }

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changed_input(index): void {
    const color_index = this.productTypeList.findIndex((color_option) => {
      return (
        color_option.id ===
        this.productDetailArray.controls[index].get('productName').value.id
      );
    });
    if (color_index === -1) return;
    this.matAutocompleteRef.panel.nativeElement.scrollTop = 48 * color_index;
  }

  amountChange(): void {
    const formArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;
    this.totalAmount = 0;
    this.postponedVatAmount = 0;
    formArray?.getRawValue().forEach((x) => {
      this.totalAmount = (+this.totalAmount) + (+x.amount);
      this.postponedVatAmount =
        (+this.postponedVatAmount) +(+x.postponedVatAmount) ?? 0;
    });
    this.triggerNewProductAdded.emit(this.totalAmount);
  }

  loadDropdownValues(
    isAddMode: boolean,
    isBranchChange: boolean = false
  ): void {
    const vatRateList = this.store.dispatch(new GetVatRateList());
    let productList;
    if (this.branchId !== undefined && this.branchId !== null) {
      productList = this.store.dispatch(
        new GetProductListByProductTypeByBranch(
          this.getProductType(this.moduleId),
          this.branchId
        )
      );
    } else {
      productList = this.store.dispatch(
        new GetProductListByProductType(this.getProductType(this.moduleId))
      );
    }

    forkJoin([vatRateList, productList]).subscribe((res) => {
      this.productTypeList = this.store.selectSnapshot(ProductState.getProduct);
      this.productTypeData = this.productTypeList;
     
      this.vatRateList.push(this.store.selectSnapshot(CommonState.getVatRate));
      this.originalVatRateList=this.store.selectSnapshot(CommonState.getVatRate);
      
      if (this.productTypeList.length > 0 && isAddMode) {
        this.productDetailArray.controls[this.productDetailArray.length - 1]
          .get('productName')
          ?.setValue('');
      }

      if (this.isVATChecked) {
        this.vatRateList[0] = this.originalVatRateList.filter(
          (rate) =>
            rate.name.includes('Standard Rated(20.00%)') ||
            rate.name.includes('Reduced Rated(5.00%)')
        );

        this.filterVatRatesBasedOnAccount(0);
      } else {
        this.vatRateList[0] = this.originalVatRateList;

        this.filterVatRatesBasedOnAccount(0);
      }

      if (isBranchChange) {
        this.validateProductExist();
      }
    });
  }

  resetForm(): void {
    const formArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;
    formArray.clear();
  }

  clearForm(): void {
    this.dialog
      .open(CleanAllLinesComponent)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const formArray = this.formProductDetail.get(
            'productDetailArray'
          ) as UntypedFormArray;
          for (let i = 0; i < formArray.length; i++) {
            formArray.controls[i].reset();
            formArray.controls[i]
              .get('id')
              ?.setValue(Guid.EMPTY as unknown as Guid);
            formArray.controls[i].get('price')?.setValue(0);
            formArray.controls[i].get('account')?.setValue(0);
            formArray.controls[i].get('vatRate')?.setValue(null);
            formArray.controls[i].get('vatAmount')?.setValue(0);
            formArray.controls[i].get('netAmount')?.setValue(0);
            formArray.controls[i].get('amount')?.setValue(0);
            formArray.controls[i].get('productId')?.setValue(0);
            formArray.controls[i].get('productName')?.setValue('');
            formArray.controls[i].get('invoiceType')?.setValue('');
            this.setValidation(formArray, i);
          }

          this.totalAmount = 0;
        }
      });
  }

  setDisable(index: number): void {
    this.productDetailArray.controls[index].get('amount').disable();
    this.productDetailArray.controls[index].get('netAmount').disable();
  }

  setValidation(formArray, index): void {
    formArray.controls[index]
      .get('productName')
      ?.setValidators(Validators.required);

    formArray.controls[index].get('price')?.setValidators(Validators.required);

    formArray.controls[index].get('amount')?.setValidators(Validators.required);

    formArray.controls[index].get('price')?.updateValueAndValidity();
    formArray.controls[index].get('amount')?.updateValueAndValidity();
    formArray.controls[index].get('productName')?.updateValueAndValidity();
  }

  setProductDetailsForm(addNewRow: boolean): void {
    this.formProductDetail = new FormGroup({
      productDetailArray: new UntypedFormArray([]),
    });

    this.productDetailArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;

    this.setDataSource(this.productDetailArray);
    if (addNewRow) this.createRow();
  }

  createRow(): void {
    this.productDetailArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;

    this.productDetailArray.push(this.setForm());

    if (this.productDetailArray.length > 0) {
      this.loadDropdownValues(true);
      this.setDisable(this.productDetailArray.length - 1);
    }

    this.setDataSource(this.productDetailArray);
    this.triggerNewProductAdded.emit(this.totalAmount);
  }

  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      description: new FormControl<string | null>(''),
      qty: new FormControl<string | null>(''),
      price: new FormControl<number | null>(0, Validators.required),
      invoiceType: new FormControl('', [Validators.required]),
      vatRate: new FormControl<number | null>(null),
      netAmount: new FormControl<number | null>(0),
      vatAmount: new FormControl<number | null>(0),
      postponedVatAmount: new FormControl<number | null>(0),
      amount: new FormControl<number | null>(0, Validators.required),
      productName: new FormControl<string | null>('', [
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
      discountType: new FormControl<number | null>(DiscountType.Value),
      discountDescription: new FormControl<string | null>(''),
      discountValue: new FormControl<number | any>(0),
    });
  }

  onAddDiscountOnProductDetails(index: number): void {
    this.discountIndex.push(index);
  }

  onDeleteDiscountOnProductDetails(index: number): void {
    if (this.discountIndex.includes(index)) {
      this.discountIndex.splice(this.discountIndex.indexOf(index), 1);
    }
    // this.vatRateList.splice(index, 1);
    this.productDetailArray.controls[index]
      .get('discountDescription')
      .setValue('');
    this.productDetailArray.controls[index].get('discountValue').setValue(0);
    this.qtyChanges(index);
  }

  onDeleteProductDetails(index: number): void {
    this.productDetailArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;
    if (this.productDetailArray.length === 1) {
      return;
    }
    this.productDetailArray.removeAt(index);
    this.triggerVatRateChange.emit(true);
    this.setDataSource(this.productDetailArray);
    this.amountChange();
    this.onDeleteDiscountOnProductDetails(index);
   

  }

  editProduct(data: any): void {
    this.productDetailArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;

    this.productDetailArray.clear();

    data.forEach((item, i) => {
      this.productDetailArray.push(this.buildOrderItemsForm(item,i));
      this.setFormValidity(true, i);
      setTimeout(() => {
        this.qtyChanges(i);
      }, 800);
     // this.qtyChanges(i);
    });

    this.setDataSource(this.productDetailArray);
    this.amountChange();
  }

  buildOrderItemsForm(item: any,i:number): FormGroup {

    
    let account;
    if(this.isFixedAsset){
      account = item.bookAccountId;
    }else{
     
      this.accountGroupList.forEach((items) => {
        const accountId = items.listModels.find(
          (x: any) => x.id === item.bookAccountId
        );
        if (accountId !== null && accountId !== undefined) {
          account = accountId;
        }
  
        
      });
    }
    
    
    const form = this.formBuilder.group({
      id: item.id,
      productName:
        item.productId === this.defaultGuidValue ? '' : item.productId,
      description: item.description,
      qty: item.quantity !== 0 ? item.quantity : '',
      price: !this.isMultiCurrencyOption ? item.price : item.foreignPrice,
      vatRate: item.vatRateId !== null ? item.vatRateId : null,
      vatAmount: !this.isMultiCurrencyOption
        ? item.vatAmount.toFixed(2)
        : item.foreignVatAmount.toFixed(2),
      amount: !this.isMultiCurrencyOption
        ? item.totalAmount
        : item.foreignTotalAmount,
      netAmount:
        (!this.isMultiCurrencyOption ? +item.price : +item.foreignPrice) *
        +(item.quantity !== 0 ? item.quantity : 1),
      discountDescription: item.discountDescription,
      discountValue: !this.isMultiCurrencyOption
        ? item.discount
        : item.foreignDiscount,
      discountType: item.isDiscountPercentage
        ? DiscountType.Percentage
        : DiscountType.Value,
        invoiceType: account,
      
    });
    form.controls.netAmount.disable();
    setTimeout(() => {
      this.onOptionSelectedByEditRecord(i,item.vatRateId);
    }, 500);
   
    return form;
  }

  onProductChanged(event: any, index: number): any {
    const currentValue = event.currentTarget.value.trimEnd().toLowerCase();
    const data = this.productTypeData.filter(
      (x) => x.name.trimEnd().toLowerCase() === currentValue
    );

    if (data.length > 0 && currentValue !== '') {
      const param = {
        id: data[0].id,
      };
      this.productDetailArray.controls[index]
        .get('productName')
        ?.setValue(data[0]);
      this.onProductChange(param, index);
    } else {
      this.onProductSearch(event, index);
    }
  }

  resetAccountList(element: any): void {
    this.productTypeList = this.productTypeData;
    if (element.productName !== undefined) {
      this.scrollIntoView(element.productName.value);
    }
  }
  

  onProductSearch(event: any, index: number): any {
    this.productDetailArray.controls[index].get('price').reset();
    this.productDetailArray.controls[index].get('description').reset();
    this.productDetailArray.controls[index].get('vatRate').reset();
    this.productDetailArray.controls[index].get('netAmount').reset();
    this.productDetailArray.controls[index].get('vatAmount').reset();
    this.productDetailArray.controls[index].get('amount').reset();
    this.setFormValidity(false, index);
    this.productTypeList = this.productTypeData;
    const projectSearhText = event.currentTarget.value;
    const selectedArray: ProductModel[] = [];

    this.productTypeList.forEach((item) => {
      if (item.name.toLowerCase().includes(projectSearhText.toLowerCase())) {
        selectedArray.push(item);
      }
    });

    if (projectSearhText) {
      this.productTypeList = selectedArray;
    }

    if (this.productDetailArray.controls[index].get('productName').invalid) {
      this.productDetailArray.controls[index].get('productName').setValue('');
    }
  }

  displayFn(product: any): string {
    return product && product.name ? product.name : '';
  }

  UpdateVatProduct(): void {
    this.productDetailArray = this.formProductDetail.get(
      'productDetailArray'
    ) as UntypedFormArray;
    this.productDetailArray?.controls.forEach((element, i) => {
      //discount code start
      let disValue = 0;
      const discountType =
        this.productDetailArray.controls[i].get('discountType').value;
      let discountValue =
        this.productDetailArray.controls[i].get('discountValue').value;
      if (discountValue > 100 && discountType === DiscountType.Percentage) {
        this.productDetailArray.controls[i].get('discountValue').setValue(0);
        discountValue = 0;
      }
      //discount code end
      const price = this.productDetailArray.controls[i].get('price').value;
      const quantity =
        this.productDetailArray.controls[i].get('qty').value === '' ||
        this.productDetailArray.controls[i].get('qty').value === null
          ? 1
          : +this.productDetailArray.controls[i].get('qty').value;
      //discount code start
      if (discountType === DiscountType.Value) {
        disValue = discountValue;
      } else {
        disValue = (quantity * price * discountValue) / 100;
      }
      //discount code end
      if (
        element.controls.vatRate.value !== undefined &&
        element.controls.vatRate.value !== null &&
        element.controls.vatRate.value !== -1
      ) {
        const data = this.vatRateList[i].filter(
          (x) =>
            x.id === this.productDetailArray.controls[i].get('vatRate').value
        );

        if (data.length > 0) {
          this.vatRate = data[0].rate;
        }

        let vatAmount;
        let amount;
        let vatRate;
        let totalNetAmount;

        if (this.isVatIncuded) {
          if (data[0].codeType === VatRateCodeType.Custom) {
            vatAmount = Number(
              this.productDetailArray.controls[i].get('vatAmount').value
            );
            totalNetAmount = Number(+quantity * +price - vatAmount);
            amount = Number(+quantity * +price);
          } else {
            vatRate = this.vatRate === undefined ? 1 : this.vatRate;
            vatAmount = (
              ((+price * +quantity - disValue) * vatRate) /
              (100 + vatRate)
            ).toFixed(2);
            amount = (+quantity * +price - disValue).toFixed(2);
            totalNetAmount = (
              +price * +quantity -
              disValue -
              vatAmount
            ).toFixed(2);
          }
        } else {
          if (data[0].codeType === VatRateCodeType.Custom) {
            vatAmount = Number(
              this.productDetailArray.controls[i].get('vatAmount').value
            );
            amount = Number(+quantity * +price + vatAmount);
            totalNetAmount = Number(quantity * +price);
          } else {
            vatRate = this.vatRate === undefined ? 1 : this.vatRate;
            vatAmount = ((+price * +quantity - disValue) * vatRate) / 100;
            amount = +quantity * +price - disValue + vatAmount;
            totalNetAmount = quantity * +price - disValue;
          }
        }

        this.productDetailArray.controls[i]
          .get('netAmount')
          ?.setValue(totalNetAmount);

        this.productDetailArray.controls[i]
          .get('vatAmount')
          ?.setValue(Number(vatAmount).toFixed(2));
        this.productDetailArray.controls[i].get('amount')?.setValue(amount);
      } else {
        const amount = quantity * price;

        this.productDetailArray.controls[i].get('vatAmount')?.setValue(0);
        this.productDetailArray.controls[i].get('amount')?.setValue(amount);
        this.productDetailArray.controls[i].get('netAmount')?.setValue(amount);

        if (amount.toString().length > 17) {
          this.commonService.onFailure(
            NotificationTextMessage.amountErrorMessage
          );
        }
      }
    });
    this.amountChange();
  }

  vatAmountChanges(index: any): void {
    let GrossAmount;
    let totalNetAmount;
    const Price = this.productDetailArray.controls[index].get('price').value;
    const VatAmount =
      this.productDetailArray.controls[index].get('vatAmount').value;
    let qty = this.productDetailArray.controls[index].get('qty').value;
    qty = qty === '' ? 1 : qty;
    const data = this.vatRateList.filter(
      (x) =>
        x.id === this.productDetailArray.controls[index].get('vatRate').value
    );

    if (this.isVatIncuded) {
      if (data[0].codeType === VatRateCodeType.Custom) {
        totalNetAmount = Number(+qty * +Price - VatAmount);
        GrossAmount = Number(+qty * +Price);
        this.productDetailArray.controls[index]
          .get('amount')
          ?.setValue(GrossAmount);
        this.productDetailArray.controls[index]
          .get('netAmount')
          ?.setValue(totalNetAmount);
      }
    } else {
      const VatAmount =
        this.productDetailArray.controls[index].get('vatAmount').value;
      const NetAmount =
        this.productDetailArray.controls[index].get('netAmount').value;
      const amount = +VatAmount + +NetAmount;
      this.productDetailArray.controls[index].get('amount')?.setValue(amount);
    }
    this.amountChange();
  }

  qtyChanges(index: any): void {
    let disValue = 0;
    const price = this.productDetailArray.controls[index].get('price').value;
    const discountType =
      this.productDetailArray.controls[index].get('discountType').value;

    if (price === 0 || price === '') {
      this.productDetailArray.controls[index].get('discountValue').setValue(0);
    }
    let discountValue =
      this.productDetailArray.controls[index].get('discountValue').value;
    if (discountValue > 100 && discountType === DiscountType.Percentage) {
      this.productDetailArray.controls[index].get('discountValue').setValue(0);
      discountValue = 0;
    }
    const quantity =
      this.productDetailArray.controls[index].get('qty').value === '' ||
      this.productDetailArray.controls[index].get('qty').value === null
        ? 1
        : +this.productDetailArray.controls[index].get('qty').value;

    if (discountType === DiscountType.Value) {
      disValue = discountValue;
    } else {
      disValue = (quantity * price * discountValue) / 100;
    }

    const totalNetAmount = (quantity * price) - disValue;
    this.productDetailArray.controls[index]
      .get('netAmount')
      ?.setValue(totalNetAmount);

    if (
      this.productDetailArray.controls[index].get('vatRate').value !==
        undefined &&
      this.productDetailArray.controls[index].get('vatRate').value != null &&
      this.productDetailArray.controls[index].get('vatRate').value !== -1
    ) {
      const data = this.vatRateList[index]?.filter(
        (x) =>
          x.id === this.productDetailArray.controls[index].get('vatRate').value
      );

      if (data?.length > 0) {
        if (data[0].codeType === VatRateCodeType.Custom) {
          this.productDetailArray.controls[index].get('vatAmount').enable();
          this.customVatAmount = Number(
            this.productDetailArray.controls[index].get('vatAmount').value
          );
        } else {
          this.productDetailArray.controls[index].get('vatAmount').disable();
        }

        this.vatRate = data[0].rate;
        this.vatName = data[0].name;
      } else {
        this.productDetailArray.controls[index].get('vatRate')?.setValue(null);
      }

      let vatAmount;
      let postponedVatAmount;
      let amount;
      let vatRate;
      let totalNetAmount;
      if (data?.length > 0) {
      if (this.isVatIncuded) {
        vatRate = this.vatRate === undefined ? 1 : this.vatRate;
        vatAmount = this.isVATChecked
          ? 0
          : !(data[0].codeType === VatRateCodeType.Custom)
          ? (
              ((+price * +quantity - disValue) * vatRate) /
              (100 + vatRate)
            ).toFixed(2)
          : this.customVatAmount;

        postponedVatAmount = this.isVATChecked
          ? !(data[0].codeType === VatRateCodeType.Custom)
            ? ((+price * +quantity - disValue) * vatRate) / 100
            : 0
          : 0;
        amount = (+quantity * +price - disValue).toFixed(2);
        totalNetAmount = (+price * +quantity - disValue - vatAmount).toFixed(2);
      } else {
        vatRate =
          this.vatRate === undefined
            ? 1
            : this.vatName !== 'Import Reverse Charge(20.00%)' &&
              this.vatName !== 'Reverse Charge(20.00%)'
            ? this.vatRate
            : 0;
        vatAmount = this.isVATChecked
          ? 0
          : this.vatRate === undefined ||
            this.vatRate === null ||
            this.vatRate === -1
          ? 0
          : !(data[0].codeType === VatRateCodeType.Custom)
          ? ((+price * +quantity - disValue) * vatRate) / 100
          : this.customVatAmount;
        postponedVatAmount = this.isVATChecked
          ? !(data[0].codeType === VatRateCodeType.Custom)
            ? ((+price * +quantity - disValue) * vatRate) / 100
            : 0
          : 0;
       
        amount = ((+quantity * +price -(+disValue)) + vatAmount).toFixed(2);
        totalNetAmount = quantity * +price - (+disValue);
      }
    }

      this.productDetailArray.controls[index]
        .get('netAmount')
        ?.setValue(totalNetAmount);

      this.productDetailArray.controls[index]
        .get('vatAmount')
        ?.setValue(Number(vatAmount).toFixed(2));

      this.productDetailArray.controls[index]
        .get('postponedVatAmount')
        ?.setValue(postponedVatAmount);

      this.productDetailArray.controls[index].get('amount')?.setValue(amount);
      this.amountChange();
    } else {
      const amount = (quantity * price - disValue).toFixed(2);;

      this.productDetailArray.controls[index].get('vatAmount')?.setValue(0);
      this.productDetailArray.controls[index].get('amount')?.setValue(amount);
      this.productDetailArray.controls[index]
        .get('netAmount')
        ?.setValue(amount);

      this.productDetailArray.controls[index]
        .get('postponedVatAmount')
        ?.setValue(0);
      this.amountChange();
    }
  }

  setFormValidity(isSelect: boolean, index: number): void {
    if (!isSelect) {
      this.productDetailArray.controls[index].get('vatAmount')?.setValue(0);
      this.productDetailArray.controls[index].get('amount')?.setValue(0);
      this.productDetailArray.controls[index].get('vatRate')?.setValue();
      this.productDetailArray.controls[index].get('netAmount')?.setValue();
    }
    this.productDetailArray.controls[index].get('price').enable();
    this.productDetailArray.controls[index].get('description').enable();
    this.productDetailArray.controls[index].get('vatRate').enable();
    this.productDetailArray.controls[index].get('vatAmount').disable();
    this.productDetailArray.controls[index].get('amount').disable();
  }

  scrollIntoView(element) {
    if (
      element.value.productName !== '' &&
      element.value.productName !== null &&
      element.value.productName !== undefined
    ) {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  onProductChange(event: any, index: any): void {
    if (event !== undefined && event !== null && event !== '-1') {
      this.store
        .dispatch(new GetProductData(event))
        .pipe(
          tap((res) => {
            this.productDetailArray.controls[index].get('qty')?.setValue(1);
            this.productDetailArray.controls[index]
              .get('price')
              ?.setValue(res.common.productDetails.price);
            this.productDetailArray.controls[index]
              .get('description')
              ?.setValue(res.common.productDetails.description);
            // this.productDetailArray.controls[index]
            //   .get('vatRate')
           // //   ?.setValue(
            //     res.common.productDetails.vatRateId === null ||
            //       this.isVATChecked
           // //       ? null
            //       : res.common.productDetails.vatRateId
            //   );
           // this.qtyChanges(index);
            this.setFormValidity(true, index);
          })
        )
        .subscribe();
    } else {
      this.clearForm();
      this.productDetailArray.controls[this.productDetailArray.length - 1]
        .get('productName')
        ?.setValue('');
      this.productDetailArray.controls[index].get('vatAmount').enable();
      this.productDetailArray.controls[index].get('amount').enable();
    }
  }

  getProductType(module: Modules): any {
    switch (module) {
      case Modules.FixedAssets:
        return ProductType.Asset;
      default:
        return undefined;
    }
  }
  onCurrencyChanges(index: any): void {
    let disValue = 0;
    if (this.previousSelectedCurrencyRate != undefined) {
      this.isPreviousRate = true;
    } else {
      this.isPreviousRate = false;
    }

    const price = this.isPreviousRate
      ? (this.productDetailArray.controls[index].get('price').value /
          this.previousSelectedCurrencyRate) *
        this.selectedCurrencyRate
      : this.productDetailArray.controls[index].get('price').value *
        this.selectedCurrencyRate;
    this.productDetailArray.controls[index]
      .get('price')
      ?.setValue(price.toFixed(2));

    const discountType =
      this.productDetailArray.controls[index].get('discountType').value;

    let discountValue =
      this.productDetailArray.controls[index].get('discountValue').value;
    if (discountType === DiscountType.Value) {
      discountValue = this.isPreviousRate
        ? (this.productDetailArray.controls[index].get('discountValue').value /
            this.previousSelectedCurrencyRate) *
          this.selectedCurrencyRate
        : this.productDetailArray.controls[index].get('discountValue').value *
          this.selectedCurrencyRate;
      this.productDetailArray.controls[index]
        .get('discountValue')
        ?.setValue(discountValue.toFixed(2));
    }

    if (discountValue > 100 && discountType === DiscountType.Percentage) {
      this.productDetailArray.controls[index].get('discountValue').setValue(0);
    }

    if (discountType === DiscountType.Value) {
      disValue = discountValue;
    } else {
      disValue = (price * discountValue) / 100;
    }
    const quantity =
      this.productDetailArray.controls[index].get('qty').value === '' ||
      this.productDetailArray.controls[index].get('qty').value === null
        ? 1
        : +this.productDetailArray.controls[index].get('qty').value;

    const totalNetAmount = quantity * price - disValue;
    this.productDetailArray.controls[index]
      .get('netAmount')
      ?.setValue(totalNetAmount);

    if (
      this.productDetailArray.controls[index].get('vatRate').value !==
        undefined &&
      this.productDetailArray.controls[index].get('vatRate').value != null &&
      this.productDetailArray.controls[index].get('vatRate').value !== -1
    ) {
      const data = this.vatRateList.filter(
        (x) =>
          x.id === this.productDetailArray.controls[index].get('vatRate').value
      );

      if (data[0].codeType === VatRateCodeType.Custom) {
        this.productDetailArray.controls[index].get('vatAmount').enable();
        this.customVatAmount = Number(
          this.productDetailArray.controls[index].get('vatAmount').value
        );
      } else {
        this.productDetailArray.controls[index].get('vatAmount').disable();
      }

      if (data.length > 0) {
        this.vatRate = data[0].rate;
      }

      let vatAmount;
      let amount;
      let vatRate;
      let totalNetAmount;

      if (this.isVatIncuded) {
        vatRate = this.vatRate === undefined ? 1 : this.vatRate;
        vatAmount = this.isVATChecked
          ? 0
          : !(data[0].codeType === VatRateCodeType.Custom)
          ? (
              (+price * +quantity * vatRate - disValue) /
              (100 + vatRate)
            ).toFixed(2)
          : this.customVatAmount;
        amount = (+quantity * +price - disValue).toFixed(2);
        totalNetAmount = (+price * +quantity - disValue - vatAmount).toFixed(2);
      } else {
        vatRate = this.vatRate === undefined ? 1 : this.vatRate;
        vatAmount = this.isVATChecked
          ? 0
          : !(data[0].codeType === VatRateCodeType.Custom)
          ? ((+price * +quantity - disValue) * vatRate) / 100
          : this.customVatAmount;
        amount = +quantity * +price - disValue + vatAmount;
        totalNetAmount = quantity * +price - disValue;
      }

      this.productDetailArray.controls[index]
        .get('netAmount')
        ?.setValue(totalNetAmount);

      this.productDetailArray.controls[index]
        .get('vatAmount')
        ?.setValue(vatAmount.toFixed(2));

      this.productDetailArray.controls[index].get('amount')?.setValue(amount);
      this.amountChange();
    } else {
      const amount = quantity * price - disValue;

      this.productDetailArray.controls[index].get('vatAmount')?.setValue(0);
      this.productDetailArray.controls[index].get('amount')?.setValue(amount);
      this.productDetailArray.controls[index]
        .get('netAmount')
        ?.setValue(amount);
      this.amountChange();
    }
  }

  validateProductExist(): void {
    for (let i = 0; i < this.productDetailArray.length; i++) {
      if (
        this.productDetailArray.controls[i].get('productName')?.value !==
          null &&
        this.productDetailArray.controls[i].get('productName')?.value !== null
      ) {
        if (
          this.productTypeList.filter(
            (x) =>
              x.id ===
              this.productDetailArray.controls[i].get('productName')?.value
          ).length <= 0
        ) {
          this.productDetailArray.controls[i].get('productName')?.setValue({
            name: this.productDetailArray.controls[i].get('productName')?.value
              .id,
          });
        }
      } else {
        this.productDetailArray.controls[i].get('productName')?.setValue({
          name: this.productDetailArray.controls[i].get('productName')?.value
            .id,
        });
      }
      this.productDetailArray.controls[i].get('productId')?.setValue(0);
    }
  }
  getInvoiceType(): void {
    const groupNames = new Array<number>();
    if(this.isSales){
      groupNames.push(GroupNames.Turnover);
    }else{
      groupNames.push(GroupNames.CostOfSales);
      groupNames.push(GroupNames.SellingAndDistributionCosts);
      groupNames.push(GroupNames.AdministrativeExpenses);
    }
    this.store
      .dispatch(new GetGroupAccountsBasedOnGroupId(groupNames, this.moduleId))
      .pipe(
        tap((res) => {
          if (res.common.accountGroupList.length > 0) {
            this.accountGroupList = res.common.accountGroupList;
            this.tempAccountGroupList = this.accountGroupList;
          }
        })
      )
      .subscribe();
  }

  onAccountSearch(event: any,i:number): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    this.accountGroupList = list;
  }
  onSearch(event: any,i:number): void {
    const list = this.commonService.onAccountSearch(
      event,
      this.tempAccountGroupList
    );
    if (list.length === 0) {
      this.productDetailArray.controls[i].get('invoiceType')?.setValue('');
      //this.invoiceForm.controls.invoiceType.setValue('');
      this.accountGroupList = this.tempAccountGroupList;
    }
  }

  resetAccountListByAccount(i:number): void {
    this.accountGroupList = this.tempAccountGroupList;
    this.scrollIntoView(this.productDetailArray.controls[i].get('invoiceType')?.value);
  }

  getOptionText(option) {
    return option.name;
  }

  scrollIntoViewByAccount(i:number) {
    if (this.productDetailArray.controls[i].get('invoiceType')?.value) {
      this.commonService.autoScrollMatAutoComplete(this.renderer);
    }
  }

  onOptionSelectedByEditRecord(i:number,vatId:any) {
   
    const selectedValue = this.productDetailArray?.controls[i].get('invoiceType')?.value; // Get the selected value from the event
    //this.triggerAccountSelectedOption.emit(selectedValue);


  //  this.triggerAccountSelectedOption?.subscribe((data) => {
      this.accountName = selectedValue?.name;
      this.vatRateList[i] = this.originalVatRateList;
      // this.productDetailArray.controls.forEach((x, index) => {
      //   (Object as any).values(x.controls).forEach((c) => {
      //     x.controls.vatRate.setValue(null);
      //     x.controls.vatAmount.setValue(0);
      //   });
      // });

      // this.productDetailArray.controls[i].get('vatRate')?.setValue(null);
      // this.productDetailArray.controls[i].get('vatAmount')?.setValue(0);
      // if (
      //   Object.values(AccountNumber).some(
      //     (constant) => constant === this.accountName
      //   )
      // ) {
      //   this.productDetailArray.controls.forEach((x, index) => {
      //     this.qtyChanges(index);
      //   });
      // }
      if (!this.isVATChecked) {
        this.filterVatRatesBasedOnAccount(i,vatId);
      } else {
        this.filterVatRatesBasedOnCISVAT();
        this.filterVatRatesBasedOnAccount(i,vatId);
      }

  }

  onOptionSelected(i:number) {
  
    const selectedValue = this.productDetailArray?.controls[i].get('invoiceType')?.value; // Get the selected value from the event
    //this.triggerAccountSelectedOption.emit(selectedValue);


  //  this.triggerAccountSelectedOption?.subscribe((data) => {
      this.accountName = selectedValue.name;
      // if(this.isVATChecked){
      //   this.vatRateList[i] = this.originalVatRateList.filter(
      //     (item) =>
      //       item.isStandard &&
      //       (item.rate === 5 || item.rate === 20) &&
      //       item.codeType === VatRateCodeType.Normal
      //   );
      

    
      // }else{
      //   this.vatRateList[i] = this.originalVatRateList;
      // }
      
      // this.productDetailArray.controls.forEach((x, index) => {
      //   (Object as any).values(x.controls).forEach((c) => {
      //     x.controls.vatRate.setValue(null);
      //     x.controls.vatAmount.setValue(0);
      //   });
      // });

      //this.productDetailArray.controls[i].get('vatRate')?.setValue(null);
      //this.productDetailArray.controls[i].get('vatAmount')?.setValue(0);
      // if (
      //   Object.values(AccountNumber).some(
      //     (constant) => constant === this.accountName
      //   )
      // ) {
      //   this.productDetailArray.controls.forEach((x, index) => {
      //     this.qtyChanges(index);
      //   });
      // }
      if (!this.isVATChecked) {
        this.filterVatRatesBasedOnAccount(i);
      } else {
        this.filterVatRatesBasedOnCISVAT();
        this.filterVatRatesBasedOnAccount(i);
      }

  }

  getFixedInvoiceType(): void {
    this.store
      .dispatch(new GetFixedAssetAdditionList())
      .pipe(
        tap((res) => {
          this.fixedAssetsTypeList =
            this.commonService.addGroupNameToListModels(res.account.accounts);
        })
      )
      .subscribe();
  }

  isVatListSame():boolean{
   
   
    let list:any=[];
    this.productDetailArray.controls.forEach((x,index) => {
      list.push(this.productDetailArray.controls[index].get('vatRate').value)
    });
    if(list.length > 1){
   let isSame=list.every( (item:any) => item === list[0]);
//    if(!isSame){
//     this.productDetailArray.controls.forEach((x,index) => {
  
//       // const totalNetAmount = (+this.productDetailArray.controls[index].get('qty').value) * (+this.productDetailArray.controls[index].get('price').value) - disValue;
//        //console.log(totalNetAmount,'totalNetAmount')
//        //this.productDetailArray.controls[index].get('netAmount').setValue(totalNetAmount);
//       this.productDetailArray.controls[index].get('discountValue').setValue(0);
    
// this.qtyChanges(index);
     
  
//      });
//    }
     let disableIndDiscount:boolean;
        if(isSame ){
        if(this.isGlobalChecked){
           this.productDetailArray.controls.forEach((x,index) => {
      list.push(this.productDetailArray.controls[index].get('discountValue').setValue(0));
      this.discountIndex.splice(this.discountIndex.indexOf(index), 1);
    });
          disableIndDiscount= true;
        }else{
          disableIndDiscount= false;
        }
        }else{
          disableIndDiscount= false;
        }

    return disableIndDiscount;
    //return true;
    }else{
      return false;
    }
  }
//   vatChange():void{
//     this.checkIsVatListSame();
//     this.triggerVatRateChange.emit(true);
//   }
//   // discountCalc(data:any):void{
//   //   let percenatge:number;
//   //   if(data['type'] === DiscountType.Value){

//   //     let globaldiscount=(+data['value']);
//   //     let totalnetamountList:any=[]
//   //       this.productDetailArray.controls.forEach((x,index) => {
//   //         totalnetamountList.push((+this.productDetailArray.controls[index].get('netAmount').value));
//   //       });
      
//   //       let netAmountSum=totalnetamountList.reduce((a, b) => a + b, 0);
     
//   //       percenatge=(+globaldiscount/+netAmountSum)*100;
//   //      // console.log(globaldiscount,'globaldiscount',percenatge,'percenatge',netAmountSum,'netAmountSum')
     
//   //   }


//   //     this.productDetailArray.controls.forEach((x,index) => {
//   //       let disValue:number=0;
//   //       if(data['type'] === DiscountType.Value){
//   //         disValue=((+this.productDetailArray.controls[index].get('netAmount').value)*percenatge)/100;
//   //       // console.log(disValue,'disValue')
//   //       }else{
//   //         disValue=(+data['value']);
//   //       }
//   //      // if(true){
//   //       this.productDetailArray.controls[index].get('discountType').setValue(data['type']);
       
//   //      // const totalNetAmount = (+this.productDetailArray.controls[index].get('qty').value) * (+this.productDetailArray.controls[index].get('price').value) - disValue;
//   //       //console.log(totalNetAmount,'totalNetAmount')
//   //       //this.productDetailArray.controls[index].get('netAmount').setValue(totalNetAmount);
//   //       this.productDetailArray.controls[index].get('discountValue').setValue(disValue);
     

//   //       this.qtyChanges(index); 
//   //      // }
   
//   //     });
    

//   // }

//   checkIsVatListSame():void{
   
   
//     let list:any=[];
//     this.productDetailArray.controls.forEach((x,index) => {
//       list.push(this.productDetailArray.controls[index].get('vatRate').value)
//     });
//     if(list.length > 1){
//    let isSame=list.every( (item:any) => item === list[0]);
//    if(!isSame){
//     this.productDetailArray.controls.forEach((x,index) => {
  
//       // const totalNetAmount = (+this.productDetailArray.controls[index].get('qty').value) * (+this.productDetailArray.controls[index].get('price').value) - disValue;
//        //console.log(totalNetAmount,'totalNetAmount')
//        this.productDetailArray.controls[index].get('discountType').setValue(1);
//       this.productDetailArray.controls[index].get('discountValue').setValue(0);
    
// //this.qtyChanges(index);
     
  
//      });
//    }
//   }else{

//   }
    
//   }
}
