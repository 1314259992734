<div class="content-body content-body-scroll">
  <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
    <mat-icon class="material-icons-outlined">error_outline</mat-icon>
    {{ commonNotificationText.ViewOnlyAccessMessage }}
  </div>
  <div class="stepper-wrapper bridging-vat">
    <mat-horizontal-stepper linear #horizontalStepper>
      <mat-step label="1" [completed]="isFirstStepDone">
        <ng-template matStepLabel>Set-up VAT Period</ng-template>
        <app-add-submit-vat
          #submitVatDetails
          [triggerEditData]="triggerEditData"
        ></app-add-submit-vat>
      </mat-step>
      <mat-step label="2" label=" VAT Details " [completed]="isSecondStepDone">
        <ng-template matStepLabel>VAT Details</ng-template>
        <app-vat-details
          [moduleId]="moduleId"
          *ngIf="isFirstStepDone"
          #vatDetails
        ></app-vat-details>
      </mat-step>
      <mat-step label="3" [completed]="isThirdStepDone">
        <ng-template matStepLabel>Review Return</ng-template>
        <app-review-return
          *ngIf="isSecondStepDone"
          class="review-return"
          [isSubmitHmrc]="isSubmitHmrc"
        ></app-review-return>
      </mat-step>
    </mat-horizontal-stepper>
    <div class="content-body pt-0" *ngIf="(isSecondStepDone && hmrcButtonShow && stepper.selectedIndex === 2)">
      <mat-checkbox [checked]= "isSubmitHmrc" [(ngModel)]="isDeclerationChecked" (change)="onCheckBoxChanges($event)"> </mat-checkbox>
      A legal declaration that the information is true and complete. A false
      declaration can result in prosecution.
    </div>
  </div>
</div>
<div class="action-wrapper">
  <div class="content-body">
    <div class="button-container">
      <button
        *ngIf="stepper?.selectedIndex !== 0"
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onPrevClick(horizontalStepper)"
      >
        Prev
      </button>
      <button
        *ngIf="stepper?.selectedIndex === 0 || !isFirstStepDone"
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onNextClick(false)"
        [disabled]="isViewPermission$ | async"
      >
        Save & Close
      </button>
      <button
        *ngIf="stepper?.selectedIndex === 0"
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onNextClick(true)"
        [disabled]="isViewPermission$ | async"
      >
        Save & Next
      </button>
      <button
        *ngIf="
          isFirstStepDone && capiumButtonShow && stepper.selectedIndex === 1
        "
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        [disabled]="!this.vatDetails?.isImportSuccess"
        (click)="submitToCapium()"
      >
        Submit To Capium
      </button>
      <button
        *ngIf="
          isSecondStepDone && hmrcButtonShow && stepper.selectedIndex === 2
        "
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        [disabled]="isDisable"
        (click)="onSave(vatReturnStatus.SubmitToHMRC)"
        [disabled]="isTrailUser || !isDeclerationChecked"
      >
        Submit To HMRC
      </button>
      <button
        class="action-button secondary-button"
        type="button"
        mat-button
        disableRipple
        (click)="cancelClick(false)"
      >
        Close
      </button>
    </div>
  </div>
</div>
