import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaxLength, NotificationTextMessage } from '@app/core/Enum';
import { VatScheme } from '@app/core/Enum/vat-scheme';
import { SideListModel, VatRateScheme } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  GetVatReturnTypeList,
  GetVatSchemeList,
  MenuState,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-vat-settings',
  templateUrl: './add-vat-settings.component.html',
  styleUrls: ['./add-vat-settings.component.scss'],
})
export class AddVatSettingsComponent implements OnInit {
  vatSettingsForm: FormGroup;
  isFlatRate: boolean = false;
  vatSchemeList: VatRateScheme[];
  vatReturnTypeList: SideListModel[];
  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;
  isDiscountPeriod = false;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  isAddMode = true;

  @Input() triggerEditData: Observable<any>;

  constructor(private store: Store, public commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
    this.triggerEditData?.subscribe((data) => {
      this.editVatSettingsDetails(data);
    });
    this.vatSettingsForm.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange = this.vatSettingsForm.touched;
    });
  }

  editVatSettingsDetails(data): void {
    this.vatSettingsForm.patchValue({
      registrationDate: data.vatRegistrationDate,
      registrationNo: data.vatRegistrationNo,
      vatScheme: data.vatSchemeId === 0 ? '' : data.vatSchemeId,
      vatSubmitType: data.vatReturnTypeId === 0 ? '' : data.vatReturnTypeId,
      flatRate: data.flatRate,
      isDiscountPeriod: data.isDiscountPeriod,
      from: data.fromDate,
      to: data.toDate,
      discountedRate: data.discountedRate,
    });

    if (
      data.vatSchemeId !== 0 &&
      (data.vatSchemeId === VatScheme.FlatRateAccrualScheme ||
        data.vatSchemeId === VatScheme.FlatRateCashBasedScheme)
    ) {
      this.isFlatRate = true;
    } else {
      this.isFlatRate = false;
    }

    this.onCheckBoxSelected(data.isDiscountPeriod);
  }

  setForm(): void {
    this.vatSettingsForm = new FormGroup({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      registrationDate: new FormControl('', [Validators.required]),
      registrationNo: new FormControl('', [Validators.required]),
      vatScheme: new FormControl('', [Validators.required]),
      vatSubmitType: new FormControl('', [Validators.required]),
      flatRate: new FormControl(0),
      isDiscountPeriod: new FormControl(false),
      discountPeriod: new FormControl(''),
      from: new FormControl(''),
      to: new FormControl(''),
      discountedRate: new FormControl(0),
    });

    this.getVatSchemeList();
    this.getVatReturnType();
  }

  checkValue() {
    if (
      this.vatSettingsForm.controls.vatScheme.value !== 0 &&
      (this.vatSettingsForm.controls.vatScheme.value ===
        VatScheme.FlatRateAccrualScheme ||
        this.vatSettingsForm.controls.vatScheme.value ===
          VatScheme.FlatRateCashBasedScheme)
    ) {
      this.isFlatRate = true;
    } else {
      this.isFlatRate = false;
      this.vatSettingsForm.controls.flatRate.setValue(0);
      this.onCheckBoxSelected(false);
    }
  }

  onCheckBoxSelected(event: any): void {
    this.isDiscountPeriod = event;

    if (!event) {
      this.vatSettingsForm.controls.from.setValue('');
      this.vatSettingsForm.controls.to.setValue('');
      this.vatSettingsForm.controls.discountedRate.setValue(0);
    }
  }

  getVatSchemeList(): void {
    this.store
      .dispatch(new GetVatSchemeList())
      .pipe(
        tap((res) => {
          this.vatSchemeList = res.company.vatSchemeList;
        })
      )
      .subscribe();
  }

  getVatReturnType(): void {
    this.store
      .dispatch(new GetVatReturnTypeList())
      .pipe(
        tap((res) => {
          this.vatReturnTypeList = res.company.vatReturnTypeList;
        })
      )
      .subscribe();
  }
}
