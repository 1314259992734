import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss'],
})
export class AddPaymentComponent {
  constructor(public dialogRef: MatDialogRef<AddPaymentComponent>) {}

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
