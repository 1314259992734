import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Modules } from '@app/core/Enum';
import {
  GetDashboardCashFlowOverview,
  GetDashboardExpenseComparision,
  GetDashboardIncomeComparision,
} from '@app/core/Store';
import { Store } from '@ngxs/store';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexNoData,
  ApexPlotOptions,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';

import { Observable } from 'rxjs';

export type CashFlowChartOptionsv1 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  noData: ApexNoData;
  labels: any;
};

@Component({
  selector: 'app-dashboard-cashflow-chart-v1',
  templateUrl: './dashboard-cashflow-chart-v1.component.html',
  styleUrls: ['./dashboard-cashflow-chart-v1.component.scss'],
})
export class DashboardCashflowChartComponentv1 implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public cashFlowChartOptionsv1: Partial<CashFlowChartOptionsv1>;
  reportList: any;
  isNoRecord = true;
  isloadData = false;

  @Input()
  getModuleId: number;

  @Input()
  triggerHeaderFilter: Observable<any>;

  @Input()
  triggerIncomeComparison: Observable<any>;

  @Input()
  triggerExpenseComparison: Observable<any>;

  constructor(private store: Store, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.triggerHeaderFilter?.subscribe((filterData) => {
      this.spinner.show();
      if (+this.getModuleId === Modules.Dashboard) {
        let actionName;
        switch (this.getModuleId) {
          case Modules.Dashboard:
            actionName = GetDashboardCashFlowOverview;
            break;
        }
        this.store.dispatch(new actionName(filterData)).subscribe((x) => {
          this.isloadData = true;
          switch (this.getModuleId) {
            case Modules.Dashboard:
              this.reportList = x.dashboard.dashboardCashFlowOverview;
              break;
          }

          this.getData();
        });
      }
    });

    this.triggerIncomeComparison?.subscribe((filterData) => {
      this.spinner.show();
      if (+this.getModuleId === Modules.BusinessSnapshot) {
        this.store
          .dispatch(new GetDashboardIncomeComparision(filterData))
          .subscribe((x) => {
            this.isloadData = true;
            this.reportList = x.dashboard.dashboardCashFlowOverview;
            this.getData();
          });
      }
    });

    this.triggerExpenseComparison?.subscribe((filterData) => {
      this.spinner.show();
      if (+this.getModuleId === Modules.BusinessSnapshot) {
        this.store
          .dispatch(new GetDashboardExpenseComparision(filterData))
          .subscribe((x) => {
            this.isloadData = true;
            this.reportList = x.dashboard.dashboardCashFlowOverview;
            this.getData();
          });
      }
    });
  }

  getData(): void {
    if (+this.getModuleId === Modules.Dashboard || Modules.BusinessSnapshot) {
      this.isNoRecord = this.reportList.series.length === 0;

      this.cashFlowChartOptionsv1 = {
        series: this.reportList.series,
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          markers: {
            fillColors: ['#7B76D3', '#F2816F'],
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: false,
            columnWidth: '50%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return '£ ' + Number(val) / 1000 + 'K';
            },
          },
        },
        tooltip: {
          marker: {
            show: false,
          },
          enabled: true,
          fillSeriesColor: false,
          y: {
            formatter: (val) => {
              return '£ ' + Number(val) * 1 + '';
            },
          },
        },
        labels: [
          'Estimates Created - 60%  </br>  42',
          'Estimates Accepted - 30%  </br>  22',
          'Estimates Rejected - 10% </br>  12',
        ],
        xaxis: {
          categories: this.reportList.categories,
        },
        noData: {
          text: 'No Data to Display',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#6d41a1',
            fontSize: '18px',
          },
        },
        fill: {
          opacity: 1,
          colors: ['#7B76D3', '#F2816F'],
        },
      };
    }
  }
}
