import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryLossOfStockService {
  constructor(private http: HttpClient, private commonService: CommonService) {}
  saveInventoryLossOfStock(data: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}InventoryLossOfStock/save`,
      this.commonService.trimObjectSpace(JSON.stringify(data)),
      headers
    );
  }

  getInventoryLossOfStock(stockId: Guid): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}InventoryLossOfStock/get/${stockId}`
    );
  }

  deleteInventoryLossOfStock(ids?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(ids),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}InventoryLossOfStock/delete`,
      options
    );
  }
}
