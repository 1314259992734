<div class="mat-dialog-wrapper match-journal">
  <h2 mat-dialog-title>VAT Audit Report</h2>

  <button
    class="close-button"
    type="button"
    (click)="onCloseClick()"
    mat-button
    disableRipple
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    <div class="header-actions">
      <div class="toolbar">
        <button
          mat-button
          class="more-button"
          [matMenuTriggerFor]="menu"
          disableRipple
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before" class="more-button-menu">
          <ng-container>
            <div>
              <button mat-menu-item disableRipple (click)="onPdfClick()">
                <mat-icon fontSet="material-icons-outlined"
                  >picture_as_pdf</mat-icon
                >
                <span>Export As PDF</span>
              </button>
              <button mat-menu-item disableRipple>
                <mat-icon fontSet="material-icons-outlined"
                  >border_all</mat-icon
                >
                <span>Export to Excel</span>
              </button>
            </div>
          </ng-container>
        </mat-menu>
      </div>
    </div>

    <div class="content-body p-0">
      <mat-tab-group
        mat-align-tabs="start"
        disableRipple
        animationDuration="0ms"
        animationDuration="0ms "
        [(selectedIndex)]="selectedTab"
        (selectedTabChange)="onTabClick($event.index)"
      >
        <mat-tab label="VAT Return">
          <div>
            <mat-table #table [dataSource]="vatReturnDataSource">
              <ng-container matColumnDef="boxNo">
                <mat-header-cell *matHeaderCellDef> Box No. </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.boxNo }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="vatDesc">
                <mat-header-cell *matHeaderCellDef>
                  VAT Description
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.vatDesc }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="vatAmount">
                <mat-header-cell *matHeaderCellDef class="text-align-right">
                  Amount
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  £ {{ element.vatAmount | numberPipe }}
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="vatReturnDisplayedColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: vatReturnDisplayedColumns"
              ></mat-row>
            </mat-table>
            <div *ngIf="noDataFound" class="text-align-center mt-1 fw-bold">
              No Data Found
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Sales VAT" *ngIf="!this.vatReturnList.isBridgingVAT">
          <app-vat-view-data
            *ngIf="selectedTab === 1"
            [tabSalesPurchase]="selectedTab"
            [vatReturnId]="id"
          >
          </app-vat-view-data>
        </mat-tab>
        <mat-tab label="Purchase VAT" *ngIf="!this.vatReturnList.isBridgingVAT">
          <app-vat-view-data
            *ngIf="selectedTab === 2"
            [tabSalesPurchase]="selectedTab"
            [vatReturnId]="id"
          >
          </app-vat-view-data>
        </mat-tab>
        <mat-tab label="Sales No VAT" *ngIf="!this.vatReturnList.isBridgingVAT">
          <app-vat-view-data
            *ngIf="selectedTab === 3"
            [tabSalesPurchase]="selectedTab"
            [vatReturnId]="id"
          >
          </app-vat-view-data>
        </mat-tab>
        <mat-tab
          label="Purchase No VAT"
          *ngIf="!this.vatReturnList.isBridgingVAT"
        >
          <app-vat-view-data
            *ngIf="selectedTab === 4"
            [tabSalesPurchase]="selectedTab"
            [vatReturnId]="id"
          >
          </app-vat-view-data>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-dialog-content>
</div>

<app-custom-notifications></app-custom-notifications>
