<div class="content-body">
  <form [formGroup]="directorShareholderForm">
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>First Name<span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="firstName"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
            required
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Last Name</p>
        <mat-form-field>
          <input
            matInput
            formControlName="lastName"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40" *ngIf="getModuleId !== moduleId.Shareholder">
      <div class="form-field">
        <mat-checkbox formControlName="isCreateAccount"
          >Create Current A/c
        </mat-checkbox>
      </div>
      <div class="form-field">
        <mat-checkbox formControlName="isCreateLoanAccount"
          >Create Loan A/c
        </mat-checkbox>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Address Line 1</p>
        <mat-form-field>
          <input
            matInput
            formControlName="addressLine1"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Address Line 2</p>
        <mat-form-field>
          <input
            matInput
            formControlName="addressLine2"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Address Line 3</p>
        <mat-form-field>
          <input
            matInput
            formControlName="addressLine3"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>City</p>
        <mat-form-field>
          <input
            matInput
            formControlName="city"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Postcode</p>
        <mat-form-field>
          <input
            matInput
            formControlName="postCode"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Country</p>
        <ng-select
          #country
          (focus)="country.open()"
          (valueChange)="country.close()"
          [disableOptionCentering]="true"
          formControlName="country"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let cl of countryList" [value]="cl.id">
            <div title="{{ cl.description }}">{{ cl.description }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Email</p>
        <mat-form-field>
          <input
            matInput
            formControlName="email"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength180 }}"
            type="text"
            emailValidator
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Phone</p>
        <mat-form-field>
          <input
            matInput
            formControlName="phone"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>

    <div
      class="d-flex"
      [ngClass]="getModuleId !== moduleId.Shareholder ? 'mr-40' : 'gap-40'"
      *ngIf="getModuleId !== moduleId.Shareholder"
    >
      <div class="form-field">
        <p>Shareholder</p>
        <mat-form-field>
          <mat-select
            [disableOptionCentering]="true"
            formControlName="shareholderId"
            (selectionChange)="onSelectionChange($event.value)"
            panelClass="mat-select-position"
          >
            <mat-option [value]="isShareholder.No" title="No">No</mat-option>
            <mat-option [value]="isShareholder.Yes" title="Yes">Yes</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div
      class="d-flex gap-40"
      *ngIf="
        getModuleId !== moduleId.Director ||
        directorShareholderForm.controls.shareholderId.value ===
          isShareholder.Yes
      "
    >
      <div class="form-field">
        <p>Value Per Share <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            allowDecimal
            formControlName="valuePerShare"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            (keyup)="onChange()"
            required
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>No of Share <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            allowDecimal
            formControlName="noOfShare"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            (keyup)="onChange()"
            required
          />
        </mat-form-field>
      </div>
    </div>

    <div
      class="d-flex"
      [ngClass]="getModuleId !== moduleId.Director ? 'gap-40' : 'mr-40'"
    >
      <div
        class="form-field"
        *ngIf="
          getModuleId !== moduleId.Director ||
          directorShareholderForm.controls.shareholderId.value ===
            isShareholder.Yes
        "
      >
        <p>Total</p>
        <mat-form-field>
          <input
            matInput
            formControlName="total"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
            readonly
          />
        </mat-form-field>
      </div>
      <div class="form-field" *ngIf="getModuleId !== moduleId.Director">
        <p>Share Type</p>
        <mat-form-field>
          <mat-select
            [disableOptionCentering]="true"
            formControlName="shareType"
            panelClass="mat-select-position"
          >
            <mat-option value="1" title="Equity">Equity</mat-option>
            <mat-option value="2" title="Non-Equity">Non-Equity</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex gap-40" *ngIf="getModuleId !== moduleId.Shareholder">
      <div class="form-field">
        <p>Appointment Date<span class="text-danger-300">*</span></p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="appointmentDate"
            [matDatepicker]="appointmentDatePicker"
            autocomplete="off"
            tabindex="-1"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="appointmentDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #appointmentDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              directorShareholderForm.get('appointmentDate')
            ).invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              directorShareholderForm.get('appointmentDate')
            ).lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
      <div class="form-field">
        <p>Termination Date</p>
        <mat-form-field appearance="standard">
          <input
            matInput
            formControlName="terminationDate"
            [min]="directorShareholderForm.controls.appointmentDate.value"
            [matDatepicker]="terminationDatePicker"
            autocomplete="off"
            tabindex="-1"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="terminationDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #terminationDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              directorShareholderForm.get('terminationDate')
            ).invalidFinancialYear
          "
        >
          {{ notificationMessage.validPeriodDateMessage }}
        </mat-error>

        <mat-error
          *ngIf="
            commonService.checkDateValidation(
              directorShareholderForm.get('terminationDate')
            ).lockedFinancialYear
          "
        >
          {{ notificationMessage.lockedAccoutingPeriodDateMessage }}
        </mat-error>
      </div>
    </div>

    <div class="d-flex gap-40">
      <div class="form-field textarea">
        <p>Notes</p>
        <mat-form-field>
          <textarea
            matInput
            formControlName="notes"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength2000 }}"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
