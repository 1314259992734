<div class="mat-dialog-wrapper" style="width: 600px">
  <h2 mat-dialog-title class="text-align-center">View the note</h2>

  <button
    class="edit-button"
    type="button"
    (click)="onEditClick()"
    mat-button
    disableRipple
    [disabled]="!isJournalChangePermissionEdit"
  >
    <mat-icon class="text-primary" fontset="material-icons-outlined"
      >edit</mat-icon
    >
  </button>
  <button
    class="close-button"
    type="button"
    (click)="onCloseClick()"
    mat-button
    disableRipple
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    <div class="content-body">
      <div class="form-field">
        <p>Title <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="scheduleNote.title"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength100 }}"
            [disabled]="true"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Description <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <textarea
            matInput
            [(ngModel)]="scheduleNote.description"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength2000 }}"
            [disabled]="true"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
</div>

<app-custom-notifications></app-custom-notifications>
