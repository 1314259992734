import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationTextMessage } from '@app/core/Enum';
import { LinkQuickEntryModel } from '@app/core/Enum/quick-entry';
import { CommonService } from '@app/core/Services';
import { GetMatchingQuickEntry, LinkQuickEntry } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-match-quickentry',
  templateUrl: './match-quickentry.component.html',
  styleUrls: ['./match-quickentry.component.scss'],
})
export class MatchQuickentryComponent implements OnInit {
  displayedColumns: string[] = [
    'refNo',
    'ref',
    'contactName',
    'desc',
    'invoiceDate',
    'payDate',
    'amount',
    'action',
  ];
  transactionId: any;
  matchingQuickEntryData: any;
  labelText: string;
  spinner: NgxSpinnerService;
  noDataFound = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    public dialogRef: MatDialogRef<MatchQuickentryComponent>,
    private injector: Injector,
    public commonService: CommonService
  ) {
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
  }

  ngOnInit(): void {
    this.transactionId = atob(this.data.transactionId) as unknown as Guid;
    this.getData();
  }
  getData(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetMatchingQuickEntry(this.transactionId))
      .pipe(
        tap((res) => {
          this.spinner.hide();
          this.matchingQuickEntryData = res.quickentry.matchQuickEntry;
          this.noDataFound =
            this.matchingQuickEntryData.items.length > 0 ? false : true;
          this.labelText = this.matchingQuickEntryData.isCredit
            ? 'Payment'
            : 'Receipt';
        })
      )
      .subscribe();
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onLinkClick(element: any): void {
    const linkQuickEntry: LinkQuickEntryModel = {
      id: element.quickEntryId,
      itemId: element.quickEntryItemId,
      transactionId: this.matchingQuickEntryData.id,
      isManualBank: this.matchingQuickEntryData.isManualBank,
    };
    this.store
      .dispatch(new LinkQuickEntry(linkQuickEntry))
      .pipe()
      .subscribe(
        (res) => {
          if (res !== undefined) {
            this.commonService.onSuccess(
              NotificationTextMessage.successMessage
            );
            this.dialogRef.close();
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        },
        (err) => {
          this.commonService.onFailure(err.error.Message);
        }
      );
  }
}
