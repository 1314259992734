import { DatePipe } from '@angular/common';
import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActionType,
  ConfirmationType,
  DataType,
  DetailListModules,
  FilteredStatus,
  Inventory,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { ExportFormat } from '@app/core/Enum/export-type';
import {
  ExportType,
  HighLightModel,
  MainListParameters,
  ViewParamModel,
} from '@app/core/Models';
import {
  CommonService,
  ModulePermission,
  NotificationService,
} from '@app/core/Services';
import { HighlightRow } from '@app/core/Services/common/highlighted-texts.service';
import {
  CommonState,
  Copy,
  Export,
  ExportReceipt,
  GetDetailList,
  GetMainList,
  GetVatViewData,
  MenuState,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  ConfirmationBoxComponent,
  ViewReceiptComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { LossOfStockComponent } from '../loss-of-stock/loss-of-stock.component';

@Component({
  selector: 'app-detail-list',
  templateUrl: './detail-list.component.html',
  styleUrls: ['./detail-list.component.scss'],
})
export class DetailListComponent implements OnInit {
  @Select(CommonState.columns)
  columns$: Observable<
    Array<{
      name: string;
      value: string;
      dataType?: DataType;
      allowSortBy?: boolean;
    }>
  >;

  isRowHighlighted = false;

  showPaginator = true;
  checkBoxValue = 0;
  filteredStatus = FilteredStatus;

  @Select(CommonState.columnNames)
  columnsNames$: Observable<Array<string>>;

  @Select(CommonState.actions)
  actions$: Observable<any>;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  @Input() moduleId: number;

  listParameters: MainListParameters = new MainListParameters();
  totalSelectedRecords = 0;
  isAllSelected = false;
  isExpanded = true;
  ids: Array<Guid>;
  mainList: any;
  dataSource = new MatTableDataSource();
  dataType = DataType;
  moduleEnum = Modules;
  selectedRowIndex = -1;
  moreActionCount = 4;
  isHeaderChange = false;

  triggerPaginationChange: Subject<any> = new Subject<any>();

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(MenuState.hasEditPermission)
  hasEditPermission$: Observable<boolean>;

  @Select(MenuState.hasDeletePermission)
  hasDeletePermission$: Observable<boolean>;

  @ViewChild(MatSort) sort: MatSort;

  dialog: MatDialog;
  store: Store;
  notify: NotificationService;
  commonService: CommonService;
  router: Router;
  highlightRow: HighlightRow;
  datepipe: DatePipe;
  spinner: NgxSpinnerService;
  modulePermission: ModulePermission;
  noDataFound = false;
  subscriptionRouting: Subscription;
  _Activatedroute: ActivatedRoute;
  cookieService: CookieService;
  detailListId: number;
  productId: string;
  detailListModulesId: number;

  constructor(private injector: Injector) {
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.store = injector.get<Store>(Store);
    this.notify = injector.get<NotificationService>(NotificationService);
    this.commonService = injector.get<CommonService>(CommonService);
    this.router = injector.get<Router>(Router);
    this.highlightRow = injector.get<HighlightRow>(HighlightRow);
    this.datepipe = injector.get<DatePipe>(DatePipe);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.modulePermission = injector.get<ModulePermission>(ModulePermission);
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.cookieService = injector.get<CookieService>(CookieService);
  }

  ngOnInit(): void {
    this.detailListId = +this.cookieService.get('detailListId');
    this.productId = this.cookieService.get('productId');
    this.moduleId$.subscribe((moduleId) => {
      if (moduleId === Modules.Inventory) {
        this.detailListModulesId = DetailListModules.Inventory;
      }
    });
    this.highlightRecord();
  }

  highlightRecord(): void {
    this.listParameters.sortBy = this.highlightRow.mainListHighlighted.sortBy;
    this.listParameters.sortOrder = false;
    this.isHeaderChange = false;
    this.getList();
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  selectAll(event: any): void {
    this.mainList.forEach((x) => (x.isSelected = event.checked));
    this.showFooter();
  }

  showFooter(): void {
    this.selectedIds();
    this.totalSelectedRecords = this.ids.length;
  }

  deleteClick(): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: this.moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.isHeaderChange = false;
            this.getList();
            this.cancelSelectionClick();
          }
        });
    }
  }

  export(format: number, isPrint: boolean = false): void {
    this.spinner.show();
    this.selectedIds();
    if (
      (this.moduleId === Modules.Invoices ||
        this.moduleId === Modules.Purchase ||
        this.moduleId === Modules.Quotation ||
        this.moduleId === Modules.CreditNote ||
        this.moduleId === Modules.DebitNote ||
        this.moduleId === Modules.FixedAssets ||
        this.moduleId === Modules.Journals ||
        this.moduleId === Modules.Receipt ||
        this.moduleId === Modules.Payment ||
        this.moduleId === Modules.Dividends ||
        this.moduleId === Modules.FixedAssetDetail ||
        this.moduleId === Modules.Budgeting) &&
      format === ExportFormat.PDF
    ) {
      this.store
        .dispatch(new ExportReceipt(this.moduleId, this.ids, isPrint))
        .subscribe();
      this.cancelSelectionClick();
    } else if (
      this.moduleId === Modules.DisposeIntangibleFixedAssets ||
      this.moduleId === Modules.DisposeLeasedFixedAssets ||
      this.moduleId === Modules.DisposeIntangibleFixedAssets
    ) {
      const queryParams = {
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: this.listParameters.filter,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        search: this.listParameters.search,
        moduleId: this.listParameters.moduleId,
        subModuleId: this.listParameters.subModuleId ?? -1,
        ids: this.ids,
        startDate:
          this.datepipe
            .transform(this.listParameters.startDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        endDate:
          this.datepipe
            .transform(this.listParameters.endDate, 'yyyy-MM-dd')
            ?.toString() ?? null,
        format: format,
        isPrint: isPrint,
      };

      this.store.dispatch(new Export(queryParams, this.moduleId)).subscribe();
      this.cancelSelectionClick();
    }
  }

  copyClick(): void {
    if (this.moduleId !== Modules.CashEntry) {
      this.spinner.show();

      this.selectedIds();

      this.store
        .dispatch(new Copy(this.moduleId, this.ids))
        .subscribe((res) => {
          if (res.common.isCopied) {
            this.notify.success(
              NotificationHeader.success,
              NotificationTextMessage.recordCopySuccessfully
            );
            this.isHeaderChange = false;
            this.getList();
            this.cancelSelectionClick();
          }
        });
    }
  }

  cancelSelectionClick(): void {
    this.deSelectAll();
  }

  deSelectAll(): void {
    this.mainList.forEach((x) => (x.isSelected = false));
    this.isAllSelected = false;
    this.totalSelectedRecords = 0;
  }

  printClick(): void {
    this.export(ExportType.PDF, true);
    this.cancelSelectionClick();
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.isHeaderChange = false;
    this.getList();
  }

  onAddClick(element: any): void {
    this.router.navigate([
      RoutingPath.AddCustomAccounts,
      { accountId: btoa(element[0]) },
    ]);
  }

  archiveAndRestoreClick(isArchive: any): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            isArchive,
            type: ConfirmationType.Archive,
            moduleId: this.moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: isArchive
              ? NotificationHeader.archiveConfirmation
              : NotificationHeader.restoreConfirmation,
            detailText: isArchive
              ? NotificationDetails.archiveDetailText
              : NotificationDetails.restoreDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.isHeaderChange = false;
            this.ids = [];
            this.getList();
            this.cancelSelectionClick();
          }
        });
    }
  }

  selectedIds(): void {
    this.ids = [];
    this.mainList.forEach((value) =>
      value.isSelected ? this.ids.push(value[0] ?? 0) : ''
    );
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.isHeaderChange = false;
      this.getList();
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.isHeaderChange = false;
    this.getList();
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  onCheckBoxSelected(element: any, event: any): void {
    element.isSelected = event.checked;

    this.isAllSelected = this.mainList.every(
      (item: any) => item.isSelected === true
    );
    this.showFooter();
  }

  onDeleteClick(id: any): void {
    this.ids = [];
    this.ids.push(id);

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Delete,
            moduleId: this.moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.isHeaderChange = false;
            this.getList();
          }
        });
    }
  }

  editLossOfStock(id): void {
    this.dialog
      .open(LossOfStockComponent, {
        data: { id: id },
      })
      .afterClosed()
      .subscribe((result) => {
        this.getList();
      });
  }

  onButtonClick(id: any, actionType: ActionType): void {
    switch (actionType) {
      case ActionType.Update:
        if (this.detailListId === Inventory.Loss) {
          this.editLossOfStock(id);
        } else {
          this.commonService.onEditRouting(true, this.moduleId, id);
        }
        break;

      case ActionType.Delete:
        this.onDeleteClick(id);
        break;

      case ActionType.Activate:
        this.onDisposedActivateClick(id);
        break;

      case ActionType.Export:
        this.ids = [];
        this.ids.push(id);
        this.store
          .dispatch(new ExportReceipt(this.moduleId, this.ids, false))
          .subscribe();
        break;

      case ActionType.View:
        const data: ViewParamModel = {
          moduleId: this.moduleId,
          id,
        };

        this.dialog
          .open(ViewReceiptComponent, {
            data,
            disableClose: true,
          })
          .afterClosed()
          .subscribe((result) => {});
        break;
    }
  }

  onDisposedActivateClick(id: any): void {
    this.ids = [];
    this.ids.push(id);

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.Activate,
            moduleId: this.moduleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.disposedConfirmation,
            detailText: NotificationDetails.disposedDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.isHeaderChange = false;
            this.getList();
          }
        });
    }
  }

  getList(): void {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids:
        this.listParameters.ids !== undefined &&
        this.listParameters.ids.length > 0
          ? this.listParameters.ids
          : null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };

    this.spinner.show();

    if (
      this.moduleId === this.moduleEnum.DisposeTangibleFixedAssets ||
      this.moduleId === this.moduleEnum.DisposeLeasedFixedAssets ||
      this.moduleId === this.moduleEnum.DisposeIntangibleFixedAssets
    ) {
      this.store
        .dispatch(new GetMainList(queryParams, this.moduleId))
        .subscribe((res) => {
          this.highlightRow.mainListHighlighted.moduleId = 0;
          this.spinner.hide();
          this.mainList = res.common.mainList.resultSet.data;
          this.noDataFound = this.mainList.length > 0 ? false : true;
          this.dataSource.data = this.mainList;
          this.checkBoxValue = this.dataSource.data.length;

          this.actions$.subscribe((x) => {
            if (x !== null && x !== undefined && x.length > 4) {
              this.moreActionCount = 3;
            }
          });

          const param = {
            pageSize: this.listParameters.pageSize,
            totalRecord: res.common.totalRecord,
            isHeaderChange: this.isHeaderChange,
          };
          this.triggerPaginationChange.next(param);
        });
    } else if (this.listParameters.isCustomDate) {
      this.store
        .dispatch(new GetVatViewData(queryParams, this.detailListId))
        .subscribe((res) => {
          this.listParameters.isCustomDate = false;
          this.isHeaderChange = false;
          this.getList();
        });
    } else {
      const dataParam = {
        detailListId: this.detailListId,
        productId: this.productId,
        detailListModulesId: this.detailListModulesId,
      };
      this.store
        .dispatch(new GetDetailList(queryParams, dataParam))

        .subscribe((res) => {
          this.spinner.hide();
          this.mainList = res.common.mainList.resultSet.data;

          this.actions$.subscribe((x) => {
            if (x.length > 4) {
              this.moreActionCount = 3;
            }
          });

          this.noDataFound = this.mainList.length > 0 ? false : true;
          this.dataSource.data = this.mainList;
          this.checkBoxValue = this.dataSource.data.length;
          this.highlightRow.mainListHighlighted = new HighLightModel();
          const param = {
            pageSize: this.listParameters.pageSize,
            totalRecord: res.common.totalRecord,
            isHeaderChange: this.isHeaderChange,
          };
          this.triggerPaginationChange.next(param);
        });
    }
  }

  getDataFromHeader(data: any): void {
    data.ids = this.ids;
    if (data.search === '') {
      this.spinner.show();
    }
    this.isHeaderChange = true;
    this.listParameters = data;
    !this.listParameters.format
      ? this.getList()
      : this.export(this.listParameters.format, this.listParameters.isPrint);
    this.cancelSelectionClick();
  }

  getDataFromBank(data: any): void {
    this.isExpanded = data.isExpanded;
    this.listParameters.moduleId = data.id;
    this.isHeaderChange = false;
    this.getList();
  }

  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
  }

  onToggleMatMenu(i): void {
    this.selectedRowIndex = i;
  }
}
