<div class="import-stepper mt-2">
  <mat-stepper [linear]="false" #stepper>
    <mat-step disableRipple>
      <ng-template matStepLabel>Upload</ng-template>
      <form [formGroup]="uploadFormGroup" class="p-0">
        <div class="upload-detail">
          <p class="import-title text-primary d-flex mt-1 mb-0 pb-5">
            {{ panelHeaderTitle }}
          </p>
          <div class="import-leads mb-2">
            <div class="file-box" style="width: -webkit-fill-available">
              <div class="drop-detail">
                <p>Drag and drop files to upload</p>
                <h4><span>or</span></h4>
                <button class="file-upload-button primary-button">
                  <input
                    type="file"
                    #fileInput
                    accept=".xls, .xlsx"
                    (change)="onFileSelected(fileInput)"
                    (click)="onFileOpen($event)"
                  />Browse
                </button>
                <p class="file-info">{{ fileInfo }}</p>
                <div
                  class="d-flex align-items-center justify-content-center gap-5 cursor-pointer"
                  (click)="downloadTemplateFile()"
                  *ngIf="fileInfo === ''"
                >
                  <mat-icon fontSet="material-icons-outlined"
                    >file_download</mat-icon
                  >
                  <p class="m-0 link">Download sample file</p>
                </div>
                <div class="file-formate">
                  <p>File Format : XLSX</p>
                </div>
                <div
                  style="text-align: left !important"
                  *ngIf="importDetailList.length > 0 && fileInfo === ''"
                >
                  <div
                    class="fw-bold mb-1"
                    *ngFor="let element of importDetailList"
                  >
                    {{ element.message }}({{ element.count }})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step disableRipple>
      <ng-template matStepLabel>Verified Data</ng-template>
      <div class="content-body mt-2">
        <form [formGroup]="importForm" class="p-0">
          <div class="form-field d-flex gap-40">
            <div class="form-field" *ngIf="showAccountName">
              <p>Account Name</p>
              <mat-form-field appearance="fill" class="w-200">
                <input
                  type="text"
                  matInput
                  formControlName="accountObj"
                  [matAutocomplete]="accountGroup"
                  #trigger="matAutocompleteTrigger"
                  appAutocompletePosition="trigger"
                  (keyup)="onAccountSearch($event)"
                />
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                <mat-autocomplete
                  [displayWith]="getOptionText"
                  (opened)="
                    scrollIntoView(this.importForm.controls.accountObj.value)
                  "
                  #accountGroup="matAutocomplete"
                >
                  <mat-optgroup
                    *ngFor="let group of accountGroupList"
                    [label]="group.groupName"
                    title="{{ group.groupName }}"
                  >
                    <mat-option
                      *ngFor="let names of group.listModels"
                      [value]="names"
                      title="{{ names.name }}"
                    >
                      {{ names.name }}
                    </mat-option>
                  </mat-optgroup>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="form-field" *ngIf="showIncomeAccount">
              <p>Sales Account</p>
              <mat-form-field appearance="fill" class="w-200">
                <input
                  type="text"
                  matInput
                  required
                  formControlName="incomeAccountObj"
                  [matAutocomplete]="accountGroup"
                  #trigger="matAutocompleteTrigger"
                  appAutocompletePosition="trigger"
                  (keyup)="onAccountSearch($event)"
                />
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                <mat-autocomplete
                  [displayWith]="getOptionText"
                  (opened)="
                    scrollIntoView(
                      this.importForm.controls.incomeAccountObj.value
                    )
                  "
                  #accountGroup="matAutocomplete"
                >
                  <mat-optgroup
                    *ngFor="let group of accountGroupList"
                    [label]="group.groupName"
                    title="{{ group.groupName }}"
                  >
                    <mat-option
                      *ngFor="let names of group.listModels"
                      [value]="names"
                      title="{{ names.name }}"
                    >
                      {{ names.name }}
                    </mat-option>
                  </mat-optgroup>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div class="form-field" *ngIf="showDepositAccount">
              <p>Deposit Account</p>
              <ng-select
                #depsoitAccount
                (focus)="depsoitAccount.open()"
                (valueChange)="depsoitAccount.close()"
                [disableOptionCentering]="true"
                formControlName="depositAccountObj"
                panelClass="mat-select-position"
                appendTo="body"
              >
                <ng-option *ngFor="let fn of depositToList" [value]="fn">
                  <div title="{{ fn.name }}">{{ fn.name }}</div>
                </ng-option>
              </ng-select>
            </div>
            <div class="form-field fund-name" *ngIf="branchList.length > 0">
              <p>Branch <span class="text-danger-300">*</span></p>
              <ng-select
                class="w-200"
                i
                #branch
                (focus)="branch.open()"
                (valueChange)="branch.close()"
                [disableOptionCentering]="true"
                formControlName="branchId"
                dropdownPosition="bottom"
                (change)="onChangeBranch($event)"
              >
                <ng-option *ngFor="let bl of branchList" [value]="bl.id">
                  <div title="{{ bl.name }}">{{ bl.name }}</div>
                </ng-option>
              </ng-select>
            </div>
            <div class="form-field" *ngIf="departmentList.length > 0">
              <p>Department <span class="text-danger-300">*</span></p>
              <ng-select
                class="w-200"
                #department
                (focus)="department.open()"
                (valueChange)="department.close()"
                [disableOptionCentering]="true"
                formControlName="departmentId"
                dropdownPosition="bottom"
              >
                <ng-option *ngFor="let dl of departmentList" [value]="dl.id">
                  <div title="{{ dl.name }}">{{ dl.name }}</div>
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div
            class="d-flex gap-20 align-items-center mb-2"
            *ngIf="selectedModuleId === module.TrialBalance"
          >
            <p class="fw-bold white-space-nowrap m-0">
              Select Accounting Period: <span class="text-danger-300">*</span>
            </p>
            <mat-form-field>
              <mat-select
                panelClass="mat-select-position"
                [disableOptionCentering]="true"
                formControlName="accountingPeriod"
                [(ngModel)]="selectedValue"
                (selectionChange)="onAccountPeriodChange()"
              >
                <mat-option
                  *ngFor="let option of periodicDateList"
                  [value]="option.id"
                  title="{{ option.fromDate | date: 'dd-LLL-yyyy' }} - {{
                    option.toDate | date: 'dd-LLL-yyyy'
                  }}"
                >
                  {{ option.fromDate | date: "dd-LLL-yyyy" }} -
                  {{ option.toDate | date: "dd-LLL-yyyy" }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="d-flex gap-20 align-items-center mb-2"
            *ngIf="selectedModuleId === module.OpeningBalance"
          >
            <p class="fw-bold white-space-nowrap m-0">
              Date: <span class="text-danger-300">*</span>
            </p>
            <mat-form-field appearance="standard" class="w-150">
              <input
                matInput
                formControlName="accountingDate"
                [matDatepicker]="accountingDatePicker"
                autocomplete="off"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="accountingDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #accountingDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div
            class="d-flex gap-20 align-items-center mb-2"
            *ngIf="openingbranchList.length > 0"
          >
            <p class="fw-bold white-space-nowrap m-0">
              Branch <span class="text-danger-300">*</span>
            </p>
            <ng-select
              class="form-field fund-name mb-0"
              *ngIf="openingbranchList.length > 0"
              #openingbranch
              (focus)="openingbranch.open()"
              (valueChange)="openingbranch.close()"
              [disableOptionCentering]="true"
              formControlName="openingBranchId"
              dropdownPosition="bottom"
              (change)="onChangeOpeningBranch()"
            >
              <ng-option *ngFor="let bl of openingbranchList" [value]="bl.id">
                <div title="{{ bl.name }}">{{ bl.name }}</div>
              </ng-option>
            </ng-select>
          </div>
        </form>

        <mat-accordion [multi]="true">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon fontSet="material-icons-outlined" class="text-primary"
                  >description</mat-icon
                >
                <div class="d-flex flex-column">
                  <span
                    >Success List ({{ importSuccessList.data.length }})</span
                  >
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="content-body">
              <div class="mat-table-wrapper">
                <table
                  mat-table
                  aria-describedby="Import Success List"
                  [dataSource]="importSuccessList"
                >
                  <ng-container
                    matColumnDef="customerName"
                    *ngIf="this.selectedModuleId !== module.CIS"
                  >
                    <th mat-header-cell *matHeaderCellDef class="w-170">
                      Customer Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon (click)="gotoAddCustomer()" title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <ng-select
                        #customerName
                        [disableOptionCentering]="true"
                        (focus)="customerName.open()"
                        (valueChange)="customerName.close()"
                        [(ngModel)]="element.accountId"
                        panelClass="mat-select-position"
                        appendTo="body"
                        disabled
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of customerList"
                          [value]="option.id"
                          title="{{ option.name }}"
                          class="clamp"
                        >
                          {{ option.name }}
                        </ng-option>
                      </ng-select>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>
                  <ng-container
                    matColumnDef="customerName"
                    *ngIf="this.selectedModuleId === module.CIS"
                  >
                    <th mat-header-cell *matHeaderCellDef class="w-170">
                      Customer Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <ng-select
                        #customerName
                        [disableOptionCentering]="true"
                        (focus)="customerName.open()"
                        (valueChange)="customerName.close()"
                        [(ngModel)]="element.customerId"
                        panelClass="mat-select-position"
                        appendTo="body"
                        disabled
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of subcontractorList"
                          [value]="option.id"
                          title="{{ option.name }}"
                          class="clamp"
                        >
                          {{ option.name }}
                        </ng-option>
                      </ng-select>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="accountName">
                    <th mat-header-cell *matHeaderCellDef>Account Name</th>
                    <td mat-cell *matCellDef="let element">
                      <!-- <div
                        class="clamp"
                        title="{{
                          this.importForm.controls.accountObj.value?.name
                        }}"
                      >
                        {{ this.importForm.controls.accountObj.value?.name }}
                      </div> -->

                      <mat-form-field appearance="fill" class="w-200">
                        <input
                          type="text"
                          matInput
                          [disabled]="true"
                          [(ngModel)]="element.accountData"
                          [matAutocomplete]="accountGroup"
                          #trigger="matAutocompleteTrigger"
                          appAutocompletePosition="trigger"
                          (keyup)="onAccountSearch($event)"
                        />
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                        <mat-autocomplete
                          [displayWith]="getOptionText"
                          (opened)="
                            scrollIntoView(element.bookAccountId)
                          "
                          #accountGroup="matAutocomplete"
                        >
                          <mat-optgroup
                            *ngFor="let group of accountGroupList"
                            [label]="group.groupName"
                            title="{{ group.groupName }}"
                          >
                            <mat-option
                              *ngFor="let names of group.listModels"
                              [value]="names"
                              title="{{ names.name }}"
                            >
                              {{ names.name }}
                            </mat-option>
                          </mat-optgroup>
                        </mat-autocomplete>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="invoiceNo">
                    <th mat-header-cell *matHeaderCellDef>Invoice No</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.entryNumber }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>
                  <ng-container
                    matColumnDef="journalNo"
                    *ngIf="this.selectedModuleId === module.Journals"
                  >
                    <th mat-header-cell *matHeaderCellDef>Journal No</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.entryNumber }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container
                    matColumnDef="invoiceDate"
                    *ngIf="
                      this.selectedModuleId !== module.CIS &&
                      this.selectedModuleId !== module.QuickEntry
                    "
                  >
                    <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="invoiceSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="invoiceSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #invoiceSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container
                    matColumnDef="invoiceDate"
                    *ngIf="
                      this.selectedModuleId === module.CIS ||
                      this.selectedModuleId === module.QuickEntry
                    "
                  >
                    <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.invoiceDateError">
                        {{ element.invoiceDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.invoiceDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.invoiceDate"
                          [matDatepicker]="invoiceSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="invoiceSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #invoiceSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>
                  <ng-container
                    matColumnDef="journalDate"
                    *ngIf="this.selectedModuleId === module.Journals"
                  >
                    <th mat-header-cell *matHeaderCellDef>Journal Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.journalDateError">
                        {{ element.journalDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.journalDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.journalDate"
                          [matDatepicker]="invoiceSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="invoiceSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #invoiceSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="dueDate">
                    <th mat-header-cell *matHeaderCellDef>Due Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.dueDateError">
                        {{ element.dueDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.dueDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.dueDate"
                          [matDatepicker]="dueDateSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="dueDateSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #dueDateSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="productName">
                    <th mat-header-cell *matHeaderCellDef>Product Name</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="clamp">{{ element.productName }}</div>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="qty">
                    <th mat-header-cell *matHeaderCellDef class="w-50">Qty</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.quantity }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Price
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.price }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="Material Amount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Material Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.materialAmount | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="Total Payment">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Total Payment
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.totalPayment | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="Rate of CIS">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Rate of CIS
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.rateOfCIS | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="Tax Amount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Tax Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.taxAmount | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="vatRate">
                    <th mat-header-cell *matHeaderCellDef class="w-100">
                      Vat Rate
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field class="w-100">
                        <mat-select
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.vatRateId"
                          dropdownPosition="top"
                          disabled
                          panelClass="mat-select-position"
                        >
                          <mat-option
                            value="{{ this.commonService.defaultGuidValue }}"
                            title="--Select--"
                            >--Select--</mat-option
                          >
                          <mat-option
                            *ngFor="let option of originalVatRateList"
                            [value]="option.id"
                            title="{{ option.name }}"
                          >
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="vatAmount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Vat Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.vatAmount }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="totalAmount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Total Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.totalAmount }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="supplierName">
                    <th mat-header-cell *matHeaderCellDef>
                      Supplier Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon (click)="gotoAddSupplier()" title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <ng-select
                        #suppliername
                        (focus)="suppliername.open()"
                        (valueChange)="suppliername.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.accountId"
                        panelClass="mat-select-position"
                        appendTo="body"
                        disabled
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of supplierList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}" class="clamp">
                            {{ option.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="billNo">
                    <th mat-header-cell *matHeaderCellDef>Purchase No</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.entryNumber }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="billDate">
                    <th mat-header-cell *matHeaderCellDef>Purchase Date</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.entryDate | date: "dd-LLL-yyyy" }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="creditNoteNo">
                    <th mat-header-cell *matHeaderCellDef>Credit Note No</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.entryNumber }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="creditNoteDate">
                    <th mat-header-cell *matHeaderCellDef>Credit Note Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="creditNoteDateSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="creditNoteDateSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #creditNoteDateSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="debitNoteNo">
                    <th mat-header-cell *matHeaderCellDef>Debit Note No</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.entryNumber }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="debitNoteDate">
                    <th mat-header-cell *matHeaderCellDef>Debit Note Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="debitNoteDateSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="debitNoteDateSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #debitNoteDateSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="fixedAssetNo">
                    <th mat-header-cell *matHeaderCellDef>Fixed Asset No</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.entryNumber }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="fixedAssetDate">
                    <th mat-header-cell *matHeaderCellDef>Fixed Asset Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="fixedAssetDateSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="fixedAssetDateSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #fixedAssetDateSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="accountIBANCode">
                    <th mat-header-cell *matHeaderCellDef>AccountIBAN Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.accountIBANCode }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="addressLine1">
                    <th mat-header-cell *matHeaderCellDef>AddressLine 1</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.addressLine1 }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="addressLine2">
                    <th mat-header-cell *matHeaderCellDef>AddressLine 2</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.addressLine2 }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="bankAccountName">
                    <th mat-header-cell *matHeaderCellDef>Bank Account Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.bankAccountName }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="bankAccountNumber">
                    <th mat-header-cell *matHeaderCellDef>
                      Bank Account Number
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.bankAccountNumber }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="branchCode">
                    <th mat-header-cell *matHeaderCellDef>Branch Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.branchCode }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef>City</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.city }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="contactName">
                    <th mat-header-cell *matHeaderCellDef>Contact Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.contactName }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef>Country</th>
                    <td mat-cell *matCellDef="let element">
                      <ng-select
                        #country
                        (focus)="country.open()"
                        (valueChange)="country.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.countryId"
                        panelClass="mat-select-position"
                        appendTo="body"
                        disabled
                        required
                      >
                        <ng-option
                          *ngFor="let cl of countryList"
                          [value]="cl.id"
                        >
                          <div title="{{ cl.description }}" class="clamp">
                            {{ cl.description }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.email }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="openingBalance">
                    <th mat-header-cell *matHeaderCellDef>Opening Balance</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.openingBalance }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Phone</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.phone }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="postCode">
                    <th mat-header-cell *matHeaderCellDef>PostCode</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.postCode }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="tradingName">
                    <th mat-header-cell *matHeaderCellDef>Trading Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.tradingName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatRegistrationNo">
                    <th mat-header-cell *matHeaderCellDef>
                      Vat RegistrationNo
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.vatRegistrationNo }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="depositAccount">
                    <th mat-header-cell *matHeaderCellDef>Deposit Account</th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        this.importForm.controls.depositAccountObj.value?.name
                      }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="incomeAccount">
                    <th mat-header-cell *matHeaderCellDef>Sales Account</th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        this.importForm.controls.incomeAccountObj.value?.name
                      }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="debit">
                    <th mat-header-cell *matHeaderCellDef>Debit</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.debit }}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                      {{ totalDebit }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="credit">
                    <th mat-header-cell *matHeaderCellDef>Credit</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.credit }}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                      {{ totalCredit }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="account">
                    <th mat-header-cell *matHeaderCellDef>Account</th>
                    <td mat-cell *matCellDef="let element">
                      <ng-select
                        required
                        #accountName
                        [items]="accountGroupList"
                        (focus)="accountName.open()"
                        (valueChange)="accountName.close()"
                        class="bank-account"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.accountId"
                        bindLabel="name"
                        bindValue="id"
                        groupBy="groupName"
                        panelClass="mat-select-position"
                        appendTo="body"
                      >
                        <ng-template ng-option-tmp let-item="item">
                          <span
                            class="ng-option-label"
                            [attr.title]="item.name"
                            >{{ item.name }}</span
                          >
                        </ng-template>
                      </ng-select>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                  </ng-container>

                  <ng-container matColumnDef="shareHolder">
                    <th mat-header-cell *matHeaderCellDef>Shareholder</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #shareholder
                        [disableOptionCentering]="true"
                        (focus)="shareholder.open()"
                        (valueChange)="shareholder.close()"
                        [(ngModel)]="element.shareholderId"
                        panelClass="mat-select-position"
                        [disabled]="!element.shareholderError"
                        appendTo="body"
                        (ngModelChange)="checkValidElement(element, i)"
                        required
                      >
                        <ng-option
                          *ngFor="let option of shareHolderList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}">{{ option.name }}</div>
                        </ng-option>
                      </ng-select>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="shareClassType">
                    <th mat-header-cell *matHeaderCellDef class="w-170">
                      Share Class Type
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #shareClassType
                        [disableOptionCentering]="true"
                        (focus)="shareClassType.open()"
                        (valueChange)="shareClassType.close()"
                        [(ngModel)]="element.shareClassTypeId"
                        panelClass="mat-select-position"
                        [disabled]="!element.shareClassTypeIdError"
                        appendTo="body"
                        (ngModelChange)="checkValidElement(element, i)"
                        required
                      >
                        <ng-option title="--Select--">--Select--</ng-option>
                        <ng-option
                          *ngFor="let option of shareClassTypeList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}">{{ option.name }}</div>
                        </ng-option>
                      </ng-select>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="yearEndDate">
                    <th mat-header-cell *matHeaderCellDef>Year End Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.yearEndDateError">
                        {{ element.yearEndDate | date: "dd-LLL-yyyy" }}
                      </span>

                      <mat-form-field
                        *ngIf="element.yearEndDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.yearEndDate"
                          [matDatepicker]="yearEndDateSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="yearEndDateSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #yearEndDateSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="declarationDate">
                    <th mat-header-cell *matHeaderCellDef>Declaration Date</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.declarationDateError">
                        {{ element.declarationDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        appearance="standard"
                        class="w-150"
                        *ngIf="element.declarationDateError"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.declarationDate"
                          [matDatepicker]="declarationDateSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="declarationDateSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #declarationDateSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="paymentDate">
                    <th mat-header-cell *matHeaderCellDef>Payment Date</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.paymentDate | date: "dd-LLL-yyyy" }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="dividendPerShare">
                    <th mat-header-cell *matHeaderCellDef>
                      Dividend Per Share
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.dividendPerShare }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="taxCredit">
                    <th mat-header-cell *matHeaderCellDef>Tax Credit</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.excludeTaxCredit }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="postJournal">
                    <th mat-header-cell *matHeaderCellDef>Post Journal</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.isPostJournal }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef>FirstName</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.firstName }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef>Last Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.lastName }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="appointmentDate">
                    <th mat-header-cell *matHeaderCellDef>AppointmentDate</th>
                    <td mat-cell *matCellDef="let element">
                      <span *ngIf="!element.appointmentDateError">
                        {{ element.appointmentDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.appointmentDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.appointmentDate"
                          [matDatepicker]="appointmentSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="appointmentSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #appointmentSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="shareholder">
                    <th mat-header-cell *matHeaderCellDef>Shareholder</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.isShareHolder }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="valuePerShare">
                    <th mat-header-cell *matHeaderCellDef>ValuePerShare</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.valuePerShare }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="noOfShare">
                    <th mat-header-cell *matHeaderCellDef>NoOfShare</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.noOfShare }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef class="w-100">
                      Code
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.code }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="trackProductInInvetory">
                    <th mat-header-cell *matHeaderCellDef class="w-100">
                      Track Product In Inventory Register
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.trackProductInInvetory }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>Description</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.description }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="ShareType">
                    <th mat-header-cell *matHeaderCellDef>Share Type</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field>
                        <mat-select
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.shareType"
                          panelClass="mat-select-position"
                          [disabled]="!element.shareTypeError"
                        >
                          <mat-option [value]="1" title="Equity"
                            >Equity</mat-option
                          >
                          <mat-option [value]="2" title="Non-Equity"
                            >Non-Equity</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>
                  <ng-container
                    matColumnDef="type"
                    *ngIf="this.selectedModuleId === module.QuickEntry"
                  >
                    <th mat-header-cell *matHeaderCellDef>Type</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field class="w-100">
                        <mat-select
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.type"
                          dropdownPosition="top"
                          [disabled]="!element.typeError"
                          panelClass="mat-select-position"
                        >
                          <mat-option
                            value="{{ this.commonService.defaultGuidValue }}"
                            title="--Select--"
                            >--Select--</mat-option
                          >
                          <mat-option
                            *ngFor="let option of quickEntryTypeList"
                            [value]="option.id"
                          >
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container
                    matColumnDef="customerorsupplier"
                    *ngIf="this.selectedModuleId === module.QuickEntry"
                  >
                    <th mat-header-cell *matHeaderCellDef>
                      Customer/Supplier Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon
                            (click)="gotoAddCustomerOrSupplier()"
                            title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #customerName
                        [disableOptionCentering]="true"
                        (focus)="customerName.open()"
                        (valueChange)="customerName.close()"
                        [(ngModel)]="element.customerSupplierId"
                        panelClass="mat-select-position"
                        [disabled]="!element.customerSupplierError"
                        appendTo="body"
                        required
                        (click)="getCustomerOrSupplierList(i)"
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of customeOrSupplierList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}" class="clamp">
                            {{ option.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>
                  <ng-container
                    matColumnDef="reference"
                    *ngIf="this.selectedModuleId === module.QuickEntry"
                  >
                    <th mat-header-cell *matHeaderCellDef>Reference</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.reference }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>
                  <ng-container matColumnDef="qeaccount">
                    <th mat-header-cell *matHeaderCellDef>Account Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        required
                        #accountName
                        [items]="accountGroupList"
                        (focus)="accountName.open()"
                        (valueChange)="accountName.close()"
                        class="bank-account"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.accountId"
                        bindLabel="name"
                        bindValue="id"
                        groupBy="groupName"
                        (ngModelChange)="checkValidElement(element, i, true)"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.accountIdError"
                        (click)="onClickAccountList(i)"
                      >
                        <ng-template ng-option-tmp let-item="item">
                          <span
                            class="ng-option-label"
                            [attr.title]="item.name"
                            >{{ item.name }}</span
                          >
                        </ng-template>
                      </ng-select>
                    </td>
                  </ng-container>
                  <ng-container
                    matColumnDef="netAmount"
                    *ngIf="this.selectedModuleId === module.QuickEntry"
                  >
                    <th mat-header-cell *matHeaderCellDef>Net Amount</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.netAmount }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>
                  <ng-container
                    matColumnDef="payAccountName"
                    *ngIf="this.selectedModuleId === module.QuickEntry"
                  >
                    <th mat-header-cell *matHeaderCellDef>Pay Account Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field class="w-100">
                        <mat-select
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.paymentAccountId"
                          dropdownPosition="top"
                          [disabled]="!element.paymentAccountIdError"
                          panelClass="mat-select-position"
                        >
                          <mat-option
                            value="{{ this.commonService.defaultGuidValue }}"
                            title="--Select--"
                            >--Select--</mat-option
                          >
                          <mat-option
                            *ngFor="let option of paymentAccountList"
                            [value]="option.id"
                          >
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <div *ngIf="this.selectedModuleId === module.TrialBalance">
                    <ng-container
                      *ngFor="let columnName of displayedColumns2"
                      [matColumnDef]="columnName"
                    >
                      <td
                        mat-footer-cell
                        *matFooterCellDef="let row; let i = index"
                        [ngClass]="
                          countDifferenceOfDrCr(columnName) === 'Difference'
                            ? 'text-align-left'
                            : 'text-align-center'
                        "
                      >
                        {{ countDifferenceOfDrCr(columnName) }}
                      </td>
                    </ng-container>
                  </div>
                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                  <div *ngIf="this.selectedModuleId === module.TrialBalance">
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                    <tr
                      mat-footer-row
                      *matFooterRowDef="displayedColumns2"
                    ></tr>
                  </div>
                </table>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="importErrorList.data.length > 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon fontSet="material-icons-outlined" class="text-primary"
                  >error_outline</mat-icon
                >
                <div class="d-flex align-items-center gap-40">
                  <span>Error List ({{ importErrorList.data.length }})</span>
                  <button
                    class="action-button primary-button"
                    mat-button
                    disableRipple
                    *ngIf="selectedModuleId === module.TrialBalance"
                    (click)="gotoAddCustomAccount()"
                  >
                    <mat-icon class="p-0 m-0 no-border button-icon"
                      >add</mat-icon
                    >
                    Add Custom Account
                  </button>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="content-body">
              <div class="mat-table-wrapper">
                <table
                  mat-table
                  aria-describedby="Import Error List"
                  [dataSource]="importErrorList"
                >
                  <ng-container
                    matColumnDef="customerName"
                    *ngIf="this.selectedModuleId !== module.CIS"
                  >
                    <th mat-header-cell *matHeaderCellDef class="w-170">
                      Customer Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon (click)="gotoAddCustomer()" title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #customerName
                        [disableOptionCentering]="true"
                        (focus)="customerName.open()"
                        (valueChange)="customerName.close()"
                        [(ngModel)]="element.accountId"
                        panelClass="mat-select-position"
                        [disabled]="!element.accountError"
                        appendTo="body"
                        (ngModelChange)="checkValidElement(element, i)"
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of customerList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}" class="clamp">
                            {{ option.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container
                    matColumnDef="customerName"
                    *ngIf="this.selectedModuleId === module.CIS"
                  >
                    <th mat-header-cell *matHeaderCellDef class="w-170">
                      Customer Name
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #customerName
                        [disableOptionCentering]="true"
                        (focus)="customerName.open()"
                        (valueChange)="customerName.close()"
                        [(ngModel)]="element.customerId"
                        panelClass="mat-select-position"
                        [disabled]="!element.customerNameError"
                        (ngModelChange)="checkValidElement(element, i)"
                        appendTo="body"
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of subcontractorList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}" class="clamp">
                            {{ option.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Material Amount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Material Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.materialAmount | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="Total Payment">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Total Payment
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.totalPayment | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="Rate of CIS">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Rate of CIS
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.rateOfCIS | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="Tax Amount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Tax Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.taxAmount | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="accountName">
                    <th mat-header-cell *matHeaderCellDef>Account Name</th>
                    <td mat-cell *matCellDef="let element ; let i = index">
                      <!-- <div
                        class="clamp"
                        title="{{
                          this.importForm.controls.accountObj.value?.name
                        }}"
                      >
                        {{ this.importForm.controls.accountObj.value?.name }}
                      </div> -->
                      <mat-form-field appearance="fill" class="w-200">
                        <input
                          type="text"
                          matInput
                          [disabled]="!element.bookAccountError"
                          [(ngModel)]="element.accountData"
                          [matAutocomplete]="accountGroup"
                          #trigger="matAutocompleteTrigger"
                          appAutocompletePosition="trigger"
                          (keyup)="onAccountSearch($event)"
                        />
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                        <mat-autocomplete
                          [displayWith]="getOptionText"
                         
                          #accountGroup="matAutocomplete"
                          (optionSelected)="onOptionSelected(i,true)"
                        >
                          <mat-optgroup
                            *ngFor="let group of accountGroupList"
                            [label]="group.groupName"
                            title="{{ group.groupName }}"
                          >
                            <mat-option
                              *ngFor="let names of group.listModels"
                              [value]="names"
                              title="{{ names.name }}"
                            >
                              {{ names.name }}
                            </mat-option>
                          </mat-optgroup>
                        </mat-autocomplete>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="invoiceNo">
                    <th mat-header-cell *matHeaderCellDef>Invoice No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkErrorEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container
                    matColumnDef="journalNo"
                    *ngIf="this.selectedModuleId === module.Journals"
                  >
                    <th mat-header-cell *matHeaderCellDef>Journal No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkErrorEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container
                    matColumnDef="invoiceDate"
                    *ngIf="
                      this.selectedModuleId !== module.CIS &&
                      this.selectedModuleId !== module.QuickEntry
                    "
                  >
                    <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="invoiceErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.entryDate,
                              importDateName.invoiceDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="invoiceErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #invoiceErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container
                    matColumnDef="invoiceDate"
                    *ngIf="
                      this.selectedModuleId === module.CIS ||
                      this.selectedModuleId === module.QuickEntry
                    "
                  >
                    <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.invoiceDateError">
                        {{ element.invoiceDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.invoiceDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.invoiceDate"
                          [matDatepicker]="invoiceErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.invoiceDate,
                              importDateName.invoiceDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="invoiceErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #invoiceErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container
                    matColumnDef="journalDate"
                    *ngIf="this.selectedModuleId === module.Journals"
                  >
                    <th mat-header-cell *matHeaderCellDef>Journal Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.journalDateError">
                        {{ element.journalDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.journalDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.journalDate"
                          [matDatepicker]="invoiceErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.journalDate,
                              importDateName.journalDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="invoiceErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #invoiceErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="dueDate">
                    <th mat-header-cell *matHeaderCellDef>Due Date</th>
                    <td mat-cell *matCellDef="let element; let i">
                      <span *ngIf="!element.dueDateError">
                        {{ element.dueDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.dueDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.dueDate"
                          [matDatepicker]="dueDateErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.dueDate,
                              importDateName.dueDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="dueDateErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #dueDateErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="productName">
                    <th mat-header-cell *matHeaderCellDef>Product Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.productNameError">{{
                        element.productName
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.productNameError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.productName"
                          (focusout)="checkErrorProductName(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="qty">
                    <th mat-header-cell *matHeaderCellDef class="w-50">Qty</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.quantity }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Price
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.priceError">{{
                        element.price
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.priceError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.price"
                          (focusout)="checkErrorProductPrice(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatRate">
                    <th mat-header-cell *matHeaderCellDef class="w-100">
                      Vat Rate
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field class="w-100">
                        <mat-select
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.vatRateId"
                          dropdownPosition="top"
                          [disabled]="!element.vatRateError"
                          panelClass="mat-select-position"
                          (ngModelChange)="checkValidElement(element, i)"
                        >
                          <mat-option
                            value="{{ this.commonService.defaultGuidValue }}"
                            title="--Select--"
                            >--Select--</mat-option
                          >
                          <mat-option
                            *ngFor="let option of vatRateList[i]"
                            [value]="option.id"
                            title="{{ option.name }}"
                          >
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatAmount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Vat Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.vatAmount }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="totalAmount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Total Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.totalAmount }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="supplierName">
                    <th mat-header-cell *matHeaderCellDef>
                      Supplier Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon (click)="gotoAddSupplier()" title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #suppliername
                        [disableOptionCentering]="true"
                        (focus)="suppliername.open()"
                        (valueChange)="suppliername.close()"
                        [(ngModel)]="element.accountId"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.accountError"
                        (ngModelChange)="checkValidElement(element, i)"
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of supplierList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}" class="clamp">
                            {{ option.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="billNo">
                    <th mat-header-cell *matHeaderCellDef>Purchase No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkErrorEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="billDate">
                    <th mat-header-cell *matHeaderCellDef>Purchase Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="billDateErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.entryDate,
                              importDateName.billDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="billDateErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #billDateErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="creditNoteNo">
                    <th mat-header-cell *matHeaderCellDef>Credit Note No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkErrorEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="creditNoteDate">
                    <th mat-header-cell *matHeaderCellDef>Credit Note Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="creditNoteDateErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.entryDate,
                              importDateName.creditNoteDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="creditNoteDateErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #creditNoteDateErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="debitNoteNo">
                    <th mat-header-cell *matHeaderCellDef>Debit Note No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkErrorEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="debitNoteDate">
                    <th mat-header-cell *matHeaderCellDef>Debit Note Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="debitNoteDateErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.entryDate,
                              importDateName.debitNoteDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="debitNoteDateErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #debitNoteDateErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fixedAssetNo">
                    <th mat-header-cell *matHeaderCellDef>Fixed Asset No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkErrorEntryNumber(element, i)"
                          maxlength="{{ maxLength.maxLength25 }}"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fixedAssetDate">
                    <th mat-header-cell *matHeaderCellDef>Fixed Asset Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="fixedAssestDateErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.entryDate,
                              importDateName.fixedAssetDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="fixedAssestDateErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #fixedAssestDateErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="accountIBANCode">
                    <th mat-header-cell *matHeaderCellDef>AccountIBAN Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.accountIBANCode }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="addressLine1">
                    <th mat-header-cell *matHeaderCellDef>AddressLine 1</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.addressLine1 }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="addressLine2">
                    <th mat-header-cell *matHeaderCellDef>AddressLine 2</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.addressLine2 }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bankAccountName">
                    <th mat-header-cell *matHeaderCellDef>Bank Account Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.bankAccountName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bankAccountNumber">
                    <th mat-header-cell *matHeaderCellDef>
                      Bank Account Number
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.bankAccountNumber }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="branchCode">
                    <th mat-header-cell *matHeaderCellDef>Branch Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.branchCode }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef>City</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.city }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="contactName">
                    <th mat-header-cell *matHeaderCellDef>Contact Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          unicodeCharacter
                          [(ngModel)]="element.contactName"
                          (focusout)="checkCustomerValidElement(element, i)"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength180 }}"
                          type="text"
                          [disabled]="!element.contactNameError"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef>Country</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #country
                        (focus)="country.open()"
                        (valueChange)="country.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.countryId"
                        (ngModelChange)="checkCustomerValidElement(element, i)"
                        [disabled]="!element.countryError"
                        panelClass="mat-select-position"
                        appendTo="body"
                        required
                      >
                        <ng-option
                          *ngFor="let cl of countryList"
                          [value]="cl.id"
                        >
                          <div title="{{ cl.description }}" class="clamp">
                            {{ cl.description }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="element.email"
                          (focusout)="checkCustomerValidElement(element, i)"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength180 }}"
                          type="email"
                          [disabled]="!element.emailError"
                          emailValidator
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="openingBalance">
                    <th mat-header-cell *matHeaderCellDef>Opening Balance</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.openingBalance }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Phone</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.phone }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="postCode">
                    <th mat-header-cell *matHeaderCellDef>PostCode</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.postCode }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="tradingName">
                    <th mat-header-cell *matHeaderCellDef>Trading Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.tradingName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatRegistrationNo">
                    <th mat-header-cell *matHeaderCellDef>
                      Vat RegistrationNo
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.vatRegistrationNo }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="depositAccount">
                    <th mat-header-cell *matHeaderCellDef>Deposit Account</th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        this.importForm.controls.depositAccountObj.value?.name
                      }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="incomeAccount">
                    <th mat-header-cell *matHeaderCellDef>Sales Account</th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        this.importForm.controls.incomeAccountObj.value?.name
                      }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="debit">
                    <th mat-header-cell *matHeaderCellDef>Debit</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span>{{ element.debit }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="credit">
                    <th mat-header-cell *matHeaderCellDef>Credit</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span>{{ element.credit }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="account">
                    <th mat-header-cell *matHeaderCellDef>Account Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <div
                        class="d-flex align-items-center gap-10"
                        [ngClass]="element.accountError ? 'pt-10 pb-10' : ''"
                      >
                        <ng-select
                          required
                          #accountName
                          [items]="accountGroupList"
                          (focus)="accountName.open()"
                          (valueChange)="accountName.close()"
                          class="bank-account"
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.accountId"
                          bindLabel="name"
                          bindValue="id"
                          groupBy="groupName"
                          (ngModelChange)="checkValidElement(element, i, true)"
                          panelClass="mat-select-position"
                          appendTo="body"
                        >
                          <ng-template ng-option-tmp let-item="item">
                            <span
                              class="ng-option-label"
                              [attr.title]="item.name"
                              >{{ item.name }}</span
                            >
                          </ng-template>
                        </ng-select>
                        <span *ngIf="element.accountError">{{
                          element.accountErrorValue
                        }}</span>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="shareHolder">
                    <th mat-header-cell *matHeaderCellDef>Shareholder</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #shareholder
                        [disableOptionCentering]="true"
                        (focus)="shareholder.open()"
                        (valueChange)="shareholder.close()"
                        [(ngModel)]="element.shareholderId"
                        panelClass="mat-select-position"
                        [disabled]="!element.shareholderError"
                        appendTo="body"
                        (ngModelChange)="checkValidElement(element, i)"
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of shareHolderList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}">{{ option.name }}</div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="shareClassType">
                    <th mat-header-cell *matHeaderCellDef class="w-170">
                      Share Class Type
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #shareClassType
                        [disableOptionCentering]="true"
                        (focus)="shareClassType.open()"
                        (valueChange)="shareClassType.close()"
                        [(ngModel)]="element.shareClassTypeId"
                        panelClass="mat-select-position"
                        [disabled]="!element.shareClassTypeIdError"
                        appendTo="body"
                        (ngModelChange)="checkValidElement(element, i)"
                        required
                      >
                        <ng-option value="0" title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of shareClassTypeList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}">{{ option.name }}</div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="yearEndDate">
                    <th mat-header-cell *matHeaderCellDef>Year End Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.yearEndDateError">{{
                        element.yearEndDate | date: "dd-LLL-yyyy"
                      }}</span>
                      <mat-form-field
                        *ngIf="element.yearEndDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.yearEndDate"
                          [matDatepicker]="yearEndDateErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.yearEndDate,
                              importDateName.yearEndDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="yearEndDateErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #yearEndDateErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="declarationDate">
                    <th mat-header-cell *matHeaderCellDef>Declaration Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.declarationDateError">
                        {{ element.declarationDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        appearance="standard"
                        class="w-150"
                        *ngIf="element.declarationDateError"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.declarationDate"
                          [matDatepicker]="declarationDateSuccessDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (ngModelChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.declarationDate,
                              importDateName.declarationDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="declarationDateSuccessDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #declarationDateSuccessDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="paymentDate">
                    <th mat-header-cell *matHeaderCellDef>Payment Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.paymentDateError">
                        {{ element.paymentDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        appearance="standard"
                        *ngIf="element.paymentDateError"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.paymentDate"
                          [matDatepicker]="paymentDateErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              true,
                              element.paymentDate,
                              importDateName.paymentDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="paymentDateErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #paymentDateErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="dividendPerShare">
                    <th mat-header-cell *matHeaderCellDef>
                      Dividend Per Share
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.dividendPerShare }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="taxCredit">
                    <th mat-header-cell *matHeaderCellDef>Tax Credit</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.excludeTaxCredit }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="postJournal">
                    <th mat-header-cell *matHeaderCellDef>Post Journal</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.isPostJournal }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef>FirstName</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.firstNameError">{{
                        element.firstName
                      }}</span>
                      <mat-form-field *ngIf="element.firstNameError">
                        <input
                          matInput
                          [(ngModel)]="element.firstName"
                          (focusout)="checkCustomerValidElement(element, i)"
                          autocomplete="off"
                          type="text"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef>Last Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.lastName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="appointmentDate">
                    <th mat-header-cell *matHeaderCellDef>Appointment Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.appointmentDateError">
                        {{ element.appointmentDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        appearance="standard"
                        *ngIf="element.appointmentDateError"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.appointmentDate"
                          [matDatepicker]="appointmentErrorDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidAppointmentDate(
                              element,
                              i,
                              importDateName.appointmentDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="appointmentErrorDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #appointmentErrorDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="shareholder">
                    <th mat-header-cell *matHeaderCellDef>Shareholder</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.isShareHolder }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="valuePerShare">
                    <th mat-header-cell *matHeaderCellDef>ValuePerShare</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.valuePerShareError">{{
                        element.valuePerShare
                      }}</span>
                      <mat-form-field *ngIf="element.valuePerShareError">
                        <input
                          matInput
                          [(ngModel)]="element.valuePerShare"
                          (ngModelChange)="
                            checkCustomerValidElement(element, i)
                          "
                          autocomplete="off"
                          type="number"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="noOfShare">
                    <th mat-header-cell *matHeaderCellDef>NoOfShare</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.noOfShareError">{{
                        element.noOfShare
                      }}</span>
                      <mat-form-field *ngIf="element.noOfShareError">
                        <input
                          matInput
                          [(ngModel)]="element.noOfShare"
                          (ngModelChange)="
                            checkCustomerValidElement(element, i)
                          "
                          autocomplete="off"
                          type="number"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef class="w-100">
                      Code
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.code }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="trackProductInInvetory">
                    <th mat-header-cell *matHeaderCellDef class="w-100">
                      Track Product In Inventory Register
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.trackProductInInvetory }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>Description</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.description }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="ShareType">
                    <th mat-header-cell *matHeaderCellDef>Share Type</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <mat-select
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.shareType"
                          panelClass="mat-select-position"
                          [disabled]="!element.shareTypeError"
                          (ngModelChange)="
                            checkCustomerValidElement(element, i)
                          "
                        >
                          <mat-option [value]="1" title="Equity"
                            >Equity</mat-option
                          >
                          <mat-option [value]="2" title="Non-Equity"
                            >Non-Equity</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container
                    matColumnDef="type"
                    *ngIf="this.selectedModuleId === module.QuickEntry"
                  >
                    <th mat-header-cell *matHeaderCellDef>Type</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field class="w-100">
                        <mat-select
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.type"
                          dropdownPosition="top"
                          [disabled]="!element.typeError"
                          panelClass="mat-select-position"
                        >
                          <mat-option
                            value="{{ this.commonService.defaultGuidValue }}"
                            title="--Select--"
                            >--Select--</mat-option
                          >
                          <mat-option
                            *ngFor="let option of quickEntryTypeList"
                            [value]="option.id"
                          >
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container
                    matColumnDef="customerorsupplier"
                    *ngIf="this.selectedModuleId === module.QuickEntry"
                  >
                    <th mat-header-cell *matHeaderCellDef>
                      Customer/Supplier Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon
                            (click)="gotoAddCustomerOrSupplier()"
                            title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #customerorSupplierName
                        [disableOptionCentering]="true"
                        (focus)="customerorSupplierName.open()"
                        (valueChange)="customerorSupplierName.close()"
                        [(ngModel)]="element.customerSupplierId"
                        panelClass="mat-select-position"
                        [disabled]="!element.customerSupplierError"
                        appendTo="body"
                        required
                        (click)="getCustomerOrSupplierList(i)"
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of customeOrSupplierList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}" class="clamp">
                            {{ option.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>
                  <ng-container
                    matColumnDef="reference"
                    *ngIf="this.selectedModuleId === module.QuickEntry"
                  >
                    <th mat-header-cell *matHeaderCellDef>Reference</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.reference }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>
                  <ng-container matColumnDef="qeaccount">
                    <th mat-header-cell *matHeaderCellDef>Account Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        required
                        #accountName
                        [items]="accountGroupList"
                        (focus)="accountName.open()"
                        (valueChange)="accountName.close()"
                        class="bank-account"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.accountId"
                        bindLabel="name"
                        bindValue="id"
                        groupBy="groupName"
                        (ngModelChange)="checkValidElement(element, i)"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.accountIdError"
                        (click)="onClickAccountList(i)"
                      >
                        <ng-template ng-option-tmp let-item="item">
                          <span
                            class="ng-option-label"
                            [attr.title]="item.name"
                            >{{ item.name }}</span
                          >
                        </ng-template>
                      </ng-select>
                    </td>
                  </ng-container>
                  <ng-container
                    matColumnDef="netAmount"
                    *ngIf="this.selectedModuleId === module.QuickEntry"
                  >
                    <th mat-header-cell *matHeaderCellDef>Net Amount</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.netAmount }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>
                  <ng-container
                    matColumnDef="payAccountName"
                    *ngIf="this.selectedModuleId === module.QuickEntry"
                  >
                    <th mat-header-cell *matHeaderCellDef>Pay Account Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field class="w-100">
                        <mat-select
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.paymentAccountId"
                          dropdownPosition="top"
                          [disabled]="!element.paymentAccountIdError"
                          panelClass="mat-select-position"
                        >
                          <mat-option
                            value="{{ this.commonService.defaultGuidValue }}"
                            title="--Select--"
                            >--Select--</mat-option
                          >
                          <mat-option
                            *ngFor="let option of paymentAccountList"
                            [value]="option.id"
                          >
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="importDuplicateList.data.length > 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon fontSet="material-icons-outlined" class="text-primary"
                  >content_copy</mat-icon
                >
                <div class="d-flex flex-column">
                  <span
                    >Duplicate List ({{
                      importDuplicateList.data.length
                    }})</span
                  >
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="content-body">
              <div class="mat-table-wrapper">
                <table
                  mat-table
                  aria-describedby="Duplicate List"
                  [dataSource]="importDuplicateList"
                >
                  <ng-container matColumnDef="customerName">
                    <th mat-header-cell *matHeaderCellDef class="w-170">
                      Customer Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon (click)="gotoAddCustomer()" title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <ng-select
                        #customerName
                        [disableOptionCentering]="true"
                        (focus)="customerName.open()"
                        (valueChange)="customerName.close()"
                        [(ngModel)]="element.accountId"
                        panelClass="mat-select-position"
                        [disabled]="!element.accountError"
                        appendTo="body"
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of customerList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}">{{ option.name }}</div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="Material Amount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Material Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.materialAmount | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="Total Payment">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Total Payment
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.totalPayment | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="Rate of CIS">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Rate of CIS
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.rateOfCIS | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>

                  <ng-container matColumnDef="Tax Amount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Tax Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.taxAmount | numberPipe }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>
                  <ng-container matColumnDef="accountName">
                    <th mat-header-cell *matHeaderCellDef>Account Name</th>
                    <td mat-cell *matCellDef="let element">
                      <!-- <div
                        class="clamp"
                        title="{{
                          this.importForm.controls.accountObj.value?.name
                        }}"
                      >
                        {{ this.importForm.controls.accountObj.value?.name }}
                      </div> -->

                      <mat-form-field appearance="fill" class="w-200">
                        <input
                          type="text"
                          matInput
                          [disabled]="!element.bookAccountError"
                          [(ngModel)]="element.accountData"
                          [matAutocomplete]="accountGroup"
                          #trigger="matAutocompleteTrigger"
                          appAutocompletePosition="trigger"
                          (keyup)="onAccountSearch($event)"
                        />
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                        <mat-autocomplete
                          [displayWith]="getOptionText"
                          (opened)="
                            scrollIntoView(element.bookAccountId)
                          "
                          #accountGroup="matAutocomplete"
                        >
                          <mat-optgroup
                            *ngFor="let group of accountGroupList"
                            [label]="group.groupName"
                            title="{{ group.groupName }}"
                          >
                            <mat-option
                              *ngFor="let names of group.listModels"
                              [value]="names"
                              title="{{ names.name }}"
                            >
                              {{ names.name }}
                            </mat-option>
                          </mat-optgroup>
                        </mat-autocomplete>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="invoiceNo">
                    <th mat-header-cell *matHeaderCellDef>Invoice No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container
                    matColumnDef="journalNo"
                    *ngIf="this.selectedModuleId === module.Journals"
                  >
                    <th mat-header-cell *matHeaderCellDef>Journal No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="invoiceDate">
                    <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="invoiceDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.entryDate,
                              importDateName.invoiceDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="invoiceDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #invoiceDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container
                    matColumnDef="journalDate"
                    *ngIf="this.selectedModuleId === module.Journals"
                  >
                    <th mat-header-cell *matHeaderCellDef>Journal Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.journalDateError">
                        {{ element.journalDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.journalDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.journalDate"
                          [matDatepicker]="invoiceDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.journalDate,
                              importDateName.journalDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="invoiceDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #invoiceDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="dueDate">
                    <th mat-header-cell *matHeaderCellDef>Due Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.dueDateError">
                        {{ element.dueDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.dueDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.dueDate"
                          [matDatepicker]="dueDateDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.dueDate,
                              importDateName.dueDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="dueDateDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #dueDateDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="productName">
                    <th mat-header-cell *matHeaderCellDef>Product Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.productNameError">{{
                        element.productName
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.productNameError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.productName"
                          (focusout)="checkProductName(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="qty">
                    <th mat-header-cell *matHeaderCellDef class="w-80">Qty</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.quantity }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Price
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.price }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatRate">
                    <th mat-header-cell *matHeaderCellDef class="w-100">
                      Vat Rate
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field class="w-100">
                        <mat-select
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.vatRateId"
                          dropdownPosition="top"
                          [disabled]="!element.vatRateError"
                          panelClass="mat-select-position"
                        >
                          <mat-option
                            value="{{ this.commonService.defaultGuidValue }}"
                            title="--Select--"
                            >--Select--</mat-option
                          >
                          <mat-option
                            *ngFor="let option of vatRateList"
                            [value]="option.id"
                            title="{{ option.name }}"
                          >
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatAmount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Vat Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.vatAmount }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="totalAmount">
                    <th mat-header-cell *matHeaderCellDef class="w-80">
                      Total Amount
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.totalAmount }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="supplierName">
                    <th mat-header-cell *matHeaderCellDef>
                      Supplier Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon (click)="gotoAddSupplier()" title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <ng-select
                        #suppliername
                        [disableOptionCentering]="true"
                        (focus)="suppliername.open()"
                        (valueChange)="suppliername.close()"
                        [(ngModel)]="element.accountId"
                        panelClass="mat-select-position"
                        [disabled]="!element.accountError"
                        appendTo="body"
                        required
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of supplierList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}" class="clamp">
                            {{ option.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="billNo">
                    <th mat-header-cell *matHeaderCellDef>Purchase No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="billDate">
                    <th mat-header-cell *matHeaderCellDef>Purchase Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="billDateDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.entryDate,
                              importDateName.billDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="billDateDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #billDateDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="creditNoteNo">
                    <th mat-header-cell *matHeaderCellDef>Credit Note No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="creditNoteDate">
                    <th mat-header-cell *matHeaderCellDef>Credit Note Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="creditNoteDateDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.entryDate,
                              importDateName.creditNoteDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="creditNoteDateDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #creditNoteDateDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="debitNoteNo">
                    <th mat-header-cell *matHeaderCellDef>Debit Note No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="debitNoteDate">
                    <th mat-header-cell *matHeaderCellDef>Debit Note Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="debitNoteDateDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.entryDate,
                              importDateName.debitNoteDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="debitNoteDateDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #debitNoteDateDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fixedAssetNo">
                    <th mat-header-cell *matHeaderCellDef>Fixed Asset No</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryNumberError">{{
                        element.entryNumber
                      }}</span>
                      <mat-form-field
                        floatLabel="never"
                        *ngIf="element.entryNumberError"
                      >
                        <input
                          matInput
                          autocomplete="off"
                          [(ngModel)]="element.entryNumber"
                          (focusout)="checkEntryNumber(element, i)"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fixedAssetDate">
                    <th mat-header-cell *matHeaderCellDef>Fixed Asset Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.entryDateError">
                        {{ element.entryDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.entryDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.entryDate"
                          [matDatepicker]="debitNoteDateDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.entryDate,
                              importDateName.fixedAssetDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="debitNoteDateDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #debitNoteDateDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="accountIBANCode">
                    <th mat-header-cell *matHeaderCellDef>AccountIBAN Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.accountIBANCode }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="addressLine1">
                    <th mat-header-cell *matHeaderCellDef>AddressLine 1</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.addressLine1 }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="addressLine2">
                    <th mat-header-cell *matHeaderCellDef>AddressLine 2</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.addressLine2 }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bankAccountName">
                    <th mat-header-cell *matHeaderCellDef>Bank Account Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.bankAccountName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="bankAccountNumber">
                    <th mat-header-cell *matHeaderCellDef>
                      Bank Account Number
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.bankAccountNumber }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="branchCode">
                    <th mat-header-cell *matHeaderCellDef>Branch Code</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.branchCode }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef>City</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.city }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="contactName">
                    <th mat-header-cell *matHeaderCellDef>Contact Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="element.contactName"
                          (focusout)="checkCustomerDuplicate(element, i)"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength180 }}"
                          type="text"
                          [disabled]="!element.contactNameError"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef>Country</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #country
                        (focus)="country.open()"
                        (valueChange)="country.close()"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.countryId"
                        [disabled]="!element.countryError"
                        panelClass="mat-select-position"
                        appendTo="body"
                        disabled
                        required
                      >
                        <ng-option
                          *ngFor="let cl of countryList"
                          [value]="cl.id"
                        >
                          <div title="{{ cl.description }}" class="clamp">
                            {{ cl.description }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="element.email"
                          (focusout)="checkCustomerDuplicate(element, i)"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength180 }}"
                          type="email"
                          [disabled]="!element.emailError"
                          emailValidator
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="openingBalance">
                    <th mat-header-cell *matHeaderCellDef>Opening Balance</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.openingBalance }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Phone</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.phone }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="postCode">
                    <th mat-header-cell *matHeaderCellDef>PostCode</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.postCode }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="tradingName">
                    <th mat-header-cell *matHeaderCellDef>Trading Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.tradingName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="vatRegistrationNo">
                    <th mat-header-cell *matHeaderCellDef>
                      Vat RegistrationNo
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.vatRegistrationNo }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="depositAccount">
                    <th mat-header-cell *matHeaderCellDef>Deposit Account</th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        this.importForm.controls.depositAccountObj.value?.name
                      }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="incomeAccount">
                    <th mat-header-cell *matHeaderCellDef>Sales Account</th>
                    <td mat-cell *matCellDef="let element">
                      {{
                        this.importForm.controls.incomeAccountObj.value?.name
                      }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="debit">
                    <th mat-header-cell *matHeaderCellDef>Debit</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.debit }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="credit">
                    <th mat-header-cell *matHeaderCellDef>Credit</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.credit }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="account">
                    <th mat-header-cell *matHeaderCellDef>Account</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        required
                        #accountName
                        [items]="accountGroupList"
                        (focus)="accountName.open()"
                        (valueChange)="accountName.close()"
                        class="bank-account"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.accountId"
                        bindLabel="name"
                        bindValue="id"
                        groupBy="groupName"
                        (ngModelChange)="checkValidElement(element, i)"
                        panelClass="mat-select-position"
                        appendTo="body"
                      >
                        <ng-template ng-option-tmp let-item="item">
                          <span
                            class="ng-option-label"
                            [attr.title]="item.name"
                            >{{ item.name }}</span
                          >
                        </ng-template>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="shareHolder">
                    <th mat-header-cell *matHeaderCellDef>Shareholder</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #shareholder
                        [disableOptionCentering]="true"
                        (focus)="shareholder.open()"
                        (valueChange)="shareholder.close()"
                        [(ngModel)]="element.shareholderId"
                        panelClass="mat-select-position"
                        [disabled]="!element.shareholderError"
                        appendTo="body"
                        (ngModelChange)="checkValidElement(element, i)"
                        required
                      >
                        <ng-option
                          *ngFor="let option of shareHolderList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}">{{ option.name }}</div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="shareClassType">
                    <th mat-header-cell *matHeaderCellDef class="w-170">
                      Share Class Type
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #shareClassType
                        [disableOptionCentering]="true"
                        (focus)="shareClassType.open()"
                        (valueChange)="shareClassType.close()"
                        [(ngModel)]="element.shareClassTypeId"
                        panelClass="mat-select-position"
                        [disabled]="!element.shareClassTypeIdError"
                        appendTo="body"
                        (ngModelChange)="checkValidElement(element, i)"
                        required
                      >
                        <ng-option value="0" title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of shareClassTypeList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}">{{ option.name }}</div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="yearEndDate">
                    <th mat-header-cell *matHeaderCellDef>Year End Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.yearEndDateError">
                        {{ element.yearEndDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        *ngIf="element.yearEndDateError"
                        appearance="standard"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.yearEndDate"
                          [matDatepicker]="yearEndDateDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.entryDate,
                              importDateName.yearEndDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="yearEndDateDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #yearEndDateDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="declarationDate">
                    <th mat-header-cell *matHeaderCellDef>Declaration Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.declarationDateError">
                        {{ element.declarationDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        appearance="standard"
                        class="w-150"
                        *ngIf="element.declarationDateError"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.declarationDate"
                          [matDatepicker]="declarationDateDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (ngModelChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.declarationDate,
                              importDateName.declarationDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="declarationDateDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #declarationDateDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="paymentDate">
                    <th mat-header-cell *matHeaderCellDef>Payment Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.paymentDateError">
                        {{ element.paymentDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        appearance="standard"
                        *ngIf="element.paymentDateError"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.paymentDate"
                          [matDatepicker]="paymentDateDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                          (dateChange)="
                            checkValidErrorDate(
                              element,
                              i,
                              false,
                              element.paymentDate,
                              importDateName.paymentDate
                            )
                          "
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="paymentDateDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #paymentDateDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="dividendPerShare">
                    <th mat-header-cell *matHeaderCellDef>
                      Dividend Per Share
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.dividendPerShare }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="taxCredit">
                    <th mat-header-cell *matHeaderCellDef>Tax Credit</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.excludeTaxCredit }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="postJournal">
                    <th mat-header-cell *matHeaderCellDef>Post Journal</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.isPostJournal }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef>FirstName</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field>
                        <input
                          matInput
                          [(ngModel)]="element.firstName"
                          (focusout)="checkCustomerDuplicate(element, i)"
                          autocomplete="off"
                          maxlength="{{ maxLength.maxLength180 }}"
                          type="text"
                          [disabled]="!element.firstNameError"
                        />
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef>Last Name</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.lastName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="appointmentDate">
                    <th mat-header-cell *matHeaderCellDef>Appointment Date</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <span *ngIf="!element.appointmentDateError">
                        {{ element.appointmentDate | date: "dd-LLL-yyyy" }}
                      </span>
                      <mat-form-field
                        appearance="standard"
                        *ngIf="element.appointmentDateError"
                        class="w-150"
                      >
                        <input
                          matInput
                          [(ngModel)]="element.appointmentDate"
                          [matDatepicker]="appointmentDuplicateDatePicker"
                          autocomplete="off"
                          (change)="commonService.validateDate($event)"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="appointmentDuplicateDatePicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #appointmentDuplicateDatePicker
                        ></mat-datepicker>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="shareholder">
                    <th mat-header-cell *matHeaderCellDef>Shareholder</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.isShareHolder }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="valuePerShare">
                    <th mat-header-cell *matHeaderCellDef>ValuePerShare</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.valuePerShare }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="noOfShare">
                    <th mat-header-cell *matHeaderCellDef>NoOfShare</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.noOfShare }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef class="w-100">
                      Code
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.code }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="trackProductInInvetory">
                    <th mat-header-cell *matHeaderCellDef class="w-100">
                      Track Product In Inventory Register
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.trackProductInInvetory }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>Description</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.description }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="ShareType">
                    <th mat-header-cell *matHeaderCellDef>Share Type</th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field>
                        <mat-select
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.shareType"
                          panelClass="mat-select-position"
                        >
                          <mat-option value="1" title="Equity"
                            >Equity</mat-option
                          >
                          <mat-option value="2" title="Non-Equity"
                            >Non-Equity</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container
                    matColumnDef="type"
                    *ngIf="this.selectedModuleId === module.QuickEntry"
                  >
                    <th mat-header-cell *matHeaderCellDef>Type</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field class="w-100">
                        <mat-select
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.type"
                          dropdownPosition="top"
                          [disabled]="!element.typeError"
                          panelClass="mat-select-position"
                        >
                          <mat-option
                            value="{{ this.commonService.defaultGuidValue }}"
                            title="--Select--"
                            >--Select--</mat-option
                          >
                          <mat-option
                            *ngFor="let option of quickEntryTypeList"
                            [value]="option.id"
                          >
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container
                    matColumnDef="customerorsupplier"
                    *ngIf="this.selectedModuleId === module.QuickEntry"
                  >
                    <th mat-header-cell *matHeaderCellDef>
                      Customer/Supplier Name
                      <span>
                        <button mat-button disableRipple>
                          <mat-icon
                            (click)="gotoAddCustomerOrSupplier()"
                            title="add"
                            >add_circle_outline</mat-icon
                          >
                        </button>
                      </span>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        #customerorSupplierName
                        [disableOptionCentering]="true"
                        (focus)="customerorSupplierName.open()"
                        (valueChange)="customerorSupplierName.close()"
                        [(ngModel)]="element.customerSupplierId"
                        panelClass="mat-select-position"
                        [disabled]="!element.customerSupplierError"
                        appendTo="body"
                        required
                        (click)="getCustomerOrSupplierList(i)"
                      >
                        <ng-option
                          value="{{ this.commonService.defaultGuidValue }}"
                          title="--Select--"
                          >--Select--</ng-option
                        >
                        <ng-option
                          *ngFor="let option of customeOrSupplierList"
                          [value]="option.id"
                        >
                          <div title="{{ option.name }}" class="clamp">
                            {{ option.name }}
                          </div>
                        </ng-option>
                      </ng-select>
                    </td>
                  </ng-container>
                  <ng-container
                    matColumnDef="reference"
                    *ngIf="this.selectedModuleId === module.QuickEntry"
                  >
                    <th mat-header-cell *matHeaderCellDef>Reference</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.reference }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>
                  <ng-container matColumnDef="qeaccount">
                    <th mat-header-cell *matHeaderCellDef>Account Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <ng-select
                        required
                        #accountName
                        [items]="accountGroupList"
                        (focus)="accountName.open()"
                        (valueChange)="accountName.close()"
                        class="bank-account"
                        [disableOptionCentering]="true"
                        [(ngModel)]="element.accountId"
                        bindLabel="name"
                        bindValue="id"
                        groupBy="groupName"
                        (ngModelChange)="checkValidElement(element, i)"
                        panelClass="mat-select-position"
                        appendTo="body"
                        [disabled]="!element.accountIdError"
                        (click)="onClickAccountList(i)"
                      >
                        <ng-template ng-option-tmp let-item="item">
                          <span
                            class="ng-option-label"
                            [attr.title]="item.name"
                            >{{ item.name }}</span
                          >
                        </ng-template>
                      </ng-select>
                    </td>
                  </ng-container>
                  <ng-container
                    matColumnDef="netAmount"
                    *ngIf="this.selectedModuleId === module.QuickEntry"
                  >
                    <th mat-header-cell *matHeaderCellDef>Net Amount</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.netAmount }}
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      class="text-align-center"
                    ></td>
                  </ng-container>
                  <ng-container
                    matColumnDef="payAccountName"
                    *ngIf="this.selectedModuleId === module.QuickEntry"
                  >
                    <th mat-header-cell *matHeaderCellDef>Pay Account Name</th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-form-field class="w-100">
                        <mat-select
                          [disableOptionCentering]="true"
                          [(ngModel)]="element.paymentAccountId"
                          dropdownPosition="top"
                          [disabled]="!element.paymentAccountIdError"
                          panelClass="mat-select-position"
                        >
                          <mat-option
                            value="{{ this.commonService.defaultGuidValue }}"
                            title="--Select--"
                            >--Select--</mat-option
                          >
                          <mat-option
                            *ngFor="let option of paymentAccountList"
                            [value]="option.id"
                          >
                            {{ option.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-step>
    <mat-step disableRipple>
      <ng-template matStepLabel>Details</ng-template>
      <div class="end-details">
        <div class="list-detail">
          <p>
            {{ panelHeaderTitle }} Imported ({{ finalResponse?.imported ?? 0 }})
          </p>
          <p>No. of records duplicate ({{ finalResponse?.skipped ?? 0 }})</p>
          <p>No. of error records ({{ finalResponse?.error ?? 0 }})</p>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</div>
<app-custom-notifications></app-custom-notifications>
