import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActionType, Modules } from '@app/core/Enum';
import { GlobalComponent } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { CommonState, MenuState } from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-more-grid-actions',
  templateUrl: './more-grid-actions.component.html',
  styleUrls: ['./more-grid-actions.component.scss'],
})
export class MoreGridActionsComponent implements OnInit {
  selectedRowIndex: number = -1;
  actionTypeEnum = ActionType;
  @Input()
  moduleId: number;

  @Input()
  moreActionCount: number;

  @Input()
  elements: any;

  @Input()
  elements1: any;

  @Input()
  colIndex: any;

  @Input()
  colName: any;

  @Input()
  row: any;

  @Output()
  readonly triggerActionType = new EventEmitter<any>();

  @Output()
  readonly triggerSelectedRowIndex = new EventEmitter<any>();

  actions: any = [];
  modules = Modules;
  capiumPayList: any;
  showMailOption: any;

  @Select(CommonState.actions)
  actions$: Observable<any>;
  store: Store;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  isReceiptChangePermission: boolean = true;
  isJournalChangePermission: boolean = true;
  isPaymentChangePermission: boolean = true;
  constructor(
    private injector: Injector,
    private globalComponent: GlobalComponent,
    private commonService: CommonService
  ) {
    this.store = injector.get<Store>(Store);
  }

  actionClick(actionType: ActionType) {
    this.triggerActionType.emit(actionType);
  }

  ngOnInit(): void {
    if (this.moduleId === Modules.Invoices) {
      this.showMailOption = this.globalComponent.checkCapiumPayIntegrated();
    }
    this.actions$.subscribe((actionlist) => {
      if (actionlist !== null) {
        actionlist.forEach((element) => {
          this.actions.push({
            name: element.name,
            actionType: element.actionType,
            icon: element.icon,
          });
        });
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isReceiptChangePermission = this.commonService.checkPermission(
        Modules.Sales,
        Modules.Receipt
      );
      this.isJournalChangePermission = this.commonService.checkPermission(
        Modules.Tasks,
        Modules.Journals
      );
      this.isPaymentChangePermission = this.commonService.checkPermission(
        Modules.Purchase,
        Modules.Payment
      );
    }, 1000);
  }

  onButtonClick(i): void {
    this.selectedRowIndex = i;
    this.triggerSelectedRowIndex.emit(this.selectedRowIndex);
  }

  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
    this.triggerSelectedRowIndex.emit(this.selectedRowIndex);
  }
}
