<div class="side-list-actions" *ngIf="numberOfRecords > 0">
  <div class="content-body">
    <div class="mb-2">
      <div class="mb-1 d-flex align-items-center justify-space-between">
        <p class="text-gray-200 fw-bold m-0">Selected items</p>
        <button
          mat-button
          class="text-gray-300"
          disableRipple
          (click)="cancelSelection()"
        >
          <mat-icon>highlight_off</mat-icon>
        </button>
      </div>
      <div>
        <p class="font-size-18 fw-bold mb-2">
          <span class="text-gray-200 font-size-20">Records:</span>
          {{ numberOfRecords }}
        </p>
        <a class="link" href="javascript:void(0)" (click)="cancelSelection()"
          >Cancel selections</a
        >
      </div>
    </div>
    <hr class="hr" />
    <div>
      <div>
        <div class="mt-1">
          <p class="font-size-18 fw-bold mb-2">Edit</p>
          <div class="d-flex align-items-center">
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="delete()"
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon>delete_outline</mat-icon>
              <p>Delete</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              *ngIf="
                getModuleId !== moduleEnum.CashEntry &&
                getModuleId !== moduleEnum.Receipt &&
                getModuleId !== moduleEnum.Payment &&
                getModuleId !== moduleEnum.BankEntry &&
                getModuleId !== moduleEnum.Clients &&
                getModuleId !== moduleEnum.Users
              "
              disableRipple
              (click)="copy()"
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon>content_copy</mat-icon>
              <p>Copy</p>
            </button>
          </div>
        </div>
        <div class="mt-2" *ngIf="getModuleId !== moduleEnum.CustomizeReport">
          <p class="font-size-18 fw-bold mb-2">Export</p>
          <div class="d-flex align-items-center">
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="export(exportFormat.Excel)"
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon>border_all</mat-icon>
              <p>Excel</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="export(exportFormat.Csv)"
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon fontSet="material-icons-outlined">description</mat-icon>
              <p>CSV</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="export(exportFormat.PDF)"
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon fontSet="material-icons-outlined"
                >picture_as_pdf</mat-icon
              >
              <p>PDF</p>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="getModuleId !== moduleEnum.CustomizeReport">
        <div class="mt-2">
          <p class="font-size-18 fw-bold mb-2">Options</p>
          <div class="d-flex align-items-center">
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="print()"
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon fontSet="material-icons-outlined">print</mat-icon>
              <p>Print</p>
            </button>
            <button
              *ngIf="
                getModuleId !== moduleEnum.Clients &&
                getModuleId !== moduleEnum.Users
              "
              [disabled]="disableInActiveAction || (isViewPermission$ | async)"
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="archiveAndRestore(true)"
            >
              <mat-icon>archive</mat-icon>
              <p>Inactive</p>
            </button>
            <button
              *ngIf="
                getModuleId !== moduleEnum.Clients &&
                getModuleId !== moduleEnum.Users
              "
              [disabled]="disableActiveAction"
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="archiveAndRestore(false)"
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon>unarchive</mat-icon>
              <p>Active</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="email()"
              *ngIf="
                getModuleId === moduleEnum.Receipt ||
                getModuleId === moduleEnum.Invoices ||
                getModuleId === moduleEnum.CreditNote ||
                getModuleId === moduleEnum.DebitNote ||
                getModuleId === moduleEnum.Quotation
              "
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon fontSet="material-icons-outlined">email</mat-icon>
              <p>Email</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
