<div class="mat-dialog-wrapper">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">{{ headerText }}</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onNoClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div>
      <form [formGroup]="form">
        <div class="form-field">
          <p>Due Amount<span class="text-danger-300">*</span></p>
          <mat-form-field class="dueAmt-inputbox amt-inputbox">
            <input
              currencyMask
              allow_10_2_Decimal
              zeroNotAllowed
              matInput
              autocomplete="off"
              formControlName="dueAmount"
            />
            <button mat-button type="button" disableRipple matSuffix>
              <span>{{ currencySymbol }}</span>
            </button>
          </mat-form-field>
        </div>
        <div class="form-field datepicker">
          <p *ngIf="this.data.isReceipt">
            Receipt Date<span class="text-danger-300">*</span>
          </p>
          <p *ngIf="!this.data.isReceipt">
            Payment Date<span class="text-danger-300">*</span>
          </p>
          <mat-form-field appearance="standard">
            <input
              matInput
              formControlName="receiptDate"
              [matDatepicker]="receiptDate"
              autocomplete="off"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="receiptDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #receiptDate></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-field">
          <p *ngIf="this.data.isReceipt">Receipt No.</p>
          <p *ngIf="!this.data.isReceipt">Payment No.</p>
          <mat-form-field>
            <input
              matInput
              formControlName="receiptNo"
              autocomplete="off"
              type="text"
            />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Account<span class="text-danger-300">*</span></p>
          <ng-select
            class="big-inputbox"
            #accountId
            [disableOptionCentering]="true"
            (focus)="accountId.open()"
            (valueChange)="accountId.close()"
            formControlName="accountId"
            panelClass="mat-select-position"
            required
            (change)="onAccountChange($event)"
          >
            <ng-option
              *ngFor="let option of paymentAccountList"
              [value]="option.id"
            >
              <div title="{{ option.name }}">{{ option.name }}</div>
            </ng-option>
          </ng-select>
        </div>
        <div class="form-field" *ngIf="isCurrencyShow">
          <p>Currency<span class="text-danger-300">*</span></p>
          <ng-select
            #currency
            (focus)="currency.open()"
            (valueChange)="currency.close()"
            [disableOptionCentering]="true"
            formControlName="currency"
            panelClass="mat-select-position"
          >
            <ng-option
              *ngFor="let cl of invoiceCurrencyList"
              [value]="cl.currencyId"
            >
              <div title="{{ cl.currency }} ">{{ cl.currency }}</div>
            </ng-option>
          </ng-select>
        </div>
        <div class="form-field" *ngIf="isForeignCurrency">
          <p>Rate <span class="text-danger-300">*</span></p>
          <mat-form-field class="w-140 recpt-inputbox">
            <input
              matInput
              formControlName="currencyRate"
              allowDecimal
              autocomplete="off"
              (change)="onCurrencyRateEntered()"
            />
            <button
              mat-button
              type="button"
              disableRipple
              matSuffix
              (click)="onCurrencyRateRefresh()"
            >
              <mat-icon>refresh</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div *ngIf="isPaymentFromCisInv">
          <span class="cis-amts">For CIS Purpose:</span>
        </div>
        <div *ngIf="isPaymentFromCisInv" class="form-field">
          <p>Labour Amount</p>
          <mat-form-field class="amt-inputbox">
            <input
              matInput
              formControlName="labourAmount"
              allowDecimal
              autocomplete="off"
            />
            <button mat-button type="button" disableRipple matSuffix>
              <mat-icon>£</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div *ngIf="isPaymentFromCisInv" class="form-field">
          <p>Material Amount</p>
          <mat-form-field class="amt-inputbox">
            <input
              matInput
              formControlName="materialAmount"
              allowDecimal
              autocomplete="off"
            />
            <button mat-button type="button" disableRipple matSuffix>
              <mat-icon>£</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div *ngIf="isPaymentFromCisInv">
          <span class="cis-amts">For Invoice Purpose:</span>
        </div>
        <div class="form-field">
          <p>Amount <span class="text-danger-300">*</span></p>
          <mat-form-field class="amt-inputbox">
            <input
              allow_10_2_Decimal
              zeroNotAllowed
              matInput
              autocomplete="off"
              formControlName="amount"
              (change)="validate(this.form.controls.amount)"
            />
            <button mat-button type="button" disableRipple matSuffix>
              <span>{{ currencySymbol }}</span>
            </button>
          </mat-form-field>
        </div>
        <div class="form-field" *ngIf="isForeignCurrency">
          <mat-form-field class="amt-inputbox">
            <input
              allow_10_2_Decimal
              zeroNotAllowed
              matInput
              autocomplete="off"
              formControlName="convertedAmount"
            />
            <button mat-button type="button" disableRipple matSuffix>
              <span>{{ convertedAmountSymbol }}</span>
            </button>
          </mat-form-field>
        </div>

        <div class="form-field wp-100 textarea">
          <p>Notes</p>
          <mat-form-field>
            <textarea
              matInput
              maxlength="{{ maxLength.maxLength2000 }}"
              formControlName="reference"
              autocomplete="off"
            ></textarea>
          </mat-form-field>
        </div>
      </form>
      <mat-accordion [multi]="true">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon fontSet="material-icons-outlined" class="text-primary"
                >attachment</mat-icon
              >
              <div class="d-flex flex-column">
                <span>Attachment</span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-transactions-attachment> </app-transactions-attachment>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onYesClick()"
    >
      Save
    </button>
    <button
      class="action-button secondary-button"
      mat-dialog-close
      type="button"
      mat-button
      disableRipple
      (click)="onNoClick()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
