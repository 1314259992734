<div class="content content-header bulk-edit-list">
  <div class="content-header-input">
    <div class="content-body">
      <div class="d-flex justify-space-between align-items-end wp-100 mb-15">
        <div
          class="content-header d-flex align-items-end justify-space-between header-filters header-count header-count-1"
        >
          <div class="d-flex align-items-end">
            <div class="content-right-button d-flex flex-column gap-5 mr-10">
              <span class="fw-bold"> Account </span>
              <div [ngClass]="['w-250']">
                <ng-select
                  #accounts
                  (focus)="accounts.open()"
                  (valueChange)="accounts.close()"
                  [disableOptionCentering]="true"
                  [(ngModel)]="accountId"
                  class="input-sm form-control"
                  panelClass="mat-select-position"
                  (change)="onAccoutHeaderChange()"
                  required
                >
                  <ng-option *ngFor="let fn of accountList" [value]="fn.id">
                    <div title="{{ fn.name }}">{{ fn.name }}</div>
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="content-right-button d-flex flex-column gap-5 mr-10">
              <mat-form-field appearance="fill" class="search-field">
                <input
                  matInput
                  placeholder="Search here..."
                  autocomplete="off"
                  [(ngModel)]="search"
                />

                <button mat-button disableRipple class="search-button">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="content-right-button d-flex flex-column gap-5 mr-10">
              <span class="fw-bold"> From : </span>
              <div>
                <mat-form-field appearance="standard" class="ml-0">
                  <input
                    [(ngModel)]="fromDate"
                    [matDatepicker]="fromPicker"
                    matInput
                    autocomplete="off"
                    tabindex="-1"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="fromPicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #fromPicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="content-right-button d-flex flex-column gap-5 mr-10">
              <span class="fw-bold"> To : </span>
              <div>
                <mat-form-field appearance="standard" class="ml-0">
                  <input
                    [(ngModel)]="toDate"
                    [matDatepicker]="toPicker"
                    matInput
                    autocomplete="off"
                    tabindex="-1"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="toPicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #toPicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="toolbar mr-10">
              <button matTooltip="Search" mat-button (click)="onSearch()">
                <mat-icon>search</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
        <mat-icon class="material-icons-outlined">error_outline</mat-icon>
        {{ commonNotificationText.ViewOnlyAccessMessage }}
      </div>
      <div
        class="mat-table-wrapper mt-1"
        [ngClass]="isRecordSelected ? '' : 'mat-table-wrapper-full'"
      >
        <table
          aria-describedby="buld-edit-List"
          mat-table
          [dataSource]="bulkEditList"
        >
          <ng-container matColumnDef="srNo">
            <th mat-header-cell *matHeaderCellDef class="w-20">
              <mat-checkbox
                [(ngModel)]="isAllSelected"
                (change)="selectAll($event)"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex">
                <mat-checkbox
                  [(ngModel)]="element.isSelected"
                  (change)="onCheckBoxChanges($event, element)"
                >
                </mat-checkbox>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex">
                {{ element.date | date: "dd-LLL-yyyy" }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef>Reference</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex">
                <mat-form-field class="wmin-100">
                  <input
                    matInput
                    [(ngModel)]="element.reference"
                    autocomplete="off"
                    maxlength="{{ maxLength.maxLength2000 }}"
                    [disabled]="isViewPermission$ | async"
                  />
                </mat-form-field>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="account">
            <th mat-header-cell *matHeaderCellDef class="w-200">Account</th>
            <td mat-cell *matCellDef="let element">
              <div
                class="d-flex"
                *ngIf="
                  element.invoiceTypeId === invoiceType.Income ||
                  element.invoiceTypeId === invoiceType.CreditNote
                "
              >
                <ng-select
                  class="wp-100"
                  panelClass="myPanelClass"
                  [disableOptionCentering]="true"
                  (change)="onAccoutChange(element)"
                  [(ngModel)]="element.accountValue"
                >
                  <ng-option
                    *ngFor="let account of invoiceAccountList"
                    [value]="account.id"
                    title="{{ account.name }}"
                  >
                    {{ account.name }}</ng-option
                  >
                </ng-select>
              </div>
              <div
                class="d-flex"
                *ngIf="element.invoiceTypeId === invoiceType.Expenditure"
              >
                <ng-select
                  class="wp-100"
                  panelClass="myPanelClass"
                  [disableOptionCentering]="true"
                  (change)="onAccoutChange(element)"
                  [(ngModel)]="element.accountValue"
                >
                  <ng-option
                    *ngFor="let account of billAccountList"
                    [value]="account.id"
                    title="{{ account.name }}"
                  >
                    {{ account.name }}</ng-option
                  >
                </ng-select>
              </div>
              <div
                class="d-flex"
                *ngIf="element.invoiceTypeId === invoiceType.DebitNote"
              >
                <ng-select
                  class="wp-100"
                  panelClass="myPanelClass"
                  [disableOptionCentering]="true"
                  (change)="onAccoutChange(element)"
                  [(ngModel)]="element.accountValue"
                  [disabled]="isViewPermission$ | async"
                >
                  <ng-option
                    *ngFor="let account of debitNoteAccountList"
                    [value]="account.id"
                    title="{{ account.name }}"
                  >
                    {{ account.name }}</ng-option
                  >
                </ng-select>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="contactName">
            <th mat-header-cell *matHeaderCellDef>Contact Name</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex">
                {{ element.contactName }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="referenceNo">
            <th mat-header-cell *matHeaderCellDef>Ref. No</th>
            <td mat-cell *matCellDef="let element">
              <a
                class="link text-primary wmx-100 clamp"
                href="javascript:void()"
                (click)="onInvoiceClick(element)"
              >
                {{ element.referenceNo }}</a
              >
            </td>
          </ng-container>
          <ng-container matColumnDef="receipt">
            <th mat-header-cell *matHeaderCellDef class="text-align-right">
              Reciept
            </th>
            <td mat-cell *matCellDef="let element">
              <div
                class="d-flex justify-content-end"
                *ngIf="element.receipt > 0"
              >
                £ {{ element.receipt }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="payment">
            <th mat-header-cell *matHeaderCellDef class="text-align-right">
              Payment
            </th>
            <td mat-cell *matCellDef="let element">
              <div
                class="d-flex justify-content-end"
                *ngIf="element.payment > 0"
              >
                £ {{ element.payment }}
              </div>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayBulkEditColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayBulkEditColumns"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td
              class="mat-cell"
              [ngClass]="'text-align-center'"
              [attr.colspan]="displayBulkEditColumns.length"
            >
              <span *ngIf="listParameters">No records found</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <app-buttons
    *ngIf="isRecordSelected && !(isViewPermission$ | async)"
    [getModuleId]="moduleId"
    (triggerOnSaveClick)="onSave($event)"
  ></app-buttons>
</div>
