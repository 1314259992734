import { Guid } from 'guid-typescript';

export class UserModel {
  id: Guid;
  fullName: string;
  email: string;
  roleId: number;
  roleName?: string;
  photoUrl?: string;
  identityUserId?: Guid;
  branchId?: Guid;
  departmentId?: Guid;
  isInviteUser?: boolean;
}

export class Role {
  id: number;
  name: string;
}
