<div class="content-body pt-0">
  <div class="form-field-title">
    <p>CIS Invoice Details</p>
  </div>
  <div class="sidenav-table sidenav-table-input wp-100">
    <div class="mat-table-wrapper product-detail-table">
      <form [formGroup]="formCISInvoiceDetail">
        <ng-container formArrayName="cisInvoiceDetailArray">
          <table
            aria-describedby="product-list"
            mat-table
            [dataSource]="tableDataSource"
          >
            <ng-container matColumnDef="srNo">
              <th mat-header-cell *matHeaderCellDef class="w-20">Sr No.</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <div class="d-flex">
                  {{ i + 1 }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="widthemail"
              >
                <mat-form-field floatLabel="never">
                  <input
                    matInput
                    [value]="element.description"
                    autocomplete="off"
                    formControlName="description"
                    maxlength="{{ maxLength.maxLength200 }}"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="item">
              <th mat-header-cell *matHeaderCellDef>Item</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <ng-select
                  [disableOptionCentering]="true"
                  formControlName="item"
                  dropdownPosition="top"
                  panelClass="mat-select-position"
                  [appendTo]="'.sidenav-table'"
                  (change)="qtyChanges(i)"
                >
                  <ng-option
                    *ngFor="let option of itemList"
                    [value]="option.id"
                    title="{{ option.name }}"
                  >
                    {{ option.name }}
                  </ng-option>
                </ng-select>
              </td>
            </ng-container>
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>
                Price <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field class="w-60">
                  <input
                    allowDecimal
                    zeroNotAllowed
                    matInput
                    [value]="element.price"
                    autocomplete="off"
                    formControlName="price"
                    (change)="qtyChanges(i)"
                    maxlength="{{ maxLength.maxLength10 }}"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="netAmount">
              <th mat-header-cell *matHeaderCellDef>Net Amount</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never" class="w-100">
                  <input
                    currencyMask
                    allow_14_2_Decimal
                    matInput
                    [value]="element.netAmount"
                    autocomplete="off"
                    formControlName="netAmount"
                    maxlength="10"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="invoiceType" >
              <th mat-header-cell *matHeaderCellDef>
                Account Name <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
              <mat-form-field appearance="fill" class="w-160">
                <input
                  type="text"
                  matInput
                  formControlName="invoiceType"
                  required
                  [matAutocomplete]="accountGroup"
                  (keyup)="onAccountSearch($event,i)"
                  (keydown.Tab)="onSearch($event,i)"
                  (change)="onSearch($event,i)"
                  #trigger="matAutocompleteTrigger"
                  appAutocompletePosition="trigger"
                  [appendTo]="'.sidenav-table'"
                  (mousedown)="resetAccountListByAccount(i)"
                />
                <mat-icon class="downarrow" matSuffix>keyboard_arrow_down</mat-icon>
                <mat-autocomplete
                  [displayWith]="getOptionText"
                  #accountGroup="matAutocomplete"
                  (opened)="scrollIntoViewByAccount(i)"
                  (optionSelected)="onOptionSelected(i)"
                >
                  <mat-optgroup
                    *ngFor="let group of accountGroupList"
                    [label]="group.groupName"
                    title="{{ group.groupName }}"
                  >
                    <mat-option
                      *ngFor="let names of group.listModels"
                      [value]="names"
                      title="{{ names.name }}"
                      [class]="
                      
                      cisInvoiceDetailArray.controls[i]?.get('invoiceType').value?.id === names.id
                          ? 'mat-selected'
                          : ''
                      "
                    >
                      {{ names.name }}
                    </mat-option>
                  </mat-optgroup>
                </mat-autocomplete>
              </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="vatrate" *ngIf="isVatRegistered">
              <th mat-header-cell *matHeaderCellDef>
                VAT Rate <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <ng-select
                  class="w-160"
                  [disableOptionCentering]="true"
                  formControlName="vatRate"
                  dropdownPosition="top"
                  panelClass="mat-select-position"
                  [appendTo]="'.sidenav-table'"
                  (change)="qtyChanges(i)"
                  required
                >
                  <ng-option
                    *ngFor="let option of vatRateList[i]"
                    [value]="option.id"
                    title="{{ option.name }}"
                  >
                    {{ option.name }}
                  </ng-option>
                </ng-select>
              </td>
            </ng-container>
            <ng-container matColumnDef="vatammount" *ngIf="isVatRegistered">
              <th mat-header-cell *matHeaderCellDef>VAT Amount</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never" class="w-120">
                  <input
                    allow_14_2_Decimal
                    matInput
                    [value]="element.vatAmount"
                    autocomplete="off"
                    formControlName="vatAmount"
                    maxlength="{{ maxLength.maxLength10 }}"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>
                Gross Amount <span class="text-danger-300">*</span>
              </th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field floatLabel="never" class="w-120">
                  <input
                    currencyMask
                    allow_14_2_Decimal
                    matInput
                    [value]="element.amount"
                    autocomplete="off"
                    formControlName="amount"
                    maxlength="{{ maxLength.maxLength10 }}"
                  />
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="closeButton">
              <th mat-header-cell *matHeaderCellDef class="w-15"></th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="text-align-center"
              >
                <button
                  class="close-btn"
                  type="button"
                  mat-button
                  disableRipple
                  (click)="onDeleteProductDetails(i)"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayCISInvoiceDetailsColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayCISInvoiceDetailsColumns"
            ></tr>
          </table>
        </ng-container>
      </form>
    </div>
    <div class="mb-1 mt-1 d-flex justify-space-between">
      <div>
        <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
          >Add New Line</a
        >
        <a
          class="link mr-1"
          *ngIf="cisInvoiceDetailArray.length > 0"
          href="javascript:void(0)"
          (click)="clearForm()"
          >Clear All Lines</a
        >
      </div>
    </div>
  </div>
</div>
