<div class="nav-menu">
  <mat-tree
    [dataSource]="tempMenuList"
    [treeControl]="treeControl"
    class="example-tree"
  >
    <mat-tree-node
      (click)="activeNode = node"
      (click)="onLeafNodeClick(node)"
      [ngClass]="{ active: activeMenuLink === node.url }"
      *matTreeNodeDef="let node"
      matTreeNodeToggle
    >
      <mat-icon
        (click)="onAddClick(node)"
        fontSet="material-icons-outlined"
        [ngClass]="node.id === modules.ManageExport ? 'export-icon' : ''"
        *ngIf="node.hasAddPermission"
        >{{ node.icon }}</mat-icon
      >
      <a
        [ngClass]="{ 'no-icon': !node.hasAddPermission }"
        (click)="onChildClick(node)"
        class="d-flex align-items-center"
        title="{{ node.name }}"
      >
        {{ node.name }}
      </a>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div
        class="mat-tree-node justify-space-between align-items-center nav-item"
        [ngClass]="{
          active: node.name === parentName || node.name === subparentName
        }"
        matTreeNodeToggle
      >
        <div class="d-flex align-items-center">
          <mat-icon
            fontSet="material-icons-outlined"
            *ngIf="node.hasAddPermission"
            >{{ node.icon }}</mat-icon
          >
          <a
            class="d-flex align-items-center"
            title="{{ node.name }}"
            [ngClass]="!node.hasAddPermission ? 'no-icon' : ''"
          >
            {{ node.name }}
          </a>
        </div>
        <button
          mat-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
          disableRipple
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
          </mat-icon>
        </button>
      </div>

      <div
        [class.example-tree-invisible]="!treeControl.isExpanded(node)"
        role="group"
        class="sub-nav-item"
      >
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
</div>
