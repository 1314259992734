<div class="content-body content-body-scroll">
  <div class="main-list content">
    <div class="d-flex align-items-end wp-100 mb-1 gap-10">
      <div class="grid-search">
        <mat-form-field appearance="fill" class="search-field">
          <input matInput placeholder="Search here..." autocomplete="off" />
          <button mat-button disableRipple class="search-button">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="header-actions wp-100">
        <div
          class="align-items-end content-header d-flex header-count header-filters justify-space-between header-count-1"
        >
          <div class="d-flex align-items-center gap-10">
            <ng-select
              #client
              (focus)="client.open()"
              (valueChange)="client.close()"
              [disableOptionCentering]="true"
              dropdownPosition="bottom"
            >
              <ng-option>All Clients</ng-option>
              <ng-option>Client 1</ng-option>
              <ng-option>Client 11102023</ng-option>
              <ng-option>Financial Detective</ng-option>
              <ng-option>OISIS</ng-option>
              <ng-option>ST Client</ng-option>
            </ng-select>
            <ng-select
              #status
              (focus)="status.open()"
              (valueChange)="status.close()"
              [disableOptionCentering]="true"
              dropdownPosition="bottom"
            >
              <ng-option>All</ng-option>
              <ng-option>Pending</ng-option>
              <ng-option>Recorded</ng-option>
              <ng-option>Rejected</ng-option>
            </ng-select>
            <div class="content-header">
              <div class="year-picker">
                <div class="d-flex year-picker-content">
                  <form>
                    <mat-form-field appearance="standard">
                      <mat-date-range-input [rangePicker]="picker">
                        <input
                          matStartDate
                          matInput
                          #start
                          formControlName="start"
                        />
                        <input matEndDate matInput #end formControlName="end" />
                      </mat-date-range-input>
                      <mat-datepicker-toggle
                        matPrefix
                        [for]="picker"
                      ></mat-datepicker-toggle>
                      <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mat-table-wrapper">
    <table
      mat-table
      [dataSource]="dataSource"
      aria-describedby="Main Grid Table"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef>Company Name</th>
        <td mat-cell *matCellDef="let element">{{ element.company }}</td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element">{{ element.type }}</td>
      </ng-container>
      <ng-container matColumnDef="receipt">
        <th mat-header-cell *matHeaderCellDef>Scanned Receipt</th>
        <td mat-cell *matCellDef="let element">{{ element.receipt }}</td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">{{ element.date }}</td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>
      <ng-container matColumnDef="actionbtn">
        <th mat-header-cell *matHeaderCellDef class="w-50"></th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex align-item-center justify-content-end gap-10">
            <button type="button" mat-button disableRipple title="delete">
              <mat-icon class="font-size-20">delete_outline</mat-icon>
            </button>
            <button type="button" mat-button disableRipple title="edit">
              <mat-icon class="font-size-20" fontSet="material-icons-outlined"
                >edit</mat-icon
              >
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
