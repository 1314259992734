import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AccountEntity,
  Modules,
  NotificationTextMessage,
  TransactionType,
} from '@app/core/Enum';
import { Currency, GlobalComponent, ReceiptModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateReceipt,
  CurrencyState,
  GetAccountBranchId,
  GetAllDepartmentListByBranch,
  GetBankAccountTransactionData,
  GetClientCurrencyList,
  GetNonStandardAccountList,
  GetNonStandardAccountListByBranch,
  GetRefreshCurrencyData,
  GetRefundData,
  GetTransactionLogData,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-refund-popup',
  templateUrl: './refund-popup.component.html',
  styleUrls: ['./refund-popup.component.scss'],
})
export class RefundPopupComponent implements OnInit {
  displayAllocationRefundColumns: string[] = [
    'invoiceNo',
    'amount',
    'due',
    'amountToAllocate',
  ];

  defaultCurrency: Guid;
  currencyList: Currency[];
  clientCurrencyList: any = [];
  accountList: any = [];
  date: any;
  selectedCurrencyId: any;
  amount: any;
  currencySymbol: any;
  accountId: any;
  postingAccountId: any;
  isMultiCurrencySelected = false;
  currencyIds: any = [];
  refundData: any = [];
  refreshedCurrencyData: any = [];
  refreshedCurrencyRate: number;
  selectedCurrencyRate: number;
  currencyAmt: number;
  selectedCurrencySymbol: string;
  account: any;
  noDataFound: boolean = true;
  note = '';
  branchId: any;
  departmentId: any;
  departmentList: any[] = [];
  receiptData: ReceiptModel;

  receiptItems = new Array<any>();

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalComponent: GlobalComponent,
    public dialogRef: MatDialogRef<RefundPopupComponent>,
    public commonService: CommonService,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    if (atob(this.data.isBankFeed)) {
      this.getTransactionLogData().then(() => {
        this.getCustomer();
      });
    } else {
      this.getBankAccountTransactionData().then(() => {
        this.getCustomer();
      });
    }
    this.getCurrencyList();
  }

  getCurrencyList(): void {
    this.store
      .dispatch(new GetClientCurrencyList())
      .pipe(
        tap(() => {
          this.clientCurrencyList = this.store.selectSnapshot(
            CurrencyState.getCurrencyList
          );
          if (this.clientCurrencyList.length > 0) {
            this.selectedCurrencyId = this.clientCurrencyList[0].currencyId;
            this.currencyAmt = 1;
          }
        })
      )
      .subscribe();
  }

  async getTransactionLogData(): Promise<void> {
    await this.store
      .dispatch(
        new GetTransactionLogData(
          atob(this.data.transactionId) as unknown as Guid
        )
      )
      .toPromise()
      .then(async (res) => {
        this.date = res.common.transactionLogData.entryDate;
        this.currencySymbol = res.common.transactionLogData.symbol;
        this.amount = res.common.transactionLogData.amount;
        this.note = res.common.transactionLogData.note;
        this.postingAccountId = res.common.transactionLogData.accountId;

        await this.getAccountBranchId(this.postingAccountId);
      });
  }

  async getBankAccountTransactionData(): Promise<void> {
    await this.store
      .dispatch(
        new GetBankAccountTransactionData(
          atob(this.data.transactionId) as unknown as Guid
        )
      )
      .toPromise()
      .then(async (res) => {
        this.date = res.common.transactionLogData.transactionDate;
        this.amount = res.common.transactionLogData.amount;
        this.note = res.common.transactionLogData.description;
        await this.getAccountBranchId(this.postingAccountId);
      });
  }

  getCustomer(id?: Guid): void {
    const entityId = new Array<number>();
    if (this.data.isReceipt) {
      entityId.push(AccountEntity.Customer);
    } else {
      entityId.push(AccountEntity.Supplier);
    }
    let actionName;
    if (
      this.branchId !== undefined &&
      this.branchId !== null &&
      this.branchId !== ''
    ) {
      actionName = GetNonStandardAccountListByBranch;
    } else {
      actionName = GetNonStandardAccountList;
    }

    this.store
      .dispatch(new actionName(entityId, this.branchId))

      .pipe(
        tap((res) => {
          this.accountList = res.common.accountList;
        })
      )
      .subscribe();
  }

  onCurrencyRefresh(): void {
    this.currencyIds = [];
    this.currencyIds.push(this.selectedCurrencyId);

    this.store
      .dispatch(new GetRefreshCurrencyData(this.currencyIds))
      .subscribe((res) => {
        this.currencyIds = [];
        this.refreshedCurrencyData = res.currency.currencyList.data;

        this.refreshedCurrencyData.forEach((x) => {
          this.refreshedCurrencyRate = x.rate;
        });
        this.currencyAmt = this.refreshedCurrencyRate;
        this.selectedCurrencyRate = this.refreshedCurrencyRate;
      });
  }

  currencyChange(value: any): void {
    this.selectedCurrencyRate = this.clientCurrencyList.find(
      (x) => x.currencyId === value
    )?.rate;
    this.selectedCurrencyId = this.clientCurrencyList.find(
      (x) => x.currencyId === value
    )?.currencyId;

    if (value === 123) {
      this.isMultiCurrencySelected = false;
      this.currencyAmt = 1;
    } else {
      this.currencyAmt = this.selectedCurrencyRate;
      this.isMultiCurrencySelected = true;
    }

    this.getRefundData();
  }

  customerChange(): void {
    this.getRefundData();
  }

  departmentChange(): void {
    this.getRefundData();
  }

  onCurrencyRateEntered() {
    this.selectedCurrencyRate = this.currencyAmt;
  }

  getRefundData(): void {
    if (
      this.selectedCurrencyId === undefined ||
      this.selectedCurrencyId === null ||
      this.selectedCurrencyId <= 0
    ) {
      this.commonService.onFailure('Please select currency');
    } else if (
      this.accountId === undefined ||
      this.accountId === null ||
      this.accountId === this.commonService.defaultGuidValue
    ) {
      this.commonService.onFailure(
        !this.data.isReceipt
          ? 'Please select supplier'
          : 'Please select customer'
      );
    } else if (
      this.departmentList.length > 0 &&
      (this.departmentId === undefined ||
        this.departmentId === null ||
        this.departmentId === this.commonService.defaultGuidValue)
    ) {
      this.commonService.onFailure('Please select department');
    } else {
      this.spinner.show();
      this.store
        .dispatch(
          new GetRefundData(
            {
              accountId: this.accountId,
              currencyId: this.selectedCurrencyId,
              branchId: this.branchId,
              departmentId: this.departmentId,
            },
            !this.data.isReceipt ? Modules.DebitNote : Modules.CreditNote
          )
        )
        .subscribe((res) => {
          this.refundData = [];
          this.refundData = res.common.refundData;
          if (this.refundData.length > 0) {
            this.noDataFound = false;
          } else {
            this.noDataFound = true;
          }
          this.spinner.hide();
        });
    }
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onAllocationAmountChange(element: any): void {
    if (+element.allocatedAmount > +element.dueAmount) {
      element.allocatedAmount = 0;
    } else {
      if (
        this.refundData
          .filter(
            (x) => x.allocatedAmount !== null && x.allocatedAmount !== undefined
          )
          .reduce((x, y) => +x + +y.allocatedAmount, 0) > this.amount
      ) {
        element.allocatedAmount = 0;
      }
    }
  }

  onSaveClick(): void {
    if (
      this.refundData
        .filter(
          (x) => x.allocatedAmount !== null && x.allocatedAmount !== undefined
        )
        .reduce((x, y) => +x + +y.allocatedAmount, 0) === 0
    ) {
      this.commonService.onFailure(
        `You have to allocate total amount to ${
          !this.data.isReceipt ? 'Debit Note.' : 'Credit Note.'
        }`
      );
    } else if (
      this.refundData
        .filter(
          (x) => x.allocatedAmount !== null && x.allocatedAmount !== undefined
        )
        .reduce((x, y) => +x + +y.allocatedAmount, 0) !== +this.amount
    ) {
      this.commonService.onFailure(
        `You have to allocate total amount to ${
          !this.data.isReceipt ? 'Debit Note.' : 'Credit Note.'
        }`
      );
    } else if (this.dataSubmit()) {
      this.store
        .dispatch(new CreateReceipt(this.receiptData))
        .pipe()
        .subscribe(
          (res) => {
            if (res !== undefined) {
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
              this.onNoClick();
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      const filedata: any[] = [];
      this.receiptItems = new Array<any>();
      this.refundData
        ?.filter(
          (x) => x.allocatedAmount !== null && x.allocatedAmount !== undefined
        )
        .forEach((x) => {
          this.receiptItems.push({
            invoiceId: x.id,
            amount:
              this.selectedCurrencyId !== 123
                ? +x.allocatedAmount / this.selectedCurrencyRate
                : +x.allocatedAmount,
            invoiceTypeId: !this.data.isReceipt ? 4 : 3,
            note: x.note,
            currencyRate: this.selectedCurrencyRate,
            foreignAmount: +x.allocatedAmount,
          });
        });

      this.receiptData = {
        isCredit: !this.data.isReceipt ? false : true ?? false,
        receiptDate: this.datepipe
          .transform(this.date, 'yyyy-MM-dd')
          ?.toString(),

        note: this.note,
        accountId: this.accountId,
        postingAccountId: this.postingAccountId,
        totalAmount: this.amount,
        currencyId: this.selectedCurrencyId,
        attachment: filedata,
        isAdvance: false,
        transactionTypeId: TransactionType.Bank,
        receiptItems: this.receiptItems,
        transactionLogId: atob(this.data.transactionId) as unknown as Guid,
        branchId:
          this.branchId === undefined ||
          this.branchId === null ||
          this.branchId === ''
            ? null
            : this.branchId,
        departmentId:
          this.departmentId === undefined ||
          this.departmentId === null ||
          this.departmentId === ''
            ? null
            : this.departmentId,
        currencyRate:
          this.selectedCurrencyId === 123 ? 1 : this.selectedCurrencyRate,
        foreignTotalAmount: this.amount,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  receiptPaymentChange(): void {
    this.commonService.onEditRouting(
      false,
      !this.data.isReceipt ? Modules.DebitNote : Modules.CreditNote,
      atob(this.data.transactionId) as unknown as Guid,
      true,
      undefined,
      !this.data.isBankFeed,
      this.postingAccountId
    );
  }

  async getAccountBranchId(bankId: any): Promise<void> {
    try {
      const res = await this.store
        .dispatch(new GetAccountBranchId(bankId))
        .toPromise();
      this.branchId = res.common.branchId;

      if (
        this.branchId !== undefined &&
        this.branchId !== null &&
        this.branchId !== this.commonService.defaultGuidValue
      ) {
        await this.getDepartmentListByBranch(this.branchId);
      }
    } catch (error) {
      console.error('Error occurred:', error);
      // Handle errors as needed
    }
  }

  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .pipe(
          tap((res) => {
            this.departmentList = [];
            if (res.company.department.length > 0) {
              this.departmentList = res.company.department;
              this.departmentId = this.departmentList[0].id;
            } else {
              this.departmentList = [];
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
    }
  }
}
