import { Injectable } from '@angular/core';
import { CISReportService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  CisSubmissionReport,
  GetInvoiceReport,
  SendEmailInvoiceReport,
  SubContractorYTDDetailsReport,
} from './CISReport.action';

export class CISReportStateInfo {
  totalRecord?: number;
  cisInvoice?: any;
  isLoading?: boolean;
  isExport: boolean;
  cisSubmissionReport?: any;
}

@State<CISReportStateInfo>({
  name: 'CISReport',
  defaults: {
    isExport: false,
  },
})
@Injectable()
export class CISReportState {
  constructor(private cISReportService: CISReportService) {}

  @Selector()
  static totalRecord(state: CISReportStateInfo): number {
    return state.totalRecord ?? 0;
  }

  @Action(SubContractorYTDDetailsReport, { cancelUncompleted: true })
  subContractorYTDDetailsReport(
    { patchState }: StateContext<CISReportStateInfo>,
    action: SubContractorYTDDetailsReport
  ) {
    return this.cISReportService
      .subContractorYTDDetailsReport(action.queryParams)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              cisInvoice: result,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetInvoiceReport, { cancelUncompleted: true })
  getInvoiceReport(
    { patchState }: StateContext<CISReportStateInfo>,
    action: GetInvoiceReport
  ) {
    let isExport = false;
    return this.cISReportService
      .getInvoiceReport(action.invoiceReportParameters)
      .pipe(
        tap((res) => {
          isExport = true;
          patchState({
            isExport,
          });
        })
      );
  }

  @Action(SendEmailInvoiceReport)
  sendEmailInvoiceReport(
    { getState, patchState }: StateContext<CISReportStateInfo>,
    action: SendEmailInvoiceReport
  ) {
    return this.cISReportService.sendEmailInvoiceReport(
      action.attachmentParams,
      action.toMailId,
      action.ccMailId,
      action.bccMailId,
      action.message,
      action.subject
    );
  }

  @Action(CisSubmissionReport, { cancelUncompleted: true })
  cisSubmissionReport(
    { patchState }: StateContext<CISReportStateInfo>,
    action: CisSubmissionReport
  ) {
    return this.cISReportService.cisSubmissionReport().pipe(
      tap({
        next: (result) => {
          patchState({
            cisSubmissionReport: result,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }
}
