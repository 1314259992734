<div class="content-body content-body-scroll">
  <div class="main-list content">
    <div class="d-flex align-items-end wp-100 mb-1 gap-10">
      <button
        class="action-button primary-button border-radius-small w-150"
        mat-button
        disableRipple
        (click)="openDialog()"
      >
        <mat-icon>add</mat-icon>
        Account
      </button>
      <div class="grid-search">
        <mat-form-field appearance="fill" class="search-field">
          <input matInput placeholder="Search here..." autocomplete="off" />
          <button mat-button disableRipple class="search-button">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="header-actions wp-100">
        <div
          class="align-items-end content-header d-flex header-count header-filters justify-space-between header-count-1"
        >
          <div class="d-flex align-items-center gap-10">
            <ng-select
              #status
              (focus)="status.open()"
              (valueChange)="status.close()"
              [disableOptionCentering]="true"
              dropdownPosition="bottom"
            >
              <ng-option>Normal</ng-option>
              <ng-option>Archive</ng-option>
            </ng-select>
            <ng-select
              #account
              (focus)="account.open()"
              (valueChange)="account.close()"
              [disableOptionCentering]="true"
              dropdownPosition="bottom"
            >
              <ng-option>All Account Types</ng-option>
              <ng-option>Turnover</ng-option>
              <ng-option>Cost of Sales</ng-option>
            </ng-select>
          </div>

          <div class="toolbar">
            <button mat-button [matMenuTriggerFor]="menu" disableRipple>
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu
              #menu="matMenu"
              class="action-menu more-menu"
              xPosition="before"
            >
              <button mat-menu-item disableRipple>
                <mat-icon fontSet="material-icons-outlined"
                  >picture_as_pdf</mat-icon
                >Export As PDF
              </button>
              <button mat-menu-item disableRipple>
                <mat-icon>border_all</mat-icon>Export As Excel
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="accordion-toggle">
      <mat-icon
        (click)="toggleAccordion(); accordion.openAll()"
        *ngIf="isExpandAll"
        >keyboard_double_arrow_down
      </mat-icon>
      <mat-icon
        (click)="toggleAccordion(); accordion.closeAll()"
        *ngIf="!isExpandAll"
        >keyboard_double_arrow_up
      </mat-icon>
    </div>
    <mat-accordion [multi]="true">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-icons-outlined" class="text-primary"
              >description</mat-icon
            >
            <div class="d-flex flex-column">
              <span>Limited</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-body">
          <div class="sidenav-table sidenav-table-input wp-100">
            <div class="mat-table-wrapper">
              <table
                mat-table
                [dataSource]="dataSource"
                aria-describedby="Main Grid Table"
              >
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Account Name</th>
                  <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>
                <ng-container matColumnDef="category">
                  <th mat-header-cell *matHeaderCellDef>Category</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.category }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="code">
                  <th mat-header-cell *matHeaderCellDef>Code</th>
                  <td mat-cell *matCellDef="let element">{{ element.code }}</td>
                </ng-container>
                <ng-container matColumnDef="archive">
                  <th mat-header-cell *matHeaderCellDef>Archive</th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox></mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="actionbtn">
                  <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                  <td mat-cell *matCellDef="let element">
                    <div
                      class="d-flex align-item-center justify-content-end gap-10"
                    >
                      <button
                        type="button"
                        mat-button
                        disableRipple
                        title="delete"
                      >
                        <mat-icon class="font-size-20">delete_outline</mat-icon>
                      </button>
                      <button
                        type="button"
                        mat-button
                        disableRipple
                        title="edit"
                      >
                        <mat-icon
                          class="font-size-20"
                          fontSet="material-icons-outlined"
                          >edit</mat-icon
                        >
                      </button>
                    </div>
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-icons-outlined" class="text-primary"
              >trending_up</mat-icon
            >
            <div class="d-flex flex-column">
              <span>Sole Trader</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-body">
          <div class="sidenav-table sidenav-table-input wp-100">
            <div class="mat-table-wrapper">
              <table
                mat-table
                [dataSource]="dataSource"
                aria-describedby="Main Grid Table"
              >
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Account Name</th>
                  <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>
                <ng-container matColumnDef="category">
                  <th mat-header-cell *matHeaderCellDef>Category</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.category }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="code">
                  <th mat-header-cell *matHeaderCellDef>Code</th>
                  <td mat-cell *matCellDef="let element">{{ element.code }}</td>
                </ng-container>
                <ng-container matColumnDef="archive">
                  <th mat-header-cell *matHeaderCellDef>Archive</th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox></mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="actionbtn">
                  <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                  <td mat-cell *matCellDef="let element">
                    <div
                      class="d-flex align-item-center justify-content-end gap-10"
                    >
                      <button
                        type="button"
                        mat-button
                        disableRipple
                        title="delete"
                      >
                        <mat-icon class="font-size-20">delete_outline</mat-icon>
                      </button>
                      <button
                        type="button"
                        mat-button
                        disableRipple
                        title="edit"
                      >
                        <mat-icon
                          class="font-size-20"
                          fontSet="material-icons-outlined"
                          >edit</mat-icon
                        >
                      </button>
                    </div>
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-icons-outlined" class="text-primary"
              >handshake</mat-icon
            >
            <div class="d-flex flex-column">
              <span>Partnership</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-body">
          <div class="sidenav-table sidenav-table-input wp-100">
            <div class="mat-table-wrapper">
              <table
                mat-table
                [dataSource]="dataSource"
                aria-describedby="Main Grid Table"
              >
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Account Name</th>
                  <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>
                <ng-container matColumnDef="category">
                  <th mat-header-cell *matHeaderCellDef>Category</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.category }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="code">
                  <th mat-header-cell *matHeaderCellDef>Code</th>
                  <td mat-cell *matCellDef="let element">{{ element.code }}</td>
                </ng-container>
                <ng-container matColumnDef="archive">
                  <th mat-header-cell *matHeaderCellDef>Archive</th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox></mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="actionbtn">
                  <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                  <td mat-cell *matCellDef="let element">
                    <div
                      class="d-flex align-item-center justify-content-end gap-10"
                    >
                      <button
                        type="button"
                        mat-button
                        disableRipple
                        title="delete"
                      >
                        <mat-icon class="font-size-20">delete_outline</mat-icon>
                      </button>
                      <button
                        type="button"
                        mat-button
                        disableRipple
                        title="edit"
                      >
                        <mat-icon
                          class="font-size-20"
                          fontSet="material-icons-outlined"
                          >edit</mat-icon
                        >
                      </button>
                    </div>
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-icons-outlined" class="text-primary"
              >people_alt</mat-icon
            >
            <div class="d-flex flex-column">
              <span>LLP</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-body">
          <div class="sidenav-table sidenav-table-input wp-100">
            <div class="mat-table-wrapper">
              <table
                mat-table
                [dataSource]="dataSource"
                aria-describedby="Main Grid Table"
              >
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Account Name</th>
                  <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                </ng-container>
                <ng-container matColumnDef="category">
                  <th mat-header-cell *matHeaderCellDef>Category</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.category }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="code">
                  <th mat-header-cell *matHeaderCellDef>Code</th>
                  <td mat-cell *matCellDef="let element">{{ element.code }}</td>
                </ng-container>
                <ng-container matColumnDef="archive">
                  <th mat-header-cell *matHeaderCellDef>Archive</th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox></mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="actionbtn">
                  <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                  <td mat-cell *matCellDef="let element">
                    <div
                      class="d-flex align-item-center justify-content-end gap-10"
                    >
                      <button
                        type="button"
                        mat-button
                        disableRipple
                        title="delete"
                      >
                        <mat-icon class="font-size-20">delete_outline</mat-icon>
                      </button>
                      <button
                        type="button"
                        mat-button
                        disableRipple
                        title="edit"
                      >
                        <mat-icon
                          class="font-size-20"
                          fontSet="material-icons-outlined"
                          >edit</mat-icon
                        >
                      </button>
                    </div>
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
