import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Modules, NotificationAddClosePopUpMessage } from '@app/core/Enum';
import { CommonService } from '@app/core/Services';

@Component({
  selector: 'app-add-close-popup',
  templateUrl: './add-close-popup.component.html',
  styleUrls: ['./add-close-popup.component.scss'],
})
export class AddClosePopupComponent {
  headerText = NotificationAddClosePopUpMessage.defaultHeaderText;
  bodyText = NotificationAddClosePopUpMessage.defaultBodyText;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddClosePopupComponent>,
    public commonService: CommonService
  ) {
    if (
      this.data !== null &&
      this.data !== undefined &&
      this.data.isWarning !== null &&
      this.data.isWarning !== undefined &&
      this.data.isWarning === true
    ) {
      this.headerText =
        NotificationAddClosePopUpMessage.saveOpeningBalanceHeaderText;
      this.bodyText = NotificationAddClosePopUpMessage.saveWarningBodyText;
    } else if (
      this.data !== null &&
      this.data !== undefined &&
      (this.data.moduleId === Modules.AccountDetails ||
        this.data.moduleId === Modules.OpeningBalance ||
        this.data.moduleId === Modules.EditTrialBalance ||
        this.data.moduleId === Modules.Journals)
    ) {
      this.headerText =
        this.data.moduleId === Modules.OpeningBalance ||
        this.data.moduleId === Modules.AccountDetails ||
        this.data.moduleId === Modules.EditTrialBalance
          ? this.data.isEdit === false
            ? NotificationAddClosePopUpMessage.informHeaderText
            : NotificationAddClosePopUpMessage.saveOpeningBalanceHeaderText
          : NotificationAddClosePopUpMessage.saveOpeningBalanceHeaderText;
      this.bodyText =
        this.data.moduleId === Modules.OpeningBalance ||
        this.data.moduleId === Modules.AccountDetails ||
        this.data.moduleId === Modules.EditTrialBalance
          ? this.data.isEdit === false
            ? NotificationAddClosePopUpMessage.informBodyText
            : NotificationAddClosePopUpMessage.saveOpeningBalanceBodyText
          : NotificationAddClosePopUpMessage.saveJournalBodyText;
    } else if (
      this.data !== null &&
      this.data !== undefined &&
      this.data.moduleId === Modules.Import
    ) {
      this.headerText =
        NotificationAddClosePopUpMessage.saveOpeningBalanceHeaderText;
      this.bodyText = NotificationAddClosePopUpMessage.importBodyText;
    }
  }

  onYesClick(): void {
    if (
      this.data !== null &&
      this.data !== undefined &&
      (this.data.moduleId === Modules.AccountDetails ||
        this.data.moduleId === Modules.OpeningBalance ||
        this.data.moduleId === Modules.EditTrialBalance ||
        this.data.moduleId === Modules.Journals ||
        this.data.moduleId === Modules.Import ||
        this.data.isWarning === true)
    ) {
      this.dialogRef.close(true);
    } else {
      history.back();
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
