<div class="financial-year">
  <div class="sidenav-table sidenav-table-input">
    <div class="mat-table-wrapper">
      <form [formGroup]="formBranchDetail">
        <ng-container formArrayName="branchArray">
          <table
            aria-describedby="product-list"
            mat-table
            [dataSource]="tableDataSource"
          >
            <ng-container matColumnDef="srNo">
              <th mat-header-cell *matHeaderCellDef class="w-20">Sr No.</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <div class="d-flex">
                  {{ i + 1 }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
              >
                <mat-form-field
                  appearance="standard"
                  [ngClass]="{
                    'disabled-branch':
                      branchArray.controls[i].get('isDefault').value
                  }"
                >
                  <input
                    matInput
                    formControlName="name"
                    (change)="onBranchNameChange($event)"
                    autocomplete="off"
                    tabindex="-1"
                    [readonly]="branchArray.controls[i].get('isDefault').value"
                  />
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="closeButton">
              <th mat-header-cell *matHeaderCellDef class="w-50"></th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                [formGroupName]="i"
                class="text-align-center"
              >
                <div class="quick-add-buttons">
                  <button
                    type="button"
                    mat-button
                    disableRipple
                    [disabled]="branchArray.controls[i].get('isDefault').value"
                    (click)="onDeleteBranchDetails(i)"
                  >
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
          </table>
        </ng-container>
      </form>
    </div>
    <div class="mb-1 mt-1 d-flex justify-space-between">
      <div>
        <a class="link mr-1" href="javascript:void(0)" (click)="addNewRow()"
          >Add new line</a
        >
      </div>
    </div>
  </div>
</div>
