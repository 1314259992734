import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Injector, OnInit } from '@angular/core';
import { NotificationTextMessage } from '@app/core/Enum';
import {
  CisSubmissionReport,
  ExportCisReturn,
  MenuState,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-cis-submission',
  templateUrl: './cis-submission.component.html',
  styleUrls: ['./cis-submission.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('isExpanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'isExpanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class CisSubmissionComponent implements OnInit {
  displayedColumns: string[] = [
    'period',
    'totalSubcontractor',
    'status',
    'acceptedOn',
    'action',
  ];
  spinner: NgxSpinnerService;
  currentRowSelected: any;
  expandId: any;
  expandedCollapseList: any[] = [];
  noDataFound = false;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(private injector: Injector, public store: Store) {
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
  }

  ngOnInit(): void {
    this.getList();
  }

  getList(): void {
    this.store
      .dispatch(new CisSubmissionReport())
      .pipe()
      .subscribe((res) => {
        this.expandedCollapseList = res.CISReport.cisSubmissionReport;
      });
  }

  expandCollapse(row): void {
    if (this.currentRowSelected && this.currentRowSelected !== row) {
      this.currentRowSelected.isExpanded = false;
    }
    this.currentRowSelected = row;

    this.expandId = row[0];
    if (row.isExpanded) {
      row.isExpanded = false;
    } else {
      row.isExpanded = true;
    }
  }
  onDownloadClick(element: any, event: MouseEvent): void {
    event.stopPropagation();
    this.spinner.show();
    let ids: any = [];
    ids.push(element.id);

    this.store.dispatch(new ExportCisReturn(ids)).subscribe();
  }

  statusText(id: Guid, status: number): string {
    let t = '';

    switch (status) {
      case 1:
        t = `<span class="badge badge-warning" style="width:150px; vertical-align: middle;" id="vatstatus_${id}">Response Pending</span>`;
        break;
      case 2:
        t = `<span class="badge badge-success" style="width:150px; vertical-align: middle;" id="vatstatus_${id}">HMRC Submitted</span>`;
        break;
      case 3:
        t = `<span class="badge badge-darkpink" style="width:150px; vertical-align: middle;" id="vatstatus_${id}">Failed</span>`;
        break;
      case 5:
        t = `<span class="badge badge-darkpink" style="width:150px; background:#493b92;vertical-align: middle;" id="vatstatus_${id}">Capium Submitted</span>`;
        break;
      case 0:
      default:
        break;
    }

    return t;
  }
}
