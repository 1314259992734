import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AccountEntity,
  GroupNames,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { CommonService } from '@app/core/Services';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';

import { Location } from '@angular/common';

import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { VatRateCodeType } from '@app/core/Enum/vat-rate-code-type';
import {
  CashCodingModel,
  GlobalComponent,
  GroupListModel,
  MainListParameters,
} from '@app/core/Models';
import { BankTransactionModel } from '@app/core/Models/bank/bank-overview/bank-overview';
import {
  GetAccountBranchId,
  GetAllDepartmentListByBranch,
  GetBankTrasnactions,
  GetCustomBankAccountList,
  GetGroupAccountsBasedOnGroupId,
  GetJournalAccounts,
  GetNonStandardAccountList,
  GetNonStandardAccountListByBranch,
  GetVatRateList,
  MenuState,
} from '@app/core/Store';
import { CreateCashCoding } from '@app/core/Store/bank/cash-coding';
import { AddClosePopupComponent } from '@app/modules/common';
import { Guid } from 'guid-typescript';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-cash-coding',
  templateUrl: './cash-coding.component.html',
  styleUrls: ['./cash-coding.component.scss'],
})
export class CashCodingComponent implements OnInit {
  formCashCoding: UntypedFormGroup;
  cashCodingArray: any;
  listParameters: MainListParameters = new MainListParameters();
  vatRate = 0;
  moduleEnum = Modules;
  isRowHighlighted = false;
  noDataFound = false;
  accountGroupList: any[] = [];
  tempAccountGroupList: any[] = [];
  invoiceGroupList: GroupListModel[];
  purchaseGroupList: GroupListModel[];
  vatRateList: any[];
  customerList: any[];
  supplierList: any[];
  bankTransactions: BankTransactionModel[] = [];
  isAllSelected = false;
  isRecordSelected = false;
  isCustomerAccount = false;
  isSupplierAccount = false;
  receiptAccountList: any[] = [];
  tempReceiptAccountList: any[];
  departmentList: any[] = [];
  branchList: any[] = [];

  vatAmountIsDisabled = true;

  searchText = '';
  tableDataSource: MatTableDataSource<AbstractControl>;
  subscriptionRouting: Subscription;
  router: Router;

  customId: any;
  branchId: any;
  departmentId: any;
  isHeaderChange = false;
  customDate = false;
  spinner: NgxSpinnerService;
  commonService: CommonService;
  store: Store;
  isVatRegistered: any;

  displayedColumns: string[] = [
    'srNo',
    'date',
    'reference',
    'account',
    'vatRate',
    'vatAmount',
    'contactName',
    'receipt',
    'payment',
  ];
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  _Activatedroute: ActivatedRoute;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public datepipe: DatePipe,

    private renderer: Renderer2,
    private location: Location,
    private injector: Injector,
    public globalComponent: GlobalComponent
  ) {
    this.router = injector.get<Router>(Router);
    this._Activatedroute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.vatRateList = [];
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.commonService = injector.get<CommonService>(CommonService);
    this.store = injector.get<Store>(Store);
  }

  ngOnInit(): void {
    this.isVatRegistered = this.globalComponent.getIsVatRegistered();
    if (!this.isVatRegistered) {
      this.displayedColumns = this.displayedColumns.filter(
        (column) => column !== 'vatRate' && column !== 'vatAmount'
      );
    }
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.customId = atob(params.get('customId')!) as unknown as any;
          this.listParameters.pageNumber = 1;
          this.listParameters.pageSize = 2147483647;
          this.listParameters.moduleId = this.customId;

          if (this.customId !== null && this.customId !== undefined) {
            this.getList();
            this.loadDropdownValues();
          } else {
            this.location.back();
            this.commonService.onFailure('Please select bank account');
          }
        }
      }
    );
  }

  getList(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetBankTrasnactions(this.getParamter()))
      .pipe()
      .subscribe((res) => {
        this.spinner.hide();
        this.bankTransactions = res.bankOverview.getBankTransactions;
        this.bankTransactions = this.bankTransactions.filter(
          (transaction) => !transaction.isManual
        );

        this.noDataFound = this.bankTransactions.length > 0 ? false : true;
      });
  }

  getBankAccountList(): void {
    this.store
      .dispatch(new GetCustomBankAccountList())
      .pipe(
        tap((res) => {
          this.receiptAccountList = res.common.customBankAccount;
          this.tempReceiptAccountList = this.receiptAccountList;
          if (this.receiptAccountList.length > 0) {
            if (this.customId === undefined || this.customId === null) {
              this.customId = this.receiptAccountList[0].id;
            }
          }
        })
      )
      .subscribe();
  }

  async getAccountBranchId(): Promise<void> {
    try {
      const res = await this.store
        .dispatch(new GetAccountBranchId(this.customId))
        .toPromise();
      this.branchId = res.common.branchId;

      if (
        this.branchId !== undefined &&
        this.branchId !== null &&
        this.branchId !== this.commonService.defaultGuidValue
      ) {
        await this.getDepartmentListByBranch(this.branchId);
      }
    } catch (error) {
      console.error('Error occurred:', error);
      // Handle errors as needed
    }
  }

  setForm(): FormGroup {
    const form = this.formBuilder.group({
      id: new FormControl<Guid | null>(Guid.EMPTY as unknown as Guid),
      account: new FormControl('', [Validators.required]),
      customerSupplier: new FormControl(''),
      vatRate: new FormControl(''),
      vatAmount: new FormControl({ value: 0.0, disabled: true }),
    });

    return form;
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    return queryParams;
  }

  async loadDropdownValues(): Promise<any> {
    await this.getAccountBranchId();
    this.getBankAccountList();
    this.getAccounts();
    this.getVatRateList();
    this.getSupplierList();
    this.getCustomerList();
    this.getInvoiceAccountList();
    this.getPurchaseAccountList();
  }

  getSupplierList(): void {
    this.spinner.show();
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Supplier);

    let actionName;
    if (
      this.branchId !== undefined &&
      this.branchId !== null &&
      this.branchId !== this.commonService.defaultGuidValue
    ) {
      actionName = GetNonStandardAccountListByBranch;
    } else {
      actionName = GetNonStandardAccountList;
    }

    this.store
      .dispatch(new actionName(entityId, this.branchId))
      .pipe(
        tap((res) => {
          this.spinner.hide();
          this.supplierList = res.common.accountList;
        })
      )
      .subscribe();
  }

  getCustomerList(): void {
    this.spinner.show();
    const entityId = new Array<number>();
    entityId.push(AccountEntity.Customer);

    let actionName;
    if (
      this.branchId !== undefined &&
      this.branchId !== null &&
      this.branchId !== this.commonService.defaultGuidValue
    ) {
      actionName = GetNonStandardAccountListByBranch;
    } else {
      actionName = GetNonStandardAccountList;
    }

    this.store
      .dispatch(new actionName(entityId, this.branchId))
      .pipe(
        tap((res) => {
          this.spinner.hide();
          this.customerList = res.common.accountList;
        })
      )
      .subscribe();
  }

  getVatRateList(): void {
    this.spinner.show();
    this.store.dispatch(new GetVatRateList()).subscribe((res) => {
      this.spinner.hide();
      this.vatRateList = res.common.vatRate;
    });
  }

  getAccounts(): void {
    this.spinner.show();
    this.store.dispatch(new GetJournalAccounts()).subscribe((res) => {
      this.spinner.hide();
      this.accountGroupList = this.addGroupNameToListModels(
        res.account.accounts
      );
      this.accountGroupList = this.accountGroupList.filter(
        (x) => x.id !== this.customId
      );
    });
  }

  getInvoiceAccountList(): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.Turnover);

    this.store
      .dispatch(new GetGroupAccountsBasedOnGroupId(groupNames, Modules.Invoices))
      .pipe(
        tap((res) => {
          if (res.common.accountGroupList.length > 0) {
            this.invoiceGroupList = res.common.accountGroupList;
          }
        })
      )
      .subscribe();
  }

  getPurchaseAccountList(): void {
    const groupNames = new Array<number>();
    groupNames.push(GroupNames.CostOfSales);
    groupNames.push(GroupNames.SellingAndDistributionCosts);
    groupNames.push(GroupNames.AdministrativeExpenses);

    this.store
      .dispatch(new GetGroupAccountsBasedOnGroupId(groupNames, Modules.Purchase))
      .pipe(
        tap((res) => {
          if (res.common.accountGroupList.length > 0) {
            this.purchaseGroupList = res.common.accountGroupList;
          }
        })
      )
      .subscribe();
  }

  onAccountChange(element: any): void {
    let customerAccount;
    this.invoiceGroupList.forEach((item) => {
      item.listModels.forEach((x) => {
        if (x.id == element.accountName) {
          customerAccount = x;
          return;
        }
      });
    });

    let supplierAccount;
    this.invoiceGroupList.concat(this.purchaseGroupList).forEach((item) => {
      item.listModels.forEach((x) => {
        if (x.id == element.accountName) {
          supplierAccount = x;
          return;
        }
      });
    });

    if (customerAccount !== undefined && customerAccount !== null) {
      this.isCustomerAccount = true;
      this.isSupplierAccount = false;
    } else if (supplierAccount !== undefined && supplierAccount !== null) {
      this.isSupplierAccount = true;
      this.isCustomerAccount = false;
    } else {
      this.isCustomerAccount = false;
      this.isSupplierAccount = false;
    }

    this.bankTransactions.forEach((y) => {
      if (y.isSelected && element.isSelected) {
        y.accountName = element.accountName;
        if (
          y.reciepts !== 0 &&
          customerAccount !== undefined &&
          customerAccount !== null
        ) {
          y.contactEnable = true;
        } else if (
          y.reciepts === 0 &&
          supplierAccount !== undefined &&
          supplierAccount !== null
        ) {
          y.contactEnable = true;
        } else {
          y.contact = null;
          y.contactEnable = false;
        }
      }
    });
  }

  onContactChange(element: any): void {
    this.bankTransactions.forEach((y) => {
      if (y.isSelected && element.isSelected) {
        if (+element.reciepts !== 0 && this.isCustomerAccount) {
          element.contactEnable = true;
          y.contact = element.contact;
        } else if (+element.reciepts === 0 && this.isSupplierAccount) {
          element.contactEnable = true;
          y.contact = element.contact;
        } else {
          element.contactEnable = false;
          element.contact = null;
        }
      }
    });
  }

  onVatChange(element: any): void {
    this.calVatAmount(element);
    this.bankTransactions.forEach((y) => {
      if (y.isSelected && element.isSelected) {
        y.vatRateId = element.vatRateId;
        y.vatAmount = element.vatAmount;
      }
    });
  }

  calVatAmount(element: any): void {
    let isCustom = false;
    if (this.vatRateList.length > 0) {
      const data = this.vatRateList.filter((x) => x.id === element.vatRateId);
      if (data.length > 0) {
        this.vatRate = data[0].rate;
        isCustom = data[0].codeType === VatRateCodeType.Custom ? true : false;
      } else {
        this.vatRate = 0;
      }
    }

    if (isCustom) {
      this.vatAmountIsDisabled = false;
      element.vatAmount = 0;
      return;
    }

    if (+element.reciepts !== 0) {
      const vatAmount =
        (element.reciepts * (this.vatRate / 100)) / (1 + this.vatRate / 100);

      element.vatAmount = vatAmount.toFixed(2);
    } else {
      const vatAmount =
        (element.payments * (this.vatRate / 100)) / (1 + this.vatRate / 100);

      element.vatAmount = vatAmount.toFixed(2);
    }
  }

  selectAll(event: any): void {
    this.bankTransactions.forEach((x) => this.onCheckBoxChanges(event, x));
    this.isRecordSelected = event.checked;
  }

  onCheckBoxChanges(event: any, element: any): void {
    element.isSelected = event.checked;
    if (event.checked) {
      this.bankTransactions.forEach((y) => {
        if (y.isSelected && element.isSelected) {
          element.accountName = y.accountName;
          element.vatRateId = y.vatRateId;
          element.vatAmount = y.vatAmount;
          if (+element.reciepts !== 0 && this.isCustomerAccount) {
            element.contactEnable = true;
            element.contact = y.contact;
          } else if (+element.reciepts === 0 && this.isSupplierAccount) {
            element.contactEnable = true;
            element.contact = y.contact;
          } else {
            element.contactEnable = false;
            element.contact = null;
          }
        }
      });
    } else {
      element.accountName = '';
      element.vatRateId = '';
      element.contact = '';
      element.vatAmount = 0;
      element.contactEnable = false;
    }
  }

  onSave(): void {
    const param: CashCodingModel[] = [];
    for (let bulkEdit of this.bankTransactions) {
      if (bulkEdit.isSelected) {
        param.push({
          transactionId: bulkEdit.id,
          accountId: bulkEdit.accountName,
          bankAccountId: this.customId,
          isBankFeed: bulkEdit.isBankFeed,
          contactId: bulkEdit.contact,
          isCredit: bulkEdit.reciepts !== 0 ? true : false,
          vatRateId:
            bulkEdit.vatRateId === undefined ||
            bulkEdit.vatRateId === null ||
            bulkEdit.vatRateId === this.commonService.defaultGuidValue
              ? null
              : bulkEdit.vatRateId,
          vatAmount: bulkEdit.vatAmount,
          branchId: this.branchId,
          departmentId: this.departmentId,
        });
      }
    }

    if (param.length > 0) {
      this.spinner.show();
      this.store
        .dispatch(new CreateCashCoding(param))
        .pipe()
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res !== undefined) {
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
              history.back();
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    }
  }

  addGroupNameToListModels(data: any[]): any[] {
    let result: any = [];

    data.forEach((group) => {
      group.listModels.forEach((listModel) => {
        let listModelWithGroup = {
          ...listModel,
          groupName: group.groupName,
        } as any;
        result.push(listModelWithGroup);
      });
    });

    return result;
  }

  onCancel(): void {
    this.ngOnInit();
  }

  onCloseClick(): void {
    this.dialog
      .open(AddClosePopupComponent, {})
      .afterClosed()
      .subscribe((result) => {
        this.router.navigate([RoutingPath.BankDashboard]);
        this.commonService.setLocalStorage('selectedBank', this.customId);
      });
  }

  triggerDateChange(data: any): void {
    if (data.value === '-1') {
      this.listParameters.startDate = '';
      this.listParameters.endDate = '';
    } else {
      this.listParameters.startDate = data.startDate;
      this.listParameters.endDate = data.endDate;
    }

    this.getList();
  }

  onSearch(): void {
    this.listParameters.search = this.searchText;
    this.getList();
  }

  onSearchAll(): void {
    if (this.searchText === '') {
      this.onSearch();
    }
  }

  async onBankAccountChange(): Promise<any> {
    this.listParameters.moduleId = this.customId;
    this.getList();
    this.loadDropdownValues();
  }

  getDepartmentListByBranch(branch: any): void {
    if (branch !== undefined && branch !== null && branch !== '') {
      this.store
        .dispatch(new GetAllDepartmentListByBranch(branch))
        .pipe(
          tap((res) => {
            this.departmentList = [];
            if (res.company.department.length > 0) {
              this.departmentList = res.company.department;
              // if (!this.isEdit) {
              // this.invoiceForm.controls.departmentId.setValue(
              this.departmentId = this.departmentList[0].id;
              // );
              // }
            } else {
              this.departmentList = [];
            }
          })
        )
        .subscribe();
    } else {
      this.departmentList = [];
    }
  }
}
