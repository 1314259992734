import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { GeneralDashboardBarChart } from '@app/core/Models/general-dashboard/general-dashboard';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexPlotOptions,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
  title: ApexTitleSubtitle;
};
@Component({
  selector: 'app-dashboard-monthly-return',
  templateUrl: './dashboard-monthly-return.component.html',
  styleUrls: ['./dashboard-monthly-return.component.scss'],
})
export class DashboardMonthlyReturnComponent {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  analyticsData: Array<GeneralDashboardBarChart>;
  @Input() generalDashboardBarChartData: any;
  ngOnChanges(changes: SimpleChanges) {
    if (this.generalDashboardBarChartData) {
      if (changes['generalDashboardBarChartData']) {
        this.analyticsData = this.generalDashboardBarChartData;
        if (this.analyticsData?.length > 0) {
          this.setChartValue();
        }
      }
    }
  }
  setChartValue(): void {
    const barChartLables: any = [];
    const barChartValues: any = [];

    for (const data of this.analyticsData) {
      barChartValues.push(data.vatReturnCount);
    }
    for (const data of this.analyticsData) {
      barChartLables.push(data.month);
    }
    this.chartOptions = {
      series: [
        {
          name: 'Return(s)',
          data: barChartValues,
        },
      ],
      chart: {
        height: 230,
        type: 'bar',
        toolbar: {
          show: false,
        },
      },

      plotOptions: {
        bar: {
          columnWidth: '45%',
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      fill: {
        colors: [
          '#7B76D3',
          '#F4C64D',
          '#F2816F',
          '#BA5397',
          '#7A5295',
          '#B9CE64',
        ],
      },
      xaxis: {
        categories: barChartLables,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
      },
    };
  }
}
