import { OpeningBalance, SaveOpeningBalanceModel } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class SaveOpeningBalance {
  static readonly type = '[OPENINGBALANCE] save Opening balance';

  constructor(public openingBalance: SaveOpeningBalanceModel) {}
}

export class GetOpeningBalanceList {
  static readonly type = '[OPENINGBALANCE] Get Opening balance List';
}

export class GetOpeningBalanceListBasedOnBranchId {
  static readonly type =
    '[OPENINGBALANCE] Get Opening balance List based on branch Id';

  constructor(public branchId: Guid) {}
}

export class SaveOpeningBalanceExplain {
  static readonly type = '[OPENINGBALANCE] save Opening balance Explain';

  constructor(
    public openingBalanceExplain: Array<OpeningBalance>,
    public accountTypeId: number
  ) {}
}

export class SaveOpeningBalanceAdvance {
  static readonly type = '[OPENINGBALANCE] save Opening balance Advance';

  constructor(
    public openingBalanceExplain: Array<OpeningBalance>,
    public accountTypeId: number
  ) {}
}

export class GetOpeningBalanceBasedOnAPId {
  static readonly type =
    '[OPENINGBALANCE] Get Opening Balance Based On Accouting Period Id ';

  constructor(public accoutingPeriodId: Guid) {}
}

export class SaveTrialBalance {
  static readonly type = '[TRIALBALANCE] save Opening balance';
  constructor(public trialBalance: SaveOpeningBalanceModel) {}
}
