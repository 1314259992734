import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { ProductsModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { CreateProduct, GetDataByProductId, MenuState } from '@app/core/Store';
import { AddClosePopupComponent, AddProductComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;
  productId = Guid.EMPTY as unknown as Guid;
  defaultGuid = Guid.EMPTY as unknown as Guid;
  moduleId = Modules.Product;
  moduleName = ModuleName.Product;
  productData: ProductsModel;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  triggerEditData: Subject<any> = new Subject<any>();
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddProductComponent, { static: true })
  productDetails;

  subscriptionRouting: Subscription;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    public dialog: MatDialog,
    private _Activatedroute: ActivatedRoute,
    public commonService: CommonService,
    private spinner: NgxSpinnerService,
    private location: Location,
    private store: Store,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.productId = atob(params.get('id')!) as unknown as Guid;
          this.editProduct();
        }
      }
    );
  }

  editProduct(): void {
    this.store
      .dispatch(new GetDataByProductId(this.productId))
      .subscribe((res) => {
        this.productDetails.productForm.markAsUntouched();

        this.triggerEditData.next(res.product.productData);
      });
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.productData = {
        id: this.productId,
        name: this.productDetails.productForm.controls.productName.value,
        code: this.productDetails.productForm.controls.productCode.value,
        description:
          this.productDetails.productForm.controls.productDescription.value,
        vatRateId:
          this.productDetails.productForm.controls.vatRate.value ?? null,
        price: +this.productDetails.productForm.controls.productPrice.value,
        openingBalanceQuantity:
          +this.productDetails.productForm.controls.openingBalanceQuantity
            .value,
        openingBalancePrice:
          +this.productDetails.productForm.controls.openingBalancePrice.value,
        trackProductInInvetory:
          this.productDetails.productForm.controls.trackProductInInvetory.value,
        branchId:
          this.productDetails.productForm.controls.branchId.value === ''
            ? null
            : this.productDetails.productForm.controls.branchId.value,
        departmentId:
          this.productDetails.productForm.controls.departmentId.value === ''
            ? null
            : this.productDetails.productForm.controls.departmentId.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.productDetails.productForm.invalid) {
      this.commonService.addValidation(
        this.productDetails.productForm,
        this.renderer
      );
    } else if (
      this.productDetails.productForm.controls.productPrice.value === '' ||
      this.productDetails.productForm.controls.productPrice.value === '0'
    ) {
      this.commonService.onFailure(NotificationTextMessage.amtErrorMessage);
      this.commonService.addValidation(
        this.productDetails.productForm.controls.productPrice.value,
        this.renderer
      );
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new CreateProduct(this.productData))
          .pipe()
          .subscribe(
            (res) => {
              if (res !== undefined) {
                this.setHighlightData(isExit);

                if (!isExit) {
                  this.onCancel(false);
                  this.reloadSideList.emit();
                } else {
                  this.onCancel(false);
                  this.location.back();
                }
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.productId,
      isExit,
      Modules.Product,
      RoutingPath.AddProduct
    );
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.productId)) {
      this.editProduct();
    } else {
      this.productId = Guid.EMPTY as unknown as Guid;
      this.productDetails.ngOnInit();
    }
  }
}
