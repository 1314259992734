import { DatePipe, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  DiscountType,
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { ProductDetailsModel, QuotationModel } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  CreateQuotation,
  GetDataByQuotationId,
  GetQuotationNumber,
  MenuState,
} from '@app/core/Store';
import {
  AddClosePopupComponent,
  AddQuotationComponent,
  ProductDetailsComponent,
  ProductDetailsFooterComponent,
  TransactionsAttachmentComponent,
} from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss'],
})
export class QuotationComponent implements OnInit {
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;

  moduleId = Modules.Quotation;
  moduleName = ModuleName.Quotation;

  quotationId = Guid.EMPTY as unknown as Guid;
  defaultGuid = Guid.EMPTY as unknown as Guid;
  quotationData: QuotationModel;
  productItems: Array<ProductDetailsModel>;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddQuotationComponent, { static: true })
  quotationDetails;

  @ViewChild(ProductDetailsComponent, { static: true })
  productDetails;

  @ViewChild(TransactionsAttachmentComponent, { static: true })
  attachmentDetail;

  @ViewChild(ProductDetailsFooterComponent, { static: true })
  productDetailsFooter;

  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;

  subscriptionRouting: Subscription;

  triggerEditData: Subject<any> = new Subject<any>();
  triggereEditProductData: Subject<any> = new Subject<any>();
  triggerBranchChange: Subject<any> = new Subject<any>();
  triggerCurrencySelectedOption: Subject<boolean> = new Subject<boolean>();
  triggerCurrencyRefreshOption: Subject<boolean> = new Subject<boolean>();
  triggerSelectedCurrencyRate: Subject<number> = new Subject<number>();
  triggerNewProductAdded: Subject<any> = new Subject<any>();
  triggerDiscountData: Subject<any> = new Subject<any>();
  amount = 0;
  balanceAmount = 0;
  selectedCurrencyId: number;
  selectedCurrencyRate: number;
  store: Store;
  commonService: CommonService;
  public defaultGuidValue = Guid.EMPTY as unknown as Guid;
  constructor(
    private _Activatedroute: ActivatedRoute,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe,
    private location: Location,
    private renderer: Renderer2,
    private injector: Injector
  ) {
    this.store = injector.get<Store>(Store);
    this.commonService = injector.get<CommonService>(CommonService);
  }

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.quotationId = atob(params.get('id')!) as unknown as Guid;
          this.editQuotation();
        } else {
          this.getQuotationNumber();
        }
      }
    );
  }

  editQuotation(): void {
    this.store
      .dispatch(new GetDataByQuotationId(this.quotationId))
      .subscribe((res) => {
        this.quotationDetails.quotationForm.markAsUntouched();
        this.productDetails.formProductDetail.markAsUntouched();
        setTimeout(() => {
          this.triggerEditData.next(res.quotation.quotationData);
          const data = {
            productData: res.quotation.quotationData.quotationItems,
          };
          this.triggereEditProductData.next(data);
          let discountData = {
            discountValue: res.quotation.quotationData.foreignDiscount,
            discountType: res.quotation.quotationData.isDiscountPercentage
              ? DiscountType.Percentage
              : DiscountType.Value,
          };
          this.triggerDiscountData.next(discountData);
        }, 500);
      });
  }

  onAddedNewProductDetails(selectedOption: any) {
    this.triggerNewProductAdded.next(selectedOption);
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(val: boolean): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  reloadAutoNumberGenerator(): void {
    this.getQuotationNumber();
  }

  triggerReloadSideList(): void {
    this.reloadSideList.emit();
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  getQuotationNumber(): void {
    this.store.dispatch(new GetQuotationNumber()).subscribe((res) => {
      res.quotation.quotationNo !== null
        ? this.quotationDetails.quotationForm.controls.quotationNo.disable()
        : this.quotationDetails.quotationForm.controls.quotationNo.enable();
      this.quotationDetails.quotationForm.controls.quotationNo.setValue(
        res.quotation.quotationNo
      );
    });
  }

  dataSubmit(): boolean {
    this.spinner.show();
    this.selectedCurrencyId =
      this.quotationDetails.quotationForm.controls.currency.value === 0
        ? 123
        : this.quotationDetails.quotationForm.controls.currency.value;
    this.selectedCurrencyRate =
      this.quotationDetails.quotationForm.controls.currencyAmt.value;
    try {
      this.productItems = new Array<ProductDetailsModel>();
      const filedata: any[] = [];

      if (
        this.attachmentDetail.fileUploadResponse.length > 0 &&
        this.attachmentDetail.fileUploadResponse !== undefined &&
        this.attachmentDetail.fileUploadResponse !== null
      ) {
        this.attachmentDetail.fileUploadResponse.forEach((element) => {
          filedata.push(element.fileUrl);
        });
      }

      this.productDetails.productDetailArray?.getRawValue().forEach((x) => {
        this.productItems.push({
          id: x.id,
          productId: x.productName ? x.productName : this.defaultGuidValue,
          description: x.description,
          quantity: +x.qty,
          price: +x.price / this.selectedCurrencyRate,
          vatRateId: x.vatRate === -1 ? null : x.vatRate,
          vatAmount: +x.vatAmount / this.selectedCurrencyRate,
          totalAmount: +(x.amount / this.selectedCurrencyRate).toFixed(2),
          foreignVatAmount: +x.vatAmount,
          foreignPrice: +x.price,
          foreignTotalAmount:  +(+x.amount).toFixed(2),
          discount:
            x.discountType === DiscountType.Percentage
              ? +x.discountValue
              : +x.discountValue / this.selectedCurrencyRate,
          isDiscountPercentage: x.discountType === DiscountType.Percentage,
          discountDescription: x.discountDescription,
          foreignDiscount: +x.discountValue,
          bookAccountId: x.invoiceType?.id,

        });
      });

      this.quotationData = {
        id: this.quotationId,
        entryNumber:
          this.quotationDetails.quotationForm.controls.quotationNo.value,
        entryDate: this.datepipe
          .transform(
            this.quotationDetails.quotationForm.controls.entryDate.value,
            'yyyy-MM-dd'
          )
          ?.toString(),

        currencyId: this.quotationDetails.quotationForm.controls.currency.value,
        currencyRate:
          this.quotationDetails.quotationForm.controls.currencyAmt.value,
        accountId:
          this.quotationDetails.quotationForm.controls.customerName.value,
        note: this.quotationDetails.quotationForm.controls.note.value,
        quotationItems: this.productItems,
        //bookAccountId:
        //  this.quotationDetails.quotationForm.controls.invoiceType.value.id,
        attachment: filedata,
        branchId:
          this.quotationDetails.quotationForm.controls.branchId.value === ''
            ? null
            : this.quotationDetails.quotationForm.controls.branchId.value,
        departmentId:
          this.quotationDetails.quotationForm.controls.departmentId.value === ''
            ? null
            : this.quotationDetails.quotationForm.controls.departmentId.value,
        discount:
          this.productDetailsFooter.discountType === DiscountType.Percentage
            ? this.productDetailsFooter.discountValue
            : this.productDetailsFooter.discountValue /
              this.selectedCurrencyRate,
        isDiscountPercentage:
          this.productDetailsFooter.discountType === DiscountType.Percentage,
        foreignDiscount: this.productDetailsFooter.discountValue,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.quotationId,
      isExit,
      Modules.Quotation,
      RoutingPath.AddQuotation
    );
  }

  onSave(isExit: boolean): void {
    if (this.quotationDetails.quotationForm.invalid) {
      this.commonService.addValidation(
        this.quotationDetails.quotationForm,
        this.renderer
      );
    } else if (this.productDetails.productDetailArray.invalid) {
      this.productDetails.productDetailArray.controls.forEach((x) => {
        (Object as any).values(x.controls).forEach((c) => {
          c.markAsTouched();
        });
      });
    } else {
      if (this.dataSubmit()) {
        if (this.quotationData) {
          this.store
            .dispatch(new CreateQuotation(this.quotationData))
            .pipe()
            .subscribe(
              (res) => {
                if (res !== undefined) {
                  this.setHighlightData(isExit);

                  if (!isExit) {
                    this.onCancel(false);
                    this.reloadSideList.emit();
                  } else {
                    this.onCancel(false);
                    this.location.back();
                  }
                  this.commonService.onSuccess(
                    NotificationTextMessage.successMessage
                  );
                } else {
                  this.commonService.onFailure(
                    NotificationTextMessage.errorMessage
                  );
                }
              },
              (err) => {
                this.commonService.onFailure(err.message);
              }
            );
        }
      }
    }
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.quotationId)) {
      this.editQuotation();
    } else {
      this.quotationId = Guid.EMPTY as unknown as Guid;
      this.quotationDetails.ngOnInit();
      this.productDetails.ngOnInit();
      this.attachmentDetail.ngOnInit();
      this.getQuotationNumber();
      this.productDetailsFooter.grossTotal = 0;
      this.productDetailsFooter.total = 0;
      this.productDetailsFooter.balanceAmount = 0;
      this.productDetailsFooter.postponedVatAmount = 0;
      this.productDetailsFooter.discountedValue = 0;
      this.productDetailsFooter.discountType = DiscountType.Value;
    }
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  onBranchChange(selectedOption: any) {
    this.triggerBranchChange.next(selectedOption);
  }
  onCurrencySelectedOption(selectedOption: any) {
    this.triggerCurrencySelectedOption.next(selectedOption);
  }
  onCurrencyRefreshOption(selectedOption: any) {
    this.triggerCurrencyRefreshOption.next(selectedOption);
  }
  onCurrencySelectedRate(selectedOption: any) {
    this.triggerSelectedCurrencyRate.next(selectedOption);
  }
}
