import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BankFeedDuplicateTransactionModel,
  BankTransferModel,
} from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { CommonService } from '../..';

@Injectable({
  providedIn: 'root',
})
export class BankTransferService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createBankTransfer(BankTransfer: BankTransferModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}BankTransfer/save`,
      this.commonService.trimObjectSpace(JSON.stringify(BankTransfer)),
      headers
    );
  }

  getDataByBankTransferId(bankTransferId: Guid): Observable<BankTransferModel> {
    return this.http.get<BankTransferModel>(
      `${environment.apiVersionUrl}BankTransfer/get/${bankTransferId}`
    );
  }

  deleteBankTransfer(bankTransferIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: this.commonService.trimObjectSpace(JSON.stringify(bankTransferIds)),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}BankTransfer/delete`,
      options
    );
  }

  copyBankTransfer(bankTransferIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}BankTransfer/copyBankTransfer`,
      this.commonService.trimObjectSpace(JSON.stringify(bankTransferIds)),
      headers
    );
  }

  archiveAndRestoreBankTransfer(
    bankTransferIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.patch<any>(
      `${environment.apiVersionUrl}BankTransfer/archive/${isArchive}`,
      this.commonService.trimObjectSpace(JSON.stringify(bankTransferIds)),
      options
    );
  }

  getBankTransferNumber(): Observable<any> {
    return this.http.get(
      `${environment.apiVersionUrl}BankTransfer/getBankTransferNo`,
      {
        responseType: 'text',
      }
    );
  }

  bankFeedDuplicateTransactionExist(
    bankFeedDuplicateTransaction: BankFeedDuplicateTransactionModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}BankAccount/bankFeedDuplicateTransactionExist`,
      this.commonService.trimObjectSpace(
        JSON.stringify(bankFeedDuplicateTransaction)
      ),
      headers
    );
  }
}
