<div class="content-body">
  <form [formGroup]="contactDetailForm">
    <div
      class="d-flex"
      [ngClass]="getModuleId == moduleId.Subcontractor ? 'mr-40' : 'gap-40'"
    >
      <div
        *ngIf="(moduleId$ | async) !== moduleId.AddCustomAccounts"
        class="form-field"
      >
        <p>{{ getModuleName }} Name <span class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="name"
            autocomplete="off"
            appAutoFocusDirective
          />
        </mat-form-field>
      </div>
      <div
        class="form-field"
        *ngIf="(moduleId$ | async) !== moduleId.Subcontractor"
      >
        <p>Trading Name</p>
        <mat-form-field>
          <input
            matInput
            formControlName="tradingName"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>

      <div
        class="form-field m-0"
        *ngIf="(moduleId$ | async) === moduleId.Suppliers"
      >
        <mat-checkbox formControlName="cisEnabled"> CIS Enabled </mat-checkbox>
      </div>
    </div>

    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Address Line 1</p>
        <mat-form-field>
          <input
            matInput
            formControlName="addressLine1"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Address Line 2</p>
        <mat-form-field>
          <input
            matInput
            formControlName="addressLine2"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Address Line 3</p>
        <mat-form-field>
          <input
            matInput
            formControlName="addressLine3"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>City / Town</p>
        <mat-form-field>
          <input
            matInput
            formControlName="city"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Country</p>
        <ng-select
          #country
          (focus)="country.open()"
          (valueChange)="country.close()"
          [disableOptionCentering]="true"
          formControlName="country"
          panelClass="mat-select-position"
        >
          <ng-option *ngFor="let cl of countryList" [value]="cl.id">
            <div title="{{ cl.description }}">{{ cl.description }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="form-field">
        <p>Postcode</p>
        <mat-form-field>
          <input
            matInput
            formControlName="postCode"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength10 }}"
            type="text"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Email<span *ngIf="getModuleName === 'Customer'" class="text-danger-300">*</span></p>
        <mat-form-field>
          <input
            matInput
            formControlName="email"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength180 }}"
            type="text"
            emailValidator
            [required]="getModuleName === 'Customer'"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Mobile Number</p>
        <mat-form-field>
          <input
            matInput
            formControlName="mobileNumber"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength25 }}"
            type="text"
            oninput="this.value = this.value.replace(/[^0-9\+]/g, '').replace(/(\.*)\./g, '$1');"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field">
        <p>Phone</p>
        <mat-form-field>
          <input
            matInput
            formControlName="phone"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength10 }}"
            type="text"
            oninput="this.value = this.value.replace(/[^0-9\+]/g, '').replace(/(\.*)\./g, '$1');"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <p>Fax</p>
        <mat-form-field>
          <input
            matInput
            formControlName="fax"
            autocomplete="off"
            maxlength="{{ maxLength.maxLength11 }}"
            type="text"
            oninput="this.value = this.value.replace(/[^0-9\+]/g, '').replace(/(\.*)\./g, '$1');"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex gap-40">
      <div class="form-field fund-name" *ngIf="branchList.length > 0">
        <p>Branch <span class="text-danger-300">*</span></p>
        <ng-select
          #branch
          (focus)="branch.open()"
          (valueChange)="branch.close()"
          [disableOptionCentering]="true"
          formControlName="branchId"
          dropdownPosition="bottom"
        >
          <ng-option *ngFor="let bl of branchList" [value]="bl.id">
            <div title="{{ bl.name }}">{{ bl.name }}</div>
          </ng-option>
        </ng-select>
      </div>
    </div>
  </form>
</div>
