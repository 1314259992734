import { MinuteOfMeeting } from '@app/core/Models';
import { Guid } from 'guid-typescript';

export class GetDefaultTemplate {
  static readonly type = '[Template] Get Template';
}

export class CreateMeeting {
  static readonly type = '[MinutesOfMeeting] Create Minutes Of Meeting ';

  constructor(public data: MinuteOfMeeting) {}
}

export class GetDataByMeetingId {
  static readonly type =
    '[MinutesOfMeeting] Get Minutes Of Meeting Data By Minutes Of Meeting Id';

  constructor(public minutesOfMeetingId: Guid) {}
}

export class ArchiveAndRestoreMinutesOfMeeting {
  static readonly type =
    '[MinutesOfMeeting] Archive and Restore Selected Minutes Of Meeting';

  constructor(
    public minutesOfMeetingIds: Array<Guid>,
    public isArchive: boolean
  ) {}
}

export class DeleteMinutesOfMeeting {
  static readonly type =
    '[MinutesOfMeeting] Delete Selected Minutes Of Meeting';

  constructor(public minutesOfMeetingIds: Array<Guid>) {}
}
