<div class="mat-dialog-wrapper quick-add">
  <div class="d-flex justify-space-between align-items-center mb-1">
    <h2 mat-dialog-title class="m-0">{{ quickData.headerText }}</h2>
    <button
      mat-button
      class="header-text-close d-flex align-items-center"
      disableRipple
      (click)="onCloseClick()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content
    [ngClass]="quickData.moduleId === moduleEnum.Currency ? 'add-curency' : ''"
  >
    <div *ngIf="quickData.moduleId !== moduleEnum.Product">
      <form [formGroup]="form">
        <div>
          <div *ngIf="quickData.moduleId === moduleEnum.QuickEntry">
            <div class="form-field">
              <mat-radio-group
                aria-label="Select an option"
                formControlName="quickEntryType"
              >
                <mat-radio-button [value]="true" class="mr-3"
                  >Customer</mat-radio-button
                >
                <mat-radio-button [value]="false">Supplier</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div
            *ngIf="
              quickData.moduleId !== moduleEnum.AddCustomAccounts &&
              quickData.moduleId !== moduleEnum.Permission &&
              quickData.moduleId !== moduleEnum.Currency
            "
            class="form-field"
          >
            <p>Name <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                formControlName="name"
                autocomplete="off"
                appAutoFocusDirective
                required
              />
            </mat-form-field>
          </div>

          <div
            *ngIf="quickData.moduleId === moduleEnum.Permission"
            class="form-field"
          >
            <div class="form-field">
              <p>First Name <span class="text-danger-300">*</span></p>
              <mat-form-field>
                <input
                  matInput
                  formControlName="firstName"
                  maxlength="{{ maxLength.maxLength50 }}"
                  autocomplete="off"
                  appAutoFocusDirective
                  unicodeCharacter
                />
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf="quickData.moduleId === moduleEnum.Permission"
            class="form-field"
          >
            <div class="form-field">
              <p>Last Name<span class="text-danger-300">*</span></p>
              <mat-form-field>
                <input
                  matInput
                  formControlName="lastName"
                  maxlength="{{ maxLength.maxLength50 }}"
                  autocomplete="off"
                  unicodeCharacter
                />
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf="
              quickData.moduleId !== moduleEnum.Shareholder &&
              quickData.moduleId !== moduleEnum.AddCustomAccounts &&
              quickData.moduleId !== moduleEnum.Currency
            "
            class="form-field"
          >
            <p>Email<span *ngIf="quickData.moduleId === moduleEnum.Customers" class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                formControlName="email"
                autocomplete="off"                
                type="text"
                emailValidator
                [required]="quickData.moduleId === moduleEnum.Customers"
              />
            </mat-form-field>
          </div>

          <div
            *ngIf="quickData.moduleId === moduleEnum.Shareholder"
            class="form-field"
          >
            <p>Value Per Share <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                allowDecimal
                formControlName="valuePerShare"
                autocomplete="off"
                maxlength="{{ maxLength.maxLength25 }}"
                required
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="quickData.moduleId === moduleEnum.Shareholder"
            class="form-field"
          >
            <p>No of Share <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                allowDecimal
                formControlName="noOfShare"
                autocomplete="off"
                maxlength="{{ maxLength.maxLength25 }}"
                required
              />
            </mat-form-field>
          </div>

          <div
            *ngIf="quickData.moduleId === moduleEnum.AddCustomAccounts"
            class="form-field"
          >
            <p>Account Group <span class="text-danger-300">*</span></p>
            <ng-select
              #chartOfAccountGroup
              (focus)="chartOfAccountGroup.open()"
              (valueChange)="chartOfAccountGroup.close()"
              [disableOptionCentering]="true"
              formControlName="chartOfAccountGroup"
              (change)="onChartOfAccountGroupChange($event)"
              appAutoFocusDirective
              [readonly]="(moduleId$ | async) === moduleEnum.BankDashboard"
            >
              <ng-option
                *ngFor="let cl of chartOfAccountGroupList"
                [value]="cl.id"
                title="{{ cl.name }}"
              >
                <div title="{{ cl.name }}">{{ cl.name }}</div>
              </ng-option>
            </ng-select>
          </div>
          <div
            class="form-field"
            *ngIf="
              quickData.moduleId === moduleEnum.AddCustomAccounts &&
              !isCashInHandGroup
            "
          >
            <p *ngIf="!isFixedAssestsGroup">Account Type</p>
            <p *ngIf="isFixedAssestsGroup">
              Account Name <span class="text-danger-300">*</span>
            </p>
            <ng-select
              *ngIf="!isFixedAssestsGroup"
              #chartOfAccountType
              [disabled]="isReadOnly"
              (focus)="chartOfAccountType.open()"
              (valueChange)="chartOfAccountType.close()"
              [disableOptionCentering]="true"
              formControlName="chartOfAccountType"
              appAutoFocusDirective
              (change)="checkIsCashInHandGroup()"
              [readonly]="(moduleId$ | async) === moduleEnum.BankDashboard"
            >
              <ng-option
                *ngFor="let clt of chartOfAccountTypeList"
                [value]="clt.id"
              >
                <div title="{{ clt.name }}">{{ clt.name }}</div>
              </ng-option>
            </ng-select>
            <mat-form-field *ngIf="isFixedAssestsGroup">
              <input
                matInput
                formControlName="chartOfAccountType"
                autocomplete="off"
                appAutoFocusDirective
                [required]="isFixedAssestsGroup"
              />
            </mat-form-field>
          </div>

          <div
            *ngIf="
              quickData.moduleId === moduleEnum.AddCustomAccounts &&
              !isFixedAssestsGroup
            "
            class="form-field"
          >
            <p>Account Name <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                formControlName="accountName"
                maxlength="{{ maxLength.maxLength200 }}"
                autocomplete="off"
                appAutoFocusDirective
              />
            </mat-form-field>
          </div>

          <div
            *ngIf="
              quickData.moduleId === moduleEnum.AddCustomAccounts &&
              !isFixedAssestsGroup
            "
            class="form-field"
          >
            <p>Account Code <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                maxlength="{{ maxLength.maxLength50 }}"
                formControlName="accountCode"
                autocomplete="off"
              />
            </mat-form-field>
          </div>

          <div
            *ngIf="
              quickData.moduleId === moduleEnum.AddCustomAccounts &&
              isCashInHandGroup
            "
            class="form-field"
          >
            <p>Bank Account Type <span class="text-danger-300">*</span></p>
            <ng-select
              #bankAccountType
              (focus)="bankAccountType.open()"
              (valueChange)="bankAccountType.close()"
              [disableOptionCentering]="true"
              formControlName="bankAccountType"
              panelClass="mat-select-position"
              [required]="isCashInHandGroup"
            >
              <ng-option *ngFor="let cl of accountTypeList" [value]="cl.id">
                <div title="{{ cl.name }}">
                  {{ cl.name }}
                </div>
              </ng-option>
            </ng-select>
          </div>

          <div
            *ngIf="
              quickData.moduleId === moduleEnum.AddCustomAccounts &&
              isCashInHandGroup
            "
            class="form-field"
          >
            <p>Account No <span class="text-danger-300">*</span></p>
            <mat-form-field>
              <input
                matInput
                maxlength="{{ maxLength.maxLength20 }}"
                formControlName="accountNo"
                autocomplete="off"
                [required]="isCashInHandGroup"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="
              quickData.moduleId === moduleEnum.AddCustomAccounts &&
              isCashInHandGroup
            "
            class="form-field"
          >
            <div class="form-field">
              <p>Sort/Branch Code</p>
              <mat-form-field>
                <input
                  matInput
                  formControlName="sortBranchCode"
                  maxlength="{{ maxLength.maxLength50 }}"
                  autocomplete="off"
                />
              </mat-form-field>
            </div>
          </div>
          <div
            *ngIf="
              quickData.moduleId === moduleEnum.AddCustomAccounts &&
              isCashInHandGroup
            "
            class="form-field"
          >
            <div class="form-field">
              <p>IBAN</p>
              <mat-form-field>
                <input
                  matInput
                  formControlName="bankAccountIBANCode"
                  maxlength="{{ maxLength.maxLength50 }}"
                  autocomplete="off"
                />
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf="
              quickData.moduleId === moduleEnum.AddCustomAccounts &&
              isCashInHandGroup
            "
            class="form-field"
          >
            <p>Currency</p>
            <ng-select
              #currency
              (focus)="currency.open()"
              (valueChange)="currency.close()"
              [disableOptionCentering]="true"
              formControlName="currency"
              panelClass="mat-select-position"
            >
              <ng-option *ngFor="let cl of currencyList" [value]="cl.id">
                <div title="{{ cl.country }} {{ cl.name }}">
                  {{ cl.country }} {{ cl.name }}
                </div>
              </ng-option>
            </ng-select>
          </div>

          <div
            *ngIf="quickData.moduleId === moduleEnum.Shareholder"
            class="form-field"
          >
            <p>Share Type</p>
            <mat-form-field>
              <mat-select
                [disableOptionCentering]="true"
                formControlName="shareType"
                panelClass="mat-select-position"
              >
                <mat-option value="1" title="Equity">Equity</mat-option>
                <mat-option value="2" title="Non-Equity">Non-Equity</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div
            *ngIf="quickData.moduleId === moduleEnum.Permission"
            class="form-field"
          >
            <div class="form-field">
              <p>Permission</p>
              <mat-form-field>
                <mat-select
                  [disableOptionCentering]="true"
                  formControlName="roleId"
                  panelClass="mat-select-position"
                >
                  <mat-option *ngFor="let role of roleList" [value]="role.id">
                    {{ role.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="form-field fund-name" *ngIf="branchList.length > 0">
            <p>Branch <span class="text-danger-300">*</span></p>
            <ng-select
              #branch
              (focus)="branch.open()"
              (valueChange)="branch.close()"
              [disableOptionCentering]="true"
              formControlName="branchId"
              dropdownPosition="bottom"
            >
              <ng-option *ngFor="let bl of branchList" [value]="bl.id">
                <div title="{{ bl.name }}">{{ bl.name }}</div>
              </ng-option>
            </ng-select>
          </div>
        </div>
      </form>
      <form [formGroup]="currencyForm">
        <div
          *ngIf="quickData.moduleId === moduleEnum.Currency"
          class="form-field"
        >
          <p>Currency</p>
          <ng-select
            #currency
            [disableOptionCentering]="true"
            (focus)="currency.open()"
            (valueChange)="currency.close()"
            formControlName="currency"
            panelClass="mat-select-position"
          >
            <ng-option *ngFor="let cl of currencyList" [value]="cl.id">
              <div title="{{ cl.country }}  {{ cl.name }}">
                {{ cl.country }} {{ cl.name }}
              </div>
            </ng-option>
          </ng-select>
        </div>
      </form>
    </div>
    <div *ngIf="quickData.moduleId === moduleEnum.Product">
      <app-add-product-popup
        [inputModuleId]="moduleId"
        [triggerOnProductSave]="triggerOnProductSave"
        [triggerOnProductClear]="triggerOnProductClear"
      >
      </app-add-product-popup>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="m-0">
    <app-buttons
      [getModuleId]="quickData.moduleId"
      (triggerOnSaveClick)="onSave()"
      (triggerOnCancelClick)="onCancel()"
      [saveButtonShow]="true"
    >
    </app-buttons>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
