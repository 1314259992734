import { Injectable, Injector } from '@angular/core';
import { DataType, Modules } from '@app/core/Enum';
import {
  AccountDetailViewModel,
  Currency,
  DefaultCurrency,
  FileUploadRequestModel,
  FinancialData,
  GlobalStandardPermission,
  GroupListModel,
  HeaderModel,
  InvoiceDetailsModel,
  MainList,
  ProductDetailsModel,
  RefundListModel,
  SideListModel,
  UserStandardPermission,
  VatRateScheme,
  VATReportModel,
  ViewDetails,
} from '@app/core/Models';
import {
  AccountService,
  BankTransferService,
  BillService,
  BudgetingService,
  CISInvoiceService,
  CommonService,
  CreditNoteService,
  CustomAccountService,
  DebitNoteService,
  DirectorShareholderService,
  DividendsService,
  FixedAssetsService,
  InvoiceService,
  JournalsService,
  MinutesOfMeetingsService,
  PaymentService,
  ProductService,
  QuickEntryService,
  QuotationService,
  RecurringModuleService,
  ScheduleService,
} from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';
import {
  AdvanceAllotmentAccounts,
  AdvanceAllotmentAccountsByBranch,
  CheckCustomerSupplierDuplicate,
  CheckEntryNumber,
  ConvertToInvoice,
  Copy,
  CreateCustomReport,
  CustomEmail,
  CustomReportExport,
  DeleteAccountingPeriod,
  DeleteBankImport,
  DeleteBankImportTransactionHistory,
  DeleteBranch,
  DeleteCustomReport,
  DeleteDepartment,
  EditReceipt,
  ExplainAllotmentAccounts,
  ExplainAllotmentAccountsByBranch,
  Export,
  ExportReceipt,
  ExportSubcontractorVerification,
  FileDowload,
  FileUpload,
  GetAccountBranchId,
  GetAccountListByNature,
  GetAccountsBasedOnGroupId,
  GetAccountsBasedOnGroupIdAndTypeId,
  GetAccountTransactionList,
  GetAdvancePaymentList,
  GetAllocationData,
  GetBankAccountTransactionData,
  GetCashAccountList,
  GetChartOfAccountsBasedOnGroupId,
  GetChartOfAccountsBasedOnGroupIdAndTypeId,
  GetClientTypes,
  GetComparitiveReport,
  GetComparitiveReportExport,
  GetCreditorDebtorReport,
  GetCreditorDebtorTransaction,
  GetCurrencyList,
  GetCustomBankAccountList,
  GetCustomBankAndCashAccount,
  GetCustomizeReportList,
  GetCustomReportQuery,
  GetDefaultCurrency,
  GetDetailHeaderList,
  GetDetailList,
  GetDueInvoicesReceipt,
  GetFileAsByte,
  GetFinancialPeriod,
  GetGroupAccountList,
  GetGroupAccountsBasedOnGroupId,
  GetGroupAccountsBasedOnGroupIdAndTypeId,
  GetGroupCustomBankAndCashAccount,
  GetGroupCustomBankAndCashAccountByBranch,
  GetGroupList,
  GetHeaderList,
  GetIsVatRegistered,
  GetMainList,
  GetModuleDetailView,
  GetNominalLedgerReport,
  GetNominalLedgerTransaction,
  GetNonStandardAccountList,
  GetNonStandardAccountListByBranch,
  GetPermissions,
  GetProductData,
  GetProductUnit,
  GetRefundData,
  GetReportList,
  GetSideList,
  GetStandardAccountList,
  GetTitleList,
  GetTransactionLogData,
  GetUnExplainedBankTransactions,
  GetUserBusinessPermission,
  GetVatRateList,
  GetVatReportDetailList,
  GetVatReportList,
  GetVatViewData,
  ReportDetailView,
  ReportExport,
  SaveAllocation,
  SaveBusinessPermission,
  SaveColumnsUserLevel,
  SaveReconciliation,
  SaveUserPermission,
  SendEmail,
  UnAllocateAmount,
  UpdateThemeColor,
} from './common.action';

export class CommonStateInfo {
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isCommonAdded?: boolean;
  isLastPage?: boolean;
  fileUploadRequestModel: Array<FileUploadRequestModel>;
  mainList?: MainList;
  isLoading?: boolean;
  currency: Array<Currency>;
  defaultCurrency: Array<DefaultCurrency>;
  totalRecord?: number;
  isCopied: boolean;
  accountList: Array<SideListModel>;
  chartOfAccountList: Array<SideListModel>;
  vatRate: Array<VatRateScheme>;
  productunit: Array<SideListModel>;
  productDetails?: ProductDetailsModel;
  isExport: boolean;
  headerList?: Array<HeaderModel>;
  actions?: any;
  emailData?: any;
  moduleViewDetails?: ViewDetails;
  detailView?: AccountDetailViewModel;
  periodicDate: Array<FinancialData>;
  vatReportList?: VATReportModel;
  vatReportDetailList?: any;
  accountGroupList: Array<GroupListModel>;
  customBankAndCashAccount: Array<SideListModel>;
  customBankAccount: Array<SideListModel>;
  expandedCollapseDetailList?: any;
  vatObligations?: any;
  isValidCustomerSupplier?: boolean;
  isValidEntryNo?: boolean;
  primaryColor: string;
  isMailSent: boolean;
  transactionLogData?: any;
  customBankAndCashAccountGroupList?: Array<GroupListModel>;
  isBankImportTransactionHistoryDeleted: boolean;
  isBankImportDeleted: boolean;
  advancePayment?: any;
  dueInvoicesReceipt?: any;
  isEdit: boolean;
  allocationData?: any;
  refundData?: Array<RefundListModel>;
  isAllocationAdded?: boolean;
  isReconciliationAdded?: boolean;
  unExplainedBankTransactions: any;
  customReportId?: Guid;
  isCustomReportAdded?: boolean;
  customReportQuery?: any;
  customReport: Array<any>;
  branchId?: Guid;
  invoiceDetails: Array<InvoiceDetailsModel>;
  allotmentAccountsList: Array<any>;
  standardPermission?: Array<GlobalStandardPermission>;
  isBusinessPermissionUpdated?: boolean;
  clientTypes?: any;
  isVatRegistered: boolean;
  title: Array<any>;
  fileByteUrl?: string;
  userPermission?: Array<UserStandardPermission>;
  isUserPermissionUpdated?: boolean;
  inventoryData: Array<any>;
}

@State<CommonStateInfo>({
  name: 'common',
  defaults: {
    exported: false,
    sideListModel: [],
    currency: [],
    defaultCurrency: [],
    isCommonAdded: false,
    isCopied: false,
    fileUploadRequestModel: [],
    accountList: [],
    vatRate: [],
    productunit: [],
    chartOfAccountList: [],
    isExport: false,
    periodicDate: [],
    accountGroupList: [],
    customBankAndCashAccount: [],
    customBankAccount: [],
    primaryColor: '',
    isMailSent: false,
    isBankImportTransactionHistoryDeleted: false,
    isBankImportDeleted: false,
    isEdit: false,
    unExplainedBankTransactions: [],
    isCustomReportAdded: false,
    customReportId: Guid.EMPTY as unknown as Guid,
    customReport: [],
    invoiceDetails: [],
    allotmentAccountsList: [],
    isVatRegistered: false,
    title: [],
    inventoryData: [],
  },
})
@Injectable()
export class CommonState {
  commonService: CommonService;
  quotationService: QuotationService;
  invoiceService: InvoiceService;
  accountService: AccountService;
  customAccountService: CustomAccountService;
  creditNoteService: CreditNoteService;
  billService: BillService;
  cisInvoiceService: CISInvoiceService;
  debitNoteService: DebitNoteService;
  fixedAssetsService: FixedAssetsService;
  productService: ProductService;
  paymentService: PaymentService;
  journalsService: JournalsService;
  recurringService: RecurringModuleService;
  minutesOfMeetingsService: MinutesOfMeetingsService;
  scheduleService: ScheduleService;
  dividendsService: DividendsService;
  ngxSpinnerService: NgxSpinnerService;
  shareholderService: DirectorShareholderService;
  budgetingService: BudgetingService;
  bankTransferService: BankTransferService;
  quickEntryService: QuickEntryService;

  constructor(private injector: Injector) {
    this.commonService = injector.get<CommonService>(CommonService);
    this.quotationService = injector.get<QuotationService>(QuotationService);
    this.invoiceService = injector.get<InvoiceService>(InvoiceService);
    this.quotationService = injector.get<QuotationService>(QuotationService);
    this.accountService = injector.get<AccountService>(AccountService);
    this.customAccountService =
      injector.get<CustomAccountService>(CustomAccountService);
    this.creditNoteService = injector.get<CreditNoteService>(CreditNoteService);
    this.billService = injector.get<BillService>(BillService);
    this.cisInvoiceService = injector.get<CISInvoiceService>(CISInvoiceService);
    this.debitNoteService = injector.get<DebitNoteService>(DebitNoteService);
    this.fixedAssetsService =
      injector.get<FixedAssetsService>(FixedAssetsService);
    this.paymentService = injector.get<PaymentService>(PaymentService);
    this.journalsService = injector.get<JournalsService>(JournalsService);
    this.productService = injector.get<ProductService>(ProductService);
    this.minutesOfMeetingsService = injector.get<MinutesOfMeetingsService>(
      MinutesOfMeetingsService
    );
    this.scheduleService = injector.get<ScheduleService>(ScheduleService);
    this.recurringService = injector.get<RecurringModuleService>(
      RecurringModuleService
    );
    this.ngxSpinnerService = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.shareholderService = injector.get<DirectorShareholderService>(
      DirectorShareholderService
    );
    this.dividendsService = injector.get<DividendsService>(DividendsService);
    this.budgetingService = injector.get<BudgetingService>(BudgetingService);
    this.bankTransferService =
      injector.get<BankTransferService>(BankTransferService);
    this.quickEntryService = injector.get<QuickEntryService>(QuickEntryService);
  }

  @Selector()
  static getTitle(state: CommonStateInfo) {
    return state.title;
  }

  @Selector()
  static getPermissions(state: CommonStateInfo) {
    return state.standardPermission;
  }

  @Selector()
  static getSideList(state: CommonStateInfo) {
    return state.sideListModel;
  }

  @Selector()
  static isLoading(state: CommonStateInfo): boolean {
    return state.isLoading ?? false;
  }

  @Selector()
  static totalRecord(state: CommonStateInfo): number {
    return state.totalRecord ?? 0;
  }

  @Selector()
  static reportActions(state: CommonStateInfo): any {
    if (!state.mainList) {
      return [];
    }

    return state.actions;
  }

  @Selector()
  static isLastPage(state: CommonStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static accountList(state: CommonStateInfo): Array<SideListModel> {
    return state.accountList;
  }

  @Selector()
  static allotmentAccountsList(state: CommonStateInfo): Array<any> {
    return state.allotmentAccountsList;
  }

  @Selector()
  static accountGroupList(state: CommonStateInfo): Array<GroupListModel> {
    return state.accountGroupList;
  }

  @Selector()
  static getCustomBankAccount(state: CommonStateInfo) {
    return state.customBankAccount;
  }

  @Selector()
  static columns(state: CommonStateInfo): Array<{
    name: string;
    id: Guid;
    dataType?: DataType;
    allowSortBy: boolean;
  }> {
    if (!state.mainList) {
      return [];
    }

    let columns;
    columns = state.mainList.resultSet.columns.filter(
      (i) => i.name !== 'ModuleId'
    );

    return columns;
  }

  @Selector()
  static expandedCollapseColumns(state: CommonStateInfo): Array<{
    name: string;
    id: Guid;
    dataType?: DataType;
    allowSortBy: boolean;
  }> {
    if (!state.expandedCollapseDetailList) {
      return [];
    }
    return state.expandedCollapseDetailList.resultSet.columns;
  }

  @Selector()
  static vatColumns(state: CommonStateInfo): Array<{
    name: string;
    id: Guid;
    dataType?: DataType;
    allowSortBy: boolean;
  }> {
    if (!state.vatReportDetailList) {
      return [];
    }
    return state.vatReportDetailList.resultSet.columns;
  }

  @Selector()
  static columnNames(state: CommonStateInfo): Array<string> {
    if (!state.mainList) {
      return [];
    }

    return state.mainList.resultSet.columns.map((x) => x.name);
  }

  @Selector()
  static vatColumnNames(state: CommonStateInfo): Array<string> {
    if (!state.vatReportDetailList) {
      return [];
    }

    return state.vatReportDetailList.resultSet.columns.map((x) => x.name);
  }

  @Selector()
  static expandedCollapseColumnNames(state: CommonStateInfo): Array<string> {
    if (!state.expandedCollapseDetailList) {
      return [];
    }

    return state.expandedCollapseDetailList.resultSet.columns.map(
      (x) => x.name
    );
  }

  @Selector()
  static headerList(state: CommonStateInfo): Array<HeaderModel> {
    if (!state.headerList) {
      return [];
    }

    return state.headerList;
  }

  @Selector()
  static actions(state: CommonStateInfo): any {
    if (!state.mainList) {
      return [];
    }

    return state.actions;
  }

  @Selector()
  static getCurrency(state: CommonStateInfo) {
    return state.currency;
  }

  @Selector()
  static getVatRate(state: CommonStateInfo) {
    return state.vatRate;
  }

  @Selector()
  static getProductUnit(state: CommonStateInfo) {
    return state.productunit;
  }

  @Selector()
  static defaultCurrency(state: CommonStateInfo) {
    return state.defaultCurrency;
  }

  @Selector()
  static periodicDate(state: CommonStateInfo) {
    return state.periodicDate;
  }

  @Action(SaveBusinessPermission)
  saveBusinessPermission(
    { patchState }: StateContext<CommonStateInfo>,
    action: SaveBusinessPermission
  ) {
    return this.commonService
      .saveBusinessPermission(action.standardPermission, action.userId)
      .pipe(
        tap((res) => {
          patchState({
            isBusinessPermissionUpdated: res,
          });
        })
      );
  }

  @Action(GetPermissions, { cancelUncompleted: true })
  getPermissions(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetPermissions
  ) {
    return this.commonService.getPermissions(action.searchText).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          standardPermission: res,
        });
      })
    );
  }

  @Selector()
  static getClientTypes(state: CommonStateInfo) {
    return state.clientTypes ?? [];
  }

  @Selector()
  static getUserBusinessPermission(state: CommonStateInfo) {
    return state.userPermission;
  }

  @Action(GetUserBusinessPermission, { cancelUncompleted: true })
  getUserBusinessPermission(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetUserBusinessPermission
  ) {
    return this.commonService.getUserBusinessPermission(action.userId).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          userPermission: res,
        });
      })
    );
  }

  @Action(SaveUserPermission)
  saveUserPermission(
    { patchState }: StateContext<CommonStateInfo>,
    action: SaveUserPermission
  ) {
    return this.commonService
      .saveUserPermission(action.standardPermission, action.userId)
      .pipe(
        tap((res) => {
          patchState({
            isUserPermissionUpdated: res,
          });
        })
      );
  }

  //region transaction

  @Action(GetVatRateList)
  getVatRateList({ getState, setState }: StateContext<CommonStateInfo>) {
    return this.commonService.getVatRateList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          vatRate: res,
        });
      })
    );
  }

  @Action(GetChartOfAccountsBasedOnGroupId)
  getChartOfAccountsBasedOnGroupId(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetChartOfAccountsBasedOnGroupId
  ) {
    return this.commonService
      .getChartOfAccountsBasedOnGroupId(action.groupIds)
      .pipe(
        tap((res) => {
          patchState({
            chartOfAccountList: res,
          });
        })
      );
  }

  @Action(GetAccountListByNature)
  getAccountListByNature(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetAccountListByNature
  ) {
    return this.commonService.getAccountListByNature(action.natures).pipe(
      tap((res) => {
        patchState({
          chartOfAccountList: res,
        });
      })
    );
  }

  @Action(GetChartOfAccountsBasedOnGroupIdAndTypeId)
  getChartOfAccountsBasedOnGroupIdAndTypeId(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetChartOfAccountsBasedOnGroupIdAndTypeId
  ) {
    return this.commonService
      .getChartOfAccountsBasedOnGroupIdAndTypeId(action.param)
      .pipe(
        tap((res) => {
          patchState({
            chartOfAccountList: res,
          });
        })
      );
  }

  //#endregion

  @Action(GetMainList, { cancelUncompleted: true })
  getMainList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetMainList
  ) {
    return this.commonService
      .getMainList(action.queryParams, action.moduleId)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              mainList: result,
              totalRecord: result.paginationModel.totalItemCount,
              actions: result.actions,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetDetailList, { cancelUncompleted: true })
  getDetailList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetDetailList
  ) {
    return this.commonService
      .getDetailList(action.queryParams, action.dataParam)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              mainList: result,
              totalRecord: result.paginationModel.totalItemCount,
              actions: result.actions,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetVatViewData, { cancelUncompleted: true })
  getVatViewData(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetVatViewData
  ) {
    return this.commonService
      .getVatViewData(action.queryParams, action.detailListId)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              vatObligations: result,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetNominalLedgerReport, { cancelUncompleted: true })
  getNominalLedgerReport(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetNominalLedgerReport
  ) {
    return this.commonService.getNominalLedgerReport(action.queryParams).pipe(
      tap({
        next: (result) => {
          patchState({
            mainList: result,
            totalRecord: result.paginationModel.totalItemCount,
            actions: result.actions,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(GetAccountTransactionList, { cancelUncompleted: true })
  getAccountTransactionList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetAccountTransactionList
  ) {
    return this.commonService
      .getAccountTransactionList(action.queryParams, action.id)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              mainList: result,
              totalRecord: result.paginationModel.totalItemCount,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetCustomizeReportList, { cancelUncompleted: true })
  getCustomizeReportList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetCustomizeReportList
  ) {
    return this.commonService.getCustomizeReportList(action.queryParams).pipe(
      tap({
        next: (result) => {
          patchState({
            mainList: result,
            totalRecord: result.paginationModel.totalItemCount,
            actions: result.actions,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(GetReportList, { cancelUncompleted: true })
  getReportList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetReportList
  ) {
    return this.commonService
      .getReportList(action.queryParams, action.moduleId)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              mainList: result,
              totalRecord: result.paginationModel.totalItemCount,
              actions: result.actions,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetComparitiveReport, { cancelUncompleted: true })
  getComparitiveReport(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetComparitiveReport
  ) {
    return this.commonService.getComparitiveReport(action.queryParams).pipe(
      tap({
        next: (result) => {
          patchState({
            mainList: result,
            totalRecord: result.paginationModel.totalItemCount,
            actions: result.actions,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(GetComparitiveReportExport, { cancelUncompleted: true })
  getComparitiveReportExport(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetComparitiveReportExport
  ) {
    let isExport = false;
    return this.commonService
      .getComparitiveReportExport(action.queryParams)
      .pipe(
        tap((res) => {
          isExport = true;
          patchState({
            isExport,
          });
        })
      );
  }

  @Action(GetVatReportDetailList, { cancelUncompleted: true })
  getVatReportDetailList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetVatReportDetailList
  ) {
    return this.commonService
      .getVatReportDetailList(action.vatId, action.queryParams)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              vatReportDetailList: result,
              totalRecord: result.paginationModel.totalItemCount,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetNominalLedgerTransaction, { cancelUncompleted: true })
  getNominalLedgerTransaction(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetNominalLedgerTransaction
  ) {
    return this.commonService
      .getNominalLedgerTransaction(action.queryParams)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              expandedCollapseDetailList: result,
              totalRecord: result.paginationModel.totalItemCount,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetVatReportList, { cancelUncompleted: true })
  getVatReportList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetVatReportList
  ) {
    return this.commonService.getVatReportList(action.queryParams).pipe(
      tap({
        next: (result) => {
          patchState({
            vatReportList: result,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(GetHeaderList, { cancelUncompleted: true })
  getHeaderList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetHeaderList
  ) {
    return this.commonService.getHeaderList(action.moduleId).pipe(
      tap({
        next: (result) => {
          result.forEach((element) => {
            if (element.groupValue.length > 0) {
              element.groupValue = this.addGroupNameToListModels(
                element.groupValue
              );
            }
          });
          patchState({
            headerList: result,
          });
        },
      })
    );
  }

  addGroupNameToListModels(data: any[]): any[] {
    let result: any = [];

    data.forEach((group) => {
      group.listModels.forEach((listModel) => {
        let listModelWithGroup = {
          ...listModel,
          groupName: group.groupName,
        } as any;
        result.push(listModelWithGroup);
      });
    });

    return result;
  }

  @Action(GetDetailHeaderList, { cancelUncompleted: true })
  getDetailHeaderList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetDetailHeaderList
  ) {
    return this.commonService
      .getDetailHeaderList(action.mtdId, action.detailListModulesId)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              headerList: result,
            });
          },
        })
      );
  }

  @Action(Export, { cancelUncompleted: true })
  export({ patchState }: StateContext<CommonStateInfo>, action: GetMainList) {
    let isExport = false;
    return this.commonService.export(action.queryParams, action.moduleId).pipe(
      tap((res) => {
        isExport = true;
        patchState({
          isExport,
        });
      })
    );
  }

  @Action(ReportExport, { cancelUncompleted: true })
  reportExport(
    { patchState }: StateContext<CommonStateInfo>,
    action: ReportExport
  ) {
    let isExport = false;
    return this.commonService.reportExport(action.queryParams, action.id).pipe(
      tap((res) => {
        isExport = true;
        patchState({
          isExport,
        });
      })
    );
  }

  @Action(ExportSubcontractorVerification, { cancelUncompleted: true })
  exportSubcontractorVerification(
    { patchState }: StateContext<CommonStateInfo>,
    action: ExportSubcontractorVerification
  ) {
    let isExport = false;
    return this.commonService.exportSubcontractorVerification(action.id).pipe(
      tap((res) => {
        isExport = true;
        patchState({
          isExport,
        });
      })
    );
  }

  @Action(ReportDetailView, { cancelUncompleted: true })
  reportDetailView(
    { patchState }: StateContext<CommonStateInfo>,
    action: ReportDetailView
  ) {
    return this.commonService.reportDetailView(action.id).pipe(
      tap((res) => {
        patchState({
          detailView: res,
        });
      })
    );
  }

  @Action(ExportReceipt, { cancelUncompleted: true })
  exportReceipt(
    { patchState }: StateContext<CommonStateInfo>,
    action: ExportReceipt
  ) {
    let isExport = false;
    return this.commonService
      .exportReceipt(action.ids, action.moduleId, action.isPrint)
      .pipe(
        tap((res) => {
          isExport = true;
          patchState({
            isExport,
          });
        })
      );
  }

  @Action(EditReceipt, { cancelUncompleted: true })
  editReceipt(
    { patchState }: StateContext<CommonStateInfo>,
    action: EditReceipt
  ) {
    let isEdit = false;
    return this.commonService.editReceipt(action.ids, action.moduleId).pipe(
      tap((res) => {
        patchState({
          isEdit: res,
        });
      })
    );
  }

  @Action(GetSideList, { cancelUncompleted: true })
  getSideList(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetSideList
  ) {
    return this.commonService
      .getSideList(action.queryParams, action.moduleId)
      .pipe(
        tap((res) => {
          const state = getState();
          let common: SideListModel[] | null = [];
          const headers = JSON.parse(res.headers.get('Pagination')!);
          const isLastPage = headers.IsLastPage;
          if (
            action.queryParams.pageNumber &&
            action.queryParams.pageNumber > 1
          ) {
            common = state.sideListModel;
            common = common.concat(res.body!);
          } else {
            common = res.body;
          }

          setState({
            ...state,
            sideListModel: common!,
            isLastPage: isLastPage,
          });
        })
      );
  }

  @Action(FileUpload)
  fileUpload(
    { patchState }: StateContext<CommonStateInfo>,
    action: FileUpload
  ) {
    return this.commonService.fileUpload(action.fileUploadRequestModel).pipe(
      tap((res) => {
        patchState({
          fileUploadRequestModel: res,
        });
      })
    );
  }

  @Action(GetCurrencyList)
  getCurrencyList({ patchState }: StateContext<CommonStateInfo>) {
    return this.commonService.getCurrencyList().pipe(
      tap((res) => {
        patchState({
          currency: res,
        });
      })
    );
  }

  @Action(GetProductData)
  getProductData(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetProductData
  ) {
    return this.commonService.getProductData(action.productId).pipe(
      tap((res) => {
        patchState({
          productDetails: res,
        });
      })
    );
  }

  @Action(Copy)
  copy({ patchState }: StateContext<CommonStateInfo>, action: Copy) {
    let isCopied = false;

    switch (action.moduleId) {
      case Modules.Customers:
      case Modules.Suppliers:
      case Modules.Subcontractor:
        return this.accountService.copyAccount(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Journals:
        return this.journalsService.copyJournals(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Quotation:
        return this.quotationService.copyQuotation(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Invoices:
        return this.invoiceService.copyInvoice(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Purchase:
        return this.billService.copyBill(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.DebitNote:
        return this.debitNoteService.copyDebitNote(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.CreditNote:
        return this.creditNoteService.copyCreditNote(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.RecurringPurchase:
      case Modules.RecurringInvoice:
        return this.recurringService.copyRecurringData(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.AddCustomAccounts:
        return this.customAccountService.copyCustomAccount(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.FixedAssets:
        return this.fixedAssetsService.copyFixedAssets(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Product:
        return this.productService.copyProduct(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.MinutesOfMeeting:
        return this.minutesOfMeetingsService
          .copyMinutesOfMeeting(action.ids)
          .pipe(
            tap((res) => {
              isCopied = true;
              patchState({
                isCopied,
              });
            })
          );

      case Modules.Notes:
        return this.scheduleService.copyScheduleNote(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Director:
      case Modules.Shareholder:
        return this.shareholderService
          .copyDirectorShareholderData(action.ids)
          .pipe(
            tap((res) => {
              isCopied = true;
              patchState({
                isCopied,
              });
            })
          );

      case Modules.Dividends:
        return this.dividendsService.copyDividends(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Budgeting:
        return this.budgetingService.copyBudget(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.CISInvoice:
        return this.cisInvoiceService.copyCISInvoice(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.CustomizeReport:
        return this.commonService.copyCustomReport(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.BankTransfer:
        return this.bankTransferService.copyBankTransfer(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.QuickEntry:
        return this.quickEntryService.copyQuickEntry(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );
    }
    this.ngxSpinnerService.hide();
    return isCopied;
  }

  @Action(FileDowload)
  downloadFile(
    { patchState }: StateContext<CommonStateInfo>,
    action: FileDowload
  ) {
    return this.commonService.downloadFile(action.fileData);
  }

  @Action(GetProductUnit)
  getProductUnit({ patchState }: StateContext<CommonStateInfo>) {
    return this.commonService.getProductUnit().pipe(
      tap((res) => {
        patchState({
          productunit: res,
        });
      })
    );
  }
  //#endregion

  @Action(GetDefaultCurrency)
  getDefaultCurrency({ patchState }: StateContext<CommonStateInfo>) {
    return this.commonService.getDefaultCurrency().pipe(
      tap((res) => {
        patchState({
          defaultCurrency: res,
        });
      })
    );
  }

  @Action(GetFinancialPeriod)
  getFinancialPeriod(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetFinancialPeriod
  ) {
    return this.commonService.getFinancialPeriod().pipe(
      tap((res) => {
        patchState({
          periodicDate: res,
        });
      })
    );
  }

  @Action(GetModuleDetailView)
  getModuleDetailView(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetModuleDetailView
  ) {
    return this.commonService.getModuleDetailView(action.param).pipe(
      tap((res) => {
        patchState({
          moduleViewDetails: res,
        });
      })
    );
  }

  @Action(GetStandardAccountList)
  getStandardAccountList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetStandardAccountList
  ) {
    return this.commonService.getStandardAccountList(action.entityId).pipe(
      tap((res) => {
        patchState({
          accountList: res,
        });
      })
    );
  }

  @Action(ExplainAllotmentAccounts)
  explainAllotmentAccounts(
    { patchState }: StateContext<CommonStateInfo>,
    action: AdvanceAllotmentAccounts
  ) {
    return this.commonService.explainAllotmentAccounts(action.entityId).pipe(
      tap((res) => {
        patchState({
          allotmentAccountsList: res,
        });
      })
    );
  }

  @Action(ExplainAllotmentAccountsByBranch)
  explainAllotmentAccountsByBranch(
    { patchState }: StateContext<CommonStateInfo>,
    action: ExplainAllotmentAccountsByBranch
  ) {
    return this.commonService
      .explainAllotmentAccountsByBranch(action.entityId, action.branchId)
      .pipe(
        tap((res) => {
          patchState({
            allotmentAccountsList: res,
          });
        })
      );
  }

  @Action(AdvanceAllotmentAccounts)
  advanceAllotmentAccounts(
    { patchState }: StateContext<CommonStateInfo>,
    action: AdvanceAllotmentAccounts
  ) {
    return this.commonService.advanceAllotmentAccounts(action.entityId).pipe(
      tap((res) => {
        patchState({
          allotmentAccountsList: res,
        });
      })
    );
  }

  @Action(AdvanceAllotmentAccountsByBranch)
  advanceAllotmentAccountsByBranch(
    { patchState }: StateContext<CommonStateInfo>,
    action: AdvanceAllotmentAccountsByBranch
  ) {
    return this.commonService
      .advanceAllotmentAccountsByBranch(action.entityId, action.branchId)
      .pipe(
        tap((res) => {
          patchState({
            allotmentAccountsList: res,
          });
        })
      );
  }

  @Action(GetNonStandardAccountList)
  getNonStandardAccountList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetNonStandardAccountList
  ) {
    return this.commonService.getNonStandardAccountList(action.entityId).pipe(
      tap((res) => {
        patchState({
          accountList: res,
        });
      })
    );
  }

  @Action(GetNonStandardAccountListByBranch)
  getNonStandardAccountListByBranch(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetNonStandardAccountListByBranch
  ) {
    return this.commonService
      .getNonStandardAccountListByBranch(action.branchId, action.entityId)
      .pipe(
        tap((res) => {
          patchState({
            accountList: res,
          });
        })
      );
  }

  @Action(GetCustomBankAccountList)
  getCustomBankAccountList({
    getState,
    setState,
  }: StateContext<CommonStateInfo>) {
    return this.commonService.getCustomBankAccountList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          customBankAccount: res,
        });
      })
    );
  }

  @Action(GetGroupList)
  getGroupList({ patchState }: StateContext<CommonStateInfo>) {
    return this.commonService.getGroupList().pipe(
      tap((res) => {
        patchState({
          accountGroupList: res,
        });
      })
    );
  }

  @Action(GetGroupAccountsBasedOnGroupId)
  getGroupAccountsBasedOnGroupId(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetGroupAccountsBasedOnGroupId
  ) {
    return this.commonService
      .getGroupAccountsBasedOnGroupId(action.groupIds, action.moduleId)
      .pipe(
        tap((res) => {
          patchState({
            accountGroupList: res,
          });
        })
      );
  }

  @Action(GetAccountsBasedOnGroupId)
  getAccountsBasedOnGroupId(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetAccountsBasedOnGroupId
  ) {
    return this.commonService.getAccountsBasedOnGroupId(action.groupIds).pipe(
      tap((res) => {
        patchState({
          accountList: res,
        });
      })
    );
  }

  @Action(GetGroupAccountsBasedOnGroupIdAndTypeId)
  getGroupAccountsBasedOnGroupIdAndTypeId(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetGroupAccountsBasedOnGroupIdAndTypeId
  ) {
    return this.commonService
      .getGroupAccountsBasedOnGroupIdAndTypeId(action.param)
      .pipe(
        tap((res) => {
          patchState({
            accountGroupList: res,
          });
        })
      );
  }

  @Action(GetAccountsBasedOnGroupIdAndTypeId)
  getAccountsBasedOnGroupIdAndTypeId(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetAccountsBasedOnGroupIdAndTypeId
  ) {
    return this.commonService
      .getAccountsBasedOnGroupIdAndTypeId(action.param)
      .pipe(
        tap((res) => {
          patchState({
            accountList: res,
          });
        })
      );
  }

  @Action(GetGroupAccountList)
  getGroupAccountList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetGroupAccountList
  ) {
    return this.commonService.getGroupAccountList(action.entityId).pipe(
      tap((res) => {
        patchState({
          accountGroupList: res,
        });
      })
    );
  }

  @Action(GetCustomBankAndCashAccount)
  getCustomBankAndCashAccount(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetCustomBankAndCashAccount
  ) {
    return this.commonService.getCustomBankAndCashAccount().pipe(
      tap((res) => {
        patchState({
          customBankAndCashAccount: res,
        });
      })
    );
  }

  @Action(GetCreditorDebtorReport, { cancelUncompleted: true })
  getCreditorDebtorReport(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetCreditorDebtorReport
  ) {
    return this.commonService
      .getCreditorDebtorReport(action.queryParams, action.moduleId)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              mainList: result,
              totalRecord: result.paginationModel.totalItemCount,
              actions: result.actions,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(GetCreditorDebtorTransaction, { cancelUncompleted: true })
  getCreditorDebtorTransaction(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetCreditorDebtorTransaction
  ) {
    return this.commonService
      .getCreditorDebtorTransaction(
        action.queryParams,
        action.moduleId,
        action.id
      )
      .pipe(
        tap({
          next: (result) => {
            patchState({
              expandedCollapseDetailList: result,
              totalRecord: result.paginationModel.totalItemCount,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(CheckCustomerSupplierDuplicate)
  checkCustomerSupplierDuplicate(
    { patchState }: StateContext<CommonStateInfo>,
    action: CheckCustomerSupplierDuplicate
  ) {
    return this.commonService
      .checkCustomerSupplierDuplicate(
        action.name,
        action.email,
        action.moduleId
      )
      .pipe(
        tap((res) => {
          patchState({
            isValidCustomerSupplier: res,
          });
        })
      );
  }

  @Action(CheckEntryNumber)
  checkEntryNumber(
    { patchState }: StateContext<CommonStateInfo>,
    action: CheckEntryNumber
  ) {
    return this.commonService
      .checkEntryNumber(action.entryNumber, action.moduleId)
      .pipe(
        tap((res) => {
          patchState({
            isValidEntryNo: res,
          });
        })
      );
  }

  @Action(CustomEmail)
  customEmail(
    { patchState }: StateContext<CommonStateInfo>,
    action: CustomEmail
  ) {
    return this.commonService
      .customEmail(action.moduleId, action.emailData)
      .pipe(
        tap((res) => {
          patchState({
            emailData: res,
          });
        })
      );
  }

  @Action(UpdateThemeColor)
  updateThemeColor(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: UpdateThemeColor
  ) {
    return this.commonService.updateThemeColor(action.colorCode).pipe(
      tap((res) => {
        patchState({
          primaryColor: res,
        });
      })
    );
  }

  @Action(SendEmail, { cancelUncompleted: true })
  sendEmail({ patchState }: StateContext<CommonStateInfo>, action: SendEmail) {
    return this.commonService
      .sendEmail(action.id, action.moduleId, action.paymentLink)
      .pipe(
        tap((res) => {
          patchState({
            isMailSent: res,
          });
        })
      );
  }

  @Action(GetCashAccountList)
  getCashAccountList({ getState, setState }: StateContext<CommonStateInfo>) {
    return this.commonService.getCashAccountList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          accountList: res,
        });
      })
    );
  }

  @Action(GetTransactionLogData, { cancelUncompleted: true })
  getTransactionLogData(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetTransactionLogData
  ) {
    return this.commonService.getTransactionLogData(action.id).pipe(
      tap({
        next: (result) => {
          patchState({
            transactionLogData: result,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(GetGroupCustomBankAndCashAccount)
  getGroupCustomBankAndCashAccount(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetGroupCustomBankAndCashAccount
  ) {
    return this.commonService.getGroupCustomBankAndCashAccount().pipe(
      tap((res) => {
        patchState({
          customBankAndCashAccountGroupList: res,
        });
      })
    );
  }

  @Action(GetGroupCustomBankAndCashAccountByBranch)
  getGroupCustomBankAndCashAccountByBranch(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetGroupCustomBankAndCashAccountByBranch
  ) {
    return this.commonService
      .getGroupCustomBankAndCashAccountByBranch(action.branchId)
      .pipe(
        tap((res) => {
          patchState({
            customBankAndCashAccountGroupList: res,
          });
        })
      );
  }

  @Action(DeleteBankImportTransactionHistory)
  deleteBankImportTransactionHistory(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: DeleteBankImportTransactionHistory
  ) {
    return this.commonService
      .deleteBankImportTransactionHistory(action.ids)
      .pipe(
        tap((res) => {
          patchState({
            isBankImportTransactionHistoryDeleted: res,
          });
        })
      );
  }

  @Action(DeleteBankImport)
  deleteBankImport(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: DeleteBankImport
  ) {
    return this.commonService.deleteBankImport(action.ids).pipe(
      tap((res) => {
        patchState({
          isBankImportDeleted: res,
        });
      })
    );
  }

  @Action(SaveColumnsUserLevel)
  saveColumnsUserLevel(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: SaveColumnsUserLevel
  ) {
    return this.commonService.saveColumnsUserLevel(action.data).pipe(
      tap((res) => {
        patchState({
          isBankImportTransactionHistoryDeleted: res,
        });
      })
    );
  }

  @Action(GetAdvancePaymentList)
  getAdvancePaymentList(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetAdvancePaymentList
  ) {
    return this.commonService
      .getAdvancePaymentList(action.entityId, action.currencyId)
      .pipe(
        tap((res) => {
          patchState({
            advancePayment: res,
          });
        })
      );
  }

  @Action(GetDueInvoicesReceipt)
  getDueInvoicesReceipt(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetDueInvoicesReceipt
  ) {
    return this.commonService
      .getDueInvoicesReceipt(action.receiptId, action.currencyId)
      .pipe(
        tap((res) => {
          patchState({
            dueInvoicesReceipt: res,
          });
        })
      );
  }

  @Action(GetBankAccountTransactionData, { cancelUncompleted: true })
  getBankAccountTransactionData(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetTransactionLogData
  ) {
    return this.commonService.getBankAccountTransactionData(action.id).pipe(
      tap({
        next: (result) => {
          patchState({
            transactionLogData: result,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(GetAllocationData)
  getAllocationData(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetAllocationData
  ) {
    return this.commonService
      .getAllocationData(action.id, action.moduleId)
      .pipe(
        tap((res) => {
          patchState({
            allocationData: res,
          });
        })
      );
  }

  @Action(GetRefundData)
  getRefundData(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetRefundData
  ) {
    return this.commonService
      .getRefundData(action.refundInputParameters, action.moduleId)
      .pipe(
        tap((res) => {
          patchState({
            refundData: res,
          });
        })
      );
  }

  @Action(SaveAllocation)
  saveCreditNoteAllocation(
    { patchState }: StateContext<CommonStateInfo>,
    action: SaveAllocation
  ) {
    return this.commonService
      .saveAllocation(action.allocationData, action.moduleId)
      .pipe(
        tap((res) => {
          patchState({
            isAllocationAdded: true,
          });
        })
      );
  }

  @Action(SaveReconciliation)
  saveReconciliation(
    { patchState }: StateContext<CommonStateInfo>,
    action: SaveReconciliation
  ) {
    return this.commonService.saveReconciliation(action.reconcilationData).pipe(
      tap((res) => {
        patchState({
          isReconciliationAdded: true,
        });
      })
    );
  }

  @Action(GetUnExplainedBankTransactions, { cancelUncompleted: true })
  getUnExplainedBankTransactions(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetUnExplainedBankTransactions
  ) {
    return this.commonService
      .getUnExplainedBankTransactions(action.postingAccountId)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              unExplainedBankTransactions: result,
            });
          },
          error: () => {
            patchState({ isLoading: false });
          },
        })
      );
  }

  @Action(CreateCustomReport)
  createCustomReport(
    { patchState }: StateContext<CommonStateInfo>,
    action: CreateCustomReport
  ) {
    return this.commonService.createCustomReport(action.customReportQuery).pipe(
      tap((res) => {
        patchState({
          customReportId: res,
          isCustomReportAdded: true,
        });
      })
    );
  }

  @Action(GetCustomReportQuery, { cancelUncompleted: true })
  getCustomReportQuery(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetCustomReportQuery
  ) {
    return this.commonService.getCustomReportQuery(action.id).pipe(
      tap((res) => {
        patchState({
          customReportQuery: res,
        });
      })
    );
  }

  @Action(DeleteCustomReport)
  deleteCustomReport(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: DeleteCustomReport
  ) {
    return this.commonService.deleteCustomReport(action.ids).pipe(
      tap((res) => {
        const state = getState();

        const filteredCustomReport = state.customReport.filter(
          (item) =>
            !action.ids?.includes(item.id ?? (Guid.EMPTY as unknown as Guid))
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.ids?.includes(item.id ?? (Guid.EMPTY as unknown as Guid))
        );

        patchState({
          customReport: filteredCustomReport,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(CustomReportExport, { cancelUncompleted: true })
  customReportExport(
    { patchState }: StateContext<CommonStateInfo>,
    action: CustomReportExport
  ) {
    let isExport = false;
    return this.commonService.customReportExport(action.queryParams).pipe(
      tap((res) => {
        isExport = true;
        patchState({
          isExport,
        });
      })
    );
  }

  @Action(GetAccountBranchId)
  getAccountBranchId(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetAccountBranchId
  ) {
    return this.commonService.getAccountBranchId(action.id).pipe(
      tap((res) => {
        patchState({
          branchId: res,
        });
      })
    );
  }
  @Action(ConvertToInvoice)
  convertToInvoice(
    { patchState }: StateContext<CommonStateInfo>,
    action: ConvertToInvoice
  ) {
    return this.commonService.convertToInvoice(action.id).pipe(
      tap((res) => {
        patchState({
          invoiceDetails: res,
        });
      })
    );
  }

  @Action(GetClientTypes)
  getClientTypes(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetClientTypes
  ) {
    return this.commonService.getClientTypes().pipe(
      tap((res) => {
        patchState({
          clientTypes: res,
        });
      })
    );
  }

  @Action(GetIsVatRegistered)
  getIsVatRegistered(
    { patchState }: StateContext<CommonStateInfo>,
    action: GetIsVatRegistered
  ) {
    return this.commonService.getIsVatRegistered().pipe(
      tap((res) => {
        patchState({
          isVatRegistered: res,
        });
      })
    );
  }

  @Action(GetFileAsByte)
  getFileAsByte(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetFileAsByte
  ) {
    return this.commonService.getFileAsByte(action.fileUrl).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          fileByteUrl: res.data,
        });
      })
    );
  }

  @Action(GetTitleList)
  getTitleList(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetTitleList
  ) {
    return this.commonService.getTitleList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          title: res,
        });
      })
    );
  }

  @Action(DeleteBranch)
  deleteBranch(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: DeleteBranch
  ) {
    return this.commonService.deleteBranch(action.ids).pipe(tap((res) => {}));
  }

  @Action(DeleteDepartment)
  deleteDepartment(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: DeleteDepartment
  ) {
    return this.commonService
      .deleteDepartment(action.ids)
      .pipe(tap((res) => {}));
  }

  @Action(DeleteAccountingPeriod)
  deleteAccountingPeriod(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: DeleteAccountingPeriod
  ) {
    return this.commonService
      .deleteAccountingPeriod(action.ids)
      .pipe(tap((res) => {}));
  }
  @Action(UnAllocateAmount)
  setUnaallocationAmount(
    { patchState }: StateContext<CommonStateInfo>,
    action: UnAllocateAmount
  ) {
    return this.commonService.unallocateAmount(action.id, action.typeId).pipe();
  }
}
