import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Modules, NotificationTextMessage } from '@app/core/Enum';
import { GlobalComponent } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import {
  AllocateAdvanceReceipt,
  CurrencyState,
  GetClientCurrencyList,
  GetDataByReceiptId,
  GetDueInvoicesReceipt,
} from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-assign-receipt',
  templateUrl: './assign-receipt.component.html',
  styleUrls: ['./assign-receipt.component.scss'],
})
export class AssignReceiptComponent implements OnInit {
  displayAllocatedInvoiceColumns: string[] = [
    'date',
    'invoiceNo',
    'totalAmount',
    'allocated',
  ];

  displayAllocationRefundColumns: string[] = [
    'invoiceNo',
    'amount',
    'due',
    'amountToAllocate',
  ];

  receiptData: any;
  refundData: any = [];
  allocatedData: any = [];
  dueInvoicesReceipt: any;

  clientCurrencyList: any = [];

  selectedCurrency: any;
  totalAllocatedAmount: number = 0;

  dueInvoiceNoDataFound: boolean = true;
  id: Guid = Guid.EMPTY as unknown as Guid;

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalComponent: GlobalComponent,
    public dialogRef: MatDialogRef<AssignReceiptComponent>,
    public commonService: CommonService,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.id = this.data.id;
    this.getReceiptData();
    this.getCurrencyList().then(() => {
      this.getDueInvoiceRefund();
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  getDueInvoiceRefund(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetDueInvoicesReceipt(this.id, this.selectedCurrency))
      .pipe(
        tap((res) => {
          this.dueInvoicesReceipt = [];
          this.totalAllocatedAmount = 0;
          this.dueInvoicesReceipt = res.common.dueInvoicesReceipt;
          if (this.dueInvoicesReceipt.length > 0) {
            this.dueInvoiceNoDataFound = false;
          } else {
            this.dueInvoiceNoDataFound = true;
          }
          this.spinner.hide();
        })
      )
      .subscribe();
  }

  onAllocationAmountChange(element: any): void {
    if (+element.allocatedAmount > +element.dueAmount) {
      element.allocatedAmount = 0;
    } else {
      if (
        this.dueInvoicesReceipt
          .filter(
            (x) => x.allocatedAmount !== null && x.allocatedAmount !== undefined
          )
          .reduce((x, y) => +x + +y.allocatedAmount, 0) >
        this.receiptData.foreignDueAmount
      ) {
        element.allocatedAmount = 0;
      }
    }

    this.totalAllocatedAmount = this.dueInvoicesReceipt
      .filter(
        (x) => x.allocatedAmount !== null && x.allocatedAmount !== undefined
      )
      .reduce((x, y) => +x + +y.allocatedAmount, 0);
  }

  async getCurrencyList(): Promise<void> {
    this.spinner.show();
    await this.store
      .dispatch(new GetClientCurrencyList())
      .toPromise()
      .then(() => {
        this.spinner.hide();
        this.clientCurrencyList = this.store.selectSnapshot(
          CurrencyState.getCurrencyList
        );
        if (this.clientCurrencyList.length > 0) {
          this.selectedCurrency = this.clientCurrencyList[0].currencyId;
          this.selectedCurrency = this.clientCurrencyList[0].currencyId;
        }
      });
  }

  getReceiptData(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetDataByReceiptId(this.id, Modules.Receipt))
      .subscribe((res) => {
        this.spinner.hide();
        this.receiptData = res.receipt.receiptData;
        this.allocatedData = this.receiptData.receiptItems;
      });
  }

  onSaveClick(): void {
    if (
      this.dueInvoicesReceipt.filter(
        (x) => x.allocatedAmount !== null && x.allocatedAmount !== undefined
      ).length === 0 ||
      this.dueInvoicesReceipt
        .filter(
          (x) => x.allocatedAmount !== null && x.allocatedAmount !== undefined
        )
        .reduce((x, y) => +x + +y.allocatedAmount, 0) === 0
    ) {
      this.commonService.onFailure('Please allocate to proceed');
    } else if (
      this.dueInvoicesReceipt
        .filter(
          (x) => x.allocatedAmount !== null && x.allocatedAmount !== undefined
        )
        .reduce((x, y) => +x + +y.allocatedAmount, 0) >
      +this.receiptData.foreignDueAmount
    ) {
      this.commonService.onFailure(
        'Allocation cannot be greater than due amount!'
      );
    } else {
      let assignData: any = [];

      this.dueInvoicesReceipt
        .filter((x) => +x.allocatedAmount > 0)
        .forEach((x) =>
          assignData.push({
            id: x.id,
            amount: +x.allocatedAmount,
            invoiceTypeId: x.invoiceTypeId,
            note: x.note,
            currencyRate: this.clientCurrencyList.find(
              (x) => x.currencyId === this.selectedCurrency
            )?.rate,
            currencyId: this.selectedCurrency,
          })
        );

      this.spinner.show();
      this.store
        .dispatch(new AllocateAdvanceReceipt(this.id, assignData))
        .pipe()
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res !== undefined && res.receipt.advanceReceiptAllocated) {
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
              this.dialogRef.close(true);
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.error.Message);
          }
        );
    }
  }
}
