import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-hmrc-gateway-details',
  templateUrl: './hmrc-gateway-details.component.html',
  styleUrls: ['./hmrc-gateway-details.component.scss'],
})
export class HMRCGatewayDetailsComponent implements OnInit {
  hmrcGatewayDetailsForm: FormGroup;
  monthList: string[] = [];
  selectedYear: number;
  years: number[] = [];
  isChecked = true;
  isAgent = false;
  isActivity = false;
  informationCorrect = true;
  endDate: any;
  startDate: any;
  @Input() triggerHmrcCredentialsData: Observable<any>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.setForm();
    this.triggerHmrcCredentialsData?.subscribe((data) => {
      this.editHMRCCredentialsData(data);
    });
  }
  setForm(): void {
    this.hmrcGatewayDetailsForm = new FormGroup({
      userName: new FormControl(),
      password: new FormControl(),
      isAgent: new FormControl(),
    });
  }
  editHMRCCredentialsData(data: any): void {
    this.hmrcGatewayDetailsForm.patchValue({
      userName: data.userName,
      password: data.password,
      isAgent: data.isAgent,
    });
  }

  onCheckBoxChanges(event: any): void {
    if (event.checked) {
      this.isChecked = false;
    } else {
      this.isChecked = true;
    }
  }
}
