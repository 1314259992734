import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Modules } from '@app/core/Enum';
import { GlobalComponent } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { Observable } from 'rxjs';
export class YearDictionary {
  key: number;
  value: string;
}
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-custom-year-picker',
  templateUrl: './custom-year-picker.component.html',
  styleUrls: ['./custom-year-picker.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export default class CustomYearPickerComponent implements OnInit {
  selectedOption: any = '-1';
  selectedOptionId: any;
  currentYear: number = new Date().getFullYear();
  yearsListPair = this.globalComponent.getFinancialPeriod();
  rangeFormGroup: FormGroup;
  moduleEnum = Modules;

  @Input() moduleId: number;

  @Input() triggerResetDetailList: Observable<any>;

  @Output()
  triggerDateChange = new EventEmitter<any>();

  constructor(
    public datepipe: DatePipe,
    private formBuilder: FormBuilder,
    public commonService: CommonService,
    private globalComponent: GlobalComponent
  ) {}

  ngOnInit(): void {
    this.triggerResetDetailList?.subscribe((data) => {
      if (data) {
        this.setForm();
        this.selectedOption = '1';
        this.onCustomDate();
      }
    });
    if (
      this.moduleId === this.moduleEnum.IncomeAndExpenditure ||
      this.moduleId === this.moduleEnum.AccountDetails ||
      this.moduleId === this.moduleEnum.TrialBalance ||
      this.moduleId === this.moduleEnum.EditTrialBalance ||
      this.moduleId === this.moduleEnum.OpeningBalance ||
      this.moduleId === this.moduleEnum.ComparativeReport ||
      this.moduleId === this.moduleEnum.BalanceSheet ||
      this.moduleId === this.moduleEnum.BalanceSheetComparison ||
      this.moduleId === this.moduleEnum.VAT ||
      this.moduleId === this.moduleEnum.CashFlow ||
      this.moduleId === this.moduleEnum.ProfitAndLoss ||
      this.moduleId === this.moduleEnum.ProfitAndLossComparision ||
      this.moduleId === this.moduleEnum.ProfitAndLossSummary ||
      this.moduleId === this.moduleEnum.ProfitAndLossWithPercentage ||
      this.moduleId === this.moduleEnum.ProfitAndLossDetail ||
      this.moduleId === this.moduleEnum.BalanceSheetDetail
    ) {
      this.yearsListPair.forEach((value, i) => {
        if (
          localStorage.getItem('defaultFinancialPeriod') !== null &&
          localStorage.getItem('defaultFinancialPeriod') !== undefined &&
          localStorage.getItem('defaultFinancialPeriod') !== '-1'
        ) {
          if (value.name === localStorage.getItem('defaultFinancialPeriod')) {
            this.selectedOption = this.yearsListPair[i];
            const param: any = {
              value: this.yearsListPair[i],
            };
            this.onDropdownChange(param);
          }
        } else {
          this.selectedOption = this.yearsListPair[0];
          const param: any = {
            value: this.yearsListPair[0],
          };
          this.onDropdownChange(param);
        }
      });
    } else {
      this.yearsListPair.forEach((value, i) => {
        if (value.name === localStorage.getItem('defaultFinancialPeriod')) {
          this.selectedOption = this.yearsListPair[i];
          this.onLoadDateChange(this.yearsListPair[i].name);
        }
      });
    }
    this.setForm();
  }

  setForm(): void {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.rangeFormGroup = this.formBuilder.group({
      start: [startDate],
      end: [new Date()],
    });
  }

  onCustomDate(): void {
    const sendingData = {
      startDate: this.rangeFormGroup.controls.start.value,
      endDate: this.rangeFormGroup.controls.end.value,
      isCustomDate: true,
    };
    if (sendingData.endDate !== null && sendingData.startDate !== null) {
      this.triggerDateChange.emit(sendingData);
    }
  }

  onLoadDateChange(selectedOption: any): void {
    if (selectedOption === '1') {
      return;
    }

    const sendingData = {
      startDate:
        selectedOption !== '-1' ? this.getStartDate(selectedOption) : '',

      endDate: selectedOption !== '-1' ? this.getEndDate(selectedOption) : '',
      isCustomDate: false,
    };

    if (sendingData.endDate !== null && sendingData.startDate !== null) {
      this.triggerDateChange.emit(sendingData);
    }
  }

  onDateChange(): void {
    if (this.selectedOption === '1') {
      return;
    }

    const sendingData = {
      startDate:
        this.selectedOption !== '-1'
          ? this.getStartDate(this.selectedOption)
          : '',

      endDate:
        this.selectedOption !== '-1'
          ? this.getEndDate(this.selectedOption)
          : '',
      isCustomDate: false,
    };

    if (sendingData.endDate !== null && sendingData.startDate !== null) {
      this.triggerDateChange.emit(sendingData);
    }
    this.selectedOption = this.selectedOptionId;
  }

  onMinus(): void {
    this.selectedOption--;

    this.onDateChange();

    if (this.selectedOption === -1) {
      this.selectedOption = 1;
    }
  }

  getStartDate(selectedOption: any): any {
    if (selectedOption !== 1) {
      return this.datepipe.transform(
        new Date(selectedOption.split(' - ')[0]),
        'dd-MMM-yyyy'
      );
    } else {
      return this.rangeFormGroup.controls.start.value;
    }
  }

  getEndDate(selectedOption: any): any {
    if (selectedOption !== 1) {
      return this.datepipe.transform(
        new Date(selectedOption.split(' - ')[1]),
        'dd-MMM-yyyy'
      );
    } else {
      return this.rangeFormGroup.controls.end.value;
    }
  }

  onPlus(): void {
    this.selectedOption++;
    this.onDateChange();

    if (this.yearsListPair.length === this.selectedOption) {
      this.selectedOption = 0;
    }
  }

  onDropdownChange(event: any): void {
    if (event.value.id !== undefined && event.value.id !== null) {
      this.selectedOption = event.value.name;
      this.selectedOptionId = event.value;
      localStorage.setItem('defaultFinancialPeriod', event.value.name);
    } else {
      this.selectedOption = event.value;
      this.selectedOptionId = event.value;
      localStorage.setItem('defaultFinancialPeriod', event.value);
    }

    this.onDateChange();
  }
}
