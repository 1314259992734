import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  ModuleName,
  Modules,
  NotificationTextMessage,
  RoutingPath,
} from '@app/core/Enum';
import { ScheduleNote } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { MenuState } from '@app/core/Store';
import {
  CreateScheduleNote,
  GetDataByNoteId,
} from '@app/core/Store/schedules/schedules.action';
import { AddClosePopupComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subject, Subscription } from 'rxjs';
import { AddSchedulesNotesComponent } from './add-schedules-notes/add-schedules-notes.component';

@Component({
  selector: 'app-schedules-notes',
  templateUrl: './schedules-notes.component.html',
  styleUrls: ['./schedules-notes.component.scss'],
})
export class SchedulesNotesComponent implements OnInit {
  customerId = Guid.EMPTY as unknown as Guid;
  isShowSideListAction = false;
  istoggleSideList = false;
  isExpandAll = false;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @ViewChild(AddSchedulesNotesComponent, { static: true })
  scheduleNoteData;

  triggerEditData: Subject<any> = new Subject<any>();

  moduleName = ModuleName.Notes;
  moduleId = Modules.Notes;

  noteData: ScheduleNote;
  noteId = Guid.EMPTY as unknown as Guid;
  subscriptionRouting: Subscription;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    public commonService: CommonService,
    public dialog: MatDialog,
    public store: Store,
    private _Activatedroute: ActivatedRoute,
    private renderer: Renderer2,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.noteId = atob(params.get('id')!) as unknown as Guid;
          this.editNote();
        }
      }
    );
  }

  editNote(): void {
    this.store.dispatch(new GetDataByNoteId(this.noteId)).subscribe((res) => {
      this.scheduleNoteData.scheduleNoteForm.markAsUntouched();
      this.triggerEditData.next(res.scheduleNote.noteData);
    });
  }

  onCancel(isCancelClick: boolean): void {
    this.commonService.isInitialValueChange = false;
    if (isCancelClick && !this.commonService.isEmpty(this.noteId)) {
      this.editNote();
    } else {
      this.noteId = Guid.EMPTY as unknown as Guid;
      this.scheduleNoteData.ngOnInit();
    }
  }

  onSave(isExit: boolean): void {
    if (this.scheduleNoteData.scheduleNoteForm.invalid) {
      this.commonService.addValidation(
        this.scheduleNoteData.scheduleNoteForm,
        this.renderer
      );
    } else {
      this.noteData = {
        id: this.noteId,
        title: this.scheduleNoteData.scheduleNoteForm.controls.notesTitle.value,
        description:
          this.scheduleNoteData.scheduleNoteForm.controls.notesDescription
            .value,
        branchId:
          this.scheduleNoteData.scheduleNoteForm.controls.branchId.value === ''
            ? null
            : this.scheduleNoteData.scheduleNoteForm.controls.branchId.value,
        departmentId:
          this.scheduleNoteData.scheduleNoteForm.controls.departmentId.value ===
          ''
            ? null
            : this.scheduleNoteData.scheduleNoteForm.controls.departmentId
                .value,
      };

      this.store.dispatch(new CreateScheduleNote(this.noteData)).subscribe(
        (res) => {
          if (res.scheduleNote.isNoteAdded) {
            this.setHighlightData(isExit);

            if (!isExit) {
              this.onCancel(false);
              this.reloadSideList.emit();
            } else {
              this.onCancel(false);
              this.location.back();
            }
            this.commonService.onSuccess(
              NotificationTextMessage.successMessage
            );
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        },
        (err) => {
          this.commonService.onFailure(err.message);
        }
      );
    }
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.noteId,
      isExit,
      Modules.Notes,
      RoutingPath.AddSchedules
    );
  }

  showSideListAction(val: boolean): void {
    this.isShowSideListAction = val;
  }

  toggleSideList(): void {
    this.istoggleSideList = !this.istoggleSideList;
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }
}
