export enum FilteredStatus {
  All = -1,
  Active = 0,
  Archive = 1,
}

export enum UserFilteredStatus {
  'All Status' = -1,
  'Active' = 1,
  'Resend Invitation' = 2,
  'Invite' = 3,
  'Inactive' = 4,
}

export enum CompanyFilteredStatus {
  'All Status' = -1,
  Active = 0,
  Archived = 1,
}
