import { Guid } from 'guid-typescript';

export class ActivityModel {
  id: Guid;
  name: string;
  code: string;
  description: string;
  attachment: Array<string>;
  isDefault: boolean;
}

export class ActivityLogModel {
  month: string;
  year: number;
  activityModels: Array<ActivityTask>;
}

export class ActivityTask {
  createdByName: string;
  createdByPhoto: string;
  moduleId: number;
  entityTypeId: number;
  businessId: number;
  createdById: number;
  activityDate: string;
  startDate: string;
  endDate: string;
  expenseDate: string;
  entities: Array<ActivityEntity>;
}

export class ActivityEntity {
  id: number;
  name: string;
  amount: string;
  hasAttachment?: boolean;
  toEmail?: Array<string>;
  note: string;
  providerType: string;
  providerTypeId: number;
  previousStatus: string;
  paymentMethodName: string;
  newStatus: string;
  customMessage: string;
  attachments: Array<ActivityAttachment>;
  code?: string;
}

export class ActivityAttachment {
  attachmentName: string;
  url: string;
  size: string;
}

export class AuthorisedMenuModel {
  id: number;
  name: string;
  displayName: string;
  parentId: 0;
  moduleUrl: string;
  moduleAddUrl: string;
  iconName: string;
  permissions: [
    {
      id: 0;
      name: string;
    }
  ];
}