import { Guid } from 'guid-typescript';

export class IndustryType {
  id: number;
  name: string;
}

export class MinuteOfMeeting {
  id?: Guid;
  meetingDate?: string;
  meetingType: string;
  description: string;
  branchId?: Guid | null;
  departmentId?: Guid | null;
}
