import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  ConfirmationType,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/Enum';
import { MainListParameters, SideListModel } from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import { CompanyState } from '@app/core/Store';
import { ConfirmationBoxComponent } from '@app/modules';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.scss'],
})
export class AddDepartmentComponent implements OnInit {
  formDepartmentDetail: UntypedFormGroup;
  departmentArray: any;
  listParameters: MainListParameters = new MainListParameters();

  notificationMessage = NotificationTextMessage;
  displayColumns: string[] = ['srNo', 'name', 'branchName', 'closeButton'];
  departmentData: any[] = [];
  branchList: SideListModel[];

  tableDataSource: MatTableDataSource<AbstractControl>;

  @Input() triggerEditData: Observable<any>;
  @Input() triggerEditDepartmentData: Observable<any>;

  @Select(CompanyState.branchList)
  branchList$: Observable<any>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private highlightRow: HighlightRow,
    public datepipe: DatePipe,
    public commonService: CommonService,
    private store: Store,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setDepartmentDetailsForm(false);

    this.triggerEditDepartmentData?.subscribe((data) => {
      setTimeout(() => {
        this.editDepartment(data);
      }, 500);
    });

    this.branchList$.subscribe((data) => {
      this.branchList = data;
      this.reassignDepartment();
    });

    this.formDepartmentDetail.valueChanges.subscribe((value) => {
      this.commonService.isInitialValueChange =
        this.formDepartmentDetail.touched;
    });
  }

  reassignDepartment(): void {
    if (this.departmentArray.length > 0 && this.branchList.length > 0) {
      let editData: any[] = [];
      this.departmentArray?.getRawValue().forEach((element, i) => {
        let branchList: any = [];
        if (element.branchName !== null && element.branchName !== undefined) {
          element.branchName.forEach((res) => {
            const isExist = this.branchList.find((x: any) => x.srNo === res);
            if (isExist !== null && isExist !== undefined) {
              branchList.push(res);
            }
          });
          const param = {
            id: element.id,
            name: element.name,
            isDefault: element.isDefault,
            brancheSrNos: branchList,
          };
          editData.push(param);
        } else if (element.id === this.commonService.defaultGuidValue) {
          const param = {
            id: element.id,
            name: element.name,
            isDefault: element.isDefault,
            brancheSrNos: branchList,
          };
          editData.push(param);
        }
      });
      if (editData.length > 0) {
        this.editDepartment(editData);
      }
    }
  }

  editDepartment(data: any): void {
    this.departmentData = [];
    this.highlightRow.departmentData = [];
    this.departmentArray = this.formDepartmentDetail.get(
      'departmentArray'
    ) as UntypedFormArray;

    this.departmentArray.clear();

    data.forEach((element) => {
      this.departmentData?.push(element);
    });

    this.departmentData.forEach((item, i) => {
      this.departmentArray.push(this.buildOrderItemsForm(item));
      this.departmentArray.controls[i]
        .get('name')
        ?.setValidators(Validators.required);
      this.departmentArray.controls[i].get('name')?.updateValueAndValidity();
    });

    this.setDataSource(this.departmentArray);
  }

  buildOrderItemsForm(item: any): FormGroup {
    this.highlightRow.departmentData.push({
      id: item.id,
      name: item.name,
      isDefault: item.isDefault,
      branchName: item.brancheSrNos,
    });

    const data = this.formBuilder.array(
      item.brancheSrNos.map((x) => this.formBuilder.control(x))
    );

    return this.formBuilder.group({
      id: item.id,
      name: item.name,
      isDefault: item.isDefault,
      branchName: this.formBuilder.control(data.value),
    });
  }

  setDepartmentDetailsForm(addNewRow: boolean): void {
    this.formDepartmentDetail = new FormGroup({
      departmentArray: new UntypedFormArray([]),
    });

    this.departmentArray = this.formDepartmentDetail.get(
      'departmentArray'
    ) as UntypedFormArray;

    this.setDataSource(this.departmentArray);
    if (addNewRow) this.createRow();
  }

  getBranchParamaters(): any {
    const queryParams = {
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
    };

    return queryParams;
  }

  createRow(): void {
    this.departmentArray = this.formDepartmentDetail.get(
      'departmentArray'
    ) as UntypedFormArray;

    this.departmentArray.push(this.setForm());
    this.highlightRow.departmentData = [];

    this.departmentArray?.getRawValue().forEach((x) => {
      this.highlightRow.departmentData.push({
        id: x.id,
        name: x.name,
        branchName: x.branchName,
        isDefault: x.isDefault,
      });
    });

    this.setDataSource(this.departmentArray);
  }

  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  setForm(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(Guid.EMPTY as unknown as Guid),
      name: new FormControl('', [
        this.commonService.whiteSpaceValidate,
        Validators.required,
      ]),
      branchName: new FormControl(),
      isDefault: new FormControl(false),
    });
  }

  onDeleteDepartmentDetails(index: number): void {
    this.departmentArray = this.formDepartmentDetail.get(
      'departmentArray'
    ) as UntypedFormArray;

    if (
      this.departmentArray.value[index].id !== undefined &&
      this.departmentArray.value[index].id !== null &&
      this.departmentArray.value[index].id !== Guid.EMPTY
    ) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: [this.departmentArray.value[index].id],
            type: ConfirmationType.DeleteDepartment,
            headerText: NotificationHeader.deleteConfirmation,
            detailText: NotificationDetails.deleteAllDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.departmentArray = this.formDepartmentDetail.get(
              'departmentArray'
            ) as UntypedFormArray;
            this.departmentArray.removeAt(index);
            this.highlightRow.departmentData.splice(index, 1);
            this.setDataSource(this.departmentArray);
          }
        });
    } else {
      this.departmentArray = this.formDepartmentDetail.get(
        'departmentArray'
      ) as UntypedFormArray;
      this.departmentArray.removeAt(index);
      this.highlightRow.departmentData.splice(index, 1);
      this.setDataSource(this.departmentArray);
    }
  }
}
