import { Component, OnInit } from '@angular/core';
export interface PeriodicElement {
  contact: string;
  date: string;
  refno: string;
  netamount: string;
  vatamount: string;
  totalamount: string;
}

export interface GroupBy {
  initial: string;
  isGroupBy: boolean;
}

const ELEMENT_DATA: (PeriodicElement | GroupBy)[] = [
  {initial: 'VAT on Purchases', isGroupBy: true},
  {
    contact: 'John Smit',
    date: '22/04/2024',
    refno: 'PUR-55',
    netamount: '1,250.00',
    vatamount: '62.50',
    totalamount: '1,312.50',
  },
  {initial: 'VAT on Sales', isGroupBy: true},
  {
    contact: 'EU Sales Services',
    date: '11/04/2024',
    refno: 'JNL-1',
    netamount: '1000.00',
    vatamount: '200.00',
    totalamount: '1,200.00',
  },
  {
    contact: 'EU Sales Services',
    date: '11/04/2024',
    refno: 'JNL-1',
    netamount: '1000.00',
    vatamount: '200.00',
    totalamount: '1,200.00',
  },
  {
    contact: 'EU Sales Services',
    date: '11/04/2024',
    refno: 'JNL-1',
    netamount: '1000.00',
    vatamount: '200.00',
    totalamount: '1,200.00',
  },
  {
    contact: 'EU Sales Services',
    date: '11/04/2024',
    refno: 'JNL-1',
    netamount: '1000.00',
    vatamount: '200.00',
    totalamount: '1,200.00',
  },
];

@Component({
  selector: 'app-vat-details-grid',
  templateUrl: './vat-details-grid.component.html',
  styleUrls: ['./vat-details-grid.component.scss']
})
export class VatDetailsGridComponent implements OnInit {
  displayedColumns: string[] = ['contact', 'date', 'refno', 'netamount', 'vatamount', 'totalamount'];
  dataSource = ELEMENT_DATA;
  constructor() { }

  ngOnInit(): void {
  }

  isGroup(index: any, item: { isGroupBy: boolean; }): boolean{
    return item.isGroupBy;
  }

}
