<div class="company-preference content-body-wrapper">
  <div class="company-preference-info">
    <app-vat-return
      [triggerEditData]="triggerEditData"
      [submitVatId]="submitVatId"
      [isSubmitHmrc]="isSubmitHmrc"
    >
    </app-vat-return>
  </div>
</div>
