<app-menu-items></app-menu-items>
<div class="d-flex h-100">
  <app-side-list
    [getModuleId]="moduleId"
    [getModuleName]="moduleName"
    (showSideListAction)="showSideListAction($event)"
    (toggleSideList)="toggleSideList($event)"
    [reloadSideList]="reloadSideList"
  ></app-side-list>
  <div class="sidemenu-hide" *ngIf="!isShowSideListAction">
    <div class="d-flex h-100">
      <div class="form mb-4 wp-100">
        <div class="content-body">
          <div class="form-list-content">
            <p
              class="header-text d-flex justify-space-between align-items-center"
            >
              <span>{{ headerText }}</span>
              <button
                mat-button
                class="header-text-close d-flex align-items-center"
                disableRipple
                (click)="onCloseClick()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </p>
            <div class="alert-message" *ngIf="isViewPermission$ | async">
              <mat-icon class="material-icons-outlined">error_outline</mat-icon>
              {{ commonNotificationText.ViewOnlyAccessMessage }}
            </div>
            <div class="mt-1">
              <mat-accordion [multi]="true">
                <mat-expansion-panel
                  [expanded]="true"
                  [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title> Basic Info </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="content-body">
                    <div class="sidenav-details">
                      <form [formGroup]="clientForm">
                        <div class="sidenav-body">
                          <div class="sidenav-body-content p-0">
                            <div class="wp-100">
                              <div>
                                <div class="form-field">
                                  <p>
                                    Client ID<span class="text-danger-300"
                                      >*</span
                                    >
                                  </p>
                                  <mat-form-field appearance="fill">
                                    <input
                                      matInput
                                      autocomplete="off"
                                      maxlength="255"
                                      formControlName="clientCode"
                                      (focusout)="checkClietCodeDuplicateExist()"
                                    />
                                  </mat-form-field>
                                </div>
                                <div
                                  class="form-field wp-100 toggle-option mt-1 mb-0"
                                >
                                  <p>Client Type</p>
                                  <div class="d-flex align-center gap-15">
                                    <div *ngFor="let type of clientTypeList">
                                      <mat-checkbox
                                        [disabled]="
                                          clientId !== defaultUniversalId
                                        "
                                        [checked]="type.id === companyTypeId"
                                        (change)="valueChange(type.id, $event)"
                                      >
                                        {{ type.name }}
                                      </mat-checkbox>
                                    </div>
                                  </div>
                                </div>

                                <div class="hr-top pt-1 mt-5">
                                  <div class="form-field">
                                    <p>
                                      Company Name<span class="text-danger-300"
                                        >*</span
                                      >
                                    </p>
                                    <mat-form-field class="company-name w-250">
                                      <input
                                        cdkFocusInitial
                                        type="text"
                                        matInput
                                        formControlName="companyName"
                                        [matAutocomplete]="auto"
                                        maxlength="100"
                                        #trigger="matAutocompleteTrigger"
                                        appAutocompletePosition="trigger"
                                        (input)="onCompanySearch($event)"
                                      />
                                      <mat-icon class="downarrow" matSuffix
                                        >keyboard_arrow_down</mat-icon
                                      >
                                      <mat-autocomplete
                                        #auto="matAutocomplete"
                                        role="listbox"
                                        (optionSelected)="
                                          onCompanySelection($event)
                                        "
                                        #companySelect
                                        (opened)="(companySelect.opened)"
                                      >
                                        <mat-option
                                          *ngFor="let option of companyList"
                                          [value]="option.name"
                                        >
                                          {{ option.name }}
                                        </mat-option>
                                      </mat-autocomplete>
                                    </mat-form-field>
                                  </div>
                                  <div
                                    class="form-field"
                                    *ngIf="
                                      +companyTypeId ===
                                        companyType['Sole Trader'] ||
                                      +companyTypeId === companyType.Individual
                                    "
                                  >
                                    <p>National Insurance Number</p>
                                    <mat-form-field appearance="fill">
                                      <input
                                        matInput
                                        autocomplete="off"
                                        required
                                        maxlength="255"
                                      />
                                    </mat-form-field>
                                  </div>
                                </div>

                                <div
                                  class="hr-top form-field-title mt-1 mb-1 pt-1"
                                >
                                  <p>Business Details</p>
                                </div>

                                <div class="business-form">
                                  <div class="form-field">
                                    <p>
                                      Business Start Date<span
                                        class="text-danger-300"
                                        >*</span
                                      >
                                    </p>
                                    <mat-form-field appearance="fill">
                                      <input
                                        matInput
                                        [matDatepicker]="businessDate"
                                        autocomplete="off"
                                        formControlName="businessStartDate"
                                        (dateChange)="businessStartdateChange()"
                                      />
                                      <mat-datepicker-toggle
                                        matSuffix
                                        [for]="businessDate"
                                      ></mat-datepicker-toggle>
                                      <mat-datepicker
                                        #businessDate
                                      ></mat-datepicker>
                                    </mat-form-field>
                                  </div>
                                  <div class="form-field">
                                    <p>
                                      Book Start Date<span
                                        class="text-danger-300"
                                        >*</span
                                      >
                                    </p>
                                    <mat-form-field appearance="fill">
                                      <input
                                        matInput
                                        [matDatepicker]="bookDate"
                                        autocomplete="off"
                                        formControlName="bookStartDate"
                                        [min]="
                                          clientForm.value.businessStartDate
                                        "
                                      />
                                      <mat-datepicker-toggle
                                        matSuffix
                                        [for]="bookDate"
                                      ></mat-datepicker-toggle>
                                      <mat-datepicker
                                        #bookDate
                                      ></mat-datepicker>
                                    </mat-form-field>
                                  </div>

                                  <div class="form-field datepicker">
                                    <p>Year End</p>
                                    <div class="d-flex gap-15">
                                      <ng-select
                                        class="wp-50"
                                        [disableOptionCentering]="true"
                                        formControlName="yearEndMonth"
                                        (change)="bindDayList($event)"
                                      >
                                        <ng-option
                                          *ngFor="
                                            let month of monthList;
                                            let i = index
                                          "
                                          [value]="i + 1"
                                          >{{ month }}
                                        </ng-option>
                                      </ng-select>
                                      <ng-select
                                        class="wp-50"
                                        [disableOptionCentering]="true"
                                        formControlName="yearEndDate"
                                      >
                                        <ng-option
                                          *ngFor="let day of dayList"
                                          [value]="day.value"
                                          >{{ day.label }}
                                        </ng-option>
                                      </ng-select>
                                    </div>
                                  </div>
                                  <div class="form-field">
                                    <p class="d-flex align-items-center gap-5">
                                      Registration Number
                                    </p>
                                    <mat-form-field appearance="fill">
                                      <input
                                        matInput
                                        formControlName="companyNumber"
                                        autocomplete="off"
                                        maxlength="{{ maxLength.maxLength8 }}"
                                      />
                                    </mat-form-field>
                                  </div>

                                  <div class="form-field">
                                    <p>UTR No.</p>
                                    <mat-form-field appearance="fill">
                                      <input
                                        matInput
                                        autocomplete="off"
                                        formControlName="taxRefNo"
                                        minlength="{{ maxLength.maxLength10 }}"
                                        maxlength="{{ maxLength.maxLength10 }}"
                                      />
                                    </mat-form-field>
                                  </div>
                                  <div class="form-field">
                                    <p>Accounting Method</p>
                                    <ng-select
                                      #method
                                      (focus)="method.open()"
                                      (valueChange)="method.close()"
                                      [disableOptionCentering]="true"
                                      panelClass="mat-select-position"
                                      formControlName="accountingMethodId"
                                      [readonly]="true"
                                      [disabled]="true"
                                      required
                                    >
                                      <ng-option
                                        *ngFor="let cl of accountingMethodList"
                                        [value]="cl.id"
                                      >
                                        <div title="{{ cl.name }}">
                                          {{ cl.name }}
                                        </div>
                                      </ng-option>
                                    </ng-select>
                                  </div>
                                  <div class="form-field">
                                    <p>Currency</p>
                                    <ng-select
                                      #currency
                                      (focus)="currency.open()"
                                      (valueChange)="currency.close()"
                                      [disableOptionCentering]="true"
                                      formControlName="currency"
                                      panelClass="mat-select-position"
                                    >
                                      <ng-option
                                        *ngFor="let cl of currencyList"
                                        [value]="cl.id"
                                      >
                                        <div
                                          title="{{ cl.country }} - {{
                                            cl.name
                                          }} - {{ cl.code }}"
                                        >
                                          {{ cl.country }} - {{ cl.name }} -
                                          {{ cl.code }}
                                        </div>
                                      </ng-option>
                                    </ng-select>
                                  </div>

                                  <div class="form-field">
                                    <p>Is this Client registered for VAT?</p>
                                    <div
                                      class="radio-button d-flex align-items-center gap-10 pt-5"
                                    >
                                      <span class="fw-bold">{{
                                        tooggleText
                                      }}</span>
                                      <mat-slide-toggle
                                        (change)="registeredForVat()"
                                      ></mat-slide-toggle>
                                    </div>
                                  </div>

                                  <div
                                    class="form-field"
                                    [hidden]="!isRegisteredForVat"
                                  >
                                    <p>
                                      VAT Scheme<span class="text-danger-300"
                                        >*</span
                                      >
                                    </p>
                                    <ng-select
                                      #vatScheme
                                      (focus)="vatScheme.open()"
                                      (valueChange)="vatScheme.close()"
                                      [disableOptionCentering]="true"
                                      formControlName="vatSchemeId"
                                      panelClass="mat-select-position"
                                      required
                                    >
                                      <ng-option
                                        *ngFor="let sl of vatSchemeList"
                                        [value]="sl.id"
                                      >
                                        <div title="{{ sl.name }}">
                                          {{ sl.name }}
                                        </div>
                                      </ng-option>
                                    </ng-select>
                                  </div>

                                  <div
                                    class="form-field"
                                    [hidden]="!isRegisteredForVat"
                                  >
                                    <p>
                                      VAT Reg. No.<span class="text-danger-300"
                                        >*</span
                                      >
                                    </p>
                                    <mat-form-field appearance="fill">
                                      <input
                                        matInput
                                        formControlName="vatRegistrationNo"
                                        autocomplete="off"
                                        maxlength="{{ maxLength.maxLength9 }}"
                                        minlength="{{ maxLength.maxLength9 }}"
                                        type="text"
                                        [required]="isRegisteredForVat"
                                        allowDecimal
                                      />
                                    </mat-form-field>
                                  </div>

                                  <div
                                    class="form-field"
                                    [hidden]="!isRegisteredForVat"
                                  >
                                    <p>
                                      VAT Registration Date<span
                                        class="text-danger-300"
                                        >*</span
                                      >
                                    </p>
                                    <mat-form-field appearance="fill">
                                      <input
                                        matInput
                                        formControlName="vatRegistrationDate"
                                        [matDatepicker]="regDate"
                                        autocomplete="off"
                                      />
                                      <mat-datepicker-toggle
                                        matSuffix
                                        [for]="regDate"
                                      ></mat-datepicker-toggle>
                                      <mat-datepicker #regDate></mat-datepicker>
                                    </mat-form-field>
                                  </div>

                                  <div
                                    class="form-field"
                                    [hidden]="!isRegisteredForVat"
                                  >
                                    <p>
                                      VAT Submit Type<span
                                        class="text-danger-300"
                                        >*</span
                                      >
                                    </p>
                                    <ng-select
                                      #activity
                                      (focus)="activity.open()"
                                      (valueChange)="activity.close()"
                                      [disableOptionCentering]="true"
                                      formControlName="vatReturnTypeId"
                                      panelClass="mat-select-position"
                                      required
                                    >
                                      <ng-option
                                        *ngFor="let rl of vatReturnTypeList"
                                        [value]="rl.id"
                                      >
                                        <div title="{{ rl.name }}">
                                          {{ rl.name }}
                                        </div>
                                      </ng-option>
                                    </ng-select>
                                  </div>
                                  <div class="form-field">
                                    <p>Account Office Ref No.</p>
                                    <mat-form-field appearance="fill">
                                      <input
                                        matInput
                                        autocomplete="off"
                                        formControlName="accountOfficeRefNumber"
                                        placeholder="e.g. 123PA00123456"
                                        maxlength="{{ maxLength.maxLength13 }}"
                                      />
                                    </mat-form-field>
                                  </div>

                                  <div class="form-field">
                                    <p>PAYE Ref No.</p>
                                    <mat-form-field appearance="fill">
                                      <input
                                        matInput
                                        autocomplete="off"
                                        formControlName="payeRefNumber"
                                        placeholder="e.g. 123/ABCD121"
                                        maxlength="{{ maxLength.maxLength14 }}"
                                      />
                                    </mat-form-field>
                                  </div>
                                  <div
                                    class="form-field datepicker"
                                    *ngIf="
                                      +companyTypeId ===
                                        companyType['Sole Trader'] ||
                                      +companyTypeId === companyType.Individual
                                    "
                                  >
                                    <p>Date of Birth</p>
                                    <mat-form-field appearance="fill">
                                      <input
                                        matInput
                                        cdkFocusInitial
                                        [matDatepicker]="picker5"
                                        autocomplete="off"
                                        formControlName="dateOfBirth"
                                      />
                                      <mat-datepicker-toggle
                                        matSuffix
                                        [for]="picker5"
                                      ></mat-datepicker-toggle>
                                      <mat-datepicker #picker5></mat-datepicker>
                                    </mat-form-field>
                                  </div>
                                </div>                                

                                <div class="content-box hr-top">
                                  <div class="form-field-title">
                                    <p>Address Details</p>
                                  </div>
                                  <div
                                    class="sidenav-table sidenav-table-input wp-100"
                                  >
                                    <div
                                      class="mat-table-wrapper contact-detail-table"
                                    >
                                      <form [formGroup]="formAddressDetail">
                                        <ng-container
                                          formArrayName="addressDetailArray"
                                        >
                                          <table
                                            mat-table
                                            [dataSource]="
                                              addressTableDataSource
                                            "
                                            aria-describedby="User Addresses Table"
                                          >
                                            <ng-container
                                              matColumnDef="addressType"
                                            >
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                class="w-100"
                                              ></th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                              >
                                                <ng-select
                                                  #addresstype
                                                  (focus)="addresstype.open()"
                                                  (blur)="addresstype.close()"
                                                  class="wp-100 detail-column"
                                                  panelClass="myPanelClass"
                                                  [disableOptionCentering]="
                                                    true
                                                  "
                                                  formControlName="addressType"
                                                >
                                                  <ng-option value="Business"
                                                    >Business</ng-option
                                                  >
                                                  <ng-option value="Postal"
                                                    >Postal</ng-option
                                                  >
                                                  <ng-option value="Personal"
                                                    >Personal</ng-option
                                                  >
                                                  <ng-option value="Registered"
                                                    >Registered</ng-option
                                                  >
                                                </ng-select>
                                              </td>
                                            </ng-container>
                                            <ng-container
                                              matColumnDef="address"
                                            >
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                              >
                                                Address
                                              </th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                                class="widthemail"
                                              >
                                                <mat-form-field
                                                  floatLabel="never"
                                                  class="wp-100"
                                                >
                                                  <input
                                                    matInput
                                                    [value]="element.address"
                                                    autocomplete="off"
                                                    formControlName="address"
                                                    maxlength="1000"
                                                  />
                                                </mat-form-field>
                                              </td>
                                            </ng-container>
                                            <ng-container matColumnDef="city">
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                              >
                                                City
                                              </th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                              >
                                                <mat-form-field
                                                  floatLabel="never"
                                                  class="wp-100"
                                                >
                                                  <input
                                                    matInput
                                                    [value]="element.city"
                                                    autocomplete="off"
                                                    formControlName="city"
                                                    maxlength="100"
                                                  />
                                                </mat-form-field>
                                              </td>
                                            </ng-container>
                                            <ng-container matColumnDef="county">
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                              >
                                                County
                                              </th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                              >
                                                <mat-form-field
                                                  floatLabel="never"
                                                  class="wp-100"
                                                >
                                                  <input
                                                    matInput
                                                    [value]="element.county"
                                                    autocomplete="off"
                                                    formControlName="county"
                                                    maxlength="255"
                                                  />
                                                </mat-form-field>
                                              </td>
                                            </ng-container>
                                            <ng-container
                                              matColumnDef="country"
                                            >
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                class="w-150"
                                              >
                                                Country
                                              </th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                              >
                                                <ng-select
                                                  #country
                                                  (focus)="country.open()"
                                                  (blur)="country.close()"
                                                  class="w-150 detail-column"
                                                  panelClass="myPanelClass"
                                                  [disableOptionCentering]="
                                                    true
                                                  "
                                                  formControlName="country"
                                                >
                                                  <ng-option
                                                    *ngFor="
                                                      let cl of countryList
                                                    "
                                                    [value]="cl.id"
                                                    >{{ cl.description }}
                                                  </ng-option>
                                                </ng-select>
                                              </td>
                                            </ng-container>
                                            <ng-container
                                              matColumnDef="postalCode"
                                            >
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                class="w-100"
                                              >
                                                ZIP/Post Code
                                              </th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                              >
                                                <mat-form-field
                                                  floatLabel="never"
                                                  class="w-100"
                                                >
                                                  <input
                                                    matInput
                                                    [value]="element.postalCode"
                                                    autocomplete="off"
                                                    type="tel"
                                                    minlength="0"
                                                    maxlength="10"
                                                    formControlName="postalCode"
                                                  />
                                                </mat-form-field>
                                              </td>
                                            </ng-container>
                                            <tr
                                              mat-header-row
                                              *matHeaderRowDef="
                                                displayAddressDetailColumns;
                                                sticky: true
                                              "
                                            ></tr>
                                            <tr
                                              mat-row
                                              *matRowDef="
                                                let row;
                                                columns: displayAddressDetailColumns
                                              "
                                            ></tr>
                                          </table>
                                        </ng-container>
                                      </form>
                                      <div
                                        class="text-align-center space-top"
                                        *ngIf="!addressDetailArray.length"
                                      >
                                        {{
                                          commonNotificationText.noRecordFoundText
                                        }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="content-box hr mt-1 pt-10">
                                  <div class="form-field-title">
                                    <p>Contact Details</p>
                                  </div>
                                  <div
                                    class="sidenav-table sidenav-table-input wp-100"
                                  >
                                    <div
                                      class="mat-table-wrapper contact-detail-table"
                                    >
                                      <form [formGroup]="formContactDetail">
                                        <ng-container
                                          formArrayName="contactDetailArray"
                                        >
                                          <table
                                            mat-table
                                            [dataSource]="
                                              contactTableDataSource
                                            "
                                            aria-describedby="Client details table"
                                          >
                                            <ng-container
                                              matColumnDef="closeButton"
                                            >
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                class="w-15"
                                              ></th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                                class="text-align-center"
                                              >
                                                <button
                                                  class="close-btn"
                                                  type="button"
                                                  mat-button
                                                  disableRipple
                                                  (click)="
                                                    onDeleteContactDetail(
                                                      i,
                                                      element
                                                    )
                                                  "
                                                >
                                                  <mat-icon
                                                    >highlight_off</mat-icon
                                                  >
                                                </button>
                                              </td>
                                            </ng-container>
                                            <ng-container matColumnDef="name">
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                class="w-200"
                                              >
                                                Full Name &nbsp;<span
                                                  class="text-danger-300"
                                                  >*</span
                                                >
                                              </th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                              >
                                                <div class="d-flex">
                                                  <ng-select
                                                    #selectType
                                                    (focus)="selectType.open()"
                                                    (valueChange)="
                                                      selectType.close()
                                                    "
                                                    formControlName="title"
                                                    class="w-80 mr-10"
                                                  >
                                                    <ng-option
                                                      *ngFor="
                                                        let prefix of titleList
                                                      "
                                                      [value]="prefix.id"
                                                      >{{ prefix.name }}
                                                    </ng-option>
                                                  </ng-select>
                                                  <mat-form-field
                                                    floatLabel="never"
                                                    class="w-200"
                                                  >
                                                    <input
                                                      matInput
                                                      [value]="element.name"
                                                      required
                                                      formControlName="name"
                                                      maxlength="255"
                                                      autocomplete="off"
                                                    />
                                                  </mat-form-field>
                                                </div>
                                              </td>
                                            </ng-container>
                                            <ng-container matColumnDef="email">
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                              >
                                                Email &nbsp;<span
                                                  class="text-danger-300"
                                                  >*</span
                                                >
                                              </th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                                class="widthemail"
                                              >
                                                <mat-form-field
                                                  floatLabel="never"
                                                  class="wp-100"
                                                >
                                                  <input
                                                    matInput
                                                    [value]="element.email"
                                                    required
                                                    autocomplete="off"
                                                    formControlName="email"
                                                    maxlength="320"
                                                  />
                                                </mat-form-field>
                                              </td>
                                            </ng-container>
                                            <ng-container matColumnDef="phone">
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                class="w-200"
                                              >
                                                Phone &nbsp;<span
                                                  class="text-danger-300"
                                                  >*</span
                                                >
                                              </th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                              >
                                                <mat-form-field
                                                  floatLabel="never"
                                                  class="w-200"
                                                >
                                                  <input
                                                    matInput
                                                    appOnlynumber
                                                    [value]="element.phone"
                                                    required
                                                    autocomplete="off"
                                                    type="tel"
                                                    (paste)="
                                                      validateInput($event)
                                                    "
                                                    minlength="0"
                                                    (keypress)="
                                                      keyPress($event)
                                                    "
                                                    maxlength="15"
                                                    formControlName="phone"
                                                  />
                                                </mat-form-field>
                                              </td>
                                            </ng-container>
                                            <ng-container
                                              matColumnDef="isPrimary"
                                            >
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                [ngClass]="'text-align-center'"
                                                class="w-80"
                                              >
                                                Is Primary
                                              </th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                                [ngClass]="'text-align-center'"
                                              >
                                                <mat-radio-button
                                                  (click)="
                                                    primaryClick(i, true)
                                                  "
                                                  [checked]="
                                                    isPrimarySelected(i)
                                                  "
                                                  formControlName="isPrimary"
                                                  [value]="primaryContact.id"
                                                  name="isPrimary"
                                                  ngDefaultControl
                                                >
                                                </mat-radio-button>
                                              </td>
                                            </ng-container>
                                            <tr
                                              mat-header-row
                                              *matHeaderRowDef="
                                                displayContactDetailColumns;
                                                sticky: true
                                              "
                                            ></tr>
                                            <tr
                                              mat-row
                                              *matRowDef="
                                                let row;
                                                columns: displayContactDetailColumns
                                              "
                                            ></tr>
                                            <tr class="mat-row" *matNoDataRow>
                                              <td
                                                class="mat-cell"
                                                [ngClass]="'text-align-center'"
                                                [attr.colspan]="
                                                  displayContactDetailColumns.length
                                                "
                                              >
                                                {{
                                                  commonNotificationText.noRecordFoundText
                                                }}
                                              </td>
                                            </tr>
                                          </table>
                                        </ng-container>
                                      </form>
                                    </div>
                                  </div>                                
                                </div>

                                <div class="content-box hr mt-1 pt-10">
                                  <div class="form-field-title">
                                    <p>Address Details</p>
                                  </div>
                                  <div
                                    class="sidenav-table sidenav-table-input wp-100"
                                  >
                                    <div
                                      class="mat-table-wrapper contact-detail-table"
                                    >
                                      <form [formGroup]="formAddressDetail">
                                        <ng-container
                                          formArrayName="addressDetailArray"
                                        >
                                          <table
                                            mat-table
                                            [dataSource]="
                                              addressTableDataSource
                                            "
                                            aria-describedby="User Addresses Table"
                                          >
                                            <ng-container
                                              matColumnDef="addressType"
                                            >
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                class="w-100"
                                              ></th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                              >
                                                <ng-select
                                                  #addresstype
                                                  (focus)="addresstype.open()"
                                                  (blur)="addresstype.close()"
                                                  class="wp-100 detail-column"
                                                  panelClass="myPanelClass"
                                                  [disableOptionCentering]="
                                                    true
                                                  "
                                                  formControlName="addressType"
                                                >
                                                  <ng-option value="Business"
                                                    >Business</ng-option
                                                  >
                                                  <ng-option value="Postal"
                                                    >Postal</ng-option
                                                  >
                                                  <ng-option value="Personal"
                                                    >Personal</ng-option
                                                  >
                                                  <ng-option value="Registered"
                                                    >Registered</ng-option
                                                  >
                                                </ng-select>
                                              </td>
                                            </ng-container>
                                            <ng-container
                                              matColumnDef="address"
                                            >
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                              >
                                                Address
                                              </th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                                class="widthemail"
                                              >
                                                <mat-form-field
                                                  floatLabel="never"
                                                  class="wp-100"
                                                >
                                                  <input
                                                    matInput
                                                    [value]="element.address"
                                                    autocomplete="off"
                                                    formControlName="address"
                                                    maxlength="1000"
                                                  />
                                                </mat-form-field>
                                              </td>
                                            </ng-container>
                                            <ng-container matColumnDef="city">
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                              >
                                                City
                                              </th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                              >
                                                <mat-form-field
                                                  floatLabel="never"
                                                  class="wp-100"
                                                >
                                                  <input
                                                    matInput
                                                    [value]="element.city"
                                                    autocomplete="off"
                                                    formControlName="city"
                                                    maxlength="100"
                                                  />
                                                </mat-form-field>
                                              </td>
                                            </ng-container>
                                            <ng-container matColumnDef="county">
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                              >
                                                County
                                              </th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                              >
                                                <mat-form-field
                                                  floatLabel="never"
                                                  class="wp-100"
                                                >
                                                  <input
                                                    matInput
                                                    [value]="element.county"
                                                    autocomplete="off"
                                                    formControlName="county"
                                                    maxlength="255"
                                                  />
                                                </mat-form-field>
                                              </td>
                                            </ng-container>
                                            <ng-container
                                              matColumnDef="country"
                                            >
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                class="w-150"
                                              >
                                                Country
                                              </th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                              >
                                                <ng-select
                                                  #country
                                                  (focus)="country.open()"
                                                  (blur)="country.close()"
                                                  class="w-150 detail-column"
                                                  panelClass="myPanelClass"
                                                  [disableOptionCentering]="
                                                    true
                                                  "
                                                  formControlName="country"
                                                >
                                                  <ng-option
                                                    *ngFor="
                                                      let cl of countryList
                                                    "
                                                    [value]="cl.id"
                                                    >{{ cl.description }}
                                                  </ng-option>
                                                </ng-select>
                                              </td>
                                            </ng-container>
                                            <ng-container
                                              matColumnDef="postalCode"
                                            >
                                              <th
                                                mat-header-cell
                                                *matHeaderCellDef
                                                class="w-100"
                                              >
                                                ZIP/Post Code
                                              </th>
                                              <td
                                                mat-cell
                                                *matCellDef="
                                                  let element;
                                                  let i = index
                                                "
                                                [formGroupName]="i"
                                              >
                                                <mat-form-field
                                                  floatLabel="never"
                                                  class="w-100"
                                                >
                                                  <input
                                                    matInput
                                                    [value]="element.postalCode"
                                                    autocomplete="off"
                                                    type="tel"
                                                    minlength="0"
                                                    maxlength="10"
                                                    formControlName="postalCode"
                                                  />
                                                </mat-form-field>
                                              </td>
                                            </ng-container>
                                            <tr
                                              mat-header-row
                                              *matHeaderRowDef="
                                                displayAddressDetailColumns;
                                                sticky: true
                                              "
                                            ></tr>
                                            <tr
                                              mat-row
                                              *matRowDef="
                                                let row;
                                                columns: displayAddressDetailColumns
                                              "
                                            ></tr>
                                          </table>
                                        </ng-container>
                                      </form>
                                      <div
                                        class="text-align-center space-top"
                                        *ngIf="!addressDetailArray.length"
                                      >
                                        {{
                                          commonNotificationText.noRecordFoundText
                                        }}
                                      </div>
                                    </div>
                                  </div>                                 
                                </div>

                                <div class="hr pt-1 mt-1">
                                  <div class="form-field mb-10">
                                    <p>Assign to users</p>
                                    <mat-checkbox
                                      class="example-margin"
                                      formControlName="addToAllFutureUsers"
                                      >Assign this to all existing users
                                    </mat-checkbox>
                                    <br />
                                    <mat-checkbox
                                      class="example-margin"
                                      formControlName="addToAllExistingUsers"
                                      >Assign this all future users
                                    </mat-checkbox>
                                  </div>
                                </div>
                                <div class="form-field attached">
                                  <p>
                                    Logo <span class="photo-size">(Size: 100x100 px)</span>
                                  </p>
                                  <img
                                    class="mr-1"
                                    [src]="url"
                                    *ngIf="url"
                                    height="100"
                                    width="100px"
                                    alt="Logo"
                                  />
                                  <a class="link" (click)="onDeleteLogo()" *ngIf="isImageSelected">
                                    Delete Logo
                                  </a>
                                  <div class="attachUploadFile"
                                  [ngClass]="isImageSelected ? 'hidden' : ''"
                                  >
                                    <mat-icon fontSet="material-icons-outlined">cloud_upload</mat-icon>
                                    <input
                                      #fileupload
                                      id="selectFile"
                                      type="file"
                                      accept="{{ accept }}"
                                      accept="image/jpg,image/png,image/jpeg"
                                      autocomplete="off"
                                      maxlength="100"
                                      formControlName="logo"
                                      class="cursor-pointer"
                                      (change)="onFileSelected($event)"
                                    />
                                    <label for="file" class="cursor-pointer"
                                      ><strong>Choose a file</strong> or drag it here</label
                                    >
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel
                  [expanded]="true"
                  [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title> Add Accounting Period </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="content-body">
                    <h3 class="text-gray-200 font-size-18">
                      Accounting Period
                    </h3>
                    <app-add-financial-year
                      [triggerEditFinancialData]="triggerEditFinancialData"
                      [businessStartDate]="businessStartDate"
                    ></app-add-financial-year>
                  </div>
                </mat-expansion-panel>

                <mat-expansion-panel
                  [expanded]="true"
                  [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title> Branch and Department </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="content-body">
                    <div class="d-flex justify-space-between mb-1 mt-2">
                      <p class="font-size-16 fw-bold m-0">
                        Is this Client Allowed to add branch and department?
                      </p>
                      <div
                        class="radio-button d-flex align-items-center gap-10"
                      >
                        <span class="fw-bold">{{ tooggleBranchDepText }}</span>
                        <mat-slide-toggle
                          (change)="addBranchDep()"
                        ></mat-slide-toggle>
                      </div>
                    </div>
                    <div [hidden]="!isAddBranchDep">
                      <div class="d-flex gap-40">
                        <div class="form-field wp-100">
                          <h3 class="text-gray-200 font-size-18 pt-1">
                            Branch Details
                          </h3>
                          <p>Branch</p>
                          <app-add-branch
                            [triggerEditBranchData]="triggerEditBranchData"
                            [triggerSetDefaultBranch]="triggerSetDefaultBranch"
                          ></app-add-branch>
                        </div>
                        <div class="form-field wp-100">
                          <h3 class="text-gray-200 font-size-18 pt-1">
                            Department Details
                          </h3>
                          <p>Department</p>
                          <app-add-department
                            [triggerEditDepartmentData]="
                              triggerEditDepartmentData
                            "
                          ></app-add-department>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
        <div class="action-wrapper" *ngIf="!(isViewPermission$ | async)">
          <div class="content-body">
            <button
              class="action-button primary-button mr-1"
              type="button"
              mat-button
              disableRipple
              (click)="onSave(false)"
            >
              Save
            </button>
            <button
              class="action-button primary-button mr-1"
              type="button"
              mat-button
              disableRipple
              (click)="onSave(true)"
            >
              Save and Exit
            </button>
            <button
              class="action-button secondary-button mr-1"
              type="button"
              mat-button
              disableRipple
              (click)="onCancel(true)"
            >
              Cancel Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
