export enum AuthorizedRole {
  Owner = 1,
  Admin = 2,
  Manager = 3,
  'Regular User' = 4,
  'Client User' = 5,
}

export enum AuthorizedRoleName {
  Owner = 'Owner',
  Admin = 'Administrator',
  Manager = 'Manager',
  User = 'Regular User',
}
