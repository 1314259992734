<div class="content-body p-0 pt-1 mb-4">
  <div class="template-pdf-wrap">
    <div class="w-387">
      <p class="text-primary font-size-16 fw-bold mb-2">
        Edit Invoice Template
      </p>
      <div class="template-slider">
        <ngx-slick-carousel
          class="carousel"
          #slickModal="slick-carousel"
          [config]="slideConfig"
        >
            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
              <div class="text-align-center">
                <img src="{{ slide.img }}" alt="Preview" />
                <mat-radio-group aria-label="Select an option">
                  <mat-radio-button [value]="false">{{
                    slide.title
                  }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
        </ngx-slick-carousel>
      </div>

      <div class="pdf-temp-form">
        <div class="form-field">
          <p>Template Name</p>
          <mat-form-field>
            <input matInput autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Page Size</p>
          <ng-select
            #Page
            (focus)="Page.open()"
            (valueChange)="Page.close()"
            [disableOptionCentering]="true"
            panelClass="mat-select-position"
          >
            <ng-option>Letter</ng-option>
            <ng-option>A4</ng-option>
          </ng-select>
        </div>
        <div class="form-field">
          <p>Title (Invoice)</p>
          <mat-form-field>
            <input matInput autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Title (Draft)</p>
          <mat-form-field>
            <input matInput autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Margin Top (pt)</p>
          <mat-form-field class="w-50">
            <input matInput autocomplete="off" value="25" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Margin Bottom (pt)</p>
          <mat-form-field class="w-50">
            <input matInput autocomplete="off" value="25" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Margin Left (pt)</p>
          <mat-form-field class="w-50">
            <input matInput autocomplete="off" value="25" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Margin Right (pt)</p>
          <mat-form-field class="w-50">
            <input matInput autocomplete="off" value="25" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Title (Credit Note)</p>
          <mat-form-field>
            <input matInput autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Bank Name</p>
          <ng-select
            #bank
            (focus)="bank.open()"
            (valueChange)="bank.close()"
            [disableOptionCentering]="true"
            panelClass="mat-select-position"
          >
            <ng-option>Barclays Bank</ng-option>
            <ng-option>Natwest Bank</ng-option>
            <ng-option>ICICI international Banking</ng-option>
            <ng-option>Cash in Hand</ng-option>
          </ng-select>
        </div>
        <div class="form-field">
          <p>Title (Paid)</p>
          <mat-form-field>
            <input matInput autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>Footer Height</p>
          <mat-form-field>
            <input matInput autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="form-field">
          <p>VAT Reg.No</p>
          <mat-checkbox></mat-checkbox>
        </div>
        <div class="form-field">
          <p>Company Reg.No</p>
          <mat-checkbox></mat-checkbox>
        </div>
      </div>
    </div>
    <div class="wp-100">
      <div class="mb-1">
        <angular-editor id="editor1" [config]="editorConfig"> </angular-editor>
      </div>
      <div class="mb-1">
        <angular-editor id="editor1" [config]="editorConfig"> </angular-editor>
      </div>
    </div>
  </div>
</div>

<div class="action-wrapper button-action-wrapper">
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="openPreviewInvoice()"
    >
      Preview
    </button>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
    >
      Send
    </button>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
    >
      Download
    </button>
  </div>
</div>
