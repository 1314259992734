import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  DashboardAgeingSummaryType,
  Modules,
  OverviewYear,
  RoutingPath,
} from '@app/core/Enum';
import { FilterDateRange, MainListParameters } from '@app/core/Models';
import { CommonService, DashboardService } from '@app/core/Services';
import {
  GetBranchList,
  GetDashboardCard,
  GetDepartmentListByBranchIds,
  MenuState,
} from '@app/core/Store';
import { Select, Store } from '@ngxs/store';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-v1',
  templateUrl: './dashboard-v1.component.html',
  styleUrls: ['./dashboard-v1.component.scss'],
})
export class DashboardComponentv1 implements OnInit {
  moduleId = Modules.Dashboard;
  module = Modules;
  @Output()
  readonly triggerHeaderFilter = new EventEmitter<any>();

  @Output()
  readonly triggerAgeingSummary = new EventEmitter<any>();

  selectedYearValue = OverviewYear['This year'];
  selectYear = OverviewYear;
  rangeFormGroup: FormGroup;
  ageingSummaryValue = DashboardAgeingSummaryType.Receivables.toString();
  dashboardCard: any;

  showCalender = false;
  dateRange: any;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;
  @ViewChild('branch')
  public mulObj: MultiSelectComponent;
  public branchmode: string;
  public branchfilterPlaceholder: string;
  public branchFields: Object = { text: 'name', value: 'id' };
  public branchWaterMark: string = 'Branch - All';
  public branchpopHeight: string = '350px';

  public departmentmode: string;
  public departmentfilterPlaceholder: string;
  public departmentFields: Object = { text: 'name', value: 'id' };
  public departmentWaterMark: string = 'Department - All';
  public departmentpopHeight: string = '350px';

  selectedBranchValues: any[] = [];
  selectedDepartmentValues: any[] = [];
  departmentList: any[] = [];
  branchList: any[] = [];
  departmentListParameters: MainListParameters = new MainListParameters();
  branchListParameters: MainListParameters = new MainListParameters();
  private setDashboardDetailsEmitter;
  constructor(
    private store: Store,
    private router: Router,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    public commonService: CommonService,
    private spinner: NgxSpinnerService,
    public dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.dateRange = this.commonService.generateDateSeries(
      this.selectedYearValue
    );

    this.moduleId$.subscribe((moduleId) => {
      if (moduleId === Modules.Dashboard) {
        this.setForm();
        this.setBranchDetails();
      }
    });

    this.setDashboardDetailsEmitter =
      this.dashboardService.setReloadDashboardEmitter.subscribe((isReload) => {
        this.setForm();
        this.setBranchDetails();
      });
  }

  setBranchDetails(): void {
    this.branchmode = 'CheckBox';
    this.branchfilterPlaceholder = 'Search Branch';
    this.departmentmode = 'CheckBox';
    this.departmentfilterPlaceholder = 'Search Department';
    this.getBranchList();
  }

  ngOnDestroy(): void {
    if (
      this.setDashboardDetailsEmitter !== null &&
      this.setDashboardDetailsEmitter !== undefined
    ) {
      this.setDashboardDetailsEmitter.unsubscribe();
    }
  }

  getDepartmentListByBranchIds(): void {
    this.store
      .dispatch(new GetDepartmentListByBranchIds(this.selectedBranchValues))
      .subscribe((res) => {
        this.departmentList = [];
        if (res.company.departmentListbyIds.length > 0) {
          res.company.departmentListbyIds.forEach((element) => {
            if (this.departmentList.length === 0) {
              this.departmentList.push(element);
            } else {
              const isExist = this.departmentList.filter(
                (x) => x.id === element.id
              );
              if (isExist.length === 0) {
                this.departmentList.push(element);
              }
            }
          });
        }
      });
  }

  onDepartmentMultiSelectChange(event: any): void {
    this.selectedDepartmentValues = event.value;

    this.onSelectionChange(this.selectedYearValue);
  }

  onBranchMultiSelectChange(event: any): void {
    this.selectedBranchValues = event.value;
    if (this.selectedBranchValues.length > 0) {
      this.getDepartmentListByBranchIds();
    } else {
      this.departmentList = [];
      this.selectedDepartmentValues = [];
    }

    this.onSelectionChange(this.selectedYearValue);
  }

  getBranchParamaters(): any {
    const queryParams = {
      sortBy: this.branchListParameters.sortBy,
      sortOrder: this.branchListParameters.sortOrder,
      search: this.branchListParameters.search,
    };

    return queryParams;
  }

  getBranchList(): void {
    this.store
      .dispatch(new GetBranchList(this.getBranchParamaters()))
      .pipe(
        tap((res) => {
          this.branchList = [];
          res.company.branch.forEach((element) => {
            this.branchList.push(element);
          });
        })
      )
      .subscribe();
  }

  setForm(): void {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.rangeFormGroup = this.formBuilder.group({
      start: [startDate],
      end: [new Date()],
    });

    this.getData();
  }

  ageingSummaryChange(): void {
    const data = this.getFilter();
    data.moduleId = this.ageingSummaryValue;
    this.triggerAgeingSummary.emit(data);
  }

  goToBankBook(): void {
    this.router.navigate([RoutingPath.BankDashboard]);
  }

  goToCashBook(): void {
    this.router.navigate([RoutingPath.CashEntryList]);
  }

  onSelectionChange(val: any): void {
    this.showCalender = +val === 7;
    this.dateRange = this.commonService.generateDateSeries(val);
    if (
      this.showCalender &&
      this.rangeFormGroup.controls.start.value !== null &&
      this.rangeFormGroup.controls.end.value !== null
    ) {
      this.dateRange = new FilterDateRange();
      this.dateRange.startDate = new Date(
        this.rangeFormGroup.controls.start.value
      );
      this.dateRange.endDate = new Date(this.rangeFormGroup.controls.end.value);
      this.getData();
    } else if (!this.showCalender) {
      this.getData();
    }
    this.getCardData();
  }

  getFilter(): any {
    const filterData = {
      moduleId: 0,
      dateFilter: {
        filter: this.selectedYearValue,
        startDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(
                  this.rangeFormGroup.controls.start.value,
                  'yyyy-MM-dd'
                )
                ?.toString(),
        branchIds: this.selectedBranchValues ?? [],
        departmentIds: this.selectedDepartmentValues ?? [],
        endDate:
          this.selectedYearValue < 7
            ? undefined
            : this.datepipe
                .transform(this.rangeFormGroup.controls.end.value, 'yyyy-MM-dd')
                ?.toString(),
      },
    };

    return filterData;
  }

  getData(): void {
    this.getCardData();
    const data = this.getFilter();

    setTimeout(() => {
      this.triggerHeaderFilter.emit(data);
      data.moduleId = this.ageingSummaryValue;
      this.triggerAgeingSummary.emit(data);
    }, 500);
  }

  getCardData(): void {
    const data = this.getFilter();
    this.store.dispatch(new GetDashboardCard(data)).subscribe((res) => {
      this.spinner.show();
      this.dashboardCard = res.dashboard.dashboardCard;
    });
  }
}
