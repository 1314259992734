import { ReportFilterTypes } from '@app/core/Enum';

export class OverviewModel {
  pieChart: PieChartModel;
  barChart: BarChartDetailModel;
  barChart1: BarChartDetailModel;
}

export class BarChartDetailModel {
  series: Array<BarChartSeries>;
  Categories: Array<string>;
}

export class BarChartSeries {
  name: string;
  data: Array<number>;
}

export class PieChartModel {
  series: Array<number>;
  category: Array<string>;
}

export class OverviewFilterModel {
  filter1: FilterModel;
  filter2: FilterModel;
  filter3: FilterModel;
}

export class FilterModel {
  filter: ReportFilterTypes;
  startDate?: Date;
  endDate?: Date;
}

export class DashboardSummary {
  totalIncome: number;
  totalExpenditure: number;
  totalExpenses: number;
  bankBalance: number;
  cashInHand: number;
  receivable: number;
  payable: number;
}

export class ReportDetail {
  name: string;
  count: number;
  amount: number;
  percentage: number;
}

export class DashboardModel {
  income: PieChartModel;
  expense: PieChartModel;
  receivable: BarChartDetailModel;
  payable: BarChartDetailModel;
  incomeExpense: DashboardIncomeExpenseModel;
  dashboard: DashboardDetailModel;
  cashFlow: CashFlowDetailModel;
}

export class DashboardIncomeExpenseModel {
  income: BarChartDetailModel;
  expense: BarChartDetailModel;
}

export class DashboardDetailModel {
  income: DashboardFilterModel;
  expense: DashboardFilterModel;
  bank: DashboardFilterModel;
  cashInHand: DashboardFilterModel;
}

export class DashboardFilterModel {
  totalAmount: number;
  amount: number;
}

export class CashFlowBarChatModel {
  income: Array<number>;
  expense: Array<number>;
}

export class CashFlowDetailModel {
  series: Array<CashFlowBarChatModel>;
  Categories: Array<string>;
}

export class FilterDateRange {
  startDate: Date;
  endDate: Date;
}

export class DashboardVATSummary {
  fromDate: Date;
  toDate: Date;
  status: number;
  vatAmount: number;
}
