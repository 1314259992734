<div class="content button-with-pagination">
  <div class="content-body">
    <app-transactions-header
      (triggerDataFromHeader)="getDataFromHeader($event)"
      [listParameters]="listParameters"
    >
    </app-transactions-header>

    <div>
      <app-bank-dashboard
        (triggerDataFromBank)="getDataFromBank($event)"
        (triggerIsCustomFlag)="checkIsCustom($event)"
      ></app-bank-dashboard>
    </div>

    <div
      class="mat-table-wrapper"
      [ngClass]="[showPaginator ? '' : 'mat-table-wrapper-pagination-full']"
    >
      <table
        [style.display]="'none'"
        aria-describedby="Bank-Reconcillation-List"
        mat-table
        [dataSource]="reconciliationList"
      >
        <ng-container matColumnDef="srNo">
          <th mat-header-cell *matHeaderCellDef class="w-20">Sr No.</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="d-flex">
              <mat-checkbox [(ngModel)]="element.isSelected"> </mat-checkbox>
              {{ i + 1 }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="transactionCategory">
          <th mat-header-cell *matHeaderCellDef class="w-40">
            Transaction Category
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              {{ element.transactionCategory }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="transactionType">
          <th mat-header-cell *matHeaderCellDef class="w-30">
            Transaction Type
          </th>
          <td mat-cell *matCellDef="let element" class="widthemail">
            {{ element.transactionType }}
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef class="w-40">Description</th>

          <td mat-cell *matCellDef="let element" class="widthemail">
            {{ element.description }}
          </td>
        </ng-container>
        <ng-container matColumnDef="totalAmount">
          <th mat-header-cell *matHeaderCellDef class="w-30">Total Amount</th>
          <td mat-cell *matCellDef="let element">
            £ {{ element.amount | numberPipe }}
          </td>
        </ng-container>
        <ng-container matColumnDef="accounts">
          <th mat-header-cell *matHeaderCellDef class="w-40">Accounts</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field appearance="fill">
              <input
                [(ngModel)]="element.accountValue"
                type="text"
                matInput
                [required]="element.isSelected"
                [matAutocomplete]="accountGroup"
                #trigger="matAutocompleteTrigger"
                appAutocompletePosition="trigger"
                (keyup)="onAccountSearch($event)"
                (mousedown)="resetAccountList(element)"
              />
              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
              <mat-autocomplete
                [displayWith]="getOptionText"
                #accountGroup="matAutocomplete"
                (opened)="scrollIntoView(element)"
                (optionSelected)="accountSelect($event, element)"
              >
                <mat-optgroup
                  *ngFor="let group of accountList"
                  [label]="group.groupName"
                  title="{{ group.groupName }}"
                >
                  <mat-option
                    *ngFor="let names of group.listModels"
                    [value]="names"
                    title="{{ names.name }}"
                    [class]="
                      element.accountValue?.id === names.id
                        ? 'mat-selected'
                        : ''
                    "
                  >
                    {{ names.name }}
                  </mat-option>
                </mat-optgroup>
              </mat-autocomplete>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="addButton">
          <th mat-header-cell *matHeaderCellDef class="w-15 text-align-center">
            Explain
          </th>
          <td mat-cell *matCellDef="let element" class="text-align-center">
            <button
              *ngIf="element.isAdd"
              class="close-btn"
              type="button"
              mat-button
              disableRipple
              (click)="clickReconciltionDetails(element)"
            >
              <mat-icon>toc</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayReconciliationColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayReconciliationColumns"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell"
            [ngClass]="'text-align-center'"
            [attr.colspan]="displayReconciliationColumns.length"
          >
            <span *ngIf="listParameters">No records found</span>
          </td>
        </tr>
      </table>

      <table
        aria-describedby="Bank-Reconcillation-List"
        mat-table
        [dataSource]="reconciliationList"
      >
        <ng-container matColumnDef="srNo">
          <th mat-header-cell *matHeaderCellDef class="w-20">Sr No.</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="d-flex">
              {{ i + 1 }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="transactionCategory">
          <th mat-header-cell *matHeaderCellDef class="w-40">
            Transaction Category
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex">
              {{ element.transactionCategory }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="transactionType">
          <th mat-header-cell *matHeaderCellDef class="w-30">
            Transaction Type
          </th>
          <td mat-cell *matCellDef="let element" class="widthemail">
            {{ element.transactionType }}
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef class="w-40">Description</th>

          <td mat-cell *matCellDef="let element" class="widthemail">
            {{ element.description }}
          </td>
        </ng-container>
        <ng-container matColumnDef="totalAmount">
          <th mat-header-cell *matHeaderCellDef class="w-30">Total Amount</th>
          <td mat-cell *matCellDef="let element">
            £ {{ element.amount | numberPipe }}
          </td>
        </ng-container>

        <ng-container matColumnDef="explain">
          <th mat-header-cell *matHeaderCellDef class="w-30">Explain</th>
          <td mat-cell *matCellDef="let element">
            <ng-select
              class="w-150"
              *ngIf="
                element.transactionType === 'CREDIT' &&
                element.referenceTypeId == null
              "
              #receipt
              (focus)="receipt.open()"
              (valueChange)="receipt.close()"
              [disableOptionCentering]="true"
              panelClass="mat-select-position"
              appendTo="body"
              (change)="receiptPaymentChange($event, element)"
            >
              <ng-option *ngFor="let fn of receiptList" [value]="fn">
                <div title="{{ fn.name }}">{{ fn.name }}</div>
              </ng-option>
            </ng-select>

            <ng-select
              class="w-150"
              *ngIf="
                element.transactionType === 'DEBIT' &&
                element.referenceTypeId == null
              "
              #payment
              (focus)="payment.open()"
              (valueChange)="payment.close()"
              [disableOptionCentering]="true"
              panelClass="mat-select-position"
              appendTo="body"
              (change)="receiptPaymentChange($event, element)"
            >
              <ng-option *ngFor="let fn of paymentList" [value]="fn">
                <div title="{{ fn.name }}">{{ fn.name }}</div>
              </ng-option>
            </ng-select>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayReconciliationColumns_v1; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayReconciliationColumns_v1"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell"
            [ngClass]="'text-align-center'"
            [attr.colspan]="displayReconciliationColumns_v1.length"
          >
            <span *ngIf="listParameters">No records found</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div
    class="paginator-wrapper"
    *ngIf="totalSelectedRecords <= 0 && (totalRecord$ | async) > 0"
  >
    <app-custom-paginator
      *ngIf="(totalRecord$ | async) > 10"
      [length]="totalRecord$ | async"
      [listParameters]="listParameters"
      (pageChanged)="pageChanged($event)"
      (pageSizeVal)="pageSizeVal($event)"
      (togglePaginator)="togglePaginator($event)"
      [triggerPaginationChange]="triggerPaginationChange"
    >
    </app-custom-paginator>
  </div>
  <app-buttons
    [getModuleId]="moduleId"
    (triggerOnSaveClick)="onSave()"
    (triggerOnCancelClick)="onCancel(true)"
  >
  </app-buttons>
</div>
