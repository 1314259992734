<div class="mat-table-wrapper">
    <table
      mat-table
      [dataSource]="dataSource"
      aria-describedby="list Table"
    >
      <ng-container matColumnDef="contact">
        <th mat-header-cell *matHeaderCellDef>Contact</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex">
            <a class="link text-primary wmx-100 clamp" title="{{ element.contact }}">
              {{ element.contact }}</a>
          </div>            
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">
            <span class="white-space-nowrap">{{ element.date }}</span></td>
      </ng-container> 
      <ng-container matColumnDef="refno">
        <th mat-header-cell *matHeaderCellDef>Ref. No.</th>
        <td mat-cell *matCellDef="let element">
            <a class="link text-primary wmx-100 clamp">{{ element.refno }}</a></td>
      </ng-container>
      <ng-container matColumnDef="netamount">
        <th mat-header-cell *matHeaderCellDef class="text-align-right">Net Amount</th>
        <td mat-cell *matCellDef="let element" class="text-align-right">
            <span class="white-space-nowrap">£ {{ element.netamount }}</span></td>
      </ng-container>
      <ng-container matColumnDef="vatamount">
        <th mat-header-cell *matHeaderCellDef class="text-align-right">VAT Amount</th>
        <td mat-cell *matCellDef="let element" class="text-align-right">
            <span class="white-space-nowrap">£ {{ element.vatamount }}</span></td>
      </ng-container>
      <ng-container matColumnDef="totalamount">
        <th mat-header-cell *matHeaderCellDef class="text-align-right">Total Amount</th>
        <td mat-cell *matCellDef="let element" class="text-align-right">
            <span class="white-space-nowrap">£ {{ element.totalamount }}</span></td>
      </ng-container>
                    
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <ng-container matColumnDef="groupHeader">
        <td colspan="999" mat-cell *matCellDef="let groupBy">
            <span class="text-lightgray fw-bold">{{groupBy.initial}}</span>
        </td>
      </ng-container>              
      <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>

    </table>
  </div>
  <div class="mt-10">
    <div class="comon-detail">
        <div class="comon-form">
            <h2>VAT on Sales</h2>
            <div>
              <div class="payment-detail">
                  <div class="payment-text">Net Amount</div>
                  <div class="payment-text">£ 2,000.00</div>
              </div>
              <div class="payment-detail">
                <div class="payment-text">VAT Amount</div>
                <div class="payment-text">£ 0.00</div>
              </div>
              <div class="payment-detail">
                <div class="payment-text">Total Amount</div>
                <div class="payment-text">£ 2,000.00</div>
              </div>
            </div>
        </div>
        <div class="comon-form">
            <h2>VAT on Purchases</h2>
            <div>
              <div class="payment-detail">
                  <div class="payment-text">Net Amount</div>
                  <div class="payment-text">£ 2,000.00</div>
              </div>
              <div class="payment-detail">
                <div class="payment-text">VAT Amount</div>
                <div class="payment-text">£ 0.00</div>
              </div>
              <div class="payment-detail">
                <div class="payment-text">Total Amount</div>
                <div class="payment-text">£ 2,000.00</div>
              </div>
            </div>
        </div>
    </div>
    <div class="comon-detail">
      <div class="comon-form">
          <h2>VAT Amount : <span>(£62.50)</span></h2>
        </div>
    </div>
  </div>