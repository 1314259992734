<app-menu-items></app-menu-items>
<div
  class="content-body content-body-scroll"
  [ngClass]="{ 'mb-4': (totalRecord$ | async)! > 10 && showPaginator }"
>
  <div class="sidenav-body-content">
    <div class="main-list content">
      <div class="d-flex align-items-end wp-100 mb-1 gap-10">
        <button
          class="action-button primary-button border-radius-small w-150"
          mat-button
          disableRipple
          (click)="onAddClick()"
          [disabled]="isViewPermission$ | async"
        >
          <mat-icon>add</mat-icon>
          Add Client
        </button>
        <div class="grid-search">
          <mat-form-field appearance="fill" class="search-field">
            <input
              matInput
              placeholder="Search"
              autocomplete="off"
              (keyup)="onSearch($event)"
            />
            <button mat-button disableRipple class="search-button">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="header-actions wp-100">
          <div
            class="align-items-end content-header d-flex header-count header-filters justify-content-end header-count-1"
          >
            <div class="toolbar d-flex align-items-center gap-10">
              <ng-select
                [(ngModel)]="defaultFilterStatus"
                #selectType
                (focus)="selectType.open()"
                (valueChange)="selectType.close()"
                panelClass="myPanelClass"
                [disableOptionCentering]="true"
                (change)="onFilterChange($event)"
              >
                <ng-option
                  *ngFor="let sf of statusFilter | useEnumValues"
                  [value]="sf.key"
                >
                  {{ sf.value }}
                </ng-option>
              </ng-select>
              <button mat-button [matMenuTriggerFor]="menu" disableRipple>
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu
                #menu="matMenu"
                class="action-menu more-menu"
                xPosition="before"
              >
                <button
                  mat-menu-item
                  disableRipple
                  (click)="export(exportType.PDF, false)"
                  [disabled]="isViewPermission$ | async"
                  [ngClass]="
                    (isViewPermission$ | async) ? 'cursor-not-allowed' : ''
                  "
                >
                  <mat-icon fontSet="material-icons-outlined"
                    >picture_as_pdf</mat-icon
                  >Export As PDF
                </button>
                <button
                  mat-menu-item
                  disableRipple
                  (click)="export(exportType.Excel, false)"
                  [disabled]="isViewPermission$ | async"
                  [ngClass]="
                    (isViewPermission$ | async) ? 'cursor-not-allowed' : ''
                  "
                >
                  <mat-icon>border_all</mat-icon>Export As Excel
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
      <mat-icon class="material-icons-outlined">error_outline</mat-icon>
      {{ commonNotificationText.ViewOnlyAccessMessage }}
    </div>
    <div
      class="mat-table-wrapper"
      [ngClass]="[
        totalSelectedRecords > 0 ? 'mat-table-wrapper-full' : '',
        totalSelectedRecords <= 0 && (totalRecord$ | async)! > 0
          ? 'mat-table-wrapper-pagination'
          : ''
      ]"
    >
      <table
        mat-table
        [dataSource]="companyList"
        matSort
        (matSortChange)="sorting(sort.active, sort.direction)"
        aria-describedby="Company details table"
      >
        <ng-container matColumnDef="clientCode">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            <mat-checkbox
              [(ngModel)]="isAllSelected"
              (change)="selectAll($event)"
              (click)="$event.stopPropagation()"
            ></mat-checkbox
            >ID
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [(ngModel)]="element.isSelected"
              (change)="onCheckBoxSelected(element, $event)"
            ></mat-checkbox>
            <a
              class="link"
              (click)="onClientChange(element)"
              [ngClass]="!element.isArchived ? '' : 'link-disable'"
              >{{ element.clientCode }}</a
            >
          </td>
        </ng-container>
        <ng-container matColumnDef="companyName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Client</th>
          <td mat-cell *matCellDef="let element">{{ element.companyName }}</td>
        </ng-container>
        <ng-container matColumnDef="contactName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Contact Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.contactName }}</td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>
        <ng-container matColumnDef="phone">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Phone No</th>
          <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
        </ng-container>
        <ng-container matColumnDef="pay">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>CapiumPay</th>
          <td mat-cell *matCellDef="let element">
            <p class="link p-0">{{ element.pay }}</p>
          </td>
        </ng-container>
        <ng-container matColumnDef="isArchived">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex align-items-center justify-space-between">
              <div
                [ngClass]="
                  element.isArchived ? 'status-archived' : 'status-active'
                "
              >
                <span *ngIf="!element.isArchived">
                  {{ "Active" }}
                </span>
                <span *ngIf="element.isArchived">
                  {{ "Archived" }}
                </span>
              </div>
              <div class="d-flex align-items-center grid-actions">
                <button
                  mat-button
                  disableRipple
                  title="Edit"
                  (click)="onClientEdit(element)"
                >
                  <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                </button>
                <button
                  mat-button
                  disableRipple
                  title="Delete"
                  (click)="onClientDelete(element)"
                  [disabled]="isViewPermission$ | async"
                >
                  <mat-icon fontSet="material-icons-outlined"
                    >delete_outline</mat-icon
                  >
                </button>
              </div>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell"
            [ngClass]="'text-align-center'"
            [attr.colspan]="displayedColumns.length"
          >
            <span
              *ngIf="
                listParameters &&
                !(listParameters.search || listParameters.filter)
              "
              >{{ commonNotificationText.NoRecordsFound }}</span
            >
            <span
              *ngIf="
                listParameters &&
                (listParameters.search || listParameters.filter)
              "
              >{{ commonNotificationText.NoRecordsFoundForFilter }}</span
            >
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<div
  class="paginator-wrapper"
  *ngIf="totalSelectedRecords <= 0 && (totalRecord$ | async)! > 0"
>
  <app-custom-paginator
    *ngIf="(totalRecord$ | async)! >= 10"
    [length]="totalRecord$ | async"
    [listParameters]="listParameters"
    (pageChanged)="pageChanged($event)"
    (pageSizeVal)="pageSizeVal($event)"
    (togglePaginator)="togglePaginator($event)"
  >
  </app-custom-paginator>
</div>
<app-card-footer
  *ngIf="totalSelectedRecords > 0"
  [totalSelectedRecords]="totalSelectedRecords"
  [getModuleId]="moduleId"
  (deleteClick)="onClientDelete()"
  (exportClick)="export($event, false)"
  (cancelSelectionClick)="cancelSelectionClick()"
  (printClick)="printClick()"
></app-card-footer>
