<div class="content-body content-body-scroll bank-overview">
  <div class="content-header">
    <div class="content-header-input">
      <div class="d-flex justify-space-between align-items-end wp-100 mb-15">
        <div *ngIf="!isFinalReturnSubmitted">
          <div class="d-flex justify-space-between wp-100">
            <button
              class="action-button primary-button mr-10"
              mat-button
              disableRipple
              (click)="onAddClick()"
              [disabled]="isViewPermission$ | async"
            >
              <mat-icon>add</mat-icon>
              <span> VAT Period </span>
            </button>
            <div
              *ngIf="moduleId === moduleEnum.SubmitVat"
              class="d-flex align-items-center justify-space-between"
            >
              <button
                mat-button
                (click)="$event.stopPropagation()"
                [matMenuTriggerFor]="menu"
                disableRipple
              >
                <mat-icon class="text-yellow-200 highlight-icon"
                  >emoji_objects</mat-icon
                >
              </button>
              <mat-menu #menu="matMenu" class="highlight" xPosition="before">
                <div class="highlight-wrapper">
                  <p class="font-size-16 fw-bold">MTD VAT Sumission</p>
                  <ul>
                    <li>
                      Add a VAT Period from <strong>VAT Period</strong> button
                      available adjacent to this screen
                    </li>
                    <li>Setup the VAT period using the wizard</li>
                    <li>
                      Continue for HMRC VAT submission by including / excluding
                      the required transactions imported
                    </li>
                    <li>Review and then submit to Capium from this page</li>
                    <li>
                      Finally move forward to the last step of the wizard by
                      entering your HMRC Gateway details to send the submission.
                    </li>
                  </ul>
                  More info please
                  <a class="link mr-1" (click)="onSubmitVATMoreInfoClick()"
                    >Click Here</a
                  >
                </div>
              </mat-menu>
            </div>
            <div
              *ngIf="moduleId === moduleEnum.BridgingVAT"
              class="d-flex align-items-center justify-space-between"
            >
              <button
                mat-button
                (click)="$event.stopPropagation()"
                [matMenuTriggerFor]="menu"
                disableRipple
              >
                <mat-icon class="text-yellow-200 highlight-icon"
                  >emoji_objects</mat-icon
                >
              </button>
              <mat-menu #menu="matMenu" class="highlight" xPosition="before">
                <div class="highlight-wrapper">
                  <p class="font-size-16 fw-bold">Bridging Sumission</p>
                  <ul>
                    <li>Prepare VAT submission in VT for example or Excel</li>
                    <li>
                      Generate/Export the Excel.Excel should be in the pre
                      defined format
                    </li>
                    <li>Check and review the 9-box summary</li>
                    <li>Once summary is confirmed, submit to HMRC</li>
                  </ul>
                  More info please
                  <a class="link mr-1" (click)="onBridgingVATMoreInfoClick()"
                    >Click Here</a
                  >
                </div>
              </mat-menu>
            </div>
          </div>
        </div>
        <div class="top-list-wrapper wp-100" *ngIf="isFinalReturnSubmitted">
          <div class="list-card">
            <em class="fa-fw fa fa-check"></em>
            <span
              >The VAT Period ({{
                finalReturn?.fromDate | date: "dd-MMM-yyyy"
              }}
              - {{ finalReturn?.toDate | date: "dd-MMM-yyyy" }}) has been marked
              as the Final VAT period, hence you can not create any further VAT
              period.</span
            >
            <br />
            <a
              href="javascript:void(0);"
              (click)="finalVATUpdate()"
              class="link fw-bold"
            >
              Click here to remove Final VAT
            </a>
          </div>
        </div>

        <div
          class="content-header d-flex align-items-end justify-space-between header-filters header-count header-count-1"
        ></div>
      </div>
      <div
        class="mat-table-wrapper"
        [ngClass]="{
          'mat-table-wrapper-noData': vatSubmitNoDataFound
        }"
      >
        <table
          mat-table
          [dataSource]="vatSubmit"
          aria-describedby="VatSubmit"
          mat-table
          matSort
        >
          <ng-container matColumnDef="from">
            <th mat-header-cell *matHeaderCellDef>From</th>
            <td
              mat-cell
              *matCellDef="let element"
              class="clamp"
              title="{{ element.fromDate }}"
            >
              <a class="link clamp wmx-100" (click)="vatView(element.id)">
                {{ element.fromDate | date: "dd-MMM-yyyy" }}
              </a>
            </td>
          </ng-container>
          <ng-container matColumnDef="to">
            <th mat-header-cell *matHeaderCellDef>To</th>
            <td
              mat-cell
              *matCellDef="let element"
              class="clamp"
              title="{{ element.toDate }}"
            >
              {{ element.toDate | date: "dd-MMM-yyyy" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td
              mat-cell
              *matCellDef="let element"
              class="clamp"
              title="{{ element.description }}"
            >
              {{ element.description }}
            </td>
          </ng-container>
          <ng-container matColumnDef="vatPayable">
            <th mat-header-cell *matHeaderCellDef class="text-align-right pr-3">
              VAT Payable
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="clamp text-align-right pr-3"
              title="{{ element.netVatDue }}"
            >
              £ {{ element.netVatDue | numberPipe }}
            </td>
          </ng-container>
          <ng-container matColumnDef="vatStatus">
            <th mat-header-cell *matHeaderCellDef>Vat Status</th>
            <td mat-cell *matCellDef="let element">
              <span
                [innerHTML]="
                  statusText(element.id, element.status) | viewHtmlContent
                "
              ></span>
            </td>
          </ng-container>
          <ng-container matColumnDef="paymentStatus">
            <th mat-header-cell *matHeaderCellDef>Payment Status</th>
            <td mat-cell *matCellDef="let element">
              <span
                *ngIf="
                  element.netVatDue > 0 &&
                  (element.status === 2 || element.status === 5)
                "
              >
                <a
                  *ngIf="!element.paymentStatus"
                  href="javascript:void(0)"
                  (click)="paymentStatus(element.id, element.netVatDue)"
                >
                  <span
                    *ngIf="!element.paymentStatus"
                    class="badge badge-warning"
                    style="width: 150px; vertical-align: middle"
                    id="vatstatus_{{ element.id }}"
                  >
                    <mat-icon
                      style="color: #f4f7f9 !important; vertical-align: top"
                      >schedule</mat-icon
                    >
                    Pending</span
                  >
                </a>
                <a
                  *ngIf="element.paymentStatus"
                  href="javascript:void(0)"
                  (click)="paymentStatusView(element.id)"
                >
                  <span
                    class="badge badge-success"
                    style="width: 150px; vertical-align: middle"
                    id="vatstatus_{{ element.id }}"
                  >
                    <mat-icon
                      style="color: #f4f7f9 !important; vertical-align: top"
                      >check_box</mat-icon
                    >
                    Paid
                  </span>
                </a>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="w-160"></th>
            <td mat-cell *matCellDef="let element; let i = index">
              <div
                class="grid-actions"
                *ngIf="
                  element.id !== undefined &&
                  element.id !== null &&
                  element.id !== '' &&
                  element.id !== commonService.defaultGuidValue
                "
                [ngClass]="{
                  'show-button': selectedRowIndex === i,
                  'hide-edit-button': !element.isManual
                }"
              >
                <div
                  class="d-flex align-items-center"
                  *ngFor="
                    let item of getActions(element);
                    let permissionsIndex = index
                  "
                >
                  <span>
                    <button
                      *ngIf="permissionsIndex < moreActionCount"
                      mat-button
                      disableRipple
                      title="{{ item.name }}"
                      (click)="onButtonClick(element, item.actionType)"
                    >
                      <mat-icon fontSet="material-icons-outlined">{{
                        item.icon
                      }}</mat-icon>
                    </button>
                  </span>
                  <button
                    mat-button
                    class="more-button"
                    (click)="onToggleMatMenu(i)"
                    [matMenuTriggerFor]="menu"
                    (menuClosed)="allowCloseOnClickOut()"
                    disableRipple
                  >
                    <mat-icon *ngIf="permissionsIndex === moreActionCount"
                      >more_vert</mat-icon
                    >
                  </button>
                </div>

                <mat-menu
                  #menu="matMenu"
                  xPosition="before"
                  class="more-button-menu"
                >
                  <ng-container
                    *ngFor="
                      let item of getActions(element);
                      let permissionsIndex = index
                    "
                  >
                    <div *ngIf="permissionsIndex > 3">
                      <button
                        mat-menu-item
                        disableRipple
                        title="{{ item.name }}"
                        (click)="onButtonClick(element, item.actionType)"
                      >
                        <mat-icon fontSet="material-icons-outlined">{{
                          item.icon
                        }}</mat-icon>
                        <span>{{ item.name }} </span>
                      </button>
                    </div>
                  </ng-container>
                </mat-menu>
              </div>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="submitVatDisplayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: submitVatDisplayedColumns"
          ></tr>
        </table>
        <div *ngIf="vatSubmitNoDataFound" class="text-align-center mt-1">
          No records found
        </div>
      </div>
    </div>
    <div class="paginator-wrapper" *ngIf="(totalRecord$ | async) > 0">
      <app-custom-paginator
        *ngIf="(totalRecord$ | async) > 10"
        [length]="totalRecord$ | async"
        [listParameters]="listParameters"
        (pageChanged)="pageChanged($event)"
        (pageSizeVal)="pageSizeVal($event)"
        (togglePaginator)="togglePaginator($event)"
        [triggerPaginationChange]="triggerPaginationChange"
      >
      </app-custom-paginator>
    </div>
  </div>
</div>
