import {
  JournalsModel,
  LinkJournalModel,
  QuickJournalModel,
} from '@app/core/Models';
import { Guid } from 'guid-typescript';
export class CreateJournals {
  static readonly type = '[JOURNALS] Create Journals';

  constructor(public journals: JournalsModel) {}
}

export class GetDataByJournalsId {
  static readonly type = '[JOURNALS] Get Journals Data By Journals Id';

  constructor(public journalsId: Guid) {}
}

export class ArchiveAndRestoreJournals {
  static readonly type = '[JOURNALS] Archive and Restore Selected Journals';

  constructor(public journalsIds: Array<Guid>, public isArchive: boolean) {}
}

export class DeleteJournals {
  static readonly type = '[JOURNALS] Delete Selected Journals';

  constructor(public journalsIds: Array<Guid>) {}
}

export class GetJournalNo {
  static readonly type = '[JOURNALS] Get Journals Number';
}

export class QuickJournal {
  static readonly type = '[JOURNALS] Quick Journal';

  constructor(public quickJournals: QuickJournalModel) {}
}

export class GetMatchingJournal {
  static readonly type = '[JOURNALS] Get Matching Journal';

  constructor(public transactionId: Guid) {}
}

export class LinkJournal {
  static readonly type = '[JOURNALS] Link Journal';

  constructor(public linkJournal: LinkJournalModel) {}
}
