import { Injectable } from '@angular/core';
import { BankTransactionModel } from '@app/core/Models/bank/bank-overview/bank-overview';
import { BankOverviewService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  DeleteBankStatementTransaction,
  GetBankTrasnactions,
} from './bank-overview.action';

export class BankOverviewStateInfo {
  getBankTransactions?: Array<BankTransactionModel>;
  totalRecord?: number;
  actions?: any;
  isBankStatementTransactionDeleted: boolean;
}

@State<BankOverviewStateInfo>({
  name: 'bankOverview',
  defaults: {
    isBankStatementTransactionDeleted: false,
  },
})
@Injectable()
export class BankOverviewState {
  constructor(private bankOverviewService: BankOverviewService) {}

  @Selector()
  static totalRecord(state: BankOverviewStateInfo): number {
    return state.totalRecord ?? 0;
  }

  @Action(GetBankTrasnactions, { cancelUncompleted: true })
  GetBankTrasnactions(
    { patchState }: StateContext<BankOverviewStateInfo>,
    action: GetBankTrasnactions
  ) {
    return this.bankOverviewService.getBankTransctions(action.queryParams).pipe(
      tap({
        next: (result) => {
          patchState({
            getBankTransactions: result.resultSet.data,
            totalRecord: result.paginationModel.totalItemCount,
            actions: result.actions,
          });
        },
      })
    );
  }

  @Action(DeleteBankStatementTransaction)
  deleteBankImportTransactionHistory(
    { getState, patchState }: StateContext<BankOverviewStateInfo>,
    action: DeleteBankStatementTransaction
  ) {
    return this.bankOverviewService
      .deleteBankStatementTransaction(action.ids)
      .pipe(
        tap((res) => {
          patchState({
            isBankStatementTransactionDeleted: res,
          });
        })
      );
  }
}
