import { Injectable } from '@angular/core';
import { BankTransferModel, SideListModel } from '@app/core/Models';
import { BankTransferService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreBankTransfer,
  BankFeedDuplicateTransactionExist,
  CreateBankTransfer,
  DeleteBankTransfer,
  GetBankTransferNumber,
  GetDataByBankTransferId,
} from './bank-transfer.action';

export class BankTransferStateInfo {
  bankTransferData?: BankTransferModel;
  bankTransfer: Array<BankTransferModel>;
  bankTransferId?: Guid;
  bankTransferNo?: string;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  isBankTransferIdAdded?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
  isBankFeedDuplicateTransactionExist?: boolean;
}

@State<BankTransferStateInfo>({
  name: 'bankTransfer',
  defaults: {
    bankTransfer: [],
    bankTransferId: Guid.EMPTY as unknown as Guid,
    exported: false,
    sideListModel: [],
    isBankTransferIdAdded: false,
  },
})
@Injectable()
export class BankTransferState {
  constructor(private bankTransferService: BankTransferService) {}

  @Selector()
  static getBankTransferId(state: BankTransferStateInfo) {
    return state.bankTransferId;
  }

  @Selector()
  static isLastPage(state: BankTransferStateInfo) {
    return state.isLastPage;
  }

  @Action(CreateBankTransfer)
  createBankTransfer(
    { patchState }: StateContext<BankTransferStateInfo>,
    action: CreateBankTransfer
  ) {
    return this.bankTransferService
      .createBankTransfer(action.bankTransfer)
      .pipe(
        tap((res) => {
          patchState({
            bankTransferId: res,
            isBankTransferIdAdded: true,
          });
        })
      );
  }

  @Action(GetDataByBankTransferId)
  getDataByBankTransferId(
    { patchState }: StateContext<BankTransferStateInfo>,
    action: GetDataByBankTransferId
  ) {
    return this.bankTransferService
      .getDataByBankTransferId(action.bankTransferId)
      .pipe(
        tap((res) => {
          patchState({
            bankTransferData: res,
          });
        })
      );
  }

  @Action(DeleteBankTransfer)
  deleteBankTransfer(
    { getState, setState }: StateContext<BankTransferStateInfo>,
    action: DeleteBankTransfer
  ) {
    return this.bankTransferService
      .deleteBankTransfer(action.bankTransferIds)
      .pipe(
        tap((res) => {
          const state = getState();

          const filteredBankTransfers = state.bankTransfer.filter(
            (item) =>
              !action.bankTransferIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          const filteredForSideList = state.sideListModel?.filter(
            (item) =>
              !action.bankTransferIds?.includes(
                item.id ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          setState({
            ...state.bankTransfer,
            bankTransfer: filteredBankTransfers,
            sideListModel: filteredForSideList,
          });
        })
      );
  }

  @Action(ArchiveAndRestoreBankTransfer)
  archiveAndRestoreBankTransfer(
    { getState }: StateContext<BankTransferStateInfo>,
    action: ArchiveAndRestoreBankTransfer
  ) {
    return this.bankTransferService.archiveAndRestoreBankTransfer(
      action.bankTransferIds,
      action.isArchive
    );
  }

  @Action(GetBankTransferNumber)
  getBankTransferNumber({ patchState }: StateContext<BankTransferStateInfo>) {
    return this.bankTransferService.getBankTransferNumber().pipe(
      tap((res) => {
        patchState({
          bankTransferNo: res,
        });
      })
    );
  }

  @Action(BankFeedDuplicateTransactionExist)
  bankFeedDuplicateTransactionExist(
    { patchState }: StateContext<BankTransferStateInfo>,
    action: BankFeedDuplicateTransactionExist
  ) {
    return this.bankTransferService
      .bankFeedDuplicateTransactionExist(action.bankFeedDuplicateTransaction)
      .pipe(
        tap((res) => {
          patchState({
            isBankFeedDuplicateTransactionExist: res,
          });
        })
      );
  }
}
