import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ControlType,
  HeaderFilter,
  ModuleName,
  Modules,
  NotificationTextMessage,
  ReportEnum,
  RoutingPath,
  VatSettings,
} from '@app/core/Enum';
import {
  ExportType,
  GlobalComponent,
  HeaderModel,
  MainListParameters,
} from '@app/core/Models';
import {
  BankDasboardService,
  CommonService,
  ModulePermission,
  NotificationService,
} from '@app/core/Services';
import {
  CheckVatNumber,
  CommonState,
  CreateCurrency,
  CreateCustomAccount,
  CreateUser,
  MenuState,
  SetDefaultVatId,
} from '@app/core/Store';
import {
  BankLinkComponent,
  DepreciationExpandedCollapseComponent,
} from '@app/modules';
import { QuickAddComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-transactions-header',
  templateUrl: './transactions-header.component.html',
  styleUrls: ['./transactions-header.component.scss'],
})
export class TransactionHeaderComponent implements OnInit {
  defaultValue: any[] = [
    { id: -1 },
    { id: -1 },
    { id: -1 },
    { id: -1 },
    { id: -1 },
  ];

  @Output()
  readonly triggerDataFromHeader = new EventEmitter<any>();

  @Input() listParameters: any;

  @Input() dataSource: any;

  @Input() customId: any;

  @Input() showAccountDetails: boolean = false;

  @Select(MenuState.hasAddPermission)
  hasAddPermission$: Observable<boolean>;

  @Select(MenuState.moduleId)
  moduleId$: Observable<number>;

  @Select(MenuState.menu)
  menu$: Observable<any>;

  @Select(MenuState.moduleName)
  moduleName$: Observable<string>;

  controlType = ControlType;
  periodicDateList: any;

  @Select(CommonState.headerList)
  headerList$: Observable<Array<HeaderModel>>;

  customDate = false;

  HeaderFilters = HeaderFilter;
  moduleEnum = Modules;
  moduleId: number;
  headerData: MainListParameters = new MainListParameters();
  exportType = ExportType;
  modules = Modules;
  periodicId = '-1';
  moduleName = '';
  searchText = '';
  isSelected = true;
  reportlist: any[] = [];
  isCheckVatNumber: boolean = true;
  isRowHighlighted: boolean = false;
  private _moduleIdSubscription;
  subscriptionRouting: Subscription;

  @Input() isBankImport: boolean = false;

  @Input() showAddButton: boolean = true;

  @Output()
  readonly triggerQuickAdd = new EventEmitter<any>();

  @Output()
  readonly triggerUserAdd = new EventEmitter<any>();
  @Output()
  readonly triggerCurrencyAdd = new EventEmitter<any>();

  @Input() isCustom: boolean = true;

  @Select(CommonState.totalRecord)
  totalRecord$: Observable<number>;

  @Input() transactionModuleId: number;
  commonService: CommonService;
  store: Store;
  dialog: MatDialog;
  notify: NotificationService;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  constructor(
    private modulePermission: ModulePermission,
    private router: Router,
    private bankDasboardService: BankDasboardService,
    private globalComponent: GlobalComponent,
    private _Activatedroute: ActivatedRoute,
    public spinner: NgxSpinnerService,
    private injector: Injector
  ) {
    this.commonService = injector.get<CommonService>(CommonService);
    this.store = injector.get<Store>(Store);
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.notify = injector.get<NotificationService>(NotificationService);
  }

  ngOnInit(): void {
    for (let key in ReportEnum) {
      this.reportlist.push({ number: key, word: ReportEnum[key] });
    }

    this.periodicDateList = this.globalComponent.getFinancialPeriod();

    if (
      !this.isBankImport &&
      this.transactionModuleId !== Modules.BankImportTransactionHistory
    ) {
      this.modulePermission.permissionData.subscribe((value) => {
        if (
          value.data.id !== this.moduleEnum.BalanceSheet &&
          value.data.id !== this.moduleEnum.ProfitAndLoss
        ) {
          this.spinner.hide();
        }

        this.moduleId = value.data.id;
        this.moduleName = value.data.name;
      });

      this.customDate = false;
      this.moduleName$.subscribe((x) => {
        this.moduleName =
          x.toLocaleLowerCase() === VatSettings.VatSettings.toLocaleLowerCase()
            ? VatSettings.VatCodes[VatSettings.VatCodes.length - 1] === 's'
              ? VatSettings.VatCodes.substring(
                  0,
                  VatSettings.VatCodes.length - 1
                )
              : VatSettings.VatCodes
            : x[x.length - 1] === 's'
            ? x.substring(0, x.length - 1)
            : x;
      });
    } else if (this.isBankImport) {
      this.moduleId = Modules.BankImportHistory;
    } else if (
      (this.transactionModuleId = Modules.BankImportTransactionHistory)
    ) {
      this.moduleId = Modules.BankImportTransactionHistory;
    } else if ((this.transactionModuleId = Modules.BankDashboard)) {
      this.moduleId = Modules.BankDashboard;
    }
  }

  onAddClick(): void {
    this.commonService.toggleMenu = false;
    this.commonService.isInitialValueChange = false;
    if (
      this.transactionModuleId === Modules.ActiveTangibleFixedAssets ||
      this.transactionModuleId === Modules.ActiveLeasedFixedAssets ||
      this.transactionModuleId === Modules.ActiveIntangibleFixedAssets
    ) {
      this.dialog
        .open(DepreciationExpandedCollapseComponent, {
          data: {
            moduleId: this.transactionModuleId,
          },
          panelClass: 'depreciation-list-dialog',
        })
        .afterClosed()
        .subscribe(() => {
          this.dataChangeFromHeader(false);
        });
    } else {
      this._moduleIdSubscription = this.moduleId$.subscribe((data: any) => {
        if (
          data !== Modules.BankDashboard &&
          data !== Modules.BankFeed &&
          data !== Modules.SubmitVat &&
          data !== Modules.BridgingVAT
        ) {
          this.commonService.onAddRouting(data);
        } else if (data === Modules.SubmitVat || data === Modules.BridgingVAT) {
          this.store.dispatch(new SetDefaultVatId());
          this.checkVatNumber(data);
        } else if (data === Modules.BankDashboard) {
          this.gotoAddCustomAccount();
        } else {
          this.dialog
            .open(BankLinkComponent)
            .afterClosed()
            .subscribe(() => {});
        }
      });
      this._moduleIdSubscription.unsubscribe();
    }
  }

  checkVatNumber(data: any): void {
    this.store.dispatch(new CheckVatNumber()).subscribe((res) => {
      this.isCheckVatNumber = res.submitVat.checkVatNumber;
      if (this.isCheckVatNumber) {
        this.commonService.onAddRouting(data);
      } else {
        this.commonService.onFailure(
          NotificationTextMessage.vatNumberErrorMessage
        );
      }
    });
  }

  dataChangeFromHeader(onLoad: boolean): void {
    const params = {
      startDate: this.headerData.startDate,
      endDate: this.headerData.endDate,
      search: this.headerData.search,
      moduleId: this.headerData.moduleId,
      subModuleId: this.headerData.subModuleId,
      format: this.headerData.format,
      isPrint: this.headerData.isPrint,
      filter: this.headerData.filter,
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      sortBy: onLoad ? this.listParameters.sortBy : '',
      sortOrder: onLoad ? this.listParameters.sortOrder : true,
    };
    this.isRowHighlighted = onLoad;
    this.triggerDataFromHeader.emit(params);
  }

  gotoAddCustomAccount(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.AddCustomAccounts,
          headerText: `Add ${ModuleName.AddCustomAccounts}`,
          saveActionName: CreateCustomAccount,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.triggerQuickAdd.emit(true);
        }
      });
  }

  gotoAddUser(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Permission,
          headerText: `Add ${ModuleName.Permission}`,
          saveActionName: CreateUser,
        },
      })
      .afterClosed()
      .subscribe((id) => {
        if (!this.commonService.isEmpty(id)) {
          this.triggerUserAdd.emit(true);
        }
      });
  }
  gotoAddCurrency(): void {
    this.dialog
      .open(QuickAddComponent, {
        data: {
          moduleId: Modules.Currency,
          headerText: `Add ${ModuleName.Currency}`,
          saveActionName: CreateCurrency,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (!this.commonService.isEmpty(res)) {
          this.triggerCurrencyAdd.emit(true);
        }
      });
  }

  import(): void {
    if (this.moduleId !== Modules.BankDashboard) {
      const params = { moduleId: btoa(this.moduleId.toString()) };
      this.router.navigate([RoutingPath.Import, params]);
    } else {
      const params = { customId: btoa(this.customId.toString()) };
      this.router.navigate([RoutingPath.BankImport, params]);
    }
  }

  bankEntryClick(isBankEntry): void {
    const params = {
      customId: btoa(this.customId),
    };

    if (isBankEntry) {
      this.router.navigate([RoutingPath.AddBankEntry, params]);
    } else {
      this.router.navigate([RoutingPath.CashCoding, params]);
    }
  }
}
