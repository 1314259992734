import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { DashboardModel } from '@app/core/Models/overview/overview-model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  setReloadDashboardEmitter = new EventEmitter();
  constructor(
    private http: HttpClient,
    public datepipe: DatePipe,
    private commonService: CommonService
  ) {}

  setDashboardReload(isCompanyChanged: boolean) {
    this.setReloadDashboardEmitter.emit(isCompanyChanged);
  }
  getDashboardData(): Observable<DashboardModel> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<DashboardModel>(
      `${environment.apiVersionUrl}Dashboard/dashboard`,
      headers
    );
  }

  getDashboardCard(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/dashboardCard
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDashboardIncomeByCategoriesOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/incomeByCategory
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDashboardExpenseByCategoriesOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/expenseByCategory
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDashboardIncomeVsExpensesOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/incomeVsExpenses
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDashboardAgeingSummaryOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/receivablePayable
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDashboardCashFlowOverview(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/cashFlow
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDashboardExpenseComparision(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Dashboard/expenseComparision
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }

  getDashboardIncomeComparision(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}Dashboard/incomeComparision
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }
  getDashboardVATSummaryData(filterData: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl_V2}Dashboard/vatSummary
      `,
      this.commonService.trimObjectSpace(JSON.stringify(filterData)),
      headers
    );
  }
}
