<div class="mat-dialog-wrapper match-journal">
  <h2 mat-dialog-title>Multiple Transactions</h2>
  <button
    class="close-button"
    type="button"
    (click)="onCloseClick()"
    mat-button
    disableRipple
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    <div class="content-body p-0">
      <form>
        <div class="form-field">
          <p>Amount</p>
          <mat-form-field class="w-250">
            <input matInput autocomplete="off" name="amount" />
          </mat-form-field>
        </div>
      </form>
      <div class="mat-table-wrapper">
        <table
          mat-table
          [dataSource]="dataSource"
          aria-describedby="Multiple Transactions Grid"
        >
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let element">
              <mat-form-field appearance="fill" class="w-200">
                <mat-select
                  #clients
                  panelClass="myPanelClass"
                  placeholder="Select"
                  [disableOptionCentering]="true"
                >
                  <mat-option value="0">Select</mat-option>
                  <mat-option value="1" (click)="AddReceipt()"
                    >Receipt</mat-option
                  >
                  <mat-option value="2" (click)="AddPayment()"
                    >Payment</mat-option
                  >
                  <mat-option value="3" (click)="AddJournal()"
                    >Journal</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let element">
              <mat-form-field class="w-200">
                <input matInput autocomplete="off" name="amount" />
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="w-80">Action</th>
            <td mat-cell *matCellDef="let element">
              <div class="grid-actions">
                <button mat-button disableRipple title="Edit">
                  <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                </button>
                <button mat-button disableRipple title="Delete">
                  <mat-icon fontSet="material-icons-outlined"
                    >delete_outline</mat-icon
                  >
                </button>
              </div>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <div class="mt-1 mb-1">
        <a href="javascript:void(0)" class="link">Add New Row</a>
      </div>
      <div>
        <p class="fw-bold mt-0 text-align-right">
          Balance Amount :
          <span class="fw-normal display-inline w-100 ml-5">£ 50.00</span>
        </p>
        <p class="fw-bold mt-0 text-align-right">
          Total Amount :
          <span class="fw-normal display-inline w-100 ml-5">£ 100.00</span>
        </p>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="hr pt-1 mt-0 justify-content-end">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
    >
      Save
    </button>
    <button
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onCloseClick()"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>

<app-custom-notifications></app-custom-notifications>
