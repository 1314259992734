import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Modules } from '@app/core/Enum';
import { AnnualReportModel } from '@app/core/Models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnnualReportService {
  constructor(private http: HttpClient) {}

  getAnnualReportContent(
    accountPeriodId: Guid,
    annualReportModel: AnnualReportModel,
    moduleId: Modules
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'text' as 'json', // Set the response type as text
    };
    let url;

    switch (moduleId) {
      case Modules.CompanyOverview:
        url = 'companyOverviewReport';
        break;

      case Modules.ExpensePerformance:
        url = 'expensesPerformanceReport';
        break;

      case Modules.SalesPerformance:
        url = 'salesPerformanceReport';
        break;
    }
    return this.http.post(
      `${environment.apiVersionUrl}report/${url}/${accountPeriodId}`,
      JSON.stringify(annualReportModel),
      options
    );
  }
}
