import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModuleName, Modules } from '@app/core/Enum';
import { MainListParameters } from '@app/core/Models';
import { NotificationService } from '@app/core/Services';
import { Export, GetCashFlow } from '@app/core/Store';
import { CashFlowDetailsComponent } from '@app/modules/common/cash-flow-details/cash-flow-details.component';
import { HeaderActionsComponent } from '@app/modules/common/header-actions/header-actions.component';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.scss'],
})
export class CashFlowComponent implements OnInit {
  moduleName = ModuleName.CashFlow;

  data = {
    OA_1: {
      name: 'Net Profit as per Profit and Loss A/c or diff. b/w Cl. & Op. bal. of P & L',
      amount: 0,
    },
    OA_2: {
      name: 'Transfer to Reserve',
      amount: 0,
    },
    OA_3: {
      name: 'Dividend',
      amount: 0,
    },
    OA_4: {
      name: 'Provision for Tax made during the current year',
      amount: 0,
    },
    OA_5: {
      name: 'Extraordinary item, if any debited to P & L A/c',
      amount: 0,
    },
    OA_6: {
      name: 'Extraordinary item, if any credited to P & L A/c.',
      amount: 0,
    },
    OA_7: {
      name: 'Depreciation',
      amount: 0,
    },
    OA_8: {
      name: 'Preliminary Exps./ Disc. On issue of shares & deb. Written off',
      amount: 0,
    },
    OA_9: {
      name: 'Interest expenses',
      amount: 0,
    },
    OA_10: {
      name: 'Goodwill, Patents and Trade etc. (Intangible assets) Amortised',
      amount: 0,
    },
    OA_11: {
      name: 'Loss on Sale of Fixed Assets',
      amount: 0,
    },
    OA_12: {
      name: 'Impairment Losses',
      amount: 0,
    },
    OA_13: {
      name: 'Others',
      amount: 0,
    },
    OA_14: {
      name: 'Interest  Income',
      amount: 0,
    },
    OA_15: {
      name: 'Profit on Sale of Fixed Assets',
      amount: 0,
    },
    OA_16: {
      name: 'Decrease in Stock/ Inventories',
      amount: 0,
    },
    OA_17: {
      name: 'Decrease in Debtors / Bills Receivables',
      amount: 0,
    },
    OA_18: {
      name: 'Decrease in Prepayments and Accrued Expenses',
      amount: 0,
    },
    OA_19: {
      name: 'Increase in Creditors / Bills Payables',
      amount: 0,
    },
    OA_20: {
      name: 'Increase in Accruals and Deffered Income',
      amount: 0,
    },
    OA_21: {
      name: 'Increase in Provision',
      amount: 0,
    },
    OA_22: {
      name: 'Increase in Stock/ Inventories',
      amount: 0,
    },
    OA_23: {
      name: 'Increase in Debtors / Bills Receivables',
      amount: 0,
    },
    OA_24: {
      name: 'Increase in Prepayments and Accrued Incomes',
      amount: 0,
    },
    OA_25: {
      name: 'Decrease in Creditors / Bills Payables',
      amount: 0,
    },
    OA_26: {
      name: 'Decrease in Accruals and Deffered Income',
      amount: 0,
    },
    OA_27: {
      name: 'Decrease in Provisions',
      amount: 0,
    },
    OA_28: {
      name: 'Income Tax Paid (Net of income tax refund received)',
      amount: 0,
    },
    IA_1: {
      name: 'Proceeds from sale of Fixed Assets',
      amount: 0,
    },
    IA_2: {
      name: 'Proceeds from sale of Investments',
      amount: 0,
    },
    IA_3: {
      name: 'Proceeds from sale /Disposal of Intangible Assets',
      amount: 0,
    },
    IA_4: {
      name: 'Interest and Dividend received',
      amount: 0,
    },
    IA_5: {
      name: 'Purchase of Fixed Assets',
      amount: 0,
    },
    IA_6: {
      name: 'Purchase of Investments',
      amount: 0,
    },
    IA_7: {
      name: 'Purchase of / Invest in Intangible Assets',
      amount: 0,
    },
    FA_1: {
      name: 'Proceeds from issue of Shares / Capital Introduced',
      amount: 0,
    },
    FA_2: {
      name: 'Proceeds from other Long Term Borrowings',
      amount: 0,
    },
    FA_3: {
      name: 'Dividend paid',
      amount: 0,
    },
    FA_4: {
      name: 'Interest expenses and other similar charges',
      amount: 0,
    },
    FA_5: {
      name: 'Repayment of Loans',
      amount: 0,
    },
    FA_6: {
      name: 'Redemption of Debentures/ Preference shares',
      amount: 0,
    },
    FA_7: {
      name: 'Capital repaid',
      amount: 0,
    },
    FA_8: {
      name: 'Preliminary Exps./ Disc. On issue of shares & deb. Written off',
      amount: 0,
    },
    CA_1: {
      name: 'Cash in hand',
      amount: 0,
    },
    CA_2: {
      name: 'Cash at bank',
      amount: 0,
    },
    total_A: {
      name: '',
      amount: 0,
    },
    total_D: {
      name: '',
      amount: 0,
    },
    total_G: {
      name: '',
      amount: 0,
    },
    total_I: {
      name: '',
      amount: 0,
    },
    total_II: {
      name: '',
      amount: 0,
    },
    total_III: {
      name: '',
      amount: 0,
    },
    total_IV: {
      name: '',
      amount: 0,
    },
    total_J: {
      name: '',
      amount: 0,
    },
    total_K: {
      name: '',
      amount: 0,
    },
    total_VI: {
      name: '',
      amount: 0,
    },
  };

  @ViewChild(HeaderActionsComponent, { static: true })
  headerAction;
  listParameters: MainListParameters = new MainListParameters();
  moduleId = Modules.CashFlow;
  constructor(
    private store: Store,
    private spinner: NgxSpinnerService,
    private notify: NotificationService,
    private datepipe: DatePipe,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  onClick(data): void {
    this.dialog
      .open(CashFlowDetailsComponent, {
        data: {
          data,
          listParameters: this.listParameters,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {});
  }

  getParamter(): any {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId ?? (Guid.EMPTY as unknown as Guid),
      subModuleId: this.listParameters.subModuleId ?? -1,
      ids: this.listParameters.ids ?? null,
      branchIds: this.listParameters.branchIds ?? [],
      departmentIds: this.listParameters.departmentIds ?? [],
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
    };
    return queryParams;
  }

  getList(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetCashFlow(this.getParamter()))
      .subscribe((res) => {
        this.data = res.report.cashFlowData;
        this.spinner.hide();
      });
  }

  getDataFromHeader(data: any): void {
    this.headerAction.ngOnDestroy();
    this.listParameters = data;
    this.getList();
  }

  exportReport(format: number, isPrint?: boolean): void {
    this.spinner.show();
    const queryParams: any = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      moduleId: this.listParameters.moduleId,
      subModuleId: this.listParameters.subModuleId ?? -1,
      branchIds: this.listParameters.branchIds ?? [],
      departmentIds: this.listParameters.departmentIds ?? [],
      ids: [],
      startDate:
        this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      endDate:
        this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString() ?? null,
      format: format,
      isPrint: isPrint,
    };

    this.store.dispatch(new Export(queryParams, this.moduleId)).subscribe();
  }
}
