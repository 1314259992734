import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@environments/environment';
import { NgxsModule } from '@ngxs/store';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import {
  DocumentEditorAllModule,
  DocumentEditorContainerAllModule,
} from '@syncfusion/ej2-angular-documenteditor';
import {
  DropDownListModule,
  MultiSelectAllModule,
  MultiSelectModule,
} from '@syncfusion/ej2-angular-dropdowns';
import { QueryBuilderModule } from '@syncfusion/ej2-angular-querybuilder';
import { TreeGridAllModule } from '@syncfusion/ej2-angular-treegrid';
import {
  AuthModule,
  LoggerService,
  LogLevel,
  OidcConfigService,
} from 'angular-auth-oidc-client';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgChartsModule } from 'ng2-charts';
import { CookieService } from 'ngx-cookie-service';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxDatetimeRangePickerModule } from 'ngx-datetime-range-picker';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxSummernoteModule } from 'ngx-summernote';
import { AppMaterialModule } from './app-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  AllowAlphaNumericCharacterDirective,
  AutocompletePositionDirective,
  AutoFocusDirective,
  DisableInputControlDirective,
  EmailValidatorDirective,
  FourteenDigitDecimaNumberDirective,
  NumberOnlyDirective,
  PercentageDirective,
  TenDigitDecimaNumberDirective,
  ThirteenDigitDecimaNumberDirective,
  TwoDigitDecimaNumberDirective,
  UnicodeCharacterDirective,
  ZeroNotAllowedDirective,
} from './core/Directives';
import { AuthGuard } from './core/Guards/auth.guard';
import { AuthResolverService } from './core/Guards/auth.resolver';
import { LoaderInterceptor } from './core/Interceptors';
import { CustomHttpInterceptor } from './core/Interceptors/http.interceptor';
import { GlobalComponent } from './core/Models';
import { NumberPipe } from './core/Pipes/number-pipe.pipes';
import { ViewHtmlContent } from './core/Pipes/safe-html.pipes';
import { ShortNamePipe } from './core/Pipes/short-name.pipes';
import { UseEnumValuePipe } from './core/Pipes/use-enum-value.pipe';
import {
  AccountService,
  AnnualReportService,
  BankDasboardService,
  BankOverviewService,
  BankTransferService,
  BillService,
  BridgingVatService,
  BudgetingService,
  BulkEditService,
  CashCodingService,
  CISInvoiceService,
  CISReportService,
  CISService,
  CommonEmitterService,
  CommonService,
  CompanyService,
  CreditNoteService,
  CurrencyService,
  CustomAccountService,
  DashboardService,
  DataSecurityService,
  DebitNoteService,
  DirectorShareholderService,
  DividendsService,
  DummyService,
  EmailTemplateService,
  FixedAssetsRegisterService,
  FixedAssetsReturnService,
  GeneralDashboardService,
  ImportService,
  IntegrationsService,
  InventoryLossOfStockService,
  InventoryService,
  InvoiceService,
  JournalsService,
  MenuService,
  MinutesOfMeetingsService,
  NotificationService,
  OpeningBalanceService,
  PermissionService,
  ProductService,
  QuickEntryService,
  QuotationService,
  RecurringModuleService,
  ReportService,
  SettingsService,
  SubmitVatService,
  SubscriptionService,
  TicketService,
  UserService,
  VATCodesService,
  VATSettingsService,
  ViewVatDeatilsService,
} from './core/Services';
import {
  AccountState,
  ActivityState,
  AnnualReportState,
  BankDashboardState,
  BankOverviewState,
  BankReconciliationState,
  BillState,
  BridgingVatState,
  BudgetingState,
  CashCodingState,
  CISInvoiceState,
  CISReportState,
  CISState,
  CommonState,
  CompanyState,
  CountryState,
  CreditNoteState,
  CurrencyState,
  CustomAccountState,
  DashboardState,
  DebitNoteState,
  DirectorShareholderState,
  DividendsState,
  EmailTemplateState,
  FixedAssetReturnState,
  FixedAssetsRegisterState,
  FixedAssetsState,
  GeneralDashBoardState,
  ImportState,
  ImportStateAll,
  IntegrationState,
  InventoryLossOfStockState,
  InventoryState,
  InvoiceState,
  JournalsState,
  MenuState,
  MinutesOfMeetingsState,
  OpeningBalanceState,
  OverviewState,
  PaymentsState,
  PermissionState,
  ProductState,
  QuickEntryState,
  QuotationState,
  ReceiptState,
  RecurringModuleState,
  ReportState,
  ScheduleNoteState,
  SettingsState,
  SubscriptionState,
  TicketState,
  UserState,
  VatCodesState,
  VATSettingsState,
  ViewVatDetailsState,
  DataSecurityState,
} from './core/Store';
import { BankTransferState } from './core/Store/bank/bank-transfer';
import { SubmitVatState } from './core/Store/mtd';
import { BulkEditState } from './core/Store/task/bulk-edit';

import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';

import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';

import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';

import {
  NumericTextBoxAllModule,
  NumericTextBoxModule,
} from '@syncfusion/ej2-angular-inputs';

import { DialogModule } from '@syncfusion/ej2-angular-popups';

import {
  DetailRowService,
  FilterService,
  GridAllModule,
  GroupService,
  PageService,
  SortService,
} from '@syncfusion/ej2-angular-grids';
import {
  ActivityComponent,
  ActivityDetailsComponent,
  AddAllocateRefundPopupComponent,
  AddBankAccountComponent,
  AddBankTransferComponent,
  AddBillComponent,
  AddBranchComponent,
  AddBudgetingComponent,
  AddBulkCashCodingComponent,
  AddBulkJournalComponent,
  AddCashCodingComponent,
  AddCISInformationComponent,
  AddClosePopupComponent,
  AddCommericalDetailsComponent,
  AddCompanyComponent,
  AddCompanyPreferenceComponent,
  AddContactDetailsComponent,
  AddContractorSettingsComponent,
  AddCreditNoteComponent,
  AddCustomAccountsComponent,
  AddCustomizeReportPopup,
  AddDebitNoteComponent,
  AddDepartmentComponent,
  AddDirectorShareholderDetailComponent,
  AddDividendComponent,
  AddFinancialYearComponent,
  AddFixedAssestsComponent,
  AddFixedAssetReturnComponent,
  AddIncomeInvoiceComponent,
  AddJournalComponent,
  AddJournalsComponent,
  AddMinutesOfMeetingsComponent,
  AddPaymentComponent,
  AddProductComponent,
  AddProductPopupComponent,
  AddQuickentryComponent,
  AddQuotationComponent,
  AddReceiptComponent,
  AddReceiptPaymentPopupComponent,
  AddReceiptsComponent,
  AddRecurringModuleComponent,
  AddSchedulesNotesComponent,
  AddSubmitVatComponent,
  AddSubscriptionDetailsComponent,
  AddVatCodesComponent,
  AddVatSettingsComponent,
  AdvancePaymentComponent,
  AnnualReportComponent,
  AssetsCountChartComponent,
  AssignReceiptComponent,
  AttachmentPopupComponent,
  AutoBankReconcillationComponent,
  BalanceSheetComponent,
  BankDashboardComponent,
  BankFeedComponent,
  BankImportComponent,
  BankLinkComponent,
  BankOverviewComponent,
  BankReconciliationComponent,
  BankReconciliationDetailsComponent,
  BankTransferComponent,
  BankTransferViewComponent,
  BillComponent,
  BillOutstandingChartComponent,
  BridgingVATComponent,
  BudgetingCalculationPopupComponent,
  BudgetingComponent,
  BudgetingDetailListComponent,
  BulkEditComponent,
  BusinessSnapshotComponent,
  BusinessSnapshotPieChartComponent,
  ButtonsComponent,
  CapiumpayDialogComponent,
  CardFooterComponent,
  CashCodingComponent,
  CashFlowComponent,
  CISInvoiceComponent,
  CisInvoiceComponent,
  CISInvoiceDetailsComponent,
  CisManualVerificationPopupComponent,
  CISReturnDetailsComponent,
  CisSubmissionComponent,
  CleanAllLinesComponent,
  CompanyPreferenceComponent,
  ComparitiveReportComponent,
  ConfirmationBoxComponent,
  ContactTransactionComponent,
  ConvertToInvoicePopupComponent,
  CreditNoteAllocationComponent,
  CreditNoteComponent,
  CurrencyComponent,
  CustomAccountsComponent,
  CustomerComponent,
  CustomizeReportComponent,
  CustomNotificationsComponent,
  CustomPaginatorComponent,
  CustomPeriodPopupComponent,
  DashboardCashflowChartComponent,
  DashboardCashflowChartComponentv1,
  DashboardComponent,
  DashboardComponentv1,
  DashboardExpensesComponentv1,
  DashboardIncomeExpensesChartComponent,
  DashboardIncomeExpensesChartComponentv1,
  DebitNoteComponent,
  DepreciationComponent,
  DepreciationExpandedCollapseComponent,
  DetailHeaderComponent,
  DetailListComponent,
  DirectorShareholderComponent,
  DividendsComponent,
  EditAssetDetailComponent,
  EditDisposeAssetComponent,
  EditReceiptComponent,
  EmailIntegrationsComponent,
  EmailPopupComponent,
  EmailTemplatesComponent,
  ExpandCollapseComponent,
  ExpenditureOverviewComponent,
  FixedAssestDisposeActiveComponent,
  FixedAssetReturnComponent,
  FixedAssetReturnPopupComponent,
  FixedAssetsComponent,
  FixedAssetsOverviewComponent,
  FlatRateAdjustmentComponent,
  HeaderComponent,
  HMRCGatewayDetailsComponent,
  ImportComponent,
  ImportFileDataComponent,
  IncludeDataComponent,
  IncludeExcludeBudgetingPopupComponent,
  IncludeExcludeTransactionComponent,
  IncomeInvoiceComponent,
  IncomeOverviewV1Component,
  IntegrationsComponent,
  InventoryComponent,
  InventoryTransactionPopupComponent,
  JournalsComponent,
  MainListComponent,
  ManualImportComponent,
  MatchJournalsComponent,
  MatchQuickentryComponent,
  MenuItemComponent,
  MinutesOfMeetingsComponent,
  MonthlyReturnComponent,
  MoreGridActionsComponent,
  MultipleTransactionComponent,
  MultiSelectDropdownComponent,
  NewCompanyComponent,
  NominalLedgerComponent,
  OpeningBalanceComponent,
  OpeningBalanceExplainContractComponent,
  OpeningBalanceFooterComponent,
  OverviewComponent,
  OverviewFlowChartComponent,
  OverviewFlowChartv1Component,
  OverviewIncomesBreckdownv1Component,
  OverviewIncomesChartComponent,
  OverviewIncomesChartComponentv1,
  OverviewReceivableSummaryChartComponent,
  OverviewSummaryChartComponent,
  OverviewSummaryChartComponentv1,
  PermissionComponent,
  ProductComponent,
  ProductDetailsComponent,
  ProductDetailsFooterComponent,
  ProfitLossComparisonComponent,
  ProfitLossComponent,
  QuickAddComponent,
  QuickEntryComponent,
  QuickentryDetailsComponent,
  QuickJournalComponent,
  QuotationComponent,
  ReceiptComponent,
  ReceiptDetailsComponent,
  ReconfirmPopupComponent,
  RecurringModuleComponent,
  RefundPopupComponent,
  ReportCreditorDebtorComponent,
  ReportDashboardComponent,
  ReportHeaderDetailsComponent,
  ReportListComponent,
  ReportListWithActionsComponent,
  ReviewReturnComponent,
  RollBackPopupComponent,
  SchedulesNotesComponent,
  SchedulesNotesViewComponent,
  ShareComponent,
  SideListActionsComponent,
  SideListComponent,
  SubcontractorComponent,
  SubContractorDetailsComponent,
  SubcontractorYtdDetailsComponent,
  SubmitVatComponent,
  SubmitVatHeaderComponent,
  SubmitVatListComponent,
  SupplierComponent,
  SupportTicketComponent,
  TicketComponent,
  TicketDialogComponent,
  TransactionHeaderComponent,
  TransactionsAttachmentComponent,
  TransactionsPaymentComponent,
  TreeListComponent,
  VatCodesComponent,
  VATDetailsComponent,
  VatPaymentPopupComponent,
  VatPaymentViewPopupComponent,
  VatReportComponent,
  VatReturnComponent,
  VatSettingsComponent,
  VatViewComponent,
  VatViewDataComponent,
  ViewReceiptComponent,
  ViewVatDetailComponent,
} from './modules';

import { ShowWholeIfNoDecimalPipe } from './core/Pipes/show-whole-if-no-decimal.pipe';
import { AccessDeniedComponent } from './modules/access-denied/access-denied.component';
import { AddAccountComponent } from './modules/common/add-account/add-account.component';
import { CashFlowDetailsComponent } from './modules/common/cash-flow-details/cash-flow-details.component';
import { HeaderActionsComponent } from './modules/common/header-actions/header-actions.component';
import { LossOfStockComponent } from './modules/common/loss-of-stock/loss-of-stock.component';
import { ViewCISMonthlyReturnPopupComponent } from './modules/common/view-cis-monthly-return-popup/view-cis-monthly-return-popup.component';
import { ViewCISMonthlyReturnComponent } from './modules/common/view-cis-monthly-return/view-cis-monthly-return.component';
import CustomYearPickerComponent from './modules/custom/custom-year-picker/custom-year-picker.component';
import { DashboardVatSummaryComponent } from './modules/dashboard/dashboard-v1/dashboard-vat-summary/dashboard-vat-summary.component';
import { DynamicGridListComponent } from './modules/dynamic-grid-list/dynamic-grid-list.component';
import { DashboardAllClientComponent } from './modules/general-dashboard/dashboard-all-client/dashboard-all-client.component';
import { DashboardCurrentMonthComponent } from './modules/general-dashboard/dashboard-current-month/dashboard-current-month.component';
import { DashboardMonthlyReturnComponent } from './modules/general-dashboard/dashboard-monthly-return/dashboard-monthly-return.component';
import { DashboardVatClientComponent } from './modules/general-dashboard/dashboard-vat-client/dashboard-vat-client.component';
import { GeneralDashboardComponent } from './modules/general-dashboard/general-dashboard.component';
import { AddClientComponent } from './modules/general-manage/clients/add-client/add-client.component';
import { ClientsListComponent } from './modules/general-manage/clients/clients-list/clients-list.component';
import { AssignClientComponent } from './modules/general-manage/common/assign-client/assign-client.component';
import { ExportSettingsComponent } from './modules/general-manage/export-settings/export-settings.component';
import { GeneralManageComponent } from './modules/general-manage/general-manage.component';
import { ImportSettingDetailsComponent } from './modules/general-manage/import/import-setting-details/import-setting-details.component';
import { ImportDetailsSettingsComponent } from './modules/general-manage/import/import-settings/import-details-settings/import-details-settings.component';
import { ImportSettingsComponent } from './modules/general-manage/import/import-settings/import-settings.component';
import { DailyDialogComponent } from './modules/general-manage/integration-settings/daily-dialog/daily-dialog.component';
import { IntegrationSettingsComponent } from './modules/general-manage/integration-settings/integration-settings.component';
import { PermissionListComponent } from './modules/general-manage/permission-list/permission-list.component';
import {
  AddUserComponent,
  GrantPermissionPopupComponent,
  SaveAndInvitePopupComponent,
  UserBasicInfoComponent,
  UserPermissionsComponent,
  UsersListComponent,
} from './modules/general-manage/users';
import { UserClientsComponent } from './modules/general-manage/users/add-user/user-clients/user-clients.component';
import { InvoiceTemplatesComponent } from './modules/manage/invoice-templates/invoice-templates.component';
import { AddTemplatesComponent } from './modules/manage/invoice-templates/templates-docs/add-templates/add-templates.component';
import { TemplatesDocsComponent } from './modules/manage/invoice-templates/templates-docs/templates-docs.component';
import { UploadTemplatesComponent } from './modules/manage/invoice-templates/templates-docs/upload-templates/upload-templates.component';
import { PreviewInvoiceComponent } from './modules/manage/invoice-templates/templates-pdf/preview-invoice/preview-invoice.component';
import { TemplatesPdfComponent } from './modules/manage/invoice-templates/templates-pdf/templates-pdf.component';
import { AccountSettingsComponent } from './modules/settings/account-settings/account-settings.component';
import { CapiscanSettingsComponent } from './modules/settings/capiscan-settings/capiscan-settings.component';
import { DefaultSettingsComponent } from './modules/settings/default-settings/default-settings.component';
import { ReportSettingsComponent } from './modules/settings/report-settings/report-settings.component';
import { VatTransactionDetailsComponent } from './modules/mtd/vat-transaction-details/vat-transaction-details.component';
import { DataSecurityComponent } from './modules/manage/data-security/data-security.component';
import { DiscountFlatRateComponent } from './modules/mtd/submit-vat/add-submit-vat/discount-flat-rate/discount-flat-rate.component';
import { VatDetailsGridComponent } from './modules/mtd/vat-transaction-details/vat-details-grid/vat-details-grid.component';

export const CUSTOM_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: ',',
  nullable: true,
};

export function configureAuth(oidcConfigService: OidcConfigService): any {
  return () =>
    oidcConfigService.withConfig({
      stsServer: `${environment.stsServerUrl}`,
      redirectUrl: window.location.origin,
      postLogoutRedirectUri: `${environment.identityLoginUrl}`,
      clientId: `${environment.clientId}`,
      scope: 'capium_myadmin_api openid offline_access',
      responseType: 'code',
      silentRenew: false,
      silentRenewUrl: `${window.location.origin}/silent-renew.html`,
      useRefreshToken: true,
      logLevel: LogLevel.Debug,
      historyCleanupOff: true,
      autoCleanStateAfterAuthentication: true,
      postLoginRoute: '/general-dashboard',
      unauthorizedRoute: `${environment.identityLoginUrl}`,
      autoUserinfo: false,
    });
}

export const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12,
    },
    vertical: {
      position: 'top',
      distance: 60,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

@NgModule({
  exports: [],

  declarations: [
    AppComponent,
    ShortNamePipe,
    NumberPipe,
    ShowWholeIfNoDecimalPipe,
    TwoDigitDecimaNumberDirective,
    TenDigitDecimaNumberDirective,
    ThirteenDigitDecimaNumberDirective,
    FourteenDigitDecimaNumberDirective,
    DisableInputControlDirective,
    AutoFocusDirective,
    AutocompletePositionDirective,
    UnicodeCharacterDirective,
    AllowAlphaNumericCharacterDirective,
    NumberOnlyDirective,
    NewCompanyComponent,
    AddCompanyComponent,
    AddFinancialYearComponent,
    AddClosePopupComponent,
    MenuItemComponent,
    HeaderComponent,
    ProductDetailsComponent,
    QuotationComponent,
    AddQuotationComponent,
    TransactionHeaderComponent,
    TransactionsAttachmentComponent,
    ProductDetailsFooterComponent,
    SideListComponent,
    SideListActionsComponent,
    AttachmentPopupComponent,
    TransactionsPaymentComponent,
    AddIncomeInvoiceComponent,
    AddCustomizeReportPopup,
    IncomeInvoiceComponent,
    ConfirmationBoxComponent,
    ViewReceiptComponent,
    CardFooterComponent,
    CleanAllLinesComponent,
    MultiSelectDropdownComponent,
    CustomYearPickerComponent,
    CustomPaginatorComponent,
    CustomNotificationsComponent,
    MainListComponent,
    ButtonsComponent,
    ViewHtmlContent,
    AddCommericalDetailsComponent,
    AddContactDetailsComponent,
    AddSubscriptionDetailsComponent,
    SupplierComponent,
    AddCISInformationComponent,
    CustomerComponent,
    CustomAccountsComponent,
    AddCustomAccountsComponent,
    ExpandCollapseComponent,
    CreditNoteComponent,
    AddCreditNoteComponent,
    BillComponent,
    AddBillComponent,
    DebitNoteComponent,
    AddDebitNoteComponent,
    FixedAssetsComponent,
    AddFixedAssestsComponent,
    ReceiptComponent,
    AddReceiptComponent,
    ReceiptDetailsComponent,
    JournalsComponent,
    AddJournalsComponent,
    AddBulkJournalComponent,
    RecurringModuleComponent,
    AddRecurringModuleComponent,
    BankDashboardComponent,
    AddBankAccountComponent,
    TreeListComponent,
    CompanyPreferenceComponent,
    AddCompanyPreferenceComponent,
    OpeningBalanceComponent,
    OpeningBalanceFooterComponent,
    OpeningBalanceExplainContractComponent,
    DashboardIncomeExpensesChartComponent,
    DashboardComponent,
    DashboardCashflowChartComponent,
    OverviewComponent,
    OverviewSummaryChartComponent,
    OverviewIncomesChartComponent,
    OverviewFlowChartComponent,
    ReportListComponent,
    ReportHeaderDetailsComponent,
    ImportComponent,
    ImportFileDataComponent,
    ReportDashboardComponent,
    BillOutstandingChartComponent,
    ReportCreditorDebtorComponent,
    NominalLedgerComponent,
    VatCodesComponent,
    VatSettingsComponent,
    ViewVatDetailComponent,
    AddVatCodesComponent,
    AddVatSettingsComponent,
    SubmitVatComponent,
    AddSubmitVatComponent,
    VATDetailsComponent,
    BridgingVATComponent,
    IncludeExcludeTransactionComponent,
    IncludeDataComponent,
    ProductComponent,
    AddProductComponent,
    SchedulesNotesComponent,
    AddSchedulesNotesComponent,
    MinutesOfMeetingsComponent,
    AddMinutesOfMeetingsComponent,
    BankReconciliationComponent,
    BankReconciliationDetailsComponent,
    DetailListComponent,
    DetailHeaderComponent,
    VatReturnComponent,
    ReviewReturnComponent,
    BulkEditComponent,
    BudgetingComponent,
    AddBudgetingComponent,
    BudgetingDetailListComponent,
    IncludeExcludeBudgetingPopupComponent,
    SubmitVatHeaderComponent,
    AddDirectorShareholderDetailComponent,
    DirectorShareholderComponent,
    DividendsComponent,
    AddDividendComponent,
    BudgetingCalculationPopupComponent,
    AddDepartmentComponent,
    AddBranchComponent,
    CreditNoteAllocationComponent,
    SubcontractorComponent,
    MonthlyReturnComponent,
    CISReturnDetailsComponent,
    SubContractorDetailsComponent,
    CISInvoiceComponent,
    CISInvoiceDetailsComponent,
    CashCodingComponent,
    AddBulkCashCodingComponent,
    AddCashCodingComponent,
    QuickAddComponent,
    EmailPopupComponent,
    IntegrationsComponent,
    DepreciationComponent,
    DepreciationExpandedCollapseComponent,
    ZeroNotAllowedDirective,
    EmailValidatorDirective,
    EmailIntegrationsComponent,
    EmailTemplatesComponent,
    BankImportComponent,
    ReportListWithActionsComponent,
    BankTransferComponent,
    AddBankTransferComponent,
    EditAssetDetailComponent,
    UseEnumValuePipe,
    DashboardCashflowChartComponentv1,
    DashboardExpensesComponentv1,
    DashboardIncomeExpensesChartComponentv1,
    AssetsCountChartComponent,
    DashboardComponentv1,
    OverviewIncomesChartComponentv1,
    OverviewSummaryChartComponentv1,
    FixedAssetsOverviewComponent,
    UseEnumValuePipe,
    IncomeOverviewV1Component,
    OverviewReceivableSummaryChartComponent,
    OverviewIncomesBreckdownv1Component,
    OverviewFlowChartv1Component,
    ExpenditureOverviewComponent,
    DynamicGridListComponent,
    MoreGridActionsComponent,
    HeaderActionsComponent,
    PermissionComponent,
    HMRCGatewayDetailsComponent,
    AddContractorSettingsComponent,
    VatReportComponent,
    FixedAssestDisposeActiveComponent,
    EditDisposeAssetComponent,
    BusinessSnapshotComponent,
    BusinessSnapshotPieChartComponent,
    AnnualReportComponent,
    AdvancePaymentComponent,
    TicketComponent,
    TicketDialogComponent,
    SupportTicketComponent,
    QuickJournalComponent,
    ManualImportComponent,
    AddReceiptPaymentPopupComponent,
    AddAllocateRefundPopupComponent,
    BankOverviewComponent,
    BankLinkComponent,
    MatchJournalsComponent,
    BankFeedComponent,
    ReconfirmPopupComponent,
    CurrencyComponent,
    AutoBankReconcillationComponent,
    CustomizeReportComponent,
    ProfitLossComponent,
    ProfitLossComparisonComponent,
    BalanceSheetComponent,
    RollBackPopupComponent,
    ConvertToInvoicePopupComponent,
    PercentageDirective,
    ShareComponent,
    QuickEntryComponent,
    AddQuickentryComponent,
    QuickentryDetailsComponent,
    AddProductPopupComponent,
    CisInvoiceComponent,
    ViewCISMonthlyReturnPopupComponent,
    ViewCISMonthlyReturnComponent,
    CisSubmissionComponent,
    SubcontractorYtdDetailsComponent,
    LossOfStockComponent,
    EditReceiptComponent,
    MatchQuickentryComponent,
    CapiumpayDialogComponent,
    DefaultSettingsComponent,
    AccountSettingsComponent,
    ReportSettingsComponent,
    CapiscanSettingsComponent,
    AddAccountComponent,
    CashFlowComponent,
    CashFlowDetailsComponent,
    GeneralDashboardComponent,
    DashboardMonthlyReturnComponent,
    DashboardAllClientComponent,
    DashboardVatClientComponent,
    DashboardCurrentMonthComponent,
    GeneralManageComponent,
    ClientsListComponent,
    AddClientComponent,
    ImportSettingsComponent,
    ImportDetailsSettingsComponent,
    ImportSettingDetailsComponent,
    PermissionListComponent,
    ExportSettingsComponent,
    IntegrationSettingsComponent,
    DailyDialogComponent,
    DashboardVatSummaryComponent,
    MultipleTransactionComponent,
    AddPaymentComponent,
    AddJournalComponent,
    AddReceiptsComponent,
    UsersListComponent,
    AddUserComponent,
    UserBasicInfoComponent,
    GrantPermissionPopupComponent,
    SaveAndInvitePopupComponent,
    UserPermissionsComponent,
    PermissionListComponent,
    SubmitVatListComponent,
    VatPaymentViewPopupComponent,
    VatPaymentPopupComponent,
    FlatRateAdjustmentComponent,
    UserClientsComponent,
    VatViewComponent,
    VatViewDataComponent,
    RefundPopupComponent,
    AccessDeniedComponent,
    AssignClientComponent,
    AssignReceiptComponent,
    ContactTransactionComponent,
    ComparitiveReportComponent,
    CustomPeriodPopupComponent,
    CisManualVerificationPopupComponent,
    BankTransferViewComponent,
    SchedulesNotesViewComponent,
    FixedAssetReturnComponent,
    AddFixedAssetReturnComponent,
    FixedAssetReturnPopupComponent,
    InventoryComponent,
    InventoryTransactionPopupComponent,
    InvoiceTemplatesComponent,
    TemplatesDocsComponent,
    TemplatesPdfComponent,
    AddTemplatesComponent,
    UploadTemplatesComponent,
    PreviewInvoiceComponent,
    VatTransactionDetailsComponent,
    DataSecurityComponent,
    DiscountFlatRateComponent,
    VatDetailsGridComponent,
    ActivityComponent,
    ActivityDetailsComponent,
  ],
  imports: [
    AuthModule.forRoot(),
    //NgMultiSelectDropDownModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NotifierModule.withConfig(customNotifierOptions),
    NgChartsModule,
    NgApexchartsModule,
    NgxDatetimeRangePickerModule.forRoot(),
    NgxDropzoneModule,
    AppMaterialModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ButtonModule,
    QueryBuilderModule,
    DropDownListModule,
    NgxsModule.forRoot([
      CountryState,
      CompanyState,
      CommonState,
      MenuState,
      OverviewState,
      DashboardState,
      CommonState,
      CountryState,
      ProductState,
      QuotationState,
      InvoiceState,
      AccountState,
      CustomAccountState,
      CreditNoteState,
      BillState,
      DebitNoteState,
      FixedAssetsState,
      ReceiptState,
      PaymentsState,
      JournalsState,
      RecurringModuleState,
      BankDashboardState,
      OpeningBalanceState,
      ImportState,
      VatCodesState,
      VATSettingsState,
      MinutesOfMeetingsState,
      ScheduleNoteState,
      BankReconciliationState,
      ViewVatDetailsState,
      CashCodingState,
      SubmitVatState,
      BulkEditState,
      DirectorShareholderState,
      DividendsState,
      BudgetingState,
      BridgingVatState,
      CISState,
      CISInvoiceState,
      IntegrationState,
      FixedAssetsRegisterState,
      EmailTemplateState,
      BankTransferState,
      PermissionState,
      AnnualReportState,
      SubscriptionState,
      TicketState,
      BankOverviewState,
      CurrencyState,
      ReportState,
      QuickEntryState,
      CISReportState,
      InventoryLossOfStockState,
      GeneralDashBoardState,
      SettingsState,
      UserState,
      FixedAssetReturnState,
      InventoryState,
      ImportStateAll,
      DataSecurityState,
      ActivityState,
    ]),
    SlickCarouselModule,
    NgxSpinnerModule.forRoot(),
    NgxSummernoteModule,
    DocumentEditorAllModule,
    DocumentEditorContainerAllModule,
    CheckBoxModule,
    DropDownListAllModule,
    DatePickerAllModule,
    ToolbarModule,
    NumericTextBoxAllModule,
    DialogModule,
    GridAllModule,
    TreeGridAllModule,
    MultiSelectModule,
    MultiSelectAllModule,
    CheckBoxModule,
    NumericTextBoxModule,
  ],
  providers: [
    Title,
    DatePipe,
    OidcConfigService,
    LoggerService,
    CookieService,
    GlobalComponent,
    DummyService,
    AuthResolverService,
    CommonService,
    AuthGuard,
    NotificationService,
    DashboardService,
    ReportService,
    CompanyService,
    MenuService,
    QuotationService,
    AccountService,
    CustomAccountService,
    InvoiceService,
    CreditNoteService,
    BillService,
    DebitNoteService,
    JournalsService,
    RecurringModuleService,
    BankDasboardService,
    OpeningBalanceService,
    VATCodesService,
    VATSettingsService,
    ImportService,
    ProductService,
    MinutesOfMeetingsService,
    ViewVatDeatilsService,
    SubmitVatService,
    BulkEditService,
    DirectorShareholderService,
    DividendsService,
    BudgetingService,
    BridgingVatService,
    CISService,
    CISInvoiceService,
    IntegrationsService,
    FixedAssetsRegisterService,
    CashCodingService,
    EmailTemplateService,
    BankTransferService,
    PermissionService,
    CommonEmitterService,
    NumberPipe,
    AnnualReportService,
    SubscriptionService,
    TicketService,
    BankOverviewService,
    CurrencyService,
    QuickEntryService,
    ReportService,
    DetailRowService,
    PageService,
    SortService,
    FilterService,
    GroupService,
    CISReportService,
    GeneralDashboardService,
    SettingsService,
    UserService,
    InventoryLossOfStockService,
    FixedAssetsReturnService,
    DataSecurityService,
    InventoryService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    DecimalPipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
