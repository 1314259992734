import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Modules } from '@app/core/Enum';
import {
  FileImportRequestModel,
  ImportModel,
} from '@app/core/Models/common/import';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class ImportAllService {
  private readonly apiUrl = `${environment.apiVersionUrl}`;
  private readonly routeBase = 'Import';

  constructor(private http: HttpClient, private commonService: CommonService) {}

  getImportTemplate(moduleId: number): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/downloadImportTemplate?moduleId=${moduleId}`,
        headers
      )
      .pipe(
        switchMap((response) => {
          this.commonService.download(response);

          return of(true);
        })
      );
  }

  importModules(
    fileImportRequestModel: FileImportRequestModel,
    moduleId: number
  ): Observable<boolean> {
    const formData = new FormData();

    formData.append('file', fileImportRequestModel.file);
    formData.append('step', fileImportRequestModel.step.toString());
    formData.append(
      'isFromCapium3',
      fileImportRequestModel.isFromCapium3.toString()
    );
    formData.append('moduleId', moduleId?.toString());
    formData.append('addonId', fileImportRequestModel.addonId);
    formData.append(
      'isRecurringImport',
      fileImportRequestModel.isRecurringImport?.toString()
    );
    formData.append(
      'companyType',
      fileImportRequestModel.clientTypes?.toString()
    );

    return this.http.post<boolean>(
      `${this.apiUrl}${this.routeBase}/${this.getImportUrl(moduleId)}`,
      formData
    );
  }

  getImportUrl(moduleId: number): string {
    let url;

    switch (moduleId) {
      case Modules.Clients:
        url = 'importClients';
        break;

      case Modules.Users:
        url = 'importUsers';
        break;
    }

    return url;
  }

  saveImport(param: any, moduleId: number): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/${this.getSaveImportUrl(moduleId)}`,
      JSON.stringify(param),
      headers
    );
  }

  getSaveImportUrl(moduleId: number): string {
    let url;

    switch (moduleId) {
      case Modules.Clients:
        url = 'saveImportedClients';
        break;

      case Modules.Users:
        url = 'saveImportedUsers';
        break;
    }

    return url;
  }

  getImportHistory(): Observable<Array<ImportModel>> {
    return this.http.get<Array<ImportModel>>(
      `${this.apiUrl}${this.routeBase}/getImportHistory`
    );
  }

  downloadImportedFile(param: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/downloadImportFile`,
        JSON.stringify(param),
        headers
      )
      .pipe(
        switchMap((response) => {
          this.commonService.download(response);

          return of(true);
        })
      );
  }

  getCapiumImportSetting(moduleId: number): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getCapiumImportSetting/${moduleId}`
    );
  }

  saveCapiumImportSetting(param: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveCapiumImportSetting`,
      JSON.stringify(param),
      headers
    );
  }
}
