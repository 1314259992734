import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Modules } from '@app/core/Enum';
import { EntityTypes } from '@app/core/Enum/entity-types';
import { ActivityTask, ActivityTaskParameters, GlobalComponent } from '@app/core/Models';
import { CommonService } from '@app/core/Services';
import { GetActivityList, UserState } from '@app/core/Store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss']
})
export class ActivityDetailsComponent implements OnInit {
  selectedModule = 0;
  defaultUniversalId = Guid.EMPTY as unknown as Guid;
  universalId = this.defaultUniversalId;
  @Input()
  triggerActivityDetails: Observable<any>;
  @Input() getModuleId: number;
  @Input() activityDetails: any;
  activityList: any = [];
  activityTaskParameters: ActivityTaskParameters;
  activities: Array<ActivityTask>;
  message = '';
  hasAttachments = false;
  attachment: any[];
  entityTypes = EntityTypes;
  @Output()
  readonly triggerActivityCount = new EventEmitter<any>();

  constructor(
    private store: Store,
    public globalComponent: GlobalComponent,
    public commonService: CommonService,
    public datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.activityList = [];
    this.selectedModule = this.getModuleId;
    this.triggerActivityDetails?.subscribe(() => {
      this.createMessage(this.activityDetails.activityModels);
    });
  }


  getEmunKeyByValue(value: number, enumName: any): string {
    const indexOfS = Object.values(enumName).indexOf(
      value as unknown as InstanceType<typeof enumName>
    );
    let key = Object.keys(enumName)[indexOfS];
    key = key.replace(/([A-Z])/g, ' $1').trim();

    return key;
  }

  getList(): void {
    this.activityList = [];

    this.activityTaskParameters = {
      moduleId: this.selectedModule,
      search: '',
      entityId: this.universalId,
      startDate: '',
      endDate: '',
      dateFilter: 1,
      pageNumber: 0,
      pageSize: 0,
    };
    if (this.selectedModule !== null) {
      this.store
        .dispatch(new GetActivityList(this.activityTaskParameters))
        .pipe(
          tap((res) => {
            this.triggerActivityCount.emit(
              res.activity.activities
                ? res.activity.activities[0].activityModels.length
                : 0
            );
            if (res.activity.activities.length > 0) {
              this.createMessage(res.activity.activities[0].activityModels);
            }
          })
        )
        .subscribe();
    }
  }
  createMessage(val: any): void {
    this.activityList = [];
    this.activities = val;
    if (this.activities) {
      this.activities.forEach((items) => {
        this.message = ' ';
        const moduleName = this.getEmunKeyByValue(
          items.moduleId,
          Modules
        );
        
        this.message +=
          this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
          ' ' +
          moduleName +
          ' ';
        items.entities.forEach((entity) => {
          this.attachment = entity.attachments;
          this.hasAttachments = entity.hasAttachment ?? false;
        });

        const obj = {
          id: items.entities[0].id,
          createdByName: items.createdByName,
          name: items.entities[0].name,
          message: this.message,
          entityTypeId: items.entityTypeId,
          activityDate: items.activityDate,
          hasAttachment: this.hasAttachments,
          attachments: this.attachment,
        };
        this.activityList.push(obj);
      });
    }
  }

}
