import { Injectable } from '@angular/core';
import { DataType } from '@app/core/Enum';
import { BudgetingModel, MainList, SideListModel } from '@app/core/Models';
import { BudgetingService } from '@app/core/Services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  ArchiveAndRestoreBudgeting,
  CreateBudgeting,
  DeleteBudgeting,
  GetAllGroupTypeList,
  GetBudgetAccountList,
  GetBudgetDetailList,
  GetDataByBudgetingId,
  GetStartPeriodList,
  SaveBudgetDetail,
} from './budgeting.action';

export class BudgetingStateInfo {
  startPeriodList: Array<SideListModel>;
  budgetingId?: Guid;
  isBudgetAdded?: boolean;
  budgetingData?: BudgetingModel;
  allGroupType?: any;
  budgetAccountList?: any;
  isBudgetDetailSave?: boolean;
  mainList?: MainList;
  isLoading?: boolean;
  totalRecord?: number;
  actions?: any;
  budget?: Array<any>;
  sideListModel: Array<SideListModel>;
  isLastPage?: boolean;
}

@State<BudgetingStateInfo>({
  name: 'budgeting',
  defaults: {
    startPeriodList: [],
    sideListModel: [],
  },
})
@Injectable()
export class BudgetingState {
  constructor(private budgetingService: BudgetingService) {}

  @Selector()
  static columns(state: BudgetingStateInfo): Array<{
    name: string;
    id: Guid;
    dataType?: DataType;
    allowSortBy: boolean;
  }> {
    if (!state.mainList) {
      return [];
    }
    return state.mainList.resultSet.columns;
  }

  @Selector()
  static isLastPage(state: BudgetingStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static columnNames(state: BudgetingStateInfo): Array<string> {
    if (!state.mainList) {
      return [];
    }

    return state.mainList.resultSet.columns.map((x) => x.name);
  }

  @Selector()
  static actions(state: BudgetingStateInfo): any {
    if (!state.mainList) {
      return [];
    }

    return state.actions;
  }

  @Selector()
  static totalRecord(state: BudgetingStateInfo): number {
    return state.totalRecord ?? 0;
  }

  @Action(GetStartPeriodList)
  getStartPeriodList({ patchState }: StateContext<BudgetingStateInfo>) {
    return this.budgetingService.getStartPeriodList().pipe(
      tap((res) => {
        patchState({
          startPeriodList: res,
        });
      })
    );
  }

  @Action(GetAllGroupTypeList)
  getAllGroupTypeList({ patchState }: StateContext<BudgetingStateInfo>) {
    return this.budgetingService.GetAllGroupTypeList().pipe(
      tap((res) => {
        patchState({
          allGroupType: res,
        });
      })
    );
  }

  @Action(CreateBudgeting)
  createBudgeting(
    { patchState }: StateContext<BudgetingStateInfo>,
    action: CreateBudgeting
  ) {
    return this.budgetingService.createBudgeting(action.budget).pipe(
      tap((res) => {
        patchState({
          budgetingId: res,
          isBudgetAdded: true,
        });
      })
    );
  }

  @Action(GetDataByBudgetingId)
  getDataByBudgetingId(
    { patchState }: StateContext<BudgetingStateInfo>,
    action: GetDataByBudgetingId
  ) {
    return this.budgetingService.getDataByBudgetingId(action.budgetingId).pipe(
      tap((res) => {
        patchState({
          budgetingData: res,
        });
      })
    );
  }

  @Action(GetBudgetAccountList)
  getBudgetAccountList(
    { patchState }: StateContext<BudgetingStateInfo>,
    action: GetBudgetAccountList
  ) {
    return this.budgetingService.getBudgetAccountList(action.param).pipe(
      tap((res) => {
        patchState({
          budgetAccountList: res,
        });
      })
    );
  }

  @Action(SaveBudgetDetail)
  saveBudgetDetail(
    { patchState }: StateContext<BudgetingStateInfo>,
    action: SaveBudgetDetail
  ) {
    return this.budgetingService.saveBudgetDetail(action.param).pipe(
      tap((res) => {
        patchState({
          isBudgetDetailSave: res,
        });
      })
    );
  }

  @Action(GetBudgetDetailList)
  getBudgetDetailList(
    { patchState }: StateContext<BudgetingStateInfo>,
    action: GetBudgetDetailList
  ) {
    return this.budgetingService.getBudgetDetailList(action.param).pipe(
      tap({
        next: (result) => {
          patchState({
            mainList: result,
            totalRecord: result.paginationModel.totalItemCount,
            actions: result.actions,
          });
        },
        error: () => {
          patchState({ isLoading: false });
        },
      })
    );
  }

  @Action(DeleteBudgeting)
  deleteBudgeting(
    { getState, patchState }: StateContext<BudgetingStateInfo>,
    action: DeleteBudgeting
  ) {
    return this.budgetingService.deleteBudgeting(action.budgetIds).pipe(
      tap((res) => {
        const state = getState();

        const filteredBudget = state.budget?.filter(
          (item) =>
            !action.budgetIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.budgetIds?.includes(
              item.id ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          budget: filteredBudget,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreBudgeting)
  archiveAndRestoreBudgeting(
    { getState }: StateContext<BudgetingStateInfo>,
    action: ArchiveAndRestoreBudgeting
  ) {
    return this.budgetingService.archiveAndRestoreBudgeting(
      action.budgetIds,
      action.isArchive
    );
  }
}
