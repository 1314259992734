export * from './bridging-vat/bridging-vat.component';
export * from './bridging-vat/vat-details/vat-details.component';
export * from './flat-rate-adjustment/flat-rate-adjustment.component';
export * from './submit-vat-list/submit-vat-list.component';
export * from './submit-vat/add-submit-vat/add-submit-vat.component';
export * from './submit-vat/include-exclude-transaction/include-data/include-data.component';
export * from './submit-vat/include-exclude-transaction/include-exclude-transaction.component';
export * from './submit-vat/review-return/review-return.component';
export * from './submit-vat/submit-vat-header/submit-vat-header.component';
export * from './submit-vat/submit-vat.component';
export * from './vat-codes/add-vat-codes/add-vat-codes.component';
export * from './vat-codes/vat-codes.component';
export * from './vat-payment-popup/vat-payment-popup.component';
export * from './vat-payment-view-popup/vat-payment-view-popup.component';
export * from './vat-settings/add-vat-settings/add-vat-settings.component';
export * from './vat-settings/vat-settings.component';
export * from './vat-view/vat-view-data/vat-view-data.component';
export * from './vat-view/vat-view.component';
export * from './view-vat-detail/vat-return/vat-return.component';
export * from './view-vat-detail/view-vat-detail.component';
