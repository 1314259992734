import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MaxLength, Modules, NotificationTextMessage } from '@app/core/Enum';
import { ContractorSettings, SideListModel } from '@app/core/Models';
import { CommonService, HighlightRow } from '@app/core/Services';
import {
  GetContratorSetting,
  MenuState,
  SaveContratorSetting,
} from '@app/core/Store';
import { AddClosePopupComponent } from '@app/modules/common';
import { Select, Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-contractor-settings',
  templateUrl: './add-contractor-settings.component.html',
  styleUrls: ['./add-contractor-settings.component.scss'],
})
export class AddContractorSettingsComponent implements OnInit {
  contractorSettingsForm: FormGroup;
  hmrcCredentialsForm: FormGroup;
  businessTypeList: SideListModel[];
  contractorSettingsData: ContractorSettings;
  notificationMessage = NotificationTextMessage;
  maxLength = MaxLength;

  isExpandAll = false;
  istoggleSideList = false;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  moduleId = Modules.ContractorSettings;
  @Select(MenuState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;
  commonNotificationText = NotificationTextMessage;
  constructor(
    private store: Store,
    public commonService: CommonService,
    public highlightRow: HighlightRow,
    public dialog: MatDialog,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.contractorSettingsForm = new FormGroup({
      businessName: new FormControl('', [Validators.required]),
      businessType: new FormControl({ value: 'Contractor', disabled: true }),
      utrNumber: new FormControl('', [Validators.required]),
      payeReference: new FormControl('', [Validators.required]),
      accountOfficeRef: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[A-Za-z]{2}[0-9]{1,6}|[0-9]{1,8}$/),
      ]),
    });
    this.hmrcCredentialsForm = new FormGroup({
      userId: new FormControl(''),
      password: new FormControl(''),
    });

    this.editContractorSettings();
  }

  editContractorSettings(): void {
    this.store
      .dispatch(new GetContratorSetting())
      .pipe(
        tap((res) => {
          this.contractorSettingsForm.patchValue({
            businessName: res.CIS.contratorSettingData.businessName,
            utrNumber: res.CIS.contratorSettingData.utrNumber,
            payeReference: res.CIS.contratorSettingData.payeReference,
            accountOfficeRef:
              res.CIS.contratorSettingData.accountsOfficeReference,
          });
          this.hmrcCredentialsForm.patchValue({
            userId: res.CIS.contratorSettingData.userId,
            password: res.CIS.contratorSettingData.password,
          });
        })
      )
      .subscribe();
  }

  onCloseClick(): void {
    this.dialog.open(AddClosePopupComponent, {});
  }

  toggleAccordion(): void {
    this.isExpandAll = !this.isExpandAll;
  }

  dataSubmit(): boolean {
    this.spinner.show();

    this.contractorSettingsData = {
      businessName: this.contractorSettingsForm.controls.businessName.value,
      businessType: this.contractorSettingsForm.controls.businessType.value,
      utrNumber:
        this.contractorSettingsForm.controls.utrNumber.value.toString(),
      payeReference: this.contractorSettingsForm.controls.payeReference.value,
      accountsOfficeReference:
        this.contractorSettingsForm.controls.accountOfficeRef.value,
      userId:
        this.hmrcCredentialsForm.controls.userId.value !== ''
          ? this.hmrcCredentialsForm.controls.userId.value
          : null,
      password:
        this.hmrcCredentialsForm.controls.password.value !== ''
          ? this.hmrcCredentialsForm.controls.password.value
          : null,
    };
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.contractorSettingsForm.invalid) {
      this.commonService.addValidation(
        this.contractorSettingsForm,
        this.renderer
      );
    } else if (this.dataSubmit()) {
      this.store
        .dispatch(new SaveContratorSetting(this.contractorSettingsData))
        .pipe()
        .subscribe(
          (res) => {
            if (res !== undefined) {
              if (!isExit) {
                this.onCancel(false);
              } else {
                this.onCancel(false);
              }
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.message);
          }
        );
    }
  }

  onCancel(isCancelClick: boolean): void {
    if (isCancelClick) {
      this.editContractorSettings();
    } else {
      this.ngOnInit();
    }
  }

  toggleSideList(): void {
    this.istoggleSideList = !this.istoggleSideList;
  }
}
