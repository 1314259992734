import { Guid } from 'guid-typescript';

export class RefundInputParameters {
  accountId: Guid;
  currencyId: number;
  branchId?: Guid;
  departmentId?: Guid;
}

export class RefundListModel {
  id: Guid;
  entryNumber: string;
  accountId: Guid;
  accountName: string;
  entryDate: string;
  amount: number;
  dueAmount: number;
  symbol: string;
}
