<div class="header">
  <div class="d-flex align-items-center justify-space-between wp-100">
    <div class="d-flex align-items-center">
      <div class="logo-container">
        <a [routerLink]="'/'">
          <img src="assets/images/logo.png" alt="Logo" />
        </a>
      </div>
      <div *ngIf="showClientList" class="nav-container">
        <div class="d-flex gap-40">
          <div
            class="navigation-menu form-field"
            *ngIf="(companyCount$ | async) > 0"
          >
            <a
              [matMenuTriggerFor]="company"
              class="company-list"
              (click)="getCompanyList()"
            >
              {{ companyName }}
              <mat-icon>expand_more</mat-icon>
            </a>
            <mat-menu
              #company="matMenu"
              class="action-menu company-list-menu main-wrapper"
              xPosition="after"
            >
              <div>
                <div class="company-list-header">
                  <div class="d-flex align-items-center justify-space-between">
                    <mat-form-field
                      appearance="fill"
                      class="search-field w-300"
                      (click)="$event.stopPropagation()"
                    >
                      <input
                        matInput
                        placeholder="Search by client"
                        autocomplete="off"
                        [(ngModel)]="searchText"
                        (keyup.enter)="onSearch()"
                        (ngModelChange)="onSearchAll()"
                      />
                      <button
                        mat-button
                        class="search-button"
                        (click)="onSearch()"
                      >
                        <mat-icon>search</mat-icon>
                      </button>
                    </mat-form-field>
                    <button
                      class="action-button primary-button"
                      mat-button
                      disableRipple
                      (click)="onNewCompanyAdded()"
                    >
                      <mat-icon>add</mat-icon>
                      <span>Add New</span>
                    </button>
                  </div>
                </div>
                <div class="company-list-body">
                  <div
                    class="mat-table-wrapper"
                    (click)="$event.stopPropagation()"
                  >
                    <mat-radio-group>
                      <table
                        aria-describedby="company-list"
                        mat-table
                        matSort
                        (matSortChange)="sorting(sort.active, sort.direction)"
                        [dataSource]="companyList"
                      >
                        <ng-container matColumnDef="companyname">
                          <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            Client Name
                          </th>
                          <td
                            mat-cell
                            *matCellDef="let companyList; let element"
                          >
                            <div>
                              <a
                                class="link clamp wmx-100 fw-bold text-primary"
                                (click)="onChangeCompany(element)"
                                title="{{ companyList.companyName }}"
                                ><span>{{ companyList.companyName }}</span></a
                              >
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="companyTypeName">
                          <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            Client Type
                          </th>
                          <td
                            mat-cell
                            *matCellDef="let companyList; let element"
                          >
                            <div class="clamp">
                              <span title="{{ companyList.companyTypeName }}">
                                {{ companyList.companyTypeName }}</span
                              >
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="vatnumber">
                          <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            VAT Number
                          </th>
                          <td mat-cell *matCellDef="let companyList">
                            <div
                              class="d-flex align-items-center justify-space-between"
                            >
                              <div class="clamp">
                                <span>{{ companyList.vatNumber }}</span>
                              </div>
                              <div
                                class="d-flex align-items-center grid-actions"
                              >
                                <div class="d-flex align-items-center">
                                  <button
                                    mat-button
                                    (click)="onEditClick(companyList.id)"
                                    disableRipple
                                  >
                                    <mat-icon fontSet="material-icons-outlined"
                                      >edit</mat-icon
                                    >
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                        </ng-container>
                        <tr
                          mat-header-row
                          *matHeaderRowDef="displayedColumns; sticky: true"
                        ></tr>
                        <tr
                          mat-row
                          *matRowDef="let row; columns: displayedColumns"
                        ></tr>
                      </table>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </mat-menu>
          </div>
        </div>
      </div>
      <div *ngIf="showTopList" class="nav-container">
        <div class="d-flex gap-40">
          <div class="navigation-menu form-field">
            <a
              routerLinkActive="active"
              routerLink="/general-dashboard"
              (click)="onClick('/general-dashboard')"
            >
              <mat-icon fontSet="material-icons-outlined">grid_view</mat-icon>
              <span>Dashboard</span>
            </a>
            <a
              [ngClass]="GetClass()"
              routerLink="/general-manage/clients"
              (click)="onClick('/general-manage')"
            >
              <mat-icon fontSet="material-icons-outlined"
                >manage_accounts</mat-icon
              >
              <span>Manage</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <form [formGroup]="headerForm" class="p-0">
      <div class="account-container">
        <div class="account-menu">
          <ul class="d-flex align-items-center">
            <li *ngIf="(companyCount$ | async) > 0">
              <div class="d-flex align-items-center header-search-wrapper">
                <a (click)="onSearchClick()" *ngIf="!isSearchboxShow">
                  <mat-icon title="search">search</mat-icon>
                </a>
                <div class="header-search" *ngIf="isSearchboxShow">
                  <mat-form-field appearance="fill" class="header-search-field">
                    <input
                      matInput
                      placeholder="Search for anything"
                      [matAutocomplete]="auto"
                      formControlName="searchText"
                      (keyup)="onSearchModule($event)"
                      (keydown.Tab)="onSearchName($event)"
                      (change)="onSearchName($event)"
                      #trigger="matAutocompleteTrigger"
                      appAutocompletePosition="trigger"
                    />
                    <mat-autocomplete
                      #auto="matAutocomplete"
                      (opened)="scrollIntoView()"
                      (optionSelected)="onSearchModuleSelection($event)"
                      #input
                    >
                      <mat-option
                        *ngFor="let list of menuData"
                        [value]="list.url"
                      >
                        <span title="{{ list.name }}">{{ list.name }}</span>
                      </mat-option>
                    </mat-autocomplete>
                    <mat-icon title="search" matPrefix class="text-white"
                      >search</mat-icon
                    >
                    <a (click)="onCloseClick()" matSuffix>
                      <mat-icon title="close">close</mat-icon>
                    </a>
                  </mat-form-field>
                </div>
              </div>
            </li>
            <li *ngIf="!isGeneralDashboard">
              <a title="Quick Report">
                <mat-icon
                  [matMenuTriggerFor]="addreports"
                  fontSet="material-icons-outlined"
                  >analytics</mat-icon
                >
              </a>
              <mat-menu
                #addreports="matMenu"
                class="action-menu add-setting-menu"
                xPosition="before"
              >
                <div class="main-menu-list">
                  <div
                    *ngFor="let reportmenuItem of addReportList"
                    class="menu-list-item mb-10"
                  >
                    <div class="sub-menu-list">
                      <a
                        routerLink="{{ reportmenuItem.url }}"
                        class="d-flex align-items-center gap-5"
                      >
                        <mat-icon class="add-icon">add </mat-icon>
                        {{ reportmenuItem.name }}
                      </a>
                    </div>
                    <div class="sub-menu-list">
                      <div
                        *ngFor="let subMenu of reportmenuItem.subMenu"
                        class="sub-menu-list-item"
                      >
                        <a
                          routerLink="{{ subMenu.url }}"
                          class="d-flex align-items-center gap-5"
                        >
                          <mat-icon class="add-icon">add </mat-icon>
                          {{ subMenu.name }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-menu>
            </li>
            <li *ngIf="(companyCount$ | async) > 0 && !isGeneralDashboard">
              <a title="Quick Add">
                <mat-icon
                  class="mat-settings-icon"
                  fontSet="material-icons-outlined"
                  [matMenuTriggerFor]="addSettings"
                >
                  add_circle_outline</mat-icon
                >
              </a>
              <mat-menu
                #addSettings="matMenu"
                class="action-menu add-setting-menu"
                xPosition="before"
              >
                <div class="main-menu-list d-flex gap-20">
                  <div
                    *ngFor="let menuItem of addMenuList"
                    class="menu-list-item"
                  >
                    <div
                      class="main-menu-header d-flex gap-5 align-items-center"
                    >
                      <mat-icon
                        class="mat-settings-icon"
                        fontSet="material-icons-outlined"
                        >{{ menuItem.icon }}
                      </mat-icon>
                      <span>{{ menuItem.name }}</span>
                    </div>
                    <div class="sub-menu-list">
                      <div
                        *ngFor="let subMenu of menuItem.subMenu"
                        class="sub-menu-list-item"
                      >
                        <a
                          routerLink="{{ subMenu.addUrl }}"
                          class="d-flex align-items-center gap-5"
                        >
                          <mat-icon class="add-icon">add </mat-icon>
                          {{ subMenu.name }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-menu>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                (click)="getRecentActivity()"
                class="profile-setting-icon"
                [matMenuTriggerFor]="activitySubMenus"
                title="Activity"
              >
                <mat-icon>history</mat-icon>
              </a>
              <mat-menu
              #activitySubMenus="matMenu"
              class="activity-menu"
              xPosition="before"
            >
              <div class="header-activity">
                <div class="notification">
                  <div class="notification-list">
                    <div class="notification-list-wrapper">
                      <p
                        class="text-primary notf-title font-size-16 fw-bold mb-1"
                      >
                        Recent Activity
                      </p>
                      <ul>
                        <ul>
                          <li *ngFor="let details of activityList">
                            <div class="d-flex gap-20">
                              <div class="notification-date d-flex flex-column">
                                <span class="date">{{
                                  details.activityDate
                                    | date : "MMM dd, y"
                                    | slice : 4 : 6
                                }}</span>
                                <span class="month">{{
                                  details.activityDate
                                    | date : "MMM d, y"
                                    | slice : 0 : 4
                                }}</span>
                              </div>
                              <div class="notification-body">
                                <div class="media d-flex">
                                  <div class="main-avatar avatar-md online">
                                    <span
                                      class="rounded-img rounded-img-text"
                                      >{{
                                        details.createdByName | shortName : 2
                                      }}</span
                                    >
                                  </div>
                                  <div class="media-body">
                                    <p class="status">
                                      <span class="fw-bold">{{
                                        details.createdByName
                                      }}</span>
                                      <a href="javascript:void(0)">
                                        <mat-icon
                                          title="add"
                                          *ngIf="
                                            details.entityTypeId ===
                                              entityTypes.Created ||
                                            details.entityTypeId ===
                                              entityTypes.NoteCreated
                                          "
                                          >post_add</mat-icon
                                        >
                                        <mat-icon
                                          title="update"
                                          *ngIf="
                                            details.entityTypeId ===
                                            entityTypes.Updated
                                          "
                                          >update</mat-icon
                                        >
                                        <mat-icon
                                          title="delete"
                                          *ngIf="
                                            details.entityTypeId ===
                                            entityTypes.Deleted
                                          "
                                          >delete</mat-icon
                                        >
                                        <mat-icon
                                          title="copy"
                                          *ngIf="
                                            details.entityTypeId ===
                                            entityTypes.Copy
                                          "
                                          >file_copy</mat-icon
                                        >
                                        <mat-icon
                                          title="archive"
                                          *ngIf="
                                            details.entityTypeId ===
                                            entityTypes.Archived
                                          "
                                          >archive</mat-icon
                                        >
                                        <mat-icon
                                          title="restore"
                                          *ngIf="
                                            details.entityTypeId ===
                                            entityTypes.Restored
                                          "
                                          >restore_from_trash
                                        </mat-icon>
                                        <mat-icon
                                          title="import/export"
                                          *ngIf="
                                            details.entityTypeId ===
                                              entityTypes.Imported ||
                                            details.entityTypeId ===
                                              entityTypes.ExportedCSV ||
                                            details.entityTypeId ===
                                              entityTypes.ExportedEXCEL ||
                                            details.entityTypeId ===
                                              entityTypes.ExportedPDF
                                          "
                                          >import_export</mat-icon
                                        >
                                      </a>
                                      <span [innerHTML]="details.message">
                                      </span>
                                      <span
                                        class="fw-bold"
                                        *ngIf="
                                          entityTypes.Sent !==
                                            details.entityTypeId &&
                                          entityTypes.Reminder !==
                                            details.entityTypeId &&
                                          entityTypes.Recorded !==
                                            details.entityTypeId &&
                                          entityTypes.Submitted !==
                                            details.entityTypeId &&
                                          entityTypes.Approved !==
                                            details.entityTypeId &&
                                          entityTypes.Withdrawn !==
                                            details.entityTypeId &&
                                          entityTypes.Status !==
                                            details.entityTypeId &&
                                          entityTypes.DeletePayment !==
                                            details.entityTypeId &&
                                          entityTypes.UpdatedBasicInfo !==
                                            details.entityTypeId &&
                                          entityTypes.UpdatedReminders !==
                                            details.entityTypeId
                                        "
                                        >{{ details.name }}</span
                                      >
                                    </p>
                                    <div
                                      class="d-flex align-items-center mt-5"
                                      *ngIf="details.hasAttachment"
                                    >
                                      <div
                                        class="d-flex gap-10"
                                        *ngFor="
                                          let files of details.attachments;
                                          let i = index
                                        "
                                      >
                                        <div>
                                          <span
                                            class="text-primary-400 fw-normal"
                                            >{{ files.attachmentName }}</span
                                          >
                                          <p class="time">{{ files.size }}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <p class="time">
                                      at
                                      {{
                                        details.activityDate
                                          | date : "shortTime"
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </ul>
                      <div class="pt-10">
                        <a
                          class="hyperlink link"
                          href="javascript:void(0)"
                          (click)="onMoveToActivityPage()"
                          >View All Activity</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-menu>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                class="profile-setting-icon"
                [matMenuTriggerFor]="settingSubMenus"
                title="Settings"
              >
                <mat-icon>settings_outline</mat-icon>
              </a>
              <mat-menu
                #settingSubMenus="matMenu"
                class="action-menu setting-menu"
                xPosition="before"
              >
                <ng-container>
                  <button
                    *ngIf="!isGeneralDashboard"
                    mat-menu-item
                    disableRipple
                    (click)="onMoveToGeneralDashboard()"
                  >
                    <mat-icon fontSet="material-icons-outlined">home</mat-icon
                    >General Dashboard
                  </button>
                  <button
                    mat-menu-item
                    disableRipple
                    [routerLink]="'manage/templates'"
                  >
                    <mat-icon fontSet="material-icons-outlined">mail</mat-icon
                    >Email Template
                  </button>
                </ng-container>
              </mat-menu>
            </li>
            <li class="module-list separation pl-1">
              <a
                href="javascript:void(0)"
                [matMenuTriggerFor]="capiumMenu"
                title="Modules"
              >
                <div class="m-0">
                  <img
                    src="assets/images/module-icon.png"
                    width="18"
                    height="18"
                    class="capium-logo"
                    alt="Capium Logo"
                  />
                </div>
              </a>
              <mat-menu
                #capiumMenu="matMenu"
                class="action-menu dropdown-module-list"
                xPosition="after"
                [overlayPanelClass]="'custom-overlay-panel'"
              >
                <div class="justify-content-between">
                  <ul>
                    <ng-container
                      *ngFor="let subscriptionModule of subscriptionModuleList"
                    >
                      <li
                        [ngClass]="
                          subscriptionModule.isDisabled ? 'disable-box' : ''
                        "
                        *ngIf="subscriptionModule.isDisplay"
                      >
                        <a
                          [href]="appUrl ? appUrl : '#'"
                          (click)="redirectToModule(subscriptionModule, $event)"
                        >
                          <div class="top-card d-flex gap-10">
                            <img
                              [src]="subscriptionModule.iconSrc"
                              alt="logo"
                            />
                            <div>
                              <h6>{{ subscriptionModule.externalName }}</h6>
                              <p>{{ subscriptionModule.content }}</p>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                  <div class="top-switch-button text-align-right">
                    <button
                      type="button"
                      class="mat-focus-indicator action-button primary-button mat-button mat-button-base"
                      (click)="redirectToHome()"
                    >
                      <span class="mat-button-wrapper">
                        <img
                          src="assets/images/capihome.svg"
                          alt="Icon"
                          class="pr-5"
                        />
                        <span _ngcontent-krt-c167="">Home </span>
                      </span>
                      <span class="mat-ripple mat-button-ripple"> </span>
                      <span class="mat-button-focus-overlay"></span>
                    </button>
                    <button
                      type="button"
                      class="mat-focus-indicator action-button secondary-button ml-1 mat-button mat-button-base"
                    >
                      <span class="mat-button-wrapper">
                        <a (click)="myAdminClick()">
                          <img
                            src="assets/images/myadmin.svg"
                            alt="Icon"
                            class="pr-5"
                          />
                          <span>My Admin</span>
                        </a>
                      </span>
                      <span
                        matripple=""
                        class="mat-ripple mat-button-ripple"
                      ></span>
                      <span class="mat-button-focus-overlay"></span>
                    </button>
                  </div>
                </div>
              </mat-menu>
            </li>
            <li>
              <a [routerLink]="'/ticket'" title="Ticket">
                <mat-icon class="rotate" fontSet="material-icons-outlined"
                  >confirmation_number</mat-icon
                >
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                class="profile-setting-icon p-0"
                title="Help"
                (click)="onHelpClick()"
              >
                <mat-icon>help_outline</mat-icon>
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                [matMenuTriggerFor]="profileInfo"
                title="User Profile"
              >
                <div
                  *ngIf="
                    userData?.picture !== undefined &&
                    userData?.picture !== null
                  "
                >
                  <img
                    class="profile-image-thumb"
                    [src]="userData?.picture"
                    width="35"
                    height="35"
                    alt="User photo"
                  />
                </div>
                <div
                  *ngIf="
                    userData?.picture === undefined ||
                    userData?.picture === null
                  "
                  class="rounded-border m-0"
                  title="User Image"
                >
                  {{ userName | shortName: 2 }}
                </div>
              </a>
              <mat-menu
                #profileInfo="matMenu"
                class="action-menu profile-setting-menu"
                xPosition="after"
              >
                <div class="text-align-center profile-settings">
                  <div
                    class="mb-1"
                    *ngIf="
                      userData?.picture !== undefined &&
                      userData?.picture !== null
                    "
                  >
                    <img
                      class="profile-image"
                      [src]="userData?.picture"
                      alt="User photo"
                    />
                  </div>
                  <div
                    *ngIf="
                      userData?.picture === undefined ||
                      userData?.picture === null
                    "
                    class="rounded-border"
                  >
                    {{ userName | shortName: 2 }}
                  </div>

                  <div>
                    <p
                      class="fw-bold text-transform-uppercase text-gray-100 font-size-16 mb-10 m-0"
                    >
                      {{ userName }}
                    </p>
                  </div>
                  <div>
                    <p class="text-gray-200 mb-10 m-0">User ID: {{ userId }}</p>
                  </div>
                  <div>
                    <p class="text-gray-200 mb-2 m-0">{{ userEmail }}</p>
                  </div>
                  <div class="hr pt-1">
                    <a href="javascript:void(0)" (click)="goToProfile()">
                      <span class="text-gray-100 font-size-16">My Account</span>
                    </a>
                    <span class="pl-2 pr-2 text-gray-100">|</span>
                    <a href="javascript:void(0)" (click)="signOutUser()">
                      <span class="text-danger-100 font-size-16">Sign Out</span>
                    </a>
                  </div>
                  <div class="pt-1 pb-5" *ngIf="isSubscriptionPortalEnabled">
                    <a
                      href="javascript:void(0)"
                      (click)="produdctSubscriptionClick()"
                      class="link"
                    >
                      <span class="text-primary font-size-16"
                        >Products & Subcriptions</span
                      >
                    </a>
                  </div>
                  <div class="theme-colors pt-1">
                    <mat-radio-group
                      formControlName="colorSelected"
                      class="mt-10"
                    >
                      <mat-radio-button
                        *ngFor="let color of colors"
                        [value]="color"
                        ngDefaultControl
                        (change)="onSelectionChange($event.value, true)"
                        [ngClass]="{
                          'mat-radio-checked': colorSelected === color
                        }"
                      >
                        <button
                          class="theme-selector"
                          [ngStyle]="{ 'background-color': color }"
                          type="button"
                          mat-button
                          disableRipple
                        >
                          <mat-icon>check</mat-icon>
                        </button>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </mat-menu>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</div>
<app-custom-notifications></app-custom-notifications>
